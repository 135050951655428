import React, { useState } from "react";
import { Tag, Button } from "antd";


import { useAtom } from "jotai";
import { documentoSeleccionadoAtom, enviosAtom, visibleModalAsignarCarpetaAtom } from "../../ListarSeguimientoStore";
import { carpetaAtom } from "store/local/seguimiento/SeguimientoStore";


const EtiquetaCarpeta = ({ documento }) => {
  const [carpetaSeleccionado, ] = useAtom(carpetaAtom );
  const [,setVisibleModalAsignarCarpetaAtom] = useAtom(visibleModalAsignarCarpetaAtom);
    const [documentoSeleccionado, setDocumentoSeleccionadoAtom] = useAtom(documentoSeleccionadoAtom);
    const [validar, setValidar]=useState('')

    const onClickMenuAsignarCarpeta = () => {
        setDocumentoSeleccionadoAtom(documento)
        setVisibleModalAsignarCarpetaAtom(true);


      }



    return documento?.carpeta?documento.carpeta.descripcion:<Button block type="link" onClick={onClickMenuAsignarCarpeta}> 
<Tag style={{ color: '#7f7f7f', marginRight: '0px' }}>Sin Asignar</Tag></Button>


}

export default EtiquetaCarpeta;