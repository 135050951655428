import React from "react";
import { Dropdown, Menu } from "antd";
import { SelectOutlined, CaretDownOutlined, UnorderedListOutlined, CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { EstadoSolicituPersonal } from "enums/EstadoSolicitudPersonal";
import { useAtom } from "jotai";
import {
  solicitudPersonalIdSeleccionadoAtom,
  visibleModalCancelarSolicitudAtom,
} from "../../SeguimientoSolicitudesPersonalStore";

const MenuAcciones = ({ solicitud }) => {
  const [, setSolicitudPersonalIdSeleccionado] = useAtom(solicitudPersonalIdSeleccionadoAtom);
  const [, setvisibleModalCancelarSolicitud] = useAtom(visibleModalCancelarSolicitudAtom);

  let history = useHistory();
  const onClickMenu = (opcion) => {
    switch (opcion.key) {
      case "ver-detalle":
        onClickMenuVerDetalle();
        break;

      case "cancelar-solicitud":
        setSolicitudPersonalIdSeleccionado(solicitud.id);
        setvisibleModalCancelarSolicitud(true);
        break;

      // case "generar-convocatoria":
      //   onClickGenerarConvocatoria();
      //   break;

      // case "rechazar-solicitud":
      //   break;

      default:
        console.error("No se encuentra esa opción");
    }
  };

  const onClickMenuVerDetalle = () => {
    history.push(`/reclutamiento-y-seleccion/solicitud-personal/detalleSolicitud/${solicitud.id}`);
  };

  const onClickGenerarConvocatoria = () => {
    history.push(`/reclutamiento-y-seleccion/convocatorias/crear/${solicitud.id}/solicitud-personal`);
  };

  const menu = () => {
    return (
      <Menu onClick={onClickMenu}>
        <Menu.Item key="ver-detalle">
          <SelectOutlined /> Ver Detalle
        </Menu.Item>
        {solicitud.estado == EstadoSolicituPersonal.PENDIENTE ||
          (solicitud.estado == EstadoSolicituPersonal.INICIADO && (
            <Menu.Item key="cancelar-solicitud">
              <CloseOutlined /> Cancelar Solicitud
            </Menu.Item>
          ))}
      </Menu>
    );
  };
  return (
    <React.Fragment>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomCenter">
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
          <CaretDownOutlined style={{ paddingLeft: "2px", fontSize: "12px", fontWeight: "bold", color: "#1678c1" }} />
        </a>
      </Dropdown>
    </React.Fragment>
  );
};

export default MenuAcciones;
