import { Card, Col, Divider, Empty, Form, Input, Modal, Row, Select, Space, Steps, message } from "antd";
import { useAtom, useAtomValue } from "jotai";
import React, { useEffect } from "react";
import {
    contratoSeleccionadoAtom,
    controlesFormularioContratoAtom,
    informacionFormularioContratoAtom,
    pasoActualAtom,
    plantillaContratoSeleccionadoAtom,
    variablesContratoAtom,
    visibleFormularioContratoAtom,
    visibleModalAprobarContratoAtom,
    zoomVisorPdfAtom,
} from "../SeguimientoRenovacionContratoStore";
import { useState } from "react";
import { useListarPlantillasContratos } from "../hooks";
import { EditOutlined } from "@ant-design/icons";
import { PlantillaContratoService } from "services/PlantillaContratoService";
import { RenovacionContratosService } from "services/contratos";
import styled from "@emotion/styled";
import { Document, Page } from "react-pdf";
import { LoginService } from "services/LoginService";

const ModalAprobarContrato = () => {
    const [visible, setVisible] = useAtom(visibleModalAprobarContratoAtom);
    const [controlesFormularioContrato, setControlesFormularioContrato] = useAtom(controlesFormularioContratoAtom);
    const [loading, setLoading] = useState(false);
    const { plantillas } = useListarPlantillasContratos();
    const [visibleFormularioContrato, setVisibleFormularioContrato] = useAtom(visibleFormularioContratoAtom);
    const [contratoSeleccionado] = useAtom(contratoSeleccionadoAtom);
    const [form] = Form.useForm();
    const [pasoActual, setPasoActual] = useAtom(pasoActualAtom);
    const [informacionFormularioContrato, setInformacionFormularioContrato] = useAtom(
        informacionFormularioContratoAtom
    );
    const [plantillaContratoSeleccionado, setPlantillaContratoSeleccionado] = useAtom(
        plantillaContratoSeleccionadoAtom
    );

    const onClickBtnSiguiente = async () => {
        if (pasoActual === 0) {
            form.validateFields()
                .then(async (values) => {
                    await RenovacionContratosService.guardarDatosFormularioRenovacionContrato({
                        seguimientoContratoId: contratoSeleccionado.id,
                        plantillaContratoId: plantillaContratoSeleccionado,
                        datos: values,
                    });
                    //setInformacionFormularioContrato(values);
                    setPasoActual(pasoActual + 1);
                })
                .catch((info) => {
                    console.log("Validate Failed:", info);
                });
        } else if (pasoActual === 1) {
            setLoading(true);
            const response = await RenovacionContratosService.aprobarRenovacionContrato({
                seguimientoContratoId: contratoSeleccionado.id,
                usuarioId: LoginService.getUsuarioAutenticado().id,
            });
            message.success("Se aprobó la renovación de contrato exitosamente.");
            setLoading(false);
            setVisible(false);
        }
    };

    const onClickBtnAtras = () => {
        if (pasoActual > 0) {
            setPasoActual(pasoActual - 1);
        } else {
            setVisible(false);
        }
    };

    const onSeleccionarContratoRenovacion = async (plantillaContratoId) => {
        const response = await RenovacionContratosService.obtenerFormularioRenovacionContrato({
            plantillaContratoId: plantillaContratoId,
            seguimientoContratoId: contratoSeleccionado.id,
        });
        // const response = await PlantillaContratoService.obtenerVariables(plantillaContratoId);
        // setVariablesContrato(response.variables);
        setPlantillaContratoSeleccionado(plantillaContratoId);
        setControlesFormularioContrato(response.controles);
        setVisibleFormularioContrato(true);
    };

    return (
        <Modal
            title="Aprobar Contrato"
            open={visible}
            onOk={onClickBtnSiguiente}
            onCancel={onClickBtnAtras}
            okText={pasoActual === 1 ? "Aprobar y Notificar" : "Siguiente >"}
            cancelText={pasoActual === 1 ? "< Atrás" : "Cancelar"}
            confirmLoading={loading}
            maskClosable={false}
            destroyOnClose={true}
            width="100%"
            style={{
                top: 20,
                paddingBottom: "0px",
            }}
            bodyStyle={{
                height: "calc(100vh - 145px)",
                backgroundColor: "#f4f3ee",
            }}
        >
            <PasosRenovacionContrato />

            <div
                style={{
                    marginTop: "10px",
                    backgroundColor: "white",
                    height: "calc(100% - 45px)",
                }}
            >
                {pasoActual === 0 && (
                    <div id="renovar-contrato" style={{ padding: "10px 25%", height: "100%" }}>
                        <div style={{ fontSize: "16px", paddingBottom: "5px" }}>¿Seleccione el contrato a renovar?</div>

                        <div>
                            <Select
                                style={{
                                    width: "100%",
                                }}
                                onChange={onSeleccionarContratoRenovacion}
                                options={
                                    plantillas &&
                                    plantillas.map((plantilla) => {
                                        return {
                                            value: plantilla.id,
                                            label: plantilla.nombre,
                                        };
                                    })
                                }
                            />
                        </div>

                        {visibleFormularioContrato && (
                            <Card
                                size="small"
                                title="Información Contrato"
                                headStyle={{
                                    backgroundColor: "#ffffea",
                                }}
                                bodyStyle={{
                                    maxHeight: "calc(100% - 38px)",
                                    overflow: "auto",
                                }}
                                style={{
                                    marginTop: "15px",
                                    height: "calc(100% - 85px)",
                                }}
                            >
                                <Form form={form} layout="vertical">
                                    {controlesFormularioContrato.length > 0 &&
                                        controlesFormularioContrato.map((control) => (
                                            <Form.Item
                                                key={control.label}
                                                name={control.label}
                                                label={control.label}
                                                initialValue={control.initialValue}
                                            >
                                                <Input />
                                            </Form.Item>
                                        ))}
                                </Form>
                            </Card>
                        )}
                    </div>
                )}

                {pasoActual === 1 && <VisorPdf />}
            </div>
        </Modal>
    );
};

const PasosRenovacionContrato = () => {
    const pasoActual = useAtomValue(pasoActualAtom);
    return (
        <div style={{ padding: "10px 20%", backgroundColor: "white", borderRadius: "15px" }}>
            <Steps
                current={pasoActual}
                items={[
                    {
                        title: "Renovar Contrato",
                    },
                    {
                        title: "Validar Contrato",
                    },
                ]}
            />
        </div>
    );
};

const VisorPdf = () => {
    const [totalPaginas, setTotalPaginas] = useState(undefined);
    const [urlPdf, setUrlPdf] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const contratoSeleccionado = useAtomValue(contratoSeleccionadoAtom);
    //const errorMostrado = useAtomValue(errorMostradoAtom);
    //const urlPdf = useAtomValue(urlPdfAtom);
    //const [, setLoading] = useAtom(loadingAtom);

    useEffect(() => {
        const cargarUrlPdf = async () => {
            const url = await RenovacionContratosService.getUrlVistaPrevia(contratoSeleccionado.id);
            setUrlPdf(url);
        };
        //console.log("mostrando visor pdf....");
        cargarUrlPdf();
    }, []);

    const onLoadSuccess = async (successEvent) => {
        console.log("successEvent", successEvent);
        setTotalPaginas(successEvent.numPages);
        setLoading(false);
    };

    return (
        <div className="visor-formulario-generico-body" style={{ height: "calc(100%)" }}>
            <Document file={urlPdf} onLoadSuccess={onLoadSuccess} loading={null}>
                {Array.from(new Array(totalPaginas), (_, index) => (
                    <PaginaPdf key={`page_${index + 1}`} numeroPagina={index + 1} />
                ))}
            </Document>
        </div>
    );
};

const PaginaPdf = ({ numeroPagina }) => {
    const zoom = useAtomValue(zoomVisorPdfAtom);

    return (
        <React.Fragment>
            <Container>
                <Page pageNumber={numeroPagina} loading={null} scale={zoom} />
            </Container>
            <SeparadorPaginas />
        </React.Fragment>
    );
};

const Container = styled.div`
    position: relative;
`;

const SeparadorPaginas = () => {
    return (
        <div style={{ width: "100%", position: "relative" }}>
            <div style={{ height: "2em" }}></div>
        </div>
    );
};

export default ModalAprobarContrato;
