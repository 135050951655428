import { useEffect } from "react";
import { RenovacionContratosService } from "services/contratos";
import { useAtom } from "jotai";
import { contratosAprobadosParaRenovarAtom } from "../SeguimientoRenovacionContratoStore";

const useListarContratosAprobadosParaRenovar = () => {
    const [contratos, setContratos] = useAtom(contratosAprobadosParaRenovarAtom);

    useEffect(() => {
        const cargarDatos = async () => {
            const contratos = await RenovacionContratosService.listarContratosAprobadosParaRenovar();
            console.log("contratos", contratos);
            setContratos(contratos);
        };
        cargarDatos();
    }, []);

    return { contratos };
};

export default useListarContratosAprobadosParaRenovar;
