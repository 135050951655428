import React, { useEffect, useContext, useState, useRef } from "react";
import { Container } from "../../../components/Container";
import { HomeOutlined, LoadingOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { Table, Breadcrumb } from "antd";
import { columnas } from "./componentes/Columnas";
import {
  codigosOpcionesAtom,
  datosPaginacionAtom,
  detalleEnvioSeguimientoAtom,
  documentosConErrorAtom,
  documentosSeleccionadoAtom,
  resumenAtom,
} from "./ListarDetalleSeguimientoStore";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { ModalVisorPdf } from "components/Modal/ModalVisorPdf";
import { ModalActualizarCorreo } from "./componentes/ModalActualizarCorreo";
import { ModalHistorialNotificacion } from "./componentes/ModalHistorialNotificacion";
import {
  categoriaSeguimientoAtom,
  totalPaginacionDetalleEnviosAtom,
  botonAtrasDetalleSeguimientoAtom,
} from "store/local/seguimiento/SeguimientoStore";
import { InformacionDocumento } from "./componentes/InformacionDocumento";
import { FiltroBusqueda } from "./componentes/FiltroBusqueda";
import { BotonesGlobales } from "pages/detalle-seguimiento/listar/componentes/BotonesGlobales";
import { OpcionService } from "services";
import { DocumentoLaboralService } from "services/DocumentoLaboralService";
import { EstadoRegistro } from "enums/EstadoRegistro";
import { ModalActualizarDocumento } from "./componentes/ModalActualizarDocumento";

function ListarDetalleSeguimiento() {
  const { envioId, categoria } = useParams();

  const [, setCodigosOpciones] = useAtom(codigosOpcionesAtom);
  const [detalleEnvio, setDetalleEnvio] = useAtom(detalleEnvioSeguimientoAtom);
  const [detalleEnvioBase, setDetalleEnvioBase] = useState([]);
  const [documentosSeleccionados, setDocumentosSeleccionados] = useAtom(
    documentosSeleccionadoAtom
  );
  const [paginacionDetalleEnvios, setPaginacionDetalleEnvios] = useAtom(
    totalPaginacionDetalleEnviosAtom
  );
  const [, setCategoriaListar] = useAtom(categoriaSeguimientoAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [, setDocumentoConError] = useAtom(documentosConErrorAtom);
  const [, setResumen] = useAtom(resumenAtom);
  const [datosPaginacion, setDatosPaginacion] = useAtom(datosPaginacionAtom);
  const [loading, setLoading] = useState(false);
  const [titulo, setTitulo] = useState();
  const [reset, setReset] = useState(false);
  const [,setBotonAtras]= useAtom(botonAtrasDetalleSeguimientoAtom);
  let history = useHistory();

  //let paginacion = 30;

  const onClickBtnRetornar = () => {
    history.push("/notificacion/seguimiento");
    setBotonAtras(true);    
  };

  useEffect(() => {
    const cargarDatos = async () => {
      setLoading(true)
      const _documentos = await DocumentoLaboralService.listar(
        getUsuarioAutenticado().empresaSeleccionada.id,
        envioId,
        null,
        null,
        null,
        null,
        getUsuarioAutenticado().login,
        true
      );
      setResumenData(_documentos)
      setDetalleEnvioBase(_documentos.detalles);
      setDetalleEnvio(_documentos.detalles)
      setCategoriaListar(categoria);
      const codigosOpciones = await OpcionService.listar(getUsuarioAutenticado().id);
      setCodigosOpciones(codigosOpciones);
      setLoading(false)
    };
    cargarDatos();
  }, []);

  function setResumenData(data) {
    const _resumen = {
      documentoError: data.totalError,
      documentosVisualizados: data.totalVisualizado,
      documentosFirmados: data.totalFirmado,
      documentosNoVisualizados:isNaN(data.totalColaboradores - data.totalVisualizado)?undefined: 
      data.totalColaboradores - data.totalVisualizado,
      documentosPendientes:
      data.totalFirmado != null
          ? data?.totalColaboradores - data?.totalFirmado
          : null,
      totalDocumentos: data.totalColaboradores,
    };
    setPaginacionDetalleEnvios(data.totalColaboradores);
      setDocumentoConError(data.documentosConError);
      setResumen(_resumen);
      setTitulo(data.titulo)
  }



  useEffect(() => {

     
    
    if(detalleEnvioBase!==undefined){
      setDatosPaginacion({ ...datosPaginacion, paginaActual: 1 });
      setPaginacionDetalleEnvios(detalleEnvio.length);
    }
    
  }, [detalleEnvio]);

  const rowSelection = {
    selectedRowKeys: documentosSeleccionados,

    onChange: (selectedRowKeys, selectedRows) => {
      setDocumentosSeleccionados(selectedRowKeys);
    },
    getCheckboxProps: (documento) => ({
      disabled: documento.eliminado === true,
    }),
  };

  const onPageChange = async (paginaActual, paginaSize) => {
    setDatosPaginacion({
      paginaActual: paginaActual,
      registrosPorPagina: paginaSize,
    });
  };
  return (
    <Container
      icono={
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard">
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/notificacion/seguimiento">
            Seguimiento
          </Breadcrumb.Item>
          <Breadcrumb.Item href={null}>
            {categoria.replace(/_/g, " ")}
          </Breadcrumb.Item>
          <Breadcrumb.Item href={null}>{titulo}</Breadcrumb.Item>
        </Breadcrumb>
      }
      onClickRetornar={onClickBtnRetornar}
      botonExtra={
        <BotonesGlobales
        setReset={setReset}
        setResumenData={setResumenData}
          categoria={categoria}
          tipoDocumentoId={
            detalleEnvio[0]?.envio?.tipoDocumentoId
              ? detalleEnvio[0]?.envio?.tipoDocumentoId
              : ""
          }
          tipoDocumentoNombre={
            detalleEnvio[0]?.envio?.tipoDocumentoNombre
              ? detalleEnvio[0]?.envio?.tipoDocumentoNombre
              : ""
          }
          pendienteAprobacion={detalleEnvio[0]?.estadoRegistro!== EstadoRegistro.PENDIENTE_APROBAR}
        />
      }
    >
      <InformacionDocumento />
      <ModalActualizarCorreo />
      <ModalActualizarDocumento />
      <ModalHistorialNotificacion />
      <ModalVisorPdf />
      <FiltroBusqueda 
       detalleEnvio={detalleEnvioBase}
       setLoading={setLoading}
       reset={reset}/>

      <Table
        loading={{
          spinning: loading||reset,
          indicator: <LoadingOutlined style={{ fontSize: 28 }}  />,
          tip:"Cargando..."
        }}
        rowKey={(detalleEnvio) => detalleEnvio.id}
        dataSource={detalleEnvio}
        columns={columnas}
        rowSelection={rowSelection}
        pagination={{
          size: "small",
          total: paginacionDetalleEnvios,
          showTotal: (total) => `Total ${total} documentos`,
          current: datosPaginacion.paginaActual,
          pageSize: datosPaginacion.registrosPorPagina,
          onChange: onPageChange,
        }}
      />
    </Container>
  );
}

export default ListarDetalleSeguimiento;
