import { Input, Form, DatePicker, Select, Modal, message } from "antd";
import React,{useState, useEffect, useContext} from "react";
import { BandejaAprobacionAdministracionPersonalService } from "services/contratos/BandejaAprobacionAdministracionPersonalService";
import { LoginService } from "services/LoginService";
import { useAtom } from "jotai";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { SecurityContext } from "context/SecurityContextProvider";
import {
    contratoSeleccionadoAtom,
    contratosAtom,
    visibleModalModificarContratoAtom,
} from "../../BandejaAprobacionReclutamientoYSeleccionStore";
import ColaboradorService from "services/ColaboradorService";
import { CampoColaborador } from "enums/CampoColaborador";
import moment from "moment";

const ModalModificar = () => {
    const [visible, setVisible] = useAtom(visibleModalModificarContratoAtom);
    const [contratoSeleccionado] = useAtom(contratoSeleccionadoAtom);
    const [sedes, setSedes] = useState([]);
    const [subsedes, setSubsedes] = useState([]);
    const [secciones, setSecciones] = useState([]);
    const [cargos, setCargos] = useState([]);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [formModalTransferir] = Form.useForm();
    //const [sedeSeleccionada, setSedeSeleccionada] = useState(undefined);
    const [subsedeSeleccionada, setSubsedeSeleccionada] = useState(undefined);
    const [seccionSeleccionada, setSeccionSeleccionada] = useState(undefined);
    const [cargoSeleccionado, setCargoSeleccionado] = useState(undefined);
    //const [documentoSeleccionado, setDocumentoSeleccionado] = useAtom(contratoSeleccionadoAtom);
    const [responsablesRenovarContrato, setresponsablesRenovarContrato] = useState([]);
    const [ responsableRenovarContratoSeleccionado,setresponsableRenovarContratoSeleccionado] = useState(undefined);
    const [administradoresObra, setAdministradoresObra] = useState([]);
    const [administradorObraSeleccionado, setAdministradorObraSeleccionado] = useState(undefined);
    const [ocultarAdminObra, setOcultarAdminObra] = useState(false);
    const [ocultarTipoServicio, setOcultarTipoServicio] = useState(false);
    const [loading, setLoading] = useState(false);

    const [formModalModificar] = Form.useForm();

    const onClickBtnModificar = async () => {
        formModalModificar.validateFields()
            .then(async (seguimiento) => {
                setLoading(true);
                seguimiento.id = contratoSeleccionado.id;
                seguimiento.seccion = seccionSeleccionada;
                seguimiento.subsede = subsedeSeleccionada;

                seguimiento.contrato.fechaInicioContrato =seguimiento.contrato.fechaInicioContrato.format("DD/MM/YYYY");
                seguimiento.contrato.fechaFinContrato = seguimiento.contrato.fechaFinContrato.format("DD/MM/YYYY");

			    seguimiento.usuarioModificacion = {
				    nombreCompleto: getUsuarioAutenticado().nombreCompleto,
				    numeroDocumentoIdentidad: getUsuarioAutenticado().numeroDocumentoIdentidad
			    };

			    console.log("Informacion Cola:::", seguimiento);
                await BandejaAprobacionAdministracionPersonalService.modificarContrato(seguimiento);
                
                message.success("El contrato fue modificado.");
                setLoading(false);
                setVisible(false);
            })
            .catch((info) => {
                setLoading(false);
                console.log("Validate Failed:", info);
                message.error("No se pudo realiar la operación requerida. Intente nuevamente!")
            });
    };

    useEffect(() => {
        if (visible) {
            const cargarDatos = async () => {   
                console.log("contratoSeleccionado", contratoSeleccionado);
                const colaborador = await ColaboradorService.buscarPorNumeroDocumentoIdentidad(getUsuarioAutenticado().empresaSeleccionada.id, 
                contratoSeleccionado?.colaborador?.numeroDocumentoIdentidad);
    
                console.log("colaborador", colaborador);
                // const campoEnviado = await ColaboradorService.camposColaboradorListarNuevo(
                //     getUsuarioAutenticado().empresaSeleccionada.id
                // );
    
                const campos = await ColaboradorService.camposColaborador(
                    getUsuarioAutenticado().empresaSeleccionada.id,
                    colaborador.id
                );
    
                setSedes(campos.SEDE);
                setSubsedes(campos.SUBSEDE);
                setSecciones(campos.SECCION);
                setCargos(campos.CARGO);
    
                if (colaborador.responsableRenovarContratoId != null) {
                    const responsableContrato = await ColaboradorService.buscarPorId(
                        colaborador.responsableRenovarContratoId
                    );
                    var responsablesContratos = [];
                    responsablesContratos.push(
                        responsableContrato !== null
                            ? {
                                    id: responsableContrato.id,
                                    numeroDocumentoIdentidad: responsableContrato.numeroDocumentoIdentidad,
                                    nombreCompleto:
                                        responsableContrato.apellidoPaterno +
                                        " " +
                                        responsableContrato.apellidoMaterno +
                                        " " +
                                        responsableContrato.nombres,
                                    codigoTrabajador: responsableContrato.codigoTrabajador,
                              }
                            : null
                    );
                    setresponsablesRenovarContrato(responsablesContratos);
                }
                if (colaborador.administradorObraId != null) {
                    const administradorObra = await ColaboradorService.buscarPorId(colaborador.administradorObraId);
                    var administradoresObra = [];
                    administradoresObra.push(
                        administradorObra !== null
                            ? {
                                    id: administradorObra.id,
                                    numeroDocumentoIdentidad: administradorObra.numeroDocumentoIdentidad,
                                    nombreCompleto:
                                        administradorObra.apellidoPaterno +
                                        " " +
                                        administradorObra.apellidoMaterno +
                                        " " +
                                        administradorObra.nombres,
                                    codigoTrabajador: administradorObra.codigoTrabajador,
                              }
                            : null
                    );
                    setAdministradoresObra(administradoresObra);
                }
                formModalModificar.setFieldsValue({
                    cargo: {
                        id: colaborador.cargo?.id,
                        nombre: colaborador.cargo?.nombre,
                    },
                    sede: {
                        id: colaborador.sede?.id,
                        nombre: colaborador.sede?.nombre,
                    },
                    subsede: colaborador.subsede?.id,
                    seccion: colaborador.seccion?.id,
                    perfil: colaborador.perfil?.id,
    
                    contrato: {
                        tipoServicio:colaborador.contrato?.tipoServicio,
                        sueldo: colaborador.contrato?.sueldo,
                        fechaInicioContrato:
                            colaborador.contrato?.fechaInicioContrato &&
                            moment(colaborador.contrato?.fechaInicioContrato, "DD/MM/YYYY"),
                        fechaFinContrato:
                            colaborador.contrato?.fechaFinContrato &&
                            moment(colaborador.contrato?.fechaFinContrato, "DD/MM/YYYY"),
                    },
                    responsableRenovarContratoId: colaborador.responsableRenovarContratoId,
                    administradorObraId: colaborador.administradorObraId,
                });
    
                if (colaborador.subsede !== null) {
                    setSubsedeSeleccionada(colaborador.subsede);
                }
                if (colaborador.seccion !== null) {
                    setSeccionSeleccionada(colaborador.seccion);
                }
            };
            cargarDatos();
        } 
    }, [visible]);

    const handleOnCancel = () => {
        setVisible(false);
        formModalModificar.resetFields();
    };

    const procesarCambios = (campoEnviado) => {
		if (campoEnviado.CARGO != null) {
			setCargos(campoEnviado.CARGO);
			formModalModificar.setFieldsValue({ cargo: { id: "" } });
		}
		if (campoEnviado.SECCION != null) {
			setSecciones(campoEnviado.SECCION);
			formModalModificar.setFieldsValue({ seccion: "" });
		}
		if (campoEnviado.SEDE != null) {
			setSedes(campoEnviado.SEDE);
			formModalModificar.setFieldsValue({ sede: { id: "" } });
		}
		if (campoEnviado.SUBSEDE != null) {
			setSubsedes(campoEnviado.SUBSEDE);
			formModalModificar.setFieldsValue({ subsede: "" });
		}
    };

    // const onSelectSede = async (value, option) => {
    //     const sede = {
    //         id: option.value,
    //         nombre: option.children,
    //     };
    //     setSedeSeleccionada(sede);
    //     const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
    //         getUsuarioAutenticado().empresaSeleccionada.id,
    //         value,
    //         CampoColaborador.SEDE
    //     );
    //     procesarCambios(campoEnviado);

    //     if (option.children === "PRINCIPAL") {
    //         setOcultarTipoServicio(true);
    //         setOcultarAdminObra(true);
    //       } else {
    //         setOcultarTipoServicio(false);
    //         setOcultarAdminObra(false);
    //       }
    // };

    async function handleOnChangeSede(value, option) {
		formModalModificar.setFieldsValue({ sede: { nombre: option.children } });
		const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
			getUsuarioAutenticado().empresaSeleccionada.id,
			value,
			CampoColaborador.SEDE
		);
		procesarCambios(campoEnviado);
		if (option.children === "PRINCIPAL") {
			setOcultarAdminObra(true);
		} else {
			setOcultarAdminObra(false);
		}
	}

    const onSelectSubsede = async (value, option) => {
        const subsede = {
            id: option.value,
            nombre: option.children,
        };
        setSubsedeSeleccionada(subsede);
        const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
            getUsuarioAutenticado().empresaSeleccionada.id,
            value,
            CampoColaborador.SUBSEDE
        );
        procesarCambios(campoEnviado);
    };

    const onSelectSeccion = async (value, option) => {
        const seccion = {
            id: option.value,
            nombre: option.children,
        };
        setSeccionSeleccionada(seccion);
        const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
            getUsuarioAutenticado().empresaSeleccionada.id,
            value,
            CampoColaborador.SECCION
        );
        procesarCambios(campoEnviado);
    };

    const onSelectCargo = async (value, option) => {
        const cargo = {
            id: option.value,
            nombre: option.children,
        };
        setCargoSeleccionado(cargo);
        const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
            getUsuarioAutenticado().empresaSeleccionada.id,
            value,
            CampoColaborador.CARGO
        );
        procesarCambios(campoEnviado);
    };

    
    const onSeleccionarResponsableRenovacionContrato = (value) => {
        setresponsableRenovarContratoSeleccionado(value);
      };

    const onSearchColaboradorResponsableContrato = async (value) => {
        const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
          filtro: value.trim(),
          login: getUsuarioAutenticado().login,
        });
    
        if (value === "") {
            formModalTransferir.setFieldsValue({
            responsableRenovarContratoId: "",
          });
          setresponsableRenovarContratoSeleccionado(undefined);
          setresponsablesRenovarContrato([]);
        } else {
          setresponsablesRenovarContrato(respuesta.colaboradores);
        }
      };

      const onSeleccionarAdministradorObra = (value) => {
        setAdministradorObraSeleccionado(value);
      };
    
      const onSearchAdministradorObra = async (value) => {
        const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
          filtro: value.trim(),
          login: getUsuarioAutenticado().login,
        });
    
        if (value === "") {
            formModalTransferir.setFieldsValue({
            responsableRenovarContratoId: "",
          });
          setAdministradorObraSeleccionado(undefined);
          setAdministradoresObra([]);
        } else {
          setAdministradoresObra(respuesta.colaboradores);
        }
      };

    return (
        <Modal
            title="Modificar Contrato"
            open={visible}
            onOk={onClickBtnModificar}
            onCancel={handleOnCancel}
            okText="Modificar"
            destroyOnClose={true}
        >
           <Form layout="vertical" form={formModalModificar}>
                <Form.Item
                    label="Sede"
                    name={["sede", "id"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onChange={handleOnChangeSede}>
                        {sedes.length > 0 &&
                            sedes.map((sede) => {
                                return (
                                    <Select.Option key={sede.id} value={sede.id}>
                                        {sede.nombre}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item>
                <Form.Item name={["sede", "nombre"]} noStyle>
												<Input type="hidden" />
				</Form.Item>
                <Form.Item
                    label="Proyecto"
                    name="seccion"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onSelect={onSelectSeccion}>
                        {secciones.length > 0 &&
                            secciones.map((seccion) => {
                                return (
                                    <Select.Option key={seccion.id} value={seccion.id}>
                                        {seccion.nombre}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Area"
                    name="subsede"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onSelect={onSelectSubsede}>
                        {subsedes.length > 0 &&
                            subsedes.map((subsede) => {
                                return (
                                    <Select.Option key={subsede.id} value={subsede.id}>
                                        {subsede.nombre}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Cargo"
                    name={["cargo", "id"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onSelect={onSelectCargo}>
                        {cargos.length > 0 &&
                            cargos.map((cargo) => {
                                return (
                                    <Select.Option key={cargo.id} value={cargo.id}>
                                        {cargo.nombre}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Sueldo"
                    name={["contrato", "sueldo"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                {!ocultarTipoServicio && (
                    <Form.Item
                    label="Tipo Servicio"
                    name={["contrato", "tipoServicio"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        options={[
                            {
                                value: "SERVICIO TECNICO DE MUESTRERIA",
                                label: "SERVICIO TECNICO DE MUESTRERIA",
                            },
                            {
                                value: "PERFORACIÓN DE DIAMANTINA",
                                label: "PERFORACIÓN DE DIAMANTINA",
                            },
                            {
                                value: "PERFORACIÓN DE AIRE REVERSO",
                                label: "PERFORACIÓN DE AIRE REVERSO",
                            },
                            {
                                value: "PERFORACIÓN POZOS DE AGUA",
                                label: "PERFORACIÓN POZOS DE AGUA",
                            },
                            {
                                value: "PERFORACIÓN GEOTECNIA",
                                label: "PERFORACIÓN GEOTECNIA",
                              },
                        ]}
                    />
                </Form.Item>
                )}
                
                <Form.Item
                    label="Fecha Inicio Contrato"
                    name={["contrato", "fechaInicioContrato"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
                </Form.Item>
                <Form.Item
                    label="Fecha Fin Contrato"
                    name={["contrato", "fechaFinContrato"]}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
                </Form.Item>
                <Form.Item
                    name="responsableRenovarContratoId"
                    label="Responsable de Renovación de Contrato"
                  >
                    <Select
                      showSearch
                      allowClear
                      onSearch={onSearchColaboradorResponsableContrato}
                      onChange={onSeleccionarResponsableRenovacionContrato}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {responsablesRenovarContrato !== undefined &&
                        responsablesRenovarContrato.map((colaborador) => {
                          return (
                            <Select.Option
                              key={colaborador.id}
                              value={colaborador.id}
                            >
                              {colaborador.nombreCompleto}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="administradorObraId"
                    label="Administrador de Obra"
                    hidden={ocultarAdminObra}
                  >
                    <Select
                      showSearch
                      allowClear
                      onSearch={onSearchAdministradorObra}
                      onChange={onSeleccionarAdministradorObra}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {administradoresObra !== undefined &&
                        administradoresObra.map((colaborador) => {
                          return (
                            <Select.Option
                              key={colaborador.id}
                              value={colaborador.id}
                            >
                              {colaborador.nombreCompleto}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalModificar;
