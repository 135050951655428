import React from "react";
import { Badge, Button, Dropdown, Menu, Modal, Space, Spin, Table, Tag, Typography, notification } from "antd";
//import { userCargarPostulantes } from "../../hooks";
import { FiltroBusqueda } from "../FiltroBusqueda";
import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import { PostulantesService } from "services/reclutamiento-y-seleccion";
import { LoadingOutlined, EditOutlined, UnorderedListOutlined, CaretDownOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { abrirModalCarga } from "../../PostulanteListarStore";
import { ModalCargaMasiva } from "../carga-masiva";
import { useHistory } from "react-router-dom";

const TablaPostulantes = () => {
  //const { postulantes } = userCargarPostulantes();
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [filtros, setFiltros] = useState(undefined);
  const [reset, setReset] = useState(false);
  const [postulantes, setPostulantes] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useAtom(abrirModalCarga);
  const camposColaborador = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;

  useEffect(() => {
    if (filtros !== undefined) {
      actualizarData();
    }
  }, [filtros]);

  async function actualizarData(pagina) {
    setLoading(true);
    const _accesoPlataforma = filtros["accesoPlataforma"]?.map((p) => {
      return p.replace("SI", true).replace("NO", false);
    });
    // const _tipoDocumento = filtros["tipoDocumento"]?.map((p) => {
    //   return p.replace("Carnet de Extranjería", "CARNET_EXTRANJERIA").replace("Pasaporte", "PASAPORTE");
    // });

    try {
      const respuesta = await PostulantesService.listar(
        getUsuarioAutenticado().empresaSeleccionada.id,
        _accesoPlataforma,
        filtros["estado"],
        null,
        filtros["colaborador"],
        filtros["documentoIdentidad"],
        getUsuarioAutenticado().login,
        pagina
      );
      //console.log(respuesta)
      setPostulantes(respuesta.postulantes);
      setReset(false);
      // if (pagina === undefined) {
      //   setPaginaActual(1);
      // }
  
      setLoading(false);
    } catch (error) {
      notification.error({
        message:"Error",
        description: error.response.data.mensaje
      })
      setLoading(false)
    }

    
  }

  function cerrarModal() {
    setVisibleModal(false);
    setReset(true);
    setFiltros({});
  }

  const renderBody = (props, columnas) => {
    return (
      <tr className={props.className}>
        {columnas.map((item, idx) => {
          if (item.visible) {
            return props.children[idx];
          }
        })}
      </tr>
    );
  };

  const renderHeader = (props, columnas) => {
    return (
      <tr>
        {columnas.map((item, idx) => {
          if (item.visible) return props.children[idx];
        })}
        ;
      </tr>
    );
  };

  const columnas = [
    {
      title: "Postulante",
      dataIndex: "postulante",
      align: "left",
      width: "25%",
      render: (text, fila) => (
        <span>
          <Typography.Text strong>{fila.nombreCompleto}</Typography.Text>
          <br />
          {fila.tipoDocumentoIdentidad + " - " + fila.numeroDocumentoIdentidad}
        </span>
      ),
    },
    {
      title: "Correo electrónico",
      dataIndex: "correoElectronico",
      align: "left",
      width: "15%",
    },
    {
      title: "Información",
      dataIndex: "gerencia",
      align: "left",
      visible: false,
      width: "20%",
      render: (text, fila) => (
        <div>
          {camposColaborador.length > 0 &&
            camposColaborador.map((campoColaborador) => {
              const campo = campoColaborador.campo.toLowerCase();
              const entrie = fila[`${campo}`];
              if (entrie != null)
                return (
                  <li key={entrie.nombre}>
                    <Badge color="orange" />
                    <Typography.Text strong> {campoColaborador.etiqueta}: </Typography.Text>
                    {entrie.nombre}
                  </li>
                );
              return null;
            })}{" "}
        </div>
      ),
    },
    {
      title: "Género",
      dataIndex: "sexoColaborador",
      align: "center",
      width: "10%",
    },
    {
      title: "Fecha de Nacimiento",
      dataIndex: "fechaNacimiento",
      align: "center",
      width: "12%",
    },
    {
      title: "Estado",
      dataIndex: "estadoRegistro",
      align: "center",
      width: "6%",

      fixed: "right",
      render: (_, fila) => (
        <React.Fragment>
          {fila.estadoRegistro === "ACTIVO" || fila.estadoRegistro === null ? (
            <Tag color="green">{fila.estadoRegistro}</Tag>
          ) : (
            <Tag color="red">{fila.estadoRegistro}</Tag>
          )}
        </React.Fragment>
      ),
    },
    {
      title: "Acciones",
      //dataIndex: "estado",
      align: "center",
      width: "10%",
      fixed: "right",

      render: (text, fila) => (
        <React.Fragment>
          <MenuAcciones fila={fila} />
        </React.Fragment>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 28 }} />} spinning={loading} tip="Cargando...">
        <FiltroBusqueda setFiltros={setFiltros} filtro={filtros} reset={reset} filtroActual={filtros} />

        <Table
          rowKey={(postulante) => postulante.id}
          dataSource={postulantes}
          columns={columnas}
          // scroll={{
          //   x: 2500,
          // }}
          // components={{
          //   header: {
          //     row: (props) => renderHeader(props, columnas)
          //   },
          //   body: {
          //     row: (props) => renderBody(props, columnas)
          //   },}}
        />
      </Spin>
      <ModalCargaMasiva visibleModal={visibleModal} onCerrarModal={cerrarModal} />
    </React.Fragment>
  );
};

export default TablaPostulantes;

const MenuAcciones = ({ fila }) => {
  let history = useHistory();

  const handleOnClickEditarColaborador = (param) => {
    history.push(`/personal/postulante/editar/${param}`);
  };

  function handleOnclickMenu({ item, key }) {
    if (key === "1") {
      handleOnClickEditarColaborador(item.props.colaborador.id);
    }
  }

  const menu = (colaborador) => (
    <Menu onClick={handleOnclickMenu}>
      <Menu.Item key="1" colaborador={colaborador}>
        <EditOutlined /> Editar
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown trigger={"click"} overlay={() => menu(fila)}>
      <Button type="text" className="custom-color-element" onClick={(e) => e.preventDefault()}>
        <UnorderedListOutlined /> <CaretDownOutlined />
      </Button>
    </Dropdown>
  );
};
