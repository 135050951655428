import React, { useContext, useEffect, useState } from "react";
import { Container } from "components/Container";
import { useHistory } from "react-router";
import * as atoms from "./CrearSubsedeStore";
import { useAtom } from "jotai";
import { useModalConfirmar } from "store/global";
import { ModalConfirmar } from "components/Modal/ModalConfirmar";
import {SubsedeRegistro} from "./subsede-registro";
import SubsedeService from "services/SubsedeService";
import { notification } from "antd";

const CrearSubsedePage=()=>{
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [subsedeAtom]=useAtom(atoms.subsede);
    const cerrarModalConfirmacion = useModalConfirmar(state => state.cerrarModal);

    const onClickRetornarListarSubsedes=()=>{
        history.push("/subsede");
    }
    const onClickBtnCancelarModal=()=>{
        cerrarModalConfirmacion();
    }
    const onClickBtnConfirmarModal = async ()=>{
        try {
            setLoading(true);
            const _subsede= await SubsedeService.insertar(subsedeAtom);
            setLoading(false);
            cerrarModalConfirmacion();
             history.push("/subsede");
            
        } catch (error) {
            console.error(error);
            cerrarModalConfirmacion();
            notification.error({
                message:"Error",
                description:error.response.data.mensaje});
            
        }finally{
            setLoading(false);
        }

    }
    return(
        <Container titulo="Crear Subcentro de costo" onClickRetornar={onClickRetornarListarSubsedes}>
         <SubsedeRegistro/>
         <ModalConfirmar
                loading={loading}
                mensaje="¿Está seguro de guardar el subcentro de costo? "
                onClickBtnCancelar={onClickBtnCancelarModal}
                onClickBtnConfirmar={onClickBtnConfirmarModal} />
        </Container>
    )

}
export default CrearSubsedePage;