export const EtapaDocumento = Object.freeze({
    POSTULANTE:{
        id:"2f45c2b4-b524-4418-921d-f6f0ec4e639e",
        nombre:"POSTULANTE",
    },
    SELECCIONADO:{
        id:"e554c979-5fe1-4c6a-96f7-7905a2a75f81",
        nombre:"SELECCIONADO",
    },
    CONTRATADO:{
        id:"3cf5f5d4-d97a-48e4-a595-28139911c479",
        nombre:"CONTRATADO",
    },
});