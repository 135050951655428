import React, { useState } from "react";
import { Tabs, Button } from "antd";
import { useAtom } from "jotai";
import { SolicitudesPendientesAprobacionPage } from "./pendiente-aprobacion";
import { ConsultaSolicitudesPage } from "./consulta-solicitudes";
import { visibleModalCrearSolicitudAtom } from "./SolicitudesAusenciaStore";

const SolicitudesAusenciaPage = () => {
    return (
        <ContainerTabs>
            <Tabs destroyInactiveTabPane={true}>
                <Tabs.TabPane tab="Solicitudes pendientes de aprobación" key="solicitudes-pendientes-aprobacion">
                    <SolicitudesPendientesAprobacionPage />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Consultar solicitudes" key="consultar-solicitudes">
                    <ConsultaSolicitudesPage />
                </Tabs.TabPane>
            </Tabs>
        </ContainerTabs>
    );
};

const ContainerTabs = ({ children }) => {
    return <div style={{ padding: "10px 20px", background: "white" }}>{children}</div>;
};

export default SolicitudesAusenciaPage;
