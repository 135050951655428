import React, { useContext, useEffect, useState } from "react";
import { FilterServer } from "components/MultiFiltro";
import { SecurityContext } from "context/SecurityContextProvider";
import UsuarioService from "services/UsuarioService";
import SedeService from "services/SedeService";

const FiltroBusqueda = ({ setFiltros, reset = false, filtroActual }) => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [sedes, setSedes] = useState([]);

  const isLaredo = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20132377783" ? true : false;
  const isExplomin = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837" ? true : false;

  let listaFiltros = [];
  let filtrosBusqueda = {
    titulo: "Filtrar por: ",
    data: [
      { id: "colaborador", titulo: "Apellidos y Nombres", tipo: "texto" },
      { id: "documentoIdentidad", titulo: "Documento Identidad", tipo: "texto" },
      { id: "codigoColaborador", titulo: "Código de Colaborador", tipo: "texto" },
      { id: "accesoPlataforma", titulo: "Acceso a Plataforma", tipo: "lista" },
      { id: "estado", titulo: "Estado del colaborador", tipo: "lista" },
      // { id: "tipoDocumento", titulo: "Tipo de Documento", tipo: "lista" },
      // { id: "centroCosto", titulo: "Centro de costo", tipo: "lista" },
    ],
  };

  if (isExplomin) {
    filtrosBusqueda = {
      titulo: "Filtrar por: ",
      data: [
        { id: "colaborador", titulo: "Apellidos y Nombres", tipo: "texto" },
        { id: "documentoIdentidad", titulo: "Documento Identidad", tipo: "texto" },
        { id: "codigoColaborador", titulo: "Código de Colaborador", tipo: "texto" },
        { id: "proyecto", titulo: "Proyecto", tipo: "texto" },
        { id: "reclutador", titulo: "Reclutador", tipo: "texto" },
        { id: "accesoPlataforma", titulo: "Acceso a Plataforma", tipo: "lista" },
        { id: "estado", titulo: "Estado del colaborador", tipo: "lista" },
        { id: "fechaIngreso", titulo: "Fecha de ingreso (MM/YYYY)", tipo: "texto" },

      ],
    };
  }

  if (isLaredo) {
    filtrosBusqueda = {
      titulo: "Filtrar por: ",
      data: [
        { id: "colaborador", titulo: "Apellidos y Nombres", tipo: "texto" },
        { id: "documentoIdentidad", titulo: "Documento Identidad", tipo: "texto" },
        { id: "codigoColaborador", titulo: "Código de Colaborador", tipo: "texto" },
        { id: "subsede", titulo: "Gerencia", tipo: "texto" },
        { id: "seccion", titulo: "Área", tipo: "texto" },
        { id: "planilla", titulo: "Departamento", tipo: "texto" },
        { id: "cargo", titulo: "Cargo", tipo: "texto" },
        { id: "accesoPlataforma", titulo: "Acceso a Plataforma", tipo: "lista" },
        { id: "estado", titulo: "Estado del colaborador", tipo: "lista" },
      ],
    };
  }


  useEffect(() => {
    const cargarDatos = async () => {
      const respuesta = await SedeService.listarPorUsuario(getUsuarioAutenticado().login);
      setSedes(respuesta);
    };
    cargarDatos();
  }, []);

  /** centro de costo: omitido temporalmente */
  // sedes.length > 0 &&
  //   sedes.map((sede) => {
  //     var objetoSede = {
  //       centroCosto: sede.nombre,
  //     };
  //     listaFiltros.push(objetoSede);
  //   });

  listaFiltros.push({ accesoPlataforma: "SI" });
  listaFiltros.push({ accesoPlataforma: "NO" });
  listaFiltros.push({ estado: "ONBOARDING" });
  listaFiltros.push({ estado: "ACTIVO" });
  listaFiltros.push({ estado: "CESADO" });
  listaFiltros.push({ tipoDocumento: "DNI" });
  // listaFiltros.push({ tipoDocumento: "Carnet de Extranjería" });
  // listaFiltros.push({ tipoDocumento: "Pasaporte" });

  const onFiltrar = (filtrosSeleccionados) => {
    try {
      if (filtroActual !== undefined) {
        if (!(Object.entries(filtroActual).length == 0 && Object.entries(filtrosSeleccionados).length == 0)) {
          setFiltros(filtrosSeleccionados);
        }
      } else if (filtroActual === undefined) {
        setFiltros(filtrosSeleccionados);
      }
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  return (
    <FilterServer
      placeholder="Haz clic aquí para filtrar los colaboradores"
      filtros={filtrosBusqueda}
      onFiltrarDatos={onFiltrar}
      data={listaFiltros}
      reset={reset}
      filtroSeleccionadoInicial={{ id: "colaborador", titulo: "Apellidos y Nombres", tipo: "texto" }}
    />
  );
};

export default FiltroBusqueda;
