import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";
import qs from "qs";

const URL_API = ApiUrl.RECLUTAMIENTO_Y_SELECCION;

const EntrevistaService = {
  async insertar(entrevista) {
    const respuesta = await ApiService(URL_API).post("/entrevistas", entrevista);
    return respuesta.data;
  },
  async listar() {
    const respuesta = await ApiService(URL_API).get("/entrevistas");
    return respuesta.data;
  },
  async listarPorPostulante(postulanteId,convocatoriaId) {
    const respuesta = await ApiService(URL_API).get("/entrevistas/postulantes", {
      params: {
         postulanteId: postulanteId,
         convocatoriaId:convocatoriaId },
    });
    return respuesta.data;
  },

  async listarConFiltros(fechaEntrevista,estado){
    const respuesta = await ApiService(URL_API).get("/entrevistas/listar-con-filtros",{
      params:{
        fechaEntrevista:fechaEntrevista,
        estado:estado,
      },
      // paramsSerializer: (params) => {
      //   return qs.stringify(params, { arrayFormat: "repeat" });
      // },
    })
    return respuesta.data;
  },
  async actualizarEstado(entrevista){
    
    const respuesta= await ApiService(URL_API).put("/entrevistas/actualizar-estado",entrevista);
    return respuesta.data;
  }
};

export { EntrevistaService };
