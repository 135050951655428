import { atom } from "jotai";

export const vacacionesAtom = atom([]);
export const visibleDrawerCargarHistoricoVacacionesAtom = atom(false);
export const formCargaHistoricaVacacionesAtom = atom({ archivo: undefined, clave: undefined });
export const visibleModalDetalleVacacionesAtom = atom(false);
export const colaboradorSeleccionadoAtom = atom(undefined);
export const paginaActualAtom = atom(1);
export const tamanioPaginadoAtom = atom(30);
export const totalRegistrosAtom = atom(0);
export const filtrosSeleccionadosAtom = atom({});