import { useAtom } from "jotai";
import * as atoms from "../../NotificarComunicadoStore";
import {EstadoCertificadoDigital} from "../../../../../../enums/EstadoCertificadoDigital";
import React from "react";
import { Alert,Typography } from "antd";

export function AlertCertificadoDigital(){
    const [respuestaCertificadoExpirado,setRespuestaCertificadoExpirado]=useAtom(atoms.respuestaCertificadoExpiradoAtom);
    const [mostrarAlerta,setMostrarAlerta]=useAtom(atoms.mostrarAlertaAtom);
    const {Text}=Typography;
    return(
        <React.Fragment>
             {
            mostrarAlerta &&(
                <div style={{marginBottom:"10px"}}>
               <Alert
                   message={
                       respuestaCertificadoExpirado ===EstadoCertificadoDigital.EXPIRADO ?
                       <Text>No puede enviar este documento porque su certificado digital se encuentra expirado. </Text>:
                       <Text >No hay certificado registrado para está empresa</Text>
                   }    
                   
                   type="warning"
                   showIcon
              />
              </div>
            )
           }
        </React.Fragment>
    )

}
