import { useAtom } from "jotai";
import { filtro } from "pages/legajo/consultas/ConsultarLegajoColaboradorStore";
import { useEffect } from "react";
import { VacacionesService } from "services/VacacionesService";
import {
  filtrosSeleccionadosAtom,
  paginaActualAtom,
  tamanioPaginadoAtom,
  totalRegistrosAtom,
  vacacionesAtom,
} from "../ControlVacacionalStorage";

const usePaginacion = () => {
  const [vacaciones, setVacaciones] = useAtom(vacacionesAtom);
  const [paginaActual, setPaginaActual] = useAtom(paginaActualAtom);
  const [tamanioPaginado] = useAtom(tamanioPaginadoAtom);
  const [totalRegistros, setTotalRegistros] = useAtom(totalRegistrosAtom);
  const [filtrosSeleccionados, setFiltrosSeleccionados] = useAtom(filtrosSeleccionadosAtom);

  useEffect(async () => {
    const respuesta = await VacacionesService.listarConPaginacion({
      colaborador: filtrosSeleccionados["colaborador"],
      documentoIdentidad: filtrosSeleccionados["documentoIdentidad"],
      codigoColaborador: filtrosSeleccionados["codigoColaborador"],
      pagina: paginaActual - 1,
      cantidad: tamanioPaginado,
    });
    setVacaciones(respuesta.data.vacaciones);
    setTotalRegistros(respuesta.data.totalRegistros);
  }, [filtrosSeleccionados, paginaActual]);

  const onCambiarPaginacion = (page, pageSize) => {
    setPaginaActual(page);
  };

  const onFiltrar = async (filtros) => {
    setFiltrosSeleccionados(filtros);
    setPaginaActual(1);
  };

  return {
    vacaciones,
    paginaActual,
    tamanioPaginado,
    totalRegistros,
    setTotalRegistros,
    onCambiarPaginacion,
    onFiltrar,
  };
};

export default usePaginacion;
