import { BaseService } from "./BaseService";
import { HttpService } from "./HttpService";

const PerfilService = {
  async listar(empresaId) {
    const respuesta = await HttpService(empresaId).get("/perfil");
    return respuesta.data;
  },

  async listarPromise(empresaId) {
    return HttpService(empresaId).get("/perfil");
  },

  async listar(empresaId) {
    const respuesta = await HttpService(empresaId).get("/perfil");
    return respuesta.data;
  },

  async insertar(perfil) {
    const respuesta = await BaseService.post("/perfil", perfil);
    return respuesta.data;
  },
  async actualizar(perfil) {
    const respuesta = await BaseService.put("/perfil", perfil);
    return respuesta.data;
  },
  async buscarPorId(id) {
    const respuesta = await BaseService.get(`/perfil/${id}`);
    return respuesta.data;
  },
  async eliminar(id) {
    const respuesta = await BaseService.delete(`/perfil/${id}`);
    return respuesta.data;
  },
  async listarPorUsuario(login) {
    const respuesta = await BaseService.get("/perfil/usuario", {
      params: {
        login: login,
      },
    });
    return respuesta.data;
  },
};

export default PerfilService;
