import { useAtom } from "jotai";
import { colaboradorAtom, contratosAtom, onboardingsIngresoAtom } from "../../../LegajoColaboradorStore";
import { useEffect } from "react";
import { ContratosColaboradorService } from "services/contratos";

const useListarContratos = () => {
    const [colaborador] = useAtom(colaboradorAtom);
    const [contratos, setContratos] = useAtom(contratosAtom);

    useEffect(() => {
        const cargarDatos = async () => {
            const respuesta = await ContratosColaboradorService.listar(colaborador.numeroDocumentoIdentidad);
            console.log("contratos", respuesta);
            setContratos(respuesta);
        };
        cargarDatos();
    }, []);

    return { contratos };
};
export default useListarContratos;
