import create from 'zustand'
import { DocumentoGenericoService } from "services/DocumentoGenericoService";

const useListaDocumentosStore = create((set, get) => ({
    loading: false,
    documentos: [],
    // getDocumentos: async (organizacionId, usuarioId) => {
    //     try {
    //         set({ loading: true });
    //         const documentos = await DocumentoGenericoService.listarPorFirmante(organizacionId, usuarioId);
    //         set({ documentos: documentos })
    //     } catch (error) {
    //         console.error(error);
    //     } finally {
    //         set({ loading: false })
    //     }
    // },
    buscarDocumentos: async (filtros) => {
        try {
            set({ loading: true });
            const documentos = await DocumentoGenericoService.listar({
                ...filtros,
                fechaEnvioInicio: filtros.fechaEnvioInicio.format("DD/MM/YYYY"),
                fechaEnvioFin: filtros.fechaEnvioFin.format("DD/MM/YYYY")
            });
            return documentos;
        } catch (error) {
            console.error(error);
        } finally {
            set({ loading: false })
        }
    },
    setDocumentos: (documentos) => {
        set({ documentos: documentos });
    },
    getUrlPdf: async (documentoGenericoId) => {
        try {
            set({ loading: true });
            const respuesta = await DocumentoGenericoService.obtenerUrlPdf(documentoGenericoId);
            return respuesta.urlDocumento;
        } catch (error) {
            console.error(error);
        } finally {
            set({ loading: false });
        }
    }

}));

export default useListaDocumentosStore;