import React from "react";
import { Container } from "components/Container";
import { Provider as JotaiProvider, useAtom } from "jotai";
import { Evaluacion } from "./evaluacion";
const NotificarEvaluacionPage = () => {
  return (
    <JotaiProvider>
      <Container titulo="Notificar Evaluación">
        <Evaluacion />
      </Container>
    </JotaiProvider>
  );
};
export default NotificarEvaluacionPage;
