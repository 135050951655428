import React from "react";
import { FilterServer } from "components/MultiFiltro";
import { EstadoProcesoEnvio } from "enums/EstadoProcesoEnvio";

const FiltroBusqueda = ({setFiltros, reset = false, filtroActual}) => {

  let listaFiltros=[];
  const filtrosBusqueda = {
    titulo: "Filtrar por: ",
    data: [
      { id: "postulante", titulo: "Postulante", tipo: "texto" },
      { id: "convocatoria", titulo: "Convocatoria", tipo: "texto" },
      { id: "estado", titulo: "Estado", tipo: "lista" },
    ],
  };
   listaFiltros.push({ estado: EstadoProcesoEnvio.COMPLETADO });
   listaFiltros.push({ estado: EstadoProcesoEnvio.EN_CURSO }); 
   
   
  const onFiltrar =  (filtrosSeleccionados) => {

    try {

      if(filtroActual!==undefined){
        if(!(Object.entries(filtroActual).length===0 && Object.entries(filtrosSeleccionados).length===0)){
          setFiltros(filtrosSeleccionados)
        }
    }else if(filtroActual===undefined){
      setFiltros(filtrosSeleccionados)
    }
        
    } catch (error) {
      console.error(error);
    } finally {

    }
  };

  return (
    <FilterServer
      placeholder="Haz clic aquí para filtrar los documentos"
      filtros={filtrosBusqueda}
      onFiltrarDatos={onFiltrar}
      data={listaFiltros}
      reset={reset}
    />
  );
};

export default FiltroBusqueda;
