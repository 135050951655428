import React from "react";
import { Tag } from "antd";
import { EstadoVisualizacion } from "enums/EstadoVisualizacion";
import { TagVisualizado } from "./TagVisualizado";

const EtiquetaEstadoVisualizacion = ({ documento }) => {
    switch (documento.estadoVisualizacion) {
        case EstadoVisualizacion.PENDIENTE:
            return <Tag color="gold" className="milegajo-ant-tag">PENDIENTE</Tag>;

        case EstadoVisualizacion.VISUALIZADO:
            return (
                <TagVisualizado documento={documento} color="green" className="milegajo-ant-tag pointer">
                    VISUALIZADO
                </TagVisualizado>
            );

         case EstadoVisualizacion.NO_APLICA:
             return <Tag style={{ color: '#7f7f7f', marginRight: '0px' }}>NO APLICA</Tag>;

        default:
            return <Tag color="gold" className="milegajo-ant-tag">PENDIENTE</Tag>;
    }

}
export default EtiquetaEstadoVisualizacion;