import React from "react";
import { Loading3QuartersOutlined } from "@ant-design/icons";

export const Loading = () => {
  return (
    <div className="loading">
      <Loading3QuartersOutlined spin className="spin" />
    </div>
  );
};
