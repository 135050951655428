import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";
import qs from "qs";
const URL_API = ApiUrl.RECLUTAMIENTO_Y_SELECCION;

const ProcesoService = {
  async listar() {
    const respuesta = await ApiService(URL_API).get("/procesos");
    return respuesta.data;
  },
  async crear(proceso) {
    const respuesta = await ApiService(URL_API).post("/procesos", proceso);
    return respuesta.data;
  },
  async listarDocumentos(procesoId) {
    const respuesta = await ApiService(URL_API).get(`/procesos/${procesoId}/documentos`);
    return respuesta.data;
  },
  async listarResumenDocumentos(procesoId) {
    const respuesta = await ApiService(URL_API).get(`/procesos/${procesoId}/documentos/resumen`);
    return respuesta.data;
  },
  async obtenerDocumentosPorProcesoYFiltro(procesoId, documento, responsable, etapa, estadoDocumento, estadoAprobacion) {
    const respuesta = await ApiService(URL_API).get(`/procesos/${procesoId}/documentos/filtro`, {
    params: {
      documento: documento,
      responsable: responsable,
      etapa: etapa,
      estadoDocumento: estadoDocumento,
      estadoAprobacion: estadoAprobacion,
    },
    paramsSerializer: (params) => {
    return qs.stringify(params, { arrayFormat: "repeat" });
    },
    });
    return respuesta.data;
    },
    async actualizarParametros(proceso) {
      const respuesta = await ApiService(URL_API).put("/procesos/documentos/editarParametros", proceso);
      return respuesta.data;
    },
    async listarProcesoPorFiltro(convocatoria, postulante, estado, pagina, cantidad) {
      const respuesta = await ApiService(URL_API).get("/procesos/busquedaAvanzada", {
        params: {
          convocatoria: convocatoria,
          postulante: postulante,
          estado: estado,
          pagina: pagina,
          cantidad: cantidad,
        },
        paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
        },
        });
      return respuesta.data;
    },
    async obtenerProceso(procesoId) {
      const respuesta = await ApiService(URL_API).get(`/procesos/${procesoId}/obtenerProceso`);
      return respuesta.data;
    },
    async agregarProcesos(procesoId,proceso) {
      const respuesta = await ApiService(URL_API).put(`/procesos/documentos/${procesoId}/agregarProcesos`, proceso);
      return respuesta.data;
    },

    async obtenerURlconvocatoria(numeroDocumentoIdentidad) {
      const respuesta = await ApiService(URL_API).get("/procesos/obtenerEnlaceConvocatoria",{
        params:{
          numeroDocumentoIdentidad:numeroDocumentoIdentidad
        }
      });
      return respuesta.data;
    },
};
export { ProcesoService };
