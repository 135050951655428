import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const Botones = () =>{

    let history = useHistory();

    const BotonCrearTipoDocumento = () =>{

       const onClickBtnCrearTipoDocumento = () =>{
            history.push("/configuracion/tipo-documentos/crear");
        }
        return(
            <Button
            type="primary"
            icon={<PlusOutlined/>}
            onClick={onClickBtnCrearTipoDocumento}            
            >
                Crear Tipo Documento
            </Button>
        )

    }

    return(
        <React.Fragment>
            <BotonCrearTipoDocumento/>
        </React.Fragment>
    )


};
export default Botones;
