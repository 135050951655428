import React from "react";
import { useContext, useEffect } from "react";
import { useAtom } from "jotai";
import {formulariosAtom} from "../SolicitudPendienteAprobacionStore";
import TipoDocumentoService from "services/TipoDocumentoService";
import { CategoriaDocumento } from "enums/CategoriaDocumento";

const useListarFormularios=()=>{

    const [formularios,setFormularios]=useAtom(formulariosAtom);

    useEffect(()=>{
        const cargarDatos = async()=>{
           const respuesta= await TipoDocumentoService.listar("",CategoriaDocumento.FORMATO_AUSENCIA.id);
           setFormularios(respuesta);
        }
        cargarDatos();
    },[])
  return {formularios};

}
export default useListarFormularios;
