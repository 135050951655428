import React from "react";
import { Container } from "components/Container";
import { useHistory
 } from "react-router-dom";
 import { RegistroDescargaMasiva } from "./componentes"; 

const DescargaMasivaPage=()=>{
    let history = useHistory();

    const onClickRetornarListarDescargas=()=>{
      history.push("/consultar/descarga-masiva");
    }
   return(
    <Container titulo={"Crear Descarga Masiva"} onClickRetornar={onClickRetornarListarDescargas}>
        <RegistroDescargaMasiva/>
    </Container>
   )
   
}
export default DescargaMasivaPage;