import React from "react";
import { Button } from "antd";
import {visibleModalCrearCargaAtom, listaArchivosAtom} from "../../LegajoCargaStore";
import { useAtom } from "jotai";
import {PlusOutlined} from"@ant-design/icons";
//import {visibleModalCrearCargaAtom, listaArchivosAtom} from "../../LegajoCargaStore";

const Botones =()=>{
    const [, setCargaArchivo] = useAtom(listaArchivosAtom);
    const[visibleModalCrearCarga,setVisibleModalCrearCarga] = useAtom(visibleModalCrearCargaAtom);

    const onClickCrearCarga = () =>{
      setVisibleModalCrearCarga(true);
      setCargaArchivo([]);
      console.log("visble:",visibleModalCrearCarga);
    }
  return(
    <Button type="primary" icon={<PlusOutlined />} onClick={onClickCrearCarga}>Subir documento</Button>
  )
}
export default Botones;