import { VacacionesService } from "services/VacacionesService";
import { filtrosSeleccionadosAtom, paginaActualAtom,
  tamanioPaginadoAtom,} from "../ControlVacacionalStorage";
import { useAtom } from "jotai";
import { saveAs } from "file-saver";
import { notification } from "antd";

const useGenerarReporteExcelSolicitudes = ()=>{

    const [filtrosSeleccionados, setFiltrosSeleccionados] = useAtom(filtrosSeleccionadosAtom);

    const generarReporteSolicitudes= async()=>{     
        try {
          const respuesta= await VacacionesService.descargarReporteExcelSolicitudes({          
           colaborador: filtrosSeleccionados["colaborador"],
           documentoIdentidad: filtrosSeleccionados["documentoIdentidad"],
           codigoColaborador: filtrosSeleccionados["codigo"],       
           });
         const filename = respuesta.headers.filename;
         saveAs(respuesta.data,filename);
        } catch (error) {
         notification.error({
           message:"No hay vacaciones para exportar",        
           description: error.response.data.mensaje,
          }
         )
        }                
       }
       return{
         generarReporteSolicitudes,
       }

}
export default useGenerarReporteExcelSolicitudes;