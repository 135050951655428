import React, { useContext } from "react";
import { EstadoNotificacion } from "enums/EstadoNotificacion";
import { Tag, Button } from "antd";
import { useHistory } from "react-router";
import { categoriaAtom, documentoSeleccionadoAtom } from "../../ListarSeguimientoStore";
import { useAtom } from "jotai";
import { EstadoProcesoEnvio } from "enums/EstadoProcesoEnvio";
import { SecurityContext } from "context/SecurityContextProvider";
import { paginaSeguimientoAtom } from "store/local/seguimiento/SeguimientoStore";
import Text from "antd/lib/typography/Text";

const BotonDocumentoNombre = ({ documento }) => {
  const [paginaSeguimiento, setPaginaSeguimiento] = useAtom(paginaSeguimientoAtom);
  const [, setCategoria] = useAtom(categoriaAtom);
  const { setDatosEnvio } = useContext(SecurityContext);
  const [, setDocumentosSeleccionado] = useAtom(documentoSeleccionadoAtom);
  let history = useHistory();
  //setEnvio(documento);

  const onClickMenuVerDetalle = () => {
    setCategoria(documento?.categoriaDocumento?.nombre);
    setDocumentosSeleccionado(documento);
    switch (documento.estado) {
      case EstadoProcesoEnvio.REGISTRADO:
        if (documento.categoriaDocumento.nombre === "DOCUMENTO LABORAL") {
          setDatosEnvio(
            documento.id,
            documento.tipoDocumentoNombre,
            documento.descripcion,
            documento.tipoDocumentoId,
            documento.flujo,
            documento.formatoCargaId,
            null,
            documento.listaTipoDocumentos
          );
          return history.push("/documento-laboral/listarEnvioNotificacion");
        } else if (documento.categoriaDocumento.nombre === "CONTRATOS") {
          return history.push(`/contrato/seguimiento/${documento.id}/detalle`);
        }
      case EstadoProcesoEnvio.PENDIENTE_NOTIFICAR:
        return history.push(`/contrato/seguimiento/${documento.id}/detalle`);

      default:
        return history.push(
          `/notificacion/seguimiento/detalle/${documento.categoriaDocumento.nombre
            .replace(/\w\S*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            })
            .replace(/\s/g, "_")}/${documento.id}`
        );
    }
  };

  return documento.tipoDocumentoNombre ? (
    <React.Fragment>
      <Button block type="text" onClick={onClickMenuVerDetalle} style={{ textAlign: "left" }}>
        <Text strong>{documento.tipoDocumentoNombre}</Text>
        <br />
        {documento.descripcion}{" "}
      </Button>
    </React.Fragment>
  ) : (
    <React.Fragment>
      <Button block type="text" onClick={onClickMenuVerDetalle} style={{ textAlign: "left" }}>
        <Text strong>
          {documento.categoriaDocumento.nombre.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          })}
        </Text>
        <br />
        {documento.descripcion}
      </Button>
    </React.Fragment>
  );
};

export default BotonDocumentoNombre;
