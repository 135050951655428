import React from "react";
import { MenuAcciones } from "../MenuAcciones";


export const columnas = [
  
    {
      title: "Documento",
      dataIndex: ["colaborador","numeroDocumentoIdentidad"],    
    },
    {
      title: "Colaborador",
      dataIndex: ["colaborador","nombreCompleto"],

    },
    {
      title: "Código",
      dataIndex: ["colaborador","codigo"],
      render: (text, fila) =>  fila.colaborador.codigo?.length>0?fila.colaborador.codigo:"-",

    },
    {
      title: "Bandeja", 
      dataIndex: ["colaborador","correoElectronico"],
    },
    {
      title: "Acciones",
      align: "center",
      width: "10%",
      render: (text, fila) => (<MenuAcciones envio={fila}/>),
    }
  ];
