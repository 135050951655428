import { useEffect } from "react";
import { useAtom } from "jotai";
import {
	contratosSeguimientoAtom,
	loadingContratosSeguimientos,
} from "../BandejaAprobacionAdministracionPersonalStore";
import { BandejaSeguimientoAdministracionPersonalService } from "services/contratos/BandejaSeguimientoAdministracionPersonalService";

const useListarContratosSeguimiento = () => {
	const [contratosSeguimiento, setContratosSeguimiento] = useAtom(contratosSeguimientoAtom);
	const [, setLoading] = useAtom(loadingContratosSeguimientos);
	useEffect(() => {
		const cargarDatos = async () => {
			setLoading(true);
			const respuesta = await BandejaSeguimientoAdministracionPersonalService.listarContratos({
				filtro: "",
			});
			setContratosSeguimiento({
				...contratosSeguimiento,
				contratos: respuesta.contratos,
				paginaActual: respuesta.paginaActual,
				totalContratos: respuesta.totalContratos,
			});
			setLoading(false);
		};
		cargarDatos();
	}, []);

	return { contratosSeguimiento, setContratosSeguimiento };
};
export default useListarContratosSeguimiento;
