import React, { useState, useContext } from "react";
import { Modal, Button, Input, Form, Row, notification } from "antd";
import TipoDocumentoService from "services/TipoDocumentoService";
import { SecurityContext } from "context/SecurityContextProvider";
import { CategoriaDocumento } from "enums/CategoriaDocumento";
import { useAtom } from "jotai";
import {
    visibleModalTiposDocumentoAtom,
    tipoDocumentosAtom,
    tipoDocumentoSeleccionadoAtom} from "../../LegajoCargaStore";

export function ModalAgregarTipoDocumento() {
    const [frmCrearTipoDocumento] = Form.useForm();
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const[tipoDocumentoSeleccionado,setTipoDocumentoSeleccionado]=useAtom(tipoDocumentoSeleccionadoAtom);
    const [visibleModalTiposDocumento, setVisibleModalTiposDocumento] = useAtom(visibleModalTiposDocumentoAtom);
    const [tipoDocumentos, setTipoDocumentos] = useAtom(tipoDocumentosAtom);

    function onClickCerrarModal() {
        setVisibleModalTiposDocumento(false);
    }
    async function onClickAgregarTipoDocumento(formulario) {
        try {
            formulario.empresaId = getUsuarioAutenticado().empresaSeleccionada.id;
            formulario.categoriaDocumento = {
                id: CategoriaDocumento.CARGA_LEGAJO.id,
                nombre: CategoriaDocumento.CARGA_LEGAJO.nombre,
            };
            const tipoDocumentoInsertado=await TipoDocumentoService.insertarNuevo(formulario);
            
            onClickCerrarModal();

        //     const _tipoDocumento = await TipoDocumentoService.listarPorUsuario(getUsuarioAutenticado().login);
        //     let documentoListado=null;
        //  if (_tipoDocumento!==null){
        //     documentoListado = _tipoDocumento.filter(f=> f.categoriaDocumento.nombre==="COMUNICADOS");
        //     setTipoDocumentos(documentoListado);
            
        // }else{
        //     documentoListado = await TipoDocumentoService.listar(
        //       getUsuarioAutenticado().empresaSeleccionada.id,CategoriaDocumento.COMUNICADOS.id);
        //       setTipoDocumentos(documentoListado);
        // }
        let _documentos=[];
        const _tipoDocumento = await TipoDocumentoService.listarPorUsuarioYCategoria(getUsuarioAutenticado().empresaSeleccionada.id,getUsuarioAutenticado().login, CategoriaDocumento.DOCUMENTO_LABORAL.id);

        const _tipoDocumentoCargaLegajos = await TipoDocumentoService.listarPorUsuarioYCategoria(getUsuarioAutenticado().empresaSeleccionada.id,getUsuarioAutenticado().login, CategoriaDocumento.CARGA_LEGAJO.id);
        
        _documentos = _documentos.concat(_tipoDocumento, _tipoDocumentoCargaLegajos);

        setTipoDocumentos(_documentos);
       console.log("tipoDocumentoInsertado.id", tipoDocumentoInsertado.id);
        //setTipoDocumentoSeleccionado(tipoDocumentoInsertado);
        if(_tipoDocumentoCargaLegajos!=null){
            if(_tipoDocumentoCargaLegajos.filter(c=> c.id===tipoDocumentoInsertado.id).length>0){
                setTipoDocumentoSeleccionado(tipoDocumentoInsertado.id);
            }
        
        }
            
            

        } catch (error) {
            console.error("error:", error);
            onClickCerrarModal();
            notification.error({
                message: "No se puede registrar el tipo documento",
                description: error.response.data.mensaje,
            });
        }


    }
    function onLimpiarDatos() {
        frmCrearTipoDocumento.setFieldsValue({
            nombre: "",
        })

    }

    return (
        <Modal
            title="Tipo Documento"
            open={visibleModalTiposDocumento}
            onCancel={onClickCerrarModal}
            //destroyOnClose={false}
            afterClose={onLimpiarDatos}
            // maskClosable={false}
            footer={null}
            //forceRender

        >

            <Form
                onFinish={onClickAgregarTipoDocumento}
                form={frmCrearTipoDocumento}
            >
                <Form.Item
                    label="Tipo Documento"
                    name="nombre"
                    //valuePropName="tipoDocumento"
                    rules={[{ required: true, message: "Ingrese el tipo documento" }]}
                >
                    <Input autoComplete="off" />


                </Form.Item>
                <Form.Item>
                    <Row justify="center">
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            Agregar
                        </Button>
                    </Row>
                </Form.Item>

            </Form>


        </Modal>
    );


}