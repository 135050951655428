import React, { useEffect, useState } from "react";
import { Modal, Progress, Skeleton } from "antd";
import { visibleModalVisualizarResumenAtom } from "../../ColaboradorListarStore";
import { useAtom } from "jotai";
import ColaboradorService from "../../../../../services/ColaboradorService";

const ModalVisualizarResumen = () => {
	const [visible, setVisible] = useAtom(visibleModalVisualizarResumenAtom);
	const [totalActivos, setTotalActivos] = useState(0);
	const [totalOnboarding, setTotalOnboarding] = useState(0);
	const [totalCesados, setTotalCesados] = useState(0);
	const [total, setTotal] = useState(0);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const cargaInicial = async () => {
			if (visible) {
				setLoading(true);
				try {
					const response = await ColaboradorService.contarColaboradoresPorEstado();
					setTotalActivos(response.ACTIVO);
					setTotalOnboarding(response.ONBOARDING);
					setTotalCesados(response.CESADO);
					setTotal(response.ACTIVO + response.ONBOARDING + response.CESADO);
				} catch (error) {
					console.error(error);
				}
				setLoading(false);
			}
		};
		cargaInicial();
	}, [visible]);

	// Componente circular de progreso
	const ProgresoCircular = ({ descripcion, porcentaje, total, color = "#1890ff" }) => {
		return (
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
				<Progress
					type="circle"
					percent={porcentaje}
					strokeColor={color}
					format={() => <span style={{ color: color, fontWeight: 'bold' }}>{total}</span>}
				/>
				<div style={{ marginTop: '10px', color: color }}>{descripcion}</div>
			</div>
		);
	};

	// const ProgresoBarra = ({ descripcion, porcentaje, total, color = "#1890ff" }) => {
	// 	return (
	// 		<div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
	// 			<span style={{ width: '40%', textAlign: 'right', paddingRight: '10px' }}>{descripcion}</span>
	// 			<Progress percent={porcentaje} size="small" strokeColor={color} format={() => total} />
	// 		</div>
	// 	);
	// };

	return (
		<Modal
			title="Resumen Colaboradores"
			open={visible}
			width={800}
			onCancel={() => setVisible(false)}
			footer={null}
		>
			<Skeleton loading={loading} active paragraph={{ rows: 4 }} title={false}>
				{/* Gráficos de progreso circulares */}
				<div style={{ display: 'flex', justifyContent: 'space-around' }}>
					<ProgresoCircular
						descripcion="Total"
						porcentaje={Math.round((total * 100) / total)}
						total={total}
						color="#1890ff"
					/>
					<ProgresoCircular
						descripcion="Activos"
						porcentaje={Math.round((totalActivos * 100) / total)}
						total={totalActivos}
						color="#52c41a"
					/>
					<ProgresoCircular
						descripcion="Onboarding"
						porcentaje={Math.round((totalOnboarding * 100) / total)}
						total={totalOnboarding}
						color="#d48806"
					/>
					<ProgresoCircular
						descripcion="Cesados"
						porcentaje={Math.round((totalCesados * 100) / total)}
						total={totalCesados}
						color="#ff4d4f"
					/>
				</div>

				{/* Barras de progreso */}
				{/* <div style={{ marginTop: '40px' }}>
					<ProgresoBarra
						descripcion="Total"
						porcentaje={Math.round((total * 100) / total)}
						total={total}
						color="#1890ff"
					/>
					<ProgresoBarra
						descripcion="Activos"
						porcentaje={Math.round((totalActivos * 100) / total)}
						total={totalActivos}
						color="#52c41a"
					/>
					<ProgresoBarra
						descripcion="Onboarding"
						porcentaje={Math.round((totalOnboarding * 100) / total)}
						total={totalOnboarding}
						color="#d48806"
					/>
					<ProgresoBarra
						descripcion="Cesados"
						porcentaje={Math.round((totalCesados * 100) / total)}
						total={totalCesados}
						color="#ff4d4f"
					/> */}
				{/* </div> */}
			</Skeleton>
		</Modal>
	);
};

export default ModalVisualizarResumen;
