import React from "react";
import { Provider as JotaiProvider, Provider } from "jotai";
import { Container } from "components/Container";
import {Botones} from "./Botones";
import {TablaFirmaHolografa} from "./Tabla-Firma-Holografa";
import {ModalVerFirmaHolografa} from "./ModalVerFirmaHolografa";

const ListarFirmaHolografa=()=>{
return(
    <Provider>
        <Container titulo=" Mis Firmas Hológrafas" botonExtra={<Botones/>}>
            <TablaFirmaHolografa/>
        </Container>
        <ModalVerFirmaHolografa/>
        
    </Provider>
)

}
export default ListarFirmaHolografa;