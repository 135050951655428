import { atom } from "jotai";
import { EstadoSolicitudAusencia } from "enums/EstadoSolicitudAusencia";

export const solicitudesAusenciaAtom = atom(false);
export const paginaActualAtom = atom(1);
export const tamanioPaginadoAtom = atom(30);
export const totalRegistrosAtom = atom(0);
export const filtroColaboradorAtom = atom(undefined);
export const filtroFechaInicioAtom = atom(undefined);
export const filtroFechaFinAtom = atom(undefined);
export const filtroEstadoAtom = atom(EstadoSolicitudAusencia.APROBADO);
export const filtroEstadoJefeInmediatoAtom = atom(EstadoSolicitudAusencia.APROBADO);
export const filtroEstadoOtrosCargosAtom = atom(EstadoSolicitudAusencia.APROBADO);
export const filtroTipoAusenciaAtom = atom(undefined);
export const filtroEstadoAprobacionAtom = atom(EstadoSolicitudAusencia.APROBADO);

export const solicitudAusenciaSeleccionadaAtom = atom(undefined);
export const visibleModalSeguimientoAtom = atom(false);
export const visibleModalAdjuntarAltaMedicaAtom = atom(false);
export const visibleModalEliminarSolicitudAtom = atom(false);
export const visibleModalCrearSolicitudAtom = atom(false);
export const visibleModalCrearSolicitudAusenciaAtom = atom(false);
export const tipoAusenciaSeleccionadaAtom = atom(undefined);

export const openDrawerImportarAusenciasMasivasAtom = atom(false);

export const loadingExportarAtom = atom(false);