import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";

const URL_API = ApiUrl.BASE;

const TransferirContratoService = {
    async transferir(data) {
        const respuesta = await ApiService(URL_API).post(`/admin/contratos/transferir`, data);
        return respuesta.data;
    },

    async anular(data) {
        const respuesta = await ApiService(URL_API).post(`/admin/contratos/transferir/anular`, data);
        return respuesta.data;
    },
};

export { TransferirContratoService };
