import { BaseService } from "./BaseService";
import qs from "qs";
export const ContratoVencidoService={
    async listar(params){
        const respuesta= await BaseService.get("/contratoVencido",{
            params:params,
            paramsSerializer: (params) => {
                return qs.stringify(params, { arrayFormat: "repeat" });
              },
            },
        );
        return respuesta.data;
    },
    async insertarEvaluacion(evaluacion) {
        const respuesta = await BaseService.post("/contratoVencido/evaluacion/notificar", evaluacion);
        return respuesta.data;
      },
    
    async descargarExcel(parametros) {
        const respuesta = await BaseService.get(
            "/contratoVencido/descargarReporteExcel",         
          {
            responseType: "blob",
            params: parametros,
            paramsSerializer: (params) => {
              return qs.stringify(params, { arrayFormat: "repeat" });
            },
          }
        );
        return respuesta;
      }, 
      
      async descargarTodosContratosTodosColaboradores(){
        const respuesta= await BaseService.get("/contratoVencido/descargarReporteExcelTodosContratos",
        {
          responseType: "blob",
          
        }
        );
        return respuesta;
    },
     
}