import React from "react";
import { useAtom } from "jotai";
import { Modal, Form, Checkbox, DatePicker, Button } from "antd";
import { visibleModalDocumentoAtom, documentoSeleccionadoAtom } from "../../AgregarDocumentoStore";
import useCargarDocumentos from "../../hooks/useCargarDocumentos";
import { useEffect } from "react";
import moment from "moment";

const ModalDocumento = () => {
  const [visibleModalDocumento, setVisibleModalDocumento] = useAtom(visibleModalDocumentoAtom);
  const [documentoSeleccionado, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
  const { documentos, setDocumentos } = useCargarDocumentos();
  const [formDocumento] = Form.useForm();

  useEffect(() => {
    const cargarDatos = () => {
      if (documentoSeleccionado) {
        formDocumento.setFieldsValue({
          fechaLimite: documentoSeleccionado.fechaLimite && moment(documentoSeleccionado.fechaLimite, "DD/MM/YYYY"),
          caducidad: documentoSeleccionado.caducidad,
          obligatorio: documentoSeleccionado.obligatorio,
        });
      }
    };
    cargarDatos();
  }, [documentoSeleccionado]);

  const onCancel = () => {
    formDocumento.resetFields();
    setDocumentoSeleccionado(undefined);
    setVisibleModalDocumento(false);
  };
  const onclickActualizarDocumento = () => {
    formDocumento
      .validateFields()
      .then((form) => {
        setDocumentos(
          documentos.map((documento) => {
            if (documento.id === documentoSeleccionado.id) {
              return {
                ...documento,
                caducidad: form.caducidad,
                obligatorio: form.obligatorio,
                fechaLimite: form.fechaLimite?.format("DD/MM/YYYY"),
              };
            } else {
              return documento;
            }
          })
        );

        onCancel();
      })
      .catch((info) => {
        console.log(info);
      });
  };
  return (
    <Modal title="Editar Documento" visible={visibleModalDocumento} footer={null} onCancel={onCancel}>
      <Form layout="vertical" form={formDocumento}>
        <Form.Item name="fechaLimite" label="Fecha Límite">
          <DatePicker format={"DD/MM/YYYY"} allowClear={false} />
        </Form.Item>
        <Form.Item name="caducidad" valuePropName="checked">
          <Checkbox>¿Documento tiene caducidad?</Checkbox>
        </Form.Item>
        <Form.Item name="obligatorio" valuePropName="checked">
          <Checkbox>¿Documento es obligatorio?</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={onclickActualizarDocumento}>
            Actualizar
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default ModalDocumento;
