import React, { useEffect, useState } from "react";
import { Modal, Form, Select, DatePicker } from "antd";
import { visibleModalExportarFormularioAtom } from "../../ColaboradorListarStore";
import { useAtom } from "jotai";
import { FormularioService } from "services/FormularioService";
import { LoginService } from "services/LoginService";
import { saveAs } from "file-saver";
import moment from "moment";

const ModalReingreso = () => {
	const [visible, setVisible] = useAtom(visibleModalExportarFormularioAtom);
	const [formularios, setFormularios] = useState([]);
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const cargaInicial = async () => {
			try {
				const response = await FormularioService.listarPorUsuario(LoginService.getUsuarioAutenticado().login);
				setFormularios(response);
			} catch (error) {
				console.error(error);
			}
		};
		cargaInicial();
	}, []);

	const onClickBtnExportar = () => {
		form.validateFields()
			.then(async (values) => {
				setLoading(true);
				console.log("values::::", values);
				values.periodo = values.periodo != null ? moment(values.periodo).format("MM/YYYY") : null;

				const response = await FormularioService.exportarDatos(values);
				console.log("response", response);
				setLoading(false);
				// const filename = respuesta.headers.filename;
				saveAs(response.data, response.headers.filename);
			})
			.catch((info) => {
				console.log("Validate Failed:", info);
			});
	};

	return (
		<Modal
			title="Exportar Formulario"
			open={visible}
			okText="Exportar"
			onOk={onClickBtnExportar}
			onCancel={() => setVisible(false)}
			loading={loading}
			confirmLoading={loading}
		// footer={null}
		>
			<Form form={form} autoComplete="off" layout="vertical">
				<Form.Item
					label="Formulario"
					name="formularioId"
					rules={[{ required: true, message: "Seleccione el formulario" }]}
				>
					<Select
						showSearch
						placeholder="Selecciona el formulario"
						filterOption={(input, option) =>
							option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
						}
					// onChange={onChangeFormulario}
					>
						{formularios.length > 0 &&
							formularios.map((formulario) => {
								return (
									<Select.Option key={formulario.id} value={formulario.id}>
										{formulario.nombre}
									</Select.Option>
								);
							})}
					</Select>
				</Form.Item>
				<Form.Item label="Periodo" name="periodo">
					<DatePicker picker="month" format="MM/YYYY" style={{ width: "50%" }} />
				</Form.Item>
			</Form>
		</Modal>
	);
};
export default ModalReingreso;
