import React from "react";
import { EstadoDocumentoGenerico } from "enums/EstadoDocumentoGenerico"
import { Tag } from "antd";
import { FileTextOutlined, FileExcelOutlined, FileSyncOutlined, FileDoneOutlined } from "@ant-design/icons"
import "./index.css";

const EtiquetaNombreDocumentoGenerico = ({ descripcion, estado }) => {
    return (
        <div className="container-nombre-documento-generico">
            <FileTextOutlined style={{ fontSize: '28px', paddingRight: '10px', color: 'rgb(98 112 123)' }} /> { descripcion}
        </div >
    );

    // switch (estado) {
    //     case EstadoDocumentoGenerico.PENDIENTE_FIRMA:
    //         return (
    //             <div className="container-nombre-documento-generico">
    //                 <FileSyncOutlined className="icono pendiente" /> {descripcion}
    //             </div>
    //         )

    //     case EstadoDocumentoGenerico.FIRMADO:
    //         return (
    //             <div className="container-nombre-documento-generico">
    //                 <FileDoneOutlined className="icono firmado" /> {descripcion}
    //             </div>
    //         )

    //     case EstadoDocumentoGenerico.RECHAZADO:
    //         return (
    //             <div className="container-nombre-documento-generico">
    //                 <FileExcelOutlined className="icono rechazado" /> {descripcion}
    //             </div>
    //         )

    //     default:
    //         return <Tag>-</Tag>;
    // }
}

export default EtiquetaNombreDocumentoGenerico;