export const Flujo=Object.freeze({
//    SIN_APROBACION: "SIMPLE_SIN_APROBADOR",
//    CON_APROBACION: "APROBACION_Y_FIRMA_GENERICA"  
SIN_APROBACION:{
    nombre:"Sin Aprobación",
    valor:"SIMPLE_SIN_APROBADOR",

} ,
CON_APROBACION:{
   nombre:"Con Aprobación",
   valor:"APROBACION_Y_FIRMA_GENERICA",
},
});