import React from "react";
import { Table, Tag,Space,Form,Select, Empty } from "antd";
import { useListarSolicitudPersonal } from "../../hooks";
import Title from "antd/lib/skeleton/Title";
import MenuAcciones from "./MenuAcciones";
import { EstadoSolicituPersonal } from "enums/EstadoSolicitudPersonal";
import {filtroEstadoSolicitudPersonalAtom} from "../../SeguimientoSolicitudesPersonalStore";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { SpinLoading } from "components/SpinLoading";

const TablaSeguimientoSolicitudPersonal = () => {
  const { solicitudesPersonal, loading } = useListarSolicitudPersonal();
  const [,setFiltroEstadoSolicitud] = useAtom(filtroEstadoSolicitudPersonalAtom);
  const [formFiltro] = Form.useForm();

  useEffect(()=>{
    setFiltroEstadoSolicitud(formFiltro.getFieldValue("estado"));
  },[]);

  const onSelectEstado=()=>{   
   setFiltroEstadoSolicitud(formFiltro.getFieldValue("estado"));
  }

  return (
    <React.Fragment>
      <Space>
          <Form 
            form={formFiltro}
             layout="inline"
             initialValues={{
              estado:"TODOS"
             }}
             > 
            <Form.Item name="estado" label="Estado">
              <Select style={{ width: 300 }} onSelect={()=>onSelectEstado()}>
                <Select.Option value="TODOS">TODOS</Select.Option>
                <Select.Option value="PENDIENTE">PENDIENTE</Select.Option>
                <Select.Option value="INICIADO">INICIADO</Select.Option>
                <Select.Option value="CANCELADO">CANCELADO</Select.Option>
                <Select.Option value="RECHAZADO">RECHAZADO</Select.Option>
                <Select.Option value="CERRADO">CERRADO</Select.Option>
              </Select>
            </Form.Item>            
          </Form>
      </Space>
      <SpinLoading loadingPage={loading} >
      <Table
      rowKey={(solicitud) => solicitud.id}
      dataSource={solicitudesPersonal}
      columns={columnas}
      pagination={false}
      expandable={{
        expandedRowRender
      }}
    />
  </SpinLoading>
    </React.Fragment>
    
  );
};

const expandedRowRender = (row) => {
  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Fecha Cierre',
      dataIndex: 'fechaCierre',
      key: 'fechaCierre',
    },
    {
      title: 'Tiempo Restante',
      dataIndex: 'tiempoRestante',
      key: 'tiempoRestante',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      render: (texto) => (<Tag color={texto==="DENTRO_DE_PLAZO"?"green":"red"} >{texto}</Tag>),
    },
  ];

  return (row.listaContratados && row.listaContratados.length !== 0)?<Table columns={columns} rowKey={(detalle) => detalle.nombre+detalle.cierre} dataSource={row.listaContratados} pagination={false} />:<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"Sin personal"}/>;
};

const columnas = [
  {
    title: "Perfil",
    dataIndex: ["perfilPuesto", "descripcion"],
    align: "center",
  },
  {
    title: "Puesto",
    dataIndex: ["puesto", "nombre"],
    align: "center",
  },
  {
    title: "Reclutador",
    align: "center",
    render: (fila) => (fila.responsableConvocatoria !== null ? fila.responsableConvocatoria : "-"),
  },
  {
    title: "Solicitante",
    dataIndex: ["solicitante", "nombreCompleto"],
    align: "center",
  },
  {
    title: "Estado",
    align: "center",
    render: (fila) => <EtiquetaEstado estado={fila.estado} />,
  },
  {
    title: "Fecha aprobación",
    align: "center",
    render: (fila) =>
      fila.fechaAprobacion !== null
        ? fila.fechaAprobacion
        : fila.estado !== EstadoSolicituPersonal.PENDIENTE && fila.estado !== EstadoSolicituPersonal.RECHAZADO
        ? fila.fechaSolicitud
        : "-",
  },
  {
    title: "Tiempo restante",
    align: "center",
    render: (fila) => (fila.tiempoRestante !== null ? fila.tiempoRestante : "-"),
  },
  {
    title: "Fecha cierre",
    align: "center",
    render: (fila) => (fila.fechaCierre !== null ? fila.fechaCierre : "-"),
  },
  {
    title: "Status",
    align: "center",
    //render: (fila) => (fila.status !== null ? fila.status : "-"),
    render: (fila)=><EtiquetaStatus status={fila.status}/>
  },
  {
    title: "Acciones",
    key: "acciones",
    align: "center",
    render: (fila) => <MenuAcciones solicitud={fila} />,
  },
];

const EtiquetaEstado = ({ estado }) => {
  switch (estado) {
    case EstadoSolicituPersonal.PENDIENTE:
      return (
        <Tag color="gold" className="milegajo-ant-tag">
          PENDIENTE
        </Tag>
      );

    case EstadoSolicituPersonal.INICIADO:
      return (
        <Tag color="green" className="milegajo-ant-tag">
          {EstadoSolicituPersonal.INICIADO}
        </Tag>
      );

    case EstadoSolicituPersonal.RECHAZADO:
      return (
        <Tag color="red" className="milegajo-ant-tag">
          {EstadoSolicituPersonal.RECHAZADO}
        </Tag>
      );

    case EstadoSolicituPersonal.CERRADO:
      return (
        <Tag color="magenta" className="milegajo-ant-tag">
          {EstadoSolicituPersonal.CERRADO}
        </Tag>
      );

    default:
      return <Tag className="milegajo-ant-tag">{estado}</Tag>;
  }
};

const EtiquetaStatus=({status})=>{
    switch(status){
      case "FUERA DE PLAZO":
        return(
          <Tag color="red">
              {status}
          </Tag>
        )
       case null:
         return("-")

      default:
          return <Tag className="milegajo-ant-tag">{status}</Tag>;  
    }
}
export default TablaSeguimientoSolicitudPersonal;
