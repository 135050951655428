export const destinatarioComunicado = Object.freeze({
  TODOS_LOS_COLABORADORES: "TODOS_LOS_COLABORADORES",
  SEDE: "CENTRO_DE_COSTO",
  COLABORADOR: "COLABORADOR",
  SEXO: "SEXO",
  CARGO: "CARGO",
  PADRES: "PADRES",
  MADRES: "MADRES",
  CARGA_EXCEL_DNIS: "CARGA_EXCEL_DNIS",
});
