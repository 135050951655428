export const CategoriaDocumento = Object.freeze({
  COMUNICADOS: {
    id: "39af41a3-fec5-4089-a8e8-c303e2e0cafd",
    nombre: "COMUNICADOS",
  },
  COMUNICADOS_FORMULARIOS: {
    id: "a0260d32-ce5b-4269-91d4-cc390725b85a",
    nombre: "COMUNICADOS PERSONALIZADOS",
  },
  CONTRATOS: {
    id: "869c9220-e9bd-4285-af35-656a8935e7e4",
    nombre: "CONTRATOS",
  },
  DOCUMENTO_LABORAL: {
    id: "1e2cf094-d086-4626-a4bb-ea16170ca18d",
    nombre: "DOCUMENTO LABORAL",
  },
  FORMULARIOS: {
    id: "869c9220-e9bd-4285-af35-656a8935e7e5",
    nombre: "FORMULARIOS",

  },
  EVALUACION: {
    id: "0dd0eb10-0f82-44b3-9254-9b00be850c05",
    nombre: "EVALUACIÓN",
  },
  CARGA_DOCUMENTO: {
    id: "869c9220-e9bd-4285-af8e-656a8939c2d3",
    nombre: "CARGA DOCUMENTO",
  },
  FORMATO_AUSENCIA: {
    id: "976ec9a8-27cd-47e6-9a1c-9916e1dc5743",
    nombre: "FORMATO AUSENCIA",
  },
  CARGA_LEGAJO: {
    id: "566ec9a8-27cd-47e6-9a1c-9916e1dc5731",
    nombre: "CARGA LEGAJO",
  },
  CARGA_MANUAL: {
    id: "566ec9a8-27cd-47e6-9a1c-9916e1dc5732",
    nombre: "CARGA MANUAL",
  },
});
