import React, { useEffect, useContext, useState } from "react";
import { Form, DatePicker, Select, Input, Button, Upload, message, notification } from "antd";
import TipoDocumentoService from "../../../../../services/TipoDocumentoService";
import EvaluacionService from "../../../../../services/EvaluacionService";
import { SecurityContext } from "context/SecurityContextProvider";
import { CategoriaDocumento } from "enums/CategoriaDocumento";
import UploadOutlined from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const Evaluacion = () => {
  const [frmNotificarEvaluacion] = Form.useForm();
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [tipoDocumentos, setTipoDocumentos] = useState([]);
  const [tipoDocumentoNombre, setTipoDocumentoNombre] = useState([]);
  const [archivoExcel, setArchivoExcel] = useState([]);
  const { Option } = Select;
  const history = useHistory();

  useEffect(() => {
    const cargarDatos = async () => {
      const _tipoDocumentos = await TipoDocumentoService.listar(
        getUsuarioAutenticado().empresaSeleccionada.id,
        CategoriaDocumento.EVALUACION.id
      );
      console.log("tipoDocumento", _tipoDocumentos);
      setTipoDocumentos(_tipoDocumentos);
    };
    cargarDatos();
  }, []);

  const propsCargaExcel = {
    onRemove: (file) => {
      setArchivoExcel([]);

      return {
        setArchivoExcel: [],
      };
    },
    beforeUpload: (file) => {
      if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        message.error(`${file.name} no es un archivo Excel`);
      } else {
        setArchivoExcel(file);
        return false;
      }
    },
    archivoExcel,
  };

  const onClickBtnNotificar = async (formulario) => {
    try {
      const evaluacion = {
        periodo: formulario.periodo.format("MM/YYYY"),
        empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
        organizacionId: getUsuarioAutenticado().organizacionId,
        descripcion: formulario.descripcion,
        tipoDocumentoId: formulario.documento,
        tipoDocumentoNombre: tipoDocumentoNombre,
      };
      // console.log("evaluacion:",evaluacion);
      const formData = new FormData();
      formData.append("fileExcel", formulario.cargarArchivoExcel.file);
      formData.append(
        "evaluacion",
        new Blob([JSON.stringify(evaluacion)], {
          type: "application/json",
        })
      );
      await EvaluacionService.insertar(formData);
      notification["success"]({
        message: "Notificación enviada",
        description: "Se ha enviado las evaluaciones exitosamente.",
      });
      history.push("/evaluacion/seguimiento");
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Error",
        description: error.response.data.mensaje,
      });
    }
  };
  const onSelectTipoDocumento = (option) => {
    console.log("option", option);
    const documento = tipoDocumentos.filter((tipoDocumento) => tipoDocumento.id === option);
    setTipoDocumentoNombre(documento[0].nombre);
  };

  return (
    <React.Fragment>
      <Form
        // name="frmNotificarEvaluacion"
        layout="vertical"
        labelCol={{ span: 10, offset: 8 }}
        wrapperCol={{ span: 10, offset: 8 }}
        onFinish={onClickBtnNotificar}
        form={frmNotificarEvaluacion}
      >
        <Form.Item label="Periodo" name="periodo" rules={[{ required: true, message: "Seleccione el periodo" }]}>
          <DatePicker picker="month" format="MM/YYYY" style={{ width: "50%" }} />
        </Form.Item>
        <Form.Item label="Documento" name="documento" rules={[{ required: true, message: "Seleccione el documento" }]}>
          <Select onSelect={onSelectTipoDocumento}>
            {tipoDocumentos.length > 0 &&
              tipoDocumentos.map((tipoDocumento) => {
                return (
                  <Option key={tipoDocumento.id} value={tipoDocumento.id}>
                    {tipoDocumento.nombre}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item label="Descripción" name="descripcion">
          <Input />
        </Form.Item>
        <Form.Item
          label="Notificar"
          name="cargarArchivoExcel"
          rules={[
            {
              required: true,
              validator: (_, value) =>
                archivoExcel.length !== 0
                  ? Promise.resolve()
                  : Promise.reject(new Error("Seleccione el Archivo Excel")),
            },
          ]}
        >
          <Upload {...propsCargaExcel} maxCount={1}>
            <Button icon={<UploadOutlined />} block style={{ whiteSpace: "normal", width: "100%" }}>
              Cargar Archivo Excel&nbsp;
            </Button>
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            block
            style={{ paddingLeft: "30px", paddingRight: "30px" }}
          >
            Notificar
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};
export default Evaluacion;
