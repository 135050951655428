import { BaseService } from "./BaseService";


export const SeguimientoService = {

  async inhabilitar(empresaId, detalleEnvioId) {
    const respuesta = await BaseService.post(`/seguimiento/empresa/${empresaId}/detalleEnvio/${detalleEnvioId}/inhabilitar`);
    return respuesta.data;
  },

  async habilitar(empresaId, detalleEnvioId) {
    const respuesta = await BaseService.post(`/seguimiento/empresa/${empresaId}/detalleEnvio/${detalleEnvioId}/habilitar`);
    return respuesta.data;
  },

  async reenviarNotificacionMasivamente(organizacionId, empresaId, envioId, listaSeguimientoId,categoria, tipoDocumentoId, tipoDocumentoNombre) {
    const respuesta = await BaseService.post("/seguimiento/reenviar-notificacion-masiva",
      {
        organizacionId: organizacionId,
        empresaId: empresaId,
        envioId: envioId,
        listaSeguimientoId: listaSeguimientoId,
        categoria: categoria,
        tipoDocumentoId: tipoDocumentoId,
        tipoDocumentoNombre: tipoDocumentoNombre
      }
    );
    return respuesta.data;
  },

  async actualizarCorreoYCelular(data) {
    const respuesta = await BaseService.post("/seguimiento/actualizar-correo-y-celular",
      {
        empresaId: data.empresaId,
        detalleEnvioId: data.detalleEnvioId,
        correoElectronico: data.correoElectronico,
        numeroCelular: data.numeroCelular,
      }
    );
    return respuesta.data;
  },

  async reprocesar(organizacionId, empresaId, envioId, listaDocumentosId){
    const respuesta = await BaseService.post("/seguimiento/reprocesar",
    {
      organizacionId: organizacionId,
        empresaId: empresaId,
        envioId: envioId,
        listaDocumentosId: listaDocumentosId,
    }
    );
    return respuesta.data
  }

};