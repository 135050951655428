import React from "react";
import {Form,Input,Button,Table,Select,message,DatePicker,Space,Row,Col,Typography, Spin} from "antd";
import {useCargarCargos} from "../../hooks";
import {useCargarUsuarios} from "../../hooks";
import { useCargarPostulantes } from "../../hooks";
import {responsableSeleccionadoAtom,
        postulanteSeleccionadoAtom,
        postulantesAgregadosAtom,
        convocatoriaSeleccionadaIdAtom,
        postulantesConvocatoriaAtom,
        loadingAtom} from "../../ConvocatoriaEditarStore";
import { useAtom } from "jotai";
import { TipoConvocatoria } from "enums/reclutamiento-y-seleccion/TipoConvocatoria";
import { MinusCircleOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { ConvocatoriaService } from "services/reclutamiento-y-seleccion/ConvocatoriaService";
import { useHistory } from "react-router-dom";
import  moment from "moment";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
//import TablaPostulantes from "./TablaPostulantes";
import { PostulantesService } from "services/reclutamiento-y-seleccion";
import { useState } from "react";
const EditarConvocatoria=()=>{

    const[formEditar]=Form.useForm();
    const {convocatoriaId}=useParams();
    const[responsableSeleccionado,setResponsableSeleccionado]=useAtom(responsableSeleccionadoAtom);
    const [,setConvocatoriaSeleccionadaId]=useAtom(convocatoriaSeleccionadaIdAtom);
    const [loading, setLoading ]=useAtom(loadingAtom);
    let history =useHistory();
    useEffect(()=>{
       const cargarDatos = async()=>{
        setLoading(true)
         const convocatoriaBuscada= await ConvocatoriaService.buscarPorId(convocatoriaId);
        // console.log("convocatoria:", convocatoriaBuscada);
            formEditar.setFieldsValue({
              id:convocatoriaBuscada.id && convocatoriaBuscada.id,
              solicitudPersonalId:convocatoriaBuscada.solicitudPersonalId ? convocatoriaBuscada.solicitudPersonalId :"",
              tipo:convocatoriaBuscada.tipo && convocatoriaBuscada.tipo,
              cargo:convocatoriaBuscada.cargo,
              objetivo:convocatoriaBuscada.objetivo && convocatoriaBuscada.objetivo,
              fechaLimitePostulacion: convocatoriaBuscada.fechaLimitePostulacion && moment(convocatoriaBuscada.fechaLimitePostulacion,"DD/MM/YYYY"),
              responsable:convocatoriaBuscada.responsable?.id,
              requisitos:convocatoriaBuscada.requisitos && convocatoriaBuscada.requisitos,
            })
            setResponsableSeleccionado(convocatoriaBuscada.responsable);
            setConvocatoriaSeleccionadaId(convocatoriaId);
          setLoading(false)
       }
       cargarDatos();
    },[]);
    const onFinishEditarConvocatoria= async( convocatoria)=>{
      setLoading(true)
      try {
           convocatoria.cargo=convocatoria.cargo.trim();
           convocatoria.responsable=responsableSeleccionado;
           convocatoria.fechaLimitePostulacion = convocatoria.fechaLimitePostulacion?.format("DD/MM/YYYY");
           if(convocatoria.solicitudPersonalId==="")
                 convocatoria.solicitudPersonalId=null;           
           //console.log("convocatoria:",convocatoria);
           await ConvocatoriaService.actualizar(convocatoria);
           history.push("/reclutamiento-y-seleccion/convocatorias");        
        
           
      } catch (error) {
        console.error(error);
      } finally{
        setLoading(false)
      }
    }
    return(
      <Spin indicator={<LoadingOutlined style={{ fontSize: 28 }} />} spinning={loading} tip="Cargando...">
          <Form layout="vertical" style={{ padding: "0px 2%" }} form={formEditar} onFinish={onFinishEditarConvocatoria}>
                          
                <InputId/>
                <SolicitudId/>
                <SelectTipo/>
                <SelectCargo/>
                <InputObjetivo/>
                <DatePickerLimite/>
                <SelectResponsable/>
                <Requisitos/>
                <BotonEditarConvocatoria/>              
                  
              {/* <Col span={12}>
                  <SelectPostulante formRef={formEditar} /> 
                  <TablaAgregarPostulantes/>
              </Col> */}                 
            
        </Form>
      </Spin>
    )
};

const InputId=()=>{
  return(
    <Form.Item name="id" hidden={true}>
       <Input/>
    </Form.Item>
  )
}
const SolicitudId=()=>{
  return(
    <Form.Item name="solicitudPersonalId" hidden={true}>
       <Input/>
    </Form.Item>
  )
}
const InputObjetivo=()=>{
  return(    
      <Form.Item
        label="Objetivo"
        name="objetivo"
       // rules={[{ required: true, message: "Ingrese el objetivo de convocatoria" }]}
      >
          <Input.TextArea rows={4}/>
      </Form.Item>
  )
}
const SelectTipo=()=>{
return(
<Form.Item
  label="Tipo"
  name="tipo"
 // rules={[{ required: true, message: "Seleccione el Tipo" }]}
>
 <Select placeholder="Seleccione el Tipo">
    <Select.Option value={TipoConvocatoria.INTERNO}>{TipoConvocatoria.INTERNO} </Select.Option>
    <Select.Option value={TipoConvocatoria.EXTERNO}>{TipoConvocatoria.EXTERNO} </Select.Option>
 </Select>
</Form.Item>
)
}   
const SelectCargo=()=>{
  
  return(
    <Form.Item
      label="Cargo"
      name="cargo"
      rules={[{ required: true, message: "Ingrese el cargo",whitespace:true }]}
    >
     
     <Input/>
    </Form.Item>
  )
}     
const SelectResponsable=()=>{
  const {responsables} = useCargarUsuarios();
  const[,setResponsableSeleccionado]=useAtom(responsableSeleccionadoAtom);

  const onChangeResponsable=(value,Option)=>{    
     const responsable={
        id:value,
        nombreCompleto:Option.children,
        login:Option.labellogin,
        numeroDocumentoIdentidad:Option.labelnumerodocumentoidentidad
     }
     //console.log("Reponsable:",responsable);
     setResponsableSeleccionado(responsable);
  }
  return(
    <Form.Item 
          label="Responsable" 
          name="responsable"
         // rules={[{ required: true, message: "Seleccione el Responsable" }]}
          >
        <Select
         showSearch
         placeholder="Seleccione el Responsable"
         onChange={onChangeResponsable}
         optionFilterProp="children"
        >
            {responsables!==undefined && responsables.map((responsable)=>{
              return(
                <Select.Option key={responsable.id} value={responsable.id} labellogin={responsable.login} labelnumerodocumentoidentidad={responsable.numeroDocumentoIdentidad}>
                  {responsable.nombreCompleto}
                </Select.Option>
              )
            })}
        </Select>
    </Form.Item>
    
  )

} 
const Requisitos=()=>{
  return(
    <Form.Item label= "Requisitos" name="requisitos">
      <Input.TextArea rows={4}/>
    </Form.Item>            
  )
 }
 const DatePickerLimite=()=>{
  return(
    <Form.Item 
        label= "Fecha Límite Postulación"
        name="fechaLimitePostulacion"
        //rules={[{ required: true, message: "Seleccione la fecha límite" }]}
        
        >
      <DatePicker format={"DD/MM/YYYY"} style={{width:"100%"}}/>
    </Form.Item>
  )
    
 }
 const SelectPostulante=({formRef})=>{
    //const {postulantes} =useCargarPostulantes();
    const[postulanteSeleccionado,setPostulanteSeleccionado]= useAtom(postulanteSeleccionadoAtom);
    const [postulantesConvocatoria,setPostulantesConvocatoria]=useAtom(postulantesConvocatoriaAtom);
    const [convocatoriaSeleccionadaId,]=useAtom(convocatoriaSeleccionadaIdAtom);
    const [colaboradores, setColaboradores] = useState(undefined);
    const [,setLoading ]=useAtom(loadingAtom);

    const onChangePostulante=(value,Option)=>{
       
       const postulante={
         id:value,
         nombreCompleto:Option.labelnombre,
       }       
       setPostulanteSeleccionado(postulante);     
    }

    const onSearchColaboradores = async (value) => {
      if (value === "") {
        setColaboradores([]);
        return;
      }
      const respuesta = await PostulantesService.listarFiltrado({
        filtro: value.trim(),
      });
      console.log(respuesta)
      setColaboradores(respuesta.colaboradores);
    };

    // const onSelectColaborador = async (value, value2) => {
    
    //   if (value !== null) {
    //     const colaboradorEncontrado = colaboradores.find(c=> c.id === value2.key)
    //     formOnboardingColaborador.setFieldsValue({ colaborador: { id: value2.key,
    //                                             codigoTrabajador: colaboradorEncontrado.codigoTrabajador
    //                                                      } });
    //   }
    // };

    const onclickAgregar = async()=>{
      if(postulanteSeleccionado){        
        const postulanteExistente = postulantesConvocatoria.find(postulante=>postulante.id===postulanteSeleccionado.id);
        if(postulanteExistente){
          message.error("Ya existe un postulante con ese nombre");
        }
        else{
          setLoading(true)
         const postulante={
            convocatoriaId:convocatoriaSeleccionadaId,
            postulanteId:postulanteSeleccionado.id,
          }
           await ConvocatoriaService.insertarPostulante(postulante);
           setPostulantesConvocatoria([...postulantesConvocatoria,postulanteSeleccionado]);
           setLoading(false)
        }
      
        //console.log("Form",formulario);
        setPostulanteSeleccionado();
        setColaboradores([]);
        formRef.setFieldsValue({ postulante: undefined });
      }     
       
   }
  
      return(
        <React.Fragment>
        <div style={{paddingBottom:10,textAlign:"center"}}><Typography.Title level={5} type={"danger"}>Agregar Postulantes</Typography.Title></div>
        <Form.Item label="Postulante" name="postulante">   

        <Select
            style={{ width: "100%" }}
            allowClear
            showSearch
            placeholder="Ingrese el nombre del colaborador"
            onSearch={onSearchColaboradores}
            //onChange={onChangeSelectColaboradores}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onSelect={onChangePostulante}
          >
            {colaboradores !== undefined &&
              colaboradores?.map((colaborador) => (
                <Select.Option key={colaborador.id} value={colaborador.id} labelnombre={colaborador.nombreCompleto}>
                  {colaborador.nombreCompleto}
                </Select.Option>
              ))}
          </Select>

           {/* <Select
            showSearch
            onChange={onChangePostulante}
            optionFilterProp="labelnombre"
            //filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
           >
               {postulantes!==undefined && postulantes.map((postulante)=>{
                return(
                  <Select.Option key={postulante.id} value={postulante.id} labelnombre={postulante.apellidoPaterno+ " "+ postulante.apellidoMaterno+" "+postulante.nombres}>
                     {postulante.apellidoPaterno}{" "}{postulante.apellidoMaterno}{" "}{postulante.nombres}
                  </Select.Option>
                )
                    
               })}
           </Select> */}
        </Form.Item>
        <Button type="primary"  onClick={onclickAgregar} block>Agregar Postulante</Button>
        </React.Fragment>
      )
  }  
//   const TablaAgregarPostulantes=({formulario})=>{  
  
//   return(
//         <Form.Item name="agregar">
        
//           <TablaPostulantes/>
//       </Form.Item>
//     )
    
//  }  
const BotonEditarConvocatoria=()=>{
return(
  <Form.Item style={{textAlign:"center"}}>
    <Button type="primary" htmlType="submit">Guardar Convocatoria</Button>
  </Form.Item>
)
}
export default EditarConvocatoria;