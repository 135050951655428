import React, { useState, useEffect } from "react";
import {
	Button,
	Dropdown,
	Table,
	Tag,
	Input,
	Form,
	DatePicker,
	Spin,
	Popover,
	Space,
	Select,
	Row,
	Col,
	notification,
	Modal,
	message,
	Typography
} from "antd";
import {
	DownloadOutlined,
	UnorderedListOutlined,
	CaretDownOutlined,
	SearchOutlined,
	LoadingOutlined,
	CloseOutlined,
	ExclamationCircleOutlined,
	WarningOutlined
} from "@ant-design/icons";
import { saveAs } from "file-saver";
import { useAtom } from "jotai";
import { useListarContratosSeguimiento, useListarProyectos } from "../../hooks";
import {
	contratoSeleccionadoAtom,
	urlPdfContratoAtom,
	visibleModalAprobarContratoAtom,
	visibleModalRechazarContratoAtom,
	visibleModalVisorPdfAtom,
	loadingContratosSeguimientos,
	filtroAtom,
	visibleModalTransferirProyectoAtom,
	filtroEstadoAtom,
	filtroFechaFinAtom,
	filtroProyectoAtom,
	filtroRespRenovacionAtom,
	filtroColaboradorAtom,
	filtroFechaInicioAtom,
	filtroEstadoFirmaAtom,
	filtroEstadoColaboradorAtom,
	contratosSeguimientoAtom
} from "../../BandejaAprobacionAdministracionPersonalStore";
import { BandejaAprobacionAdministracionPersonalService } from "services/contratos";
import { BandejaSeguimientoAdministracionPersonalService } from "services/contratos/BandejaSeguimientoAdministracionPersonalService";
import ColaboradorService from "services/ColaboradorService";
import {TransferirContratoService } from "services/contratos";
//import { useHistory } from "react-router";

const ListaContratosSeguimiento = () => {
	const { Search } = Input;
	const { contratosSeguimiento, setContratosSeguimiento } = useListarContratosSeguimiento();
	const { proyectos, setProyectos } = useListarProyectos();
	const [loading, setLoading] = useState(false);
	const [loadingSeguimiento, setLoadingSeguimiento] = useAtom(loadingContratosSeguimientos);
	const [filtroSearch, setFiltroSearch] = useAtom(filtroAtom);
	const [colaboradores, setColaboradores] = useState(undefined);
	const [filtroProyecto, setFiltroProyecto] = useAtom(filtroProyectoAtom);
	const [filtroColaborador, setFiltroColaborador] = useAtom(filtroColaboradorAtom);
	const [filtroRespRenovacion, setFiltroRespRenovacion] = useAtom(filtroRespRenovacionAtom);
	const [filtroEstado, setFiltroEstado] = useAtom(filtroEstadoAtom);
	const [filtroEstadoFirma, setFiltroEstadoFirma] = useAtom(filtroEstadoFirmaAtom);
	const [filtroFechaInicio, setFiltroFechaInicio] = useAtom(filtroFechaInicioAtom);
	const [filtroFechaFin, setFiltroFechaFin] = useAtom(filtroFechaFinAtom);
	const [filtroEstadoColaborador, setFiltroEstadoColaborador] = useAtom(filtroEstadoColaboradorAtom);
	const [formFiltro] = Form.useForm();
	const [loadingExportar, setLoadingExportar] = useState(false);

	const columnas = [
		{
			title: "Tipo Contrato",
			dataIndex: "tipoContrato",
			align: "center",
			width: "10%",
			render: (tipoContrato) => <TagTipoContrato tipoContrato={tipoContrato} />,
		},
		// {
		// 	title: "Colaborador",
		// 	dataIndex: ["colaborador", "nombreCompleto"],
		// 	width: "25%",
		// },
		{
			title: "Colaborador",
			//dataIndex: ["colaborador", "nombreCompleto"],
			width: "25%",
			render: (fila) => (
				<div>
					<div style={{ display: "flex", alignItems: "center" }}>
						{fila.colaborador.nombreCompleto}
					</div>
					{fila.colaborador?.estadoRegistro == "CESADO" && (
						<div style={{ alignItems: "center" }}>
							<Tag color="red">CESADO</Tag>
						</div>
					)}
					{fila.colaborador?.estadoRegistro == "ACTIVO" && (
						<div style={{ alignItems: "center" }}>
							<Tag color="green">ACTIVO</Tag>
						</div>
					)}
					{fila.colaborador?.estadoRegistro == "ONBOARDING" && (
						<div style={{ alignItems: "center" }}>
							<Tag color="blue">ONBOARDING</Tag>
						</div>
					)}
				</div>
			),
		},
		{
			title: "Proyecto",
			dataIndex: ["colaborador", "proyecto"],
			width: "25%",
		},
		{
			title: "Cargo",
			dataIndex: ["colaborador", "cargo"],
			width: "25%",
		},
		{
			title: "Fecha Inicio",
			dataIndex: "fechaInicio",
			align: "center",
			width: "10%",
		},
		{
			title: "Fecha Fin",
			dataIndex: "fechaFin",
			align: "center",
			width: "10%",
		},
		{
			title: "Estado",
			align: "center",
			width: "15%",
			render: (fila) => <TagEstado contrato={fila} />,
		},
		{
			title: "Firmado por Colaborador",
			dataIndex: "estadoFirma",
			align: "center",
			width: "15%",
			render: (estado) => <TagEstadoFirma estado={estado} />,
		},
		{
			title: "Acciones",
			align: "center",
			width: "10%",
			render: (fila) => <MenuAcciones contrato={fila} />,
		},
	];

	function onChangePagination(page) {
		cargarContratos(page - 1);
	}

	useEffect(() => {
		cargarContratos();
	}, [
		filtroProyecto,
		filtroRespRenovacion,
		filtroEstado,
		filtroEstadoFirma,
		filtroFechaInicio,
		filtroFechaFin,
		filtroColaborador,
		filtroEstadoColaborador,
	]);

	const cargarContratos = async (paginaInput) => {
		setLoading(true);
		const respuesta = await BandejaSeguimientoAdministracionPersonalService.listarContratos({
			filtroColaborador: filtroColaborador,
			filtroProyecto: filtroProyecto,
			filtroRespRenovacion: filtroRespRenovacion,
			filtroEstado: filtroEstado,
			filtroEstadoFirma: filtroEstadoFirma,
			filtroFechaInicio: filtroFechaInicio,
			filtroFechaFin: filtroFechaFin,
			filtroEstadoColaborador:filtroEstadoColaborador,
			pagina: paginaInput != undefined ? paginaInput : 0,
			cantidad: 10,
		});

		setContratosSeguimiento({
			...contratosSeguimiento,
			contratos: respuesta.contratos,
			paginaActual: respuesta.paginaActual,
			totalContratos: respuesta.totalContratos,
		});
		setLoading(false);
	};

	const onSearchColaboradores = async (value) => {
		if (value === "") {
			setColaboradores([]);
			return;
		}
		const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
			filtro: value.trim(),
		});
		setColaboradores(respuesta.colaboradores);
	};

	const onClickFiltrar = async () => {
		setLoading(true);
		setFiltroProyecto(formFiltro.getFieldValue("proyecto"));
		setFiltroRespRenovacion(formFiltro.getFieldValue("responsableRenovacion"));
		setFiltroEstado(formFiltro.getFieldValue("estado"));
		setFiltroEstadoFirma(formFiltro.getFieldValue("estadoFirma"));
		setFiltroFechaFin(
			formFiltro.getFieldValue("meses") !== undefined && formFiltro.getFieldValue("meses") !== null
				? formFiltro.getFieldValue("meses").format("MM/YYYY")
				: ""
		);
		setFiltroFechaInicio(
			formFiltro.getFieldValue("fechaInicio") !== undefined && formFiltro.getFieldValue("fechaInicio") !== null
				? formFiltro.getFieldValue("fechaInicio").format("MM/YYYY")
				: ""
		);
		setFiltroColaborador(formFiltro.getFieldValue("colaborador"));
		setFiltroEstadoColaborador(formFiltro.getFieldValue("estadoColaborador"));
		cargarContratos(0);
		setLoading(false);
	};

	const handleOnclickExportarSeguimiento = async () => {
		try {
			setLoadingExportar(true);

			const respuesta = await BandejaSeguimientoAdministracionPersonalService.descargarReporteSeguimientoExcel({
				filtroColaborador: filtroColaborador,
				filtroProyecto: filtroProyecto,
				filtroRespRenovacion: filtroRespRenovacion,
				filtroEstado: filtroEstado,
				filtroEstadoFirma: filtroEstadoFirma,
				filtroFechaInicio: filtroFechaInicio,
				filtroFechaFin: filtroFechaFin,
				filtroEstadoColaborador: filtroEstadoColaborador,
			});
			setLoadingExportar(false);
			const filename = respuesta.headers.filename;
			saveAs(respuesta.data, filename);
		} catch (error) {
			setLoadingExportar(false);
			notification.error({
				message: "No hay Seguimiento para exportar",
				description: error.response.data.mensaje,
			});
		}
	};

	return (
		<Spin indicator={<LoadingOutlined style={{ fontSize: 28 }} />} spinning={loadingSeguimiento} tip="Cargando...">
			<div>
				<Form layout="vertical" form={formFiltro}>
					<Row gutter={16}>
						<Col span={8}>
							<Form.Item label="Colaborador" name="colaborador">
								<Select
									style={{ width: "100%" }}
									allowClear
									showSearch
									placeholder="Ingrese responsable renovación"
									onSearch={onSearchColaboradores}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									onClear={() => setFiltroColaborador(undefined)}
								>
									{colaboradores !== undefined &&
										colaboradores?.map((colaborador) => (
											<Select.Option
												key={colaborador.numeroDocumentoIdentidad}
												value={colaborador.numeroDocumentoIdentidad}
											>
												{colaborador.nombreCompleto}
											</Select.Option>
										))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Proyecto" name="proyecto">
								<Select
									style={{ width: "100%" }}
									placeholder="Ingrese el proyecto"
									allowClear
									showSearch
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									onClear={() => setFiltroProyecto(undefined)}
								>
									{proyectos !== undefined &&
										proyectos?.map((proyecto) => (
											<Select.Option key={proyecto.id} value={proyecto.nombre}>
												{proyecto.nombre}
											</Select.Option>
										))}
								</Select>
							</Form.Item>
						</Col>
						<Col span={8}>
							<Form.Item label="Resp. Renovación" name="responsableRenovacion">
								<Select
									style={{ width: "100%" }}
									allowClear
									showSearch
									placeholder="Ingrese responsable renovación"
									onSearch={onSearchColaboradores}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
									onClear={() => setFiltroRespRenovacion(undefined)}
								>
									{colaboradores !== undefined &&
										colaboradores?.map((colaborador) => (
											<Select.Option
												key={colaborador.numeroDocumentoIdentidad}
												value={colaborador.numeroDocumentoIdentidad}
											>
												{colaborador.nombreCompleto}
											</Select.Option>
										))}
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={6}>
							<Form.Item label="Fecha inicio de contrato" name="fechaInicio">
								<DatePicker
									style={{ width: "100%" }}
									picker="month"
									format={"MM/YYYY"}
									onChange={(date) => {
										if (date === null) {
											setFiltroFechaInicio(undefined);
										}
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={6}>
							<Form.Item label="Fecha fin de contrato" name="meses">
								<DatePicker
									style={{ width: "100%" }}
									picker="month"
									format={"MM/YYYY"}
									onChange={(date) => {
										if (date === null) {
											setFiltroFechaFin(undefined);
										}
									}}
								/>
							</Form.Item>
						</Col>
						<Col span={4}>
							<Form.Item label="Estado" name="estado">
								<Select
									allowClear
									placeholder="Ingrese el estado"
									onClear={() => setFiltroEstado(undefined)}
								>
									<Select.Option value={"PENDIENTE"}>PENDIENTE</Select.Option>
									<Select.Option value={"EMITIDO"}>EMITIDO</Select.Option>
									<Select.Option value={"NOTIFICADO"}>NOTIFICADO</Select.Option>
									<Select.Option value={"RENOVADO"}>RENOVADO</Select.Option>
									<Select.Option value={"RECHAZADO"}>RECHAZADO</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={4}>
							<Form.Item label="Estado Firma" name="estadoFirma">
								<Select
									allowClear
									placeholder="Seleccion el estado"
									onClear={() => setFiltroEstadoFirma(undefined)}
								>
									<Select.Option value={"PENDIENTE"}>PENDIENTE</Select.Option>
									<Select.Option value={"FIRMADO"}>FIRMADO</Select.Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={4}>
							<Form.Item label="Estado Colaborador" name="estadoColaborador">
								<Select
									allowClear
									placeholder="Seleccion el estado"
									onClear={() => setFiltroEstadoColaborador(undefined)}
								>
									<Select.Option value={"ACTIVO"}>ACTIVO</Select.Option>
									<Select.Option value={"CESADO"}>CESADO</Select.Option>
									<Select.Option value={"ONBOARDING"}>ONBOARDING</Select.Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={16}>
							<Form.Item label=" "></Form.Item>
						</Col>
						<Col span={4}>
							<Form.Item label=" ">
								<Button icon={<SearchOutlined />} type="primary" onClick={onClickFiltrar} block>
									Filtrar
								</Button>
							</Form.Item>
						</Col>
						<Col span={4}>
							<Form.Item label=" ">
								<Button
									icon={<DownloadOutlined />}
									//   type="primary"
									onClick={handleOnclickExportarSeguimiento}
									loading={loadingExportar}
									block
								>
									Exportar
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
				<br />
				<Table
					rowKey={(item) => item.id}
					columns={columnas}
					dataSource={contratosSeguimiento.contratos}
					pagination={{
						size: "small",
						defaultCurrent: 1,
						current: contratosSeguimiento?.paginaActual + 1,
						total: contratosSeguimiento.totalContratos,
						showTotal: (total) => `Total ${total} documentos`,
						pageSize: 10,
						onChange: (page) => onChangePagination(page),
					}}
				/>
			</div>
		</Spin>
	);
};
const TagTipoContrato = ({ tipoContrato }) => {
	if (tipoContrato === "INGRESO") {
		return (
			<Tag color="#87d068" style={{ borderRadius: "10px" }}>
				INGRESO
			</Tag>
		);
	} else if (tipoContrato === "RENOVACION") {
		return (
			<Tag color="#108ee9" style={{ borderRadius: "10px" }}>
				RENOVACIÓN
			</Tag>
		);
	}
	return tipoContrato;
};
const TagEstado = ({ contrato }) => {
	const tagStyle = {
		borderRadius: "15px",
	};

	if (contrato.estado === "PENDIENTE") {
		return (
			<Tag color="warning" style={{ borderRadius: "5px" }}>
				PENDIENTE
			</Tag>
		);
	} else if (contrato.estado === "EMITIDO") {
		return (
			<Tag color="gold" style={{ borderRadius: "5px" }}>
				EMITIDO
			</Tag>
		);
	} else if (contrato.estado === "NOTIFICADO") {
		return (
			<Tag color="blue" style={{ borderRadius: "5px" }}>
				NOTIFICADO
			</Tag>
		);
	} else if (contrato.estado === "RENOVADO") {
		return (
			<Tag color="success" style={{ borderRadius: "5px" }}>
				RENOVADO
			</Tag>
		);
	} else if (contrato.estado === "RECHAZADO") {
		const content = (
			<div style={{ width: 140 }}>
				<p>
					<strong>Motivo rechazo: </strong>
					{contrato.motivoRechazo}
				</p>
				{contrato?.descripcionRechazo && (
					<p>
						<strong>Descripción: </strong>
						{contrato.descripcionRechazo}
					</p>
				)}
			</div>
		);

		return (
			<div style={{ width: 140 }}>
				<Popover
					title={null}
					content={content}
					trigger="click"
					placement="bottomRight"
					// onOpenChange={onOpenChange}
				>
					<Space>
						<Tag color="red" className="milegajo-ant-tag" style={{ cursor: "pointer", ...tagStyle }}>
							RECHAZADO
						</Tag>
					</Space>
				</Popover>
			</div>
		);
	}
	return contrato.estado;
};

const TagEstadoFirma = ({ estado }) => {
	if (estado === "PENDIENTE") {
		return (
			<Tag color="gold" style={{ borderRadius: "5px" }}>
				PENDIENTE
			</Tag>
		);
	} else if (estado === "FIRMADO") {
		return (
			<Tag color="cyan" style={{ borderRadius: "5px" }}>
				FIRMADO
			</Tag>
		);
	}
	return estado;
};

const MenuAcciones = ({ contrato }) => {
	const [, setVisibleModalAprobar] = useAtom(visibleModalAprobarContratoAtom);
	const [, setVisibleModalRechazar] = useAtom(visibleModalRechazarContratoAtom);
	const [, setVisibleModalVisorPdf] = useAtom(visibleModalVisorPdfAtom);
	const [, setContratoSeleccionado] = useAtom(contratoSeleccionadoAtom);
	const [, setUrlPdfContrato] = useAtom(urlPdfContratoAtom);
	const [, setVisibleModal] = useAtom(visibleModalTransferirProyectoAtom);
	const [contratosSeguimiento, setContratosSeguimiento] = useAtom(contratosSeguimientoAtom);
	//const history = useHistory();
	const [filtroProyecto, setFiltroProyecto] = useAtom(filtroProyectoAtom);
	const [filtroColaborador, setFiltroColaborador] = useAtom(filtroColaboradorAtom);
	const [filtroRespRenovacion, setFiltroRespRenovacion] = useAtom(filtroRespRenovacionAtom);
	const [filtroEstado, setFiltroEstado] = useAtom(filtroEstadoAtom);
	const [filtroEstadoFirma, setFiltroEstadoFirma] = useAtom(filtroEstadoFirmaAtom);
	const [filtroFechaInicio, setFiltroFechaInicio] = useAtom(filtroFechaInicioAtom);
	const [filtroFechaFin, setFiltroFechaFin] = useAtom(filtroFechaFinAtom);
	const [filtroEstadoColaborador, setFiltroEstadoColaborador] = useAtom(filtroEstadoColaboradorAtom);

	const cargarContratos = async (paginaInput) => {
		//setLoading(true);
		const respuesta = await BandejaSeguimientoAdministracionPersonalService.listarContratos({
			filtroColaborador: filtroColaborador,
			filtroProyecto: filtroProyecto,
			filtroRespRenovacion: filtroRespRenovacion,
			filtroEstado: filtroEstado,
			filtroEstadoFirma: filtroEstadoFirma,
			filtroFechaInicio: filtroFechaInicio,
			filtroFechaFin: filtroFechaFin,
			filtroEstadoColaborador:filtroEstadoColaborador,
			pagina: paginaInput != undefined ? paginaInput : 0,
			cantidad: 10,
		});

		setContratosSeguimiento({
			...contratosSeguimiento,
			contratos: respuesta.contratos,
			paginaActual: respuesta.paginaActual,
			totalContratos: respuesta.totalContratos,
		});
		//setLoading(false);
	};

	let items = [];
	if (contrato.tipoRegistro != null && contrato.tipoRegistro != "MIGRACION") {
		items = [
			{
				label: "Visualizar Contrato",
				key: "visualizar-contrato",
				icon: <SearchOutlined style={{ fontSize: "16px" }} />,
			},
		];
	} else if (contrato.estadoFirma == "FIRMADO" || contrato.estado == "RECHAZADO") {
		if(contrato?.tipoContrato == "TRANSFERENCIA"){
			items = [
				{
					label: "Transferir a otro proyecto",
					key: "transferir",
					icon: <SearchOutlined style={{ fontSize: "16px" }} />,
				},
				{
					label: "Anular transferencia",
					key: "anular-transferencia",
					icon: <CloseOutlined style={{ fontSize: "16px" }} />,
				},
			];
		}else{
			items = [
				{
					label: "Transferir a otro proyecto",
					key: "transferir",
					icon: <SearchOutlined style={{ fontSize: "16px" }} />,
				},
			];
		}
	}else if (contrato?.tipoContrato == "TRANSFERENCIA" && contrato.estadoFirma == "PENDIENTE") {
		items = [
			{
				label: "Anular transferencia",
				key: "anular-transferencia",
				icon: <CloseOutlined style={{ fontSize: "16px" }} />,
			},
		];
	}

	const onClick = ({ key }) => {
		if (key === "aprobar-contrato") {
			setVisibleModalAprobar(true);
			setContratoSeleccionado(contrato);
		} else if (key === "rechazar-contrato") {
			setVisibleModalRechazar(true);
			setContratoSeleccionado(contrato);
		} else if (key === "visualizar-contrato") {
			//console.log("visualizar contrato!!!");
			cargarUrlPdfContrato();
			setVisibleModalVisorPdf(true);
		} else if (key === "transferir") {
			onClickTransferirPRoyecto();
		}else if (key === "anular-transferencia") {
			console.log("anular");
			onClickEliminarTransferencia();
		}
	};

	const cargarUrlPdfContrato = async () => {
		const url = await BandejaAprobacionAdministracionPersonalService.getUrlVistaPrevia(contrato.id);
		setUrlPdfContrato(url);
	};

	const onClickEliminarTransferencia = () => {
		  Modal.confirm({
			title: "¿Está seguro de anular la transferencia?",
			content: contrato?.tipoContrato == "TRANSFERENCIA" && contrato.estadoFirma == "FIRMADO" && (
				<div style={{ display: "flex", alignItems: "flex-start" }}>
					<WarningOutlined style={{ fontSize: "20px", color: "#dc3545" }} />
					<Typography.Text style={{ marginLeft: "8px", wordWrap: "break-word" }}>
						Se eliminará la transferencia firmada por el colaborador.
					</Typography.Text>
				</div>
			),
			icon: <ExclamationCircleOutlined />,
			okText: "Si",
			cancelText: "No",
			onOk() {
				onConfirmEliminar();
			},
		});
	};

	const onConfirmEliminar = async () =>{
		console.log("Eliminando", contrato);
		await TransferirContratoService.anular({
			seguimientoContratoId: contrato.id,
			numeroDocumentoIdentidad: contrato.colaborador?.numeroDocumentoIdentidad
		});

		// const _contratosSeguimiento = [...contratosSeguimiento];
		// const _contratos = _contratosSeguimiento.contratos;
		// setContratosSeguimiento({
		// 	...contratosSeguimiento,
		// 	contratos: respuesta.contratos,
		// 	paginaActual: respuesta.paginaActual,
		// 	totalContratos: respuesta.totalContratos,
		// });
		message.success("Se Elimino la transferencia correctamente");
		cargarContratos();
		//history.push(`/explomin/contrato/bandeja/aprobacion/administracion-personal`);
	  }
	

	const onClickTransferirPRoyecto = () => {
		setVisibleModal(true);
		//setDocumentoSeleccionado(contrato);
		setContratoSeleccionado(contrato);
	};

	return (
		<div>
			<Dropdown menu={{ items, onClick }} trigger="click" placement="bottomRight" arrow>
				<Button type="link" className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
					<UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
					<CaretDownOutlined
						style={{
							paddingLeft: "2px",
							fontSize: "12px",
							fontWeight: "bold",
							color: "#1678c1",
							marginLeft: "0px",
						}}
					/>
				</Button>
			</Dropdown>
		</div>
	);
};
export default ListaContratosSeguimiento;
