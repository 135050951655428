import { ApiService } from "./ApiService";
import { ApiUrl } from "./ApiUrl";

const CargaLegajoService = {
	async listar() {
		const respuesta = await ApiService(ApiUrl.CARGA_LEGAJO).get("/listar");
		return respuesta.data;
	},
	async insertar(cargalegajo) {
        const respuesta = await ApiService(ApiUrl.CARGA_LEGAJO).post("/cargar/v2", cargalegajo);
        return respuesta.data;
    },
};
export default CargaLegajoService;