import React, {useContext} from "react";

import { Button } from "antd";
import { useHistory } from "react-router";

import { useAtom } from "jotai";

import { paginaSeguimientoAtom } from "store/local/seguimiento/SeguimientoStore";



const BotonDocumento = ({ documento, index }) => {
    const [paginaSeguimiento, ]= useAtom(paginaSeguimientoAtom)

    let history = useHistory();
    
    const onClickMenuVerDetalle = () => {

            return (
                history.push(`/notificacion-por-completar/${documento.id}`)
            );
    
}

        return(

            <Button block type="link" onClick={onClickMenuVerDetalle}>{(paginaSeguimiento?paginaSeguimiento:0)*5+index+1} </Button>)

}

export default BotonDocumento;