import create from "zustand";
import { saveAs } from "file-saver";
import { message } from "antd";
import { OnboardingColaboradorService } from "services/OnboardingColaboradorService";

const useModalVisorPdf = create((set, get) => ({
  loading: false,
  visible: false,
  visibleBtnImprimir: false,
  visibleBtnDescargar: false,
  titulo: undefined,
  urlPdf: undefined,
  blobPdf: undefined,
  categoriaDocumento: undefined,
  detalleId: undefined,
  motivoRechazo: undefined,
  botonRechazo: false,
  finProceso: false,
  mostrarModal: (
    urlPdf,
    titulo,
    visibleBtnImprimir = false,
    visibleBtnDescargar = false,
    categoriaDocumento,
    detalleId,
    motivoRechazo
  ) => {
    set({ loading: true, visible: true });
    set({ urlPdf, titulo, visibleBtnImprimir, visibleBtnDescargar, categoriaDocumento, detalleId, motivoRechazo });
  },
  cerrarModal: () => {
    set({ visible: false });
  },
  imprimirPdf: () => {
    window.open(URL.createObjectURL(get().blobPdf)).print();
  },
  descargarPdf: () => {
    saveAs(get().blobPdf, get().titulo);
  },
  setLoading: (estado) => {
    set({ loading: estado });
  },
  setBlobPdf: (blob) => {
    set({ blobPdf: blob });
  },
  // rechazarOnboarding: () => {
  //     if( get().botonRechazo)
  //         set({botonRechazo:false});
  //     else
  //         set({botonRechazo:true});
  // },
  aprobarOnboarding: () => {
    const aprobar = async () => {
      const _ = await OnboardingColaboradorService.aprobar(get().detalleId);
      set({ finProceso: true });
      set({ visible: false });
      message.success("El documento ha sido aprobado.");
    };
    aprobar();
  },

  finalizarOnboarding: () => {
    set({ visible: false });
    set({ finProceso: false });
  },
}));

export default useModalVisorPdf;
