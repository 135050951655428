
import React, { useState, useEffect, useContext, Suspense } from "react"
import { Container } from "components/Container";
import {TablaFormularios} from "./lista-formularios-notificados";
//import * as atoms from "./DetalleSeguimientoFormularioStore";
import * as atoms from "store/local/formularios/DetalleSeguimientoFormularioStore";
import { FormularioService}  from "../../../../services/FormularioService";
import { LoadingContainer } from "components/Loading";
import { ListaFormulariosNotificados } from "./lista-formularios-notificados";
import { useParams } from "react-router-dom";
import { atom, useAtom, Provider } from "jotai";
import { EstadoProcesoEnvio } from "enums/EstadoProcesoEnvio";

const DetalleSeguimientoFormulariosPage = () => {
    const { envioId } = useParams();
    const [loading, setLoading] = useState(false);
    const [envio, setEnvio] = useAtom(atoms.envio);

    useEffect(() => {
        const fetch = async () => {
            try {
                setLoading(true);
                const envio = await FormularioService.buscarEnvioPorId(envioId);
                setEnvio(envio);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
        fetch();
    }, []);
    const DetalleFormulario = () => {
        // if (envio?.estado === EstadoProcesoEnvio.REGISTRADO) {
        //     return <FormularioCargarTramaExcel />;
        // } else if (envio?.estado === EstadoProcesoEnvio.PENDIENTE_NOTIFICAR) {
        //     return <ListaContratosSinNotificar />;
       // } else 
        if (envio?.estado === EstadoProcesoEnvio.EN_CURSO || envio?.estado === EstadoProcesoEnvio.COMPLETADO) {
            return <ListaFormulariosNotificados />
        } else {
            return null;
        }
    }

    return (
        <React.Fragment>
            {loading && <LoadingContainer />}
            {!loading && <DetalleFormulario />}
        </React.Fragment>
        
    );
}

export default DetalleSeguimientoFormulariosPage;