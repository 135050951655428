import { BaseService } from "./BaseService";

export const EmpresaService = {
  async listarPorOrganizacionId(organizacionId) {
    const respuesta = await BaseService.get("/empresa", {
      params: {
        organizacionId: organizacionId,
      },
    });
    return respuesta.data;
  },
};
