import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { CardInformacionPersonalColaborador } from "./componentes/CardInformacionPersonalColaborador";
import { CardDocumentosColaborador } from "./componentes/CardDocumentosColaborador";
import { ModalActivarColaborador, ModalExito, ModalVisorPdf } from "./componentes";
import ModalAnularDocumento from "./componentes/CardDocumentosColaborador/TabOnboarding/ModalAnularDocumento";
import ModalCargarDocumento from "./componentes/CardDocumentosColaborador/TabOnboarding/ModalCargarDocumento";
const LegajoColaboradorPage = () => {
    return (
        <JotaiProvider>
            <div className="contenedor-legajo">
                <CardInformacionPersonalColaborador />
                <CardDocumentosColaborador />
                <ModalActivarColaborador />
                <ModalAnularDocumento />
                <ModalExito />
                <ModalCargarDocumento />
                <ModalVisorPdf />
            </div>
        </JotaiProvider>
    );
};
export default LegajoColaboradorPage;
