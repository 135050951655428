import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { Container } from "components/Container";
import { BotonesExtra } from "./componentes";
import { TablaListarPerfilSolicitudPersonal } from "./componentes/TablaListarPerfilSolicitudPersonal";
import { UnorderedListOutlined } from "@ant-design/icons";
const ListarPerfilSolicitudPersonalPage = () => {
  return (
    <JotaiProvider>
      <Container titulo="Perfil" icono={<UnorderedListOutlined />} botonExtra={<BotonesExtra />}>
        <TablaListarPerfilSolicitudPersonal />
      </Container>
    </JotaiProvider>
  );
};
export default ListarPerfilSolicitudPersonalPage;
