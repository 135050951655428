import React from "react";
import { Dropdown, Menu } from "antd";
import {
  SelectOutlined, 
  CaretDownOutlined,
  UnorderedListOutlined,  
} from "@ant-design/icons";
import{ visibleModalLinkAtom,enlacePostulanteAtom} from "../../ProcesosListarStore";
import { useHistory } from "react-router-dom";
import { useAtom } from "jotai";
import { ProcesoService } from "services/reclutamiento-y-seleccion/ProcesoService";
const MenuAcciones = ({proceso}) =>{

   const[,setVisible]=useAtom(visibleModalLinkAtom);
   const[enlacePostulante,setEnlacePostulante]=useAtom(enlacePostulanteAtom);
   let history=useHistory();

    const onClickMenu=(opcion)=>{
        switch(opcion.key){
            case "ver-detalle":
             onClickVerDetalle();
            break;
            case "obtener-link":
               onClickObtenerLink();
            break;    
        default:
        console.error("No se encuentra esa opción");
        }
    }

    const onClickVerDetalle=()=>{
         history.push(`/reclutamiento-y-seleccion/procesos/${proceso.id}/documentos`);
    }
    const onClickObtenerLink = async()=>{
        
        const respuesta= await ProcesoService.obtenerURlconvocatoria(proceso.postulante.numeroDocumentoIdentidad);
        setEnlacePostulante(respuesta.enlace);
        console.log("link",respuesta.enlace);
        setVisible(true);
    }

    const menu=()=>{
        return(
            <Menu onClick={onClickMenu}>
                <Menu.Item key="ver-detalle">
                  <SelectOutlined /> Ver Detalle
                </Menu.Item>
                <Menu.Item key="obtener-link">
                  <SelectOutlined /> Obtener link de convocatoria
                </Menu.Item>
            </Menu>
            
        )
    }

    return(
        <React.Fragment>
            <Dropdown overlay={menu} trigger={["click"]} placement="bottomCenter">
            <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
            <CaretDownOutlined style={{ paddingLeft: "2px", fontSize: "12px", fontWeight: "bold", color: "#1678c1" }} />
           </a>
        </Dropdown>
        </React.Fragment>
        
    )

}
export default MenuAcciones;