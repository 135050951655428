import React, { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import { Upload, message, notification } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { LoginService } from "services/LoginService";
import { Headers } from "enums";

const { Dragger } = Upload;

export function VentanaCargaColaboradorExcel({ visible, setColaboradores }) {
  const { getUsuarioAutenticado } = useContext(SecurityContext);

  const props = {
    name: "file",
    multiple: true,
    action:
      process.env.REACT_APP_ENDPOINT +
      "comunicado/colaborador/excel/convertir/json",
    headers: {
      Authorization: "Bearer " + LoginService.getJwtToken(),
      [Headers.ORGANIZACION_ID]:
        LoginService.getUsuarioAutenticado().organizacionId,
      [Headers.EMPRESA_ID]:
        LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
    },
    data: {
      empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        // console.log("info", info);
        setColaboradores(info.file.response);
        // message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        // message.error(`El archivo ${info.file.name} no se ha podido cargar.`);
        notification.error({
          message: "Error al cargar el archivo",
          description: info.file.response.mensaje,
        });
      }
    },
  };

  if (!visible) {
    return "";
  }

  return (
    <Dragger {...props}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Clic aquí o arrastra el archivo excel en esta área.
      </p>
    </Dragger>
  );
}
