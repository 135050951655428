import React, { useState } from "react";
import { Provider as JotaiProvider, useAtom } from "jotai";
import { Table, Tag, Card, Dropdown, Button, message, Input, Row, Col } from "antd";
import { useListarContratosPendientesAprobar } from "./hooks";
import {
    UnorderedListOutlined,
    CaretDownOutlined,
    MailOutlined,
    SearchOutlined,
    DislikeOutlined,
    FormOutlined
} from "@ant-design/icons";
import { BandejaAprobacionReclutamientoSeleccionService } from "services/contratos";
import { LoginService } from "services/LoginService";
import {
    contratoSeleccionadoAtom,
    contratosAtom,
    urlPdfContratoAtom,
    visibleModalRechazarContratoAtom,
    visibleModalVisorPdfAtom,
    visibleModalModificarContratoAtom
} from "./BandejaAprobacionReclutamientoYSeleccionStore";
import produce from "immer";
import { ModalVisorPdf as VisorPdf } from "components/common";
import { ModalModificar, ModalRechazar } from "./componentes";

const BandejaAprobacionReclutamientoYSeleccionPage = () => {
    return (
        <JotaiProvider>
            <Card title="Bandeja Aprobación Reclutamiento y Selección">
                <ListaSeguimientoContratosAprobados />
                <ModalVisorPdf />
                <ModalRechazar />
                <ModalModificar />
            </Card>
        </JotaiProvider>
    );
};
const ListaSeguimientoContratosAprobados = () => {
    const { Search } = Input;
    const { contratos, setContratos } = useListarContratosPendientesAprobar();
    const [loading, setLoading] = useState(false);

    const columnas = [
        {
            title: "Tipo Contrato",
            dataIndex: "tipoContrato",
            align: "center",
            width: "10%",
            render: (tipoContrato) => <TagTipoContrato tipoContrato={tipoContrato} />,
        },
        {
            title: "Colaborador",
            dataIndex: ["colaborador", "nombreCompleto"],
            width: "25%",
        },
        {
            title: "Cargo",
            dataIndex: ["colaborador", "cargo"],
            width: "25%",
        },
        {
            title: "Fecha Inicio",
            dataIndex: "fechaInicio",
            align: "center",
            width: "10%",
        },
        {
            title: "Fecha Fin",
            dataIndex: "fechaFin",
            align: "center",
            width: "10%",
        },
        {
            title: "Acciones",
            align: "center",
            width: "10%",
            render: (fila) => <MenuAcciones contrato={fila} />,
        },
    ];
    const onSearch = async (valor) => {
        setLoading(true);
        const contratosFiltro = await BandejaAprobacionReclutamientoSeleccionService.listarContratosPendientesAprobarAndFiltro({
            filtro:valor.trim()
        });
        setContratos(contratosFiltro);
        setLoading(false);
    };
    return (
        <div>
        <Row>
            <Col span={12}>
            <Search
                placeholder="Buscar por Nombre ó Documento de identidad"
                allowClear
                loading={loading}
                onSearch={onSearch}
                enterButton
            />
            </Col>
        </Row>
        
        <br />
        <Table rowKey={(item) => item.id} dataSource={contratos} columns={columnas} pagination={false} />
    </div>
    );
};

const TagTipoContrato = ({ tipoContrato }) => {
    if (tipoContrato === "INGRESO") {
        return (
            <Tag color="#87d068" style={{ borderRadius: "10px" }}>
                INGRESO
            </Tag>
        );
    } else if (tipoContrato === "RENOVACION") {
        return (
            <Tag color="#108ee9" style={{ borderRadius: "10px" }}>
                RENOVACIÓN
            </Tag>
        );
    }
    return tipoContrato;
};

const MenuAcciones = ({ contrato }) => {
    const [, setContratos] = useAtom(contratosAtom);
    const [, setContratoSeleccionado] = useAtom(contratoSeleccionadoAtom);
    const [, setVisibleVisorPdf] = useAtom(visibleModalVisorPdfAtom);
    const [, setVisibleModalRechazar] = useAtom(visibleModalRechazarContratoAtom);
    const [, setVisibleModalModificar] = useAtom(visibleModalModificarContratoAtom);
    const [, setUrlPdfContrato] = useAtom(urlPdfContratoAtom);

    const items = [
        {
            label: "Visualizar Contrato",
            key: "visualizar-contrato",
            icon: <SearchOutlined style={{ fontSize: "16px" }} />,
        },
        {
            label: "Notificar Contrato",
            key: "notificar-contrato",
            icon: <MailOutlined style={{ fontSize: "16px" }} />,
        },
        {
            label: "Rechazar Contrato",
            key: "rechazar-contrato",
            icon: <DislikeOutlined style={{ fontSize: "16px" }} />,
        },
        // {
        //     label: "Modificar Contrato",
        //     key: "modificar-contrato",
        //     icon: <FormOutlined  style={{ fontSize: "16px" }} />,
        // },
    ];

    const onClick = ({ key }) => {
        if (key === "notificar-contrato") {
            notificarContrato();
        } else if (key === "visualizar-contrato") {
            cargarUrlPdfContrato();
            setVisibleVisorPdf(true);
        } else if (key === "rechazar-contrato") {
            setContratoSeleccionado(contrato);
            setVisibleModalRechazar(true);
        } else if (key === "modificar-contrato") {
            setContratoSeleccionado(contrato);
            setVisibleModalModificar(true);
        }
    };

    const cargarUrlPdfContrato = async () => {
        const url = await BandejaAprobacionReclutamientoSeleccionService.getUrlVistaPrevia(contrato.id);
        setUrlPdfContrato(url);
    };

    const notificarContrato = async () => {
        try {
            await BandejaAprobacionReclutamientoSeleccionService.aprobarContrato({
                seguimientoContratoId: contrato.id,
                usuarioId: LoginService.getUsuarioAutenticado().id,
            });
            setContratos(
                produce((draft) => {
                    const index = draft.findIndex((item) => item.id === contrato.id);
                    draft.splice(index, 1);
                })
            );
            message.success("Se realizó la notificación de contrato exitosamente.");
        } catch (error) {
            message.error("No se pudo notificar el contrato, intente nuevamente porfavor.");
        }
    };

    return (
        <Dropdown menu={{ items, onClick }} trigger="click" placement="bottomRight" arrow>
            <Button type="link" className="ant-dropdown-link">
                <UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
                <CaretDownOutlined
                    style={{
                        paddingLeft: "2px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#1678c1",
                        marginLeft: "0px",
                    }}
                />
            </Button>
        </Dropdown>
    );
};

const ModalVisorPdf = () => {
    const [visible, setVisible] = useAtom(visibleModalVisorPdfAtom);
    const [urlPdfContrato] = useAtom(urlPdfContratoAtom);

    return <VisorPdf urlPdf={urlPdfContrato} visible={visible} onCerrar={() => setVisible(false)} />;
};

export default BandejaAprobacionReclutamientoYSeleccionPage;
