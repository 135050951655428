import { atom } from "jotai";

export const solicitudPersonalIdAtom =atom(undefined);
export const convocatoriasAtom = atom([]);
export const solicitudAtom = atom(undefined)
export const loadingPageAtom = atom(false)

export const modalRechazarAtom = atom(false)
export const modalCrearAtom = atom(false)
export const isConvocatoriaAtom = atom(false)
export const convocatoriasActivasAtom = atom([]);