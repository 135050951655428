import React, { useEffect, useState } from "react";
import { Tag, Table, Typography, Space } from "antd";
import { useAtom } from "jotai";
import { documentosAtom, loadingAtom } from "pages/consulta-documento/ConsultaDocumentoStore";
import { EstadoDocumento } from "enums/EstadoDocumento";
import { EtiquetaEstadoNotificacion } from "../etiqueta-estado-notificacion";
import { EtiquetaEstadoVisualizacion } from "../etiqueta-estado-visualizacion";
import { EtiquetaEstadoFirmaColaborador } from "../etiqueta-estado-firma-colaborador";
import { MenuAcciones } from "../MenuAcciones";
import { MinusCircleOutlined, FileUnknownOutlined, SyncOutlined, LoadingOutlined } from "@ant-design/icons";
const { Text } = Typography;

const TablaDocumentos = ({ onChangePagination }) => {
  const [documentos] = useAtom(documentosAtom);
  const [loading] = useAtom(loadingAtom);

  const columnas = [
    {
      title: "Documento Identidad",
      dataIndex: ["colaborador", "numeroDocumentoIdentidad"],
    },
    {
      title: "Colaborador",
      dataIndex: ["colaborador", "nombreCompleto"],
    },
    {
      title: "Correo",
      dataIndex: ["colaborador", "correoElectronico"],
    },
    {
      title: "Tipo Documento",
      dataIndex: ["envio", "tipoDocumentoNombre"],
      render: (text, fila) =>
        //fila.envio?.tipoDocumentoNombre ? fila?.envio?.tipoDocumentoNombre : fila?.categoriaDocumento?.nombre,
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {fila.envio?.tipoDocumentoNombre ? fila?.envio?.tipoDocumentoNombre : fila?.categoriaDocumento?.nombre}
          </div>
          {fila.interno && (
            <div style={{ alignItems: "center" }}>
              <Tag color="blue">Documento Interno</Tag>
            </div>
          )}
          {fila.estadoDocumento === EstadoDocumento.CARGADO
            && (
              <div style={{ alignItems: "center" }}>
                <Tag color="cyan" style={{ borderRadius: "8px" }}>Carga Manual</Tag>
              </div>
            )}
        </div>
    },
    {
      title: "Periodo",
      dataIndex: "periodo",
    },
    {
      title: "Notificación",
      dataIndex: "estadoNotificacion",
      render: (text, fila) => {
        // return <EtiquetaEstadoNotificacion documento={fila} />
        if (
          (fila.eliminado === false || fila.eliminado === null) &&
          (fila.estadoDocumento === EstadoDocumento.GENERADO
            || fila.estadoDocumento === EstadoDocumento.CARGADO
            || fila.estadoDocumento === null)
        ) {
          return <EtiquetaEstadoNotificacion documento={fila} />;
        }

        if (fila.eliminado === true) {
          return {
            children: (
              <span>
                <Tag color="default" icon={<MinusCircleOutlined />}>
                  INHABILITADO
                </Tag>
              </span>
            ),
            props: {
              colSpan: 3,
            },
          };
        }

        if (fila.estadoProcesoId && fila.estadoProcesoId === 6) {
          //PENDIENTE DE APROBAR
          return {
            children: (
              <span style={{ color: "#7d7939" }}>
                <FileUnknownOutlined /> Esperando aprobación ...
              </span>
            ),
            props: {
              colSpan: 3,
            },
          };
        }

        return {
          children: (
            <span style={{ color: "#7d7939" }}>
              <SyncOutlined spin style={{ color: "#7d7939" }} /> Procesando...
            </span>
          ),
          props: {
            colSpan: 3,
          },
        };
      },
    },
    {
      title: "Visualizado",
      dataIndex: "estadoVisualizacion",
      render: (text, fila) => {
        // return <EtiquetaEstadoVisualizacion documento={fila} />
        if (
          (fila.eliminado === false || fila.eliminado === null) &&
          (fila.estadoDocumento === EstadoDocumento.GENERADO
            || fila.estadoDocumento === EstadoDocumento.CARGADO
            || fila.estadoDocumento === null)
        ) {
          return <EtiquetaEstadoVisualizacion documento={fila} />;
        }

        return {
          props: {
            colSpan: 0,
          },
        };
      },
    },
    {
      title: "Firmado",
      dataIndex: "estadoFirmaColaborador",
      render: (text, fila) => {
        // return <EtiquetaEstadoFirmaColaborador documento={fila} />
        if (
          (fila.eliminado === false || fila.eliminado === null) &&
          (fila.estadoDocumento === EstadoDocumento.GENERADO
            || fila.estadoDocumento === EstadoDocumento.CARGADO
            || fila.estadoDocumento === null)
        ) {
          return <EtiquetaEstadoFirmaColaborador documento={fila} />;
        }

        return {
          props: {
            colSpan: 0,
          },
        };
      },
    },
    {
      title: "Acciones",
      dataIndex: "menuAcciones",
      render: (text, fila) => <MenuAcciones envio={fila} />,
    },
  ];

  const expanded = {
    expandedRowRender: (record, i) => {
      return (
        <React.Fragment>
          {record?.colaborador != null && (
            <React.Fragment>
              <Space size={100}>
                <div style={{ marginLeft: "50px" }}>
                  <Text strong>{"Cargo: "}</Text>
                  {record?.colaborador.cargo != null ? (
                    <Tag color="blue" key={record.colaborador.cargo.id}>
                      {record.colaborador.cargo.nombre}
                    </Tag>
                  ) : (
                    <Tag color="default" key={record.colaborador.id}>
                      {"No Asignado"}
                    </Tag>
                  )}
                </div>
                <div>
                  <Text strong>{"Centro de Costo: "}</Text>
                  {record?.colaborador.sede != null ? (
                    <Tag color="blue" key={record.colaborador.sede.id}>
                      {record.colaborador.sede.nombre}
                    </Tag>
                  ) : (
                    <Tag color="default" key={record.colaborador.id}>
                      {"No Asignado"}
                    </Tag>
                  )}
                </div>
              </Space>
            </React.Fragment>
          )}
        </React.Fragment>
      );
    },
  };

  return (
    <Table
      loading={{
        spinning: loading,
        indicator: <LoadingOutlined style={{ fontSize: 28 }} />,
        tip: "Cargando...",
      }}
      rowKey={(fila) => fila.id}
      dataSource={documentos.data}
      columns={columnas}
      expandable={{ ...expanded }}
      pagination={{
        size: "small",
        defaultCurrent: 1,
        current: documentos?.paginaActual,
        total: documentos.totalDocumentos,
        showTotal: (total) => `Total ${total} documentos`,
        pageSize: 30,
        onChange: (page) => onChangePagination(page),
      }}
    />
  );
};

export default TablaDocumentos;
