import React, { useContext } from "react";

import { Menu, Dropdown, Avatar, Space } from "antd";

import { UserOutlined, LogoutOutlined, SettingOutlined } from "@ant-design/icons";

import { SecurityContext } from "../../../../context/SecurityContextProvider";
import { useHistory } from "react-router-dom";

export function MenuPerfil() {
  const { getUsuarioAutenticado, cerrarSesion } = useContext(SecurityContext);

  let history = useHistory();

  const menu = (
    <Menu className="antd-pro-components-global-header-index-menu" selectedKeys={[]} onClick={onMenuClick}>

      <div>{getUsuarioAutenticado().nombreCompleto}</div>
      <Menu.Divider />

      <Menu.Item key="clave" className="texto-menu-perfil">
        <SettingOutlined />
        Cambiar Clave
      </Menu.Item>

      <Menu.Divider />
      <Menu.Item key="logout" className="texto-menu-perfil">
        <LogoutOutlined />
        Cerrar Sesión
      </Menu.Item>
    </Menu>
  );

  function onMenuClick(event) {
    const { key } = event;

    if (key === "logout") {
      return cerrarSesion();
    } else if (key === "clave") {
      return cambiarClave();
    }
  }
  function cambiarClave() {
    history.push("/cuenta/cambiar-clave");
  }

  return (
    <Dropdown overlay={menu}>
      <Space>
        <Avatar style={{ backgroundColor: "#1678c1", color: "#fff" }} icon={<UserOutlined />} size="small" />
        {/* <span className="texto-menu-perfil">
          {getUsuarioAutenticado().nombreCompleto}
        </span> */}
      </Space>
    </Dropdown>
  );
}
