import React from "react";
import { Container } from "components/Container"
import { TablaVerificarDatosColaborador } from "./tabla-verificar-datos-colaborador"
import { Botones } from "./botones";
import { tabNumberAtom,loadingAtom } from "./ListarVerificarDatosColaboradorStore";
import { useAtom } from "jotai";
const ListarVerificarDatosColaboradorPage=()=>{
  const [tabNumber, ] = useAtom(tabNumberAtom);
  const [loading,setLoading] = useAtom(loadingAtom);
    return(

        <Container titulo="Verificación Datos Colaborador" botonExtra={<Botones setLoading={setLoading} tabNumber={tabNumber} />}>
          <TablaVerificarDatosColaborador loading={loading}/>
        </Container>
    )


}
export default ListarVerificarDatosColaboradorPage;