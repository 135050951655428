import { useEffect } from "react";
import { useAtom } from "jotai";
import { documentoProcesoAtom, loadingAtom} from "../AgregarDocumentoStore";
import { useParams } from "react-router-dom";
import { ProcesoService } from "services/reclutamiento-y-seleccion/ProcesoService";
const useCargarDocumentoProceso = ()=>{
    const[documentoProceso,setDocumentoProceso]=useAtom(documentoProcesoAtom);
    const[,setLoading]=useAtom(loadingAtom);
    const { procesoId } = useParams();
     useEffect(()=>{
        const cargarDatos = async () =>{
            setLoading(true)
            const respuesta= await ProcesoService.obtenerProceso(procesoId);
            setDocumentoProceso(respuesta);
            //console.log(respuesta)
        }
        cargarDatos();
     },[]);
     return{documentoProceso}
}
export default useCargarDocumentoProceso;