import React, { useState, useEffect } from "react";
import {
  GroupOutlined,
  PlusOutlined,
  EditOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { Container } from "../../../components/Container";
import { useHistory } from "react-router-dom";
import { Button, Table, Space, Popconfirm } from "antd";
import TipoDocumentoService from "../../../services/TipoDocumentoService";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";

export function LegajoListarDocumento() {
  const [tipoDocumentos, setTipoDocumentos] = useState([]);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  let history = useHistory();

  useEffect(() => {
    async function cargarDatos() {
      const tipoDocumentos = await TipoDocumentoService.listarPorEmpresa(
        getUsuarioAutenticado().empresaSeleccionada.id
      );
      setTipoDocumentos(tipoDocumentos);
    }

    cargarDatos();
  }, []);

  function handleOnClickCrearDocumento() {
    history.push("/legajos/documento/crear");
  }

  function handleOnClickEditarTipoDocumento() {
    //console.log("funcion handleOnClickEditarTipoDocumento");
  }

  function handleOnClickEliminarTipoDocumento() {
    //console.log("funcion handleOnClickEliminarTipoDocumento");
  }

  const columnas = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      align: "center",
      // ...adicionarColumnasFiltro("nombre"),
    },
    // {
    //   title: "Acciones",
    //   align: "center",
    //   key: "acciones",
    //   align: "center",
    //   render: (fila) =>
    //     tipoDocumentos.length >= 1 ? (
    //       <Space>
    //         <a
    //           onClick={() => handleOnClickEditarTipoDocumento(fila.id)}
    //           className="custom-color-element"
    //         >
    //           <EditOutlined /> Editar
    //         </a>
    //         <Popconfirm
    //           title="¿Está seguro de eliminar el registro seleccionado?"
    //           okText="Si"
    //           cancelText="No"
    //           icon={<QuestionCircleOutlined style={{ color: "red" }} />}
    //           onConfirm={() => handleOnClickEliminarTipoDocumento(fila.id)}
    //         >
    //           <a style={{ color: "#CE3E3E" }}>
    //             {" "}
    //             <DeleteOutlined /> Eliminar
    //           </a>
    //         </Popconfirm>
    //       </Space>
    //     ) : null,
    // },
  ];

  return (
    <Container
      icono={<GroupOutlined style={{ fontSize: "40px" }} />}
      titulo="Documentos"
    >
      <div style={{ marginBottom: 16 }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleOnClickCrearDocumento}
        >
          Crear Documento
        </Button>
      </div>
      <Table
        rowKey={(tipoDocumento) => tipoDocumento.id}
        columns={columnas}
        dataSource={tipoDocumentos}
        pagination={{
          pageSize: [10],
        }}
      />
    </Container>
  );
}
