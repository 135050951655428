import React, { useReducer } from "react";
import { VisorPdfContext, VisorPdfReducer } from "./index";
import { saveAs } from "file-saver";

const VisorPdfContextProvider = ({ children }) => {
  const estadoInicial = {
    informacion: {
      periodo: "",
      descripcion: "",
      nombreArchivo: "",
    },
    loading: false,
    visible: false,
    visibleBtnImprimir: true,
    visibleBtnDescargar: true,
    urlPdf: undefined,
    blobPdf: undefined,
  };

  const [state, dispatch] = useReducer(VisorPdfReducer, estadoInicial);

  const mostrarVisorPdf = (url, informacion, visibleBtnImprimir = true, visibleBtnDescargar = true) => {
    dispatch({ type: "INICIAR" });
    dispatch({ type: "SET_URL_PDF", payload: url });
    dispatch({ type: "SET_INFORMACION", payload: informacion });
    dispatch({ type: "SET_VISIBLE_BTN_IMPRIMIR", payload: visibleBtnImprimir });
    dispatch({ type: "SET_VISIBLE_BTN_DESCARGAR", payload: visibleBtnDescargar });
    dispatch({ type: "MOSTRAR_VISOR_PDF" });
  };

  const cerrarVisorPdf = () => {
    dispatch({ type: "CERRAR_VISOR_PDF" });
  };

  const setBlobPdf = (blob) => {
    dispatch({ type: "SET_BLOB_PDF", payload: blob });
  };

  const finalizarCargaPdf = () => {
    dispatch({ type: "FINALIZAR" });
  };

  const imprimirPdf = () => {
    window.open(URL.createObjectURL(state.blobPdf)).print();
  };

  const descargarPdf = () => {
    saveAs(state.blobPdf, state.informacion.nombreArchivo);
  };

  return (
    <VisorPdfContext.Provider
      value={{
        informacion: state.informacion,
        loading: state.loading,
        urlPdf: state.urlPdf,
        visible: state.visible,
        visibleBtnImprimir: state.visibleBtnImprimir,
        visibleBtnDescargar: state.visibleBtnDescargar,
        imprimirPdf,
        descargarPdf,
        setBlobPdf,
        mostrarVisorPdf,
        cerrarVisorPdf,
        finalizarCargaPdf,
      }}
    >
      {children}
    </VisorPdfContext.Provider>
  );
};

export default VisorPdfContextProvider;
