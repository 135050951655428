import React, { useContext, useEffect, useState } from "react";
import { Table,Space } from "antd";
import * as atoms from "../ListarTipoPlanillaStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import  TipoPlanillaService from "../../../../services/TipoPlanillaService";
import { MenuAcciones } from "./MenuAcciones";

const TablaTipoPlanilla=()=>{

    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [loading, setLoading] = useState(false);
    const [tipoPlanillas, setTipoPlanillas] = useAtom(atoms.tipoPlanillaAtom);

    useEffect(()=>{
        const fetch = async ()=>{
            try {
             setLoading(true);                    
             const _tipoPlanillas = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             setTipoPlanillas(_tipoPlanillas);                        
             
            } catch (error) {
                console.error(error);
                
            } finally{
                setLoading(false);
            }

        }
        fetch(); 
    },[]);  
    const columnasTipoPlanillas=[
        {
            title:"Nombre",
            dataIndex:"nombre",   
            align:"center"             
        },
        
        {
            title:"Acciones",
            key:"acciones",
            align:"center",
            render:(fila)=>
                <MenuAcciones
                  tipoPlanilla={fila}
                  tipoPlanillas={tipoPlanillas}
                  setTipoPlanillas={setTipoPlanillas}
                />            
        },
        
    ];
    return(
        <Table
            loading={loading}
            rowKey={(tipoPlanilla)=>tipoPlanilla.id}
            columns={columnasTipoPlanillas}
            dataSource={tipoPlanillas}
            pagination={false}
        
        
        />
    )

}
export default TablaTipoPlanilla;