import React, { useContext, useEffect } from "react";
import { Tabs, Result, Button } from "antd";
import * as atoms from "../ConsultarLegajoColaboradorStore";
import { useAtom } from "jotai";
import { LoadingOutlined } from "@ant-design/icons";

import { TablaDocumentos } from "./tabla-documentos";
import { TabDocumentos } from "./tab-documentos";
import { BreadCrumbDocumentos } from "./breadCrumb_Documentos";
import { ModalVisorPdf } from "components/Modal/ModalVisorPdf";
import { OpcionService } from "services";
import { SecurityContext } from "context/SecurityContextProvider";

const ListaDocumentos = () => {
  const { TabPane } = Tabs;
  // const [loading, setLoading] = useState(false);
  //const [loadingColaboradores] = useAtom(atoms.loadingColaboradores);
  // const [loadingDocumentos] = useAtom(atoms.loadingDocumentos);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [colaboradorSeleccionado] = useAtom(atoms.colaboradorSeleccionado);
  const [primerColaborador] = useAtom(atoms.primerColaborador);
  const [, setCodigosOpciones] = useAtom(atoms.codigosOpcionesAtom);

  useEffect(() => {
    const cargarOpciones = async () => {
      const codigosOpciones = await OpcionService.listar(getUsuarioAutenticado().id);
      setCodigosOpciones(codigosOpciones);
    };
    cargarOpciones();
  }, []);

  return (
    <div className="lista-documentos">
      <div className="card-container-legajo">
        {colaboradorSeleccionado === undefined && (
          <Result
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              background: "#fff",
            }}
            status="warning"
            title="No se ha seleccionado ningún colaborador."
            subTitle="Por favor, seleccione un colaborador de la lista en su lado izquierdo."
          />
        )}

        {colaboradorSeleccionado && (
          <Tabs type="card" style={{ height: "100%" }}>
            <TabPane
              tab={
                colaboradorSeleccionado?.length === 0
                  ? primerColaborador?.nombreCompleto
                  : colaboradorSeleccionado?.nombreCompleto
              }
              key="1"
            >
              <TabDocumentos>
                <BreadCrumbDocumentos />
                <TablaDocumentos />
                <ModalVisorPdf />
              </TabDocumentos>
            </TabPane>
          </Tabs>
        )}
      </div>
    </div>
  );
};
export default ListaDocumentos;
