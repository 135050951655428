import React, { useState, useEffect } from "react";

import { ContainerCrear } from "../../../components/ContainerCrear";
import { Form, Input, Row, Col, Button, Space, notification, Select } from "antd";

import { CheckOutlined } from "@ant-design/icons";
import SedeService from "../../../services/SedeService";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { CampoColaborador } from "enums/CampoColaborador";
import SeccionService from "../../../services/SeccionService";
import SubsedeService from "../../../services/SubsedeService";
import PerfilService from "../../../services/PerfilService";
import TipoPlanillaService from "../../../services/TipoPlanillaService";
import CargoService from "services/CargoService";

export function SedeCrearPage() {
  const [loading, setLoading] = useState(false);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [dependenciaSede, setDependenciaSede] = useState();
  const [listaCampos, setListaCampos] = useState([]);
  const [perfiles, setPerfiles] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [subsedes, setSubsedes] = useState([]);
  const [tipoPlanillas, setTipoPlanillas] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [camposColaborador, setCamposColaborador] = useState([]);


  const { Option } = Select;
  const [formSede] = Form.useForm();

  let history = useHistory();
  useEffect(() => {
    const cargarDatosIniciales = async () => {
      var listaCampos = [], dependencia;
      const empresa = getUsuarioAutenticado().empresaSeleccionada.id;
      const campos_colaborador = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
      setCamposColaborador(campos_colaborador);

      const campo_sede = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.SEDE);

      const sedeDependencia = campo_sede.campoDependencia;


      if (sedeDependencia !== null) {

        setDependenciaSede(sedeDependencia);
        dependencia = sedeDependencia;
        listaCampos.push(dependencia);

        while (dependencia !== null) {
          var campo = campos_colaborador.find(campoColaborador => campoColaborador.campo === dependencia);
          dependencia = campo.campoDependencia;
          if (dependencia !== null) {
            listaCampos.push(dependencia);
          }

        }
        setListaCampos(listaCampos.reverse());

      }
      else {
        setListaCampos(listaCampos);
        setDependenciaSede(CampoColaborador.SINCAMPO);
      }
      if (listaCampos.length > 0) {

        const primerCampo = listaCampos[0];
        if (primerCampo === CampoColaborador.PERFIL) {
          const perfiles = await PerfilService.listar(empresa);
          setPerfiles(perfiles);
        }
        else if (primerCampo === CampoColaborador.SECCION) {
          const secciones = await SeccionService.listar(empresa);
          setSecciones(secciones);

        }
        else if (primerCampo === CampoColaborador.SUBSEDE) {
          const subsedes = await SubsedeService.listar(empresa);
          setSubsedes(subsedes);

        }
        else if (primerCampo === CampoColaborador.PLANILLA) {
          const planillas = await TipoPlanillaService.listar(empresa);
          setTipoPlanillas(planillas);

        }
        else if (primerCampo === CampoColaborador.CARGO) {
          const cargos = await CargoService.listar(empresa);
          setCargos(cargos);
        }
      }
    }
    cargarDatosIniciales();
  }, []);


  async function handleOnFinish(formulario) {
    try {
      setLoading(true);
      formulario.empresaId = getUsuarioAutenticado().empresaSeleccionada.id;
      formulario.dependencia = dependenciaSede;
      await SedeService.insertar(formulario);
      notification.success({
        message: "Operación exitosa",
        description: "Se registró la sede correctamente.",
      });
      setLoading(false);
      history.push("/sede");
    } catch (error) {
      console.error(error);
      notification.error({
        message: "No se puede registrar la Sede",
        description: error.response.data.mensaje,
      });
      setLoading(false);
    }
  }

  function handleOnClickCancelar() {
    history.push("/sede");
  }
  const handleOnchangePerfil = async (value, option) => {

    let listaCampos = camposColaborador.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.PERFIL);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.SECCION) {
        const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _secciones = secciones.filter(seccion => seccion.perfilId === value)
        setSecciones(_secciones);
        formSede.setFieldsValue({ seccionId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter(subsede => subsede.perfilId === value);
        setSubsedes(_subsedes);
        formSede.setFieldsValue({ subsedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.CARGO) {
        const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _cargos = cargos.filter(cargo => cargo.perfilId === value);
        setCargos(_cargos);
        formSede.setFieldsValue({ cargoId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanilla = tipoPlanilla.filter(planilla => planilla.perfilId === value);
        setTipoPlanillas(_tipoPlanilla);
        formSede.setFieldsValue({ tipoPlanillaId: "" })

      }
    }
  }
  const handleOnchangeSeccion = async (value, option) => {
    let listaCampos = camposColaborador.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.SECCION);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.seccionId === value)
        setPerfiles(_perfiles);
        formSede.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter(subsede => subsede.seccionId === value);
        setSubsedes(_subsedes);
        formSede.setFieldsValue({ subsedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.CARGO) {
        const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _cargos = cargos.filter(cargo => cargo.seccionId === value);
        setCargos(_cargos);
        formSede.setFieldsValue({ cargoId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanilla = tipoPlanilla.filter(planilla => planilla.seccionId === value);
        setTipoPlanillas(_tipoPlanilla);
        formSede.setFieldsValue({ tipoPlanillaId: "" })

      }
    }
  }
  const handleOnchangeSubsede = async (value, option) => {
    let listaCampos = camposColaborador.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.SUBSEDE);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.subsedeId === value)
        setPerfiles(_perfiles);
        formSede.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SECCION) {
        const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _secciones = secciones.filter(seccion => seccion.subsedeId === value);
        setSecciones(_secciones);
        formSede.setFieldsValue({ seccionId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.CARGO) {
        const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _cargos = cargos.filter(cargo => cargo.subsedeId === value);
        setCargos(_cargos);
        formSede.setFieldsValue({ cargoId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanilla = tipoPlanilla.filter(planilla => planilla.subsedeId === value);
        setTipoPlanillas(_tipoPlanilla);
        formSede.setFieldsValue({ tipoPlanillaId: "" })

      }
    }

  }
  const handleOnchangeTipoPlanilla = async (value, option) => {
    let listaCampos = camposColaborador.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.PLANILLA);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.tipoPlanillaId === value)
        setPerfiles(_perfiles);
        formSede.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter(subsede => subsede.tipoPlanillaId === value);
        setSubsedes(_subsedes);
        formSede.setFieldsValue({ subsedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.CARGO) {
        const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _cargos = cargos.filter(cargo => cargo.tipoPlanillaId === value);
        setCargos(_cargos);
        formSede.setFieldsValue({ cargoId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.SECCION) {
        const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _secciones = secciones.filter(seccion => seccion.tipoPlanillaId === value);
        setSecciones(_secciones);
        formSede.setFieldsValue({ seccionId: "" })

      }
    }

  }
  const handleOnchangeCargo = async (value, option) => {
    let listaCampos = camposColaborador.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.CARGO);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.cargoId === value)
        setPerfiles(_perfiles);
        formSede.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter(subsede => subsede.cargoId === value);
        setSubsedes(_subsedes);
        formSede.setFieldsValue({ subsedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanillas = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanillas = tipoPlanillas.filter(planilla => planilla.cargoId === value);
        setTipoPlanillas(_tipoPlanillas);
        formSede.setFieldsValue({ tipoPlanillaId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.SECCION) {
        const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _secciones = secciones.filter(seccion => seccion.cargoId === value);
        setSecciones(_secciones);
        formSede.setFieldsValue({ seccionId: "" })

      }
    }
  }
  return (
    <ContainerCrear
      titulo="Crear Centro de costo"
      descripcion="Formulario para crear los centros de costo para tu organización."
      colSpan={12}

    >
      <Form layout="vertical" size="large" onFinish={handleOnFinish} form={formSede}>
        {/* <Row>
          <Col span={24}>
            <Form.Item
              label="Código"
              name="codigo"             
            >
              <Input
                placeholder="Código de la Sede"
                maxLength="50"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row> */}
        {
          listaCampos.length > 0 && (
            listaCampos.map((listaCampo) => {
              if (listaCampo === CampoColaborador.PERFIL) {
                return (
                  <Row>
                    <Col span={24} >
                      <Form.Item
                        label="Perfil"
                        name="perfilId"
                        rules={[
                          {
                            required: true,
                            message: "Seleccione Perfil"
                          },
                        ]}
                      >
                        <Select
                          placeholder="Seleccione Perfil"
                          onChange={handleOnchangePerfil}
                        >
                          {perfiles.length > 0 &&
                            perfiles.map((lista) => {
                              return (
                                <Option key={lista.id} value={lista.id}>
                                  {lista.nombre}
                                </Option>
                              );
                            })}
                        </Select>

                      </Form.Item>
                    </Col>
                  </Row>
                )
              }

              else if (listaCampo === CampoColaborador.SECCION) {
                return (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Sección"
                        name="seccionId"
                        rules={[
                          {
                            required: true,
                            message: "Seleccione sección"
                          },
                        ]}
                      >
                        <Select
                          placeholder="Seleccione sección"
                          onChange={handleOnchangeSeccion}
                        >
                          {secciones.length > 0 &&
                            secciones.map((lista) => {
                              return (
                                <Option key={lista.id} value={lista.id}>
                                  {lista.nombre}
                                </Option>
                              );
                            })}

                        </Select>

                      </Form.Item>
                    </Col>
                  </Row>
                )
              }
              else if (listaCampo === CampoColaborador.SUBSEDE) {
                return (
                  <Row>
                    <Col span={24} >
                      <Form.Item
                        label="Subsede"
                        name="subsedeId"
                        rules={[
                          {
                            required: true,
                            message: "Seleccione Subsede"
                          },
                        ]}
                      >
                        <Select
                          placeholder="Seleccione Subsede"
                          onChange={handleOnchangeSubsede}
                        >
                          {subsedes.length > 0 &&
                            subsedes.map((lista) => {
                              return (
                                <Option key={lista.id} value={lista.id}>
                                  {lista.nombre}
                                </Option>
                              );
                            })}
                        </Select>

                      </Form.Item>
                    </Col>
                  </Row>
                )
              }
              else if (listaCampo === CampoColaborador.PLANILLA) {
                return (
                  <Row>
                    <Col span={24} >
                      <Form.Item
                        label="Tipo planilla"
                        name="tipoPlanillaId"
                        rules={[
                          {
                            required: true,
                            message: "Seleccione Tipo planilla"
                          },
                        ]}
                      >
                        <Select
                          placeholder="Seleccione Tipo planilla"
                          onChange={handleOnchangeTipoPlanilla}
                        >
                          {tipoPlanillas.length > 0 &&
                            tipoPlanillas.map((lista) => {
                              return (
                                <Option key={lista.id} value={lista.id}>
                                  {lista.nombre}
                                </Option>
                              );
                            })}
                        </Select>

                      </Form.Item>
                    </Col>
                  </Row>
                )

              }
              else if (listaCampo === CampoColaborador.CARGO) {
                return (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Cargo"
                        name="cargoId"
                        rules={[
                          {
                            required: true,
                            message: "Seleccione Cargo"
                          },
                        ]}
                      >
                        <Select
                          placeholder="Seleccione Cargo"
                          onChange={handleOnchangeCargo}
                        >
                          {cargos.length > 0 &&
                            cargos.map((lista) => {
                              return (
                                <Option key={lista.id} value={lista.id}>
                                  {lista.nombre}
                                </Option>
                              );
                            })}
                        </Select>

                      </Form.Item>
                    </Col>
                  </Row>
                )
              }

            })

          )

        }
        <Row>
          <Col span={24}>
            <Form.Item
              label="Nombre"
              name="nombre"
              rules={[
                {
                  required: true,
                  message: "Escribe el nombre del centro de costo",
                },
              ]}
            >
              <Input
                placeholder="Nombre del centro de costo"
                maxLength="50"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Space>
            <Col>
              <Form.Item style={{ textAlign: "center" }}>
                <Button type="default" onClick={handleOnClickCancelar}>
                  Cancelar
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  loading={loading}
                  htmlType="submit"
                >
                  Crear Centro de costo
                </Button>
              </Form.Item>
            </Col>
          </Space>
        </Row>
      </Form>
    </ContainerCrear>
  );
}
