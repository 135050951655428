import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";

const URL_API = ApiUrl.RECLUTAMIENTO_Y_SELECCION;

const PlantillaService = {
  async insertar(plantilla) {
    const respuesta = await ApiService(URL_API).post("/plantillas", plantilla);
    return respuesta.data;
  },
  async listar() {
    const respuesta = await ApiService(URL_API).get("/plantillas");
    return respuesta.data;
  },
  async eliminar(plantillaId) {
    const respuesta = await ApiService(URL_API).delete(`/plantillas/${plantillaId}`);
    return respuesta.data;
  },
  async buscarPorId(plantillaId) {
    const respuesta = await ApiService(URL_API).get(`/plantillas/${plantillaId}`);
    return respuesta.data;
  },
  async actualizar(plantilla) {
    const respuesta = await ApiService(URL_API).put("/plantillas", plantilla);
    return respuesta.data;
  },
};
export { PlantillaService };
