import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { tipoDocumentosAtom } from "../DescargaMasivaCrearStore";
import TipoDocumentoService from "services/TipoDocumentoService";

const useListarTipoDocumento = () => {

  const [tipoDocumentos, setTipoDocumentos] = useAtom(tipoDocumentosAtom);
  useEffect(() => {
    const cargarDatos = async () => {
      const respuesta = await TipoDocumentoService.listarPorEmpresa("");
      setTipoDocumentos(respuesta);
    }
    cargarDatos();
  }, [])

  return { tipoDocumentos };
}
export default useListarTipoDocumento;