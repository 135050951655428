import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

export function SpinLoading({ children, loadingPage }) {

    return (
        <Spin spinning={loadingPage} tip="Loading..." indicator={<LoadingOutlined style={{ fontSize: 36, }} spin />}>
            {children}
        </Spin>
    );
}