import { useEffect } from "react";
import { useAtom } from "jotai";
import {convocatoriasActivasAtom} from "../../../DetalleSeguimientoSolicitudPersonalStore";
import { ConvocatoriaService } from "services/reclutamiento-y-seleccion/ConvocatoriaService";
import { EstadoConvocatoria } from "enums/reclutamiento-y-seleccion/EstadoConvocatoria";

const useListarConvocatoriasActivas=(convocatoriaId)=>{

    const[convocatoriasActivas,setConvocatoriasActivas] = useAtom(convocatoriasActivasAtom);

    useEffect(()=>{
        const cargarDatos=async()=>{
           const respuesta= await ConvocatoriaService.listarPorEstado(EstadoConvocatoria.ACTIVO.toString());
           setConvocatoriasActivas(respuesta);
          
           
        }
        if(convocatoriasActivas!==null && convocatoriasActivas.length===0)
            cargarDatos();
    },[])

    return{convocatoriasActivas,setConvocatoriasActivas}

}
export default useListarConvocatoriasActivas;