import { atom } from "jotai";

export const seccionAtom = atom([]);
export const dependenciaSeccion= atom();

export const listaCampos=atom([]);
export const perfiles=atom([]);
export const sedes=atom([]);
export const subsedes=atom([]);
export const tipoPlanillas=atom([]);
export const cargos=atom([]);
export const camposColaborador=atom([]);