import React from "react";
import { Container } from "components/Container"
import { ListaDocumentosGenericos, ModalExito, ModalClaveCertificado, VisorPdfFirma, ModalFirmar } from "./componentes"
import { VisorPdfContextProvider } from "context/visor-pdf"
import { VisorPdf } from "components/VisorPdf"
import { FiltroDocumentos } from "./componentes"

const FirmaDocumentoGenericoMisDocumentosPage = () => {
    return (
        <VisorPdfContextProvider>

            <Container titulo="Mis documentos">

                <FiltroDocumentos />

                <ListaDocumentosGenericos />

                <ModalFirmar />

                <ModalClaveCertificado />

                <ModalExito />

                <VisorPdf />

            </Container>

        </VisorPdfContextProvider>
    )
}

export default FirmaDocumentoGenericoMisDocumentosPage;