import { useState, useRef, useEffect } from "react";

export function useMenuVisible(valorInicial) {
  const [menu, setMenu] = useState(valorInicial);
  const refFilter = useRef(null);

  const handleClickOutside = event => {
    if (refFilter.current && !refFilter.current.contains(event.target)) {
      setMenu("");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return [refFilter, menu, setMenu];
}
