import React from "react";
import { componentesAtom, zoomVisorFormularioAtom } from "../../../../VisorFormularioStorage";
import { useAtomValue } from "jotai";
import { ControlPdf } from "../ControlPdf";

const ListaControlesPdf = ({ pageNumber }) => {
	const zoom = useAtomValue(zoomVisorFormularioAtom);
	const componentes = useAtomValue(componentesAtom);

	return (
		<div style={{ zoom: zoom }}>
			{componentes?.length > 0 &&
				componentes
					.filter((componente) => componente.overlay.page === pageNumber)
					.map((componente) => {
						return <ControlPdf key={componente.key} componente={componente} />;
					})}
		</div>
	);
};

export default ListaControlesPdf;
