import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";

const URL_API = ApiUrl.BASE;

const ContratosColaboradorService = {
    async listar(numeroDocumentoIdentidad) {
        const respuesta = await ApiService(URL_API).get(`/admin/contratos/colaborador/${numeroDocumentoIdentidad}`);
        return respuesta.data;
    },

    async getUrlDocumento(detalleEnvioId) {
        const respuesta = await ApiService(URL_API).get(
            `/admin/contratos/colaborador/detalle-envio/${detalleEnvioId}/url-pdf`
        );
        return respuesta.data;
    },
};

export { ContratosColaboradorService };
