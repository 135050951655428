import { useAtom } from "jotai";
import {solicitudesPersonalAtom} from "../SeguimientoSolicitudesPersonalStore";
import { useEffect } from "react";
import SolicitudPersonalService from "services/reclutamiento-y-seleccion/SolicitudPersonalService";
import {filtroEstadoSolicitudPersonalAtom} from "../SeguimientoSolicitudesPersonalStore";
import { useState } from "react";
import { notification } from "antd";

const useListarSolicitudPersonal=()=>{

    const [solicitudesPersonal, setSolicitudesPersonal] = useAtom(solicitudesPersonalAtom);
    const [filtroEstadoSolicitud,setFiltroEstadoSolicitud] = useAtom(filtroEstadoSolicitudPersonalAtom);
    const [loading, setLoading] = useState(false);

    useEffect(() => { 
        const cargarDatos = async () => {
            if(filtroEstadoSolicitud!==undefined){
                try {
                    setLoading(true)
                    //const respuesta = await SolicitudPersonalService.listar();
                    const respuesta = await SolicitudPersonalService.listarPorFiltros(filtroEstadoSolicitud);
                    setSolicitudesPersonal(respuesta);
                    setLoading(false)
                } catch (error) {
                    notification.error({message:"Ocurrio un error en la consulta"})
                    setLoading(false)
                }
                
            }
            
        }
        cargarDatos();
    },[filtroEstadoSolicitud])
    return{solicitudesPersonal, loading}
}
export default useListarSolicitudPersonal;