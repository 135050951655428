import React from "react";
import { Modal, notification, Form, Input, message } from "antd";
import { VacacionesServiceAnterior } from "services/VacacionesService.bk";
import { useAtom } from "jotai";
import * as atoms from "../../ConsultaVacacionesStore";
import { EnvioAbsentismoRechazo } from "enums/EnvioAbsentismoRechazo";

const ModalRechazarSolicitudVacaciones = () => {
  const [visibleModalRechazo, setVisibleModalRechazo] = useAtom(atoms.visibleModalRechazoAtom);
  const [solicitudVacacionesSeleccionada, setSolicitudVacacionesSeleccionada] = useAtom(
    atoms.solicitudVacacionesSeleccionadaAtom
  );
  const [solicitudesVacaciones, setSolicitudesVacaciones] = useAtom(atoms.solicitudesVacacionesAtom);
  const [solicitudesVacacionesIds, setSolicitudesVacacionesIds] = useAtom(atoms.solicitudesVacacionesIdsAtom);
  const [envioRechazo, setEnvioRechazo] = useAtom(atoms.envioRechazoAtom);

  const [formRechazo] = Form.useForm();
  const { TextArea } = Input;

  const cerrarModal = () => {
    setVisibleModalRechazo(false);
    formRechazo.setFieldsValue({
      motivoRechazo: "",
    });
    setEnvioRechazo("");
  };

  const onFinishRechazo = async (formulario) => {
    try {
      if (envioRechazo !== EnvioAbsentismoRechazo.MASIVA) {
        const data = {
          ...formulario,
          solicitudVacacionesId: solicitudVacacionesSeleccionada.id,
          tipoEnvioRechazo: EnvioAbsentismoRechazo.INDIVIDUAL,
        };
        await VacacionesServiceAnterior.rechazar(data);
        message.success("La solicitud ha sido rechazada.");
        setVisibleModalRechazo(false);
        formRechazo.setFieldsValue({
          motivoRechazo: "",
        });
        setSolicitudesVacaciones(
          solicitudesVacaciones.filter((solicitudV) => solicitudV.id !== solicitudVacacionesSeleccionada.id)
        );
        setEnvioRechazo("");
      } else {
        formulario.tipoEnvioRechazo = EnvioAbsentismoRechazo.MASIVA;
        formulario.solicitudVacacionesIds = solicitudesVacacionesIds;
        await VacacionesServiceAnterior.rechazar(formulario);
        message.success("Las solicitudes han sido rechazadas.");
        setVisibleModalRechazo(false);
        formRechazo.setFieldsValue({
          motivoRechazo: "",
        });
        let vacaciones = solicitudesVacaciones;
        solicitudesVacacionesIds.map((solicitudId) => {
          vacaciones = vacaciones.filter((solicitudV) => solicitudV.id !== solicitudId);
        });
        setSolicitudesVacaciones(vacaciones);
        setEnvioRechazo("");
        setSolicitudesVacacionesIds([]);
      }
    } catch (error) {
      notification.error({
        message: "No se puede aprobar la solicitud",
        description: error.response.data.mensaje,
      });
    }
  };
  return (
    <Modal
      title="Motivo de Rechazo"
      visible={visibleModalRechazo}
      onCancel={cerrarModal}
      onOk={() => formRechazo.submit()}
    >
      <Form layout="vertical" form={formRechazo} onFinish={onFinishRechazo}>
        <Form.Item
          label="Indica el motivo del rechazo"
          name={"motivoRechazo"}
          rules={[{ required: true, message: "Ingrese el motivo de rechazo" }]}
        >
          <TextArea showCount maxLength={160} style={{ height: 120 }} autoSize={false} />
        </Form.Item>
        <Form.Item name={"solicitudVacacionesId"} hidden>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default ModalRechazarSolicitudVacaciones;
