import React, { useEffect, useState } from "react";
import { Breadcrumb, Spin } from "antd";
import { ModalVisorPdf } from "components/Modal/ModalVisorPdf";
import { useHistory, useParams } from "react-router-dom";
import { DetalleFiltro, DetalleTablaListar } from "./componentes";
import { Container } from "components/Container";
import { HomeOutlined, LoadingOutlined } from "@ant-design/icons";
import {InformacionDocumento} from "./componentes/InformacionDocumento"
import { useAtom } from "jotai";
import { loadingAtom } from "./DetalleSeguimientoOnboardingStorage";


const DetalleSeguimientoOnboarding = () => {
  const { descripcion } = useParams();
  const history = useHistory();
  const [loading] = useAtom(loadingAtom)

  const onClickBtnRetornar = () => {
    history.push("/onboarding/seguimiento");
  };

  return (
    <Container
      icono={
        <Breadcrumb itemRender>
          <Breadcrumb.Item href="/dashboard">
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/onboarding/seguimiento">Seguimiento Onboarding</Breadcrumb.Item>
          <Breadcrumb.Item href={null}>{descripcion}</Breadcrumb.Item>
        </Breadcrumb>
      }
      onClickRetornar={onClickBtnRetornar}
    >
      <Spin indicator={<LoadingOutlined style={{ fontSize: 28 }}/>} spinning={loading} tip="Cargando...">
      <InformacionDocumento />
      </Spin>
      <br />
      <DetalleFiltro />
      <DetalleTablaListar />
      <ModalVisorPdf />
    </Container>
  );
};

export default DetalleSeguimientoOnboarding;
