import React, { useContext, useState } from "react";
import * as atoms from "../ListarReportesStore";
import { useAtom } from "jotai";
import { Modal, Button, Select, Form, DatePicker, Row, Col, Space, notification } from "antd";
import { TipoProceso } from "enums/TipoProceso";
import { CheckOutlined } from "@ant-design/icons";
import { SecurityContext } from "context/SecurityContextProvider";
import ReporteService from "services/ReporteService";

const ModalCrearReporte = () => {
  const [visibleCrearReporte, setVisibleCrearReporte] = useAtom(atoms.visibleModalCrearReporteAtom);
  const [reportes, setReportes] = useAtom(atoms.reportesAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [loading, setLoading] = useState(false);
  const dateFormat = "MM/YYYY";
  const { Option } = Select;
  const [formReporte] = Form.useForm();
  const cerrarModal = () => {
    setVisibleCrearReporte(false);
    formReporte.setFieldsValue({
      tipo: [],
      periodoInicio: null,
      periodoFin: null,
    });
  };
  const handleOnFinish = async (reporte) => {
    try {
      setLoading(true);
      reporte.solicitanteId = getUsuarioAutenticado().id;
      reporte.periodoInicio = reporte.periodoInicio.format("MM/YYYY");
      reporte.periodoFin = reporte.periodoFin.format("MM/YYYY");

      const reporteNuevo = await ReporteService.insertar(reporte);
      setReportes([...reportes, reporteNuevo]);
      formReporte.setFieldsValue({
        tipo: null,
        periodoInicio: null,
        periodoFin: null,
      });
      notification.success({
        description: "Se registró el reporte correctamente.",
      });
      setLoading(false);
      setVisibleCrearReporte(false);
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Advertencia",
        description: "No se registró la solicitud de geneneración de reporte.",
        //description: error.response.data.mensaje,
      });
      setLoading(false);
    }
  };
  return (
    <Modal
      title="Crear Reporte"
      visible={visibleCrearReporte}
      onCancel={cerrarModal}
      footer={null}
      destroyOnClose={true}
    >
      <Form layout="vertical" form={formReporte} onFinish={handleOnFinish}>
        <Row>
          <Col span={12}>
            <Form.Item
              name="tipo"
              label="Tipo"
              rules={[
                {
                  required: true,
                  message: "Selecciona el tipo",
                },
              ]}
            >
              <Select placeholder="Seleccione el tipo">
                <Option value={TipoProceso.REPORTE_CONSULTA_DOCUMENTOS}>Reporte Consulta Documentos</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="periodoInicio"
              label="Periodo Inicio"
              rules={[
                {
                  required: true,
                  message: "Selecciona el periodo inicio",
                },
              ]}
            >
              <DatePicker
                placeholder="Seleccione periodo inicio"
                // defaultValue={moment(new Date(),dateFormat)}
                format={dateFormat}
                style={{ width: "100%" }}
                picker="month"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              name="periodoFin"
              label="Periodo Fin"
              rules={[
                {
                  required: true,
                  message: "Selecciona el periodo fin",
                },
              ]}
            >
              <DatePicker
                placeholder="Seleccione periodo fin"
                //defaultValue={moment(new Date(),dateFormat)}
                format={dateFormat}
                style={{ width: "100%" }}
                picker="month"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Form.Item style={{ textAlign: "center" }}>
            <Space>
              <Button onClick={cerrarModal}>Cancelar</Button>
              <Button type="primary" icon={<CheckOutlined />} htmlType="submit" loading={loading}>
                Crear Reporte
              </Button>
            </Space>
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  );
};
export default ModalCrearReporte;
