import React from "react";
import { Card, Row, Col } from "antd";

export function PanelCrear({ titulo, children }) {

    return (
        <Card size="small" title={titulo} headStyle={{ color: "#1678c1" }} bodyStyle={{ paddingTop: "20px" }}>
            {children}
        </Card>
    );
}