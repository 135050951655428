import React from "react";
import { Button, Select } from "antd";
import { ZoomInOutlined, ZoomOutOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { zoomVisorFormularioAtom } from "../../VisorFormularioStorage";
import styled from "@emotion/styled";

const { Option } = Select;

const Zoom = () => {
	const [zoom, setZoom] = useAtom(zoomVisorFormularioAtom);

	const onClickBtnAcercar = () => {
		if (zoom < 2) {
			setZoom(zoom + 0.25);
		}
	};

	const onClickBtnAlejar = () => {
		if (zoom > 1) {
			setZoom(zoom - 0.25);
		}
	};

	const onSeleccionarZoom = (valor) => {
		setZoom(valor);
	};

	return (
		<Container>
			<Button onClick={onClickBtnAlejar}>
				<ZoomOutOutlined />
			</Button>
			<Select
				defaultValue={zoom}
				style={{ width: 120, textAlign: "center" }}
				value={zoom}
				onChange={onSeleccionarZoom}
			>
				<Option value={1}>100 %</Option>
				<Option value={1.25}>125 %</Option>
				<Option value={1.5}>150 %</Option>
				<Option value={1.75}>175 %</Option>
				<Option value={2}>200 %</Option>
			</Select>
			<Button onClick={onClickBtnAcercar}>
				<ZoomInOutlined />
			</Button>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid #ccc;
`;

export default Zoom;
