import React, { useState, useEffect } from "react";
import { Button, Form, Input, Table, Col, Row, Select, notification, message } from "antd";
import { useAtom } from "jotai";
import { visibleDrawerCampoAtom, camposAtom } from "../../EditarPerfilSolicitudPersonalStore";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import PerfilService from "services/reclutamiento-y-seleccion/PerfilService";

const PerfilEditar = () => {
  const [, setVisibleDrawerCampos] = useAtom(visibleDrawerCampoAtom);
  const [campos, setCampos] = useAtom(camposAtom);
  const { perfilId } = useParams();
  const [formEditarPerfil] = Form.useForm();
  let history = useHistory();
  useEffect(() => {
    const cargarDatos = async () => {
      const perfilBuscado = await PerfilService.buscarPorId(perfilId);
      formEditarPerfil.setFieldsValue({
        id: perfilBuscado.id,
        nombre: perfilBuscado.nombre,
        diasAnticipacionSolicitud: perfilBuscado.diasAnticipacionSolicitud,
      });
      setCampos(perfilBuscado.campos);
    };
    cargarDatos();
  }, []);
  const onClickAgregar = () => {
    setVisibleDrawerCampos(true);
  };
  const onClickQuitarCampo = (campo) => {
    setCampos(campos.filter((item) => item.key !== campo.key));
  };
  const columnasCamposPersonalizados = [
    {
      title: "Campo",
      dataIndex: "key",
      align: "center",
    },
    {
      title: "Etiqueta",
      dataIndex: "label",
      align: "center",
    },
    {
      title: "Tipo",
      dataIndex: "type",
      align: "center",
    },
    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      render: (fila) => (
        <React.Fragment>
          {/* <Button onClick={() => onClickEditarCampo(fila)} type="link">
                Editar
              </Button>*/}
          <Button onClick={() => onClickQuitarCampo(fila)} type="link">
            Quitar
          </Button>
        </React.Fragment>
      ),
    },
  ];

  const onFinishActualizarPerfil = async (formulario) => {
    try {
      // if (campos.length > 0) {
      formulario.campos = campos;
      console.log("datos: ", formulario);

      await PerfilService.actualizar(formulario);
      notification.success({
        message: "Operación exitosa",
        description: "Se actualizó el perfil correctamente",
      });
      history.push("/reclutamiento-y-seleccion/solicitud-personal/perfiles");
      // } else {
      //   message.error("Debe agregar al menos un campo");
      // }
    } catch (error) {
      notification.error({
        message: "No se puede actualizar el Perfil",
        description: error.response.data.mensaje,
      });
    }
  };

  return (
    <Form
      layout="vertical"
      labelCol={{ span: 4, offset: 8 }}
      wrapperCol={{ span: 8, offset: 8 }}
      form={formEditarPerfil}
      onFinish={onFinishActualizarPerfil}
    >
      <Form.Item name="id" label="id" noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        name="nombre"
        label="Nombre"
        rules={[
          {
            required: true,
            message: "Debe ingresar el nombre.",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="diasAnticipacionSolicitud"
        label="Días de anticipación a la solicitud"
        rules={[
          {
            required: true,
            message: "Debe ingresar los días.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="campos" label="Campos">
        <Button onClick={onClickAgregar}>Agregar </Button>
        <Table
          rowKey={(campo) => campo.key}
          columns={columnasCamposPersonalizados}
          dataSource={campos}
          pagination={false}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          Actualizar Perfil
        </Button>
      </Form.Item>
    </Form>
  );
};
export default PerfilEditar;
