import React from "react";
import { Modal } from "antd";
import { cssBodyVisorPdf, cssVisorPdf } from "./ModalVisorPdfStyles";
import { Header } from "./Header";
import { Body } from "./Body";
import useModalVisorPdf from "store/global/modal-visor-pdf/useModalVisorPdf";

const ModalVisorPdf = ({ botonesExtra }) => {
  const visible = useModalVisorPdf((state) => state.visible);
  const cerrarModal = useModalVisorPdf((state) => state.cerrarModal);

  return (
    <React.Fragment>
      <Modal
        title={null}
        open={visible}
        onCancel={cerrarModal}
        bodyStyle={cssBodyVisorPdf}
        style={cssVisorPdf}
        closable={false}
        footer={null}
        className="milegajo-ant-modal"
        width="calc(100vw - 60px)"
        height="calc(100vh - 60px)"
      >
        <Header botonesExtra={botonesExtra} />
        <Body />
      </Modal>
    </React.Fragment>
  );
};

export default ModalVisorPdf;
