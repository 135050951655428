import React, { useEffect, useState } from "react";
import { Tabs, Button, Typography, Spin, notification } from "antd";
import {
  DetalleSolicitudPersonal,
  ModalCrearConvocatoria,
  ModalRechazarConvocatoria,
  ConvocatoriasListar,
} from "./componentes";
import { ArrowLeftOutlined, PlusCircleOutlined, UndoOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
//import {ConvocatoriasListar} from "./componentes/convocatorias";
import SolicitudPersonalService from "services/reclutamiento-y-seleccion/SolicitudPersonalService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useAtom } from "jotai";
import { isConvocatoriaAtom, loadingPageAtom, solicitudAtom } from "./DetalleSeguimientoSolicitudPersonalStore";
import { SpinLoading } from "components/SpinLoading";

const DetalleSolicitudPersonalPage = () => {
  const [solicitud, setSolicitud] = useAtom(solicitudAtom);
  const [modalRechazar, setModalRechazar] = useState(false);
  const [modalCrear, setModalCrear] = useState(false);
  const [isConvocatoria, setIsConvocatoria] = useAtom(isConvocatoriaAtom);
  const [pendiente, isPendiente] = useState(false);
  const [modalAgregar, setModalAgregar] = useState(false);
  const [restablecer, setRestablecer] = useState(false);
  const [tabActivo, setTabActivo] = useState("informacion-solicitud");
  const [modalCerrarProceso, setModalCerrarProceso] = useState(false);

  const [loadingPage, setLoadingPage] = useAtom(loadingPageAtom)

  let { solicitudId } = useParams();
  let history = useHistory();

  const operaciones = {
    left: (
      <Button
        type="text"
        onClick={() => {
          onClickRetornar();
          setIsConvocatoria(false);
          setSolicitud(undefined);
        }}
      >
        <ArrowLeftOutlined />
      </Button>
    ),
    right: (
      <div style={{ padding: "0 0 15px 0" }}>
        {pendiente ? (
          <div style={{ display: "flex", gap: "1rem" }}>
            <Button danger onClick={() => setModalRechazar(true)}>
              Rechazar Solicitud
            </Button>
            <Button onClick={() => setModalCrear(true)}>Generar Convocatoria</Button>
          </div>
        ) : isConvocatoria ? (
          <div style={{ display: "flex", gap: "1rem" }}>
            <Button onClick={() => setModalCerrarProceso(true)} disabled={solicitud.estado === "CERRADO"}>
              <CloseCircleOutlined />
              Cerrar Proceso
            </Button>
            {/* <Button onClick={() => setRestablecer(true)}>
              <UndoOutlined />
              Restablecer Etapas
            </Button> */}

            <Button onClick={() => setModalAgregar(true)} disabled={solicitud.estado === "CERRADO"}>
              <PlusCircleOutlined />
              Agregar Postulante
            </Button>
          </div>
        ) : null}
      </div>
    ),
  };

  useEffect(() => {
    const cargarDatos = async () => {
      setLoadingPage(true);
      try {
        const respuesta = await SolicitudPersonalService.buscarPorId(solicitudId);
        //console.log(respuesta)
        isPendiente(respuesta != null && respuesta?.estado === "PENDIENTE");
        setSolicitud(respuesta);
        setLoadingPage(false);
      } catch (error) {
        notification.error({
          message: "Ocurrio un error en la consulta"
        })
        setLoadingPage(false);
      }
      
    };
    cargarDatos();
  }, []);

  const onClickRetornar = () => {
    history.push("/reclutamiento-y-seleccion/solicitud-personal/solicitudes");
  };

  return (
    <ContainerTabs>
      <SpinLoading loadingPage={loadingPage} >
        <Tabs
          tabBarExtraContent={operaciones}
          destroyInactiveTabPane={true}
          style={{ padding: "5px 15px" }}
          onChange={(key) => {
            setIsConvocatoria(false);
            setTabActivo(key);
          }}
          activeKey={tabActivo}
        >
          <Tabs.TabPane tab="Información solicitud" key="informacion-solicitud">
            <DetalleSolicitudPersonal solicitud={solicitud} />
          </Tabs.TabPane>
          {/* <Tabs.TabPane tab="Convocatorias" key="convocatorias" disabled={solicitud?.estado !== "INICIADO"}> */}
          <Tabs.TabPane tab="Convocatorias" key="convocatorias" disabled={solicitud?.estado === "PENDIENTE" || solicitud?.estado === "RECHAZADO"}>
            <ConvocatoriasListar
              modalAgregarPostulante={modalAgregar}
              setModalAgregarPostulante={setModalAgregar}
              restablecer={restablecer}
              setRestablecer={setRestablecer}
              modalCerrarProceso={modalCerrarProceso}
              setModalCerrarProceso={setModalCerrarProceso}
              cantidad={solicitud?.cantidad}
            />
          </Tabs.TabPane>
        </Tabs>
      </SpinLoading>
      <ModalRechazarConvocatoria modalRechazar={modalRechazar} setModalRechazar={setModalRechazar} />
      <ModalCrearConvocatoria
        modalCrear={modalCrear}
        setModalCrear={setModalCrear}
        setTabActivo={setTabActivo}
        isPendiente={isPendiente}
      />
    </ContainerTabs>
  );
};
const ContainerTabs = ({ children }) => {
  return <div style={{ padding: "10px 5px", background: "white", height: "85vh" }}>{children}</div>;
};
export default DetalleSolicitudPersonalPage;
