import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";

const URL_API = ApiUrl.RECLUTAMIENTO_Y_SELECCION;

const ReporteSolicitudPersonalService = {
  
  async getTasaRotacionPersonal(params) {
    return ApiService(URL_API).get("/reportes/dashboard/tasa-rotacion-personal", {
      params: { ...params },
    });
  },

  async getTasaRotacionPersonalPorSede(params) {
    return ApiService(URL_API).get("/reportes/dashboard/tasa-rotacion-personal-por-centro-costo", {
      params: { ...params },
    });
  },

  async getTasaContratacionPersonal(params) {
    return ApiService(URL_API).get("/reportes/dashboard/tasa-contratacion-personal", {
      params: { ...params },
    });
  },
};
export { ReporteSolicitudPersonalService };
