import { BaseService } from "./BaseService";
import { HttpService } from "./HttpService";

const SeccionService = {
  async listar(empresaId) {
    const respuesta = await BaseService.get("/seccion", { params: { empresaId: empresaId } });
    return respuesta.data;
  },

  async listarPromise(empresaId) {
    return HttpService(empresaId).get("/seccion");
  },

  async insertar(seccion) {
    const respuesta = await BaseService.post("/seccion", seccion);
    return respuesta.data;
  },

  async actualizar(seccion) {
    const respuesta = await BaseService.put("/seccion", seccion);
    return respuesta.data;
  },
  async buscarPorId(id) {
    const respuesta = await BaseService.get(`/seccion/${id}`);
    return respuesta.data;
  },
  async eliminar(id) {
    const respuesta = await BaseService.delete(`/seccion/${id}`);
    return respuesta.data;
  },
  async listarPorUsuario(login) {
    const respuesta = await BaseService.get("/seccion/usuario", {
      params: {
        login: login,
      },
    });
    return respuesta.data;
  },
  async listarPorNombreSede(empresaId, nombreSede) {
    const respuesta = await BaseService.get("/seccion/listarPorNombreSede", {
      params: {
        empresaId:empresaId,
        nombreSede: nombreSede
      },
    });
    return respuesta.data;
  },
};
export default SeccionService;
