import React, { useContext, useEffect, useState } from "react";
import { Modal, Table } from "antd";
import { visibleModalHistorialCodigosAtom } from "../ColaboradorListarStore";
import { useAtom } from "jotai";
import { historialCodigosAtom } from "../ColaboradorListarStore";
import { SecurityContext } from "context/SecurityContextProvider";
import { CampoColaborador } from "enums/CampoColaborador";

var cols = [
  {
    title: "Código",
    dataIndex: "codigoTrabajador",
    key: "codigoTrabajador",
    width: "100px",
  },
  {
    title: "Correo Electrónico",
    dataIndex: "correoElectronico",
    key: "correoElectronico",
    width: "250px",
  },
  {
    title: "Celular",
    dataIndex: "celular",
    key: "celular",
    width: "110px",
  },
  {
    title: "Fecha Ingreso",
    dataIndex: "fechaIngreso",
    key: "fechaIngreso",
    width: "110px",
    render: (text, fila) => (fila.fechaIngreso != null ? new Date(fila.fechaIngreso).toLocaleString() : "-"),
  },
  {
    title: "Centro de costo",
    dataIndex: ["sede", "nombre"],
    key: "sede",
    width: "150px",
  },
  {
    title: "Subcentro de costo",
    dataIndex: ["subsede", "nombre"],
    key: "subsede",
    width: "150px",
  },
  {
    title: "Sección",
    dataIndex: ["seccion", "nombre"],
    key: "seccion",
    width: "150px",
  },
  {
    title: "Tipo Planilla",
    dataIndex: ["tipoPlanilla", "nombre"],
    key: "tipoPlanilla",
    width: "150px",
  },
  {
    title: "Perfil",
    dataIndex: ["perfil", "nombre"],
    key: "perfil",
    width: "150px",
  },
  {
    title: "Cargo",
    dataIndex: ["cargo", "nombre"],
    key: "cargo",
    width: "150px",
  },

  {
    title: "Fecha Registro",
    dataIndex: "fechaRegistro",
    key: "fechaRegistro",
    width: "110px",
    render: (text, fila) => new Date(fila.fechaRegistro).toLocaleString(),
  },
];

const ModalHistorialColaborador = () => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [visibleModalHistorialCodigo, setVisibleModalHistorialCodigo] = useAtom(visibleModalHistorialCodigosAtom);
  const [historialCodigos] = useAtom(historialCodigosAtom);
  const [columnas, setColumnas] = useState([]);

  useEffect(() => {
    if (visibleModalHistorialCodigo) {
      const camposColaborador = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;

      var columnas = [];
      columnas.push({
        title: "Código",
        dataIndex: "codigoTrabajador",
        key: "codigoTrabajador",
        width: "100px",
      });
      columnas.push({
        title: "Correo Electrónico",
        dataIndex: "correoElectronico",
        key: "correoElectronico",
        width: "250px",
      });
      columnas.push({
        title: "Celular",
        dataIndex: "celular",
        key: "celular",
        width: "110px",
      });
      columnas.push({
        title: "Fecha Ingreso",
        dataIndex: "fechaIngreso",
        key: "fechaIngreso",
        width: "110px",
        render: (text, fila) => (fila.fechaIngreso != null ? new Date(fila.fechaIngreso).toLocaleString() : "-"),
      });

      if (camposColaborador.find((c) => c.campo === CampoColaborador.SEDE)) {
        columnas.push({
          title: "Centro de costo",
          dataIndex: ["sede", "nombre"],
          key: "sede",
          width: "150px",
        });
      }

      if (camposColaborador.find((c) => c.campo === CampoColaborador.SUBSEDE)) {
        columnas.push({
          title: "Subcentro de costo",
          dataIndex: ["subsede", "nombre"],
          key: "subsede",
          width: "150px",
        });
      }

      if (camposColaborador.find((c) => c.campo === CampoColaborador.SECCION)) {
        columnas.push({
          title: "Sección",
          dataIndex: ["seccion", "nombre"],
          key: "seccion",
          width: "150px",
        });
      }

      if (camposColaborador.find((c) => c.campo === CampoColaborador.PLANILLA)) {
        columnas.push({
          title: "Tipo Planilla",
          dataIndex: ["tipoPlanilla", "nombre"],
          key: "tipoPlanilla",
          width: "150px",
        });
      }

      if (camposColaborador.find((c) => c.campo === CampoColaborador.PERFIL)) {
        columnas.push({
          title: "Perfil",
          dataIndex: ["perfil", "nombre"],
          key: "perfil",
          width: "150px",
        });
      }

      if (camposColaborador.find((c) => c.campo === CampoColaborador.CARGO)) {
        columnas.push({
          title: "Cargo",
          dataIndex: ["cargo", "nombre"],
          key: "cargo",
          width: "150px",
        });
      }

      columnas.push({
        title: "Fecha Cese",
        dataIndex: "fechaCese",
        key: "fechaCese",
        width: "110px",
        render: (text, fila) => (fila.fechaCese != null ? new Date(fila.fechaCese).toLocaleString() : "-"),
      });
      columnas.push({
        title: "Fecha Registro",
        dataIndex: "fechaRegistro",
        key: "fechaRegistro",
        width: "110px",
        render: (text, fila) => new Date(fila.fechaRegistro).toLocaleString(),
      });

      setColumnas(columnas);
    }
  }, [visibleModalHistorialCodigo]);

  const cerrarModal = () => {
    setVisibleModalHistorialCodigo(false);
  };

  return (
    <Modal
      title="Histórico de Cambios"
      visible={visibleModalHistorialCodigo}
      //onOk={cerrarModal}
      onCancel={cerrarModal}
      footer={null}
      width="90%"
    >
      <Table
        rowKey={(historialCodigo) => historialCodigo.id}
        columns={columnas}
        dataSource={historialCodigos}
        pagination={false}
        scroll={{ y: 600 }}
        bordered={true}
      />
    </Modal>
  );
};
export default ModalHistorialColaborador;
