import React, { useContext } from "react";
import { Modal } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons"
import xd from "context/firma-documento-generico/crear/FirmaDocumentoGenericoCrearContext";

const ModalConfirmar = ()  => {

    const { loading, visibleModalConfirmacion, guardarDocumentoGenerico, cerrarModalConfirmacion } = useContext(xd);

    return (
        <Modal
            title="Mi Legajo Digital"
            visible={visibleModalConfirmacion}
            onOk={guardarDocumentoGenerico}
            okText="Guardar"
            onCancel={cerrarModalConfirmacion}
            cancelText="Cancelar"
            confirmLoading={loading}
        >
            <QuestionCircleOutlined className="milegajo-icono-pregunta" />
            <span className="milegajo-texto-pregunta">¿Está seguro de guardar el Documento Genérico?</span>
        </Modal>
    );
}

export default ModalConfirmar;