import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { ListaColaboradores } from "./lista-colaboradores";
import { ListaDocumentos } from "./lista-documentos";

const ConsultarLegajoColaboradorPage = () => {
  return (
    <JotaiProvider>
      <div className="contenedor-legajo">
        <ListaColaboradores />
        <ListaDocumentos />
      </div>
    </JotaiProvider>
  );
};
export default ConsultarLegajoColaboradorPage;
