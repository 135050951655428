import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Select, Button, DatePicker, Alert, Typography } from "antd";
import { ContratoCrearContext } from "context/contrato/crear/ContratoCrearContext";
import CarpetaService from "services/CarpetaService";
import { SecurityContext } from "context/SecurityContextProvider";
import { FolderTwoTone, FolderFilled } from "@ant-design/icons";
import { Space } from "antd";
import { useAtom } from "jotai";
import * as atoms from "../../CrearContratoStore";
import { EstadoCertificadoDigital } from "enums/EstadoCertificadoDigital";

export function FormularioCrearContrato() {
  const { plantillasContratos, getPlantillasContratos, mostrarModalConfirmacion } = useContext(ContratoCrearContext);
  const { Option } = Select;
  const [carpetas, setCarpetas] = useState([]);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [frmContrato] = Form.useForm();
  const [respuestaCertificadoExpirado, setRespuestaCertificadoExpirado] = useAtom(
    atoms.respuestaCertificadoExpiradoAtom
  );
  //const [mostrarAlerta, setMostrarAlerta] = useAtom(atoms.mostrarAlertaAtom);
  const { Text } = Typography;
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [estado, setEstado] = useState("VIGENTE");

  useEffect(() => {
    getPlantillasContratos();
    async function cargarCarpetas() {
      const _carpetas = await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
      setCarpetas(_carpetas);
    }
    cargarCarpetas();
  }, []);

  const onClickBtnGuardar = async (contrato) => {
    mostrarModalConfirmacion(contrato);
  };

  const onChangeSelectCarpeta = (value, option) => {
    frmContrato.setFieldsValue({ carpeta: { descripcion: option.descripcion } });
  };

  function handleOnChangeSeleccionarContrato(value) {
    const temp = plantillasContratos?.find((item) => item.id == value);
    setMostrarAlerta(temp.estadoCertificado === "EXPIRADO" || temp.estadoCertificado === "SIN_CERTIFICADO");
    setEstado(temp.estadoCertificado);
  }

  return (
    <React.Fragment>
      {mostrarAlerta && (
        <div style={{ marginBottom: "10px" }}>
          <Alert
            message={
              estado === EstadoCertificadoDigital.EXPIRADO ? (
                <Text>No puede crear este documento porque su certificado digital se encuentra expirado. </Text>
              ) : (
                <Text>No hay certificado registrado para está empresa</Text>
              )
            }
            type="warning"
            showIcon
          />
        </div>
      )}
      <Form
        name="frmCrearContrato"
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 8 }}
        onFinish={onClickBtnGuardar}
        form={frmContrato}
      >
        <Form.Item label="Periodo" name="periodo" rules={[{ required: true, message: "Seleccione el periodo." }]}>
          <DatePicker format="MM/YYYY" style={{ width: "65%" }} picker="month" placeholder="Selecciona un periodo" />
        </Form.Item>

        <Form.Item
          label="Tipo Documento"
          name="plantillaContratoId"
          rules={[{ required: true, message: "Seleccione la plantilla de contrato." }]}
        >
          <Select
            placeholder="Seleccione una plantilla"
            showSearch
            onChange={(value) => handleOnChangeSeleccionarContrato(value)}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {plantillasContratos.length > 0 &&
              plantillasContratos.map((plantillaContrato) => {
                return (
                  <Option key={plantillaContrato.id} value={plantillaContrato.id}>
                    {plantillaContrato.nombre}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item label="Descripción" name="descripcion">
          <Input placeholder="Ingrese una descripción" maxLength="50" autoComplete="off" />
        </Form.Item>

        <Form.Item label="Carpeta" name={["carpeta", "id"]}>
          <Select onChange={onChangeSelectCarpeta} optionLabelProp="label" placeholder="Seleccione la carpeta">
            {carpetas.length > 0 &&
              carpetas.map((carpeta) => {
                return (
                  <Option
                    key={carpeta.id}
                    value={carpeta.id}
                    label={
                      <div>
                        <FolderTwoTone style={{ fontSize: "20px", color: "#F7D358" }} twoToneColor="#F7D358" />{" "}
                        {carpeta.ruta}
                      </div>
                    }
                    descripcion={carpeta.descripcion}
                  >
                    <Space size={carpeta.nivel * 10}>
                      <span></span>
                      <span>
                        {carpeta.nivel === 0 ? (
                          <FolderFilled style={{ fontSize: "20px", color: "#F7D358" }} />
                        ) : (
                          <FolderTwoTone style={{ fontSize: "18px", color: "#F7D358" }} twoToneColor="#F7D358" />
                        )}{" "}
                        {carpeta.descripcion}
                      </span>
                    </Space>
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item name={["carpeta", "descripcion"]} noStyle>
          <Input type="hidden" />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 24, offset: 9 }}>
          <Button
            type="primary"
            disabled={mostrarAlerta}
            htmlType="submit"
            style={{ paddingLeft: "30px", paddingRight: "30px" }}
          >
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </React.Fragment>
  );
}
