import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { Container } from "components/Container";
import { EditarConvocatoria } from "./componentes";
import { useHistory } from "react-router-dom";

const ConvocatoriaEditarPage=()=>{
    let history=useHistory();

    const onClickRetornar=()=>{
       history.push("/reclutamiento-y-seleccion/convocatorias");
    }
    return(
        <JotaiProvider>
            <Container titulo="Editar convocatoria" onClickRetornar={onClickRetornar}>
               <EditarConvocatoria/>
            </Container>
        </JotaiProvider>
        
    )
}
export default ConvocatoriaEditarPage;