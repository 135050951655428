import React, { useEffect } from 'react';
import { Button, Table } from "antd";
import { useAtom } from 'jotai';
import * as atoms from "../../store/NotificarFormularioStore";

const TablaSedesSeleccionadas = () =>{

  const[sedesAgregadas,setSedesAgregadas]=useAtom(atoms.sedesAgregadasAtom);
  const [sedesAgregadasIds,setSedesAgregadasIds]=useAtom(atoms.sedesAgregadasIdsAtom);

    useEffect(()=>{         

    },[]);

    const columnasSede=[
        {
          title:"Centro de costo",
          dataIndex:"nombre",
          key:"nombre"
        },
        {
          title: "Acciones",
          key: "acciones",
          render: (text, fila) => (<Button type="link" onClick={() => onClickQuitarSede(fila.id)}>Quitar</Button>)
        }
      ];
      const onClickQuitarSede = (sedeId) =>{
        setSedesAgregadas(sedesAgregadas.filter(item=>item.id!==sedeId));
        setSedesAgregadasIds(sedesAgregadasIds.filter(item=>item!==sedeId));
      }
      return(
        <Table
        rowKey={(sede) => sede.id}
        dataSource={sedesAgregadas}
        columns={columnasSede}
        pagination={false}
        scroll={{ y: 240 }} />        

      );

}
export default TablaSedesSeleccionadas;