import React from "react";
import { Button, Table } from "antd";
import { useCargarVacaciones, usePaginacion } from "../../hooks";
import MenuAcciones from "./MenuAcciones";

const TablaControlVacaciones = () => {
  const { vacaciones } = useCargarVacaciones();
  const { totalRegistros, paginaActual, tamanioPaginado, onCambiarPaginacion } = usePaginacion();

  const columnas = [
    {
      title: "Documento de Identidad",
      dataIndex: ["colaborador", "numeroDocumentoIdentidad"],
      align: "center",
    },
    {
      title: "Colaborador",
      dataIndex: ["colaborador", "nombreCompleto"],
      align: "center",
    },
    {
      title: "Fecha Ingreso",
      dataIndex: "fechaIngreso",
      align: "center",
    },
    {
      title: "Vacaciones Disponibles",
      dataIndex: "vacacionesDisponibles",
      align: "center",
    },
    {
      title: "Acciones",
      align: "center",
      render: (text, fila) => <MenuAcciones colaborador={fila} />,
    },
  ];

  return (
    <Table
      rowKey={(vacaciones) => vacaciones.id}
      dataSource={vacaciones}
      columns={columnas}
      pagination={{
        pageSize: tamanioPaginado,
        total: totalRegistros,
        current: paginaActual,
        showTotal: (total) => `Total ${total} registros`,
        onChange: (page, pageSize) => onCambiarPaginacion(page, pageSize),
      }}
    />
  );
};

export default TablaControlVacaciones;
