import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { Container } from "components/Container";
import { Formulario } from "./componentes";
import ModalDocumento from "./componentes/Formulario/ModalDocumento";
import { useHistory, useParams } from "react-router-dom";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
const AgregarDocumentoPage = () => {
    let history=useHistory();
    const { procesoId } = useParams();

  const onClickRetornar=()=>{
     history.push(`/reclutamiento-y-seleccion/procesos/${procesoId}/documentos`);
  }
  return (
    <JotaiProvider>
      <Container icono={<IconoRuta/>} onClickRetornar={onClickRetornar}>
        <Formulario />
        <ModalDocumento/>
      </Container>
    </JotaiProvider>
  );
};

export default AgregarDocumentoPage;

const IconoRuta = () =>{
  //const [dashboard, ] = useAtom(dataColaboradorAtom);
  return(
      <Breadcrumb itemRender>
        <Breadcrumb.Item href="/dashboard">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item href="/reclutamiento-y-seleccion/procesos">
          Procesos de Onboarding
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          Agregar Proceso Onboarding
        </Breadcrumb.Item>
      </Breadcrumb>
    
  )
}