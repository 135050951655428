import React, { useState } from "react";
import { Button, Modal, message, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useAtom, useAtomValue } from "jotai";
import DetalleEnvioService from "services/DetalleEnvioService";
import { useListarTareas } from "../hooks";
import { documentoSeleccionadoAtom, visibleModalSubirDocumentoAtom } from "../store/Store";

const ModalSubirDocumento = () => {

  const [fileDocumento, setFileDocumento] = useState([]);
  const [visible, setVisible] = useAtom(visibleModalSubirDocumentoAtom);
  const documentoSeleccionado = useAtomValue(documentoSeleccionadoAtom);
  const [loading, setLoading] = useState(false);
  const { fetchTareas } = useListarTareas();

  const props = {
    onRemove: (file) => {
      setFileDocumento([]);
      return {
        fileDocumento: [],
      };
    },
    beforeUpload: (file) => {
      setFileDocumento(file);
      return false;
    },
    fileDocumento,
  };

  const onClicksubirDocumento = async () => {
    if (fileDocumento && fileDocumento.name) {
      setLoading(true)
      const formData = new FormData();
      formData.append("file", fileDocumento);
      formData.append("detalleEnvioId", documentoSeleccionado.id)
      await DetalleEnvioService.cargarDocumento(formData);
      setFileDocumento(undefined);
      setLoading(false);
      fetchTareas();
      setVisible(false);
    } else {
      message.error("No ha seleccionado el documento")
    }

  }

  return (
    <Modal
      title="Seleccionar Documento"
      open={visible}
      footer={null}
      onCancel={() => setVisible(false)}
      destroyOnClose={true}
    >
      <div style={{ textAlign: "center" }}>
        <Upload {...props} accept="application/pdf" maxCount={1}>
          <Button icon={<UploadOutlined />}>
            Seleccionar Archivo
          </Button>
        </Upload>
        <br />
        <div style={{ textAlign: "center" }}>
          <Button onClick={onClicksubirDocumento} type="primary" loading={loading}>
            Subir Documento
          </Button>
        </div>
      </div>

    </Modal>
  )
}
export default ModalSubirDocumento;