import { atom } from "jotai";

export const visibleModalDocumentosAtom = atom(false);
export const tipoDocumentosAtom = atom([]);
export const usuariosAtom = atom([]);
export const filtroResponsableAtom = atom([]);
export const documentosAtom = atom([]);
export const documentoEditadoAtom = atom(false);
export const documentoSeleccionadoAtom = atom([]);
export const filtroDocumentoAtom = atom(undefined);
