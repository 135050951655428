import React from "react";
import { Tag } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { EstadoFirmaColaborador } from "enums/EstadoFirmaColaborador";

const EtiquetaEstadoFirmaColaborador = ({ documento }) => {
    switch (documento.estadoFirmaColaborador) {
        case EstadoFirmaColaborador.NO_APLICA:
            return <Tag style={{ color: '#7f7f7f', marginRight: '0px' }}>NO APLICA</Tag>;

        case EstadoFirmaColaborador.PENDIENTE:
            return <Tag color="gold" className="milegajo-ant-tag">PENDIENTE</Tag>;

        case EstadoFirmaColaborador.FIRMADO:
            return (
                <Tag color="green" className="milegajo-ant-tag pointer">
                    <EditOutlined /> FIRMADO
                </Tag>
            );

        default:
            return <Tag color="gold" className="milegajo-ant-tag">PENDIENTE</Tag>;
    }

}
export default EtiquetaEstadoFirmaColaborador;