import React, { useContext, useState,useEffect } from "react";
import { Space, Form, Input, Button, Checkbox, TreeSelect,Row,Col,Select } from "antd";
import { CheckOutlined,FolderTwoTone,FolderFilled } from "@ant-design/icons";
import { useModalConfirmar } from "store/global"
import { SecurityContext } from "context/SecurityContextProvider";
import * as atoms from "../CrearCarpetasLegajoStore";
import { useAtom } from "jotai";
import { useHistory } from "react-router";
import CarpetaService from "services/CarpetaService";

const CarpetaLegajoRegistro = () =>{
    let history = useHistory();
    const { TreeNode } = TreeSelect;
    const { Option } = Select;

    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const[,setCarpetasLegajos]=useAtom(atoms.carpetasLegajos);
    const [carpetas,setCarpetas]=useState([]);    
    const mostrarModalConfirmacion = useModalConfirmar(state => state.mostrarModal);     

    useEffect(()=>{
        const cargarDatos= async()=>{
            try {
                const _carpetas= await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
               /* for(let i=0; i<_carpetas.length; i++){
                    if (_carpetas[i].nivel>0)
                    {

                      _carpetas[i]={..._carpetas[i],ruta:_carpetas[i-1].ruta+"/"+_carpetas[i].descripcion}
                    }else{
                      _carpetas[i]={..._carpetas[i],ruta:"/"+_carpetas[i].descripcion}
                    }
                  }*/
                setCarpetas(_carpetas);                
            } catch (error) {
                console.error(error);
            }

        }

        cargarDatos();        

    },[]);

    const ordenarNodo1=async(carpeta)=>{
        if (carpeta.carpetaPadreId===undefined){
            carpeta.carpetaPadreId="";              

        }
   }   

    const onClickBtnCrearCarpeta=async(carpeta)=>{    

        const rutaTemp = carpetas.find(item=>item.id==carpeta.carpetaPadreId)   
        let rutaIni 
        if (rutaTemp != undefined)
        {
            rutaIni=  rutaTemp.ruta+"/"+carpeta.descripcion
        }else {rutaIni = "/"+carpeta.descripcion}


       ordenarNodo1(carpeta);
       console.log("carpeta",carpeta);      
        setCarpetasLegajos({
            ...carpeta,
            empresaId:getUsuarioAutenticado().empresaSeleccionada.id,
            organizacionId:getUsuarioAutenticado().organizacionId,
            ruta: rutaIni,
            usuarioCreacion:{
                id:getUsuarioAutenticado().id,
                nombreCompleto:getUsuarioAutenticado().nombreCompleto,
            },
            
            
        });
        mostrarModalConfirmacion();

    }
    
    
    return(
        <Form
            layout="vertical"           
            //size="middle"
            onFinish={onClickBtnCrearCarpeta}
        >
            <Form.Item
                label="Nombre"
                name="descripcion"
                rules={[
                    {
                        required: true,
                        message: "El campo nombre es obligatorio.",
                    },
                ]}   
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Ruta"
                name="carpetaPadreId"
            >              
                
                <Select                  
                    optionLabelProp="label"
                    placeholder="Seleccione la carpeta"

                >               
                        {carpetas.length > 0 &&
                        carpetas.map((carpeta) => {
                            return (
                            <Option key={carpeta.id}
                                    value={carpeta.id}
                                    label={
                                         <div>
                                             <FolderTwoTone 
                                                   style={{fontSize:'20px',color:'#F7D358' }} 
                                                   twoToneColor='#F7D358' 
                                            /> {carpeta.ruta}
                                         </div>}
                             >
                                <Space size={carpeta.nivel * 10}>
                                    <span></span>
                                    <span>
                                        
                                        {carpeta.nivel===0?(
                                                      <FolderFilled 
                                                        style={{fontSize:'20px',color:'#F7D358' }}                                                                                     
                                                      /> 
                                                      ):
                                                      (
                                                          <FolderTwoTone
                                                          style={{fontSize:'18px',color:'#F7D358' }}
                                                          twoToneColor='#F7D358'                              
                                                        /> 

                                                      )
                                                  } 
                                                 {" "}{carpeta.descripcion}
                                    </span>
                                </Space>
                            </Option>
                            );
                        })}
              </Select>
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }} >
                <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    htmlType="submit"
                >
                    Crear Carpeta
                </Button>
            </Form.Item>

        </Form>
    )

}
export default CarpetaLegajoRegistro;