import create from 'zustand'
import { DocumentoGenericoService } from "services/DocumentoGenericoService";

const useMisDocumentosStore = create((set, get) => ({
    loading: false,
    documentos: [],
    buscarDocumentos: async (filtros) => {
        try {
            set({ loading: true });
            const documentos = await DocumentoGenericoService.listar({
                ...filtros,
                fechaEnvioInicio: filtros.fechaEnvioInicio.format("DD/MM/YYYY"),
                fechaEnvioFin: filtros.fechaEnvioFin.format("DD/MM/YYYY")
            });
            return documentos;
        } catch (error) {
            console.error(error);
        } finally {
            set({ loading: false })
        }
    },
    setDocumentos: (documentos) => {
        set({ documentos: documentos });
    },
    getUrlPdf: async (documentoGenericoId) => {
        try {
            set({ loading: true });
            const respuesta = await DocumentoGenericoService.obtenerUrlPdf(documentoGenericoId);
            return respuesta.urlDocumento;
        } catch (error) {
            console.error(error);
        } finally {
            set({ loading: false });
        }
    }


}));

export default useMisDocumentosStore;