import React, {useContext, useState} from "react";
import { useEffect } from "react";
import {
    FileTextOutlined, SoundOutlined, FileDoneOutlined, CaretDownOutlined, PlusOutlined
  } from "@ant-design/icons";
import { Menu, Button, Dropdown, Space, Form, DatePicker, Select, Input, Row, Col } from "antd";
import { useHistory } from "react-router";
import ColaboradorService from "services/ColaboradorService";
import SeccionService from "services/SeccionService";
import { OnboardingColaboradorService } from "services/OnboardingColaboradorService";
import { PlantillaEnvioDocumentoService } from "services/PlantillaEnvioDocumentoService";
import { SecurityContext } from "context/SecurityContextProvider";
import { documentosOnboardingAtom, filtroColaboradorAtom, filtroFechaFinAtom, filtroFechaInicioAtom, 
  loadingAtom,totalEnviosAtom,paginaActualAtom,filtroEstadoColaboradorAtom,
  filtroProyectoAtom, filtroReclutadorAtom, filtroProcesoAtom } from "../../SeguimientoOnboardingStore";
import { useAtom } from "jotai";


const FormularioFiltro = () => {
    let history = useHistory();
    const [formFiltro] = Form.useForm();
    const [colaboradores, setColaboradores] = useState(undefined);
    const [reclutadores, setReclutadores] = useState(undefined);
    const [, setDocumentosOnboarding] = useAtom(documentosOnboardingAtom)
    const [, setFiltroColaborador] = useAtom(filtroColaboradorAtom)
    const [, setFiltroInicio] = useAtom(filtroFechaInicioAtom)
    const [, setFiltroFin] = useAtom(filtroFechaFinAtom)
    const [, setLoading] = useAtom(loadingAtom);
    const [, setTotalEnvios] = useAtom(totalEnviosAtom);
    const [, setPaginActual] = useAtom(paginaActualAtom);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [proyectos, setProyectos] = useState(undefined);
    const [filtroProyecto, setFiltroProyecto] = useAtom(filtroProyectoAtom);
    const [filtroReclutador, setFiltroReclutador] = useAtom(filtroReclutadorAtom);
    const [procesos, setProcesos] = useState(undefined);
    const [filtroProceso, setFiltroProceso] = useAtom(filtroProcesoAtom);
    const [filtroEstadoColaborador, setFiltroEstadoColaborador] = useAtom(filtroEstadoColaboradorAtom);
    const isExplomin =
    getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837"
      ? true
      : false;

    useEffect(() => {
      if(isExplomin){
          const cargarDatos = async () => {
            const respuesta = await SeccionService.listarPorNombreSede(getUsuarioAutenticado().empresaSeleccionada.id, "PROYECTOS");
            const agregarPrincipal = {id: '651e25d758909a28d6141f56', nombre: 'PRINCIPAL'};

            const proyectosActualizados = [...respuesta, agregarPrincipal];

            setProyectos(proyectosActualizados);

            const plantillas = await PlantillaEnvioDocumentoService.listar(getUsuarioAutenticado().login);
            setProcesos(plantillas);
        };
        cargarDatos();
      }
    }, []);

    const onSearchColaboradores = async (value) => {
        if (value === "") {
          setColaboradores([]);
          return;
        }
        const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
          filtro: value.trim(),
        });
        setColaboradores(respuesta.colaboradores);
      };
    
      const onSelectColaborador = (value,value2) => {
        if(value!==null){
            formFiltro.setFieldsValue({ colaborador: { dni: value2.key } });
          }
            setColaboradores([]);
      };
      
      const onClickFiltrar = async () => {
        setLoading(true)
        const _documentos = await OnboardingColaboradorService.listarEnvioOnboarding(formFiltro.getFieldValue(["colaborador", "dni"]),
                                                                                    formFiltro.getFieldValue("fechaInicio")?.format("DD/MM/YYYY"),
                                                                                    formFiltro.getFieldValue("fechaFin")?.format("DD/MM/YYYY"),
                                                                                    formFiltro.getFieldValue("proyecto"),
                                                                                    formFiltro.getFieldValue(["reclutador", "dni"]),
                                                                                    formFiltro.getFieldValue("proceso"),
                                                                                    formFiltro.getFieldValue("estadoColaborador"),
                                                                                    getUsuarioAutenticado().login,
                                                                                    0);
        setDocumentosOnboarding(_documentos.envios);
        setFiltroColaborador(formFiltro.getFieldValue(["colaborador", "dni"]));
        setFiltroInicio(formFiltro.getFieldValue("fechaInicio")?.format("DD/MM/YYYY"));
        setFiltroFin(formFiltro.getFieldValue("fechaFin")?.format("DD/MM/YYYY"));
        setFiltroProyecto(formFiltro.getFieldValue("proyecto"));
        setFiltroReclutador(formFiltro.getFieldValue(["reclutador", "dni"]));
        setFiltroProceso(formFiltro.getFieldValue("proceso"));
        setFiltroEstadoColaborador(formFiltro.getFieldValue("estadoColaborador"));
        setLoading(false);
        setTotalEnvios(_documentos.totalEnvios);
        setPaginActual(1);
    };

    const onSearchReclutadores = async (value) => {
      if (value === "") {
        setReclutadores([]);
        return;
      }
      const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
        filtro: value.trim(),
      });
      setReclutadores(respuesta.colaboradores);
    };
  
    const onSelectReclutador = (value,value2) => {
      if(value!==null){
          formFiltro.setFieldsValue({ reclutador: { dni: value2.key } });
        }
          setReclutadores([]);
    };

    return(
      <div>
        {!isExplomin && (
            <Form
            layout="inline"
              form={formFiltro}>
              <Form.Item
                    label="Colaborador"
                    name={["colaborador", "nombreCompleto"]}
                  >
                  <Select
                      style={{ width: "250px" }}
                      allowClear
                      showSearch
                      placeholder="Ingrese el nombre del colaborador"
                      onSearch={onSearchColaboradores}
                      onClear={()=>formFiltro.setFieldsValue({ colaborador: { dni: null } })}
                      //onChange={onChangeSelectColaboradores}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onSelect={onSelectColaborador}
                    >
                      {colaboradores !== undefined &&
                        colaboradores?.map((colaborador) => (
                          <Select.Option key={colaborador.numeroDocumentoIdentidad} value={colaborador.nombreCompleto}>
                            {colaborador.nombreCompleto}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name={["colaborador", "dni"]} noStyle>
                            <Input type="hidden" />
                  </Form.Item>
            <Form.Item label="Fecha Inicio" name="fechaInicio">
                  <DatePicker
                    allowClear={true}
                    //onChange={onClickDate}
                    format={"DD/MM/YYYY"}
                    //picker="month"
                    style={{ width: 200 }}
                  />
            </Form.Item>
            <Form.Item label="Fecha Fin" name="fechaFin">
                  <DatePicker
                    allowClear={true}
                    //onChange={onClickDate}
                    format={"DD/MM/YYYY"}
                    //picker="month"
                    style={{ width: 200 }}
                  />
            </Form.Item>
            <Form.Item>
                <Button onClick={onClickFiltrar}>
                    Filtrar
                </Button>
            </Form.Item>
        </Form>
      )}

      {isExplomin && (
        <Form
        layout="vertical"
          form={formFiltro}>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                      label="Colaborador"
                      name={["colaborador", "nombreCompleto"]}
                    >
                    <Select
                        style={{  width: "100%"}}
                        allowClear
                        showSearch
                        placeholder="Ingrese el nombre del colaborador"
                        onSearch={onSearchColaboradores}
                        onClear={()=>formFiltro.setFieldsValue({ colaborador: { dni: null } })}
                        //onChange={onChangeSelectColaboradores}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onSelect={onSelectColaborador}
                      >
                        {colaboradores !== undefined &&
                          colaboradores?.map((colaborador) => (
                            <Select.Option key={colaborador.numeroDocumentoIdentidad} value={colaborador.nombreCompleto}>
                              {colaborador.nombreCompleto}
                            </Select.Option>
                          ))}
                      </Select>
                  </Form.Item>
                  <Form.Item name={["colaborador", "dni"]} noStyle>
                            <Input type="hidden" />
                  </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Proyecto" name="proyecto">
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Ingrese el nombre del proyecto"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onClear={() => setFiltroProyecto(undefined)}
                  >
                    {proyectos !== undefined &&
                      proyectos?.map((proyecto) => (
                        <Select.Option key={proyecto.id} value={proyecto.nombre}>
                          {proyecto.nombre}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                  <Form.Item
                      label="Reclutador"
                      name={["colaborador", "reclutador"]}
                    >
                    <Select
                        style={{  width: "100%"}}
                        allowClear
                        showSearch
                        placeholder="Ingrese el nombre del reclutador"
                        onSearch={onSearchReclutadores}
                        onClear={()=>formFiltro.setFieldsValue({ reclutador: { dni: null } })}
                        //onChange={onChangeSelectColaboradores}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onSelect={onSelectReclutador}
                      >
                        {reclutadores !== undefined &&
                          reclutadores?.map((colaborador) => (
                            <Select.Option key={colaborador.numeroDocumentoIdentidad} value={colaborador.nombreCompleto}>
                              {colaborador.nombreCompleto}
                            </Select.Option>
                          ))}
                      </Select>
                  </Form.Item>
                  <Form.Item name={["reclutador", "dni"]} noStyle>
                            <Input type="hidden" />
                  </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="Proceso" name="proceso">
                  <Select
                    style={{ width: "100%" }}
                    placeholder="Ingrese el nombre del proceso"
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    onClear={() => setFiltroProyecto(undefined)}
                  >
                    {procesos !== undefined &&
                      procesos?.map((proceso) => (
                        <Select.Option key={proceso.id} value={proceso.nombre}>
                          {proceso.nombre}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
               <Col span={4}>
                <Form.Item label="Fecha Inicio" name="fechaInicio">
                  <DatePicker
                    allowClear={true}
                    //onChange={onClickDate}
                    format={"DD/MM/YYYY"}
                    //picker="month"
                    style={{ width: 200 }}
                  />
                </Form.Item>
               </Col>
               <Col span={4}>
                <Form.Item label="Fecha Fin" name="fechaFin">
                    <DatePicker
                      allowClear={true}
                      //onChange={onClickDate}
                      format={"DD/MM/YYYY"}
                      //picker="month"
                      style={{ width: 200 }}
                    />
                  </Form.Item>
               </Col>
               <Col span={6}>
                  <Form.Item label="Estado Colaborador" name="estadoColaborador">
                    <Select
                      allowClear
                      placeholder="Seleccion el estado"
                      // onClear={() => setFiltroEstadoColaborador(undefined)}
                    >
                      <Select.Option value={"ACTIVO"}>ACTIVO</Select.Option>
                      <Select.Option value={"CESADO"}>CESADO</Select.Option>
                      <Select.Option value={"ONBOARDING"}>ONBOARDING</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
               <Col span={2}>
                <Form.Item label=" ">
                  <Button type="primary" onClick={onClickFiltrar}>
                      Filtrar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
        </Form>
      )}
      </div>
    );

}

export default FormularioFiltro;