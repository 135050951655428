import React, { useEffect, useState, useContext } from "react";
import TablaListar from "../TablaListar/TablaListar";

function Listar() {
    return (
        <TablaListar />
    )
}
export default Listar;

