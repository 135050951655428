import { atom } from "jotai";

export const tipoDocumentosAtom = atom([]);
export const detalleEnvioSeguimientoAtom = atom([]);
export const detalleEnvioSeleccionadoAtom = atom();
export const visibleModalActualizarCorreoAtom = atom();
export const documentoNombreAtom = atom();
export const loadingAtom = atom();
export const documentoEnvioAtom=atom();
export const documentosSeleccionadoAtom = atom([]);

export const urlAtom = atom();
export const visibleAtom = atom();

export const codigosOpcionesAtom = atom();
export const datosPaginacionAtom = atom({
    paginaActual: 1,
    registrosPorPagina: 30,
  });