import { useEffect,useState,useContext } from "react";
import CargoService from "services/CargoService";
import {cargosAtom} from "../CrearPerfilSolicitudPersonalStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";

const useListarCargo = ()=>{
    const [cargos,setCargos] = useAtom(cargosAtom);
    const {getUsuarioAutenticado} = useContext(SecurityContext);
    useEffect(()=>{
        const cargarDatos = async()=>{
         const cargosListados= await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         setCargos(cargosListados);
        }
        cargarDatos()
    },[])
    return{cargos};

}
export default useListarCargo;