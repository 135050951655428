import React from "react";
import {Table,Card} from "antd"
import { useListarEntrevistas } from "../../hooks";
import { useEffect } from "react";
import FiltroEntrevistas from "./FiltroEntrevistas";
import { EstadoEntrevista } from "enums/reclutamiento-y-seleccion/EstadoEntrevista";
const TablaSeguimientoEntrevistas=()=>{
    const {entrevistas} = useListarEntrevistas();
    
    const columnasEntrevistas=[
        
        {
            title:"Hora Entrevista",
            dataIndex:"horaEntrevista",
            align:"center",
            
        },
        {
            title:"Entrevistador",
            dataIndex:["entrevistador","nombreCompleto"],
            align:"center",
        },
        {
            title:"Puesto",
            dataIndex:["puesto","nombre"],
            align:"center",
        },
        {
            title:"Postulante",
            dataIndex:["postulante","nombreCompleto"],
            align:"center",
        },
        {
            title:"Estado",            
            align:"center",
            render:(fila)=>(fila.estado===EstadoEntrevista.NO_ASISTIO ?"NO ASISTIO":fila.estado)
        },
       
    ]
    return(
      
        <React.Fragment>
       
        <FiltroEntrevistas/> 
       { entrevistas.length>0 &&(
           
             entrevistas.map((entrevista)=>
                    {
                      return(
                        Object.entries(entrevista).map(([key,value])=>{
                        
                        return(                          
                            <React.Fragment>
                                <Card title={key}>
                                    <Table
                                        rowKey={(item)=>item.id}
                                        dataSource={value}
                                        columns={columnasEntrevistas}
                                        pagination={false}
                                />
                                </Card>
                                <br/>
                            </React.Fragment>
                            
                        )
                        
                      })       
                    
                      )
                   }
        
            )
         )   }
         </React.Fragment>
     )
     
}
export default TablaSeguimientoEntrevistas;