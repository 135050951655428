import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { Container } from "components/Container";
import {
  BotonesExtra,
  FiltroTablaControlVacaciones,
  ModalDetalleVacaciones,
  TablaControlVacaciones,
} from "./componentes";
import { DrawerCargarHistoricoVacaciones } from "./componentes/DrawerCargarHistoricoVacaciones";

const ControlVacacionalPage = () => {
  return (
    <JotaiProvider>
      <Container titulo="Control Vacaciones" botonExtra={<BotonesExtra />}>
        <FiltroTablaControlVacaciones />
        <TablaControlVacaciones />
        <ModalDetalleVacaciones />
        <DrawerCargarHistoricoVacaciones />
      </Container>
    </JotaiProvider>
  );
};

export default ControlVacacionalPage;
