import ListarFirmaHolografa from "pages/configuracion/firmas-holografas/listar/ListarFirmaHolografaPage";
import { BaseService } from "./BaseService";

export const FirmaHolografaService={
    async insertar(firmaHolografa){        
    const respuesta = await BaseService.post("/firmaHolografa", firmaHolografa);
    return respuesta.data;
    },

    async listar(usuarioId,empresaId){
        const respuesta=await BaseService.get("/firmaHolografa",{
          params:{
            usuarioId:usuarioId,
            empresaId:empresaId,
          },
        });
        return respuesta.data;
    },
    async getUrlDocumento(firmaHolografaId,empresaId ) {
        const respuesta = await BaseService.get(`/firmaHolografa/id/${firmaHolografaId}/empresa/${empresaId}`);
        return respuesta.data;
      },
    async eliminarPorId(firmaHolografaId)  {
        const respuesta=await BaseService.delete("/firmaHolografa",{params: {
            firmaHolografaId: firmaHolografaId,
          },});
        return respuesta.data;
    }
    

};