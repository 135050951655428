import React, { useEffect } from "react";
import { Table, Tag, Button } from "antd";
import * as atoms from "../ListarReportesStore";
import { useAtom } from "jotai";
import ReporteService from "services/ReporteService";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";

const TablaReportes = () => {
  const [reportes, setReportes] = useAtom(atoms.reportesAtom);
  useEffect(() => {
    const cargarDatos = async () => {
      const respuesta = await ReporteService.listar();
      setReportes(respuesta);
    };
    cargarDatos();
  }, []);

  const onClickDescargar = async (reporteId) => {
    const respuesta = await ReporteService.obtenerUrl(reporteId);
    saveAs(respuesta.urlDocumento);
  };

  const columnas = [
    {
      title: "Tipo",
      dataIndex: "tipo",
      key: "tipo",
      align: "center",
    },
    {
      title: "Periodo Inicio",
      dataIndex: "periodoInicio",
      key: "periodoInicio",
      align: "center",
      //render:(fila)=>(new Date(fila.periodoInicio).toLocaleDateString())
    },
    {
      title: "Periodo Fin",
      dataIndex: "periodoFin",
      key: "periodoFin",
      align: "center",
      //render:(fila)=>(new Date(fila.periodoFin).toLocaleDateString())
    },
    {
      title: "Solicitante",
      dataIndex: "solicitanteNombre",
      key: "solicitante",
      align: "center",
    },
    {
      title: "Fecha Creación",
      dataIndex: "fechaCreacion",
      key: "fechaCreacion",
      align: "center",
      //render: (fila) => new Date(fila.fechaCreacion).toLocaleDateString(),
    },
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      align: "center",
      render: (text, fila) => <TagEstado estado={fila.estado} onClickDescargar={() => onClickDescargar(fila.id)} />,
    },
    // {
    //     title:"Acciones",
    //     key:"acciones",
    //     align:"center",
    //     render:(fila)=>(<Button type="link">Editar</Button>)

    // },
  ];
  return <Table rowKey={(reporte) => reporte.id} columns={columnas} dataSource={reportes} />;
};

const TagEstado = ({ estado, onClickDescargar }) => {
  if (estado === "PENDIENTE") {
    return (
      <Tag color="blue" style={{ marginRight: "0px" }}>
        PENDIENTE
      </Tag>
    );
  } else if (estado === "PROCESANDO") {
    return (
      <Tag color="green" style={{ marginRight: "0px" }}>
        PROCESANDO ...
      </Tag>
    );
  } else if (estado === "FINALIZADO") {
    return (
      <Button type="link" onClick={onClickDescargar}>
        Descargar
      </Button>
    );
  } else if (estado === "ERROR") {
    return (
      <Tag color="red" style={{ marginRight: "0px" }}>
        ERROR
      </Tag>
    );
  }
};

export default TablaReportes;
