import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { Container } from "components/Container";
import { useHistory } from "react-router-dom";
import { PerfilEditar } from "./componentes";
import { DrawerCampos } from "./componentes";

const EditarPerfilSolicitudPersonalPage = () => {
  let history = useHistory();

  const onClickRetornar = () => {
    history.push("/reclutamiento-y-seleccion/solicitud-personal/perfiles");
  };

  return (
    <JotaiProvider>
      <Container titulo="Editar Perfil" onClickRetornar={onClickRetornar}>
        <PerfilEditar />
        <DrawerCampos />
      </Container>
    </JotaiProvider>
  );
};
export default EditarPerfilSolicitudPersonalPage;
