import { ControlVacacionalService } from "services";

const useCalcularVacaciones = () => {
  const calcularVacaciones = async () => {
    const respuesta = await ControlVacacionalService.calcular({});
    console.log("respuesta", respuesta);
  };

  return {
    calcularVacaciones,
  };
};

export default useCalcularVacaciones;
