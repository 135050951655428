import { BaseService } from "./BaseService";

export const PlantillaContratoService = {
    async listar(empresaId) {
        const respuesta = await BaseService.get("/plantillaContrato", {
            params: {
                empresaId: empresaId,
            },
        });
        return respuesta.data;
    },

    async insertar(plantilla) {
        const respuesta = await BaseService.post("/plantillaContrato", plantilla);
        return respuesta.data;
    },

    async actualizar(plantilla) {
        const respuesta = await BaseService.put("/plantillaContrato", plantilla);
        return respuesta.data;
    },

    async eliminar(id) {
        const respuesta = await BaseService.delete(`/plantillaContrato/${id}`);
        return respuesta.data;
    },

    async buscarPorId(id) {
        const respuesta = await BaseService.get(`/plantillaContrato/${id}`);
        return respuesta.data;
    },

    async listarUsuariosFirmantes(empresaId) {
        const respuesta = await BaseService.get("/plantillaContrato/listarUsuariosFirmantes", {
            params: { empresaId: empresaId },
        });
        return respuesta.data;
    },

    async insertarNuevo(plantilla) {
        const respuesta = await BaseService.post("/insertarPlantillaContrato", plantilla);
        return respuesta.data;
    },

    async listarNuevo(empresaId) {
        const respuesta = await BaseService.get("/listarPlantillaContrato", {
            params: { empresaId: empresaId },
        });
        return respuesta.data;
    },

    async buscarPorIdNuevo(id) {
        const respuesta = await BaseService.get(`/buscarPlantillaContrato/${id}`);
        return respuesta.data;
    },
    async actualizarNuevo(plantilla) {
        const respuesta = await BaseService.put("/actualizarPlantillaContrato", plantilla);
        return respuesta.data;
    },

    async validarNuevo(plantilla) {
        const respuesta = await BaseService.post("/validarPlantillaContrato", plantilla);
        return respuesta.data;
    },

    async obtenerVariables(plantillaContratoId) {
        const respuesta = await BaseService.get(`/plantillaContrato/${plantillaContratoId}/obtener-variables`);
        return respuesta.data;
    },
};
// export default PlantillaContratoService;
