import React, { useState, useEffect,useContext } from "react";

import { ContainerCrear } from "../../../components/ContainerCrear";
import { Form, Input, Row, Col, Button, Space, notification,Select } from "antd";

import { CheckOutlined } from "@ant-design/icons";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import SedeService from "../../../services/SedeService";
import { useHistory, useParams } from "react-router-dom";
import {CampoColaborador} from "enums/CampoColaborador";
import SeccionService from "../../../services/SeccionService";
import SubsedeService from "../../../services/SubsedeService";
import PerfilService from "../../../services/PerfilService";
import TipoPlanillaService from "../../../services/TipoPlanillaService";
import CargoService from "services/CargoService";

export function SedeEditarPage() {
  const [frmEditar] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [dependenciaSede,setDependenciaSede] = useState();  
  const [listaCampos,setListaCampos]=useState([]);
  const [perfiles,setPerfiles]=useState([]);
  const [secciones,setSecciones]=useState([]);
  const [subsedes,setSubsedes]=useState([]);
  const [tipoPlanillas,setTipoPlanillas]=useState([]);
  const [cargos,setCargos]=useState([]);
  const [camposColaborador,setCamposColaborador]=useState([]);
  
  const { Option } = Select;
  const { getUsuarioAutenticado } = useContext(SecurityContext);

  let history = useHistory();
  let { idSede } = useParams();

  useEffect(() => {
    async function cargarDatos() {  
      const sede = await SedeService.buscarPorId(idSede);
      frmEditar.setFieldsValue({
        id: sede.id,
       // codigo: sede.codigo,        
        nombre: sede.nombre,
        empresaId: sede.empresaId,
      }); 
      var listaCampos=[],dependencia;
      var perfilId, cargoId, seccionId,tipoPlanillaId,subsedeId;
      const empresa = getUsuarioAutenticado().empresaSeleccionada.id;
      const campos_colaborador=getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
      setCamposColaborador(campos_colaborador); 
      
      const campo_sede= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.SEDE);

      const sedeDependencia=campo_sede.campoDependencia;   
      if(sedeDependencia!==null){
        if(sedeDependencia===CampoColaborador.PERFIL){                                                                 
              perfilId=sede.perfilId;
        }
        else if(sedeDependencia===CampoColaborador.CARGO){
              cargoId=sede.cargoId;
        }
        else if(sedeDependencia===CampoColaborador.SECCION){
              seccionId=sede.seccionId;                    
        }
        else if(sedeDependencia===CampoColaborador.PLANILLA){
              tipoPlanillaId=sede.tipoPlanillaId;
        }
        else if(sedeDependencia===CampoColaborador.SUBSEDE){
              subsedeId=sede.subsedeId;
        }    

        setDependenciaSede(sedeDependencia);
        dependencia=sedeDependencia;
        listaCampos.push(dependencia);

        while(dependencia!==null){
          var campo= campos_colaborador.find(campoColaborador=>campoColaborador.campo===dependencia);
          dependencia=campo.campoDependencia;
          if(dependencia!==null){
            listaCampos.push(dependencia);
                if(dependencia===CampoColaborador.PERFIL){                                                                 
                     perfilId=sede.perfilId;                         
                }
                else if(dependencia===CampoColaborador.CARGO){
                      cargoId=sede.cargoId;
                }
                else if(dependencia===CampoColaborador.SECCION){
                      seccionId=sede.seccionId;                    
                }
                else if(dependencia===CampoColaborador.PLANILLA){
                      tipoPlanillaId=sede.tipoPlanillaId;
                }
                else if(dependencia===CampoColaborador.SUBSEDE){                         
                      subsedeId=sede.subsedeId;
                }     
          }
        
        }
        setListaCampos(listaCampos.reverse());                 
        
    }
    else{
      setListaCampos(listaCampos);
      setDependenciaSede(CampoColaborador.SINCAMPO);
    }
    if(listaCampos.length>0){
                      
      for(let i=0 ; i < listaCampos.length ; i++){
        
        if (listaCampos[i]===CampoColaborador.CARGO){ 
            const campo_cargo= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.CARGO);
            const cargoDependencia=campo_cargo.campoDependencia;             
            const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
            var _cargos;
            if(cargoDependencia===CampoColaborador.PERFIL){
                _cargos = cargos.filter(cargo=>cargo.perfilId===perfilId)
            } 
            else if(cargoDependencia===CampoColaborador.SECCION){
                _cargos = cargos.filter(cargo=>cargo.seccionlId===seccionId)    

            }    
            else if(cargoDependencia===CampoColaborador.PLANILLA){
                _cargos = cargos.filter(cargo=>cargo.tipoPlanillaId===tipoPlanillaId)
            } 
            else if(cargoDependencia===CampoColaborador.SUBSEDE){
                _cargos= cargos.filter(cargo=>cargo.subsedeId===subsedeId)
            }  
            else{
                _cargos=cargos;
            }                      
            setCargos(_cargos);  
            frmEditar.setFieldsValue({ cargoId:cargoId})
                
            
          }    
        else if(listaCampos[i]===CampoColaborador.PERFIL){
            
            const campo_perfil= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.PERFIL);
            const perfilDependencia=campo_perfil.campoDependencia;             
            const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
            var _perfiles;
            if(perfilDependencia===CampoColaborador.CARGO){
                _perfiles = perfiles.filter(perfil=>perfil.cargoId===cargoId)
            } 
            else if(perfilDependencia===CampoColaborador.SECCION){
                _perfiles = perfiles.filter(perfil=>perfil.seccionlId===seccionId)     

            }    
            else if(perfilDependencia===CampoColaborador.PLANILLA){
                _perfiles = perfiles.filter(perfil=>perfil.tipoPlanillaId===tipoPlanillaId)

            } 
            else if(perfilDependencia===CampoColaborador.SUBSEDE){
                _perfiles = perfiles.filter(perfil=>perfil.subsedeId===subsedeId)

            }  
            else{
                _perfiles=perfiles;
            }                      
            setPerfiles(_perfiles); 
            
            frmEditar.setFieldsValue({ perfilId:perfilId})
        }
        else if(listaCampos[i]===CampoColaborador.SECCION){
            
            const campo_seccion= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.SECCION);
            const seccionDependencia=campo_seccion.campoDependencia;             
            const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
            var _secciones;
            if(seccionDependencia===CampoColaborador.CARGO){
                _secciones = secciones.filter(seccion=>seccion.cargoId===cargoId)
            } 
            else if(seccionDependencia===CampoColaborador.PERFIL){
                _secciones = secciones.filter(seccion=>seccion.perfilId===perfilId)     

            }    
            else if(seccionDependencia===CampoColaborador.PLANILLA){
                _secciones = secciones.filter(seccion=>seccion.tipoPlanillaId===tipoPlanillaId)

            } 
            else if(seccionDependencia===CampoColaborador.SUBSEDE){
                _secciones = secciones.filter(seccion=>seccion.subsedeId===subsedeId)
            }  
            else{
                _secciones=secciones;
            }                      
            setSecciones(_secciones); 
            
            frmEditar.setFieldsValue({ seccionId:seccionId})
        }
        else if(listaCampos[i]===CampoColaborador.PLANILLA){
            
            const campo_planilla= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.PLANILLA);
            const planillaDependencia=campo_planilla.campoDependencia;             
            const planillas = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
            var _planillas;
            if(planillaDependencia===CampoColaborador.CARGO){
                _planillas = planillas.filter(planilla=>planilla.cargoId===cargoId)
            } 
            else if(planillaDependencia===CampoColaborador.PERFIL){
                _planillas = planillas.filter(planilla=>planilla.perfilId===perfilId)     

            }    
            else if(planillaDependencia===CampoColaborador.SECCION){
                _planillas = planillas.filter(planilla=>planilla.seccionaId===seccionId)

            } 
            else if(planillaDependencia===CampoColaborador.SUBSEDE){
                _planillas = planillas.filter(planilla=>planilla.subsedeId===subsedeId)
            }  
            else{
                _planillas=planillas;
            }                      
            setTipoPlanillas(_planillas); 
            
            frmEditar.setFieldsValue({ tipoPlanillaId:tipoPlanillaId})
        }
        else if(listaCampos[i]===CampoColaborador.SUBSEDE){
            
            const campo_subsede= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.SUBSEDE);
            const subsedeDependencia=campo_subsede.campoDependencia;             
            const subsedes= await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
            var _subsedes;
            if(subsedeDependencia===CampoColaborador.CARGO){
                _subsedes = subsedes.filter(subsede=>subsede.cargoId===cargoId)
            } 
            else if(subsedeDependencia===CampoColaborador.PERFIL){
                _subsedes = subsedes.filter(subsede=>subsede.perfilId===perfilId)     

            }    
            else if(subsedeDependencia===CampoColaborador.SECCION){
                _subsedes = subsedes.filter(subsede=>subsede.seccionaId===seccionId)

            } 
            else if(subsedeDependencia===CampoColaborador.PLANILLA){
                _subsedes = subsedes.filter(subsede=>subsede.tipoPlanillaId===tipoPlanillaId)
            }  
            else{
                _subsedes=subsedes;
            }                      
            setSubsedes(_subsedes); 
            
            frmEditar.setFieldsValue({ subsedeId:subsedeId})
        }
      }
    }      
          
    }
    cargarDatos();
  }, []);
  async function handleOnFinish(formulario) {
    try {
      setLoading(true);
      formulario.dependencia=dependenciaSede;
      await SedeService.actualizar(formulario);
      notification.success({
        message: "Operación exitosa",
        description: "Se actualizó la sede correctamente.",
      });
      setLoading(false);
      history.push("/sede");
    } catch (error) {
      console.error(error);
      notification.error({
        message:"No se puede actualizar la Sede",
        description: error.response.data.mensaje,
    });
      setLoading(false);
    }
  }

  function handleOnClickCancelar() {
    history.push("/sede");
  }
  const handleOnchangePerfil = async(value,option)=>{

    let listaCampos= camposColaborador.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.PERFIL);

    for(let i=0 ; i < listaCampos.length ; i++){
       if (listaCampos[i].campo===CampoColaborador.SECCION){         
         const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _secciones = secciones.filter(seccion=>seccion.perfilId===value)
         setSecciones(_secciones);  
         frmEditar.setFieldsValue({ seccionId:""})
             
         
       }         
       else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
         const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _subsedes = subsedes.filter(subsede=>subsede.perfilId===value);
         setSubsedes(_subsedes);
         frmEditar.setFieldsValue({ subsedeId:""})
        
       }
       else if(listaCampos[i].campo===CampoColaborador.CARGO){
         const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _cargos = cargos.filter(cargo=>cargo.perfilId===value);
         setCargos(_cargos);
         frmEditar.setFieldsValue({ cargoId:""})
         
       }
       else if(listaCampos[i].campo===CampoColaborador.PLANILLA){
         const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _tipoPlanilla = tipoPlanilla.filter(planilla=>planilla.perfilId===value);
         setTipoPlanillas(_tipoPlanilla);
         frmEditar.setFieldsValue({ tipoPlanillaId:""})
         
       }
      }   
  }
  const handleOnchangeSeccion = async(value,option)=>{
    let listaCampos= camposColaborador.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.SECCION);

    for(let i=0 ; i < listaCampos.length ; i++){
       if (listaCampos[i].campo===CampoColaborador.PERFIL){         
         const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _perfiles = perfiles.filter(perfil=>perfil.seccionId===value)
         setPerfiles(_perfiles);  
         frmEditar.setFieldsValue({ perfilId:""})
             
         
       }         
       else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
         const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _subsedes = subsedes.filter(subsede=>subsede.seccionId===value);
         setSubsedes(_subsedes);
         frmEditar.setFieldsValue({ subsedeId:""})
        
       }
       else if(listaCampos[i].campo===CampoColaborador.CARGO){
         const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _cargos = cargos.filter(cargo=>cargo.seccionId===value);
         setCargos(_cargos);
         frmEditar.setFieldsValue({ cargoId:""})
         
       }
       else if(listaCampos[i].campo===CampoColaborador.PLANILLA){
         const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _tipoPlanilla = tipoPlanilla.filter(planilla=>planilla.seccionId===value);
         setTipoPlanillas(_tipoPlanilla);
         frmEditar.setFieldsValue({ tipoPlanillaId:""})
         
       }
      }   
  }
  const handleOnchangeSubsede = async(value,option)=>{
    let listaCampos= camposColaborador.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.SUBSEDE);

    for(let i=0 ; i < listaCampos.length ; i++){
       if (listaCampos[i].campo===CampoColaborador.PERFIL){         
         const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _perfiles = perfiles.filter(perfil=>perfil.subsedeId===value)
         setPerfiles(_perfiles);  
         frmEditar.setFieldsValue({ perfilId:""})
             
         
       }         
       else if(listaCampos[i].campo===CampoColaborador.SECCION){
         const secciones= await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _secciones = secciones.filter(seccion=>seccion.subsedeId===value);
         setSecciones(_secciones);
         frmEditar.setFieldsValue({ seccionId:""})
        
       }
       else if(listaCampos[i].campo===CampoColaborador.CARGO){
         const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _cargos = cargos.filter(cargo=>cargo.subsedeId===value);
         setCargos(_cargos);
         frmEditar.setFieldsValue({ cargoId:""})
         
       }
       else if(listaCampos[i].campo===CampoColaborador.PLANILLA){
         const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _tipoPlanilla = tipoPlanilla.filter(planilla=>planilla.subsedeId===value);
         setTipoPlanillas(_tipoPlanilla);
         frmEditar.setFieldsValue({ tipoPlanillaId:""})
         
       }
      }   
    
  }
  const handleOnchangeTipoPlanilla = async(value,option)=>{
    let listaCampos= camposColaborador.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.PLANILLA);

    for(let i=0 ; i < listaCampos.length ; i++){
       if (listaCampos[i].campo===CampoColaborador.PERFIL){         
         const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _perfiles = perfiles.filter(perfil=>perfil.tipoPlanillaId===value)
         setPerfiles(_perfiles);  
         frmEditar.setFieldsValue({ perfilId:""})
             
         
       }         
       else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
         const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _subsedes = subsedes.filter(subsede=>subsede.tipoPlanillaId===value);
         setSubsedes(_subsedes);
         frmEditar.setFieldsValue({ subsedeId:""})
        
       }
       else if(listaCampos[i].campo===CampoColaborador.CARGO){
         const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _cargos = cargos.filter(cargo=>cargo.tipoPlanillaId===value);
         setCargos(_cargos);
         frmEditar.setFieldsValue({ cargoId:""})
         
       }
       else if(listaCampos[i].campo===CampoColaborador.SECCION){
         const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _secciones =  secciones.filter(seccion=>seccion.tipoPlanillaId===value);
         setSecciones(_secciones);
         frmEditar.setFieldsValue({ seccionId:""})
         
       }
      }   

  }
  const handleOnchangeCargo = async(value,option)=>{
    let listaCampos= camposColaborador.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.CARGO);

    for(let i=0 ; i < listaCampos.length ; i++){
       if (listaCampos[i].campo===CampoColaborador.PERFIL){         
         const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _perfiles = perfiles.filter(perfil=>perfil.cargoId===value)
         setPerfiles(_perfiles);  
         frmEditar.setFieldsValue({ perfilId:""})
             
         
       }         
       else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
         const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _subsedes = subsedes.filter(subsede=>subsede.cargoId===value);
         setSubsedes(_subsedes);
         frmEditar.setFieldsValue({ subsedeId:""})
        
       }
       else if(listaCampos[i].campo===CampoColaborador.PLANILLA){
         const tipoPlanillas=await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _tipoPlanillas = tipoPlanillas.filter(planilla=>planilla.cargoId===value);
         setTipoPlanillas(_tipoPlanillas);
         frmEditar.setFieldsValue({ tipoPlanillaId:""})
         
       }
       else if(listaCampos[i].campo===CampoColaborador.SECCION){
         const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
         const _secciones =  secciones.filter(seccion=>seccion.cargoId===value);
         setSecciones(_secciones);
         frmEditar.setFieldsValue({ seccionId:""})
         
       }
      }   
  }
  return (
    <ContainerCrear
      titulo="Editar Centro de costo"
      descripcion="Formulario para editar los Centros de costo para tu organización."
      colSpan={12}
    >
      <Form
        layout="vertical"
        size="large"
        onFinish={handleOnFinish}
        form={frmEditar}
      >
        <Form.Item name="id" label="id" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="empresaId" noStyle>
          <Input type="hidden" />
        </Form.Item>
        {/* <Row>
          <Col span={24}>
            <Form.Item
              label="Código"
              name="codigo"              
            >
              <Input
                placeholder="Código de la Sede"
                maxLength="50"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row> */}
         {
              listaCampos.length>0 &&(
                listaCampos.map((listaCampo)=>{
                    if (listaCampo===CampoColaborador.PERFIL){   
                      return(
                            <Row>
                                    <Col span={24} >
                                    <Form.Item
                                        label="Perfil"
                                        name="perfilId"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Seleccione Perfil"
                                          },
                                        ]}
                                    >
                                      <Select
                                      placeholder="Seleccione Perfil"
                                      onChange={handleOnchangePerfil}
                                      >
                                      {perfiles.length > 0 &&
                                        perfiles.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                      </Select>

                                    </Form.Item>
                                    </Col>
                          </Row>
                      )       
                      }                                             
                      
                      else if (listaCampo===CampoColaborador.SECCION){
                        return(
                          <Row>
                                    <Col span={24}>
                                    <Form.Item
                                        label="Sección"
                                        name="seccionId"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Seleccione sección"
                                          },
                                        ]}
                                    >
                                      <Select
                                      placeholder="Seleccione sección"
                                      onChange={handleOnchangeSeccion}
                                      >
                                      {secciones.length > 0 &&
                                        secciones.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                        
                                      </Select>

                                    </Form.Item>
                                    </Col>
                          </Row>
                        )
                      }
                      else if (listaCampo===CampoColaborador.SUBSEDE){
                        return(
                          <Row>
                                <Col span={24} >
                                <Form.Item
                                    label="Subsede"
                                    name="subsedeId"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Seleccione Subsede"
                                      },
                                    ]}
                                >
                                  <Select
                                  placeholder="Seleccione Subsede"
                                  onChange={handleOnchangeSubsede}
                                  >
                                  {subsedes.length > 0 &&
                                        subsedes.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                  </Select>

                                </Form.Item>
                                </Col>
                        </Row>
                        )
                      }
                      else if (listaCampo===CampoColaborador.PLANILLA){
                        return(
                          <Row>
                                <Col span={24} >
                                <Form.Item
                                    label="Tipo planilla"
                                    name="tipoPlanillaId"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Seleccione Tipo planilla"
                                      },
                                    ]}
                                >
                                  <Select
                                  placeholder="Seleccione Tipo planilla"
                                  onChange={handleOnchangeTipoPlanilla}
                                  >
                                  {tipoPlanillas.length > 0 &&
                                        tipoPlanillas.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                  </Select>

                                </Form.Item>
                                </Col>
                        </Row>
                        )
                
                      }
                      else if (listaCampo===CampoColaborador.CARGO){
                        return(
                          <Row>
                                <Col span={24}>
                                <Form.Item
                                    label="Cargo"
                                    name="cargoId"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Seleccione Cargo"
                                      },
                                    ]}
                                >
                                  <Select
                                    placeholder="Seleccione Cargo"
                                    onChange={handleOnchangeCargo}
                                  >
                                  {cargos.length > 0 &&
                                        cargos.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                  </Select>

                                </Form.Item>
                                </Col>
                        </Row>
                        )             
                      }                      
                                        
                })
                
              )           
            
          }     
        <Row>
          <Col span={24}>
            <Form.Item
              label="Nombre"
              name="nombre"
              rules={[
                {
                  required: true,
                  message: "Escribe el nombre del centro de costo",
                },
              ]}
            >
              <Input
                placeholder="Nombre del centro de costo"
                maxLength="50"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Space>
            <Col>
              <Form.Item style={{ textAlign: "center" }}>
                <Button type="default" onClick={handleOnClickCancelar}>
                  Cancelar
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  loading={loading}
                  htmlType="submit"
                >
                  Actualizar Centro de costo
                </Button>
              </Form.Item>
            </Col>
          </Space>
        </Row>
      </Form>
    </ContainerCrear>
  );
}
