import create from 'zustand'
import { DocumentoGenericoService } from "services/DocumentoGenericoService"

const useModalClaveCertificadoStore = create((set, get) => ({
    loading: false,
    mensajeError: '',
    visibleModalClaveCertificado: false,
    setMensajeError: (mensaje) => {
        set({ mensajeError: mensaje });
    },
    mostrarModalClaveCertificado: () => {
        set({ visibleModalClaveCertificado: true })
    },
    cerrarModalClaveCertificado: () => {
        set({ visibleModalClaveCertificado: false });
    },
    firmarDocumento: async (datosFirma) => {
        try {
            set({ loading: true });
            const respuesta = await DocumentoGenericoService.firmar(datosFirma);
            return respuesta;
        } catch (error) {
            console.error(error?.response?.data?.mensaje);
            set({ mensajeError: error?.response?.data?.mensaje })
        } finally {
            set({ loading: false });
        }
    }

}));

export default useModalClaveCertificadoStore;