import { useEffect ,useContext} from "react";
import { useAtom } from "jotai";
import {perfilesAtom} from "../ListarPerfilSolicitudPersonalStorage";
import { SecurityContext } from "context/SecurityContextProvider";
//import PerfilSolicitudPersonalService from "services/PerfilSolicitudPersonalService";
import PerfilService from "services/reclutamiento-y-seleccion/PerfilService";
const useListarPerfilSolicitudPersonal=()=>{

    const[perfiles,setPerfiles]=useAtom(perfilesAtom);
    const {getUsuarioAutenticado} = useContext(SecurityContext);

    useEffect(()=>{
       const cargarDatos = async()=>{
         //const respuesta = await PerfilSolicitudPersonalService.listar();
         const respuesta = await PerfilService.listar();
         setPerfiles(respuesta);
       }
       cargarDatos();
    },[])
   return{perfiles,setPerfiles}

}
export default useListarPerfilSolicitudPersonal;