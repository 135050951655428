import React, { useContext, useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import { useModalConfirmar } from "store/global"
import { SecurityContext } from "context/SecurityContextProvider";
import * as atoms from "../EditarSeccionStore";
import { useAtom } from "jotai";
import { useParams } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import SeccionService from "../../../../services/SeccionService";
import { CampoColaborador } from "enums/CampoColaborador";
import SubsedeService from "services/SubsedeService";
import PerfilService from "services/PerfilService";
import SedeService from "services/SedeService";
import TipoPlanillaService from "services/TipoPlanillaService";
import CargoService from "services/CargoService";

const SeccionEditar = () => {

  let { idSeccion } = useParams();
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [, setSeccion] = useAtom(atoms.seccionAtom);
  const mostrarModalConfirmacion = useModalConfirmar(state => state.mostrarModal);
  const [formularioSeccion] = Form.useForm();

  const [dependenciaSeccionAtom, setDependenciaSeccionAtom] = useAtom(atoms.dependenciaSeccion);
  const [listaCamposAtom, setListaCamposAtom] = useAtom(atoms.listaCampos);
  const [perfilesAtom, setPerfilesAtom] = useAtom(atoms.perfiles);
  const [sedesAtom, setSedesAtom] = useAtom(atoms.sedes);
  const [subsedesAtom, setSubsedesAtom] = useAtom(atoms.subsedes);
  const [tipoPlanillasAtom, setTipoPlanillasAtom] = useAtom(atoms.tipoPlanillas);
  const [cargosAtom, setCargosAtom] = useAtom(atoms.cargos);
  const [camposColaboradorAtom, setCamposColaboradorAtom] = useAtom(atoms.camposColaborador)

  const { Option } = Select;

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        const seccionObtenida = await SeccionService.buscarPorId(idSeccion);
        formularioSeccion.setFieldsValue(
          {
            id: seccionObtenida.id,
            nombre: seccionObtenida.nombre,
          }
        )
        var listaCampos = [], dependencia;
        var perfilId, sedeId, subsedeId, tipoPlanillaId, cargoId;
        const empresa = getUsuarioAutenticado().empresaSeleccionada.id;
        const campos_colaborador = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
        setCamposColaboradorAtom(campos_colaborador);

        const campo_seccion = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.SECCION);

        const seccionDependencia = campo_seccion.campoDependencia;


        if (seccionDependencia !== null) {
          if (seccionDependencia === CampoColaborador.PERFIL) {
            perfilId = seccionObtenida.perfilId;
          }
          else if (seccionDependencia === CampoColaborador.SEDE) {
            sedeId = seccionObtenida.sedeId;
          }
          else if (seccionDependencia === CampoColaborador.SUBSEDE) {
            subsedeId = seccionObtenida.subsedeId;
          }
          else if (seccionDependencia === CampoColaborador.PLANILLA) {
            tipoPlanillaId = seccionObtenida.tipoPlanillaId;
          }
          else if (seccionDependencia === CampoColaborador.CARGO) {
            cargoId = seccionObtenida.cargoId;
          }

          setDependenciaSeccionAtom(seccionDependencia);
          dependencia = seccionDependencia;
          listaCampos.push(dependencia);

          while (dependencia !== null) {
            var campo = campos_colaborador.find(campoColaborador => campoColaborador.campo === dependencia);
            dependencia = campo.campoDependencia;
            if (dependencia !== null) {
              listaCampos.push(dependencia);
              if (dependencia === CampoColaborador.PERFIL) {
                perfilId = seccionObtenida.perfilId;
              }
              else if (dependencia === CampoColaborador.SEDE) {
                sedeId = seccionObtenida.sedeId;
              }
              else if (dependencia === CampoColaborador.SUBSEDE) {
                subsedeId = seccionObtenida.subsedeId;
              }
              else if (dependencia === CampoColaborador.PLANILLA) {
                tipoPlanillaId = seccionObtenida.tipoPlanillaId;
              }
              else if (dependencia === CampoColaborador.CARGO) {
                cargoId = seccionObtenida.cargoId;
              }
            }

          }
          setListaCamposAtom(listaCampos.reverse());

        }
        else {
          setListaCamposAtom(listaCampos);
          setDependenciaSeccionAtom(CampoColaborador.SINCAMPO);
        }
        if (listaCampos.length > 0) {

          for (let i = 0; i < listaCampos.length; i++) {

            if (listaCampos[i] === CampoColaborador.SEDE) {
              const campo_sede = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.SEDE);
              const sedeDependencia = campo_sede.campoDependencia;
              const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
              var _sedes;
              if (sedeDependencia === CampoColaborador.PERFIL) {
                _sedes = sedes.filter(sede => sede.perfilId === perfilId)
              }
              else if (sedeDependencia === CampoColaborador.SUBSEDE) {
                _sedes = sedes.filter(sede => sede.subsedelId === subsedeId)

              }
              else if (sedeDependencia === CampoColaborador.PLANILLA) {
                _sedes = sedes.filter(sede => sede.tipoPlanillaId === tipoPlanillaId)

              }
              else if (sedeDependencia === CampoColaborador.CARGO) {

                _sedes = sedes.filter(sede => sede.cargoId === cargoId)

              }
              else {
                _sedes = sedes;
              }
              setSedesAtom(_sedes);
              formularioSeccion.setFieldsValue({ sedeId: sedeId })


            }
            else if (listaCampos[i] === CampoColaborador.PERFIL) {

              const campo_perfil = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.PERFIL);
              const perfilDependencia = campo_perfil.campoDependencia;
              const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
              var _perfiles;
              if (perfilDependencia === CampoColaborador.SEDE) {
                _perfiles = perfiles.filter(perfil => perfil.sedeId === sedeId)
              }
              else if (perfilDependencia === CampoColaborador.SUBSEDE) {
                _perfiles = perfiles.filter(perfil => perfil.subsedeId === subsedeId)

              }
              else if (perfilDependencia === CampoColaborador.PLANILLA) {
                _perfiles = perfiles.filter(perfil => perfil.tipoPlanillaId === tipoPlanillaId)

              }
              else if (perfilDependencia === CampoColaborador.CARGO) {

                _perfiles = perfiles.filter(perfil => perfil.cargoId === cargoId)

              }
              else {
                _perfiles = perfiles;
              }
              setPerfilesAtom(_perfiles);
              formularioSeccion.setFieldsValue({ perfilId: perfilId })
            }
            else if (listaCampos[i] === CampoColaborador.SUBSEDE) {

              const campo_subsede = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.SUBSEDE);
              const subsedeDependencia = campo_subsede.campoDependencia;
              const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
              var _subsedes;
              if (subsedeDependencia === CampoColaborador.SEDE) {
                _subsedes = subsedes.filter(subsede => subsede.sedeId === sedeId)
              }
              else if (subsedeDependencia === CampoColaborador.PERFIL) {
                _subsedes = subsedes.filter(subsede => subsede.perfilId === perfilId)

              }
              else if (subsedeDependencia === CampoColaborador.PLANILLA) {
                _subsedes = subsedes.filter(subsede => subsede.tipoPlanillaId === tipoPlanillaId)

              }
              else if (subsedeDependencia === CampoColaborador.CARGO) {

                _subsedes = subsedes.filter(subsede => subsede.cargoId === cargoId)

              }
              else {
                _subsedes = subsedes;
              }
              setSubsedesAtom(_subsedes);

              formularioSeccion.setFieldsValue({ subsedeId: subsedeId })
            }
            else if (listaCampos[i] === CampoColaborador.PLANILLA) {

              const campo_planilla = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.PLANILLA);
              const planillaDependencia = campo_planilla.campoDependencia;
              const planillas = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
              var _planillas;
              if (planillaDependencia === CampoColaborador.SEDE) {
                _planillas = planillas.filter(planilla => planilla.sedeId === sedeId)
              }
              else if (planillaDependencia === CampoColaborador.PERFIL) {
                _planillas = planillas.filter(planilla => planilla.perfilId === perfilId)

              }
              else if (planillaDependencia === CampoColaborador.SUBSEDE) {
                _planillas = planillas.filter(planilla => planilla.subsedeId === subsedeId)

              }
              else if (planillaDependencia === CampoColaborador.CARGO) {

                _planillas = planillas.filter(planilla => planilla.cargoId === cargoId)

              }
              else {
                _planillas = planillas;
              }
              setTipoPlanillasAtom(_planillas);

              formularioSeccion.setFieldsValue({ tipoPlanillaId: tipoPlanillaId })
            }
            else if (listaCampos[i] === CampoColaborador.CARGO) {

              const campo_cargo = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.CARGO);
              const cargoDependencia = campo_cargo.campoDependencia;
              const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
              var _cargos;
              if (cargoDependencia === CampoColaborador.SEDE) {
                _cargos = cargos.filter(cargo => cargo.sedeId === sedeId)
              }
              else if (cargoDependencia === CampoColaborador.PERFIL) {
                _cargos = cargos.filter(cargo => cargo.perfilId === perfilId)

              }
              else if (cargoDependencia === CampoColaborador.SUBSEDE) {
                _cargos = cargos.filter(cargo => cargo.subsedeId === subsedeId)

              }
              else if (cargoDependencia === CampoColaborador.PLANILLA) {
                _cargos = cargos.filter(cargo => cargo.tipoPlanillaId === tipoPlanillaId)

              }
              else {
                _cargos = cargos;
              }
              setCargosAtom(_cargos);

              formularioSeccion.setFieldsValue({ cargoId: cargoId })
            }
          }
        }

      } catch (error) {
        console.error(error);
      }

    }

    cargarDatos();

  }, []);
  const onClickBtnActualizarSeccion = async (seccion) => {
    seccion.empresaId = getUsuarioAutenticado().empresaSeleccionada.id;
    seccion.dependencia = dependenciaSeccionAtom;
    setSeccion(seccion);
    mostrarModalConfirmacion();

  }
  const handleOnchangePerfil = async (value, option) => {

    let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.PERFIL);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter(sede => sede.perfilId === value)
        setSedesAtom(_sedes);
        formularioSeccion.setFieldsValue({ sedeId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter(subsede => subsede.perfilId === value);
        setSubsedesAtom(_subsedes);
        formularioSeccion.setFieldsValue({ subsedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.CARGO) {
        const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _cargos = cargos.filter(cargo => cargo.perfilId === value);
        setCargosAtom(_cargos);
        formularioSeccion.setFieldsValue({ cargoId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanilla = tipoPlanilla.filter(planilla => planilla.perfilId === value);
        setTipoPlanillasAtom(_tipoPlanilla);
        formularioSeccion.setFieldsValue({ tipoPlanillaId: "" })

      }
    }
  }
  const handleOnchangeSede = async (value, option) => {
    let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.SEDE);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.sedeId === value)
        setPerfilesAtom(_perfiles);
        formularioSeccion.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter(subsede => subsede.sedeId === value);
        setSubsedesAtom(_subsedes);
        formularioSeccion.setFieldsValue({ subsedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.CARGO) {
        const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _cargos = cargos.filter(cargo => cargo.sedeId === value);
        setCargosAtom(_cargos);
        formularioSeccion.setFieldsValue({ cargoId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanilla = tipoPlanilla.filter(planilla => planilla.sedeId === value);
        setTipoPlanillasAtom(_tipoPlanilla);
        formularioSeccion.setFieldsValue({ tipoPlanillaId: "" })

      }
    }
  }
  const handleOnchangeSubsede = async (value, option) => {
    let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.SUBSEDE);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.subsedeId === value)
        setPerfilesAtom(_perfiles);
        formularioSeccion.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter(sede => sede.subsedeId === value);
        setSedesAtom(_sedes);
        formularioSeccion.setFieldsValue({ sedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.CARGO) {
        const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _cargos = cargos.filter(cargo => cargo.subsedeId === value);
        setCargosAtom(_cargos);
        formularioSeccion.setFieldsValue({ cargoId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanilla = tipoPlanilla.filter(planilla => planilla.subsedeId === value);
        setTipoPlanillasAtom(_tipoPlanilla);
        formularioSeccion.setFieldsValue({ tipoPlanillaId: "" })

      }
    }

  }
  const handleOnchangeTipoPlanilla = async (value, option) => {
    let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.PLANILLA);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.tipoPlanillaId === value)
        setPerfilesAtom(_perfiles);
        formularioSeccion.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter(subsede => subsede.tipoPlanillaId === value);
        setSubsedesAtom(_subsedes);
        formularioSeccion.setFieldsValue({ subsedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.CARGO) {
        const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _cargos = cargos.filter(cargo => cargo.tipoPlanillaId === value);
        setCargosAtom(_cargos);
        formularioSeccion.setFieldsValue({ cargoId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter(sede => sede.tipoPlanillaId === value);
        setSedesAtom(_sedes);
        formularioSeccion.setFieldsValue({ sedeId: "" })

      }
    }

  }
  const handleOnchangeCargo = async (value, option) => {
    let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.CARGO);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.cargoId === value)
        setPerfilesAtom(_perfiles);
        formularioSeccion.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter(subsede => subsede.cargoId === value);
        setSubsedesAtom(_subsedes);
        formularioSeccion.setFieldsValue({ subsedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanillas = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanillas = tipoPlanillas.filter(planilla => planilla.cargoId === value);
        setTipoPlanillasAtom(_tipoPlanillas);
        formularioSeccion.setFieldsValue({ tipoPlanillaId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter(sede => sede.cargoId === value);
        setSedesAtom(_sedes);
        formularioSeccion.setFieldsValue({ sedeId: "" })

      }
    }
  }

  return (
    <Form
      layout="vertical"
      form={formularioSeccion}
      onFinish={onClickBtnActualizarSeccion}
    >
      <Form.Item
        label="id"
        name="id"
        noStyle
      >
        <Input type="hidden" />
      </Form.Item>
      {
        listaCamposAtom.length > 0 && (
          listaCamposAtom.map((listaCampo) => {
            if (listaCampo === CampoColaborador.PERFIL) {
              return (
                <Row>
                  <Col span={12} offset={6}>
                    <Form.Item
                      label="Perfil"
                      name="perfilId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Perfil"
                        },
                      ]}
                    >
                      <Select
                        placeholder="Seleccione Perfil"
                        onChange={handleOnchangePerfil}
                      >
                        {perfilesAtom.length > 0 &&
                          perfilesAtom.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}
                      </Select>

                    </Form.Item>
                  </Col>
                </Row>
              )
            }

            else if (listaCampo === CampoColaborador.SEDE) {
              return (
                <Row>
                  <Col span={12} offset={6}>
                    <Form.Item
                      label="Centro de Costo"
                      name="sedeId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Centro de Costo"
                        },
                      ]}
                    >
                      <Select
                        placeholder="Seleccione Centro de Costo"
                        onChange={handleOnchangeSede}
                      >
                        {sedesAtom.length > 0 &&
                          sedesAtom.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}

                      </Select>

                    </Form.Item>
                  </Col>
                </Row>
              )
            }
            else if (listaCampo === CampoColaborador.SUBSEDE) {
              return (
                <Row>
                  <Col span={12} offset={6}>
                    <Form.Item
                      label="Subcentro de Costo"
                      name="subsedeId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Subcentro de Costo"
                        },
                      ]}
                    >
                      <Select
                        placeholder="Seleccione Subcentro de Costo"
                        onChange={handleOnchangeSubsede}
                      >
                        {subsedesAtom.length > 0 &&
                          subsedesAtom.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}
                      </Select>

                    </Form.Item>
                  </Col>
                </Row>
              )
            }
            else if (listaCampo === CampoColaborador.PLANILLA) {
              return (
                <Row>
                  <Col span={12} offset={6}>
                    <Form.Item
                      label="Tipo planilla"
                      name="tipoPlanillaId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Tipo planilla"
                        },
                      ]}
                    >
                      <Select
                        placeholder="Seleccione Tipo planilla"
                        onChange={handleOnchangeTipoPlanilla}
                      >
                        {tipoPlanillasAtom.length > 0 &&
                          tipoPlanillasAtom.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}
                      </Select>

                    </Form.Item>
                  </Col>
                </Row>
              )

            }
            else if (listaCampo === CampoColaborador.CARGO) {
              return (
                <Row>
                  <Col span={12} offset={6}>
                    <Form.Item
                      label="Cargo"
                      name="cargoId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Cargo"
                        },
                      ]}
                    >
                      <Select
                        placeholder="Seleccione Cargo"
                        onChange={handleOnchangeCargo}
                      >
                        {cargosAtom.length > 0 &&
                          cargosAtom.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}
                      </Select>

                    </Form.Item>
                  </Col>
                </Row>
              )
            }

          })

        )

      }

      <Row>
        <Col span={12} offset={6}>
          <Form.Item
            label="Nombre"
            name="nombre"
            rules={[
              {
                required: true,
                message: "Escribe el nombre de la sección.",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item style={{ textAlign: "center" }} >
        <Button
          type="primary"
          icon={<CheckOutlined />}
          htmlType="submit"
        >
          Actualizar Sección
        </Button>
      </Form.Item>
    </Form>
  )
}
export default SeccionEditar;