import React, { useContext, useEffect, useState } from "react";
import { Table,Space } from "antd";
import * as atoms from "../ListarCarpetasLegajosStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import  CarpetaService from "services/CarpetaService";
import CertificadoService from "services/CertificadoService";
import { MenuAcciones } from "./MenuAcciones";
import { FolderFilled,FolderTwoTone} from "@ant-design/icons";
const TablaCarpetasLegajos=()=>{
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [loading, setLoading] = useState(false);
    const [carpetasLegajos, setCarpetasLegajos] = useAtom(atoms.carpetasLegajos);
    
    useEffect(()=>{
        const fetch = async ()=>{
            try {
             setLoading(true);                    
             const _carpetasLegajos = await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             setCarpetasLegajos(_carpetasLegajos); 
             console.log(_carpetasLegajos)            
             
            } catch (error) {
                console.error(error);
                
            } finally{
                setLoading(false);
            }


        }
        fetch(); 
    },[]);  

    const columnasTablaCarpeta=[
        {
            title:"Nombre",
            //dataIndex:"descripcion",
            render:(fila)=>(
                <React.Fragment>
                    <Space size={fila.nivel * 12}>
                        <span></span>
                        <span>
                            {fila.nivel===0?(
                                <FolderFilled 
                                  style={{fontSize:'24px',color:'#F7D358' }}                              
                                 /> 
                                 ):
                                 (fila.nivel===1?(
                                  <FolderTwoTone 
                                  style={{fontSize:'21px',color:'#F7D358' }}
                                  twoToneColor='#F7D358'                            
                                 /> 
                                 ):(
                                    <FolderTwoTone
                                    style={{fontSize:'18px',color:'#F7D358' }}
                                    twoToneColor='#F7D358'                              
                                   /> 

                                )
                            )}                            
                             {" "}{fila.descripcion}
                        </span>
                    
                    </Space>
                </React.Fragment>
               
                   
                
            )
            

        },
        // {
        //     title:"Orden",
        //     dataIndex:"codigoOrden",
           

        // },
        {
            title:"Acciones",
            key:"acciones",
            align:"center",
            render:(fila)=>
                <MenuAcciones
                  carpeta={fila}
                  carpetas={carpetasLegajos}
                  setCarpetas={setCarpetasLegajos}
                />            
        },
        
    ];
    return(
        <Table
            loading={loading}
            rowKey={(carpeta)=>carpeta.id}
            columns={columnasTablaCarpeta}
            dataSource={carpetasLegajos}
            pagination={false}
        
        
        />
    )

    
}
export default TablaCarpetasLegajos;