import React, { useState, useEffect, useContext, useRef } from "react";
import { Modal, Form, Select, Divider, Input, Row, Col, DatePicker, Space } from "antd";

import { FolderTwoTone, FolderFilled } from "@ant-design/icons";
import { useAtom } from "jotai";
import {
  filtrosSeleccionadosAtom,
  listaTiposDocumentoFiltroAtom,
  listraCarpetasFiltroAtom,
  visibleModalFiltrarAtom,
  carpetaAtom,
  categoriasAtom,
  opcionesAtom,
  opcionesSeleccionadasAtom,
  filtrosAtom,
  valoresInicialesAtom,
} from "../../ConsultaDocumentoStore";
import ColaboradorService from "services/ColaboradorService";
import produce from "immer";
import { CampoSelect } from "components";
import { SecurityContext } from "context/SecurityContextProvider";



const ModalFiltrar = () => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const camposColaboradores = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
  const [visibleModal, setVisibleModal] = useAtom(visibleModalFiltrarAtom);

  const [listaCarpetasFiltro] = useAtom(listraCarpetasFiltroAtom);
  const [carpetas,] = useAtom(carpetaAtom);
  const [tipoDocumento,] = useAtom(listaTiposDocumentoFiltroAtom);
  const [categorias,] = useAtom(categoriasAtom);
  const [opciones,] = useAtom(opcionesAtom);
  const [opcionesSeleccionadas, setOpcionesSeleccionadas] = useAtom(opcionesSeleccionadasAtom);
  const [valoresIniciales, setValoresIniciales] = useAtom(valoresInicialesAtom);
  const [filtrosSeleccinados, setFiltrosSeleccionados] = useAtom(filtrosSeleccionadosAtom);
  const [colaboradores, setColaboradores] = useState(undefined);
  const [frmFiltrar] = Form.useForm();
  const [filtros, setFiltros] = useAtom(filtrosAtom);
  const formRef = useRef(null)



  useEffect(() => {
    const cargarDatosIniciales = async () => {

      var _opcionesSeleccionadas = camposColaboradores.map((campo) => {
        const datos = filtros[campo.nombre];
        return {
          campo: campo.campo,
          datos: datos !== undefined && datos !== null ? datos : [],
        };
      });
      setOpcionesSeleccionadas(_opcionesSeleccionadas);

      var _opcionesIniciales = [];
      camposColaboradores.forEach((campo) => {
        const categoriasSeleccionadasPorCampo = _opcionesSeleccionadas.find((c) => c.campo === campo.campo).datos;
        const categoriasPorCampo = categorias.filter((c) => c.campo === campo.campo && categoriasSeleccionadasPorCampo.includes(c.id));
        _opcionesIniciales.push({
          campo: campo.campo,
          datos: categoriasPorCampo
            .filter((c) => categoriasSeleccionadasPorCampo.includes(c.id))
            .map((c) => {
              var opcion = {
                id: c.id,
                label: c.nombre,
                value: c.id,
                campo: campo.campo,
                campodependencia: c.campoDependencia,
                iddependencia: c.idDependencia,
              };
              return opcion;
            }),
        });
      });
      setValoresIniciales(_opcionesIniciales);
      frmFiltrar.setFieldsValue(filtrosSeleccinados);
    };

    cargarDatosIniciales();



  }, [visibleModal, filtros]);

  const onClickBtnCancelar = () => {
    frmFiltrar.resetFields();
    setVisibleModal(false);
  };



  const onSearchColaboradores = async (value) => {
    if (value === "") {
      setColaboradores([]);
      return;
    }
    const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
      filtro: value.trim(),
    });
    setColaboradores(respuesta.colaboradores);
  };

  const onClickBtnFiltrar = () => {


    frmFiltrar
      .validateFields()
      .then((values) => {
        var _formularioNotUndefined = values;
        const _filtros = [];
        camposColaboradores.forEach((campo) => {
          const filtros = opcionesSeleccionadas
            .find((opcion) => opcion.campo === campo.campo)
            .datos.map((opcion) => {
              return opcion.value;
            });
          //      console.log("filtros boton filtrar : ",filtros)
          let nombresCampo = campo.nombre.substring(6).charAt(0).toLowerCase() + campo.nombre.substring(7);
          if (filtros.length > 0) {
            _formularioNotUndefined = { ..._formularioNotUndefined, [nombresCampo]: filtros };
            _filtros[campo.nombre] = filtros;
          } else {
            _formularioNotUndefined = { ..._formularioNotUndefined, [nombresCampo]: null };
            _filtros[campo.nombre] = null;
          }
        });

        console.log("**filtros", formatearFormulario(_formularioNotUndefined));

        setFiltros(_filtros);
        setFiltrosSeleccionados(formatearFormulario(_formularioNotUndefined));
        setVisibleModal(false);
      })
      .catch((info) => {
        console.log(info)
      });
  };

  const getValor = (valor, valorReemplazar = null) => {
    if (valor === undefined) {
      return valorReemplazar;
    }
    return valor;
  };

  const formatearFormulario = (filtro) => {
    filtro.cargo = getValor(filtro.cargo, []);
    filtro.carpeta = getValor(filtro.carpeta);
    filtro.colaborador = getValor(filtro.colaborador);
    filtro.documentoIdentidad = getValor(filtro.documentoIdentidad);
    filtro.codigoColaborador = getValor(filtro.codigoColaborador)
    filtro.estadoDocumento = getValor(filtro.estadoDocumento);
    filtro.fechaEnvio = getValor(filtro.fechaEnvio);
    filtro.periodoDocumento = getValor(filtro.periodoDocumento);
    filtro.sede = getValor(filtro.sede, []);
    filtro.tipoDocumento = getValor(filtro.tipoDocumento, []);
    filtro.tipoPlanilla = getValor(filtro.tipoPlanilla, []);
    filtro.subSede = getValor(filtro.subSede, []);
    filtro.seccion = getValor(filtro.seccion, []);
    filtro.perfil = getValor(filtro.perfil, []);
    filtro.fechaIngreso = getValor(filtro.fechaIngreso)
    filtro.fechaFirma = getValor(filtro.fechaFirma);
    return filtro;
  };

  return (
    <Modal
      title={"Seleccionar Filtro"}
      getContainer={false}
      bodyStyle={{ padding: "0px" }}
      width={900}
      open={visibleModal}
      onOk={onClickBtnFiltrar}
      onCancel={onClickBtnCancelar}
    >
      <Form form={frmFiltrar} name="frmFiltrar" layout="vertical" ref={formRef}>
        <Divider plain orientation="left">
          Por Documento:
        </Divider>
        <Row style={{ paddingLeft: "60px", paddingRight: "30px" }}>
          <Col span={23}>
            <Form.Item name={"tipoDocumento"} label={"Tipo de Documento:"} rules={[{ type: "array" }]}>
              <Select
                style={{ width: "100%" }}
                maxTagCount={5}
                allowClear
                showSearch
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                dropdownMatchSelectWidth={true}
                placeholder="Seleccione el tipo de documento"
                mode="multiple"
              >
                {tipoDocumento?.map((tipoDocumento) => (
                  <Select.Option
                    key={tipoDocumento.id}
                    //value={JSON.stringify(tipoDocumento)}
                    value={tipoDocumento.id}
                  >
                    {tipoDocumento.nombre}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingLeft: "60px", paddingRight: "30px" }}>
          <Col span={11}>
            <Form.Item name={"periodoDocumento"} label={"Periodo Documento:"}>
              <DatePicker format={"MM/YYYY"} picker="month" inputReadOnly style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name={"estadoDocumento"} label={"Estado Documento:"}>
              <Select
                style={{ width: "100%" }}
                allowClear
                showSearch
                dropdownMatchSelectWidth={false}
                placeholder="Seleccione el estado del documento"
              >
                <Select.Option value={"PENDIENTE"}>PENDIENTE</Select.Option>
                <Select.Option value={"VISUALIZADO"}>VISUALIZADO</Select.Option>
                <Select.Option value={"SIN_VISUALIZAR"}>SIN VISUALIZAR</Select.Option>
                <Select.Option value={"FIRMADO"}>FIRMADO</Select.Option>
                <Select.Option value={"ERROR"}>ERROR</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Divider plain orientation="left">
          Por Colaborador:
        </Divider>
        <Row style={{ paddingLeft: "60px", paddingRight: "30px" }}>
          <Col span={11}>
            <Form.Item name={"documentoIdentidad"} label={"Documento de Identidad:"} autoComplete="off">
              <Input allowClear placeholder="Ingrese el documento de Identidad" autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={11} offset={1} >
            <Form.Item name={"codigoColaborador"} label={"Código de Colaborador:"} autoComplete="off">
              <Input allowClear placeholder="Ingrese el código del colaborador" /*autoComplete="off"*/ autoComplete="newpassword" />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingLeft: "60px", paddingRight: "30px" }}>
          <Col span={23}>
            <Form.Item name={"colaborador"} label={"Apellidos y Nombres:"}>
              <Select
                style={{ width: "100%" }}
                allowClear
                showSearch
                placeholder="Ingrese el nombre del colaborador"
                onSearch={onSearchColaboradores}
                onSelect={(value, colaborador) => {
                  frmFiltrar.setFieldValue("colaboradorDni", colaborador.numeroDocumentoIdentidad);
                }}
                //onChange={onChangeSelectColaboradores}
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {colaboradores !== undefined &&
                  colaboradores?.map((colaborador) => (
                    <Select.Option
                      key={colaborador.id}
                      value={colaborador.nombreCompleto}
                      numeroDocumentoIdentidad={colaborador.numeroDocumentoIdentidad}>
                      {colaborador.nombreCompleto}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item name="colaboradorDni">
              <Input type="hidden" />
            </Form.Item>
          </Col>

        </Row>
        <Row style={{ paddingLeft: "20px", paddingRight: "60px" }}>
          {camposColaboradores.length > 0 &&
            camposColaboradores.map((campo) => {
              const options = opciones.find((o) => o.campo === campo.campo);
              const _valoresIniciales = valoresIniciales.find((o) => o.campo === campo.campo);
              const camposHijos = camposColaboradores.filter((c) => c.campoDependencia === campo.campo);

              return (
                <FormItemCampos
                  key={campo.campo}
                  label={campo.etiqueta}
                  name={campo.nombre}
                  campo={campo.campo}
                  valoresIniciales={_valoresIniciales?.datos}
                  camposHijos={camposHijos}
                  options={options?.datos}
                />
              );
            })}
        </Row>
        <Divider plain orientation="left">
          Otros:
        </Divider>
        <Row style={{ paddingLeft: "60px", paddingRight: "30px" }}>
          <Col span={11}>
            <Form.Item name={"carpeta"} label={"Carpeta:"}>
              <Select allowClear showSearch placeholder="Seleccione Carpeta" optionLabelProp="label" dropdownMatchSelectWidth={false}>
                {carpetas?.map((carpeta) => {
                  return (
                    <Select.Option
                      key={carpeta.id}
                      value={carpeta.id}
                      disabled={listaCarpetasFiltro.filter((item) => item.id == carpeta.id).length == 0}
                      label={
                        <div>
                          <FolderTwoTone style={{ fontSize: "20px", color: "#F7D358" }} twoToneColor="#F7D358" /> {carpeta.ruta}
                        </div>
                      }
                      descripcion={carpeta.descripcion}
                    >
                      <Space size={carpeta.nivel * 10}>
                        <span></span>
                        <span>
                          {carpeta.nivel === 0 ? (
                            <FolderFilled style={{ fontSize: "20px", color: "#F7D358" }} />
                          ) : (
                            <FolderTwoTone style={{ fontSize: "18px", color: "#F7D358" }} twoToneColor="#F7D358" />
                          )}{" "}
                          {carpeta.descripcion}
                        </span>
                      </Space>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name={"fechaEnvio"} label={"Fecha de Envío:"}>
              <DatePicker.RangePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ paddingLeft: "60px", paddingRight: "30px" }}>
          <Col span={11}>
            <Form.Item name={"fechaIngreso"} label={"Fecha de Ingreso:"}>
              <DatePicker.RangePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={11} offset={1}>
            <Form.Item name={"fechaFirma"} label={"Fecha Firma:"}>
              <DatePicker.RangePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const FormItemCampos = ({ label, name, options, valoresIniciales, campo, camposHijos }) => {
  const [categorias] = useAtom(categoriasAtom);
  const [, setOpciones] = useAtom(opcionesAtom);
  const [opcionesSeleccionadas, setOpcionesSeleccionadas] = useAtom(opcionesSeleccionadasAtom);

  const cargarDependencias = (option) => {
    const nuevasOpciones = camposHijos.map((hijo) => {
      var options = option.map((o) => {
        var grupo = { label: o.label };
        var options = categorias
          .filter((c) => c.campo === hijo.campo && o.id === c.idDependencia)
          .map((c) => {
            var opcion = {
              id: c.id,
              label: c.nombre,
              value: c.id,
              campo: c.campo,
              campodependencia: c.campoDependencia,
              iddependencia: c.idDependencia,
            };
            return opcion;
          });
        return { ...grupo, options: [...options] };
      });
      return { campo: hijo.campo, datos: options };
    });

    setOpciones(
      produce((draft) => {
        nuevasOpciones.forEach((nuevaOpcion) => {
          const opcion = draft.find((o) => o.campo === nuevaOpcion.campo);
          opcion.datos = nuevaOpcion.datos;
        });
      })
    );

    setOpcionesSeleccionadas(
      produce((draft) => {
        const opcion = draft.find((o) => o.campo === campo);
        opcion.datos = option;
      })
    );
  };

  const onSeleccionar = (itemsSeleccionados) => {
    // console.log("Items seleccionados : ",itemsSeleccionados)
    cargarDependencias(itemsSeleccionados);
  };

  const onQuitar = (value, option) => {
    console.log(value)
    let quitarCampo = true;
    camposHijos.forEach((campoHijo) => {
      const opcionesSeleccionadasPorCampo = opcionesSeleccionadas.find((opcion) => opcion.campo === campoHijo.campo)?.datos;
      opcionesSeleccionadasPorCampo.forEach((opcion) => {
        if (opcion.iddependencia === value) {
          quitarCampo = false;
          return;
        }
      });
    });
    return quitarCampo;
  };

  return (
    <Col span={11} offset={1}>
      <Form.Item label={label} name={name} rules={[{ type: "array" }]}>
        <CampoSelect options={options} valoresIniciales={valoresIniciales} onSeleccionar={onSeleccionar} onQuitar={onQuitar} />
      </Form.Item>
    </Col>
  );
};

export default ModalFiltrar;
