import React ,{useContext}from "react";
import { Button,notification} from "antd";
import { EditOutlined, LayoutOutlined,DeleteOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import  VerificarDatosColaboradorObservadosService from "services/VerificarDatosColaboradorObservadosService";
import { SecurityContext } from "context/SecurityContextProvider";

export const MenuAcciones=({verificarDatos, data, setData})=>{
    let history = useHistory();
    const { getUsuarioAutenticado } = useContext(SecurityContext);
   
    const BotonAprobar = ()=>{
        const onclickBtnAprobar= async() =>{ 
            verificarDatos.empresaId = getUsuarioAutenticado().empresaSeleccionada.id;  
            //console.log("datos",verificarDatos);
              await VerificarDatosColaboradorObservadosService.aprobarVerificacionDatosColaborador(verificarDatos);                        
              notification.success({
                message: "Ha sido aprobada la verificación de datos",
              });
            const newData = data.filter(item=> item.numeroDocumentoIdentidad !== verificarDatos.numeroDocumentoIdentidad);
            setData(newData);

        }
        return(            
            <Button type="link" onClick={onclickBtnAprobar} >
                Aprobar
            </Button>         

        )
    }
    return (
        <React.Fragment>            
            <BotonAprobar />
        </React.Fragment>
    );
}


