import React, { useContext, useState, useEffect } from "react";
import { List, Row, Col, Tag } from "antd";
import { UserOutlined, RightOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import LegajoService from "services/LegajoService";
import { SecurityContext } from "context/SecurityContextProvider";
import * as atoms from "../../ConsultarLegajoColaboradorStore";
import { EstadoRegistro } from "enums/EstadoRegistro";

const ItemColaborador = ({ colaborador, loading }) => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [colaboradorSeleccionado, setColaboradorSeleccionado] = useAtom(
    atoms.colaboradorSeleccionado
  );
  const [, setLoadingDocumentos] = useAtom(atoms.loadingDocumentos);
  const [, setDocumentos] = useAtom(atoms.documentos);
  const [, setBreadcrumbItems] = useAtom(atoms.breadcrumbItems);
  const [background, setBackground] = useState("#ffffff");
  const [primerColaborador] = useAtom(atoms.primerColaborador);

  const onClickColaborador = async (colaborador) => {
    try {
      setLoadingDocumentos(true);
      setColaboradorSeleccionado(colaborador);
      const _documentos = await LegajoService.listarDocumentosColaborador(
        colaborador.numeroDocumentoIdentidad,
        getUsuarioAutenticado().empresaSeleccionada.id,
        getUsuarioAutenticado().login
      );
      setDocumentos(_documentos);
      setBreadcrumbItems([]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDocumentos(false);
    }
  };

  useEffect(() => {
    if (primerColaborador && primerColaborador.id === colaborador.id) {
      onClickColaborador(primerColaborador);
    }
  }, [primerColaborador]);

  useEffect(() => {
    if (colaboradorSeleccionado) {
      if (colaboradorSeleccionado.id === colaborador.id) {
        setBackground("#e6f7ff");
      } else {
        setBackground("#ffffff");
      }
    }
  }, [colaboradorSeleccionado]);

  return (
    <List.Item
      key={colaborador.id}
      onClick={() => onClickColaborador(colaborador)}
      style={{
        cursor: "pointer",
        borderBottomWidth: "1px",
        borderBottomStyle: "solid",
        borderBottomColor: "#EAEDED",
        background: background,
        paddingLeft: "15px",
      }}
    >
      <List.Item.Meta
        avatar={
          <UserOutlined
            style={{
              fontSize: "24px",
              marginTop: "12px",
              color: "#1678c1",
            }}
          />
        }
        title={
          <Col>
            <Row>{colaborador.numeroDocumentoIdentidad}</Row>
            <Row style={{ fontWeight: "500" }}>
              {colaborador.nombreCompleto}
            </Row>
            <Row>
              {colaborador.estadoRegistro === EstadoRegistro.ACTIVO ? (
                <Tag color="green">{colaborador.estadoRegistro}</Tag>
              ) : (
                <Tag color="red">{colaborador.estadoRegistro}</Tag>
              )}
            </Row>
          </Col>
        }
      />
      {colaboradorSeleccionado &&
        colaboradorSeleccionado.id === colaborador.id && (
          <RightOutlined
            style={{
              fontSize: "18px",
              color: "#1678c1",
              padding: "5px",
            }}
          />
        )}
    </List.Item>
  );
};

export default ItemColaborador;
