import React, { useContext, useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useModalConfirmar } from "store/global"
import { SecurityContext } from "context/SecurityContextProvider";
import * as atoms from "../CrearSubsedeStore";
import { useAtom } from "jotai";
import { useHistory } from "react-router";
import SubsedeService from "services/SubsedeService";
import { CampoColaborador } from "enums/CampoColaborador";
import PerfilService from "services/PerfilService";
import SedeService from "services/SedeService";
import SeccionService from "services/SeccionService";
import TipoPlanillaService from "services/TipoPlanillaService";
import CargoService from "services/CargoService";

const SubsedeRegistro = () => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [, setSubsedeAtom] = useAtom(atoms.subsede);
  const mostrarModalConfirmacion = useModalConfirmar(state => state.mostrarModal);

  const [dependenciaSubsedeAtom, setDependenciaSubsedeAtom] = useAtom(atoms.dependenciaSubsede);
  //const [listaCampoDependenciaAtom,setListaCampoDependenciaAtom]=useAtom(atoms.listacampoDependencia);
  //const [nameCampoAtom,setNameCampoAtom]=useAtom(atoms.nameCampo);
  const [listaCamposAtom, setListaCamposAtom] = useAtom(atoms.listaCampos);
  const [perfilesAtom, setPerfilesAtom] = useAtom(atoms.perfiles);
  const [sedesAtom, setSedesAtom] = useAtom(atoms.sedes);
  const [seccionesAtom, setSeccionesAtom] = useAtom(atoms.secciones);
  const [tipoPlanillasAtom, setTipoPlanillasAtom] = useAtom(atoms.tipoPlanillas);
  const [cargosAtom, setCargosAtom] = useAtom(atoms.cargos);
  const [camposColaboradorAtom, setCamposColaboradorAtom] = useAtom(atoms.camposColaborador)

  const { Option } = Select;
  const [formSubsede] = Form.useForm();

  useEffect(() => {
    const cargarDatosIniciales = async () => {

      var listaCampos = [], dependencia;
      const empresa = getUsuarioAutenticado().empresaSeleccionada.id;
      const campos_colaborador = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
      setCamposColaboradorAtom(campos_colaborador);
      const campo_subsede = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.SUBSEDE);
      const subsedeDependencia = campo_subsede.campoDependencia;

      if (subsedeDependencia !== null) {
        setDependenciaSubsedeAtom(subsedeDependencia);
        dependencia = subsedeDependencia;
        listaCampos.push(dependencia);

        while (dependencia !== null) {
          var campo = campos_colaborador.find(campoColaborador => campoColaborador.campo === dependencia);
          dependencia = campo.campoDependencia;
          if (dependencia !== null) {
            listaCampos.push(dependencia);
          }

        }
        setListaCamposAtom(listaCampos.reverse());
      }
      else {
        setListaCamposAtom(listaCampos);
        setDependenciaSubsedeAtom(CampoColaborador.SINCAMPO);
      }
      if (listaCampos.length > 0) {
        const primerCampo = listaCampos[0];
        if (primerCampo === CampoColaborador.PERFIL) {
          const perfiles = await PerfilService.listar(empresa);
          setPerfilesAtom(perfiles);
        }
        else if (primerCampo === CampoColaborador.SEDE) {
          const sedes = await SedeService.listar(empresa);
          setSedesAtom(sedes);

        }
        else if (primerCampo === CampoColaborador.SECCION) {
          const secciones = await SeccionService.listar(empresa);
          setSeccionesAtom(secciones);

        }
        else if (primerCampo === CampoColaborador.PLANILLA) {
          const planillas = await TipoPlanillaService.listar(empresa);
          setTipoPlanillasAtom(planillas);

        }
        else if (primerCampo === CampoColaborador.CARGO) {
          const cargos = await CargoService.listar(empresa);
          setCargosAtom(cargos);
        }

      }



      //console.log("lista",listaCampos);


      // if(subsedeDependencia!==null){

      //     setDependenciaSubsedeAtom(subsedeDependencia);
      //     setNameCampoAtom(subsedeDependencia.toLowerCase()+"Id");

      //     if (subsedeDependencia===CampoColaborador.SEDE){
      //       const sedes= await SedeService.listar(empresa);
      //       setListaCampoDependenciaAtom(sedes);                   

      //     }
      //     else if(subsedeDependencia===CampoColaborador.PERFIL){
      //       const perfiles= await PerfilService.listar(empresa);
      //       setListaCampoDependenciaAtom(perfiles);

      //     }
      //     else if(subsedeDependencia===CampoColaborador.SECCION){
      //       const secciones= await SeccionService.listar(empresa);
      //       setListaCampoDependenciaAtom(secciones);

      //     }
      //     else if(subsedeDependencia===CampoColaborador.PLANILLA){
      //       const planillas= await TipoPlanillaService.listar(empresa);
      //       setListaCampoDependenciaAtom(planillas);
      //       setNameCampoAtom("tipoPlanillaId");
      //     }
      //     else if(subsedeDependencia===CampoColaborador.CARGO){
      //       const cargos = await CargoService.listar(empresa);
      //       setListaCampoDependenciaAtom(cargos);
      //     }


      // }
      // else{          
      //   setDependenciaSubsedeAtom(CampoColaborador.SINCAMPO);         
      // }
    }
    cargarDatosIniciales();

  }, []);

  const onClickBtnCrearSubsede = (subsede) => {
    subsede.empresaId = getUsuarioAutenticado().empresaSeleccionada.id;
    subsede.dependencia = dependenciaSubsedeAtom;
    setSubsedeAtom(subsede);
    mostrarModalConfirmacion();
  }
  const handleOnchangePerfil = async (value, option) => {

    let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.PERFIL);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter(sede => sede.perfilId === value)
        setSedesAtom(_sedes);
        formSubsede.setFieldsValue({ sedeId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SECCION) {
        const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _secciones = secciones.filter(seccion => seccion.perfilId === value);
        setSeccionesAtom(_secciones);
        formSubsede.setFieldsValue({ seccionId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.CARGO) {
        const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _cargos = cargos.filter(cargo => cargo.perfilId === value);
        setCargosAtom(_cargos);
        formSubsede.setFieldsValue({ cargoId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanilla = tipoPlanilla.filter(planilla => planilla.perfilId === value);
        setTipoPlanillasAtom(_tipoPlanilla);
        formSubsede.setFieldsValue({ tipoPlanillaId: "" })

      }
    }
  }
  const handleOnchangeSede = async (value, option) => {
    let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.SEDE);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.sedeId === value)
        setPerfilesAtom(_perfiles);
        formSubsede.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SECCION) {
        const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _secciones = secciones.filter(seccion => seccion.sedeId === value);
        setSeccionesAtom(_secciones);
        formSubsede.setFieldsValue({ seccionId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.CARGO) {
        const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _cargos = cargos.filter(cargo => cargo.sedeId === value);
        setCargosAtom(_cargos);
        formSubsede.setFieldsValue({ cargoId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanilla = tipoPlanilla.filter(planilla => planilla.sedeId === value);
        setTipoPlanillasAtom(_tipoPlanilla);
        formSubsede.setFieldsValue({ tipoPlanillaId: "" })

      }
    }
  }
  const handleOnchangeSeccion = async (value, option) => {
    let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.SECCION);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.seccionId === value)
        setPerfilesAtom(_perfiles);
        formSubsede.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter(sede => sede.seccionId === value);
        setSedesAtom(_sedes);
        formSubsede.setFieldsValue({ sedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.CARGO) {
        const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _cargos = cargos.filter(cargo => cargo.seccionId === value);
        setCargosAtom(_cargos);
        formSubsede.setFieldsValue({ cargoId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanilla = tipoPlanilla.filter(planilla => planilla.seccionId === value);
        setTipoPlanillasAtom(_tipoPlanilla);
        formSubsede.setFieldsValue({ tipoPlanillaId: "" })

      }
    }

  }
  const handleOnchangeTipoPlanilla = async (value, option) => {
    let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.PLANILLA);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.tipoPlanillaId === value)
        setPerfilesAtom(_perfiles);
        formSubsede.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SECCION) {
        const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _secciones = secciones.filter(seccion => seccion.tipoPlanillaId === value);
        setSeccionesAtom(_secciones);
        formSubsede.setFieldsValue({ seccionId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.CARGO) {
        const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _cargos = cargos.filter(cargo => cargo.tipoPlanillaId === value);
        setCargosAtom(_cargos);
        formSubsede.setFieldsValue({ cargoId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter(sede => sede.tipoPlanillaId === value);
        setSedesAtom(_sedes);
        formSubsede.setFieldsValue({ sedeId: "" })

      }
    }

  }
  const handleOnchangeCargo = async (value, option) => {
    let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.CARGO);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.cargoId === value)
        setPerfilesAtom(_perfiles);
        formSubsede.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SECCION) {
        const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _secciones = secciones.filter(seccion => seccion.cargoId === value);
        setSeccionesAtom(_secciones);
        formSubsede.setFieldsValue({ seccionId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanillas = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanillas = tipoPlanillas.filter(planilla => planilla.cargoId === value);
        setTipoPlanillasAtom(_tipoPlanillas);
        formSubsede.setFieldsValue({ tipoPlanillaId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter(sede => sede.cargoId === value);
        setSedesAtom(_sedes);
        formSubsede.setFieldsValue({ sedeId: "" })

      }
    }
  }
  return (
    <Form
      layout="vertical"
      onFinish={onClickBtnCrearSubsede}
      form={formSubsede}
    >
      {
        listaCamposAtom.length > 0 && (
          listaCamposAtom.map((listaCampo) => {
            if (listaCampo === CampoColaborador.PERFIL) {
              return (
                <Row>
                  <Col span={12} offset={6}>
                    <Form.Item
                      label="Perfil"
                      name="perfilId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Perfil"
                        },
                      ]}
                    >
                      <Select
                        placeholder="Seleccione Perfil"
                        onChange={handleOnchangePerfil}
                      >
                        {perfilesAtom.length > 0 &&
                          perfilesAtom.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}
                      </Select>

                    </Form.Item>
                  </Col>
                </Row>
              )
            }

            else if (listaCampo === CampoColaborador.SEDE) {
              return (
                <Row>
                  <Col span={12} offset={6}>
                    <Form.Item
                      label="Centro de Costo"
                      name="sedeId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Centro de Costo"
                        },
                      ]}
                    >
                      <Select
                        placeholder="Seleccione Centro de Costo"
                        onChange={handleOnchangeSede}
                      >
                        {sedesAtom.length > 0 &&
                          sedesAtom.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}

                      </Select>

                    </Form.Item>
                  </Col>
                </Row>
              )
            }
            else if (listaCampo === CampoColaborador.SECCION) {
              return (
                <Row>
                  <Col span={12} offset={6}>
                    <Form.Item
                      label="Sección"
                      name="seccionId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Sección"
                        },
                      ]}
                    >
                      <Select
                        placeholder="Seleccione Sección"
                        onChange={handleOnchangeSeccion}
                      >
                        {seccionesAtom.length > 0 &&
                          seccionesAtom.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}
                      </Select>

                    </Form.Item>
                  </Col>
                </Row>
              )
            }
            else if (listaCampo === CampoColaborador.PLANILLA) {
              return (
                <Row>
                  <Col span={12} offset={6}>
                    <Form.Item
                      label="Tipo planilla"
                      name="tipoPlanillaId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Tipo planilla"
                        },
                      ]}
                    >
                      <Select
                        placeholder="Seleccione Tipo planilla"
                        onChange={handleOnchangeTipoPlanilla}
                      >
                        {tipoPlanillasAtom.length > 0 &&
                          tipoPlanillasAtom.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}
                      </Select>

                    </Form.Item>
                  </Col>
                </Row>
              )

            }
            else if (listaCampo === CampoColaborador.CARGO) {
              return (
                <Row>
                  <Col span={12} offset={6}>
                    <Form.Item
                      label="Cargo"
                      name="cargoId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Cargo"
                        },
                      ]}
                    >
                      <Select
                        placeholder="Seleccione Cargo"
                        onChange={handleOnchangeCargo}
                      >
                        {cargosAtom.length > 0 &&
                          cargosAtom.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}
                      </Select>

                    </Form.Item>
                  </Col>
                </Row>
              )
            }

          })

        )

      }
      {/* { dependenciaSubsedeAtom!==CampoColaborador.SINCAMPO &&
              <Row>
              <Col span={12} offset={6}>
                  <Form.Item
                    label={ dependenciaSubsedeAtom===CampoColaborador.SEDE ?"Centro de costo":                            
                            dependenciaSubsedeAtom===CampoColaborador.SECCION ?"Sección" :
                            dependenciaSubsedeAtom===CampoColaborador.PLANILLA ?"Tipo planilla":
                            dependenciaSubsedeAtom}
                    name={nameCampoAtom}
                    rules={[
                      {
                        required: true,
                        message: "Seleccione "+(dependenciaSubsedeAtom===CampoColaborador.SEDE ?"Centro de costo":                            
                                                dependenciaSubsedeAtom===CampoColaborador.SECCION ?"Sección" :
                                                dependenciaSubsedeAtom===CampoColaborador.PLANILLA ?"Tipo planilla":
                                                dependenciaSubsedeAtom),
                      },
                    ]}
                  >
                        <Select 
                          placeholder={ "Seleccione "+(dependenciaSubsedeAtom===CampoColaborador.SEDE ?"Centro de costo":                            
                          dependenciaSubsedeAtom===CampoColaborador.SECCION ?"Sección" :
                          dependenciaSubsedeAtom===CampoColaborador.PLANILLA ?"Tipo planilla":
                          dependenciaSubsedeAtom)}
                        >
                        {listaCampoDependenciaAtom.length > 0 &&
                              listaCampoDependenciaAtom.map((lista) => {
                                          return (
                                            <Option key={lista.id} value={lista.id}>
                                              {lista.nombre}
                                            </Option>
                                          );
                                        })}  
                        </Select>
                  </Form.Item>
                </Col>
              </Row>
          } */}
      <Row >
        <Col span={12} offset={6}>
          <Form.Item
            label="Nombre"
            name="nombre"
            rules={[
              {
                required: true,
                message: "Escribe el nombre del subcentro de costo",
              },
            ]}
          >
            <Input
              placeholder="Nombre del subcentro de costo"
              maxLength="50"
              autoComplete="off"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="center">

        <Form.Item style={{ textAlign: "center" }}>
          <Button
            type="primary"
            icon={<CheckOutlined />}
            htmlType="submit"
          >
            Crear Subcentro de costo
          </Button>
        </Form.Item>


      </Row>
    </Form>

  )

}
export default SubsedeRegistro;