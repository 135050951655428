import React from "react";

import { Row, Col, Card, Divider } from "antd";

export function ContainerCrear({
  icono,
  titulo,
  descripcion,
  colSpan = 24,
  children,
  loading = false,
  divider = true,
}) {
  return (
    <Card loading={loading}>
      <Row justify="center">
        <Col span={colSpan}>
          <div className="container-crear-titulo">
            {icono} {titulo}
          </div>
          <div className="container-crear-descripcion">{descripcion}</div>
          {divider && <Divider />}
          {children}
        </Col>
      </Row>
    </Card>
  );
}
