import React from "react";
import { Tag, Select, Progress, Button } from "antd";
import { useHistory } from "react-router-dom";
import { MenuAcciones } from "../MenuAcciones";
import { BotonDocumento, BotonDocumentoNombre, BotonProgreso } from "../Botones";
import { colaboradores } from "pages/legajo/consultas/ConsultarLegajoColaboradorStore";
import { MenuAvance } from "../MenuAvance/MenuAvance";
import EtiquetaCarpeta from "../Etiqueta/EtiquetaCarpeta";

const { Option } = Select;

export const columnas = [
  {
    title: "#",
    dataIndex: "indice",
    width: "5%",
    render: (text, fila, index) => <BotonDocumento documento={fila} index={index} />,
  },
  {
    title: "Periodo",
    dataIndex: "periodo",
    width: "8%",
  },
  {
    title: "Documento",
    dataIndex: "documento",
    align: "center",
    ellipsis: true,
    width: "30%",
    render: (text, fila) => <BotonDocumentoNombre documento={fila} />,
  },

  {
    title: "Carpeta",
    dataIndex: "carpeta",
    align: "center",
    width: "10%",
    render: (text, fila) => <EtiquetaCarpeta documento={fila} />,
  },
  {
    title: "Fecha Envío",
    dataIndex: "fechaEnvio",
    width: "10%",
  },
  {
    title: "Responsable",
    dataIndex: ["usuarioEnvio", "nombreCompleto"],
    width: "10%",
  },
  {
    title: "% Avance",
    dataIndex: "avance",
    width: "12%",
    align: "center",
    render: (text, fila) => <MenuAvance documento={fila} />,
  },
  {
    title: "Acciones",
    align: "center",
    width: "10%",
    //width: "25%",
    render: (text, fila) => <MenuAcciones envio={fila} />,
  },
];
