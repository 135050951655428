import { Headers } from "enums";
import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";
import { LoginService } from "services/LoginService";

const URL_API = ApiUrl.BASE;

const RenovacionContratosService = {
    async listarContratosAprobadosParaRenovar() {
        const respuesta = await ApiService(URL_API).get(
            `/admin/contratos/renovacion-contrato/contratos-aprobados-por-jefatura`
        );
        return respuesta.data;
    },

    async obtenerFormularioRenovacionContrato(data) {
        const respuesta = await ApiService(URL_API).get(`/admin/contratos/renovacion-contrato/obtener-formulario`, {
            params: { ...data },
        });
        return respuesta.data;
    },

    async guardarDatosFormularioRenovacionContrato(data) {
        const respuesta = await ApiService(URL_API).post(
            `/admin/contratos/renovacion-contrato/guardar-datos-renovacion`,
            data
        );
        return respuesta.data;
    },

    async aprobarRenovacionContrato(data) {
        const respuesta = await ApiService(URL_API).post(`/admin/contratos/renovacion-contrato/aprobar`, data);
        return respuesta.data;
    },

    async getUrlVistaPrevia(seguimientoContratoId) {
        const urlPdf =
            process.env.REACT_APP_ENDPOINT +
            `admin/contratos/renovacion-contrato/vista-previa/${seguimientoContratoId}`;
        const httpHeaders = {
            Authorization: "Bearer " + LoginService.getJwtToken(),
            [Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
            [Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
        };

        return {
            url: urlPdf,
            httpHeaders: httpHeaders,
        };
    },
};

export { RenovacionContratosService };
