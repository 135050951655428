import React, { useState } from "react";
import { Button, Space, notification, message,Dropdown } from "antd";
import { PlusOutlined, ContainerOutlined, HddOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useAtom } from "jotai";
// import * as atoms from "../../ConsultaHorariosPageStore";
// import { VacacionesServiceAnterior } from "services/VacacionesService.bk";
// import { EnvioAbsentismoRechazo } from "enums/EnvioAbsentismoRechazo";

const Botones = () => {
  // const [solicitudesVacacionesIds, setSolicitudesVacacionesIds] = useAtom(atoms.solicitudesVacacionesIdsAtom);
  // const [visibleModalRechazo, setVisibleModalRechazo] = useAtom(atoms.visibleModalRechazoAtom);
  // const [envioRechazo, setEnvioRechazo] = useAtom(atoms.envioRechazoAtom);
  // const [solicitudesVacaciones, setSolicitudesVacaciones] = useAtom(atoms.solicitudesVacacionesAtom);
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const onClickBtnMasAcciones = (e) => {
		if (e.key === "permanente") {
      onclickBtnpermanente();
		}else if(e.key === "actualizar-masivo-responsable"){
      //console.log("ACTUALIZAR");
      //setVisibleModalActualizarResponsables(true);
    }
	};

  const onclickBtnpermanente = (e) => {
		history.push("/asistencia/horarios/crear");
	};

  const BotonCrear = () => {
    const onClickBtnAprobar = () => {
      // try {
      //   setLoading(true);
      //   let vacaciones = solicitudesVacaciones;
      //   solicitudesVacacionesIds.map(async (solicitudId) => {
      //     vacaciones = vacaciones.filter((solicitudV) => solicitudV.id !== solicitudId);
      //     await VacacionesServiceAnterior.aprobar(solicitudId);
      //   });
      //   message.success("La solicitudes han sido aprobadas.");
      //   setSolicitudesVacaciones(vacaciones);
      //   setSolicitudesVacacionesIds([]);
      //   setLoading(false);
      // } catch (error) {
      //   setLoading(false);
      //   notification.error({
      //     message: "No se puede aprobar la solicitud",
      //     description: error.response.data.mensaje,
      //   });
      // }
    };

    return (
      <Dropdown.Button
						menu={{
							items: [
								{
									label: "Permanente",
									key: "permanente",
									icon: <ContainerOutlined style={{ fontSize: "18px" }} />,
								},
							],
							onClick: onClickBtnMasAcciones,
						}}
						icon={<HddOutlined />}
						trigger={["click"]}
						arrow
			>
						Crear
			</Dropdown.Button>
    );
  };
  // const BotonRechazar = () => {
  //   const onClickBtnRechazar = () => {
  //     setVisibleModalRechazo(true);
  //     setEnvioRechazo(EnvioAbsentismoRechazo.MASIVA);
  //   };
  //   return (
  //     <Button type="primary" onClick={onClickBtnRechazar} disabled={solicitudesVacacionesIds.length > 0 ? false : true}>
  //       Rechazar
  //     </Button>
  //   );
  // };
  // const BotonSeguimientoVacaciones = () => {
  //   const onClickBtnIrSeguimiento = () => {
  //     history.push("/vacaciones/seguimiento");
  //   };
  //   return (
  //     <Button type="primary" onClick={onClickBtnIrSeguimiento}>
  //       Ir al Seguimimiento
  //     </Button>
  //   );
  // };
  return (
    <React.Fragment>
      <Space>
        <BotonCrear />
        {/* <BotonRechazar />
        <BotonSeguimientoVacaciones /> */}
      </Space>
    </React.Fragment>
  );
};
export default Botones;
