import { useEffect } from "react";
import { useAtom } from "jotai";
import { PlantillaService } from "services/reclutamiento-y-seleccion";
import { plantillasAtom } from "../ProcesosCrearStore";

const useCargarPlantillas = () => {
  const [plantillas, setPlantillas] = useAtom(plantillasAtom);

  useEffect(() => {
    const cargarDatos = async () => {
      const plantillas = await PlantillaService.listar();
      setPlantillas(plantillas);
    };
    cargarDatos();
  }, []);

  return { plantillas };
};

export default useCargarPlantillas;
