import React, { useContext, useState,useEffect } from "react";
import {  Form, Input, Button,Row,Col,Select } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useModalConfirmar } from "store/global"
import { SecurityContext } from "context/SecurityContextProvider";
import * as atoms from "../CrearPerfilStore";
import { useAtom } from "jotai";
import {CampoColaborador} from "enums/CampoColaborador";
import SeccionService from "services/SeccionService";
import SubsedeService from "services/SubsedeService";
import SedeService from "services/SedeService";
import TipoPlanillaService from "services/TipoPlanillaService";
import CargoService from "services/CargoService";

const PerfilRegistro = () =>{    
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const[,setPerfilAtom]=useAtom(atoms.perfil);   
    const mostrarModalConfirmacion = useModalConfirmar(state => state.mostrarModal);

    const [dependenciaPerfilAtom,setDependenciaPerfilAtom]=useAtom(atoms.dependenciaPerfil);
    const [listaCamposAtom,setListaCamposAtom]=useAtom(atoms.listaCampos);
    const [seccionesAtom,setSeccionesAtom]=useAtom(atoms.secciones);
    const [sedesAtom,setSedesAtom]=useAtom(atoms.sedes);
    const [subsedesAtom,setSubsedesAtom]=useAtom(atoms.subsedes);
    const [tipoPlanillasAtom,setTipoPlanillasAtom]=useAtom(atoms.tipoPlanillas);
    const [cargosAtom,setCargosAtom]=useAtom(atoms.cargos);
    const [camposColaboradorAtom,setCamposColaboradorAtom]=useAtom(atoms.camposColaborador)
    
    const { Option } = Select;
    const [formPerfil] = Form.useForm();

    useEffect(()=>{
      const cargarDatosIniciales = async ()=>{               
        var listaCampos=[],dependencia;
        const empresa = getUsuarioAutenticado().empresaSeleccionada.id;
        const campos_colaborador=getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
        setCamposColaboradorAtom(campos_colaborador);


        const campo_perfil= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.PERFIL);

        const perfilDependencia=campo_perfil.campoDependencia;            
        

        if(perfilDependencia!==null){

            setDependenciaPerfilAtom(perfilDependencia);
            dependencia=perfilDependencia;
            listaCampos.push(dependencia);

            while(dependencia!==null){
              var campo= campos_colaborador.find(campoColaborador=>campoColaborador.campo===dependencia);
              dependencia=campo.campoDependencia;
              if(dependencia!==null){
                listaCampos.push(dependencia);
              }
            
            }
          setListaCamposAtom(listaCampos.reverse());                 
            
        }
        else{  
          setListaCamposAtom(listaCampos);        
          setDependenciaPerfilAtom(CampoColaborador.SINCAMPO);         
        }

        if(listaCampos.length>0 ){
          const primerCampo=listaCampos[0];
          if(primerCampo===CampoColaborador.SECCION){
            const secciones= await SeccionService.listar(empresa);
            setSeccionesAtom(secciones);
          }
          else if(primerCampo===CampoColaborador.SEDE){
            const sedes= await SedeService.listar(empresa);
            setSedesAtom(sedes);
    
          }
          else if(primerCampo===CampoColaborador.SUBSEDE){           
            const subsedes= await SubsedeService.listar(empresa);
            setSubsedesAtom(subsedes);
    
          }
          else if(primerCampo===CampoColaborador.PLANILLA){
            const planillas= await TipoPlanillaService.listar(empresa);
            setTipoPlanillasAtom(planillas);
            
          }
          else if(primerCampo===CampoColaborador.CARGO){
            const cargos = await CargoService.listar(empresa);
            setCargosAtom(cargos);
          }       
       }      
     }
      cargarDatosIniciales();

    },[]);
    
    const onClickBtnCrearPerfil=(perfil)=>{
      perfil.empresaId=getUsuarioAutenticado().empresaSeleccionada.id;
      perfil.dependencia=dependenciaPerfilAtom;     
      setPerfilAtom(perfil);
      mostrarModalConfirmacion();
    }
    const handleOnchangeSeccion = async(value,option)=>{

      let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.SECCION);
  
      for(let i=0 ; i < listaCampos.length ; i++){
         if (listaCampos[i].campo===CampoColaborador.SEDE){         
           const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _sedes = sedes.filter(sede=>sede.seccionId===value)
           setSedesAtom(_sedes);  
           formPerfil.setFieldsValue({ sedeId:""})
               
           
         }         
         else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
           const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _subsedes = subsedes.filter(subsede=>subsede.seccionId===value);
           setSubsedesAtom(_subsedes);
           formPerfil.setFieldsValue({ subsedeId:""})
          
         }
         else if(listaCampos[i].campo===CampoColaborador.CARGO){
           const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _cargos = cargos.filter(cargo=>cargo.seccionId===value);
           setCargosAtom(_cargos);
           formPerfil.setFieldsValue({ cargoId:""})
           
         }
         else if(listaCampos[i].campo===CampoColaborador.PLANILLA){
           const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _tipoPlanilla = tipoPlanilla.filter(planilla=>planilla.seccionId===value);
           setTipoPlanillasAtom(_tipoPlanilla);
           formPerfil.setFieldsValue({ tipoPlanillaId:""})
           
         }
        }   
    }
    const handleOnchangeSede = async(value,option)=>{
      let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.SEDE);
  
      for(let i=0 ; i < listaCampos.length ; i++){
         if (listaCampos[i].campo===CampoColaborador.SECCION){         
           const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _secciones = secciones.filter(seccion=>seccion.sedeId===value)
           setSeccionesAtom(_secciones);  
           formPerfil.setFieldsValue({ seccionId:""})
               
           
         }         
         else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
           const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _subsedes = subsedes.filter(subsede=>subsede.sedeId===value);
           setSubsedesAtom(_subsedes);
           formPerfil.setFieldsValue({ subsedeId:""})
          
         }
         else if(listaCampos[i].campo===CampoColaborador.CARGO){
           const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _cargos = cargos.filter(cargo=>cargo.sedeId===value);
           setCargosAtom(_cargos);
           formPerfil.setFieldsValue({ cargoId:""})
           
         }
         else if(listaCampos[i].campo===CampoColaborador.PLANILLA){
           const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _tipoPlanilla = tipoPlanilla.filter(planilla=>planilla.sedeId===value);
           setTipoPlanillasAtom(_tipoPlanilla);
           formPerfil.setFieldsValue({ tipoPlanillaId:""})
           
         }
        }   
    }
    const handleOnchangeSubsede = async(value,option)=>{
      let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.SUBSEDE);
  
      for(let i=0 ; i < listaCampos.length ; i++){
         if (listaCampos[i].campo===CampoColaborador.SECCION){         
           const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _secciones = secciones.filter(seccion=>seccion.subsedeId===value)
           setSeccionesAtom(_secciones);  
           formPerfil.setFieldsValue({ seccionId:""})
               
           
         }         
         else if(listaCampos[i].campo===CampoColaborador.SEDE){
           const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _sedes = sedes.filter(sede=>sede.subsedeId===value);
           setSedesAtom(_sedes);
           formPerfil.setFieldsValue({ sedeId:""})
          
         }
         else if(listaCampos[i].campo===CampoColaborador.CARGO){
           const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _cargos = cargos.filter(cargo=>cargo.subsedeId===value);
           setCargosAtom(_cargos);
           formPerfil.setFieldsValue({ cargoId:""})
           
         }
         else if(listaCampos[i].campo===CampoColaborador.PLANILLA){
           const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _tipoPlanilla = tipoPlanilla.filter(planilla=>planilla.subsedeId===value);
           setTipoPlanillasAtom(_tipoPlanilla);
           formPerfil.setFieldsValue({ tipoPlanillaId:""})
           
         }
        }   
      
    }
    const handleOnchangeTipoPlanilla = async(value,option)=>{
      let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.PLANILLA);
  
      for(let i=0 ; i < listaCampos.length ; i++){
         if (listaCampos[i].campo===CampoColaborador.SECCION){         
           const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _secciones = secciones.filter(seccion=>seccion.tipoPlanillaId===value)
           setSeccionesAtom(_secciones);  
           formPerfil.setFieldsValue({ seccionId:""})
               
           
         }         
         else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
           const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _subsedes = subsedes.filter(subsede=>subsede.tipoPlanillaId===value);
           setSubsedesAtom(_subsedes);
           formPerfil.setFieldsValue({ subsedeId:""})
          
         }
         else if(listaCampos[i].campo===CampoColaborador.CARGO){
           const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _cargos = cargos.filter(cargo=>cargo.tipoPlanillaId===value);
           setCargosAtom(_cargos);
           formPerfil.setFieldsValue({ cargoId:""})
           
         }
         else if(listaCampos[i].campo===CampoColaborador.SEDE){
           const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _sedes =  sedes.filter(sede=>sede.tipoPlanillaId===value);
           setSedesAtom(_sedes);
           formPerfil.setFieldsValue({ sedeId:""})
           
         }
        }   

    }
    const handleOnchangeCargo = async(value,option)=>{
      let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.CARGO);
  
      for(let i=0 ; i < listaCampos.length ; i++){
         if (listaCampos[i].campo===CampoColaborador.SECCION){         
           const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _secciones = secciones.filter(seccion=>seccion.cargoId===value)
           setSeccionesAtom(_secciones);  
           formPerfil.setFieldsValue({ seccionId:""})
               
           
         }         
         else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
           const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _subsedes = subsedes.filter(subsede=>subsede.cargoId===value);
           setSubsedesAtom(_subsedes);
           formPerfil.setFieldsValue({ subsedeId:""})
          
         }
         else if(listaCampos[i].campo===CampoColaborador.PLANILLA){
           const tipoPlanillas=await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _tipoPlanillas = tipoPlanillas.filter(planilla=>planilla.cargoId===value);
           setTipoPlanillasAtom(_tipoPlanillas);
           formPerfil.setFieldsValue({ tipoPlanillaId:""})
           
         }
         else if(listaCampos[i].campo===CampoColaborador.SEDE){
           const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _sedes =  sedes.filter(sede=>sede.cargoId===value);
           setSedesAtom(_sedes);
           formPerfil.setFieldsValue({ sedeId:""})
           
         }
        }   
    }
    return(
        <Form
           layout="vertical"                      
           onFinish={onClickBtnCrearPerfil}
           form={formPerfil}
        > 
           {
              listaCamposAtom.length>0 &&(
                listaCamposAtom.map((listaCampo)=>{
                    if (listaCampo===CampoColaborador.SECCION){   
                      return(
                            <Row>
                                    <Col span={12} offset={6}>
                                    <Form.Item
                                        label="Sección"
                                        name="seccionId"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Seleccione Sección"
                                          },
                                        ]}
                                    >
                                      <Select
                                      placeholder="Seleccione Sección"
                                      onChange={handleOnchangeSeccion}
                                      >
                                      {seccionesAtom.length > 0 &&
                                        seccionesAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                      </Select>

                                    </Form.Item>
                                    </Col>
                          </Row>
                      )       
                      }                                             
                      
                      else if (listaCampo===CampoColaborador.SEDE){
                        return(
                          <Row>
                                    <Col span={12} offset={6}>
                                    <Form.Item
                                        label="Sede"
                                        name="sedeId"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Seleccione Sede"
                                          },
                                        ]}
                                    >
                                      <Select
                                      placeholder="Seleccione Sede"
                                      onChange={handleOnchangeSede}
                                      >
                                      {sedesAtom.length > 0 &&
                                        sedesAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                        
                                      </Select>

                                    </Form.Item>
                                    </Col>
                          </Row>
                        )
                      }
                      else if (listaCampo===CampoColaborador.SUBSEDE){
                        return(
                          <Row>
                                <Col span={12} offset={6}>
                                <Form.Item
                                    label="Subsede"
                                    name="subsedeId"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Seleccione Subsede"
                                      },
                                    ]}
                                >
                                  <Select
                                  placeholder="Seleccione Subsede"
                                  onChange={handleOnchangeSubsede}
                                  >
                                  {subsedesAtom.length > 0 &&
                                        subsedesAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                  </Select>

                                </Form.Item>
                                </Col>
                        </Row>
                        )
                      }
                      else if (listaCampo===CampoColaborador.PLANILLA){
                        return(
                          <Row>
                                <Col span={12} offset={6}>
                                <Form.Item
                                    label="Tipo planilla"
                                    name="tipoPlanillaId"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Seleccione Tipo planilla"
                                      },
                                    ]}
                                >
                                  <Select
                                  placeholder="Seleccione Tipo planilla"
                                  onChange={handleOnchangeTipoPlanilla}
                                  >
                                  {tipoPlanillasAtom.length > 0 &&
                                        tipoPlanillasAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                  </Select>

                                </Form.Item>
                                </Col>
                        </Row>
                        )
                
                      }
                      else if (listaCampo===CampoColaborador.CARGO){
                        return(
                          <Row>
                                <Col span={12} offset={6}>
                                <Form.Item
                                    label="Cargo"
                                    name="cargoId"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Seleccione Cargo"
                                      },
                                    ]}
                                >
                                  <Select
                                    placeholder="Seleccione Cargo"
                                    onChange={handleOnchangeCargo}
                                  >
                                  {cargosAtom.length > 0 &&
                                        cargosAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                  </Select>

                                </Form.Item>
                                </Col>
                        </Row>
                        )             
                      }                      
                                        
                })
                
              )           
            
          }     
          <Row> 
              <Col span={12} offset={6}>   
                <Form.Item                  
                  label="Nombre"
                  name="nombre"
                  rules={[
                    {
                      required: true,
                      message: "Escribe el nombre del perfil",
                    },
                  ]}
                >
                  <Input
                    placeholder="Nombre del perfil"
                    maxLength="50"
                    autoComplete="off"
                  />
                </Form.Item>
            </Col>
            </Row> 
          
        <Row justify="center">
          
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}                  
                  htmlType="submit"
                >
                  Crear Perfil
                </Button>
              </Form.Item>           
         
        </Row>
        </Form>

    )

}
export default PerfilRegistro;