import { BaseService } from "./BaseService";
import qs from "qs";
const ConsultarMensajeColaboradorService = {
  async listar(empresaId, usuarioEnvioId, periodoInicio, periodoFin, pagina, cantidad) {
    const respuesta = await BaseService.get("/consultaColaborador", {
      params: {
        empresaId: empresaId,
        usuarioEnvioId: usuarioEnvioId,
        periodoInicio: periodoInicio,
        periodoFin: periodoFin,
        pagina: pagina,
        cantidad: cantidad,
      },
    });
    return respuesta.data;
  },

  async listarPaginado(
    //vinculoLaboral,
    empresaId,
    usuarioEnvioId,
    tipoDocumento,
    colaborador,
    documentoIdentidad,
    codigoColaborador,
    tipoConsulta,
    estadoConsulta,
    periodoInicio,
    periodoFin,
    pagina
  ) {
    const respuesta = await BaseService.get("/consultaColaboradorPaginado", {
      params: {
        //vinculoLaboral: vinculoLaboral,
        empresaId: empresaId,
        usuarioEnvioId: usuarioEnvioId,
        tipoDocumento: tipoDocumento,
        colaborador: colaborador,
        documentoIdentidad: documentoIdentidad,
        codigoColaborador: codigoColaborador,
        tipoConsulta: tipoConsulta,
        estadoConsulta: estadoConsulta,
        periodoInicio: periodoInicio,
        periodoFin: periodoFin,
        pagina: pagina,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    return respuesta.data;
  },
  async descargarExcel(empresaId,usuarioEnvioId,tipoDocumento, colaborador, documentoIdentidad,codigoColaborador,tipoConsulta,estadoConsulta,periodoInicio,periodoFin) {
      const respuesta = await BaseService.get(
        "/consultaColaborador/descargarReporteExcel",
       
        {
          responseType: "blob",
          params: {
            empresaId: empresaId,
            usuarioEnvioId:usuarioEnvioId,         
            tipoDocumento: tipoDocumento,
            colaborador: colaborador,
            documentoIdentidad: documentoIdentidad,
            codigoColaborador:codigoColaborador, 
            tipoConsulta:tipoConsulta,
            estadoConsulta:estadoConsulta,
            periodoInicio:periodoInicio,
            periodoFin:periodoFin,        
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: "repeat" });
          },
        }
      );
      return respuesta;
    },
};
   /* async listar(empresaId,usuarioEnvioId,periodoInicio,periodoFin,pagina,
      cantidad,){
        const respuesta= await BaseService.get("/consultaColaborador",{
         params: {
            empresaId : empresaId,
            usuarioEnvioId : usuarioEnvioId,           
            periodoInicio: periodoInicio,
            periodoFin: periodoFin,
            pagina:pagina,
            cantidad:cantidad,
         },
        });
        return respuesta.data;
     },

     async listarPaginado(empresaId,usuarioEnvioId,tipoDocumento, colaborador, documentoIdentidad,codigoColaborador,tipoConsulta,estadoConsulta,periodoInicio,periodoFin,pagina) {
      const respuesta = await BaseService.get("/consultaColaboradorPaginado", {
        params: {
          empresaId: empresaId,
          usuarioEnvioId:usuarioEnvioId,         
          tipoDocumento: tipoDocumento,
          colaborador: colaborador,
          documentoIdentidad: documentoIdentidad,
          codigoColaborador:codigoColaborador, 
          tipoConsulta:tipoConsulta,
          estadoConsulta:estadoConsulta,
          periodoInicio:periodoInicio,
          periodoFin:periodoFin,        
          pagina:pagina
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: "repeat" });
        },
      });
      return respuesta.data;
    },*/
    

//}
export default ConsultarMensajeColaboradorService;
