import React from "react";
import {
    FileDoneOutlined, CheckCircleOutlined
  } from '@ant-design/icons';
  import moment from 'moment';



export const columnaVerificado = [

    {
        title:"Documento de Identidad",
        dataIndex:"numeroDocumentoIdentidad",
        align:"center",            

    },
    {
    title:"Nombres completos",
    dataIndex:"nombresCompletos",
    align:"center",            

    },
    {
        title:"Correo Verificado",
        dataIndex:"correoElectronicoVerificado",
        align:"center",  
        render: ((correoElectronico)=><div><CheckCircleOutlined style={{ fontSize: '20px', color: 'green' }} /> {correoElectronico}</div>)
    },
    {
        title:"Celular Verificado",
        dataIndex:"celularVerificado",
        align:"center",  
        render: ((celular)=><div><CheckCircleOutlined style={{ fontSize: '20px', color: 'green' }} /> {celular}</div>)
    },
   {
        title:"Fecha de Envío",
        dataIndex:"fechaEnvio",
        align:"center",  
        render: ((fechaEnvio) => moment(new Date(fechaEnvio)).format("DD/MM/YYYY HH:mm"))
    } ,
    // {
    //     title:"Acciones",
    //     key:"acciones",
    //     align:"center",
    //     render:(fila)=><FileDoneOutlined />
    // }
        
];