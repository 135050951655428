export const OPCIONES = Object.freeze({
  PAGINA_CONSULTAR_POR_DOCUMENTOS: {
    BOTON_VER_DOCUMENTO: "consulta.documento.ver-documento",
    BOTON_REENVIAR_DOCUMENTO: "consulta.documento.reenviar-documento",
    BOTON_INHABILITAR_DOCUMENTO: "consulta.documento.inhabilitar-documento",
    BOTON_HABILITAR_DOCUMENTO: "consulta.documento.habilitar-documento",
    BOTON_ACTUALIZAR_CORREO: "consulta.documento.actualizar-correo",
    BOTON_HISTORIAL_NOTIFICACION: "consulta.documento.historial-notificacion",
  },
  PAGINA_CONSULTAR_POR_NOTIFICACIONES: {
    BOTON_VER_DETALLE: "notificacion.seguimiento.v2.ver-detalle",
    BOTON_GENERAR_REPORTE_EXCEL: "notificacion.seguimiento.v2.generar-reporte-excel",
    BOTON_GENERAR_REPORTE_PDF: "notificacion.seguimiento.v2.generar-reporte-pdf",
    BOTON_ASIGNAR_CARPETA: "notificacion.seguimiento.v2.asignar-carpeta",
    BOTON_ELIMINAR: "notificacion.seguimiento.v2.eliminar",
  },
  PAGINA_DETALLE_NOTIFICACION: {
    BOTON_VER_DOCUMENTO: "notificacion.seguimiento.v2.ver-detalle.ver-documento",
    BOTON_REENVIAR_DOCUMENTO: "notificacion.seguimiento.v2.ver-detalle.reenviar-documento",
    BOTON_INHABILITAR_DOCUMENTO: "notificacion.seguimiento.v2.ver-detalle.inhabilitar-documento",
    BOTON_HABILITAR_DOCUMENTO: "notificacion.seguimiento.v2.ver-detalle.habilitar-documento",
    BOTON_ACTUALIZAR_CORREO: "notificacion.seguimiento.v2.ver-detalle.actualizar-correo",
    BOTON_HISTORIAL_NOTIFICACION: "notificacion.seguimiento.v2.ver-detalle.historial-notificacion",
  },
  PAGINA_CONSULTAR_POR_CARPETAS: {
    BOTON_VER_DOCUMENTO: "legajo.v2.consultas.ver-documento",
  },
  PAGINA_MANTENIMIENTO_COLABORADOR: {
    TXT_EDITAR_VER_SUELDO: "mantenimiento.colaborador.editar.ver-sueldo",
    TXT_REPORTE_VER_SUELDO: "mantenimiento.colaborador.reporte.ver-sueldo",
    TXT_OMITIR_RANGO_SUELDO: "mantenimiento.colaborador.omitir.rango-sueldo"
  },
  PAGINA_VACACIONES_SEGUIMIENTO_SOLICITUDES:{
    BOTON_CREAR_SOLICITUD:"vacaciones-y-ausencias.ausencias.solicitudes.crear-solicitud" ,
    BOTON_EXPORTAR_SOLICITUDES:"vacaciones-y-ausencias.ausencias.solicitudes.exportar-solicitudes",
  },
});
