import React, { useEffect, useState } from "react";
import { Modal, Descriptions,notification } from "antd";
import { useAtom } from "jotai";
import { visibleModalConfirmacionAtom } from "../../store/NotificarFormularioStore";
import { FormularioNotificarService } from "services/FormularioNotificarService";
import { useHistory } from "react-router";

const ModalConfirmacion = ({
  tipoDocumento,
  descripcion,
  periodo,
  tipoEnvio,
  responsableFirmaDocumentos,
  formulario,
}) => {
  let history = useHistory();
  const [visibleModalConfirmacion, setVisibleModalconfirmacion] = useAtom(visibleModalConfirmacionAtom);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visibleModalConfirmacion) {
      console.log("formulario", formulario);
    }
  }, [visibleModalConfirmacion]);

  const onClickBtnConfirmar = async () => {
    try {
      setLoading(true);
      console.log("onClickBtnConfirmar::",formulario);
      //const respuesta={};
      const respuesta = await FormularioNotificarService.notificar(formulario);
      onClickBtnCerrar();
      setLoading(false);
      if(respuesta.idEnvio!==""){
        history.push(`/notificacion/seguimiento/detalle/Formulario/${respuesta.idEnvio}`);
      }
      else{
        notification.warning({
         // message:"Alerta",
          description:"No se ha encontrado colaboradores para notificar el formulario",
        })
      }
      
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const onClickBtnCerrar = () => {
    setVisibleModalconfirmacion(false);
  };

  return (
    <Modal
      title="Detalle de Notificación"
      visible={visibleModalConfirmacion}
      onOk={onClickBtnConfirmar}
      onCancel={onClickBtnCerrar}
      cancelButtonProps={{ style: { display: "none" } }}
      okText="Notificar"
      okButtonProps={{ loading: loading }}
      forceRender
      width={600}
    >
      <Descriptions column={1} bordered>
        <Descriptions.Item label="Tipo Documento">{tipoDocumento}</Descriptions.Item>
        <Descriptions.Item label="Descripción">{descripcion}</Descriptions.Item>
        <Descriptions.Item label="Periodo">{periodo}</Descriptions.Item>
        <Descriptions.Item label="Tipo de Envío">
          {tipoEnvio
            .replace(/\w\S*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            })
            .replace(/_/g, " ")}
        </Descriptions.Item>
        <Descriptions.Item label="Responsable de Firma">
          {responsableFirmaDocumentos ? responsableFirmaDocumentos : "Sin Configuración"}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  );
};
export default ModalConfirmacion;
