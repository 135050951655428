import React, { useContext, useEffect, useState } from "react";
import { Container } from "components/Container";
import { ModalConfirmar } from "components/Modal/ModalConfirmar";
import { TipoDocumentoEditar } from "./tipo-documento-editar";
import { useHistory } from "react-router";
import * as atoms from "./EditarTipoDocumentoStore";
import { useAtom } from "jotai";
import { useModalConfirmar } from "store/global";
import TipoDocumentoService from "services/TipoDocumentoService";
import { notification} from "antd";


const EditarTipoDocumentoPage=()=>{
    let history = useHistory();
    const cerrarModalConfirmacion = useModalConfirmar(state => state.cerrarModal);
    const [tipoDocumento] = useAtom(atoms.tipoDocumento);
    const [loading, setLoading] = useState(false);
    const onClickBtnConfirmarModal = async () => {
        try {
            setLoading(true);
            const _tipoDocumento = await TipoDocumentoService.actualizar(
                tipoDocumento
            );
            cerrarModalConfirmacion();
            history.push("/configuracion/tipo-documentos/listar");
        } catch (error) {
            console.error(error);
            notification.error({
                message: "No se puede actualizar el Tipo documento",
                description: error.response.data.mensaje,        
              });
        } finally {
            setLoading(false);
        }
    };
    const onClickBtnCancelarModal = () => {
        cerrarModalConfirmacion();
    }
    const onClickRetonarListarTipoDocumentos=()=>{
        history.push("/configuracion/tipo-documentos/listar");
    }
    return(
        <Container titulo=" Editar tipo Documento" onClickRetornar={onClickRetonarListarTipoDocumentos}>
            <TipoDocumentoEditar/>
            <ModalConfirmar
                loading={loading}
                mensaje="¿Está seguro de actualizar el Tipo Documento? "
                onClickBtnCancelar={onClickBtnCancelarModal}
                onClickBtnConfirmar={onClickBtnConfirmarModal} />
        </Container>
        
    )


}
export default EditarTipoDocumentoPage;