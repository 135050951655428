import React from "react";
import { Container } from "components/Container"
import {TablaTipoPlanilla} from "./tabla-tipoPlanilla";
import {Botones} from "./botones";
import {UnorderedListOutlined} from "@ant-design/icons";

const ListarTipoPlanillaPage = () =>{
    return(

        <Container icono={<UnorderedListOutlined />} titulo="Tipo Planilla" botonExtra={<Botones/>}  >
             <TablaTipoPlanilla/>             
        </Container>
    )

}
export default ListarTipoPlanillaPage;