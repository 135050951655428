import React, { useEffect, useState, useContext } from "react";
import { Modal, Form, Select, Space, Row, Input, Button } from "antd";
import { FolderFilled, FolderTwoTone } from "@ant-design/icons";
import { useHistory } from "react-router";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import CarpetaService from "services/CarpetaService";
import { useModalAsignarCarpeta } from "store/global";
import * as atoms from "../../../pages/notificacion/formulario/seguimiento/SeguimientoFormularioStore";
import { useAtom } from "jotai";
import { EnvioService } from "services/EnvioService";
import {enviosAtom} from "pages/seguimiento/listar/ListarSeguimientoStore"

const ModalAsignarCarpeta = ({ onClickBtnCancelar, documento, enviosSeguimiento }) => {
  const visible = useModalAsignarCarpeta((state) => state.visible);
  const cerrarModal = useModalAsignarCarpeta((state) => state.cerrarModal);
  const { Option } = Select;
  const [carpetas, setCarpetas] = useState([]);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [formularioSeleccionado, setFormularioSeleccionado] = useAtom(
    atoms.formularioSeleccionado
  );
  const [envios, setEnvios] = useAtom(enviosAtom );
  const [frmCarpeta] = Form.useForm();

  useEffect(() => {
    const cargarDatos = async () => {
      const _carpetas = await CarpetaService.listar(
        getUsuarioAutenticado().empresaSeleccionada.id
      );
      setCarpetas(_carpetas);
      // if (documento.carpeta !== null) {
      //     frmCarpeta.setFieldsValue(
      //         {
      //             carpetaId: documento.carpeta.id,
      //             carpetaDescripcion: documento.carpeta.descripcion
      //         });

      // }
    };
    cargarDatos();
    // return () => {
    //     cerrarModal();
    // }
  }, []);



  const onFinishCarpeta = async (documentoCarpeta) => {
    //console.log("contratoS:", carpetaDescripcion.carpeta.descripcion);
    documentoCarpeta.id = documento.id;
    await EnvioService.asignarCarpeta(documentoCarpeta);
    if(envios.length>0){
      console.log("se esta modificando");
      const _documentos = [...envios]; //siempre se debe generar un nuevo array
      const indice = envios.findIndex((envio) => envio.id === documento.id);
      const detalleModificar = envios[indice];
      
      const detalleModificado = {
          ...detalleModificar,
          carpeta: {
              descripcion:documentoCarpeta.carpetaDescripcion
          }
      }
      _documentos.splice(indice, 1, detalleModificado);
      setEnvios(_documentos);
    }
    
    cerrarModal();
  };
  const onChangeSelectCarpeta = (value, option) => {
    frmCarpeta.setFieldsValue({ carpetaDescripcion: option.descripcion });
    console.log("des", option.descripcion);
  };
  const onLimpiarDatos = () => {
    frmCarpeta.setFieldsValue({
      carpetaId: "",
      carpetaDescripcion: "",
    });
  };

  return (
    <Modal
      title={<div>Asignar Carpeta a:</div>}
      //title={documento}
      visible={visible}
      onCancel={onClickBtnCancelar}
      footer={null}
      afterClose={onLimpiarDatos}
    >
      <Form
        onFinish={onFinishCarpeta}
        form={frmCarpeta}
        //initialValues={{ carpetaId: documento.carpeta.id }}
      >
        <Form.Item
          label="Carpeta"
          name="carpetaId"
          rules={[
            {
              required: true,
              message: "Seleccione la Carpeta.",
            },
          ]}
        >
          <Select
            onChange={onChangeSelectCarpeta}
            optionLabelProp="label"
            placeholder="Seleccione la carpeta"
          >
            {carpetas.length > 0 &&
              carpetas.map((carpeta) => {
                return (
                  <Option
                    key={carpeta.id}
                    value={carpeta.id}
                    label={
                      <div>
                        <FolderTwoTone
                          style={{ fontSize: "20px", color: "#F7D358" }}
                          twoToneColor="#F7D358"
                        />{" "}
                        {carpeta.descripcion}
                      </div>
                    }
                    descripcion={carpeta.descripcion}
                  >
                    <Space size={carpeta.nivel * 10}>
                      <span></span>
                      <span>
                        {carpeta.nivel === 0 ? (
                          <FolderFilled
                            style={{ fontSize: "20px", color: "#F7D358" }}
                          />
                        ) : (
                          <FolderTwoTone
                            style={{ fontSize: "18px", color: "#F7D358" }}
                            twoToneColor="#F7D358"
                          />
                        )}{" "}
                        {carpeta.descripcion}
                      </span>
                    </Space>
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item name="carpetaDescripcion" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default ModalAsignarCarpeta;
