import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import { ContainerListarDetalle } from "../../../components/ContainerListarDetalle";
import {
  FolderOpenOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import {
  Table,
  Space,
  Menu,
  Button,
  Input,
  Select,
  notification,
  Modal,
  Form,
  Tag,
  Typography,
} from "antd";
import DetalleEnvioService from "../../../services/DetalleEnvioService";
import EnvioService from "../../../services/EnvioService"
import { ModalPDF } from "../../../components/PDFViewer";
import Highlighter from "react-highlight-words";
const { Option } = Select;
const { confirm } = Modal;

export function ContratoListarDetallePage() {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [estado, setEstado] = useState("");
  const [defaultValue, setDefaultValue] = useState("default");
  const [selectedRowKeys, setSelectedRowsKeys] = useState([]);
  const [filaSeleccionada, setFilaSeleccionada] = useState("");
  const { Text } = Typography;


  const [detalleEnvio, setDetalleEnvio] = useState([]);
  const { getDatosEnvio } = useContext(SecurityContext);
  let envioId = getDatosEnvio().id;
  const [url, setUrl] = useState("");
  const [visible, setVisible] = useState("");
  const [loading, setLoading] = useState(false);
  const [habilitado, setHabilitado] = useState(true);
  const [documentoNombre, setDocumentoNombre] = useState("");
  const [fechaFirma, setFechaFirma] = useState("");
  const [representanteLegal, setRepresentanteLegal] = useState("");
  const [nombreColaborador, setNombreColaborador] = useState("");
  const [fechaFirmaColaborador, setFechaFirmaColaborador] = useState("");
  const [estadoDetalleEnvio, setEstadoDetalleEnvio] = useState();
  const [detalleEnvioVisualizados, setDetalleEnvioVisualizados] = useState([]);
  const [detalleEnvioNoVisualizados, setDetalleEnvioNoVisualizados] = useState([]);
  const [detalleEnvioErroneos, setDetalleEnvioErroneos] = useState([]);
  const [filtradoDetalleEnvio, setFiltradoDetalleEnvio] = useState("");

  let history = useHistory();

  useEffect(() => {
    async function cargarDatos() {
      setEstado(-1);
      const detalleEnvio = await DetalleEnvioService.listar(envioId, -1);
      setDetalleEnvio(detalleEnvio);
    }
    cargarDatos();
  }, []);

  var adicionarColumnasFiltro = function getColumns(
    dataIndex,
    placeholder,
    propiedad
  ) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchInput}
              placeholder={
                placeholder !== undefined
                  ? placeholder
                  : `Buscar por ${dataIndex}`
              }
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Buscar
            </Button>
              <Button
                onClick={() => handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Limpiar
            </Button>
            </Space>
          </div>
        ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        propiedad === undefined
          ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
          : record[dataIndex][propiedad]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={
              propiedad === undefined
                ? text?.toString()
                : text[propiedad]?.toString()
            }
          />
        ) : propiedad === undefined ? (
          text
        ) : (
              text[propiedad]
            ),
    };
  };

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText("");
  }
  const columnas = [
    {
      title: "Colaborador",
      dataIndex: "colaborador",
      align: "center",
      ...adicionarColumnasFiltro(
        "colaborador",
        "Buscar por Colaborador",
        "nombreCompleto"
      ),
    },
    {
      title: "Documento",
      dataIndex: "colaborador",
      align: "center",
      width: "120px",
      ...adicionarColumnasFiltro(
        "colaborador",
        "Buscar por Documento",
        "numeroDocumentoIdentidad"
      ),
    },
    {
      title: "Email",
      dataIndex: ["colaborador", "correoElectronico"],
      align: "center",
    },
    {
      title: "Visualizado",
      dataIndex: "visualizado",
      align: "center",
      width: "120px",
      ...adicionarColumnasFiltro("visualizado", "Buscar por visualización"),
    },
    {
      title: "Plataforma Visualización",
      dataIndex: "visualizacionPlataforma",
      align: "center",
      width: "150px",
    },
    {
      title: "Fecha Visualización",
      dataIndex: "visualizacionFecha",
      align: "center",
      width: "150px",
    },
    {
      title: "Estado",
      //dataIndex: "estadoProcesoNombre",
      align: "center",
      width: "160px",
      ...adicionarColumnasFiltro("estado"),
      render: (fila) =>
        fila.estado === "VISUALIZADO" ? (
          <Tag color="success" style={{ textTransform: "uppercase" }}>
            {fila.estado}
          </Tag>

        ) : fila.estado === "NO_VISUALIZADO" ? (
          <Tag color="gold" style={{ textTransform: "uppercase" }}>
            {/* {fila.estadoProcesoNombre} */}
                NO VISUALIZDAO
          </Tag>
        )
            : fila.estado === "PROCESANDO" ? (
              <Tag color="warning" style={{ textTransform: "uppercase" }}>
                {fila.estado}
              </Tag>
            ) : (
                <Tag color="blue" style={{ textTransform: "uppercase" }}>
                  {fila.estado}
                </Tag>
              ),
    },
    {
      title: "Acciones",
      align: "center",
      //width: "130px",
      render: (fila) => (
        <React.Fragment>
          {fila.eliminado ? (
            // <a onClick={() => handleOnClickHabilitarDetalleEnvio(fila.id)}>
            <a>
              Habilitar
            </a>
          ) : (
              <Space size={8}>
                <Select
                  placeholder="Seleccione la Acción"
                  value={defaultValue}
                  style={{ width: 200 }}
                  onSelect={(value) => handleAccionesOnClick(value, fila)}
                  loading={filaSeleccionada === fila.id ? loading : false}
                  allowClear
                >
                  <Option value="default">Seleccione la Acción </Option>
                  <Option value="ver">Ver </Option>
                  <Option value="inhabilitar">Inhabilitar </Option>
                  <Option value="reenviar">Reenviar </Option>
                  {/*<Option value="cambiarcorreo">Cambiar Correo electrónico</Option>*/}
                </Select>
              </Space>
            )}
        </React.Fragment>
      ),
    },
  ];
  function handleAccionesOnClick(value, fila) {

  }
  function handleOnOkModal() {
    setVisible(false);
  }
  function handleOnClickVolverSeguimieto() {
    history.push("/contrato/seguimiento");
  }

  return (
    <ContainerListarDetalle
      icono={<FolderOpenOutlined style={{ fontSize: "50px" }} />}
      titulo={getDatosEnvio().documentoNombre}
      descripcion={getDatosEnvio().descripcion}
      sede={getDatosEnvio().sedeNombre}
    //menu={menu}
    //menuMasOpciones={menuMasOpciones}

    >
      <ModalPDF
        ruta={url}
        visible={visible}
        handleOnOkModal={handleOnOkModal}
        documentoNombre={documentoNombre}
        fechaFirma={fechaFirma}
        representanteLegal={representanteLegal}
        nombreColaborador={nombreColaborador}
        fechaFirmaColaborador={fechaFirmaColaborador}
        estadoDetalleEnvio={estadoDetalleEnvio}
      />

      <div style={{ textAlign: "center" }}>
      </div>
      <div style={{ marginBottom: 16 }}></div>

      <Table
        //components={components}
        rowKey={(detalleEnvio) => detalleEnvio.id}
        columns={columnas}
        //dataSource={detalleEnvio}
        dataSource={
          filtradoDetalleEnvio === "visualizado" ?
            (detalleEnvioVisualizados)
            : filtradoDetalleEnvio === "novisualizados" ?
              (detalleEnvioNoVisualizados)
              : filtradoDetalleEnvio === "erroneos" ?
                (detalleEnvioErroneos)
                : filtradoDetalleEnvio === "todos" ?
                  (detalleEnvio)
                  : detalleEnvio
        }
        //rowClassName="editable-row"
        // pagination={{
        //   pageSize: [10],
        // }}
        pagination={false}
        rowClassName={(detalleEnvio) =>
          detalleEnvio.eliminado ? "detalle-envio_registro-eliminado" : ""
        }
      // rowSelection={{
      // type: "checkbox",
      // // columnWidth: 15,
      // ...rowSelection,
      // }}
      // scroll={{ y: 600 }}

      />
      <Button
        type="primary"
        icon={<ArrowLeftOutlined style={{ fontSize: "20px" }} />}
        onClick={handleOnClickVolverSeguimieto}
      ></Button>
    </ContainerListarDetalle>
  );
}