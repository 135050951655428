import React from "react";

import { Listar, } from "./componentes";
import { useAtom } from "jotai";
import { Container } from "components/Container";
import { ModalVisorPdf } from "components/Modal/ModalVisorPdf";
import { ModalRechazarOnboarding } from "components/Modal/ModalRechazarOnboarding";


const ConsultaOnboarding = () => {
	return (
		<Container titulo="Bandeja de Aprobaciones">
			<Listar />
			<ModalVisorPdf />
			<ModalRechazarOnboarding />
		</Container>
	);
};
export default ConsultaOnboarding;

