import React, { useState, useRef, useEffect } from 'react';
import { Table, Input, Button, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useAtom } from "jotai";
import * as atoms from "../../store/NotificarFormularioStore";

const TablaColaboradores = ({ colaboradores, loading }) => {

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);
    const [, setColaboradoresSeleccionados] = useAtom(atoms.colaboradoresSeleccionados);
    const [colaboradoresSeleccionadosId, setColaboradoresSeleccionadosId] = useAtom(atoms.colaboradoresSeleccionadosId);

    var getColumnSearchProps = function getColumns(dataIndex, propiedad) {
        return {
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={searchInput}
                        placeholder={`Buscar por ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Buscar
                        </Button>
                        <Button
                            onClick={() => handleReset(clearFilters)}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Limpiar
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: (filtered) => (
                <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
            ),
            onFilter: (value, record) =>
                propiedad === undefined
                    ? record[dataIndex]?.toString()
                        .toLowerCase()
                        .includes(value.toLowerCase())
                    : record[dataIndex][propiedad]?.toString()
                        .toLowerCase()
                        .includes(value.toLowerCase()),
            onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                    setTimeout(() => searchInput.current.select());
                }
            },
            render: (text) =>
                searchedColumn === dataIndex ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={
                            propiedad === undefined
                                ? text?.toString()
                                : text[propiedad]?.toString()
                        }
                    />
                ) : propiedad === undefined ? (
                    text
                ) : (
                    text[propiedad]
                ),
        };
    };

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    }

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    }

    const columnas = [
        {
            title: 'Documento Identidad',
            dataIndex: 'numeroDocumentoIdentidad',
            key: 'numeroDocumentoIdentidad',
            align: 'center'
        },
        {
            title: 'Apellidos y Nombres',
            dataIndex: 'nombreCompleto',
            key: 'nombreCompleto',
            ...getColumnSearchProps("nombreCompleto"),
        },
    ];

    const rowSelection = {
        selectedRowKeys: colaboradoresSeleccionadosId,
        onChange: (selectedRowKeys, selectedRows) => {
            setColaboradoresSeleccionadosId(selectedRowKeys);
        },
    }

    useEffect(() => {

        const _colaboradores = [...colaboradores];
        const _colaboradoresSeleccionados = _colaboradores.filter(colaborador => colaboradoresSeleccionadosId.includes(colaborador.id));
        setColaboradoresSeleccionados(_colaboradoresSeleccionados);

    }, [colaboradoresSeleccionadosId]);

    return (

        <Table
            rowKey={(colaborador) => colaborador.id}
            dataSource={colaboradores}
            rowSelection={{ ...rowSelection }}
            columns={columnas}
            pagination={false}
            loading={loading}
            scroll={{ y: 300 }} />

    );
};

export default TablaColaboradores;