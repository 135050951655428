import Base from "antd/lib/typography/Base";
import { BaseService } from "./BaseService";

const CertificadoService = {
  async listar(empresaId, usuarioId, estado) {
    const respuesta = await BaseService.get("/certificado", {
      params: {
        empresaId: empresaId,
        usuarioId: usuarioId,
        estado: estado,
      },
    });
    return respuesta.data;
  },
  async insertar(certificado) {
    const respuesta = await BaseService.post("/certificado", certificado);
    return respuesta.data;
  },
  //elimina mi certificado
  async eliminar(empresaId, usuarioId, id) {
    const respuesta = await BaseService.delete(
      `/certificado/${empresaId}/${usuarioId}/${id}`
    );
    return respuesta.data;
  },

  //elimina certificado digital
  async eliminar(empresaId, id) {
    const respuesta = await BaseService.delete(
      `/certificado/${empresaId}/${id}`
    );
    return respuesta.data;
  },
  async validarCertificadoExpiracion(empresaId, usuarioId, tipoDocumentoId) {
    const respuesta = await BaseService.get("/certificado/certificado-expirado",
      {
        params: {
          empresaId: empresaId,
          usuarioId: usuarioId,
          tipoDocumentoId: tipoDocumentoId,
        },
      }
    );
    return respuesta.data;
  },
  async validarCertificadoEstado(empresaId, tipoDocumentoId,envioId) {
    const respuesta = await BaseService.get("/certificado/estadoCertificado",
      {
        params: {
          empresaId: empresaId,
          tipoDocumentoId: tipoDocumentoId,
          envioId:envioId
        },
      }
    );
    return respuesta.data;
  },
};
export default CertificadoService;
