import React from "react";
import { Container } from "components/Container";
import { TablaSeguimientoContratos} from "./componentes/tabla-seguimiento-contratos";
import {FiltroSeguimientoContrato} from"./componentes/FiltroSeguimientoContrato";
import { Button,notification,Space } from "antd";
import {DownloadOutlined} from "@ant-design/icons";
import {filtroColaboradorAtom,filtroFechaFinAtom} from "./SeguimientoContratosStore";
import { useAtom, Provider as JotaiProvider } from "jotai";
import { ContratoVencidoService } from "services/ContratoVencidoService";
import { saveAs } from "file-saver";

const SeguimientoContratosPage = () => {

  return(
    <JotaiProvider>
      <Container titulo="Contratos vencidos" 
            botonExtra={<BotonesExtra />}>
        <FiltroSeguimientoContrato/>
        <TablaSeguimientoContratos/>
      </Container>
    </JotaiProvider>
  )
}

const BotonesExtra = () => {

  const[filtroColaborador,setFiltroColaborador]=useAtom(filtroColaboradorAtom);
    const [filtroFechaFin,setFiltroFechaFin] = useAtom(filtroFechaFinAtom);

  const onClickDescargar= async()=>{
    
    try {
        const respuesta= await ContratoVencidoService.descargarExcel({
           colaborador:filtroColaborador,
           mesFinContrato:filtroFechaFin,
        })
       
        const filename = respuesta.headers.filename;
      
        saveAs(respuesta.data, filename);
      
    } catch (error) {
      console.log(error);
      notification.error({
        message:"No hay contratos vencidos para exportar",        
        description: error.response.data.mensaje,
       }
      )
    }

  }

  const onClickDescargarTodosContratos = async ()=>{
        try {
          const respuesta= await ContratoVencidoService.descargarTodosContratosTodosColaboradores("");
          
          const filename = respuesta.headers.filename;
          saveAs(respuesta.data, filename);
        } catch (error) {
            console.log(error);
            notification.error({
              message:"No hay contratos para exportar",        
              description: error.response.data.mensaje,
            }
            )
        }
  }

  return (
    <Space>
      <Button icon={<DownloadOutlined /> } onClick={onClickDescargar} type="primary">Exportar</Button>
      <Button icon={<DownloadOutlined />}  onClick={onClickDescargarTodosContratos} type="primary">Exportar contratos de todos los colaboradores</Button>
    </Space>
  )
}

export default SeguimientoContratosPage;