import React,{useEffect} from "react"
import { Container } from "components/Container"
import {TablaReportes} from "./tabla-reportes"
import ModalCrearReporte from "./ModalCrearReporte/ModalCrearReporte"
import { Button } from "antd"
import {UnorderedListOutlined,PlusOutlined} from"@ant-design/icons"
import * as atoms from "./ListarReportesStore"
import { useAtom } from "jotai"

const ListarReportesPage = ()=>{
    const [visibleCrearReporte,setVisibleCrearReporte] =useAtom(atoms.visibleModalCrearReporteAtom);      

    const onClickCrearReporte=()=>{
        setVisibleCrearReporte(true);
    }
return(
    <Container titulo="Reportes" icono={<UnorderedListOutlined/>} botonExtra={<Button type="primary" icon={<PlusOutlined />} onClick={onClickCrearReporte}> Crear Reporte</Button>}>
        <TablaReportes/>
        <ModalCrearReporte/>
    </Container>
)

}
export default ListarReportesPage;