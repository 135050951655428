import React, { useState,useContext } from "react";
import { Menu, Button, Dropdown, Space, Form, DatePicker, Select, Input,Row,Col } from "antd";
import ColaboradorService from "services/ColaboradorService";
import { useAtom } from "jotai";
import {SolicitudAusenciaService}  from "services" ;
import { SecurityContext } from "context/SecurityContextProvider";
import EvaluacionService from "services/EvaluacionService";
import { evaluacionesFiltradasAtom, loadingAtom } from "../EvaluacionSeguimientoStore";
import moment from "moment";
  import { EstadoSolicitudAusencia } from "enums/EstadoSolicitudAusencia";

const FiltroConsultaSolicitudes=()=>{

    const [colaboradores,setColaboradores]=useState(undefined);
    const [evaluacionesFiltradas,setEvaluacionesFiltradas]=useAtom(evaluacionesFiltradasAtom);
    const [loading, setLoading] = useAtom(loadingAtom)

     const[formFiltro] = Form.useForm();
     const { RangePicker } = DatePicker;

    const onSearchColaboradores = async (value)=>{
        if (value === "") {
            setColaboradores([]);
            return;
          }
          const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
            filtro: value.trim(),
          });
          setColaboradores(respuesta.colaboradores);
        };
  
    const actualizarTabla = async (formulario)=>{ 
        setLoading(true)        
            const respuesta = await EvaluacionService.listarConFiltro(formulario.colaborador,
                formulario.meses?formulario.meses[0]?.format("DD/MM/YYYY 00:00"):undefined,
                formulario.meses?formulario.meses[1]?.format("DD/MM/YYYY 00:00"):undefined,
                formulario.estadoJefeInmediato,
                formulario.estado);     
            setEvaluacionesFiltradas(respuesta);
        setLoading(false)
        }  

    return(
            <Form 
               layout="vertical" 
               form={formFiltro}
               onFinish={actualizarTabla}
               initialValues={{
                estado:"",
                estadoJefeInmediato:"",
                meses:[moment().startOf("year"), moment().endOf("year")]
                }}>
                <Row gutter={16}>
                    <Col>
                <Form.Item label="Colaborador" name="colaborador" >
                    <Select
                        style={{ width: "250px" }}
                        allowClear
                        showSearch
                        placeholder="Ingrese el nombre del colaborador"
                        onSearch={onSearchColaboradores}                                              
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={()=>formFiltro.submit()}
                    >
                         {colaboradores!==undefined && colaboradores?.map((colaborador)=>(                       
                            <Select.Option key={colaborador.numeroDocumentoIdentidad} value={colaborador.numeroDocumentoIdentidad}>
                                {colaborador.nombreCompleto}
                            </Select.Option>                      
                        
                     ))}
                    </Select>

                </Form.Item>
                </Col>
                <Col>
                <Form.Item label="Por meses" name="meses">
                <RangePicker picker="month" format={"MM/YYYY"} onChange={()=>formFiltro.submit()} />
                </Form.Item>
                </Col>
                <Col>
                <Form.Item label="Estado Aprobación Jefe Inmediato" name="estadoJefeInmediato" >
                    <Select style={{ width: "250px" }} placeholder="Seleccion el estado"  onChange={()=>formFiltro.submit()} >
                        <Select.Option value="">TODOS</Select.Option>
                        <Select.Option value={EstadoSolicitudAusencia.PENDIENTE}>{EstadoSolicitudAusencia.PENDIENTE}</Select.Option>
                        <Select.Option value={EstadoSolicitudAusencia.APROBADO}>{EstadoSolicitudAusencia.APROBADO}</Select.Option>
                        <Select.Option value={EstadoSolicitudAusencia.RECHAZADO}>{EstadoSolicitudAusencia.RECHAZADO}</Select.Option>
                        
                    </Select>
                </Form.Item>
                </Col>
                <Col>
                <Form.Item label="Estado Aprobación Gestión Humana" name="estado" >
                    <Select style={{ width: "250px" }} placeholder="Seleccion el estado"  onChange={()=>formFiltro.submit()} >
                        <Select.Option value="">TODOS</Select.Option>
                        <Select.Option value={EstadoSolicitudAusencia.PENDIENTE}>{EstadoSolicitudAusencia.PENDIENTE}</Select.Option>
                        <Select.Option value={EstadoSolicitudAusencia.APROBADO}>{EstadoSolicitudAusencia.APROBADO}</Select.Option>
                        <Select.Option value={EstadoSolicitudAusencia.RECHAZADO}>{EstadoSolicitudAusencia.RECHAZADO}</Select.Option>
                        
                    </Select>
                </Form.Item>
                </Col>
               
                {/* <Form.Item>
                    <Button onClick={onClickFiltrar}>Filtrar</Button>
                </Form.Item> */}
                </Row>
            </Form>
    )

}
export default FiltroConsultaSolicitudes;