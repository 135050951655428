import React from "react";
import { useModalRechazarOnboarding, useModalVisorPdf } from "store/global";
import { Button, Popconfirm } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { CategoriaDocumento } from "enums/CategoriaDocumento";

export const Botones = ({ botonesExtra }) => {
  const loading = useModalVisorPdf((state) => state.loading);

  const visibleBtnImprimir = useModalVisorPdf((state) => state.visibleBtnImprimir);
  const visibleBtnDescargar = useModalVisorPdf((state) => state.visibleBtnDescargar);
  const detalleId = useModalVisorPdf(state => state.detalleId)
  const imprimirPdf = useModalVisorPdf((state) => state.imprimirPdf);
  const descargarPdf = useModalVisorPdf((state) => state.descargarPdf);
  const categoriaDocumento = useModalVisorPdf((state) => state.categoriaDocumento);
  const cerrarVisorPdf = useModalVisorPdf((state) => state.cerrarModal);
  const aprobarOnboarding = useModalVisorPdf((state) => state.aprobarOnboarding);
  //const rechazarOnboarding = useModalVisorPdf((state) => state.rechazarOnboarding);
  const mostrarModal = useModalRechazarOnboarding(state => state.mostrarModal);

  const BotonImprimir = () => {
    if (visibleBtnImprimir) {
      return (
        <Button className="milegajo-visor-pdf-header-botones" disabled={loading} onClick={imprimirPdf}>
          Imprimir
        </Button>
      );
    }
    return null;
  };

  const BotonDescargar = () => {
    if (visibleBtnDescargar) {
      return (
        <Button className="milegajo-visor-pdf-header-botones" disabled={loading} onClick={descargarPdf}>
          Descargar
        </Button>
      );
    }
    return null;
  };

  const BotonRechazar = () => {
    if (categoriaDocumento === "ONBOARDING" || categoriaDocumento==="ONBOARDING RECLUTAMIENTO Y SELECCION") {
      return (
        <Button danger className="milegajo-visor-pdf-header-botones"  onClick={()=>mostrarModal(detalleId)}>
          Rechazar
        </Button>
      );
    }
    return null;
  };

  const BotonAprobar = () => {
    if (categoriaDocumento === "ONBOARDING" || categoriaDocumento==="ONBOARDING RECLUTAMIENTO Y SELECCION") {
      return (
        <Popconfirm
          placement="bottom"
          title={"¿Está seguro de aprobar el documento actual?"}
          onConfirm={aprobarOnboarding}
          okText="Si"
          cancelText="No"
        >
          <Button type="primary" className="milegajo-visor-pdf-header-botones" disabled={loading}>
            Aprobar
          </Button>
        </Popconfirm>
      );
    }
    return null;
  };

  const BotonCerrar = () => {
    return (
      <Button type="text" className="cerrar" onClick={cerrarVisorPdf}>
        <CloseOutlined style={{ fontSize: "16px" }} />
      </Button>
    );
  };

  const Separador = () => {
    return <div className="milegajo-visor-pdf-header-separador" />;
  };

  return (
    <div className="acciones">
      {botonesExtra}

      <BotonImprimir />

      <BotonDescargar />

      <BotonRechazar />

      <BotonAprobar />

      <Separador />

      <BotonCerrar />
    </div>
  );
};
