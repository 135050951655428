import {useEffect} from "react";
import { useAtom } from "jotai";
import { EntrevistaService } from "services/reclutamiento-y-seleccion/EntrevistaService";
import { entrevistasAtom } from "../SeguimientoEntrevistasStore";

const useListarEntrevistas =()=>{
    const [entrevistas,setEntrevistas]=useAtom(entrevistasAtom);

useEffect(()=>{
      const cargarDatos= async()=>{        
          const respuesta = await EntrevistaService.listarConFiltros(undefined,null);
          setEntrevistas(respuesta);
        //  console.log("Entrevistas",respuesta);
      }
      cargarDatos();
},[])
   return{entrevistas};

}
export default useListarEntrevistas;