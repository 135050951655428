import React, { useEffect, useState } from "react";
import { Checkbox, Modal, Table } from "antd";
import { visibleModalDocumentoAtom } from "../EditarPlantillaEnvioDocumentosStore";
import { useAtom } from "jotai";
import { documentosListadosAtom } from "../EditarPlantillaEnvioDocumentosStore";
import { documentosSeleccionadosAtom } from "../EditarPlantillaEnvioDocumentosStore";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import TipoDocumentoService from "../../../../../services/TipoDocumentoService";
import { CategoriaDocumento } from "enums/CategoriaDocumento";

const ModalDocumentos = () => {
  const [visibleModalDocumento, setVisibleModalDocumento] = useAtom(visibleModalDocumentoAtom);
  const [documentosListados, setDocumentosListados] = useAtom(documentosListadosAtom);
  const [documentosSeleccionados, setDocumentosSeleccionados] = useAtom(documentosSeleccionadosAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [documentosSeleccionadosTemp, setDocumentosSeleccionadosTemp] = useState([]);
  const [documentosSeleccionadosTempIds, setDocumentosSeleccionadosTempIds] = useState([]);

  useEffect(() => {
    const cargarDatos = async () => {
      //const documentos= await TipoDocumentoService.listarPorEmpresa(getUsuarioAutenticado().empresaSeleccionada.id);
      // const documentos_formularios = await TipoDocumentoService.listar(getUsuarioAutenticado().empresaSeleccionada.id,CategoriaDocumento.FORMULARIOS.id);
      // const documentos_contratos = await TipoDocumentoService.listar(getUsuarioAutenticado().empresaSeleccionada.id,CategoriaDocumento.CONTRATOS.id);
      const documentos2 = await TipoDocumentoService.listarPorUsuarioYFormularioYContrato(
        getUsuarioAutenticado().login
      );
      let documentos = documentos2.CONTRATOS;
      documentos = documentos.concat(documentos2.FORMULARIOS);
      documentos = documentos.concat(documentos2.DOCUMENTO_LABORAL);
      documentos = documentos.concat(documentos2.CARGA_DOCUMENTO);
      setDocumentosListados(documentos);
    };
    cargarDatos();
  }, []);

  useEffect(() => {
    setDocumentosSeleccionadosTempIds(documentosSeleccionados.map((documento) => documento.id));
    setDocumentosSeleccionadosTemp(documentosSeleccionados);
  }, [documentosSeleccionados]);

  const cerrarModal = () => {
    setVisibleModalDocumento(false);
  };
  const onClickAceptar = () => {
    setVisibleModalDocumento(false);

    // const copiaDocumentosSeleccionados= [...documentosSeleccionados];

    //  for(let i=0;i<documentosSeleccionadosTemp.length;i++){
    //     const documentoBuscado=copiaDocumentosSeleccionados.find(item=>item.id===documentosSeleccionadosTemp[i].id);
    //     if(documentoBuscado===undefined){

    //         copiaDocumentosSeleccionados.push(documentosSeleccionadosTemp[i]);
    //     }
    //  }
    setDocumentosSeleccionados(documentosSeleccionadosTemp);
    setDocumentosSeleccionadosTemp([]);
  };
  const columnas = [
    {
      title: "Documentos",
      dataIndex: "nombre",
    },
    {
      title: "Categoria",
      dataIndex: ["categoriaDocumento", "nombre"],
    },
  ];
  const rowSelection = {
    columnTitle: <></>,
    selectedRowKeys: documentosSeleccionadosTempIds,
    onChange: (selectedRowKeys, selectedRows) => {
      // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
      // console.log("IDs: ",selectedRows);
      setDocumentosSeleccionadosTemp(selectedRows);
      setDocumentosSeleccionadosTempIds(selectedRowKeys);
    },

    getCheckboxProps: (record) => ({
      disabled:
        documentosSeleccionadosTemp.filter(
          (documento) =>
            documento.categoriaDocumento.nombre === "CONTRATOS" && record.categoriaDocumento.nombre === "CONTRATOS"
        ).length > 0 && !documentosSeleccionadosTempIds.includes(record.id)
          ? true
          : false,
    }),
  };
  return (
    <Modal
      title="Documentos"
      visible={visibleModalDocumento}
      onCancel={cerrarModal}
      onOk={onClickAceptar}
      destroyOnClose={true}
    >
      <Table
        rowKey={(documento) => documento.id}
        columns={columnas}
        dataSource={documentosListados}
        rowSelection={{
          type: Checkbox,
          ...rowSelection,
        }}
        pagination={false}
        scroll={{ y: 240 }}
      />
    </Modal>
  );
};
export default ModalDocumentos;
