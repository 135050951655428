import React, { useContext, useEffect, useState } from "react";
import { Space, Modal, Form, Row, Col, Typography, Select } from "antd";
import { FolderFilled, FolderTwoTone } from "@ant-design/icons";
import { useAtom } from "jotai";
import {
  categoriasAtom,
  empresasAgregadasAtom,
  empresaSeleccionadaAtom,
  empresasEnviadasAtom,
  opcionesAtom,
  opcionesSeleccionadasAtom,
  visibleModalFiltrarAtom,
} from "../../UsuarioEditarStore";
import { SecurityContext } from "context/SecurityContextProvider";
import { CampoColaborador } from "enums/CampoColaborador";
import SedeService from "services/SedeService";
import SubsedeService from "services/SubsedeService";
import SeccionService from "services/SeccionService";
import CargoService from "services/CargoService";
import TipoPlanillaService from "services/TipoPlanillaService";
import PerfilService from "services/PerfilService";
import produce from "immer";
import { CampoSelect } from "components";
import TipoDocumentoService from "services/TipoDocumentoService";
import CarpetaService from "services/CarpetaService";
import { PlantillaEnvioDocumentoService } from "services/PlantillaEnvioDocumentoService";
const { Text, Title } = Typography;
const { Option } = Select;

const ModalFiltrar = () => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const camposColaboradores = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
  const [frmConfigurarFiltros] = Form.useForm();
  const [visible, setVisible] = useAtom(visibleModalFiltrarAtom);
  const [empresaSeleccionada, setEmpresaSeleccionada] = useAtom(empresaSeleccionadaAtom);
  const [tipoDocumento, setTipoDocumento] = useState([]);
  const [carpetas, setCarpetas] = useState([]);
  const [plantillasOnboarding, setPlantillasOnboarding] = useState([]);
  const [empresasEnviadas, setEmpresasEnviadas] = useAtom(empresasEnviadasAtom);
  const [empresasAgregadas, setEmpresasAgregadas] = useAtom(empresasAgregadasAtom);

  const [, setCategorias] = useAtom(categoriasAtom);
  const [opciones, setOpciones] = useAtom(opcionesAtom);
  const [opcionesSeleccionadas, setOpcionesSeleccionadas] = useAtom(opcionesSeleccionadasAtom);
  const [valoresIniciales, setValoresIniciales] = useState([]);

  useEffect(() => {
    const cargarDatosIniciales = async () => {
      if (visible) {
        var _categorias = [];

        const _tipoDocumento = await TipoDocumentoService.listarPorEmpresaYCategoria(empresaSeleccionada.id, "");
        setTipoDocumento(_tipoDocumento);
        const _carpetas = await CarpetaService.listar(empresaSeleccionada.id);
        setCarpetas(_carpetas);
        const _plantillasOnboarding = await PlantillaEnvioDocumentoService.listarTodosPorEmpresa(
          empresaSeleccionada.id
        );
        setPlantillasOnboarding(_plantillasOnboarding);

        var perfiles = (await PerfilService.listar(empresaSeleccionada.id)).map((perfil) =>
          getEntidad(perfil, CampoColaborador.PERFIL)
        );
        _categorias.push(...perfiles);

        var sedes = (await SedeService.listar(empresaSeleccionada.id)).map((sede) =>
          getEntidad(sede, CampoColaborador.SEDE)
        );
        _categorias.push(...sedes);

        var subsedes = (await SubsedeService.listar(empresaSeleccionada.id)).map((subsede) =>
          getEntidad(subsede, CampoColaborador.SUBSEDE)
        );
        _categorias.push(...subsedes);

        var secciones = (await SeccionService.listar(empresaSeleccionada.id)).map((seccion) =>
          getEntidad(seccion, CampoColaborador.SECCION)
        );
        _categorias.push(...secciones);

        var cargos = (await CargoService.listar(empresaSeleccionada.id)).map((cargo) =>
          getEntidad(cargo, CampoColaborador.CARGO)
        );
        _categorias.push(...cargos);

        var planillas = (await TipoPlanillaService.listar(empresaSeleccionada.id)).map((planilla) =>
          getEntidad(planilla, CampoColaborador.PLANILLA)
        );
        _categorias.push(...planillas);
        setCategorias(_categorias);

        var _opciones = [];
        camposColaboradores.forEach((campo) => {
          if (campo.campoDependencia === null) {
            _opciones.push({
              campo: campo.campo,
              datos: _categorias
                .filter((categoria) => categoria.campo === campo.campo)
                .map((categoria) => {
                  var opcion = {
                    id: categoria.id,
                    label: categoria.nombre,
                    value: categoria.id,
                    campo: campo.campo,
                    campodependencia: null,
                    iddependencia: null,
                  };
                  return opcion;
                }),
            });
          } else {
            _opciones.push({ campo: campo.campo, datos: [] });
          }
        });
        setOpciones(_opciones);

        console.log("gullet::", empresaSeleccionada);
        var _opcionesSeleccionadas = camposColaboradores.map((campo) => {
          const datos = empresaSeleccionada[campo.nombre];
          console.log("campo.nombre::", campo.nombre);
          console.log("campo.nombre::", empresaSeleccionada[campo.nombre]);
          return {
            campo: campo.campo,
            datos: datos !== undefined ? datos : [],
          };
        });
        setOpcionesSeleccionadas(_opcionesSeleccionadas);

        var _opcionesIniciales = [];
        camposColaboradores.forEach((campo) => {
          const categoriasSeleccionadasPorCampo = _opcionesSeleccionadas.find((c) => c.campo === campo.campo).datos;
          const categoriasPorCampo = _categorias.filter(
            (c) => c.campo === campo.campo && categoriasSeleccionadasPorCampo.includes(c.id)
          );

          _opcionesIniciales.push({
            campo: campo.campo,
            datos: categoriasPorCampo
              .filter((c) => categoriasSeleccionadasPorCampo.includes(c.id))
              .map((c) => {
                var opcion = {
                  id: c.id,
                  label: c.nombre,
                  value: c.id,
                  campo: campo.campo,
                  campodependencia: c.campoDependencia,
                  iddependencia: c.idDependencia,
                };
                return opcion;
              }),
          });
        });
        setValoresIniciales(_opcionesIniciales);

        frmConfigurarFiltros.setFieldsValue({
          filtroUsuarioNotificador:
            empresaSeleccionada.filtroUsuarioNotificador == null
              ? undefined
              : empresaSeleccionada.filtroUsuarioNotificador,
          filtroTipoDocumento:
            empresaSeleccionada.filtroTipoDocumento == null ? undefined : empresaSeleccionada.filtroTipoDocumento,
          filtroPlantillaOnboarding:
            empresaSeleccionada.filtroPlantillaOnboarding == null
              ? undefined
              : empresaSeleccionada.filtroPlantillaOnboarding,
        });
        frmConfigurarFiltros.setFields([
          {
            name: "filtroCarpeta",
            value: empresaSeleccionada.filtroCarpeta == null ? undefined : empresaSeleccionada.filtroCarpeta,
          },
        ]);
      }
    };

    cargarDatosIniciales();
  }, [visible]);

  const getEntidad = (entidad, campo) => {
    const campoDependencia = camposColaboradores.find((c) => c.campo === campo).campoDependencia;
    var campo = {
      id: entidad.id,
      nombre: entidad.nombre,
      campo: campo,
      campoDependencia: undefined,
      idDependencia: undefined,
    };
    if (campoDependencia === CampoColaborador.PERFIL) {
      return { ...campo, campoDependencia: CampoColaborador.PERFIL, idDependencia: entidad.perfilId };
    } else if (campoDependencia === CampoColaborador.SEDE) {
      return { ...campo, campoDependencia: CampoColaborador.SEDE, idDependencia: entidad.sedeId };
    } else if (campoDependencia === CampoColaborador.SUBSEDE) {
      return { ...campo, campoDependencia: CampoColaborador.SUBSEDE, idDependencia: entidad.subsedeId };
    } else if (campoDependencia === CampoColaborador.SECCION) {
      return { ...campo, campoDependencia: CampoColaborador.SECCION, idDependencia: entidad.seccionId };
    } else if (campoDependencia === CampoColaborador.CARGO) {
      return { ...campo, campoDependencia: CampoColaborador.CARGO, idDependencia: entidad.cargoId };
    } else if (campoDependencia === CampoColaborador.PLANILLA) {
      return { ...campo, campoDependencia: CampoColaborador.PLANILLA, idDependencia: entidad.tipoPlanillaId };
    }
    return campo;
  };

  const onClickBtnCancelar = () => {
    setCategorias([]);
    setEmpresaSeleccionada(undefined);
    setVisible(false);
  };

  const onClickBtnGuardar = () => {
    frmConfigurarFiltros.validateFields().then((formulario) => {
      var _formularioNotUndefined;
      let carpetasToOut = [];

      camposColaboradores.forEach((campo) => {
        const filtros = opcionesSeleccionadas
          .find((opcion) => opcion.campo === campo.campo)
          .datos.map((opcion) => {
            return opcion.value;
          });

        if (filtros.length > 0) {
          _formularioNotUndefined = { ..._formularioNotUndefined, [campo.nombre]: filtros };
        } else {
          _formularioNotUndefined = { ..._formularioNotUndefined, [campo.nombre]: undefined };
        }
      });

      if (formulario.filtroTipoDocumento !== undefined) {
        if (formulario.filtroTipoDocumento.length > 0) {
          _formularioNotUndefined = { ..._formularioNotUndefined, filtroTipoDocumento: formulario.filtroTipoDocumento };
        } else {
          _formularioNotUndefined = { ..._formularioNotUndefined, filtroTipoDocumento: null };
        }
      }
      if (formulario.filtroCarpeta !== undefined) {
        if (formulario.filtroCarpeta.length > 0) {
          const temp = [];
          for (let i = 0; i < formulario.filtroCarpeta.length; i++) {
            const tempCarpeta = carpetas.find((item) => item.id == formulario.filtroCarpeta[i]);
            const tempInside = {
              id: tempCarpeta.id,
              descripcion: tempCarpeta.descripcion,
              nivel: tempCarpeta.nivel,
            };
            carpetasToOut.push(tempInside.id);
            temp.push(tempInside);
          }
          _formularioNotUndefined = { ..._formularioNotUndefined, filtroCarpeta: temp };
        } else {
          _formularioNotUndefined = { ..._formularioNotUndefined, filtroCarpeta: null };
        }
      }
      if (formulario.filtroUsuarioNotificador !== undefined) {
        _formularioNotUndefined = {
          ..._formularioNotUndefined,
          filtroUsuarioNotificador: formulario.filtroUsuarioNotificador,
        };
      } else {
        _formularioNotUndefined = { ..._formularioNotUndefined, filtroUsuarioNotificador: null };
      }
      if (formulario.filtroPlantillaOnboarding !== undefined) {
        if (formulario.filtroPlantillaOnboarding.length > 0) {
          _formularioNotUndefined = {
            ..._formularioNotUndefined,
            filtroPlantillaOnboarding: formulario.filtroPlantillaOnboarding,
          };
        } else {
          _formularioNotUndefined = { ..._formularioNotUndefined, filtroPlantillaOnboarding: null };
        }
      }

      let indexEmpresas = 0;
      const _empresasEnviadas = [...empresasEnviadas];
      const _empresasAgregadas = [...empresasAgregadas];
      _empresasEnviadas[indexEmpresas] = { ..._empresasEnviadas[indexEmpresas], ..._formularioNotUndefined };
      _empresasAgregadas[indexEmpresas] = {
        ..._empresasAgregadas[indexEmpresas],
        ..._formularioNotUndefined,
        filtroCarpeta: carpetasToOut.length > 0 ? carpetasToOut : null,
      };
      setCategorias([]);
      setEmpresaSeleccionada(undefined);
      setEmpresasEnviadas(_empresasEnviadas);
      setEmpresasAgregadas(_empresasAgregadas);
      setVisible(false);
    });
  };

  if (!visible) {
    return "";
  }

  return (
    <Modal
      bodyStyle={{ height: "400px", overflowY: "scroll" }}
      width={820}
      title={<div>Configurar Filtros</div>}
      visible={visible}
      onCancel={onClickBtnCancelar}
      onOk={onClickBtnGuardar}
    >
      <Form form={frmConfigurarFiltros} layout="vertical" size="large" style={{ width: "750px" }}>
        {camposColaboradores.length > 0 && (
          <Row>
            <Col span={24}>
              <Title level={5}>Selecciona los filtros del colaborador</Title>
            </Col>
          </Row>
        )}
        <Row gutter={16}>
          {camposColaboradores.length > 0 &&
            camposColaboradores.map((campo) => {
              const options = opciones.find((o) => o.campo === campo.campo);
              const camposHijos = camposColaboradores.filter((c) => c.campoDependencia === campo.campo);
              const _valoresIniciales = valoresIniciales.find((o) => o.campo === campo.campo);
              return (
                <FormItemCampos
                  key={campo.campo}
                  label={campo.etiqueta}
                  name={campo.nombre}
                  campo={campo.campo}
                  valoresIniciales={_valoresIniciales?.datos}
                  camposHijos={camposHijos}
                  options={options?.datos}
                />
              );
            })}
        </Row>
        <Row>
          <Col span={24}>
            <Title level={5}>Filtros por usuario notificador</Title>
            <Text>
              Seleccione el usuario notificador de los documentos que el usuario actual podrá acceder a consultar.
            </Text>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Notificado por" name="filtroUsuarioNotificador">
              <Select placeholder="Seleccionar usuario notificador">
                <Option value="todos">TODOS</Option>
                <Option value="enviadosPorMi">ENVIADOS POR MI</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={5}>Filtros por tipo de documento y carpeta</Title>
            <Text>Seleccione el tipo de documento y la carpeta que el usuario actual podrá acceder a consultar.</Text>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Por Tipo de Documento" name="filtroTipoDocumento" rules={[{ type: "array" }]}>
              <Select
                placeholder="Seleccionar el tipo de documento"
                mode="multiple"
                optionLabelProp="label"
                filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
              >
                {tipoDocumento.map((tipoDocumento) => (
                  <Option key={tipoDocumento.id} value={tipoDocumento.id} label={tipoDocumento.nombre}>
                    {tipoDocumento.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Por Carpeta" name="filtroCarpeta" rules={[{ type: "array" }]}>
              <Select placeholder="Seleccionar la carpeta" mode="multiple" optionLabelProp="label">
                {carpetas.length > 0 &&
                  carpetas.map((carpeta) => {
                    return (
                      <Option key={carpeta.id} value={carpeta.id} label={carpeta.ruta}>
                        <Space size={carpeta.nivel * 10}>
                          <span></span>
                          <span>
                            {carpeta.nivel === 0 ? (
                              <FolderFilled style={{ fontSize: "20px", color: "#F7D358" }} />
                            ) : (
                              <FolderTwoTone style={{ fontSize: "18px", color: "#F7D358" }} twoToneColor="#F7D358" />
                            )}{" "}
                            {carpeta.descripcion}
                          </span>
                        </Space>
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Title level={5}>Filtros por Plantilla Onboarding</Title>
            <Text>Seleccione la plantilla onboarding que el usuario actual podrá acceder a consultar.</Text>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Por plantilla Onboarding" name="filtroPlantillaOnboarding">
              <Select placeholder="Seleccionar plantilla onboarding" mode="multiple">
                <Option key="TODOS" value="TODOS">
                  TODOS
                </Option>
                {plantillasOnboarding.length > 0 &&
                  plantillasOnboarding.map((plantilla) => (
                    <Option key={plantilla.id} value={plantilla.id}>
                      {plantilla.nombre}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const FormItemCampos = ({ label, name, options, valoresIniciales, campo, camposHijos }) => {
  const [categorias] = useAtom(categoriasAtom);
  const [, setOpciones] = useAtom(opcionesAtom);
  const [opcionesSeleccionadas, setOpcionesSeleccionadas] = useAtom(opcionesSeleccionadasAtom);

  const cargarDependencias = (option) => {
    const nuevasOpciones = camposHijos.map((hijo) => {
      var options = option?.map((o) => {
        var grupo = { label: o.label };
        var options = categorias
          .filter((c) => c.campo === hijo.campo && o.id === c.idDependencia)
          .map((c) => {
            var opcion = {
              id: c.id,
              label: c.nombre,
              value: c.id,
              campo: c.campo,
              campodependencia: c.campoDependencia,
              iddependencia: c.idDependencia,
            };
            return opcion;
          });
        return { ...grupo, options: [...options] };
      });
      return { campo: hijo.campo, datos: options };
    });

    setOpciones(
      produce((draft) => {
        nuevasOpciones.forEach((nuevaOpcion) => {
          const opcion = draft?.find((o) => o.campo === nuevaOpcion.campo);
          if (opcion !== undefined) {
            opcion.datos = nuevaOpcion.datos;
          }
        });
      })
    );

    setOpcionesSeleccionadas(
      produce((draft) => {
        const opcion = draft?.find((o) => o.campo === campo);
        if (opcion !== undefined) {
          opcion.datos = option;
        }
      })
    );
  };

  const onSeleccionar = (itemsSeleccionados) => {
    if (itemsSeleccionados) {
      cargarDependencias(itemsSeleccionados);
    }
  };

  const onQuitar = (value, option) => {
    let quitarCampo = true;
    camposHijos.forEach((campoHijo) => {
      const opcionesSeleccionadasPorCampo = opcionesSeleccionadas.find(
        (opcion) => opcion.campo === campoHijo.campo
      )?.datos;
      opcionesSeleccionadasPorCampo.forEach((opcion) => {
        if (opcion.iddependencia === value) {
          quitarCampo = false;
          return;
        }
      });
    });
    return quitarCampo;
  };

  return (
    <Col span={8}>
      <Form.Item label={label} name={name} rules={[{ type: "array" }]}>
        <CampoSelect
          options={options}
          valoresIniciales={valoresIniciales}
          onSeleccionar={onSeleccionar}
          onQuitar={onQuitar}
        />
      </Form.Item>
    </Col>
  );
};

export default ModalFiltrar;
