import React, { useEffect } from 'react';
import { Avatar, Breadcrumb, Button, Card, Dropdown, Table } from 'antd';
import { Provider as JotaiProvider, useSetAtom } from "jotai";

import {
    HomeOutlined,
    FilePdfOutlined,
    UserOutlined,
    MoreOutlined,
    UploadOutlined
} from "@ant-design/icons"

import { useListarTareas } from './hooks';
import { documentoSeleccionadoAtom, visibleModalSubirDocumentoAtom, visibleModalVisorPdfAtom } from './store/Store';
import { ModalSubirDocumento, ModalVisorPdf } from './components';
import FormularioSeguimientoService from 'services/FormularioSeguimientoService';

const BandejaTareasPage = () => {
    return (
        <JotaiProvider>
            <Navegacion />
            <Card bordered={false}>
                <ListaDocumentos />
                <ModalVisorPdf />
                <ModalSubirDocumento />
            </Card>
        </JotaiProvider>
    );
};

const ListaDocumentos = () => {

    const { documentos } = useListarTareas(true);

    const columnas = [
        {
            title: 'Colaborador',
            render: (documento) => <ColumnaColaborador documento={documento} />
        },
        {
            title: 'Documento',
            render: (documento) => <ColumnaDocumento documento={documento} />
        },
        {
            width: '5px',
            render: (documento) => <MenuAcciones documento={documento} />
        }
    ];

    return (
        <Table
            rowKey={(item) => item.id}
            columns={columnas}
            dataSource={documentos}
            pagination={false}
            size="small"
        />
    );

}

const MenuAcciones = ({ documento }) => {

    const setDocumentoSeleccionado = useSetAtom(documentoSeleccionadoAtom);
    const setVisibleModalVisorPdf = useSetAtom(visibleModalVisorPdfAtom);
    const setVisibleModalSubirDocumento = useSetAtom(visibleModalSubirDocumentoAtom);

    let items = [];

    if (documento.interno) {
        items.push({
            key: 'subir-documento',
            label: 'Subir Documento',
            icon: <UploadOutlined style={{ fontSize: "16px" }} />
        })
    }

    if (!documento.interno) {
        items.push({
            key: 'ver-documento',
            label: 'Ver Documento',
            icon: <FilePdfOutlined style={{ fontSize: "16px" }} />
        })
    }

    const onClick = ({ key }) => {
        if (key === "ver-documento") {
            verDocumento();
        } else if (key === "subir-documento") {
            subirDocumento();
        }
    };

    const verDocumento = async () => {
        const respuesta = await FormularioSeguimientoService.getUrlDocumentoPdf(documento.id);
        setDocumentoSeleccionado({ ...documento, urlDocumento: respuesta.urlDocumento });
        setVisibleModalVisorPdf(true);
    }

    const subirDocumento = async () => {
        setDocumentoSeleccionado(documento);
        setVisibleModalSubirDocumento(true);
    }

    return (
        <Dropdown menu={{ items, onClick }} trigger="click" placement="bottomRight" arrow>
            <Button type="link" onClick={(e) => e.preventDefault()}>
                <MoreOutlined style={{ fontSize: "18px", fontWeight: "bold", color: "gray" }} />
            </Button>
        </Dropdown>
    );


}

const ColumnaColaborador = ({ documento }) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingRight: "5px" }}>
                <Avatar size="large" icon={<UserOutlined />} />
            </span>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <span>{documento.colaborador.nombreCompleto}</span>
                <span>{documento.colaborador.numeroDocumentoIdentidad}</span>
            </div>
        </div>
    )
}

const ColumnaDocumento = ({ documento }) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <span style={{ paddingRight: "5px" }}>
                <FilePdfOutlined style={{ fontSize: "24px" }} />
            </span>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                {documento.nombreDocumento}
            </div>
        </div>
    )
}

const Navegacion = () => {
    return (
        <Breadcrumb style={{ marginBottom: "10px" }}>
            <Breadcrumb.Item>
                <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                Consultar
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                Bandeja de Tareas
            </Breadcrumb.Item>
        </Breadcrumb>
    )
}

export default BandejaTareasPage;