import React, { useContext, useState } from "react";
import { Alert, Table, Typography, Tag, Space, message } from "antd";
import {
  FolderFilled,
  FileTextFilled,
  MobileOutlined,
  MailOutlined,
  SendOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import * as atoms from "../../ConsultarLegajoColaboradorStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import LegajoService from "../../../../../services/LegajoService";
import { TipoDocumentoLegajo } from "../../../../../enums/TipoDocumentoLegajo";
import { EstadoDocumento } from "enums/EstadoDocumento";
import { MenuAcciones } from "../menu-acciones";
import DetalleEnvioService from "../../../../../services/DetalleEnvioService";
import { useModalVisorPdf } from "store/global";
import { EtiquetaEstadoNotificacion } from "./etiqueta-estado-notificacion";
import { EtiquetaEstadoVisualizacion } from "./etiqueta-estado-visualizacion";
import { EtiquetaEstadoFirmaColaborador } from "./etiqueta-estado-firma-colaborador";
import { OpcionService } from "services";

const TablaDocumentos = () => {
  const { Text } = Typography;
  const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [loadingDocumentos, setLoadingDocumentos] = useAtom(atoms.loadingDocumentos);
  const [, setLoading] = useState(false);
  const [documentos, setDocumentos] = useAtom(atoms.documentos);
  const [colaboradorSeleccionado] = useAtom(atoms.colaboradorSeleccionado);
  const [breadcrumbItems, setBreadcrumbItems] = useAtom(atoms.breadcrumbItems);
  const [mensajeLegajo, setMensajeLegajo] = useAtom(atoms.mensajeLegajoAtom);

  const verDocumentosCarpeta = async (documento) => {
    try {
      setLoadingDocumentos(true);
      var _documentos = await LegajoService.listarDocumentosCarpeta(
        colaboradorSeleccionado.numeroDocumentoIdentidad,
        getUsuarioAutenticado().empresaSeleccionada.id,
        documento.carpetaId,
        getUsuarioAutenticado().login
      );
      const mensaje = _documentos.find((d) => d.tipo === "MENSAJE");
      if (mensaje !== undefined) {
        _documentos = _documentos.filter((d) => d.tipo !== "MENSAJE");
        setMensajeLegajo(mensaje.mensaje);
      } else {
        setMensajeLegajo(undefined);
      }
      setDocumentos(_documentos);
      setBreadcrumbItems([
        ...breadcrumbItems,
        {
          nombre: documento.nombre,
          carpetaId: documento.carpetaId,
        },
      ]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingDocumentos(false);
    }
  };

  const verDocumento = async (documento) => {
    try {
      setLoading(true);
      if (documento.categoriaDocumento === "FORMULARIOS" && documento.estadoFirmaColaborador === "PENDIENTE")
        message.error("El formulario seleccionado aun no ha sido completado.");
      else {
        const respuesta = await DetalleEnvioService.obtenerUrlDocumento(
          getUsuarioAutenticado().empresaSeleccionada.id,
          documento.detalleEnvioId
        );

        mostrarModalVisorPdf(respuesta.urlDocumento, documento.nombre, true, true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columnaDocumento = [
    {
      title: "Nombre",
      //dataIndex: "nombre",
      key: "nombre",
      width: "25%",
      render: (text, fila) => (
        <span>
          {fila.tipo === TipoDocumentoLegajo.CARPETA ? (
            <React.Fragment>
              <a onClick={() => verDocumentosCarpeta(fila)}>
                <FolderFilled style={{ fontSize: "30px", color: "#F7D358" }} /> {fila.nombre}
              </a>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <a onClick={() => verDocumento(fila)}>
                <FileTextFilled style={{ fontSize: "30px", color: "#0080FF" }} /> {fila.nombre}
              </a>
            </React.Fragment>
          )}
        </span>
      ),
    },

    {
      title: "Periodo",
      //dataIndex: "fechaNotificacion",
      key: "fechaNotificacion",
      align: "center",
      render: (text, fila) => {
        if (fila.tipo === TipoDocumentoLegajo.CARPETA) {
          return <Text>{"-"}</Text>;
        } else {
          return <React.Fragment>{fila.periodo}</React.Fragment>;
        }
      },
    },
    {
      title: "Bandeja",
      key: "bandejaNotificacion",
      align: "center",
      width: "20%",
      render: (text, fila) => {
        if (fila.tipo === TipoDocumentoLegajo.CARPETA) {
          return <Text>{"-"}</Text>;
        } else {
          return (
            <React.Fragment>
              {fila.fechaNotificacion && (
                <p>
                  <SendOutlined /> {fila.fechaNotificacion}
                </p>
              )}
              {fila.correoElectronico && (
                <p>
                  <MailOutlined /> {fila.correoElectronico}
                </p>
              )}
              {fila.celular && (
                <p>
                  <MobileOutlined /> {fila.celular}
                </p>
              )}
            </React.Fragment>
          );
        }
      },
    },
    {
      title: "Notificación",
      dataIndex: "estadoNotificacion",
      key: "estadoNotificacion",
      align: "center",
      render: (text, fila) => {
        if (fila.tipo === TipoDocumentoLegajo.CARPETA) {
          return <Text>{"-"}</Text>;
        }
        if(fila.estadoCorreoEnviado != null && fila.estadoCorreoEnviado ==="NO_APLICA"){
          return <Tag style={{ color: '#7f7f7f', marginRight: '0px' }}>NO APLICA</Tag>;;
        }

        if (fila.estadoDocumento === EstadoDocumento.GENERADO || fila.estadoDocumento === null) {
          return <EtiquetaEstadoNotificacion documento={fila} />;
        }
        if (fila.estadoProcesoId === 6) {
          return {
            children: <Tag>PENDIENTE DE APROBACIÓN</Tag>,
            props: {
              colSpan: 2,
            },
          };
        }
      },
    },
    {
      title: "Visualizado",
      dataIndex: "estadoVisualizacion",
      key: "estadoVisualizacion",
      align: "center",
      render: (text, fila) => {
        if (fila.tipo === TipoDocumentoLegajo.CARPETA) {
          return <Text>{"-"}</Text>;
        }
        if (fila.estadoDocumento === EstadoDocumento.GENERADO || fila.estadoDocumento === null) {
          return <EtiquetaEstadoVisualizacion documento={fila} />;
        }
        if (fila.estadoProcesoId === 6) {
          return {
            props: {
              colSpan: 0,
            },
          };
        }
      },
    },
    {
      title: "Firmado",
      dataIndex: "estadoFirmaColaborador",
      key: "estadoFirmaColaborador",
      align: "center",
      render: (text, fila) => {
        if (fila.tipo === TipoDocumentoLegajo.CARPETA) {
          return <Text>{"-"}</Text>;
        }
        if (fila.estadoDocumento === EstadoDocumento.GENERADO || fila.estadoDocumento === null) {
          return <EtiquetaEstadoFirmaColaborador documento={fila} />;
        }
      },
      onCell: (fila) => ({
        colSpan: fila.estadoProcesoId === 6 ? 0 : 1,
      }),
    },
    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      width: "8%",
      render: (fila) => fila.tipo === TipoDocumentoLegajo.DOCUMENTO && <MenuAcciones documento={fila} />,
    },
  ];

  return (
    <Table
      //bordered
      rowKey={(documento) => (documento.detalleEnvioId ? documento.detalleEnvioId : documento.carpetaId)}
      loading={{
        spinning: loadingDocumentos,
        indicator: <LoadingOutlined style={{ fontSize: 28 }} />,
        tip: "Cargando...",
      }}
      dataSource={documentos}
      columns={columnaDocumento}
      pagination={false}
      scroll={{ y: "40vh" }}
      //footer={() => mensajeLegajo!==undefined?<Alert message={mensajeLegajo} type="warning" />:undefined}
    />
  );
};
export default TablaDocumentos;
