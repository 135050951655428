import React from "react";
import { Form, Input, Row, Col, Button, TimePicker, Select, Tabs, Space, Checkbox } from "antd";
import { PlusOutlined} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import { TipoTurno } from "enums/asistencia/TipoTurno";
import { Provider as JotaiProvider, useAtom } from "jotai";
const FormCrearReglas = () => {
  const format = 'HH:mm';
  let history = useHistory();
  const options = [
    { key: 1, value: '1', label: 'Lunes' },
    { key: 2, value: '2', label: 'Martes' },
    { key: 3, value: '3', label: 'Miercoles' },
    { key: 4, value: '4', label: 'Jueves' },
    { key: 5, value: '5', label: 'Viernes' },
    { key: 6, value: '6', label: 'Sabado' },
    { key: 7, value: '7', label: 'Domingo' },
  ];

  const onClickRetonarTurnos=()=>{
    history.push("/asistencia/turnos");
  }

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onChangeMarcacionInicioObligatoria=(e)=>{    
    if(e.target.checked===true){
      //setOmitirRango(true);
    }else{
      //setOmitirRango(false);
    }    
  }

  const onChangeMarcacionFinObligatoria=(e)=>{    
    if(e.target.checked===true){
      //setOmitirRango(true);
    }else{
      //setOmitirRango(false);
    }    
  }

  return (
   <div>
      <Form
             layout="vertical"           
             //size="middle"
             //onFinish={onClickBtnCrearCarpeta}
        >
         <Row gutter={16}>
            <Col span={12}>
                <Checkbox onChange={onChangeMarcacionInicioObligatoria} >Marcación de inicio de turno es obligatorio</Checkbox>
            </Col>
            <Col span={12}>
                <Checkbox onChange={onChangeMarcacionFinObligatoria} >Marcación de fin de turno es obligatorio</Checkbox>
            </Col>
          </Row> 
        
        <Form.Item style={{ textAlign: "center", marginTop:"10px"}} >
            <Button
                type="primary"
                icon={<CheckOutlined />}
                htmlType="submit"
            >
            Crear Regla
            </Button>
        </Form.Item>
       </Form>
   </div> 
  );
};
export default FormCrearReglas;
