import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "../../../components/Container";
import {
  Input,
  Button,
  Table,
  Space,
  Popconfirm,
  notification,
  Modal,
  Upload,
  message,
  Dropdown,
  Menu
} from "antd";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";

import {
  UnorderedListOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  UploadOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  KeyOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";

import UsuarioService from "../../../services/UsuarioService";

export function UsuarioListarPage() {
  const [usuarios, setUsuarios] = useState([]);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  let history = useHistory();

  useEffect(() => {
    async function cargarDatos() {
      const usuariosListados = await UsuarioService.listarOrganizacion(
        //getUsuarioAutenticado().empresaSeleccionada.id
        getUsuarioAutenticado().organizacionId
      );
      setUsuarios(usuariosListados);
    }

    cargarDatos();
  }, []);
  var getColumnSearchProps = function getColumns(dataIndex, propiedad) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchInput}
              placeholder={`Buscar por ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Buscar
                </Button>
              <Button
                onClick={() => handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Limpiar
                </Button>
            </Space>
          </div>
        ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) => (
        propiedad === undefined
          ? record[dataIndex].toString()
            .toLowerCase()
            .includes(value.toLowerCase())
          : record[dataIndex][propiedad].toString()
            .toLowerCase()
            .includes(value.toLowerCase())
      ),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={
              propiedad === undefined
                ? text?.toString()
                : text[propiedad]?.toString()
            }
          />
        ) : propiedad === undefined ? (
          text
        ) : (
              text[propiedad]
            ),
    };
  };
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText("");
  }
  async function generaClave(idUsuario){        
    
    try {
      const respuesta = await UsuarioService.generaClave(idUsuario,getUsuarioAutenticado().empresaSeleccionada.id);
      notification.success({
        message: "Operación exitosa",
        description: "Clave generada correctamente,será enviada al correo del usuario.",
      });
      setUsuarios(usuarios.map((usuario)=>{
        if(usuario.id===idUsuario){
          return{...usuario,estadoClaveGenerada:true}
        }
        return usuario;
      }));    
      
    } catch (error) {
         notification.error({       
        description: error.response.data.mensaje,
      });
    }               
            
  }
  function handleOnclickMenu({item,key}){
    if(key==="1"){      
     Modal.confirm({      
      title: "¿Está seguro de eliminar el usuario seleccionado?",
      icon: <ExclamationCircleOutlined />,      
      okText: "Si",
      cancelText: "No",
      onOk() { handleOnClickEliminarUsuario(item.props.usuario.id); }

    });
    }
    else if(key==="2"){
      generaClave(item.props.usuario.id);
      
    }

  }
  const menu = (usuario) => (
    <Menu onClick={handleOnclickMenu}>
      
        <Menu.Item key="1" usuario={usuario}>
          <DeleteOutlined /> Eliminar
        </Menu.Item>
        {usuario.estadoClaveGenerada===false &&     
          <Menu.Item key="2" usuario={usuario}>
            <KeyOutlined /> Generar Clave
        </Menu.Item>       
        }
    </Menu>

  );

  const columnas = [
    // {
    //   title: "Tipo de Documento de Identidad",
    //   dataIndex: "tipoDocumentoIdentidad",
    //   colSpan: 0,
    //   align: "center",
    //   width: "10%",
    //   //  render: (documentoIdentidad) => `${documentoIdentidad.tipo}`,
    // },
    // {
    //   title: "Documento",
    //   colSpan: 2,
    //   dataIndex: "numeroDocumentoIdentidad",
    //   align: "center",
    //   width: "10%",
    //   // render: (documentoIdentidad) => `${documentoIdentidad.numero}`,
    //   //...getColumnSearchProps("documentoIdentidad", "numero"),
    // },
    {
      title: "Apellidos y Nombres",
      dataIndex: "nombreCompleto",
      align: "center",
      ...getColumnSearchProps("nombreCompleto"),
    },
    {
      title: "Login",
      dataIndex: "login",
      align: "center",
      ...getColumnSearchProps("login"),
    },

    // {
    //   title: "Correo Electrónico",
    //   dataIndex: "correoElectronico",
    //   align: "center",
    //   ...getColumnSearchProps("correoElectronico"),
    // },
    {
      title: "Acciones",
      align: "center",
      key: "acciones",
      align: "center",
      render: (text, fila) => (
        <Space size={8}>
          <a
            onClick={() => handleOnClickEditarUsuario(fila.id)}
            className="custom-color-element"
          >
            <EditOutlined /> Editar
              </a>

          {/* <Popconfirm
            title="¿Está seguro de eliminar el registro seleccionado?"
            okText="Si"
            cancelText="No"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => handleOnClickEliminarUsuario(fila.id)}
          >            
            <a style={{ color: "#E01D4C" }}>
              {" "}
              <DeleteOutlined /> Eliminar
                </a>
           </Popconfirm> */}

          <Dropdown overlay={() => menu(fila)}>
                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                  <UnorderedListOutlined /> Otros
                </a>
          </Dropdown>
            </Space>
          ),
    
          // colaboradores.length >= 1 ? (
          //   <Space size={8}>
          //     <Button
          //       type="link"
          //       onClick={() => handleOnClickEditarColaborador(fila.id)}
          //     >
          //       Editar
          //     </Button>
    
          //     <Popconfirm
          //       title="¿Está seguro de eliminar el registro seleccionado?"
          //       okText="Si"
          //       cancelText="No"
          //       icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          //       onConfirm={() => handleOnClickEliminarColaborador(fila.id)}
          //     >
          //       <a href="#/">Eliminar</a>
          //     </Popconfirm>
          //   </Space>
          // ) : null,
        },
      ];
      function handleOnClickCrearUsuario() {
        history.push("/usuario/crear");
      }
    
      function handleOnClickEditarUsuario(idUsuario) {
        history.push(`/usuario/editar/${idUsuario}`);
      }
    
      async function handleOnClickEliminarUsuario(idUsuario) {
        await UsuarioService.eliminar(idUsuario);
        setUsuarios(
          usuarios.filter((usuario) => usuario.id !== idUsuario)
        );
        notification.success({
          message: "Operación exitosa",
          description: "Se eliminó el registro correctamente.",
        });
      }
      return(
          <Container 
            icono={<UnorderedListOutlined />} 
            titulo="Usuario" 
            botonExtra={
              <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleOnClickCrearUsuario}
              >
                Crear Usuario
              </Button>
      }
    >
      {/* <Space>
                <div style={{ marginBottom: 16 }}>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={handleOnClickCrearUsuario}
                >
                    Crear Usuario
                </Button>
                </div>                
            </Space> */}
      <Table
        rowKey={(fila) => fila.id}
        columns={columnas}
        dataSource={usuarios}
        pagination={{
          pageSize: [10],
        }}
      />
    </Container>
  );
}