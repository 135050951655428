import React, { useContext, useEffect, useState } from "react";
import { Table, Tabs } from "antd";
import * as atoms from "../ListarVerificarDatosColaboradorStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import  VerificarDatosColaboradorObservadosService from "services/VerificarDatosColaboradorObservadosService";
import { columnaEnvio, columnaVerificado, columnaPendiente } from "./Columnas";

const TablaVerificarDatosColaborador=({loading})=>{
    const { TabPane } = Tabs;
    const [verificacionDatosPersonales, setVerificacionDatosPersonales] = useAtom(atoms.verificacionDatosPersonales);
    const [, setTabNumber] = useAtom(atoms.tabNumberAtom);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [colaboradorEnviado,setColaboradorEnviado]=useState([]);
    const [colaboradorVerificado,setColaboradorVerificado]=useState([]);
    const [colaboradorPendiente,setColaboradorPendiente]=useAtom(atoms.colaboradorPendienteAtom);

    useEffect(()=>{
       const cargarDatos = async () =>{
             const respuesta=await VerificarDatosColaboradorObservadosService.listarRegistros(getUsuarioAutenticado().empresaSeleccionada.id);
             setVerificacionDatosPersonales(respuesta);
       }
       cargarDatos();
    },[]
    );

    useEffect(()=>{
        setColaboradorEnviado(verificacionDatosPersonales.filter((v)=>v.verificado===null));
        setColaboradorVerificado(verificacionDatosPersonales.filter((v)=>v.verificado?v.verificado===true:false));
        setColaboradorPendiente(verificacionDatosPersonales.filter((v)=>v.verificado!==null?v.verificado===false:false));
     },[verificacionDatosPersonales]
     );


    
    return( <React.Fragment>
        <Tabs onChange={(e)=>setTabNumber(e)}>
        <TabPane tab="Pendientes de Respuesta" key="1" disabled={loading} >
                <Table
                    loading={loading}
                    rowKey={(colaboradorEnviado)=>colaboradorEnviado.numeroDocumentoIdentidad}
                    columns={columnaEnvio}
                    dataSource={colaboradorEnviado}
                />
            </TabPane>
            <TabPane tab="Pendientes de Aprobar" key="2">
                <Table
                    loading={loading}
                    rowKey={(colaboradorPendiente)=>colaboradorPendiente.numeroDocumentoIdentidad}
                     columns={columnaPendiente(verificacionDatosPersonales,setVerificacionDatosPersonales)}
                    dataSource={colaboradorPendiente}
                />
            </TabPane>
            <TabPane tab="Verificados" key="3" disabled={loading} >
                <Table
                    loading={loading}
                    rowKey={(colaboradorVerificado)=>colaboradorVerificado.numeroDocumentoIdentidad}
                    columns={columnaVerificado}
                    dataSource={colaboradorVerificado}
                />
            </TabPane>
        </Tabs>
        </React.Fragment>
    )
}
export default TablaVerificarDatosColaborador;