import React from "react";
import { useAtom } from "jotai";
import { carpetasAtom, carpetaSeleccionadaAtom, documentoSeleccionadoAtom, formInstanceAtom, loadingMoverDocumentoAtom, openModalMoverDocumentoAtom } from "../../../LegajoColaboradorStore";
import CarpetaService from "services/CarpetaService";
import { message, Space } from "antd";
import { FolderFilled, FolderTwoTone } from "@ant-design/icons"
import DetalleEnvioService from "services/DetalleEnvioService";
import useListarDocumentos from "./useListarDocumentos";

const useModalMoverDocumento = () => {
    const [open, setOpen] = useAtom(openModalMoverDocumentoAtom);
    const [formInstance, setFormInstance] = useAtom(formInstanceAtom);
    const [carpetas, setCarpetas] = useAtom(carpetasAtom);
    const [documentoSeleccionado, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
    const [carpetaSeleccionada] = useAtom(carpetaSeleccionadaAtom);
    const { fetchDocumentos } = useListarDocumentos();
    const [loading, setLoading] = useAtom(loadingMoverDocumentoAtom);

    const abrirModal = (documento) => {
        setDocumentoSeleccionado(documento);
        setOpen(true);
    }

    const cerrarModal = () => {
        setDocumentoSeleccionado(undefined);
        formInstance.resetFields();
        setOpen(false);
    }

    const guardar = () => {
        formInstance.validateFields()
            .then(async (formulario) => {
                try {
                    setLoading(true);
                    await DetalleEnvioService.asignarCarpeta({
                        id: documentoSeleccionado.detalleEnvioId,
                        carpetaId: formulario.carpetaId,
                        carpetaDescripcion: formulario.carpetaDescripcion
                    })

                    fetchDocumentos(carpetaSeleccionada);
                    message.success(`Se movió el documento a la carpeta ${formulario.carpetaDescripcion}`);
                    setLoading(false);
                    cerrarModal();

                } catch (error) {
                    console.log(error);
                    setLoading(false);
                }
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });

    }

    const fetchCarpetas = async () => {
        const carpetas = await CarpetaService.listar();
        setCarpetas(
            carpetas.map((carpeta) => ({
                value: carpeta.id,
                label: (
                    <span style={{ paddingLeft: `${carpeta.nivel * 10}px` }}>
                        {carpeta.nivel === 0 ? (
                            <FolderFilled style={{ fontSize: "20px", color: "#F7D358" }} />
                        ) : (
                            <FolderTwoTone style={{ fontSize: "18px", color: "#F7D358" }} twoToneColor="#F7D358" />
                        )}
                        {" "}
                        {carpeta.descripcion}
                    </span>
                ),
                carpetaDescripcion: carpeta.descripcion
            })));
    }

    return {
        open,
        carpetas,
        loading,
        abrirModal,
        cerrarModal,
        fetchCarpetas,
        guardar,
        setFormInstance
    };
};

export default useModalMoverDocumento;
