import { BaseService } from "./BaseService";

const TipoAbsentismoService={
     async listar(empresaId){
         const respuesta= await BaseService.get("/tipoAbsentismo",{
             params:{
                 empresaId:empresaId,
             }
         });
         return respuesta.data;
     },
     async insertar(tipoAbsentismo){
         const respuesta= await BaseService.post("/tipoAbsentismo",tipoAbsentismo);
         return respuesta.data;
     },
     async listarColaboradoresPorEmpresaConFiltro(data){
        const respuesta= await BaseService.get("/tipoAbsentismo/colaborador/listar",{
         params: {
           ...data,
         },
        });
        return respuesta.data;
     },
     async actualizar(tipoAbsentismo){
        const respuesta= await BaseService.put("/tipoAbsentismo",tipoAbsentismo);
        return respuesta.data;
    },
    async buscarPorId(idTipoAbsentismo){
        const respuesta= await BaseService.get(`/tipoAbsentismo/${idTipoAbsentismo}`);
        return respuesta.data;
    },
    async buscarPorId(idTipoAbsentismo){
        const respuesta= await BaseService.get(`/tipoAbsentismo/${idTipoAbsentismo}`);
        return respuesta.data;
    },
    async eliminar(idTipoAbsentismo){
        const respuesta= await BaseService.delete(`/tipoAbsentismo/${idTipoAbsentismo}`);
        return respuesta.data;
    }
};
export default TipoAbsentismoService;