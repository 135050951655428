import React from "react";
import{Container} from "components/Container";
import { useHistory } from "react-router";
import {PlantillaEnvioDocumentoEditar} from "./PlantillaEnvioDocumentoEditar";
import {ModalDocumentos} from "./ModalDocumentos";
import {documentosSeleccionadosAtom} from "./EditarPlantillaEnvioDocumentosStore";
import { useAtom } from "jotai";
const EditarPlantillaEnvioDocumentosPage = () =>{
    const[documentosSeleccionados,setDocumentosSeleccionados]=useAtom(documentosSeleccionadosAtom);
    let history=useHistory();

    const onClickRetornar=()=>{
        setDocumentosSeleccionados([]);
        history.push("/configuracion/plantilla-envio-documentos/listar")
    }

    return(
        <Container titulo="Editar Plantilla Envio Documento" onClickRetornar={onClickRetornar}>
          <PlantillaEnvioDocumentoEditar/>
          <ModalDocumentos/>
        </Container>
    )
}
export default EditarPlantillaEnvioDocumentosPage;