import React, { useState } from "react";
import { Select, Form, Input, Row, Col, Button, notification, Space, DatePicker, Spin } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useHistory, useParams } from "react-router-dom";
import { useContext } from "react";
import { SexoColaborador } from "enums/SexoColaborador";
import { ContainerCrear } from "components/ContainerCrear";
import { SecurityContext } from "context/SecurityContextProvider";
import { tipoDocumentoIdentidad } from "enums/TipoDocumentoIdentidad";
import ColaboradorService from "services/ColaboradorService";
import { useEffect } from "react";
import { CampoColaborador } from "enums/CampoColaborador";
// import { moment } from 'formiojs/utils/utils';
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;

export function PostulanteEditarPage() {
  const [formularioEditar] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [tipoDocumentoSeleccionado, setTipoDocumentoSeleccionado] = useState(null);
  const [camposColaboradores, setCamposPostulantes] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [subsedes, setSubsedes] = useState([]);
  const [perfiles, setPerfiles] = useState([]);
  const [tipoPlanillas, setTipoPlanillas] = useState([]);

  let history = useHistory();
  const { postulanteId } = useParams();

  useEffect(() => {
    async function cargarDatos() {
      setLoading(true);
      try {
        const colaborador = await ColaboradorService.buscarPorId(postulanteId);
        const campos_postulante = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
        setCamposPostulantes(campos_postulante);
        const campoEnviado = await ColaboradorService.camposColaboradorListarNuevo(
          getUsuarioAutenticado().empresaSeleccionada.id
        );

        setPerfiles(campoEnviado.PERFIL);
        setSedes(campoEnviado.SEDE);
        setSubsedes(campoEnviado.SUBSEDE);
        setSecciones(campoEnviado.SECCION);
        setCargos(campoEnviado.CARGO);
        setTipoPlanillas(campoEnviado.PLANILLA);

        formularioEditar.setFieldsValue({
          id: colaborador.id,
          personaId: colaborador.personaId,
          codigoTrabajador: colaborador.codigoTrabajador,
          estadoRegistro: colaborador.estadoRegistro,
          tipoDocumentoIdentidad: colaborador.tipoDocumentoIdentidad,
          numeroDocumentoIdentidad: colaborador.numeroDocumentoIdentidad,
          apellidoPaterno: colaborador.apellidoPaterno,
          apellidoMaterno: colaborador.apellidoMaterno,
          nombres: colaborador.nombres,
          fechaNacimiento: moment(colaborador.fechaNacimiento, "DD/MM/YYYY"),
          empresa: {
            id: colaborador.empresa.id,
            razonsocial: colaborador.empresa.razonsocial,
          },
          correoElectronico: colaborador.correoElectronico,
          celular: colaborador.celular,
          cargo: {
            id: colaborador.cargo?.id,
            nombre: colaborador.cargo?.nombre,
          },
          sede: {
            id: colaborador.sede?.id,
            nombre: colaborador.sede?.nombre,
          },
          eliminado: colaborador.eliminado,
          motivoCese: colaborador.motivoCese,
          sexoColaborador: colaborador.sexoColaborador,
          tieneHijos: colaborador.tieneHijos,
          subsede: colaborador.subsede?.id,
          seccion: colaborador.seccion?.id,
          perfil: colaborador.perfil?.id,
          tipoPlanilla: colaborador.tipoPlanilla?.id,
        });
      } catch (error) {
        console.log(error);
        notification.error({
          message: "Ocurrio un error al cargar los datos.",
          description: error.response.data.mensaje,
        });
      }
      setLoading(false);
    }
    cargarDatos();
  }, []);

  async function handleOnFinish(postulante) {
    try {
      setLoading(true);
      postulante.id = postulanteId;
      postulante.empresa = {
        id: getUsuarioAutenticado().empresaSeleccionada.id,
        razonSocial: getUsuarioAutenticado().empresaSeleccionada.razonSocial,
        urlImagenLogotipo: getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo,
      };
      postulante.organizacionId = getUsuarioAutenticado().organizacionId;
      postulante.subdominio = getUsuarioAutenticado().subdominio;
      postulante.login = postulante.numeroDocumentoIdentidad;
      postulante.eliminado = false;
      postulante.estadoRegistro = "ACTIVO";
      postulante.apellidoPaterno = postulante.apellidoPaterno.trim();
      postulante.apellidoMaterno = postulante.apellidoMaterno.trim();
      postulante.nombres = postulante.nombres.trim();
      postulante.celular = !postulante.celular || postulante.celular.trim() === "" ? null : postulante.celular.trim();
      await ColaboradorService.actualizar(postulante);
      notification.success({
        message: "Operación exitosa",
        description: "Se actualizó el postulante correctamente.",
      });
      setLoading(false);
      history.push("/personal/postulante");
    } catch (error) {
      console.log(error);
      notification.error({
        message: "No se puede actualizar el Postulante",
        description: error.response.data.mensaje,
      });
      setLoading(false);
    }
  }

  function handleOnClickCancelar() {
    history.push("/personal/postulante");
  }

  async function handleOnChangeCampo(value, option, index) {
    const nombreCampo = camposColaboradores[index].campo.toLowerCase();
    let jsonValue = {};
    jsonValue[nombreCampo] = { nombre: option.children, id: option.value };
    formularioEditar.setFieldsValue(jsonValue);
    const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
      getUsuarioAutenticado().empresaSeleccionada.id,
      value,
      camposColaboradores[index].campo
    );
    procesarCambios(campoEnviado);
  }

  function procesarCambios(campoEnviado) {
    if (campoEnviado.PLANILLA != null) {
      setTipoPlanillas(campoEnviado.PLANILLA);
      formularioEditar.setFieldsValue({ tipoPlanilla: "" });
    }
    if (campoEnviado.CARGO != null) {
      setCargos(campoEnviado.CARGO);
      formularioEditar.setFieldsValue({ cargo: { id: "" } });
    }
    if (campoEnviado.PERFIL != null) {
      setPerfiles(campoEnviado.PERFIL);
      formularioEditar.setFieldsValue({ perfil: "" });
    }
    if (campoEnviado.SECCION != null) {
      setSecciones(campoEnviado.SECCION);
      formularioEditar.setFieldsValue({ seccion: "" });
    }
    if (campoEnviado.SEDE != null) {
      setSedes(campoEnviado.SEDE);
      formularioEditar.setFieldsValue({ sede: { id: "" } });
    }
    if (campoEnviado.SUBSEDE != null) {
      setSubsedes(campoEnviado.SUBSEDE);
      formularioEditar.setFieldsValue({ subsede: "" });
    }
  }

  const onChangeNumeroDocumentoIdentidad = (e) => {
    const dni = formularioEditar.getFieldValue("numeroDocumentoIdentidad").replace(/\s+/g, "");
    formularioEditar.setFieldsValue({
      numeroDocumentoIdentidad: dni,
    });
  };
  const onChangeCodigoColaborador = (e) => {
    const codigo = formularioEditar.getFieldValue("codigoTrabajador").replace(/\s+/g, "");
    formularioEditar.setFieldsValue({
      codigoTrabajador: codigo,
    });
  };
  const onSelectTipoDocumento = (string, option) => {
    setTipoDocumentoSeleccionado(option.value);
  };

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 28 }} />} spinning={loading} size="large" tip="Cargando...">
      <ContainerCrear
        titulo="Editar Postulante"
        descripcion="En este formulario podrás editar la información personal de tu postulante."
        colSpan={12}
      >
        <Form form={formularioEditar} layout="vertical" size="large" onFinish={handleOnFinish}>
          <Form.Item name="id" noStyle>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="estadoRegistro" noStyle>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="personaId" noStyle>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item name="eliminado" noStyle>
            <Input type="hidden" value={false} />
          </Form.Item>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Tipo Documento Identidad"
                name="tipoDocumentoIdentidad"
                rules={[
                  {
                    required: true,
                    message: "Selecciona tu tipo de documento de identidad",
                  },
                ]}
              >
                <Select allowClear onSelect={onSelectTipoDocumento} disabled>
                  <Option value={tipoDocumentoIdentidad.DNI.valor}>{tipoDocumentoIdentidad.DNI.nombre}</Option>
                  <Option value={tipoDocumentoIdentidad.CARNET_EXTRANJERIA.valor}>
                    {tipoDocumentoIdentidad.CARNET_EXTRANJERIA.descripcion}
                  </Option>
                  <Option value={tipoDocumentoIdentidad.PASAPORTE.valor}>
                    {tipoDocumentoIdentidad.PASAPORTE.nombre}
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Número de Documento Identidad"
                name="numeroDocumentoIdentidad"
                rules={[
                  {
                    required: true,
                    message: "Escribe el número de tu documento de identidad",
                    whitespace: true,
                  },
                ]}
              >
                <Input
                  disabled
                  autoComplete="off"
                  maxLength={tipoDocumentoSeleccionado == tipoDocumentoIdentidad.DNI.valor ? 8 : 15}
                  onChange={onChangeNumeroDocumentoIdentidad}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Apellido Paterno"
                name="apellidoPaterno"
                rules={[
                  {
                    required: true,
                    message: "Escribe tu apellido paterno",
                    whitespace: true,
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Apellido Materno"
                name="apellidoMaterno"
                rules={[
                  {
                    required: true,
                    message: "Escribe tu apellido materno",
                    whitespace: true,
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Nombres"
                name="nombres"
                rules={[
                  {
                    required: true,
                    message: "Escribe los nombres completos del postulante",
                    whitespace: true,
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Sexo" name="sexoColaborador">
                <Select>
                  <Option value={SexoColaborador.FEMENINO}>{SexoColaborador.FEMENINO} </Option>
                  <Option value={SexoColaborador.MASCULINO}>{SexoColaborador.MASCULINO}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Correo Electrónico"
                name="correoElectronico"
                rules={[
                  {
                    required: true,
                    message: "Escribe tu correo electrónico",
                  },
                  {
                    type: "email",
                    message: "Correo electrónico ingresado no es válido",
                  },
                ]}
              >
                <Input autoComplete="off" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Código Colaborador" name="codigoTrabajador">
                <Input autoComplete="off" onChange={onChangeCodigoColaborador} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Número de Celular" name="celular">
                <Input autoComplete="off" />
              </Form.Item>
            </Col>
          </Row>
          <Col span={12}>
            <Form.Item label="Fecha Nacimiento" name="fechaNacimiento">
              <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
            </Form.Item>
          </Col>
          <Row gutter={16}>
            {camposColaboradores.length > 0 &&
              camposColaboradores.map((campoColaborador, index) => {
                if (campoColaborador.visible === true && campoColaborador.campo === CampoColaborador.PERFIL) {
                  return (
                    <Col span={12} key={campoColaborador.campo}>
                      <Form.Item
                        label={campoColaborador.etiqueta}
                        name="perfil"
                        rules={[
                          {
                            required: CampoColaborador.campoObligatorio,
                            message: "Seleccione Perfil",
                          },
                        ]}
                      >
                        <Select
                          onSelect={(value, options) => handleOnChangeCampo(value, options, index)}
                          showSearch
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {perfiles.length > 0 &&
                            perfiles.map((perfil) => {
                              return (
                                <Option key={perfil.id} value={perfil.id}>
                                  {perfil.nombre}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                  );
                } else if (campoColaborador.visible === true && campoColaborador.campo === CampoColaborador.SEDE) {
                  return (
                    <Col span={12} key={campoColaborador.campo}>
                      <Form.Item
                        name={["sede", "id"]}
                        label={campoColaborador.etiqueta}
                        rules={[
                          {
                            required: campoColaborador.campoObligatorio,
                            message: `Seleccione ${campoColaborador.etiqueta}`,
                          },
                        ]}
                      >
                        <Select onChange={(value, options) => handleOnChangeCampo(value, options, index)}>
                          {sedes.length > 0 &&
                            sedes.map((sede) => {
                              return (
                                <Option key={sede.id} value={sede.id}>
                                  {sede.nombre}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item name={["sede", "nombre"]} noStyle>
                        <Input type="hidden" />
                      </Form.Item>
                    </Col>
                  );
                } else if (campoColaborador.visible === true && campoColaborador.campo === CampoColaborador.SUBSEDE) {
                  return (
                    <Col span={12} key={campoColaborador.campo}>
                      <Form.Item
                        label={campoColaborador.etiqueta}
                        name="subsede"
                        rules={[
                          {
                            required: campoColaborador.campoObligatorio,
                            message: "Seleccione Subcentro de costo",
                          },
                        ]}
                      >
                        <Select
                          onSelect={(value, options) => handleOnChangeCampo(value, options, index)}
                          showSearch
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {subsedes.length > 0 &&
                            subsedes.map((subsede) => {
                              return (
                                <Option key={subsede.id} value={subsede.id}>
                                  {subsede.nombre}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                  );
                } else if (campoColaborador.visible === true && campoColaborador.campo === CampoColaborador.SECCION) {
                  return (
                    <Col span={12} key={campoColaborador.campo}>
                      <Form.Item
                        label={campoColaborador.etiqueta}
                        name="seccion"
                        rules={[
                          {
                            required: campoColaborador.campoObligatorio,
                            message: "Seleccione Sección",
                          },
                        ]}
                      >
                        <Select
                          onSelect={(value, options) => handleOnChangeCampo(value, options, index)}
                          showSearch
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {secciones.length > 0 &&
                            secciones.map((seccion) => {
                              return (
                                <Option key={seccion.id} value={seccion.id}>
                                  {seccion.nombre}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                  );
                } else if (campoColaborador.visible === true && campoColaborador.campo === CampoColaborador.PLANILLA) {
                  return (
                    <Col span={12} key={campoColaborador.campo}>
                      <Form.Item
                        label={campoColaborador.etiqueta}
                        name="tipoPlanilla"
                        rules={[
                          {
                            required: campoColaborador.campoObligatorio,
                            message: "Seleccione Tipo Planilla",
                          },
                        ]}
                      >
                        <Select
                          onSelect={(value, options) => handleOnChangeCampo(value, options, index)}
                          showSearch
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {tipoPlanillas.length > 0 &&
                            tipoPlanillas.map((tipoPlanilla) => {
                              return (
                                <Option key={tipoPlanilla.id} value={tipoPlanilla.id}>
                                  {tipoPlanilla.nombre}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                    </Col>
                  );
                } else if (campoColaborador.visible === true && campoColaborador.campo === CampoColaborador.CARGO) {
                  return (
                    <Col span={12} key={campoColaborador.campo}>
                      <Form.Item
                        name={["cargo", "id"]}
                        label={campoColaborador.etiqueta}
                        rules={[
                          {
                            required: campoColaborador.campoObligatorio,
                            message: "Seleccione Cargo",
                          },
                        ]}
                      >
                        <Select onChange={(value, options) => handleOnChangeCampo(value, options, index)}>
                          {cargos.length > 0 &&
                            cargos.map((cargo) => {
                              return (
                                <Option key={cargo.id} value={cargo.id}>
                                  {cargo.nombre}
                                </Option>
                              );
                            })}
                        </Select>
                      </Form.Item>
                      <Form.Item name={["cargo", "nombre"]} noStyle>
                        <Input type="hidden" />
                      </Form.Item>
                    </Col>
                  );
                } else return null;
              })}
          </Row>
          <Row justify="center">
            <Space>
              <Col>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button type="default" onClick={handleOnClickCancelar}>
                    Cancelar
                  </Button>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button type="primary" icon={<CheckOutlined />} htmlType="submit" loading={loading}>
                    Guardar Postulante
                  </Button>
                </Form.Item>
              </Col>
            </Space>
          </Row>
        </Form>
      </ContainerCrear>
    </Spin>
  );
}
