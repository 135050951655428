import React, { useContext } from "react";

import { Button } from "antd";
import { useHistory } from "react-router";

const Botones = () => {
  let history = useHistory();
  const onClickCrearPlantilla = () => {
    history.push("/configuracion/plantilla-envio-documentos/crear");
  };
  return (
    <Button type="primary" onClick={onClickCrearPlantilla}>
      Crear Plantilla Onboarding
    </Button>
  );
};
export default Botones;
