import React, { useEffect, useState } from 'react'
import { Form, Input, Row, Col, Modal, DatePicker, Select } from 'antd'
import { MinusCircleOutlined, RedoOutlined } from '@ant-design/icons'
import { useCargarUsuarios } from 'pages/seleccion/convocatorias/crear/hooks'
import { ConvocatoriaService } from 'services/reclutamiento-y-seleccion/ConvocatoriaService'
import { solicitudAtom } from '../DetalleSeguimientoSolicitudPersonalStore'
import { useAtom } from 'jotai'

const ModalCrearConvocatoria = ({
  modalCrear,
  setModalCrear,
  setTabActivo, isPendiente
}) => {
  const [formCrear] = Form.useForm()

  const { responsables } = useCargarUsuarios()
  const [responsableSeleccionado, setResponsableSeleccionado] = useState(undefined)
  const [solicitud,setSolicitud]=useAtom(solicitudAtom);

  useEffect(() => {
    if (solicitud) {
      formCrear.setFieldsValue(solicitud)
    }
  }, [solicitud])

  const onChangeResponsable = (value, Option) => {
    const responsable = {
      id: value,
      nombreCompleto: Option.children,
      login: Option.labellogin,
      numeroDocumentoIdentidad: Option.labelnumerodocumentoidentidad,
    }
    //console.log("Reponsable:",responsable);
    setResponsableSeleccionado(responsable)
  }

  const handleOk = () => {
    formCrear.submit()
  }

  const crearConvocatoria = async (formulario) => {
    try {
      formulario.tipo = solicitud.tipoConvocatoria
      formulario.cargo = solicitud.puesto.nombre
      formulario.responsable = responsableSeleccionado
      formulario.solicitudPersonalId = solicitud.id
      formulario.fechaLimitePostulacion = formulario.fechaLimite.format('DD/MM/YYYY')
      
      await ConvocatoriaService.insertar(formulario)
      setSolicitud({...solicitud, estado:"INICIADO"})
      setTabActivo("convocatorias")
      isPendiente(false)
      setModalCrear(false)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal
      title="Crear Convocatoria"
      open={modalCrear}
      onOk={handleOk}
      //width={"50%"}
      onCancel={() => setModalCrear(false)}
      destroyOnClose
    >
      <Form layout="vertical" form={formCrear} onFinish={crearConvocatoria}>
        <Form.Item name={'tipoConvocatoria'} label={'Tipo Convocatoria'}
          rules={[{ required: true, message: 'Ingrese la Fecha Limite.' }]}
        >
          <Input readOnly width={'100%'} />
        </Form.Item>
        <Form.Item name={['puesto', 'nombre']} label={'Puesto'}
          rules={[{ required: true, message: 'Ingrese la Fecha Limite.' }]}
        >
          <Input readOnly width={'100%'} />
        </Form.Item>
        <Form.Item name={'objetivo'} label={'Objetivos'}
          rules={[{ required: true, message: 'Ingrese la Fecha Limite.' }]}
        >
          <Input.TextArea
            autoSize={{ minRows: 2, maxRows: 4 }}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item name={'requisitos'} label={'Requisitos'}
          rules={[{ required: true, message: 'Ingrese la Fecha Limite.' }]}
        >
          <Input.TextArea
            autoSize={{ minRows: 4, maxRows: 7 }}
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name={'fechaLimite'} label={'Fecha Límite Postulación'}
              rules={[
                {required: true,message: 'Ingrese la Fecha Limite de Postulación.'},
              ]}
            >
              <DatePicker style={{ width: '100%' }} format={'DD/MM/YYYY'} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'cantidad'} label={'Cantidad'}>
              <Input readOnly width={'100%'} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Responsable" name="responsableForm"
          rules={[{ required: true, message: 'Seleccione el Responsable.' }]}
        >
          <Select
            showSearch
            placeholder="Seleccione el Responsable"
            onChange={onChangeResponsable}
            optionFilterProp="children"
          >
            {responsables !== undefined &&
              responsables.map((responsable) => {
                return (
                  <Select.Option
                    key={responsable.id}
                    value={responsable.id}
                    labellogin={responsable.login}
                    labelnumerodocumentoidentidad={responsable.numeroDocumentoIdentidad}>
                    {responsable.nombreCompleto}
                  </Select.Option>
                )
              })}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default ModalCrearConvocatoria
