import React, { useRef } from "react";
import FilterServerConstantes from "./FilterServerConstantes";
// import DatePicker from "react-datepicker";
import { Button, Input } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import parse from "date-fns/parse";
import format from "date-fns/format";
import { useEffect } from "react";
import { useState } from "react";
import { getRenderPropValue } from "antd/lib/_util/getRenderPropValue";

export function FilterServerInputMenuEditar({
  menu,
  filtro,
  onCambiarFiltro,
  onCerrarMenu,
}) {
  if (menu === FilterServerConstantes.MENU.opcionesEditar) {
    if (filtro.tipo === "fecha") {
      return (
        <MenuEditarFecha filtro={filtro} onCambiarFiltro={onCambiarFiltro} />
      );
    }

    if (filtro.tipo === "texto") {
      return (
        <MenuEditarTexto
          filtro={filtro}
          onCambiarFiltro={onCambiarFiltro}
          onCerrarMenu={onCerrarMenu}
        />
      );
    }
  }
  return "";
}

export function MenuEditarFecha({ filtro, onCambiarFiltro }) {
  //   console.log(parse(filtro.valor, "dd/MM/yyyy", new Date()));

  const [fecha, setFecha] = useState(null);
  //   const fecha = new Date(parse(filtro.valor, "dd/MM/yyyy", new Date()));

  useEffect(() => {
    const fechaFormateada = parse(filtro.valor, "dd/MM/yyyy", new Date());
    setFecha(fechaFormateada);
  }, [filtro.valor]);

  function handleOnChange(fecha) {
    setFecha(fecha);
    filtro.nuevoValor = format(fecha, "dd/MM/yyyy");
    onCambiarFiltro(filtro);
  }

  return (
    <div className="filter-menu-container" style={filtro.style}>
      Cambiar por el datepicker de antd
      {/* <DatePicker
        className="form-control"
        selected={fecha}
        // onChange={(fecha) => setFecha(fecha)}
        onChange={(fecha) => handleOnChange(fecha)}
        inline
      /> */}
    </div>
  );
}

export function MenuEditarTexto({ filtro, onCambiarFiltro, onCerrarMenu }) {
  const [inputValue, setInputValue] = useState("");
  const refInput = useRef(null);

  function handleOnChange(e) {
    setInputValue(e.target.value);
  }

  function handleOnKeyDown(evento) {
    // if (evento.key === "Enter") {
    cambiarFiltro();
    // }
  }

  function handleOnClickBtnAceptar() {
    cambiarFiltro();
  }

  function cambiarFiltro() {
    filtro.nuevoValor = inputValue.trim();
    onCambiarFiltro(filtro);
  }

  useEffect(() => {
    setInputValue(filtro.valor);
    refInput.current.focus();
  }, [filtro.valor]);

  return (
    <div className="filter-menu-container-editar" style={filtro.style}>
      <Input ref={refInput}
        value={inputValue}
        onChange={handleOnChange}
        onPressEnter={handleOnKeyDown} />
      {/* <input
        ref={refInput}
        type="text"
        className="form-control"
        style={{ minWidth: "220px" }}
        value={inputValue}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
      /> */}
      &nbsp;&nbsp;
      <Button onClick={handleOnClickBtnAceptar}>
        <CheckOutlined style={{ color: 'green', fontWeight: 'bold' }} />
      </Button>

      {/* <button
        id="btnAceptar"
        className="btn btn-default"
        style={{ minWidth: "35px" }}
        onClick={handleOnClickBtnAceptar}
      >
        <i className="fa fa-check text-success"></i>
      </button> */}
      &nbsp;&nbsp;
      <Button onClick={onCerrarMenu}>
        <CloseOutlined style={{ color: 'red', fontWeight: 'bold' }} />
      </Button>
      {/* <button
        id="btnCerrar"
        className="btn btn-default"
        style={{ minWidth: "35px" }}
        onClick={onCerrarMenu}
      >
        <i className="fa fa-times text-danger"></i>
      </button> */}
    </div>
  );
}
