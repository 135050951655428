import React from "react";
import { Button, Popconfirm } from "antd";
import { EditOutlined, LayoutOutlined, DeleteOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { FormularioService } from "services/FormularioService";
import * as atoms from "../ListarFormulariosStore";
import { useAtom } from "jotai";
import { formulario } from "../../crear/CrearFormulariosStore";
import { EstadoRegistro } from "enums/EstadoRegistro";
import { CategoriaDocumento } from "enums/CategoriaDocumento";

export const MenuAcciones = ({ formulario }) => {
  let history = useHistory();
  const [formularios, setFormularios] = useAtom(atoms.formularios);

  const BotonEditar = () => {
    const onclickEditar = () => {
      const rutaEditarFormularioPage = `/configuracion/formularios/${formulario.id}/editar`;
      history.push(rutaEditarFormularioPage);
    };
    return (
      <Button type="link" icon={<EditOutlined />} onClick={onclickEditar}>
        Editar
      </Button>
    );
  };

  const BotonDisenar = () => {
    const onClickBtnDisenar = () => {
      const rutaDisenarFormularioPage = `/configuracion/formularios/${formulario.id}/disenar`;
      history.push(rutaDisenarFormularioPage);
    };

    return (
      <Button type="link" icon={<LayoutOutlined />} onClick={onClickBtnDisenar} disabled={formulario.categoriaDocumento.nombre===CategoriaDocumento.CARGA_DOCUMENTO.nombre ? true:false}>
        Diseñar
      </Button>
    );
  };
  const BotonEliminar = () => {
    const onClickEliminar = () => {
      const formularioId = formulario.id;
      FormularioService.eliminar(formularioId);
      setFormularios(
        formularios
          .map((formulario) => {
            if (formulario.id === formularioId) {
              return { ...formulario, estadoRegistro: EstadoRegistro.ELIMINADO };
            }
            return formulario;
          })
          .filter((formulario) => formulario.estadoRegistro === EstadoRegistro.ACTIVO)
      );
    };

    return (
      <Popconfirm
        title="¿Estás seguro de eliminar el formulario?"
        onConfirm={onClickEliminar}
        okText="Si"
        cancelText="No"
      >
        <Button type="link" icon={<DeleteOutlined />}>
          Eliminar
        </Button>
      </Popconfirm>
    );
  };

  return (
    <React.Fragment>
      <BotonEditar />      
      <BotonDisenar />
      <BotonEliminar />
    </React.Fragment>
  );
};
