import { message } from 'antd';
import { OnboardingColaboradorService } from 'services/OnboardingColaboradorService';
import create from 'zustand'

const useModalRechazarOnboarding = create((set, get) => ({
    loading: false,
    visible: false,
    detalleId:undefined,
    finProceso:false,
    motivoRechazo:undefined,
    mostrarModal: (detalleId) => {
        set({ visible: true});
        set({detalleId});
    },
    cerrarModal: () => {
        set({ visible: false});
    },
    terminarProceso: (motivoRechazo) => {
        const rechazar = async() =>{      
            const _ = await OnboardingColaboradorService.rechazar(motivoRechazo);
            set({finProceso:true });
            set({motivoRechazo:motivoRechazo});
            message.success("El documento ha sido rechazado.")
            set({ visible: false});
        }
        rechazar();
    },
    setFinalizar:() => {
        set({finProceso:false });
        set({detalleId:undefined});
        set({motivoRechazo:undefined});
    }
}));

export default useModalRechazarOnboarding;