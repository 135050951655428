import React from "react";
import { Table } from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import { useListarSolicitudesAusenciaPorGestionHumana } from "../../hooks";
import moment from "moment";
import { MenuAcciones } from "./MenuAcciones";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";

const ListaSolicitudesAusenciaPendientes = () => {
  const { solicitudesAusencia } = useListarSolicitudesAusenciaPorGestionHumana("PENDIENTE");
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const isMaccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const isMaccaferriConstruction = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20521749378" ? true : false;
  const isAceros = getUsuarioAutenticado().empresaSeleccionada?.ruc==="20538379302"	? true	: false;
  return <Table 
             rowKey={(value) => value.id} 
             columns={columnas}
             dataSource={solicitudesAusencia} 
             expandable={{              
              expandedRowRender: (fila) => <Comentario registro={fila} />,
              rowExpandable: (fila) =>
                ((isMaccaferriConstruction || isMaccaferriDePeru) &&
                fila.comentario !== null &&
                fila.comentario !== undefined &&
                fila.comentario !== "") ||
                (isAceros && fila.informacionAdicional !== null),
            }}
             />;
};

const columnas = [
  {
    title: "Colaborador",
    dataIndex: ["colaborador", "nombreCompleto"],
    width: "20%",
    align: "center",
  },
  {
    title: "Jefe Inmediato",
    dataIndex: ["jefeInmediato", "nombreCompleto"],
    width: "20%",
    align: "center",
    render: (text, fila) => {
      return (
        <React.Fragment>
          <CheckCircleTwoTone twoToneColor="#52c41a" /> {fila.jefeInmediato?.nombreCompleto}
        </React.Fragment>
      );
    },
  },
  {
    title: "Tipo Ausencia",
    dataIndex: ["tipo", "descripcion"],
    width: "10%",
    align: "center",
  },
  {
    title: "Fecha Inicio",
    dataIndex: "fechaInicioAusencia",
    width: "10%",
    align: "center",
    render: (text, fila) => moment(fila.fechaInicioAusencia).format("DD/MM/YYYY hh:mm a"),
  },
  {
    title: "Fecha Fin",
    dataIndex: "fechaFin",
    width: "10%",
    align: "center",
    render: (text, fila) => moment(fila.fechaFinAusencia).format("DD/MM/YYYY hh:mm a"),
  },
  {
    title: "Dias",
    dataIndex: "diasSolicitados",
    width: "5%",
    align: "center",
  },
  {
    title: "Fecha de Solicitud",
    dataIndex: "fechaHoraSolicitud",
    width: "15%",
    align: "center",
    render: (text, fila) => moment(fila.fechaHoraSolicitud).format("DD/MM/YYYY hh:mm a"),
  },
  {
    title: "Acciones",
    align: "center",
    width: "15%",
    render: (text, fila) => <MenuAcciones solicitud={fila} />,
  },
];

const Comentario = ({ registro }) => {
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isMaccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const isMaccaferriConstruction = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20521749378" ? true : false;
	const isAceros = getUsuarioAutenticado().empresaSeleccionada?.ruc==="20538379302"	? true	: false;

	return (
		<React.Fragment>
			{(isMaccaferriDePeru || isMaccaferriConstruction) && (
				<p style={{ margin: 5 }}>{"EMPLEADO DESIGNADO: " + registro.comentario}</p>
			)}
			{isAceros && (
				<div style={{ marginLeft: 50 }}>
					<p>
						<strong>Fecha sobre tiempo: </strong>
						{moment(registro.informacionAdicional.fechaSobreTiempo).format("DD/MM/YYYY")}
					</p>
					<p>
						<strong>Día de compensación: </strong>{" "}
						{moment(registro.informacionAdicional.diaCompensacion).format("DD/MM/YYYY")}
					</p>
					<p>
						<strong>Cantidad de horas: </strong> {registro.informacionAdicional.cantidadHoras}
					</p>
					<p>
						<strong>Horas compensadas: </strong> {registro.informacionAdicional.horasCompensadas}
					</p>
				</div>
			)}
		</React.Fragment>
	);
};

export default ListaSolicitudesAusenciaPendientes;
