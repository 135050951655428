import React, { useContext } from "react";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons"
import { VisorPdfContext } from "context/visor-pdf"
export const PanelBotones = () => {

    const { loading, visibleBtnImprimir, visibleBtnDescargar, imprimirPdf, descargarPdf, cerrarVisorPdf } = useContext(VisorPdfContext);

    return (
        <div className="acciones">

            {
                visibleBtnImprimir &&
                <Button className="milegajo-visor-pdf-header-botones" disabled={loading} onClick={imprimirPdf}>
                    Imprimir
                </Button>
            }

            {
                visibleBtnDescargar &&
                <Button className="milegajo-visor-pdf-header-botones" disabled={loading} onClick={descargarPdf}>
                    Descargar
                </Button>
            }


            <div className="milegajo-visor-pdf-header-separador" />

            <Button type="text" className="cerrar">
                <CloseOutlined style={{ fontSize: "16px" }} onClick={cerrarVisorPdf} />
            </Button>

        </div >
    )
}