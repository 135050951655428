import { BaseService } from "./BaseService";

const OnboardingMasivoService = {

  async notificar(data) {
    const respuesta = await BaseService.post("/notificacion/onboarding-masivo", data);
    return respuesta.data;
  },

}
export default OnboardingMasivoService;