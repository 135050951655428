import React, { useContext, useEffect, useState, useRef } from "react";
import { Table, Button, Input, Space } from "antd";
import { ContratoDetalleSeguimientoContext } from "context/contrato/seguimiento-detalle/ContratoDetalleSeguimientoContext";
import { SearchOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { VisorPdfContext } from "context/visor-pdf";
import Highlighter from "react-highlight-words";
import { useDetalleSeguimientoContratoStore } from "hooks/contratos";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import * as atoms from "store/local/contratos/DetalleSeguimientoContratoStore";
import { ContratoService } from "services/ContratoService";
import { useModalVisorPdf } from "store/global";

const TablaContratosCargados = ({ envio }) => {
  const { getOrganizacion, getUsuarioAutenticado } = useContext(SecurityContext);

  const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal);
  // const { mostrarVisorPdf } = useContext(VisorPdfContext);

  const [loading, setLoading] = useState(false);
  // const [loading, setLoading] = useAtom(atoms.loading);
  //const [envio] = useAtom(atoms.envio);
  const [contratos, setContratos] = useAtom(atoms.contratos);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  useEffect(() => {
    const cargarContratos = async () => {
      try {
        setLoading(true);
        const respuesta = await ContratoService.listar(getUsuarioAutenticado().empresaSeleccionada.id, envio.id);
        setContratos(respuesta);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    cargarContratos();
  }, []);

  const adicionarColumnasFiltro = (dataIndex, placeholder, propiedad) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={placeholder !== undefined ? placeholder : `Buscar por ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Limpiar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) =>
      propiedad === undefined
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : record[dataIndex][propiedad].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={propiedad === undefined ? text?.toString() : text[propiedad]?.toString()}
        />
      ) : propiedad === undefined ? (
        text
      ) : (
        text[propiedad]
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columnas = [
    {
      title: "Nro. Documento",
      key: "colaboradorNumeroDocumentoIdentidad",
      dataIndex: "colaboradorNumeroDocumentoIdentidad",
      align: "center",
      width: "20%",
      ...adicionarColumnasFiltro("colaboradorNumeroDocumentoIdentidad", "Buscar por Documento"),
    },
    {
      title: "Colaborador",
      key: "colaboradorNombreCompleto",
      dataIndex: "colaboradorNombreCompleto",
      width: "30%",
      ...adicionarColumnasFiltro("colaboradorNombreCompleto", "Buscar por Colaborador"),
    },
    {
      title: "Correo Electrónico / Celular",
      key: "colaboradorCorreoElectronico",
      dataIndex: "colaboradorCorreoElectronico",
      width: "30%",
      render: (text, fila) => {
        return (
          <React.Fragment>
            <div style={{ display: "flex", flexDirection: "column" }}>
              {fila.colaboradorCorreoElectronico && (
                <span>
                  <MailOutlined /> {fila.colaboradorCorreoElectronico}
                </span>
              )}
              {getOrganizacion().notificarPorSms && fila.colaboradorNumeroCelular && (
                <span>
                  <PhoneOutlined /> {fila.colaboradorNumeroCelular}
                </span>
              )}
            </div>
          </React.Fragment>
        );
      },
      // ...adicionarColumnasFiltro("colaboradorCorreoElectronico", "Buscar por Correo Electrónico")
    },
    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      width: "15%",
      render: (text, fila) => (
        <Button type="link" onClick={() => onClickVerDocumento(fila)}>
          <SearchOutlined />
          Ver Documento
        </Button>
      ),
    },
  ];

  const onClickVerDocumento = async (detalleEnvio) => {
    // const urlPdf = getUrlPdf(getUsuarioAutenticado().empresaSeleccionada.id, detalleEnvio.id);
    const urlPdf = ContratoService.getUrlVistaPrevia(getUsuarioAutenticado().empresaSeleccionada.id, detalleEnvio.id);
    const titulo = envio?.descripcion;
    // const informacionPdf = {
    //     periodo: envio?.periodo,
    //     descripcion: envio?.descripcion
    // }
    mostrarModalVisorPdf(urlPdf, titulo);
  };

  return <Table rowKey={(contrato) => contrato.id} dataSource={contratos} columns={columnas} />;
};

export default TablaContratosCargados;
