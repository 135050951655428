import React from "react";
import { Button, Dropdown, notification, } from "antd";
import { DownloadOutlined ,  } from "@ant-design/icons";
import { useAtom } from "jotai";
import SolicitudPersonalService from "services/reclutamiento-y-seleccion/SolicitudPersonalService";
import { saveAs } from "file-saver";
import { loadingSolicitudAtom } from "../../SeguimientoSolicitudesPersonalStore";

const BotonExportar = () => {
    const [,setLoadingPage] = useAtom(loadingSolicitudAtom);
    const items = [
        {
          key: '1',
          label: (
            <Button type="link" onClick={()=>handleOnclickExportarDatos()}>
              Exportar en Excel
            </Button>
          ),
        }
      ];

      async function handleOnclickExportarDatos() {
        try {
            setLoadingPage(true);
          let respuesta = await SolicitudPersonalService.descargarReporteExcel();
          const filename = respuesta.headers.filename;
          saveAs(respuesta.data, filename);
          setLoadingPage(false);
        } catch (error) {
          notification.error({
            message: "No se excontraron solicitudes para exportar",
          });
        }
        setLoadingPage(false);
      }

  return (
    <Dropdown
      menu={{
        items,
      }}
      placement="bottom"
      arrow={{
        pointAtCenter: true,
      }}
    >
      <Button type="primary" icon={<DownloadOutlined />}>Exportar</Button>
    </Dropdown>
  );
};

export default BotonExportar;
