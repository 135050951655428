import create from "zustand";
import { ContratoService } from "services/ContratoService";
import { LoginService } from "services/LoginService";
import { Headers } from "enums";

const useDetalleSeguimientoContratoStore = create((set, get) => ({
  loading: false,
  contratos: [],
  envio: undefined,
  cargarEnvio: async (envioId) => {
    try {
      set({ loading: true });
      const envio = await ContratoService.buscarEnvioPorId(envioId);
      set({ envio: envio });
    } catch (error) {
      console.error(error);
    } finally {
      set({ loading: false });
    }
  },
  cargarContratos: async (empresaId, envioId) => {
    try {
      set({ loading: true });
      const contratos = await ContratoService.listar(empresaId, envioId);
      set({ contratos: contratos });
    } catch (error) {
      console.error(error);
    } finally {
      set({ loading: false });
    }
  },
  setEnvio: (envio) => {
    set({ envio: envio });
  },
  getUrlPdf: (empresaId, detalleEnvioId) => {
    // const empresaId = getUsuarioAutenticado().empresaSeleccionada.id;
    const urlPdf =
      process.env.REACT_APP_ENDPOINT +
      `contrato/empresa/${empresaId}/detalle-envio/${detalleEnvioId}/vistaPrevia`;
    const httpHeaders = {
      Authorization: "Bearer " + LoginService.getJwtToken(),
      [Headers.ORGANIZACION_ID]:
        LoginService.getUsuarioAutenticado().organizacionId,
      [Headers.EMPRESA_ID]:
        LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
    };

    return {
      url: urlPdf,
      httpHeaders: httpHeaders,
    };
  },
}));

export default useDetalleSeguimientoContratoStore;
