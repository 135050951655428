import React, { useEffect, useState } from "react";
import { Table, Button } from "antd";
import * as atoms from "../../SeguimientevaluacionStore";
import { useAtom } from "jotai";
import EvaluacionService from "../../../../../../services/EvaluacionService";
import { EstadoAprobacionEvaluacion } from "enums/EstadoAprobacionEvaluacion";
import { LoadingOutlined } from "@ant-design/icons";
import MenuAcciones from "./MenuAcciones";

const TablaEvaluaciones = () => {
  const [evaluaciones, setEvaluaciones] = useAtom(atoms.evaluacionesAtom);
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const cargarDatos = async () => {
      setLoading(true)
      const evaluacionesListadas = await EvaluacionService.listar();
      setEvaluaciones(evaluacionesListadas);
      setLoading(false)
    };
    cargarDatos();
  }, []);
  const columnas = [
    {
      title: "Periodo",
      dataIndex: "periodo",
      align: "center",
    },
    {
      title: "Colaborador",
      dataIndex: ["colaborador", "nombreCompleto"],
      align: "center",
    },
    {
      title: "Jefe Inmediato",
      dataIndex: ["jefeInmediato", "nombreCompleto"],
      align: "center",
    },
    {
      title: "Fecha Evaluación",
      dataIndex: ["jefeInmediato", "fechaAprobacion"],
      align: "center",
    },
    {
      title: "Estado",
      //dataIndex:"estado",
      align: "center",
      render: (fila) =>
        fila.estado === EstadoAprobacionEvaluacion.PENDIENTE_APROBACION_GH ? "PENDIENTE APROBACIÓN" : fila.estado,
    },
    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      render: (fila) => <MenuAcciones evaluacion={fila} />,
    },
  ];
  return <Table rowKey={(evaluacion) => evaluacion.id} columns={columnas} dataSource={evaluaciones}
  loading={{
    spinning: loading,
    indicator: <LoadingOutlined style={{ fontSize: 28 }}  />,
    tip:"Cargando..."
  }} />;
};
export default TablaEvaluaciones;
