import React, { useContext, useEffect, useState } from "react";
import { Table, Button, Space, Form, Select, DatePicker, Row, Col, Badge } from "antd";
import * as atoms from "../ListarConsultasColaboradorStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import ConsultarMensajeColaboradorService from "services/ConsultarMensajeColaboradorService";
import TipoDocumentoService from "services/TipoDocumentoService";
import { ContratoService } from "services/ContratoService";
import { FiltroBusqueda } from "../FiltroBusqueda";
import { useModalVisorPdf } from "store/global";
import moment from "moment";
import { consultaDocumentoSeleccionadoAtom } from "pages/consulta-documento/ConsultaDocumentoStore";
import { TipoConsulta } from "enums/TipoConsulta";
import { DetalleConsultaColaboradorService } from "services/DetalleConsultaColaboradorService";
import MenuAcciones from "./Acciones";
import { VinculoLaboral } from "enum/VinculoLaboral";

const TablaConsultasColaborador = () =>{
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const[consultas,setConsultas] = useAtom(atoms.consultasAtom);
    const[visibleModalConsulta,setVisibleModalConsulta] = useAtom(atoms.visibleModalConsultaAtom);
    const[,setConsultaSeleccionada] = useAtom(atoms.consultaSeleccionadaAtom);
    const[detalleConsulta,setDetalleConsulta] =useAtom(atoms.detalleConsultaAtom);
    const[tipoDocumentos,setTipoDocumentos]=useAtom(atoms.tipoDocumentosAtom);
    const [periodoListar] = useAtom(atoms.periodoAtom);
    const [paginActual, setPaginaActual] = useAtom(atoms.paginaActualAtom);
    const [paginaSeguimiento, setPaginaSeguimiento] = useAtom(atoms.paginaSeguimientoAtom);    
    const [totalPaginacion, setTotalPaginacion] = useAtom(atoms.totalPaginacionEnviosAtom );    
    const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal);
    const { Option,OptGroup  } = Select;
    const { RangePicker } = DatePicker;
    const [formListaConsulta] = Form.useForm();
    //const [loading, setLoading] = useState(false);
    const [reset, setReset] = useState(false);
    //const [filtros, setFiltros] = useState(undefined);    
    const [filtros, setFiltros] = useAtom(atoms.filtrosSeleccionadosAtom);
    const [numeroRespuestas, setNumeroRespuestas] = useAtom(atoms.numeroRespuestasAtom);
    const [,setFiltroPeriodoInicio]= useAtom(atoms.periodoInicioAtom);
    const [,setFiltroPeriodoFin]= useAtom(atoms.periodoFinAtom);
    let paginacion = 30;

  //let paginacion = 30;

  useEffect(() => {
    const fetch = async () => {
      setPaginaActual(1);
      setPaginaSeguimiento(0);
      const tipoDocumento = await TipoDocumentoService.listarPorUsuarioYCategorizado(getUsuarioAutenticado().login);
      const arrayTipo = Object.entries(tipoDocumento);
      setTipoDocumentos(arrayTipo);
        }        
        fetch();        
        
        if (filtros !== undefined ){
       
          actualizarData();
          setFiltros(filtros);
        }
        setFiltroPeriodoInicio(formListaConsulta.getFieldValue("periodo")[0].format("MM/YYYY"));
        setFiltroPeriodoFin(formListaConsulta.getFieldValue("periodo")[1].format("MM/YYYY"));
    },[filtros]);  
    

    // const cargarDatos= async(      
    //   periodoInicio=moment().startOf("year").format("MM/YYYY"),
    //   periodoFin=moment().endOf("year").format("MM/YYYY"),
    //   pagina=0  )=>{

    //   const _consultas = await ConsultarMensajeColaboradorService.listar(
    //     getUsuarioAutenticado().empresaSeleccionada.id,
    //     getUsuarioAutenticado().id,       
    //     periodoInicio,
    //     periodoFin,
    //     pagina,
    //     paginacion);
    //   setConsultas(_consultas.consultas); 
    //   setConsultasBase(_consultas.consultas);
          
    //   setTotalPaginacion(_consultas.totalConsultas);
      
    // }
    const actualizarData = async (     
                        periodoInicio=formListaConsulta.getFieldValue("periodo")[0].format("MM/YYYY"),
                        periodoFin=formListaConsulta.getFieldValue("periodo")[1].format("MM/YYYY"),
                        pagina=0)=> {
          
      const _consultas = await ConsultarMensajeColaboradorService.listarPaginado(getUsuarioAutenticado().empresaSeleccionada.id,
         getUsuarioAutenticado().id,
        filtros["tipoDocumento"],       
        filtros["colaborador"],
        filtros["documentoIdentidad"],
        filtros["codigoColaborador"], 
        filtros["tipoConsulta"],
        filtros["estadoConsulta"],
        periodoInicio,
        periodoFin,       
        pagina);       
      
      setConsultas(_consultas.consultas);
      setTotalPaginacion(_consultas.totalConsultas);
      setReset(false);
      obtenerNumeroDetalleNoVisualizadas(_consultas.consultas);
      // if (pagina === undefined) {
      //   setPaginaActual(1);
      // }  
      
    }   
    const obtenerNumeroDetalleNoVisualizadas =async (consultas) => {
      let listaConsultasId = [];
          
         if (consultas.length>0){

            consultas.map((consulta)=>{
            listaConsultasId.push(consulta.id); 
          });  
          const _numeroRespuestas = await DetalleConsultaColaboradorService.listaNumeroRespuestas(listaConsultasId,"mensajeColaborador");           
          setNumeroRespuestas(_numeroRespuestas);
        }        
               
          
  }
    const columnasTablaConsulta=[
        {
            title:"Periodo",
           // dataIndex:"periodo",
            align:"center",
            render:(fila)=>(fila.periodo!==null ? fila.periodo: "-")
        },
        {
           title:"Tipo Consulta",
           align:"center",
           render:(fila)=>( fila.tipoConsulta===null && fila.tipoDocumentoId !==null ?
                             ("POR DOCUMENTO"):                            
                             fila.tipoConsulta!==null && fila.tipoConsulta===TipoConsulta.POR_DOCUMENTO ? ("POR DOCUMENTO"):                             
                             (fila.tipoConsulta)
                           )
        },
        {
            title:"Documento",
           // dataIndex:"documentoNombre",
            align:"center",
            render:(fila)=>(fila.documentoNombre!==null ? fila.documentoNombre: "-")
        },
        {
            title:"Colaborador",
            //dataIndex:"nombreCompletoColaborador",
            align:"center",
            render:(fila)=>(fila.nombreCompleto!==null ? fila.nombreCompleto:
                            fila.nombreCompletoColaborador
                            )
        },
        {
            title:"Documento Identidad",
            dataIndex:"numeroDocumentoIdentidad",
            align:"center",
        },
        {
            title:"Código",
            dataIndex:"codigoColaborador",
            align:"center",
        },
        {
            title:"Fecha Envio",   
            align:"center",       
            render:(fila)=>(                                 
                fila.fechaRegistro
            )            

        },
      //   {
      //       title:"Ver Consulta", 
      //       align:"center",           
      //       render:(fila)=>( 
      //         <React.Fragment>
      //         {numeroRespuestas.length>0 && numeroRespuestas.map((respuesta) => {
      //             return(
      //                  fila.id === respuesta.consultaColaboradorId && (
      //                     <Badge
      //                       count={respuesta.numeroRespuestas}                                    
      //                     >
      //                       <Button type="link" onClick={()=>onClickVerConsulta(fila)}>ver</Button>
      //                     </Badge>
      //                   )
      //             )
      //         })}                                                  
                
      //      </React.Fragment>            
                
      //       )
      //   },
      //   {
      //     title:"Ver Documento", 
      //     align:"center",           
      //     render:(fila)=>(
      //           fila.tipoConsulta===TipoConsulta.POR_DOCUMENTO ?(
      //             <Button type="link" onClick={()=>onClickVerDocumento(fila)}>ver</Button>
      //           ):("-")
              
      //     )
      // },
      {
        title:"Estado",
        //dataIndex:"estadoConsulta",        
        align:"center",
        render:(fila)=>(
          <React.Fragment>
               {numeroRespuestas.length>0 && numeroRespuestas.map((respuesta) => {
                   return(
                        fila.id === respuesta.consultaColaboradorId && (
                           <Badge
                             count={respuesta.numeroRespuestas}   
                             offset={[12, 0]}                                 
                           >
                             {/* {fila.estadoConsulta} */}
                             {fila.estadoConsulta===null ?("ABIERTO"):fila.estadoConsulta}
                           </Badge>
                         )
                  )
               })}                                                  
                
            </React.Fragment> 
        )
      },
      {
        title:"Acciones",
        key:"acciones",
        align:"center",
        render:(fila)=>( <MenuAcciones consulta={fila}/>)        
      },

    ]
    const onClickVerDocumento = async(consulta)=>{
      try {       
        const respuesta = await ContratoService.getUrlDocumento(getUsuarioAutenticado().empresaSeleccionada.id, consulta.detalleEnvioId);
        mostrarModalVisorPdf(respuesta.urlDocumento, consulta.descripcionDocumentoNombre, true, true);
      } catch (error) {        
        console.error(error);
      } 

    }
    const onClickVerConsulta = async(consulta) =>{
        setVisibleModalConsulta(true);
        setConsultaSeleccionada(consulta);
        const _detallesconsulta = await DetalleConsultaColaboradorService.listar(consulta.id);         
        setDetalleConsulta(_detallesconsulta);
        const consultaEnviada = {
          consultaColaboradorId: consulta.id,
          tipoDetalle:"mensajeColaborador",
      }
        await DetalleConsultaColaboradorService.cambiarEstadoVisualizacionDetalleConsulta(consultaEnviada);
        obtenerNumeroDetalleNoVisualizadas(consultas);
    }
    
    const onChangePeriodo = async()=>{
      setPaginaActual(1);
      setPaginaSeguimiento(0);
      
      if (formListaConsulta.getFieldValue("periodo")) {                  
       
       actualizarData(formListaConsulta.getFieldValue("periodo")[0].format("MM/YYYY"),formListaConsulta.getFieldValue("periodo")[1].format("MM/YYYY"));
     } 

      setFiltroPeriodoInicio(formListaConsulta.getFieldValue("periodo")[0].format("MM/YYYY"));
      setFiltroPeriodoFin(formListaConsulta.getFieldValue("periodo")[1].format("MM/YYYY"));
    
    }
    const onChangePagination = async (page) => {
      setPaginaActual(page);
      setPaginaSeguimiento(page - 1);
      actualizarData(formListaConsulta.getFieldValue("periodo")[0].format("MM/YYYY"),formListaConsulta.getFieldValue("periodo")[1].format("MM/YYYY"));
      
    };
  /*  fetch();

    if (filtros !== undefined) {
      actualizarData();
    }
  }, [filtros]);

  // const cargarDatos= async(
  //   periodoInicio=moment().startOf("year").format("MM/YYYY"),
  //   periodoFin=moment().endOf("year").format("MM/YYYY"),
  //   pagina=0  )=>{

  //   const _consultas = await ConsultarMensajeColaboradorService.listar(
  //     getUsuarioAutenticado().empresaSeleccionada.id,
  //     getUsuarioAutenticado().id,
  //     periodoInicio,
  //     periodoFin,
  //     pagina,
  //     paginacion);
  //   setConsultas(_consultas.consultas);
  //   setConsultasBase(_consultas.consultas);

  //   setTotalPaginacion(_consultas.totalConsultas);

  // }
  const actualizarData = async (
    periodoInicio = formListaConsulta.getFieldValue("periodo")[0].format("MM/YYYY"),
    periodoFin = formListaConsulta.getFieldValue("periodo")[1].format("MM/YYYY"),
    pagina = 0
  ) => {
    const _consultas = await ConsultarMensajeColaboradorService.listarPaginado(
      getUsuarioAutenticado().empresaSeleccionada.id,
      getUsuarioAutenticado().id,
      filtros["tipoDocumento"],
      filtros["colaborador"],
      filtros["documentoIdentidad"],
      filtros["codigoColaborador"],
      filtros["tipoConsulta"],
      filtros["estadoConsulta"],
      periodoInicio,
      periodoFin,
      pagina
    );

    setConsultas(_consultas.consultas);
    setTotalPaginacion(_consultas.totalConsultas);
    setReset(false);
    obtenerNumeroDetalleNoVisualizadas(_consultas.consultas);
    // if (pagina === undefined) {
    //   setPaginaActual(1);
    // }
  };
  const obtenerNumeroDetalleNoVisualizadas = async (consultas) => {
    let listaConsultasId = [];

    if (consultas.length > 0) {
      consultas.map((consulta) => {
        listaConsultasId.push(consulta.id);
      });
      const _numeroRespuestas = await DetalleConsultaColaboradorService.listaNumeroRespuestas(
        listaConsultasId,
        "mensajeColaborador"
      );
      setNumeroRespuestas(_numeroRespuestas);
    }
  };
  const columnasTablaConsulta = [
    {
      title: "Periodo",
      // dataIndex:"periodo",
      align: "center",
      render: (fila) => (fila.periodo !== null ? fila.periodo : "-"),
    },
    {
      title: "Documento",
      // dataIndex:"documentoNombre",
      align: "center",
      render: (fila) => (fila.documentoNombre !== null ? fila.documentoNombre : "-"),
    },
    {
      title: "Colaborador",
      dataIndex: "nombreCompletoColaborador",
      align: "center",
    },
    {
      title: "Documento Identidad",
      dataIndex: "numeroDocumentoIdentidad",
      align: "center",
    },
    {
      title: "Código",
      dataIndex: "codigoColaborador",
      align: "center",
    },
    {
      title: "Fecha Envio",
      align: "center",
      render: (fila) => fila.fechaRegistro,
    },
    //   {
    //       title:"Ver Consulta",
    //       align:"center",
    //       render:(fila)=>(
    //         <React.Fragment>
    //         {numeroRespuestas.length>0 && numeroRespuestas.map((respuesta) => {
    //             return(
    //                  fila.id === respuesta.consultaColaboradorId && (
    //                     <Badge
    //                       count={respuesta.numeroRespuestas}
    //                     >
    //                       <Button type="link" onClick={()=>onClickVerConsulta(fila)}>ver</Button>
    //                     </Badge>
    //                   )
    //             )
    //         })}

    //      </React.Fragment>

    //       )
    //   },
    //   {
    //     title:"Ver Documento",
    //     align:"center",
    //     render:(fila)=>(
    //           fila.tipoConsulta===TipoConsulta.POR_DOCUMENTO ?(
    //             <Button type="link" onClick={()=>onClickVerDocumento(fila)}>ver</Button>
    //           ):("-")

    //     )
    // },
    {
      title: "Estado",
      //dataIndex:"estadoConsulta",
      align: "center",
      render: (fila) => (
        <React.Fragment>
          {numeroRespuestas.length > 0 &&
            numeroRespuestas.map((respuesta) => {
              return (
                fila.id === respuesta.consultaColaboradorId && (
                  <Badge count={respuesta.numeroRespuestas} offset={[12, 0]}>
                    {fila.estadoConsulta}
                  </Badge>
                )
              );
            })}
        </React.Fragment>
      ),
    },
    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      render: (fila) => <MenuAcciones consulta={fila} />,
    },
  ];
  const onClickVerDocumento = async (consulta) => {
    try {
      const respuesta = await ContratoService.getUrlDocumento(
        getUsuarioAutenticado().empresaSeleccionada.id,
        consulta.detalleEnvioId
      );
      mostrarModalVisorPdf(respuesta.urlDocumento, consulta.descripcionDocumentoNombre, true, true);
    } catch (error) {
      console.error(error);
    }
  };
  const onClickVerConsulta = async (consulta) => {
    setVisibleModalConsulta(true);
    setConsultaSeleccionada(consulta);
    const _detallesconsulta = await DetalleConsultaColaboradorService.listar(consulta.id);
    setDetalleConsulta(_detallesconsulta);
    const consultaEnviada = {
      consultaColaboradorId: consulta.id,
      tipoDetalle: "mensajeColaborador",
    };
    await DetalleConsultaColaboradorService.cambiarEstadoVisualizacionDetalleConsulta(consultaEnviada);
    obtenerNumeroDetalleNoVisualizadas(consultas);
  };

  const onChangePeriodo = async () => {
    setPaginaActual(1);
    setPaginaSeguimiento(0);

    if (formListaConsulta.getFieldValue("periodo")) {
      actualizarData(
        formListaConsulta.getFieldValue("periodo")[0].format("MM/YYYY"),
        formListaConsulta.getFieldValue("periodo")[1].format("MM/YYYY")
      );
    }
  };
  const onChangePagination = async (page) => {
    setPaginaActual(page);
    setPaginaSeguimiento(page - 1);
    actualizarData(
      formListaConsulta.getFieldValue("periodo")[0].format("MM/YYYY"),
      formListaConsulta.getFieldValue("periodo")[1].format("MM/YYYY")
    );
  };
*/
  return (
    <React.Fragment>
      <Form
        layout="inline"
        form={formListaConsulta}
        initialValues={{
          periodo:
            periodoListar == null
              ? [moment().startOf("year"), moment().date("year")]
              : periodoListar.length != 0
              ? periodoListar
              : [moment().startOf("year"), moment().date("year")],
        }}
      >
        <Row gutter={{ sm: 8, md: 16 }} style={{ width: "100%" }}>
          <Col span={{ sm: 12, md: 8 }} style={{ width: "30%" }}>
            <Form.Item label="Periodo" name="periodo" style={{ marginTop: "11px" }}>
              <RangePicker
                allowClear={false}
                onChange={onChangePeriodo}
                format={"MM/YYYY"}
                picker="month"
                style={{ width: "100% " }}
              />
            </Form.Item>
          </Col>
          <Col span={{ sm: 12, md: 16 }} style={{ width: "70%" }}>
            <Form.Item label="" name="filtroB">
              <FiltroBusqueda
                setFiltros={setFiltros}
                //filtro={filtros}
                reset={reset}
                filtroActual={filtros}
                tipoDocumentos={tipoDocumentos}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        rowKey={(consulta) => consulta.id}
        columns={columnasTablaConsulta}
        dataSource={consultas}
        pagination={{
          size: "small",
          defaultCurrent: paginActual ? paginActual : 1,
          current: paginActual,
          total: totalPaginacion,
          showSizeChanger: false,
          showTotal: (total) => `Total ${total} documentos`,
          pageSize: paginacion,
          onChange: onChangePagination,
        }}
      />
    </React.Fragment>
  );
};
export default TablaConsultasColaborador;
