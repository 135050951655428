import React from "react"
import { Container } from "components/Container";
import {TablaSeguimientoEntrevistas} from "./componentes";
import { UnorderedListOutlined } from "@ant-design/icons";
const SeguimientoEntrevistasPage=()=>{
    return(
       <Container titulo="Entrevistas" icono={<UnorderedListOutlined/>}>
          <TablaSeguimientoEntrevistas/>
       </Container>
    )

}
export default SeguimientoEntrevistasPage;