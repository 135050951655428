import React, { useState, useContext, useEffect } from "react";

import { Row, Col, Form, Typography, Input, Button, Alert, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { LoginService } from "services/LoginService";
import { useHistory } from "react-router-dom";
import { SecurityContext } from "../../context/SecurityContextProvider";

const parametros = window.location.search;
const buscadorParametros = new URLSearchParams(parametros);

export function LoginPage() {
  const [autenticacionFallida, setAutenticacionFallida] = useState(false);
  const [mensajeError, setMensajeError] = useState("");
  const [loading, setLoading] = useState(false);

  const { setSesionUsuario } = useContext(SecurityContext);

  let history = useHistory();

  useEffect(() => {
    const sesionExpirada = localStorage.getItem("sesionExpirada");
    if (sesionExpirada === "si") {
      message.info("Su sesión ha expirado.", 8);
      localStorage.removeItem("sesionExpirada");
    }
  }, []);

  async function handleOnFinish(formulario) {
    setAutenticacionFallida(false);
    setLoading(true);

    try {
      const respuesta = await LoginService.autenticar(formulario.usuario.replace(/\s+/g, ""), formulario.clave);

      if (respuesta.estado === "ok") {
        setSesionUsuario(respuesta);
        // localStorage.setItem("jwt-token", respuesta.token);
        // localStorage.setItem("jwt-info", JSON.stringify(respuesta.usuario));
        // localStorage.setItem(
        //   "empresa-seleccionada",
        //   JSON.stringify(respuesta.usuario.empresaFavorita)
        // );
        setAutenticacionFallida(false);

        const sesionUrl = buscadorParametros.get("sesionUrl");
        if (sesionUrl) {
          const sesionId = buscadorParametros.get("sesionId");
          const urlParametros = sesionId ? "?sesionId=" + sesionId : "";
          history.push(sesionUrl + urlParametros);
        } else {
          history.push("/dashboard");
        }
      } else if (respuesta.error === "auth-1") {
        setLoading(false);
        setAutenticacionFallida(true);
        setMensajeError("Usuario y/o clave son incorrectos");
      }
    } catch (error) {
      //console.log(error.response.data.mensaje);
      if (error.response && error.response.data.mensaje) {
        setAutenticacionFallida(true);
        setMensajeError(error.response.data.mensaje);
      }
      setLoading(false);
    }
  }

  // function guardarEmpresaSeleccionada(empresa) {
  //   console.log("estoy aqui", empresa);
  //   localStorage.setItem("empresaSeleccionada", JSON.stringify(empresa));
  // }
  function handleOlvideClave() {
    history.push("/cuenta/actualizar-clave");
  }

  return (
    <Row className="container-login">
      <Col span={16} className="container-login__imagen" />

      <Col span={8} className="container-login__formulario">
        <div className="login">
          <div className="logotipo">
            <img
              className="imagen-derecha"
              src="https://storage.googleapis.com/milegajodigital.com/admin/publico/logotipo.png"
              alt="Logotipo"
            />
          </div>

          <div className="texto">
            <Typography.Title level={3}>
              Iniciar Sesión{" "}
              {(window.location.hostname.includes("-cert") || window.location.hostname.includes("localhost")) &&
                "[TEST]"}
            </Typography.Title>
          </div>
          {autenticacionFallida && (
            <Alert
              style={{
                marginBottom: 24,
              }}
              message={mensajeError}
              type="error"
              showIcon
            />
          )}

          <Form name="frmLogin" className="login-form" onFinish={handleOnFinish}>
            <Form.Item name="usuario" rules={[{ required: true, message: "Por favor, ingresa tu usuario." }]}>
              <Input
                size="large"
                maxLength={70}
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Ingresa tu usuario"
              />
            </Form.Item>
            <Form.Item name="clave" rules={[{ required: true, message: "Por favor, ingresa tu clave." }]}>
              <Input.Password
                size="large"
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Ingresa tu clave"
              />
            </Form.Item>
            <Form.Item>
              <a
                style={{ float: "right" }}
                // href="#/"
                onClick={handleOlvideClave}
              >
                Olvide mi clave
              </a>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" size="large" loading={loading} style={{ width: "100%" }}>
                Iniciar Sesión
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
}
