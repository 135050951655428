import React, { useEffect, useContext, useState, useRef } from "react";
import { Table,  } from "antd";
import { OnboardingColaboradorService } from "services/OnboardingColaboradorService";
import { ContratoService } from "services/ContratoService";
import { useModalRechazarOnboarding, useModalVisorPdf } from "store/global";
import { SecurityContext } from "context/SecurityContextProvider";
import {
  SearchOutlined,
} from '@ant-design/icons';
import FormularioSeguimientoService from "services/FormularioSeguimientoService";
import { useAtom } from "jotai";
import { detalleIdAtom } from "../../ConsultaOnboardingStore";

function TablaListar() {

    const [envioOnboarding, setEnvioOnboarding]= useState([]);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal); 
    const cerrarModalVisorPdf = useModalVisorPdf((state) => state.cerrarModal); 
    const cerrarModalRechazo = useModalRechazarOnboarding((state) => state.cerrarModal); 
    const botonRechazo = useModalVisorPdf((state) => state.botonRechazo); 
    const mostrarModalRechazoOnboarding = useModalRechazarOnboarding((state) => state.mostrarModal); 
    const finProcesoRechazoOnboarding = useModalRechazarOnboarding((state) => state.finProceso); 
    const finProcesoAceptaOnboarding = useModalVisorPdf((state) => state.finProceso); 
    const rechazarOnboarding = useModalVisorPdf(state => state.rechazarOnboarding);
    const [detalleId,setDetalleId] =useAtom(detalleIdAtom);

    useEffect(() => {
     const CargarDatos = async() =>{
        const _envioOnboarding = await OnboardingColaboradorService.listar();
        setEnvioOnboarding(_envioOnboarding)
     }
     CargarDatos();
    }, [])
    
    const onClickBtnVerDocumento = async ({fila}) => {
      
      try {
       // setLoading(true);
        // let respuesta = null;
        // if (envio.categoriaDocumentoId === CategoriaDocumento.FORMULARIOS.id) {
        //   respuesta = await FormularioSeguimientoService.getUrlDocumento(
        //     getUsuarioAutenticado().empresaSeleccionada.id,
        //     envio.id
        //   );
        // } else {
        const respuesta = await FormularioSeguimientoService.getUrlDocumento(
          getUsuarioAutenticado().empresaSeleccionada.id,
          fila.id
        );
        setDetalleId(fila.id);
        mostrarModalVisorPdf(respuesta.urlDocumento, fila.nombreDocumento, false, false, "ONBOARDING", fila.id );
      } catch (error) {
        console.error(error);
      } finally {
       // setLoading(false);
      }
    };

    useEffect(() => {
      if(botonRechazo)
          mostrarModalRechazoOnboarding(detalleId);
      else if(finProcesoAceptaOnboarding){
        const actualizarData = async() =>{
          cerrarModalVisorPdf();
          const _envioOnboarding = await OnboardingColaboradorService.listar();
          setEnvioOnboarding(_envioOnboarding)
        }
        actualizarData();
      }

    }, [botonRechazo, , finProcesoAceptaOnboarding])

    useEffect(() => {
      if(finProcesoRechazoOnboarding){
        const actualizarData = async() =>{
          cerrarModalRechazo();
          rechazarOnboarding();
          cerrarModalVisorPdf();
          const _envioOnboarding = await OnboardingColaboradorService.listar();
          setEnvioOnboarding(_envioOnboarding)
        }
        actualizarData();
      }

    }, [finProcesoRechazoOnboarding])

    const columnas = [

      {
        title: "Código de Trabajador",
        dataIndex: ["colaborador","codigo"],
      }, 
      {
        title: "Documento de Identidad",
        dataIndex: ["colaborador","numeroDocumentoIdentidad"],
        //render: (text, fila) =>  fila.colaborador.codigo?.length>0?fila.colaborador.codigo:"-",
    
      }, 
        {
          title: "Colaborador",
          dataIndex: ["colaborador","nombreCompleto"],    
        },
        {
          title: "Documento",
          dataIndex: "nombreDocumento"   
        },
        {
          title: "Periodo",
          dataIndex: "periodo",
    
        },
        {
          title: "Acciones",
          align: "center",
          align: "center",
          render: (text, fila) => (
              <a onClick={() => onClickBtnVerDocumento(fila={fila})} className="custom-color-element">
                <SearchOutlined /> Ver Documento
              </a>
          )
        },
        
      ];


    return(
        <Table
        rowKey={(detalleEnvio) => detalleEnvio.id}
        dataSource={envioOnboarding}
        columns={columnas}
      />
    )
}
export default TablaListar;