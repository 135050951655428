import React,{useState} from "react";
import {Tabs,Card } from "antd";
import { Container } from "components/Container";
import { UnorderedListOutlined } from "@ant-design/icons";
import ReporteRotacionPersonal from "./componentes/ReporteRotacionPersonal";
import ReporteRotacionPesonalPorSede from "./componentes/ReporteRotacionPersonalPorSede";
import ReporteContratacionPersonal from "./componentes/ReporteContratacionPersonal";
import ReporteCesadosPorMotivoCese from "./componentes/ReporteCesadosPorMotivoCese";
const ReporteSolicitudPersonalPage=()=>{

    const [activeTabKey, setActiveTabKey] = useState('rotacionPersonal');
    const onTabChange = (key) => {
        setActiveTabKey(key);
      };
    const tabListNoTitle = [
        {
          key: 'rotacionPersonal',
          tab: 'Rotación Personal',
        },   
        {
          key: 'rotacionPersonalPorArea',
          tab: 'Rotación Personal por Área',
        },   
        {
          key: 'efectividadContratacion',
          tab: 'Efectividad Contratación',
        }, 
        
        {
          key: 'cesadosPorMotivoCese',
          tab: 'Cesados Por Motivo cese',
        },  
        
      ];

      const contentListNoTitle = {
        rotacionPersonal:  <ReporteRotacionPersonal/>,       
        rotacionPersonalPorArea: <ReporteRotacionPesonalPorSede/>,
        efectividadContratacion:<ReporteContratacionPersonal/>,
        cesadosPorMotivoCese:<ReporteCesadosPorMotivoCese/>
      };

    return(       
        <Card
          style={{
            width: '100%',
          }}
          tabList={tabListNoTitle}
          activeTabKey={activeTabKey}         
          onTabChange={(key) => {
            onTabChange(key);
          }}
        >
         {contentListNoTitle[activeTabKey]}
        </Card>
    )
}
export default ReporteSolicitudPersonalPage;