import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from "antd";
import { useAtom } from 'jotai';
import * as atoms from "../store/NotificarFormularioStore";
import { FormularioNotificarService } from 'services/FormularioNotificarService';
import { SecurityContext } from 'context/SecurityContextProvider';
import { TablaColaboradores } from "./tabla-colaboradores";

const ModalSeleccionarColaboradores = () => {

    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [visibleModal, setVisibleModal] = useAtom(atoms.visibleModalSeleccionarColaborador);
    const [, setColaboradoresSeleccionadosId] = useAtom(atoms.colaboradoresSeleccionadosId);
    // const [, setColaboradoresSeleccionados] = useAtom(atoms.colaboradoresSeleccionados);
    const [loading, setLoading] = useState(false);
    const [colaboradores, setColaboradores] = useState([]);


    useEffect(() => {
        if (visibleModal) {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    const _colaboradores = await FormularioNotificarService.listarColaboradoresPorEmpresaId(getUsuarioAutenticado().empresaSeleccionada.id);
                    setColaboradores(_colaboradores);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            }
            fetchData();
        }
    }, [visibleModal]);

    const onClickBtnCancelar = () => {
        setVisibleModal(false);
    }

    const onClickBtnLimpiar = () => {
        setColaboradoresSeleccionadosId([]);
    }

    const onClickBtnSeleccionar = () => {
        setVisibleModal(false);
    }

    return (
        <Modal
            title="Seleccionar Colaboradores"
            visible={visibleModal}
            onCancel={onClickBtnCancelar}
            footer={[
                <Button key="btnLimpiar" loading={loading} onClick={onClickBtnLimpiar}>
                    Limpiar
                </Button>,
                <Button key="btnSeleccionar" type="primary" loading={loading} onClick={onClickBtnSeleccionar}>
                    Seleccionar
                </Button>
            ]}
            width={700}
        >

            <TablaColaboradores colaboradores={colaboradores} loading={loading} />

        </Modal>
    );
};

export default ModalSeleccionarColaboradores;