import React from "react";
import { Button, Alert,Modal,Table } from "antd";
import { WarningOutlined } from "@ant-design/icons";

const AlertaTramaConObservaciones = ({ envio }) => {
  if (envio?.colaboradoresConObservacion.length == 0) {
    return null;
  }
  const onclickVerObservaciones=()=>{
    const columnas=[
      {
        title:"Documento Identidad",
        dataIndex:"numeroDocumentoIdentidad"
      },
      {
        title:"Observación",
        dataIndex:"observacion"
      }
         

    ];
   
    Modal.error({
      title:"Colaboradores Observados",
      content:(
        <Table
           rowKey={colaboradorObservado=>colaboradorObservado.numeroDocumentoIdentidad}
           columns={columnas}
           dataSource={envio.colaboradoresConObservacion}
           pagination={false}
        />
      ),  
       
    })
  }
  return (
    <Alert
      type="error"
      icon={<WarningOutlined style={{ fontSize: "20px" }} />}
      showIcon
      message={
        <React.Fragment>
          <span>Se ha encontrado observaciones con la trama cargada.</span>
          <span>
            <Button size="small" type="link" danger style={{ padding: "0px", paddingLeft: "2px" }} onClick={onclickVerObservaciones}>
              Clic aquí para ver detalle.
            </Button>
          </span>
        </React.Fragment>
      }
      style={{ marginBottom: "10px" }}
    />
  );
};

export default AlertaTramaConObservaciones;
