import React, { useContext, useEffect } from "react";
import { SecurityContext } from "../../context/SecurityContextProvider";
import {
  UserOutlined,
  FileDoneOutlined,
  LineChartOutlined,
  FileSearchOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Row, Col, Button, Space, Modal } from "antd";
import { useHistory } from "react-router-dom";
import UsuarioService from "../../services/UsuarioService";

const style = { padding: "8px 0" };
const style1 = { paddingTop: "150px" };

export function DashboardPage() {
  let history = useHistory();
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  useEffect(() => {
    async function cargarUsuario() {
      const usuario = await UsuarioService.buscarPorOrganizacionLogin(
        getUsuarioAutenticado().organizacionId,
        getUsuarioAutenticado().login
      );
      if (usuario.primerInicioSesion) {
        confirm();
      }
    }
    function confirm() {
      Modal.confirm({
        title: "Cambiar Clave",
        icon: <ExclamationCircleOutlined />,
        content: "Debes cambiar la clave de tu cuenta, al iniciar sesión.",
        okText: "Aceptar",
        cancelText: "Cancelar",
        onOk() {
          history.push("/cuenta/cambiar-clave");
        },
      });
    }

    cargarUsuario();
  }, []);
  function handleOnClickCrearColaborador() {
    //history.push("/colaborador/crear");
    history.push("/colaborador");
  }

  function handleOnClickCrearNotificacion() {
    history.push("/documento-laboral/envio");
  }

  function handleOnClickSeguimiento() {
    //history.push("/documento-laboral");
    history.push("/notificacion/seguimiento/");
  }

  function handleOnClickConsultaLegajo() {
    history.push("/legajos");
  }

  return (
    <div>
      {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col className="gutter-row" span={12}>
        <div style={style}></div>
      </Col>
      <Col className="gutter-row" span={12}>
        <div style={style}></div>
      </Col>
    </Row> */}

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="center">
        <Col className="gutter-row" span={10} align="middle">
          <div style={style}>
            <img
              src={require("../../assets/img/dashboard_img.svg")}
              width="500px"
              height="550px"
            />
          </div>
        </Col>
        <Col className="gutter-row" span={14} align="middle">
          <div style={style1}>
            <h1 className="custom-color-element">
              Bienvenido {getUsuarioAutenticado().nombres}.
            </h1>
            <h1 className="custom-color-element">
              <strong>Elige una opción en el menú principal.</strong>
            </h1>
            <br></br>
            {/* <Space>
              <Button
                type="primary"
                icon={<UserOutlined />}
                onClick={handleOnClickCrearColaborador}
              >
                Crear Colaborador
                </Button>
              <Button
                type="primary"
                icon={<FileDoneOutlined />}
                onClick={handleOnClickCrearNotificacion}
              >
                Crear notificación
                </Button>
              <Button
                type="primary"
                icon={<LineChartOutlined />}
                onClick={handleOnClickSeguimiento}
              >
                Seguimiento
                </Button>
              <Button
                type="primary"
                icon={<FileSearchOutlined />}
                onClick={handleOnClickConsultaLegajo}
              >
                Consulta legajos
                </Button>
            </Space> */}
          </div>
          {/* <div style={style}>
        <Space>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                >
                  Crear Colaborador
                </Button>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                >
                  Crear notificación
                </Button>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                >
                  Seguimiento
                </Button>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                >
                  Consulta legajos
                </Button>
                </Space>
        </div> */}
        </Col>
      </Row>

      {/* <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col className="gutter-row" span={12}>
        <div style={style}></div>
      </Col>
      <Col className="gutter-row" span={12}>
        <div style={style}></div>
      </Col>
    </Row> */}
      {/* <Row >
        <Col span={2}></Col>
        <Col span={12}>
          <img src={require('../../assets/img/dashboard_img.svg')} width="500px" height="550px"/>
        </Col>
        <Col span={8} align="middle">
          <Row>
            <Col span={24}>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <span><h1>Bienvenido {getUsuarioAutenticado().nombres}.</h1></span> 
              <br></br> <span><h1>¿Qué documento quieres generar hoy? </h1></span>
              <br></br>
              <Space>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                >
                  Crear Colaborador
                </Button>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                >
                  Crear notificación
                </Button>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                >
                  Seguimiento
                </Button>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                >
                  Consulta legajos
                </Button>
                </Space>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
            </Col>
          </Row>

        </Col>
        <Col span={2}></Col>
      </Row> */}
    </div>
  );
}
