import React from "react";
import {Table} from "antd";
import { useListarPerfilSolicitudPersonal } from "../../hooks";
import MenuAcciones from "./MenuAcciones";

const TablaListarPerfilSolicitudPersonal=()=>{

     const{perfiles}=useListarPerfilSolicitudPersonal();

    const columnas=[
        {
            title:"Nombre",
            dataIndex:"nombre",
            key:"nombre",
            align:"center",
        },
        // {
        //     title:"Puesto",
        //     dataIndex:["cargo","nombre"],
        //     key:"puesto",
        //     align:"center",
        // },
        {
            title:"Acciones",
            key:"acciones",
            align:"center",
            render:(fila)=><MenuAcciones perfil={fila}/>

        }

    ]
         return(
            <Table
              rowKey={(perfil)=>perfil.id}
              dataSource={perfiles}
              columns={columnas}
            />
         )

}
export default TablaListarPerfilSolicitudPersonal;