import React from "react";
import {Modal, Table,Button} from "antd";
import { useEffect } from "react";
import {verDocumentoAtom} from "../ListarPlantillaEnvioDocumentosStore";
import {plantillaSeleccionadaAtom} from "../ListarPlantillaEnvioDocumentosStore";
import { useAtom } from "jotai";
import {plantillasDocumentosListadasAtom} from "../ListarPlantillaEnvioDocumentosStore";

const ModalVerDocumento=()=>{
    const [verDocumento,setVerDocumento]=useAtom(verDocumentoAtom);
    const [plantillaSeleccionada,setPlantillaSeleccionada]=useAtom(plantillaSeleccionadaAtom);
    const [plantillasListadas,setPlantillasListadas]=useAtom(plantillasDocumentosListadasAtom);

    useEffect(()=>{
       
    },[plantillaSeleccionada]);

    const columnas=[
        {
            title:"Documentos",
            dataIndex:"nombre",
            key:"nombre",
        },
    ];
    const cerrarModal=()=>{
        setVerDocumento(false);
    }
  return(
      <Modal
        visible={verDocumento}
        onCancel={cerrarModal}
        onOk={cerrarModal}
        footer={[
            <Button type="primary" onClick={cerrarModal}>
              Aceptar
            </Button>
        ]}
      >
           <Table
            rowKey={documento=>documento.id}
            columns={columnas}
            dataSource={plantillaSeleccionada}
            scroll={{ y: 240 }}
            pagination={false}
            
          /> 

      </Modal>
      
  );    

}
export default ModalVerDocumento;