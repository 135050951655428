import React from "react";
// import { Container } from "components/Container";
import { useHistory } from "react-router";
import { Form, Input, Row, Col, Button, TimePicker, Select, Tabs } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { TipoTurno } from "enums/asistencia/TipoTurno";
import { Provider as JotaiProvider, useAtom } from "jotai";
import { FormCrearTurno } from "./componentes/form-crear-turno";
import { FormCrearReglas } from "./componentes/form-crear-reglas";
const CrearTurnoPage=()=>{

  return(

    <JotaiProvider>
      <ContainerTabs>
        <Tabs
          // tabBarExtraContent={operaciones}
        > 
          <Tabs.TabPane
            tab="Registrar turno"
            key="registrar-turno"
          >
            <FormCrearTurno/>

          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Registrar reglas"
            key="registrar-reglas"
          >
            <FormCrearReglas/>
          </Tabs.TabPane>
        </Tabs>
        
      </ContainerTabs>
    </JotaiProvider>
    // <Container titulo="Crear Turno" onClickRetornar={onClickRetonarTurnos}>
    //   <Form
    //         layout="vertical"           
    //         //size="middle"
    //         //onFinish={onClickBtnCrearCarpeta}
    //   >
    //     <Row gutter={16}>
    //       <Col span={12}>
    //         <Form.Item
    //               label="Nombre"
    //               name="nombre"
    //               rules={[
    //                   {
    //                       required: true,
    //                       message: "El campo nombre es obligatorio.",
    //                   },
    //               ]}   
    //           >
    //               <Input/>
    //           </Form.Item>
    //       </Col>
    //       <Col span={12}>
    //         <Form.Item
    //               label="Descripción"
    //               name="descripcion"
    //               rules={[
    //                   {
    //                       required: true,
    //                       message: "El campo descripción es obligatorio.",
    //                   },
    //               ]}   
    //           >
    //               <Input/>
    //           </Form.Item>
    //       </Col>
    //      </Row> 
    //      <Row gutter={16}>
		// 					<Col span={12}>
		// 						<Form.Item label="Tipo" name="tipo">
		// 							<Select placeholder="Seleccione el tipo" allowClear>
		// 								<Select.Option value={TipoTurno.laborable}>
		// 									{TipoTurno.laborable}
		// 								</Select.Option>
		// 								<Select.Option value={TipoTurno.dia_descanso}>
		// 									{TipoTurno.dia_descanso}
		// 								</Select.Option>
		// 								<Select.Option value={TipoTurno.horas_extras}>
		// 									{TipoTurno.horas_extras}
		// 								</Select.Option>
		// 							</Select>
		// 						</Form.Item>
		// 					</Col>
		// 					<Col span={12}>
    //             <Form.Item
    //                   label="Color"
    //                   name="color"
    //                   rules={[
    //                       {
    //                           required: true,
    //                           message: "El campo color es obligatorio.",
    //                       },
    //                   ]}   
    //               >
    //                   <Input/>
    //               </Form.Item>
    //           </Col>
		// 				</Row>
    //         <Row gutter={16}>
		// 					<Col span={6}>
    //             <Form.Item
    //                   label="Hora Inicio"
    //                   name="hotaInicio"
    //                   rules={[
    //                       {
    //                           required: true,
    //                           message: "El campo hora inicio es obligatorio.",
    //                       },
    //                   ]}   
    //               >
    //                   <TimePicker format={format} />
    //               </Form.Item>
		// 					</Col>
    //           <Col span={6}>
    //             <Form.Item
    //                   label="Hora Fin"
    //                   name="horaFin"
    //                   rules={[
    //                       {
    //                           required: true,
    //                           message: "El campo hora fin es obligatorio.",
    //                       },
    //                   ]}   
    //               >
    //                   <TimePicker format={format} />
    //               </Form.Item>
		// 					</Col>
		// 					<Col span={12}>
    //             <Form.Item
    //                   label="Días"
    //                   name="dias"
    //                   rules={[
    //                       {
    //                           required: true,
    //                           message: "El campo días es obligatorio.",
    //                       },
    //                   ]}   
    //               >
    //                   <Select
    //                     placeholder="Seleccione el día"
    //                     mode="tags"
    //                     style={{
    //                       width: '100%',
    //                     }}
    //                     onChange={handleChange}
    //                     tokenSeparators={[',']}
    //                     options={options}
    //                   />
    //               </Form.Item>
    //           </Col>
		// 				</Row>
    //         <Form.Item style={{ textAlign: "center" }} >
    //             <Button
    //                 type="primary"
    //                 icon={<CheckOutlined />}
    //                 htmlType="submit"
    //             >
    //                 Crear Turno
    //             </Button>
    //         </Form.Item>
    //   </Form>
    // </Container>
  )

}

const ContainerTabs = ({ children }) => {
  return (
    <div style={{ padding: "10px 20px", background: "white" }}>{children}</div>
  );
};

export default CrearTurnoPage;