import React,{useEffect} from "react";
import {Form,Select,Input,message,Typography,Button,Alert} from "antd";
import {useCargarPostulantes} from "../hooks";
import TablaPostulantes from "./TablaPostulantes";
import { useAtom } from "jotai";
import { postulanteSeleccionadoAtom,
        postulantesAgregadosAtom,
        convocatoriaSeleccionadaIdAtom,
        postulantesConvocatoriaAtom,
        filtroPostulanteAtom,
        estadoMensajeErrorAtom} from "../AgregarPostulanteStore";
import {ConvocatoriaService} from "services/reclutamiento-y-seleccion/ConvocatoriaService"
import { TipoConvocatoria } from "enums/reclutamiento-y-seleccion/TipoConvocatoria";
import { useParams } from "react-router-dom";

const FormularioAgregarPostulante=()=>{
  const {convocatoriaId} = useParams();
  const [,setConvocatoriaSeleccionadaId] = useAtom(convocatoriaSeleccionadaIdAtom);
  const[postulanteSeleccionado,setPostulanteSeleccionado]= useAtom(postulanteSeleccionadoAtom);
  const [formAgregarPostulante] = Form.useForm();  

  useEffect(()=>{
     const cargarDatos = async() =>{
          const convocatoriaBuscada= await ConvocatoriaService.buscarPorId(convocatoriaId);
          formAgregarPostulante.setFieldsValue({
              tipo:convocatoriaBuscada.tipo && convocatoriaBuscada.tipo,
              cargo:convocatoriaBuscada.cargo,
          })
        setConvocatoriaSeleccionadaId(convocatoriaId);
       
     }
      cargarDatos();
  },[]);

  useEffect(()=>{
         if(!postulanteSeleccionado){
          formAgregarPostulante.setFieldsValue({
              postulante:"",
          })
         }
  },[postulanteSeleccionado])
  
    return(
        <Form layout="vertical" style={{ padding: "0px 2%" }} form={formAgregarPostulante}>
             <SelectTipo/>
             <SelectCargo/> 
             <SelectPostulante/>
             <TablaAgregarPostulantes/>  
        </Form>
       
    )

};

const SelectTipo=()=>{
    return(
    <Form.Item
      label="Tipo"
      name="tipo"    
    >
     {/* <Select placeholder="Seleccione el Tipo">
        <Select.Option value={TipoConvocatoria.INTERNO}>{TipoConvocatoria.INTERNO} </Select.Option>
        <Select.Option value={TipoConvocatoria.EXTERNO}>{TipoConvocatoria.EXTERNO} </Select.Option>
     </Select> */}
        <Input readOnly={true}/>
    </Form.Item>
    )
    }   
    const SelectCargo=()=>{
      
      return(
        <Form.Item
          label="Cargo"
          name="cargo"          
        >
        
         <Input readOnly={true}/>
        </Form.Item>
      )
    }

    const SelectPostulante=()=>{
        const {postulantes,cargarPostulantes,setPostulantes} = useCargarPostulantes();
        const[postulanteSeleccionado,setPostulanteSeleccionado]= useAtom(postulanteSeleccionadoAtom);
        const [postulantesConvocatoria,setPostulantesConvocatoria]=useAtom(postulantesConvocatoriaAtom);
        const [convocatoriaSeleccionadaId,setConvocatoriaSeleccionadaId]=useAtom(convocatoriaSeleccionadaIdAtom);
        const[,setFiltroPostulante] = useAtom(filtroPostulanteAtom);
        const [estadoMensajeError,setEstadoMensajeError] = useAtom(estadoMensajeErrorAtom);

        const onChangePostulante=(value,Option)=>{           
           const postulante={
             id:value,
             nombreCompleto:Option.labelnombre,
           }
           //console.log("Postulante Seleccionado",postulante);           
           setPostulanteSeleccionado(postulante);    
           
        }
        const onSearchPostulante = async(value)=>{ 
          setEstadoMensajeError(false);
          if(value.trim()!=="")  {   
            cargarPostulantes(value.trim());        
            //setFiltroPostulante(value.trim());
            //console.log("filtropostulante:",value.trim());
            
          }
          else{            
            setPostulantes([]);            
          }
          
          
        }
        const onclickAgregar = async()=>{
          if(postulanteSeleccionado){
            const postulanteExistente = postulantesConvocatoria.find(postulante=>postulante.id===postulanteSeleccionado.id);
            if(postulanteExistente){
              //message.error("Ya existe un postulante con ese nombre");
              setEstadoMensajeError(true);
            }
            else{
             const postulante={
                convocatoriaId:convocatoriaSeleccionadaId,
                postulanteId:postulanteSeleccionado.id,
              }
               await ConvocatoriaService.insertarPostulante(postulante);
               setPostulantesConvocatoria([...postulantesConvocatoria,postulanteSeleccionado]);
               
            }
                      
            setPostulanteSeleccionado();
            setPostulantes([]);
          }     
           
       }
      
          return(
            <React.Fragment>           
            <Form.Item label="Postulante" name="postulante">         
               <Select
               placeholder="Buscar Postulante"
                showSearch
                onChange={onChangePostulante}                
                optionFilterProp="labelnombre"
                onSearch={onSearchPostulante}  
                              
               >
                   {postulantes.length>0 && postulantes.map((postulante)=>{
                    return(
                      <Select.Option key={postulante.id} value={postulante.id} labelnombre={postulante.nombreCompleto}>
                         {postulante.nombreCompleto}
                      </Select.Option>
                    )
                        
                   })}
               </Select>
            </Form.Item>
             <div style={{paddingBottom:10}}>{estadoMensajeError && <Alert message="Ya existe un postulante con ese nombre" type="error" showIcon/>}</div>
            <div style={{textAlign:"center"}}><Button type="primary"  onClick={onclickAgregar}>Agregar Postulante</Button></div>
            </React.Fragment>
          )
      }  
      const TablaAgregarPostulantes=({formulario})=>{  
      
      return(
            <Form.Item name="tabla">
            
              <TablaPostulantes/>
          </Form.Item>
        )
        
     }  
     
    
       
export default FormularioAgregarPostulante;