import { ApiService } from "./ApiService";
import { ApiUrl } from "./ApiUrl";
import qs from "qs";

const URL_API = ApiUrl.VACACIONES;

const VacacionesService = {
  async listar() {
    const respuesta = await ApiService(URL_API).get("/control-vacacional");
    return respuesta.data;
  },
  async listarConPaginacion(params) {
    const respuesta = await ApiService(URL_API).get("/control-vacacional/listar-con-paginacion", {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    return respuesta.data;
  },
  async calcular(data) {
    const respuesta = await ApiService(URL_API).post("/calcular", data);
    return respuesta.data;
  },
  async descargarReporteExcel(params) {
    const respuesta = await ApiService(URL_API).get("/control-vacacional/descargarReporteExcel", {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },

      responseType: "blob",

    });
    return respuesta;
  },

  async descargarReporteExcelSolicitudes(params) {
    const respuesta = await ApiService(URL_API).get("/control-vacacional/descargarReporteExcelSolicitudes", {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },

      responseType: "blob",

    });
    return respuesta;
  },

  async simularDescontarDias(body) {
    const respuesta = await ApiService(URL_API).post("/control-vacacional/simular-descontar-dias", body);
    return respuesta.data;
  },

};

const SolicitudAusenciaService = {
  async listarPorGestionHumana(params) {
    const respuesta = await ApiService(URL_API).get("/solicitud/gestionhumana", {
      params: params,
    });
    return respuesta.data;
  },

  async listarPorGestionHumanaConPaginacion(params) {
    const respuesta = await ApiService(URL_API).get("/solicitud/gestionhumana/listar-con-paginacion", {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    return respuesta.data;
  },

  async aprobarPorGestionHumana(body) {
    const respuesta = await ApiService(URL_API).post("/solicitud/gestionhumana/aprobar", body);
    return respuesta.data;
  },

  async rechazarPorGestionHumana(body) {
    const respuesta = await ApiService(URL_API).post("/solicitud/gestionhumana/rechazar", body);
    return respuesta.data;
  },

  async getUrlDocumento(empresaId, solicitudId, plataforma) {

    const respuesta = await ApiService(URL_API).get(`/solicitud/gestionhumana/solicitudId/${solicitudId}/empresa/${empresaId}/plataforma/${plataforma}/url`);

    return respuesta.data;
  },
  async agregarSeguimiento(body) {

    const respuesta = await ApiService(URL_API).post("/solicitud/gestionhumana/seguimiento", body);

    return respuesta.data;

  },
  async getUrlSeguimiento(empresaId, solicitudId, plataforma, seguimientoId) {

    const respuesta = await ApiService(URL_API).get(`/solicitud/gestionhumana/solicitudId/${solicitudId}/empresa/${empresaId}/plataforma/${plataforma}/seguimientoId/${seguimientoId}/url`);

    return respuesta.data;
  },

  async buscarPorId(solicitudAusenciaId) {
    const respuesta = await ApiService(URL_API).get("/solicitud/gestionhumana/" + solicitudAusenciaId);
    return respuesta.data;
  },
  async agregarAltaMedica(body) {

    const respuesta = await ApiService(URL_API).post("solicitud/gestionhumana/altaMedica", body);
    return respuesta.data;

  },
  async getUrlAltaMedica(solicitudId, plataforma) {

    const respuesta = await ApiService(URL_API).get(`/solicitud/gestionhumana/solicitudId/${solicitudId}/plataforma/${plataforma}/url`);

    return respuesta.data;
  },
  async getUrlsAdjuntos(solicitudId) {
    const respuesta = await ApiService(URL_API).get(`/solicitud/gestionhumana/solicitudId/${solicitudId}/url`);

    return respuesta.data;
  },

  async descargarReporteExcelSolicitudes(params) {
    const respuesta = await ApiService(URL_API).get("/solicitud/gestionhumana/descargarReporteExcelSolicitudesAusencia", {
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },

      responseType: "blob",

    });
    return respuesta;
  },

  async notificarDocumento(body) {
    const respuesta = await ApiService(URL_API).post("/solicitud/gestionhumana/notificar-documento-antes-aprobar", body);
    return respuesta.data;
  },

  async solicitarAusenciasFromAdmin(body) {
    const respuesta = await ApiService(URL_API).post("/solicitud/solicitarAusenciasFromAdmin", body);
    return respuesta.data;
  },

  async cancelarSolicitudFromAdmin(body) {
    const respuesta = await ApiService(URL_API).post("/solicitud/solicitudPersonal/admin/cancelar", body);
    return respuesta.data;
  },
};

const CargarHistoricoVacacionesService = {
  async cargar(body) {
    const respuesta = await ApiService(URL_API).post("/historico", body);
    return respuesta.data;
  },
};

const TiposAusenciaService = {
  async listar() {
    const respuesta = await ApiService(URL_API).get("/tipos");
    return respuesta.data;
  },

};

const ReglasVacacionesService = {
  async listar() {
    const respuesta = await ApiService(URL_API).get("/reglas");
    return respuesta.data;
  },
  async listarFiltros(params) {
    const respuesta = await ApiService(URL_API).get("/reglas/filtro", {
      params: params
    });
    return respuesta.data;
  },
  async actualizarFiltro(data) {
    const respuesta = await ApiService(URL_API).put("/reglas/filtro", data);
    return respuesta.data;
  },
};

const AusenciaService = {
  async importar(data) {
    const respuesta = await ApiService(URL_API).post("/ausencia/importar", data);
    return respuesta.data;
  },
}

export {
  AusenciaService,
  ReglasVacacionesService,
  VacacionesService,
  SolicitudAusenciaService,
  CargarHistoricoVacacionesService,
  TiposAusenciaService
};
