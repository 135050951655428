const VisorPdfReducer = (state, action) => {
    switch (action.type) {
        case 'INICIAR':
            return {
                ...state,
                loading: true
            }

        case 'FINALIZAR':
            return {
                ...state,
                loading: false
            }

        case 'MOSTRAR_VISOR_PDF':
            return {
                ...state,
                visible: true
            }

        case 'CERRAR_VISOR_PDF':
            return {
                ...state,
                visible: false
            }

        case 'SET_URL_PDF':
            return {
                ...state,
                urlPdf: action.payload
            }

        case 'SET_BLOB_PDF':
            return {
                ...state,
                blobPdf: action.payload
            }

        case 'SET_VISIBLE_BTN_IMPRIMIR':
            return {
                ...state,
                visibleBtnImprimir: action.payload
            }

        case 'SET_VISIBLE_BTN_DESCARGAR':
            return {
                ...state,
                visibleBtnDescargar: action.payload
            }

        case 'SET_INFORMACION':
            return {
                ...state,
                informacion: action.payload
            }

        default:
            return state;
    }
}

export default VisorPdfReducer;