import { useAtom } from "jotai";
import { visibleDrawerCargarHistoricoVacacionesAtom } from "../ControlVacacionalStorage";

const useDrawerCargarHistoricoVacaciones = () => {
  const [visible, setVisible] = useAtom(visibleDrawerCargarHistoricoVacacionesAtom);

  const mostrar = () => {
    setVisible(true);
  };

  const ocultar = () => {
    setVisible(false);
  };

  return {
    visible,
    mostrar,
    ocultar,
  };
};

export default useDrawerCargarHistoricoVacaciones;
