import React, { useEffect, useState, useContext } from "react";
import { Badge, Button, Table, Tag,Modal } from "antd";
import { EntrevistaService } from "services/reclutamiento-y-seleccion/EntrevistaService";
import { useAtom } from "jotai";
import { entrevistaAtom } from "./ConvocatoriasStore";
import ModalVerEntrevista from "./ModalVerEntrevista";
import { EstadoEntrevista } from "enums/reclutamiento-y-seleccion/EstadoEntrevista";
import { ExclamationCircleOutlined } from '@ant-design/icons';

const ListarEntrevista = ({ postulanteId,convocatoriaId }) => {
  const [entrevistas, setEntrevistas] = useAtom(entrevistaAtom);
  const [entrevistaSeleccionada,setEntrevistaSeleccionada]= useState(undefined);
  const [modalVerEntrevista,setModalVerEntrevista]=useState(false);

  const [tableParams] = useState({
    pagination: {
      pageSize: 5,
    },
  });

  useEffect(() => {
    const cargarDatos = async () => {        
      //console.log(postulanteId)   
      const _entrevistas = await EntrevistaService.listarPorPostulante(postulanteId,convocatoriaId);
      setEntrevistas(_entrevistas);
      //console.log("Entrevistas: ",_entrevistas);
    };
    cargarDatos();
  }, []);

  const showConfirmNoAsitio=(fila)=>{
      Modal.confirm({
        
          title: 'Entrevista',
          icon: <ExclamationCircleOutlined />,
          content: "¿Postulante no asistió a la entrevista?",
          onOk() {
               handleOnOkNoAsistio(fila)            
          },         
        
      })
  }
  const handleOnOkNoAsistio=async(fila)=>{    
    console.log("entrevistaaaa",fila.id);
    const entrevista={
        id:fila.id,
        estado:EstadoEntrevista.NO_ASISTIO,
    }
    await EntrevistaService.actualizarEstado(entrevista);
  }

  const columns = [
    {
      title: "Entrevistador",
      dataIndex: ["entrevistador", "nombreCompleto"],
      key: "entrevistador",
      align: "center",
      width: "15%",
    },

    {
      title: "Fecha Programada",
      dataIndex: "fechaEntrevista",
      key: "fechaEntrevista",
      align: "center",
      width: "8%",
      // render: (item) => {
      //   const nuevafecha = item.replace("p. m.", "pm").replace("a. m.", "am");
      //   return nuevafecha;
      // },
    },

    {
      title: "Hora Programada",
      dataIndex: "horaEntrevista",
      key: "horaEntrevista",
      align: "center",
      width: "15%",
      // render: (item) => {
      //   const nuevafecha = item.replace("p. m.", "pm").replace("a. m.", "am");
      //   return nuevafecha;
      // },
    },
    // {
    //   title: "Duración",
    //   dataIndex: "duracion",
    //   align: "center",
    //   width: "10%",
    //   key: "duracion",
    // },
    {
      title: "Estado",
      dataIndex: "estado",
      align: "center",
      key: "estado",
      width: "8%",
      // render:(item)=><div><Badge color={item==="APROBADO"?"green":item==="DESCARTADO"?"red":"orange"} />&nbsp;&nbsp;{item}</div>
      render: (item) => <EtiquetaEstado estado={item} />,
    },
    {
      title:"Resultado",
      dataIndex:["resultado","descripcion"],
      align:"center",
      width:"8%",
    },
    {
      title:"Acciones",
      //dataIndex:,
      align:"center",
      width:"10%",
      render:(fila)=>(fila.estado===EstadoEntrevista.REALIZADO ?
                      <Button 
                        type="link" 
                        onClick={()=>{setEntrevistaSeleccionada(fila);
                                      setModalVerEntrevista(true);}              
                              }>
                          Ver Entrevista
                      </Button>
                      : fila.estado===EstadoEntrevista.PENDIENTE ?(
                        <Button type="link"
                        onClick={()=>showConfirmNoAsitio(fila)}
                        > 
                            No asistió
                        </Button>
                      )
                      :(<></>)
                   )
    }
  ];

  return (
    <React.Fragment>
               <Table
              columns={columns}
              bordered
              rowKey={(row) => row.id}
              dataSource={entrevistas}
              // pagination={tableParams.pagination}
              pagination={false}
              scroll={{
                x: 790,
                y: 332,
              }}
            />
              <ModalVerEntrevista  
                 estado={modalVerEntrevista}
                 entrevistaSeleccionada={entrevistaSeleccionada}
                 setEstado={setModalVerEntrevista}
                 />
    </React.Fragment>
    
  );
   
};
const EtiquetaEstado = ({ estado }) => {
  switch (estado) {
    case "PENDIENTE":
      return <Tag color="gold">{estado}</Tag>;

    case "REALIZADO":
      return <Tag color="green">{estado}</Tag>;

    case "DESCARTADO":
      return <Tag color="red">{estado}</Tag>;

    case "NO_ASISTIO":
      return <Tag >NO ASISTIO</Tag>;

    default:
      return <Tag>{estado}</Tag>;
  }
};
export default ListarEntrevista;
