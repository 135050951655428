import React from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

const BotonesExtra = () =>{
  let history =useHistory();

    const onClickCrearConvocatoria=()=>{
     history.push(`/reclutamiento-y-seleccion/convocatorias/crear/${undefined}/${undefined}`);
    }
    return(
        <Button type="primary" onClick={onClickCrearConvocatoria}>Crear convocatoria</Button>
    )
}
export default BotonesExtra;