import { useEffect } from "react";
import { useAtom } from "jotai";
import { convocatoriasAtom, loadingAtom } from "../ConvocatoriaListarStore";
import { ConvocatoriaService } from "services/reclutamiento-y-seleccion/ConvocatoriaService";
const useCargarConvocatorias=()=>{

    const [convocatorias,setConvocatorias] = useAtom(convocatoriasAtom);
    const [,setLoading] = useAtom(loadingAtom)

 useEffect(()=>{
    const cargarDatos = async ()=>{
        setLoading(true)
        const respuesta= await ConvocatoriaService.listar();
        setConvocatorias(respuesta);
        setLoading(false)
    }
    cargarDatos();
 },[]);
 return{convocatorias,setConvocatorias}
}
export default useCargarConvocatorias;