import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb } from "antd";
import { HomeOutlined, FolderOpenOutlined } from "@ant-design/icons";
import * as atoms from "../../ConsultarLegajoColaboradorStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import LegajoService from "../../../../../services/LegajoService";

const BreadCrumbDocumentos = () => {
  const [, setDocumentos] = useAtom(atoms.documentos);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [colaboradorSeleccionado] = useAtom(atoms.colaboradorSeleccionado);
  const [breadcrumbItems, setBreadcrumbItems] = useAtom(atoms.breadcrumbItems);
  const [, setMensajeLegajo] = useAtom(atoms.mensajeLegajoAtom);

  const onClickInicio = async () => {
    const _documentos = await LegajoService.listarDocumentosColaborador(
      colaboradorSeleccionado.numeroDocumentoIdentidad,
      getUsuarioAutenticado().empresaSeleccionada.id,
      getUsuarioAutenticado().login
    );
    setDocumentos([]);
    setDocumentos(_documentos);
    setBreadcrumbItems([]);

  };

  const onClickItem = async (item) => {
    const menuItems = [...breadcrumbItems];
    const indice = menuItems.findIndex((i) => i.carpetaId === item.carpetaId);
    menuItems.splice(indice + 1);
    setBreadcrumbItems(menuItems);
    var _documentos = await LegajoService.listarDocumentosCarpeta(
      colaboradorSeleccionado.numeroDocumentoIdentidad,
      getUsuarioAutenticado().empresaSeleccionada.id,
      item.carpetaId,
      getUsuarioAutenticado().login
    );
    const mensaje = _documentos.find(d=> d.tipo==="MENSAJE");
      if (mensaje!==undefined){
        _documentos=_documentos.filter(d=>d.tipo !== "MENSAJE");
        setMensajeLegajo(mensaje.mensaje);
      } else{
        setMensajeLegajo(undefined);
      }
    // setDocumentos([]);
    setDocumentos(_documentos);
  };
  return (
    <Breadcrumb>
      <Breadcrumb.Item>
        <a onClick={onClickInicio}>
          <HomeOutlined style={{ fontSize: "20px" }} />
          <span> Inicio</span>
        </a>
      </Breadcrumb.Item>
      {breadcrumbItems &&
        breadcrumbItems.map((item) => (
          <Breadcrumb.Item key={item.carpetaId}>
            <a onClick={() => onClickItem(item)}>
              <FolderOpenOutlined style={{ fontSize: "20px" }} />
              <span> {item.nombre}</span>
            </a>
          </Breadcrumb.Item>
        ))}
    </Breadcrumb>
  );
};
export default BreadCrumbDocumentos;
