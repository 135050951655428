import React,{useContext} from "react";
import { Table, Tag} from "antd";
import { CheckCircleTwoTone } from "@ant-design/icons";
import moment from "moment";
import { useListarSolicitudesAusencia, usePaginacion } from "../../hooks";
import { EstadoSolicitudAusencia } from "enums/EstadoSolicitudAusencia";
import MenuAcciones from "./MenuAcciones";
import {ModalSeguimiento} from "../ModalSeguimiento";
import {ModalAdjuntarAltaMedica} from "../ModalAdjuntarAltaMedica";
import {ModalCancelarSolicitud} from "../ModalCancelarSolicitud";
import { SecurityContext } from "context/SecurityContextProvider";
const ListaConsultaSolicitudes = () => {
  //const { solicitudesAusencia } = useListarSolicitudesAusencia("TODOS");
  const { totalRegistros, paginaActual, tamanioPaginado, onCambiarPaginacion, solicitudesAusencia } = usePaginacion();
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const isMaccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const isMaccaferriConstruction = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20521749378" ? true : false;
  const isAceros = getUsuarioAutenticado().empresaSeleccionada?.ruc==="20538379302"	? true	: false;
  const columnas = [
    {
      title: "Colaborador",
      dataIndex: ["colaborador", "nombreCompleto"],
      width: "20%",
      align: "center",
    },
    {
      title: "Tipo Ausencia",
      dataIndex: ["tipo", "descripcion"],
      width: "10%",
      align: "center",
    },
    {
      title: "Otros cargos",
      width: "20%",
      align: "center",
      render: (text, fila) => {
        return(
          <React.Fragment>
            {fila.responsableOtrosCargos?.nombreCompleto}
            <br/>
            {fila.responsableOtrosCargos?.fechaAprobacion!==undefined && fila.responsableOtrosCargos?.fechaAprobacion!==null ? "Fecha Aprobación: "+ moment(fila.responsableOtrosCargos?.fechaAprobacion).format("DD/MM/YYYY"):
             fila.responsableOtrosCargos?.fechaRechazo!==undefined && fila.responsableOtrosCargos?.fechaRechazo!==null && "Fecha Rechazo: "+ moment(fila.responsableOtrosCargos?.fechaRechazo).format("DD/MM/YYYY")
            }
            <br />
            {
              fila.responsableOtrosCargos?.estadoAprobacion === EstadoSolicitudAusencia.PENDIENTE && fila.estado=== EstadoSolicitudAusencia.PENDIENTE ?(
                <Tag color="warning">{fila.responsableOtrosCargos.estadoAprobacion}</Tag>
              ): fila.responsableOtrosCargos?.estadoAprobacion === EstadoSolicitudAusencia.PENDIENTE && fila.estado=== "CANCELADO" ?(  
                <Tag color="purple">CANCELADO POR COLABORADOR</Tag>
              ): fila.responsableOtrosCargos?.estadoAprobacion === EstadoSolicitudAusencia.APROBADO ?(
                <Tag color="success">{fila.responsableOtrosCargos.estadoAprobacion}</Tag>
              ): fila?.admin && fila?.estado==="CANCELADO" ?(
                <Tag color="purple">{fila.estado}</Tag>                
              ):(
                <Tag color="error">{fila.responsableOtrosCargos?.estadoAprobacion}</Tag>
              )
            }         

            {/* {fila?.admin && fila?.estado=="APROBADO" &&(
              <Tag color="blue">{fila.estado}</Tag>
            )} */}
            {/* {fila?.admin && fila?.estado=="CANCELADO" &&(
              <Tag color="purple">{fila.estado}</Tag>
            )}              */}
          </React.Fragment>
        )
        },
    },
    {
      title: "Jefe Inmediato",
      dataIndex: ["jefeInmediato", "nombreCompleto"],
      width: "20%",
      align: "center",
      render: (text, fila) => {
        return (
          <React.Fragment>
            {fila.jefeInmediato?.nombreCompleto}
            <br />
            {fila.jefeInmediato?.fechaAprobacion!==undefined && fila.jefeInmediato?.fechaAprobacion!==null ? "Fecha Aprobación: "+ moment(fila.jefeInmediato?.fechaAprobacion).format("DD/MM/YYYY"):
             fila.jefeInmediato?.fechaRechazo!==undefined && fila.jefeInmediato?.fechaRechazo!==null && "Fecha Rechazo: "+ moment(fila.jefeInmediato?.fechaRechazo).format("DD/MM/YYYY")
            }
            <br />
            {fila.jefeInmediato?.estadoAprobacion === EstadoSolicitudAusencia.PENDIENTE && fila.estado===EstadoSolicitudAusencia.PENDIENTE ? (
              <Tag color="warning">{fila.jefeInmediato.estadoAprobacion}</Tag>
            ): fila.jefeInmediato?.estadoAprobacion === EstadoSolicitudAusencia.PENDIENTE && fila.estado=== "CANCELADO" ?(  
              <Tag color="purple">CANCELADO POR COLABORADOR</Tag>
            ) : fila.jefeInmediato?.estadoAprobacion === EstadoSolicitudAusencia.APROBADO ? (
              <Tag color="success">{fila.jefeInmediato?.estadoAprobacion}</Tag>
            ) : (
              <Tag color="error">{fila.jefeInmediato?.estadoAprobacion}</Tag>
            )}
          </React.Fragment>
        );
      },
    },
    {
      title: "Gestión Humana",
      width: "8%",
      align: "center",
      render: (fila) =>{
        return(
          <React.Fragment>
            {/* fila.estado === EstadoSolicitudAusencia.PENDIENTE ? (
          <Tag color="warning">{fila.estado}</Tag>
        ) : fila.estado === EstadoSolicitudAusencia.APROBADO ? (
          <Tag color="success">{fila.estado}</Tag>
        ) : (
          <Tag color="error">{fila.estado}</Tag>
        ), */}
            {fila.responsableGestionHumana?.fechaAprobacion!==undefined && fila.responsableGestionHumana?.fechaAprobacion!==null ? "Fecha Aprobación: "+ moment(fila.responsableGestionHumana?.fechaAprobacion).format("DD/MM/YYYY"):
            fila.responsableGestionHumana?.fechaRechazo!==undefined && fila.responsableGestionHumana?.fechaRechazo!==null && "Fecha Rechazo: "+ moment(fila.responsableGestionHumana?.fechaRechazo).format("DD/MM/YYYY")
          }
          <br />

          { fila.responsableGestionHumana?.estadoAprobacion === EstadoSolicitudAusencia.PENDIENTE && fila.estado=== EstadoSolicitudAusencia.PENDIENTE? (
              <Tag color="warning">{fila.responsableGestionHumana.estadoAprobacion}</Tag>
            ):fila.responsableGestionHumana?.estadoAprobacion === EstadoSolicitudAusencia.PENDIENTE && fila.estado=== "CANCELADO"? (
              <Tag color="purple">CANCELADO POR COLABORADOR</Tag>  
            ) : fila.responsableGestionHumana?.estadoAprobacion === EstadoSolicitudAusencia.APROBADO ? (
              <Tag color="success">{fila.responsableGestionHumana.estadoAprobacion}</Tag>
            ) : (
              <Tag color="error">{fila.responsableGestionHumana?.estadoAprobacion}</Tag>
            )}
          </React.Fragment>
        )
      }
        
    },

    {
      title: "Fecha Inicio",
      dataIndex: "fechaInicioAusencia",
      width: "10%",
      align: "center",
      render: (text, fila) => moment(fila.fechaInicioAusencia).format("DD/MM/YYYY hh:mm a"),
    },
    {
      title: "Fecha Fin",
      dataIndex: "fechaFin",
      width: "10%",
      align: "center",
      render: (text, fila) => moment(fila.fechaFinAusencia).format("DD/MM/YYYY hh:mm a"),
    },
    {
      title: "Dias",
      dataIndex: "diasSolicitados",
      width: "5%",
      align: "center",
    },
    {
      title: "Fecha de Solicitud",
      dataIndex: "fechaHoraSolicitud",
      width: "15%",
      align: "center",
      render: (text, fila) => moment(fila.fechaHoraSolicitud).format("DD/MM/YYYY hh:mm a"),
    },

    {
      title: "Acciones",
      align: "center",
      width: "15%",
      render: (text, fila) => <MenuAcciones solicitud={fila} />
    },
  ];
  return (
    <React.Fragment>
        <Table
          rowKey={(value) => value.id}
          columns={columnas}
          dataSource={solicitudesAusencia}
          pagination={{
            pageSize: tamanioPaginado,
            total: totalRegistros,
            current: paginaActual,
            showTotal: (total) => `Total ${total} registros`,
            onChange: (page, pageSize) => onCambiarPaginacion(page, pageSize),
          }}          
          expandable={{            
            expandedRowRender: (fila) => <Comentario registro={fila}/>,
            rowExpandable: (fila) =>
              ((isMaccaferriConstruction || isMaccaferriDePeru) &&
               fila.comentario !== null &&
               fila.comentario !== undefined &&
               fila.comentario !== "") ||
               (isAceros && fila.informacionAdicional !== null),
           }}
        />
        <ModalSeguimiento/>
        <ModalAdjuntarAltaMedica/>
        <ModalCancelarSolicitud/>
    </React.Fragment>
  );
};
const Comentario = ({ registro }) => {
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const isMaccaferriDePeru = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20418710757" ? true : false;
	const isMaccaferriConstruction = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20521749378" ? true : false;
	const isAceros = getUsuarioAutenticado().empresaSeleccionada?.ruc==="20538379302"	? true	: false;

	return (
		<React.Fragment>
			{(isMaccaferriDePeru || isMaccaferriConstruction) && (
				<p style={{ margin: 5 }}>{"EMPLEADO DESIGNADO: " + registro.comentario}</p>
			)}
			{isAceros && (
				<div style={{ marginLeft: 50 }}>
					<p>
						<strong>Fecha sobre tiempo: </strong>
						{moment(registro.informacionAdicional.fechaSobreTiempo).format("DD/MM/YYYY")}
					</p>
					<p>
						<strong>Día de compensación: </strong>{" "}
						{moment(registro.informacionAdicional.diaCompensacion).format("DD/MM/YYYY")}
					</p>
					<p>
						<strong>Cantidad de horas: </strong> {registro.informacionAdicional.cantidadHoras}
					</p>
					<p>
						<strong>Horas compensadas: </strong> {registro.informacionAdicional.horasCompensadas}
					</p>
				</div>
			)}
		</React.Fragment>
	);
};
export default ListaConsultaSolicitudes;
