import React, { useEffect, useContext, useState } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import { Document, Page } from "react-pdf";
import { useParams } from "react-router";
import { FormularioService } from "services/FormularioService";
import PaginaPdf from "./PaginaPdf";
import SubirPdf from "./SubirPdf";
import { useAtom } from "jotai";
import {
  componentesAtom,
  estaPaginaPdfCargadaAtom,
  formularioAtom,
  paginaPdfAtom,
  zoomAtom,
} from "../../DisenarFormularioPdfStore";
import { Button, Select, message } from "antd";
import { ZoomOutOutlined, ZoomInOutlined } from "@ant-design/icons";

const VisorFormularioPdf = () => {
  const { formularioId } = useParams();
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [formulario, setFormulario] = useAtom(formularioAtom);
  const [, setEstaPaginaPdfCargada] = useAtom(estaPaginaPdfCargadaAtom);
  const [, setPaginaPdf] = useAtom(paginaPdfAtom);
  const [, setLoading] = useState(false);
  const [totalPaginas, setTotalPaginas] = useState(undefined);
  const [componentes, setComponentes] = useAtom(componentesAtom);
  // const [zoom, setZoom] = useAtom(zoomAtom);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const formulario = await FormularioService.buscarPorFormularioId(formularioId);
        setFormulario(formulario);
        setComponentes(formulario.componentes ? formulario.componentes : []);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (formulario !== undefined && formulario?.urlPlantillaPdfFirmada !== null) {
      FormularioService.guardarDiseno({
        id: formularioId,
        empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
        componentes: componentes,
      })
        .then(async (respuesta) => {
          message.info("Guardando diseño temporalmente...");
          //console.log("Guardando diseño temporalmente...");
        })
        .catch(async (error) => {
          console.error("error", error);
          message.error("Ocurrió un error al guardar el diseño. Intente nuevamente.");
        });
    }
  }, [componentes]);

  const onLoadSuccess = async (successEvent) => {
    const page = await successEvent.getPage(1);
    setPaginaPdf({ alto: page.view[3], ancho: page.view[2] });
    setTotalPaginas(successEvent.numPages);
    setEstaPaginaPdfCargada(true);
  };

  // const onClickBtnComponentes = () => {
  //   console.log("lista de componentes", componentes);
  // };

  if (formulario?.urlPlantillaPdfFirmada === null) {
    return <SubirPdf />;
  }

  return (
    <div
      style={{
        width: "85%",
        maxWidth: "85%",
        height: "inherit",
        padding: "10px 10px 10px 10px",
      }}
    >
      <BotonesZoom />
      <div
        style={{
          height: "calc(100% - 96px)",
          overflow: "auto",
        }}
      >
        <div className="formulario-visor-pdf">
          {formulario?.urlPlantillaPdfFirmada && (
            <Document file={formulario.urlPlantillaPdfFirmada} onLoadSuccess={onLoadSuccess} loading={null}>
              {Array.from(new Array(totalPaginas), (el, index) => (
                <PaginaPdf key={`page_${index + 1}`} numeroPagina={index + 1} />
              ))}
            </Document>
          )}
        </div>
      </div>
    </div>
  );
};

const BotonesZoom = () => {
  const [zoom, setZoom] = useAtom(zoomAtom);

  const onClickBtnAlejar = () => {
    if (zoom > 1) {
      setZoom(zoom - 0.25);
    }
  };

  const onClickBtnAcercar = () => {
    if (zoom < 2) {
      setZoom(zoom + 0.25);
    }
  };

  const onSeleccionarZoom = (valor) => {
    console.log("zoom", valor);
    setZoom(valor);
  };

  return (
    <div
      style={{
        border: "1px solid #ddd",
        height: "41px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button onClick={onClickBtnAlejar}>
        <ZoomOutOutlined />
      </Button>
      <Select defaultValue={zoom} style={{ width: 120, textAlign: "center" }} value={zoom} onChange={onSeleccionarZoom}>
        <Select.Option value={1}>100 %</Select.Option>
        <Select.Option value={1.25}>125 %</Select.Option>
        <Select.Option value={1.5}>150 %</Select.Option>
        <Select.Option value={1.75}>175 %</Select.Option>
        <Select.Option value={2}>200 %</Select.Option>
      </Select>
      <Button onClick={onClickBtnAcercar}>
        <ZoomInOutlined />
      </Button>
    </div>
  );
};

export default VisorFormularioPdf;
