import React from "react";
import { Progress, Skeleton } from "antd";

import { useAtom } from "jotai";
import "./Resumen.css";
import { resumenAtom } from "pages/detalle-seguimiento/listar/ListarDetalleSeguimientoStore";


const Resumen = ({ loading }) => {

    const [resumen] = useAtom(resumenAtom);


    const Progreso = ({ descripcion, porcentaje, total, color = "#1890ff" }) => {
        return (
            <div style={{ display: 'flex'}}>
                <span style={{ width: resumen?.requiereFirmaColaborador?'32%':'40%', textAlign: 'right', paddingRight: '10px' }} >{descripcion}</span>
                <Progress percent={porcentaje} size="small" strokeColor={color} format={porcentaje => <span style={{ color: color, fontWeight: 'bold' }}>{total}</span>} />
            </div>
        );
    }

    return (
        <div className="resumen">

    <Skeleton loading={loading} active paragraph={{ rows: 4, width: '100%' }} title={false} className="resumen-skeleton" >

                <Progreso
                    descripcion="Total"

                    porcentaje={Math.round((resumen?.totalDocumentos* 100 ) / (resumen?.totalDocumentos) )}
                    total={resumen?.totalDocumentos}

                    color="#1890ff" />

                <Progreso
                    descripcion={resumen?.documentosFirmados!=null?"Firmados":"Visualizados"}
                    porcentaje={resumen?.documentosFirmados!=null?(resumen?.documentosFirmados / resumen?.totalDocumentos * 100):(resumen?.documentosVisualizados/ resumen?.totalDocumentos * 100)}
                    total={resumen?.documentosFirmados!=null?resumen?.documentosFirmados:resumen?.documentosVisualizados}
                    color="#52c41a" />

                <Progreso
                    descripcion={resumen?.documentosFirmados!=null?"Pendientes":"No Visualizados"}
                    porcentaje={resumen?.documentosFirmados!=null?(resumen?.documentosPendientes / resumen?.totalDocumentos * 100):(resumen?.documentosNoVisualizados/ resumen?.totalDocumentos * 100)}
                    total={resumen?.documentosFirmados!=null?resumen?.documentosPendientes:resumen?.documentosNoVisualizados}
                    color="#d48806" />

                <Progreso
                    descripcion="Erróneos"
                    porcentaje={resumen?.documentoError / resumen?.totalDocumentos * 100}
                    total={resumen?.documentoError}
                    color="#ff4d4f" />
        </Skeleton>
        </div>

    )
}

export default Resumen;