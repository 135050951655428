import React, {useContext, useEffect, useState} from "react";
import FormularioFiltro from "./FormularioFiltro";
import TablaListar from "./TablaListar";



const EnvioOnboardingPage = () => {

    return(
        <React.Fragment>
            <FormularioFiltro />
            <br />
            <TablaListar />
        </React.Fragment>
    )


}
export default EnvioOnboardingPage;