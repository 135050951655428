import React from "react";
import { Modal, Button } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { visibleModalExitoAtom } from "../../LegajoColaboradorStore";

const ModalExito = () => {
  const [visible, setVisible] = useAtom(visibleModalExitoAtom);

  return (
    <Modal title="Mi Legajo Digital" open={visible} footer={null} width="30%" centered>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          paddingBottom: "20px",
        }}
      >
        <CheckCircleOutlined style={{ fontSize: "48px", color: "#73d13d" }} />
        <div
          style={{
            fontSize: "20px",
            color: "#4a4e69",
            padding: "10px 0px",
            textAlign: "center",
          }}
        >
          Usuario activado exitosamente
        </div>
        <Button type="primary" onClick={() => setVisible(false)} style={{ padding: "0px 60px", borderRadius: "15px" }}>
          Aceptar
        </Button>
      </div>
    </Modal>
  );
};

export default ModalExito;
