import { BaseService } from "./BaseService";
import qs from "qs";
export const DetalleConsultaColaboradorService = {
    async insertar(detalleConsulta) {
		const respuesta = await BaseService.post(
			"/detalleConsultaColaborador",
			detalleConsulta
		);
		return respuesta.data;
	},
    async listar(consultaColaboradorId) {
		const respuesta = await BaseService.get(
			"/detalleConsultaColaborador",
			{
                params: {									
                    consultaColaboradorId: consultaColaboradorId,
                }
            }
		);
		return respuesta.data;
	},
	async listaNumeroRespuestas(consultasColaboradorIds,tipoDetalle) {
		const respuesta = await BaseService.get(
			"/detalleConsultaColaborador/numeroDetalleRespuestasNoVisualizadas", {
			params: {
					consultasColaboradorIds: consultasColaboradorIds,
				    tipoDetalle:tipoDetalle,
			},
			paramsSerializer: (params) => {
            	return qs.stringify(params, { arrayFormat: "repeat" });
       		},
		});
		return respuesta.data;
	},
	async cambiarEstadoVisualizacionDetalleConsulta(consultaColaborador) {
		const respuesta = await BaseService.post("/detalleConsultaColaborador/cambiarEstadoVisualizacionDetalleConsulta",
			consultaColaborador			
		);
		return respuesta.data;
	}	
    
};
