import React, { useState } from "react";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Document, Page } from "react-pdf";
import { useModalVisorPdf } from "store/global";

const Body = () => {
  const loading = useModalVisorPdf((state) => state.loading);
  const setLoading = useModalVisorPdf((state) => state.setLoading);
  const urlPdf = useModalVisorPdf((state) => state.urlPdf);
  const setBlobPdf = useModalVisorPdf((state) => state.setBlobPdf);
  const [totalPaginas, setTotalPaginas] = useState(undefined);
  const motivoRechazo = useModalVisorPdf((state) => state.motivoRechazo);
 
  async function onLoadSuccess(successEvent) {   
    const data = await successEvent.getData();
    const blob = new Blob([data], { type: "application/pdf" });
    setBlobPdf(blob);
    setTotalPaginas(successEvent.numPages);
    setLoading(false);    
  }

  return (
    <div
      className="milegajo-visor-pdf-body"
      style={{ height: motivoRechazo ? "calc(90% - 64px)" : "calc(100% - 64px)" }}
    >
      {loading && (
        <div className="loading">
          <Loading3QuartersOutlined spin className="spin" />
        </div>
      )}

      <Document file={urlPdf} onLoadSuccess={onLoadSuccess} loading={null}>
        {Array.from(new Array(totalPaginas), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1.5} loading={""} />
        ))}
      </Document>
    </div>
  );
};

export default Body;
