import React, { useState } from "react";
import { ContainerCrear } from "../../components/ContainerCrear";
import {
    Button,
    message, notification, Popconfirm,
} from "antd";
import ColaboradorService from "../../services/ColaboradorService";
import { useContext } from "react";
import { SecurityContext } from "../../context/SecurityContextProvider";
import { EnvioService } from "services/EnvioService";


export function EliminarDatosPage() {
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    async function handleOnClickEliminar() {
        try {
            await ColaboradorService.eliminarTodos(getUsuarioAutenticado().organizacionId, getUsuarioAutenticado().empresaSeleccionada.id);

            await EnvioService.eliminarTodos(getUsuarioAutenticado().empresaSeleccionada.id);
            message.success("Colaboradores y Envios eliminados");

        } catch (error) {
            notification.error({
                message: "No se puede eliminar",
                description: error.response.data,
            });
        }

    }
    return (
        <ContainerCrear>
            <div align="center">
                <Popconfirm
                    title="Estás seguro de Eliminar los datos?"
                    onConfirm={handleOnClickEliminar}
                    //onCancel={cancel}
                    okText="Si"
                    cancelText="No"
                >
                    <Button>Eliminar Datos</Button>

                </Popconfirm>
            </div>

        </ContainerCrear>
    );

}