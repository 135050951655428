import React, { useEffect } from "react";
import {
  componentesAtom,
  datosFormularioAtom,
  formAtom,
  loadingAtom,
  typeAtom,
  urlFirmaHolografaAtom,
  urlHuellaDactilarAtom,
  urlPdfAtom,
  variablesGlobalesAtom,
  urlFotoAtom
} from "../../VisorFormularioStorage";
import { useAtom, useSetAtom } from "jotai";

const InicializarValores = ({
  type,
  form,
  urlPdf,
  componentes,
  variablesGlobales,
  datosFormulario,
  urlFirmaHolografa,
  urlHuellaDactilar,
  urlFoto,
}) => {
  const setType = useSetAtom(typeAtom);
  const setUrlPdf = useSetAtom(urlPdfAtom);
  //const setLoading = useSetAtom(loadingAtom);
  const [loading, setLoading] = useAtom(loadingAtom);
  const setComponentes = useSetAtom(componentesAtom);
  const setVariablesGlobales = useSetAtom(variablesGlobalesAtom);
  const setDatosFormulario = useSetAtom(datosFormularioAtom);
  const setForm = useSetAtom(formAtom);
  const setUrlFirmaHolografa = useSetAtom(urlFirmaHolografaAtom);
  const setUrlHuellaDactilar = useSetAtom(urlHuellaDactilarAtom);
  const setUrlFoto = useSetAtom(urlFotoAtom);

  useEffect(() => {
    const inicializar = () => {
      setType(type);
      setUrlPdf(urlPdf);
      setVariablesGlobales(variablesGlobales);
      setDatosFormulario(datosFormulario);
      setForm(form);
      setUrlFirmaHolografa(urlFirmaHolografa);
      setUrlHuellaDactilar(urlHuellaDactilar);
      setUrlFoto(urlFoto);
      if (urlPdf) {
        setLoading(true);
      }
    };
    inicializar();
  }, [urlPdf]);

  useEffect(() => {
    const limpiar = () => {
      if (type === "form") {
        if (loading) {
          setComponentes([]);
          form.reset();
        } else {
          setComponentes(componentes);
        }
      }
    };
    limpiar();
  }, [loading]);

  return "";
};

export default InicializarValores;
