import React, { useState } from "react";
import { Card, Tabs, Dropdown,Popconfirm, Modal } from "antd";
import { Provider as JotaiProvider, useAtom } from "jotai";
import {
  ListaContratos,
  ModalAprobar,
  ModalRechazar,
  ListaContratosSeguimiento,
  ModalTransferencia,
} from "./componentes";
import {
  CaretDownOutlined,
  NotificationOutlined,
  FileExcelOutlined
} from "@ant-design/icons";
import { ModalVisorPdf as VisorPdf } from "components/common";
import {
  urlPdfContratoAtom,
  visibleModalVisorPdfAtom,
} from "./BandejaAprobacionAdministracionPersonalStore";
import { ModalActualizarResponsables } from "./componentes/ModalActualizarResponsables";
import { BandejaAprobacionAdministracionPersonalService } from "services/contratos";

const BandejaAprobacionAdministracionPersonalPage = () => {
  const [visibleModalActualizarResponsables, setVisibleModalActualizarResponsables] = useState(false);
  const onClickBtnMasAcciones = (e) => {
		if (e.key === "notificar-masivo-responsable") {
      onclickBtnNotificarResponsables();
		}else if(e.key === "actualizar-masivo-responsable"){
      console.log("ACTUALIZAR");
      setVisibleModalActualizarResponsables(true);
    }
	};

  const onclickBtnNotificarResponsables = async() =>{
    await BandejaAprobacionAdministracionPersonalService.notificarContratosPorVencerResponsables();
  }

  const operaciones = {
    right: (
      <Dropdown.Button
						menu={{
							items: [
                                {
									label: ( <Popconfirm
                                        title="¿Desea notificar los contratos por vencer masivamente a los responsables?"
                                        onConfirm={() => onClickBtnMasAcciones({ key: "notificar-masivo-responsable" })}
                                        okText="Sí"
                                        cancelText="No"
                                    >
                                        <span>
                                            Notificar Masivamente Responsables
                                        </span>
                                    </Popconfirm>),
									icon: <NotificationOutlined style={{ fontSize: "18px" }} />,
								},
								{
									label: "Actualizar Masivamente Responsables",
									key: "actualizar-masivo-responsable",
									icon: <FileExcelOutlined style={{ fontSize: "18px" }} />,
								},
							],
							onClick: onClickBtnMasAcciones,
						}}
						icon={<CaretDownOutlined />}
						trigger={["click"]}
						arrow
					>
						Acciones
				</Dropdown.Button>
    ),
  };

  return (
    <JotaiProvider>
      <ContainerTabs>
        <Tabs
          tabBarExtraContent={operaciones}
        > 
          <Tabs.TabPane
            tab="Contratos Pendientes de Aprobación"
            key="contrato-pendientes-aprobacion"
          >
            <ListaContratos />
            <ModalAprobar />
            <ModalRechazar />
            <ModalVisorPdf />
            <ModalTransferencia />
            <ModalActualizarResponsables visible={visibleModalActualizarResponsables} setVisible={setVisibleModalActualizarResponsables}/>
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Seguimiento de Contratos"
            key="seguimiento-aprobacion"
          >
            <ListaContratosSeguimiento />
          </Tabs.TabPane>
        </Tabs>
        
      </ContainerTabs>
    </JotaiProvider>
  );
};

const ModalVisorPdf = () => {
  const [visible, setVisible] = useAtom(visibleModalVisorPdfAtom);
  const [urlPdfContrato] = useAtom(urlPdfContratoAtom);

  return (
    <VisorPdf
      urlPdf={urlPdfContrato}
      visible={visible}
      onCerrar={() => setVisible(false)}
    />
  );
};

const ContainerTabs = ({ children }) => {
  return (
    <div style={{ padding: "10px 20px", background: "white" }}>{children}</div>
  );
};

export default BandejaAprobacionAdministracionPersonalPage;
