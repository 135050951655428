import React from "react";
import { Container } from "components/Container";
import { TablaFormularios } from "./tabla-formularios";
//import { InformacionContratos } from "./informacion-contratos";
import { ModalVisorPdf } from "components/Modal/ModalVisorPdf";
//import { FiltroTabla } from "./filtro-tabla";
//import * as atoms from "store/local/contratos/DetalleSeguimientoContratoStore";
//import * as atoms from "../DetalleSeguimientoFormularioStore";
import * as atoms from "store/local/formularios/DetalleSeguimientoFormularioStore";
import { useAtom } from "jotai";
//import { BotonesGlobales } from "./botones-globales";
import { useHistory } from "react-router";

const ListaFormulariosNotificados = () => {
    const [envio] = useAtom(atoms.envio);
    let history = useHistory();

    const onClickBtnRetornar = () => {
        history.push("/notificacion/formulario/seguimiento");
    }
    return (


        <Container titulo={envio.descripcion} /*botonExtra={<BotonesGlobales />} */ onClickRetornar={onClickBtnRetornar}>

            {/* <InformacionContratos />

            <FiltroTabla /> */}

            <TablaFormularios />

            <ModalVisorPdf />

        </Container>

    )

}
export default ListaFormulariosNotificados;