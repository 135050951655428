import React,{useEffect} from "react";
import { useAtom } from "jotai";
import { descargasAtom } from "../DescargaMasivaListarStore";
import DescargaMasivaService from "services/DescargaMasivaService";

const useListarDescargasMasivas=()=>{
    
 const [descargas,setDescargas]=useAtom(descargasAtom);

 useEffect(()=>{
    const cargarDatos=async()=>{
      const respuesta= await DescargaMasivaService.listar();
      setDescargas(respuesta);
    }
    cargarDatos();
 },[])

 return{descargas};
}
export default useListarDescargasMasivas;