import React, { useEffect, useState } from "react";
import { Form, Input, Button, Table, Select, message, DatePicker, Space, Row } from "antd";
import { useCargarPostulantes } from "../../hooks";
import { useCargarCargos } from "../../hooks";
import { useCargarUsuarios } from "../../hooks";
import { responsableSeleccionadoAtom } from "../../ConvocatoriaCrearStore";
import { useAtom } from "jotai";
//import TablaPostulantes from "./TablaPostulantes";
import { TipoConvocatoria } from "enums/reclutamiento-y-seleccion/TipoConvocatoria";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ConvocatoriaService } from "services/reclutamiento-y-seleccion/ConvocatoriaService";
import { useHistory } from "react-router-dom";
import { moment } from "moment";
import SolicitudPersonalService from "services/reclutamiento-y-seleccion/SolicitudPersonalService";
import { useParams } from "react-router-dom";

const RegistroConvocatoria = () => {
  const [formCrear] = Form.useForm();
  const [responsableSeleccionado, setResponsableSeleccionado] = useAtom(responsableSeleccionadoAtom);

  const { solicitudPersonalId } = useParams();
  const { desde } = useParams();
  let history = useHistory();

  useEffect(() => {
    const cargarDatos = async () => {
      if (solicitudPersonalId !== "undefined") {
        const respuesta = await SolicitudPersonalService.buscarPorId(solicitudPersonalId);
        formCrear.setFieldsValue({
          cargo: respuesta.puesto.nombre,
        });
        console.log("solicitudId:", solicitudPersonalId);
        console.log("desde:", desde);
      }
    };
    cargarDatos();
  }, []);

  const onFinishConvocatoria = async (convocatoria) => {
    try {
      convocatoria.cargo = convocatoria.cargo.trim();
      convocatoria.responsable = responsableSeleccionado;
      convocatoria.fechaLimitePostulacion = convocatoria.fechaLimitePostulacion?.format("DD/MM/YYYY");
      if (solicitudPersonalId !== "undefined") convocatoria.solicitudPersonalId = solicitudPersonalId;
      console.log("convocatoria:", convocatoria);
      await ConvocatoriaService.insertar(convocatoria);
      if (desde === "solicitud-personal") {
        history.push("/reclutamiento-y-seleccion/solicitud-personal/solicitudes");
      } else {
        history.push("/reclutamiento-y-seleccion/convocatorias");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Form layout="vertical" style={{ padding: "0px 2%" }} form={formCrear} onFinish={onFinishConvocatoria}>
      <SelectTipo />
      <SelectCargo />
      <InputObjetivo />
      <Requisitos />
      <DatePickerLimite />
      <SelectResponsable />

      {/* <SelectPostulante/>           */}
      {/* <TablaAgregarPostulantes formulario={formCrear}/> */}
      <BotonCrearConvocatoria />
    </Form>
  );
};
const InputObjetivo = () => {
  return (
    <Form.Item
      label="Objetivo"
      name="objetivo"
      //rules={[{ required: true, message: "Ingrese el objetivo de convocatoria" }]}
    >
      <Input.TextArea rows={4} />
    </Form.Item>
  );
};
const SelectTipo = () => {
  return (
    <Form.Item
      label="Tipo"
      name="tipo"
      //rules={[{ required: true, message: "Seleccione el Tipo" }]}
    >
      <Select placeholder="Seleccione el Tipo">
        <Select.Option value={TipoConvocatoria.INTERNO}>{TipoConvocatoria.INTERNO} </Select.Option>
        <Select.Option value={TipoConvocatoria.EXTERNO}>{TipoConvocatoria.EXTERNO} </Select.Option>
      </Select>
    </Form.Item>
  );
};

const SelectCargo = () => {
  //const{cargos}=useCargarCargos();
  return (
    <Form.Item label="Cargo" name="cargo" rules={[{ required: true, message: "Ingrese el cargo", whitespace: true }]}>
      {/* <Select placeholder="Seleccione el Cargo">
        {cargos!==undefined && cargos.map((cargo)=>{
          return(
            <Select.Option key={cargo.id}value={cargo.nombre}>{cargo.nombre}</Select.Option>
          )
        })}
     </Select> */}
      <Input />
    </Form.Item>
  );
};
// const SelectPostulante=()=>{
//   const {postulantes} =useCargarPostulantes();
//   const[postulanteSeleccionado,setPostulanteSeleccionado]= useAtom(postulanteSeleccionadoAtom);

//   const onChangePostulante=(value,Option)=>{

//      const postulante={
//        id:value,
//        nombre:Option.labelNombre,
//      }
//      console.log("Postulante Seleccionado",postulante);

//      setPostulanteSeleccionado(postulante);

//   }

//     return(
//       <Form.Item label="Postulante" name="postulante">
//          <Select
//           showSearch
//           onChange={onChangePostulante}
//           //filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
//          >
//              {postulantes!==undefined && postulantes.map((postulante)=>{
//               return(
//                 <Select.Option key={postulante.id} value={postulante.id} labelNombre={postulante.apellidoPaterno+ " "+ postulante.apellidoMaterno+" "+postulante.nombres}>
//                    {postulante.apellidoPaterno}{" "}{postulante.apellidoMaterno}{" "}{postulante.nombres}
//                 </Select.Option>
//               )

//              })}
//          </Select>
//       </Form.Item>
//     )
// }
const SelectResponsable = () => {
  const { responsables } = useCargarUsuarios();
  const [, setResponsableSeleccionado] = useAtom(responsableSeleccionadoAtom);

  const onChangeResponsable = (value, Option) => {
    const responsable = {
      id: value,
      nombreCompleto: Option.children,
      login: Option.labellogin,
      numeroDocumentoIdentidad: Option.labelnumerodocumentoidentidad,
    };
    //console.log("Reponsable:",responsable);
    setResponsableSeleccionado(responsable);
  };
  return (
    <Form.Item
      label="Responsable"
      name="responsable"
      // rules={[{ required: true, message: "Seleccione el Responsable" }]}
    >
      <Select
        showSearch
        placeholder="Seleccione el Responsable"
        onChange={onChangeResponsable}
        optionFilterProp="children"
      >
        {responsables !== undefined &&
          responsables.map((responsable) => {
            return (
              <Select.Option
                key={responsable.id}
                value={responsable.id}
                labellogin={responsable.login}
                labelnumerodocumentoidentidad={responsable.numeroDocumentoIdentidad}
              >
                {responsable.nombreCompleto}
              </Select.Option>
            );
          })}
      </Select>
    </Form.Item>
  );
};
//  const TablaAgregarPostulantes=({formulario})=>{
//   const[postulanteSeleccionado,setPostulanteSeleccionado]= useAtom(postulanteSeleccionadoAtom);
//   const[postulantesAgregados,setPostulantesAgregados]= useAtom(postulantesAgregadosAtom);

//    const onclickAgregar=()=>{
//      if(postulanteSeleccionado){
//       const postulanteExistente = postulantesAgregados.find(postulante=>postulante.id===postulanteSeleccionado.id);
//       if(postulanteExistente){
//         message.error("Ya existe un postulante con ese nombre");
//       }
//       else{
//         setPostulantesAgregados([...postulantesAgregados,postulanteSeleccionado]);
//       }

//       console.log("Form",formulario);
//       setPostulanteSeleccionado();
//      }

//    }

//   return(
//         <Form.Item name="agregar">
//           <Button type="primary" onClick={onclickAgregar}>Agregar</Button>
//           <TablaPostulantes/>
//       </Form.Item>
//     )

//  }
const Requisitos = () => {
  return (
    <Form.Item label="Requisitos" name="requisitos">
      <Input.TextArea rows={4} />
    </Form.Item>
    //    {(fields, { add, remove }) => (
    //     <>
    //       <div style={{ paddingBottom: 15 }}>Requisitos</div>

    //       {fields.map(({ key, name, ...restField }) => (
    //         <Space
    //           key={key}
    //           style={{
    //             display: "flex",
    //             marginBottom: 8,
    //           }}
    //           align="baseline"
    //         >
    //           <Form.Item
    //             {...restField}
    //             name={[name]}
    //             rules={[
    //               {
    //                 required: true,
    //                 message: "Ingrese el requisito",
    //               },
    //             ]}
    //           >
    //             <Input placeholder="Requisitos" style={{ width: "700px" }} />
    //           </Form.Item>
    //           <MinusCircleOutlined onClick={() => remove(name)} />
    //         </Space>
    //       ))}
    //       <Form.Item>
    //         <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
    //           Agregar Requisitos
    //         </Button>
    //       </Form.Item>
    //     </>
    //   )}

    //  </Form.List>
  );
};
const DatePickerLimite = () => {
  return (
    <Form.Item
      label="Fecha Límite Postulación"
      name="fechaLimitePostulacion"
      //rules={[{ required: true, message: "Seleccione la fecha límite" }]}
    >
      <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
    </Form.Item>
  );
};
const BotonCrearConvocatoria = () => {
  return (
    <Form.Item style={{ textAlign: "center" }}>
      <Button type="primary" htmlType="submit">
        Crear convocatoria
      </Button>
    </Form.Item>
  );
};

export default RegistroConvocatoria;
