import React from "react";
import {
  Tag,Select, Progress} from "antd";
  import {
    SyncOutlined,
    MinusCircleOutlined,
    FileUnknownOutlined,
  } from "@ant-design/icons";
import { EstadoRegistro } from "enums/EstadoRegistro";
import { EstadoDocumento } from "enums/EstadoDocumento";
import { EtiquetaEstadoNotificacion } from "../../../../documento-laboral/litarDetalle/etiqueta-estado-notificacion";
import { EtiquetaEstadoVisualizacion } from "../../../../documento-laboral/litarDetalle/etiqueta-estado-visualizacion";
import { EtiquetaEstadoFirmaColaborador } from "../../../../documento-laboral/litarDetalle/etiqueta-estado-firma-colaborador";
import { findAllInRenderedTree } from "react-dom/test-utils";
import  {MenuAcciones}  from "../MenuAcciones";
const { Option } = Select;
export const columnas = [
  
    {
      title: "Documento",
      dataIndex: ["colaborador","numeroDocumentoIdentidad"],    
    },
    {
      title: "Colaborador",
      dataIndex: ["colaborador","nombreCompleto"],

    },
    {
      title: "Código",
      dataIndex: ["colaborador","codigo"],
      render: (text, fila) =>  fila.colaborador.codigo?.length>0?fila.colaborador.codigo:"-",

    },
    {
      title: "Bandeja",
      dataIndex: ["colaborador","correoElectronico"],
    },
    {
      title: "Notificación",
      dataIndex: "notificacion",
      render: (text, fila) => {
        // return <EtiquetaEstadoNotificacion documento={fila} />
        if (
          (fila.eliminado === false || fila.eliminado === null) &&
          (fila.estadoDocumento === EstadoDocumento.GENERADO || fila.estadoDocumento === EstadoDocumento.ERROR ||fila.estadoProcesoNombre === "Incompleta")
        ) {
          return <EtiquetaEstadoNotificacion documento={fila} />;
        }

        if (fila.eliminado === true) {
          return {
            children: (
              <span>
                <Tag color="default" icon={<MinusCircleOutlined />}>
                  INHABILITADO
                </Tag>
              </span>
            ),
            props: {
              colSpan: 3,
            },
          };
        }

        if (fila.estadoRegistro===EstadoRegistro.PENDIENTE_APROBAR)  {
          //PENDIENTE DE APROBAR
          return {
            children: (
              <span style={{ color: "#7d7939" }}>
                <FileUnknownOutlined /> Esperando aprobación ...
              </span>
            ),
            props: {
              colSpan: 3,
            },
          };
        }

        return {
          children: (
            <span style={{ color: "#7d7939" }}>
              <SyncOutlined spin style={{ color: "#7d7939" }} /> Procesando...
            </span>
          ),
          props: {
            colSpan: 3,
          },
        };
      },
   
    },
    {
      title: "Visualizado",
      dataIndex: "Firmado",
      render: (text, fila) => {
        // return <EtiquetaEstadoVisualizacion documento={fila} />
        if (
          (fila.eliminado === false || fila.eliminado === null) &&
          (fila.estadoDocumento === EstadoDocumento.GENERADO || fila.estadoDocumento === EstadoDocumento.ERROR || fila.estadoProcesoNombre === "Incompleta")
        ) {
          return <EtiquetaEstadoVisualizacion documento={fila} />;
        }

        return {
          props: {
            colSpan: 0,
          },
        };
      },

    },
    {
      title: "Firmado",
      dataIndex: "firmado",
      render: (text, fila) => {
        // return <EtiquetaEstadoFirmaColaborador documento={fila} />
        if (
          (fila.eliminado === false || fila.eliminado === null) &&
          (fila.estadoDocumento === EstadoDocumento.GENERADO || fila.estadoDocumento === EstadoDocumento.ERROR || fila.estadoProcesoNombre === "Incompleta")
        ) {
          return <EtiquetaEstadoFirmaColaborador documento={fila} />;
        }

        return {
          props: {
            colSpan: 0,
          },
        };
      },
   
    },
    {
      title: "Acciones",
      align: "center",
      width: "10%",
      render: (text, fila) =>  <MenuAcciones envio={fila} />,
    }
  ];
