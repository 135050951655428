import React,{useEffect} from "react";
import {plantillasAtom} from "../PlantillaListarStore";
import { useAtom } from "jotai";
import { PlantillaService } from "services/reclutamiento-y-seleccion";


const useCargarPlantillas=()=>{
    const[plantillas,setPlantillas]=useAtom(plantillasAtom);
        useEffect(()=>{

            const cargarDatos=async()=>{
                const respuesta = await PlantillaService.listar();
                setPlantillas(respuesta);
            }
            cargarDatos();
        },[]);
 return{plantillas,setPlantillas}
}
export default useCargarPlantillas;