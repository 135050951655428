import React, { useContext, useEffect, useState } from "react";
import { Table, Tag } from "antd";
import { MenuAcciones } from "./MenuAcciones";
import TipoAbsentismoService from "../../../../services/TipoAbsentismoService";
import { useAtom } from "jotai";
import * as atoms from "../ListarTipoAbsentismoStore";
import { SecurityContext } from "context/SecurityContextProvider";

const TablaTipoAbsentismo = () => {
  const [tiposAbsentismo, setTiposAbsentismo] = useAtom(atoms.tiposAbsentismoAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const tipos = await TipoAbsentismoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        setTiposAbsentismo(tipos);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, []);

  const columnas = [
    {
      title: "Tipo",
      dataIndex: "descripcion",
      align: "center",
    },
    {
      title: "Nombre Documento",
      dataIndex: "nombre",
      align: "center",
    },
    {
      title: "Aprobación Jefe Inmediato",
      //dataIndex:"requiereAprobacionJefeInmediato",
      align: "center",
      render: (fila) =>
        fila.requiereAprobacionJefeInmediato ? <Tag color="green">Si</Tag> : <Tag color="red">No</Tag>,
    },
    {
      title: "Aprobación Gestión Humana",
      //dataIndex:"requiereAprobacionGestionHumana",
      align: "center",
      render: (fila) =>
        fila.requiereAprobacionGestionHumana ? <Tag color="green">Si</Tag> : <Tag color="red">No</Tag>,
    },
    {
      title: "Contabilizar días vacaciones",
      //dataIndex:"contabilizarDiasVacaciones",
      align: "center",
      render: (fila) => (fila.contabilizarDiasVacaciones ? <Tag color="green">Si</Tag> : <Tag color="red">No</Tag>),
    },
    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      render: (fila) => (
        <MenuAcciones tipo={fila} tiposAbsentismo={tiposAbsentismo} setTiposAbsentismos={setTiposAbsentismo} />
      ),
    },
  ];
  return <Table loading={loading} rowKey={(tipo) => tipo.id} columns={columnas} dataSource={tiposAbsentismo} />;
};
export default TablaTipoAbsentismo;
