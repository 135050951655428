import React, { useState, useEffect } from "react";
import {
  Modal,
  Upload,
  message,
  Button,
  notification,
  Space,
  Typography,
  Tag,
  Divider,
  Row,
  Checkbox,
  Col,
  Badge,
} from "antd";
import { FileExcelOutlined, CheckCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import { FormatoCarga } from "enums/FormatoCarga";
import { SecurityContext } from "context/SecurityContextProvider";
import ColaboradorService from "services/ColaboradorService";

export default function ModalCargaMasiva({ visibleModal, onCerrarModal, etiquetaColaboradores }) {
  const { Text, Paragraph } = Typography;
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [postulantesNuevos, setPostulantesNuevos] = useState([]);
  const [colaboradoresActualizados, setColaboradoresActualizados] = useState([]);
  const [listaDocumentos, setListaDocumentos] = useState([]);
  const [colaboradorNoProcesados, setColaboradorNoProcesados] = useState([]);
  const [rol, setRol] = useState("");
  const [etiqueta, setEtiqueta] = useState(false);
  const [reemplazarCorreos, setReemplazarCorreos] = useState(false);
  const [urlExcelObservaciones, setUrlExcelObservaciones] = useState();
  const [colaboradoresObservados, setColaboradoresObservados] = useState([]);
  const [totalColaboradores, setTotalColaboradores] = useState(undefined);
  const [visibleDescargaModelo, setVisibleDescargaModelo] = useState(true);
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  useEffect(() => {
    setPostulantesNuevos(0);
    setColaboradoresActualizados(0);
    setColaboradorNoProcesados(0);
    setColaboradoresObservados(0);
  }, []);

  const datos = {
    organizacionId: getUsuarioAutenticado().organizacionId,
    subdominio: getUsuarioAutenticado().subdominio,
    empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
    empresaRazonSocial: getUsuarioAutenticado().empresaSeleccionada.razonSocial,
    empresaUrlImagenLogotipo: getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo,
    reemplazarCorreos: reemplazarCorreos,
  };
  const props = {
    onRemove: (file) => {
      setListaDocumentos([]);
      borrarDatos();
      return {
        listaDocumentos: [],
      };
    },
    beforeUpload: (file) => {
      if (!FormatoCarga.EXCEL.extension.includes(file.type)) {
        message.error(`${file.name} no es un archivo Excel`);
      } else {
        setListaDocumentos(file);
        setVisibleDescargaModelo(false);
        return false;
      }
    },
    listaDocumentos,
  };
  function borrarDatos() {
    setPostulantesNuevos(0);
    setColaboradoresActualizados(0);
    setColaboradorNoProcesados(0);
    setEtiqueta(false);
    setListaDocumentos([]);
    setReemplazarCorreos(false);
    setVisibleDescargaModelo(true);
    setColaboradoresObservados(0);
    setTotalColaboradores(undefined);
  }

  const cargarColaboradores = async () => {
    if (listaDocumentos.name) {
      try {
        setLoading(true);
        const datosColaborador = {
          organizacionId: getUsuarioAutenticado().organizacionId,
          subdominio: getUsuarioAutenticado().subdominio,
          empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
          empresaRazonSocial: getUsuarioAutenticado().empresaSeleccionada.razonSocial,
          empresaUrlImagenLogotipo: getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo,
          reemplazarCorreos: reemplazarCorreos,
          vinculoLaboral: "POSTULANTE",
        };
        const formData = new FormData();
        //console.log("archivo",listaDocumentos);
        formData.append("file", listaDocumentos);
        formData.append(
          "datosColaborador",
          new Blob([JSON.stringify(datosColaborador)], {
            type: "application/json",
          })
        );
        const respuesta = await ColaboradorService.cargarMasivamente(formData);
        if (respuesta.colaboradoresObservados === 0) {
          message.success(`${listaDocumentos.name} Archivo subido correctamente`);
        }
        setPostulantesNuevos(respuesta.postulantesNuevos);
        setColaboradoresActualizados(respuesta.colaboradoresActualizados);
        setColaboradorNoProcesados(respuesta.colaboradorNoProcesados);
        setUrlExcelObservaciones(respuesta.urlExcelObservaciones);
        setColaboradoresObservados(respuesta.colaboradoresObservados);
        setTotalColaboradores(respuesta.totalColaboradores);
        setEtiqueta(true);
      } catch (error) {
        setLoading(false);
        notification.error({
          message: "Alerta!",
          description: error.response.data.mensaje,
        });
      } finally {
        setLoading(false);
      }
    } else {
      message.error("No ha seleccionado el archivo excel");
    }
  };

  return (
    <Modal
      title="Importar postulantes desde excel"
      visible={visibleModal}
      width={750}
      //centered
      onCancel={onCerrarModal}
      afterClose={borrarDatos}
      destroyOnClose={true}
      footer={null}
    >
      <div>
        <Row>
          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Upload {...props} maxCount={1} accept={FormatoCarga.EXCEL.extension}>
              <Button style={{ borderRadius: 4, borderColor: "green" }}>
                <FileExcelOutlined style={{ fontSize: 20, color: "green" }} />{" "}
                <Text style={{ color: "green" }}>Cargar archivo Excel</Text>
              </Button>
            </Upload>
          </div>
        </Row>
        <br />
        <Row style={{ justifyContent: "center", margin: "12px 0px" }}>
          <Button type="primary" onClick={cargarColaboradores} loading={loading}>
            <UploadOutlined />
            Iniciar Carga
          </Button>
        </Row>
      </div>
      {colaboradoresObservados > 0 && (
        <Row justify="center">
          <Text strong type="danger">
            Se encontraron observaciones en el archivo cargado,ver{" "}
            <a style={{ color: "#0F7FF0", fontStyle: "italic" }} href={urlExcelObservaciones}>
              aquí
            </a>
          </Text>
        </Row>
      )}
      
      {etiqueta && totalColaboradores > 0 ? (
        <div>
          <br />
          <Row justify="center">
            <Space size={60}>
              {/*<Tag color="success">*/}

              <Text style={{ fontSize: 12 }}>
                <CheckCircleOutlined style={{ color: "#329BE9 " }} /> Postulantes Nuevos: {postulantesNuevos}
              </Text>

              <Text style={{ fontSize: 12 }}>
                <CheckCircleOutlined style={{ color: "#D89817" }} /> Postulantes actualizados:{" "}
                {colaboradoresActualizados}
              </Text>

              <Text style={{ fontSize: 12 }}>
                <CheckCircleOutlined style={{ color: "#DD0C3F" }} /> Postulantes no procesados:{" "}
                {colaboradorNoProcesados}
              </Text>

            </Space>
          </Row>
        </div>
      ) : (
        <div></div>
      )}

      {totalColaboradores === 0 && (
        <Row justify="center">
          <Text type="danger">El archivo no tiene colaboradores</Text>
        </Row>
      )}

      <Divider />
      <div style={{ textAlign: "center" }}>
        {/* <Button
          icon={<DownloadOutlined />}
          onClick={handleOnClickDescargarPlantilla}
          style={{ backgroundColor: "#06A428", color: "white" }}
        >
          Descargar Plantilla Colaborador
        </Button> */}
      </div>
    </Modal>
  );
}
