import React, { useState, useContext, useEffect } from "react";
import {
  Alert,
  Form,
  Select,
  Button,
  DatePicker,
  Upload,
  message,
  Modal,
  Table,
  Row,
  Col,
  Space,
  Input,
  Typography,
} from "antd";
import { ModalAgregarColaboradores } from "./componentes/ModalAgregarColaboradores";
import { SecurityContext } from "../../../../context/SecurityContextProvider";
import TipoDocumentoService from "../../../../services/TipoDocumentoService";
import { CategoriaDocumento } from "../../../../enums/CategoriaDocumento";
import ComunicadoService from "../../../../services/ComunicadoService";
import CarpetaService from "../../../../services/CarpetaService";
import { Container } from "components/Container";
import {
  UploadOutlined,
  CheckSquareTwoTone,
  CloseSquareTwoTone,
  ExclamationCircleOutlined,
  PlusOutlined,
  FolderTwoTone,
  FolderFilled,
} from "@ant-design/icons";
import { ModalAgregarTipoDocumento } from "./componentes/ModalAgregarTipoDocumento";
import { destinatarioComunicado } from "enums/DestinatarioComunicado";
import { estadoColaborador } from "enums/EstadoColaborador";
import LegajoColaboradorService from "../../../../services/LegajoColaboradorService";
import SedeService from "services/SedeService";
import { useAtom } from "jotai";
import { tipoDocumentoSeleccionadoAtom, visibleModalConfirmacionAtom } from "./NotificarComunicadoStore";
import { useHistory } from "react-router-dom";
import { loadingAtom } from "pages/detalle-seguimiento/listar/ListarDetalleSeguimientoStore";
import { ModalConfirmacion } from "./componentes/ModalConfirmacion";
import moment from "moment";

import Bowser from "bowser";
import Axios from "axios";
import { SexoColaborador } from "enums/SexoColaborador";
import CargoService from "services/CargoService";
import UsuarioService from "services/UsuarioService";
import { CampoColaborador } from "enums/CampoColaborador";
import SubsedeService from "services/SubsedeService";
import SeccionService from "services/SeccionService";
import TipoPlanillaService from "services/TipoPlanillaService";
import PerfilService from "services/PerfilService";
import produce from "immer";
import CertificadoService from "services/CertificadoService";
import { EstadoCertificadoDigital } from "enums/EstadoCertificadoDigital";
import * as atoms from "./NotificarComunicadoStore";
import { AlertCertificadoDigital } from "./componentes/alert-certificado-digital";
import { FormatoCarga } from "enums/FormatoCarga";

const { Option } = Select;

export function ComunicadosCrearPage() {
  const [visibleModalConfirmacion, setVisibleModalConfirmacion] = useAtom(visibleModalConfirmacionAtom);
  const [archivoPdf, setArchivoPdf] = useState([]);
  // const [visibleModalColaboradores, setVisibleModalColaboradores] =    useState(false);
  const [visibleConfirmacion, setVisibleConfirmacion] = useState(false);
  const [tiposDocumento, setTiposDocumento] = useState([]);
  const [colaboradores, setColaboradores] = useState(undefined);

  const { getUsuarioAutenticado } = useContext(SecurityContext);

  const [visibleModalTiposDocumento, setVisibleModalTiposDocumento] = useState(false);
  const [carpetas, setCarpetas] = useState([]);
  const [frmCrearComunicado] = Form.useForm();
  const [ingresaColaborador, setIngresaColaborador] = useState(false);
  const [ingresaSede, setIngresaSede] = useState(false);
  const [ingresaSexo, setIngresaSexo] = useState(false);
  const [ingresaCargo, setIngresaCargo] = useState(false);
  const [estadosColaboradores, setEstadosColaboradores] = useState(false);
  const [colaboradoresSeleccionados, setColaboradoresSeleccionados] = useState([]);
  const [cargaArchivoExcel, setCargaArchivoExcel] = useState(false);
  const [sedes, setSedes] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [sedeSeleccionada, setSedeSeleccionada] = useState([]);
  const [cargoSeleccionado, setCargoSeleccionado] = useState([]);
  const [colaboradoresAgregados, setColaboradoresAgregados] = useState([]);
  const [sedesAgregadas, setSedesAgregadas] = useState([]);
  const [cargosAgregados, setCargosAgregados] = useState([]);
  const [visibleTabla, setVisibleTabla] = useState(false);
  const [visibleTablaSede, setVisibleTablaSede] = useState(false);
  const [visibleTablaCargo, setVisibleTablaCargo] = useState(false);
  const [valordestinatarioComunicado, setValorDestinatarioComunicado] = useState("");
  const [valorEstadoColaborador, setValorEstadoColaborador] = useState(estadoColaborador.SINESTADO);
  const [valorEstadoSexo, setValorEstadoSexo] = useState(undefined);

  const [todosColaboradores, setTodosColaboradores] = useState(false);
  const [colaboradoresAgregadosIds, setColaboradoresAgregadosIds] = useState([]);
  const [sedesAgregadasIds, setSedesAgregadasIds] = useState([]);
  const [cargosAgregadosIds, setCargosAgregadosIds] = useState([]);
  const [tipoDocumentoSeleccionado, setTipoDocumentoSeleccionado] = useAtom(tipoDocumentoSeleccionadoAtom);
  const [loading, setLoading] = useState(false);
  const [listaCarpetasFiltro, setListaCarpetasFiltro] = useState([]);
  const [comunicadoEnvio, setComunicadoEnvio] = useState();
  const [parametrosEnvio, setParametros] = useState();
  const [listaDependencias, setListaDependencias] = useState([]);
  const [gutterForm, setgutterForm] = useState(0);
  const [categorias, setCategorias] = useState([]);
  const [valoresSeleccionados, setValoresSeleccionados] = useState(null);
  const [archivoExcel, setArchivoExcel] = useState([]);

  const camposColaboradores = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
  const [respuestaCertificadoExpirado, setRespuestaCertificadoExpirado] = useAtom(
    atoms.respuestaCertificadoExpiradoAtom
  );
  const [mostrarAlerta, setMostrarAlerta] = useAtom(atoms.mostrarAlertaAtom);

  let history = useHistory();
  const { Text } = Typography;

  const propiedadesUploadDocumento = {
    onRemove: (file) => {
      setArchivoPdf([]);

      return {
        archivoPdf: [],
      };
    },
    beforeUpload: (file) => {
      if (file.type !== "application/pdf") {
        message.error(`${file.name} no es un archivo PDF`);
      } else {
        setArchivoPdf(file);
        return false;
      }
    },
    archivoPdf,
  };
  const propsCargaExcel = {
    onRemove: (file) => {
      setArchivoExcel([]);

      return {
        setArchivoExcel: [],
      };
    },
    beforeUpload: (file) => {
      if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        message.error(`${file.name} no es un archivo Excel`);
      } else {
        setArchivoExcel(file);
        return false;
      }
    },
    archivoExcel,
  };
  const [tipoDocumentoId, setTipoDocumentoId] = useState([]);
  const [tipoDocumentoNombre, setTipoDocumentoNombre] = useState([]);

  useEffect(() => {
    async function cargarDatosIniciales() {
      try {
        setTipoDocumentoSeleccionado("");
        const _tipoDocumento = await TipoDocumentoService.listarPorUsuario(getUsuarioAutenticado().login);
        if (_tipoDocumento !== null) {
          const _tipoDocumentoFormulario = _tipoDocumento.filter(
            (f) => f.categoriaDocumento?.id === CategoriaDocumento.COMUNICADOS.id
          );
          setTiposDocumento(_tipoDocumentoFormulario);
        } else {
          const tipoDocumentos = await TipoDocumentoService.listar(
            getUsuarioAutenticado().empresaSeleccionada.id,
            CategoriaDocumento.COMUNICADOS.id
          );
          setTiposDocumento(tipoDocumentos);
        }

        const usuario = await UsuarioService.buscarPorOrganizacionLogin(
          getUsuarioAutenticado().organizacionId,
          getUsuarioAutenticado().login
        );

        const empresaSeleccionada = usuario?.empresas.find(
          (empresa) => empresa.id === getUsuarioAutenticado().empresaSeleccionada.id
        );

        const _carpetas = await getListaCarpetasFiltro(empresaSeleccionada);
        //const _carpetas=await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);

        setListaCarpetasFiltro(_carpetas);

        if (tipoDocumentoSeleccionado !== "") {
          //console.log("doc selecc:", tipoDocumentoSeleccionado);
          frmCrearComunicado.setFieldsValue({
            tipoDocumento: tipoDocumentoSeleccionado,
          });
          const _tipoDocSeleccionado = tiposDocumento.filter((item) => item.id === tipoDocumentoSeleccionado);
          //console.log("TipoDocumento creado:",_tipoDocSeleccionado[0].id);
          setTipoDocumentoId(_tipoDocSeleccionado[0].id);
          setTipoDocumentoNombre(_tipoDocSeleccionado[0].nombre);
        }

        const browser = Bowser.parse(window.navigator.userAgent);
        //const res = await Axios.get("https://geolocation-db.com/json/");
        const parametros = {
          navegador: browser.browser.name,
          navegadorVersion: browser.browser.version,
          os: browser.os.name,
          osVersion: browser.os.versionName,
          //ipEnvio: res.data.IPv4,
        };
        setParametros(parametros);
      } catch (error) {
        console.error(error);
      }
    }

    cargarDatosIniciales();
  }, [tipoDocumentoSeleccionado]);

  const getListaCarpetasFiltro = async (empresaSeleccionada) => {
    const _carpetas = await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
    setCarpetas(_carpetas);
    if (!empresaSeleccionada?.filtroCarpeta?.length > 0) {
      return _carpetas;
    }

    var temp = [];
    const _temporal = empresaSeleccionada?.filtroCarpeta?.map((e) => {
      temp.push(_carpetas.find((c) => c.id == e.id));
    });

    return temp;
  };

  useEffect(() => {
    async function cargarDatosIniciales() {
      var _categorias = [];
      //carga categorias:
      var perfiles = (await PerfilService.listarPorUsuario(getUsuarioAutenticado().login)).map((perfil) =>
        getEntidad(perfil, CampoColaborador.PERFIL)
      );
      _categorias.push(...perfiles);

      var sedes = (await SedeService.listarPorUsuario(getUsuarioAutenticado().login)).map((sede) =>
        getEntidad(sede, CampoColaborador.SEDE)
      );
      _categorias.push(...sedes);

      var subsedes = (await SubsedeService.listarPorUsuario(getUsuarioAutenticado().login)).map((subsede) =>
        getEntidad(subsede, CampoColaborador.SUBSEDE)
      );
      _categorias.push(...subsedes);

      var secciones = (await SeccionService.listarPorUsuario(getUsuarioAutenticado().login)).map((seccion) =>
        getEntidad(seccion, CampoColaborador.SECCION)
      );
      _categorias.push(...secciones);

      var cargos = (await CargoService.listarPorUsuario(getUsuarioAutenticado().login)).map((cargo) =>
        getEntidad(cargo, CampoColaborador.CARGO)
      );
      _categorias.push(...cargos);

      var planillas = (await TipoPlanillaService.listarPorUsuario(getUsuarioAutenticado().login)).map((planilla) =>
        getEntidad(planilla, CampoColaborador.PLANILLA)
      );
      _categorias.push(...planillas);

      setCategorias(_categorias);
    }
    cargarDatosIniciales();
  }, []);

  useEffect(() => {
    if (ingresaCargo || ingresaSede) {
      const camposColaborador = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
      let campoSeleccionadoForm = ingresaSede
        ? CampoColaborador.SEDE
        : ingresaCargo
        ? CampoColaborador.CARGO
        : CampoColaborador.SINCAMPO;
      const campoCargo = camposColaborador.find((c) => c.campo === campoSeleccionadoForm);
      let dependencia = campoCargo.campoDependencia;
      let arrayDependencia = [];
      let busqueda = true;

      if (dependencia !== null) {
        arrayDependencia.push({ campo: campoCargo.campo, etiqueta: campoCargo.etiqueta, datos: [] });
        while (busqueda) {
          let campoActual = camposColaborador.find((c) => c.campo === dependencia);
          if (campoActual.campoDependencia === null) {
            arrayDependencia.push({
              campo: campoActual.campo,
              etiqueta: campoActual.etiqueta,
              datos: categorias
                .filter((categoria) => categoria.campo === campoActual.campo)
                .map((categoria) => {
                  var opcion = {
                    key: categoria.id,
                    id: categoria.id,
                    label: categoria.nombre,
                    value: categoria.id,
                    campo: campoActual.campo,
                    campodependencia: null,
                    iddependencia: null,
                  };
                  return opcion;
                }),
            });
            busqueda = false;
          } else {
            arrayDependencia.push({ campo: campoActual.campo, etiqueta: campoActual.etiqueta, datos: [] });
            dependencia = campoActual.campoDependencia;
          }
        }
        arrayDependencia.reverse();
      } else {
        arrayDependencia.push({
          campo: campoCargo.campo,
          etiqueta: campoCargo.etiqueta,
          datos: categorias
            .filter((categoria) => categoria.campo === campoCargo.campo)
            .map((categoria) => {
              var opcion = {
                key: categoria.id,
                id: categoria.id,
                label: categoria.nombre,
                value: categoria.id,
                campo: campoCargo.campo,
                campodependencia: null,
                iddependencia: null,
              };
              return opcion;
            }),
        });
      }
      setListaDependencias(arrayDependencia);
      for (let i = 0; i < arrayDependencia.length; i++) {
        frmCrearComunicado.setFieldsValue({ [arrayDependencia[i].campo]: null });
      }
      const size = Math.ceil((arrayDependencia.length + 1) / 2 - 1) * 50;
      setgutterForm(size);
    }
  }, [ingresaCargo, ingresaSede]);

  const getEntidad = (entidad, campo) => {
    const campoDependencia = camposColaboradores.find((c) => c.campo === campo).campoDependencia;
    var campo = {
      id: entidad.id,
      nombre: entidad.nombre,
      campo: campo,
      campoDependencia: undefined,
      idDependencia: undefined,
    };
    if (campoDependencia === CampoColaborador.PERFIL) {
      return { ...campo, campoDependencia: CampoColaborador.PERFIL, idDependencia: entidad.perfilId };
    } else if (campoDependencia === CampoColaborador.SEDE) {
      return { ...campo, campoDependencia: CampoColaborador.SEDE, idDependencia: entidad.sedeId };
    } else if (campoDependencia === CampoColaborador.SUBSEDE) {
      return { ...campo, campoDependencia: CampoColaborador.SUBSEDE, idDependencia: entidad.subsedeId };
    } else if (campoDependencia === CampoColaborador.SECCION) {
      return { ...campo, campoDependencia: CampoColaborador.SECCION, idDependencia: entidad.seccionId };
    } else if (campoDependencia === CampoColaborador.CARGO) {
      return { ...campo, campoDependencia: CampoColaborador.CARGO, idDependencia: entidad.cargoId };
    } else if (campoDependencia === CampoColaborador.PLANILLA) {
      return { ...campo, campoDependencia: CampoColaborador.PLANILLA, idDependencia: entidad.tipoPlanillaId };
    }
    return campo;
  };

  // useEffect(() => {
  //   setVisibleModalColaboradores(false);
  // }, [colaboradores]);

  // function handleOnClickAgregarColaboradores() {
  //   setVisibleModalColaboradores(true);
  // }
  async function onClickConfirmarNotificacion(formulario) {
    // if (!colaboradores.colaboradoresEncontrados.length > 0) {
    /* const respuesta = await CertificadoService.validarCertificadoExpiracion(getUsuarioAutenticado().empresaSeleccionada.id, getUsuarioAutenticado().id, tiposDocumento.id);
     setRespuestaCertificadoExpirado(respuesta.certificado);
     if (respuesta.certificado === EstadoCertificadoDigital.EXPIRADO) {
       setMostrarAlerta(true);
     }
     else if (respuesta.certificado === EstadoCertificadoDigital.NO_CERTIFICADO) {
       setMostrarAlerta(true);
     }
     else {*/
    if (valordestinatarioComunicado === "") {
      message.error("Seleccione destinatario");
    } else if (
      valordestinatarioComunicado === destinatarioComunicado.COLABORADOR &&
      colaboradoresAgregados.length === 0
    ) {
      message.error("No se encontraron colaboradores");
    } else if (
      valordestinatarioComunicado === destinatarioComunicado.TODOS_LOS_COLABORADORES &&
      valorEstadoColaborador === estadoColaborador.SINESTADO
    ) {
      message.error("No ha seleccionado el estado de los colaboradores");
    } else if (valordestinatarioComunicado === destinatarioComunicado.SEDE && sedesAgregadas.length === 0) {
      message.error("No se encontraron centros de costo");
    } else if (valordestinatarioComunicado === destinatarioComunicado.SEXO && valorEstadoSexo === undefined) {
      message.error("Debe seleccionar el sexo de los colaboradores a notificar.");
    } else if (valordestinatarioComunicado === destinatarioComunicado.CARGO && cargosAgregados.length === 0) {
      message.error("No se encontraron cargos");
    } else {
      const comunicado = {
        periodo: formulario.periodo.format("MM/YYYY"),
        organizacionId: getUsuarioAutenticado().organizacionId,
        empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
        categoriaDocumento: CategoriaDocumento.COMUNICADOS,
        // tipoDocumentoId: formulario.tipoDocumento.split("|")[0],
        // tipoDocumentoNombre: formulario.tipoDocumento.split("|")[1],
        destinatarioComunicado:
          valordestinatarioComunicado === destinatarioComunicado.TODOS_LOS_COLABORADORES
            ? "TODOS"
            : valordestinatarioComunicado,
        todosColaboradores: todosColaboradores,
        estadoColaborador: valorEstadoColaborador,
        sexoColaborador: valorEstadoSexo,
        tipoDocumentoId: tipoDocumentoId,
        descripcion: formulario.descripcion,
        tipoDocumentoNombre: tipoDocumentoNombre,
        colaboradoresId: colaboradoresAgregadosIds,
        sedesId: sedesAgregadasIds,
        cargosId: cargosAgregadosIds,
        evidencia: parametrosEnvio,
        //requiereFirmaColaborador: false,
        usuarioCreacion: {
          id: getUsuarioAutenticado().id,
          documentoIdentidad: getUsuarioAutenticado().numeroDocumentoIdentidad,
          nombreCompleto: getUsuarioAutenticado().nombreCompleto,
        },
        usuarioEnvio: {
          id: getUsuarioAutenticado().id,
          documentoIdentidad: getUsuarioAutenticado().numeroDocumentoIdentidad,
          nombreCompleto: getUsuarioAutenticado().nombreCompleto,
        },
        // colaboradores: colaboradores.colaboradoresEncontrados.map(
        //   (colaborador) => {
        //     return {
        //       numeroDocumentoIdentidad: colaborador.numeroDocumentoIdentidad,
        //       email: colaborador.correoElectronico,
        //     };
        //   }
        // ),
        carpeta: {
          id: formulario.carpeta.id,
          descripcion: carpetas.find((i) => i.id === formulario.carpeta.id)?.descripcion,
        },
      };
      const formData = new FormData();
      formData.append("file", formulario.documento.file);
      if (valordestinatarioComunicado === destinatarioComunicado.CARGA_EXCEL_DNIS) {
        formData.append("fileExcel", formulario.cargarArchivoExcel.file);
      }
      formData.append(
        "comunicado",
        new Blob([JSON.stringify(comunicado)], {
          type: "application/json",
        })
      );
      setComunicadoEnvio(formData);
      setVisibleModalConfirmacion(true);
      setTipoDocumentoSeleccionado("");
    }

    /* }*/
  }

  // const columnas = [
  //   {
  //     title: "Número Documento Identidad",
  //     dataIndex: "numeroDocumentoIdentidad",
  //     key: "numeroDocumentoIdentidad",
  //   },
  // ];
  // function onclickVerColaboradoresNoEncontrados() {
  //   Modal.info({
  //     title: "Colaboradores No Encontrados",
  //     //icon: <ExclamationCircleOutlined />,
  //     content: (
  //       <Table
  //         rowKey="numeroDocumentoIdentidad"
  //         dataSource={colaboradores.colaboradoresNoEncontrados}
  //         columns={columnas}
  //         pagination={false}
  //       />
  //     ),
  //   });
  // }

  // function ListaItemCargaColaboradores() {
  //   return (
  //     <div style={{ marginTop: "4px" }}>
  //       {colaboradores?.colaboradoresEncontrados?.length > 0 && (
  //         <span style={{ color: "green", display: "flex" }}>
  //           <Button
  //             type="link"
  //             icon={
  //               <CheckSquareTwoTone
  //                 style={{ fontSize: "16px" }}
  //                 twoToneColor="#52c41a"
  //               />
  //             }
  //             style={{ padding: "0px", color: "green" }}
  //           >
  //             {`Se cargaron ${colaboradores?.colaboradoresEncontrados?.length} colaboradores.`}
  //           </Button>
  //         </span>
  //       )}
  //       {colaboradores?.colaboradoresNoEncontrados?.length > 0 && (
  //         <span style={{ color: "#bd0707", display: "flex" }}>
  //           <Button
  //             type="link"
  //             icon={
  //               <CloseSquareTwoTone
  //                 style={{ fontSize: "16px" }}
  //                 twoToneColor="#bd0707"
  //               />
  //             }
  //             style={{ padding: "0px", color: "#bd0707" }}
  //             onClick={onclickVerColaboradoresNoEncontrados}
  //           >
  //             {`No se encontraron ${colaboradores?.colaboradoresNoEncontrados?.length} colaborador(es).`}
  //           </Button>
  //         </span>
  //       )}
  //     </div>
  //   );
  // }
  function onClickAgregarTipoDocumento() {
    setVisibleModalTiposDocumento(true);
  }
  function onChangeTipoDocumento(value, option) {
    // setTipoDocumentoId(value.split("|")[0]);
    // setTipoDocumentoNombre(value.split("|")[1]);
    setTipoDocumentoSeleccionado("");
    setTipoDocumentoId(value);
    setTipoDocumentoNombre(option.children);
    //console.log("option tipo Doc:",value);
  }

  function onChangeSelectCarpeta(value, option) {
    frmCrearComunicado.setFieldsValue({
      carpeta: { descripcion: option.descripcion },
    });
  }

  function onChangeDestinatario(value) {
    setgutterForm(0);
    if (value === destinatarioComunicado.TODOS_LOS_COLABORADORES) {
      setIngresaColaborador(false);
      setIngresaSede(false);
      setEstadosColaboradores(true);
      setValorDestinatarioComunicado(destinatarioComunicado.TODOS_LOS_COLABORADORES);
      setTodosColaboradores(true);
      setVisibleTabla(false);
      setVisibleTablaSede(false);
      setColaboradoresSeleccionados([]);
      setColaboradoresAgregados([]);
      setColaboradoresAgregadosIds([]);
      setSedes([]);
      setSedeSeleccionada([]);
      setSedesAgregadas([]);
      setSedesAgregadasIds([]);
      frmCrearComunicado.setFieldsValue({
        buscarColaborador: [],
      });
      frmCrearComunicado.setFieldsValue({
        buscarSede: [],
      });
      limpiarSeleccionSexo();
      limpiarSeleccionCargo();
      limpiarSeleccionCargaArchivoExcel();
    } else if (value === destinatarioComunicado.COLABORADOR) {
      setgutterForm(50);
      setIngresaColaborador(true);
      setEstadosColaboradores(false);
      setIngresaSede(false);
      setTodosColaboradores(false);
      setValorDestinatarioComunicado(destinatarioComunicado.COLABORADOR);
      setValorEstadoColaborador(estadoColaborador.SINESTADO);
      setSedes([]);
      setSedeSeleccionada([]);
      setSedesAgregadas([]);
      setSedesAgregadasIds([]);
      setVisibleTablaSede(false);
      frmCrearComunicado.setFieldsValue({
        buscarSede: [],
      });
      limpiarSeleccionSexo();
      limpiarSeleccionCargo();
      limpiarSeleccionCargaArchivoExcel();
    } else if (value === destinatarioComunicado.SEDE) {
      setIngresaSede(true);
      setEstadosColaboradores(false);
      setIngresaColaborador(false);
      setTodosColaboradores(false);
      setValorDestinatarioComunicado(destinatarioComunicado.SEDE);
      setValorEstadoColaborador(estadoColaborador.SINESTADO);
      setVisibleTabla(false);
      setColaboradoresSeleccionados([]);
      setColaboradoresAgregados([]);
      setColaboradoresAgregadosIds([]);
      frmCrearComunicado.setFieldsValue({
        buscarColaborador: [],
      });
      limpiarSeleccionSexo();
      handleOnClickSede();
      limpiarSeleccionCargo();
      limpiarSeleccionCargaArchivoExcel();
    } else if (value === destinatarioComunicado.SEXO) {
      setIngresaSexo(true);
      setValorDestinatarioComunicado(destinatarioComunicado.SEXO);
      limpiarSeleccionSede();
      limpiarSeleccionTodosColaboradores();
      limpiarSeleccionCargo();
      limpiarSeleccionCargaArchivoExcel();
    } else if (value === destinatarioComunicado.CARGO) {
      setIngresaCargo(true);
      setValorDestinatarioComunicado(destinatarioComunicado.CARGO);
      limpiarSeleccionTodosColaboradores();
      limpiarSeleccionSede();
      limpiarSeleccionSexo();
      handleOnClickCargo();
      limpiarSeleccionCargaArchivoExcel();
    } else if (value === destinatarioComunicado.PADRES) {
      setValorDestinatarioComunicado(destinatarioComunicado.PADRES);
      limpiarSeleccionTodosColaboradores();
      limpiarSeleccionCargo();
      limpiarSeleccionSede();
      limpiarSeleccionSexo();
      limpiarSeleccionCargaArchivoExcel();
    } else if (value === destinatarioComunicado.MADRES) {
      setValorDestinatarioComunicado(destinatarioComunicado.MADRES);
      limpiarSeleccionTodosColaboradores();
      limpiarSeleccionCargo();
      limpiarSeleccionSede();
      limpiarSeleccionSexo();
      limpiarSeleccionCargaArchivoExcel();
    } else if (value === destinatarioComunicado.CARGA_EXCEL_DNIS) {
      setValorDestinatarioComunicado(destinatarioComunicado.CARGA_EXCEL_DNIS);
      setCargaArchivoExcel(true);
      limpiarSeleccionTodosColaboradores();
      limpiarSeleccionCargo();
      limpiarSeleccionSede();
      limpiarSeleccionSexo();
    }
  }

  const limpiarSeleccionSexo = () => {
    setIngresaSexo(false);
    setValorEstadoSexo(undefined);
    frmCrearComunicado.setFieldsValue({ buscarSexo: [] });
  };
  const limpiarSeleccionCargo = () => {
    setIngresaCargo(false);
    setVisibleTablaCargo(false);
    setCargos([]);
    setCargoSeleccionado([]);
    setCargosAgregados([]);
    setCargosAgregadosIds([]);
    frmCrearComunicado.setFieldsValue({ buscarCargo: [] });
  };

  const limpiarSeleccionTodosColaboradores = () => {
    setEstadosColaboradores(false);
    setIngresaColaborador(false);
    setTodosColaboradores(false);
    setValorEstadoColaborador(estadoColaborador.SINESTADO);
    setColaboradoresSeleccionados([]);
    frmCrearComunicado.setFieldsValue({
      buscarColaborador: [],
    });
    setColaboradoresAgregados([]);
    setColaboradoresAgregadosIds([]);
    setVisibleTabla(false);
  };

  const limpiarSeleccionSede = () => {
    setIngresaSede(false);
    setSedes([]);
    setSedeSeleccionada([]);
    setSedesAgregadas([]);
    setSedesAgregadasIds([]);
    setVisibleTablaSede(false);
    frmCrearComunicado.setFieldsValue({
      buscarSede: [],
    });
  };
  const limpiarSeleccionCargaArchivoExcel = () => {
    setCargaArchivoExcel(false);
    setArchivoExcel([]);
  };

  async function handleOnClickSede() {
    const _respuesta = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
    //console.log(_respuesta);
    setSedes(_respuesta);
  }
  async function handleOnClickCargo() {
    const _respuesta = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
    setCargos(_respuesta);
  }

  async function onSearchColaboradores(value) {
    const _respuesta = await ComunicadoService.listarColaboradoresPorEmpresaConFiltro({
      empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
      filtro: value.trim(),
      login:getUsuarioAutenticado().login,
    });
    setColaboradores(_respuesta);
    if (value === "") {
      frmCrearComunicado.setFieldsValue({
        buscarColaborador: "",
      });
      setColaboradoresSeleccionados([]);
    }

    //console.log("cola:", _respuesta);
  }
  async function onSearchSedes(value) {
    if (value === "") {
      frmCrearComunicado.setFieldsValue({
        buscarSede: "",
      });
      setSedeSeleccionada([]);
    }
  }
  async function onSearchCargo(value) {
    if (value === "") {
      frmCrearComunicado.setFieldsValue({
        buscarCargo: "",
      });
      setCargoSeleccionado([]);
    }
  }

  function onChangeColaboradoresSeleccionados(value) {
    setColaboradoresSeleccionados(value);
    //console.log("colaboradores Selecc:", value);
  }
  const columnasColaborador = [
    {
      title: "Documento",
      dataIndex: "numeroDocumentoIdentidad",
      key: "documento",
    },
    {
      title: "Apellidos y Nombres",
      dataIndex: "nombreCompleto",
      key: "nombreCompleto",
    },
    {
      title: "Acciones",
      key: "acciones",
      render: (text, fila) => (
        <Button type="link" onClick={() => onClickQuitarColaborador(fila)}>
          Quitar
        </Button>
      ),
    },
  ];
  const columnasSede = [
    {
      title: "Centro de costo",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Acciones",
      key: "acciones",
      render: (text, fila) => (
        <Button type="link" onClick={() => onClickQuitarSede(fila)}>
          Quitar
        </Button>
      ),
    },
  ];

  const columnasCargo = [
    {
      title: "Cargo",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Acciones",
      key: "acciones",
      render: (text, fila) => (
        <Button type="link" onClick={() => onClickQuitarCargo(fila)}>
          Quitar
        </Button>
      ),
    },
  ];
  function onClickAgregarColaboradores() {
    //console.log("Colaboradores Seleccionados N: " + colaboradoresSeleccionados);
    if (colaboradoresSeleccionados.length !== 0) {
      const colaboradorBuscado = colaboradoresAgregados.find((item) => item.id === colaboradoresSeleccionados);
      if (colaboradorBuscado === undefined) {
        setColaboradoresAgregadosIds([...colaboradoresAgregadosIds, colaboradoresSeleccionados]);
        const _colaboradoresAgregados = colaboradores.find(
          (colaborador) => colaborador.id === colaboradoresSeleccionados
        );
        //console.log("_colaboradoresAgregados: ", _colaboradoresAgregados);
        setColaboradoresSeleccionados([]);
        setColaboradoresAgregados([...colaboradoresAgregados, _colaboradoresAgregados]);
        setVisibleTabla(true);
        frmCrearComunicado.setFieldsValue({
          buscarColaborador: [],
        });

        setColaboradores([]);
      } else {
        message.error("El colaborador ya ha sido agregado");
      }
    }
  }
  function onClikAgregarSedes() {
    if (sedeSeleccionada.length !== 0 && sedeSeleccionada !== null) {
      const sedeBuscada = sedesAgregadas.find((item) => item.id === sedeSeleccionada);
      if (sedeBuscada === undefined) {
        setSedesAgregadasIds([...sedesAgregadasIds, sedeSeleccionada]);
        const _sedeAgregada = sedes.find((sede) => sede.id === sedeSeleccionada);
        setSedesAgregadas([...sedesAgregadas, _sedeAgregada]);
        setSedeSeleccionada([]);
        setVisibleTablaSede(true);
        frmCrearComunicado.setFieldsValue({
          SEDE: undefined,
        });
        //setSedes([]) ;
      } else {
        message.error("La sede ya ha sido agregada.");
      }
    }
  }
  function onClikAgregarCargos() {
    if (cargoSeleccionado.length !== 0) {
      const cargoBuscado = cargosAgregados.find((item) => item.id === cargoSeleccionado);
      if (cargoBuscado === undefined) {
        setCargosAgregadosIds([...cargosAgregadosIds, cargoSeleccionado]);
        const _cargoAgregado = cargos.find((cargo) => cargo.id === cargoSeleccionado);
        setCargosAgregados([...cargosAgregados, _cargoAgregado]);
        setCargoSeleccionado([]);
        setVisibleTablaCargo(true);
        frmCrearComunicado.setFieldsValue({
          CARGO: undefined,
        });
      } else {
        message.error("El cargo ya ha sido agregado.");
      }
    }
  }
  function onClickQuitarColaborador(colaborador) {
    //console.log("ColaboradorEliminado:",colaborador.id);
    setColaboradoresAgregados(colaboradoresAgregados.filter((item) => item.id !== colaborador.id));
    setColaboradoresAgregadosIds(colaboradoresAgregadosIds.filter((item) => item !== colaborador.id));
  }
  function onClickQuitarSede(sede) {
    setSedesAgregadas(sedesAgregadas.filter((item) => item.id !== sede.id));
    setSedesAgregadasIds(sedesAgregadasIds.filter((item) => item !== sede.id));
  }
  function onClickQuitarCargo(cargo) {
    setCargosAgregados(cargosAgregados.filter((item) => item.id !== cargo.id));
    setCargosAgregadosIds(cargosAgregadosIds.filter((item) => item !== cargo.id));
  }
  function onChangeEstado(value) {
    if (value === estadoColaborador.ACTIVO) {
      setValorEstadoColaborador(estadoColaborador.ACTIVO);
    } else if (value === estadoColaborador.CESADO) {
      setValorEstadoColaborador(estadoColaborador.CESADO);
    }
  }

  const onChangeSelectSexo = (valor) => {
    if (valor === SexoColaborador.MASCULINO) {
      setValorEstadoSexo(SexoColaborador.MASCULINO);
    } else if (valor === SexoColaborador.FEMENINO) {
      setValorEstadoSexo(SexoColaborador.FEMENINO);
    }
  };

  const onChangeFiltros = (valor, item) => {
    if (valor != null) {
      if (item.campo == CampoColaborador.CARGO && ingresaCargo) {
        setCargoSeleccionado(valor);
      } else if (item.campo == CampoColaborador.SEDE && ingresaSede) {
        setSedeSeleccionada(valor);
      }
      const index = listaDependencias.findIndex((l) => l.campo == item.campo);
      if (index + 1 < listaDependencias.length) {
        setListaDependencias(
          produce((draft) => {
            draft[index + 1].datos = categorias
              .filter(
                (categoria) =>
                  categoria.campo === listaDependencias[index + 1].campo && categoria.idDependencia === valor
              )
              .map((categoria) => {
                var opcion = {
                  key: categoria.id,
                  id: categoria.id,
                  label: categoria.nombre,
                  value: categoria.id,
                  campo: listaDependencias[index + 1].campo,
                  campodependencia: categoria.campoDependencia,
                  iddependencia: valor,
                };
                return opcion;
              });
          })
        );
      }

      if (index < listaDependencias.length) {
        for (let i = index + 1; i < listaDependencias.length; i++) {
          if (i > index + 1) {
            setListaDependencias(
              produce((draft) => {
                draft[i].datos = [];
              })
            );
          }
          frmCrearComunicado.setFieldsValue({ [listaDependencias[i].campo]: null });
        }
      }
    } else {
      const index = listaDependencias.findIndex((l) => l.campo == item);
      if (item == CampoColaborador.CARGO && ingresaCargo) {
        setCargoSeleccionado([]);
      } else if (item == CampoColaborador.SEDE && ingresaSede) {
        setSedeSeleccionada([]);
      }
      if (index < listaDependencias.length) {
        for (let i = index; i < listaDependencias.length; i++) {
          if (i > index) {
            setListaDependencias(
              produce((draft) => {
                draft[i].datos = [];
              })
            );
          }
          frmCrearComunicado.setFieldsValue({ [listaDependencias[i].campo]: null });
        }
      }
    }
  };

  function onChangeSede(value) {
    //setSedeSeleccionado([...sedesSeleccionados,value]);
    setSedeSeleccionada(value);
  }
  function onChangeCargo(value) {
    setCargoSeleccionado(value);
  }
  return (
    <Container titulo="Crear Comunicado">
      <AlertCertificadoDigital />
      <Form
        layout="vertical"
        labelCol={{ span: 10, offset: 8 }}
        wrapperCol={{ span: 10, offset: 8 }}
        onFinish={onClickConfirmarNotificacion}
        form={frmCrearComunicado}
      >
        <Form.Item label="Periodo" name="periodo" rules={[{ required: true, message: "Seleccione el periodo" }]}>
          <DatePicker picker="month" format="MM/YYYY" style={{ width: "50%" }} />
        </Form.Item>

        <Form.Item
          label="Tipo Documento"
          name="_tipoDocumento"
          rules={[
            {
              required: true,
              validator: (_, value) =>
                tipoDocumentoId.length !== 0
                  ? Promise.resolve()
                  : Promise.reject(new Error("Seleccione el Tipo Documento")),
            },
          ]}
        >
          <Row gutter={16} style={{ height: " 4vh " }}>
            <Col span={21}>
              <Form.Item name="tipoDocumento">
                <Select
                  showSearch
                  placeholder="Seleccionar el Tipo de Documento"
                  onChange={onChangeTipoDocumento}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  style={{ width: "100%" }}
                >
                  {tiposDocumento.length > 0 &&
                    tiposDocumento.map((tipoDocumento) => {
                      return (
                        <Option key={tipoDocumento.id} value={tipoDocumento.id}>
                          {tipoDocumento.nombre}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item>
                <Button type="primary" shape="circle-outline" onClick={onClickAgregarTipoDocumento}>
                  <PlusOutlined />
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="Descripción" name="descripcion">
          <Input placeholder="Ingrese una descripción" autoComplete="off" maxLength="50" style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item
          label="Documento"
          name="documento"
          //valuePropName="documento"
          rules={[
            {
              required: true,
              validator: (_, value) =>
                archivoPdf.length !== 0 ? Promise.resolve() : Promise.reject(new Error("Seleccione el Documento")),
            },
          ]}
        >
          <Upload {...propiedadesUploadDocumento} maxCount={1}>
            <Button icon={<UploadOutlined />} block style={{ whiteSpace: "normal", width: "100%" }}>
              Subir Documento PDF&nbsp;
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item
          label="Notificar a"
          name="notificarA"
          // rules={[{ required:true,
          //   validator: (_, value) =>
          //    ( (valordestinatarioComunicado === destinatarioComunicado.SEDE && sedesAgregadasIds.length > 0) ||
          //      (colaboradoresAgregadosIds.length !== 0 && valordestinatarioComunicado=== destinatarioComunicado.COLABORADOR)||
          //      ( valordestinatarioComunicado === destinatarioComunicado.TODOS_LOS_COLABORADORES && valorEstadoColaborador !== estadoColaborador.SINESTADO)
          //     ) ?
          //         Promise.resolve() :
          //         Promise.reject(new Error('Seleccione el Destinatario')), }]}
        >
          <Row gutter={2} style={{ height: "4vh" }}>
            <Col
              span={
                frmCrearComunicado.getFieldValue("destinatarioComunicado") == destinatarioComunicado.COLABORADOR ||
                //frmCrearComunicado.getFieldValue("destinatarioComunicado")==destinatarioComunicado.SEDE?5:8}>
                frmCrearComunicado.getFieldValue("destinatarioComunicado") == destinatarioComunicado.SEDE ||
                frmCrearComunicado.getFieldValue("destinatarioComunicado") == destinatarioComunicado.CARGO
                  ? 11
                  : 15
              }
            >
              <Form.Item name="destinatarioComunicado">
                <Select placeholder="Seleccionar destinatario" onChange={onChangeDestinatario} style={{ width: "98%" }}>
                  <Option value={destinatarioComunicado.TODOS_LOS_COLABORADORES} key="1">
                    TODOS LOS COLABORADORES
                  </Option>
                  <Option value={destinatarioComunicado.COLABORADOR} key="2">
                    {destinatarioComunicado.COLABORADOR}
                  </Option>
                  <Option value={destinatarioComunicado.SEDE} key="3">
                    CENTRO DE COSTO
                  </Option>
                  <Option value={destinatarioComunicado.SEXO} key="4">
                    {destinatarioComunicado.SEXO}
                  </Option>
                  <Option value={destinatarioComunicado.CARGO} key="5">
                    {destinatarioComunicado.CARGO}
                  </Option>
                  <Option value={destinatarioComunicado.PADRES} key="6">
                    {destinatarioComunicado.PADRES}
                  </Option>
                  <Option value={destinatarioComunicado.MADRES} key="7">
                    {destinatarioComunicado.MADRES}
                  </Option>
                  <Option value={destinatarioComunicado.CARGA_EXCEL_DNIS} key="8">
                    COLABORADORES ARCHIVO EXCEL
                  </Option>
                </Select>
              </Form.Item>
            </Col>

            {estadosColaboradores && (
              <Col span={9}>
                <Form.Item>
                  <Select placeholder="Seleccionar estado" onChange={onChangeEstado}>
                    <Option value={estadoColaborador.ACTIVO}>{estadoColaborador.ACTIVO}</Option>
                    <Option value={estadoColaborador.CESADO}>{estadoColaborador.CESADO}</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}

            {ingresaSexo && (
              <Col span={9}>
                <Form.Item name="buscarSexo">
                  <Select placeholder="Seleccionar sexo" onChange={onChangeSelectSexo}>
                    <Option value={SexoColaborador.MASCULINO}>{SexoColaborador.MASCULINO}</Option>
                    <Option value={SexoColaborador.FEMENINO}>{SexoColaborador.FEMENINO}</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}

            {ingresaSede &&
              listaDependencias.map((campo) => {
                return (
                  <Col span={11}>
                    <Form.Item key={campo.campo + 1} name={campo.campo}>
                      <Select
                        key={campo.campo + 2}
                        placeholder={"Seleccione " + campo.etiqueta}
                        onSelect={onChangeFiltros}
                        allowClear
                        onClear={() => onChangeFiltros(null, campo.campo)}
                        options={campo.datos}
                        value={valoresSeleccionados} /*onSelect={onSelect} options={options} onDeselect={onDeselect}*/
                      />
                    </Form.Item>
                  </Col>
                );
              })}
            {/* {ingresaSede && (
              //<Col span={6}>
              <Col span={12}>
                <Form.Item name="buscarSede">
                  <Select
                    placeholder="Ingrese Centro de costo"
                    showSearch
                    onChange={onChangeSede}
                    onSearch={onSearchSedes}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {sedes.map((sede) => (
                      <Option key={sede.id} value={sede.id}>
                        {sede.nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )} */}

            {ingresaCargo &&
              listaDependencias.map((campo) => {
                return (
                  <Col span={11}>
                    <Form.Item key={campo.campo + 1} name={campo.campo}>
                      <Select
                        key={campo.campo + 2}
                        placeholder={"Seleccione " + campo.etiqueta}
                        onSelect={onChangeFiltros}
                        allowClear
                        onClear={() => onChangeFiltros(null, campo.campo)}
                        options={campo.datos}
                        value={valoresSeleccionados} /*onSelect={onSelect} options={options} onDeselect={onDeselect}*/
                      />
                    </Form.Item>
                  </Col>
                );
              })}
            {/* {ingresaCargo && (
              
              <Col span={12}>
                <Form.Item name="buscarCargo">
                  <Select
                    placeholder="Ingrese Cargo"
                    showSearch
                    onChange={onChangeCargo}
                    onSearch={onSearchCargo}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cargos.map((cargo) => (
                      <Option key={cargo.id} value={cargo.id}>
                        {cargo.nombre}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )} */}
            {ingresaColaborador && (
              <Col span={12}>
                <Form.Item name="buscarColaborador">
                  <Select
                    placeholder="Ingrese los colaboradores"
                    //mode="multiple"
                    showSearch
                    onSearch={onSearchColaboradores}
                    //style={{width:"100%"}}
                    onChange={onChangeColaboradoresSeleccionados}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {colaboradores !== undefined &&
                      colaboradores.map((colaborador) => {
                        return (
                          <Select.Option key={colaborador.id} value={colaborador.id}>
                            {colaborador.nombreCompleto}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {ingresaColaborador && (
              // <Col span={3}>
              <Col span={2}>
                <Form.Item>
                  <Button onClick={onClickAgregarColaboradores}>Agregar</Button>
                </Form.Item>
              </Col>
            )}
            {ingresaSede && (
              <Col span={2}>
                <Form.Item>
                  <Button onClick={onClikAgregarSedes}>Agregar</Button>
                </Form.Item>
              </Col>
            )}
            {ingresaCargo && (
              <Col span={2}>
                <Form.Item>
                  <Button onClick={onClikAgregarCargos}>Agregar</Button>
                </Form.Item>
              </Col>
            )}
            {cargaArchivoExcel && (
              <Col span={9}>
                <Form.Item
                  name="cargarArchivoExcel"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) =>
                        archivoExcel.length !== 0
                          ? Promise.resolve()
                          : Promise.reject(new Error("Seleccione el Archivo Excel")),
                    },
                  ]}
                >
                  <Upload {...propsCargaExcel} maxCount={1} accept={FormatoCarga.EXCEL.extension}>
                    <Button icon={<UploadOutlined />} block style={{ whiteSpace: "normal", width: "100%" }}>
                      Cargar Archivo Excel&nbsp;
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form.Item>
        {(valorEstadoColaborador === estadoColaborador.CESADO ||
          valorEstadoColaborador === estadoColaborador.ACTIVO) && (
          <React.Fragment>
            <div>
              {/* <Col span={7} offset={9}>   */}
              <Col span={10} offset={8}>
                <Text>
                  {valorEstadoColaborador === estadoColaborador.ACTIVO && (
                    <span
                      style={{
                        border: "1px solid #91d5ff",
                        backgroundColor: "#e6f7ff",
                        padding: "5px 10px 5px 10px",
                        borderRadius: "8px",
                      }}
                    >
                      Se notificará a todos los colaboradores<b> ACTIVOS</b> de la empresa
                    </span>
                  )}
                  {valorEstadoColaborador === estadoColaborador.CESADO && (
                    <span
                      style={{
                        border: "1px solid #91d5ff",
                        backgroundColor: "#e6f7ff",
                        padding: "5px 10px 5px 10px",
                        borderRadius: "8px",
                      }}
                    >
                      Se notificará a todos los colaboradores<b> CESADOS</b> de la empresa
                    </span>
                  )}
                </Text>
              </Col>
            </div>
            <br />
          </React.Fragment>
        )}

        {valorEstadoSexo && (
          <React.Fragment>
            <div>
              <Col span={10} offset={8}>
                <Text>
                  {valorEstadoSexo === SexoColaborador.MASCULINO && (
                    <span
                      style={{
                        border: "1px solid #91d5ff",
                        backgroundColor: "#e6f7ff",
                        padding: "5px 10px 5px 10px",
                        borderRadius: "8px",
                      }}
                    >
                      Se notificará a todos los colaboradores con sexo <b>MASCULINO</b>
                    </span>
                  )}
                  {valorEstadoSexo === SexoColaborador.FEMENINO && (
                    <span
                      style={{
                        border: "1px solid #91d5ff",
                        backgroundColor: "#e6f7ff",
                        padding: "5px 10px 5px 10px",
                        borderRadius: "8px",
                      }}
                    >
                      Se notificará a todos los colaboradores con sexo <b>FEMENINO</b>
                    </span>
                  )}
                </Text>
              </Col>
            </div>
            <br />
          </React.Fragment>
        )}

        {(visibleTablaCargo || visibleTablaSede || visibleTabla) && (
          <Row gutter={[, gutterForm]}>
            <Col span={24} />
            <Col span={24} />
          </Row>
        )}

        {visibleTabla && (
          <Form.Item wrapperCol={{ span: 10, offset: 8 }}>
            <Table
              rowKey={(colaboradorAgregado) => colaboradorAgregado.id}
              columns={columnasColaborador}
              dataSource={colaboradoresAgregados}
              pagination={false}
            />
          </Form.Item>
        )}
        {visibleTablaSede && (
          <Form.Item wrapperCol={{ span: 10, offset: 8 }}>
            <Table
              rowKey={(sedeAgregada) => sedeAgregada.id}
              columns={columnasSede}
              dataSource={sedesAgregadas}
              pagination={false}
            />
          </Form.Item>
        )}
        {visibleTablaCargo && (
          <Form.Item wrapperCol={{ span: 10, offset: 8 }}>
            <Table
              rowKey={(cargoAgregado) => cargoAgregado.id}
              columns={columnasCargo}
              dataSource={cargosAgregados}
              pagination={false}
            />
          </Form.Item>
        )}
        {!visibleTablaCargo && !visibleTablaSede && !visibleTabla && (
          <Row gutter={[, gutterForm]}>
            <Col span={24} />
            <Col span={24} />
          </Row>
        )}

        <Form.Item label="Carpeta" name={["carpeta", "id"]}>
          <Select
            //onChange={onChangeSelectCarpeta}
            optionLabelProp="label"
            placeholder="Seleccionar la carpeta"
            style={{ width: "100%" }}
            allowClear
          >
            {carpetas.length > 0 &&
              carpetas.map((carpeta) => {
                return (
                  <Option
                    key={carpeta.id}
                    disabled={listaCarpetasFiltro.filter((item) => item.id == carpeta.id).length == 0}
                    value={carpeta.id}
                    label={
                      <div>
                        <FolderTwoTone style={{ fontSize: "20px", color: "#F7D358" }} twoToneColor="#F7D358" />{" "}
                        {carpeta.ruta}
                      </div>
                    }
                    descripcion={carpeta.descripcion}
                  >
                    <Space size={carpeta.nivel * 10}>
                      <span></span>
                      <span>
                        {carpeta.nivel === 0 ? (
                          <FolderFilled style={{ fontSize: "20px", color: "#F7D358" }} />
                        ) : (
                          <FolderTwoTone style={{ fontSize: "18px", color: "#F7D358" }} twoToneColor="#F7D358" />
                        )}{" "}
                        {carpeta.descripcion}
                      </span>
                    </Space>
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item name={["carpeta", "descripcion"]} noStyle>
          <Input type="hidden" />
        </Form.Item>

        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            block
            // style={{ width: "73%" }}
            style={{ paddingLeft: "30px", paddingRight: "30px" }}
            loading={loading}
          >
            Notificar
          </Button>
        </Form.Item>
      </Form>

      {/* <ModalAgregarColaboradores
        visible={visibleModalColaboradores}
        setVisible={setVisibleModalColaboradores}
        setColaboradores={setColaboradores}
      /> */}
      <ModalAgregarTipoDocumento
        visible={visibleModalTiposDocumento}
        setVisible={setVisibleModalTiposDocumento}
        setTipoDocumentos={setTiposDocumento}
        //setTipoDocumentoSeleccionado={setTipoDocumentoSeleccionado}
      />
      <ModalConfirmacion
        tipoEnvio={valordestinatarioComunicado}
        comunicado={comunicadoEnvio}
        descripcion={frmCrearComunicado.getFieldValue("descripcion")}
        responsabelEnvio={getUsuarioAutenticado().nombreCompleto}
        tipoDocumento={tipoDocumentoNombre}
      />
    </Container>
  );
}
