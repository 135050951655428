import React, { useState } from "react";
import { Button, Form, Input, Table, Col, Row, Select, notification, message } from "antd";
import { visibleDrawerCampoAtom, camposAtom } from "../../CrearPerfilSolicitudPersonalStore";
import { useAtom } from "jotai";
import { useListarCargo } from "../../hooks";
//import PerfilSolicitudPersonalService from "services/PerfilSolicitudPersonalService";
import PerfilService from "services/reclutamiento-y-seleccion/PerfilService";
import { useHistory } from "react-router-dom";

const PerfilRegistro = () => {
  const [, setVisibleDrawerCampos] = useAtom(visibleDrawerCampoAtom);
  const [campos, setCampos] = useAtom(camposAtom);
  const { cargos } = useListarCargo();
  const [puestoId, setPuestoId] = useState(undefined);
  let history = useHistory();

  const onClickAgregar = () => {
    setVisibleDrawerCampos(true);
  };
  const onClickQuitarCampo = (campo) => {
    setCampos(campos.filter((item) => item.key !== campo.key));
  };
  const columnasCamposPersonalizados = [
    {
      title: "Campo",
      dataIndex: "key",
      align: "center",
    },
    {
      title: "Etiqueta",
      dataIndex: "label",
      align: "center",
    },
    {
      title: "Tipo",
      dataIndex: "type",
      align: "center",
    },
    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      render: (fila) => (
        <React.Fragment>
          {/* <Button onClick={() => onClickEditarCampo(fila)} type="link">
                Editar
              </Button>*/}
          <Button onClick={() => onClickQuitarCampo(fila)} type="link">
            Quitar
          </Button>
        </React.Fragment>
      ),
    },
  ];
  const onFinishCrearPerfil = async (formulario) => {
    try {
      // if (campos.length > 0) {
      formulario.campos = campos;
      console.log("datos: ", formulario);
      await PerfilService.insertar(formulario);
      notification.success({
        message: "Operación exitosa",
        description: "Se registró el perfil correctamente",
      });
      history.push("/reclutamiento-y-seleccion/solicitud-personal/perfiles");
      // } else {
      //   message.error("Debe agregar al menos un campo");
      // }
    } catch (error) {
      notification.error({
        message: "No se puede registrar el Perfil",
        description: error.response.data.mensaje,
      });
    }
  };
  const onChangeCargo = (value, option) => {
    setPuestoId(option.key);
  };
  return (
    <Form
      layout="vertical"
      labelCol={{ span: 4, offset: 8 }}
      wrapperCol={{ span: 8, offset: 8 }}
      onFinish={onFinishCrearPerfil}
    >
      <Form.Item
        name="nombre"
        label="Nombre"
        rules={[
          {
            required: true,
            message: "Debe ingresar el nombre.",
          },
        ]}
      >
        <Input />
      </Form.Item>

      {/* <Form.Item 
           name="puesto" 
           label="Puesto" 
           rules={[
            {
                required: true,
                message: "Debe ingresar el puesto.",
            },
        ]}   
           >
            
             <Select
                placeholder="Seleccionar puesto"
                onChange={onChangeCargo}
             >
             {cargos && cargos.length>0 &&
               cargos.map((cargo)=>(
                 <Select.Option key={cargo.id} value={cargo.nombre}>
                     {cargo.nombre}
                 </Select.Option>
                 )
               )
             }     
             </Select>
            
        </Form.Item> */}
      <Form.Item
        name="diasAnticipacionSolicitud"
        label="Días de anticipación a la solicitud"
        rules={[
          {
            required: true,
            message: "Debe ingresar los días.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="campos" label="Campos">
        <Button onClick={onClickAgregar}>Agregar </Button>
        <Table
          rowKey={(campo) => campo.key}
          columns={columnasCamposPersonalizados}
          dataSource={campos}
          pagination={false}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" block>
          Crear Perfil
        </Button>
      </Form.Item>
    </Form>
  );
};
export default PerfilRegistro;
