import React, { useState, useEffect } from "react";

import { ContainerCrear } from "../../../components/ContainerCrear";
import { Form, Input, Row, Col, Button, Space, notification, Select } from "antd";

import { CheckOutlined } from "@ant-design/icons";
import CargoService from "../../../services/CargoService";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { CampoColaborador } from "enums/CampoColaborador";
import SubsedeService from "services/SubsedeService";
import PerfilService from "services/PerfilService";
import SedeService from "services/SedeService";
import SeccionService from "services/SeccionService";
import TipoPlanillaService from "services/TipoPlanillaService";

export function CargoCrearPage() {
  const [loading, setLoading] = useState(false);
  const { getUsuarioAutenticado } = useContext(SecurityContext);

  const [dependenciaCargo, setDependenciaCargo] = useState();
  const [listaCampos, setListaCampos] = useState([]);
  const [perfiles, setPerfiles] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [subsedes, setSubsedes] = useState([]);
  const [tipoPlanillas, setTipoPlanillas] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [camposColaborador, setCamposColaborador] = useState([]);

  const { Option } = Select;
  const [formCargo] = Form.useForm();
  const isExplomin =
          getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837"
            ? true
            : false;

  let history = useHistory();
  useEffect(() => {
    const cargarDatosIniciales = async () => {
      var listaCampos = [],
        dependencia;
      const empresa = getUsuarioAutenticado().empresaSeleccionada.id;
      const campos_colaborador = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
      setCamposColaborador(campos_colaborador);
      const campo_cargo = campos_colaborador.find(
        (campoColaborador) => campoColaborador.campo === CampoColaborador.CARGO
      );

      const cargoDependencia = campo_cargo.campoDependencia;

      if (cargoDependencia !== null) {
        setDependenciaCargo(cargoDependencia);
        dependencia = cargoDependencia;
        listaCampos.push(dependencia);

        while (dependencia !== null) {
          var campo = campos_colaborador.find((campoColaborador) => campoColaborador.campo === dependencia);
          dependencia = campo.campoDependencia;
          if (dependencia !== null) {
            listaCampos.push(dependencia);
          }
        }
        setListaCampos(listaCampos.reverse());
      } else {
        setListaCampos(listaCampos);
        setDependenciaCargo(CampoColaborador.SINCAMPO);
      }
      if (listaCampos.length > 0) {
        const primerCampo = listaCampos[0];
        if (primerCampo === CampoColaborador.PERFIL) {
          const perfiles = await PerfilService.listar(empresa);
          setPerfiles(perfiles);
        } else if (primerCampo === CampoColaborador.SECCION) {
          const secciones = await SeccionService.listar(empresa);
          setSecciones(secciones);
        } else if (primerCampo === CampoColaborador.SUBSEDE) {
          const subsedes = await SubsedeService.listar(empresa);
          setSubsedes(subsedes);
        } else if (primerCampo === CampoColaborador.PLANILLA) {
          const planillas = await TipoPlanillaService.listar(empresa);
          setTipoPlanillas(planillas);
        } else if (primerCampo === CampoColaborador.SEDE) {
          const sedes = await SedeService.listar(empresa);
          setSedes(sedes);
        }
      }
    };

    cargarDatosIniciales();
  }, []);

  async function handleOnFinish(formulario) {
    try {
      setLoading(true);
      formulario.empresaId = getUsuarioAutenticado().empresaSeleccionada.id;
      formulario.dependencia = dependenciaCargo;
      await CargoService.insertar(formulario);
      notification.success({
        message: "Operación exitosa",
        description: "Se registró el cargo correctamente.",
      });
      setLoading(false);
      history.push("/cargo");
    } catch (error) {
      console.error(error);
      notification.error({
        message: "No se puede registrar el Cargo",
        description: error.response.data.mensaje,
      });
      setLoading(false);
    }
  }

  function handleOnClickCancelar() {
    history.push("/cargo");
  }
  const handleOnchangePerfil = async (value, option) => {
    let listaCampos = camposColaborador.filter(
      (campoColaborador) => campoColaborador.campoDependencia === CampoColaborador.PERFIL
    );

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.SECCION) {
        const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _secciones = secciones.filter((seccion) => seccion.perfilId === value);
        setSecciones(_secciones);
        formCargo.setFieldsValue({ seccionId: "" });
      } else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter((subsede) => subsede.perfilId === value);
        setSubsedes(_subsedes);
        formCargo.setFieldsValue({ subsedeId: "" });
      } else if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter((sede) => sede.perfilId === value);
        setSedes(_sedes);
        formCargo.setFieldsValue({ sedeId: "" });
      } else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanilla = tipoPlanilla.filter((planilla) => planilla.perfilId === value);
        setTipoPlanillas(_tipoPlanilla);
        formCargo.setFieldsValue({ tipoPlanillaId: "" });
      }
    }
  };
  const handleOnchangeSeccion = async (value, option) => {
    let listaCampos = camposColaborador.filter(
      (campoColaborador) => campoColaborador.campoDependencia === CampoColaborador.SECCION
    );

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter((perfil) => perfil.seccionId === value);
        setPerfiles(_perfiles);
        formCargo.setFieldsValue({ perfilId: "" });
      } else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter((subsede) => subsede.seccionId === value);
        setSubsedes(_subsedes);
        formCargo.setFieldsValue({ subsedeId: "" });
      } else if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter((sede) => sede.seccionId === value);
        setSedes(_sedes);
        formCargo.setFieldsValue({ sedeId: "" });
      } else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanilla = tipoPlanilla.filter((planilla) => planilla.seccionId === value);
        setTipoPlanillas(_tipoPlanilla);
        formCargo.setFieldsValue({ tipoPlanillaId: "" });
      }
    }
  };
  const handleOnchangeSubsede = async (value, option) => {
    let listaCampos = camposColaborador.filter(
      (campoColaborador) => campoColaborador.campoDependencia === CampoColaborador.SUBSEDE
    );

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter((perfil) => perfil.subsedeId === value);
        setPerfiles(_perfiles);
        formCargo.setFieldsValue({ perfilId: "" });
      } else if (listaCampos[i].campo === CampoColaborador.SECCION) {
        const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _secciones = secciones.filter((seccion) => seccion.subsedeId === value);
        setSecciones(_secciones);
        formCargo.setFieldsValue({ seccionId: "" });
      } else if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter((sede) => sede.subsedeId === value);
        setSedes(_sedes);
        formCargo.setFieldsValue({ sedeId: "" });
      } else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanilla = tipoPlanilla.filter((planilla) => planilla.subsedeId === value);
        setTipoPlanillas(_tipoPlanilla);
        formCargo.setFieldsValue({ tipoPlanillaId: "" });
      }
    }
  };
  const handleOnchangeTipoPlanilla = async (value, option) => {
    let listaCampos = camposColaborador.filter(
      (campoColaborador) => campoColaborador.campoDependencia === CampoColaborador.PLANILLA
    );

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter((perfil) => perfil.tipoPlanillaId === value);
        setPerfiles(_perfiles);
        formCargo.setFieldsValue({ perfilId: "" });
      } else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter((subsede) => subsede.tipoPlanillaId === value);
        setSubsedes(_subsedes);
        formCargo.setFieldsValue({ subsedeId: "" });
      } else if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter((sede) => sede.tipoPlanillaId === value);
        setSedes(_sedes);
        formCargo.setFieldsValue({ sedeId: "" });
      } else if (listaCampos[i].campo === CampoColaborador.SECCION) {
        const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _secciones = secciones.filter((seccion) => seccion.tipoPlanillaId === value);
        setSecciones(_secciones);
        formCargo.setFieldsValue({ seccionId: "" });
      }
    }
  };
  const handleOnchangeSede = async (value, option) => {
    let listaCampos = camposColaborador.filter(
      (campoColaborador) => campoColaborador.campoDependencia === CampoColaborador.SEDE
    );

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter((perfil) => perfil.sedeId === value);
        setPerfiles(_perfiles);
        formCargo.setFieldsValue({ perfilId: "" });
      } else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter((subsede) => subsede.sedeId === value);
        setSubsedes(_subsedes);
        formCargo.setFieldsValue({ subsedeId: "" });
      } else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanillas = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanillas = tipoPlanillas.filter((planilla) => planilla.sedeId === value);
        setTipoPlanillas(_tipoPlanillas);
        formCargo.setFieldsValue({ tipoPlanillaId: "" });
      } else if (listaCampos[i].campo === CampoColaborador.SECCION) {
        const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _secciones = secciones.filter((seccion) => seccion.sedeId === value);
        setSecciones(_secciones);
        formCargo.setFieldsValue({ seccionId: "" });
      }
    }
  };

  return (
    <ContainerCrear
      titulo="Crear Cargo"
      descripcion="Formulario para crear los cargos de tus colaboradores para tu organización."
      colSpan={12}
    >
      <Form layout="vertical" size="large" onFinish={handleOnFinish} form={formCargo}>
        {listaCampos.length > 0 &&
          listaCampos.map((listaCampo) => {
            if (listaCampo === CampoColaborador.PERFIL) {
              return (
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Perfil"
                      name="perfilId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Perfil",
                        },
                      ]}
                    >
                      <Select placeholder="Seleccione Perfil" onChange={handleOnchangePerfil}>
                        {perfiles.length > 0 &&
                          perfiles.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              );
            } else if (listaCampo === CampoColaborador.SECCION) {
              return (
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Sección"
                      name="seccionId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione sección",
                        },
                      ]}
                    >
                      <Select placeholder="Seleccione sección" onChange={handleOnchangeSeccion}>
                        {secciones.length > 0 &&
                          secciones.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              );
            } else if (listaCampo === CampoColaborador.SUBSEDE) {
              return (
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Subcentro de Costo"
                      name="subsedeId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Subcentro de Costo",
                        },
                      ]}
                    >
                      <Select placeholder="Seleccione Subcentro de Costo" onChange={handleOnchangeSubsede}>
                        {subsedes.length > 0 &&
                          subsedes.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              );
            } else if (listaCampo === CampoColaborador.PLANILLA) {
              return (
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Tipo planilla"
                      name="tipoPlanillaId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Tipo planilla",
                        },
                      ]}
                    >
                      <Select placeholder="Seleccione Tipo planilla" onChange={handleOnchangeTipoPlanilla}>
                        {tipoPlanillas.length > 0 &&
                          tipoPlanillas.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              );
            } else if (listaCampo === CampoColaborador.SEDE) {
              return (
                <Row>
                  <Col span={24}>
                    <Form.Item
                      label="Centro de Costo"
                      name="sedeId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Centro de Costo",
                        },
                      ]}
                    >
                      <Select placeholder="Seleccione Centro de Costo" onChange={handleOnchangeSede}>
                        {sedes.length > 0 &&
                          sedes.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              );
            }
          })}

        <Row>
          <Col span={24}>
            <Form.Item
              label="Nombre"
              name="nombre"
              rules={[
                {
                  required: true,
                  message: "Escribe el nombre del cargo",
                },
              ]}
            >
              <Input placeholder="Nombre del cargo" autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>
        {isExplomin &&(
            <Row>
            <Col span={11}>
              <Form.Item
                label="Rango Mínimo"
                name="sueldoMinimo"
                rules={[
                  {
                    required: true,
                    message: "Escribe el rango mínimo",
                  },
                ]}
              >
                <Input placeholder="Rango mínimo" autoComplete="off" />
              </Form.Item>
            </Col>
            <Col span={2}></Col>
            <Col span={11}>
              <Form.Item
                label="Rango Máximo"
                name="sueldoMaximo"
                rules={[
                  {
                    required: true,
                    message: "Escribe el rango máximo",
                  },
                ]}
              >
                <Input placeholder="Rango máximo" autoComplete="off" />
              </Form.Item>
            </Col>
          </Row>

        )}
        <Row justify="center">
          <Space>
            <Col>
              <Form.Item style={{ textAlign: "center" }}>
                <Button type="default" onClick={handleOnClickCancelar}>
                  Cancelar
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ textAlign: "center" }}>
                <Button type="primary" icon={<CheckOutlined />} loading={loading} htmlType="submit">
                  Crear Cargo
                </Button>
              </Form.Item>
            </Col>
          </Space>
        </Row>
      </Form>
    </ContainerCrear>
  );
}
