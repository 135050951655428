import React, { useContext } from "react";

import { Layout, Select } from "antd";
import { MenuPrincipal } from "./components/MenuPrincipal";
import { MenuPerfil } from "./components/MenuPerfil";
import { SecurityContext } from "../../context/SecurityContextProvider";
import { useState } from "react";
import { useEffect } from "react";
import { DownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const { Header, Content, Footer } = Layout;

const { Option } = Select;

export function PlantillaWeb({ children }) {
  const {
    // getOpciones,
    // getEmpresas,
    getUsuarioAutenticado,
    // getEmpresaFavorita,
    setEmpresaSeleccionada,
  } = useContext(SecurityContext);

  const [opciones, setOpciones] = useState(null);
  const [urlImagenLogotipo, setUrlImagenLogotipo] = useState("");

  let history = useHistory();

  useEffect(() => {
    const opcionesEmpresaSeleccionada = getUsuarioAutenticado().empresaSeleccionada.opciones;
    setOpciones(opcionesEmpresaSeleccionada);
    setUrlImagenLogotipo(getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo);
  }, []);

  function handleOnChangeEmpresa(idEmpresa) {
    setEmpresaSeleccionada(idEmpresa);
    setOpciones(getUsuarioAutenticado().empresaSeleccionada.opciones);
    setUrlImagenLogotipo(getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo);
    history.push("/dashboard");
  }
  function handleOnClickLogo() {
    history.push("/dashboard");
  }

  return (
    <Layout className="layout">
      <Header />
      <Header className="layout-header">
        <div>
          <a onClick={handleOnClickLogo}>
            <img
              className="layout-header__logotipo-imagen"
              src={urlImagenLogotipo ? urlImagenLogotipo : ""}
              alt="logo"
            />
          </a>
        </div>
        <div className="layout-header__logotipo">
          <Select
            className="texto-menu-perfil"
            suffixIcon={<DownOutlined style={{ color: "#1678c1" }} />}
            defaultValue={getUsuarioAutenticado().empresaSeleccionada.id}
            bordered={false}
            dropdownMatchSelectWidth={false}
            onChange={handleOnChangeEmpresa}
          >
            {getUsuarioAutenticado().empresas.map((empresa) => (
              <Option key={empresa.id} value={empresa.id}>
                {empresa.razonSocial}
              </Option>
            ))}
          </Select>
        </div>
        <div className="layout-header__menu">
          <MenuPrincipal opciones={opciones} />
        </div>
        <div className="layout-header__menu-perfil">
          <MenuPerfil />
        </div>
      </Header>
      <Content className="layout-content">{children}</Content>
      {/* <Footer className="layout-footer">InGenio Technologies ©2020</Footer> */}
    </Layout>
  );
}
