import React, { useState, useRef, useContext, useEffect } from "react";
import { Input, Button, Space, Table } from "antd";
import { EtiquetaEstadoDocumentoGenerico } from "../etiqueta-estado-documento-generico"
import { EtiquetaNombreDocumentoGenerico } from "../etiqueta-nombre-documento-generico"
import { MenuAcciones } from "../menu-acciones"
import { useListaDocumentosStore } from "hooks/firma-documento-generico"
import { SecurityContext } from "context/SecurityContextProvider";
import moment from "moment"

const ListaDocumentosGenericos = () => {


    const loading = useListaDocumentosStore(state => state.loading);

    const documentos = useListaDocumentosStore(state => state.documentos);

    const buscarDocumentos = useListaDocumentosStore(state => state.buscarDocumentos);

    const setDocumentos = useListaDocumentosStore(state => state.setDocumentos);

    const { getUsuarioAutenticado } = useContext(SecurityContext);

    useEffect(() => {

        const cargarDatosIniciales = async () => {
            const filtros = {
                organizacionId: getUsuarioAutenticado().organizacionId,
                fechaEnvioInicio: moment().startOf('month'),
                fechaEnvioFin: moment().endOf('month')
            };
            const documentos = await buscarDocumentos(filtros);
            setDocumentos(documentos);
        }

        cargarDatosIniciales();

    }, []);

    const columnas = [
        {
            title: 'Documento',
            dataIndex: 'nombreDocumento',
            key: 'nombreDocumento',
            width: '30%',
            render: (text, fila) => (
                <EtiquetaNombreDocumentoGenerico descripcion={fila.nombreDocumento} estado={fila.estadoDocumento} />
            )
        },

        {
            title: 'Estado',
            align: "center",
            dataIndex: 'estadoDocumento',
            key: 'estadoDocumento',
            width: '10%',
            render: (text, fila) => (
                <EtiquetaEstadoDocumentoGenerico estado={fila.estadoDocumento} />
            )
        },
        {
            title: 'Firmante:',
            dataIndex: 'usuarioFirmanteNombreCompleto',
            key: 'usuarioFirmanteNombreCompleto',
            width: '15%'
        },
        {
            title: 'Solicitado por:',
            key: 'solicitadoPor',
            dataIndex: 'solicitadoPor',
            width: '15%',
        },
        {
            title: 'Empresa',
            dataIndex: 'empresaRazonSocial',
            key: 'empresaRazonSocial',
            width: '15%'
        },
        {
            title: 'Acciones',
            align: "center",
            key: 'acciones',
            render: (text, fila) => (
                <MenuAcciones documentoGenerico={fila} />
            )
        },
    ]

    return (
        <Table rowKey={(documentos) => documentos.id} dataSource={documentos} loading={loading} columns={columnas} />
    );

}

export default ListaDocumentosGenericos;