import React from "react";
import { Provider as JotaiProvider} from "jotai";
import { Container } from "components/Container";
import { EditarPlantilla }  from "./componentes";
import { ModalDocumentos } from "./componentes";
import { useHistory } from "react-router-dom";

const PlantillaEditarPage=()=>{
    let history =useHistory();

    const onClickRetornar=()=>{
       history.push("/reclutamiento-y-seleccion/plantillas");
    }

    return(
        <Container titulo="Editar Plantilla" onClickRetornar={onClickRetornar}>
              <EditarPlantilla/>
              <ModalDocumentos/>
        </Container>
    )

}
export default PlantillaEditarPage;