import React, { useContext, useEffect, useState } from "react";
import { Dropdown, Menu, Button, Modal, notification,Popconfirm } from "antd";
import {
  SearchOutlined,  
  CheckCircleOutlined,
  UnorderedListOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router";
import { useAtom } from "jotai";
import * as atoms from "../../ListarConsultasColaboradorStore"
import { DetalleConsultaColaboradorService } from "services/DetalleConsultaColaboradorService";
import { SecurityContext } from "context/SecurityContextProvider";
import {ContratoService} from "services/ContratoService";
import { useModalVisorPdf } from "store/global";
import { TipoConsulta } from "enums/TipoConsulta";


const MenuAcciones = ({ consulta }) => {
     const[visibleModalConsulta,setVisibleModalConsulta] = useAtom(atoms.visibleModalConsultaAtom);    
     const [consultaSeleccionada, setConsultaSeleccionada] = useAtom(atoms.consultaSeleccionadaAtom);
     const [, setDetalleConsulta] = useAtom(atoms.detalleConsultaAtom);
     const [consultas, setConsultas] = useAtom(atoms.consultasAtom);     
     const [numeroRespuestas, setNumeroRespuestas] = useAtom(atoms.numeroRespuestasAtom);
     const { getUsuarioAutenticado } = useContext(SecurityContext);
     const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal);
    // const [totalRespuestasUsuario, setTotalRespuestasUsuario] = useAtom(atoms.totalRespuestasUsuarioAtom);

    const obtenerNumeroDetalleNoVisualizadas =async (consultas) => {
         let listaConsultasId = [];       
         consultas.length>0 && consultas.map((consulta)=>{
            listaConsultasId.push(consulta.id); 
          })           
          const _numeroRespuestas = await DetalleConsultaColaboradorService.listaNumeroRespuestas(listaConsultasId,"mensajeColaborador");           
          setNumeroRespuestas(_numeroRespuestas);
    }

    const onClickBtnVerConsulta = async (consulta) => {
        setVisibleModalConsulta(true);
        setConsultaSeleccionada(consulta);
        const _detallesconsulta = await DetalleConsultaColaboradorService.listar(consulta.id);         
        setDetalleConsulta(_detallesconsulta);
        const consultaEnviada = {
          consultaColaboradorId: consulta.id,
          tipoDetalle:"mensajeColaborador",
      }
        await DetalleConsultaColaboradorService.cambiarEstadoVisualizacionDetalleConsulta(consultaEnviada);
        obtenerNumeroDetalleNoVisualizadas(consultas);
        
        
    }
    const onclickBtnVerDocumento= async (consulta) => {
        
        try {       
            const respuesta = await ContratoService.getUrlDocumento(getUsuarioAutenticado().empresaSeleccionada.id, consulta.detalleEnvioId);
            mostrarModalVisorPdf(respuesta.urlDocumento, consulta.descripcionDocumentoNombre, true, true);
          } catch (error) {        
            console.error(error);
          } 
    }
    const onClickMenu = (opcion) => {
    switch (opcion.key) {
      case "ver-consulta":
        onClickBtnVerConsulta(consulta);
        break;

      case "ver-documento":
        onclickBtnVerDocumento(consulta);
        break;
      
      default:
        
    }
  };
    const menu = () => {
        
            return (
                <Menu onClick={onClickMenu}>
                    
                        <Menu.Item key="ver-consulta">
                            <SearchOutlined /> Ver Consulta
                        </Menu.Item>                   

                        {consulta.tipoConsulta===TipoConsulta.POR_DOCUMENTO &&(
                            <Menu.Item key="ver-documento">
                            <SearchOutlined /> Ver Documento
                          </Menu.Item>
                        )}                                       
                  
                </Menu>
            );
        
    }
  return (
    <React.Fragment>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomCenter">
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
          <CaretDownOutlined
            style={{
              paddingLeft: "2px",
              fontSize: "12px",
              fontWeight: "bold",
              color: "#1678c1",
            }}
          />
        </a>
      </Dropdown>
    </React.Fragment>
  );
};
export default MenuAcciones;
