import React,{useEffect,useState,useContext} from "react";
import {Button, Form,Input,Table,message,notification, Space, Row, Col, Select} from "antd";
import { visibleModalDocumentoAtom} from "../CrearPlantillaEnvioDocumentosStore";
import { useAtom } from "jotai";
import {documentosSeleccionadosAtom} from "../CrearPlantillaEnvioDocumentosStore";
import{ PlantillaEnvioDocumentoService} from "../../../../../services/PlantillaEnvioDocumentoService";
import { SecurityContext } from "context/SecurityContextProvider"
import { useHistory } from "react-router";
import { LoadingOutlined, CheckCircleOutlined ,CloseCircleOutlined, FolderTwoTone, FolderFilled } from "@ant-design/icons";
import CarpetaService from "services/CarpetaService";
import UsuarioService from "services/UsuarioService";

const PlantillaEnvioDocumentoRegistro = () =>{
   const[,setVisibleModalDocumento]=useAtom(visibleModalDocumentoAtom);
   const[documentosSeleccionados,setDocumentosSeleccionados]=useAtom(documentosSeleccionadosAtom);
   const {getUsuarioAutenticado}=useContext(SecurityContext);
   const[formPlantillaEnvioDocumento]=Form.useForm();
   const { Option } = Select;
   const[estado,setEstado]=useState();
   const [carpetas, setCarpetas] = useState([]);
   const [listaCarpetasFiltro, setListaCarpetasFiltro] = useState([]);
   let history=useHistory();
   useEffect(() => {
        const cargarDatos=async ()=>{  
            
            const usuario = await UsuarioService.buscarPorOrganizacionLogin(
                getUsuarioAutenticado().organizacionId,
                getUsuarioAutenticado().login
              );
      
              const empresaSeleccionada = usuario?.empresas.find(
                (empresa) => empresa.id === getUsuarioAutenticado().empresaSeleccionada.id
              );
            
            const _carpetas = await getListaCarpetasFiltro(empresaSeleccionada);
            setListaCarpetasFiltro(_carpetas);
        }
        cargarDatos();
        setDocumentosSeleccionados([]);
    }, []);
   const columnas=[
        {
            title:"Documentos",
            dataIndex:"nombre",
            key:"nombre"
        },
        {
            title:"Acciones",
            key:"acciones",
            render:(text,fila)=>(<div><Button type={"link"} onClick={()=>onClickQuitar(fila)}>Quitar</Button></div>)// <Button type={"link"} onClick={()=>onClickVer(fila)}>ver</Button></div>)
        }
    ];
    const onClikSeleccionarDocumento=()=>{
        setVisibleModalDocumento(true);
    }
    const onClickQuitar=(documento)=>{
        setDocumentosSeleccionados(documentosSeleccionados.filter(item=>item.id!==documento.id));

    }
    const getListaCarpetasFiltro = async (empresaSeleccionada) => {
        const _carpetas = await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        setCarpetas(_carpetas);
        if (!empresaSeleccionada?.filtroCarpeta?.length > 0) {
          return _carpetas;
        }
    
        var temp = [];
        const _temporal = empresaSeleccionada?.filtroCarpeta?.map((e) => {
          temp.push(_carpetas.find((c) => c.id == e.id));
        });
    
        return temp;
      };

    const onClickVer=(documento)=>{
       // setDocumentosSeleccionados(documentosSeleccionados.filter(item=>item.id!==documento.id));
    }
    const onFinishCrearPlantilla = async(plantilla)=>{
        
        try {
            if(documentosSeleccionados.length>0){
                const documentosEnviados=[];
            plantilla.empresaId=getUsuarioAutenticado().empresaSeleccionada.id;
            plantilla.organizacionId=getUsuarioAutenticado().organizacionId;
            plantilla.usuarioCreacion=getUsuarioAutenticado().id;
            
            for(let i=0;i<documentosSeleccionados.length;i++){
                const documento={
                    id:documentosSeleccionados[i].id,
                    nombre:documentosSeleccionados[i].nombre,
                    categoriaDocumento:documentosSeleccionados[i].categoriaDocumento,
                }
                documentosEnviados.push(documento);                

            }
            plantilla.documentos=documentosEnviados;
            const respuesta = await PlantillaEnvioDocumentoService.insertar(plantilla);
            if(respuesta){
                notification.success({
                    message:"La Plantilla fue registrada correctamente"
                });
                history.push("/configuracion/plantilla-envio-documentos/listar");
               }    else
               notification.error({
                   message:"No se pudo registrar"
               });
            } else{
               message.error("Debe seleccionar los documentos");
           }
            
        } catch (error) {
            console.log(error);
            notification.error({
                message:error,
            });
        }

    }

    const onChangeNombre = async(e) =>{
       // console.log(e.target.value)
        if(e.target.value!=null){
            setEstado('CARGANDO')
            const valido = await PlantillaEnvioDocumentoService.buscarPorNombre(getUsuarioAutenticado().empresaSeleccionada.id, e.target.value)
            setEstado(valido)
        }else
        setEstado(false)
        
    }
    
    return(

        <Form
            labelCol={{span:10,offset:8}}
            wrapperCol={{span:10,offset:8}}
            layout="vertical"
            onFinish={onFinishCrearPlantilla} 
            form={formPlantillaEnvioDocumento}                    
        >
            <Form.Item
               label="Nombre"
               name="nombre"
               rules={[
                {
                    required: true,
                    message: "Debe ingresar el nombre.",
                },
            ]}   
            >
                <Input onBlur={(e)=>onChangeNombre(e)} suffix={estado==='CARGANDO'? <LoadingOutlined />:estado === true?<CheckCircleOutlined style={{ color: 'green' }} />:estado===false?<CloseCircleOutlined style={{ color: 'red' }} />:""}/>
            </Form.Item>
            <Form.Item label="Carpeta" name={["carpeta", "id"]}>
          <Select
            onChange={(_, value2)=>formPlantillaEnvioDocumento.setFieldsValue({carpeta:{descripcion: value2.descripcion}})}
            optionLabelProp="label"
            placeholder="Seleccionar la carpeta"
            style={{ width: "100%" }}
            allowClear
          >
            {carpetas.length > 0 &&
              carpetas.map((carpeta) => {
                return (
                  <Option
                    key={carpeta.id}
                    disabled={listaCarpetasFiltro.filter((item) => item.id == carpeta.id).length == 0}
                    value={carpeta.id}
                    label={
                      <div>
                        <FolderTwoTone style={{ fontSize: "20px", color: "#F7D358" }} twoToneColor="#F7D358" />{" "}
                        {carpeta.ruta}
                      </div>
                    }
                    descripcion={carpeta.descripcion}
                  >
                    <Space size={carpeta.nivel * 10}>
                      <span></span>
                      <span>
                        {carpeta.nivel === 0 ? (
                          <FolderFilled style={{ fontSize: "20px", color: "#F7D358" }} />
                        ) : (
                          <FolderTwoTone style={{ fontSize: "18px", color: "#F7D358" }} twoToneColor="#F7D358" />
                        )}{" "}
                        {carpeta.descripcion}
                      </span>
                    </Space>
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item name={["carpeta", "descripcion"]} noStyle>
          <Input type="hidden" />
        </Form.Item>
            <Form.Item>
                <Button onClick={onClikSeleccionarDocumento}>Seleccionar Documentos</Button>
            </Form.Item>
            <Form.Item>
                <Table
                  rowKey={documento=>documento.id}
                  columns={columnas}
                  dataSource={documentosSeleccionados}
                  scroll={{y:240}}
                  pagination={false}
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" block htmlType="submit" disabled={!estado}>
                    Crear Plantilla
                </Button>
            </Form.Item>
        </Form>
    );

}
export default PlantillaEnvioDocumentoRegistro;