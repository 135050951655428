import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Row,
  Col,
  Button,
  Space,
  Upload,
  Table,
  Modal,
  notification,
  Tabs,
  Typography,
  Card,
  Alert,
  Descriptions,
  Checkbox
} from "antd";
import { SearchOutlined, FolderOpenOutlined, UploadOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { LoginService } from "services/LoginService";
import { EnvioService } from "services/EnvioService";
import UsuarioService from "../../../services/UsuarioService";
import ParametrosService from "services/ParametrosService";
import { ModalPDF } from "../../../components/PDFViewer/visorCrearNotificacion";
import { useHistory } from "react-router-dom";
import { FormatoCarga } from "enums/FormatoCarga";
import Bowser from "bowser";
import Axios from "axios";
import { Headers } from "enums";
import { ModalCargarExcel } from "./ModalCargarExcel";
import CertificadoService from "services/CertificadoService";
import { EstadoCertificadoDigital } from "enums/EstadoCertificadoDigital";
import TipoDocumentoService from "../../../services/TipoDocumentoService";
const { Text } = Typography;
const { TabPane } = Tabs;

export function ListarDetalleNotificacionPage() {
  const { Dragger } = Upload;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  //const { getDatosTipoDocumento } = useContext(SecurityContext);
  const { getDatosEnvio } = useContext(SecurityContext);

  //const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deshabilitarBtnNotificar, setDeshabilitarBtnNotificar] = useState(true);
  const [listaDocumentos, setListaDocumentos] = useState([]);
  //const [cargaTemporalId, setCargaTemporalId] = useState([]);
  const [representanteLegal, setRepresentanteLegal] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [colaboradoresObservados, setColaboradoresObservados] = useState([]);
  const [botonCargaExcelModal, setBotonCargaExcelModal] = useState(false);
  const datos = {
    //empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
    //tipoDocumentoId: getDatosEnvioCargarDocumento().documentoId,
    organizacionId: getUsuarioAutenticado().organizacionId,
    //cargaTemporalId: cargaTemporalId,
    envioId: getDatosEnvio().id,
    formatoCargaId: getDatosEnvio().formatoCargaId,
  };
  const [notificacionCompletar, setNotificacionCompletar] = useState("11");
  //const [tipoDocumentoAprobacion, setTipoDocumentoAprobacion] = useState();
  const [visibleModalAprobacion, setVisibleModalAprobacion] = useState();
  const [usuariosAprobadores, setUSuariosAprobadores] = useState([]);
  const [usuariosAprobadoresSeleccionados, setUsuariosAprobadoresSeleccionados] = useState([]);
  const [documentosSeleccionados, setDocumentosSeleccionados] = useState([]);
  const [colaboradoresVisibles, setColaboradoresVisibles] = useState(false);
  const [visibleModalResumen, setVisibleModalResumen] = useState(false);
  let history = useHistory();
  const [filaSeleccionada, setFilaSeleccionada] = useState("");
  const [url, setUrl] = useState("");
  const [visible, setVisible] = useState("");
  const [excelDisabled, setExcelDisabled] = useState(false);
  //const [acceptedFiles, setAcceptedFiles] = useState(undefined);
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [estado, setEstado] = useState("VIGENTE");
  const [codigoObligatorio, setCodigoObligatorio] = useState(false);
  const [hiddenNombre, setHiddenNombre] = useState(false)
  const [canditdadColaboradores, setCantidadColaboradores] = useState(0);
  const [habilitarAdjuntarDocumentos, setHabilitarAdjuntarDocumentos] = useState(false);
  const [adjuntarDocumentoCorreo, setAdjuntarDocumentoCorreo] = useState(false);
  const [habilitarHistorico, setHabilitarHistorico] = useState(false);
  const [historico, setHistorico] = useState(false);

  useEffect(() => {
    const cargarDatos = async () => {
      const consulta = await ParametrosService.notificacionCompletarActivo(
        LoginService.getUsuarioAutenticado().empresaSeleccionada.id
      );
      // console.log(consulta)
      setNotificacionCompletar(consulta);
      const codigoObligatorio = await ParametrosService.buscarPorEmpresaConCodigoColaboradorActivo(
        LoginService.getUsuarioAutenticado().empresaSeleccionada.id
      );
      setCodigoObligatorio(codigoObligatorio.respuesta === "SI" ? true : false);
      // if (getDatosEnvio().formatoCargaId === FormatoCarga.EXCEL.id.toString()) {
      //   console.log(1);
      //   setAcceptedFiles(FormatoCarga.EXCEL.extension);
      // } else if (
      //   getDatosEnvio().formatoCargaId === FormatoCarga.PDF_MULTIPAGINA.id.toString() ||
      //   getDatosEnvio().formatoCargaId === FormatoCarga.PDF_MULTIPAGINA_OCR.id.toString()
      // ) {
      //   console.log(2);
      //   setAcceptedFiles(FormatoCarga.PDF_MULTIPAGINA.extension);
      // } else if (
      //   getDatosEnvio().formatoCargaId === FormatoCarga.TXT_MULTIPAGINA.id.toString() ||
      //   getDatosEnvio().formatoCargaId === FormatoCarga.TXT_MULTIPAGINA_PARAMETROS.id.toString()
      // ) {
      //   console.log(3);
      //   setAcceptedFiles(FormatoCarga.TXT_MULTIPAGINA.extension);
      // } else {
      //   console.log(4);
      //   setAcceptedFiles(undefined);
      // }
    };
    const cargarCertificadoEstado = async () => {
      const seleccionMultiple = await ParametrosService.isSeleccionMultiple();
      if (!seleccionMultiple) {
        const estado = await CertificadoService.validarCertificadoEstado(
          getUsuarioAutenticado().empresaSeleccionada.id,
          getDatosEnvio().documentoId,
          null
        );
        setMostrarAlerta(estado === "EXPIRADO" || estado === "SIN_CERTIFICADO");
        setEstado(estado);
      }
    };
    cargarDatos();
    cargarCertificadoEstado();
  }, []);

  const props = {
    name: "file",
    multiple: true,
    action: process.env.REACT_APP_ENDPOINT + "envio/cargarDocumentoMasivo",
    headers: {
      Authorization: "Bearer " + LoginService.getJwtToken(),
      [Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
      [Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
    },
    data: datos,
    onChange(info) {
      const { status } = info.file;
      // Garantiza solo la carga de un archivo
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);

      if (status !== "uploading") {
      }
      if (status === "done") {
        //setCargaTemporalId(info.file.response.cagaTemporalId);

        setColaboradores(info.file.response.listaColaboradores);
        //console.log("Lista colaboradores::", info.file.response.listaColaboradores);
        //const colaboradoresId = info.file.response.listaColaboradores.map((c) => c.detalleEnvioId);
        const colaboradoresId = info.file.response.listaColaboradores?.filter((c) => c.estadoDocumentoEnvio == null)
          .map((c) => c.detalleEnvioId);
        //console.log(info.file.response.listaColaboradores)
        //console.log(info.file.response.listaColaboradores.filter(c=>c.estadoDocumentoEnvio==null))
        setDocumentosSeleccionados(colaboradoresId);

        //setDocumentosSeleccionados(info.file.response.listaColaboradores.map(d=>d.id))
        setRepresentanteLegal(info.file.response.representanteLegal);
        setColaboradoresObservados(info.file.response.listaColaboradoresObservados);
        //setDeshabilitarBtnNotificar(false);

        if (
          info.file.response.listaColaboradores?.length === 0 &&
          info.file.response.listaColaboradoresObservados === 0
        ) {
          // setDeshabilitarBtnNotificar(true);
          notification.warning({
            message: "Advertencia",
            description:
              "No se encontraron Colaboradores por favor revisar el archivo de carga - validar posición del número de documento.",
          });
        } else {
          notification.success({
            message: "Operación exitosa",
            description: `Se cargo correctamente el archivo: ${info.file.name}`,
          });

          if (info.file.response.listaColaboradores[0]?.tipoDocumentoNombre == null) {
            setHiddenNombre(true)
            setCantidadColaboradores(info.file.response.listaColaboradores?.length)
          } else
            setCantidadColaboradores(info.file.response.numeroColaboradores);

          setDeshabilitarBtnNotificar(false);
          setColaboradoresVisibles(true);
        }
      } else if (status === "error") {
        notification.error({
          message: "Error al cargar el archivo",
          description: "codigo: " + info.file.response.error + " - " + info.file.response.mensaje,
        });
      }
      setListaDocumentos(fileList);
    },
  };

  async function procesarNotificacion() {
    setLoading(true);
    const browser = Bowser.parse(window.navigator.userAgent);
    //const res = await Axios.get("https://geolocation-db.com/json/");

    try {
      //setDeshabilitarBtnCancelar(true);

      const datos = {
        navegador: browser.browser.name,
        navegadorVersion: browser.browser.version,
        os: browser.os.name,
        osVersion: browser.os.versionName,
        //ipEnvio: res.data.IPv4,
        id: getDatosEnvio().id,
        organizacionId: getUsuarioAutenticado().organizacionId,
        empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
        //TODO validar y pasar  aca el Id del envio
        //cargaTemporalId: cargaTemporalId,
        envioId: getDatosEnvio().id,
        tipoDocumentoId: getDatosEnvio().documentoId,
        tipoDocumentoNombre: getDatosEnvio().documentoNombre,
        descripcion: getDatosEnvio().descripcion,
        usuarioLogin: getUsuarioAutenticado().login,
        // usuarioNumeroDocumentoIdentidad: getUsuarioAutenticado()
        //   .numeroDocumentoIdentidad,
        usuarioCorreoElectronico: getUsuarioAutenticado().correoElectronico,
        usuarioNombreCompleto: getUsuarioAutenticado().nombreCompleto,
        estadoEnvio:
          //getDatosEnvio().aprobacion != null &&
          //getDatosEnvio().aprobacion == true
          getDatosEnvio().flujo != "SIMPLE_SIN_APROBADOR" ? 1 : 0,
        usuariosAprobadores:
          //getDatosEnvio().aprobacion != null &&
          //getDatosEnvio().aprobacion == true
          getDatosEnvio().flujo != "SIMPLE_SIN_APROBADOR" ? usuariosAprobadoresSeleccionados : null,
        documentosSeleccionados: documentosSeleccionados,
        totalDetallesSeleccionados: documentosSeleccionados.length,
        totalDetallesPendientes: colaboradores.length - documentosSeleccionados.length,
        pdfMultiple: !hiddenNombre,
        adjuntarDocumentoCorreo: adjuntarDocumentoCorreo,
        historico: historico
      };

      const data = await EnvioService.notificarNuevo(datos);
      notification.success({
        message: "Operación exitosa",
        description: "Se realizo el proceso correctamente.",
      });
      console.log("dataaaa:::", datos);

      //setEnvioId(data.id);
      //const detalleEnvio = await DetalleEnvioService.listar(data.id, -1);
      //setDetalleEnvio(detalleEnvio);
      cerrarModalAprobacion();
      cerrarModalResumen();
      setColaboradoresVisibles(false);

      //setDatosEnvio(data.id, data.tipoDocumentoNombre, data.descripcion);
      setLoading(false);
      //antiguo redireccionamiento
      // history.push("/documento-laboral/listar-detalle");

      //nuevo redireccionamiento
      if (data.pdfMultiple != null && data.pdfMultiple)
        history.push(`/notificacion/seguimiento/`);
      else
        history.push(`/notificacion/seguimiento/detalle/Documento_Laboral/${data.id}`);
    } catch (error) {
      console.error(error);
      setLoading(false);
      // setDeshabilitarBtnCancelar(false);
    } finally {
      setLoading(false);
    }
  }

  async function handleOnClickVerDocumento(colaborador) {
    try {
      setLoading(true);
      setFilaSeleccionada(colaborador.detalleEnvioId);

      let data;
      const formData = new FormData();

      //Excel:fa8c5f15-dffb-4c64-8e52-e65de5faea75
      if (getDatosEnvio().formatoCargaId === "fa8c5f15-dffb-4c64-8e52-e65de5faea75") {
        formData.append("envioId", getDatosEnvio().id);
        formData.append("numeroDocumentoColaborador", colaborador.numeroDocumentoIdentidad);
        if (codigoObligatorio) formData.append("codigoColaborador", colaborador.codigo);

        data = await EnvioService.verPDFExcelOrWs(formData);
      } else if (
        getDatosEnvio().formatoCargaId === "e136ea17-8bea-4b1c-b719-dd876597a303" ||
        getDatosEnvio().formatoCargaId === FormatoCarga.TXT_MULTIPAGINA.id ||
        getDatosEnvio().formatoCargaId === FormatoCarga.TXT_MULTIPAGINA_PARAMETROS.id ||
        getDatosEnvio().formatoCargaId === FormatoCarga.ZIP.id ||
        getDatosEnvio().formatoCargaId === FormatoCarga.ZIP_OCR.id
      ) {
        data = await EnvioService.vistaPrevia({
          empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
          detalleEnvioId: colaborador.detalleEnvioId,
        });
      } else {
        formData.append("ruta", colaborador.urlDocumentoCargado);
        formData.append("tipoDocumentoId", getDatosEnvio().documentoId);
        formData.append("empresaId", getUsuarioAutenticado().empresaSeleccionada.id);

        formData.append("organizacionId", getUsuarioAutenticado().organizacionId);
        formData.append("detalleEnvioId", colaborador.detalleEnvioId);
        formData.append("numeroDocumentoIdentidad", colaborador.numeroDocumentoIdentidad);
        data = await EnvioService.verPDF(formData);
      }
      //TODO validar esta funcion que crea url
      //const url = window.URL.createObjectURL(new Blob([data]));
      const url = window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));

      // FileSaver.saveAs(response.data, effectiveFileName);
      setUrl(url);
      setVisible(true);
      //setUrl("https://arxiv.org/pdf/quant-ph/0410100.pdf");
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  function handleOnOkModal() {
    setVisible(false);
  }

  var adicionarColumnasFiltro = function getColumns(dataIndex, placeholder, propiedad) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={placeholder !== undefined ? placeholder : `Buscar por ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Limpiar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
      onFilter: (value, record) =>
        propiedad === undefined
          ? record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase())
          : record[dataIndex][propiedad]?.toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={propiedad === undefined ? text?.toString() : text[propiedad]?.toString()}
          />
        ) : propiedad === undefined ? (
          text
        ) : (
          text[propiedad]
        ),
    };
  };

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  // useEffect(() => {
  //   console.log(colaboradores);
  // }, [colaboradores]);

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText("");
  }
  const columnas = [
    {
      title: "Documento",
      dataIndex: "numeroDocumentoIdentidad",
      align: "center",
      ...adicionarColumnasFiltro("numeroDocumentoIdentidad"),
    },
    {
      title: "Apellidos y Nombres",
      dataIndex: "nombreCompleto",
      align: "center",
      ...adicionarColumnasFiltro("nombreCompleto"),
    },
    ...(!hiddenNombre ?
      [
        {
          title: "Tipo Documento",
          dataIndex: "tipoDocumentoNombre",
          align: "center",
        }
      ]
      :
      []
    ),
    {
      title: "Correo electrónico",
      dataIndex: "correoElectronico",
      align: "center",
      ...adicionarColumnasFiltro("correoElectronico"),
    },
    {
      title: "Acciones",
      key: "acciones",
      render: (fila, colaborador) =>
        colaboradores.length >= 1 ? (
          <Space>
            <Button
              type="link"
              onClick={() => handleOnClickVerDocumento(colaborador)}
              loading={filaSeleccionada === colaborador.detalleEnvioId && loading}
            >
              ver
            </Button>
          </Space>
        ) : null,
    },
  ];

  // tabla observaciones
  const columnasColaboradoresObservados = [
    {
      title: "Documento",
      dataIndex: "numeroDocumentoIdentidad",
      align: "center",
      ...adicionarColumnasFiltro("numeroDocumentoIdentidad"),
    },
    {
      title: "Apellidos y Nombres",
      dataIndex: "nombreCompleto",
      align: "center",
      ...adicionarColumnasFiltro("nombreCompleto"),
    },
    {
      title: "Correo electrónico",
      dataIndex: "correoElectronico",
      align: "center",
      //...adicionarColumnasFiltro("correoElectronico"),
    },
    {
      title: "Mensaje",
      dataIndex: "mensaje",
      align: "center",
      //...adicionarColumnasFiltro("nombreCompleto"),
    },
    {
      title: "Acciones",
      key: "acciones",
      render: (fila, colaborador) =>
        colaboradores.length >= 1 && colaborador.urlDocumentoCargado ? (
          <Space>
            <Button
              type="link"
            // onClick={() => handleOnClickVerDocumento(colaborador)}
            >
              ver
            </Button>
          </Space>
        ) : null,
    },
  ];

  async function handleOnClickNotificar() {
    const _tipoDocumento = await TipoDocumentoService.buscarPorId(getDatosEnvio().documentoId);
    console.log("_tipoDocumento", _tipoDocumento);
    setHabilitarAdjuntarDocumentos(_tipoDocumento?.adjuntarDocumentosCorreo);
    setHabilitarHistorico(_tipoDocumento?.historico);
    setAdjuntarDocumentoCorreo(false);
    setHistorico(false);
    //if (getDatosEnvio().aprobacion && getDatosEnvio().aprobacion === true) {
    if (
      getDatosEnvio().flujo &&
      (getDatosEnvio().flujo === "APROBACION_Y_FIRMA_GENERICA" ||
        getDatosEnvio().flujo === "APROBACION_Y_FIRMA_DEL_APROBADOR")
    ) {
      setVisibleModalAprobacion(true);
      const usuarios = await UsuarioService.listarUsuariosAProbadores(getUsuarioAutenticado().empresaSeleccionada.id);
      setUSuariosAprobadores(usuarios);
      console.log("con aprobación");
    } else {
      setVisibleModalResumen(true);
      console.log("sin aprobación");
    }
  }

  function handleOnClickLimpiar() {
    setColaboradoresVisibles(false);
    setListaDocumentos([]);
    setColaboradoresObservados([]);
    setHiddenNombre(false);
  }

  function cerrarModalAprobacion() {
    setVisibleModalAprobacion(false);
    setUsuariosAprobadoresSeleccionados([]);
  }
  function cerrarModalResumen() {
    setVisibleModalResumen(false);
  }

  const rowSelection = {
    selectedRowKeys: documentosSeleccionados,

    onChange: (selectedRowKeys) => {
      setDocumentosSeleccionados(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: excelDisabled,
    }),
  };

  const columnaAprobadores = [
    {
      // title: "Todos",
      dataIndex: "nombreCompleto",
      key: "nombreCompleto",
    },
  ];
  const rowSelectionUsuario = {
    onChange: (selectedRowKeysUsers, selectedRowsUsers) => {
      setUsuariosAprobadoresSeleccionados(selectedRowsUsers);
    },
  };

  const onCheckAdjuntarDocumentoCorreo = (e) => {
    setAdjuntarDocumentoCorreo(e.target.checked);
  };

  const onCheckHistorico = (e) => {
    setHistorico(e.target.checked);
  };

  return (
    <Row>
      <Col span={24}>
        <Card
          title={
            <Text>
              <Space>
                {<FolderOpenOutlined style={{ fontSize: "50px" }} />}
                <span className="mock-block">
                  <Text strong>{getDatosEnvio().documentoNombre}</Text>
                  <p>{getDatosEnvio().descripcion}</p>
                </span>
              </Space>
            </Text>
          }
          extra={
            <React.Fragment>
              {colaboradoresVisibles && (
                <Space>
                  <Button
                    disabled={documentosSeleccionados.length <= 0 && getDatosEnvio().flujo === "SIMPLE_SIN_APROBADOR"}
                    type="primary"
                    onClick={handleOnClickNotificar}
                  >
                    {getDatosEnvio().flujo != "SIMPLE_SIN_APROBADOR" ? (
                      <span>Solicitar Aprobación</span>
                    ) : (
                      <span>Notificar</span>
                    )}
                  </Button>
                  <Button onClick={handleOnClickLimpiar}>Limpiar</Button>
                </Space>
              )}
            </React.Fragment>
          }
          headStyle={{ fontWeight: "400" }}
        >
          {colaboradoresObservados.length > 0 && (
            <Alert
              description="Se ha encontrado observaciones al cargar el Documento.Por favor haz click en Observaciones."
              type="error"
              showIcon
            />
          )}
          {colaboradoresVisibles === false ? (
            /*<Result key="cargaInfo"
                    icon={<FrownOutlined />}
                   // title="Usted no ha realizado carga de información"  
                   extra={[
                    <Button key="btnCargaInfo" type="primary" icon={<UploadOutlined />} onClick={handleOnclickCargarDocumento}>
                      Cargar Información
                    </Button>
                   
                      ]}            
                   />*/
            <div
              style={{
                marginBottom: "30px",
                textAlign: "left",
              }}
            >
              <div>
                <Text strong style={{ fontSize: "15px" }}>
                  Cargar Documento Laboral
                </Text>
              </div>
              <br />
              {mostrarAlerta && (
                <div style={{ marginBottom: "10px" }}>
                  <Alert
                    message={
                      estado === EstadoCertificadoDigital.EXPIRADO ? (
                        <Text>
                          No puede cargar este documento porque su certificado digital se encuentra expirado.{" "}
                        </Text>
                      ) : (
                        <Text>No hay certificado registrado para está empresa</Text>
                      )
                    }
                    type="warning"
                    showIcon
                  />
                </div>
              )}
              <Dragger
                {...props}
                fileList={listaDocumentos}
                //accept={acceptedFiles}
                disabled={mostrarAlerta}
              >
                <p className="ant-upload-drag-icon">
                  <UploadOutlined />
                </p>
                <p className="ant-upload-text">Haga click ó arrastre su archivo hacia aquí para iniciar el proceso.</p>
              </Dragger>
            </div>
          ) : (
            <Tabs defaultActiveKey="1">
              <TabPane tab="Colaboradores" key="1">
                <Row>
                  {notificacionCompletar && (
                    <Space>
                      <Button type="primary" onClick={() => setBotonCargaExcelModal(true)}>
                        Seleccionar Colaboradores por excel
                      </Button>
                      {excelDisabled && (
                        <Button
                          onClick={() => {
                            setExcelDisabled(false);
                            setDocumentosSeleccionados([]);
                          }}
                        >
                          Limpiar Selección
                        </Button>
                      )}
                    </Space>
                  )}
                </Row>

                <Table
                  rowKey={(colaboradores) => colaboradores.detalleEnvioId}
                  columns={columnas}
                  dataSource={colaboradores}
                  rowSelection={notificacionCompletar ? rowSelection : null}
                  pagination={false}
                />
              </TabPane>
              <TabPane tab="Observaciones" key="2">
                <Table
                  rowKey={(colaboradoresObservados) => colaboradoresObservados.numeroDocumentoIdentidad}
                  columns={columnasColaboradoresObservados}
                  dataSource={colaboradoresObservados}
                  pagination={false}
                />
              </TabPane>
            </Tabs>
          )}
        </Card>
      </Col>
      <Modal
        title="Requiere Aprobación"
        visible={visibleModalAprobacion}
        onOk={procesarNotificacion}
        onCancel={cerrarModalAprobacion}
        footer={[
          <Button key="back" onClick={cerrarModalAprobacion}>
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={procesarNotificacion}
            disabled={
              usuariosAprobadores.length > 0 &&
                usuariosAprobadores !== null &&
                usuariosAprobadoresSeleccionados.length > 0 &&
                !loading
                ? false
                : true
            }
          >
            {/* Solicitar Aprobación */}
            Enviar
          </Button>,
        ]}
        destroyOnClose={true}
      >
        {usuariosAprobadores.length > 0 && usuariosAprobadores !== null ? (
          <React.Fragment>
            Seleccione al responsable de Aprobar la Notificación
            <Table
              rowKey={(usuarioAprobador) => usuarioAprobador.id}
              columns={columnaAprobadores}
              dataSource={usuariosAprobadores}
              bordered={false}
              pagination={false}
              rowSelection={{
                type: "radio",
                ...rowSelectionUsuario,
                columnWidth: 2,
              }}
              size="small"
            />
          </React.Fragment>
        ) : (
          <Text type="danger">No existen usuarios aprobadores</Text>
        )}
      </Modal>
      <Modal
        title="Resumen"
        //width={600}
        visible={visibleModalResumen}
        onOk={procesarNotificacion}
        onCancel={cerrarModalResumen}
        width={650}
        footer={[
          <Button key="back" onClick={cerrarModalResumen}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={procesarNotificacion} disabled={loading}>
            Enviar
          </Button>,
        ]}
      >
        <Space direction="vertical" size={10}>
          <Descriptions bordered size="small" column={1}>
            <Descriptions.Item label="Documento">{getDatosEnvio().documentoNombre !== null ? getDatosEnvio().documentoNombre : getDatosEnvio().listaTipoDocumentos}</Descriptions.Item>
            <Descriptions.Item label="Descripción">{getDatosEnvio().descripcion}</Descriptions.Item>
            <Descriptions.Item label="Colaboradores a notificar">{canditdadColaboradores}</Descriptions.Item>
            <Descriptions.Item label="Colaboradores pendientes de Notificar *">
              {colaboradores?.length - documentosSeleccionados?.length}
            </Descriptions.Item>
            <Descriptions.Item label="Responsable de Firma">{representanteLegal}</Descriptions.Item>
            {habilitarAdjuntarDocumentos && (
              <Descriptions.Item label="Adjuntar documento en el correo electrónico">
                <Checkbox onChange={onCheckAdjuntarDocumentoCorreo} checked={adjuntarDocumentoCorreo}>
                </Checkbox>
              </Descriptions.Item>
            )}
            {habilitarHistorico && (
              <Descriptions.Item label="Carga historica">
                <Checkbox onChange={onCheckHistorico} checked={historico}>
                </Checkbox>
              </Descriptions.Item>
            )}
          </Descriptions>

          <Alert
            message="(*) Colaboradores pendientes de notificar: Estos colaboradores no serán notificados en este envío1"
            type="warning"
          />
        </Space>
        {/* <Row align="center">
          <Col span={14} className="space-right">
            <Row justify="start">
              <span style={{ fontWeight: "bold" }}>
                <FileOutlined /> Documento Laboral
              </span>
            </Row>

            <Row justify="start">
              <span style={{ fontWeight: "bold" }}>
                <HighlightOutlined /> Descripción
              </span>
            </Row>

            <Row justify="start">
              <span style={{ fontWeight: "bold" }}>
                <NumberOutlined /> Cantidad de colaboradores
              </span>
            </Row>

            <Row justify="start">
              <span style={{ fontWeight: "bold" }}>
                <SolutionOutlined /> Responsable de envío de los Documentos
              </span>
            </Row>
          </Col>
          <Col span={10}>
            <Row justify="start">{getDatosEnvio().documentoNombre}</Row>
            <Row justify="start">{getDatosEnvio().descripcion}</Row>
            <Row justify="start">{colaboradores.length}</Row>
            <Row justify="start">{getUsuarioAutenticado().nombreCompleto}</Row>
          </Col>
        </Row> */}
      </Modal>
      <ModalCargarExcel
        visibleModal={botonCargaExcelModal}
        setVisibleModal={setBotonCargaExcelModal}
        envioId={getDatosEnvio().id}
        setSeleccion={setDocumentosSeleccionados}
        setExcelDisabled={setExcelDisabled}
      />
      <ModalPDF ruta={url} visible={visible} handleOnOkModal={handleOnOkModal} />
    </Row>
  );
}
