import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import { ContainerListarDetalle } from "../../../components/ContainerListarDetalle";
import {
  FolderOpenOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
  ArrowLeftOutlined,
  MinusCircleOutlined,
  SyncOutlined,
  MailOutlined,
  PhoneOutlined,
  FileUnknownOutlined,
} from "@ant-design/icons";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import {
  Table,
  Space,
  Menu,
  Button,
  Input,
  Select,
  notification,
  Modal,
  Form,
  Tag,
  Typography,
} from "antd";
import DetalleEnvioService from "../../../services/DetalleEnvioService";
import { DocumentoLaboralService } from "services/DocumentoLaboralService";
import { EnvioService } from "services/EnvioService";
import { ModalPDF } from "../../../components/PDFViewer";
import Highlighter from "react-highlight-words";
import { MenuAcciones } from "./menu-acciones";
import { EtiquetaEstadoNotificacion } from "./etiqueta-estado-notificacion";
import { EtiquetaEstadoVisualizacion } from "./etiqueta-estado-visualizacion";
import { EtiquetaEstadoFirmaColaborador } from "./etiqueta-estado-firma-colaborador";
import { EstadoRegistro } from "enums/EstadoRegistro";
import { EstadoDocumento } from "enums/EstadoDocumento";
import { FiltroTabla } from "./filtro-tabla";
import { ModalActualizarCorreo } from "./modal-actualizar-correo";
import { ModalHistorialNotificacion } from "./modal-historial-notificacion";
import HistorialNotificacionService from "../../../services/HistorialNotificacionService";

const { Option } = Select;
const { confirm } = Modal;

const EditableContext = React.createContext();

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);

    form.setFieldsValue({
      [dataIndex[1]]: record[dataIndex[0]][dataIndex[1]],
    });
  };

  const save = async (e) => {
    try {
      const values = await form.validateFields();
      const colaborador = {
        ...record.colaborador,
        ...values,
      };
      toggleEdit();
      handleSave({ ...record, colaborador });
    } catch (errInfo) {
      console.error("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable && !record.eliminado) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex[1]}
        rules={[
          {
            required: true,
            message: `${title} es requerido.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        // className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
          padding: "5px 12px",
          cursor: "pointer",
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export function DocumentoLaboralListarDetallePage() {
  const { getOrganizacion, getUsuarioAutenticado } =
    useContext(SecurityContext);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [estado, setEstado] = useState("");
  const [defaultValue, setDefaultValue] = useState("default");
  const [selectedRowKeys, setSelectedRowsKeys] = useState([]);
  const [filaSeleccionada, setFilaSeleccionada] = useState("");
  const [visibleModalActualizarCorreo, setVisibleModalActualizarCorreo] =
    useState(false);
  const [visibleModalHistorialNotificacion, setVisibleModalHistorialNotificacion] =
  useState(false);
  const { Text } = Typography;
  const menu = (
    <Menu onClick={onClickMenu}>
      <Menu.Item key="-1">Todos</Menu.Item>
      <Menu.Item key="0">Documentos En Proceso</Menu.Item>
      <Menu.Item key="1">Documentos Enviados</Menu.Item>
    </Menu>
  );
  const menuMasOpciones = (
    <Menu onClick={showReenvioMasivoConfirm}>
      <Menu.Item key="0" disabled={selectedRowKeys.length === 0}>
        Reenvio Masivo
      </Menu.Item>
    </Menu>
  );

  const [detalleEnvio, setDetalleEnvio] = useState([]);
  const { getDatosEnvio } = useContext(SecurityContext);
  let envioId = getDatosEnvio().id;
  const [url, setUrl] = useState("");
  const [visible, setVisible] = useState("");
  const [loading, setLoading] = useState(false);
  const [habilitado, setHabilitado] = useState(true);
  const [documentoNombre, setDocumentoNombre] = useState("");
  const [fechaFirma, setFechaFirma] = useState("");
  const [representanteLegal, setRepresentanteLegal] = useState("");
  const [nombreColaborador, setNombreColaborador] = useState("");
  const [fechaFirmaColaborador, setFechaFirmaColaborador] = useState("");
  const [estadoDetalleEnvio, setEstadoDetalleEnvio] = useState();
  const [detalleEnvioVisualizados, setDetalleEnvioVisualizados] = useState([]);
  const [detalleEnvioNoVisualizados, setDetalleEnvioNoVisualizados] = useState(
    []
  );
  const [detalleEnvioErroneos, setDetalleEnvioErroneos] = useState([]);
  const [filtradoDetalleEnvio, setFiltradoDetalleEnvio] = useState("");
  const [documentoSeleccionado, setDocumentoSeleccionado] = useState(undefined);
  const [historialNotificacion, setHistorialNotificacion] = useState(undefined);

  let history = useHistory();

  useEffect(() => {
    async function cargarDatos() {
      const _documentos = await DocumentoLaboralService.listar(
        getUsuarioAutenticado().empresaSeleccionada.id,
        envioId
      );
      setDetalleEnvio(_documentos);

      const visualizados = await DetalleEnvioService.listarVisualizados(
        envioId
      );
      setDetalleEnvioVisualizados(visualizados);

      const novisualizados = await DetalleEnvioService.listarNoVisualizados(
        envioId
      );
      setDetalleEnvioNoVisualizados(novisualizados);

      const erroneos = await DetalleEnvioService.listarErroneos(envioId);
      setDetalleEnvioErroneos(erroneos);
    }

    cargarDatos();
  }, []);

  var adicionarColumnasFiltro = function getColumns(
    dataIndex,
    placeholder,
    propiedad
  ) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={
              placeholder !== undefined
                ? placeholder
                : `Buscar por ${dataIndex}`
            }
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        propiedad === undefined
          ? record[dataIndex]
              ?.toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : record[dataIndex][propiedad]
              ?.toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={
              propiedad === undefined
                ? text.toString()
                : text[propiedad].toString()
            }
          />
        ) : propiedad === undefined ? (
          text
        ) : (
          text[propiedad]
        ),
    };
  };

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText("");
  }

  async function handleOnClickVerDocumento(detalleEnvio) {
    try {
      setLoading(true);
      setFilaSeleccionada(detalleEnvio.id);
      setEstadoDetalleEnvio(detalleEnvio.estadoProcesoId);
      const formData = new FormData();
      let data;
      if (
        detalleEnvio.envio.formatoCargaId ===
          "fa8c5f15-dffb-4c64-8e52-e65de5faea75" &&
        detalleEnvio.estadoProcesoId == 6
      ) {
        formData.append("envioId", detalleEnvio.envio.id);
        formData.append(
          "numeroDocumentoColaborador",
          detalleEnvio.colaborador.numeroDocumentoIdentidad
        );

        data = await EnvioService.verPDFExcelOrWs(formData);
        setVisible(false);
      } else {
        formData.append("detalleEnvioId", detalleEnvio.id);
        formData.append("estadoProcesoId", detalleEnvio.estadoProcesoId);
        data = await DetalleEnvioService.verPDF(formData);
      }
      //TODO validar esta funcion que crea url
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/pdf" })
      );
      setUrl(url);
      setVisible(true);
      setDocumentoNombre(detalleEnvio.envio.tipoDocumentoNombre);
      setFechaFirma(detalleEnvio.fechaEnvio);
      setRepresentanteLegal(detalleEnvio.representanteLegalNombre);
      setNombreColaborador(detalleEnvio.colaborador.nombreCompleto);
      setFechaFirmaColaborador(detalleEnvio.colaborador.fechaFirma);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
    //TODO: se comenta temporalmente
    // try {
    //   setLoading(true);
    //   setFilaSeleccionada(detalleEnvio.id);
    //   setEstadoDetalleEnvio(detalleEnvio.estadoProcesoId)
    //   const formData = new FormData();
    //   formData.append("detalleEnvioId", detalleEnvio.id);
    //   formData.append("estadoProcesoId", detalleEnvio.estadoProcesoId);
    //   let data = await DetalleEnvioService.verPDF(formData);
    //   //TODO validar esta funcion que crea url
    //   const url = window.URL.createObjectURL(
    //     new Blob([data], { type: "application/pdf" })
    //   );
    //   setUrl(url);
    //   setVisible(true);
    //   setDocumentoNombre(detalleEnvio.envio.tipoDocumentoNombre);
    //   setFechaFirma(detalleEnvio.fechaEnvio);
    //   setRepresentanteLegal(detalleEnvio.representanteLegalNombre);
    //   setNombreColaborador(detalleEnvio.colaborador.nombreCompleto);
    //   setFechaFirmaColaborador(detalleEnvio.colaborador.fechaFirma);
    //   setLoading(false);
    // } catch (error) {
    //   setLoading(false);
    // }
  }

  function handleAccionesOnClick(value, detalleEnvio) {
    if (value === "ver") {
      handleOnClickVerDocumento(detalleEnvio);
    } else if (value === "inhabilitar") {
      showDeleteConfirm(detalleEnvio.id);
    } else if (value === "reenviar") {
      showReenviarIndividualConfirm(detalleEnvio.id);
    }
  }

  function showReenviarIndividualConfirm(detalleEnvioId) {
    confirm({
      title: "Está seguro de reenviar el registro seleccionado?",
      icon: <ExclamationCircleOutlined />,
      okText: "si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleReenviarNotificacionPorId(detalleEnvioId);
      },
    });
  }

  function showDeleteConfirm(detalleEnvioId) {
    confirm({
      title: "Estas seguro de inhabilitar el registro seleccionado?",
      icon: <ExclamationCircleOutlined />,
      okText: "si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleOnClickInhabilitarDetalleEnvio(detalleEnvioId);
      },
    });
  }
  function showReenvioMasivoConfirm() {
    confirm({
      title: "Está seguro de hacer Reenvio Masivo?",
      icon: <ExclamationCircleOutlined />,
      okText: "Si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleOnOkReenvioMasivo();
      },
    });
  }

  async function handleOnClickInhabilitarDetalleEnvio(detalleEnvioId) {
    setFilaSeleccionada(detalleEnvioId);
    await DetalleEnvioService.inhabilitar(detalleEnvioId);

    setDetalleEnvio(
      detalleEnvio.map((detalleEnvio) => {
        if (detalleEnvio.id === detalleEnvioId) {
          return {
            ...detalleEnvio,
            estadoRegistro: EstadoRegistro.INACTIVO,
            eliminado: true,
          };
        }
        return detalleEnvio;
      })
    );

    // const newDetallesEnvio = [...detalleEnvio];

    // const indiceDetalleEnvioInactivar = newDetallesEnvio.findIndex(
    //   (item) => item.id === detalleEnvioId
    // );

    // const detalleEnvioInactivar = newDetallesEnvio[indiceDetalleEnvioInactivar];

    // // detalleEnvioInactivar.eliminado = true;

    // newDetallesEnvio.splice(indiceDetalleEnvioInactivar, 1, {
    //   ...detalleEnvioInactivar,
    //   eliminado: true,
    // });
    // setDetalleEnvio(newDetallesEnvio);

    notification.success({
      message: "Operación exitosa",
      description: "Se inhabilito el registro correctamente.",
    });
  }

  async function handleReenviarNotificacionPorId(detalleEnvioId) {
    await DetalleEnvioService.reenviarNotificacionPorId(
      detalleEnvioId,
      getUsuarioAutenticado().organizacionId,
      getUsuarioAutenticado().empresaSeleccionada.id
    );

    notification.success({
      message: "Operación exitosa",
      description: "Se realizo el reenvío correctamente.",
    });

    cargarDetallePorEstado(estado);
  }

  const mostrarModalActualizarCorreo = (documento) => {
    setDocumentoSeleccionado(documento);
    setVisibleModalActualizarCorreo(true);
  };

  const mostrarModalHistorialNotificacion = (documento) => {

    setDocumentoSeleccionado(documento);
    setVisibleModalHistorialNotificacion(true);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columnas = [
    {
      title: "Documento",
      dataIndex: ["colaborador", "numeroDocumentoIdentidad"],
      align: "center",
      width: "10%",
    },
    {
      title: "Colaborador",
      dataIndex: ["colaborador", "nombreCompleto"],
      width: "25%",
    },
    {
      title: "Bandeja",
      dataIndex: ["colaborador", "correoElectronico"],
      render: (text, fila) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {fila.colaborador.correoElectronico && (
              <span>
                <MailOutlined /> {fila.colaborador.correoElectronico}
              </span>
            )}

            {getOrganizacion().notificarPorSms &&
              fila.colaborador.numeroCelular && (
                <span>
                  <PhoneOutlined /> {fila.colaborador.numeroCelular}
                </span>
              )}
          </div>
        );
      },

      // onCell: (record) => ({
      //   record,
      //   editable: true,
      //   dataIndex: ["colaborador", "correoElectronico"],
      //   title: "Email",
      //   handleSave: handleOnSaveCorreoElectronico,
      // }),
    },
    {
      title: "Notificación",
      dataIndex: "estadoNotificacion",
      align: "center",
      width: "10%",
      render: (text, fila) => {
        // return <EtiquetaEstadoNotificacion documento={fila} />
        if (
          fila.estadoRegistro === EstadoRegistro.ACTIVO &&
          fila.estadoDocumento === EstadoDocumento.GENERADO
        ) {
          return <EtiquetaEstadoNotificacion documento={fila} />;
        }

        if (fila.estadoRegistro === EstadoRegistro.INACTIVO) {
          return {
            children: (
              <span>
                <Tag color="default" icon={<MinusCircleOutlined />}>
                  INHABILITADO
                </Tag>
              </span>
            ),
            props: {
              colSpan: 3,
            },
          };
        }

        if (fila.estadoProcesoId && fila.estadoProcesoId === 6) {
          //PENDIENTE DE APROBAR
          return {
            children: (
              <span style={{ color: "#7d7939" }}>
                <FileUnknownOutlined /> Esperando aprobación ...
              </span>
            ),
            props: {
              colSpan: 3,
            },
          };
        }

        return {
          children: (
            <span style={{ color: "#7d7939" }}>
              <SyncOutlined spin style={{ color: "#7d7939" }} /> Procesando...
            </span>
          ),
          props: {
            colSpan: 3,
          },
        };
      },
    },
    {
      title: "Visualizado",
      dataIndex: "estadoVisualizacion",
      align: "center",
      width: "10%",
      render: (text, fila) => {
        // return <EtiquetaEstadoVisualizacion documento={fila} />
        if (
          fila.estadoRegistro === EstadoRegistro.ACTIVO &&
          fila.estadoDocumento === EstadoDocumento.GENERADO
        ) {
          return <EtiquetaEstadoVisualizacion documento={fila} />;
        }

        return {
          props: {
            colSpan: 0,
          },
        };
      },
    },
    {
      title: "Firmado",
      dataIndex: "firmado",
      align: "center",
      width: "10%",
      render: (text, fila) => {
        // return <EtiquetaEstadoFirmaColaborador documento={fila} />
        if (
          fila.estadoRegistro === EstadoRegistro.ACTIVO &&
          fila.estadoDocumento === EstadoDocumento.GENERADO
        ) {
          return <EtiquetaEstadoFirmaColaborador documento={fila} />;
        }

        return {
          props: {
            colSpan: 0,
          },
        };
      },
    },
    // {
    //   title: "Plataforma Visualización",
    //   dataIndex: "visualizacionPlataforma",
    //   align: "center",
    //   width: "150px",
    // },
    // {
    //   title: "Fecha Visualización",
    //   dataIndex: "visualizacionFecha",
    //   align: "center",
    //   width: "150px",
    // },
    // {
    //   title: "Estado",
    //   //dataIndex: "estadoProcesoNombre",
    //   align: "center",
    //   width: "160px",
    //   ...adicionarColumnasFiltro("estadoProcesoNombre"),
    //   render: (fila) =>
    //     fila.estadoProcesoId === 1 && fila.visualizado === "Si" ? (
    //       <Tag color="success" style={{ textTransform: "uppercase" }}>
    //         Visualizado
    //       </Tag>

    //     ) : fila.estadoProcesoId === 8 ? (
    //       <Tag color="gold" style={{ textTransform: "uppercase" }}>
    //         Visualizado y firmado
    //       </Tag>
    //     )
    //       : fila.estadoProcesoNombre === "Procesando" ? (
    //         <Tag color="warning" style={{ textTransform: "uppercase" }}>
    //           {fila.estadoProcesoNombre}
    //         </Tag>
    //       ) : (
    //         <Tag color="blue" style={{ textTransform: "uppercase" }}>
    //           {fila.estadoProcesoNombre}
    //         </Tag>
    //       ),
    // },
    {
      title: "Acciones",
      align: "center",
      width: "10%",
      render: (fila) => (
        <MenuAcciones
          documento={fila}
          onClickVerDocumento={handleOnClickVerDocumento}
          onClickReenviarNotificacion={showReenviarIndividualConfirm}
          onClickInhabilitarDocumento={showDeleteConfirm}
          onClickHabilitarDocumento={handleOnClickHabilitarDetalleEnvio}
          onClickActualizarCorreo={mostrarModalActualizarCorreo}
          onClickHitorialNotificacion={mostrarModalHistorialNotificacion}
        
          />
        // <React.Fragment>
        //   {fila.eliminado ? (
        //     <a onClick={() => handleOnClickHabilitarDetalleEnvio(fila.id)}>
        //       Habilitar
        //     </a>
        //   ) : (
        //       <Space size={8}>
        //         <Select
        //           placeholder="Seleccione la Acción"
        //           value={defaultValue}
        //           style={{ width: 200 }}
        //           onSelect={(value) => handleAccionesOnClick(value, fila)}
        //           loading={filaSeleccionada === fila.id ? loading : false}
        //           allowClear
        //         >
        //           <Option value="default">Seleccione la Acción </Option>
        //           <Option value="ver">Ver </Option>
        //           <Option value="inhabilitar">Inhabilitar </Option>
        //           <Option value="reenviar">Reenviar </Option>
        //           {/*<Option value="cambiarcorreo">Cambiar Correo electrónico</Option>*/}
        //         </Select>
        //       </Space>
        //     )}
        // </React.Fragment>
      ),
    },
  ];

  async function handleOnClickHabilitarDetalleEnvio(detalleEnvioId) {
    setFilaSeleccionada(detalleEnvioId);
    await DetalleEnvioService.habilitar(detalleEnvioId);

    setDetalleEnvio(
      detalleEnvio.map((detalleEnvio) => {
        if (detalleEnvio.id === detalleEnvioId) {
          return {
            ...detalleEnvio,
            estadoRegistro: EstadoRegistro.ACTIVO,
            eliminado: false,
          };
        }
        return detalleEnvio;
      })
    );

    notification.success({
      message: "Operación exitosa",
      description: "Se habilitado el registro.",
    });
  }

  const actualizarInformacionColaborador = (
    documentoId,
    correoElectronico,
    numeroCelular
  ) => {
    const _documentos = detalleEnvio.slice();
    const indice = _documentos.findIndex(
      (documento) => documento.id === documentoId
    );
    const documentoModificar = _documentos[indice];
    const documentoModificado = {
      ...documentoModificar,
      colaborador: {
        ...documentoModificar.colaborador,
        correoElectronico: correoElectronico,
        numeroCelular: numeroCelular,
      },
    };

    _documentos.splice(indice, 1, documentoModificado);

    setDetalleEnvio(_documentos);
  };

  async function handleOnSaveCorreoElectronico(row) {
    try {
      setLoading(true);
      const newDetallesEnvio = [...detalleEnvio];
      const indiceDetalleEnvioModificar = newDetallesEnvio.findIndex(
        (item) => row.id === item.id
      );
      const detalleEnvioModificar =
        newDetallesEnvio[indiceDetalleEnvioModificar];

      if (
        detalleEnvioModificar.colaborador.correoElectronico !==
        row.colaborador.correoElectronico
      ) {
        const formulario = {
          id: row.id,
          correoElectronico: row.colaborador.correoElectronico,
        };
        await DetalleEnvioService.actualizarCorreoE(formulario);
        notification.success({
          message: "Operación exitosa",
          description: "Se actualizó el correo electrónico correctamente.",
        });
        // const newData = [...detalleEnvio];
        // const index = newData.findIndex((item) => row.id === item.id);
        // const item = newData[index];
        newDetallesEnvio.splice(indiceDetalleEnvioModificar, 1, {
          ...detalleEnvioModificar,
          ...row,
        });
        setDetalleEnvio(newDetallesEnvio);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  function handleOnOkModal() {
    setVisible(false);
  }

  function onClickMenu(opcion) {
    setEstado(opcion.key);
    cargarDetallePorEstado(opcion.key);
  }

  async function handleOnOkReenvioMasivo() {
    const reenvioMasivoDetalle =
      await DetalleEnvioService.reenviarNotificacionMasivamente(
        getUsuarioAutenticado().organizacionId,
        getUsuarioAutenticado().empresaSeleccionada.id,
        envioId,
        selectedRowKeys
      );

    //setSelectedRowsKeys([]);

    notification.success({
      message: "Operación exitosa",
      description: "Se realizo el reenvio de los registros seleccionados.",
    });
  }

  async function cargarDetallePorEstado(estadoDetalleId) {
    const detalleEnvio = await DetalleEnvioService.listar(
      envioId,
      estadoDetalleId
    );
    setDetalleEnvio(detalleEnvio);
  }
  function handleOnClickVolverSeguimieto() {
    history.push("/documento-laboral");
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowsKeys(selectedRowKeys);
    },
  };
  function handleOnClickfiltrarVisualizados() {
    setFiltradoDetalleEnvio("visualizado");
  }
  function handleOnClickfiltrarNoVisualizados() {
    setFiltradoDetalleEnvio("novisualizados");
  }
  function handleOnClickfiltrarErroneos() {
    setFiltradoDetalleEnvio("erroneos");
  }
  function handleOnClickfiltrarTodos() {
    setFiltradoDetalleEnvio("todos");
  }
  return (
    <ContainerListarDetalle
      icono={<FolderOpenOutlined style={{ fontSize: "50px" }} />}
      titulo={getDatosEnvio().documentoNombre}
      descripcion={getDatosEnvio().descripcion}
      sede={getDatosEnvio().sedeNombre}
      // menu={menu}
      menuMasOpciones={menuMasOpciones}
    >
      <ModalPDF
        ruta={url}
        visible={visible}
        handleOnOkModal={handleOnOkModal}
        documentoNombre={documentoNombre}
        fechaFirma={fechaFirma}
        representanteLegal={representanteLegal}
        nombreColaborador={nombreColaborador}
        fechaFirmaColaborador={fechaFirmaColaborador}
        estadoDetalleEnvio={estadoDetalleEnvio}
      />

      {/* <div style={{ marginBottom: 16 }}></div> */}
      <div style={{ textAlign: "center" }}>
        <Space size={80}>
          <Tag color="blue">
            <Button type="text" onClick={handleOnClickfiltrarTodos}>
              <span style={{ color: "#108ee9" }}>
                <Text strong style={{ color: "#108ee9" }}>
                  {detalleEnvio.length}
                </Text>
                <p> Total Documentos</p>
              </span>
            </Button>
          </Tag>
          <Tag color="green">
            <Button type="text" onClick={handleOnClickfiltrarVisualizados}>
              <span style={{ color: "#1BB00C" }}>
                <Text strong style={{ color: "#1BB00C" }}>
                  {detalleEnvioVisualizados.length}
                </Text>
                <p>Visualizados</p>
              </span>
            </Button>
          </Tag>
          <Tag color="gold">
            <Button type="text" onClick={handleOnClickfiltrarNoVisualizados}>
              <span style={{ color: "#E1A511" }}>
                <Text strong style={{ color: "#E1A511" }}>
                  {detalleEnvioNoVisualizados.length}
                </Text>
                <p>No visualizados</p>
              </span>
            </Button>
          </Tag>
          <Tag color="red">
            <Button type="text" onClick={handleOnClickfiltrarErroneos}>
              <span style={{ color: "#DA0C1F" }}>
                <Text strong style={{ color: "#DA0C1F" }}>
                  {detalleEnvioErroneos.length}
                </Text>
                <p>Error al notificar</p>
              </span>
            </Button>{" "}
          </Tag>
        </Space>
      </div>
      <div style={{ marginBottom: 16 }}></div>

      <FiltroTabla envioId={envioId} setDetalleEnvio={setDetalleEnvio} />

      <Table
        components={components}
        rowKey={(detalleEnvio) => detalleEnvio.id}
        columns={columnas}
        //dataSource={detalleEnvio}
        dataSource={
          filtradoDetalleEnvio === "visualizado"
            ? detalleEnvioVisualizados
            : filtradoDetalleEnvio === "novisualizados"
            ? detalleEnvioNoVisualizados
            : filtradoDetalleEnvio === "erroneos"
            ? detalleEnvioErroneos
            : filtradoDetalleEnvio === "todos"
            ? detalleEnvio
            : detalleEnvio
        }
        //rowClassName="editable-row"
        // pagination={{
        //   pageSize: [10],
        // }}
        pagination={false}
        rowClassName={(detalleEnvio) =>
          detalleEnvio.eliminado ? "detalle-envio_registro-eliminado" : ""
        }
        rowSelection={{
          type: "checkbox",
          // columnWidth: 15,
          ...rowSelection,
        }}
        scroll={{ y: 600 }}
      />
      <Button
        type="primary"
        icon={<ArrowLeftOutlined style={{ fontSize: "20px" }} />}
        onClick={handleOnClickVolverSeguimieto}
      ></Button>

      <ModalActualizarCorreo
        visible={visibleModalActualizarCorreo}
        setVisible={setVisibleModalActualizarCorreo}
        documento={documentoSeleccionado}
        actualizarInformacionColaborador={actualizarInformacionColaborador}
      />
      <ModalHistorialNotificacion
      visible={visibleModalHistorialNotificacion}
      setVisible={setVisibleModalHistorialNotificacion}
      documento={documentoSeleccionado}
      empresaId={getUsuarioAutenticado().empresaSeleccionada.id}
      />
    </ContainerListarDetalle>
  );
}
