import React, { Fragment, useContext, useEffect, useState } from "react";
import { Steps, Spin, Modal, Descriptions, Button, Space, message, Tag, notification, Popconfirm } from "antd";
import {
  pasoActualAtom,
  resumenAtom,
  datosColaboradorStepAtom,
  colaboradoresAgregadosAtom,
  listaTipoPlantillaEnvioAtom,
  rutaStorageZipAtom,
} from "../../OnboardingIndividualStore";
import { useAtom } from "jotai";
import { OnboardingColaboradorService } from "services/OnboardingColaboradorService";
import { SecurityContext } from "context/SecurityContextProvider";
import { useHistory } from "react-router-dom";

const ResumenStep = () => {
  let history = useHistory();
  const [, setPasoActual] = useAtom(pasoActualAtom);
  const [resumen, setResumen] = useAtom(resumenAtom);
  const [, setColaboradores] = useAtom(colaboradoresAgregadosAtom);
  const [, setDatosColaborador] = useAtom(datosColaboradorStepAtom);
  const [, setListaPlantillas] = useAtom(listaTipoPlantillaEnvioAtom);
  const [plantillas, setPlantillas] = useState([]);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [rutaStorageZip] = useAtom(rutaStorageZipAtom);

  const onClickFinalizar = async () => {
    const data = { ...resumen, rutaStorageZip: rutaStorageZip, usuarioLogin: getUsuarioAutenticado().login };
    const onboardingInsert = await OnboardingColaboradorService.notificar(data);
    notification.success({
      message: "Notificación correcta",
      description: "El documento onboarding ha sido notificado.",
    });
    limpiarDatos();
    history.push("/onboarding/seguimiento");
  };

  const onClickBtnAtras = () => {
    setPasoActual(2);
  };

  const limpiarDatos = () => {
    setResumen();
    setColaboradores([]);
    setPasoActual(1);
    setDatosColaborador();
    setListaPlantillas();
  };

  useEffect(() => {
    const plantillasOnboarding = resumen.documentos.map((documento) => documento.tipoPlantilla.nombre);
    const plantillasOnboardingUnicas = plantillasOnboarding.filter(
      (plantilla, indice) => plantillasOnboarding.indexOf(plantilla) === indice
    );
    setPlantillas(plantillasOnboardingUnicas);
  }, []);

  return (
    <div style={{ padding: "0 20%" }}>
      <Descriptions bordered size="small" column={1}>
        <Descriptions.Item label="Periodo">{resumen?.periodo.format("MM/YYYY")}</Descriptions.Item>
        <Descriptions.Item label="Total Colaboradores">{resumen?.documentos.length}</Descriptions.Item>
        <Descriptions.Item label="Plantillas a notificar">
          {plantillas.map((plantilla) => {
            return (
              <Tag color="cyan" key={plantilla}>
                {plantilla}
              </Tag>
            );
          })}
        </Descriptions.Item>
      </Descriptions>
      <br />
      <Space>
        <Button size="large" onClick={onClickBtnAtras}>
          Atras
        </Button>
        <Popconfirm
          title="¿Está seguro de realizar la notificación de onboarding?"
          onConfirm={onClickFinalizar}
          okText="SI"
          cancelText="NO"
        >
          <Button size="large" type="primary">
            Notificar
          </Button>
        </Popconfirm>
      </Space>
    </div>
  );
};
export default ResumenStep;
