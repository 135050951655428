import { atom } from "jotai";

export const aprobadoresAtom = atom([]);
export const aprobadorSeleccionadoAtom=atom([]);

export const visibleDrawerCamposPersonalizadosAtom = atom(false);
export const camposPersonalizadosAtom = atom([]);
export const editarCampoAtom = atom(false);
export const campoSeleccionadoAtom = atom();

export const visibleDrawerArchivosAtom = atom(false);
export const archivosAdjuntosAtom = atom([]);
export const editarArchivoAtom = atom(false);
export const archivoSeleccionadoAtom = atom();

export const aprobadoresOtrosCargosAtom = atom([]);
export const aprobadorSeleccionadoOtrosCargosAtom = atom([])
