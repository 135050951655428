import { ApiService } from "./ApiService";
import { ApiUrl } from "./ApiUrl";

const ControlVacacionalService = {
	async listar() {
		const respuesta = await ApiService(ApiUrl.VACACIONES).get("/control-vacacional");
		return respuesta.data;
	},
	async calcular(data) {
		const respuesta = await ApiService(ApiUrl.VACACIONES).post("/calcular", data);
		return respuesta.data;
	},
};
export default ControlVacacionalService;
