import React, { useState, useEffect } from "react";
import { Modal, Button, Switch, Typography, Space, Row, Col, Card, Drawer, Divider } from "antd";

//import PDFViewer from "pdf-viewer-reactjs";
import { PDFObject } from "react-pdfobject";

const { Text } = Typography;
export function ModalPDF({ ruta, visible, handleOnOkModal, }) {

  if (ruta === "") {
    return null;
  }


  return (
    <Modal
      // title={
      //   <Row>
      //     <Col span={24}>
      //       <Space>
      //         <Button
      //           icon={<DownloadOutlined />}
      //           //onClick={handleOnClickBtnDescargar}
      //         >
      //           Descargar
      //         </Button>
      //         <Button icon={<PrinterOutlined />}>Imprimir</Button>
      //       </Space>
      //     </Col>
      //   </Row>
      // }
      title={<Col style={{ height: "20px" }}></Col>}
      visible={visible}
      onCancel={handleOnOkModal}
      style={{
        top: 20,
        bottom: 20,
      }}
      bodyStyle={{ padding: "0px", height: "calc(100vh - 50px)" }}
      footer={null}
      width="96vw"
      height="96vh"
    //  afterClose={cerrarModal}
    //  destroyOnClose={true}
    >
      {/* <object
        data={`${ruta}`}
        type="application/pdf"
        height="100%"
        width="100%"
      /> */}
      {/* {informacion?(<Row gutter={15}>
                      <Col span={12}>
                      <PDFObject url={ruta} width={"48vw"} height={"calc(100vh - 50px)"} />
                      </Col>
                      <Col span={12}>      
                      <Card
                        title="Información"
                        style={{ height: "98%", width: "98%" }}
                        bordered={true}
                       > 
                      </Card>
                      </Col>
                    </Row>)
         :(<PDFObject url={ruta} width={"96vw"} height={"calc(100vh - 50px)"} />)} */}
      <PDFObject url={ruta} width={"96vw"} height={"calc(100vh - 50px)"} />

    </Modal>

  );
}
