import React from "react";
import {UnorderedListOutlined} from"@ant-design/icons";
import { Provider as JotaiProvider, useAtom } from "jotai";
import { ListaCargoLegajo } from "./componentes/ListaCargaLegajo";
import { Container } from "components/Container"

import { ModalCrearCargaLegajo}  from "./componentes/ModalCrearCargaLegajo";
import { ModalAgregarTipoDocumento}  from "./componentes/ModalAgregarTipoDocumento";
import { Botones } from "./componentes/Botones";
const LegajoCargaPage = () => {
 
  return (
    <JotaiProvider>
      <Container titulo="Carga Legajo" icono={<UnorderedListOutlined/>} botonExtra={<Botones/>}>
          <ListaCargoLegajo />  
          <ModalCrearCargaLegajo/>
          <ModalAgregarTipoDocumento/>
      </Container>      
     </JotaiProvider>
  );
};
export default LegajoCargaPage;
