import { atom } from 'jotai'

export const consultasAtom = atom([]);
export const visibleModalConsultaAtom =atom(false);
export const consultaSeleccionadaAtom = atom([]);
export const tipoDocumentosAtom=atom([]);
export const periodoAtom = atom([]);
export const paginaActualAtom =atom([]);
export const paginaSeguimientoAtom = atom([]);
export const tipoDocumentoConsultasAtom =atom([]);
export const totalPaginacionEnviosAtom= atom ([]);
export const detalleConsultaAtom= atom([]);
export const numeroRespuestasAtom = atom([]);
//
export const filtrosSeleccionadosAtom= atom(undefined);
export const periodoInicioAtom =atom(undefined);
export const periodoFinAtom = atom(undefined);
