import React, { useContext, useEffect, useState } from "react";
import {
	Form,
	Input,
	Button,
	Checkbox,
	Select,
	Upload,
	message,
	Space,
	Divider,
	Row,
	Col,
} from "antd";

import {
	CheckOutlined,
	UploadOutlined,
	FolderTwoTone,
	FolderFilled,
} from "@ant-design/icons";

import { useModalConfirmar } from "store/global";
import { SecurityContext } from "context/SecurityContextProvider";
import * as atoms from "../EditarFormulariosStore";
import { useAtom } from "jotai";
import { useParams } from "react-router-dom";
import { FormularioService } from "services/FormularioService";
import CertificadoService from "services/CertificadoService";
import { CategoriaDocumento } from "enums/CategoriaDocumento";
import CarpetaService from "services/CarpetaService";
import UsuarioService from "services/UsuarioService";

const FormularioEditar = () => {
	let { formularioId } = useParams();
	const [frmFormulario] = Form.useForm();

	const mostrarModalConfirmacion = useModalConfirmar((state) => state.mostrarModal);
	const { getUsuarioAutenticado } = useContext(SecurityContext);

	const [, setFormulario] = useAtom(atoms.formulario);

	const [certificados, setCertificados] = useState([]);
	const [requiereFirmaRepresentanteLegal, setRequiereFirmaRepresentanteLegal] = useState(false);
	const [nombreRepresentanteLegal, setNombreRepresentanteLegal] = useState(undefined);
	const [tipoDocumentoSeleccionado, setTipoDocumentoSeleccionado] = useState(undefined);
	const [uploadFormat, setUploadFormat] = useState(false);
	const [defaultList, setDefaultList] = useState([
		{
			uid: "1",
			name: "Formato_Carga.pdf",
			status: "done",
		},
	]);
	const [carpetas, setCarpetas] = useState([]);
	const [uploadLink, setUploadLink] = useState(false);
	const [aprobadores, setAprobadores] = useState([]);
	const [responsables, setResponsables] = useState([]);
	const [isDisabled, setIsDisabled] = useState(false);
	const [uploadDeclaracion, setUploadDeclaracion] = useState(false);

	const requiereAprobacion = Form.useWatch('requiereAprobacion', frmFormulario);
	const documentoInterno = Form.useWatch('interno', frmFormulario);
	const requiereFormatoCarga = Form.useWatch('requiereFormatoCarga', frmFormulario);

	const { Option } = Select;

	useEffect(() => {
		const fetch = async () => {
			try {
				const _formulario = await FormularioService.buscarPorFormularioId(
					formularioId
				);
				setFormulario(_formulario);

				console.log("_formulario", _formulario);
				const _carpetas = await CarpetaService.listar(
					getUsuarioAutenticado().empresaSeleccionada.id
				);
				setCarpetas(_carpetas);

				const aprobadores = await UsuarioService.listar();
				setAprobadores(aprobadores.map((aprobador) => {
					return {
						value: aprobador.id,
						label: aprobador.nombreCompleto
					}
				}));

				const responsables = await UsuarioService.listar();
				setResponsables(responsables.map((responsable) => {
					return {
						value: responsable.id,
						label: responsable.nombreCompleto
					}
				}));

				const certificados = await CertificadoService.listar("", "", -1);
				setCertificados(certificados);
				setRequiereFirmaRepresentanteLegal(
					_formulario.requiereFirmaRepresentanteLegal
				);
				setNombreRepresentanteLegal(_formulario.nombreRepresentanteLegal);

				frmFormulario.setFieldsValue({
					id: _formulario.id,
					nombre: _formulario.nombre,
					mostrarEnPortalColaborador: _formulario.mostrarEnPortalColaborador,
					requiereFirmaColaborador: _formulario.requiereFirmaColaborador,
					requiereAprobacion: _formulario.requiereAprobacion,
					requiereFirmaRepresentanteLegal:
						_formulario.requiereFirmaRepresentanteLegal,
					interno: _formulario.interno,
					certificadoDigitalIdRepresentanteLegal:
						_formulario.certificadoDigitalIdRepresentanteLegal,
					tipoDocumento: _formulario.categoriaDocumento.id,
					requierePlantilla: _formulario.requierePlantilla,
					fileTemplate:
						_formulario.requierePlantilla === true ? defaultList : null,
					carpeta: {
						id: _formulario.carpeta?.id,
						descripcion: _formulario.carpeta?.descripcion,
					},
					opcional: _formulario.opcional,
					mostrarlink: _formulario.mostrarlink,
					link: _formulario.link,
					mostrarDeclaracion: _formulario.mostrarDeclaracion,
					declaracion: _formulario.declaracion,
					aprobadores: _formulario.aprobadores && _formulario.aprobadores.length > 0 ? _formulario.aprobadores : undefined,
					responsables: _formulario.responsables && _formulario.responsables.length > 0 ? _formulario.responsables : undefined,
					requiereFormatoCarga: _formulario.requiereFormatoCarga,
					formatosCarga: _formulario.formatosCarga && _formulario.formatosCarga.length > 0 ? _formulario.formatosCarga : undefined
				});
				setTipoDocumentoSeleccionado(_formulario.categoriaDocumento);
				if (_formulario.requierePlantilla) {
					setUploadFormat(_formulario.requierePlantilla);
				}
				if (_formulario.mostrarlink) {
					setUploadLink(_formulario.mostrarlink);
				}

				if (_formulario.mostrarDeclaracion) {
					setUploadDeclaracion(_formulario.mostrarDeclaracion);
				}

				if (_formulario.categoriaDocumento?.id == CategoriaDocumento.COMUNICADOS_FORMULARIOS.id) {
					setIsDisabled(true);
				} else {
					setIsDisabled(false);
				}
			} catch (error) {
				console.error(error);
			}
		};
		fetch();
	}, []);

	const onClickBtnActualizarFormulario = async (formulario) => {
		if (requiereFirmaRepresentanteLegal) {
			formulario.nombreRepresentanteLegal = nombreRepresentanteLegal;
		}

		formulario.empresaId = getUsuarioAutenticado().empresaSeleccionada.id;
		formulario.categoriaDocumento = tipoDocumentoSeleccionado;
		formulario.carpeta = {
			id: formulario.carpeta.id,
			descripcion: formulario.carpeta.descripcion,
		};
		const formData = new FormData();
		if (formulario.fileTemplate != null && formulario.fileTemplate.length === 1)
			formData.append(
				"plantillaCarga",
				formulario.fileTemplate[0].originFileObj
			);
		formData.append(
			"tipoDocumentoRequest",
			new Blob([JSON.stringify({ ...formulario, fileTemplate: undefined })], {
				type: "application/json",
			})
		);
		setFormulario(formData);
		// setFormulario({
		//   ...formulario,
		//   empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
		//   categoriaDocumento: tipoDocumentoSeleccionado,
		// });
		mostrarModalConfirmacion();
	};

	const onSeleccionarCertificado = (value, option) => {
		setNombreRepresentanteLegal(option.children);
	};

	useEffect(() => {
		if (!requiereFirmaRepresentanteLegal) {
			setNombreRepresentanteLegal(null);
		}
	}, [requiereFirmaRepresentanteLegal]);

	const normFile = (e) => {
		console.log(e);
		if (Array.isArray(e)) {
			return e;
		}
		return e?.fileList;
	};

	const onChangeCargaFormato = (e) => {
		if (!e.target.checked)
			frmFormulario.setFieldValue("fileTemplate", undefined);
		setDefaultList(null);
		setUploadFormat(e.target.checked);
	};
	const onChangeSelectCarpeta = (value, option) => {
		frmFormulario.setFieldsValue({
			carpeta: { descripcion: option.descripcion },
		});
	};

	const onChangeMostrarLink = (e) => {
		setUploadLink(e.target.checked);
	};

	const onChangeHabilitarDeclaracion = (e) => {
		setUploadDeclaracion(e.target.checked);
	};

	return (
		<Form
			layout="vertical"
			size="large"
			onFinish={onClickBtnActualizarFormulario}
			form={frmFormulario}
		>
			<Form.Item name="id" label="id" noStyle>
				<Input type="hidden" />
			</Form.Item>
			<Form.Item
				label="Tipo Documento"
				name="tipoDocumento"
				rules={[
					{
						required: true,
						message: "El campo Tipo documento es obligatorio.",
					},
				]}
			>
				<Select placeholder="Seleccione tipo documento" disabled={true}>
					<Select.Option value={CategoriaDocumento.FORMULARIOS.id}>
						{CategoriaDocumento.FORMULARIOS.nombre}
					</Select.Option>
					<Select.Option value={CategoriaDocumento.CARGA_DOCUMENTO.id}>
						{CategoriaDocumento.CARGA_DOCUMENTO.nombre}
					</Select.Option>
					<Select.Option value={CategoriaDocumento.FORMATO_AUSENCIA.id}>
						{CategoriaDocumento.FORMATO_AUSENCIA.nombre}
					</Select.Option>
					<Select.Option value={CategoriaDocumento.COMUNICADOS_FORMULARIOS.id}>
						{CategoriaDocumento.COMUNICADOS_FORMULARIOS.nombre}
					</Select.Option>
				</Select>
			</Form.Item>
			<Form.Item
				label="Nombre"
				name="nombre"
				rules={[
					{
						required: true,
						message: "El campo nombre es obligatorio.",
					},
				]}
			>
				<Input autoComplete="off" />
			</Form.Item>

			<Form.Item label="Carpeta" name={["carpeta", "id"]}>
				<Select
					onChange={onChangeSelectCarpeta}
					optionLabelProp="label"
					placeholder="Seleccione la carpeta"
				>
					{carpetas.length > 0 &&
						carpetas.map((carpeta) => {
							return (
								<Option
									key={carpeta.id}
									value={carpeta.id}
									label={
										<div>
											<FolderTwoTone
												style={{ fontSize: "20px", color: "#F7D358" }}
												twoToneColor="#F7D358"
											/>{" "}
											{carpeta.ruta}
										</div>
									}
									descripcion={carpeta.descripcion}
								>
									<Space size={carpeta.nivel * 10}>
										<span></span>
										<span>
											{carpeta.nivel === 0 ? (
												<FolderFilled
													style={{ fontSize: "20px", color: "#F7D358" }}
												/>
											) : (
												<FolderTwoTone
													style={{ fontSize: "18px", color: "#F7D358" }}
													twoToneColor="#F7D358"
												/>
											)}{" "}
											{carpeta.descripcion}
										</span>
									</Space>
								</Option>
							);
						})}
				</Select>
			</Form.Item>
			<Form.Item name={["carpeta", "descripcion"]} noStyle>
				<Input type="hidden" />
			</Form.Item>

			<div style={{ fontWeight: "600", marginBottom: "10px" }}>
				Configuración
			</div>
			<Divider />
			<Row gutter={16}>
				<Col span={12}>
					<Form.Item>
						<Form.Item
							name="mostrarEnPortalColaborador"
							valuePropName="checked"
						>
							<Checkbox disabled={isDisabled}>
								<span style={{ display: "block" }}>
									Mostrar en portal del colaborador
								</span>
								<span style={{ color: "gray" }}>
									Formulario estará siempre disponible para el colaborador.
								</span>
							</Checkbox>
						</Form.Item>
						<Form.Item name="requiereFirmaColaborador" valuePropName="checked">
							<Checkbox
								disabled={
									tipoDocumentoSeleccionado?.id ===
										CategoriaDocumento.CARGA_DOCUMENTO.id
										? true
										: isDisabled
								}
							>
								<span style={{ display: "block" }}>
									Requiere firma colaborador
								</span>
								<span style={{ color: "gray" }}>
									Formulario debe tener la firma del colaborador.
								</span>
							</Checkbox>
						</Form.Item>

						<Form.Item name="requiereAprobacion" valuePropName="checked">
							<Checkbox disabled={isDisabled}>
								<span style={{ display: "block" }}>Requiere Aprobación</span>
								<span style={{ color: "gray" }}>
									Formulario requiere aprobación.
								</span>
							</Checkbox>
						</Form.Item>

						{requiereAprobacion
							&&
							<Form.Item
								name="aprobadores"
								label="Seleccione los aprobadores"
								style={{ paddingLeft: "25px" }}
							>
								<Select
									mode="multiple"
									allowClear
									options={aprobadores}
									filterOption={(input, option) =>
										(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
									}
								/>
							</Form.Item>
						}

						<Form.Item
							name="requiereFirmaRepresentanteLegal"
							valuePropName="checked"
						>
							<Checkbox
								onChange={(e) =>
									setRequiereFirmaRepresentanteLegal(e.target.checked)
								}
								disabled={
									tipoDocumentoSeleccionado?.id ===
										CategoriaDocumento.CARGA_DOCUMENTO.id
										? true
										: false
								}
							>
								<span style={{ display: "block" }}>
									Requiere Firma Represente Legal
								</span>
								<span style={{ color: "gray" }}>
									Formulario debe ser firmado por el representante legal.
								</span>
							</Checkbox>
						</Form.Item>
						{requiereFirmaRepresentanteLegal && (
							<div style={{ paddingLeft: "25px" }}>
								<Form.Item
									name="certificadoDigitalIdRepresentanteLegal"
									label="Seleccione el certificado digital del representante legal"
									rules={[
										{
											required: true,
											message: "El campo certificado digital es obligatorio.",
										},
									]}
								>
									<Select
										allowClear
										style={{ width: "90%" }}
										onChange={onSeleccionarCertificado}
									>
										{certificados.length > 0 &&
											certificados.map((certificado) => {
												return (
													<Select.Option
														key={certificado.id}
														value={certificado.id}
													>
														{certificado.representanteLegalNombre}
													</Select.Option>
												);
											})}
									</Select>
								</Form.Item>
							</div>
						)}

						<Form.Item name="interno" valuePropName="checked">
							<Checkbox disabled={isDisabled}>
								<span style={{ display: "block" }}>¿Documento interno?</span>
								<span style={{ color: "gray" }}>
									Este documento solo estará disponible para usuarios
									administradores.
								</span>
							</Checkbox>
						</Form.Item>

						{documentoInterno
							&&
							<Form.Item
								name="responsables"
								label="Seleccione los responsables"
								style={{ paddingLeft: "25px" }}
							>
								<Select
									mode="multiple"
									allowClear
									options={responsables}
									filterOption={(input, option) =>
										(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
									}
								/>
							</Form.Item>
						}


						<Form.Item name="requierePlantilla" valuePropName="checked">
							<Checkbox
								onChange={onChangeCargaFormato}
								disabled={
									tipoDocumentoSeleccionado?.id ===
										CategoriaDocumento.CARGA_DOCUMENTO.id
										? false
										: isDisabled
								}
							>
								<span style={{ display: "block" }}>Subir Formato</span>
								<span style={{ color: "gray" }}>
									Agregar el formato PDF que se para los archivos de carga.
								</span>
							</Checkbox>
						</Form.Item>

						{uploadFormat && (
							<Form.Item
								name="fileTemplate"
								valuePropName="fileList"
								getValueFromEvent={normFile}
								noStyle
								rules={[{ required: true, message: "Cargue un archivo." }]}
							>
								<Upload
									defaultFileList={defaultList}
									beforeUpload={() => {
										return false;
									}}
									accept="application/pdf"
									maxCount={1}
								>
									<Button>
										<UploadOutlined /> Cargar Formato ( max. 1 )
									</Button>
								</Upload>
							</Form.Item>
						)}
					</Form.Item>
				</Col>

				<Col span={12}>
					<Form.Item name="opcional" valuePropName="checked">
						<Checkbox disabled={isDisabled}>
							<span style={{ display: "block" }}>Opcional</span>
							<span style={{ color: "gray" }}>
								El formulario o carga de documento será opcional.
							</span>
						</Checkbox>
					</Form.Item>
					<Form.Item name="mostrarlink" valuePropName="checked">
						<Checkbox
							onChange={onChangeMostrarLink}
							disabled={isDisabled}
						>
							<span style={{ display: "block" }}>Mostrar Link</span>
							<span style={{ color: "gray" }}>
								El formulario o carga de documento mostrará el Link.
							</span>
						</Checkbox>
					</Form.Item>
					{uploadLink && (
						<Form.Item
							label="Link"
							name="link">
							<Input
								placeholder="Ingresar Link"
							/>
						</Form.Item>
					)}
					<Form.Item name="mostrarDeclaracion" valuePropName="checked">
						<Checkbox
							onChange={onChangeHabilitarDeclaracion}
							disabled={isDisabled}
						>
							<span style={{ display: "block" }}>Mostrar Declaración</span>
							<span style={{ color: "gray" }}>
								El formulario o carga de documento mostrará la declaración.
							</span>
						</Checkbox>
					</Form.Item>
					{uploadDeclaracion && (
						<Form.Item
							label="Declaración"
							name="declaracion">
							<Input
								placeholder="Ingresar Declaración"
							/>
						</Form.Item>
					)}

					<Form.Item name="requiereFormatoCarga" valuePropName="checked">
						<Checkbox disabled={isDisabled}>
							<span style={{ display: "block" }}>Especificar Formato Carga</span>
							<span style={{ color: "gray" }}>
								Especificar el formato de carga (Imágen, Pdf, Word)
							</span>
						</Checkbox>
					</Form.Item>

					{requiereFormatoCarga
						&&
						<Form.Item
							name="formatosCarga"
							label="Seleccione los formatos de carga"
							style={{ paddingLeft: "25px" }}
						>
							<Select
								mode="multiple"
								allowClear
								options={[
									{
										value: 'imagen',
										label: 'Imágen',
									},
									{
										value: 'pdf',
										label: 'Pdf',
									},
									{
										value: 'word',
										label: 'Word',
									}
								]}
								filterOption={(input, option) =>
									(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
								}
							/>
						</Form.Item>
					}
				</Col>

			</Row>



			<Form.Item style={{ textAlign: "center" }}>
				<Button type="primary" icon={<CheckOutlined />} htmlType="submit">
					Actualizar Tipo Documento
				</Button>
			</Form.Item>
		</Form>
	);
};
export default FormularioEditar;
