import React, { useContext, useEffect, useReducer } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import { FirmaDocumentoGenericoCrearReducer, FirmaDocumentoGenericoCrearContext } from "./index";
import { EmpresaService } from "services/EmpresaService";
import { UsuarioFirmanteService } from "services/UsuarioFirmanteService";
import { DocumentoGenericoService } from "services/DocumentoGenericoService";
import { useHistory } from "react-router-dom";
const FirmaDocumentoGenericoCrearContextProvider = ({ children }) => {

    const estadoInicial = {
        loading: false,
        visibleModalConfirmacion: false,
        documentoGenerico: {},
        empresas: [],
        firmantes: [],
        archivos:[],
        firmanteNombreCompleto:[],
        empresaRazonSocial:"",
        empresaSeleccionada:[],
        firmanteEmail:"",
    }

    const [state, dispatch] = useReducer(FirmaDocumentoGenericoCrearReducer, estadoInicial);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const history = useHistory();

    // async function getEmpresas() {
    //     try {
    //         dispatch({ type: 'INICIAR' });
    //         const empresas = await EmpresaService.listarPorOrganizacionId(getUsuarioAutenticado().organizacionId);
    //         dispatch({ type: 'SET_EMPRESAS', payload: empresas });
    //     } catch (error) {
    //         console.error(error);
    //     } finally {
    //         dispatch({ type: 'FINALIZAR' });
    //     }
    // }

    const getEmpresas = async () => {
        try {
            dispatch({ type: 'INICIAR' });
            const empresas = await EmpresaService.listarPorOrganizacionId(getUsuarioAutenticado().organizacionId);
            dispatch({ type: 'SET_EMPRESAS', payload: empresas });
        } catch (error) {
            console.error(error);
        } finally {
            dispatch({ type: 'FINALIZAR' });
        }
    }
    const getFirmantes = async (empresaSeleccionadaId) => {
        try {
            dispatch({ type: 'INICIAR' });
            const firmantes = await UsuarioFirmanteService.listarUsuariosFirmantes(empresaSeleccionadaId);
            console.log("firmantes", firmantes);
            dispatch({ type: 'SET_FIRMANTES', payload: firmantes });

        } catch (error) {
            console.error(error);
        } finally {
            dispatch({ type: 'FINALIZAR' });
        }
    }

    const guardarDocumentoGenerico = async () => {
        try {
            dispatch({ type: 'INICIAR' })
            const envio = await DocumentoGenericoService.insertar(state.documentoGenerico);
            dispatch({ type: 'CERRAR_MODAL_CONFIRMACION' })
            //history.push(`/contrato/seguimiento/${envio.id}/detalle`);
            history.push('/notificacion/firma-documento-generico/listar');
        } catch (error) {
            console.error(error.response.data.mensaje);
            // notification.error({
            //     message: "No se puede registrar el Documento Generico",
            //     description: error.response.data.mensaje,
            //   });
        } finally {
            dispatch({ type: 'FINALIZAR' })
        }
    }


    const mostrarModalConfirmacion = (documentoGenerico) => {
        dispatch({ type: 'SET_DOCUMENTO_GENERICO', payload: getDocumentoGenerico(documentoGenerico) });
        dispatch({ type: 'MOSTRAR_MODAL_CONFIRMACION' })
    }


    const cerrarModalConfirmacion = () => {
        dispatch({ type: 'CERRAR_MODAL_CONFIRMACION' })
    }

    const getDocumentoGenerico = (documentoGenerico) => {
        const documentoGenericoEnviado = {
            organizacionId: getUsuarioAutenticado().organizacionId,
            empresaId: documentoGenerico.empresaId,
            empresaRazonSocial: state.empresaRazonSocial,
            nombreDocumento: documentoGenerico.nombreDocumento,
            usuarioFirmanteId: documentoGenerico.usuarioFirmanteId,
            usuarioFirmanteNombreCompleto: state.firmanteNombreCompleto,
            usuarioFirmanteEmail:state.firmanteEmail,
            usuarioEnvioId: getUsuarioAutenticado().id,
            usuarioEnvioNombreCompleto: getUsuarioAutenticado().nombreCompleto,
            usuarioRegistroId: getUsuarioAutenticado().id,
            usuarioEnvioEmail: getUsuarioAutenticado().login,
            usuarioRegistroNombreCompleto: getUsuarioAutenticado().nombreCompleto,
            solicitadoPor:documentoGenerico.solicitadoPor,

        }
        const formData = new FormData();
        formData.append("file", documentoGenerico.documento.file);
        formData.append("documentoGenerico", new Blob([JSON.stringify(documentoGenericoEnviado)], {
            type: "application/json",
        }));

        return formData;
        // documentoGenerico.organizacionId=getUsuarioAutenticado().organizacionId;
        // documentoGenerico.usuarioFirmanteNombreCompleto=state.firmanteNombreCompleto;
        // documentoGenerico.usuarioEnvioId=getUsuarioAutenticado().id;
        // documentoGenerico.usuarioEnvioNombreCompleto=getUsuarioAutenticado().nombreCompleto;
        // documentoGenerico.usuarioRegistroId=getUsuarioAutenticado().id;
        // documentoGenerico.usuarioRegistroNombreCompleto=getUsuarioAutenticado().nombreCompleto;

        // return documentoGenerico;


    }

    const propiedadesUploadDocumento = {
        onRemove: (file) => {
            dispatch({ type: 'SET_ARCHIVOS', payload: [] });
            //setArchivos([]);
            return [];
        },
        beforeUpload: (file) => {
            if (file.type !== 'application/pdf') {
                //message.error(`${file.name} no es un archivo PDF`);
            } else {
                dispatch({ type: 'SET_ARCHIVOS', payload: file });
                return false;
            }
        },
        // fileList: state.archivos
    }
    const handleOnChangeUsuarioFirmante = (value) => {
        let usuarioNombrecompleto = state.firmantes.find((obj) => obj.id === value).nombreCompleto;
        let usuarioEmail = state.firmantes.find((obj) => obj.id === value).login;
        //console.log("Email",usuarioEmail);
        dispatch({ type: 'SET_FIRMANTE_NOMBRE_COMPLETO', payload: usuarioNombrecompleto });
        dispatch({ type: 'SET_FIRMANTE_EMAIL', payload: usuarioEmail });
    }

    const handleOnchangeEmpresa = (empresaSeleccionadaId) => {
        let empresa = state.empresas.find((obj) => obj.id === empresaSeleccionadaId).razonSocial;
        dispatch({ type: 'SET_EMPRESA_RAZON_SOCIAL', payload: empresa });
        dispatch({ type: 'SET_EMPRESA_SELECCIONADA', payload: empresaSeleccionadaId });
        getFirmantes(empresaSeleccionadaId);
    }


    return (
        <FirmaDocumentoGenericoCrearContext.Provider
            value={{
                loading: state.loading,
                visibleModalConfirmacion: state.visibleModalConfirmacion,
                empresas: state.empresas,
                firmantes: state.firmantes,
                propiedadesUploadDocumento,
                getEmpresas,
                mostrarModalConfirmacion,
                cerrarModalConfirmacion,
                handleOnChangeUsuarioFirmante,
                handleOnchangeEmpresa,
                guardarDocumentoGenerico
            }}
        >
            {children}
        </FirmaDocumentoGenericoCrearContext.Provider>
    )
}

export default FirmaDocumentoGenericoCrearContextProvider;