import { BaseService } from "./BaseService";
const ParametrosService={
    async buscarPorEmpresaConCodigoColaboradorActivo(empresaId){
        const respuesta = await BaseService.get("/parametros/buscarPorEmpresaColaboradorActivo",{params:{empresaId:empresaId}});
        return respuesta.data;
    },
    async notificacionCompletarActivo(empresaId){
        const respuesta = await BaseService.get("/parametros/notificacionCompletar",{params:{empresaId:empresaId}});
        return respuesta.data;
    },
    async isSeleccionMultiple(){
        const respuesta = await BaseService.get("/parametros/seleccionMultiple");
        return respuesta.data;
    }
    
}
export default ParametrosService;