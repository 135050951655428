import React, { useState }from "react";
import { Button, Dropdown, Table, Tag, Input, Row, Col, Popconfirm } from "antd";
import {
    LikeOutlined,
    DislikeOutlined,
    UnorderedListOutlined,
    CaretDownOutlined,
    SearchOutlined,
    FormOutlined,
    NotificationOutlined,
    DeleteOutlined
} from "@ant-design/icons";
import { useAtom } from "jotai";
import { useListarContratosPendientesAprobar } from "../../hooks";
import {
    contratoSeleccionadoAtom,
    urlPdfContratoAtom,
    visibleModalAprobarContratoAtom,
    visibleModalRechazarContratoAtom,
    visibleModalVisorPdfAtom
} from "../../BandejaAprobacionAdministracionPersonalStore";
import { BandejaAprobacionAdministracionPersonalService } from "services/contratos";
// import {
//     contratoNuevoSeleccionadoAtom,
//     visibleModalAprobarContratoAtom,
//     visibleModalRechazarContratoAtom,
// } from "../../BandejaAprobacionAdministracionPersonalStore";

const ListaContratos = () => {
    const { Search } = Input;
    const { contratos, setContratos } = useListarContratosPendientesAprobar();
    const [loading, setLoading] = useState(false);

    const columnas = [
        {
            title: "Tipo Contrato",
            //dataIndex: "tipoContrato",
            align: "center",
            width: "10%",
             render: (fila) => <TagTipoContrato contrato={fila} />,
        },
        {
            title: "Colaborador",
            dataIndex: ["colaborador", "nombreCompleto"],
            width: "25%",
        },
        {
            title: "Proyecto",
            dataIndex: ["colaborador", "proyecto"],
            width: "25%",
        },
        {
            title: "Cargo",
            dataIndex: ["colaborador", "cargo"],
            width: "25%",
        },
        {
            title: "Fecha Inicio",
            dataIndex: "fechaInicio",
            align: "center",
            width: "10%",
        },
        {
            title: "Fecha Fin",
            dataIndex: "fechaFin",
            align: "center",
            width: "10%",
        },
        // {
        //     title: "Estado",
        //     dataIndex: "estado",
        //     align: "center",
        //     width: "10%",
        //     render: (fila) => (
        //         <Tag color="orange" style={{ borderRadius: "10px" }}>
        //             PENDIENTE
        //         </Tag>
        //     ),
        // },
        {
            title: "Acciones",
            align: "center",
            width: "10%",
            render: (fila) => <MenuAcciones contrato={fila} />,
        },
    ];

    const onSearch = async (valor) => {
        setLoading(true);
        const contratosFiltro = await BandejaAprobacionAdministracionPersonalService.listarContratosPendientesAprobarAndFiltro({
            filtro:valor.trim()
        });
        setContratos(contratosFiltro);
        setLoading(false);
    };

    return (
    <div>
        <Row>
            <Col span={12}>
            <Search
                placeholder="Buscar por Nombre ó Documento de identidad"
                allowClear
                loading={loading}
                onSearch={onSearch}
                enterButton
            />
            </Col>
            <Col span={9}>

            </Col>
            <Col span={3} style={{ textAlign: 'right' }}>
            </Col>
        </Row>
        
        <br />
        <Table rowKey={(item) => item.id} columns={columnas} dataSource={contratos} pagination={false} />
    </div>
    
    );
};

const TagTipoContrato = ({ contrato }) => {
    if (contrato?.tipoContrato === "INGRESO") {
        if(contrato?.estadoContrato ==="INCOMPLETO"){
            return (
                <Tag color="error" style={{ borderRadius: "10px" }}>
                    INGRESO
                </Tag>
            );
        }

        return (
            <Tag color="#87d068" style={{ borderRadius: "10px" }}>
                INGRESO
            </Tag>
        );
    } else if (contrato?.tipoContrato === "RENOVACION") {
        return (
            <Tag color="#108ee9" style={{ borderRadius: "10px" }}>
                RENOVACIÓN
            </Tag>
        );
    }
     return contrato?.tipoContrato;
};

const MenuAcciones = ({ contrato }) => {
    const [, setVisibleModalAprobar] = useAtom(visibleModalAprobarContratoAtom);
    const [, setVisibleModalRechazar] = useAtom(visibleModalRechazarContratoAtom);
    const [, setVisibleModalVisorPdf] = useAtom(visibleModalVisorPdfAtom);
    const [, setContratoSeleccionado] = useAtom(contratoSeleccionadoAtom);
    const [, setUrlPdfContrato] = useAtom(urlPdfContratoAtom);

    let items = [];

    if(contrato?.estadoContrato ==="INCOMPLETO"){
        items = [
            {
                label: "Visualizar Contrato",
                key: "visualizar-contrato",
                icon: <SearchOutlined style={{ fontSize: "16px" }} />,
            },
        ];
    }else{
        items = [
            {
                label: "Visualizar Contrato",
                key: "visualizar-contrato",
                icon: <SearchOutlined style={{ fontSize: "16px" }} />,
            },
            {
                label: "Aprobar Contrato",
                key: "aprobar-contrato",
                icon: <LikeOutlined style={{ fontSize: "16px" }} />,
            },
            {
                label: "Rechazar Contrato",
                key: "rechazar-contrato",
                icon: <DislikeOutlined style={{ fontSize: "16px" }} />,
            },
        ];

    }

   
    const onClick = ({ key }) => {
        if (key === "aprobar-contrato") {
            setVisibleModalAprobar(true);
            setContratoSeleccionado(contrato);
        } else if (key === "rechazar-contrato") {
            setVisibleModalRechazar(true);
            setContratoSeleccionado(contrato);
        } else if (key === "visualizar-contrato") {
            //console.log("visualizar contrato!!!");
            cargarUrlPdfContrato();
            setVisibleModalVisorPdf(true);
        }
    };

    const cargarUrlPdfContrato = async () => {
        const url = await BandejaAprobacionAdministracionPersonalService.getUrlVistaPrevia(contrato.id);
        setUrlPdfContrato(url);
    };

    return (
        <Dropdown menu={{ items, onClick }} trigger="click" placement="bottomRight" arrow>
            <Button type="link" className="ant-dropdown-link">
                <UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
                <CaretDownOutlined
                    style={{
                        paddingLeft: "2px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#1678c1",
                        marginLeft: "0px",
                    }}
                />
            </Button>
        </Dropdown>
    );
};

export default ListaContratos;
