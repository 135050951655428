import { useAtom } from "jotai";
import { formCargaHistoricaVacacionesAtom } from "../ControlVacacionalStorage";
import { ApiResponse, HttpResponseStatus } from "enums";
import { CargarHistoricoVacacionesService } from "services/VacacionesService";
import { useState } from "react";

const useCargarHistorialVacaciones = () => {
  const [formulario, setFormulario] = useAtom(formCargaHistoricaVacacionesAtom);
  const [mensajeExito, setMensajeExito] = useState(undefined);
  const [mensajeError, setMensajeError] = useState(undefined);
  const [fileList, setFileList] = useState([]);

  const setArchivo = (archivo) => {
    setFormulario({ ...formulario, archivo: archivo });
    setFileList([...fileList, archivo]);
    //formulario.archivo = archivo;
  };

  const setClave = (clave) => {
    setFormulario({ ...formulario, clave: clave });
  };

  const cargarHistorico = async () => {
    try {
      console.log("formulario", formulario);
      const formData = new FormData();
      formData.append("archivo", formulario.archivo);
      formData.append(
        "info",
        new Blob([JSON.stringify({ clave: formulario.clave })], {
          type: "application/json",
        })
      );

      const respuesta = await CargarHistoricoVacacionesService.cargar(formData);
      if (respuesta.estado == ApiResponse.EXITO) {
        setMensajeExito(
          "Se realizó la carga histórica de vacaciones correctamente, cierre la ventana para ver las vacaciones cargadas..."
        );
        setFileList([]);
        setMensajeError("");
        setFormulario({});
      } else {
        setMensajeExito("");
        setFileList([]);
        setMensajeError(respuesta.mensaje);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return { setArchivo, setClave, cargarHistorico, fileList, mensajeExito, mensajeError };
};

export default useCargarHistorialVacaciones;
