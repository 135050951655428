import React from "react";
import { Tag, Popover, Table } from "antd";

export const TagVisualizado = ({ documento, color, className, children }) => {
    const columnas = [
        {
            title: 'Fecha Visualización',
            dataIndex: 'fechaVisualizacion',
            key: 'fechaVisualizacion',
            align: 'center'
        },
        {
            title: 'Plataforma',
            dataIndex: 'plataformaVisualizacion',
            key: 'plataformaVisualizacion',
            align: 'center'
        },
    ];

    const historial = [
        {
            key: '1',
            fechaVisualizacion: documento.fechaVisualizacion,
            plataformaVisualizacion: documento.plataformaVisualizacion
        }
    ];

    const datosVisualizacion = (
        <div>
            <Table dataSource={historial} columns={columnas} pagination={false} />
        </div>
    );

    return (
        <Popover content={datosVisualizacion} placement="bottom" trigger="click">
            <Tag color={color} className={className}>{children}</Tag>
        </Popover>
    )

}