import React, { useContext, useEffect, useState } from "react";
import { PanelListar } from "components/Paneles/PanelListar";
import { Button, Upload, Table, Alert } from "antd";
import { CloudUploadOutlined, LoadingOutlined, CloseCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { LoginService } from "services/LoginService";
import { SecurityContext } from "context/SecurityContextProvider";
import { Headers } from "enums";
import Modal from "antd/lib/modal/Modal";
import Text from "antd/lib/typography/Text";
import { FormatoCarga } from "enums/FormatoCarga";
import CertificadoService from "services/CertificadoService";
import { EstadoCertificadoDigital } from "enums/EstadoCertificadoDigital";

const FormularioCargarTramaExcel = ({ envio, setEnvio }) => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);

  const [visibleInfoModal, setVisibleInfoModal] = useState(false);
  const [estadoTrama, setEstadoTrama] = useState();
  const [validacion, setValidacion] = useState();
  const [data, setData] = useState();
  const [mostrarAlerta, setMostrarAlerta] = useState(false);
  const [estado, setEstado] = useState("VIGENTE");
  useEffect(() => {
    const cargarCertificadoEstado = async () => {
      const estado = await CertificadoService.validarCertificadoEstado(
        getUsuarioAutenticado().empresaSeleccionada.id,
        null,
        envio.id
      );
      setMostrarAlerta(estado === "EXPIRADO" || estado === "SIN_CERTIFICADO");
      setEstado(estado);
    };
    cargarCertificadoEstado();
  }, []);

  const propiedadesUpload = {
    name: "file",
    multiple: false,
    accept: FormatoCarga.EXCEL.extension,
    action: process.env.REACT_APP_ENDPOINT + "contrato/trama/subir",
    headers: {
      Authorization: "Bearer " + LoginService.getJwtToken(),
      [Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
      [Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
    },
    maxCount: 1,
    data: {
      trama: new Blob(
        [
          JSON.stringify({
            empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
            envioId: envio?.id,
          }),
        ],
        { type: "application/json" }
      ),
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        setVisibleInfoModal(true);
        if (status === "done") {
          const data = info.file.response;
          setData(data.envio);
          setValidacion(data.check);
          if (data.campos != null) {
            setEstadoTrama(data.campos);
          }
        } else if (status === "error") {
          console.error("error: ", info.file.response);
        }
      } else setVisibleInfoModal(true);
    },
  };

  useEffect(() => {
    if (!visibleInfoModal) {
      if (data !== undefined) {
        setEnvio(data);
        setEstadoTrama(undefined);
        setValidacion(undefined);
        setData(undefined);
      }
    }
  }, [visibleInfoModal]);

  return (
    <PanelListar titulo="Cargar Contrato">
      {mostrarAlerta && (
        <div style={{ marginBottom: "10px" }}>
          <Alert
            message={
              estado === EstadoCertificadoDigital.EXPIRADO ? (
                <Text>No puede cargar este documento porque su certificado digital se encuentra expirado. </Text>
              ) : (
                <Text>No hay certificado registrado para está empresa</Text>
              )
            }
            type="warning"
            showIcon
          />
        </div>
      )}
      <Upload.Dragger {...propiedadesUpload} disabled={mostrarAlerta}>
        <CloudUploadOutlined className="milegajo-icono-dragger" />
        <span className="milegajo-texto-dragger">
          Arrastra y suelta tu <b>trama excel</b> aquí
        </span>
        <span className="milegajo-texto-dragger">ó</span>
        <span className="milegajo-texto-dragger">Haz clic aquí</span>
      </Upload.Dragger>
      <InfoModal
        visible={visibleInfoModal}
        setVisible={setVisibleInfoModal}
        estado={estadoTrama}
        validacion={validacion}
      />
    </PanelListar>
  );
};

export default FormularioCargarTramaExcel;

const InfoModal = ({ visible, setVisible, estado, validacion }) => {
  const styleRojo = {
    fontSize: "23px",
    color: "red",
  };
  const styleVerde = {
    fontSize: "23px",
    color: "green",
  };
  const styleLoading = {
    fontSize: "50px",
  };
  const columns = [
    {
      //title: 'estado Icono',
      dataIndex: "estadoIcono",
      width: "8%",
      render: (_, item) =>
        item.estado === true ? <CheckCircleOutlined style={styleVerde} /> : <CloseCircleOutlined style={styleRojo} />,
    },
    {
      // title: 'campo',
      dataIndex: "key",
      render: (_, item) => (
        <React.Fragment>
          <Text style={{ fontSize: "13px" }} strong>
            {item.key}
          </Text>
        </React.Fragment>
      ),
    },
  ];

  useEffect(() => {
    if (validacion) {
      setVisible(false);
    }
  }, [validacion]);

  return (
    <Modal
      width={700}
      getContainer={false}
      visible={visible}
      closable={false}
      footer={null}
      title={"Verificar Campos"}
      key={"modalLoading"}
    >
      <div style={{ textAlign: "center" }}>
        {estado === undefined && (
          <div>
            <LoadingOutlined style={styleLoading} />
            <br />
            LOADING ...
          </div>
        )}
      </div>
      <div style={{ paddingLeft: "50px", paddingRight: "50px" }}>
        {estado !== undefined && (
          <Table
            title={() => (
              <div style={{ textAlign: "center" }}>
                {
                  <Text style={{ fontSize: "15px" }} strong>
                    REVISAR CAMPOS FALTANTES
                  </Text>
                }{" "}
              </div>
            )}
            columns={columns}
            pagination={false}
            dataSource={estado}
            scroll={{ y: 300 }}
            showHeader={false}
            //bordered
            key={estado.key}
          />
        )}
        <br />
      </div>
      <div style={{ textAlign: "center" }}>
        <Button type="primary" onClick={() => setVisible(false)} hidden={estado === undefined}>
          Aceptar
        </Button>
      </div>
      {/* </div> */}
    </Modal>
  );
};
