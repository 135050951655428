import React from "react";
import { Drawer, Upload, Button, Input, Alert } from "antd";
import { useCargarHistorialVacaciones, useDrawerCargarHistoricoVacaciones } from "../../hooks";
import { UploadOutlined } from "@ant-design/icons";

const DrawerCargarHistoricoVacaciones = () => {
  const { visible, ocultar } = useDrawerCargarHistoricoVacaciones();
  const { setArchivo, cargarHistorico, fileList, setFileList, mensajeExito, mensajeError } =
    useCargarHistorialVacaciones();

  const props = {
    name: "file",
    maxCount: 1,
    fileList: fileList,

    onRemove: () => {
      setArchivo(undefined);
    },

    beforeUpload: (file) => {
      setArchivo(file);
      return false;
    },

    onChange(info) {
      console.log("info", info);
    },

    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <Drawer title="Carga histórica de vacaciones" placement="right" onClose={ocultar} visible={visible}>
      <div style={{ paddingBottom: "15px" }}>
        <Upload.Dragger {...props}>
          <p className="ant-upload-drag-icon" style={{ marginBottom: "10px" }}>
            <UploadOutlined />
          </p>
          <p className="ant-upload-text">Clic aquí o suelta tu archivo en esta área</p>
          <p className="ant-upload-hint" style={{ padding: "0px 10px" }}>
            Carga el historial de vacaciones de tus colaboradores
          </p>
        </Upload.Dragger>
      </div>
      {/* <div style={{ paddingBottom: "15px" }}>
        <div style={{ paddingBottom: "5px" }}>Ingresa tu clave:</div>
        <Input.Password onChange={(e) => setClave(e.target.value)} />
      </div> */}
      <div style={{ paddingBottom: "15px" }}>
        <Button type="primary" onClick={cargarHistorico}>
          Cargar Histórico
        </Button>
      </div>
      <div>{mensajeExito && <Alert message={mensajeExito} type="success" showIcon />}</div>
      <div>{mensajeError && <Alert message={mensajeError} type="error" showIcon />}</div>
    </Drawer>
  );
};

export default DrawerCargarHistoricoVacaciones;
