import { useEffect, useState } from "react";
import { TiposAusenciaService } from "services/VacacionesService";
import { ApiResponse } from "enums";

const useListarTipoAusencia = () => {

  const [tiposAusencia, setTiposAusencia] = useState([]);

  useEffect(() => {
    const cargarDatos = async () => {
      const respuesta = await TiposAusenciaService.listar();

      if (respuesta.estado === ApiResponse.EXITO) {
        setTiposAusencia(respuesta.data);
      }

    }
    cargarDatos();
  }, [])

  return {
    tiposAusencia
  };
}
export default useListarTipoAusencia;