import React from "react";
import { Container } from "components/Container";
import {Botones} from "./componentes/botones";
import {TablaVacaciones} from "./componentes/tabla-vacaciones";
import { ModalRechazarSolicitudVacaciones } from "./componentes/modalRechazarSolicitudVacaciones";

const ConsultaVacacionesPage=()=>{

    return(
        <Container titulo="Bandeja de Aprobaciones" botonExtra={<Botones/>}>
           <TablaVacaciones/>
           <ModalRechazarSolicitudVacaciones/>
        </Container>
    )

}
export default ConsultaVacacionesPage;