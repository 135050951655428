import React,{useState,useEffect} from "react";
import {Button, Form,Input,Table,Col,Row,Select,notification, message,Space} from "antd";
import { useAtom } from "jotai";
import{visibleModalDocumentosAtom,documentosAtom,documentoEditadoAtom,documentoSeleccionadoAtom} from "../../PlantillaEditarStore";
import {PlantillaService} from"services/reclutamiento-y-seleccion";
import { useHistory ,useParams} from "react-router-dom";
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { MenuOutlined } from '@ant-design/icons';

const EditarPlantilla=()=>{

    const[visible,setVisible]=useAtom(visibleModalDocumentosAtom);
    const[documentos,setDocumentos] =useAtom(documentosAtom);
    const[documentoEditado,setDocumentoEditado]=useAtom(documentoEditadoAtom);
    const[documentoSeleccionado,setDocumentoSeleccionado]=useAtom(documentoSeleccionadoAtom);
    const [formEditarPlantilla]=Form.useForm();
    const SortableItem = SortableElement((props) => <tr {...props} />);
    const SortableBody = SortableContainer((props) => <tbody {...props} />);
    let {plantillaId} = useParams();
    let history = useHistory();

     useEffect(()=>{
        const cargarDatos = async()=>{
            const plantillasBuscada=await PlantillaService.buscarPorId(plantillaId);
            formEditarPlantilla.setFieldsValue({
                id:plantillasBuscada.id,
                nombre:plantillasBuscada.nombre,

            })
            setDocumentos(plantillasBuscada.documentos);
           //console.log("Documentos: ",plantillasBuscada.documentos);
        }
        cargarDatos()
     },[])

    const onClickAgregarDocumentos=()=>{
        setVisible(true);
    }
    const onClickQuitar=(plantilla)=>{
        setDocumentos(documentos.filter((item) => item.id !== plantilla.id));
    }
    const onClikEditar = (plantilla)=>{
       setVisible(true);
       setDocumentoEditado(true);
       setDocumentoSeleccionado(plantilla);
    }

    const DragHandle = SortableHandle(() => (
      <MenuOutlined
        style={{
          cursor: 'grab',
          color: '#999',
        }}
      />
    ));
    const columnas=[
        {
          title: 'Ordenar',
          dataIndex: 'sort',
          width: 30,
          className: 'drag-visible',
          render: () => <DragHandle />,
        },
        {
            title:"Nombre",
            dataIndex:"nombre",
            align:"center",          
        },
        {
            title:"Caducidad",           
            align:"center",
            render:(fila)=>(fila.caducidad ?"SI":"NO"),             
        },
        {
            title:"Obligatorio",           
            align:"center",
            render:(fila)=>(fila.obligatorio ?"SI":"NO"),             
        },
        {
            title:"Plazo",
            dataIndex:"plazoEnDias",
            align:"center",            
        },
        {
            title:"Etapa",
            dataIndex:["etapa","nombre"],
            align:"center",            
        },
        {
            title:"Acciones",
            key:"acciones",
            align:"center",
            render:(fila)=>(                
                    <Space>
                        <Button type="link" onClick={()=>onClikEditar(fila)}>Editar</Button>
                        <Button type="link" onClick={()=>onClickQuitar(fila)}>Quitar</Button>
                    </Space>
            )

            
        },

    ];
    const onSortEnd = ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const newData = arrayMoveImmutable(documentos.slice(), oldIndex, newIndex).filter( (el) => !!el, );
        //console.log('Sorted items: ', newData);
        setDocumentos(newData);
      }
    };
    const DraggableContainer = (props) => (
      <SortableBody
        useDragHandle
        disableAutoscroll
        helperClass="row-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );
    const DraggableBodyRow = ({ className, style, ...restProps }) => {
     
      const index = documentos.findIndex((x) => x.index === restProps['data-row-key']);
      return <SortableItem index={index} {...restProps} />;
    };
    
    const onFinishActualizarPlantilla = async(formulario)=>{
        try {
            if(documentos && documentos.length>0){

               formulario.documentos=documentos;          
               await PlantillaService.actualizar(formulario);
               notification.success({
                message: "Operación exitosa",
                description: "Se actualizó la plantilla correctamente",
              });
              history.push("/reclutamiento-y-seleccion/plantillas");
            }
            else{
                message.error("Debe agregar al menos un documento");
            }
            
            
        } catch (error) {
            console.error(error);
            notification.error({
                message: "No se puede actualizar la plantilla",
                description: error.response.data.mensaje,
              });
        }
        
    }
return(
    <Form
            layout="vertical"
            // labelCol={{ span: 4,offset:8}}
            // wrapperCol={{ span: 9,offset:8 }}
            style={{ padding: "0px 5%" }}
            onFinish={onFinishActualizarPlantilla}
            form={formEditarPlantilla}
     >
               <Form.Item name="id" hidden={true}>
                <Input />
               </Form.Item>
                <Form.Item 
                    name="nombre" 
                    label="Nombre" 
                    rules={[
                        {
                            required: true,
                            message: "Debe ingresar el nombre.",
                            whitespace:true,
                        },
                    ]}   
                >
                    <Input/>                    
                </Form.Item>
                {/* <Form.Item
                  name="buscarDocumento"
                  label="Documentos"
                >
                </Form.Item>    */}

                <Form.Item
                  name="documentos" 
                  label="Documentos"                 
                >
                     <Button type="primary" onClick={onClickAgregarDocumentos}>Agregar</Button>
                      <Table
                       // rowKey={(d) => d.id}
                        rowKey="index"
                        dataSource={documentos}
                        columns={columnas}
                        pagination={false}
                        components={{
                          body: {
                            wrapper: DraggableContainer,
                            row: DraggableBodyRow,
                          },
                        }}
                      />
                      <br/>                      
                                 
                </Form.Item>
                <Form.Item>
                 <Button type="primary" htmlType="submit" block>Actualizar Plantilla</Button>
                </Form.Item>
    </Form>
)

}
export default EditarPlantilla;