const FirmaDocumentoGenericoCrearReducer = (state, action) => {
    switch (action.type) {
        case 'INICIAR':
            return {
                ...state,
                loading: true
            }

        case 'FINALIZAR':
            return {
                ...state,
                loading: false
            }

        case 'SET_DOCUMENTO_GENERICO':
            return {
                ...state,
                documentoGenerico: action.payload
            }

        case 'SET_EMPRESAS':
            return {
                ...state,
                empresas: action.payload
            }

        case 'SET_FIRMANTES':
            return {
                ...state,
                firmantes: action.payload
            }

        case 'MOSTRAR_MODAL_CONFIRMACION':
            return {
                ...state,
                visibleModalConfirmacion: true
            }

        case 'CERRAR_MODAL_CONFIRMACION':
            return {
                ...state,
                visibleModalConfirmacion: false
            }
        case 'SET_ARCHIVOS':
            return {
                ...state,
                archivos: action.payload
            }
        case 'SET_FIRMANTE_NOMBRE_COMPLETO':
            return {
                ...state,
                firmanteNombreCompleto: action.payload
            }
        case 'SET_FIRMANTE_EMAIL':
             return {
                 ...state,
                firmanteEmail: action.payload
                }    
        case 'SET_EMPRESA_RAZON_SOCIAL':
            return {
                ...state,
                empresaRazonSocial: action.payload
            }
        case 'SET_EMPRESA_SELECCIONADA':
            return {
                ...state,
                empresaSeleccionada: action.payload
            }
        default:
            return state;
    }
}

export default FirmaDocumentoGenericoCrearReducer;