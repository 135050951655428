import { useEffect } from "react";
import { useAtom } from "jotai";
import { PlantillaService } from "services/reclutamiento-y-seleccion";
import { documentosAtom, etapasSeleccionadasAtom, plantillaIdSeleccionadaAtom } from "../ProcesosCrearStore";

const useCargarDocumentos = () => {
  const [documentos, setDocumentos] = useAtom(documentosAtom);
  const [plantillaId] = useAtom(plantillaIdSeleccionadaAtom);
  const [etapas] = useAtom(etapasSeleccionadasAtom);

  useEffect(() => {
    const cargarDocumentos = async () => {
      if (plantillaId !== undefined && etapas.length > 0) {
        const plantilla = await PlantillaService.buscarPorId(plantillaId);
        const documentosListados = plantilla.documentos;

        if (etapas.length === 1) {
          const documentosFiltrados = documentosListados.filter((documento) => documento.etapa.nombre === etapas[0]);
          setDocumentos(documentosFiltrados);
          console.log(documentosFiltrados)
        } else if (etapas.length === 2) {
          const documentosFiltrados = documentosListados.filter(
            (documento) => documento.etapa.nombre === etapas[0] || documento.etapa.nombre === etapas[1]
          );
          setDocumentos(documentosFiltrados);
          console.log(documentosFiltrados)
        } else if (etapas.length === 3) {
          const documentosFiltrados = documentosListados.filter(
            (documento) =>
              documento.etapa.nombre === etapas[0] ||
              documento.etapa.nombre === etapas[1] ||
              documento.etapa.nombre === etapas[2]
          );
          setDocumentos(documentosFiltrados);
          console.log(documentosFiltrados)
        }
      } else {
        setDocumentos([]);
      }
    };

    cargarDocumentos();
  }, [plantillaId, etapas]);

  return { documentos, setDocumentos };
};

export default useCargarDocumentos;
