import React, { useContext } from "react";
import { SecurityContext } from "../../context/SecurityContextProvider";
import { Route, Redirect } from "react-router-dom";

const parametros = window.location.search;
const buscadorParametros = new URLSearchParams(parametros);

export const PrivateRoute = ({ component: Component, ...rest }) => {
  //TODO: revisar el contexto SecurityContextProvider  
  
  const { estaAutenticado,setEmpresaSeleccionada } = useContext(SecurityContext);
  
  if (estaAutenticado()===true) {
    setEmpresaSeleccionada(buscadorParametros.get("sesionId"));
  }
  
  return (
    <Route
      {...rest}
      render={(props) =>
        estaAutenticado() === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={"/login" + parametros} />
        )
      }
    />
  );
};
