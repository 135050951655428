import React from "react";
import { Alert } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const AlertaInformacionCarga = () => {

    return (
        <Alert
            type="info"
            icon={<ExclamationCircleOutlined style={{ fontSize: "20px" }} />}
            showIcon
            message={
                <React.Fragment>
                    <span>Los contratos cargados se encuentran</span>
                    <span className="milegajo-resaltar-texto-alert-info"> pendientes de notificar.</span>
                </React.Fragment>
            }
            style={{ marginBottom: "10px" }}
        />
    )
}

export default AlertaInformacionCarga;