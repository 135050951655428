import React from "react";
import { Tag } from "antd";
import { EstadoVisualizacion } from "enums/EstadoVisualizacion";
import { TagVisualizado } from "./TagVisualizado";

const EtiquetaVisualizacion = ({ formulario }) => {

    switch (formulario.estadoVisualizacion) {
        case EstadoVisualizacion.PENDIENTE:
            return <Tag color="gold" className="milegajo-ant-tag">PENDIENTE</Tag>;

        case EstadoVisualizacion.VISUALIZADO:
            return (
                <TagVisualizado formulario={formulario} color="green" className="milegajo-ant-tag pointer">
                    VISUALIZADO
                </TagVisualizado>
            );

        default:
            return <Tag color="gold" className="milegajo-ant-tag">PENDIENTE</Tag>;
    }

}

export default EtiquetaVisualizacion;