import React from "react";
import AlertaInformacionCarga from "./AlertaInformacionCarga";
import AlertaTramaConObservaciones from "./AlertaTramaConObservaciones";

const Alertas = ({ envio }) => {
  return (
    <React.Fragment>
      <AlertaInformacionCarga />
      <AlertaTramaConObservaciones envio={envio} />
    </React.Fragment>
  );
};
export default Alertas;
