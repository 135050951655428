import React from "react";
import { ContainerCrear } from "components/ContainerCrear";
import { FormularioEditar, ModalFiltrar, ModalRol } from "./componentes";
import { Provider as JotaiProvider } from "jotai";

const UsuarioEditarPage = () => {
  return (
    <JotaiProvider>
      <ContainerCrear titulo="Editar Usuario" descripcion="En este formulario podrás editar la información del usuario." colSpan={12}>
        <FormularioEditar />
        <ModalFiltrar />
        <ModalRol />
      </ContainerCrear>
    </JotaiProvider>
  );
};

export default UsuarioEditarPage;
