import React from "react";
import { Page } from "react-pdf";
import { useAtomValue } from "jotai";
import { typeAtom, zoomVisorFormularioAtom } from "../../../../VisorFormularioStorage";
import styled from "@emotion/styled";
import { ListaControlesPdf } from "../ListaControlesPdf";

const PaginaPdf = ({ numeroPagina }) => {
  const zoom = useAtomValue(zoomVisorFormularioAtom);
  const type = useAtomValue(typeAtom);

  return (
    <React.Fragment>
      <Container>
        <Page pageNumber={numeroPagina} loading={null} scale={zoom} />
        {type === "visor" && <ListaControlesPdf pageNumber={numeroPagina} />}
      </Container>
      <SeparadorPaginas />
    </React.Fragment>
  );
};

const Container = styled.div`
  position: relative;
`;

const SeparadorPaginas = () => {
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <div style={{ height: "2em" }}></div>
    </div>
  );
};

export default PaginaPdf;
