import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { Container } from "components/Container";
import { Formulario } from "./componentes";
import ModalDocumento from "./componentes/Formulario/ModalDocumento";
import { useHistory } from "react-router-dom";
const ProcesosCrearPage = () => {
    let history=useHistory();

  const onClickRetornar=()=>{
     history.push("/reclutamiento-y-seleccion/procesos");
  }
  return (
    <JotaiProvider>
      <Container titulo="Nuevo Proceso Onboarding" onClickRetornar={onClickRetornar}>
        <Formulario />
        <ModalDocumento/>
      </Container>
    </JotaiProvider>
  );
};

export default ProcesosCrearPage;
