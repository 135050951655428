import { useEffect } from "react";
import { useAtom } from "jotai";
import {postulantesConvocatoriaAtom,convocatoriaSeleccionadaIdAtom} from "../AgregarPostulanteStore";
import { ConvocatoriaService } from "services/reclutamiento-y-seleccion/ConvocatoriaService";

const useCargarPostulantesConvocatoria=()=>{
    const [postulantesConvocatoria,setPostulantesConvocatoria]=useAtom(postulantesConvocatoriaAtom);
    const [convocatoriaSeleccionadaId,setConvocatoriaSeleccionadaId]=useAtom(convocatoriaSeleccionadaIdAtom);

    useEffect(()=>{
        
        const cargarDatos = async()=>{
            if(convocatoriaSeleccionadaId!==undefined){
                const respuesta= await ConvocatoriaService.listarPostulantes(convocatoriaSeleccionadaId);
                setPostulantesConvocatoria(respuesta);
            }
              
        }
        cargarDatos();
    },[convocatoriaSeleccionadaId]);
    return {postulantesConvocatoria,setPostulantesConvocatoria}
}
export default useCargarPostulantesConvocatoria;