import { Button } from "antd";
import { useAtom } from "jotai";
import React from "react";
import { visibleModalAprobarEvaluacionAtom } from "../../SeguimientevaluacionStore";

const BotonesExtraVisorPdf = () => {
  const [, setVisibleModalAprobarEvaluacion] = useAtom(visibleModalAprobarEvaluacionAtom);

  const onClickBtnAprobar = () => {
    setVisibleModalAprobarEvaluacion(true);
  };

  return (
    <div>
      <Button type="primary" onClick={onClickBtnAprobar}>
        Aprobar
      </Button>
    </div>
  );
};

export default BotonesExtraVisorPdf;
