import { useEffect, useContext} from "react";
import { useAtom } from "jotai";
import { contratosAtom } from "../BandejaAprobacionReclutamientoYSeleccionStore";
import { BandejaAprobacionReclutamientoSeleccionService } from "services/contratos";
import { SecurityContext } from "context/SecurityContextProvider";


const useListarContratosPendientesAprobar = () => {
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [contratos, setContratos] = useAtom(contratosAtom);
    useEffect(() => {
        const cargarDatos = async () => {
            console.log("CCCC"+getUsuarioAutenticado.login);
            const contratos = await BandejaAprobacionReclutamientoSeleccionService.listarContratosPendientesAprobar(getUsuarioAutenticado().login);
            setContratos(contratos);
        };
        cargarDatos();
    }, []);

    return { contratos, setContratos };
};
export default useListarContratosPendientesAprobar;
