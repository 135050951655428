import React from "react";
import { MenuAcciones } from "../MenuAcciones";
import {
    LoginOutlined, 
  } from '@ant-design/icons';

function columnaPendiente(verificacionDatosPersonales,setVerificacionDatosPersonales) {
    return [
        {
            title:"Documento de Identidad",
            dataIndex:"numeroDocumentoIdentidad",
            align:"center",            
    
        },
        {
        title:"Nombres completos",
        dataIndex:"nombresCompletos",
        align:"center",            
    
        },
        {
            title:"Correo Enviado",
            dataIndex:"correoElectronico",
            align:"center",  
        },
        
        {
            title:"Correo verificado",
            dataIndex:"correoElectronicoVerificado",
            align:"center",  
        } ,
        {
            title:"Celular Enviado",
            dataIndex:"celular",
            align:"center",  
        },
    
       {
            title:"Celular verificado",
            dataIndex:"celularVerificado",
            align:"center",  
        } ,
        {
            title:"Acciones",
            key:"acciones",
            align:"center",
             render:(fila)=>
               <MenuAcciones 
               verificarDatos={fila}  
               data={verificacionDatosPersonales}
               setData={setVerificacionDatosPersonales}         
                />
            
        }           
    
    
    ];
};
export default columnaPendiente;