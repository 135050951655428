import React from "react";
import { Provider as JotaiProvider} from "jotai";
import { Container } from "components/Container";
import { PlantillaRegistro }  from "./componentes";
import {ModalDocumentos} from "./componentes";
import { useHistory } from "react-router-dom";

const PlantillaCrearPage=()=>{

    let history =useHistory();

    const onClickRetornar=()=>{
       history.push("/reclutamiento-y-seleccion/plantillas");
    }

    return(
        <Container titulo="Crear Plantilla" onClickRetornar={onClickRetornar}>
              <PlantillaRegistro/>
              <ModalDocumentos/> 
        </Container>
    )

}
export default PlantillaCrearPage;