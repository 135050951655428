import React from "react";
import { Container } from "components/Container";
import { Skeleton } from "antd";

const LoadingContainer = ({ titulo = "" }) => {
    return (
        <Container titulo={titulo}>
            <Skeleton paragraph={{ rows: 6 }} />
        </Container>
    )
}

export default LoadingContainer;