import { useEffect } from "react";
import { useAtom } from "jotai";
import { responsablesAtom } from "../ConvocatoriaEditarStore";
import UsuarioService from "services/UsuarioService";
const useCargarUsuarios=()=>{

const [responsables,setResponsables]=useAtom(responsablesAtom);
useEffect(()=>{
  const cargarDatos = async()=>{
    const respuesta= await UsuarioService.listar("");
    setResponsables(respuesta);
  }
  cargarDatos();
},[]);
return {responsables}
}
export default useCargarUsuarios;