import React, { useContext, useEffect } from "react";
import { Table } from "antd";
import { MenuAcciones, EtiquetaNombreDocumentoGenerico, EtiquetaEstadoDocumentoGenerico } from "./componentes"
import { useMisDocumentosStore } from "hooks/firma-documento-generico/";
import { SecurityContext } from "context/SecurityContextProvider";
import moment from "moment";

const ListaDocumentosGenericos = () => {

    const loading = useMisDocumentosStore(state => state.loading);
    const documentos = useMisDocumentosStore(state => state.documentos);
    const setDocumentos = useMisDocumentosStore(state => state.setDocumentos);
    const buscarDocumentos = useMisDocumentosStore(state => state.buscarDocumentos);

    const { getUsuarioAutenticado } = useContext(SecurityContext);

    useEffect(() => {

        const cargarDatosIniciales = async () => {
            const filtros = {
                organizacionId: getUsuarioAutenticado().organizacionId,
                usuarioId: getUsuarioAutenticado().id,
                fechaEnvioInicio: moment().startOf('month'),
                fechaEnvioFin: moment().endOf('month')
            };
            const documentos = await buscarDocumentos(filtros);
            setDocumentos(documentos);
        }

        cargarDatosIniciales();

    }, []);

    const columnas = [
        {
            title: 'Documento',
            dataIndex: 'nombreDocumento',
            key: 'nombreDocumento',
            width: '35%',
            render: (text, fila) => (
                <EtiquetaNombreDocumentoGenerico descripcion={fila.nombreDocumento} estado={fila.estadoDocumento} />
            )
        },

        {
            title: 'Estado',
            align: "center",
            dataIndex: 'estadoDocumento',
            key: 'estadoDocumento',
            width: '10%',
            render: (text, fila) => (
                <EtiquetaEstadoDocumentoGenerico estado={fila.estadoDocumento} />
            )
        },
        {
            title: 'Enviado por:',
            dataIndex: 'usuarioEnvioNombreCompleto',
            key: 'usuarioEnvioNombreCompleto',
            width: '25%',
            render: (text, fila) => (
                <React.Fragment>
                    <span style={{ display: 'block' }}>{fila.usuarioEnvioNombreCompleto}</span>
                    <span style={{ display: 'block' }}>
                        <span style={{ fontWeight: 'bold' }}>Fecha:</span> {fila.fechaHoraEnvio}
                    </span>
                </React.Fragment>
            )
        },
        {
            title: 'Solicitado por:',
            key: 'solicitadoPor',
            dataIndex: 'solicitadoPor',
            width: '15%',
        },
        {
            title: 'Empresa',
            dataIndex: 'empresaRazonSocial',
            key: 'empresaRazonSocial',
            width: '20%'
        },
        {
            title: 'Acciones',
            align: "center",
            key: 'acciones',
            render: (text, fila) => (
                <MenuAcciones documentoGenerico={fila} />
            )

        },
    ]

    return (
        <Table rowKey={(documentos) => documentos.id} loading={loading} dataSource={documentos} columns={columnas} />
    );

}

export default ListaDocumentosGenericos;