import { BaseService } from "./BaseService";
export const DocumentoGenericoService = {
  async insertar(documentoGenerico) {
    const respuesta = await BaseService.post(
      "/documento-generico",
      documentoGenerico
    );
    return respuesta.data;
  },

  async listarPorOrganizacionId(organizacionId) {
    const respuesta = await BaseService.get(
      `/documento-generico/organizacion/${organizacionId}`
    );
    return respuesta.data;
  },

  async listarPorFirmante(organizacionId, usuarioFirmanteId) {
    const respuesta = await BaseService.get(
      `/documento-generico/organizacion/${organizacionId}/firmante/${usuarioFirmanteId}`
    );
    return respuesta.data;
  },

  async listar(filtros) {
    const respuesta = await BaseService.get(`/documento-generico`, {
      params: filtros,
    });
    return respuesta.data;
  },

  async obtenerUrlPdf(documentoGenericoId) {
    const respuesta = await BaseService.get(
      `/documento-generico/${documentoGenericoId}/obtener-url`
    );
    return respuesta.data;
  },

  async firmar(datosFirma) {
    const respuesta = await BaseService.post(
      "/documento-generico/firmar",
      datosFirma
    );
    return respuesta.data;
  },
};
