import React from "react";
import { Tag } from "antd";

const EstadoDescargaMasiva = ({ descargaMasiva }) => {
  switch (descargaMasiva.estado) {
    case "PENDIENTE":
      return <Tag color="warning">{descargaMasiva.estado}</Tag>;

    case "PROCESANDO":
      return <Tag color="processing">{descargaMasiva.estado}</Tag>;

    case "FINALIZADO":
      return <Tag color="success">{descargaMasiva.estado}</Tag>;

    default:
      return <Tag color="default">{descargaMasiva.estado}</Tag>;
  }
};

export default EstadoDescargaMasiva;
