import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";

const URL_API = ApiUrl.RECLUTAMIENTO_Y_SELECCION;

const ConvocatoriaService = {
  async insertar(convocatoria) {
    const respuesta = await ApiService(URL_API).post("/convocatorias", convocatoria);
    return respuesta.data;
  },

  async listar() {
    const respuesta = await ApiService(URL_API).get("/convocatorias");
    return respuesta.data;
  },

  async buscarPorId(convocatoriaId) {
    const respuesta = await ApiService(URL_API).get(`/convocatorias/${convocatoriaId}`);
    return respuesta.data;
  },

  async actualizar(convocatoria) {
    const respuesta = await ApiService(URL_API).put("/convocatorias", convocatoria);
    return respuesta.data;
  },

  async eliminar(convocatoriaId) {
    const respuesta = await ApiService(URL_API).delete(`/convocatorias/${convocatoriaId}`);
    return respuesta.data;
  },

  async insertarPostulante(postulante) {
    const respuesta = await ApiService(URL_API).post("/convocatorias/agregarPostulante", postulante);
    return respuesta.data;
  },

  async listarPostulantes(convocatoriaId) {
    const respuesta = await ApiService(URL_API).get(`/convocatorias/${convocatoriaId}/postulantes`);
    return respuesta.data;
  },

  async eliminarPostulante(convocatoriaId, postulanteId) {
    const respuesta = await ApiService(URL_API).delete(`/convocatorias/${convocatoriaId}/postulantes/${postulanteId}`);
    return respuesta.data;
  },

  async listarPorSolicitudPersonal(solicitudId) {
    const respuesta = await ApiService(URL_API).get("/convocatorias/listarPorSolicitudPersonal", {
      params: {
        solicitudPersonalId: solicitudId,
      },
    });
    return respuesta.data;
  },

  async buscarPorSolicitudPersonal(solicitudId) {
    const respuesta = await ApiService(URL_API).get("/convocatorias/buscarPorSolicitudPersonal", {
      params: {
        solicitudPersonalId: solicitudId,
      },
    });
    return respuesta.data;
  },

  async cambiarEtapaPostulante(data) {
    const respuesta = await ApiService(URL_API).post("/convocatorias/postulantes/cambiar-etapa", data);
    return respuesta;
  },

  async agregarPostulante(data) {
    const respuesta = await ApiService(URL_API).post("/convocatorias/postulantes/agregar", data);
    return respuesta;
  },

  async cambiarEtapaConvocatoria(convocatoriaData) {
    const respuesta = await ApiService(URL_API).post("/convocatorias/cambiarEtapa", convocatoriaData);
    return respuesta;
  },

  async removerEtapa(convocatoriaId, etapaId) {
    const respuesta = await ApiService(URL_API).delete(`/convocatorias/removerEtapa/${convocatoriaId}/${etapaId}`);
    return respuesta.data;
  },

  async restablecerEtapasConvocatoria(convocatoriaId) {
    const respuesta = await ApiService(URL_API).put(`/convocatorias/restablecerEtapas/${convocatoriaId}`);
    return respuesta.data;
  },

  async cerrarProceso(convocatoria) {
    const respuesta = await ApiService(URL_API).post("/convocatorias/cerrarProceso",convocatoria);
    return respuesta.data;
  },
  async listarPorEstado(estado) {
    const respuesta = await ApiService(URL_API).get("/convocatorias/listar-por-estado",{
      params:{
        estado:estado
      }
    });
    return respuesta.data;
  },
  async cambiarConvocatoriaPostulante(data) {
    const respuesta = await ApiService(URL_API).post("/convocatorias/postulantes/cambiar-convocatoria", data);
    return respuesta;
  },
  async ActualizarInformacionPostulanteContratado(data) { 
    const respuesta = await ApiService(URL_API).post("/convocatorias/postulante/actualizarInformacionContratado",data);
    return respuesta;
  },
  async validarCierreProceso(convocatoria) {
    const respuesta = await ApiService(URL_API).post("/convocatorias/validarCierreProceso",convocatoria);
    return respuesta.data;
  },

};
export { ConvocatoriaService };
