import React, { useState, useContext, useEffect } from "react";
import { Menu, Button, Dropdown, Space, Form, DatePicker, Select, Input, Row, Col } from "antd";
import ColaboradorService from "services/ColaboradorService";
import { filtroEstadoAprobacionAtom, openDrawerImportarAusenciasMasivasAtom, solicitudesAusenciaAtom } from "../../consultaSolicitudesStore";
import { useAtom } from "jotai";
import { SolicitudAusenciaService } from "services";
import { SecurityContext } from "context/SecurityContextProvider";
import {
    paginaActualAtom,
    tamanioPaginadoAtom,
    totalRegistrosAtom,
    filtroColaboradorAtom,
    filtroFechaInicioAtom,
    filtroFechaFinAtom,
    filtroEstadoAtom,
    filtroEstadoJefeInmediatoAtom,
    filtroTipoAusenciaAtom,
    filtroEstadoOtrosCargosAtom,
    visibleModalCrearSolicitudAtom,
    loadingExportarAtom
} from "../../consultaSolicitudesStore";
import { EstadoSolicitudAusencia } from "enums/EstadoSolicitudAusencia";
import { usePaginacion, useListarTipoAusencia } from "../../hooks";
import { useGenerarReporteExcelSolicitudesAusencia } from "../../hooks";
import { PlusOutlined, FileExcelOutlined, CaretDownOutlined } from "@ant-design/icons";
import { OpcionService } from "services";
import { OPCIONES } from "enums";

const FiltroConsultaSolicitudes = () => {
    const [colaboradores, setColaboradores] = useState(undefined);
    const [colaboradorSeleccionado, setColaboradorSeleccionado] = useState(undefined);
    const [solicitudesAusencia, setSolicitudesAusencia] = useAtom(solicitudesAusenciaAtom);
    const [filtroColaborador, setfiltroColaborador] = useAtom(filtroColaboradorAtom);
    const [filtroFechaInicio, setFiltroFechaInicio] = useAtom(filtroFechaInicioAtom);
    const [filtroFechaFin, setFiltroFechaFin] = useAtom(filtroFechaFinAtom);
    const [filtroEstado, setFiltroEstado] = useAtom(filtroEstadoAtom);
    const [filtroEstadoAprobacion, setFiltroEstadoAprobacion] = useAtom(filtroEstadoAprobacionAtom);
    const [filtroEstadoJefeInmediato, setFiltroEstadoJefeInmediato] = useAtom(filtroEstadoJefeInmediatoAtom);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [paginaActual, setPaginaActual] = useAtom(paginaActualAtom);
    const [tamanioPaginado] = useAtom(tamanioPaginadoAtom);
    const [, setTotalRegistros] = useAtom(totalRegistrosAtom);
    const [mesInicio, setMesInicio] = useState(null);
    const [mesFin, setMesFin] = useState(null);
    const { tiposAusencia } = useListarTipoAusencia();
    const [, setFiltroTipoAusencia] = useAtom(filtroTipoAusenciaAtom);
    const [filtroEstadoOtrosCargos, setFiltroEstadoOtrosCargos] = useAtom(filtroEstadoOtrosCargosAtom);
    const [, setOpenDrawerImportarAusenciasMasivas] = useAtom(openDrawerImportarAusenciasMasivasAtom);
    const [loadingExportar, setLoadingExportar] = useAtom(loadingExportarAtom);

    //const {onFiltrar}=usePaginacion();

    const [formFiltro] = Form.useForm();
    const { RangePicker } = DatePicker;

    const { generarReporteSolicitudes } = useGenerarReporteExcelSolicitudesAusencia();
    const [, setVisibleModalCrearSolicitud] = useAtom(visibleModalCrearSolicitudAtom);

    const [codigosOpciones, setCodigosOpciones] = useState([]);

    useEffect(() => {

        const cargaInicial = async () => {
            const codigosOpciones = await OpcionService.listar(getUsuarioAutenticado().id);
            setCodigosOpciones(codigosOpciones);
        }
        cargaInicial();
    }, []);

    const onSearchColaboradores = async (value) => {
        if (value === "") {
            setColaboradores([]);
            return;
        }
        const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
            filtro: value.trim(),
        });
        setColaboradores(respuesta.colaboradores);
    };

    const onSelectColaborador = (value, option) => {
        console.log("colaboradorSeleccionado", option.key);
        setColaboradorSeleccionado(option.key);
        setfiltroColaborador(option.value);
    };

    const onChangeColaborador = (value) => {
        console.log("colaboradorSeleccionado", value);
        setfiltroColaborador(value);
    };

    const onChangeMeses = (value) => {
        const mesInicio = value != null ? value[0].format("01/MM/YYYY") : null;
        const mesFin = value != null ? value[1].daysInMonth() + value[1].format("/MM/YYYY") : null;
        setMesInicio(mesInicio);
        setMesFin(mesFin);
        setFiltroFechaInicio(mesInicio);
        setFiltroFechaFin(mesFin);
    };

    const onChangeEstado = (value) => {
        setFiltroEstado(value);
    };

    const onChangeEstadoJefeInmediato = (value) => {
        setFiltroEstadoJefeInmediato(value);
    };

    const onChangeTipoAusencia = (value) => {
        setFiltroTipoAusencia(value);
    };

    const onChangeEstadoOtrosCargos = (value) => {
        setFiltroEstadoOtrosCargos(value);
    };

    const onChangeEstadoAprobacion = (value) => {
        setFiltroEstadoAprobacion(value);
    };

    const onClickExportarSolicitudes = () => {
        generarReporteSolicitudes();
    };

    const onClickBtnImportar = (e) => {
        if (e.key === "importar-ausencias-masivas") {
            setOpenDrawerImportarAusenciasMasivas(true);
        }
    }

    return (
        <div>
            <div style={{ textAlign: "end" }}>
                <Space>
                    {codigosOpciones && codigosOpciones.includes(OPCIONES.PAGINA_VACACIONES_SEGUIMIENTO_SOLICITUDES.BOTON_CREAR_SOLICITUD) && (
                        <Button type="primary" onClick={() => setVisibleModalCrearSolicitud(true)} icon={<PlusOutlined />}>
                            Crear Solicitud
                        </Button>
                    )}
                    {codigosOpciones && codigosOpciones.includes(OPCIONES.PAGINA_VACACIONES_SEGUIMIENTO_SOLICITUDES.BOTON_EXPORTAR_SOLICITUDES) && (
                        <Button type="primary" onClick={onClickExportarSolicitudes} loading={loadingExportar}>
                            Exportar Solicitudes
                        </Button>
                    )}

                    <Dropdown.Button
                        menu={{
                            items: [
                                {
                                    label: "Ausencias Masivas",
                                    key: "importar-ausencias-masivas",
                                    icon: <FileExcelOutlined style={{ fontSize: "18px" }} />,
                                },
                            ],
                            onClick: onClickBtnImportar,
                        }}
                        icon={<CaretDownOutlined />}
                        trigger={["click"]}
                        arrow
                    >
                        Importar
                    </Dropdown.Button>

                </Space>
            </div>
            <Form
                layout="vertical"
                form={formFiltro}
                initialValues={{
                    estadoAprobacion: EstadoSolicitudAusencia.APROBADO,
                }}
            >
                <Row gutter={16}>
                    <Col>
                        <Form.Item label="Tipo Ausencia" name="tipoAusencia">
                            <Select
                                style={{ width: "250px" }}
                                placeholder="Seleccione el Tipo ausencia"
                                onChange={onChangeTipoAusencia}
                                allowClear
                            >
                                {tiposAusencia !== undefined &&
                                    tiposAusencia.map((tipo) => (
                                        <Select.Option key={tipo.id} value={tipo.id}>
                                            {tipo.descripcion}
                                        </Select.Option>
                                    ))}
                                <Select.Option value="TODOS">TODOS</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="Colaborador" name="colaborador">
                            <Select
                                style={{ width: "250px" }}
                                allowClear
                                showSearch
                                placeholder="Ingrese el nombre del colaborador"
                                onSearch={onSearchColaboradores}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                //onSelect={onSelectColaborador}
                                onChange={onChangeColaborador}
                            >
                                {colaboradores !== undefined &&
                                    colaboradores?.map((colaborador) => (
                                        <Select.Option
                                            key={colaborador.numeroDocumentoIdentidad}
                                            value={colaborador.numeroDocumentoIdentidad}
                                        >
                                            {colaborador.nombreCompleto}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="Por meses" name="meses">
                            <RangePicker picker="month" format={"MM/YYYY"} onChange={onChangeMeses} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label="Estado Aprobación" name="estadoAprobacion">
                            <Select
                                style={{ width: "250px" }}
                                placeholder="Seleccione el estado de aprobación"
                                onChange={onChangeEstadoAprobacion}
                                allowClear
                            >
                                <Select.Option value="TODOS">TODOS</Select.Option>
                                <Select.Option value={EstadoSolicitudAusencia.PENDIENTE}>
                                    {EstadoSolicitudAusencia.PENDIENTE}
                                </Select.Option>
                                <Select.Option value={EstadoSolicitudAusencia.APROBADO}>
                                    {EstadoSolicitudAusencia.APROBADO}
                                </Select.Option>
                                <Select.Option value={EstadoSolicitudAusencia.RECHAZADO}>
                                    {EstadoSolicitudAusencia.RECHAZADO}
                                </Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
            </Form>
        </div>
    );
};
export default FiltroConsultaSolicitudes;
