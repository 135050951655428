import React,{useEffect} from "react";
import { Dropdown, Menu,Popconfirm } from "antd";
import { UnorderedListOutlined, CaretDownOutlined, SearchOutlined,DeleteOutlined } from "@ant-design/icons";
import { useModalVisorPdf } from "store/global";
import { useAtom } from "jotai";
import { evaluacionSeleccionadaAtom,evaluacionesAtom } from "../../SeguimientevaluacionStore";
import EvaluacionService from "services/EvaluacionService";

const MenuAcciones = ({ evaluacion }) => {
  const [, setEvaluacionSeleccionada] = useAtom(evaluacionSeleccionadaAtom);
  const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal);
  const[evaluaciones,setEvaluaciones]=useAtom(evaluacionesAtom);

  
  const onClickMenu = async (opcion) => {
    
    switch (opcion.key) {
      case "aprobar-documento":
        const urlPdf = await EvaluacionService.obtenerUrl(evaluacion.id);
        setEvaluacionSeleccionada(evaluacion);
        mostrarModalVisorPdf(urlPdf, evaluacion.tipoDocumentoNombre);
        break;
      
      default:
        console.error("No se encuentra esa opción");
    }
  };
   const eliminar = async()=>{
    
      await EvaluacionService.eliminar(evaluacion.id); 
      setEvaluaciones(evaluaciones.filter(e=>e.id!==evaluacion.id));
      
    }
    
  const menu = () => {
    return (
      <Menu onClick={onClickMenu}>
        <Menu.Item key="aprobar-documento">
          <SearchOutlined /> Aprobar Documento
        </Menu.Item>
        <Popconfirm
          title="¿Está seguro de eliminar la evaluación?"
          onConfirm={eliminar}       
          okText="Si"
          cancelText="No"
       >
          <Menu.Item key="eliminar">
            <DeleteOutlined /> Eliminar
          </Menu.Item>
       </Popconfirm>
        
      </Menu>
    );
  };

  return (
    <React.Fragment>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomCenter">
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
          <CaretDownOutlined
            style={{
              paddingLeft: "2px",
              fontSize: "12px",
              fontWeight: "bold",
              color: "#1678c1",
            }}
          />
        </a>
      </Dropdown>
    </React.Fragment>
  );
};

export default MenuAcciones;
