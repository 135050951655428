import { useState } from "react";
import UsuarioService from "services/UsuarioService";

const usePermisos = () => {
    const [opciones, setOpciones] = useState([]);

    const fetchOpciones = async () => {
        const opciones = await UsuarioService.listarOpciones();
        setOpciones(opciones);
    }

    const existeOpcion = (opcion) => {
        return opciones.includes(opcion);
    }

    return { opciones, fetchOpciones, existeOpcion };
};

export default usePermisos;
