import React, { useContext, useState,useEffect} from "react";
import { Form, Input, Button,Row,Col,Select } from "antd";
import { useModalConfirmar } from "store/global"
import { SecurityContext } from "context/SecurityContextProvider";
import * as atoms from "../EditarPerfilStore";
import { useAtom } from "jotai";
import { useParams} from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import PerfilService from "../../../../services/PerfilService";
import {CampoColaborador} from "enums/CampoColaborador";
import SeccionService from "services/SeccionService";
import SubsedeService from "services/SubsedeService";
import SedeService from "services/SedeService";
import TipoPlanillaService from "services/TipoPlanillaService";
import CargoService from "services/CargoService";

const PerfilEditar=()=>{

    let { idPerfil } = useParams();
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const[,setPerfil]=useAtom(atoms.perfilAtom);   
    const mostrarModalConfirmacion = useModalConfirmar(state => state.mostrarModal);
    const [formularioPerfil]=Form.useForm();

    const [dependenciaPerfilAtom,setDependenciaPerfilAtom]=useAtom(atoms.dependenciaPerfil);    
    const [listaCamposAtom,setListaCamposAtom]=useAtom(atoms.listaCampos);
    const [seccionesAtom,setSeccionesAtom]=useAtom(atoms.secciones);
    const [sedesAtom,setSedesAtom]=useAtom(atoms.sedes);
    const [subsedesAtom,setSubsedesAtom]=useAtom(atoms.subsedes);
    const [tipoPlanillasAtom,setTipoPlanillasAtom]=useAtom(atoms.tipoPlanillas);
    const [cargosAtom,setCargosAtom]=useAtom(atoms.cargos);
    const [camposColaboradorAtom,setCamposColaboradorAtom]=useAtom(atoms.camposColaborador)
    const { Option } = Select;

    useEffect(()=>{
        const cargarDatos= async()=>{
            try {   
                const perfilObtenido= await PerfilService.buscarPorId(idPerfil);
                formularioPerfil.setFieldsValue(
                    {   id:perfilObtenido.id,
                        nombre:perfilObtenido.nombre,                        
                    }
                )
                var listaCampos=[],dependencia;
                var seccionId, sedeId, subsedeId,tipoPlanillaId,cargoId;
                const empresa = getUsuarioAutenticado().empresaSeleccionada.id;
                const campos_colaborador=getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
                setCamposColaboradorAtom(campos_colaborador);
                const campo_perfil=  campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.PERFIL);
                const perfilDependencia=campo_perfil.campoDependencia; 

               
                if(perfilDependencia!==null){
                      if(perfilDependencia===CampoColaborador.SECCION){                                                                 
                        seccionId=perfilObtenido.seccionId;
                      }
                      else if(perfilDependencia===CampoColaborador.SEDE){
                            sedeId=perfilObtenido.sedeId;
                      }
                      else if(perfilDependencia===CampoColaborador.SUBSEDE){
                            subsedeId=perfilObtenido.subsedeId;                    
                      }
                      else if(perfilDependencia===CampoColaborador.PLANILLA){
                            tipoPlanillaId=perfilObtenido.tipoPlanillaId;
                      }
                      else if(perfilDependencia===CampoColaborador.CARGO){
                            cargoId=perfilObtenido.cargoId;
                      }  

                    setDependenciaPerfilAtom(perfilDependencia);
                    dependencia=perfilDependencia;
                    listaCampos.push(dependencia);

                   while(dependencia!==null){
                   var campo= campos_colaborador.find(campoColaborador=>campoColaborador.campo===dependencia);
                   dependencia=campo.campoDependencia;
                        if(dependencia!==null){
                            listaCampos.push(dependencia);
                            if(dependencia===CampoColaborador.SECCION){                                                                 
                                seccionId=perfilObtenido.seccionId;                                    
                              }
                              else if(dependencia===CampoColaborador.SEDE){
                                    sedeId=perfilObtenido.sedeId;
                              }
                              else if(dependencia===CampoColaborador.SUBSEDE){
                                    subsedeId=perfilObtenido.subsedeId;                    
                              }
                              else if(dependencia===CampoColaborador.PLANILLA){
                                    tipoPlanillaId=perfilObtenido.tipoPlanillaId;
                              }
                              else if(dependencia===CampoColaborador.CARGO){
                                    cargoId=perfilObtenido.cargoId;
                              }  
                        }
            
                }
                 setListaCamposAtom(listaCampos.reverse());        
                         
                }
                else{  
                  setListaCamposAtom(listaCampos);         
                  setDependenciaPerfilAtom(CampoColaborador.SINCAMPO);                      
                }
                if(listaCampos.length>0){
                      
                    for(let i=0 ; i < listaCampos.length ; i++){
                      
                      if (listaCampos[i]===CampoColaborador.SEDE){ 
                          const campo_sede= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.SEDE);
                          const sedeDependencia=campo_sede.campoDependencia;             
                          const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                          var _sedes;
                          if(sedeDependencia===CampoColaborador.SECCION){
                              _sedes = sedes.filter(sede=>sede.seccionId===seccionId)
                          } 
                          else if(sedeDependencia===CampoColaborador.SUBSEDE){
                             _sedes = sedes.filter(sede=>sede.subsedelId===subsedeId)     
  
                          }    
                          else if(sedeDependencia===CampoColaborador.PLANILLA){
                              _sedes = sedes.filter(sede=>sede.tipoPlanillaId===tipoPlanillaId)
  
                          } 
                          else if(sedeDependencia===CampoColaborador.CARGO){
  
                               _sedes = sedes.filter(sede=>sede.cargoId===cargoId)
  
                          }  
                          else{
                               _sedes=sedes;
                          }                      
                          setSedesAtom(_sedes);  
                          formularioPerfil.setFieldsValue({ sedeId:sedeId})
                              
                          
                        }    
                      else if(listaCampos[i]===CampoColaborador.SECCION){
                          
                          const campo_seccion= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.SECCION);
                          const seccionDependencia=campo_seccion.campoDependencia;             
                          const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                          var _secciones;
                          if(seccionDependencia===CampoColaborador.SEDE){
                              _secciones = secciones.filter(seccion=>seccion.sedeId===sedeId)
                          } 
                          else if(seccionDependencia===CampoColaborador.SUBSEDE){
                              _secciones = secciones.filter(seccion=>seccion.subsedeId===subsedeId)     
  
                          }    
                          else if(seccionDependencia===CampoColaborador.PLANILLA){
                              _secciones = secciones.filter(seccion=>seccion.tipoPlanillaId===tipoPlanillaId)
  
                          } 
                          else if(seccionDependencia===CampoColaborador.CARGO){
  
                              _secciones = secciones.filter(seccion=>seccion.cargoId===cargoId)
  
                          }  
                          else{
                               _secciones=secciones;
                          }                      
                          setSeccionesAtom(_secciones);                        
                          formularioPerfil.setFieldsValue({ seccionId:seccionId})
                      }
                      else if(listaCampos[i]===CampoColaborador.SUBSEDE){
                          
                          const campo_subsede= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.SUBSEDE);
                          const subsedeDependencia=campo_subsede.campoDependencia;             
                          const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                          var _subsedes;
                          if(subsedeDependencia===CampoColaborador.SEDE){
                              _subsedes = subsedes.filter(subsede=>subsede.sedeId===sedeId)
                          } 
                          else if(subsedeDependencia===CampoColaborador.SECCION){
                              _subsedes = subsedes.filter(subsede=>subsede.secciond===seccionId)     
  
                          }    
                          else if(subsedeDependencia===CampoColaborador.PLANILLA){
                              _subsedes = subsedes.filter(subsede=>subsede.tipoPlanillaId===tipoPlanillaId)
  
                          } 
                          else if(subsedeDependencia===CampoColaborador.CARGO){
  
                              _subsedes = subsedes.filter(subsede=>subsede.cargoId===cargoId)
  
                          }  
                          else{
                               _subsedes=subsedes;
                          }                      
                          setSubsedesAtom(_subsedes); 
                          
                          formularioPerfil.setFieldsValue({ subsedeId:subsedeId})
                      }
                      else if(listaCampos[i]===CampoColaborador.PLANILLA){
                          
                          const campo_planilla= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.PLANILLA);
                          const planillaDependencia=campo_planilla.campoDependencia;             
                          const planillas = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                          var _planillas;
                          if(planillaDependencia===CampoColaborador.SEDE){
                              _planillas = planillas.filter(planilla=>planilla.sedeId===sedeId)
                          } 
                          else if(planillaDependencia===CampoColaborador.SECCION){
                              _planillas = planillas.filter(planilla=>planilla.seccionId===seccionId)     
  
                          }    
                          else if(planillaDependencia===CampoColaborador.SUBSEDE){
                              _planillas = planillas.filter(planilla=>planilla.subsedeId===subsedeId)
  
                          } 
                          else if(planillaDependencia===CampoColaborador.CARGO){
  
                              _planillas = planillas.filter(planilla=>planilla.cargoId===cargoId)
  
                          }  
                          else{
                               _planillas=planillas;
                          }                      
                          setTipoPlanillasAtom(_planillas); 
                          
                          formularioPerfil.setFieldsValue({ tipoPlanillaId:tipoPlanillaId})
                      }
                      else if(listaCampos[i]===CampoColaborador.CARGO){
                          
                          const campo_cargo= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.CARGO);
                          const cargoDependencia=campo_cargo.campoDependencia;             
                          const cargos= await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                          var _cargos;
                          if(cargoDependencia===CampoColaborador.SEDE){
                              _cargos = cargos.filter(cargo=>cargo.sedeId===sedeId)
                          } 
                          else if(cargoDependencia===CampoColaborador.SECCION){
                              _cargos = cargos.filter(cargo=>cargo.seccionId===seccionId)     
  
                          }    
                          else if(cargoDependencia===CampoColaborador.SUBSEDE){
                              _cargos = cargos.filter(cargo=>cargo.subsedeId===subsedeId)
  
                          } 
                          else if(cargoDependencia===CampoColaborador.PLANILLA){
                              _cargos = cargos.filter(cargo=>cargo.tipoPlanillaId===tipoPlanillaId)
  
                          }  
                          else{
                               _cargos=cargos;
                          }                      
                          setCargosAtom(_cargos); 
                          
                          formularioPerfil.setFieldsValue({ cargoId:cargoId})
                      }
                    }
                }    

            } catch (error) {
                console.error(error);
            }

        }

        cargarDatos();        

    },[]);
    const onClickBtnActualizarPerfil=async(perfil)=>{              
        perfil.empresaId=getUsuarioAutenticado().empresaSeleccionada.id;  
        perfil.dependencia=dependenciaPerfilAtom;         
        setPerfil( perfil );
        mostrarModalConfirmacion();

    }
    const handleOnchangeSeccion = async(value,option)=>{

        let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.SECCION);
    
        for(let i=0 ; i < listaCampos.length ; i++){
           if (listaCampos[i].campo===CampoColaborador.SEDE){         
             const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _sedes = sedes.filter(sede=>sede.seccionId===value)
             setSedesAtom(_sedes);  
             formularioPerfil.setFieldsValue({ sedeId:""})
                 
             
           }         
           else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
             const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _subsedes = subsedes.filter(subsede=>subsede.seccionId===value);
             setSubsedesAtom(_subsedes);
             formularioPerfil.setFieldsValue({ subsedeId:""})
            
           }
           else if(listaCampos[i].campo===CampoColaborador.CARGO){
             const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _cargos = cargos.filter(cargo=>cargo.seccionId===value);
             setCargosAtom(_cargos);
             formularioPerfil.setFieldsValue({ cargoId:""})
             
           }
           else if(listaCampos[i].campo===CampoColaborador.PLANILLA){
             const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _tipoPlanilla = tipoPlanilla.filter(planilla=>planilla.seccionId===value);
             setTipoPlanillasAtom(_tipoPlanilla);
             formularioPerfil.setFieldsValue({ tipoPlanillaId:""})
             
           }
          }   
      }
      const handleOnchangeSede = async(value,option)=>{
        let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.SEDE);
    
        for(let i=0 ; i < listaCampos.length ; i++){
           if (listaCampos[i].campo===CampoColaborador.SECCION){         
             const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _secciones = secciones.filter(seccion=>seccion.sedeId===value)
             setSeccionesAtom(_secciones);  
             formularioPerfil.setFieldsValue({ seccionId:""})
                 
             
           }         
           else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
             const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _subsedes = subsedes.filter(subsede=>subsede.sedeId===value);
             setSubsedesAtom(_subsedes);
             formularioPerfil.setFieldsValue({ subsedeId:""})
            
           }
           else if(listaCampos[i].campo===CampoColaborador.CARGO){
             const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _cargos = cargos.filter(cargo=>cargo.sedeId===value);
             setCargosAtom(_cargos);
             formularioPerfil.setFieldsValue({ cargoId:""})
             
           }
           else if(listaCampos[i].campo===CampoColaborador.PLANILLA){
             const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _tipoPlanilla = tipoPlanilla.filter(planilla=>planilla.sedeId===value);
             setTipoPlanillasAtom(_tipoPlanilla);
             formularioPerfil.setFieldsValue({ tipoPlanillaId:""})
             
           }
          }   
      }
      const handleOnchangeSubsede = async(value,option)=>{
        let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.SUBSEDE);
    
        for(let i=0 ; i < listaCampos.length ; i++){
           if (listaCampos[i].campo===CampoColaborador.SECCION){         
             const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _secciones = secciones.filter(seccion=>seccion.subsedeId===value)
             setSeccionesAtom(_secciones);  
             formularioPerfil.setFieldsValue({ seccionId:""})
                 
             
           }         
           else if(listaCampos[i].campo===CampoColaborador.SEDE){
             const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _sedes = sedes.filter(sede=>sede.subsedeId===value);
             setSedesAtom(_sedes);
             formularioPerfil.setFieldsValue({ sedeId:""})
            
           }
           else if(listaCampos[i].campo===CampoColaborador.CARGO){
             const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _cargos = cargos.filter(cargo=>cargo.subsedeId===value);
             setCargosAtom(_cargos);
             formularioPerfil.setFieldsValue({ cargoId:""})
             
           }
           else if(listaCampos[i].campo===CampoColaborador.PLANILLA){
             const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _tipoPlanilla = tipoPlanilla.filter(planilla=>planilla.subsedeId===value);
             setTipoPlanillasAtom(_tipoPlanilla);
             formularioPerfil.setFieldsValue({ tipoPlanillaId:""})
             
           }
          }   
        
      }
      const handleOnchangeTipoPlanilla = async(value,option)=>{
        let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.PLANILLA);
    
        for(let i=0 ; i < listaCampos.length ; i++){
           if (listaCampos[i].campo===CampoColaborador.SECCION){         
             const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _secciones = secciones.filter(seccion=>seccion.tipoPlanillaId===value)
             setSeccionesAtom(_secciones);  
             formularioPerfil.setFieldsValue({ seccionId:""})
                 
             
           }         
           else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
             const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _subsedes = subsedes.filter(subsede=>subsede.tipoPlanillaId===value);
             setSubsedesAtom(_subsedes);
             formularioPerfil.setFieldsValue({ subsedeId:""})
            
           }
           else if(listaCampos[i].campo===CampoColaborador.CARGO){
             const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _cargos = cargos.filter(cargo=>cargo.tipoPlanillaId===value);
             setCargosAtom(_cargos);
             formularioPerfil.setFieldsValue({ cargoId:""})
             
           }
           else if(listaCampos[i].campo===CampoColaborador.SEDE){
             const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _sedes =  sedes.filter(sede=>sede.tipoPlanillaId===value);
             setSedesAtom(_sedes);
             formularioPerfil.setFieldsValue({ sedeId:""})
             
           }
          }   
  
      }
      const handleOnchangeCargo = async(value,option)=>{
        let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.CARGO);
    
        for(let i=0 ; i < listaCampos.length ; i++){
           if (listaCampos[i].campo===CampoColaborador.SECCION){         
             const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _secciones = secciones.filter(seccion=>seccion.cargoId===value)
             setSeccionesAtom(_secciones);  
             formularioPerfil.setFieldsValue({ seccionId:""})
                 
             
           }         
           else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
             const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _subsedes = subsedes.filter(subsede=>subsede.cargoId===value);
             setSubsedesAtom(_subsedes);
             formularioPerfil.setFieldsValue({ subsedeId:""})
            
           }
           else if(listaCampos[i].campo===CampoColaborador.PLANILLA){
             const tipoPlanillas=await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _tipoPlanillas = tipoPlanillas.filter(planilla=>planilla.cargoId===value);
             setTipoPlanillasAtom(_tipoPlanillas);
             formularioPerfil.setFieldsValue({ tipoPlanillaId:""})
             
           }
           else if(listaCampos[i].campo===CampoColaborador.SEDE){
             const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             const _sedes =  sedes.filter(sede=>sede.cargoId===value);
             setSedesAtom(_sedes);
             formularioPerfil.setFieldsValue({ sedeId:""})
             
           }
          }   
      }

    return(
        <Form
            layout="vertical"                      
            form={formularioPerfil}
            onFinish={onClickBtnActualizarPerfil}
        >
            <Form.Item 
              label="id"
              name="id"
              noStyle
            >
             <Input type="hidden" />
            </Form.Item>
            {
              listaCamposAtom.length>0 &&(
                listaCamposAtom.map((listaCampo)=>{
                    if (listaCampo===CampoColaborador.SECCION){   
                      return(
                            <Row>
                                    <Col span={12} offset={6}>
                                    <Form.Item
                                        label="Sección"
                                        name="seccionId"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Seleccione Sección"
                                          },
                                        ]}
                                    >
                                      <Select
                                      placeholder="Seleccione Sección"
                                      onChange={handleOnchangeSeccion}
                                      >
                                      {seccionesAtom.length > 0 &&
                                        seccionesAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                      </Select>

                                    </Form.Item>
                                    </Col>
                          </Row>
                      )       
                      }                                             
                      
                      else if (listaCampo===CampoColaborador.SEDE){
                        return(
                          <Row>
                                    <Col span={12} offset={6}>
                                    <Form.Item
                                        label="Sede"
                                        name="sedeId"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Seleccione Sede"
                                          },
                                        ]}
                                    >
                                      <Select
                                      placeholder="Seleccione Sede"
                                      onChange={handleOnchangeSede}
                                      >
                                      {sedesAtom.length > 0 &&
                                        sedesAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                        
                                      </Select>

                                    </Form.Item>
                                    </Col>
                          </Row>
                        )
                      }
                      else if (listaCampo===CampoColaborador.SUBSEDE){
                        return(
                          <Row>
                                <Col span={12} offset={6}>
                                <Form.Item
                                    label="Subsede"
                                    name="subsedeId"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Seleccione Subsede"
                                      },
                                    ]}
                                >
                                  <Select
                                  placeholder="Seleccione Subsede"
                                  onChange={handleOnchangeSubsede}
                                  >
                                  {subsedesAtom.length > 0 &&
                                        subsedesAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                  </Select>

                                </Form.Item>
                                </Col>
                        </Row>
                        )
                      }
                      else if (listaCampo===CampoColaborador.PLANILLA){
                        return(
                          <Row>
                                <Col span={12} offset={6}>
                                <Form.Item
                                    label="Tipo planilla"
                                    name="tipoPlanillaId"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Seleccione Tipo planilla"
                                      },
                                    ]}
                                >
                                  <Select
                                  placeholder="Seleccione Tipo planilla"
                                  onChange={handleOnchangeTipoPlanilla}
                                  >
                                  {tipoPlanillasAtom.length > 0 &&
                                        tipoPlanillasAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                  </Select>

                                </Form.Item>
                                </Col>
                        </Row>
                        )
                
                      }
                      else if (listaCampo===CampoColaborador.CARGO){
                        return(
                          <Row>
                                <Col span={12} offset={6}>
                                <Form.Item
                                    label="Cargo"
                                    name="cargoId"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Seleccione Cargo"
                                      },
                                    ]}
                                >
                                  <Select
                                    placeholder="Seleccione Cargo"
                                    onChange={handleOnchangeCargo}
                                  >
                                  {cargosAtom.length > 0 &&
                                        cargosAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                  </Select>

                                </Form.Item>
                                </Col>
                        </Row>
                        )             
                      }                      
                                        
                })
                
              )           
            
          }     
            
            <Row>
                <Col span={12} offset={6}>
                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        rules={[
                            {
                                required: true,
                                message: "Escribe el nombre del perfil.",
                            },
                        ]}   
                    >
                        <Input/>
                    </Form.Item>
               </Col>
            </Row>
            <Form.Item style={{ textAlign: "center" }} >
                <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    htmlType="submit"
                >
                    Actualizar Perfil
                </Button>
            </Form.Item>
        </Form>
    )

}
export default PerfilEditar;