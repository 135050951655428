import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "../../../components/Container";
import {
  Input,
  notification,
  Button,
  Table,
  Space,
  Popconfirm,
} from "antd";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import {
  UnorderedListOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import Highlighter from "react-highlight-words";
import RolService from "../../../services/RolService";
import Title from "antd/lib/skeleton/Title";
export function RolListarPage() {
  const [roles, setRoles] = useState([]);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  let history = useHistory();
  useEffect(() => {
    async function cargarDatos() {
      const rolesListados = await RolService.listar(
        getUsuarioAutenticado().empresaSeleccionada.id, 0
      );
      setRoles(rolesListados);
    }

    cargarDatos();
  }, []);

  var getColumnSearchProps = function getColumns(dataIndex, propiedad) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchInput}
              placeholder={`Buscar por ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Buscar
                </Button>
              <Button
                onClick={() => handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Limpiar
                </Button>
            </Space>
          </div>
        ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        propiedad === undefined
          ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
          : record[dataIndex][propiedad]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={
              propiedad === undefined
                ? text?.toString()
                : text[propiedad]?.toString()
            }
          />
        ) : propiedad === undefined ? (
          text
        ) : (
              text[propiedad]
            ),
    };
  };
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText("");
  }

  const columnas = [

    {
      title: "Nombre",
      dataIndex: "nombre",
      align: "center",
      ...getColumnSearchProps("nombre"),
    },
    {
      title: "Acciones",
      render: (fila) => (
        <Space size={8}>
          <a
            onClick={() => handleOnClickEditarRol(fila.id)}
            className="custom-color-element"
          >
            <EditOutlined /> Editar
              </a>
          {fila.nombre!=="Administrador" &&(
            <Popconfirm
            title="¿Está seguro de eliminar el registro seleccionado?"
            okText="Si"
            cancelText="No"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => handleOnClickEliminarRol(fila.id)}
          >
            <a style={{ color: "#E01D4C" }}>
              <DeleteOutlined /> Eliminar
                </a>
          </Popconfirm>
          )}
          {/* <Popconfirm
            title="¿Está seguro de eliminar el registro seleccionado?"
            okText="Si"
            cancelText="No"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => handleOnClickEliminarRol(fila.id)}
          >
            <a style={{ color: "#E01D4C" }}>
              <DeleteOutlined /> Eliminar
                </a>
          </Popconfirm> */}
        </Space>
      )
    },
  ];
  function handleOnClickCrearRol() {
    history.push("/rol/crear");
  }
  function handleOnClickEditarRol(idRol) {
    history.push(`/rol/editar/${idRol}`);
  }
  async function handleOnClickEliminarRol(idRol) {
    await RolService.eliminar(idRol);
    setRoles(
      roles.filter((rol) => rol.id !== idRol)
    );
    notification.success({
      message: "Operación exitosa",
      description: "Se eliminó el registro correctamente.",
    });
  }

  return (
    <Container
      icono={<UnorderedListOutlined />}
      titulo="Rol"
      botonExtra={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleOnClickCrearRol}
        >
          Crear Rol
          </Button>}
    >
      {/* <Space>
                <div style={{ marginBottom: 16 }}>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={handleOnClickCrearRol}
                >
                    Crear Rol
                </Button>
                </div>                
            </Space> */}
      <Table
        rowKey={(fila) => fila.id}
        columns={columnas}
        dataSource={roles}
        pagination={{
          pageSize: [10],
        }}
      />
    </Container>

  );
}