import React, { useState, useRef, useEffect } from "react";
import { FilterServerInput } from "./FilterServerInput";
import { FilterServerInputMenu } from "./FilterServerInputMenu";
import { FilterServerInputMenuEditar } from "./FilterServerInputMenuEditar";
import FilterServerConstantes from "./FilterServerConstantes";
import Cadenas from "./util/Cadenas";
import { useMenuVisible } from "./FilterServerHook";
import uuid from "react-uuid";
import "./FilterServer.css";
import { message } from "antd";

const FilterServer = ({
  placeholder,
  filtros,
  data,
  // setDataFiltrada,
  filtrosIniciales = [],
  filtroSeleccionadoInicial=null,
  onFiltrarDatos,
  reset
}) => {
  const refDivFiltro = useRef(null);
  const refTxtBusqueda = useRef(null);
  const [refFilter, menu, setMenu] = useMenuVisible("");
  const [valorTxtBusqueda, setValorTxtBusqueda] = useState("");
  const [filtroSeleccionado, setFiltroSeleccionado] = useState(filtroSeleccionadoInicial);
  const [filtrosFiltrados, setFiltrosFiltrados] = useState(filtros);

  const [filtrosSeleccionados, setFiltrosSeleccionados] = useState(filtrosIniciales);

  const [filtroEditar, setFiltroEditar] = useState({});

  useEffect(() => {
    //if (filtrosSeleccionados.length > 0) {
    let queryById = groupBy("columna", "valor");
    let query = queryById(filtrosSeleccionados);
    onFiltrarDatos(query);
    //}
  }, [filtrosSeleccionados]);

  const groupBy = (key, value) => (array) =>
    array.reduce((objectsByKeyValue, obj) => {
      const clave = obj[key];
      const valor = obj[value];
      objectsByKeyValue[clave] = (objectsByKeyValue[clave] || []).concat([valor]);
      return objectsByKeyValue;
    }, {});

  function onChangeTxtBusqueda(valorTxtBusqueda) {
    let tipoMenu = getTipoMenu(valorTxtBusqueda);
    switch (tipoMenu) {
      case FilterServerConstantes.MENU.filtros:
        setMenu(FilterServerConstantes.MENU.filtros);
        setValorTxtBusqueda(valorTxtBusqueda);
        setFiltrosFiltrados({
          ...filtros,
          data: filtrarFiltros(valorTxtBusqueda),
        });
        break;
      case FilterServerConstantes.MENU.opciones:
        setMenu(FilterServerConstantes.MENU.opciones);
        setValorTxtBusqueda(valorTxtBusqueda);
        break;
      default:
        setMenu("");
    }
  }

  function onKeyDownTxtBusqueda(evento, valor) {
    switch (evento.key) {
      case "Enter":
      case "Tab":
        if (valorTxtBusqueda === "") {
          setMenu("");
          return;
        }
        onCrearFiltro(valor);
        break;
      case "Backspace":
        if (valorTxtBusqueda.trim() === "" && filtrosSeleccionados.length > 0) {
          seleccionarUltimoFiltroSeleccionado();
          setMenu("");
        }
        break;
      default:
        return;
    }
  }

  function onClickTxtBusqueda(valorTxtBusqueda) {
    let tipoMenu = getTipoMenu(valorTxtBusqueda);
    setMenu(tipoMenu);
  }

  function onSeleccionarFiltro(filtro) {
    setFiltroSeleccionado(filtro);
    setValorTxtBusqueda(filtro.titulo + " : ");
    setMenu(FilterServerConstantes.MENU.opciones);
    refTxtBusqueda.current.focus();
  }

  function onCrearFiltro(valor) {
    crearFiltro(
      filtroSeleccionado.id,
      filtroSeleccionado.titulo,
      valor,
      filtroSeleccionado.fixed,
      filtroSeleccionado.tipo
    );
    setFiltrosFiltrados(filtros);
    setValorTxtBusqueda("");
    setMenu("");
    refTxtBusqueda.current.focus();
  }

  function onQuitarFiltro(id) {
    var quitarFiltro = true;

    var filtroSeleccionado = filtrosSeleccionados.find((filtro) => filtro.id === id);

    if (filtroSeleccionado.fixed) {
      let queryById = groupBy("columna", "valor");
      let query = queryById(filtrosSeleccionados);

      if (query[filtroSeleccionado.columna].length === 1) {
        quitarFiltro = false;
      }
    }

    if (quitarFiltro) {
      setFiltrosSeleccionados(
        filtrosSeleccionados.filter((filtroSeleccionado) => {
          return filtroSeleccionado.id !== id;
        })
      );
      setMenu("");
      refTxtBusqueda.current.focus();
    }
  }

  function onClickFiltro(filtro) {
    setMenu(FilterServerConstantes.MENU.opcionesEditar);
    setFiltroEditar(filtro);
  }

  function onCambiarFiltro(filtro) {
    const filtrosSeleccionadosModificados = filtrosSeleccionados.map((filtroSeleccionado) => {
      if (filtroSeleccionado.id === filtro.id) {
        filtroSeleccionado.valor = filtro.nuevoValor;
        return filtroSeleccionado;
      }
      return filtroSeleccionado;
    });

    setFiltrosSeleccionados(filtrosSeleccionadosModificados);
    setMenu("");
  }

  function seleccionarUltimoFiltroSeleccionado() {
    let ultimoFiltroSeleccionado = filtrosSeleccionados[filtrosSeleccionados.length - 1];
    document.getElementById("filtro-seleccionado-" + ultimoFiltroSeleccionado.id).focus();
  }

  function crearFiltro(columna, etiqueta, valor, fixed, tipo) {
    if(!filtrosSeleccionados.find(e=>e.columna===columna && e.valor === valor)){
      let filtro = {
      id: uuid(),
      columna: columna,
      etiqueta: etiqueta,
      valor: valor,
      fixed: fixed,
      tipo: tipo,
    };
    setFiltrosSeleccionados([...filtrosSeleccionados, filtro]);
    }else{
      message.error('El filtro ya ha sido seleccionado.');
    }
    
  }

  function filtrarFiltros(valorTxtBusqueda) {
    let filtrosFiltrados = filtros.data.filter((filtro) => {
      let tituloFiltroSinTilde = Cadenas.eliminarTildes(filtro.titulo);
      let valorTxtBusquedaSinTilde = Cadenas.eliminarTildes(valorTxtBusqueda);
      return tituloFiltroSinTilde.toLowerCase().trim().includes(valorTxtBusquedaSinTilde.toLowerCase().trim());
    });
    return filtrosFiltrados;
  }

  function getTipoMenu(valorTxtBusqueda) {
    if (valorTxtBusqueda.indexOf(":") === -1) {
      return FilterServerConstantes.MENU.filtros;
    }
    return FilterServerConstantes.MENU.opciones;
  }

  function onCerrarMenu() {
    setMenu("");
  }
  useEffect(() => {
    if(reset)
      setFiltrosSeleccionados([])
      
  }, [reset]);
  

  return (
    <div className="filter-container" ref={refFilter}>
      <FilterServerInput
        placeholder={placeholder}
        filtrosSeleccionados={filtrosSeleccionados}
        valorTxtBusqueda={valorTxtBusqueda}
        onQuitarFiltro={onQuitarFiltro}
        onClickFiltro={onClickFiltro}
        onChangeTxtBusqueda={onChangeTxtBusqueda}
        onKeyDownTxtBusqueda={onKeyDownTxtBusqueda}
        onClickTxtBusqueda={onClickTxtBusqueda}
        refTxtBusqueda={refTxtBusqueda}
        refDivFiltro={refDivFiltro}
        menu={menu}
      />

      <FilterServerInputMenu
        filtroSeleccionado={filtroSeleccionado}
        filtros={filtrosFiltrados}
        menu={menu}
        onSeleccionarFiltro={onSeleccionarFiltro}
        onCrearFiltro={onCrearFiltro}
        data={data}
        refTxtBusqueda={refTxtBusqueda}
      />

      <FilterServerInputMenuEditar
        menu={menu}
        filtro={filtroEditar}
        onCambiarFiltro={onCambiarFiltro}
        onCerrarMenu={onCerrarMenu}
      />
    </div>
  );
};

export default FilterServer;
