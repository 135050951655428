import { Button, Space ,Dropdown,Menu} from "antd";
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import React from "react";
import { useCalcularVacaciones, useDrawerCargarHistoricoVacaciones, useGenerarReporteExcel,useGenerarReporteExcelSolicitudes } from "../../hooks";

const BotonesExtra = () => {
  const { calcularVacaciones } = useCalcularVacaciones();
  const { mostrar: mostrarDrawerCargarHistoricoVacaciones } = useDrawerCargarHistoricoVacaciones();
  const { generarReporteExcel } = useGenerarReporteExcel(); 
  const {generarReporteSolicitudes} = useGenerarReporteExcelSolicitudes();

  const menu = () => {

    return (
        <Menu onClick={onClickMenu}>
            <Menu.Item key="vacaciones">
                Vacaciones
            </Menu.Item>
            <Menu.Item key="solicitudes">
                 solicitudes
            </Menu.Item>
            
        </Menu>
    )
}

const onClickMenu = (opcion) => {
  switch (opcion.key) {
      case 'vacaciones':
          generarReporteExcel();
          break;

      case 'solicitudes':
        generarReporteSolicitudes();
          break;
      
      default:
          console.error("No se encuentra esa opción");;
  }
}
  return (
    <Space>
      <Button type="primary" onClick={mostrarDrawerCargarHistoricoVacaciones}>
        Cargar Histórico
      </Button>

      <Button type="primary" onClick={calcularVacaciones}>
        Generar Vacaciones
      </Button>
      {/* <Button type="primary" onClick={generarReporteExcel}>
        Exportar Vacaciones
      </Button> */}
      <Dropdown overlay={menu}>        
        <Button type="primary"><Space>Exportar <DownOutlined/></Space></Button>      
      </Dropdown>
    </Space>
  );
};

export default BotonesExtra;
