import { useAtom } from "jotai";
import { documentoProcesoAtom, documentosAtom, loadingAtom } from "../AgregarDocumentoStore";
import { ProcesoService } from "services/reclutamiento-y-seleccion/ProcesoService";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";

const useCreaProceso = () => {
  const [documentos] = useAtom(documentosAtom);
  const[documentoProceso,]=useAtom(documentoProcesoAtom);
  const [loading, setLoading] = useAtom(loadingAtom);
  //const { getUsuarioAutenticado } = useContext(SecurityContext);
  let history = useHistory();

  const crearProceso = async (procesoId,proceso) => {
    try {
      setLoading(true);
      if (documentos.length > 0) {
        
        proceso.documentos = documentos.filter(d=> !documentoProceso?.etapas.includes(d.etapa.nombre) );
        proceso.documentos.etapas = proceso.etapasId;

        await ProcesoService.agregarProcesos(procesoId, proceso);
        setLoading(false);
        history.push("/reclutamiento-y-seleccion/procesos");
      } else {
        message.error("No ha seleccionado documentos");
      }
    } catch (error) {
      console.log(error);
    } finally{
      setLoading(false);
    }
  };
  return { crearProceso };
};
export default useCreaProceso;
