import React, { useState, useContext } from "react";
import { Select, Form, Input, Row, Col, Button, notification, DatePicker } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { SexoColaborador } from "enums/SexoColaborador";
import { ContainerCrear } from "components/ContainerCrear";
import { SecurityContext } from "context/SecurityContextProvider";
import { tipoDocumentoIdentidad } from "enums/TipoDocumentoIdentidad";
import ColaboradorService from "services/ColaboradorService";
import { useEffect } from "react";
import { CampoColaborador } from "enums/CampoColaborador";
import { ConvocatoriaService } from "services/reclutamiento-y-seleccion/ConvocatoriaService";
import { PostulantesService } from "services/reclutamiento-y-seleccion";

const { Option } = Select;

export function AgregarPostulantePage({
  setDrawerVisible,
  setModalAgregarPostulante,
  agregarPostulante,
  convocatoriaId,
}) {
  const [formularioCrear] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [tipoDocumentoSeleccionado, setTipoDocumentoSeleccionado] = useState(null);
  const [camposColaboradores, setCamposPostulantes] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [subsedes, setSubsedes] = useState([]);
  const [perfiles, setPerfiles] = useState([]);
  const [tipoPlanillas, setTipoPlanillas] = useState([]);

  useEffect(() => {
    async function cargarDatos() {
      const campos_postulante = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
      setCamposPostulantes(campos_postulante);
      const campoEnviado = await ColaboradorService.camposColaboradorListarNuevo(
        getUsuarioAutenticado().empresaSeleccionada.id
      );

      setPerfiles(campoEnviado.PERFIL);
      setSedes(campoEnviado.SEDE);
      setSubsedes(campoEnviado.SUBSEDE);
      setSecciones(campoEnviado.SECCION);
      setCargos(campoEnviado.CARGO);
      setTipoPlanillas(campoEnviado.PLANILLA);
    }
    cargarDatos();
  }, []);

  async function handleOnFinish(postulante) {
    try {
      setLoading(true);
      const respuesta = await PostulantesService.insertar(postulante);
      await ConvocatoriaService.agregarPostulante({
        convocatoriaId: convocatoriaId,
        postulanteId: respuesta.id,
      });

      agregarPostulante({
        id: respuesta.id,
        nombreCompleto: postulante.nombres + " " + postulante.apellidoPaterno + " " + postulante.apellidoMaterno,
      });

      notification.success({
        message: "Éxito",
        description: "Se registró el postulante correctamente.",
      });
      // postulante.empresa = {
      //   id: getUsuarioAutenticado().empresaSeleccionada.id,
      //   razonSocial: getUsuarioAutenticado().empresaSeleccionada.razonSocial,
      //   urlImagenLogotipo: getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo,
      // };
      // postulante.organizacionId = getUsuarioAutenticado().organizacionId;
      // postulante.subdominio = getUsuarioAutenticado().subdominio;
      // postulante.login = postulante.numeroDocumentoIdentidad;
      // postulante.eliminado = false;
      // postulante.apellidoPaterno = postulante.apellidoPaterno.trim();
      // postulante.apellidoMaterno = postulante.apellidoMaterno.trim();
      // postulante.nombres = postulante.nombres.trim();
      // postulante.celular = !postulante.celular || postulante.celular.trim() === "" ? null : postulante.celular.trim();
      // postulante.vinculoLaboral = "POSTULANTE";
      // const respuesta = await ColaboradorService.insertar(postulante);

      // notification.success({
      //   message: "Operación exitosa",
      //   description: "Se registró el postulante correctamente.",
      // });
      // const data = {
      //   convocatoriaId: convocatoriaId,
      //   postulanteId: respuesta,
      // };
      // await ConvocatoriaService.agregarPostulante(data);
      // const dataPostulante = {
      //   id: respuesta,
      //   nombreCompleto:
      //     postulante.nombres.trim() + " " + postulante.apellidoPaterno.trim() + " " + postulante.apellidoMaterno.trim(),
      // };
      // agregarPostulante(dataPostulante);
      // formularioCrear.resetFields();
      setModalAgregarPostulante(false);
      setDrawerVisible(false);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      notification.error({
        message: "Error",
        description: error.response.data.mensaje,
      });
      setLoading(false);
    }
  }

  const onChangeNumeroDocumentoIdentidad = (e) => {
    const dni = formularioCrear.getFieldValue("numeroDocumentoIdentidad").replace(/\s+/g, "");
    formularioCrear.setFieldsValue({
      numeroDocumentoIdentidad: dni,
    });
  };

  const onSelectTipoDocumento = (string, option) => {
    setTipoDocumentoSeleccionado(option.value);
  };

  return (
    // <ContainerCrear
    //   titulo="Crear Postulante"
    //   //descripcion="Crear un postulante dentro de tu organización permitirá enviarle cualquier documento laboral usando la información registrada."
    //   colSpan={22}
    // >
    <Form form={formularioCrear} layout="vertical" size="large" onFinish={handleOnFinish}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Tipo Documento Identidad"
            name="tipoDocumentoIdentidad"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select allowClear onSelect={onSelectTipoDocumento}>
              <Option value={tipoDocumentoIdentidad.DNI.valor}>{tipoDocumentoIdentidad.DNI.nombre}</Option>
              <Option value={tipoDocumentoIdentidad.CARNET_EXTRANJERIA.valor}>
                {tipoDocumentoIdentidad.CARNET_EXTRANJERIA.descripcion}
              </Option>
              <Option value={tipoDocumentoIdentidad.PASAPORTE.valor}>{tipoDocumentoIdentidad.PASAPORTE.nombre}</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Número de Documento Identidad"
            name="numeroDocumentoIdentidad"
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input
              autoComplete="off"
              maxLength={tipoDocumentoSeleccionado == tipoDocumentoIdentidad.DNI.valor ? 8 : 15}
              onChange={onChangeNumeroDocumentoIdentidad}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Apellido Paterno"
            name="apellidoPaterno"
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Apellido Materno"
            name="apellidoMaterno"
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Nombres"
            name="nombres"
            rules={[
              {
                required: true,
                whitespace: true,
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Género"
            name="genero"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select>
              <Option value={SexoColaborador.FEMENINO}>{SexoColaborador.FEMENINO} </Option>
              <Option value={SexoColaborador.MASCULINO}>{SexoColaborador.MASCULINO}</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Correo Electrónico"
            name="correoElectronico"
            rules={[
              {
                required: true,
              },
              {
                type: "email",
                message: "Correo electrónico ingresado no es válido",
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Celular" name="celular">
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Fecha Nacimiento" name="fechaNacimiento">
            <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} placeholder="" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center">
        <Form.Item style={{ textAlign: "center" }}>
          <Button
            type="primary"
            icon={<CheckOutlined />}
            htmlType="submit"
            loading={loading}
            style={{ borderRadius: "20px" }}
          >
            Crear Postulante
          </Button>
        </Form.Item>
      </Row>
    </Form>
    // </ContainerCrear>
  );
}
