import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { ListaConsultaSolicitudes } from "./componentes/ListaConsultaSolicitudes";
import { FiltroConsultaSolicitudes } from "./componentes/FiltroConsultaSolicitudes";
import { ModalSeguimiento } from "./componentes/ModalSeguimiento";
import { ModalAdjuntarAltaMedica } from "./componentes/ModalAdjuntarAltaMedica";
import { ModalVisorPdf } from "components/Modal/ModalVisorPdf";
import { ModalCrearSolicitud } from "../modalCrearSolicitud";
import { DrawerImportarAusenciasMasiva } from "./componentes";

const ConsultaSolicitudesPage = () => {
    return (
        <JotaiProvider>
            <FiltroConsultaSolicitudes />
            <ListaConsultaSolicitudes />
            <ModalVisorPdf />
            <ModalCrearSolicitud />
            <DrawerImportarAusenciasMasiva />
        </JotaiProvider>
    );
};
export default ConsultaSolicitudesPage;
