import React from "react";
import {Spin, Table, Tag} from "antd";
import { useCargarConvocatorias } from "../../hooks";
import  MenuAcciones  from "./MenuAcciones";
import { LoadingOutlined } from '@ant-design/icons';
import { useAtom } from "jotai";
import { loadingAtom } from "../../ConvocatoriaListarStore"
const TablaConvocatoria=()=>{
     const {convocatorias} = useCargarConvocatorias();
     const [loading] = useAtom(loadingAtom)
    const columnas=[
        {
          title:"Tipo",
          dataIndex:"tipo",
          align:"center",
        },
        {
            title:"Cargo",
            dataIndex:"cargo",
            align:"center",
       },
       {
          title:"Fecha Límite Postulación",
          dataIndex:"fechaLimitePostulacion",
          align:"center",
       },
       {
        title:"Estado",
        dataIndex:"estado",
        align:"center",
        render: (fila) =><Tag color={ fila!==null && fila === "ACTIVO"?"success":"error"} >{fila}</Tag>

     },
       {
        title:"Acciones",        
        key:"acciones",
        align:"center",
        render:(fila)=>(<MenuAcciones convocatoria={fila}/>)
      },

    ];
    return(
      <Spin indicator={<LoadingOutlined style={{ fontSize: 28 }} />} spinning={loading} tip="Cargando...">
        <Table
        rowKey={(convocatoria)=>convocatoria.id}
        dataSource={convocatorias}
        columns={columnas}
        />
        </Spin>
    )

}
export default TablaConvocatoria;