import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { Alert } from "antd";
import { useFormContext } from "react-hook-form";
import { errorMostradoAtom } from "../../VisorFormularioStorage";

const Errores = () => {
  const [errorMostrado, setErrorMostrado] = useAtom(errorMostradoAtom);
  const { formState } = useFormContext();

  useEffect(() => {
    const cargarErrores = () => {
      if (formState?.errors && Object.keys(formState?.errors).length == 0) {
        setErrorMostrado(undefined);
      } else {
        mostrarError(formState?.errors);
      }
    };
    cargarErrores();
  }, [formState]);

  const mostrarError = (erroresFormulario) => {
    if (Object.keys(erroresFormulario).length > 0) {
      const keyPrimerError = Object.keys(erroresFormulario)[0];
      let mensaje = "";
      if (Array.isArray(erroresFormulario[keyPrimerError])) {
        const errores = erroresFormulario[keyPrimerError].filter((e) => {
          if (e) {
            return e;
          }
        });
        const key = Object.keys(errores[0])[0];
        mensaje = errores[0][key].message;
      } else {
        mensaje = erroresFormulario[keyPrimerError].message;
      }
      setErrorMostrado({
        key: keyPrimerError,
        mensaje: mensaje,
      });
    }
  };

  if (errorMostrado === undefined) {
    return "";
  }

  return (
    <div style={{ maxHeight: "40px" }}>
      {errorMostrado && <Alert message={errorMostrado.mensaje} type="error" showIcon />}
    </div>
  );
};

export default Errores;
