import { atom } from "jotai";

export const contratosAprobadosParaRenovarAtom = atom([]);
export const contratoSeleccionadoAtom = atom(undefined);
export const visibleModalAprobarContratoAtom = atom(false);

export const plantillasAtom = atom([]);
export const pasoActualAtom = atom(0);
export const controlesFormularioContratoAtom = atom([]);
export const visibleFormularioContratoAtom = atom(false);
export const informacionFormularioContratoAtom = atom(undefined);
export const plantillaContratoSeleccionadoAtom = atom(undefined);

export const zoomVisorPdfAtom = atom(1.25);
