import React,{useEffect} from "react";
import { listaCargasAtom } from "../LegajoCargaStore";
import { useAtom } from "jotai";
import CargaLegajoService from "services/CargaLegajoService";


const useListarCarga = () =>{

    const [cargas,setCargas] = useAtom (listaCargasAtom);

    useEffect(()=>{
        async function cargarDatos(){
            const response = await CargaLegajoService.listar();
            console.log("XXXX::", response.data);
              setCargas(response.data);
        }
        cargarDatos()
    },[])
    return {cargas};
}
export default useListarCarga;



