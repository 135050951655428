import React, { useContext, useReducer } from "react";
import { ContratoCrearContext } from "./ContratoCrearContext";
import { ContratoCrearReducer } from "./ContratoCrearReducer";
import { SecurityContext } from "context/SecurityContextProvider";
import { PlantillaContratoService } from "services/PlantillaContratoService";
import { ContratoService } from "services/ContratoService";
import { useHistory } from "react-router-dom";
import { CategoriaDocumento } from "enums/CategoriaDocumento";
import TipoDocumentoService from "services/TipoDocumentoService";

export function ContratoCrearContextProvider({ children }) {
  const estadoInicial = {
    loading: false,
    visibleModalConfirmacion: false,
    contrato: {},
    plantillasContratos: [],
  };

  const [state, dispatch] = useReducer(ContratoCrearReducer, estadoInicial);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const history = useHistory();

  const getPlantillasContratos = async () => {
    try {
      dispatch({ type: "INICIAR" });
      let plantillasContratos = await TipoDocumentoService.listarPorUsuarioYCategoria(
        getUsuarioAutenticado().empresaSeleccionada.id,
        getUsuarioAutenticado().login,
        CategoriaDocumento.CONTRATOS.id
      );
      dispatch({ type: "SET_PLANTILLAS_CONTRATOS", payload: plantillasContratos });
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: "FINALIZAR" });
    }
  };

  const guardarContrato = async () => {
    try {
      dispatch({ type: "INICIAR" });
      const envio = await ContratoService.insertar(state.contrato);
      console.log("idenvio-crear-contrato:", envio.id);
      dispatch({ type: "CERRAR_MODAL_CONFIRMACION" });
      history.push(`/contrato/seguimiento/${envio.id}/detalle`);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch({ type: "FINALIZAR" });
    }
  };

  const mostrarModalConfirmacion = (contrato) => {
    dispatch({ type: "SET_CONTRATO", payload: getContrato(contrato) });
    dispatch({ type: "MOSTRAR_MODAL_CONFIRMACION" });
  };

  const getContrato = (contrato) => {
    contrato.periodo = contrato.periodo.format("MM/YYYY");
    contrato.empresaId = getUsuarioAutenticado().empresaSeleccionada.id;
    contrato.organizacionId = getUsuarioAutenticado().organizacionId;
    contrato.usuarioCreacionId = getUsuarioAutenticado().id;
    contrato.usuarioCreacionLogin = getUsuarioAutenticado().login;
    contrato.usuarioCreacionNombreCompleto = getUsuarioAutenticado().nombreCompleto;
    return contrato;
  };

  const cerrarModalConfirmacion = () => {
    dispatch({ type: "CERRAR_MODAL_CONFIRMACION" });
  };

  return (
    <ContratoCrearContext.Provider
      value={{
        visibleModalConfirmacion: state.visibleModalConfirmacion,
        plantillasContratos: state.plantillasContratos,
        guardarContrato,
        getPlantillasContratos,
        mostrarModalConfirmacion,
        cerrarModalConfirmacion,
      }}
    >
      {children}
    </ContratoCrearContext.Provider>
  );
}
