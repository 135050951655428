import { useEffect } from "react";
import { useAtom } from "jotai";
import {convocatoriasAtom} from "../ProcesosCrearStore";
import { ConvocatoriaService } from "services/reclutamiento-y-seleccion/ConvocatoriaService";
const useCargarConvocatorias = ()=>{
    const[convocatorias,setConvocatorias]=useAtom(convocatoriasAtom);
     useEffect(()=>{
        const cargarDatos = async () =>{
            const respuesta= await ConvocatoriaService.listar();
            setConvocatorias(respuesta);
        }
        cargarDatos();
     },[]);
     return{convocatorias}
}
export default useCargarConvocatorias;