import React from "react";
import { Button,Popconfirm } from "antd";
import { EditOutlined, LayoutOutlined,DeleteOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import CarpetaService from "../../../../../services/CarpetaService";

export const MenuAcciones=({carpeta,carpetas,setCarpetas})=>{
    let history = useHistory();
    const BotonEditar = () => {
        const onClickBtnEditar=()=>{
                                   
            const rutaEditarTipoDocumentoPage=`/configuracion/carpetas-legajos/${carpeta.id}/editar`;                                              
            history.push(rutaEditarTipoDocumentoPage);
        
        }

        return (
            <Button type="link" icon={<EditOutlined />} onClick={onClickBtnEditar}>
                Editar
            </Button >
        )
    }
    const BotonEliminar = ()=>{
        const onclickBtnEliminar = async() =>{               
              await CarpetaService.eliminar(carpeta.id);
              setCarpetas(carpetas.filter((carpetaLegajo)=>carpetaLegajo.id!==carpeta.id));              
            
        }

        return(
            // <Button type="link" icon={<DeleteOutlined />} onClick={onclickBtnDeshabilitar}>
            //     Deshabilitar
            // </Button>
            <Popconfirm
            title="Estás seguro de eliminar la carpeta"
            onConfirm={onclickBtnEliminar}           
            okText="Si"
            cancelText="No"
           >
            <Button type="link" icon={<DeleteOutlined />} >
                Eliminar
            </Button>
          </Popconfirm>

        )
    }
    return (
        <React.Fragment>
            <BotonEditar />
            <BotonEliminar />
        </React.Fragment>
    );
}


