import React, { useEffect } from "react";
import { Container } from "components/Container";
import { Formulario } from "./formulario";
import { ModalSeleccionarColaboradores } from "./modal-seleccionar-colaboradores";
import { Provider as JotaiProvider, useAtom } from "jotai";

const NotificarFormularioPage = () => {
  return (
    <JotaiProvider>
      <Container titulo="Notificar Formulario">
        <Formulario />
        <ModalSeleccionarColaboradores />
      </Container>
    </JotaiProvider>
  );
};

export default NotificarFormularioPage;
