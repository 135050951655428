import React from "react";
import { Container } from "components/Container"
import { TablaTipoDocumentos } from "./tabla-tipo-documentos"
import { Botones } from "./botones"

const ListarTipoDocumentosPage = () => {
    return (

        <Container titulo="Tipo Documentos" botonExtra={<Botones />}>
            <TablaTipoDocumentos />
        </Container>
    )
}
export default ListarTipoDocumentosPage;