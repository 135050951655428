import React, { useEffect, useState } from "react";
import { DatePicker,Typography,Space } from "antd";
import { Line } from "@ant-design/plots";
import { ReporteSolicitudPersonalService } from "services/reclutamiento-y-seleccion";
import moment from "moment";
const ReporteRotacionPersonal = () => {
  const [data, setData] = useState([]);
  const [anio,setAnio] = useState(moment().year())
  useEffect(() => {
    //const anio = 2023;      
    ReporteSolicitudPersonalService.getTasaRotacionPersonal({ anio: anio })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => console.log("error", error));
    //console.log("información...");   
  }, [anio]);

  const onChange=(date, dateString)=>{   
  
    setAnio(parseInt(dateString,10));
  }

  const minimo = 3;
  const maximo = 10;
  const config = {
    data,
    padding: "auto",
    xField: "mes",
    yField: "porcentajeRotacion",
    yAxis: {
      tickInterval: 2,
      min:20,
    },
    lineStyle: {
      stroke: "#F4664A",
    },
    label: {},
    point: {
      size: 3,
      shape: 'custom-point',
      style: {
        fill: 'white',
        stroke: '#5B8FF9',
        lineWidth: 2,
      },
    },    
    annotations: [
      {
        type: "regionFilter",
        start: ["min", minimo],
        end: ["max", maximo],
        color: "#1890ff",
      },
      {
        type: "text",
        position: ["min", maximo],
        content: "Valor máximo: " + maximo + "%",
        offsetY: -4,
        style: {
          textBaseline: "bottom",
        },
      },
      {
        type: "text",
        position: ["min", minimo],
        content: "Valor mínimo: " + minimo + "%",
        offsetY: -4,
        style: {
          textBaseline: "bottom",
        },
      },
      {
        type: "line",
        start: ["min", maximo],
        end: ["max", maximo],
        style: {
          stroke: "#1890ff",
          lineDash: [2, 2],
        },
      },
      {
        type: "line",
        start: ["min", minimo],
        end: ["max", minimo],
        style: {
          stroke: "#1890ff",
          lineDash: [2, 2],
        },
      },
    ],
  };
  return (
    <React.Fragment>
     <Space>
      <Typography.Text>Año:</Typography.Text>
        <DatePicker defaultValue={moment()} onChange={onChange} picker="year" allowClear={false}/>
      </Space> 
      <br/>
      <br/>
      <Line {...config} />
    </React.Fragment>
  
  );
};
export default ReporteRotacionPersonal;
