import React, { useState, useRef } from "react";
import { useMenuVisible } from "./FilterServerHook";
import FilterConstantes from "./FilterServerConstantes";
import { useEffect } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";

export function FilterServerInputFiltrosSeleccionados({
  refDivFiltro,
  filtrosSeleccionados,
  onQuitarFiltro,
  menu,
  onClickFiltro,
}) {
  // function onKeyDown(evento, idFiltro) {
  //   if (evento.key === "Backspace" || evento.key === "Delete") {
  //     onQuitarFiltro(idFiltro);
  //   }
  // }

  // function onClick(evento) {
  //   console.log("onClick");
  //   console.log(evento);
  // }

  // function mostrarMenuEditarFiltro() {}

  if (!filtrosSeleccionados || filtrosSeleccionados.length === 0) {
    return "";
  }

  return (
    <div className="filter-input-search-items">
      {filtrosSeleccionados.map((filtro) => {
        return (
          <FilterServerFilter
            key={filtro.id}
            id={filtro.id}
            etiqueta={filtro.etiqueta}
            valor={filtro.valor}
            onQuitarFiltro={onQuitarFiltro}
            menu={menu}
            onClickFiltro={onClickFiltro}
            refDivFiltro={refDivFiltro}
            tipoFiltro={filtro.tipo}
          />
        );
      })}
    </div>
  );
}

export function FilterServerFilter({
  id,
  etiqueta,
  valor,
  onQuitarFiltro,
  menu,
  onClickFiltro,
  tipoFiltro,
  // onSeleccionarFiltro,
}) {
  // const [estaSeleccionado, setEstaSeleccionado] = useState(false);
  // const [posicion, setPosicion] = useState({ left: 0 });
  const refDivFiltro = useRef(null);
  // const [mostrarMenu, setMostrarMenu] = useState(false);

  function onKeyDown(evento, idFiltro) {
    if (evento.key === "Backspace" || evento.key === "Delete") {
      onQuitarFiltro(idFiltro);
    }
  }

  function onClick() {
    // console.group("Filtro");
    // console.log("tipo: " + tipoFiltro);
    // console.groupEnd();
    // const posicion = { left: refDivFiltro.current.offsetLeft };

    const filtro = {
      id: id,
      etiqueta: etiqueta,
      valor: valor,
      tipo: tipoFiltro,
      style: {
        left: refDivFiltro.current.offsetLeft,
      },
    };

    onClickFiltro(filtro);
  }

  function handleOnClickCerrarFiltro(idFiltro, e) {
    e.stopPropagation();
    onQuitarFiltro(idFiltro);
  }

  return (
    <React.Fragment>
      <div
        tabIndex="0"
        className="filter-input-search-item"
        key={id}
        id={`filtro-seleccionado-${id}`}
        onKeyDown={(evento) => onKeyDown(evento, id)}
        onClick={onClick}
        ref={refDivFiltro}
      >
        <div>
          <span className="titulo">{etiqueta}</span>
          <span className="titulo">: </span>
          <span className="valor">{valor}</span>
          <span
            className="icono-cerrar"
            onClick={(e) => handleOnClickCerrarFiltro(id, e)}
          >
            <CloseCircleOutlined />
          </span>
        </div>
      </div>
    </React.Fragment>
  );
}
