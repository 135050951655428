import { BaseService } from "./BaseService";
import qs from "qs";
import { seccionAtom } from "pages/seccion/editar/EditarSeccionStore";
const DetalleEnvioService = {
  async listarPorEmpresaIdAndEnvioId(empresaId, envioId) {
    const respuesta = await BaseService.get(`/detalleEnvio/${empresaId}/${envioId}`);
    return respuesta.data;
  },

  async listarDetalleSeguimiento(empresaId, envioId, pagina, cantidad, login) {
    const respuesta = await BaseService.get(`/detalleEnvio/seguimiento/${empresaId}/${envioId}`, {
      params: { pagina: pagina, cantidad: cantidad, login: login },
    });
    return respuesta.data;
  },
  async listarConsultaDocumento(
    empresaId,
    login,
    colaborador,
    categoria,
    documentoIdentidad,
    tipoDocumento,
    cargo,
    periodoDocumento,
    carpeta,
    estadoDocumento,
    sede,
    fechaEnvioInicio,
    fechaEnvioFin,
    pagina,
    cantidad,
    subSede,
    perfil,
    seccion,
    tipoPlanilla,
    codigoColaborador,
    fechaIngresoInicio,
    fechaIngresoFin,
    fechaFirmaInicio,
    fechaFirmaFin) {
    const respuesta = await BaseService.get("/detalleEnvio/consulta-documento", {
      params: {
        empresaId: empresaId,
        login: login,
        colaborador: colaborador,
        categoria: categoria,
        documentoIdentidad: documentoIdentidad,
        tipoDocumento: tipoDocumento,
        cargo: cargo,
        periodoDocumento: periodoDocumento,
        carpeta: carpeta,
        estadoDocumento: estadoDocumento,
        sede: sede,
        fechaEnvioInicio: fechaEnvioInicio,
        fechaEnvioFin: fechaEnvioFin,
        pagina: pagina,
        cantidad: cantidad,
        subSede: subSede,
        perfil: perfil,
        seccion: seccion,
        tipoPlanilla: tipoPlanilla,
        codigoColaborador: codigoColaborador,
        fechaIngresoInicio: fechaIngresoInicio,
        fechaIngresoFin: fechaIngresoFin,
        fechaFirmaInicio: fechaFirmaInicio,
        fechaFirmaFin: fechaFirmaFin
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    return respuesta.data;
  },

  async listar(envioId, estado) {
    const respuesta = await BaseService.get("/detalleEnvio", {
      params: { envioId: envioId, estado: estado },
    });
    return respuesta.data;
  },

  async verPDF(fd) {
    const respuesta = await BaseService.post("/detalleEnvio/verPDF", fd, {
      responseType: "blob",
    });
    return respuesta.data;
  },
  async descargarExcel(fd) {
    const respuesta = await BaseService.post(
      "/detalleEnvio/descargarExcel",
      fd,
      {
        responseType: "blob",
      }
    );
    return respuesta;
  },

  async descargarPDF(fd) {
    const respuesta = await BaseService.post("/detalleEnvio/descargarPDF", fd, {
      responseType: "blob",
    });
    return respuesta;
  },
  async descargarPDFConsultaDocumento(parametros) {
    //const respuesta = await BaseService.post("/detalleEnvio/descargarPDFConsultaDocumentoCola", parametros, {
    const respuesta = await BaseService.post("/detalleEnvio/descargarPDFConsultaDocumento", parametros, {
      responseType: "blob",
    });
    return respuesta;
  },

  async descargarExcelConsultaDocumento(parametros) {
    //const respuesta = await BaseService.post("/detalleEnvio/descargarExcelConsultaDocumentoCola", parametros, {
    const respuesta = await BaseService.post("/detalleEnvio/descargarExcelConsultaDocumento", parametros, {
      responseType: "blob",
    });
    return respuesta;
  },

  async descargarPDFEvidenciaConsultaDocumento(parametros) {
    const respuesta = await BaseService.post("/detalleEnvio/descargarPDFEvidenciaConsultaDocumento", parametros, {
      responseType: "blob",
    });
    return respuesta;
  },

  async actualizarCorreoE(detalleEnvio) {
    const respuesta = await BaseService.put(
      "/detalleEnvio/actualizarCorreo",
      detalleEnvio
    );
    return respuesta.data;
  },
  async buscarPorId(id) {
    const respuesta = await BaseService.get(`/detalleEnvio/${id}`);
    return respuesta.data;
  },
  async inhabilitar(id, motivoAnulacion) {
    const respuesta = await BaseService.delete(`/detalleEnvio/${id}`,
      {
        params: { motivoAnulacion: motivoAnulacion },
      }
    );
    return respuesta.data;
  },
  async reenviarNotificacionPorId(id, organizacionId, empresaId) {
    const respuesta = await BaseService.get(
      "/detalleEnvio/reenviarNotificacionPorId",
      {
        params: {
          id: id,
          organizacionId: organizacionId,
          empresaId: empresaId,
        },
      }
    );
    return respuesta.data;
  },
  async habilitar(id) {
    const respuesta = await BaseService.post(`/detalleEnvio/${id}`);
    return respuesta.data;
  },

  async reenviarNotificacionMasivamente(
    organizacionId,
    empresaId,
    envioId,
    listaDetalleEnvioId
  ) {
    const respuesta = await BaseService.post(
      "/detalleEnvio/reenviarNotificacionMasivamente",
      {
        organizacionId: organizacionId,
        empresaId: empresaId,
        envioId: envioId,
        listaDetalleEnvioId: listaDetalleEnvioId,
      }
    );
    return respuesta.data;
  },
  // async aprobarEnvio(envio){
  //   const respuesta = await BaseService.post("/detalleEnvio/AprobarNotificacion", envio);
  //   return respuesta.data;
  // }
  async listarVisualizados(envioId) {
    const respuesta = await BaseService.get("/detalleEnvio/visualizados", {
      params: { envioId: envioId },
    });
    return respuesta.data;
  },

  async listarNoVisualizados(envioId) {
    const respuesta = await BaseService.get("/detalleEnvio/noVisualizados", {
      params: { envioId: envioId },
    });
    return respuesta.data;
  },
  async listarErroneos(envioId) {
    const respuesta = await BaseService.get("/detalleEnvio/erroneos", {
      params: { envioId: envioId },
    });
    return respuesta.data;
  },
  async obtenerUrlDocumento(empresaId, detalleEnvioId) {
    const respuesta = await BaseService.get(`/detalleEnvio/url/${empresaId}/${detalleEnvioId}`);
    return respuesta.data;
  },
  async obtenerUrlDocumentoWord(detalleEnvioId) {
    const respuesta = await BaseService.get(`/detalleEnvio/${detalleEnvioId}/url-word`);
    return respuesta.data;
  },
  async asignarCarpeta(detalleEnvio) {
    const respuesta = await BaseService.post("/detalleEnvio/asignarCarpeta", detalleEnvio);
    return respuesta.data;
  },

  async listarDetalleNotificacionPorCompletar(empresaId, envioId) {
    const respuesta = await BaseService.get(`/detalleEnvio/notificacion-por-completar/${envioId}`, {
      params: { empresaId: empresaId },
    });
    return respuesta.data;
  },
  async cargarDocumento(formData) {
    const respuesta = await BaseService.post("/detalleEnvio/cargar-documento", formData);
    return respuesta.data;
  }

};




export default DetalleEnvioService;
