import { BaseService } from "./BaseService";
import { HttpService } from "./HttpService";

const SubsedeService = {
  async listar(empresaId) {
    const respuesta = await BaseService.get("/subsede", { params: { empresaId: empresaId } });
    return respuesta.data;
  },
  async listarPromise(empresaId) {
    return HttpService(empresaId).get("/subsede");
  },
  async insertar(subsede) {
    const respuesta = await BaseService.post("/subsede", subsede);
    return respuesta.data;
  },
  async actualizar(subsede) {
    const respuesta = await BaseService.put("/subsede", subsede);
    return respuesta.data;
  },
  async buscarPorId(id) {
    const respuesta = await BaseService.get(`/subsede/${id}`);
    return respuesta.data;
  },
  async eliminar(id) {
    const respuesta = await BaseService.delete(`/subsede/${id}`);
    return respuesta.data;
  },
  async listarPorUsuario(login) {
    const respuesta = await BaseService.get("/subsede/usuario", {
      params: {
        login: login,
      },
    });
    return respuesta.data;
  },
  async listarPorNombreSede(empresaId, nombreSede) {
    const respuesta = await BaseService.get("/subsede/listarPorNombreSede", {
      params: {
        empresaId:empresaId,
        nombreSede: nombreSede
      },
    });
    return respuesta.data;
  },
};
export default SubsedeService;
