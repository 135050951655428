import React from "react";
import { Button, Space, Upload } from "antd";
import { PlusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { ApiUrl } from "services";
import { LoginService } from "services/LoginService";
import { Headers } from "enums";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAtom } from "jotai";
import { abrirModalCarga } from "../../PostulanteListarStore";

const BotonesExtra = () => {
  return (
    <Space>
      <ImportarPostulantesBoton />
      <AgregarPostulanteBoton />
    </Space>
  );
};

const AgregarPostulanteBoton = () => {
  let history = useHistory();
  return (
    <Button type="primary" onClick={()=>history.push("/personal/postulante/crear")}>
      <PlusCircleOutlined /> Agregar Postulante
    </Button>
  );
};

const ImportarPostulantesBoton = () => {
  const [, setAbrirModal] = useAtom(abrirModalCarga);
  const [loading, setLoading] = useState(false);

  const props = {
    action: ApiUrl.RECLUTAMIENTO_Y_SELECCION + "/postulantes/importar",
    showUploadList: false,
    headers: {
      authorization: "Bearer " + LoginService.getJwtToken(),
      [Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
      [Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
    },
    accept: ".xlsx",
    onChange(info) {
      const { status } = info.file;
      console.log("status: ", status);
      if (status === "uploading") {
        setLoading(true);
      }
      if (status === "done") {
        console.log("el archivo se ha procesado correctamente...");
        setLoading(false);
        //message.success(`${info.file.name} Archivo subido correctamente`);
      } else if (status === "error") {
        console.log("ocurrió un error al procesar el archivo");
        setLoading(false);
        // message.error(`${info.file.name} Carga del archivo ha fallado.`);
      }
    },
  };

  return (
    // <Upload {...props}>
      <Button loading={loading} icon={<UploadOutlined />} onClick={()=>setAbrirModal(true)}>
        Importar
      </Button>
    // </Upload>
  );
};

export default BotonesExtra;
