import React from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { DescargaMasivaService } from "services";

const MenuAcciones = ({ descargaMasiva }) => {
  return <BotonDescargar descargaMasiva={descargaMasiva} />;
};

const BotonDescargar = ({ descargaMasiva }) => {
  const onClickLinkDescargar = async () => {
    const response = await DescargaMasivaService.getUrlDescarga(descargaMasiva.id);
    window.location.href = response.url;
  };

  if (descargaMasiva.estado === "FINALIZADO") {
    return (
      <Button type="link" onClick={onClickLinkDescargar}>
        <DownloadOutlined style={{ fontSize: "18px", fontWeight: "bold" }} />
      </Button>
    );
  }
  return "";
};

export default MenuAcciones;
