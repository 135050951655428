import React from "react";
import { ContainerCrear } from "components/ContainerCrear";
import { TipoAbsentismoRegistro } from "./tipoAbsentismo-Registro";
import DrawerCamposPersonalizadosRegistro from "./tipoAbsentismo-Registro/DrawerCamposPersonalizadosRegistro";
import DrawerArchivoAdjuntoRegistro from "./tipoAbsentismo-Registro/DrawerArchivoAdjuntoRegistro";

const CrearTipoAbsentismoPage=()=>{
    return(
        <ContainerCrear
          titulo="Crear Tipo Absentismo"
          descripcion="Formulario para crear los tipos de absentismos para tu organización"
          colSpan={12}
         >
           <TipoAbsentismoRegistro/>
           <DrawerCamposPersonalizadosRegistro/>
           <DrawerArchivoAdjuntoRegistro/>
        </ContainerCrear>
    )

}
export default CrearTipoAbsentismoPage;