import React from "react";
import { Form,Select } from "antd";
import { EstadoPlanAccionEvaluacion } from "enums/EstadoPlanAccionEvaluacion";
import {filtroEstadoPlanAccionAtom} from "../../SeguimientevaluacionStore";
import { useAtom } from "jotai";

const FiltroPlanAccion=()=>{
    const [,setFiltroEstadoPlanAccion]=useAtom(filtroEstadoPlanAccionAtom);

    const onChangeFiltroEstado=(value)=>{
       setFiltroEstadoPlanAccion(value);
    }
 return(
     <Form
         layout="horizontal"
     >
        <Form.Item label="Estado Plan Acción" name="estado">
          <Select style={{width: "250px"}} placeholder="Seleccione el estado" onChange={onChangeFiltroEstado}>
              <Select.Option value="">TODOS</Select.Option>
              <Select.Option value={EstadoPlanAccionEvaluacion.PENDIENTE.toString()}>{EstadoPlanAccionEvaluacion.PENDIENTE}</Select.Option>
              <Select.Option value={EstadoPlanAccionEvaluacion.CERRADO.toString()}>{EstadoPlanAccionEvaluacion.CERRADO}</Select.Option>
              <Select.Option value={EstadoPlanAccionEvaluacion.CERRADO_FUERA_PLAZO.toString()}>CERRADO FUERA PLAZO</Select.Option>
          </Select>
        </Form.Item>
     </Form>
 )
}
export default FiltroPlanAccion;