import React from "react";
import { Container } from "components/Container";
import { Provider as JotaiProvider, useAtom } from "jotai";
import { TablaDocumentos } from "./componentes";
import { useHistory, useParams } from "react-router-dom";
import { ModalVisorPdf } from "components/Modal/ModalVisorPdf";
import { ModalRechazarOnboarding } from "components/Modal/ModalRechazarOnboarding";
import { PlusCircleOutlined, HomeOutlined, ReloadOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Space } from "antd";
import {
  dataColaboradorAtom,
  procesoIdSeleccionadoAtom,
  filtrosAtom,
  resumenAtom,
  resetAtom,
  documentosProcesoAtom,
  loadingAtom,
} from "./DocumentosProcesoListarStore";
import { ModalCargarDocumento } from "./componentes/ModalCargarDocumento";
import { ProcesoService } from "services/reclutamiento-y-seleccion/ProcesoService";
const DocumentosProcesoListarPage = () => {
  let history = useHistory();

  const onClickRetornar = () => {
    history.push("/reclutamiento-y-seleccion/procesos");
  };
  return (
    <JotaiProvider>
      <Container icono={<IconoRuta />} onClickRetornar={onClickRetornar} botonExtra={<BotonExtra />}>
        <TablaDocumentos />
        <ModalVisorPdf />
        <ModalRechazarOnboarding />
        <ModalCargarDocumento />
      </Container>
    </JotaiProvider>
  );
};
export default DocumentosProcesoListarPage;

const BotonExtra = () => {
  const [documentosListado, setDocumentosListados] = useAtom(documentosProcesoAtom);
  const [procesoSeleccionado, setProcesoSeleccionado] = useAtom(procesoIdSeleccionadoAtom);
  const [filtros, setFiltros] = useAtom(filtrosAtom);
  const [resumen, setResumen] = useAtom(resumenAtom);
  const [reset, setReset] = useAtom(resetAtom);
  const [loading, setLoading] = useAtom(loadingAtom);
  let history = useHistory();
  const { procesoId } = useParams();

  const actualizarDatos = async () => {
    setLoading(true);
    const respuesta = await ProcesoService.obtenerDocumentosPorProcesoYFiltro(
      procesoId,
      filtros["documento"],
      filtros["responsable"],
      filtros["etapa"],
      filtros["estadoDocumento"],
      filtros["estadoAprobacion"]
    );
    setDocumentosListados(respuesta.documentos);
    setResumen(respuesta.resumen);
    setReset(false);
    setLoading(false);
  };

  return (
    <Space>
      <Button icon={<ReloadOutlined />} onClick={actualizarDatos}>
        Refrescar
      </Button>
      <Button type="primary" onClick={() => history.push(`/reclutamiento-y-seleccion/procesos/agregar/${procesoId}`)}>
        <PlusCircleOutlined /> Agregar Documentos
      </Button>
    </Space>
  );
};

const IconoRuta = () => {
  const [dashboard] = useAtom(dataColaboradorAtom);
  return (
    <Breadcrumb itemRender>
      <Breadcrumb.Item href="/dashboard">
        <HomeOutlined />
      </Breadcrumb.Item>
      <Breadcrumb.Item href="/reclutamiento-y-seleccion/procesos">Procesos de Onboarding</Breadcrumb.Item>
      <Breadcrumb.Item>{dashboard?.combocatoriaNombre}</Breadcrumb.Item>
    </Breadcrumb>
  );
};
