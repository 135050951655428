import React from "react";
import { Button,Popconfirm } from "antd";
import { EditOutlined, LayoutOutlined,DeleteOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import SeccionService from "../../../../services/SeccionService";

export const MenuAcciones=({seccion,secciones,setSecciones})=>{
    let history = useHistory();
    const BotonEditar = () => {
        const onClickBtnEditar=()=>{
                                   
             const rutaEditarSeccionPage=`/seccion/editar/${seccion.id}`;                                              
             history.push(rutaEditarSeccionPage);
        
        }

        return (
            <Button type="link" icon={<EditOutlined />} onClick={onClickBtnEditar}>
                Editar
            </Button >
        )
    }
    const BotonEliminar = ()=>{
        const onclickBtnEliminar = async() =>{               
               await SeccionService.eliminar(seccion.id);
               setSecciones(secciones.filter((_seccion)=>_seccion.id!==seccion.id));              
            
        }

        return(            
            <Popconfirm
            title="Estás seguro de eliminar la sección"
            onConfirm={onclickBtnEliminar}           
            okText="Si"
            cancelText="No"
           >
            <Button type="link" icon={<DeleteOutlined />} >
                Eliminar
            </Button>
          </Popconfirm>

        )
    }
    return (
        <React.Fragment>
            <BotonEditar />
            <BotonEliminar />
        </React.Fragment>
    );

}
