import React from "react";
import { Button, Dropdown, Menu } from "antd";
import { EstadoVistaColaborador} from "enums/reclutamiento-y-seleccion/EstadoVistaColaborador";
import {EstadoAprobacion } from "enums/reclutamiento-y-seleccion/EstadoAprobacion";
import { useModalVisorPdf } from "store/global";
import {detalleIdAtom, documentoSeleccionadoAtom, modalCargaAtom, visibleModalEditarAtom} from "../../DocumentosProcesoListarStore";
import { useAtom } from "jotai";
import {ContratoService} from "services/ContratoService";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import {
  CaretDownOutlined,UnorderedListOutlined,SelectOutlined, EditOutlined , UploadOutlined , CloseOutlined, FormOutlined 
} from "@ant-design/icons";

const MenuAcciones=({documento})=>{
  
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const mostrarModalVisorpdf = useModalVisorPdf((state)=>state.mostrarModal);  
  const [,setDetalleId] =useAtom(detalleIdAtom);    
  const [,setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
  const [, setVisibleModalActualizarDocumento] = useAtom(visibleModalEditarAtom);
  const [, setVisibleModalCarga] = useAtom(modalCargaAtom)

  const onClickMenu = (opcion) => {
    switch (opcion.key) {
      case "ver-detalle":
        onclickVerDocumento();
        break;

      case "aprobar-rechazar":
        onClickAprobarRechazar();
        break;

      case "editar":
        setDocumentoSeleccionado(documento)
        setVisibleModalActualizarDocumento(true)
        break;

      // case "eliminar":
      //   console.log(opcion.key)
      //    onClickMenuEliminar();
      //   break;

      case "subir-documento":
        setDocumentoSeleccionado(documento)
        setVisibleModalCarga(true)
        break;
      default:
        console.error("No se encuentra esa opción");
    }
  };

  const onClickAprobarRechazar = async()=>{   
    setDetalleId(documento.id);
    const respuesta = await ContratoService.getUrlDocumento(getUsuarioAutenticado().empresaSeleccionada.id,documento.id);    
   // console.log("respuesta: ",respuesta);
    mostrarModalVisorpdf(respuesta.urlDocumento,documento.tipoDocumento.nombre,false,false,"ONBOARDING RECLUTAMIENTO Y SELECCION",documento.id);
  }
  const onclickVerDocumento = async()=>{  
    const respuesta = await ContratoService.getUrlDocumento(getUsuarioAutenticado().empresaSeleccionada.id, documento.id);
    mostrarModalVisorpdf(respuesta.urlDocumento,documento.tipoDocumento.nombre,false,false);
  }


  const menu = () => {
    return (
      <Menu onClick={onClickMenu}>
        {(documento.estadoVistaColaborador!==EstadoVistaColaborador.PENDIENTE &&        
         documento.estadoAprobacion!==EstadoAprobacion.PENDIENTE &&
          <Menu.Item key="ver-detalle">
            <SelectOutlined /> Ver Documento
          </Menu.Item>
        )}
        { (documento.estadoVistaColaborador!==EstadoVistaColaborador.PENDIENTE &&
         documento.estadoAprobacion===EstadoAprobacion.PENDIENTE &&
            <Menu.Item key="aprobar-rechazar">
              <FormOutlined   /> Aprobar/Rechazar
            </Menu.Item>
          )}
        { (
            <Menu.Item key="editar">
              <EditOutlined  /> Editar
            </Menu.Item>
          )}
        { (documento.estadoAprobacion!==EstadoAprobacion.APROBADO &&
            <Menu.Item key="subir-documento">
              <UploadOutlined  />
              Subir Documento
            </Menu.Item>
          )}
        {/* {(
          <Menu.Item key="eliminar">
            <CloseOutlined /> Eliminar
          </Menu.Item>
        )} */}
      </Menu>
    );
  };


   return(

    <React.Fragment>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomCenter">
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
          <CaretDownOutlined style={{ paddingLeft: "2px", fontSize: "12px", fontWeight: "bold", color: "#1678c1" }} />
        </a>
      </Dropdown>
    </React.Fragment>
    
   )
}
export default MenuAcciones;