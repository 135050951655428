import { useEffect,useContext } from "react";
import { useAtom } from "jotai";
import {postulantesAtom,filtroPostulanteAtom} from "../AgregarPostulanteStore";
import { PostulantesService } from "services/reclutamiento-y-seleccion";
import { SecurityContext } from "context/SecurityContextProvider";

const useCargarPostulantes=()=>{

  const [postulantes,setPostulantes]= useAtom(postulantesAtom);
  const {getUsuarioAutenticado}=useContext(SecurityContext);
   
  const cargarPostulantes = async(data)=>{     
       
        if(data){
          //const respuesta= await PostulantesService.listar(filtroPostulante,getUsuarioAutenticado().login);
          const respuesta= await PostulantesService.listarFiltrado({filtro: data});
          //console.log(respuesta)
          setPostulantes(respuesta.postulantes);
        }
          
    }
  
  return{postulantes,setPostulantes,cargarPostulantes};
}
export default useCargarPostulantes;