import React, { useContext } from "react";
import { Modal } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons"
import { ContratoCrearContext } from "context/contrato/crear/ContratoCrearContext";

export function ModalConfirmacion() {

    const { loading, visibleModalConfirmacion, guardarContrato, cerrarModalConfirmacion } = useContext(ContratoCrearContext);

    return (
        <Modal
            title="Mi Legajo Digital"
            visible={visibleModalConfirmacion}
            onOk={guardarContrato}
            okText="Guardar"
            onCancel={cerrarModalConfirmacion}
            cancelText="Cancelar"
            confirmLoading={loading}
        >
            <QuestionCircleOutlined className="milegajo-icono-pregunta" />
            <span className="milegajo-texto-pregunta">¿Está seguro de guardar el contrato?</span>
        </Modal>
    );
}