import React from "react";
import { Avatar, Descriptions, Typography, Skeleton, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import "./Resumen.css";
import { resumenColaboradorAtom } from "../../../DetalleSeguimientoOnboardingStorage";

const { Text } = Typography;

const Resumen = ({ loading }) => {

    const [resumenColaborador] = useAtom(resumenColaboradorAtom);




    return (
        <div className="resumen">

    <Skeleton loading={loading} active paragraph={{ rows: 4, width: '100%' }} title={false} className="resumen-skeleton" >
            <Descriptions size="small" column={1} colon={false}>
            <Descriptions.Item label={ <Avatar size={64} icon={<UserOutlined />} />}><Space style={{paddingLeft:"20px"}} direction="vertical" size={2}><Text strong>{resumenColaborador?.nombreCompleto}</Text><Text>{"DNI: "+resumenColaborador?.numeroDocumentoIdentidad}</Text><Text>{"EMAIL: "+resumenColaborador?.correoElectronico}</Text></Space></Descriptions.Item>
          </Descriptions>
        </Skeleton>
        </div>

    )
}

export default Resumen;