import React,{useContext} from "react";
import {Modal,Form,DatePicker, notification} from "antd";
import { ConvocatoriaService } from "services/reclutamiento-y-seleccion/ConvocatoriaService";
import { SecurityContext } from "context/SecurityContextProvider";
import { useHistory } from "react-router-dom";
import { WarningOutlined  } from "@ant-design/icons";

const ModalCerrarProceso=({modalCerrarProceso,setModalCerrarProceso,convocatoriaId,setIsConvocatoria, closable})=>{
  const [formCierreProceso]=Form.useForm();
  const {getUsuarioAutenticado} = useContext(SecurityContext);
  let history = useHistory();

  const limpiarPanel = () => {
    formCierreProceso.resetFields();    
    setModalCerrarProceso(false);
  };

  const cerrarProceso = async(formulario)=>{
    const respuesta = await ConvocatoriaService.validarCierreProceso({convocatoriaId:convocatoriaId});
			if (respuesta=="OK") {
        await ConvocatoriaService.cerrarProceso({convocatoriaId:convocatoriaId,
          login:getUsuarioAutenticado().login,
          fechaCierre:formulario.fechaCierre});   
          setIsConvocatoria(false);
          history.push("/reclutamiento-y-seleccion/solicitud-personal/solicitudes");       
          limpiarPanel(); 
      }else{
        notification.warning({
					message: "Notificación",
					description: respuesta,
				});
      }
  }
    return(
      <Modal
      title="Cerrar Proceso"
      width={"25%"}
      okButtonProps={{
        disabled: !closable,
      }}
      open={modalCerrarProceso}
      onOk={()=>formCierreProceso.submit()}
      onCancel={() => limpiarPanel()}
      destroyOnClose
      >
        {closable? <Form form={formCierreProceso}  onFinish={cerrarProceso}>
            <Form.Item  
            label="Fecha Cierre" 
            name="fechaCierre" 
            rules={[{ required: true, message:"Seleccione la fecha"}]}
            >
                <DatePicker format={"DD/MM/YYYY"}/>
            </Form.Item>
        </Form>:<span> <WarningOutlined  style={{fontSize:"18px", color:"#ffc107"}}/> {" Se debe registrar al menos 1 personal contratado."} </span>}
      </Modal>
    )
}
export default ModalCerrarProceso;