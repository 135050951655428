import { Table, Button } from "antd";
import { useAtom } from "jotai";
import React from "react";
import useCargarDocumentos from "../../hooks/useCargarDocumentos";
import { documentoSeleccionadoAtom, visibleModalDocumentoAtom } from "../../ProcesosCrearStore";

const TablaDocumentos = () => {
  const { documentos } = useCargarDocumentos();
  const [, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
  const [, setVisibleModalDocumento] = useAtom(visibleModalDocumentoAtom);

  const onClickEditar = (documento) => {
    setDocumentoSeleccionado(documento);
    setVisibleModalDocumento(true);
  };

  const columnas = [
    {
      title: "Documento",
      dataIndex: "nombre",
    },
    {
      title: "Fecha Límite",
      dataIndex: "fechaLimite",
    },
    {
      title: "Caducidad",
      align: "center",
      render: (fila) => (fila.caducidad ? "SI" : "NO"),
    },
    {
      title: "Obligatorio",
      align: "center",
      render: (fila) => (fila.obligatorio ? "SI" : "NO"),
    },
    {
      title: "Etapa",
      dataIndex: ["etapa", "nombre"],
    },
    {
      title: "Acciones",
      key: "acciones",
      render: (fila) => (
        <Button type="link" onClick={() => onClickEditar(fila)}>
          Editar
        </Button>
      ),
    },
  ];

  return <Table rowKey={(fila) => fila.id} dataSource={documentos} columns={columnas} pagination={false} />;
};

export default TablaDocumentos;
