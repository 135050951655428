import React, { useState, useEffect, useContext, useRef } from "react";
import { Container } from "../../../components/Container";
import {
  UnorderedListOutlined,
  SearchOutlined,
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
  FolderOutlined,
  ContainerOutlined,
} from "@ant-design/icons";
import {
  Table,
  Space,
  Typography,
  Select,
  Button,
  Input,
  Popconfirm,
  notification,
  Modal,
  Tag,
  Row,
  Col,
} from "antd";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { EnvioService } from "services/EnvioService";
import DetalleEnvioService from "../../../services/DetalleEnvioService";
import { useHistory } from "react-router-dom";
import Highlighter from "react-highlight-words";

const { Option } = Select;
const { confirm } = Modal;

export function DocumentoLaboralPorAprobarListarPage() {
  let history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const { setDatosEnvio } = useContext(SecurityContext);
  const [envios, setEnvios] = useState([]);
  const { Text } = Typography;
  const [loading, setLoading] = useState(false);
  const [defaultValue, setDefaultValue] = useState("default");
  //const [filaSeleccionada, setFilaSeleccionada] = useState("");

  useEffect(() => {
    async function cargarDatos() {
      const envios = await EnvioService.listarPorAprobar(
        getUsuarioAutenticado().empresaSeleccionada.id,
        getUsuarioAutenticado().login
      );
      setEnvios(envios);
    }

    cargarDatos();
  }, []);

  var adicionarColumnasFiltro = function getColumns(
    dataIndex,
    placeholder,
    propiedad
  ) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={
              placeholder !== undefined
                ? placeholder
                : `Buscar por ${dataIndex}`
            }
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        propiedad === undefined
          ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
          : record[dataIndex][propiedad]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={
              propiedad === undefined
                ? text?.toString()
                : text[propiedad]?.toString()
            }
          />
        ) : propiedad === undefined ? (
          text
        ) : (
              text[propiedad]
            ),
    };
  };

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText("");
  }
  const columnas = [
    {
      title: "Documento",
      key: "documento",
      ...adicionarColumnasFiltro(
        "tipoDocumentoNombre",
        "Buscar por Tipo Documento"
      ),
      render: (text, fila) => (
        <Space>
          <a onClick={() => handleonClickVerDetalle(fila.id, fila.tipoDocumentoNombre, fila.descripcion)}>
            {/* <FolderOutlined style={{ fontSize: "50px", color: "#1678c1" }} /> */}
            <img src={require('../../../assets/img/filePorAprobar.png')} />
          </a>
          <span className="mock-block">
            <Text strong>{fila.tipoDocumentoNombre}</Text>
            <p>{fila.descripcion!=null?fila.descripcion:fila.periodo}</p>
          </span>
        </Space>
      ),
    },
    // {
    //   title: "Responsable envío",
    //   dataIndex: "usuarioEnvio",
    //   align: "center",
    //   ...adicionarColumnasFiltro(
    //     "usuarioEnvio",
    //     "Buscar por responsable",
    //     "nombreCompleto"
    //   ),
    // },
    // {
    //   title: "Fecha envío",
    //   dataIndex: "fechaEnvio",
    //   align: "center",
    //   ...adicionarColumnasFiltro("fechaEnvio", "Buscar por fecha de envío"),
    // },
    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      render: (text, fila) => (
        <React.Fragment>
          <Select
            placeholder="Seleccione la Acción"
            value={defaultValue}
            style={{ width: 200 }}
            onSelect={(value) =>
              handleAccionesOnClick(
                value,
                fila.id,
                fila.tipoDocumentoNombre,
                fila.descripcion,
                fila.responsableCargaDocumento
              )
            }
            // loading={filaSeleccionada === fila.id ? loading : false}
            allowClear
          >
            <Option value="default">Seleccione la Acción </Option>
            <Option value="verDetalle">Ver detalle </Option>
          </Select>
        </React.Fragment>
      ),
    },
  ];
  function handleonClickVerDetalle(envioId,
    envioDocumentoNombre,
    envioDocumentoDescripcion,) {
    setDatosEnvio(envioId, envioDocumentoNombre, envioDocumentoDescripcion);
    history.push("/documento-laboral-porAprobar/listar-detalle");
  }
  function handleAccionesOnClick(value, envioId, envioDocumentoNombre, envioDocumentoDescripcion) {
    if (value === "verDetalle") {
      setDatosEnvio(envioId, envioDocumentoNombre, envioDocumentoDescripcion);
      history.push("/documento-laboral-porAprobar/listar-detalle");
    }
  }
  return (
    <Container
      icono={<UnorderedListOutlined />}
      titulo="Documentos Laborales Por aprobar"
    >
      <Table
        rowKey={(envios) => envios.id}
        columns={columnas}
        dataSource={envios}
        pagination={{
          pageSize: [10],
        }}
      /*rowClassName={(envios) =>
      envios.eliminado ? "detalle-envio_registro-eliminado" : ""
    }*/
      />
    </Container>
  );
}
