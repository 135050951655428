import { ApiUrl } from "./ApiUrl";
import { BaseService } from "./BaseService";

const URL_API = ApiUrl.BASE;

const UbigeoService = {
    async listarDepartamentos() {
        const respuesta = await BaseService.get("/portal-colaborador/listarDepartamentos");
        return respuesta.data;
    },

    async listarProvincias(data) {
        const respuesta = await BaseService.post("/portal-colaborador/listarProvincias", data);
        return respuesta.data;
    },

    async listarDistritos(data) {
        const respuesta = await BaseService.post("/portal-colaborador/listarDistritos", data);
        return respuesta.data;
    },
};
export default UbigeoService;
