import React, { useState, useEffect } from "react";
import {
  Modal,
  Upload,
  message,
  Button,
  Descriptions,
  Space,
  Typography,
  Input,
  Divider,
  Row,
  Col,
  Form,
  Select,
} from "antd";
import {
  LockOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { LoginService } from "services/LoginService";
import UsuarioService from "../../../services/UsuarioService";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";

const { Option } = Select;

export default function ModalCrearCertificadoDigital({
  loading,
  visibleModal,
  onCerrarModal,
  listaDocumentos,
  setListaDocumentos,
  handleOnFinish,
}) {
  const { Text, Paragraph } = Typography;
  const [usuarios, setUsuarios] = useState([]);
  const { getUsuarioAutenticado } = useContext(SecurityContext);

  useEffect(() => {
    async function cargarDatos() {
      const usuariosListados = await UsuarioService.listar(
        getUsuarioAutenticado().empresaSeleccionada.id
      );
      setUsuarios(usuariosListados);
    }
    cargarDatos();
  }, []);

  function borrarDatos() {
    //setListaDocumentos([]);
  }

  const props = {
    onRemove: (file) => {
      console.log("onRemove");
      const index = listaDocumentos.indexOf(file);
      const newFileList = listaDocumentos.slice();
      newFileList.splice(index, 1);
      return {
        listaDocumentos: newFileList,
      };
    },
    beforeUpload: (file) => {
      // this.setState(state => ({
      //     fileList: [...state.fileList, file],
      // }));
      //listaDocumentos = file;
      console.log("beforeUpload");
      setListaDocumentos(file);
      return false;
    },
    listaDocumentos,
  };

  return (
    <Modal
      title="Subir Certificado Digital"
      visible={visibleModal}
      destroyOnClose={true}
      width={750}
      onCancel={onCerrarModal}
      afterClose={borrarDatos}
      footer={null}
    >
      <div>
        <Form layout="vertical" size="large" onFinish={handleOnFinish}>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Certificado Digital"
                name="archivo"
                rules={[
                  {
                    required: true,
                    message: "Por favor, cargue su archivo.",
                  },
                ]}
              >
                <Upload {...props}>
                  <Button>
                    {/* <FileExcelOutlined
                      style={{ fontSize: 20, color: "green" }}
                    />{" "} */}
                    Seleccionar Certificado Digital
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="Ingresa la clave"
                name="clave"
                rules={[
                  {
                    required: true,
                    message: "Por favor, ingresa tu clave.",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Ingresa tu clave"
                  maxLength="50"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                label="usuario"
                name="usuarioId"
                rules={[
                  {
                    required: true,
                    message: "El campo usuario es obligatorio.",
                  },
                ]}
              >
                <Select
                  placeholder="Seleccione un usuario"
                >
                  {usuarios.length > 0 &&
                    usuarios.map((usuario) => {
                      return (
                        <Option key={usuario.id} value={usuario.id}>
                          {usuario.nombreCompleto}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center">
            <Space>
              <Col>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button type="primary" loading={loading} htmlType="submit">
                    Guardar Certificado Digital
                  </Button>
                </Form.Item>
              </Col>
            </Space>
          </Row>
        </Form>
      </div>
    </Modal>
  );
}
