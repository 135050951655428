import React from "react";
import { FirmaDocumentoGenericoCrearContextProvider } from "context/firma-documento-generico/crear"
import { FormularioCrearDocumentoGenerico, ModalConfirmar } from "./componentes"
import { Container } from "components/Container"
import { Button } from "antd"
import { ArrowLeftOutlined } from "@ant-design/icons"
import { useHistory } from "react-router";

const FirmaDocumentoGenericoCrearPage = () => {

    let history = useHistory();

    return (

        <FirmaDocumentoGenericoCrearContextProvider>

            <Container titulo="Cargar Documento" onClickRetornar={() => history.push("/notificacion/firma-documento-generico/listar")}>

                <FormularioCrearDocumentoGenerico />

                <ModalConfirmar />

            </Container >

        </FirmaDocumentoGenericoCrearContextProvider>
    )
}

export default FirmaDocumentoGenericoCrearPage;