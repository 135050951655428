import React from "react";
import { FilterServer } from "components/MultiFiltro";
import { EtapaDocumento } from "enums/EtapaDocumento";
import { EstadoVistaColaborador } from "enums/reclutamiento-y-seleccion/EstadoVistaColaborador";
import { EstadoAprobacion } from "enums/reclutamiento-y-seleccion/EstadoAprobacion";

const FiltroBusqueda = ({setFiltros, reset = false, filtroActual}) => {

  let listaFiltros=[];
  const filtrosBusqueda = {
    titulo: "Filtrar por: ",
    data: [
      { id: "documento", titulo: "Nombre Documento", tipo: "texto" },
      { id: "responsable", titulo: "Responsable", tipo: "texto" },
      { id: "etapa", titulo: "Etapa", tipo: "lista" },
      { id: "estadoDocumento", titulo: "Estado Documento", tipo: "lista" },
      { id: "estadoAprobacion", titulo: "Estado Aprobacion", tipo: "lista" },
    ],
  };
   listaFiltros.push({ etapa: EtapaDocumento.POSTULANTE.nombre });
   listaFiltros.push({ etapa: EtapaDocumento.SELECCIONADO.nombre });
   listaFiltros.push({ etapa: EtapaDocumento.CONTRATADO.nombre });
   listaFiltros.push({ estadoDocumento: EstadoVistaColaborador.FIRMADO })
  //  listaFiltros.push({ estadoDocumento: EstadoVistaColaborador.VISUALIZADO })
  //  listaFiltros.push({ estadoDocumento: EstadoVistaColaborador.PENDIENTE_APROBACION })
   listaFiltros.push({ estadoDocumento: EstadoVistaColaborador.PENDIENTE })
   listaFiltros.push({ estadoAprobacion: EstadoAprobacion.APROBADO })
   listaFiltros.push({ estadoAprobacion: EstadoAprobacion.RECHAZADO })
   listaFiltros.push({ estadoAprobacion: EstadoAprobacion.PENDIENTE })
   
   
   
   
  const onFiltrar =  (filtrosSeleccionados) => {

    try {

      if(filtroActual!==undefined){
        if(!(Object.entries(filtroActual).length===0 && Object.entries(filtrosSeleccionados).length===0)){
          setFiltros(filtrosSeleccionados)
        }
    }else if(filtroActual===undefined){
      setFiltros(filtrosSeleccionados)
    }
        
    } catch (error) {
      console.error(error);
    } finally {

    }
  };

  return (
    <FilterServer
      placeholder="Haz clic aquí para filtrar los documentos"
      filtros={filtrosBusqueda}
      onFiltrarDatos={onFiltrar}
      data={listaFiltros}
      reset={reset}
    />
  );
};

export default FiltroBusqueda;
