import { atom } from "jotai";

export const filtro = atom("");
export const colaboradores = atom([]);
export const documentos = atom([]);
export const colaboradorSeleccionado = atom([]);
export const primerColaborador = atom([]);
export const breadcrumbItems = atom([]);
export const loadingColaboradores = atom(false);
export const loadingDocumentos = atom(false);
export const codigosOpcionesAtom = atom([]);
export const mensajeLegajoAtom = atom(undefined);
