import { Headers } from "enums";
import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";
import { LoginService } from "services/LoginService";

const URL_API = ApiUrl.BASE;

const BandejaAprobacionAdministracionPersonalService = {
    async listarContratosPendientesAprobar() {
        const respuesta = await ApiService(URL_API).get(`/admin/contratos/administracion-personal/pendientes-aprobar`);
        return respuesta.data;
    },

    async aprobarContrato(data) {
        const respuesta = await ApiService(URL_API).post(`/admin/contratos/administracion-personal/aprobar`, data);
        return respuesta.data;
    },

    async rechazarContrato(data) {
        const respuesta = await ApiService(URL_API).post(`/admin/contratos/administracion-personal/rechazar`, data);
        return respuesta.data;
    },

    getUrlVistaPrevia(seguimientoContratoId) {
        const urlPdf =
            process.env.REACT_APP_ENDPOINT + `admin/contratos/administracion-personal/ver-pdf/${seguimientoContratoId}`;
        const httpHeaders = {
            Authorization: "Bearer " + LoginService.getJwtToken(),
            [Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
            [Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
        };

        return {
            url: urlPdf,
            httpHeaders: httpHeaders,
        };
    },
    async listarContratosPendientesAprobarAndFiltro(data) {
        const respuesta = await ApiService(URL_API).post(`admin/contratos/administracion-personal/pendientes-aprobar-filtro`, data);
        return respuesta.data;
    },
    async modificarContrato(data) {
        const respuesta = await ApiService(URL_API).post(`/admin/contratos/administracion-personal/modificarContrato`, data);
        return respuesta.data;
    },
    async actualizarResponsables(data) {
        const respuesta = await ApiService(URL_API).post(`/admin/contratos/administracion-personal/actualizarResponsables`, data);
        return respuesta.data;
    },
    async notificarContratosPorVencerResponsables() {
        const respuesta = await ApiService(URL_API).get(`/admin/contratos/administracion-personal/notificar/contratos-por-vencer`);
        return respuesta.data;
    },
};

export { BandejaAprobacionAdministracionPersonalService };
