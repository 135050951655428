import React, { useEffect } from "react";
import * as atoms from "store/local/contratos/DetalleSeguimientoContratoStore";
import { useAtom } from "jotai";
import { useHistory } from "react-router";

const ListaContratosNotificados = ({ envio }) => {
  //const [envio] = useAtom(atoms.envio);
  let history = useHistory();

  useEffect(() => {
    history.push(`/notificacion/seguimiento/detalle/Contratos/${envio.id}`);
  }, []);

  return null;
};

export default ListaContratosNotificados;
