import { BaseService } from "./BaseService";
import { HttpService } from "./HttpService";

const CargoService = {
    async listar(empresaId) {
        const respuesta = await BaseService.get("/cargo", {
            params: {
                empresaId: empresaId,
            },
        });
        return respuesta.data;
    },
    async listarPromise(empresaId) {
        return HttpService(empresaId).get("/cargo");
    },
    async insertar(cargo) {
        const respuesta = await BaseService.post("/cargo", cargo);
        return respuesta.data;
    },
    async actualizar(cargo) {
        const respuesta = await BaseService.put("/cargo", cargo);
        return respuesta.data;
    },
    async buscarPorId(id) {
        const respuesta = await BaseService.get(`/cargo/${id}`);
        return respuesta.data;
    },
    async eliminar(id, tipoEliminacion) {
        const respuesta = await BaseService.delete(`/cargo/${id}`, {
            params: {
                tipoEliminacion: tipoEliminacion,
            },
        });
        return respuesta.data;
    },
    async descargarCargosExcel(fd) {
        const respuesta = await BaseService.post("/cargo/descargarCargos", fd, {
            responseType: "blob",
        });
        return respuesta;
    },
    async descargarPlantillaExcel(empresaId) {
        const respuesta = await BaseService.get("/cargo/descargarPlantillaExcel", {
            params: {
                empresaId: empresaId,
            },
            responseType: "blob",
        });
        return respuesta;
    },
    async listarPorUsuario(login) {
        const respuesta = await BaseService.get("/cargo/usuario", {
            params: {
                login: login,
            },
        });
        return respuesta.data;
    },
};
export default CargoService;
