import { Button, Col, Drawer, Form, Input, Modal, notification, Row, Select, Space } from "antd";
import { SecurityContext } from "context/SecurityContextProvider";
import { CategoriaDocumento } from "enums/CategoriaDocumento";
import { useAtom } from "jotai";
import React, { Fragment, useContext, useEffect, useState } from "react";
import EvaluacionService from "services/EvaluacionService";
import TipoDocumentoService from "services/TipoDocumentoService";
import { useModalVisorPdf } from "store/global";
import {
  evaluacionesAtom,
  evaluacionSeleccionadaAtom,
  visibleModalAprobarEvaluacionAtom,
} from "../../SeguimientevaluacionStore";

const ModalAprobarEvaluacion = () => {
  const cerrarModalVisorPdf = useModalVisorPdf((state) => state.cerrarModal);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [evaluacionSeleccionada] = useAtom(evaluacionSeleccionadaAtom);
  const [visibleModal, setVisibleModal] = useAtom(visibleModalAprobarEvaluacionAtom);
  const [evaluaciones, setEvaluaciones] = useAtom(evaluacionesAtom);
  const [tiposContrato, setTiposContrato] = useState([]);
  const [ultimoContrato, setUltimoContrato] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [completarContrato, setCompletarContrato] = useState(undefined);
  const [datosDegault, setDatosDefault] = useState(undefined);
  const [contratoSeleccionado, setContratoSeleccionado] = useState([]);
  const [contratoCompletado, setContratoCompletado] = useState(false)
  const [form] = Form.useForm();
  const [formContratoNuevo] = Form.useForm();

  useEffect(async () => {
    if (visibleModal) {
      const tiposContrato = await EvaluacionService.obtenerTipoDocumento();
      setTiposContrato(tiposContrato);
      // const _ultimoContrato = await EvaluacionService.obtenerUltimoContrato(
      //   evaluacionSeleccionada.colaborador.numeroDocumentoIdentidad);
      // setUltimoContrato(_ultimoContrato);
      const datoColaborador = await EvaluacionService.obtenerDatosColaboradorPredefinido(
          evaluacionSeleccionada.colaborador.numeroDocumentoIdentidad);
      setDatosDefault(datoColaborador)
      // if(_ultimoContrato.nombre!==undefined)
      //   setContratoCompletado(true)
    }
  }, [visibleModal]);

  const onClickBtnAprobar = (formulario) => {
    try {
      //console.log(formulario)
      form
        .validateFields()
        .then(async (formulario) => {
          setLoading(true);
          await EvaluacionService.aprobarPorGestionHumana({
            evaluacionId: evaluacionSeleccionada.id,
            login: getUsuarioAutenticado().login,
            ...formulario,
          });
          notification["success"]({
            message: "Aprobación exitosa",
            description: "Se aprobó la evaluación correctamente.",
          });
          setEvaluaciones(evaluaciones.filter((evaluacion) => evaluacion.id !== evaluacionSeleccionada.id));
          setLoading(false);
          cerrarModalVisorPdf();
          setVisibleModal(false);
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
          notification["error"]({
            message: "Ocurrió un problema",
            description: "Verifique el formato de la fecha.",
          });
          setLoading(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const onClickBtnCerrar = () => {
    setVisibleModal(false);
  };

  const onSelectContrato = async(value) => {
    
    const _ultimoContrato = await EvaluacionService.obtenerUltimoContrato(
         evaluacionSeleccionada.colaborador.numeroDocumentoIdentidad,value);
    setUltimoContrato(_ultimoContrato);
    const contratoSel = tiposContrato.find(c=> c.id === value)
    setContratoSeleccionado(contratoSel?.tramoDocumento)
    formContratoNuevo.resetFields();
    form.setFieldsValue({datosContrato: undefined})
    if(_ultimoContrato?.nombre === undefined){
      setCompletarContrato(false);
      setContratoCompletado(false);
    }else{
      setCompletarContrato(undefined);
      setContratoCompletado(true);
    }

  };

  return (
    <React.Fragment>
    <Modal
      title="Aprobar Evaluación"
      visible={visibleModal}
      onOk={()=>form.submit()}
      onCancel={onClickBtnCerrar}
      okText="Aprobar"
      confirmLoading={loading}
      centered={true}
      okButtonProps={{
        disabled: !contratoCompletado
      }}
    >
      <Form form={form} layout="vertical" onFinish={onClickBtnAprobar}>
        <Form.Item
          name="renovarContrato"
          label="¿Renovar Contrato?"
          initialValue={evaluacionSeleccionada?.resultado.renovarContrato}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select>
            <Select.Option value={true}>SI</Select.Option>
            <Select.Option value={false}>NO</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="mesesRenovacion"
          label="¿Cuántos meses se renueva?"
          initialValue={evaluacionSeleccionada?.resultado?.mesesRenovacion}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="tipoDocumentoId"
          label="Tipo de contrato"
          rules={[
            {
              required: true,
            },
          ]}
          extra={contratoSeleccionado.length>0 && 
            <div>
              <span style={{ color: "#d9363e" }}>Último contrato: </span>
              <span style={{ color: ultimoContrato?.nombre?"#096dd9":"#d9363e", fontSize: "12px", fontWeight: "bold" }}>{ultimoContrato?.periodo?ultimoContrato?.periodo:"No Asignado"}</span>
              {completarContrato!==undefined && <span style={{float:"right"}}><Button type="link" onClick={()=> setCompletarContrato(true)}>Completar</Button> </span>}
            </div>
          }
        >
          <Select showSearch onSelect={onSelectContrato}>
            {tiposContrato &&
              tiposContrato.map((contrato) => {
                return (
                  <Select.Option key={contrato.id} value={contrato.id}>
                    {contrato.nombre}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          name="datosContrato" hidden={true} />
      </Form>
    </Modal>
    
      <Drawer
        title="Completar Contrato"
        width={600}
        onClose={()=> setCompletarContrato(false)}
        visible={completarContrato}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={()=> setCompletarContrato(false)}>Cancel</Button>
            <Button onClick={()=> {formContratoNuevo.submit();}} type="primary">
              Aceptar
            </Button>
          </Space>
        }
      >
        <Form layout="vertical" form={formContratoNuevo} onFinish={()=>{setCompletarContrato(false); form.setFieldsValue({datosContrato: formContratoNuevo.getFieldsValue()}); setContratoCompletado(true)}}>
          {contratoSeleccionado.length>0 && contratoSeleccionado.map((elemento) => {
            return (
              <Form.Item
                name={elemento}
                label={elemento}
                initialValue={datosDegault[elemento]?datosDegault[elemento]:/*ultimoContrato[elemento]*/null}
                rules={[
                  {
                    required: true,
                    message: 'Complete el campo.',
                  },
                ]}
              ><Input disabled={datosDegault[elemento]?true:false} /></Form.Item>
            )
          })
              }
              </Form>
              </Drawer>
    </React.Fragment>
  );
};

export default ModalAprobarEvaluacion;
