import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { Container } from "components/Container";
import {BotonesExtra} from "./componentes";
import { TablaConvocatoria } from "./componentes";
const ConvocatoriaListarPage=()=>{
    return(
      <JotaiProvider>
        <Container titulo="Convocatorias" botonExtra={<BotonesExtra/>}>
           <TablaConvocatoria/>
        </Container>
       </JotaiProvider>    
    )
   
}
export default ConvocatoriaListarPage;