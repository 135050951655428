import Axios from "axios";
import { BaseService } from "./BaseService";

export const LoginService = {
  async autenticar(usuario, clave) {
    const respuesta = await Axios.post(process.env.REACT_APP_ENDPOINT_LOGIN, {
      login: usuario,
      clave: clave,
    });
    return respuesta.data;
  },

  getJwtToken() {
    return localStorage.getItem("jwt-token");
  },

  setJwtToken(token) {
    return localStorage.setItem("jwt-token", token);
  },

  getUsuarioAutenticado() {
    return JSON.parse(localStorage.getItem("jwt-sesion"));
  },

  async cerrarSesion() {
    try {
      //TODO: consultar con endpoint para invalidar token
      await BaseService.post("/cerrar-sesion");
    } catch (error) {
      console.error(error);
    } finally {
      localStorage.removeItem("jwt-token");
      localStorage.removeItem("jwt-sesion");
      localStorage.removeItem("datosEnvio");
      window.location.href = "/login";
    }

  },
};
