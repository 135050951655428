import React from "react";
import { Button,Popconfirm } from "antd";
import { EditOutlined,DeleteOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { PlantillaService } from "services/reclutamiento-y-seleccion";
import {useCargarPlantillas} from "../../hooks";

export const MenuAcciones = ({plantilla}) =>{
    const {plantillas,setPlantillas}=useCargarPlantillas();
    let history=useHistory();

    const BotonEditar = () => {
        const onClickBtnEditar=()=>{                                   
                                                 
            history.push(`/reclutamiento-y-seleccion/plantillas/${plantilla.id}/editar`);
        
        }

        return (
            <Button type="link" icon={<EditOutlined />} onClick={onClickBtnEditar}>
                Editar
            </Button >
        )
    }

    const BotonEliminar = ()=>{
        const onclickBtnEliminar = async() =>{               
               await PlantillaService.eliminar(plantilla.id)
               setPlantillas(plantillas.filter((item)=>item.id!==plantilla.id));              
            
        }

        return(            
            <Popconfirm
            title="¿Está seguro de eliminar la planatilla?"
            onConfirm={onclickBtnEliminar}           
            okText="Si"
            cancelText="No"
           >
            <Button type="link" icon={<DeleteOutlined />} >
                Eliminar
            </Button>
          </Popconfirm>

        )
    }
 return(
    <React.Fragment>
        <BotonEditar/>
        <BotonEliminar/>
    </React.Fragment>
 )
}
