import React, { useState } from "react";
import { Modal, Input, Form, message } from "antd";
import { useAtom } from "jotai";
import {
  solicitudPersonalIdSeleccionadoAtom,
  visibleModalCancelarSolicitudAtom,
} from "../../SeguimientoSolicitudesPersonalStore";
import SolicitudPersonalService from "services/reclutamiento-y-seleccion/SolicitudPersonalService";

const ModalCancelarSolicitud = () => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useAtom(visibleModalCancelarSolicitudAtom);
  const [solicitudPersonalId] = useAtom(solicitudPersonalIdSeleccionadoAtom);
  const [form] = Form.useForm();

  const onCancel = () => {
    setVisible(false);
  };

  const onClickBtnCancelarSolicitud = () => {
    form
      .validateFields()
      .then(async (formulario) => {
        setLoading(true);
        await SolicitudPersonalService.cancelar({
          solicitudPersonalId: solicitudPersonalId,
          motivo: formulario.motivo,
        });
        setLoading(false);
        setVisible(false);
        message.success("Se canceló la solicitud seleccionada!");
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal
      title="Cancelar Solcitud"
      open={visible}
      okText="Cancelar Solicitud"
      onOk={onClickBtnCancelarSolicitud}
      onCancel={onCancel}
      confirmLoading={loading}
      centered
    >
      <Form form={form} layout="vertical" size="middle">
        <Form.Item
          label="Motivo"
          name="motivo"
          rules={[
            {
              required: true,
              message: "Ingrese el motivo",
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default ModalCancelarSolicitud;
