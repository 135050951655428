import React,{useEffect,useContext} from "react"
import {Table} from "antd"
import {ContratoVencidoService} from "services/ContratoVencidoService";
import * as atoms from "../../SeguimientoContratosStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import MenuAcciones from "./MenuAcciones";
import moment from "moment";

const TablaSeguimientoContratos=()=>{
    const[contratos,setContratos]=useAtom(atoms.contratosVencidosAtom);
    const[filtroColaborador,setFiltroColaborador]=useAtom(atoms.filtroColaboradorAtom);
    const [filtroFechaFin,setFiltroFechaFin] = useAtom(atoms.filtroFechaFinAtom);
    const [paginaActual, setPaginaActual] = useAtom(atoms.paginaActualAtom);
    const [tamanioPaginado] = useAtom(atoms.tamanioPaginadoAtom);
    const [totalRegistros, setTotalRegistros] = useAtom(atoms.totalRegistrosAtom);
    const { getUsuarioAutenticado } = useContext(SecurityContext);

    useEffect(()=>{
        const cargarDatos=async ()=>{            
         const respuesta= await ContratoVencidoService.listar({            
            colaborador:filtroColaborador,
            mesFinContrato:filtroFechaFin,
            pagina:paginaActual-1,
            cantidad:tamanioPaginado,

        });         
         setContratos(respuesta.contratos);
         setTotalRegistros(respuesta.totalContratos);
        }
        cargarDatos();
    },[filtroColaborador,filtroFechaFin,paginaActual])
    const columnas=[
        {
            title:"Documento Identidad",
            dataIndex:"numeroDocumentoIdentidadColaborador",
            align:"center"
        },
        {
            title:"Apellidos y Nombres",
            dataIndex:"nombreCompletoColaborador",
            align:"center"
        },
        {
            title:"Cargo",
            dataIndex:"cargoColaborador",
            align:"center"
        },
        {
            title:"Nombre Contrato",
            dataIndex:"nombreContrato",
            align:"center"
        },        
        {
            title:"FechaInicio",
           // dataIndex:"fechaInicio",
            align:"center",
            render:(fila)=>moment(fila.fechaInicio).format("DD/MM/YYYY")
        },
        {
            title:"Fecha Fin",
            //dataIndex:"fechaFin",
            align:"center",
            render:(fila)=>moment(fila.fechaFin).format("DD/MM/YYYY")
        },
        {
            title:"Estado",
            dataIndex:"estado",
            align:"center"
        },
        {
            title:"Acciones",           
            key:"acciones",
            align:"center",
            render: (fila) => <MenuAcciones contrato={fila}/>,
           
        },


    ]
    const onCambiarPaginacion = (page, pageSize) => {
        //console.log("page", page);
        //console.log("pageSize", pageSize);
        setPaginaActual(page);
      };
 return(
    <Table
    rowKey={contrato=>contrato.id}
    columns={columnas}
    dataSource={contratos}
    pagination={{
        pageSize: tamanioPaginado,
        total: totalRegistros,
        current: paginaActual,
        showTotal: (total) => `Total ${total} registros`,
        onChange: (page, pageSize) => onCambiarPaginacion(page, pageSize),
      }}
    />
 )
}
export default TablaSeguimientoContratos;