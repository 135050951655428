import React, { useContext, useState,useEffect} from "react";
import { Space, Form, Input, Button, Checkbox, TreeSelect,Row,Col,Select } from "antd";
import { CheckOutlined,FolderTwoTone,FolderFilled } from "@ant-design/icons";
import { useModalConfirmar } from "store/global"
import { SecurityContext } from "context/SecurityContextProvider";
import * as atoms from "../EditarCarpetaLegajoStore";
import { useAtom } from "jotai";
import { useHistory } from "react-router";
import { useParams} from "react-router-dom";
import CarpetaService from "../../../../../services/CarpetaService";

const CarpetaLegajoEditar=()=>{
    const { Option } = Select;    
    let { idCarpeta } = useParams();

    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const[,setCarpetasLegajos]=useAtom(atoms.carpetasLegajos);
    const [carpetas,setCarpetas]=useState([]);
    const[carpetaSeleccionada,setCarpetaSeleccionada]=useState([]);
    const mostrarModalConfirmacion = useModalConfirmar(state => state.mostrarModal);
    const [formularioCarpeta]=Form.useForm();
   
    useEffect(()=>{
        const cargarDatos= async()=>{
            try {
                const _carpetas= await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                setCarpetas(_carpetas);   
                const carpetaObtenida= await CarpetaService.buscarPorId(idCarpeta);
                formularioCarpeta.setFieldsValue(
                    {   id:carpetaObtenida.id,
                        descripcion:carpetaObtenida.descripcion,
                        carpetaPadreId:carpetaObtenida.carpetaPadreId,
                    }
                )
            } catch (error) {
                console.error(error);
            }

        }

        cargarDatos();        

    },[]);
    const validarCarpetaPadre=async(carpeta)=>{
        if (carpeta.carpetaPadreId===undefined){
            carpeta.carpetaPadreId="";              

        }
   }   

    const onClickBtnActualizarCarpeta=async(carpeta)=>{              
        validarCarpetaPadre(carpeta);            
        setCarpetasLegajos({
            ...carpeta,
            empresaId:getUsuarioAutenticado().empresaSeleccionada.id,           
            
        });
        mostrarModalConfirmacion();

    }
    return(
        <Form
            layout="vertical"           
            //size="middle"
            form={formularioCarpeta}
            onFinish={onClickBtnActualizarCarpeta}
        >
            <Form.Item 
              label="id"
              name="id"
              noStyle
            >
             <Input type="hidden" />
            </Form.Item>
            <Form.Item
                label="Nombre"
                name="descripcion"
                rules={[
                    {
                        required: true,
                        message: "El campo nombre es obligatorio.",
                    },
                ]}   
            >
                <Input/>
            </Form.Item>
            <Form.Item
                label="Ruta"
                name="carpetaPadreId" 
              >
                                
                <Select                                      
                    optionLabelProp="label"

                >               
                        {carpetas.length > 0 &&
                        carpetas.map((carpeta) => {
                            return (
                            <Option 
                                  key={carpeta.id}
                                  value={carpeta.id}
                                  label={
                                      <div>
                                          <FolderTwoTone 
                                                   style={{fontSize:'20px',color:'#F7D358' }} 
                                                   twoToneColor='#F7D358' 
                                            /> {carpeta.ruta}
                                          </div>}>
                                <Space size={carpeta.nivel * 10}>
                                    <span></span>
                                    <span>                                        
                                        {carpeta.nivel===0?(
                                                      <FolderFilled
                                                        style={{fontSize:'20px',color:'#F7D358' }}                                                                                      
                                                      /> 
                                                      ):
                                                      (
                                                          <FolderTwoTone
                                                          style={{fontSize:'18px',color:'#F7D358' }}
                                                          twoToneColor='#F7D358'                              
                                                        /> 

                                                      )
                                        } 
                                                 {" "}{carpeta.descripcion}
                                    </span>
                                </Space>
                            </Option>
                            );
                        })}
              </Select>
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }} >
                <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    htmlType="submit"
                >
                    Actualizar Carpeta
                </Button>
            </Form.Item>

        </Form>

    )


}
export default CarpetaLegajoEditar;