import React, { useState, useContext } from "react";
import { FirmaDocumentoGenericoMisDocumentosContext } from "context/firma-documento-generico/mis-documentos";
import { Button, Modal, Alert, Input } from "antd"
import { useModalClaveCertificadoStore, useModalFirmarStore, useModalExitoStore, useMisDocumentosStore } from "hooks/firma-documento-generico";
import { EstadoDocumentoGenerico } from "enums/EstadoDocumentoGenerico";
import { SecurityContext } from "context/SecurityContextProvider";

const ModalClaveCertificado = () => {

    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const loading = useModalClaveCertificadoStore(state => state.loading);
    const firmarDocumento = useModalClaveCertificadoStore(state => state.firmarDocumento);
    const mensajeError = useModalClaveCertificadoStore(state => state.mensajeError);
    const setMensajeError = useModalClaveCertificadoStore(state => state.setMensajeError);
    const visibleModalClaveCertificado = useModalClaveCertificadoStore(state => state.visibleModalClaveCertificado);
    const cerrarModalClaveCertificado = useModalClaveCertificadoStore(state => state.cerrarModalClaveCertificado);

    const documento = useModalFirmarStore(state => state.documento);
    const certificadoSeleccionadoId = useModalFirmarStore(state => state.certificadoSeleccionadoId);
    const posicionFirma = useModalFirmarStore(state => state.posicionFirma);
    const pagina = useModalFirmarStore(state => state.pagina);
    const numeroPagina = useModalFirmarStore(state => state.numeroPagina);
    const cerrarModalVisorFirma = useModalFirmarStore(state => state.cerrarModalVisorFirma);

    const mostrarModalExito = useModalExitoStore(state => state.mostrarModalExito);

    const documentos = useMisDocumentosStore(state => state.documentos);
    const setDocumentos = useMisDocumentosStore(state => state.setDocumentos);

    const [claveIngresada, setClaveIngresada] = useState("");

    const onClickBtnFirmar = async () => {
        const datosFirma = {
            usuarioId: getUsuarioAutenticado().id,
            documentoGenericoId: documento.id,
            certificadoDigitalId: certificadoSeleccionadoId,
            claveCertificado: claveIngresada,
            pagina: numeroPagina,
            top: posicionFirma.top,
            left: posicionFirma.left,
            height: pagina.alto,
            width: pagina.ancho
        };
        const respuesta = await firmarDocumento(datosFirma);
        if (respuesta === true) {
            setDocumentos(documentos.map((documento) => {
                if (documento.id === datosFirma.documentoGenericoId) {
                    return { ...documento, estadoDocumento: EstadoDocumentoGenerico.FIRMADO }
                }
                return documento
            }));
            cerrarModalClaveCertificado();
            cerrarModalVisorFirma();
            mostrarModalExito();
        }
    }

    return (
        <Modal
            title="Firmar Documento"
            visible={visibleModalClaveCertificado}
            closable={false}
            width={400}
            destroyOnClose={true}
            footer={[
                <Button key="cancelar" disabled={loading} onClick={cerrarModalClaveCertificado} style={{ padding: "0px 30px" }}>
                    Cancelar
                </Button>,
                <Button key="firmar" type="primary" loading={loading} onClick={onClickBtnFirmar} style={{ padding: "0px 30px" }}>
                    Firmar
                </Button>,
            ]}
            centered
        >
            {mensajeError &&
                <Alert
                    message={mensajeError}
                    onClose={() => setMensajeError(undefined)}
                    style={{ marginBottom: "4px" }}
                    type="error"
                    showIcon
                    closable />}

            <span style={{ display: "block", marginBottom: "4px" }}>Ingresa tu clave</span>
            <Input.Password value={claveIngresada} onChange={(e) => { setClaveIngresada(e.target.value); setMensajeError(undefined) }} placeholder={"Clave"} />
        </Modal >
    )
}

export default ModalClaveCertificado;