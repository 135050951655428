import { atom } from "jotai";

// export const colaboradoresOpcionesAtom = atom([]);
// export const tipoDocumentoOpcionesAtom = atom([]);
export const listaCargosFiltroAtom = atom([]);
export const listaSedesFiltroAtom = atom([]);
export const listaTiposDocumentoFiltroAtom = atom([]);
export const listraCarpetasFiltroAtom = atom([]);
export const listaSubSedesFiltroAtom = atom([]);
export const listaSeccionesFiltroAtom = atom([]);
export const listaPerfilesFiltroAtom = atom([]);
export const listaTiposPlanillaFiltroAtom = atom([]);
export const filtrosSeleccionadosAtom = atom(undefined);
export const documentosAtom = atom({});
// export const filtroTipoDocumentoAtom = atom([]);
// export const filtroCarpetaAtom = atom([]);
// export const filtroNotificadorAtom = atom();
// export const empresasAtom = atom();
// export const CategoriaAtom = atom();
export const carpetaAtom = atom([]);
// export const consultaDocumentoAtom = atom([]);
export const visibleModalActualizarCorreoAtom = atom();
export const visibleModalHistorialNotifiacaionAtom = atom();
export const visibleModalAsignarCarpetaAtom = atom();
export const visibleModalFiltrarAtom = atom(false);
export const tagVisibleAtom = atom("none");
export const consultaDocumentoSeleccionadoAtom = atom();
export const visibleModalCargarDocumentoAtom = atom();
 export const loadingAtom = atom(false);
export const opcionesAtom = atom([]);
export const categoriasAtom = atom([]);
export const opcionesSeleccionadasAtom = atom([]);
export const filtrosAtom = atom([]);
export const removeTagAtom=atom(false);
export const valoresInicialesAtom = atom([]);
export const camposHijosAtom = atom([]);
export const codigosOpcionesAtom = atom([]);
