import React from "react";
import { Skeleton } from "antd";
import "./PanelCantidad.css";

const PanelCantidad = ({ loading, descripcion, total, color, backgroundColor }) => {
    return (

        <div className="panel-cantidad" style={{ backgroundColor: backgroundColor, width:'13vw', textAlign:'center'}}>
            <Skeleton loading={loading} active paragraph={{ rows: 2, width: '100%' }} title={false} className="resumen-skeleton" >
                <div style={{ fontSize: '25px', fontWeight: 'bold', color: color }}>{total}</div>
                <div style={{ color: color }}>{descripcion}</div>
            </Skeleton>
        </div>
    )
}

export default PanelCantidad;