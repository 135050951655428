import React, {useContext} from "react";
import {
    FileTextOutlined, SoundOutlined, FileDoneOutlined, CaretDownOutlined, PlusOutlined
  } from "@ant-design/icons";
import { Menu, Button, Dropdown, Space } from "antd";
import { useHistory } from "react-router";


const BotonCrearNotificacion = () => {
    let history = useHistory();

    const menu = (
        <Menu onClick={handleOnclickCrearNotificacion}>
          <Menu.Item key="1" icon={<FileDoneOutlined  style={{ color: "#1678c1" }}/>}>
          Documento Laboral
          </Menu.Item>
          <Menu.Item key="2" icon={<SoundOutlined style={{ color: "#1678c1" }}/>}>
          Comunicado
          </Menu.Item>
          <Menu.Item key="3" icon={<FileTextOutlined style={{ color: "#1678c1" }}/>}>
          Contrato
          </Menu.Item>
        </Menu>
      );





      function  handleOnclickCrearNotificacion(item) {
        switch(item.key)
        {
            case "1":
                history.push(`/documento-laboral/envio/`)
                break;
            case "2":
                history.push(`/notificacion/comunicado/notificar/`)
                break;
            case "3":
                history.push(`/contrato/crear/`) 
                break;
            default:
                console.error("No se encuentra esa opción");;

        }
        
    }

    return <Dropdown overlay={menu}>
        <Button type="primary" >
        <PlusOutlined /> &nbsp;&nbsp;Crear Notificación &nbsp;&nbsp;&nbsp;
    </Button>
     </Dropdown>

}

export default BotonCrearNotificacion;