import { useAtom } from "jotai";
import { colaboradorAtom } from "../LegajoColaboradorStore";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ColaboradorService from "services/ColaboradorService";

const useBuscarColaborador = () => {
  let { colaboradorId } = useParams();
  const [colaborador, setColaborador] = useAtom(colaboradorAtom);

  useEffect(() => {
    const cargarDatos = async () => {
      const respuesta = await ColaboradorService.buscarPorId(colaboradorId);
      setColaborador(respuesta);
     // console.log("Colaborador: ", respuesta);
    };
    cargarDatos();
  }, []);

  return { colaborador };
};

export default useBuscarColaborador;
