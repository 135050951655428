import { atom } from "jotai";

export const tipoDocumentosAtom = atom([]);
export const categoriaAtom = atom();
export const botonVisibleAtom = atom(false);
export const enviosAtom = atom([]);
export const documentoSeleccionadoAtom = atom();
export const carpetasAtom = atom([]);
export const visibleModalAsignarCarpetaAtom = atom(false);
export const codigosOpcionesAtom = atom([]);
