import React from "react";
import styled from "@emotion/styled";
import { Errores, InicializarValores, SignatureModal, Visor, Zoom } from "./componentes";
import { Loading } from "./componentes/Loading";
import PdfNoEspecificado from "./componentes/PdfNoEspecificado/PdfNoEspecificado";

const VisorFormulario = ({
  type,
  form,
  urlPdf,
  componentes,
  variablesGlobales,
  datosFormulario,
  urlFirmaHolografa,
  urlHuellaDactilar,
}) => {
  return (
    <Container>
      <InicializarValores
        type={type}
        form={form}
        urlPdf={urlPdf}
        componentes={componentes}
        variablesGlobales={variablesGlobales}
        datosFormulario={datosFormulario}
        urlFirmaHolografa={urlFirmaHolografa}
        urlHuellaDactilar={urlHuellaDactilar}
      />
      <Loading />
      <PdfNoEspecificado />
      {type === "visor" && <Errores />}
      <Zoom />
      <Visor />
      {type === "visor" && <SignatureModal />}
    </Container>
  );
};
const Container = styled.div`
  border: 1px solid #ccc;
  height: 100%;
  width: 100%;
  position: relative;
`;

export default VisorFormulario;
