import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";
import qs from "qs";

const URL_API = ApiUrl.RECLUTAMIENTO_Y_SELECCION;

const SolicitudPersonalService = {
  async listar() {
    const respuesta = await ApiService(URL_API).get("/solicitudPersonal");
    return respuesta.data;
  },
  async buscarPorId(solicitudPersonalId) {
    const respuesta = await ApiService(URL_API).get(`/solicitudPersonal/${solicitudPersonalId}`);
    return respuesta.data;
  },

  async rechazar(motivoRechazoData) {
    const respuesta = await ApiService(URL_API).post("/solicitudPersonal/rechazarSolicitud", motivoRechazoData);
    return respuesta.data;
  },

  async cancelar(data) {
    const respuesta = await ApiService(URL_API).put("/solicitudPersonal/cancelar", data);
    return respuesta.data;
  },
  async listarPorFiltros(estado) {
    const respuesta = await ApiService(URL_API).get("/solicitudPersonal/listar-por-filtro",{
      params:{
        filtroEstado:estado,
      }
    });
    return respuesta.data;
  },
  async obtenerFechaCierreColaborador(solicitudPersonalId, postulanteId){
    const respuesta = await ApiService(URL_API).get(`/solicitudPersonal/${solicitudPersonalId}/contratadoDetalle/${postulanteId}`);
    return respuesta.data;
  },

  async descargarReporteExcel() {
    const respuesta = await ApiService(URL_API).get("/solicitudPersonal/exportar",

    {
      responseType: "blob",
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    return respuesta;
  },
};
export default SolicitudPersonalService;
