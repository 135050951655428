import React, { useContext, useEffect, useState } from "react";
import { Drawer, Button, Space, Form, Row, Col, Input, Divider, Checkbox, message, Select } from "antd";
import { useAtom } from "jotai";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {
  archivosAdjuntosAtom,
  visibleDrawerArchivosAtom,
  editarArchivoAtom,
  archivoSeleccionadoAtom,
} from "../CrearTipoAbsentismoStore";
import { TipoControl } from "enums";

const DrawerArchivoAdjuntoRegistro = () => {
  const [visible, setVisible] = useAtom(visibleDrawerArchivosAtom);
  const [frmArchivos] = Form.useForm();
  const [controlSeleccionado, setControlSeleccionado] = useState(undefined);
  const [archivosAdjuntos, setArchivosAdjuntos] = useAtom(archivosAdjuntosAtom);
  const [editarArchivo, setEditarArchivo] = useAtom(editarArchivoAtom);
  const [archivoSeleccionado, setArchivoSeleccionado] = useAtom(archivoSeleccionadoAtom);

  useEffect(() => {
    const cargarDatos = () => {
      if (editarArchivo) {
        frmArchivos.setFieldsValue({
          keyPrev: archivoSeleccionado.key,
          key: archivoSeleccionado.key,
          label: archivoSeleccionado.label,
          required: archivoSeleccionado.required,
        });
        setControlSeleccionado(archivoSeleccionado.type);
      } else
        frmArchivos.setFieldsValue({
          required: true,
        });
    };
    cargarDatos();
  }, [editarArchivo, archivoSeleccionado]);

  const onClose = () => {
    setEditarArchivo(false);
    setArchivoSeleccionado();
    setControlSeleccionado();
    frmArchivos.resetFields();
    setVisible(false);
  };

  const onSelectTipoControl = (value) => {
    setControlSeleccionado(value);
  };

  const onClickBtnGuardar = () => {
    frmArchivos
      .validateFields()
      .then((form) => {
        const archivoAgregado = {
          key: form.key,
          keyPrev: form.keyPrev,
          label: form.label,
          required: form.required,
        };

        const existeControl = archivosAdjuntos.find((a) => a.key === archivoAgregado.key);
        if (existeControl && existeControl.key !== form.keyPrev) {
          message.error("Ya existe un control con ese nombre");
        } else {
          if (editarArchivo) {
            setArchivosAdjuntos(
              archivosAdjuntos.map((archivo) => {
                if (archivo.key === archivoAgregado.keyPrev) {
                  return { ...archivo, ...archivoAgregado };
                }
                return archivo;
              })
            );
          } else {
            setArchivosAdjuntos([...archivosAdjuntos, archivoAgregado]);
          }

          onClose();
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
  return (
    <Drawer
      title="Propiedades Control"
      placement="right"
      onClose={onClose}
      visible={visible}
      width="420px"
      extra={
        <Space>
          <Button onClick={onClose}>Cerrar</Button>
          <Button onClick={onClickBtnGuardar} type="primary">
            Aplicar
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" form={frmArchivos} initialValues={{ required: !editarArchivo && true }}>
        <Form.Item name="keyPrev" hidden={true}>
          <Input />
        </Form.Item>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="key"
              label="Identificador"
              rules={[{ required: true, message: "El campo identificador es requerido" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="label"
              label="Etiqueta"
              rules={[{ required: true, message: "El campo etiqueta es requerido" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item name="required" valuePropName="checked">
              <Checkbox>Requerido</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        {controlSeleccionado && controlSeleccionado === TipoControl.LISTA && (
          <React.Fragment>
            <Divider orientation="left" plain>
              Valores Lista
            </Divider>
            <Row gutter={16}>
              <Col span={24}>
                <Form.List name="items">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                          <Form.Item
                            {...restField}
                            name={[name, "key"]}
                            rules={[{ required: true, message: "Código es obligatorio" }]}
                          >
                            <Input placeholder="Código" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "value"]}
                            rules={[{ required: true, message: "Valor es obligatorio" }]}
                          >
                            <Input placeholder="Valor" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Adicionar Valores
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </Form>
    </Drawer>
  );
};
export default DrawerArchivoAdjuntoRegistro;
