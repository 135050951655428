import React from "react";
import { Container } from "components/Container";
import { VerificarDatosColaboradorNotificar } from "./verificar-datos-colaborador-notificar";
import { useHistory } from "react-router";

const VerificarDatosColaboradorPage = () => {
  const history = useHistory();

  const onClickBtnRetornar = () => {
    history.push("/configuracion/verificar-datos-colaborador");
  };

  return (
    <Container titulo="Verificar Datos Colaborador" onClickRetornar={onClickBtnRetornar}>
      <VerificarDatosColaboradorNotificar />
    </Container>
  );
};
export default VerificarDatosColaboradorPage;
