import React  from "react";
import { Modal} from "antd";
import { useAtom } from "jotai";
import {visibleModalFirmaHolografa} from "../ListarFirmaHolografaStore";
import {urlFirmaHolografaAtom} from "../ListarFirmaHolografaStore";

const ModalVerFirmaHolografa=()=>{
    const[visible,setVisible]=useAtom(visibleModalFirmaHolografa);
    const[url,setUrl]=useAtom(urlFirmaHolografaAtom);

    const onCancelModal=()=>{
        setVisible(false);
    }

    return (
        <Modal
        visible={visible}
        onCancel={onCancelModal}
        footer={null}
        title={"Firma Holográfa"}
       
        >

            <div>
               <img src={url} style={{ display:"block",marginRight:"auto",width:"50%",marginLeft:"auto"}}/>
            </div>          
                     
            
        </Modal>
    )

}
export default ModalVerFirmaHolografa;