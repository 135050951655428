import React, { useState, useEffect, useRef } from "react";
import {
  Input,
  Row,
  Col,
  Button,
  Space,
  Select,
  Upload,
  Table,
  Modal,
  notification,
  Tabs,
  Typography,
  Card,
  Alert,
} from "antd";
import {
  InboxOutlined,
  ExclamationCircleOutlined,
  MailOutlined,
  SearchOutlined,
  FileOutlined,
  NumberOutlined,
  HighlightOutlined,
  SolutionOutlined,
  FolderOpenOutlined,
  FrownOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { LoginService } from "services/LoginService";
import UsuarioService from "../../../services/UsuarioService";
import { ModalPDF } from "../../../components/PDFViewer/visorCrearNotificacion";
import { useHistory } from "react-router-dom";
import ContratoEnvioService from "../../../services/ContratoEnvioService";
import { Headers } from "enums";
const { Text } = Typography;
const { TabPane } = Tabs;
const { Option } = Select;

export function ListarEnvioContratoPage() {
  const { Dragger } = Upload;
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  //const { getDatosTipoDocumento } = useContext(SecurityContext);
  const { getDatosEnvio } = useContext(SecurityContext);

  const [visibleModal, setVisibleModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deshabilitarBtnNotificar, setDeshabilitarBtnNotificar] = useState(true);
  const [listaDocumentos, setListaDocumentos] = useState([]);
  //const [cargaTemporalId, setCargaTemporalId] = useState([]);
  const [representanteLegal, setRepresentanteLegal] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [colaboradoresObservados, setColaboradoresObservados] = useState([]);
  const [keyTab, setKeyTab] = useState("11");
  const [tipoDocumentoAprobacion, setTipoDocumentoAprobacion] = useState();
  const [visibleModalAprobacion, setVisibleModalAprobacion] = useState();
  const [usuariosAprobadores, setUSuariosAprobadores] = useState([]);
  const [usuariosAprobadoresSeleccionados, setUsuariosAprobadoresSeleccionados] = useState([]);
  const [detalleEnvio, setDetalleEnvio] = useState([]);
  const [colaboradoresVisibles, setColaboradoresVisibles] = useState(false);
  const [visibleModalResumen, setVisibleModalResumen] = useState(false);
  let history = useHistory();
  const [filaSeleccionada, setFilaSeleccionada] = useState("");
  const [url, setUrl] = useState("");
  const [visible, setVisible] = useState("");
  const datos = {
    organizacionId: getUsuarioAutenticado().organizacionId,
    empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
    envioId: getDatosEnvio().id,
    formatoCargaId: getDatosEnvio().formatoCargaId,
  };

  const props = {
    name: "file",
    multiple: true,
    action: process.env.REACT_APP_ENDPOINT + "contrato/envio/cargarDocumento",
    headers: {
      Authorization: "Bearer " + LoginService.getJwtToken(),
      [Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
      [Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
    },
    data: datos,
    onChange(info) {
      const { status } = info.file;
      // Garantiza solo la carga de un archivo
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
        console.log("entre1:");
      }
      if (status === "done") {
        //setCargaTemporalId(info.file.response.cagaTemporalId);
        console.log("info cargada", info.file.response);

        setColaboradores(info.file.response.listaColaboradores);
        //setRepresentanteLegal(info.file.response.representanteLegal);
        setColaboradoresObservados(info.file.response.listaColaboradoresObservados);
        //setDeshabilitarBtnNotificar(false);

        if (
          info.file.response.listaColaboradores.length === 0 &&
          info.file.response.listaColaboradoresObservados === 0
        ) {
          // setDeshabilitarBtnNotificar(true);
          notification.warning({
            message: "Advertencia",
            description:
              "No se encontraron Colaboradores por favor revisar el archivo de carga - validar posición del número de documento.",
          });
        } else {
          notification.success({
            message: "Operación exitosa",
            description: `Se cargo correctamente el archivo: ${info.file.name}`,
          });
          setDeshabilitarBtnNotificar(false);
          setColaboradoresVisibles(true);
        }
      } else if (status === "error") {
        notification.error({
          message: "Error al cargar el archivo",
          description: "codigo: " + info.file.response.error + " - " + info.file.response.mensaje,
        });
      }
      setListaDocumentos(fileList);
    },
  };

  async function handleOnClickVerDocumento(colaborador) {
    try {
      setLoading(true);
      setFilaSeleccionada(colaborador.numeroDocumentoIdentidad);

      let data;
      const formData = new FormData();

      formData.append("envioId", getDatosEnvio().id);
      formData.append("organizacionId", getUsuarioAutenticado().organizacionId);
      formData.append("empresaId", getUsuarioAutenticado().empresaSeleccionada.id);
      formData.append("plantillaContratoId", getDatosEnvio().documentoId); // plantillaContratoId
      formData.append("numeroDocumentoIdentidad", colaborador.numeroDocumentoIdentidad);
      data = await ContratoEnvioService.verPDF(formData);

      const url = window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));

      setUrl(url);
      setVisible(true);
      console.log("ruta", url);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  var adicionarColumnasFiltro = function getColumns(dataIndex, placeholder, propiedad) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={placeholder !== undefined ? placeholder : `Buscar por ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
              Limpiar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
      onFilter: (value, record) =>
        propiedad === undefined
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : record[dataIndex][propiedad].toString().toLowerCase().includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={propiedad === undefined ? text?.toString() : text[propiedad]?.toString()}
          />
        ) : propiedad === undefined ? (
          text
        ) : (
          text[propiedad]
        ),
    };
  };

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText("");
  }
  const columnas = [
    {
      title: "Documento",
      dataIndex: "numeroDocumentoIdentidad",
      align: "center",
      ...adicionarColumnasFiltro("numeroDocumentoIdentidad"),
    },
    {
      title: "Apellidos y Nombres",
      dataIndex: "nombreCompleto",
      align: "center",
      ...adicionarColumnasFiltro("nombreCompleto"),
    },
    {
      title: "Correo electrónico",
      dataIndex: "correoElectronico",
      align: "center",
      ...adicionarColumnasFiltro("correoElectronico"),
    },
    {
      title: "Acciones",
      key: "acciones",
      render: (fila, colaborador) =>
        colaboradores.length >= 1 ? (
          <Space>
            <Button
              type="link"
              onClick={() => handleOnClickVerDocumento(colaborador)}
              loading={filaSeleccionada === colaborador.numeroDocumentoIdentidad && loading}
            >
              ver
            </Button>
          </Space>
        ) : null,
    },
  ];

  // tabla observaciones
  const columnasColaboradoresObservados = [
    {
      title: "Documento",
      dataIndex: "numeroDocumentoIdentidad",
      align: "center",
      ...adicionarColumnasFiltro("numeroDocumentoIdentidad"),
    },
    {
      title: "Apellidos y Nombres",
      dataIndex: "nombreCompleto",
      align: "center",
      ...adicionarColumnasFiltro("nombreCompleto"),
    },
    {
      title: "Correo electrónico",
      dataIndex: "correoElectronico",
      align: "center",
      //...adicionarColumnasFiltro("correoElectronico"),
    },
    {
      title: "Mensaje",
      dataIndex: "mensaje",
      align: "center",
      //...adicionarColumnasFiltro("nombreCompleto"),
    },
    {
      title: "Acciones",
      key: "acciones",
      render: (fila, colaborador) =>
        colaboradores.length >= 1 && colaborador.urlDocumentoCargado ? (
          <Space>
            <Button
              type="link"
              // onClick={() => handleOnClickVerDocumento(colaborador)}
            >
              ver
            </Button>
          </Space>
        ) : null,
    },
  ];
  async function handleOnClickNotificar() {
    //if (getDatosEnvio().aprobacion && getDatosEnvio().aprobacion === true) {
    // if (getDatosEnvio().flujo && (getDatosEnvio().flujo === "APROBACION_Y_FIRMA_GENERICA" || getDatosEnvio().flujo === "APROBACION_Y_FIRMA_DEL_APROBADOR")) {
    //   setVisibleModalAprobacion(true);
    //   const usuarios = await UsuarioService.listarUsuariosAProbadores(
    //     getUsuarioAutenticado().empresaSeleccionada.id
    //   );
    //   setUSuariosAprobadores(usuarios);
    // } else {
    setVisibleModalResumen(true);
    // }
  }

  function handleOnClickLimpiar() {
    setColaboradoresVisibles(false);
    setListaDocumentos([]);
    setColaboradoresObservados([]);
  }
  function cerrarModalResumen() {
    setVisibleModalResumen(false);
  }
  function cerrarModalAprobacion() {
    setVisibleModalAprobacion(false);
    setUsuariosAprobadoresSeleccionados([]);
  }
  async function procesarNotificacion() {
    try {
      const datos = {
        //id: getDatosEnvio().id,
        envioId: getDatosEnvio().id,
        usuarioLogin: getUsuarioAutenticado().login,
        usuarioNombreCompleto: getUsuarioAutenticado().nombreCompleto,
      };
      //probando
      const data = await ContratoEnvioService.notificar(datos);
      notification.success({
        message: "Operación exitosa",
        description: "Se realizo el proceso correctamente.",
      });
      setLoading(false);
      cerrarModalAprobacion();
      cerrarModalResumen();
      setColaboradoresVisibles(false);

      //setDatosEnvio(data.id, data.tipoDocumentoNombre, data.descripcion);

      //history.push("/contrato/listarContratoDetalle");
      history.push(`/contrato/seguimiento/${datos.envioId}/detalle`);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  function handleOnOkModal() {
    setVisible(false);
  }
  return (
    <Row>
      <Col span={24}>
        <Card
          title={
            <Text>
              <Space>
                {<FolderOpenOutlined style={{ fontSize: "50px" }} />}
                <span className="mock-block">
                  <Text strong>{getDatosEnvio().documentoNombre}</Text>
                  <p>{getDatosEnvio().descripcion}</p>
                </span>
              </Space>
            </Text>
          }
          // extra={
          //   <React.Fragment>
          //     {colaboradoresVisibles && (
          //       <Space>
          //         {<FolderOpenOutlined style={{ fontSize: "50px" }} />}
          //         <span className="mock-block">
          //           <Text strong>{getDatosEnvio().descripcion}</Text>
          //           <p>{getDatosEnvio().plantillaContratoNombre}</p>
          //         </span>
          //       </Space>
          //   </Text>
          // }
          extra={
            <React.Fragment>
              {colaboradoresVisibles && (
                <Space>
                  {/* <Button type="primary" onClick={handleOnClickNotificar}>
                    {getDatosEnvio().flujo != "SIMPLE_SIN_APROBADOR" ? (<span>Solicitar Aprobación</span>) : (<span>Notificar</span>)}
                  </Button> */}
                  <Button type="primary" onClick={handleOnClickNotificar}>
                    Notificar
                  </Button>
                  <Button onClick={handleOnClickLimpiar}>Limpiar</Button>
                </Space>
              )}
            </React.Fragment>
          }
          headStyle={{ fontWeight: "400" }}
        >
          {colaboradoresObservados.length > 0 && (
            <Alert
              description="Se ha encontrado observaciones al cargar el Documento.Por favor haz click en Observaciones."
              type="error"
              showIcon
            />
          )}
          {colaboradoresVisibles === false ? (
            <div
              style={{
                marginBottom: "30px",
                textAlign: "left",
              }}
            >
              <Dragger
                {...props}
                fileList={listaDocumentos}
                // disabled={deshabilitarUploadDocumentoLaboral}
              >
                <p className="ant-upload-drag-icon">
                  <UploadOutlined />
                </p>
                <p className="ant-upload-text">Haga click ó arrastre su archivo hacia aquí para iniciar el proceso.</p>
              </Dragger>
            </div>
          ) : (
            <Tabs defaultActiveKey="1">
              <TabPane tab="Colaboradores" key="1">
                <Table
                  rowKey={(colaboradores) => colaboradores.numeroDocumentoIdentidad}
                  columns={columnas}
                  dataSource={colaboradores}
                  pagination={{
                    pageSize: [10],
                  }}
                />
              </TabPane>
              <TabPane tab="Observaciones" key="2">
                <Table
                  rowKey={(colaboradoresObservados) => colaboradoresObservados.numeroDocumentoIdentidad}
                  columns={columnasColaboradoresObservados}
                  dataSource={colaboradoresObservados}
                  pagination={{
                    pageSize: [10],
                  }}
                />
              </TabPane>
            </Tabs>
          )}
        </Card>
      </Col>
      {/* <Modal
      title="Requiere Aprobación"
      visible={visibleModalAprobacion}
      onOk={procesarNotificacion}
      onCancel={cerrarModalAprobacion}
      footer={[
        <Button key="back" onClick={cerrarModalAprobacion}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={procesarNotificacion}
          disabled={
            usuariosAprobadores.length > 0 && usuariosAprobadores !== null
              ? false
              : true
          }
        >
          
          Enviar
        </Button>,
      ]}
      destroyOnClose={true}
    >
      {usuariosAprobadores.length > 0 && usuariosAprobadores !== null ? (
        <React.Fragment>
          Seleccione al responsable de Aprobar la Notificación
          <Table
            rowKey={(usuarioAprobador) => usuarioAprobador.id}
            columns={columnaAprobadores}
            dataSource={usuariosAprobadores}
            bordered={false}
            pagination={false}
            rowSelection={{
              type: "radio",
              ...rowSelectionUsuario,
              columnWidth: 2,
            }}
            size="small"
          />
        </React.Fragment>
      ) : (
        <Text type="danger">
          No existen usuarios aprobadores
        </Text>
      )}
    </Modal> */}
      <Modal
        title="Resumen"
        visible={visibleModalResumen}
        onOk={procesarNotificacion}
        onCancel={cerrarModalResumen}
        footer={[
          <Button key="back" onClick={cerrarModalResumen}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={procesarNotificacion}>
            Enviar
          </Button>,
        ]}
      >
        <Row align="center">
          <Col span={17} className="space-right">
            <Row justify="start">
              <span style={{ fontWeight: "bold" }}>
                <FileOutlined /> Documento Laboral
              </span>
            </Row>

            <Row justify="start">
              <span style={{ fontWeight: "bold" }}>
                <HighlightOutlined /> Descripción
              </span>
            </Row>

            <Row justify="start">
              <span style={{ fontWeight: "bold" }}>
                <NumberOutlined /> Cantidad de colaboradores
              </span>
            </Row>

            <Row justify="start">
              <span style={{ fontWeight: "bold" }}>
                <SolutionOutlined /> Responsable de firmar los Documentos
              </span>
            </Row>
          </Col>
          <Col span={7}>
            <Row justify="start">{getDatosEnvio().documentoNombre}</Row>
            <Row justify="start">{getDatosEnvio().descripcion}</Row>
            <Row justify="start">{colaboradores.length}</Row>
            {/* <Row justify="start">{representanteLegal}</Row> */}
          </Col>
        </Row>
      </Modal>

      <ModalPDF ruta={url} visible={visible} handleOnOkModal={handleOnOkModal} />
    </Row>
  );
}
