import React from "react";
import { Button, Space, Spin, Table, Tag } from "antd";
import { useCargarDocumentos } from "../../hooks";
import MenuAcciones from "./MenuAcciones";
import { useModalVisorPdf, useModalRechazarOnboarding } from "store/global";
import { detalleIdAtom, procesoIdSeleccionadoAtom, documentosProcesoAtom, filtrosAtom,resumenAtom,resetAtom,loadingAtom } from "../../DocumentosProcesoListarStore";
import { useEffect } from "react";
import { useAtom } from "jotai";
import { ProcesoService } from "services/reclutamiento-y-seleccion/ProcesoService";
import {
  UserOutlined,
  CalendarOutlined,
  CloseOutlined,
  CheckOutlined,
  EditOutlined,
  FileTextOutlined,LoadingOutlined
} from "@ant-design/icons";
import TagEtapa from "./TagEtapa";
import { FiltroBusqueda } from "../FiltroBusqueda";
import { useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import ModalActualizarDocumento from "./ModalActualizarDocumento";
import { Dashboard } from "../Dashboard";

import { CategoriaDocumento } from "enums/CategoriaDocumento";

const TablaDocumentos = () => {
  
  //const { documentos } = useCargarDocumentos(filtros);
  const [documentosListado, setDocumentosListados] = useAtom(documentosProcesoAtom);
  const setFinalizar = useModalRechazarOnboarding((state) => state.setFinalizar);
  const motivoRechazo = useModalRechazarOnboarding((state) => state.motivoRechazo);
  const finProcesoRechazo = useModalRechazarOnboarding((state) => state.finProceso);
  const finProcesoAprobar = useModalVisorPdf((state) => state.finProceso);
  const finalizarOnboarding = useModalVisorPdf((state) => state.finalizarOnboarding);
  const [detalleId, ] = useAtom(detalleIdAtom);
  const [,setProcesoSeleccionado]=useAtom(procesoIdSeleccionadoAtom);

  //
  //const [resumen, setResumen] = useState(undefined);
  const [resumen, setResumen] = useAtom(resumenAtom);
  //const [filtros, setFiltros] = useState({});
  const [filtros, setFiltros] = useAtom(filtrosAtom);
  //const [reset, setReset] = useState(false);
  const [reset, setReset] = useAtom(resetAtom);
  //const [loading, setLoading] = useState(false);
  const [loading, setLoading] = useAtom(loadingAtom);
  const { procesoId } = useParams();
  

  useEffect(() => {
    if (finProcesoAprobar) {
      try{
        setLoading(true);
        actualizacionInterna(true)
      }catch{console.log("Ocurrio un error en actualizar aprobar")}
      finally{
        finalizarOnboarding()
        setLoading(false);
      }
    }
  }, [finProcesoAprobar]);

  useEffect(() => {
    if (finProcesoRechazo) {
      try{
        setLoading(true);
        actualizacionInterna(false)
      }catch{console.log("Ocurrio un error en actualizar rechazo")}
      finally{
        setFinalizar()
        finalizarOnboarding()
        setLoading(false);
      }
    }
  }, [finProcesoRechazo]);

  useEffect(() => {
    setProcesoSeleccionado(procesoId);
    if (filtros!==null) {
      actualizarDatos();
    }
  }, [filtros]);


  function actualizacionInterna(estado){
    const _documentos = [...documentosListado]; //siempre se debe generar un nuevo array
        const indice = documentosListado.findIndex((detalle) => detalle.id === detalleId);
        const detalleModificar = documentosListado[indice];
        
        const detalleModificado = {
            ...detalleModificar, 
            estadoAprobacion:estado?"APROBADO":"RECHAZADO",
            motivoRechazo:estado?null:motivoRechazo            
        }
        _documentos.splice(indice, 1, detalleModificado);
        setDocumentosListados(_documentos);
      let _resumen = resumen;
      if(estado)
        _resumen.totalAprobados = parseInt(resumen.totalAprobados) + 1;
      _resumen.totalPendientesAprobar = parseInt(resumen.totalPendientesAprobar) - 1
      setResumen(_resumen)
  }

  async function actualizarDatos() {
    setLoading(true);
    const respuesta = await ProcesoService.obtenerDocumentosPorProcesoYFiltro(
      procesoId,
      filtros["documento"],
      filtros["responsable"],
      filtros["etapa"],
      filtros["estadoDocumento"],
      filtros["estadoAprobacion"]
    );
    //console.log(respuesta)
    setDocumentosListados(respuesta.documentos)
    setResumen(respuesta.resumen)
    setReset(false);
    setLoading(false);
  }

  const columnas = [
    {
      title: "Nombre Documento",
      width: "40%",
      render: (texto, fila) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <FileTextOutlined style={{ fontSize: "18px", marginRight: "4px" }} />
            </div>
            <div>
              <span style={{ fontWeight: "500" }}>{fila.tipoDocumento.nombre}</span>
              <span style={{ marginLeft: "4px" }}>
                <TagEtapa etapa={fila.etapa} />
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Responsable",
      render: (texto, fila) => {
        return (
          <span>
            <UserOutlined style={{ marginRight: "8px" }} />
            {fila.responsable.nombreCompleto}
          </span>
        );
      },
    },
    {
      title: "Fecha Límite",
      dataIndex: "fechaLimite",
      align: "center",
      render: (texto, fila) => {
        return (
          <span>
            <CalendarOutlined style={{ marginRight: "8px" }} />
            {fila.fechaLimite}
          </span>
        );
      },
    },
    {
      title: "Estado Documento",
      key: "estadoVistaColaborador",
      align: "center",
      render: (text, fila) => {
        return fila?.estadoVistaColaborador === "VISUALIZADO" ? (
          <Tag color="green" className="milegajo-ant-tag">
            VISUALIZADO
          </Tag>
        ) :fila?.categoriaDocumento.id===CategoriaDocumento.CARGA_DOCUMENTO.id && fila?.estadoVistaColaborador === "FIRMADO" ?(
          <Tag color="green" className="milegajo-ant-tag">
             CARGADO
           </Tag>
        ) : fila?.categoriaDocumento.id===CategoriaDocumento.FORMULARIOS.id && fila?.estadoVistaColaborador === "FIRMADO" ? (
          <Tag color="green" className="milegajo-ant-tag">
            {" "}
            <EditOutlined /> FIRMADO{" "}
          </Tag>
        ) : (
          <Tag color="gold" className="milegajo-ant-tag">
            PENDIENTE
          </Tag>
        );
      },
    },
    {
      title: "Estado Aprobación",
      key: "estadoAprobacion",
      align: "center",
      render: (fila) => {
        if (fila?.estadoAprobacion === null || fila?.estadoAprobacion === "SIN_APROBACION") {
          return <Tag className="milegajo-ant-tag">NO APLICA</Tag>;
        } else if (fila?.estadoAprobacion === "APROBADO") {
          return (
            <Tag color="green" className="milegajo-ant-tag">
              {" "}
              <CheckOutlined /> APROBADO
            </Tag>
          );
        } else if (fila?.estadoAprobacion === "RECHAZADO") {
          return (
            <Tag color="red" className="milegajo-ant-tag">
              {" "}
              <CloseOutlined /> RECHAZADO{" "}
            </Tag>
          );
        }
        return (
          <Tag color="gold" className="milegajo-ant-tag">
            {" "}
            PENDIENTE
          </Tag>
        );
      },
    },
    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      render: (fila) => <MenuAcciones documento={fila} />,
    },
  ];

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 28 }} />} spinning={loading} size="large" tip="Cargando...">
      <Dashboard loading={loading} resumenDocumento={resumen} />
      <ModalActualizarDocumento />
      <FiltroBusqueda setFiltros={setFiltros} filtro={filtros} reset={reset} filtroActual={filtros}  />
      <Table
        rowKey={(documento) => documento.id}
        dataSource={documentosListado}
        columns={columnas}
        pagination={false}
        size="middle"
        scroll={{ y: 300 }}
      />
    </Spin>
  );
};

export default TablaDocumentos;
