import React, { useContext, useEffect, useState } from "react";
import { FilterServer } from "components/MultiFiltro";
import { TipoConsulta } from "enums/TipoConsulta";
import { EstadoConsulta } from "enums/EstadoConsulta";

const FiltroBusqueda = ({setFiltros,reset = false,filtroActual,tipoDocumentos}) => {

  let listaFiltros=[];
  const filtrosBusqueda = {
    titulo: "Filtrar por: ",
    data: [    
      { id: "colaborador", titulo: "Apellidos y Nombres", tipo: "texto" },
      { id: "documentoIdentidad", titulo: "Documento Identidad", tipo: "texto" },
      { id: "codigoColaborador", titulo: "Código de Colaborador", tipo: "texto" },
      { id: "tipoDocumento",titulo:"Tipo Documento",tipo:"lista"},
      // { id: "tipoConsulta",titulo:"Tipo Consulta",tipo:"lista"},
      // { id:"estadoConsulta",titulo:"Estado Consulta",tipo:"lista"},
    ],
  };
  tipoDocumentos.map((t)=>{
    if(t[1].length>0){
       t[1].map((doc)=>{      
       let documento={tipoDocumento:doc.nombre}
       listaFiltros.push(documento);
       })
    }        
  });
  
   listaFiltros.push({tipoConsulta:TipoConsulta.GENERAL});  
   listaFiltros.push({tipoConsulta:TipoConsulta.POR_DOCUMENTO});
   listaFiltros.push({estadoConsulta:EstadoConsulta.ABIERTO});
   listaFiltros.push({estadoConsulta:EstadoConsulta.RESUELTA});

  const onFiltrar = (filtrosSeleccionados) => {    
    try {
       
      if(filtroActual!==undefined){
        if(!(Object.entries(filtroActual).length==0 && Object.entries(filtrosSeleccionados).length==0)){
          setFiltros(filtrosSeleccionados);
          
        }
    }else if(filtroActual===undefined){
      setFiltros(filtrosSeleccionados)
    }
        
    } catch (error) {
      console.error(error);
    } 
  };

  return (
    <FilterServer
      placeholder="Haz clic aquí para filtrar tus consultas"
      filtros={filtrosBusqueda}
      onFiltrarDatos={onFiltrar}
      data={listaFiltros}          
      reset={reset}
      filtroSeleccionadoInicial={{ id: "colaborador", titulo: "Apellidos y Nombres", tipo: "texto" }}
    />
  );
};

export default FiltroBusqueda;
