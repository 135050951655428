import React from "react";
import { Button,Popconfirm } from "antd";
import { EditOutlined, LayoutOutlined,DeleteOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import TipoPlanillaService from "../../../../services/TipoPlanillaService";

export const MenuAcciones=({tipoPlanilla,tipoPlanillas,setTipoPlanillas})=>{

    let history = useHistory();
    
    const BotonEditar = () => {
        const onClickBtnEditar=()=>{
                                   
             const rutaEditarTipoPlanillaPage=`/tipoPlanilla/editar/${tipoPlanilla.id}`;                                              
             history.push(rutaEditarTipoPlanillaPage);
        
        }

        return (
            <Button type="link" icon={<EditOutlined />} onClick={onClickBtnEditar}>
                Editar
            </Button >
        )
    }
    const BotonEliminar = ()=>{
        const onclickBtnEliminar = async() =>{               
              await TipoPlanillaService.eliminar(tipoPlanilla.id);
              setTipoPlanillas(tipoPlanillas.filter((_tipoPlanilla)=>_tipoPlanilla.id!==tipoPlanilla.id));              
            
        }

        return(            
            <Popconfirm
            title="Estás seguro de eliminar el tipo planilla"
            onConfirm={onclickBtnEliminar}           
            okText="Si"
            cancelText="No"
           >
            <Button type="link" icon={<DeleteOutlined />} >
                Eliminar
            </Button>
          </Popconfirm>

        )
    }
    return (
        <React.Fragment>
            <BotonEditar />
            <BotonEliminar />
        </React.Fragment>
    );

}
