import React from "react";
import { Avatar, Descriptions, Typography, Skeleton, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import "./Resumen.css";
import useCargarDashboardData from "../../../hooks/useCargarDashboardData";

const { Text } = Typography;

const Resumen = ({ loading }) => {
    const {dashboard} = useCargarDashboardData();
    return (
        <div className="resumen" >

    <Skeleton loading={loading} active paragraph={{ rows: 4, width: '100%' }} title={false} className="resumen-skeleton" >
            <Descriptions size="small" column={1} colon={false}>
                <Descriptions.Item label={ 
                <Avatar size={80} icon={<UserOutlined />} style={{marginTop:"10px", marginLeft:"5px"}} />}>
                    <div>
                    <li style={{textAlign:"center"}}><Text strong>{dashboard?.combocatoriaNombre}</Text></li>
                    <li><Text strong>{dashboard?.postulante?.nombreCompleto}</Text></li>
                    <li><Text>{"DNI: "+dashboard?.postulante?.numeroDocumentoIdentidad}</Text></li>
                    <li><Text>{"EMAIL: "+dashboard?.postulante?.correoElectronico}</Text></li>
                    </div>
            </Descriptions.Item>
          </Descriptions>
        </Skeleton>
        </div>

    )
}

export default Resumen;