import { Headers } from "enums";
import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";
import { LoginService } from "services/LoginService";

const URL_API = ApiUrl.BASE;

const BandejaAprobacionReclutamientoSeleccionService = {
    async listarContratosPendientesAprobar(login) {
        const respuesta = await ApiService(URL_API).get(
            `/admin/contratos/reclutamiento-y-seleccion/pendientes-aprobar`,
            {
                params: { login: login },
            
            });
        return respuesta.data;
    },

    async aprobarContrato(data) {
        const respuesta = await ApiService(URL_API).post(`/admin/contratos/reclutamiento-y-seleccion/aprobar`, data);
        return respuesta.data;
    },

    getUrlVistaPrevia(seguimientoContratoId) {
        const urlPdf =
            process.env.REACT_APP_ENDPOINT +
            `admin/contratos/reclutamiento-y-seleccion/ver-pdf/${seguimientoContratoId}`;
        const httpHeaders = {
            Authorization: "Bearer " + LoginService.getJwtToken(),
            [Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
            [Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
        };

        return {
            url: urlPdf,
            httpHeaders: httpHeaders,
        };
    },

    async listarContratosPendientesAprobarAndFiltro(data) {
        const respuesta = await ApiService(URL_API).post(`/admin/contratos/reclutamiento-y-seleccion/pendientes-aprobar-filtro`, data);
        return respuesta.data;
    },

    async rechazarContrato(data) {
        const respuesta = await ApiService(URL_API).post(`/admin/contratos/reclutamiento-y-seleccion/rechazar`, data);
        return respuesta.data;
    },
};
export { BandejaAprobacionReclutamientoSeleccionService };
