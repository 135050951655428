import React, { useEffect, useState } from "react";
import { Modal, Form, Select } from "antd";
import { empresasAgregadasAtom, empresaSeleccionadaAtom, empresasEnviadasAtom, visibleModalRolAtom } from "../../UsuarioCrearStore";
import { useAtom } from "jotai";
import RolService from "services/RolService";

const { Option } = Select;

const ModalRol = () => {
  const [frmRol] = Form.useForm();
  const [visible, setVisible] = useAtom(visibleModalRolAtom);
  const [empresaSeleccionada, setEmpresaSeleccionada] = useAtom(empresaSeleccionadaAtom);
  const [empresasEnviadas, setEmpresasEnviadas] = useAtom(empresasEnviadasAtom);
  const [empresasAgregadas, setEmpresasAgregadas] = useAtom(empresasAgregadasAtom);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const cargarDatosIniciales = async () => {
      if (visible) {
        const roles = await RolService.listar(empresaSeleccionada.id, 0);
        setRoles(roles);
      }
    };
    cargarDatosIniciales();
  }, [visible]);

  const onClickBtnCancelar = () => {
    setVisible(false);
  };

  const onClickBtnGuardar = () => {
    let indexEmpresas = empresasEnviadas.findIndex((item) => item?.id === empresaSeleccionada?.id);
    const _empresasEnviadas = [...empresasEnviadas];
    const _empresasAgregadas = [...empresasAgregadas];

    const roles_id = frmRol.getFieldValue("roles").map((rolSeleccionado) => {
      return roles.find((rol) => rol.nombre === rolSeleccionado).id;
    });

    _empresasEnviadas[indexEmpresas] = { ..._empresasEnviadas[indexEmpresas], rolesId: roles_id };
    _empresasAgregadas[indexEmpresas] = { ..._empresasEnviadas[indexEmpresas], roles: frmRol.getFieldValue("roles") };
    setEmpresasEnviadas(_empresasEnviadas);
    setEmpresasAgregadas(_empresasAgregadas);
    setRoles([]);
    setVisible(false);
  };

  return (
    <Modal title={<div>Modificar Roles</div>} visible={visible} onCancel={onClickBtnCancelar} onOk={onClickBtnGuardar}>
      <Form form={frmRol} initialValues={{ roles: empresaSeleccionada?.roles != undefined ? empresaSeleccionada.roles : undefined }}>
        <Form.Item label="Roles" name="roles">
          <Select placeholder="Seleccione los roles" mode="multiple">
            {roles.map((roles) => (
              <Option key={roles.id} value={roles.nombre}>
                {roles.nombre}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalRol;
