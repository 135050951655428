import React, { useState, useEffect } from "react";
import { Modal, Button, Switch, Typography, Space, Row, Col, Card, Drawer, Divider } from "antd";
import {
  FileTextOutlined, UserOutlined, CalendarOutlined
} from "@ant-design/icons";
//import PDFViewer from "pdf-viewer-reactjs";
import { PDFObject } from "react-pdfobject";

const { Text } = Typography;
export function ModalPDF({ ruta, visible, handleOnOkModal, documentoNombre, fechaFirma, representanteLegal, nombreColaborador, fechaFirmaColaborador, estadoDetalleEnvio }) {
  const [informacion, setInformacion] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  if (ruta === "") {
    return null;
  }
  // function handleOnChangeMasInformacion(checked){
  //   setInformacion(checked);

  // }
  // function cerrarModal(){
  //   setInformacion(false);
  // }
  function MostarDrawer() {
    setVisibleDrawer(true);
  };
  function cerraDrawer() {
    setVisibleDrawer(false);
  };

  return (
    <Modal
      // title={
      //   <Row>
      //     <Col span={24}>
      //       <Space>
      //         <Button
      //           icon={<DownloadOutlined />}
      //           //onClick={handleOnClickBtnDescargar}
      //         >
      //           Descargar
      //         </Button>
      //         <Button icon={<PrinterOutlined />}>Imprimir</Button>
      //       </Space>
      //     </Col>
      //   </Row>
      // }


      title={estadoDetalleEnvio === 0 || estadoDetalleEnvio === 6 ? (<Col style={{ height: "20px" }}></Col>) : (<Space>
        <Button type="primary" shape="round" onClick={MostarDrawer}>Más Información</Button>
      </Space>)
      }

      visible={visible}
      onCancel={handleOnOkModal}
      style={{
        top: 20,
        bottom: 20,
      }}
      bodyStyle={{ padding: "0px", height: "calc(100vh - 50px)" }}
      footer={null}
      width="96vw"
      height="96vh"
    //  afterClose={cerrarModal}
    //  destroyOnClose={true}
    >
      {/* <object
        data={`${ruta}`}
        type="application/pdf"
        height="100%"
        width="100%"
      /> */}
      {/* {informacion?(<Row gutter={15}>
                      <Col span={12}>
                      <PDFObject url={ruta} width={"48vw"} height={"calc(100vh - 50px)"} />
                      </Col>
                      <Col span={12}>      
                      <Card
                        title="Información"
                        style={{ height: "98%", width: "98%" }}
                        bordered={true}
                       > 
                      </Card>
                      </Col>
                    </Row>)
         :(<PDFObject url={ruta} width={"96vw"} height={"calc(100vh - 50px)"} />)} */}
      <PDFObject url={ruta} width={"96vw"} height={"calc(100vh - 50px)"} />
      <Drawer
        // title="Datos del Documento"
        placement="right"
        //closable={false}
        onClose={cerraDrawer}
        visible={visibleDrawer}
        width={450}
      >
        <p style={{ fontSize: "16px" }}>Datos del Documento</p>
        <Divider />
        <p style={{ fontSize: "15px" }} ><FileTextOutlined style={{ fontSize: "20px", color: "#1678c1" }} /> {documentoNombre} </p>
        <p style={{ fontSize: "15px" }} ><UserOutlined style={{ fontSize: "20px", color: "#1678c1" }} /> Firmado por representante legal {representanteLegal}</p>
        <p style={{ fontSize: "15px" }} ><CalendarOutlined style={{ fontSize: "20px", color: "#1678c1" }} /> Firma realizada el {fechaFirma} </p>
        <br />
        {estadoDetalleEnvio === 8 && (
          <React.Fragment><p style={{ fontSize: "16px" }}>Datos del Colaborador</p>
            <Divider />
            <p style={{ fontSize: "15px" }} ><UserOutlined style={{ fontSize: "20px", color: "#1678c1" }} /> {nombreColaborador}</p>
            <p style={{ fontSize: "15px" }} ><CalendarOutlined style={{ fontSize: "20px", color: "#1678c1" }} />  Firma realizada el {fechaFirmaColaborador} </p>
          </React.Fragment>)}

      </Drawer>
    </Modal>

  );
}
