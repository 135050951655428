import React from "react";
import { Container } from "components/Container";
import {TablaSubsede} from "./tabla-subsede";
import {Botones} from "./botones";
import {UnorderedListOutlined} from "@ant-design/icons";

const ListarSubsedePage = () =>{
    return(

        <Container icono={<UnorderedListOutlined />} titulo="Subcentro de costo" botonExtra={<Botones/>}  >
             <TablaSubsede/>             
        </Container>
    )

}
export default ListarSubsedePage;