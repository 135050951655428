import { BaseService } from "./BaseService";
import qs from "qs";
export const VacacionesServiceAnterior = {
  async listar(numeroDocumentoIdentidadUsuario) {
    const respuesta = await BaseService.get("/vacaciones", {
      params: {
        numeroDocumentoIdentidadUsuario: numeroDocumentoIdentidadUsuario,
      },
    });
    return respuesta.data;
  },
  async aprobar(solicitudVacacionesId) {
    const respuesta = await BaseService.get("/vacaciones/aprobarSolicitudVacaciones", {
      params: {
        solicitudVacacionesId: solicitudVacacionesId,
      },
    });
    return respuesta.data;
  },
  async rechazar(formRechazo) {
    const respuesta = await BaseService.post("/vacaciones/rechazarSolicitudVacaciones", formRechazo);
    return respuesta.data;
  },
  async listarSeguimiento() {
    const respuesta = await BaseService.get("/vacaciones/seguimiento");
    return respuesta.data;
  },
  async listarSeguimientoPaginado(
    empresaId,
    colaborador,
    documentoIdentidad,
    codigoColaborador,
    fechaInicio,
    fechaFin,
    tipoAbsentismo,
    aprobadorJefe,
    estadoAbsentismo,
    pagina
  ) {
    const respuesta = await BaseService.get("/vacaciones/ListarVacacionesSeguimiento", {
      params: {
        empresaId: empresaId,
        colaborador: colaborador,
        documentoIdentidad: documentoIdentidad,
        codigoColaborador: codigoColaborador,
        fechaInicio: fechaInicio,
        fechaFin: fechaFin,
        tipoAbsentismo: tipoAbsentismo,
        aprobadorJefe: aprobadorJefe,
        estadoAbsentismo: estadoAbsentismo,
        pagina: pagina,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    return respuesta.data;
  },
};
