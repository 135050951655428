import React, { useState } from "react";
import { Button, Space } from "antd";
import { Loading } from "../loading/Loading";
import { useModalClaveCertificadoStore, useModalFirmarStore } from "hooks/firma-documento-generico";
import { Document, Page } from "react-pdf"
import { StepsFirmaDocumento } from "enums/StepsFirmaDocumento"

const StepFirmarDocumento = () => {

    const loading = useModalFirmarStore(state => state.loading);
    const urlPdf = useModalFirmarStore(state => state.urlPdf);
    const numeroPagina = useModalFirmarStore(state => state.numeroPagina);
    const setPagina = useModalFirmarStore(state => state.setPagina);
    const setNumeroPagina = useModalFirmarStore(state => state.setNumeroPagina);
    const irAlPaso = useModalFirmarStore(state => state.irAlPaso);
    const finalizarLoading = useModalFirmarStore(state => state.finalizarLoading);

    const mostrarModalClaveCertificado = useModalClaveCertificadoStore(state => state.mostrarModalClaveCertificado);

    const [totalPaginas, setTotalPaginas] = useState(undefined);

    async function onLoadSuccess(successEvent) {
        const page = await successEvent.getPage(numeroPagina);
        setNumeroPagina(numeroPagina);
        setPagina(page.view[3], page.view[2]);
        setTotalPaginas(successEvent.numPages);
        finalizarLoading();
    }

    return (
        <React.Fragment>

            { loading && <Loading />}

            <div style={{ height: '80px' }}>
                <div style={{ marginTop: '10px', marginBottom: '10px', textAlign: 'center' }}>
                    <Space>
                        <Button onClick={() => irAlPaso(StepsFirmaDocumento.CERTIFICADO_DIGITAL)}>
                            {`< Atras`}
                        </Button>

                        <Button type="primary" onClick={mostrarModalClaveCertificado}>
                            {`Firmar`}
                        </Button>
                    </Space>
                </div>
            </div>

            <Document
                file={urlPdf}
                onLoadSuccess={onLoadSuccess}
                loading={null}
            >

                {
                    Array.from(
                        new Array(totalPaginas),
                        (el, index) => (
                            <Page
                                className="pagina-react-pdf"
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                scale={1.5}
                                loading={""}
                            />
                        ),
                    )
                }
            </Document>
        </React.Fragment>
    );
}

export default StepFirmarDocumento;