import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Alert,
  Typography,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import { CheckOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import GestionarClaveService from "../../../services/GestionarClaveService";
import { LoginService } from "services/LoginService";
const host = window.location.host; // -> http://ingenioti.smart.com:3000/
const subdominio = host.split(".")[0]; // -> [ingenioti][smart][com:3000]
const SEPARADOR_DOMINIO = "__";
const search = window.location.search;
const params = new URLSearchParams(search);
const nc = params.get("nc"); //nombreCompleto
// const td = params.get("td");
// const nd = params.get("nd");
//const lg=params.get("lg"); //login
const id = params.get("id"); //id
const { Title, Text } = Typography;

export function GenerarClavePage() {
  let history = useHistory();
  const [usuario, setUsuario] = useState({});
  const [mensaje, setMensaje] = useState("");
  // const [urlFondo, setUrlFondo] = useState("");
  //const [urlLogotipo, setUrlLogotipo] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function cargarDatos() {
      // const usuario = await GestionarClaveService.validarInformacion(
      //   nc,
      //   td,
      //   nd
      // );
      const usuario = await GestionarClaveService.validarInformacion(
        nc,
        // lg,
        id
      );
      //console.log("usuario",usuario);
      setUsuario(usuario);
      //const nombre = colaborador.nombreCompleto;
      setMensaje(
        <div>
          <Space>
            <span style={{ color: "#1890ff", fontSize: "17px" }}>
              Bienvenid(@):{" "}
            </span>

            <Text
              style={{
                fontSize: "15px",
                textTransform: "uppercase",
                color: "#002766",
              }}
            >
              {usuario.nombreCompleto}
            </Text>
            {/* <Text
              style={{
                fontSize: "14px",
                textTransform: "uppercase",
                color: "#002766",
              }}
            >
              {usuario.tipoDocumentoIdentidad}
              {" - "}
              {usuario.numeroDocumentoIdentidad}
             
            </Text> */}
          </Space>
        </div>
      );
    }

    cargarDatos();
  }, []);
  if (usuario.solicitoCambiarClave===false || usuario.solicitoCambiarClave===null ) {
    redireccionarPaginaLogin();
  }
  function redireccionarPaginaLogin() {
    history.push("/login");
  }
  async function handleOnFinish(formulario) {
    try {
      setLoading(true);
      const usuario1 = await GestionarClaveService.generarClave(
        subdominio,
        // usuario.numeroDocumentoIdentidad,
        usuario.id,
        formulario.clave
      );

      //personaBuscada.clave = formulario.clave;
      //await PersonaService.actualizarClave(personaBuscada);*/
      Modal.info({
        title: "Clave Cambiada",
        //icon: <ExclamationCircleOutlined />,
        content:
          "Tu clave ha sido cambiada exitosamente. Por favor inicia sesión con tu usuario y clave nueva.",
        okText: "Aceptar",
        //cancelText: '',
        onOk() {
          history.push("/login");
        },
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  return (
    <Row className="container-login">
      <Col span={16} className="container-login__imagen"></Col>
      <Col span={8} className="container-login__formulario">
        <div className="login">
          <div className="logotipo">
            <img
              className="imagen-derecha"
              src="https://storage.googleapis.com/milegajodigital.com/admin/publico/logotipo.png"
              alt="Logotipo"
            />
          </div>

          <div className="texto">
            <Typography.Title level={3}>Cambiar Clave</Typography.Title>
          </div>
          <Alert
            style={{
              marginBottom: 24,
            }}
            message={mensaje}
            type="info"
          />

          <Form
            layout="vertical"
            name="frmLogin"
            className="login-form"
            onFinish={handleOnFinish}
          >
            <div style={{ textAlign: "center", fontSize: "16px" }}>
              <Text>Ingresa tu nueva clave.</Text>
            </div>
            <FormItem
              label="Clave"
              name="clave"
              rules={[
                {
                  required: true,
                  message: "Ingrese su clave",
                },
              ]}
            >
              <Input.Password />
            </FormItem>
            <FormItem
              label="Repetir Clave"
              name="claveR"
              dependencies={["clave"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Ingrese nuevamente su clave",
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue("clave") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject("Las claves deben ser iguales!");
                  },
                }),
              ]}
            >
              <Input.Password />
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                icon={<CheckOutlined />}
                htmlType="submit"
                size="large"
                style={{ width: "100%" }}
                loading={loading}
              >
                Generar Clave
              </Button>
            </FormItem>
          </Form>
        </div>
      </Col>
    </Row>
  );
}
