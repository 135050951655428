import React, { useState } from "react";
import { ContainerCrear } from "../../../components/ContainerCrear";
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Space,
  Select,
  notification,
} from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { useHistory } from "react-router-dom";
import TipoDocumentoService from "../../../services/TipoDocumentoService";

const { Option } = Select;
export function LegajoCrearDocumento() {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [formularioCrear] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formatoArchivoSeleccionado, setFormatoArchivoSeleccionado] =
    useState(false);

  let history = useHistory();

  async function handleOnFinish(formulario) {
    try {
      setLoading(true);
      formulario.organizacionId = getUsuarioAutenticado().organizacionId;
      formulario.empresaId = getUsuarioAutenticado().empresaSeleccionada.id;
      formulario.usuarioLogin = getUsuarioAutenticado().login;
      formulario.usuarioNumeroDocumentoIdentidad =
        getUsuarioAutenticado().numeroDocumentoIdentidad;
      formulario.usuarioNombreCompleto = getUsuarioAutenticado().nombreCompleto;
      formulario.usuarioCorreoElectronico =
        getUsuarioAutenticado().correoElectronico;
      formulario.eliminado = false;
      //por mientras como solo cargara el colaborador sino se tendria que mostrar el combo para el
      //analista o coloborador
      formulario.responsableCargaDocumento = "COLABORADOR";
      //Por mientras hastq ue se habilite otros casos deberan ser otras ctegorias
      formulario.categoriaDocumento = {
        id: "c0779f7a-d8d8-433b-9347-08ac511a1fb9",
        nombre: "DOCUMENTO PERSONAL",
      };

      let datos = await TipoDocumentoService.insertar(formulario);
      // console.log("datosss ", datos.existe);

      if (!datos.existe) {
        notification.success({
          message: "Operación exitosa",
          description: "Se registró el documento correctamente.",
        });
        setLoading(false);
        history.push("/legajos/documento");
      } else {
        notification.error({
          message: "Documento duplicado",
          description: "No se puede registrar un Documento que ya existe",
        });
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  function handleOnClickCancelar() {
    history.push("/legajos/documento");
  }

  function handleOnChangeCategoria(value, option) {
    formularioCrear.setFieldsValue({
      categoriaDocumento: { nombre: option.children },
    });
  }

  function handleOnChangeFormato(value, option) {
    formularioCrear.setFieldsValue({
      formato: { nombre: option.children },
    });
    if (option.children === "IMAGEN") {
      setFormatoArchivoSeleccionado(true);
    } else {
      setFormatoArchivoSeleccionado(false);
    }
  }

  return (
    <ContainerCrear
      titulo="Crear Documento"
      descripcion="Crear un documento dentro de tu organización facilitara la carga de diversos archivos."
      colSpan={12}
    >
      <Form
        form={formularioCrear}
        layout="vertical"
        size="large"
        onFinish={handleOnFinish}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="Nombre"
              name="nombre"
              rules={[
                {
                  required: true,
                  message: "Escribe el nombre del documento",
                },
              ]}
            >
              <Input
                placeholder="Nombre del documento"
                maxLength="50"
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item label="Descripción" name="descripcion">
              <Input
                placeholder="Ingrese una descripción"
                maxLength="50"
                autoComplete="off"
                style={{ textTransform: "uppercase" }}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row>
          <Col span={24}>
            <Form.Item
              label="Categoría"
              name={["categoriaDocumento", "id"]}
              rules={[
                {
                  required: true,
                  message: "Seleccione la categoría del documento",
                },
              ]}
            >
              <Select
                placeholder="CATEGORIA"
                allowClear
                onChange={handleOnChangeCategoria}
              >
                <Option value="1e2cf094-d086-4626-a4bb-ea16170ca18d">
                  DOCUMENTO LABORAL
                </Option>
                <Option value="c0779f7a-d8d8-433b-9347-08ac511a1fb9">
                  DOCUMENTO PERSONAL
                </Option>
              </Select>
            </Form.Item>
            <Form.Item name={["categoriaDocumento", "nombre"]} noStyle>
              <Input type="hidden" />
            </Form.Item>
          </Col>
        </Row> */}
        {/* <Row>
          <Col span={24}>
            <Form.Item
              label="¿Quien carga el documento?"
              name="responsableCargaDocumento"
              rules={[
                {
                  required: true,
                  message: "Seleccione quien debe cargar el documento",
                },
              ]}
            >
              <Select placeholder="RESPONSABLE DE CARGA" allowClear>
                <Option value="EMPRESA">EMPRESA</Option>
                <Option value="COLABORADOR">COLABORADOR</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row> */}
        <Row>
          <Col span={24}>
            <Form.Item
              label="Formato de archivo"
              name={["formato", "id"]}
              rules={[
                {
                  required: true,
                  message: "Seleccione el formato",
                },
              ]}
            >
              <Select
                placeholder="FORMATO"
                allowClear
                onChange={handleOnChangeFormato}
              >
                {/* <Option value="f3f849b5-6919-4479-9cf2-0f0e30b1c1c6">
                  IMAGEN
                </Option> */}
                <Option value="ebaf60aa-1c35-4362-b488-84dd4c387697">
                  PDF
                </Option>
              </Select>
            </Form.Item>
            <Form.Item name={["formato", "nombre"]} noStyle>
              <Input type="hidden" />
            </Form.Item>
          </Col>
        </Row>
        {formatoArchivoSeleccionado && (
          <Row>
            <Col span={24}>
              <Form.Item
                label="N° Archivos a subir"
                name="numeroArchivos"
                rules={[{ required: true, message: "Ingrese n° de archivos" }]}
              >
                <Input
                  placeholder="N° de archivos"
                  maxLength="4"
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {/* <Row>
          <Col span={24}>
            <Form.Item
              label="Activar descripción"
              name="activarDescripcion"
              rules={[
                {
                  required: true,
                  message: "Seleccione si desea o no activar la descripción",
                },
              ]}
            >
              <Select placeholder="Descripción" allowClear>
                <Option value="SI">Si</Option>
                <Option value="NO">No</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row> */}
        <Row justify="center">
          <Space>
            <Col>
              <Form.Item style={{ textAlign: "center" }}>
                <Button type="default" onClick={handleOnClickCancelar}>
                  Cancelar
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  loading={loading}
                  htmlType="submit"
                >
                  Crear Documento
                </Button>
              </Form.Item>
            </Col>
          </Space>
        </Row>
      </Form>
    </ContainerCrear>
  );
}
