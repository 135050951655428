import { BaseService } from "./BaseService";

const PerfilSolicitudPersonalService = {
    async insertar(perfil){
        const respuesta= await BaseService.post("/perfilSolicitudPersonal",perfil);
        return respuesta.data;
    },
    async listar(){
        const respuesta = await BaseService.get("/perfilSolicitudPersonal");
        return respuesta.data;
    },
    async eliminar(perfilId){
        const respuesta = await BaseService.delete(`/perfilSolicitudPersonal/${perfilId}`);
        return respuesta.data;
    }


}
export default PerfilSolicitudPersonalService;