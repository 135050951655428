import { atom } from 'jotai'

export const envio = atom(undefined);

export const formularios = atom([]);

export const formulariosSeleccionados = atom([]);

export const visibleModalActualizarCorreo = atom(false);

export const formularioSeleccionado = atom(undefined);