import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";
import { ContainerListarDetalle } from "../../../components/ContainerListarDetalle";
import {
  FolderOpenOutlined,
  SearchOutlined,
  ExclamationCircleOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import {
  Table,
  Space,
  Menu,
  Button,
  Input,
  Select,
  notification,
  Modal,
  Form,
  Tag,
  Tabs,
} from "antd";
import { ModalPDF } from "../../../components/PDFViewer";
import DetalleEnvioService from "../../../services/DetalleEnvioService";
import LegajoService from "../../../services/LegajoService";
import Highlighter from "react-highlight-words";
const { Option } = Select;
const { confirm } = Modal;
export function DocumentoPersonalListarDetallePage() {
  const { TabPane } = Tabs;
  const [detalleEnvioCargados, setDetalleEnvioCargados] = useState([]);
  const [detalleEnvioNoCargados, setDetalleEnvioNoCargados] = useState([]);
  const { getDatosEnvio } = useContext(SecurityContext);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [defaultValue, setDefaultValue] = useState("default");
  const [filaSeleccionada, setFilaSeleccionada] = useState("");
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [visible, setVisible] = useState("");
  const [habilitado, setHabilitado] = useState(true);
  const [keyTab, setKeyTab] = useState();

  let envioId = getDatosEnvio().id;
  let history = useHistory();
  useEffect(() => {
    async function cargarDatos() {
      const detalleEnvioNoCargados = await DetalleEnvioService.listar(
        envioId,
        3
      );
      setDetalleEnvioNoCargados(detalleEnvioNoCargados);
      const detalleEnvioCargados = await DetalleEnvioService.listar(envioId, 4);
      setDetalleEnvioCargados(detalleEnvioCargados);
    }

    cargarDatos();
  }, []);

  function handleOnClickVolverSeguimiento() {
    history.push("/documento-laboral");
  }
  var adicionarColumnasFiltro = function getColumns(
    dataIndex,
    placeholder,
    propiedad
  ) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={
              placeholder !== undefined
                ? placeholder
                : `Buscar por ${dataIndex}`
            }
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        propiedad === undefined
          ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
          : record[dataIndex][propiedad]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={
              propiedad === undefined
                ? text.toString()
                : text[propiedad].toString()
            }
          />
        ) : propiedad === undefined ? (
          text
        ) : (
              text[propiedad]
            ),
    };
  };

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText("");
  }
  async function handleOnClickVerDocumento(detalleEnvio) {
    try {
      setLoading(true);
      setFilaSeleccionada(detalleEnvio.id);
      const formData = new FormData();
      formData.append("detalleEnvioId", detalleEnvio.id);
      formData.append("estadoProcesoId", detalleEnvio.estadoProcesoId);

      let data = await DetalleEnvioService.verPDF(formData);
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/pdf" })
      );
      // setUrl(data.urlEncriptada);
      setUrl(url);
      setVisible(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  function handleOnClickAccionesCargados(value, detalleEnvio) {
    if (value === "ver") {
      handleOnClickVerDocumento(detalleEnvio);
    } else if (value === "inhabilitar") {
      showDeleteConfirm(detalleEnvio.id);
    }
  }
  function handleOnClickAccionesNoCargados(value, detalleEnvioId) {
    if (value === "inhabilitar") {
      showDeleteConfirmNoCargado(detalleEnvioId);
    }
  }
  function showDeleteConfirm(detalleEnvioId) {
    confirm({
      title: "Estas seguro de inhabilitar el registro seleccionado?",
      icon: <ExclamationCircleOutlined />,
      okText: "si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleOnClickInhabilitarDetalleEnvio(detalleEnvioId);
      },
    });
  }
  function showDeleteConfirmNoCargado(detalleEnvioId) {
    confirm({
      title: "Estas seguro de inhabilitar el registro seleccionado?",
      icon: <ExclamationCircleOutlined />,
      okText: "si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleOnClickInhabilitarDetalleEnvioNoCargado(detalleEnvioId);
      },
    });
  }
  async function handleOnClickInhabilitarDetalleEnvio(detalleEnvioId) {
    try {
      setFilaSeleccionada(detalleEnvioId);
      await DetalleEnvioService.inhabilitar(detalleEnvioId);

      setDetalleEnvioCargados(
        detalleEnvioCargados.map((detalleEnvioCargado) => {
          if (detalleEnvioCargado.id === detalleEnvioId) {
            return { ...detalleEnvioCargado, eliminado: true };
          }
          return detalleEnvioCargado;
        })
      );

      notification.success({
        message: "Operación exitosa",
        description: "Se inhabilito el registro correctamente.",
      });
    } catch (error) {
      notification.error({
        message: "Operación Fallida",
        description: "No se pudo inhabilitar el registro correctamente.",
      });
    }
  }
  async function handleOnClickInhabilitarDetalleEnvioNoCargado(detalleEnvioId) {
    try {
      setFilaSeleccionada(detalleEnvioId);
      await DetalleEnvioService.inhabilitar(detalleEnvioId);
      setDetalleEnvioNoCargados(
        detalleEnvioNoCargados.map((detalleEnvioNoCargado) => {
          if (detalleEnvioNoCargado.id === detalleEnvioId) {
            return { ...detalleEnvioNoCargado, eliminado: true };
          }
          return detalleEnvioNoCargado;
        })
      );
      notification.success({
        message: "Operación exitosa",
        description: "Se inhabilito el registro correctamente.",
      });
    } catch (error) {
      notification.error({
        message: "Operación Fallida",
        description: "No se pudo inhabilitar el registro correctamente.",
      });
    }
  }
  async function handleOnClickHabilitarDetalleEnvio(detalleEnvioId) {
    try {
      setFilaSeleccionada(detalleEnvioId);
      await DetalleEnvioService.habilitar(detalleEnvioId);

      setDetalleEnvioCargados(
        detalleEnvioCargados.map((detalleEnvioCargado) => {
          if (detalleEnvioCargado.id === detalleEnvioId) {
            return { ...detalleEnvioCargado, eliminado: false };
          }
          return detalleEnvioCargado;
        })
      );

      notification.success({
        message: "Operación exitosa",
        description: "Se habilitado el registro.",
      });
    } catch (error) {
      notification.error({
        message: "Operación Fallida",
        description: "No se habilitó el registro.",
      });
    }
  }
  async function handleOnClickHabilitarDetalleEnvioNoCargado(detalleEnvioId) {
    try {
      setFilaSeleccionada(detalleEnvioId);
      await DetalleEnvioService.habilitar(detalleEnvioId);
      setDetalleEnvioNoCargados(
        detalleEnvioNoCargados.map((detalleEnvioNoCargado) => {
          if (detalleEnvioNoCargado.id === detalleEnvioId) {
            return { ...detalleEnvioNoCargado, eliminado: false };
          }
          return detalleEnvioNoCargado;
        })
      );
      notification.success({
        message: "Operación exitosa",
        description: "Se habilitado el registro.",
      });
    } catch (error) {
      notification.error({
        message: "Operación Fallida",
        description: "No se habilitó el registro.",
      });
    }
  }

  const columnasCargados = [
    {
      title: "Colaborador",
      dataIndex: "colaborador",
      align: "center",
      ...adicionarColumnasFiltro(
        "colaborador",
        "Buscar por Colaborador",
        "nombreCompleto"
      ),
    },

    {
      title: "Estado",
      //dataIndex: "estadoProcesoNombre",
      align: "center",
      ...adicionarColumnasFiltro("estadoProcesoNombre"),
      render: (fila) => (
        <Tag color="success" style={{ textTransform: "uppercase" }}>
          {fila.estadoProcesoNombre}
        </Tag>
      ),
    },
    {
      title: "Acciones",
      align: "center",
      render: (fila) => (
        <React.Fragment>
          {fila.eliminado ? (
            <a onClick={() => handleOnClickHabilitarDetalleEnvio(fila.id)}>
              Habilitar
            </a>
          ) : (
              <Select
                placeholder="Seleccione la Acción"
                value={defaultValue}
                style={{ width: 200 }}
                onSelect={(value) => handleOnClickAccionesCargados(value, fila)}
                loading={filaSeleccionada === fila.id && loading}
                allowClear
              >
                <Option value="default">Seleccione la Acción </Option>
                <Option value="ver">Ver </Option>
                <Option value="inhabilitar">Inhabilitar </Option>
              </Select>
            )}
        </React.Fragment>
      ),
    },
  ];
  const columnasNoCargados = [
    {
      title: "Colaborador",
      dataIndex: "colaborador",
      align: "center",
      ...adicionarColumnasFiltro(
        "colaborador",
        "Buscar por Colaborador",
        "nombreCompleto"
      ),
    },

    {
      title: "Estado",
      // dataIndex: "estadoProcesoNombre",
      align: "center",
      ...adicionarColumnasFiltro("estadoProcesoNombre"),
      render: (fila) => (
        <Tag color="warning" style={{ textTransform: "uppercase" }}>
          {fila.estadoProcesoNombre}
        </Tag>
      ),
    },
    {
      title: "Acciones",
      align: "center",
      render: (fila) => (
        <React.Fragment>
          {fila.eliminado ? (
            <a
              onClick={() =>
                handleOnClickHabilitarDetalleEnvioNoCargado(fila.id)
              }
            >
              Habilitar
            </a>
          ) : (
              <Select
                placeholder="Seleccione la Acción"
                value={defaultValue}
                style={{ width: 200 }}
                onSelect={(value) =>
                  handleOnClickAccionesNoCargados(value, fila.id)
                }
                loading={filaSeleccionada === fila.id && loading}
                allowClear
              >
                <Option value="default">Seleccione la Acción </Option>
                <Option value="inhabilitar">Inhabilitar </Option>
              </Select>
            )}
        </React.Fragment>
      ),
    },
  ];
  function handleOnOkModal() {
    setVisible(false);
  }
  function handleOnChangeTab(key) {
    setKeyTab(key);
  }
  return (
    <ContainerListarDetalle
      icono={<FolderOpenOutlined style={{ fontSize: "50px" }} />}
      titulo={getDatosEnvio().documentoNombre}
      descripcion={getDatosEnvio().descripcion}
    >
      <ModalPDF
        ruta={url}
        visible={visible}
        handleOnOkModal={handleOnOkModal}
      />
      <Tabs defaultActiveKey="1" onChange={handleOnChangeTab}>
        <TabPane tab="Cargados" key="1">
          <Table
            //components={components}
            rowKey={(detalleEnvio) => detalleEnvio.id}
            columns={columnasCargados}
            dataSource={detalleEnvioCargados}
            //rowClassName="editable-row"
            pagination={{
              pageSize: [10],
            }}
            rowClassName={(detalleEnvio) =>
              detalleEnvio.eliminado ? "detalle-envio_registro-eliminado" : ""
            }
          /* rowSelection={{
        type: "checkbox",
        columnWidth: 15,
        ...rowSelection,
      }}*/
          />
        </TabPane>
        <TabPane tab="No cargados" key="2">
          <Table
            rowKey={(detalleEnvio) => detalleEnvio.id}
            columns={columnasNoCargados}
            dataSource={detalleEnvioNoCargados}
            pagination={{
              pageSize: [10],
            }}
            rowClassName={(detalleEnvio) =>
              detalleEnvio.eliminado ? "detalle-envio_registro-eliminado" : ""
            }
          />
        </TabPane>
      </Tabs>
      <Button
        type="primary"
        icon={<ArrowLeftOutlined style={{ fontSize: "20px" }} />}
        onClick={handleOnClickVolverSeguimiento}
      ></Button>
    </ContainerListarDetalle>
  );
}
