import { useAtom } from "jotai";
import { plantillaIdSeleccionadaAtom } from "../ProcesosCrearStore";

const useSeleccionarPlantilla = () => {
  const [, setPlantillaId] = useAtom(plantillaIdSeleccionadaAtom);

  const seleccionarPlantilla = (plantillaId) => {
    //console.log("plantillaId: ", plantillaId);
    setPlantillaId(plantillaId);
  };

  return { seleccionarPlantilla };
};

export default useSeleccionarPlantilla;
