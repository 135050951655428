import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";


const Botones = () => {

    let history = useHistory();

    const BotonCrearFormulario = () => {

        const onClickBtnCrearFormulario = () => {
            history.push("/configuracion/formularios/crear");
        }

        return (
            <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={onClickBtnCrearFormulario}
            >
                Crear Tipo Documento
            </Button>
        )
    }

    return (
        <React.Fragment>
            <BotonCrearFormulario />
        </React.Fragment>
    )
};

export default Botones;