import React, { useContext, useEffect, useState } from "react";
import { Container } from "components/Container";
import { useHistory } from "react-router";
import * as atoms from "./CrearSeccionStore";
import { useAtom } from "jotai";
import { useModalConfirmar } from "store/global";
import { ModalConfirmar } from "components/Modal/ModalConfirmar";
import {SeccionRegistro} from "./seccion-registro";
import SeccionService from "services/SeccionService";
import { notification } from "antd";

const CrearSeccionPage=()=>{
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [seccionAtom]=useAtom(atoms.seccion);
    const cerrarModalConfirmacion = useModalConfirmar(state => state.cerrarModal);

    const onClickRetornarListarSecciones=()=>{
        history.push("/seccion");
    }
    const onClickBtnCancelarModal=()=>{
        cerrarModalConfirmacion();
    }
    const onClickBtnConfirmarModal = async ()=>{
        try {
            setLoading(true);
            const _seccion= await SeccionService.insertar(seccionAtom);
            setLoading(false);
            cerrarModalConfirmacion();
            history.push("/seccion");
            
        } catch (error) {
            console.error(error);
            cerrarModalConfirmacion();
            notification.error({
                message:"Error",
                description:error.response.data.mensaje});
            
        }finally{
            setLoading(false);
        }

    }
    return(
        <Container titulo="Crear Sección" onClickRetornar={onClickRetornarListarSecciones}>
         <SeccionRegistro/>
         <ModalConfirmar
                loading={loading}
                mensaje="¿Está seguro de guardar la Sección? "
                onClickBtnCancelar={onClickBtnCancelarModal}
                onClickBtnConfirmar={onClickBtnConfirmarModal} />
        </Container>
    )

}
export default CrearSeccionPage;