import React from "react";
import { FilePdfOutlined } from "@ant-design/icons";
import { useAtomValue } from "jotai";
import { urlPdfAtom } from "../../VisorFormularioStorage";
import styled from "@emotion/styled";

const PdfNoEspecificado = () => {
	const urlPdf = useAtomValue(urlPdfAtom);

	if (urlPdf) {
		return "";
	}

	return (
		<Container>
			<FilePdfOutlined style={{ fontSize: "40px", color: "#8e9aaf" }} />
			<span style={{ color: "#8e9aaf", fontSize: "16px", marginTop: "5px" }}>
				No ha seleccionado ningún documento
			</span>
		</Container>
	);
};

const Container = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	background: #f6f6f6;
	z-index: 1000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export default PdfNoEspecificado;
