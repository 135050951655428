import { Divider, Form, Input, Modal, message } from "antd";
import React from "react";
import {
    contratoSeleccionadoAtom,
    contratosAtom,
    visibleModalRechazarContratoAtom,
} from "../../BandejaAprobacionAdministracionPersonalStore";
import { BandejaAprobacionAdministracionPersonalService } from "services/contratos/BandejaAprobacionAdministracionPersonalService";
import { LoginService } from "services/LoginService";
import { useAtom } from "jotai";
import { QuestionCircleOutlined } from "@ant-design/icons";
import produce from "immer";

const ModalRechazar = () => {
    const [visible, setVisible] = useAtom(visibleModalRechazarContratoAtom);
    const [contratoSeleccionado] = useAtom(contratoSeleccionadoAtom);
    const [contratos, setContratos] = useAtom(contratosAtom);
    const [form] = Form.useForm();

    const onClickBtnRechazar = async () => {
        form.validateFields()
            .then(async (values) => {
                try {
                    await BandejaAprobacionAdministracionPersonalService.rechazarContrato({
                        seguimientoContratoId: contratoSeleccionado.id,
                        usuarioId: LoginService.getUsuarioAutenticado().id,
                        ...values,
                    });
                    setContratos(
                        produce((draft) => {
                            const index = draft.findIndex((contrato) => contrato.id === contratoSeleccionado.id);
                            draft.splice(index, 1);
                        })
                    );
                    message.success("El contrato fue rechazado.");
                    setVisible(false);
                } catch (error) {
                    message.error("No se pudo realiar la operación requerida. Intente nuevamente!");
                }
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    const onClickBtnCancelar = () => {
        setVisible(false);
        form.setFieldsValue({
            motivoRechazo: "",
        });
    };

    return (
        <Modal
            title="Rechazar Contrato"
            open={visible}
            onOk={onClickBtnRechazar}
            onCancel={onClickBtnCancelar}
            okText="Rechazar"
            destroyOnClose={true}
        >
            <div style={{ textAlign: "center" }}>
                <QuestionCircleOutlined style={{ fontSize: "40px", color: "#ebc334" }} />
            </div>
            <div style={{ textAlign: "center", fontSize: "18px" }}>
                ¿Está seguro de rechazar el contrato seleccionado?
            </div>
            <Divider />
            <div>
                <Form layout="vertical" form={form}>
                    <Form.Item label="Motivo de Rechazo" name="motivoRechazo" rules={[{ required: true }]}>
                        <Input.TextArea rows={4} />
                    </Form.Item>
                </Form>
            </div>
        </Modal>
    );
};

export default ModalRechazar;
