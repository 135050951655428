import { useState, useEffect } from "react";
import { ApiResponse } from "enums";
import { VacacionesService } from "services/VacacionesService";
import { useAtom } from "jotai";
import { tipoAusenciaSeleccionadaAtom, visibleModalCrearSolicitudAusenciaAtom } from "../../consulta-solicitudes/consultaSolicitudesStore";

const useSimularDescuentoVacaciones = () => {
	const [vacacionesSimuladasError, setVacacionesSimuladasError] = useState(undefined);
	const [vacacionesSimuladas, setVacacionesSimuladas] = useState(undefined);
	const [visibleModalCrearSolicitudAusencia] = useAtom(visibleModalCrearSolicitudAusenciaAtom);
	const [tipoAusencia] = useAtom(tipoAusenciaSeleccionadaAtom);

	useEffect(() => {
		const cargarDatos = async () => {
			if (visibleModalCrearSolicitudAusenciaAtom) {
				setVacacionesSimuladas(undefined);
			}
		};
		cargarDatos();
	}, [visibleModalCrearSolicitudAusencia]);

	const limpiarSimulacion = () => {
		setVacacionesSimuladas(undefined);
		setVacacionesSimuladasError(undefined);
	};

	const descontarDias = async (fechasSolicitadas) => {
		if (tipoAusencia.contabilizarDiasVacaciones && fechasSolicitadas) {
			const vacacionesSimuladas = await VacacionesService.simularDescontarDias({
				// fechaInicio: fechasSolicitadas[0]?.format("DD/MM/YYYY"),
				// fechaFin: fechasSolicitadas[1]?.format("DD/MM/YYYY"),
				fechaInicio: fechasSolicitadas[0]?.format("DD/MM/YYYY HH:mm:ss"),
				fechaFin: fechasSolicitadas[1]?.format("DD/MM/YYYY HH:mm:ss"),
			});
			if (vacacionesSimuladas.estado === ApiResponse.EXITO) {
				setVacacionesSimuladas(vacacionesSimuladas.data);
				setVacacionesSimuladasError(undefined);
			} else if (vacacionesSimuladas.estado === ApiResponse.ERROR) {
				setVacacionesSimuladas(undefined);
				setVacacionesSimuladasError(vacacionesSimuladas.mensaje);
			}
		} else {
			setVacacionesSimuladas(undefined);
			setVacacionesSimuladasError(undefined);
		}
	};

	return { descontarDias, limpiarSimulacion, vacacionesSimuladas, vacacionesSimuladasError };
};

export default useSimularDescuentoVacaciones;
