import { useAtom } from "jotai";
import { colaboradorAtom, envioSeleccionadoAtom, enviosAtom, onboardingsIngresoAtom } from "../../../LegajoColaboradorStore";
import { useContext, useEffect, useState } from "react";
import { OnboardingIngresoColaboradorService } from "services/OnboardingIngresoColaboradorService";
import { SecurityContext } from "context/SecurityContextProvider";
import { OnboardingColaboradorService } from "services/OnboardingColaboradorService";

const useListarOnboardingIngreso = () => {
	const [colaborador] = useAtom(colaboradorAtom);
	const [documentos, setDocumentos] = useState([]);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const [envios, setEnvios] = useAtom(enviosAtom);

	const fetchDocumentos = async (envioId) => {
		if (envioId) {
			const documentos = await OnboardingIngresoColaboradorService.listarDetalleOnboardingIngreso(
				getUsuarioAutenticado().login,
				envioId,
				colaborador.numeroDocumentoIdentidad
			);
			setDocumentos(documentos);
		}
	}

	const fetchEnvios = async () => {
		const response =
			await OnboardingColaboradorService
				.listarEnvioOnboarding(
					colaborador.numeroDocumentoIdentidad,
					null,
					null,
					null,
					null,
					null,
					null,
					getUsuarioAutenticado().login,
					0);

		setEnvios(response.envios);
	}

	return { documentos, envios, fetchDocumentos, fetchEnvios };
};

export default useListarOnboardingIngreso;
