import React, { useEffect, useContext } from "react";
import { Table } from "antd";
// import { MenuAcciones } from "./MenuAcciones";
import { useAtom } from "jotai";
import { horariosAtom } from "../../ListarHorariosStore";
import { SecurityContext } from "context/SecurityContextProvider";
import { HorarioService } from "services/asistencias/HorarioService";

const TablaHorarios = () => {
  //const [solicitudesVacaciones, setSolicitudesVacaciones] = useAtom(atoms.solicitudesVacacionesAtom);
  const [horarios, setHorarios] = useAtom(horariosAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);

  useEffect(() => {
    const CargarDatos = async () => {
      const horarios = await HorarioService.listar(getUsuarioAutenticado().numeroDocumentoIdentidad);
      console.log("horarios", horarios);
      setHorarios(horarios);
    };
    CargarDatos();
  }, []);

  const columnas = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      align: "center",
    },
    {
      title: "Descripción",
      dataIndex: "descripcion",
      align: "center",
    },
    // {
    //   title: "Acciones",
    //   align: "center",
    //   render: (text, fila) => (
    //     <MenuAcciones
    //       solicitud={fila}
    //       solicitudVacaciones={solicitudesVacaciones}
    //       setSolicitudVacaciones={setSolicitudesVacaciones}
    //     />
    //   ),
    // },
  ];

  return (
    <Table
      rowKey={(horarios) => horarios.id}
      dataSource={horarios}
      columns={columnas}
    />
  );
};
export default TablaHorarios;
