import React, { useState } from "react";
import { Container } from "components/Container";
import { ModalConfirmar } from "components/Modal/ModalConfirmar";
import { FormularioRegistro } from "./formulario-registro";
import { FormularioService } from "services/FormularioService";
import { useHistory } from "react-router";
import * as atoms from "./CrearFormulariosStore";
import { useAtom } from "jotai";
import { useModalConfirmar } from "store/global";
import { notification } from "antd";

const CrearFormulariosPage = () => {
  let history = useHistory();
  const cerrarModalConfirmacion = useModalConfirmar((state) => state.cerrarModal);
  const [formulario] = useAtom(atoms.formulario);
  const [loading, setLoading] = useState(false);

  const onClickBtnConfirmarModal = async () => {
    try {
      setLoading(true);
      await FormularioService.insertar(formulario);
      cerrarModalConfirmacion();
      history.push("/configuracion/formularios/listar");
    } catch (error) {
      cerrarModalConfirmacion();
      console.error(error);
      notification.error({
        message: "No se puede registrar el formulario",
        description: error.response.data.mensaje,
      });
    } finally {
      setLoading(false);
    }
  };

  const onClickBtnCancelarModal = () => {
    cerrarModalConfirmacion();
  };

  const onClickRetonarListarFormularios = () => {
    history.push("/configuracion/formularios/listar");
  };

  return (
    <div style={{ margin: "0px 15%" }}>
      <Container titulo="Crear Tipo Documento" onClickRetornar={onClickRetonarListarFormularios}>
        <FormularioRegistro />
        <ModalConfirmar
          loading={loading}
          mensaje="¿Está seguro de guardar el formulario? "
          onClickBtnCancelar={onClickBtnCancelarModal}
          onClickBtnConfirmar={onClickBtnConfirmarModal}
        />
      </Container>
    </div>
  );
};

export default CrearFormulariosPage;
