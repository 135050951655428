import React, { useState, useEffect } from "react";

import { ContainerCrear } from "../../../components/ContainerCrear";
import { Form, Input, Row, Col, Button, Space, notification, Select, Checkbox } from "antd";

import { CheckOutlined } from "@ant-design/icons";
import CargoService from "../../../services/CargoService";
import { useHistory, useParams } from "react-router-dom";
import { CampoColaborador } from "enums/CampoColaborador";
import SubsedeService from "services/SubsedeService";
import PerfilService from "services/PerfilService";
import SedeService from "services/SedeService";
import SeccionService from "services/SeccionService";
import TipoPlanillaService from "services/TipoPlanillaService";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";

export function CargoEditarPage() {
  const [frmEditar] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [omitirRango, setOmitirRango] = useState(false);
  const { getUsuarioAutenticado } = useContext(SecurityContext);

  const [dependenciaCargo, setDependenciaCargo] = useState();
  const [listaCampos, setListaCampos] = useState([]);
  const [perfiles, setPerfiles] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [subsedes, setSubsedes] = useState([]);
  const [tipoPlanillas, setTipoPlanillas] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [camposColaborador, setCamposColaborador] = useState([]);
  const isExplomin =
  getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837"
    ? true
    : false;
  const { Option } = Select;

  let history = useHistory();
  let { idCargo } = useParams();

  useEffect(() => {
    async function cargarDatos() {
      const cargo = await CargoService.buscarPorId(idCargo);
      console.log("cargo edit::", cargo);
      frmEditar.setFieldsValue({
        id: cargo.id,
        nombre: cargo.nombre,
        // descripcion: cargo.descripcion,
        empresaId: cargo.empresaId,
        sueldoMinimo: cargo.sueldoMinimo,
        sueldoMaximo: cargo.sueldoMaximo,
        //omitirRangoSalarial:cargo.omitirRangoSalarial,
      });
      setOmitirRango(cargo.omitirRangoSalarial);
      let listaCampos = [], dependencia;
      var perfilId, sedeId, seccionId, tipoPlanillaId, subsedeId;
      const empresa = getUsuarioAutenticado().empresaSeleccionada.id;
      const campos_colaborador = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
      setCamposColaborador(campos_colaborador);
      const campo_cargo = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.CARGO);

      const cargoDependencia = campo_cargo.campoDependencia;

      if (cargoDependencia !== null) {
        if (cargoDependencia === CampoColaborador.PERFIL) {
          perfilId = cargo.perfilId;
        }
        else if (cargoDependencia === CampoColaborador.SEDE) {
          sedeId = cargo.sedeId;
        }
        else if (cargoDependencia === CampoColaborador.SECCION) {
          seccionId = cargo.seccionId;
        }
        else if (cargoDependencia === CampoColaborador.PLANILLA) {
          tipoPlanillaId = cargo.tipoPlanillaId;
        }
        else if (cargoDependencia === CampoColaborador.SUBSEDE) {
          subsedeId = cargo.subsedeId;
        }

        setDependenciaCargo(cargoDependencia);
        dependencia = cargoDependencia;
        listaCampos.push(dependencia);

        while (dependencia !== null) {
          var campo = campos_colaborador.find(campoColaborador => campoColaborador.campo === dependencia);
          dependencia = campo.campoDependencia;
          if (dependencia !== null) {
            listaCampos.push(dependencia);
            //buscamos IDs
            if (dependencia === CampoColaborador.PERFIL) {
              perfilId = cargo.perfilId;
            }
            else if (dependencia === CampoColaborador.SEDE) {
              sedeId = cargo.sedeId;
            }
            else if (dependencia === CampoColaborador.SECCION) {
              seccionId = cargo.seccionId;
            }
            else if (dependencia === CampoColaborador.PLANILLA) {
              tipoPlanillaId = cargo.tipoPlanillaId;
            }
            else if (dependencia === CampoColaborador.SUBSEDE) {
              subsedeId = cargo.subsedeId;
            }

          }

        }
        setListaCampos(listaCampos.reverse());

      }
      else {
        setListaCampos(listaCampos);
        setDependenciaCargo(CampoColaborador.SINCAMPO);
      }
      if (listaCampos.length > 0) {

        for (let i = 0; i < listaCampos.length; i++) {

          if (listaCampos[i] === CampoColaborador.SEDE) {
            const campo_sede = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.SEDE);
            const sedeDependencia = campo_sede.campoDependencia;
            const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
            var _sedes;
            if (sedeDependencia === CampoColaborador.PERFIL) {
              _sedes = sedes.filter(sede => sede.perfilId === perfilId)
            }
            else if (sedeDependencia === CampoColaborador.SECCION) {
              _sedes = sedes.filter(sede => sede.seccionlId === seccionId)

            }
            else if (sedeDependencia === CampoColaborador.PLANILLA) {
              _sedes = sedes.filter(sede => sede.tipoPlanillaId === tipoPlanillaId)
            }
            else if (sedeDependencia === CampoColaborador.SUBSEDE) {
              _sedes = sedes.filter(sede => sede.subsedeId === subsedeId)
            }
            else {
              _sedes = sedes;
            }
            setSedes(_sedes);
            frmEditar.setFieldsValue({ sedeId: sedeId })


          }
          else if (listaCampos[i] === CampoColaborador.PERFIL) {

            const campo_perfil = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.PERFIL);
            const perfilDependencia = campo_perfil.campoDependencia;
            const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
            var _perfiles;
            if (perfilDependencia === CampoColaborador.SEDE) {
              _perfiles = perfiles.filter(perfil => perfil.sedeId === sedeId)
            }
            else if (perfilDependencia === CampoColaborador.SECCION) {
              _perfiles = perfiles.filter(perfil => perfil.seccionlId === seccionId)

            }
            else if (perfilDependencia === CampoColaborador.PLANILLA) {
              _perfiles = perfiles.filter(perfil => perfil.tipoPlanillaId === tipoPlanillaId)

            }
            else if (perfilDependencia === CampoColaborador.SUBSEDE) {
              _perfiles = perfiles.filter(perfil => perfil.subsedeId === subsedeId)

            }
            else {
              _perfiles = perfiles;
            }
            setPerfiles(_perfiles);

            frmEditar.setFieldsValue({ perfilId: perfilId })
          }
          else if (listaCampos[i] === CampoColaborador.SECCION) {

            const campo_seccion = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.SECCION);
            const seccionDependencia = campo_seccion.campoDependencia;
            const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
            var _secciones;
            if (seccionDependencia === CampoColaborador.SEDE) {
              _secciones = secciones.filter(seccion => seccion.sedeId === sedeId)
            }
            else if (seccionDependencia === CampoColaborador.PERFIL) {
              _secciones = secciones.filter(seccion => seccion.perfilId === perfilId)

            }
            else if (seccionDependencia === CampoColaborador.PLANILLA) {
              _secciones = secciones.filter(seccion => seccion.tipoPlanillaId === tipoPlanillaId)

            }
            else if (seccionDependencia === CampoColaborador.SUBSEDE) {
              _secciones = secciones.filter(seccion => seccion.subsedeId === subsedeId)
            }
            else {
              _secciones = secciones;
            }
            setSecciones(_secciones);

            frmEditar.setFieldsValue({ seccionId: seccionId })
          }
          else if (listaCampos[i] === CampoColaborador.PLANILLA) {

            const campo_planilla = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.PLANILLA);
            const planillaDependencia = campo_planilla.campoDependencia;
            const planillas = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
            var _planillas;
            if (planillaDependencia === CampoColaborador.SEDE) {
              _planillas = planillas.filter(planilla => planilla.sedeId === sedeId)
            }
            else if (planillaDependencia === CampoColaborador.PERFIL) {
              _planillas = planillas.filter(planilla => planilla.perfilId === perfilId)

            }
            else if (planillaDependencia === CampoColaborador.SECCION) {
              _planillas = planillas.filter(planilla => planilla.seccionaId === seccionId)

            }
            else if (planillaDependencia === CampoColaborador.SUBSEDE) {
              _planillas = planillas.filter(planilla => planilla.subsedeId === subsedeId)
            }
            else {
              _planillas = planillas;
            }
            setTipoPlanillas(_planillas);

            frmEditar.setFieldsValue({ tipoPlanillaId: tipoPlanillaId })
          }
          else if (listaCampos[i] === CampoColaborador.SUBSEDE) {

            const campo_subsede = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.SUBSEDE);
            const subsedeDependencia = campo_subsede.campoDependencia;
            const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
            var _subsedes;
            if (subsedeDependencia === CampoColaborador.SEDE) {
              _subsedes = subsedes.filter(subsede => subsede.sedeId === sedeId)
            }
            else if (subsedeDependencia === CampoColaborador.PERFIL) {
              _subsedes = subsedes.filter(subsede => subsede.perfilId === perfilId)

            }
            else if (subsedeDependencia === CampoColaborador.SECCION) {
              _subsedes = subsedes.filter(subsede => subsede.seccionaId === seccionId)

            }
            else if (subsedeDependencia === CampoColaborador.PLANILLA) {
              _subsedes = subsedes.filter(subsede => subsede.tipoPlanillaId === tipoPlanillaId)
            }
            else {
              _subsedes = subsedes;
            }
            setSubsedes(_subsedes);

            frmEditar.setFieldsValue({ subsedeId: subsedeId })
          }
        }
      }

    }
    cargarDatos();
  }, []);

  async function handleOnFinish(formulario) {
    try {
      setLoading(true);
      formulario.dependencia = dependenciaCargo;
      formulario.omitirRangoSalarial = omitirRango;

      await CargoService.actualizar(formulario);
      notification.success({
        message: "Operación exitosa",
        description: "Se actualizó el cargo correctamente.",
      });
      setLoading(false);
      history.push("/cargo");
    } catch (error) {
      console.error(error);
      notification.error({
        message: "No se puede actualizar el Cargo",
        description: error.response.data.mensaje,
      });
      setLoading(false);
    }
  }

  function handleOnClickCancelar() {
    history.push("/cargo");
  }
  const handleOnchangePerfil = async (value, option) => {

    let listaCampos = camposColaborador.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.PERFIL);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.SECCION) {
        const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _secciones = secciones.filter(seccion => seccion.perfilId === value)
        setSecciones(_secciones);
        frmEditar.setFieldsValue({ seccionId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter(subsede => subsede.perfilId === value);
        setSubsedes(_subsedes);
        frmEditar.setFieldsValue({ subsedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter(sede => sede.perfilId === value);
        setSedes(_sedes);
        frmEditar.setFieldsValue({ sedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanilla = tipoPlanilla.filter(planilla => planilla.perfilId === value);
        setTipoPlanillas(_tipoPlanilla);
        frmEditar.setFieldsValue({ tipoPlanillaId: "" })

      }
    }
  }
  const handleOnchangeSeccion = async (value, option) => {
    let listaCampos = camposColaborador.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.SECCION);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.seccionId === value)
        setPerfiles(_perfiles);
        frmEditar.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter(subsede => subsede.seccionId === value);
        setSubsedes(_subsedes);
        frmEditar.setFieldsValue({ subsedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter(sede => sede.seccionId === value);
        setSedes(_sedes);
        frmEditar.setFieldsValue({ sedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanilla = tipoPlanilla.filter(planilla => planilla.seccionId === value);
        setTipoPlanillas(_tipoPlanilla);
        frmEditar.setFieldsValue({ tipoPlanillaId: "" })

      }
    }
  }
  const handleOnchangeSubsede = async (value, option) => {
    let listaCampos = camposColaborador.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.SUBSEDE);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.subsedeId === value)
        setPerfiles(_perfiles);
        frmEditar.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SECCION) {
        const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _secciones = secciones.filter(seccion => seccion.subsedeId === value);
        setSecciones(_secciones);
        frmEditar.setFieldsValue({ seccionId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter(sede => sede.subsedeId === value);
        setSedes(_sedes);
        frmEditar.setFieldsValue({ sedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanilla = tipoPlanilla.filter(planilla => planilla.subsedeId === value);
        setTipoPlanillas(_tipoPlanilla);
        frmEditar.setFieldsValue({ tipoPlanillaId: "" })

      }
    }

  }
  const handleOnchangeTipoPlanilla = async (value, option) => {
    let listaCampos = camposColaborador.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.PLANILLA);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.tipoPlanillaId === value)
        setPerfiles(_perfiles);
        frmEditar.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter(subsede => subsede.tipoPlanillaId === value);
        setSubsedes(_subsedes);
        frmEditar.setFieldsValue({ subsedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter(sede => sede.tipoPlanillaId === value);
        setSedes(_sedes);
        frmEditar.setFieldsValue({ sedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.SECCION) {
        const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _secciones = secciones.filter(seccion => seccion.tipoPlanillaId === value);
        setSecciones(_secciones);
        frmEditar.setFieldsValue({ seccionId: "" })

      }
    }

  }
  const handleOnchangeSede = async (value, option) => {
    let listaCampos = camposColaborador.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.SEDE);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.sedeId === value)
        setPerfiles(_perfiles);
        frmEditar.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter(subsede => subsede.sedeId === value);
        setSubsedes(_subsedes);
        frmEditar.setFieldsValue({ subsedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanillas = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanillas = tipoPlanillas.filter(planilla => planilla.sedeId === value);
        setTipoPlanillas(_tipoPlanillas);
        frmEditar.setFieldsValue({ tipoPlanillaId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.SECCION) {
        const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _secciones = secciones.filter(seccion => seccion.sedeId === value);
        setSecciones(_secciones);
        frmEditar.setFieldsValue({ seccionId: "" })

      }
    }
  }

  
  const onChangeOmitirRangoSalarial=(e)=>{    
    if(e.target.checked===true){
      setOmitirRango(true);
    }else{
      setOmitirRango(false);
    }    
  }
 
  return (
    <ContainerCrear
      titulo="Editar Cargo"
      descripcion="Formulario para editar los cargos de tus colaboradores para tu organización."
      colSpan={12}
    >
      <Form
        layout="vertical"
        size="large"
        onFinish={handleOnFinish}
        form={frmEditar}
      >
        <Form.Item name="id" label="id" noStyle>
          <Input type="hidden" />
        </Form.Item>

        <Form.Item name="empresaId" noStyle>
          <Input type="hidden" />
        </Form.Item>
        {
          listaCampos.length > 0 && (
            listaCampos.map((listaCampo) => {
              if (listaCampo === CampoColaborador.PERFIL) {
                return (
                  <Row>
                    <Col span={24} >
                      <Form.Item
                        label="Perfil"
                        name="perfilId"
                        rules={[
                          {
                            required: true,
                            message: "Seleccione Perfil"
                          },
                        ]}
                      >
                        <Select
                          placeholder="Seleccione Perfil"
                          onChange={handleOnchangePerfil}
                        >
                          {perfiles.length > 0 &&
                            perfiles.map((lista) => {
                              return (
                                <Option key={lista.id} value={lista.id}>
                                  {lista.nombre}
                                </Option>
                              );
                            })}
                        </Select>

                      </Form.Item>
                    </Col>
                  </Row>
                )
              }

              else if (listaCampo === CampoColaborador.SECCION) {
                return (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Sección"
                        name="seccionId"
                        rules={[
                          {
                            required: true,
                            message: "Seleccione sección"
                          },
                        ]}
                      >
                        <Select
                          placeholder="Seleccione sección"
                          onChange={handleOnchangeSeccion}
                        >
                          {secciones.length > 0 &&
                            secciones.map((lista) => {
                              return (
                                <Option key={lista.id} value={lista.id}>
                                  {lista.nombre}
                                </Option>
                              );
                            })}

                        </Select>

                      </Form.Item>
                    </Col>
                  </Row>
                )
              }
              else if (listaCampo === CampoColaborador.SUBSEDE) {
                return (
                  <Row>
                    <Col span={24} >
                      <Form.Item
                        label="Subcentro de Costo"
                        name="subsedeId"
                        rules={[
                          {
                            required: true,
                            message: "Seleccione Subcentro de Costo"
                          },
                        ]}
                      >
                        <Select
                          placeholder="Seleccione Subcentro de Costo"
                          onChange={handleOnchangeSubsede}
                        >
                          {subsedes.length > 0 &&
                            subsedes.map((lista) => {
                              return (
                                <Option key={lista.id} value={lista.id}>
                                  {lista.nombre}
                                </Option>
                              );
                            })}
                        </Select>

                      </Form.Item>
                    </Col>
                  </Row>
                )
              }
              else if (listaCampo === CampoColaborador.PLANILLA) {
                return (
                  <Row>
                    <Col span={24} >
                      <Form.Item
                        label="Tipo planilla"
                        name="tipoPlanillaId"
                        rules={[
                          {
                            required: true,
                            message: "Seleccione Tipo planilla"
                          },
                        ]}
                      >
                        <Select
                          placeholder="Seleccione Tipo planilla"
                          onChange={handleOnchangeTipoPlanilla}
                        >
                          {tipoPlanillas.length > 0 &&
                            tipoPlanillas.map((lista) => {
                              return (
                                <Option key={lista.id} value={lista.id}>
                                  {lista.nombre}
                                </Option>
                              );
                            })}
                        </Select>

                      </Form.Item>
                    </Col>
                  </Row>
                )

              }
              else if (listaCampo === CampoColaborador.SEDE) {
                return (
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Sede"
                        name="sedeId"
                        rules={[
                          {
                            required: true,
                            message: "Seleccione Sede"
                          },
                        ]}
                      >
                        <Select
                          placeholder="Seleccione Sede"
                          onChange={handleOnchangeSede}
                        >
                          {sedes.length > 0 &&
                            sedes.map((lista) => {
                              return (
                                <Option key={lista.id} value={lista.id}>
                                  {lista.nombre}
                                </Option>
                              );
                            })}
                        </Select>

                      </Form.Item>
                    </Col>
                  </Row>
                )
              }

            })

          )

        }


        <Row>
          <Col span={24}>
            <Form.Item
              label="Nombre"
              name="nombre"
              rules={[
                {
                  required: true,
                  message: "Escribe el nombre del cargo",
                },
              ]}
            >
              <Input
                placeholder="Nombre del cargo"
                maxLength="50"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>
        {isExplomin &&(
            <Row>
            <Col span={11}>
              <Form.Item
                label="Rango Mínimo"
                name="sueldoMinimo"
                rules={[
                  {
                    required: true,
                    message: "Escribe el rango mínimo",
                  },
                ]}
              >
                <Input placeholder="Rango mínimo" autoComplete="off" />
              </Form.Item>
            </Col>
            <Col span={2}></Col>
            <Col span={11}>
              <Form.Item
                label="Rango Máximo"
                name="sueldoMaximo"
                rules={[
                  {
                    required: true,
                    message: "Escribe el rango máximo",
                  },
                ]}
              >
                <Input placeholder="Rango máximo" autoComplete="off" />
              </Form.Item>
            </Col>
          </Row>
        )}
        {isExplomin &&(
          <Row>
            <Col>
              {/* <Form.Item name="omitirRangoSalarial" label=""> */}
                 <Checkbox onChange={onChangeOmitirRangoSalarial} checked={omitirRango}>Omitir Rango Salarial</Checkbox>
              {/* </Form.Item> */}
            </Col>
          </Row>
        )}
        <Row justify="center">
          <Space>
            <Col>
              <Form.Item style={{ textAlign: "center" }}>
                <Button type="default" onClick={handleOnClickCancelar}>
                  Cancelar
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  loading={loading}
                  htmlType="submit"
                >
                  Actualizar Cargo
                </Button>
              </Form.Item>
            </Col>
          </Space>
        </Row>
      </Form>
    </ContainerCrear>
  );
}
