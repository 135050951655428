import React from "react";
import { FormularioCrear, ModalFiltrar, ModalRol } from "./componentes";
import { ContainerCrear } from "components/ContainerCrear";
import { Provider as JotaiProvider } from "jotai";

const UsuarioCrearPage = () => {
  return (
    <JotaiProvider>
      <ContainerCrear titulo="Crear Usuario" descripcion="Crear un usuario dentro de tu organización." colSpan={12}>
        <FormularioCrear />
        <ModalFiltrar />
        <ModalRol />
      </ContainerCrear>
    </JotaiProvider>
  );
};

export default UsuarioCrearPage;
