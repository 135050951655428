import React from "react";
import { Button, Card, Dropdown, Space, message } from "antd";
import { useState } from "react";
import { TabOnboarding } from "./TabOnboarding";
import { DatosPersonales } from "./DatosPersonales";
import { ModalRechazarOnboarding } from "components/Modal/ModalRechazarOnboarding";
import { UserAddOutlined, EllipsisOutlined, UserDeleteOutlined, MailOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { colaboradorAtom, visibleModalActivarColaboradorAtom } from "../../LegajoColaboradorStore";
import { useHistory } from "react-router-dom";
import { ContratosTab } from "./ContatosTab";
import { LegajoTab } from "./LegajoTab";
import { OnboardingColaboradorService } from "services/OnboardingColaboradorService";
import { OnboardingIngresoColaboradorService } from "services/OnboardingIngresoColaboradorService";

const CardDocumentosColaborador = () => {
    let history = useHistory();
    const [activeTabKey, setActiveTabKey] = useState("datosPersonales");

    const tabs = [
        {
            key: "datosPersonales",
            tab: "Datos Personales",
        },
        {
            key: "onboarding",
            tab: "Onboarding",
        },
        {
            key: "contratos",
            tab: "Contratos",
        },
        {
            key: "legajos",
            tab: "Legajo",
        },
    ];

    const listaTabs = {
        datosPersonales: <DatosPersonales />,
        onboarding: <TabOnboarding />,
        contratos: <ContratosTab />,
        legajos: <LegajoTab />,
    };

    const onTabChange = (key) => {
        setActiveTabKey(key);
    };

    return (
        <div className="lista-documentos">
            <Card
                tabList={tabs}
                activeTabKey={activeTabKey}
                onTabChange={(key) => {
                    onTabChange(key);
                }}
                tabBarExtraContent={<BotonesExtra tab={activeTabKey} />}
                style={{ marginBottom: "30px" }}
            >
                {listaTabs[activeTabKey]}
            </Card>
            {/* <ModalVisorPdf /> */}
            {/* <ModalVisorPdf visible={true} /> */}
            <ModalRechazarOnboarding />
        </div>
    );
};

const BotonesExtra = ({ tab }) => {
    const [, setVisibleModalActivarColaborador] = useAtom(visibleModalActivarColaboradorAtom);
    const [colaboradorSeleccionado] = useAtom(colaboradorAtom);

    const items = [];
    if (colaboradorSeleccionado?.estadoRegistro !== "ACTIVO") {
        items.push({
            key: "activar-postulante",
            label: (
                <Space>
                    <UserAddOutlined />
                    Activar Postulante
                </Space>
            ),
        });
    }

    items.push({
        key: "reenviar-notificacion-onboarding",
        label: (
            <Space>
                <MailOutlined />
                Reenviar Notificación Onboarding
            </Space>
        ),
    });

    // if (colaboradorSeleccionado?.estadoRegistro === "ACTIVO") {
    //     items.push({
    //         key: "cesar-colaborador",
    //         label: (
    //             <Space>
    //                 <UserDeleteOutlined />
    //                 Cesar Colaborador
    //             </Space>
    //         ),
    //         danger: true
    //     });
    // }

    const onClickItem = (e) => {
        if (e.key === "activar-postulante") {
            setVisibleModalActivarColaborador(true);
        } else if (e.key === "reenviar-notificacion-onboarding") {
            OnboardingIngresoColaboradorService.reenviarNotificacionOnboarding(colaboradorSeleccionado.numeroDocumentoIdentidad);
            message.success("Notificación reenviada.")
        }
    };

    return (
        <Dropdown
            menu={{
                items,
                onClick: onClickItem,
            }}
            trigger={["click"]}
            placement="bottomRight"
            arrow
        >
            <Button type="link">
                <EllipsisOutlined style={{ fontSize: "24px", color: "#6d6e6f" }} />
            </Button>
        </Dropdown>
    );
};

export default CardDocumentosColaborador;
