import React, { useState } from "react";
import { Modal,Button,Table,Typography,List,Descriptions } from "antd";

const ModalVerEntrevista=({estado,entrevistaSeleccionada,setEstado})=>{
    
    const columnasCompetencias=[
        {
            title:"Habilidad",
            dataIndex:"descripcion",           
            width:"15%",
        },
        // {
        //     title:"Esperado",
        //     dataIndex:"nivelRequerido",
        //     align:"center",
        //     width:"15%",
        // },
        {
            title:"Obtenido",
            dataIndex:"nivelAlcanzado",
            align:"center",
            width:"15%",
        }
    ]
    
        const handleDownload = (url,tipoName ) => {
        // const link = document.createElement('a');
        // link.href = url;
        // link.download = tipoName+'archivo.pdf'; // Nombre del archivo descargado
        // link.click();
        const newWindow = window.open('', '_blank');
        newWindow.location.href = url;
    };

    return(
        <Modal 
          title="Entrevista"
          open={estado}
          onCancel={()=>{setEstado(false);}}
          footer={
            <Button type="primary" onClick={()=>setEstado(false)}>
                Cerrar
            </Button>
          }
          width={"70vw"}
          centered
          destroyOnClose
          bodyStyle={{ overflowY: "scroll", minHeight: "calc(100vh - 220px)", maxHeight: "calc(100vh - 220px)" }}
          
          >
            {
                entrevistaSeleccionada?.competenciasLaborales.length>0 &&
                <React.Fragment>
                    <Typography.Title level={5} style={{fontWeight:"bold"}}>Competencias Laborales</Typography.Title>
                    <Table
                    rowKey={(row)=>row.id}
                    dataSource={entrevistaSeleccionada?.competenciasLaborales}
                    columns={columnasCompetencias}
                    pagination={false}
                    bordered={true}
                    />
                    <br/>
                </React.Fragment>
                

            }
           
           {
             entrevistaSeleccionada?.preguntas.length>0 &&
             <React.Fragment>
                <Typography.Title level={5} style={{fontWeight:"bold"}}>Preguntas</Typography.Title>
                <List
                    rowKey={(row)=>row.id}
                    itemLayout="horizontal"
                    footer={entrevistaSeleccionada.comentarioPreguntas?<span><b>Comentarios : </b> {entrevistaSeleccionada.comentarioPreguntas}</span>:null}
                    dataSource={entrevistaSeleccionada?.preguntas}
                    bordered={true}
                    renderItem={(item) => (
                    <List.Item  key={item.id}>
                        <List.Item.Meta                      
                        title={item.descripcion}
                        description={item.respuestaObtenida}
                       
                        />
                    </List.Item>
                    )}
                />
                <br/>
             </React.Fragment>
           }
           {entrevistaSeleccionada?.experienciasLaborales.length > 0 && <React.Fragment>
                 <Typography.Title level={5} style={{fontWeight:"bold"}}>Experiencias Laborales</Typography.Title>
            {entrevistaSeleccionada.experienciasLaborales.map((experiencia, index) => {
                  return(
                        <Descriptions key={index} bordered={true} style={{marginBottom:"15px"}}>
                         <Descriptions.Item label="Empresa" span={3}>{experiencia.empresa}</Descriptions.Item>
                         <Descriptions.Item label="Puesto" span={3}>{experiencia.puesto}</Descriptions.Item>
                         <Descriptions.Item label="Fecha inicio" span={3}>{experiencia.fechaInicio}</Descriptions.Item>
                         <Descriptions.Item label="Fecha fin" span={3}>{experiencia.fechaFin}</Descriptions.Item>
                         <Descriptions.Item label="Tiempo" span={3}>{experiencia.tiempo}</Descriptions.Item>
                         <Descriptions.Item label="Personas a cargo" span={3}>{experiencia.numeroPersonasCargo}</Descriptions.Item>
                         <Descriptions.Item label="Motivo Salida" span={3}>{experiencia.motivoSalida}</Descriptions.Item>
                         <Descriptions.Item label="Remuneración" span={3}>{experiencia.remuneracion}</Descriptions.Item>
                         <Descriptions.Item label="Bonos" span={3}>{experiencia.bonos}</Descriptions.Item>
                         <Descriptions.Item label="contacto de referencia" span={3}>{experiencia.contactoReferencia}</Descriptions.Item>  
                       </Descriptions>                   
                     
                  )                
                })  }     
            </React.Fragment>          
           }

            {
            entrevistaSeleccionada && <Descriptions title="Documentos Adjuntos" bordered={true} style={{marginBottom:"15px"}}>
                         <Descriptions.Item label="Hoja de Vida" span={3}><Button type="link" onClick={()=>handleDownload(entrevistaSeleccionada?.urlHojaVida, "CV_")}> Descargar</Button></Descriptions.Item>
                <Descriptions.Item label="Evaluación de Conocimiento" span={3}><Button type="link" onClick={() => handleDownload(entrevistaSeleccionada?.urlEvaluacionConocimiento, "EvaluacionConocimiento_")}> Descargar</Button></Descriptions.Item>
                         <Descriptions.Item label="Psicotest" span={3}><Button type="link" onClick={() => handleDownload(entrevistaSeleccionada?.urlPsicotest, "Psicotest_")} > Descargar</Button></Descriptions.Item>
                       </Descriptions>                   
          
            }
             
             
        </Modal>
    )

}
export default ModalVerEntrevista;