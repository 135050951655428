import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { Container } from "components/Container";
import { ListaContratos, ModalAprobarContrato } from "./componentes";

const SeguimientoRenovacionContratoPage = () => {
    return (
        <JotaiProvider>
            <Container titulo="Renovación de Contratos">
                <ListaContratos />
                <ModalAprobarContrato />
            </Container>
            ;
        </JotaiProvider>
    );
};

export default SeguimientoRenovacionContratoPage;
