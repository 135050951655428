import React, { useContext, useEffect } from "react";
import { Form, Button, Select, Input, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { FirmaDocumentoGenericoCrearContext } from "context/firma-documento-generico/crear";

const FormularioCrearDocumentoGenerico = () => {

    const { empresas, getEmpresas, firmantes, getFirmantes, propiedadesUploadDocumento, handleOnChangeUsuarioFirmante, mostrarModalConfirmacion, handleOnchangeEmpresa }
        = useContext(FirmaDocumentoGenericoCrearContext);


    useEffect(() => {
        getEmpresas();
    }, []);

    const onClickBtnGuardar = (documentoGenerico) => {
        mostrarModalConfirmacion(documentoGenerico);
    }

    return (
        <Form
            name="frmCrearDocumentoGenerico"
            //layout="vertical"
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 6 }}
            onFinish={onClickBtnGuardar}
        >
            <Form.Item
                label="Empresa"
                name="empresaId"
                rules={[{ required: true, message: "Seleccione una empresa" }]}

            >
                <Select
                    // showSearch
                    //placeholder="Selecciona una empresa"
                    // onChange={onChangeTipoDocumento}
                    onChange={handleOnchangeEmpresa}
                >
                    {empresas.length > 0 &&
                        empresas.map((empresa) => {
                            return (
                                <Select.Option
                                    key={empresa.id}
                                    value={empresa.id}
                                >
                                    {empresa.razonSocial}
                                </Select.Option>
                            );
                        })}
                </Select>
            </Form.Item >

            <Form.Item
                label="Nombre Documento"
                name="nombreDocumento"
                rules={[{ required: true, message: "Ingrese nombre del Documento" }]}
            >
                {/* <Select
                        showSearch
                        placeholder="Selecciona el tipo de documento"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

                        }
                        onChange={onChangeTipoDocumento}

                    >
                        {tiposDocumento.length > 0 &&
                            tiposDocumento.map((tipoDocumento) => {
                                return (
                                    <Option
                                        key={tipoDocumento.id}
                                        value={`${tipoDocumento.id}|${tipoDocumento.nombre}`}
                                    >
                                        {tipoDocumento.nombre}
                                    </Option>
                                );
                            })}
                    </Select> */}
                <Input

                    autoComplete="off"
                />
            </Form.Item>

            <Form.Item
                label="Firmante"
                name="usuarioFirmanteId"
                rules={[{ required: true, message: "Seleccione un Usuario Firmante" }]}
            >
                {/* <Select
                        showSearch
                        placeholder="Selecciona el tipo de documento"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

                        }
                        onChange={onChangeTipoDocumento}
                    >
                        {tiposDocumento.length > 0 &&
                            tiposDocumento.map((tipoDocumento) => {
                                return (
                                    <Option
                                        key={tipoDocumento.id}
                                        value={`${tipoDocumento.id}|${tipoDocumento.nombre}`}
                                    >
                                        {tipoDocumento.nombre}
                                    </Option>
                                );
                            })}
                    </Select> */}
                <Select
                    onChange={handleOnChangeUsuarioFirmante}
                >
                    {firmantes.length > 0 &&
                        firmantes.map((firmante) => {
                            return (
                                <Select.Option
                                    key={firmante.id}
                                    value={firmante.id}
                                >
                                    {firmante.nombreCompleto}
                                </Select.Option>
                            );
                        })}
                </Select>
            </Form.Item>
            <Form.Item
                label="Solicitado por"
                name="solicitadoPor"
                rules={[{ required: true, message: "Ingrese nombre del Solicitante" }]}
            >
                <Input autoComplete="off">
                </Input>
            </Form.Item>

            <Form.Item
                label="Documento"
                name="documento"
                valuePropName="documento"
                rules={[{ required: true, message: "Seleccione el documento PDF" }]}
            >
                {/* <Upload {...propiedadesUploadDocumento} maxCount={1}>
                        <Button icon={<UploadOutlined />}>Subir Documento PDF</Button>
                    </Upload> */}
                <Upload {...propiedadesUploadDocumento} maxCount={1}>
                    <Button icon={<UploadOutlined />}>Subir Documento PDF</Button>
                </Upload>
            </Form.Item>



            <Form.Item
                wrapperCol={{ span: 24, offset: 10 }}
            >
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{ paddingLeft: "30px", paddingRight: "30px" }}
                >
                    Cargar Documento
                </Button>
            </Form.Item>
        </Form >
    )

}

export default FormularioCrearDocumentoGenerico;