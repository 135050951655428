import React, { useContext, useState } from "react";
import { ListaControlesPdf, DrawerPropiedades, VisorFormularioPdf } from "./componentes";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider as JotaiProvider, useAtom } from "jotai";
import { useHistory, useParams } from "react-router-dom";
import { Button, Space, Popconfirm, message } from "antd";
import { ArrowLeftOutlined, CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import { FormularioService } from "services/FormularioService";
import { componentesAtom, formularioAtom } from "./DisenarFormularioPdfStore";
import { SecurityContext } from "context/SecurityContextProvider";

const DisenarFormularioPdfPage = () => {
  const history = useHistory();

  const onClickRetornar = () => {
    history.push("/configuracion/formularios/listar");
  };

  return (
    <JotaiProvider>
      <DndProvider backend={HTML5Backend}>
        <Container>
          <Cabecera titulo="Diseñar Formulario" onClickRetornar={onClickRetornar} botones={<BotonesCabecera />} />
          <Cuerpo>
            <ListaControlesPdf />
            <VisorFormularioPdf />
            <DrawerPropiedades />
          </Cuerpo>
        </Container>
      </DndProvider>
    </JotaiProvider>
  );
};

const BotonesCabecera = () => {
  const { formularioId } = useParams();
  const [loading, setLoading] = useState(false);
  const [formulario, setFormulario] = useAtom(formularioAtom);
  const [componentes] = useAtom(componentesAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);

  const onClickBtnEliminarArchivo = async () => {
    try {
      setLoading(true);
      const _formulario = await FormularioService.limpiarPlantillaPdf({ formularioId: formularioId });
      setFormulario(_formulario);
    } catch (error) {
      console.error("Ocurrió un error inesperado", error);
    } finally {
      setLoading(false);
    }
  };

  const onClickBtnGuardarDiseno = () => {
    FormularioService.guardarDiseno({
      id: formularioId,
      empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
      componentes: componentes,
    })
      .then(async (respuesta) => {
        message.success("Diseño guardado exitosamente.");
      })
      .catch(async (error) => {
        console.error("error", error);
        message.error("Ocurrió un error al guardar el diseño. Intente nuevamente.");
      });
  };

  return (
    <Space>
      {formulario?.urlPlantillaPdfFirmada !== null && (
        <Popconfirm
          placement="bottom"
          title="¿Está seguro de eliminar el archivo cargado?"
          okText="Si"
          cancelText="No"
          onConfirm={onClickBtnEliminarArchivo}
          okButtonProps={{ loading: loading }}
        >
          <Button>
            <DeleteOutlined /> Eliminar Archivo
          </Button>
        </Popconfirm>
      )}
      <Button type="primary" onClick={onClickBtnGuardarDiseno}>
        <CheckOutlined /> Guardar Diseño
      </Button>
    </Space>
  );
};

const Container = ({ children }) => {
  return (
    <div
      id="container"
      style={{
        border: "1px solid #ddd",
        background: "#fff",
        height: "100%",
      }}
    >
      {children}
    </div>
  );
};

const Cuerpo = ({ children }) => {
  return <div style={{ display: "flex", height: "inherit" }}>{children}</div>;
};

const Cabecera = ({ titulo, botones, onClickRetornar }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        padding: "0px 15px",
        borderBottom: "1px solid #ddd",
        height: "55px",
        maxHeight: "55px",
      }}
    >
      <div style={{ width: "40%" }}>
        <Button type="link" onClick={onClickRetornar} style={{ padding: "0", margin: 0, paddingRight: "10px" }}>
          <ArrowLeftOutlined style={{ color: "#333333", fontSize: "20px" }} />
        </Button>
        {titulo}
      </div>
      <div style={{ width: "60%", textAlign: "right" }}>{botones}</div>
    </div>
  );
};

export default DisenarFormularioPdfPage;
