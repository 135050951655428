import React from "react";
import { Container } from "components/Container";
import { BotonesGlobales } from "./botones-globales";
import { TablaDocumentosFormularios } from "./tabla-documentos-formularios";
import { ModalAsignarCarpeta } from "components/Modal/ModalAsignarCarpeta";
import { useAtom } from "jotai";
import * as atoms from "./SeguimientoFormularioStore";
import { useModalAsignarCarpeta } from "store/global";

const SeguimientoFormulariosPage = () => {
    const [formularioSeleccionado, setFormularioSeleccionado] = useAtom(atoms.formularioSeleccionado);
    const cerrarModalAsignarCarpeta = useModalAsignarCarpeta(state => state.cerrarModal);
    const onClickBtnCancelar = () => {
        cerrarModalAsignarCarpeta();
    }
    return (
        <Container titulo="Seguimiento de Formularios" botonExtra={<BotonesGlobales />}>
            <TablaDocumentosFormularios />
            {/* <ModalAsignarCarpeta
                onClickBtnCancelar={onClickBtnCancelar}
                documento={formularioSeleccionado}

            /> */}
        </Container>
    );
}

export default SeguimientoFormulariosPage;