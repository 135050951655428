import React from "react";
import { Button,Popconfirm } from "antd";
import { EditOutlined, LayoutOutlined,DeleteOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import SubsedeService from "../../../../services/SubsedeService";

export const MenuAcciones=({subsede,subsedes,setSubsedes})=>{

    let history = useHistory();

    const BotonEditar = () => {
        const onClickBtnEditar=()=>{
                                   
            const rutaEditarSubsedePage=`/subsede/editar/${subsede.id}`;                                              
            history.push(rutaEditarSubsedePage);
        
        }

        return (
            <Button type="link" icon={<EditOutlined />} onClick={onClickBtnEditar}>
                Editar
            </Button >
        )
    }
    const BotonEliminar = ()=>{
        const onclickBtnEliminar = async() =>{               
               await SubsedeService.eliminar(subsede.id);
               setSubsedes(subsedes.filter((_subsede)=>_subsede.id!==subsede.id));           
         }

        return(            
            <Popconfirm
            title="¿Está seguro de eliminar el subcentro de costo?"
            onConfirm={onclickBtnEliminar}           
            okText="Si"
            cancelText="No"
           >
            <Button type="link" icon={<DeleteOutlined />} >
                Eliminar
            </Button>
          </Popconfirm>

        )
    }
    return (
        <React.Fragment>
            <BotonEditar />
            <BotonEliminar />
        </React.Fragment>
    );

}
