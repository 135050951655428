import React from "react";
import { Container } from "components/Container";
import { useHistory } from "react-router";
import { PlantillaEnvioDocumentoRegistro } from "./PlantillaEnvioDocumentoRegistro";
import { ModalDocumentos } from "./ModalDocumentos";
import { documentosSeleccionadosAtom } from "./CrearPlantillaEnvioDocumentosStore";
import { useAtom } from "jotai";

const CrearPlantillaEnvioDocumentosPage = () => {
  const [documentosSeleccionados, setDocumentosSeleccionados] = useAtom(documentosSeleccionadosAtom);

  let history = useHistory();

  const onClickRetornar = () => {
    setDocumentosSeleccionados([]);
    history.push("/configuracion/plantilla-envio-documentos/listar");
  };

  return (
    <Container titulo="Crear Plantilla Onboarding" onClickRetornar={onClickRetornar}>
      <PlantillaEnvioDocumentoRegistro />
      <ModalDocumentos />
    </Container>
  );
};
export default CrearPlantillaEnvioDocumentosPage;
