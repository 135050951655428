import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Input,
  Alert,
  Spin,
  DatePicker,
  Select,
  Form,
  Space,
  message,
  Divider,
  Row,
  Table,
  Col,
  Modal,
} from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import {
  pasoActualAtom,
  datosColaboradorStepAtom,
  listaTipoPlantillaEnvioAtom,
  colaboradoresAgregadosAtom,
  resumenAtom,
} from "../../OnboardingIndividualStore";
import ColaboradorService from "services/ColaboradorService";
import { PlantillaEnvioDocumentoService } from "services/PlantillaEnvioDocumentoService";
import { SecurityContext } from "context/SecurityContextProvider";
import ModalCargaExcel from "./ModalCargaExcel";

const ColaboradorStep = () => {
  const [, setPasoActual] = useAtom(pasoActualAtom);
  //const [resumen, ] = useAtom(resumenAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [, setListaTipoPlantilla] = useAtom(listaTipoPlantillaEnvioAtom);
  const [formOnboardingPlanilla] = Form.useForm();
  const [estadoCarga, setEstadoCarga] = useState(undefined);
  const [modalFormVisible, setModalFormVisible] = useState(false);
  const [modalExcelVisible, setModalExcelVisible] = useState(false);
  const [colaboradoresAgregados, setColaboradoresAgregados] = useAtom(colaboradoresAgregadosAtom);
  const [resumen] = useAtom(resumenAtom);
  const [, setDatosColaboradorStep] = useAtom(datosColaboradorStepAtom);

  const { Option } = Select;

  useEffect(() => {
    const cargarDatos = async () => {
      const plantillas = await PlantillaEnvioDocumentoService.listar(getUsuarioAutenticado().login);
      setListaTipoPlantilla(plantillas);
    };
    cargarDatos();

    formOnboardingPlanilla.setFieldsValue(resumen);
  }, []);

  const onClickBtnSiguiente = (formulario) => {
    if (colaboradoresAgregados.length == 0) message.error("Seleccione un colaborador");
    else {
      const formularioStep = { ...formulario, documentos: colaboradoresAgregados };
      setDatosColaboradorStep(formularioStep);
      setPasoActual(2);
    }
  };

  const onChangeTipoCarga = (value) => {
    setColaboradoresAgregados([]);
    if (value !== null) setEstadoCarga(value);
  };

  const onCerrarModal = () => {
    setModalExcelVisible(false);
  };

  const onClickCargarBoton = () => {
    if (estadoCarga === "FORMULARIO") setModalFormVisible(true);
    else if (estadoCarga === "EXCEL") setModalExcelVisible(true);
    else message.error("Comando invalido");
  };

  const onClickQuitar = ({ fila }) => {
    const _colaboradoresNuevo = colaboradoresAgregados.filter((d) => d.colaborador.id !== fila.colaborador.id);
    setColaboradoresAgregados(_colaboradoresNuevo);
  };

  const columnas = [
    {
      title: "Apellidos y Nombres",
      dataIndex: ["colaborador", "nombreCompleto"],
    },
    {
      title: "Plantilla Onboarding",
      dataIndex: ["tipoPlantilla", "nombre"],
    },
    {
      title: "Acciones",
      key: "acciones",
      render: (text, fila) => (
        <a onClick={() => onClickQuitar((fila = { fila }))} className="custom-color-element">
          Quitar
        </a>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div style={{ display: "flex" }}>
        <div id="formulario" style={{ width: "40%", padding: "0px 0px 0px 60px" }}>
          <Form layout="vertical" onFinish={onClickBtnSiguiente} form={formOnboardingPlanilla}>
            <Form.Item
              label="Seleccione un periodo"
              name={"periodo"}
              rules={[
                {
                  required: true,
                  message: "Debe ingresar el periodo. ",
                },
              ]}
            >
              <DatePicker allowClear={false} format={"MM/YYYY"} picker="month" style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label="Agregar Colaboradores"
              name={"tipoCargaColaborador"}
              rules={[
                {
                  required: true,
                  message: "Debe ingresar el tipo de plantilla.",
                },
              ]}
            >
              <Select onSelect={(value) => onChangeTipoCarga(value)}>
                <Option value="EXCEL">Por Excel</Option>
                <Option value="FORMULARIO">Por Formulario</Option>
              </Select>
            </Form.Item>

            {estadoCarga != undefined && (
              <Form.Item label="Seleccione los colaboradores:">
                <Button onClick={onClickCargarBoton}>
                  {estadoCarga === "FORMULARIO" ? "Agregar colaboradores" : "Cargar archivo excel"}
                </Button>
              </Form.Item>
            )}
          </Form>
        </div>
        <div id="tabla" style={{ width: "60%", padding: "0px 60px" }}>
          <Table rowKey={(tabla) => tabla.colaborador.id} columns={columnas} dataSource={colaboradoresAgregados} />
        </div>
      </div>
      <div style={{ padding: "20px 0px" }}>
        <Button type="primary" size="large" onClick={() => formOnboardingPlanilla.submit()}>
          Siguiente <RightOutlined />
        </Button>
      </div>

      <ModalForm
        modalFormVisible={modalFormVisible}
        setModalFormVisible={setModalFormVisible}
        setColaboradoresAgregados={setColaboradoresAgregados}
        colaboradoresAgregados={colaboradoresAgregados}
      />
      <ModalCargaExcel visibleModal={modalExcelVisible} onCerrarModal={onCerrarModal} />
    </React.Fragment>
  );
};

export default ColaboradorStep;

const ModalForm = ({ modalFormVisible, setModalFormVisible, setColaboradoresAgregados, colaboradoresAgregados }) => {
  const [colaboradores, setColaboradores] = useState(undefined);
  const [formOnboardingColaborador] = Form.useForm();
  const [listaTipoPlantillaEnvio] = useAtom(listaTipoPlantillaEnvioAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const onSearchColaboradores = async (value) => {
    if (value === "") {
      setColaboradores([]);
      return;
    }
    const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
      filtro: value.trim(),
      login:getUsuarioAutenticado().login,
    });
    setColaboradores(respuesta.colaboradores);
  };

  const onSelectColaborador = async (value, value2) => {
    if (value !== null) {
      const colaboradorEncontrado = colaboradores.find((c) => c.id === value2.key);
      formOnboardingColaborador.setFieldsValue({
        colaborador: { id: value2.key, codigoTrabajador: colaboradorEncontrado.codigoTrabajador },
      });
    }
  };
  const onFinishForm = (formulario) => {
    const idsColaboradores = colaboradoresAgregados.find((c) => c.colaborador.id === formulario.colaborador.id);
    if (idsColaboradores == null || idsColaboradores == undefined) {
      setColaboradoresAgregados([...colaboradoresAgregados, formulario]);
      setColaboradores();
      formOnboardingColaborador.resetFields();
      setModalFormVisible(false);
    } else {
      message.error("Colaborador ya se encuentra registrado.");
    }
  };

  const onTipoPlanilla = (_, value2) => {
    formOnboardingColaborador.setFieldsValue({ tipoPlantilla: { id: value2.key } });
  };

  return (
    <Modal
      title={"Agregar Colaborador"}
      visible={modalFormVisible}
      onCancel={() => setModalFormVisible(false)}
      destroyOnClose
      footer={null}
    >
      <Form form={formOnboardingColaborador} onFinish={onFinishForm} layout="vertical">
        <Form.Item
          label="Colaborador"
          name={["colaborador", "nombreCompleto"]}
          rules={[
            {
              required: true,
              message: "Debe ingresar el colaborador.",
            },
          ]}
        >
          <Select
            style={{ width: "100%" }}
            allowClear
            showSearch
            placeholder="Ingrese el nombre del colaborador"
            onSearch={onSearchColaboradores}
            //onChange={onChangeSelectColaboradores}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onSelect={onSelectColaborador}
          >
            {colaboradores !== undefined &&
              colaboradores?.map((colaborador) => (
                <Select.Option key={colaborador.id} value={colaborador.nombreCompleto}>
                  {colaborador.nombreCompleto}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name={["colaborador", "id"]} noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name={["colaborador", "codigoTrabajador"]} noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          label="Tipo de Plantilla"
          name={["tipoPlantilla", "nombre"]}
          rules={[
            {
              required: true,
              message: "Debe ingresar el tipo de plantilla.",
            },
          ]}
        >
          <Select onSelect={onTipoPlanilla}>
            {listaTipoPlantillaEnvio !== undefined &&
              listaTipoPlantillaEnvio?.map((planilla) => (
                <Select.Option key={planilla.id} value={planilla.nombre}>
                  {planilla.nombre}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item name={["tipoPlantilla", "id"]} noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item>
          <div style={{ textAlign: "center" }}>
            <Button type="default" htmlType="submit">
              Agregar
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};
