import React,{useEffect} from "react";
import { Checkbox, Col, DatePicker, Form, Input, Modal, Row, Select,Divider,Typography } from "antd";
import useListarConvocatoriasActivas from "./hooks/useListarConvocatoriasActivas";
import { ConvocatoriaService } from "services/reclutamiento-y-seleccion/ConvocatoriaService";

const ModalCambiarconvocatoria=({modalCambiarconvocatoria,setModalCambiarConvocatoria,postulante,convocatoria})=>{
   const {convocatoriasActivas} = useListarConvocatoriasActivas(convocatoria.convocatoriaId);
   const [formConvocatoria] =Form.useForm();
   

   const onFinishCambiarConvocatoria = async(formulario)=>{
      await ConvocatoriaService.cambiarConvocatoriaPostulante({
        convocatoriaNuevaId:formulario.convocatoriaNueva,
        convocatoriaActualId:convocatoria.convocatoriaId,
        postulanteId:postulante.postulanteId
      })
      setModalCambiarConvocatoria(false); 
      formConvocatoria.resetFields();   
   }
return (
    <Modal
        title="Cambiar convocatoria"
        open={modalCambiarconvocatoria}
        onOk={() => formConvocatoria.submit()}
        okText="Mover Postulante"
        onCancel={() => {
            setModalCambiarConvocatoria(false);
            formConvocatoria.resetFields();
        }}
        destroyOnClose
    
    >
        <Form layout="vertical" form={formConvocatoria} onFinish={onFinishCambiarConvocatoria}>
            <Form.Item 
                 name="convocatoriaNueva" 
                 label="Seleccionar convocatoria"
                 rules={[{ required: true, message: "Seleccione la convocatoria" }]}
                 >
            <Select>
                {convocatoriasActivas!==undefined &&
                    convocatoriasActivas.filter(_convocatoria=>_convocatoria.id!==convocatoria.convocatoriaId).map((convocatoria)=>{
                        return(
                            <Select.Option key={convocatoria.id} value={convocatoria.id}>
                                {convocatoria.puesto}
                            </Select.Option>
                        )
                    })
                }
           </Select>
            </Form.Item>

        </Form>
        

    </Modal>
)
}
export default ModalCambiarconvocatoria;