import React from "react";
import { ContainerCrear } from "components/ContainerCrear";
import { TipoAbsentismoEditar } from "./tipoAbsentismo-editar";
import DrawerCamposPersonalizados from "./tipoAbsentismo-editar/DrawerCamposPersonalizados";
import DrawerArchivoAdjunto from "./tipoAbsentismo-editar/DrawerArchivoAdjunto";
const EditarTipoAbsentismoPage = () => {
  return (
    <ContainerCrear
      titulo="Editar Tipo Absentismo"
      descripcion="Formulario para editar los tipos de absentismos para tu organización"
      colSpan={12}
    >
      <TipoAbsentismoEditar />
      <DrawerCamposPersonalizados/>
      <DrawerArchivoAdjunto/>
    </ContainerCrear>
  );
};
export default EditarTipoAbsentismoPage;
