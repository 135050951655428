import React, { useContext, useEffect, useState } from "react";
import { Table,Button } from "antd";
import * as atoms from "../SeguimientoFormularioStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import  FormularioSeguimientoService  from "services/FormularioSeguimientoService";
import iconoFolder from "../../../../../assets/img/folder.svg";
import { EstadoFormulario } from "./EstadoFormulario";
import  MenuAcciones from "./MenuAcciones";
import { useHistory } from "react-router-dom";

export const TablaDocumentosFormularios = () => {
    let history = useHistory();
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [loading, setLoading] = useState(false);
    const [formularios, setFormularios] = useAtom(atoms.formularios);
    useEffect(() => {
        const fetch = async () => {
            try {
                setLoading(false);
                const formularios = await FormularioSeguimientoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                setFormularios(formularios);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
        fetch();
    }, []);
    function onClickSeleccionarDetalle(envioId) {
      history.push(`/notificacion/formulario/seguimiento/${envioId}/detalle`);      
      
    }
    const columnas = [
        {
            title: "Documento",            
            key:'descripcion',
            render: (text, fila) => (
                <Button type="link" className="milegajo-link-table" onClick={() => onClickSeleccionarDetalle(fila.id)}>
                  <img src={iconoFolder} alt="iconoFolder" width="32px" style={{ marginRight: "4px" }} />
                  {fila.descripcion}
                </Button>
              )
           
        },
        {
            title: 'Estado',
            align: "center",
            dataIndex: 'estado',
            key: 'estado',
            render: (text, fila) => (
              <EstadoFormulario estado={fila.estado} />
            )
         },
         {
            title: 'Fecha de envío',
            align: "center",
            dataIndex: 'fechaEnvio',
            key: 'fechaEnvio',
          },
          {
            title: '% Avance',
            align: "center",
            key: 'avance',
            render: (text, fila) => (
              // (fila.totalFirmados + fila.totalRechazados) * 100 / fila.totalEnviados + " %"
              Math.round((fila.totalFirmados + fila.totalRechazados) * 100 / fila.totalCargados) + " %"
            )
          },
          {
            title: 'Firmados',
            align: "center",
            dataIndex: 'totalFirmados',
            key: 'totalFirmados',
          },
         
          {
            title: 'Pendientes',
            align: "center",
            key: 'totalPendientes',
            render: (text, fila) => (
              fila.totalCargados - (fila.totalFirmados + fila.totalRechazados)
            )
          }, 
        {
            title: "Acciones",
            key: "acciones",
            align: 'center',
            render: (text,formulario) =>(
                <MenuAcciones envio={formulario} />
            )
        },
    ];

    return (
        <Table
            loading={loading}
            rowKey={(formulario) => formulario.id}
            columns={columnas}
            dataSource={formularios}
        />
    )
}
export default TablaDocumentosFormularios;