import { useAtom } from "jotai";
import { postulantesSeleccionadosAtom } from "../ProcesosCrearStore";

const useSeleccionarPostulantes=()=>{    
    const[,setPostulantesSeleccionados] = useAtom(postulantesSeleccionadosAtom);

    const seleccionarPostulante = (postulante,Option)=>{
      //console.log("postulante seleccionado",postulante);
      setPostulantesSeleccionados(postulante);    

    }
  return{seleccionarPostulante}
}
export default useSeleccionarPostulantes;