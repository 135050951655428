import React, { useContext, useState } from "react";
import { Button, Menu, Dropdown, Modal, notification } from "antd";
import { SecurityContext } from "context/SecurityContextProvider";
import {
  QuestionCircleOutlined,
  SyncOutlined,
  MailOutlined,
  UnorderedListOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { SeguimientoService } from "services/SeguimientoService";
import { useAtom } from "jotai";
import { useParams } from "react-router-dom";
import {
  datosPaginacionAtom,
  detalleEnvioSeguimientoAtom,
  documentosConErrorAtom,
  documentosSeleccionadoAtom,
} from "../../ListarDetalleSeguimientoStore";

import { DocumentoLaboralService } from "services/DocumentoLaboralService";

const BotonesGlobales = ({
  setResumenData,
  categoria,
  tipoDocumentoId,
  tipoDocumentoNombre,
  setReset,
  pendienteAprobacion,
}) => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const { envioId } = useParams();
  const [documentosSeleccionados, setDocumentosSeleccionados] = useAtom(documentosSeleccionadoAtom);
  const [documentosConError] = useAtom(documentosConErrorAtom);
  const [, setDetalleEnvio] = useAtom(detalleEnvioSeguimientoAtom);
  const [, setDatosPaginacion] = useAtom(datosPaginacionAtom);

  const onClickBtnRefrescar = async () => {
    setReset(true);
    const detalleEnvios = await DocumentoLaboralService.listar(
      getUsuarioAutenticado().empresaSeleccionada.id,
      envioId,
      null,
      null,
      null,
      null,
      getUsuarioAutenticado().login,
      true
    );
    setDetalleEnvio(detalleEnvios.detalles);
    setResumenData(detalleEnvios);
    setReset(false);
    setDatosPaginacion({ paginaActual: 1, registrosPorPagina: 30 });
  };

  const mostralModalNotificarCorreoElectronicoMasiva = () => {
    Modal.confirm({
      bodyStyle: { display: "flex", flexDirection: "column" },
      icon: <QuestionCircleOutlined style={{ fontSize: "32px" }} />,
      content:
        documentosSeleccionados.length > 0 ? (
          <span style={{ textAlign: "center", display: "block", fontSize: "16px" }}>
            ¿Está seguro de <b>reenviar la notificación</b> de los (<b>{documentosSeleccionados?.length}</b>) documentos
            seleccionados?
          </span>
        ) : (
          <span style={{ textAlign: "center", display: "block", fontSize: "16px" }}>
            ¿Está seguro de <b>reenviar la notificación</b> de los (<b>{documentosConError?.length}</b>) documentos con
            error?
          </span>
        ),
      autoFocusButton: "cancel",
      centered: true,
      maskClosable: true,
      cancelText: "No, cerrar ventana",
      okText: "Si, notificar documentos",
      onOk: async () => {
        try {
          setDocumentosSeleccionados([]);
          await SeguimientoService.reenviarNotificacionMasivamente(
            getUsuarioAutenticado().organizacionId,
            getUsuarioAutenticado().empresaSeleccionada.id,
            envioId,
            documentosSeleccionados.length > 0 ? documentosSeleccionados : documentosConError,
            categoria,
            tipoDocumentoId,
            tipoDocumentoNombre
          );

          notification.success({
            message: `Notificación`,
            description: "Se procedio a realizar las notificaciones.",
            placement: "topRight",
          });
        } catch (error) {
          setDocumentosSeleccionados([]);
          console.error(error);
        }
      },
      afterClose: () => {
        setDocumentosSeleccionados([]);
      },
    });
  };

  const mostrarModalReprocesar = () => {
    Modal.confirm({
      bodyStyle: { display: "flex", flexDirection: "column" },
      icon: <QuestionCircleOutlined style={{ fontSize: "32px" }} />,
      content: (
        <span style={{ textAlign: "center", display: "block", fontSize: "16px" }}>
          ¿Está seguro de <b>reprocesar</b> los documentos seleccionados?
        </span>
      ),
      autoFocusButton: "cancel",
      centered: true,
      maskClosable: true,
      cancelText: "No, cerrar ventana",
      okText: "Si, reprocesar documentos",
      onOk: async () => {
        try {
          await SeguimientoService.reenviarNotificacionMasivamente(
            getUsuarioAutenticado().organizacionId,
            getUsuarioAutenticado().empresaSeleccionada.id,
            envioId,
            documentosConError,
            categoria,
            tipoDocumentoId,
            tipoDocumentoNombre
          );

          notification.success({
            message: `Notificación`,
            description: "Se procedio a reprocesar las notificaciones masivamente.",
            placement: "topRight",
          });
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const onClickMenuAcciones = (opcion) => {
    console.log(documentosConError);
    if (opcion.key === "reenviar-correo-masivo") {
      mostralModalNotificarCorreoElectronicoMasiva();
    } else if (opcion.key === "reprocesar") {
      mostrarModalReprocesar();
    }
  };

  const menu = (
    <Menu onClick={onClickMenuAcciones}>
      <Menu.Item
        key="reenviar-correo-masivo"
        disabled={documentosConError?.length === 0 ? (documentosSeleccionados?.length === 0 ? true : false) : false}
      >
        <MailOutlined /> Reenviar Notificación
      </Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      <Button type="link" onClick={onClickBtnRefrescar}>
        <SyncOutlined /> Refrescar
      </Button>

      {pendienteAprobacion && (
        <Dropdown overlay={menu} trigger={["click"]}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <UnorderedListOutlined /> Acciones <CaretDownOutlined />
          </a>
        </Dropdown>
      )}
    </React.Fragment>
  );
};

export default BotonesGlobales;
