import React from "react";
import { Steps, Modal } from "antd";
import { cssBodyVisorPdf, cssVisorPdf } from "./ModalFirmarStyles"
import { useModalFirmarStore } from "hooks/firma-documento-generico";
import { StepPosicionFirma, StepCertificadoDigital, StepFirmarDocumento } from "./componentes"

const ModalFirmar = () => {

    const visibleModalFirmar = useModalFirmarStore(state => state.visibleModalFirmar);
    const cerrarModalVisorFirma = useModalFirmarStore(state => state.cerrarModalVisorFirma);
    const stepSeleccionado = useModalFirmarStore(state => state.stepSeleccionado);

    return (
        <Modal
            title={null}
            visible={visibleModalFirmar}
            onCancel={cerrarModalVisorFirma}
            bodyStyle={cssBodyVisorPdf}
            style={cssVisorPdf}
            closable={false}
            footer={null}
            className="milegajo-ant-modal"
            width="calc(100vw - 60px)"
            height="calc(100vh - 60px)"
            destroyOnClose={true}
        >

            <div className="milegajo-visor-pdf-firma-header">
                <Steps current={stepSeleccionado} percent={50} style={{ padding: '0 100px' }}>
                    <Steps.Step title="Posicionar Firma" />
                    <Steps.Step title="Certificado Digital" />
                    <Steps.Step title="Firmar" />
                </Steps>
            </div>

            <div className="milegajo-visor-pdf-firma-body">
                {stepSeleccionado === 0 && <StepPosicionFirma />}

                {stepSeleccionado === 1 && <StepCertificadoDigital />}

                {stepSeleccionado === 2 && <StepFirmarDocumento />}
            </div>


        </Modal >
    )
}

export default ModalFirmar;