import { atom } from "jotai";

export const contratosAtom = atom([]);
export const contratoSeleccionadoAtom = atom(undefined);
export const visibleModalAprobarContratoAtom = atom(false);
export const visibleModalRechazarContratoAtom = atom(false);
export const visibleModalVisorPdfAtom = atom(false);
export const urlPdfContratoAtom = atom(undefined);
//export const contratosSeguimientoAtom= atom([]);
export const contratosSeguimientoAtom = atom({});
export const loadingContratosSeguimientos = atom(false);
export const filtroAtom = atom(undefined);

// export const contratosNuevosIngresosAtom = atom([]);
// export const visibleModalRechazarContratoAtom = atom(false);
// export const contratoNuevoSeleccionadoAtom = atom();

// export const contratosRenovacionAtom = atom([]);

export const visibleModalTransferirProyectoAtom = atom(false);
export const filtroProyectoAtom = atom(null);
export const filtroRespRenovacionAtom = atom(null);
export const filtroEstadoAtom = atom(null);
export const filtroEstadoFirmaAtom = atom(null);
export const filtroFechaInicioAtom = atom(null);
export const filtroFechaFinAtom = atom(null);
export const proyectosAtom = atom([]);
export const filtroColaboradorAtom = atom(undefined);
export const filtroEstadoColaboradorAtom = atom(null);
export const visibleModalActualizarResponsablesAtom = atom(false);