import React, { useContext, useState,useEffect} from "react";
import { Form, Input, Button,Row,Col,Select } from "antd";
import { useModalConfirmar } from "store/global"
import { SecurityContext } from "context/SecurityContextProvider";
import * as atoms from "../EditarTipoPlanillaStore";
import { useAtom } from "jotai";
import { useParams} from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import TipoPlanillaService from "../../../../services/TipoPlanillaService";
import {CampoColaborador} from "enums/CampoColaborador";
import SubsedeService from "services/SubsedeService";
import PerfilService from "services/PerfilService";
import SedeService from "services/SedeService";
import SeccionService from "services/SeccionService";
import CargoService from "services/CargoService";

const TipoPlanillaEditar = () => {

    let { idTipoPlanilla } = useParams();
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const[,setTipoPlanilla]=useAtom(atoms.tipoPlanillaAtom);   
    const mostrarModalConfirmacion = useModalConfirmar(state => state.mostrarModal);
    const [formularioTipoPlanilla]=Form.useForm();

    const [dependenciaTipoPlanillaAtom,setDependenciaTipoPlanillaAtom]=useAtom(atoms.dependenciaTipoPlanilla);     
    const [listaCamposAtom,setListaCamposAtom]=useAtom(atoms.listaCampos);
    const [perfilesAtom,setPerfilesAtom]=useAtom(atoms.perfiles);
    const [sedesAtom,setSedesAtom]=useAtom(atoms.sedes);
    const [subsedesAtom,setSubsedesAtom]=useAtom(atoms.subsedes);
    const [seccionesAtom,setSeccionesAtom]=useAtom(atoms.secciones);
    const [cargosAtom,setCargosAtom]=useAtom(atoms.cargos);
    const [camposColaboradorAtom,setCamposColaboradorAtom]=useAtom(atoms.camposColaborador);
    const { Option } = Select;

    useEffect(()=>{
        const cargarDatos= async()=>{
            try {    
              const tipoPlanillaObtenida= await TipoPlanillaService.buscarPorId(idTipoPlanilla);

              formularioTipoPlanilla.setFieldsValue(
                    {   id:tipoPlanillaObtenida.id,
                        nombre:tipoPlanillaObtenida.nombre,                        
                    }
                )
              var listaCampos=[],dependencia;
              var perfilId, sedeId, subsedeId,seccionId,cargoId;   
              const empresa = getUsuarioAutenticado().empresaSeleccionada.id;
              const campos_colaborador=getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
              setCamposColaboradorAtom(campos_colaborador);
              const campo_tipoPlanilla= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.PLANILLA);

              const dependenciaTipoPlanilla=campo_tipoPlanilla.campoDependencia;   

              
              if(dependenciaTipoPlanilla!==null){
                    if(dependenciaTipoPlanilla===CampoColaborador.PERFIL){                                                                 
                      perfilId=tipoPlanillaObtenida.perfilId;
                    }
                    else if(dependenciaTipoPlanilla===CampoColaborador.SEDE){
                          sedeId=tipoPlanillaObtenida.sedeId;
                    }
                    else if(dependenciaTipoPlanilla===CampoColaborador.SUBSEDE){
                          subsedeId=tipoPlanillaObtenida.subsedeId;                    
                    }
                    else if(dependenciaTipoPlanilla===CampoColaborador.SECCION){
                          seccionId=tipoPlanillaObtenida.seccionId;
                    }
                    else if(dependenciaTipoPlanilla===CampoColaborador.CARGO){
                          cargoId=tipoPlanillaObtenida.cargoId;
                    }     

                    setDependenciaTipoPlanillaAtom(dependenciaTipoPlanilla);
                    dependencia=dependenciaTipoPlanilla;
                    listaCampos.push(dependencia);

                          while(dependencia!==null){
                              var campo= campos_colaborador.find(campoColaborador=>campoColaborador.campo===dependencia);
                              dependencia=campo.campoDependencia;
                              if(dependencia!==null){
                                  listaCampos.push(dependencia);                                
                                  if(dependencia===CampoColaborador.PERFIL){                                                                 
                                    perfilId=tipoPlanillaObtenida.perfilId;                                    
                                  }
                                  else if(dependencia===CampoColaborador.SEDE){
                                        sedeId=tipoPlanillaObtenida.sedeId;
                                  }
                                  else if(dependencia===CampoColaborador.SUBSEDE){
                                        subsedeId=tipoPlanillaObtenida.subsedeId;                    
                                  }
                                  else if(dependencia===CampoColaborador.SECCION){
                                        seccionId=tipoPlanillaObtenida.seccionId;
                                  }
                                  else if(dependencia===CampoColaborador.CARGO){
                                        cargoId=tipoPlanillaObtenida.cargoId;
                                  }     
                              }
                          
                          }
                        setListaCamposAtom(listaCampos.reverse());  
                   
                }
                else{  
                  setListaCamposAtom(listaCampos);         
                  setDependenciaTipoPlanillaAtom(CampoColaborador.SINCAMPO);         
                }
                if(listaCampos.length>0){
                      
                  for(let i=0 ; i < listaCampos.length ; i++){
                    
                    if (listaCampos[i]===CampoColaborador.SEDE){ 
                        const campo_sede= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.SEDE);
                        const sedeDependencia=campo_sede.campoDependencia;             
                        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                        var _sedes;
                        if(sedeDependencia===CampoColaborador.PERFIL){
                            _sedes = sedes.filter(sede=>sede.perfilId===perfilId)
                        } 
                        else if(sedeDependencia===CampoColaborador.SUBSEDE){
                           _sedes = sedes.filter(sede=>sede.subsedelId===subsedeId)     

                        }    
                        else if(sedeDependencia===CampoColaborador.SECCION){
                            _sedes = sedes.filter(sede=>sede.seccionId===seccionId)

                        } 
                        else if(sedeDependencia===CampoColaborador.CARGO){

                             _sedes = sedes.filter(sede=>sede.cargoId===cargoId)

                        }  
                        else{
                             _sedes=sedes;
                        }                      
                        setSedesAtom(_sedes);  
                        formularioTipoPlanilla.setFieldsValue({ sedeId:sedeId})
                            
                        
                      }    
                    else if(listaCampos[i]===CampoColaborador.PERFIL){
                        
                        const campo_perfil= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.PERFIL);
                        const perfilDependencia=campo_perfil.campoDependencia;             
                        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                        var _perfiles;
                        if(perfilDependencia===CampoColaborador.SEDE){
                            _perfiles = perfiles.filter(perfil=>perfil.sedeId===sedeId)
                        } 
                        else if(perfilDependencia===CampoColaborador.SUBSEDE){
                            _perfiles = perfiles.filter(perfil=>perfil.subsedeId===subsedeId)     

                        }    
                        else if(perfilDependencia===CampoColaborador.SECCION){
                            _perfiles = perfiles.filter(perfil=>perfil.seccionId===seccionId)

                        } 
                        else if(perfilDependencia===CampoColaborador.CARGO){

                            _perfiles = perfiles.filter(perfil=>perfil.cargoId===cargoId)

                        }  
                        else{
                             _perfiles=perfiles;
                        }                      
                        setPerfilesAtom(_perfiles);                        
                        formularioTipoPlanilla.setFieldsValue({ perfilId:perfilId})
                    }
                    else if(listaCampos[i]===CampoColaborador.SUBSEDE){
                        
                        const campo_subsede= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.SUBSEDE);
                        const subsedeDependencia=campo_subsede.campoDependencia;             
                        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                        var _subsedes;
                        if(subsedeDependencia===CampoColaborador.SEDE){
                            _subsedes = subsedes.filter(subsede=>subsede.sedeId===sedeId)
                        } 
                        else if(subsedeDependencia===CampoColaborador.PERFIL){
                            _subsedes = subsedes.filter(subsede=>subsede.perfilId===perfilId)     

                        }    
                        else if(subsedeDependencia===CampoColaborador.SECCION){
                            _subsedes = subsedes.filter(subsede=>subsede.seccionId===seccionId)

                        } 
                        else if(subsedeDependencia===CampoColaborador.CARGO){

                            _subsedes = subsedes.filter(subsede=>subsede.cargoId===cargoId)

                        }  
                        else{
                             _subsedes=subsedes;
                        }                      
                        setSubsedesAtom(_subsedes); 
                        
                        formularioTipoPlanilla.setFieldsValue({ subsedeId:subsedeId})
                    }
                    else if(listaCampos[i]===CampoColaborador.SECCION){
                        
                        const campo_seccion= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.SECCION);
                        const seccionDependencia=campo_seccion.campoDependencia;             
                        const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                        var _secciones;
                        if(seccionDependencia===CampoColaborador.SEDE){
                            _secciones = secciones.filter(seccion=>seccion.sedeId===sedeId)
                        } 
                        else if(seccionDependencia===CampoColaborador.PERFIL){
                            _secciones = secciones.filter(seccion=>seccion.perfilId===perfilId)     

                        }    
                        else if(seccionDependencia===CampoColaborador.SUBSEDE){
                            _secciones = secciones.filter(seccion=>seccion.subsedeId===subsedeId)

                        } 
                        else if(seccionDependencia===CampoColaborador.CARGO){

                            _secciones = secciones.filter(seccion=>seccion.cargoId===cargoId)

                        }  
                        else{
                             _secciones=secciones;
                        }                      
                        setSeccionesAtom(_secciones); 
                        
                        formularioTipoPlanilla.setFieldsValue({ seccionId:seccionId})
                    }
                    else if(listaCampos[i]===CampoColaborador.CARGO){
                        
                        const campo_cargo= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.CARGO);
                        const cargoDependencia=campo_cargo.campoDependencia;             
                        const cargos= await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                        var _cargos;
                        if(cargoDependencia===CampoColaborador.SEDE){
                            _cargos = cargos.filter(cargo=>cargo.sedeId===sedeId)
                        } 
                        else if(cargoDependencia===CampoColaborador.PERFIL){
                            _cargos = cargos.filter(cargo=>cargo.perfilId===perfilId)     

                        }    
                        else if(cargoDependencia===CampoColaborador.SUBSEDE){
                            _cargos = cargos.filter(cargo=>cargo.subsedeId===subsedeId)

                        } 
                        else if(cargoDependencia===CampoColaborador.SECCION){
                            _cargos = cargos.filter(cargo=>cargo.seccionId===seccionId)

                        }  
                        else{
                             _cargos=cargos;
                        }                      
                        setCargosAtom(_cargos); 
                        
                        formularioTipoPlanilla.setFieldsValue({ cargoId:cargoId})
                    }
                  }
              }        

            } catch (error) {
                console.error(error);
            }

        }

        cargarDatos();        

    },[]);
    
    const onClickBtnActualizarTipoPlanilla=async(tipoPlanilla)=>{              
        tipoPlanilla.empresaId=getUsuarioAutenticado().empresaSeleccionada.id;      
        tipoPlanilla.dependencia=dependenciaTipoPlanillaAtom;     
        setTipoPlanilla( tipoPlanilla );
        mostrarModalConfirmacion();

    }
    const handleOnchangePerfil = async(value,option)=>{

      let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.PERFIL);
  
      for(let i=0 ; i < listaCampos.length ; i++){
         if (listaCampos[i].campo===CampoColaborador.SEDE){         
           const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _sedes = sedes.filter(sede=>sede.perfilId===value)
           setSedesAtom(_sedes);  
           formularioTipoPlanilla.setFieldsValue({ sedeId:""})
               
           
         }         
         else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
           const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _subsedes = subsedes.filter(subsede=>subsede.perfilId===value);
           setSubsedesAtom(_subsedes);
           formularioTipoPlanilla.setFieldsValue({ subsedeId:""})
          
         }
         else if(listaCampos[i].campo===CampoColaborador.CARGO){
           const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _cargos = cargos.filter(cargo=>cargo.perfilId===value);
           setCargosAtom(_cargos);
           formularioTipoPlanilla.setFieldsValue({ cargoId:""})
           
         }
         else if(listaCampos[i].campo===CampoColaborador.SECCION){
           const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _secciones = secciones.filter(seccion=>seccion.perfilId===value);
           setSeccionesAtom(_secciones);
           formularioTipoPlanilla.setFieldsValue({ seccionId:""})
           
         }
        }   
    }
    const handleOnchangeSede = async(value,option)=>{
      let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.SEDE);
  
      for(let i=0 ; i < listaCampos.length ; i++){
         if (listaCampos[i].campo===CampoColaborador.PERFIL){         
           const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _perfiles = perfiles.filter(perfil=>perfil.sedeId===value)
           setPerfilesAtom(_perfiles);  
           formularioTipoPlanilla.setFieldsValue({ perfilId:""})
               
           
         }         
         else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
           const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _subsedes = subsedes.filter(subsede=>subsede.sedeId===value);
           setSubsedesAtom(_subsedes);
           formularioTipoPlanilla.setFieldsValue({ subsedeId:""})
          
         }
         else if(listaCampos[i].campo===CampoColaborador.CARGO){
           const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _cargos = cargos.filter(cargo=>cargo.sedeId===value);
           setCargosAtom(_cargos);
           formularioTipoPlanilla.setFieldsValue({ cargoId:""})
           
         }
         else if(listaCampos[i].campo===CampoColaborador.SECCION){
           const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _secciones = secciones.filter(seccion=>seccion.sedeId===value);
           setSeccionesAtom(_secciones);
           formularioTipoPlanilla.setFieldsValue({ seccionId:""})
           
         }
        }   
    }
    const handleOnchangeSubsede = async(value,option)=>{
      let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.SUBSEDE);
  
      for(let i=0 ; i < listaCampos.length ; i++){
         if (listaCampos[i].campo===CampoColaborador.PERFIL){         
           const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _perfiles = perfiles.filter(perfil=>perfil.subsedeId===value)
           setPerfilesAtom(_perfiles);  
           formularioTipoPlanilla.setFieldsValue({ perfilId:""})
               
           
         }         
         else if(listaCampos[i].campo===CampoColaborador.SEDE){
           const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _sedes = sedes.filter(sede=>sede.subsedeId===value);
           setSedesAtom(_sedes);
           formularioTipoPlanilla.setFieldsValue({ sedeId:""})
          
         }
         else if(listaCampos[i].campo===CampoColaborador.CARGO){
           const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _cargos = cargos.filter(cargo=>cargo.subsedeId===value);
           setCargosAtom(_cargos);
           formularioTipoPlanilla.setFieldsValue({ cargoId:""})
           
         }
         else if(listaCampos[i].campo===CampoColaborador.SECCION){
           const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _secciones = secciones.filter(seccion=>seccion.subsedeId===value);
           setSeccionesAtom(_secciones);
           formularioTipoPlanilla.setFieldsValue({ seccionId:""})
           
         }
        }   
      
    }
    const handleOnchangeSeccion = async(value,option)=>{
      let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.SECCION);
  
      for(let i=0 ; i < listaCampos.length ; i++){
         if (listaCampos[i].campo===CampoColaborador.PERFIL){         
           const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _perfiles = perfiles.filter(perfil=>perfil.seccionId===value)
           setPerfilesAtom(_perfiles);  
           formularioTipoPlanilla.setFieldsValue({ perfilId:""})
               
           
         }         
         else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
           const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _subsedes = subsedes.filter(subsede=>subsede.seccionId===value);
           setSubsedesAtom(_subsedes);
           formularioTipoPlanilla.setFieldsValue({ subsedeId:""})
          
         }
         else if(listaCampos[i].campo===CampoColaborador.CARGO){
           const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _cargos = cargos.filter(cargo=>cargo.seccionId===value);
           setCargosAtom(_cargos);
           formularioTipoPlanilla.setFieldsValue({ cargoId:""})
           
         }
         else if(listaCampos[i].campo===CampoColaborador.SEDE){
           const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _sedes =  sedes.filter(sede=>sede.seccionId===value);
           setSedesAtom(_sedes);
           formularioTipoPlanilla.setFieldsValue({ sedeId:""})
           
         }
        }   

    }
    const handleOnchangeCargo = async(value,option)=>{
      let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.CARGO);
  
      for(let i=0 ; i < listaCampos.length ; i++){
         if (listaCampos[i].campo===CampoColaborador.PERFIL){         
           const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _perfiles = perfiles.filter(perfil=>perfil.cargoId===value)
           setPerfilesAtom(_perfiles);  
           formularioTipoPlanilla.setFieldsValue({ perfilId:""})
               
           
         }         
         else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
           const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _subsedes = subsedes.filter(subsede=>subsede.cargoId===value);
           setSubsedesAtom(_subsedes);
           formularioTipoPlanilla.setFieldsValue({ subsedeId:""})
          
         }
         else if(listaCampos[i].campo===CampoColaborador.SECCION){
           const secciones=await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _secciones = secciones.filter(seccion=>seccion.cargoId===value);
           setSeccionesAtom(_secciones);
           formularioTipoPlanilla.setFieldsValue({ seccionId:""})
           
         }
         else if(listaCampos[i].campo===CampoColaborador.SEDE){
           const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _sedes =  sedes.filter(sede=>sede.cargoId===value);
           setSedesAtom(_sedes);
           formularioTipoPlanilla.setFieldsValue({ sedeId:""})
           
         }
        }   
    }

    return(
        <Form
            layout="vertical"                      
            form={formularioTipoPlanilla}
            onFinish={onClickBtnActualizarTipoPlanilla}
        >
            <Form.Item 
              label="id"
              name="id"
              noStyle
            >
             <Input type="hidden" />
            </Form.Item>
            {
              listaCamposAtom.length>0 &&(
                listaCamposAtom.map((listaCampo)=>{
                    if (listaCampo===CampoColaborador.PERFIL){   
                      return(
                            <Row>
                                    <Col span={12} offset={6}>
                                    <Form.Item
                                        label="Perfil"
                                        name="perfilId"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Seleccione Perfil"
                                          },
                                        ]}
                                    >
                                      <Select
                                      placeholder="Seleccione Perfil"
                                      onChange={handleOnchangePerfil}
                                      >
                                      {perfilesAtom.length > 0 &&
                                        perfilesAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                      </Select>

                                    </Form.Item>
                                    </Col>
                          </Row>
                      )       
                      }                                             
                      
                      else if (listaCampo===CampoColaborador.SEDE){
                        return(
                          <Row>
                                    <Col span={12} offset={6}>
                                    <Form.Item
                                        label="Sede"
                                        name="sedeId"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Seleccione Sede"
                                          },
                                        ]}
                                    >
                                      <Select
                                      placeholder="Seleccione Sede"
                                      onChange={handleOnchangeSede}
                                      >
                                      {sedesAtom.length > 0 &&
                                        sedesAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                        
                                      </Select>

                                    </Form.Item>
                                    </Col>
                          </Row>
                        )
                      }
                      else if (listaCampo===CampoColaborador.SUBSEDE){
                        return(
                          <Row>
                                <Col span={12} offset={6}>
                                <Form.Item
                                    label="Subsede"
                                    name="subsedeId"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Seleccione Subsede"
                                      },
                                    ]}
                                >
                                  <Select
                                  placeholder="Seleccione Subsede"
                                  onChange={handleOnchangeSubsede}
                                  >
                                  {subsedesAtom.length > 0 &&
                                        subsedesAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                  </Select>

                                </Form.Item>
                                </Col>
                        </Row>
                        )
                      }
                      else if (listaCampo===CampoColaborador.SECCION){
                        return(
                          <Row>
                                <Col span={12} offset={6}>
                                <Form.Item
                                    label="Sección"
                                    name="seccionId"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Seleccione sección"
                                      },
                                    ]}
                                >
                                  <Select
                                  placeholder="Seleccione sección"
                                  onChange={handleOnchangeSeccion}
                                  >
                                  {seccionesAtom.length > 0 &&
                                        seccionesAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                  </Select>

                                </Form.Item>
                                </Col>
                        </Row>
                        )
                
                      }
                      else if (listaCampo===CampoColaborador.CARGO){
                        return(
                          <Row>
                                <Col span={12} offset={6}>
                                <Form.Item
                                    label="Cargo"
                                    name="cargoId"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Seleccione Cargo"
                                      },
                                    ]}
                                >
                                  <Select
                                    placeholder="Seleccione Cargo"
                                    onChange={handleOnchangeCargo}
                                  >
                                  {cargosAtom.length > 0 &&
                                        cargosAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                  </Select>

                                </Form.Item>
                                </Col>
                        </Row>
                        )             
                      }                      
                                        
                })
                
              )           
            
          }     
            <Row>
                <Col span={12} offset={6}>
                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        rules={[
                            {
                                required: true,
                                message: "Escribe el nombre de tipo planilla.",
                            },
                        ]}   
                    >
                        <Input/>
                    </Form.Item>
               </Col>
            </Row>
            <Form.Item style={{ textAlign: "center" }} >
                <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    htmlType="submit"
                >
                    Actualizar Tipo Planilla
                </Button>
            </Form.Item>
        </Form>
    )

}
export default TipoPlanillaEditar;