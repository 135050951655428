import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "../../../components/Container";
import ModalCrearCertificado from "../crear/index";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";

import { Button, Space, Table, notification, Popconfirm } from "antd";

import {
  UnorderedListOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { LoginService } from "services/LoginService";
import CertificadoService from "../../../services/CertificadoService";

export function MisCertificadosListarPage() {
  const [certificados, setCertificados] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  //const [fileList, setFileList] = useState([]);
  const [listaDocumentos, setListaDocumentos] = useState([]);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  useEffect(() => {
    async function cargarDatos() {
      const _certificados = await CertificadoService.listar(
        getUsuarioAutenticado().empresaSeleccionada.id,
        getUsuarioAutenticado().id,
        '1'
      );
      setCertificados(_certificados);
    }

    cargarDatos();
  }, []);

  function handleOnClickCrearCertificado() {
    setVisibleModal(true);
  }

  function cerrarModal() {
    setVisibleModal(false);
  }

  async function handleOnFinish(formulario) {
    const formData = new FormData();

    try {
      setLoading(true);
      //formData.append('file', listaDocumentos);
      formData.append("archivo", formulario.archivo.file);
      formData.append("clave", formulario.clave);
      formData.append(
        "empresaId",
        getUsuarioAutenticado().empresaSeleccionada.id
      );
      formData.append("usuarioId", getUsuarioAutenticado().id);
      formData.append("estado", "1");

      const _certificados = await CertificadoService.insertar(formData);
      setCertificados(_certificados);
      notification.success({
        message: "Operación exitosa",
        description: "Se registró el certificado correctamente.",
      });
      setVisibleModal(false);
      setLoading(false);
      //history.push("/cargo");
    } catch (error) {
      notification.error({
        message: "Alerta!",
        description: error.response.data.mensaje,
      });
      setLoading(false);
    }
  }

  async function handleOnClickQuitarCertificado(certificadoId) {
    try {
      const certificados = await CertificadoService.eliminar(
        getUsuarioAutenticado().empresaSeleccionada.id,
        getUsuarioAutenticado().id,
        certificadoId
      );
      setCertificados(certificados);
      notification.success({
        message: "Operación exitosa",
        description: "Se registró el certificado correctamente.",
      });
    } catch (error) {
      notification.error({
        message: "Alerta!",
        description: error.response.data.mensaje,
      });
    }
  }

  const columnas = [
    {
      title: "Serie",
      dataIndex: "serie",
      align: "center",
    },
    {
      title: "Representante Legal",
      dataIndex: "representanteLegalNombre",
      align: "center",
    },
    {
      title: "Fecha Vigencia Inicio",
      dataIndex: "fechaVigenciaInicio",
      align: "center",
    },
    {
      title: "Fecha Vigencia Fin",
      dataIndex: "fechaVigenciaFin",
      align: "center",
    },

    {
      title: "Acciones",
      align: "center",
      key: "acciones",
      align: "center",
      render: (text, fila) => (
        <Space size={8}>
          {/* <a
            onClick={() => handleOnClickEditarUsuario(fila.id)}
            className="custom-color-element"
          >
            <EditOutlined /> Editar
          </a> */}

          <Popconfirm
            title="¿Está seguro de eliminar el registro seleccionado?"
            okText="Si"
            cancelText="No"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => handleOnClickQuitarCertificado(fila.id)}
          >
            <a style={{ color: "#E01D4C" }}>
              {" "}
              <DeleteOutlined /> Quitar
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Container
      icono={<UnorderedListOutlined />}
      titulo="Mis certificados"
      botonExtra={
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleOnClickCrearCertificado}
        >
          Subir Certificado Digital
        </Button>
      }
    >
      <Table
        rowKey={(fila) => fila.id}
        columns={columnas}
        dataSource={certificados}
        pagination={{
          pageSize: [10],
        }}
      />

      <ModalCrearCertificado
        loading={loading}
        visibleModal={visibleModal}
        onCerrarModal={cerrarModal}
        listaDocumentos={listaDocumentos}
        setListaDocumentos={setListaDocumentos}
        handleOnFinish={handleOnFinish}
      />
    </Container>
  );
}
