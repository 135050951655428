import React, { useState, useEffect, useRef } from "react";
import { Container } from "../../../../components/Container";

import {
  UnorderedListOutlined,
  QuestionCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";

import { Table, Space, Button, Popconfirm, notification, Input } from "antd";
import { PlantillaContratoService } from "services/PlantillaContratoService";
import { useHistory } from "react-router-dom";

import Highlighter from "react-highlight-words";
import { useContext } from "react";
import { SecurityContext } from "../../../../context/SecurityContextProvider";

export function PlantillaContratoListarPage() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [plantillasContrato, setPlantillasContrato] = useState([]);

  const { getUsuarioAutenticado } = useContext(SecurityContext);

  let history = useHistory();
  useEffect(() => {
    async function cargarDatos() {
      const plantillas = await PlantillaContratoService.listarNuevo(
        getUsuarioAutenticado().empresaSeleccionada.id
      );
      setPlantillasContrato(plantillas);
    }

    cargarDatos();
  }, []);

  var adicionarColumnasFiltro = function getColumns(
    dataIndex,
    placeholder,
    propiedad
  ) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={
              placeholder !== undefined
                ? placeholder
                : `Buscar por ${dataIndex}`
            }
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
                </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
                </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        propiedad === undefined
          ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
          : record[dataIndex][propiedad]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={
              propiedad === undefined
                ? text?.toString()
                : text[propiedad]?.toString()
            }
          />
        ) : propiedad === undefined ? (
          text
        ) : (
              text[propiedad]
            ),
    };
  };

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText("");
  }
  const columnas = [

    {
      title: "Descripción",
      dataIndex: "nombre",
      align: "center",
      ...adicionarColumnasFiltro("descripcion"),
    },
    {
      title: "Acciones",
      align: "center",
      key: "acciones",
      align: "center",
      render: (fila) => (
        <Space size={8}>
          <a
            onClick={() => handleOnClickEditarPlantillaContrato(fila.id)}
            className="custom-color-element"
          >
            <EditOutlined /> Editar
                </a>
          <Popconfirm
            title="¿Está seguro de eliminar el registro seleccionado?"
            okText="Si"
            cancelText="No"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => handleOnClickEliminarPlantillaContrato(fila.id)}
          >
            <a style={{ color: "#CE3E3E" }}>
              {" "}
              <DeleteOutlined /> Eliminar
                  </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];
  function handleOnClickEditarPlantillaContrato(idPlantilla) {
    history.push(`/contrato/plantilla-contrato/editar/${idPlantilla}`)

  }
  async function handleOnClickEliminarPlantillaContrato(idPlantilla) {
    await PlantillaContratoService.eliminar(idPlantilla);
    setPlantillasContrato(
      plantillasContrato.filter((plantilla) => plantilla.id !== idPlantilla)
    );
    notification.success({
      message: "Operación exitosa",
      description: "Se eliminó el registro correctamente.",
    });


  }
  function handleOnClickCrearPlantillaContrato() {
    history.push("/contrato/plantilla-contrato/crear");
  }
  return (
    <Container icono={<UnorderedListOutlined />} titulo="Plantilla Contrato" botonExtra={<Button
      type="primary"
      icon={<PlusOutlined />}
      onClick={handleOnClickCrearPlantillaContrato}
    >
      Crear Plantilla Contrato
          </Button>}>

      <Table
        rowKey={(fila) => fila.id}
        columns={columnas}
        dataSource={plantillasContrato}
        pagination={{
          pageSize: [10],
        }}
      />
    </Container>
  );
}