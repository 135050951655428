import React, { useContext } from "react";
import { Dropdown, Menu, Button } from "antd";
import { UnorderedListOutlined, CaretDownOutlined, SelectOutlined, FileExcelOutlined, FilePdfOutlined, CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import FormularioSeguimientoService from "services/FormularioSeguimientoService";
import { saveAs } from "file-saver";
import { SecurityContext } from "context/SecurityContextProvider";
import { useAtom } from "jotai";
import { useModalAsignarCarpeta } from "store/global";
import * as atoms from "../SeguimientoFormularioStore";
import { ModalAsignarCarpeta } from "components/Modal/ModalAsignarCarpeta";

const MenuAcciones = ({ envio }) => {

    let history = useHistory();
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const mostrarModalAsignarCarpeta = useModalAsignarCarpeta(state => state.mostrarModal);
    const [formularioSeleccionado, setFormularioSeleccionado] = useAtom(atoms.formularioSeleccionado);
    const cerrarModalAsignarCarpeta = useModalAsignarCarpeta(state => state.cerrarModal);

    const onClickMenu = (opcion) => {
        switch (opcion.key) {
            case 'ver-formularios':
                onClickMenuVerContratos();
                break;

            case 'exportar-datos-excel':
                onClickMenuExportarDatosExcel();
                break;

            case 'asignar-carpeta':
                onClickAsignarCarpeta();
                break;

            default:
                console.error("No se encuentra esa opción");;
        }
    }
    const onClickMenuVerContratos = () => {
        history.push(`/notificacion/formulario/seguimiento/${envio.id}/detalle`);
    }

    const onClickMenuExportarDatosExcel = async () => {
        const formData = new FormData();
        formData.append("envioId", envio.id);
        formData.append("empresaId", getUsuarioAutenticado().empresaSeleccionada.id);

        let respuesta = await FormularioSeguimientoService.exportarDatosExcel(formData);
        const filename = respuesta.headers.filename;

        saveAs(respuesta.data, filename);

    }
    const onClickAsignarCarpeta = () => {
        setFormularioSeleccionado(envio);
        mostrarModalAsignarCarpeta();

    }
    const onClickBtnCancelar = () => {
        cerrarModalAsignarCarpeta();
    }
    const menu = () => {

        return (
            <Menu onClick={onClickMenu}>
                <Menu.Item key="ver-formularios">
                    <SelectOutlined /> Ver Formularios
                </Menu.Item>
                <Menu.Item key="exportar-datos-excel">
                    <FileExcelOutlined /> Exportar Datos
                </Menu.Item>
                <Menu.Item key="asignar-carpeta">
                    <SelectOutlined />Asignar Carpeta
                </Menu.Item>
            </Menu>
        )
    }
    return (
        <React.Fragment>
            <Dropdown
                overlay={menu}
                trigger={['click']}
                placement="bottomCenter">

                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <UnorderedListOutlined style={{ fontSize: '16px', fontWeight: 'bold', color: "#1678c1" }} />
                    <CaretDownOutlined style={{ paddingLeft: '2px', fontSize: '12px', fontWeight: 'bold', color: "#1678c1" }} />
                </a>

            </Dropdown>
            <ModalAsignarCarpeta
                onClickBtnCancelar={onClickBtnCancelar}
                documento={formularioSeleccionado}

            />


        </React.Fragment>

    );


}
export default MenuAcciones;