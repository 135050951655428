import React, { useContext, useEffect, useState } from "react";
import { Table,Space } from "antd";
import * as atoms from "../ListarSeccionStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import  SeccionService from "services/SeccionService";
import { MenuAcciones } from "./MenuAcciones";
import { FolderFilled,FolderTwoTone} from "@ant-design/icons";

const TablaSeccion=()=>{
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [loading, setLoading] = useState(false);
    const [secciones, setSecciones] = useAtom(atoms.seccionesAtom);
    useEffect(()=>{
        const fetch = async ()=>{
            try {
             setLoading(true);                    
             const _secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             setSecciones(_secciones);                        
             
            } catch (error) {
                console.error(error);
                
            } finally{
                setLoading(false);
            }

        }
        fetch(); 
    },[]);  
    const columnasSecciones=[
        {
            title:"Nombre",
            dataIndex:"nombre",   
            align:"center"             
        },
        
        {
            title:"Acciones",
            key:"acciones",
            align:"center",
            render:(fila)=>
                <MenuAcciones
                  seccion={fila}
                  secciones={secciones}
                  setSecciones={setSecciones}
                />            
        },
        
    ];
    return(
        <Table
            loading={loading}
            rowKey={(seccion)=>seccion.id}
            columns={columnasSecciones}
            dataSource={secciones}
            pagination={false}
        
        
        />
    )

}
export default TablaSeccion;