import React from "react";
import {Table} from "antd";
import {useCargarPlantillas} from "../../hooks";
import  {MenuAcciones}  from "./MenuAcciones";

const TablaPlantillas=()=>{
   const {plantillas}=useCargarPlantillas();
  const columnas=[
    {
        title: "Nombre",
        dataIndex: "nombre",
        align: "center",
    },
    {
        title: "Total Documentos",       
        align: "center",
       // render:(fila)=>(fila.documentos.length)
    },
    {
        title:"Acciones",
        key:"acciones",
        align:"center",
        render:(fila)=>(<MenuAcciones plantilla={fila}/>)
    }

  ]
    return(
        <Table
           rowKey={(p=>p.id)}
           dataSource={plantillas}
           columns={columnas}
        />
    )

}
export default TablaPlantillas;