import React from "react";
import { useDrag } from "react-dnd"
import ImagenRubricaFirma from "./img/rubrica-firma.png"

// export const FirmaHolografa = ({ setIsPaginaPdf, left, top }) => {
export const FirmaHolografa = ({ left, top }) => {
    const [{ isDragging }, drag] = useDrag(() => ({
        item: { name: 'Firma Holografa', left: left, top: top },
        type: 'FirmaHolografa',
        // end: (item, monitor) => {
        //     const dropResult = monitor.getDropResult();
        // if (dropResult && dropResult.name === 'PaginaPdf') {
        //     setIsPaginaPdf(true);
        // } else {
        //     setIsPaginaPdf(false);
        // }
        // },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    }), [left, top]);

    const opacity = isDragging ? 0.4 : 1;

    if (isDragging) {
        return <div ref={drag} />;
    }

    return (
        <img ref={drag}
            className="milegajo-imagen-firma"
            src={ImagenRubricaFirma}
            alt="rubrica"
            style={{ opacity, width: '232px', height: '50px', position: 'absolute', left, top }} />
    )
}