import { useAtom } from "jotai";
import { useEffect, useContext } from "react";
import { SolicitudAusenciaService } from "services";
import { SecurityContext } from "context/SecurityContextProvider";
import {
    paginaActualAtom,
    tamanioPaginadoAtom,
    totalRegistrosAtom,
    solicitudesAusenciaAtom,
    filtroColaboradorAtom,
    filtroFechaInicioAtom,
    filtroFechaFinAtom,
    filtroEstadoAtom,
    filtroEstadoJefeInmediatoAtom,
    filtroTipoAusenciaAtom,
    filtroEstadoOtrosCargosAtom,
    filtroEstadoAprobacionAtom,
} from "../consultaSolicitudesStore";

const usePaginacion = () => {
    const [paginaActual, setPaginaActual] = useAtom(paginaActualAtom);
    const [tamanioPaginado] = useAtom(tamanioPaginadoAtom);
    const [totalRegistros, setTotalRegistros] = useAtom(totalRegistrosAtom);
    const [solicitudesAusencia, setSolicitudesAusencia] = useAtom(solicitudesAusenciaAtom);
    const [filtroColaborador, setfiltroColaborador] = useAtom(filtroColaboradorAtom);
    const [filtroFechaInicio, setFiltroFechaInicio] = useAtom(filtroFechaInicioAtom);
    const [filtroFechaFin, setFiltroFechaFin] = useAtom(filtroFechaFinAtom);
    const [filtroEstado, setFiltroEstado] = useAtom(filtroEstadoAtom);
    const [filtroEstadoAprobacion, setFiltroEstadoAprobacion] = useAtom(filtroEstadoAprobacionAtom);
    const [filtroEstadoJefeInmediato, setFiltroEstadoJefeInmediato] = useAtom(filtroEstadoJefeInmediatoAtom);
    const [filtroTipoAusencia, setFiltroTipoAusencia] = useAtom(filtroTipoAusenciaAtom);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [filtroEstadoOtrosCargos, setFiltroEstadoOtrosCargos] = useAtom(filtroEstadoOtrosCargosAtom);

    useEffect(async () => {
        //if(filtroColaborador===undefined && filtroFechaInicio===undefined && filtroFechaFin===undefined && filtroEstado===undefined){
        const respuesta = await SolicitudAusenciaService.listarPorGestionHumanaConPaginacion({
            usuario: getUsuarioAutenticado().numeroDocumentoIdentidad,
            colaborador: filtroColaborador,
            mesInicio: filtroFechaInicio,
            mesFin: filtroFechaFin,
            estadoAprobacion: filtroEstadoAprobacion,
            // estado: filtroEstado,
            // estadoJefeInmediato: filtroEstadoJefeInmediato,
            // estadoOtrosCargos: filtroEstadoOtrosCargos,
            tipoAusencia: filtroTipoAusencia,
            pagina: paginaActual - 1,
            cantidad: tamanioPaginado,
        });
        setSolicitudesAusencia(respuesta.data.solicitudesAusencia);
        setTotalRegistros(respuesta.data.totalRegistros);
    }, [
        filtroColaborador,
        filtroFechaInicio,
        filtroFechaFin,
        filtroEstado,
        filtroEstadoJefeInmediato,
        filtroTipoAusencia,
        paginaActual,
        filtroEstadoOtrosCargos,
        filtroEstadoAprobacion,
    ]);

    const onCambiarPaginacion = (page, pageSize) => {
        console.log("page", page);
        console.log("pageSize", pageSize);
        setPaginaActual(page);
    };
    const onFiltrar = async () => {
        console.log("colaborador", filtroColaborador);
        console.log("fechaInicio", filtroFechaInicio);
        console.log("fechaFin", filtroFechaFin);
        console.log("estado", filtroEstado);
        console.log("paginaActual", paginaActual);
        const respuesta = await SolicitudAusenciaService.listarPorGestionHumanaConPaginacion({
            usuario: getUsuarioAutenticado().numeroDocumentoIdentidad,
            colaborador: filtroColaborador,
            mesInicio: filtroFechaInicio,
            mesFin: filtroFechaFin,
            estado: filtroEstado,
            pagina: paginaActual - 1,
            cantidad: tamanioPaginado,
        });
        setSolicitudesAusencia(respuesta.data.solicitudesAusencia);
        setTotalRegistros(respuesta.data.totalRegistros);
        setPaginaActual(1);
        console.log("solicitudesFiltradas", respuesta.data.solicitudesAusencia);
    };
    return {
        solicitudesAusencia,
        paginaActual,
        tamanioPaginado,
        totalRegistros,
        setTotalRegistros,
        onCambiarPaginacion,
        //onFiltrar,
    };
};
export default usePaginacion;
