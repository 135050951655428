import { atom, useAtom } from "jotai";

export const postulantesAtom = atom([]);
export const plantillasAtom = atom([]);

export const plantillaIdSeleccionadaAtom = atom(undefined);
export const etapasSeleccionadasAtom = atom([]);
export const postulantesSeleccionadosAtom = atom([]);
export const convocatoriasAtom =atom([]);
export const convocatoriaSeleccionadaIdAtom = atom(undefined);
export const documentoSeleccionadoAtom = atom(undefined);
export const visibleModalDocumentoAtom = atom(false);

export const loadingAtom = atom(false);
export const documentosAtom = atom([]);
export const plantillaAtom = atom(null);
export const documentoProcesoAtom = atom(undefined);