import React, { useEffect } from "react";
import {Table,Tag} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { EstadoSolicitudAusencia } from "enums/EstadoSolicitudAusencia";
import { evaluacionesFiltradasAtom, loadingAtom } from "../EvaluacionSeguimientoStore";
import { useAtom } from "jotai";
import EvaluacionService from "services/EvaluacionService";

const ListaConsultaSolicitudes=()=>{

    const [evaluacionesFiltradas, setEvaluacionesFiltradas] = useAtom(evaluacionesFiltradasAtom)
    const [loading, setLoading] = useAtom(loadingAtom)

    useEffect(async() => {
      setLoading(true)
      const respuesta = await EvaluacionService.listarConFiltro(null,
        undefined,
        undefined,
        null,
        null);     
    setEvaluacionesFiltradas(respuesta);
    setLoading(false)
    }, []);

    const columnas = [
        {
          title: "Colaborador",
          dataIndex: ["colaborador", "nombreCompleto"],
          width: "20%",
          align: "center",
        },
        {
          title: "Jefe Inmediato",
          dataIndex: ["jefeInmediato", "nombreCompleto"],
          width: "20%",
          align: "center",
          render: (text, fila) => {
            return (
              <React.Fragment>
                 {fila.jefeInmediato.nombreCompleto}
                <br/>               
               { fila.jefeInmediato.estadoAprobacion===EstadoSolicitudAusencia.PENDIENTE?(<Tag color="warning">{fila.jefeInmediato.estadoAprobacion}</Tag>)
                  : fila.jefeInmediato.estadoAprobacion===EstadoSolicitudAusencia.APROBADO ?(<Tag color="success">{fila.jefeInmediato.estadoAprobacion}</Tag>)
                  : (<Tag color="error">{fila.jefeInmediato.estadoAprobacion}</Tag>)
               }
              </React.Fragment>
            );
          },
        },
        {
          title:"Gestión Humana",          
          width:"8%",
          align:"center",
          render:(fila)=>(fila.estado===EstadoSolicitudAusencia.PENDIENTE?(<Tag color="warning">{fila.estado}</Tag>)
                          : fila.estado===EstadoSolicitudAusencia.APROBADO ?(<Tag color="success">{fila.estado}</Tag>)
                          : (<Tag color="error">{fila.estado}</Tag>) )
          
        },
        
        {
          title: "Fecha Inicio",
          dataIndex: "fechaAprobacion",
          width: "10%",
          align: "center",
          render: (text, fila) => moment(fila.fechaEnvio).format("DD/MM/YYYY"),
        },
        {
          title: "Fecha Fin",
          dataIndex: "fechaFin",
          width: "10%",
          align: "center",
          render: (text, fila) => fila.fechaAprobacion?moment(fila.fechaFinAusencia).format("DD/MM/YYYY"):" - ",
        },
        {
          title: "Fecha de Solicitud",
          dataIndex: "fechaEnvio",
          width: "15%",
          align: "center",
          render: (text, fila) => moment(fila.fechaEnvio).format("DD/MM/YYYY hh:mm a"),
        },
        
        // {
        //   title: "Acciones",
        //   align: "center",
        //   width: "15%",
        //   render: (text, fila) => <MenuAcciones solicitud={fila} />
        // },
      ];
      return <Table 
              rowKey={(value)=>value.id} 
              columns={columnas} 
              dataSource={evaluacionesFiltradas}
              loading={{
                spinning: loading,
                indicator: <LoadingOutlined style={{ fontSize: 28 }}  />,
                tip:"Cargando..."
              }}
              // pagination={{
              //   pageSize: tamanioPaginado,
              //   total: totalRegistros,
              //   current: paginaActual,
              //   showTotal: (total) => `Total ${total} registros`,
              //   onChange: (page, pageSize) => onCambiarPaginacion(page, pageSize),
              // }}
              />

}
export default ListaConsultaSolicitudes;