import React, { useEffect, useContext, useState } from "react";
import { Button, Descriptions, Image, Row, Col, Upload, message, Tag, notification } from "antd";
import { colaboradorAtom } from "../../../LegajoColaboradorStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import { LoginService } from "services/LoginService";
import { Headers } from "enums";
import useCargarColaborador from "../../../hooks/useCargarColaborador";
import ImgCrop from "antd-img-crop";
import ColaboradorService from "services/ColaboradorService";

const DatosPersonales = () => {
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const [loadingAprobar, setLoadingAprobar] = useState(false);
	const [loadingRechazar, setLoadingRechazar] = useState(false);

	const isExplomin =
		getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837"
			? true
			: false;

	const isLaredo = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20132377783" ? true : false;

	const [colaborador, setColaborador] = useAtom(colaboradorAtom);
	const { cargarColaborador } = useCargarColaborador();

	const propsFirma = {
		name: "file",
		multiple: false,
		action: process.env.REACT_APP_ENDPOINT + "colaborador/actualizar-url-firma-holografa",
		headers: {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
		},
		data: {
			colaborador: new Blob(
				[
					JSON.stringify({
						numeroDocumentoIdentidad: colaborador?.numeroDocumentoIdentidad,
					}),
				],
				{ type: "application/json" }
			),
		},
		onChange(info) {
			const { status } = info.file;
			if (status === "done") {
				message.success(`${info.file.name} se subió exitosamente.`);
				cargarColaborador();
			} else if (status === "error") {
				message.error(`Ocurrió un error al subir el documento ${info.file.name}.`);
			}
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};

	const propsHuella = {
		name: "file",
		multiple: false,
		action: process.env.REACT_APP_ENDPOINT + "colaborador/actualizar-url-huella",
		headers: {
			Authorization: "Bearer " + LoginService.getJwtToken(),
			[Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
			[Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
		},
		data: {
			colaborador: new Blob(
				[
					JSON.stringify({
						numeroDocumentoIdentidad: colaborador?.numeroDocumentoIdentidad,
					}),
				],
				{ type: "application/json" }
			),
		},
		onChange(info) {
			const { status } = info.file;
			if (status === "done") {
				message.success(`${info.file.name} se subió exitosamente.`);
				cargarColaborador();
			} else if (status === "error") {
				message.error(`Ocurrió un error al subir el documento ${info.file.name}.`);
			}
		},
		onDrop(e) {
			console.log("Dropped files", e.dataTransfer.files);
		},
	};

	async function handleOnAprobar(tipo) {
		try {
			setLoadingAprobar(true);
			await ColaboradorService.procesarImagenesColaborador(colaborador?.id, "APROBAR", tipo);

			if (tipo == "FIRMA") {
				const colaboradorModificado = {
					...colaborador,
					estadoImagenFirma: "APROBADO"
				}
				setColaborador(colaboradorModificado);
			} else if (tipo == "HUELLA") {
				const colaboradorModificado = {
					...colaborador,
					estadoImagenHuella: "APROBADO"
				}
				setColaborador(colaboradorModificado);
			}

			notification.success({
				message: "Operación exitosa",
				description: "Se realizo el proceso correctamente.",
			});
			setLoadingAprobar(false);

		} catch (error) {
			setLoadingAprobar(false);
		}
	}

	async function handleOnRechazar(tipo) {
		console.log("Rechazar::");
		try {
			setLoadingRechazar(true);
			await ColaboradorService.procesarImagenesColaborador(colaborador?.id, "RECHAZAR", tipo);

			if (tipo == "FIRMA") {
				const colaboradorModificado = {
					...colaborador,
					estadoImagenFirma: "RECHAZADO"
				}
				setColaborador(colaboradorModificado);
			} else if (tipo == "HUELLA") {
				const colaboradorModificado = {
					...colaborador,
					estadoImagenHuella: "RECHAZADO"
				}
				setColaborador(colaboradorModificado);
			}

			notification.success({
				message: "Operación exitosa",
				description: "Se realizo el proceso correctamente.",
			});
			setLoadingRechazar(false);

		} catch (error) {
			setLoadingRechazar(false);
		}
	}

	return (
		<React.Fragment>
			<Descriptions title="Información Personal" bordered size="small">
				<Descriptions.Item label="Tipo Documento" span={3}>
					{colaborador?.tipoDocumentoIdentidad}
				</Descriptions.Item>
				<Descriptions.Item label="Documento Identidad" span={3}>
					{colaborador?.numeroDocumentoIdentidad}
				</Descriptions.Item>
				<Descriptions.Item label="Apellidos y Nombres" span={3}>
					{colaborador?.apellidoPaterno} {colaborador?.apellidoMaterno} {colaborador?.nombres}
				</Descriptions.Item>
				<Descriptions.Item label="Género" span={3}>
					{colaborador?.sexoColaborador}
				</Descriptions.Item>
				<Descriptions.Item label="Fecha Nacimiento" span={3}>
					{colaborador?.fechaNacimiento}
				</Descriptions.Item>

				<Descriptions.Item label="Firma Hológrafa" span={3} contentStyle={{ textAlign: "center" }}>
					<Row gutter={16}>
						<Col span={8}>
							{colaborador?.urlFirmaFirmado != null ? (
								<Image src={colaborador?.urlFirmaFirmado} width={100} />
							) : (
								"Pendiente"
							)}
						</Col>
						<Col span={8}>
							<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
								<Upload {...propsFirma} maxCount={1} accept={["image/*"]}>
									<Button block type="link">
										Cambiar Firma Hológrafa
									</Button>
								</Upload>
							</ImgCrop>
							{
								isExplomin && colaborador?.estadoRegistro == "ONBOARDING" && colaborador?.urlFirmaFirmado != null && (
									<React.Fragment>
										{colaborador?.estadoImagenFirma == "APROBADO" && (
											<div style={{ textAlign: "center" }}>
												<Tag color="green">APROBADO</Tag>
											</div>
										)}
										{colaborador?.estadoImagenFirma == "PENDIENTE" && (
											<div style={{ textAlign: "center" }}>
												<Tag color="gold">PENDIENTE</Tag>
											</div>
										)}
										{colaborador?.estadoImagenFirma == "RECHAZADO" && (
											<div style={{ textAlign: "center" }}>
												<Tag color="red">RECHAZADO</Tag>
											</div>
										)}
										{(colaborador?.estadoImagenFirma == "PENDIENTE" || colaborador?.estadoImagenFirma == "RECHAZADO") && (
											<div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
												<Button key="btnAprobar" size="small" loading={loadingAprobar} type="primary" onClick={() => handleOnAprobar('FIRMA')} style={{ marginRight: "10px", fontSize: "12px" }}>APROBAR</Button>
												<Button key="btnRechazar" size="small" loading={loadingRechazar} onClick={() => handleOnRechazar('FIRMA')} danger style={{ fontSize: "12px" }}>RECHAZAR</Button>
											</div>
										)}
									</React.Fragment>
								)
							}
						</Col>
					</Row>
				</Descriptions.Item>
				{LoginService.getUsuarioAutenticado().empresaSeleccionada.ruc !== "20418710757" && !isLaredo && (
					<Descriptions.Item label="Huella Dactilar" span={3} contentStyle={{ textAlign: "center" }}>
						<Row gutter={16}>
							<Col span={8}>
								{colaborador?.urlHuellaFirmado != null ? (
									<Image src={colaborador?.urlHuellaFirmado} width={100} />
								) : (
									"Pendiente"
								)}
							</Col>
							<Col span={8}>
								<ImgCrop showGrid minZoom={0.5} rotationSlider aspectSlider>
									<Upload {...propsHuella} maxCount={1} accept={["image/*"]}>
										<Button block type="link">
											Cambiar Huella Dactilar
										</Button>
									</Upload>
								</ImgCrop>
								{
									isExplomin && colaborador?.estadoRegistro == "ONBOARDING" && colaborador?.urlHuellaFirmado && (
										<React.Fragment>
											{colaborador?.estadoImagenHuella == "APROBADO" && (
												<div style={{ textAlign: "center" }}>
													<Tag color="green">APROBADO</Tag>
												</div>
											)}
											{colaborador?.estadoImagenHuella == "PENDIENTE" && (
												<div style={{ textAlign: "center" }}>
													<Tag color="gold">PENDIENTE</Tag>
												</div>
											)}
											{colaborador?.estadoImagenHuella == "RECHAZADO" && (
												<div style={{ textAlign: "center" }}>
													<Tag color="red">RECHAZADO</Tag>
												</div>
											)}
											{(colaborador?.estadoImagenHuella == "PENDIENTE" || colaborador?.estadoImagenHuella == "RECHAZADO") && (
												<div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
													<Button key="btnAprobar" size="small" loading={loadingAprobar} type="primary" onClick={() => handleOnAprobar('HUELLA')} style={{ marginRight: "10px", fontSize: "12px" }}>APROBAR</Button>
													<Button key="btnRechazar" size="small" loading={loadingRechazar} onClick={() => handleOnRechazar('HUELLA')} danger style={{ fontSize: "12px" }}>RECHAZAR</Button>
												</div>
											)}
										</React.Fragment>
									)
								}
							</Col>
						</Row>
					</Descriptions.Item>
				)}
			</Descriptions>
			<br />
			<Descriptions title="Contacto" bordered size="small">
				<Descriptions.Item label="Correo Electrónico" span={3}>
					{colaborador?.correoElectronico}
				</Descriptions.Item>
				<Descriptions.Item label="Celular" span={3}>
					{colaborador?.celular}
				</Descriptions.Item>
				<Descriptions.Item label="Dirección" span={3}>
					{colaborador?.domicilioActual?.direccion}
				</Descriptions.Item>
			</Descriptions>
			<br />

			<DatosEmpresa />

		</React.Fragment>
	);
};

const DatosEmpresa = () => {
	const [colaborador] = useAtom(colaboradorAtom);
	const campos = LoginService.getUsuarioAutenticado().empresaSeleccionada.camposColaborador;

	const getValorCampo = (nombreCampo) => {
		if (colaborador) {
			if (nombreCampo !== "PLANILLA") {
				return colaborador[nombreCampo.toLowerCase()]?.nombre;
			} else {
				return colaborador["tipoPlanilla"]?.nombre;
			}
		}
		return null;
	}

	return (
		<Descriptions title="Empresa" bordered size="small">
			<Descriptions.Item label="Código" span={3}>
				{colaborador?.codigoTrabajador}
			</Descriptions.Item>
			<Descriptions.Item label="Fecha Ingreso" span={3}>
				{colaborador?.fechaIngreso}
			</Descriptions.Item>
			<Descriptions.Item label="Jefe Inmediato" span={3}>
				{colaborador?.jefeInmediato?.apellidoPaterno} {colaborador?.jefeInmediato?.apellidoMaterno}{" "}
				{colaborador?.jefeInmediato?.nombres}
			</Descriptions.Item>
			{campos.map(campo => (
				<Descriptions.Item key={campo.campo} label={campo.etiqueta} span={3}>
					{getValorCampo(campo.campo)}
				</Descriptions.Item>
			))}
		</Descriptions>
	)
}

export default DatosPersonales;
