import React, { useContext, useState,useEffect } from "react";
import {Modal, Upload, message, Form, Select, Space, Input, Button, Row, Col,
  DatePicker, notification, Checkbox}from "antd";
import { InboxOutlined,
  PlusOutlined, FolderTwoTone, FolderFilled, UploadOutlined  } from '@ant-design/icons';
import {visibleModalCrearCargaAtom, 
  listaArchivosAtom,
  visibleModalTiposDocumentoAtom,
  tipoDocumentosAtom,
  tipoDocumentoSeleccionadoAtom,
  tipoDocumentoIdAtom} from "../../LegajoCargaStore";
import { LoginService } from "services/LoginService";
import { SecurityContext } from "context/SecurityContextProvider";
import { useAtom } from "jotai";
import { Headers } from "enums";
import { listaCargasAtom } from "../../LegajoCargaStore";
import { FormatoCarga } from 'enums/FormatoCarga';
import UsuarioService from "services/UsuarioService";
import CarpetaService from "services/CarpetaService";
import ParametrosService from "services/ParametrosService";
import TipoDocumentoService from "services/TipoDocumentoService";
import { CategoriaDocumento } from "enums/CategoriaDocumento";
import CargaLegajoService from "services/CargaLegajoService";

const { Option } = Select;

const ModalCrearCargaLegajo = () =>{
  const [visible,setVisible] = useAtom(visibleModalCrearCargaAtom);
  const [cargaArchivo, setCargaArchivo] = useAtom(listaArchivosAtom);
  const [ loading, setLoading] = useState(false);
  const [cargas, setCargas] = useAtom (listaCargasAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const{Dragger}=Upload;
  const [formCargaLegajo] = Form.useForm();
  const [carpetas, setCarpetas] = useState([]);
  const [listaCarpetasFiltro, setListaCarpetasFiltro] = useState([]);
  // const [tipoDocumentos, setTipoDocumentos] = useAtom(tipoDocumentosAtom);
  // const [isSelectionMultiple, setIsSelectionMultiple] = useState(undefined);
  const [archivo, setArchivo] = useState([]);
  // const [visibleModalTiposDocumento, setVisibleModalTiposDocumento] = useAtom(visibleModalTiposDocumentoAtom);
  // const[tipoDocumentoSeleccionado,setTipoDocumentoSeleccionado]=useAtom(tipoDocumentoSeleccionadoAtom);

  useEffect(() => {
    async function cargarDatos() {
  
      const usuario = await UsuarioService.buscarPorOrganizacionLogin(
        getUsuarioAutenticado().organizacionId,
        getUsuarioAutenticado().login
      );

      const empresaSeleccionada = usuario?.empresas.find(
        (empresa) => empresa.id === getUsuarioAutenticado().empresaSeleccionada.id
      );

      // const seleccionMultiple = await ParametrosService.isSeleccionMultiple();
      // setIsSelectionMultiple(seleccionMultiple===true?"multiple":null)
      // let _documentos=[];

      // const _tipoDocumento = await TipoDocumentoService.listarPorUsuarioYCategoria(getUsuarioAutenticado().empresaSeleccionada.id,getUsuarioAutenticado().login, CategoriaDocumento.DOCUMENTO_LABORAL.id);

      // const _tipoDocumentoCargaLegajos = await TipoDocumentoService.listarPorUsuarioYCategoria(getUsuarioAutenticado().empresaSeleccionada.id,getUsuarioAutenticado().login, CategoriaDocumento.CARGA_LEGAJO.id); 
      // _documentos = _documentos.concat(_tipoDocumento, _tipoDocumentoCargaLegajos);
      // setTipoDocumentos(_documentos);

      const _carpetas = await getListaCarpetasFiltro(empresaSeleccionada);

      setListaCarpetasFiltro(_carpetas);

      // if (tipoDocumentoSeleccionado !== "") {
      //   formCargaLegajo.setFieldsValue({
      //     tipoDocumento: tipoDocumentoSeleccionado,
      //   });
      // }

    }
    cargarDatos();
  // }, [tipoDocumentoSeleccionado]);
  }, []);

  const cerrarModal=()=>{
     setVisible(false);
     formCargaLegajo.resetFields();
  };

  // const datos = {
  //   numeroDocumentoResponsableCarga: getUsuarioAutenticado().numeroDocumentoIdentidad,
  //   login:getUsuarioAutenticado().login
  // };

  // const props = {
  //   name: "file",
  //   multiple: false,
  //   showUploadList: "false",
  //   action: process.env.REACT_APP_ENDPOINT_CARGA_DOCUMENTOS + "/cargar",
  //   headers: {
  //     Authorization: "Bearer " + LoginService.getJwtToken(),
  //     [Headers.ORGANIZACION_ID]:
  //       LoginService.getUsuarioAutenticado().organizacionId,
  //     [Headers.EMPRESA_ID]:
  //       LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
  //     [Headers.TIPO_USUARIO]:"ADMIN"
  //     // [Headers.NUMERO_DOCUMENTO_IDENTIDAD]: LoginService.getUsuarioAutenticado().numeroDocumentoIdentidad
  //   },
  //   data: datos,
  //   onChange(info) {
  //     setLoading(true);
  //     const { status } = info.file;
  //     let fileList = [...info.fileList];
  //     fileList = fileList.slice(-1);

  //     if (status !== "uploading") {
  //       //console.log(info.file, info.fileList);
  //       //console.log("entre1:");
  //     }
  //     if (status === "done") {
  //       //console.log("entre2:", info.file.response);
  //       message.success(`${info.file.name} file uploaded successfully.`);
  //       //setCargaArchivo(fileList);
  //       setLoading(false);
  //     const cargaNueva = info.file.response.data;
  //     // setReportes([...reportes, reporteNuevo]);
  //     setCargas([cargaNueva, ...cargas]);
  //     //const _documentos = [cargaNueva, ...cargas.slice(0, indice), ...cargas.slice(indice + 1)];
  //     //setCargas(_documentos);
  //     console.log("Respuesta back carga archivosss:::",info.file.response.data);
  //     setVisible(false);
  //       // setTotalRegistrosFallidos(info.file.response.totalRegistrosFallidos);
  //       // setTotalColaboradoresRegistrados(
  //       //   info.file.response.totalColaboradoresRegistrados
  //       // );
  //       // setTotalColaboradoresActualizados(
  //       //   info.file.response.totalColaboradoresActualizados
  //       // );
  //     } else if (status === "error") {
  //       //setCargaArchivo([]);
  //       setLoading(false)
  //       setVisible(false);
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //     setCargaArchivo(fileList);
  //   },
  // };

  function onChangeSelectCarpeta(value, option) {
    formCargaLegajo.setFieldsValue({ carpeta: { descripcion: option.descripcion } });
  }

  const getListaCarpetasFiltro = async (empresaSeleccionada) => {
    const _carpetas = await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
    setCarpetas(_carpetas);
    if (!empresaSeleccionada?.filtroCarpeta?.length > 0) {
      return _carpetas;
    }

    var temp = [];
    const _temporal = empresaSeleccionada?.filtroCarpeta?.map((e) => {
      temp.push(_carpetas.find((c) => c.id == e.id));
    });

    return temp;
  };

  const onClickBtnCrearCargaLegajo = async (formulario) => {
      try {    
        setLoading(true);
       //console.log("formulario::", formulario);

       formulario.numeroDocumentoResponsableCarga = getUsuarioAutenticado().numeroDocumentoIdentidad;
       formulario.login = getUsuarioAutenticado().login;
      //  formulario.periodo = formulario.periodo.format("MM/YYYY");
      //  formulario.tipoDocumentoId = formulario.tipoDocumento;
      //  const _tipoDocSeleccionado = tipoDocumentos.filter((item) => item.id === formulario.tipoDocumento);
      //  formulario.tipoDocumentoNombre = _tipoDocSeleccionado[0].nombre;

       const formData = new FormData();
       formData.append("file", formulario.archivo.file);
       console.log("formulario::", formulario);
       formData.append(
         "cargaLegajo",
         new Blob([JSON.stringify(formulario)], {
           type: "application/json",
         })
       );
      
       const cargaNueva = await CargaLegajoService.insertar(formData);
       console.log("cargaNueva::",cargaNueva.data);
       setCargas([cargaNueva.data, ...cargas]);

        notification.success({
            message: "Operación exitosa",
            description: "Se registró la carga de legajo correctamente.",
        });
        setLoading(false);
        formCargaLegajo.resetFields();
        setVisible(false);

    } catch (error) {
      console.log(error);
      notification.error({
          message: "No se puede registrar la carga de legajo",
          description: error.response?.data.mensaje,
      });
      setLoading(false);    
      setVisible(false); 
    }
  }

  // function handleOnChangeSeleccionarTipoDocumento(value) {
  //   console.log("value:::", value);
  //   setTipoDocumentoSeleccionado("");
  // }

  // function onClickAgregarTipoDocumento() {
  //   setVisibleModalTiposDocumento(true);
  // }

  const propiedadesUploadDocumento = {
    onRemove: (file) => {
      setArchivo([]);

      return {
        archivo: [],
      };
    },
    beforeUpload: (file) => {
      console.log("file.type::", file.type);
      if (file.type !== "application/zip" && file.type !== "application/x-zip-compressed") {
        console.log("1::");
        message.error(`${file.name} no es un archivo ZIP`);
      } else {
        console.log("2::");
        setArchivo(file);
        return false;
      }
    },
    archivo,
  };

    return (
      <Modal
        style={{ top: 10 }}
        title="Cargar Documento"
        open={visible}
        width={600}
        // bodyStyle={{ width: "100%", height: "85vh" }}
        onCancel={cerrarModal} 
        onOk={onClickBtnCrearCargaLegajo}
        loading={loading}
        footer={null}  
      >
          <Form
            layout="vertical"
            form={formCargaLegajo}
            onFinish={onClickBtnCrearCargaLegajo}
          >
            {/* <Form.Item label="Periodo" name="periodo" rules={[{ required: true, message: "Seleccione el periodo" }]}>
              <DatePicker picker="month" format="MM/YYYY" style={{ width: "50%" }} />
            </Form.Item> */}
            {/* <Form.Item
              label="Tipo Documento"
              name="_tipoDocumento"
            >
              <Row gutter={16} style={{ height: " 4vh " }}>
                <Col span={21}>
                  <Form.Item
                    name="tipoDocumento"
                     rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Seleccione un documento"
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {tipoDocumentos?.length > 0 &&
                        tipoDocumentos?.map((documento) => {
                          return (
                            <Option key={documento.id} value={documento.id}>
                              {documento.nombre}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item>
                    <Button type="primary" shape="circle-outline" onClick={onClickAgregarTipoDocumento}>
                      <PlusOutlined />
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
              </Form.Item>  */}
              <Form.Item label="Descripción" name="descripcion">
                <Input placeholder="Ingrese una descripción" autoComplete="off" maxLength="50" style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item label="Carpeta" name={["carpeta", "id"]}>
                <Select onChange={onChangeSelectCarpeta} optionLabelProp="label" placeholder="Seleccione la carpeta">
                  {carpetas.length > 0 &&
                    carpetas.map((carpeta) => {
                      return (
                        <Option
                          key={carpeta.id}
                          disabled={listaCarpetasFiltro.filter((item) => item.id == carpeta.id).length == 0}
                          value={carpeta.id}
                          label={
                            <div>
                              <FolderTwoTone style={{ fontSize: "20px", color: "#F7D358" }} twoToneColor="#F7D358" />{" "}
                              {carpeta.ruta}
                            </div>
                          }
                          descripcion={carpeta.descripcion}
                        >
                          <Space size={carpeta.nivel * 10}>
                            <span></span>
                            <span>
                              {carpeta.nivel === 0 ? (
                                <FolderFilled style={{ fontSize: "20px", color: "#F7D358" }} />
                              ) : (
                                <FolderTwoTone style={{ fontSize: "18px", color: "#F7D358" }} twoToneColor="#F7D358" />
                              )}{" "}
                              {carpeta.descripcion}
                            </span>
                          </Space>
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <Form.Item name="ocultarEnPortalColaborador" valuePropName="checked">
                <Checkbox
                // onChange={onChangeMostrarLink}
                >
                  <span style={{ display: "block" }}>Ocultar en el portal del colaborador</span>
                  <span style={{ color: "gray" }}>
                  Los documentos cargados no seran visibles en el portal del colaborador.
                  </span>
                </Checkbox>
              </Form.Item>
              <Form.Item name={["carpeta", "descripcion"]} noStyle>
                <Input type="hidden" />
              </Form.Item>

              <Form.Item
                label="Archivo"
                name="archivo"
                //valuePropName="documento"
                rules={[
                  {
                    required: true,
                    validator: (_, value) =>
                      archivo.length !== 0 ? Promise.resolve() : Promise.reject(new Error("Seleccione el Archivo")),
                  },
                ]}
              >
                <Upload {...propiedadesUploadDocumento} maxCount={1}>
                  <Button icon={<UploadOutlined />} block style={{ whiteSpace: "normal", width: "100%" }}>
                    Subir Documento ZIP&nbsp;
                  </Button>
                </Upload>
              </Form.Item>

              <Row justify="center">
                  <Space>
                    <Col span={12}>
                      <Button type="default" onClick={cerrarModal}>Cancelar</Button>
                    </Col>
                    <Col span={12}>
                      <Button type="primary" loading={loading} htmlType="submit">Cargar</Button>
                    </Col>
                  </Space>
              </Row>  
          </Form>
      </Modal>
    )
}
export default ModalCrearCargaLegajo;