import React from "react";

import { Row, Col, Card, Divider, Alert } from "antd";
import { KeyOutlined } from "@ant-design/icons";

export function ContainerRecuperarClave({
  icono,
  titulo,
  descripcion,
  visibleAlertError,
  mensajeError,
  colSpan = 24,
  children,
}) {
  return (
    <div className="site-card-wrapper">
      <Row gutter={[16, 120]}>
        <Col span={colSpan}></Col>
        <Col span={colSpan}></Col>
        <Col span={colSpan}></Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={colSpan}></Col>
        <Col span={colSpan}>
          {/* <Alert
                message="Error"
                description="This is an error message about copywriting."
                type="error"
                showIcon
            /> */}

          <div>
            {visibleAlertError ? (
              <Alert
                message="Error"
                description={mensajeError}
                type="error"
                showIcon
              />
            ) : null}
          </div>
          <Card>
            <div className="logotipo" style={{ textAlign: "center" }}>
              <img
                className="imagen-derecha"
                src="https://storage.googleapis.com/milegajodigital.com/admin/publico/logotipo.png"
                alt="Logotipo"
              />
            </div>
            <div className="container-crear-titulo">
              <KeyOutlined />
              {titulo}
            </div>
            <div className="container-crear-descripcion">{descripcion}</div>
            <Divider />
            {children}
          </Card>
        </Col>
        <Col span={colSpan}></Col>
      </Row>
    </div>
  );
}
