import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";

const URL_API = ApiUrl.RECLUTAMIENTO_Y_SELECCION;

const PerfilService = {
    async insertar(perfil){
        const respuesta= await ApiService(URL_API).post("/perfilSolicitudPersonal",perfil);
        return respuesta.data;
    },
    async listar(){
        const respuesta = await ApiService(URL_API).get("/perfilSolicitudPersonal");
        return respuesta.data;
    },
    async eliminar(perfilId){
        const respuesta = await ApiService(URL_API).delete(`/perfilSolicitudPersonal/${perfilId}`);
        return respuesta.data;
    },
    async buscarPorId(perfilId) {
        const respuesta = await ApiService(URL_API).get(`/perfilSolicitudPersonal/${perfilId}`);
        return respuesta.data;
    },
    async actualizar(perfil){
        const respuesta= await ApiService(URL_API).put("/perfilSolicitudPersonal",perfil);
        return respuesta.data;
    },


}
export default PerfilService;