import React from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";

const Botones = () => {
  let history = useHistory();

  const onClickCrearDescarga = () => {
    history.push("/consultar/descarga-masiva/crear");
  };

  return (
    <Button icon={<PlusOutlined />} type="primary" onClick={onClickCrearDescarga}>
      Programar Descarga Masiva
    </Button>
  );
};
export default Botones;
