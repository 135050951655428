import { BaseService } from "./BaseService";
import { HttpService } from "./HttpService";

const SedeService = {
  async listar(empresaId) {
    const respuesta = await BaseService.get("/sede");
    return respuesta.data;
  },
  async listarPromise(empresaId) {
    return HttpService(empresaId).get("/sede");
  },
  async insertar(sede) {
    const respuesta = await BaseService.post("/sede", sede);
    return respuesta.data;
  },
  async actualizar(sede) {
    const respuesta = await BaseService.put("/sede", sede);
    return respuesta.data;
  },
  async buscarPorId(id) {
    const respuesta = await BaseService.get(`/sede/${id}`);
    return respuesta.data;
  },
  async eliminar(id) {
    const respuesta = await BaseService.delete(`/sede/${id}`);
    return respuesta.data;
  },
  // async exportarSedes(formData){
  //   const respuesta=await BaseService.post("/sede/cargarSedes",formData);
  //   return respuesta.data;
  // },
  async descargarSedesExcel(fd) {
    const respuesta = await BaseService.post("/sede/descargarSedes", fd, {
      responseType: "blob",
    });
    return respuesta;
  },
  async descargarPlantillaExcel(empresaId) {
    const respuesta = await BaseService.get("/sede/descargarPlantillaExcel", {
      params: {
        empresaId: empresaId,
      },
      responseType: "blob",
    });
    return respuesta;
  },

  async listarPorUsuario(login) {
    const respuesta = await BaseService.get("/sede/usuario", {
      params: {
        login: login,
      },
    });
    return respuesta.data;
  },
  async listarPorDependenciaPerfil(empresaId, perfilId) {
    const respuesta = await BaseService.get("/sede/listarDependenciaPerfil", {
      params: {
        empresaId: empresaId,
        perfilId: perfilId,
      },
    });
    return respuesta.data;
  },
};
export default SedeService;
