import React, { useState } from 'react';
import { ModalVisorPdf as VisorPdf } from "components/common";
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { documentoSeleccionadoAtom, visibleModalVisorPdfAtom } from '../store/Store';
import { Button, Form, Input, notification, Popconfirm, Popover, Space } from 'antd';
import { OnboardingColaboradorService } from 'services/OnboardingColaboradorService';
import { useListarTareas } from '../hooks';

const ModalVisorPdf = () => {

    const [documentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
    const [visible, setVisible] = useAtom(visibleModalVisorPdfAtom);

    return (
        <VisorPdf
            titulo={documentoSeleccionado?.nombreDocumento}
            visible={visible}
            urlPdf={documentoSeleccionado?.urlDocumento}
            botonesExtra={<Botones />}
            onCerrar={() => setVisible(false)}
        />
    );
};

const Botones = () => {

    return (
        <Space>
            <BotonAprobar />
            <BotonRechazar />
        </Space >
    );
}

const BotonAprobar = () => {

    const [loading, setLoading] = useState(false);
    const [documentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
    const { fetchTareas } = useListarTareas();
    const setVisibleModalVisorPdf = useSetAtom(visibleModalVisorPdfAtom);


    const onClickBtnAprobar = async () => {
        try {
            setLoading(true);
            await OnboardingColaboradorService.aprobar(documentoSeleccionado.id);
            fetchTareas();
            notification.success({
                message: "Éxito",
                description: "Documento aprobado correctamente.",
                placement: "bottomRight",
            });
            setVisibleModalVisorPdf(false);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <Popconfirm
            placement="bottomRight"
            title="¿Está seguro de aprobar el documento?"
            okButtonProps={{
                size: "middle",
                style: { borderRadius: "15px", padding: "0px 15px" },
                loading: loading,
            }}
            cancelButtonProps={{
                size: "middle",
                style: { borderRadius: "15px", padding: "0px 15px" },
                loading: loading,
            }}
            onConfirm={onClickBtnAprobar}
            okText="Aprobar"
            cancelText="Cancelar"
        >
            <Button type="primary">Aprobar</Button>
        </Popconfirm>
    )
}

const BotonRechazar = () => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [visiblePopoverRechazar, setVisiblePopoverRechazar] = useState(false);
    const [documentoSeleccionado] = useAtom(documentoSeleccionadoAtom);
    const { fetchTareas } = useListarTareas();
    const setVisibleModalVisorPdf = useSetAtom(visibleModalVisorPdfAtom);

    const onClickBtnRechazar = async () => {
        form
            .validateFields()
            .then(async (formulario) => {
                setLoading(true);
                await OnboardingColaboradorService.rechazar({
                    detalleId: documentoSeleccionado.id,
                    motivoRechazo: formulario.motivoRechazo.trim()
                });
                form.resetFields();
                notification.success({
                    message: "Éxito",
                    description: "Documento rechazado.",
                    placement: "bottomRight",
                });
                fetchTareas();
                setVisiblePopoverRechazar(false);
                setVisibleModalVisorPdf(false);
                setLoading(false);
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    const onClickBtnCancelar = () => {
        setVisiblePopoverRechazar(false);
        form.resetFields();
    }

    const handleOpenChange = (newOpen) => {
        setVisiblePopoverRechazar(newOpen);
        form.resetFields();
    };

    const contenidoRechazar = (
        <Form layout="vertical" form={form}>
            <Form.Item label="Indica el motivo del rechazo" name="motivoRechazo" rules={[
                {
                    required: true,
                    message: "Escriba el motivo del rechazo",
                },
            ]}>
                <Input.TextArea maxLength={160} style={{ height: 80 }} autoSize={false} />
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={onClickBtnCancelar} style={{ borderRadius: "15px", marginRight: "10px" }} loading={loading}>
                    Cancelar
                </Button>
                <Button type="danger" style={{ borderRadius: "15px" }} onClick={onClickBtnRechazar} loading={loading}>
                    Rechazar
                </Button>
            </div>
        </Form>
    );

    return (
        <Popover
            content={contenidoRechazar}
            trigger="click"
            open={visiblePopoverRechazar}
            onOpenChange={handleOpenChange}
            placement="bottomRight"
        >
            <Button type="danger" loading={loading}>
                Rechazar
            </Button>
        </Popover>
    )
}

export default ModalVisorPdf;