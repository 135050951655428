import React from "react";
import { Button,Popconfirm } from "antd";
import { EditOutlined, LayoutOutlined,DeleteOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import TipoDocumentoService from "../../../../../services/TipoDocumentoService";

export const MenuAcciones = ({ tipoDocumento }) => {

    let history = useHistory();

    const BotonEditar = () => {
        const onClickBtnEditar=()=>{
                        
            const rutaEditarTipoDocumentoPage=`/configuracion/tipo-documentos/${tipoDocumento.id}/editar`;
            history.push(rutaEditarTipoDocumentoPage);
        
        }

        return (
            <Button type="link" icon={<EditOutlined />} onClick={onClickBtnEditar}>
                Editar
            </Button >
        )
    }
    const BotonEliminar = ()=>{
        const onclickBtnDeshabilitar = () =>{
              TipoDocumentoService.deshabilitarPorId(tipoDocumento.id);          
              //setTipoDocumentos(tipoDocumentos.filter((tipoDoc) => tipoDoc.id!==tipoDocumento.id);
            
        }

        return(
            // <Button type="link" icon={<DeleteOutlined />} onClick={onclickBtnDeshabilitar}>
            //     Deshabilitar
            // </Button>
            <Popconfirm
            title="Estás seguro de deshabilitar el Tipo Documento"
            onConfirm={onclickBtnDeshabilitar}
            //onCancel={cancel}
            okText="Si"
            cancelText="No"
           >
            <Button type="link" icon={<DeleteOutlined />} >
                Deshabilitar
            </Button>
          </Popconfirm>

        )
    }
   

    // const BotonDisenar = () => {

    //     const onClickBtnDisenar = () => {
    //         const rutaDisenarFormularioPage = `/configuracion/formularios/${formulario.id}/disenar`;
    //         history.push(rutaDisenarFormularioPage);
    //     }

    //     return (
    //         <Button type="link" onClick={onClickBtnDisenar}>
    //             <LayoutOutlined /> Diseñar
    //         </Button >
    //     )
    // }

    return (
        <React.Fragment>
            <BotonEditar />
            <BotonEliminar />
        </React.Fragment>
    );
}