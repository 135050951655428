import React from "react";
import { Button, Space, Upload } from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";


const BotonesExtra = () =>{
    let history=useHistory();
    const onClickCrearPlantilla=()=>{
        history.push("/reclutamiento-y-seleccion/plantillas/crear");
    }

  return(
    <Button type="primary" icon={<PlusOutlined />} onClick={onClickCrearPlantilla}>Crear Plantilla</Button>
  )
}
export default BotonesExtra;