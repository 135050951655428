import React from "react";
import { Button} from "antd";

const BotonesGlobales = ({onClickEnviar, usuariosSeleccionados, botonName}) => {

    return (
        <React.Fragment>
            <Button type="primary" onClick={onClickEnviar} disabled={usuariosSeleccionados?.length==0}>
                {botonName}
            </Button>
        
        </React.Fragment>
    );
}

export default BotonesGlobales;