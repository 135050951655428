import React, { useContext, useEffect, useState } from "react";
import { Container } from "components/Container";
import { useHistory } from "react-router";
import * as atoms from "./EditarSubsedeStore";
import { useAtom } from "jotai";
import { useModalConfirmar } from "store/global";
import { ModalConfirmar } from "components/Modal/ModalConfirmar";
import {SubsedeEditar} from "./subsede-editar";
import SubsedeService from "services/SubsedeService";
import { notification } from "antd";

const EditarSubsedePage = () =>{

    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [subsede]=useAtom(atoms.subsedeAtom);
    const cerrarModalConfirmacion = useModalConfirmar(state => state.cerrarModal);

    const onClickRetornarListarSubsedes=()=>{
        history.push("/subsede");
    }
    const onClickBtnCancelarModal=()=>{
        cerrarModalConfirmacion();
    }
    const onClickBtnConfirmarModal = async ()=>{
        try {
            setLoading(true);
            const _Subsede= await SubsedeService.actualizar(subsede);
            setLoading(false);
            cerrarModalConfirmacion();
            history.push("/subsede");
            
        } catch (error) {
            console.error(error);
            cerrarModalConfirmacion();
            notification.error({
                message:"Error",
                description:error.response.data.mensaje});
            
        }finally{
            setLoading(false);
        }

    }
    return(
        <Container titulo="Editar Subcentro de costo" onClickRetornar={onClickRetornarListarSubsedes}>
         <SubsedeEditar/>
         <ModalConfirmar
                loading={loading}
                mensaje="¿Está seguro de actualizar el Subcentro de costo? "
                onClickBtnCancelar={onClickBtnCancelarModal}
                onClickBtnConfirmar={onClickBtnConfirmarModal} />
        </Container>
    )

}
export default EditarSubsedePage;