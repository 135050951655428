import React, { useEffect ,useContext} from "react";
import {Modal,Row,Col,message,Divider} from "antd"
import { useAtom } from "jotai";
import { Form,Input,DatePicker,Button,Upload,Table } from "antd";
import{UploadOutlined} from "@ant-design/icons";
import {solicitudAusenciaSeleccionadaAtom,visibleModalSeguimientoAtom} from "../../consultaSolicitudesStore";
import { useState } from "react";
import  {SolicitudAusenciaService} from "services";
import { ApiResponse } from "enums";
import { useModalVisorPdf } from "store/global";
import { SecurityContext } from "context/SecurityContextProvider";
import {FormatoCarga} from "enums/FormatoCarga"

const ModalSeguimiento=()=>{

    const [visibleModalSeguimiento,setVisibleModalSeguimiento] = useAtom(visibleModalSeguimientoAtom);
    const [solicitudSeleccionada,setSolicitudSeleccionada] = useAtom(solicitudAusenciaSeleccionadaAtom);
    const [seguimientos,setSeguimientos]= useState([]);
    const [formSeguimiento]=Form.useForm();
    const [archivos, setArchivos] = useState(undefined);
    const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [loading, setLoading]=useState(false);

    useEffect(()=>{
      const cargarDatos = async ()=>{
        if(solicitudSeleccionada){

          const respuesta= await SolicitudAusenciaService.buscarPorId(solicitudSeleccionada.id);         
         
          setSeguimientos(respuesta.data.seguimientos);

        }
        else{
          setSeguimientos([]);
        }       
       
      }
      cargarDatos();
        
    },[solicitudSeleccionada])

    const onClickBtnCancelar =()=>{
      setVisibleModalSeguimiento(false);
      formSeguimiento.resetFields();
    }

    const onclickVer = async(seguimiento)=>{
     
      const respuesta = await SolicitudAusenciaService.getUrlSeguimiento(getUsuarioAutenticado().empresaSeleccionada.id,solicitudSeleccionada.id,"WEB",seguimiento.id);
      if(respuesta.estado===ApiResponse.EXITO){
        mostrarModalVisorPdf(respuesta.data.urlSolicitud, "Seguimientos ", true, true);
      } 
      else{
        message.error(respuesta.mensaje)
      }
      
    }

    const columnas=[   
          {
            title:"Comentario",
            dataIndex:"comentario",
            align:"center",
            key:"comentario",
          },
          {
            title:"Fecha",
            dataIndex:"fecha",
            align:"center",
            key:"fecha",
          },          
          {
            title:"Evidencia",           
            align:"center",
            key:"evidencia",
           render:(text,fila)=>(<Button type="link" onClick={()=>onclickVer(fila)}>Ver</Button>)
          }

    ];
    
    const onClikGuardar = async(formulario)=>{
       setLoading(true);
      const seguimientoInsertado={        
        solicitudId:solicitudSeleccionada.id,          
        comentario: formulario.comentario.trim(),       
        fecha: formulario.fecha?.format("DD/MM/YYYY"),
        
       }
          
       const formData=new FormData();
          
        formData.append("file",formulario.evidencia.file);
        formData.append("datos", 
               new Blob(
                [
                  JSON.stringify(seguimientoInsertado)
              
                ],

              {
                type: "application/json",
              }
              )
        );                
     
      const respuesta = await SolicitudAusenciaService.agregarSeguimiento(formData);
      if(respuesta.estado===ApiResponse.EXITO){     
       
          setSeguimientos(respuesta.data.seguimientos);        
          formSeguimiento.resetFields();
          setLoading(false);
      }
      else{
        message.error(respuesta.mensaje);
        setLoading(false);
      }

    }    

    const props = {
      onRemove: (file) => {
          setArchivos([]);
         
          return {
              archivos: [],           

          };
   
      },
      beforeUpload: (file) => {
        if (file.type !== 'application/pdf') {
          message.error(`${file.name} no es un archivo PDF`);
        }
        else{
          setArchivos(file);          
          return false;       
        }
              
     
      },
        archivos,
     
  }; 
 

 return (
    <Modal 
      title={"Seguimiento"}     
      visible={visibleModalSeguimiento}     
      onCancel={onClickBtnCancelar}
      footer={null}
      width={600}
      >
        <Form 
           form={formSeguimiento}
           labelCol={{span:5}}
           wrapperCol={{span:24}}
           layout="vertical"
           onFinish={onClikGuardar}
        >
           
                  <Form.Item 
                      label="Comentario" 
                      name="comentario"
                      rules={[
                        {
                          required: true,
                          message: "Ingrese el comentario",
                        },
                        {
                          validator: (_, value) =>
                            value?.trim().length!==0 ? Promise.resolve() : Promise.reject(new Error('No ha ingresado el comentario')),
                        },
                      ]}
                      >
                    <Input.TextArea rows={4} />
                  </Form.Item>
              
                  <Form.Item 
                      label="Fecha" 
                      name="fecha"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione la fecha",
                        },
                      ]}
                      >
                  <DatePicker style={{ width: "100% " }}/>
                  </Form.Item>   
                  <Form.Item 
                     label="Evidencia" 
                     name={"evidencia"}                     
                     rules={[
                      {
                        required: true,
                        message: "Seleccione el archivo adjunto",
                      },
                      {
                        validator: (_, value) =>
                          archivos?.length!==0 ? Promise.resolve() : Promise.reject(new Error('Seleccione el archivo adjunto')),
                      },
                    ]}
                     >
                    <Upload
                       {...props}
                       maxCount={1} 
                       accept={FormatoCarga.PDF_MULTIPAGINA.extension}                      
                    >
                    <Button icon={<UploadOutlined/> } >Subir</Button>
                    </Upload>
                    
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 10, span: 24 }} >
                    <Button  htmlType="submit" type="primary" loading={loading} > Guardar </Button>        
                    
                  </Form.Item>
                  {/* <Form.Item wrapperCol={{ offset: 5, span: 24 }}>
                     <Button onClick={onClickAgregar} >Agregar</Button>
                  </Form.Item> */}
                  <Divider/>
                  <Form.Item name="datos">
                    <Table
                      rowKey={fila=>fila.id}
                      columns={columnas}
                      dataSource={seguimientos}
                      pagination={false}
                    />
                  </Form.Item> 
                  
                  
            
        </Form>
    </Modal>
 )
}
export default ModalSeguimiento;