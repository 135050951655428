import { BaseService } from "./BaseService";
import qs from "qs";

const ExportadorService = {
  async descargarFotos(params) {
    const respuesta = await BaseService.get("/exportadores/fotos", {
      params: {
        ...params
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    return respuesta.data;
  }
};

export default ExportadorService;
