import { useAtom } from "jotai";
import { colaboradorAtom, envioSeleccionadoAtom, onboardingsIngresoAtom } from "../../../LegajoColaboradorStore";
import { useContext, useEffect } from "react";
import { OnboardingIngresoColaboradorService } from "services/OnboardingIngresoColaboradorService";
import { SecurityContext } from "context/SecurityContextProvider";

const useCargarOnboardingIngreso = () => {
    const [colaborador] = useAtom(colaboradorAtom);
    const [onboardings, setOnboardings] = useAtom(onboardingsIngresoAtom);
    const [envioSeleccionado] = useAtom(envioSeleccionadoAtom);
    const { getUsuarioAutenticado } = useContext(SecurityContext);

    const cargarOnboarding = async () => {
        if (colaborador.onboarding) {
            const respuesta = await OnboardingIngresoColaboradorService.listarDetalleOnboardingIngreso(
                getUsuarioAutenticado().login,
                envioSeleccionado,
                colaborador.numeroDocumentoIdentidad
            );
            setOnboardings(respuesta);
        }
    };
    return { cargarOnboarding };
};
export default useCargarOnboardingIngreso;
