import React from "react";
import { Button, Popconfirm } from "antd";
import PerfilSolicitudPersonalService from "services/PerfilSolicitudPersonalService";
import { useListarPerfilSolicitudPersonal } from "../../hooks";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const MenuAcciones = ({ perfil }) => {
  let history = useHistory();
  const { perfiles, setPerfiles } = useListarPerfilSolicitudPersonal();

  const BotonEditar = () => {
    const onClickEditar = () => {
      history.push(`/reclutamiento-y-seleccion/solicitud-personal/perfiles/editar/${perfil.id}`);
    };
    return (
      <Button type="link" icon={<EditOutlined />} onClick={onClickEditar}>
        Editar
      </Button>
    );
  };
  const BotonEliminar = () => {
    const onClickEliminar = async () => {
      await PerfilSolicitudPersonalService.eliminar(perfil.id);

      setPerfiles(perfiles.filter((p) => p.id !== perfil.id));
    };
    return (
      <Popconfirm title="¿Está seguro de eliminar el Perfil?" onConfirm={onClickEliminar} okText="Si" cancelText="No">
        <Button type="link" icon={<DeleteOutlined />}>
          {" "}
          Eliminar
        </Button>
      </Popconfirm>
    );
  };
  return (
    <React.Fragment>
      <BotonEditar />
      <BotonEliminar />
    </React.Fragment>
  );
};
export default MenuAcciones;
