import React from "react";
import { Button,Popconfirm } from "antd";
import {EditOutlined,DeleteOutlined,UserAddOutlined,CopyOutlined} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { ConvocatoriaService } from "services/reclutamiento-y-seleccion/ConvocatoriaService";
import { useCargarConvocatorias } from "../../hooks";
import { SecurityContext } from "context/SecurityContextProvider";
import { useContext } from "react";
const MenuAcciones=({convocatoria})=>{   

     let history=useHistory();
     const {getUsuarioAutenticado} = useContext(SecurityContext);
     const {convocatorias,setConvocatorias} = useCargarConvocatorias();

     const BotonEditar=()=>{
      const onClickEditar=()=>{
          history.push(`/reclutamiento-y-seleccion/convocatorias/${convocatoria.id}/editar`);
      }
        return(
        <Button type="link" icon={<EditOutlined />} onClick={onClickEditar}>
           Editar
        </Button>
        )
     }
     const BotonEliminar=()=>{

      const onClickEliminar =async()=>{
         //console.log("convocatoriaID",convocatoria.id);
          await ConvocatoriaService.eliminar(convocatoria.id);
          setConvocatorias(convocatorias.filter(item=>item.id!==convocatoria.id));
      }
        return(
         <Popconfirm
            title="¿Está seguro de eliminar?"
            onConfirm={onClickEliminar}           
            okText="Si"
            cancelText="No"
         >
                  <Button type="link" icon={<DeleteOutlined />}>Eliminar</Button>
         </Popconfirm>
            
        )
     }
     const AgregarPostulantes=()=>{

      const onClickAgregarPostulante=()=>{
         history.push(`/reclutamiento-y-seleccion/convocatorias/${convocatoria.id}/agregar-postulante`);
      }
      return(
         <Button type="link" icon={<UserAddOutlined />} onClick={onClickAgregarPostulante}>Agregar Postulantes</Button>
      )
     }
     const CopiarURl=()=>{
       
        const onClickCopiarUlr = async()=>{
                 
         console.log("enlace:",convocatoria.urlPlataformaColaborador+"/publico/convocatorias?id="+convocatoria.id);

         let link=convocatoria.urlPlataformaColaborador+"/publico/convocatorias?id="+convocatoria.id;

         await navigator.clipboard.writeText(link);  
        }
        return(
         <Button type="link" icon={<CopyOutlined />} onClick={onClickCopiarUlr}>Copiar Url </Button>
        )
     }
     return(
        <React.Fragment>
            <BotonEditar/>
            <BotonEliminar/>
            <AgregarPostulantes/>
            <CopiarURl/>
        </React.Fragment>
        
     )
}
export default MenuAcciones;