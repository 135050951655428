import React from "react";
import { EstadoNotificacion } from "../../../../../../enums/EstadoNotificacion";
import { Tag } from "antd";
import { TagNotificado } from "./TagNotificado";

const EtiquetaEstadoNotificacion = ({ documento }) => {

        switch (documento.estadoNotificacion) {
            case EstadoNotificacion.PENDIENTE:
                return <Tag color="gold">{documento.estadoNotificacion}</Tag>;
    
            case EstadoNotificacion.ENVIADO:
                return <TagNotificado documento={documento} color="green" className="milegajo-ant-tag pointer">
                    {documento.estadoNotificacion}
                </TagNotificado>

            case EstadoNotificacion.NO_APLICA:
                return <Tag style={{ color: '#7f7f7f', marginRight: '0px' }}>NO APLICA</Tag>;

            case EstadoNotificacion.ERROR:
                return <Tag color="red">{documento.estadoNotificacion}</Tag>;

            default:
                return <Tag color="gold">PENDIENTE</Tag>;
        }
    

}
export default EtiquetaEstadoNotificacion;