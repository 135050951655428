import React from "react";
import { Button, Space } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const BotonesExtra = () => {
  return (
    <Space>
      <CrearProcesoBoton />
    </Space>
  );
};

const CrearProcesoBoton = () => {
  const history = useHistory();

  return (
    <Button type="primary" onClick={() => history.push(`/reclutamiento-y-seleccion/procesos/crear`)}>
      <PlusCircleOutlined /> Crear Proceso
    </Button>
  );
};

export default BotonesExtra;
