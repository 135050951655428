import { useEffect } from "react";
import { useAtom } from "jotai";
import  {ConvocatoriaService} from "services/reclutamiento-y-seleccion/ConvocatoriaService";
import { postulantesAtom,convocatoriaSeleccionadaIdAtom } from "../ProcesosCrearStore";

const useCargarPostulantes = () => {
  const [postulantes, setPostulantes] = useAtom(postulantesAtom);
  const [convocatoriaSeleccionadaId,setconvocatoriaSeleccionadaId] = useAtom (convocatoriaSeleccionadaIdAtom);

  useEffect(() => {
    const cargarDatos = async () => {
      if(convocatoriaSeleccionadaId!==undefined){
        const postulantes = await ConvocatoriaService.listarPostulantes(convocatoriaSeleccionadaId);
        setPostulantes(postulantes);
      }     
    };
    cargarDatos();
  }, [convocatoriaSeleccionadaId]);

  return { postulantes };
};

export default useCargarPostulantes;
