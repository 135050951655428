import { BaseService } from "./BaseService";
const LegajoService = {
  async listar(colaboradorNumeroDocumentoIdentidad, empresaId) {
    const respuesta = await BaseService.get("/colaborador/listarDocumentos", {
      params: {
        colaboradorNumeroDocumentoIdentidad: colaboradorNumeroDocumentoIdentidad,
        empresaId: empresaId,
      },
    });
    return respuesta.data;
  },

  async buscarDocumentosPorColaborador(numeroDocumentoIdentidad, empresaId) {
    const respuesta = await BaseService.get(
      "/colaborador/buscarDocumentosPorColaborador",
      {
        params: {
          numeroDocumentoIdentidad: numeroDocumentoIdentidad,
          empresaId: empresaId,
        },
      }
    );
    return respuesta.data;
  },

  //{responseType: "blob",}
  async verPDF(fd) {
    const respuesta = await BaseService.post("/colaborador/verPDF", fd);
    return respuesta.data;
  },

  async descargarDocumentos(
    empresaId,
    listaNumeroDocumentoIdentidad,
    listaOpcionReporteIds
  ) {
    const respuesta = await BaseService.post(
      "/analista/legajo/descargarDocumentos",
      {
        empresaId: empresaId,
        listaNumeroDocumentoIdentidad: listaNumeroDocumentoIdentidad,
        listaOpcionReporteIds: listaOpcionReporteIds,
      },
      {
        responseType: "blob",
      }
    );
    return respuesta;
  },
  async listarColaboradores(empresaId) {
    const respuesta = await BaseService.get(
      "/analista/legajo/listarColaborador",
      {
        params: {
          empresaId: empresaId,
        },
      }
    );
    return respuesta.data;
  },
  async listarDocumentosPorColaboradoryTipoDocumento(
    colaboradorNumeroDocumentoIdentidad,
    tipoDocumentoId,
    empresaId
  ) {
    const respuesta = await BaseService.get(
      "/analista/listarDocumentosPorColaboradorYTipoDocumento",
      {
        params: {
          colaboradorNumeroDocumentoIdentidad: colaboradorNumeroDocumentoIdentidad,
          tipoDocumentoId: tipoDocumentoId,
          empresaId: empresaId,
        },
      }
    );
    return respuesta.data;
  },
  async descargarDocumentosPorDetalleEnvio(
    empresaId,
    listaDetalleEnvio,
    listaOpcionReporteIds
  ) {
    const respuesta = await BaseService.post(
      "/analista/legajo/descargarDocumentosPorDetalleEnvio",
      {
        empresaId: empresaId,
        listaDetalleEnvio: listaDetalleEnvio,
        listaOpcionReporteIds: listaOpcionReporteIds,
      },
      {
        responseType: "blob",
      }
    );
    return respuesta;
  },
  async verPDFCargaDocumento(detalleEnvioId) {
    const respuesta = await BaseService.get(
      "/cargaDocumento/verPDFCargaDocumento",
      {
        params: {
          detalleEnvioId: detalleEnvioId,
        },
      }
    );
    return respuesta.data;
  },
  async listarDocumentosColaborador(numeroDocumentoIdentidad, empresaId, login) {
    const respuesta = await BaseService.get("/analista/legajo/listaDocumentos", {
      params: {
        numeroDocumentoIdentidad: numeroDocumentoIdentidad,
        empresaId: empresaId,
        login:login
      },
    });
    return respuesta.data;
  },
  async listarDocumentosCarpeta(numeroDocumentoIdentidad, empresaId, carpetaId,login) {
    const respuesta = await BaseService.get("/analista/legajo/listaDocumentosCarpeta", {
      params: {
        numeroDocumentoIdentidad: numeroDocumentoIdentidad,
        empresaId: empresaId,
        carpetaId: carpetaId,
        login:login
      },
    });
    return respuesta.data;
  },
};
export default LegajoService;
