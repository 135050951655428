import { useAtom } from "jotai";
import { documentosAtom, convocatoriaSeleccionadaIdAtom, postulantesSeleccionadosAtom, plantillaIdSeleccionadaAtom } from "../ProcesosCrearStore";
import { ProcesoService } from "services/reclutamiento-y-seleccion/ProcesoService";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";

const useCreaProceso = () => {
  const [documentos] = useAtom(documentosAtom);
  const [convocatoriaSeleccionadaId] = useAtom(convocatoriaSeleccionadaIdAtom);
  const [postulantesSeleccionados] = useAtom(postulantesSeleccionadosAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [plantillaId] = useAtom(plantillaIdSeleccionadaAtom);
  let history = useHistory();

  const crearProceso = async (proceso) => {
    try {
      if (documentos.length > 0) {
        proceso.documentos = documentos;
        proceso.convocatoriaId = convocatoriaSeleccionadaId;
        proceso.postulantesId = postulantesSeleccionados;

        // proceso.documentos.map((documento) => {
        //   documento.etapa = documento.etapa.nombre;
        // });
        proceso.etapas = proceso.etapasId;
        proceso.login = getUsuarioAutenticado().login;
        proceso.platillaId = plantillaId;

        await ProcesoService.crear(proceso);
        history.push("/reclutamiento-y-seleccion/procesos");
      } else {
        message.error("No ha seleccionado documentos");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return { crearProceso };
};
export default useCreaProceso;
