import { useAtom } from "jotai";
import { etapasSeleccionadasAtom } from "../ProcesosCrearStore";

const useSeleccionarEtapas = () => {
  const [, setEtapas] = useAtom(etapasSeleccionadasAtom);

  const seleccionarEtapas = (etapas) => {
    //console.log("etapas seleccionadas: ", etapas);
    setEtapas(etapas);
  };

  return { seleccionarEtapas };
};

export default useSeleccionarEtapas;
