import React, { useEffect } from "react";
import { Tag, Popover, Table, Button, Progress } from "antd";
import {
  CheckCircleOutlined,
  LoginOutlined,
  InfoCircleOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";

export const MenuAvance = ({ documento }) => {
  if (documento.requiereFirmaColaborador) {
    const columnas = [
      {
        title: "Enviados",
        dataIndex: "enviados",
        key: "totalFirmados",
        align: "center",
      },
      {
        title: "Firmados",
        dataIndex: "totalFirmados",
        key: "totalFirmados",
        align: "center",
      },
      {
        title: "Pendientes",
        dataIndex: "pendientes",
        key: "pendientes",
        align: "center",
      },
    ];
    const notificacion = [
      {
        key: "2",
        enviados: (
          <React.Fragment>
            <LoginOutlined style={{ color: "green" }} />
            &nbsp;{documento.totalEnviados}
          </React.Fragment>
        ),
        totalFirmados: (
          <React.Fragment>
            <CheckCircleOutlined style={{ color: "green" }} />
            &nbsp;{documento.totalFirmados}
          </React.Fragment>
        ),
        pendientes: (
          <React.Fragment>
            <InfoCircleOutlined style={{ color: "orange" }} />
            &nbsp;{documento.totalEnviados - documento.totalFirmados}
          </React.Fragment>
        ),
      },
    ];

    const datosVisualizacion = (
      <div>
        <Table dataSource={notificacion} columns={columnas} pagination={false} />
      </div>
    );

    if (documento.estado === "EN_CURSO" || documento.estado === "COMPLETADO") {
      if (documento.estadoProcesoId === 1)
        return (
          <Tag color="orange" style={{ fontWeight: "normal" }}>
            Pendiente de Aprobación
          </Tag>
        );
      else
        return (
          <div style={{ width: 170 }}>
            <Popover content={datosVisualizacion} placement="bottom" trigger="click">
              <Button block type="link">
                <Progress
                  percent={Math.round((documento.totalFirmados * 100) / documento.totalEnviados)}
                  size="small"
                />
              </Button>
            </Popover>
          </div>
        );
    } else if (documento.estado === "PENDIENTE_NOTIFICAR") {
      return (
        <Tag color="orange" style={{ fontWeight: "normal" }}>
          Pendiente de Notificación
        </Tag>
      );
    } else {
      return (
        <Tag color="orange" style={{ fontWeight: "normal" }}>
          Pendiente de Carga
        </Tag>
      );
    }
  } else {
    const columnas = [
      {
        title: "Enviados",
        dataIndex: "enviados",
        key: "enviados",
        align: "center",
      },
      {
        title: "Visualizados",
        dataIndex: "visualizados",
        key: "visualizados",
        align: "center",
      },
      {
        title: "Sin Visualizar",
        dataIndex: "sinVisualizar",
        key: "pendientes",
        align: "center",
      },
    ];

    const notificacion = [
      {
        key: "1",
        enviados: (
          <React.Fragment>
            <CheckCircleOutlined style={{ color: "green" }} />
            &nbsp;{documento.totalEnviados}
          </React.Fragment>
        ),
        visualizados: (
          <React.Fragment>
            <EyeOutlined style={{ color: "green" }} />
            &nbsp;{documento.totalVisualizados}
          </React.Fragment>
        ),
        sinVisualizar: (
          <React.Fragment>
            <EyeInvisibleOutlined style={{ color: "orange" }} />
            &nbsp;{documento.totalEnviados - documento.totalVisualizados}
          </React.Fragment>
        ),
      },
    ];

    const datosVisualizacion = (
      <div>
        <Table dataSource={notificacion} columns={columnas} pagination={false} />
      </div>
    );
    if (documento.estado === "EN_CURSO" || documento.estado === "COMPLETADO") {
      if (documento.estadoProcesoId === 1)
        return (
          <Tag color="orange" style={{ fontWeight: "normal" }}>
            Pendiente de Aprobación
          </Tag>
        );
      else
        return (
          <div style={{ width: "170px" }}>
            <Popover content={datosVisualizacion} placement="bottom" trigger="click">
              <Button block type="link">
                <Progress
                  percent={Math.round((documento.totalVisualizados * 100) / documento.totalEnviados)}
                  size="small"
                />
              </Button>
            </Popover>
          </div>
        );
    } else {
      return (
        <Tag color="orange" style={{ fontWeight: "normal" }}>
          Pendiente de Carga
        </Tag>
      );
    }
  }
};
