import { BaseService } from "./BaseService";
const CarpetaService = {
  async listar(empresaId) {
    const respuesta = await BaseService.get("/carpeta", {
      params: {
        empresaId: empresaId,
      },
    });
    return respuesta.data;
  },
  async insertar(carpeta) {
    const respuesta = await BaseService.post("/carpeta", carpeta);
    return respuesta.data;
  },

  async buscarPorPadre(codigoPadreId) {
    const respuesta = await BaseService.get("/carpeta/carpetaPadre", {
      params: {
        codigoPadreId: codigoPadreId,
      },
    });
    return respuesta.data;
  },
  async buscarPorId(id) {
    const respuesta = await BaseService.get(`/carpeta/${id}`);
    return respuesta.data;
  },
  async actualizar(carpeta) {
    const respuesta = await BaseService.put("/carpeta", carpeta);
    return respuesta.data;
  },
  async eliminar(id) {
    const respuesta = await BaseService.delete(`/carpeta/${id}`);
    return respuesta.data;
  },
};
export default CarpetaService;
