import React, { useState, useEffect, useRef, useMemo } from "react";
import { ContainerCrear } from "../../../components/ContainerCrear";
import {
  Select,
  Form,
  Input,
  Row,
  Col,
  Button,
  notification,
  Space,
  DatePicker,
  Upload,
  message,
  Divider,
  Radio,
  Spin,
} from "antd";
import {
  CheckOutlined,
  EditOutlined,
  MailOutlined,
  BankOutlined,
  FolderAddOutlined,
  CarOutlined,
  PlusOutlined,
  WalletOutlined,
} from "@ant-design/icons";
import ColaboradorService from "../../../services/ColaboradorService";
import CargoService from "../../../services/CargoService";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { tipoDocumentoIdentidad } from "../../../enums/TipoDocumentoIdentidad";
import { SexoColaborador } from "enums/SexoColaborador";
import SeccionService from "../../../services/SeccionService";
import SubsedeService from "../../../services/SubsedeService";
import PerfilService from "../../../services/PerfilService";
import TipoPlanillaService from "../../../services/TipoPlanillaService";
import ParametrosService from "../../../services/ParametrosService";
import { CampoColaborador } from "enums/CampoColaborador";
import ComunicadoService from "services/ComunicadoService";
import { FormatoCarga } from "enums/FormatoCarga";
import { PlantillaEnvioDocumentoService } from "services/PlantillaEnvioDocumentoService";
import { UbigeoService } from "services";
import { OPCIONES } from "enums";
import { debounce } from "lodash";

const { Option } = Select;

export function ColaboradorCrearPage() {
  const [formularioCrear] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [cargos, setCargos] = useState([]);
  const [sedes, setSedes] = useState([]);
  const [secciones, setSecciones] = useState([]);
  const [subsedes, setSubsedes] = useState([]);
  const [perfiles, setPerfiles] = useState([]);
  const [tipoPlanillas, setTipoPlanillas] = useState([]);
  const [seccionNueva, setSeccionNueva] = useState();
  const [subsedeNueva, setSubsedeNueva] = useState();
  const [perfilNuevo, setPerfilNuevo] = useState();
  const [tipoPlanillaNueva, setTipoPlanillaNueva] = useState();
  const [seccionSeleccionada, setSeccionSeleccionada] = useState(null);
  const [subsedeSeleccionada, setSubsedeSeleccionada] = useState(null);
  const [perfilSeleccionado, setPerfilSeleccionado] = useState(null);
  const [tipoPlanillaSeleccionado, setTipoPlanillaSeleccionado] =
    useState(null);
  const [codigoColaboradorActivo, setCodigoColaboradorActivo] =
    useState(undefined);
  const [camposColaboradores, setCamposColaboradores] = useState([]);
  const [tipoDocumentoSeleccionado, setTipoDocumentoSeleccionado] =
    useState(null);
  const [colaboradoresBuscados, setColaboradoresBuscados] = useState([]);
  const [jefeSeleccionado, setJefeSeleccionado] = useState([]);
  const [firmaHolografa, setFirmaHolografa] = useState([]);
  const [huellaDactilar, setHuellaDactilar] = useState([]);
  const [plantillasOnboarding, setPlantillasOnbording] = useState([]);

  const [responsablesRenovarContrato, setresponsablesRenovarContrato] =
    useState([]);
  const [
    responsableRenovarContratoSeleccionado,
    setresponsableRenovarContratoSeleccionado,
  ] = useState(undefined);

  const [administradoresObra, setAdministradoresObra] = useState([]);
  const [administradorObraSeleccionado, setAdministradorObraSeleccionado] =
    useState(undefined);

  const [colaboradoresOtrosAprobadores, setColaboradoresOtrosAprobadores] =
    useState([]);
  const [aprobadoresSeleccionados, setAprobadoresSeleccionados] = useState([]);
  var opcionesOtrosAprobadores = [];

  const [departamentos, setDepartamentos] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [distritos, setDistritos] = useState([]);

  const [ocultarTipoServicio, setOcultarTipoServicio] = useState(false);
  //const [ocultarJefeInmediato, setOcultarJefeInmediato] = useState(false);
  const [ocultarAdminObra, setOcultarAdminObra] = useState(false);
  const [rangoMinimo, setRangoMinimo] = useState();
  const [rangoMaximo, setRangoMaximo] = useState();
  const sedeSeleccionada = Form.useWatch("name", formularioCrear);
  const [omitirValidarRango, setOmitirValidarRango] = useState(false);

  const isExplomin =
    getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837"
      ? true
      : false;

  const isCclp = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20156181596" ? true : false;

  const isLaredo = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20132377783" ? true : false;

  const existeCuenta = Form.useWatch(
    ["cuentaHaberes", "exist"],
    formularioCrear
  );
  let history = useHistory();

  const [ocultarTipoPractica, setOcultarTipoPractica] = useState(true);
  const opcionesEmpresaSeleccionada = getUsuarioAutenticado().empresaSeleccionada.opciones;

  useEffect(() => {
    async function cargarDatos() {
      const parametros =
        await ParametrosService.buscarPorEmpresaConCodigoColaboradorActivo(
          getUsuarioAutenticado().empresaSeleccionada.id
        );
      setCodigoColaboradorActivo(parametros.respuesta);

      const campos_colaborador =
        getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
      setCamposColaboradores(campos_colaborador);
      // console.log("Los campos colaborador:",campos_colaborador);
      const campoEnviado =
        await ColaboradorService.camposColaboradorListarNuevo(
          getUsuarioAutenticado().empresaSeleccionada.id
        );
      setPerfiles(campoEnviado.PERFIL);
      setSedes(campoEnviado.SEDE);
      setSubsedes(campoEnviado.SUBSEDE);
      setSecciones(campoEnviado.SECCION);
      setCargos(campoEnviado.CARGO);
      setTipoPlanillas(campoEnviado.PLANILLA);

      const departamentos = await UbigeoService.listarDepartamentos();
      setDepartamentos(departamentos);

      // const colaboradores = await ColaboradorService.listarTodos(
      //   getUsuarioAutenticado().login
      // );
      // colaboradores.forEach((colaborador) => {
      //   opcionesOtrosAprobadores.push({
      //     label: colaborador.nombreCompleto,
      //     value: colaborador.id,
      //   });
      // });
      // setColaboradoresOtrosAprobadores(opcionesOtrosAprobadores);

      PlantillaEnvioDocumentoService.listar(getUsuarioAutenticado().login)
        .then((plantillasOnboarding) => {
          setPlantillasOnbording(plantillasOnboarding);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    cargarDatos();
  }, []);

  async function handleOnFinish(colaborador) {
    try {
      setLoading(true);

      colaborador.empresa = {
        id: getUsuarioAutenticado().empresaSeleccionada.id,
        razonSocial: getUsuarioAutenticado().empresaSeleccionada.razonSocial,
        urlImagenLogotipo:
          getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo,
      };

      colaborador.organizacionId = getUsuarioAutenticado().organizacionId;
      colaborador.subdominio = getUsuarioAutenticado().subdominio;
      colaborador.login = colaborador.numeroDocumentoIdentidad;
      //colaborador.clave = "";
      colaborador.eliminado = false;
      colaborador.seccion = seccionSeleccionada;
      colaborador.subsede = subsedeSeleccionada;
      colaborador.perfil = perfilSeleccionado;
      colaborador.tipoPlanilla = tipoPlanillaSeleccionado;

      colaborador.numeroDocumentoIdentidad =
        colaborador.numeroDocumentoIdentidad.trim();
      colaborador.apellidoPaterno = colaborador.apellidoPaterno.trim();
      colaborador.apellidoMaterno = colaborador.apellidoMaterno.trim();
      colaborador.nombres = colaborador.nombres.trim();
      colaborador.codigoTrabajador =
        !colaborador.codigoTrabajador ||
          colaborador.codigoTrabajador.trim() === ""
          ? null
          : colaborador.codigoTrabajador.trim();
      colaborador.celular =
        !colaborador.celular || colaborador.celular.trim() === ""
          ? null
          : colaborador.celular.trim();
      colaborador.jefeInmediatoId =
        jefeSeleccionado.length > 0 ? jefeSeleccionado : null;
      colaborador.administradorObraId = administradorObraSeleccionado;
      colaborador.responsableRenovarContratoId =
        responsableRenovarContratoSeleccionado;
      colaborador.vinculoLaboral = "TRABAJADOR";
      colaborador.loginUsuarioCreacion = getUsuarioAutenticado().login;
      // colaborador.otrosJefesInmediatosIds =
      //   aprobadoresSeleccionados.length > 0 ? aprobadoresSeleccionados : null;

      colaborador.otrosJefesInmediatosIds = colaboradoresOtrosAprobadores.map(item => item.value);

      /* esto solo se utiliza para contratos (condicionar!) */
      if (isExplomin) {
        //colaborador.contrato.fechaInicioContrato = colaborador.contrato.fechaIngreso.format("DD/MM/YYYY");
        colaborador.fechaIngreso =
          colaborador.contrato.fechaInicioContrato.format("YYYY-MM-DD");
        colaborador.contrato.fechaInicioContrato =
          colaborador.contrato.fechaInicioContrato.format("DD/MM/YYYY");
        colaborador.contrato.fechaFinContrato =
          colaborador.contrato.fechaFinContrato.format("DD/MM/YYYY");
      }

      colaborador.usuarioRegistro = getUsuarioAutenticado().nombreCompleto;
      colaborador.usuarioRegistroDocumentoIdentidad = getUsuarioAutenticado().numeroDocumentoIdentidad;

      const formData = new FormData();
      formData.append("firmaHolografa", firmaHolografa);
      formData.append("huellaDactilar", huellaDactilar);
      formData.append(
        "colaborador",
        new Blob([JSON.stringify(colaborador)], {
          type: "application/json",
        })
      );

      await ColaboradorService.insertar(formData);

      notification.success({
        message: "Operación exitosa",
        description: "Se registró el colaborador correctamente.",
      });
      setLoading(false);
      history.push("/personal/trabajador");
    } catch (error) {
      console.log(error);
      notification.error({
        message: "No se puede registrar el Colaborador",
        description: error.response.data.mensaje,
      });
      setLoading(false);
    }
  }

  function procesarCambios(campoEnviado) {
    if (campoEnviado.PLANILLA != null) {
      setTipoPlanillas(campoEnviado.PLANILLA);
      formularioCrear.setFieldsValue({ tipoPlanilla: "" });
    }
    if (campoEnviado.CARGO != null) {
      setCargos(campoEnviado.CARGO);
      formularioCrear.setFieldsValue({ cargo: { id: "" } });
    }
    if (campoEnviado.PERFIL != null) {
      setPerfiles(campoEnviado.PERFIL);
      formularioCrear.setFieldsValue({ perfil: "" });
    }
    if (campoEnviado.SECCION != null) {
      setSecciones(campoEnviado.SECCION);
      formularioCrear.setFieldsValue({ seccion: "" });
    }
    if (campoEnviado.SEDE != null) {
      setSedes(campoEnviado.SEDE);
      formularioCrear.setFieldsValue({ sede: { id: "" } });
    }
    if (campoEnviado.SUBSEDE != null) {
      setSubsedes(campoEnviado.SUBSEDE);
      formularioCrear.setFieldsValue({ subsede: "" });
    }
  }

  async function handleOnChangeSede(value, option) {
    formularioCrear.setFieldsValue({ sede: { nombre: option.children } });

    const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
      getUsuarioAutenticado().empresaSeleccionada.id,
      value,
      CampoColaborador.SEDE
    );
    procesarCambios(campoEnviado);

    if (isExplomin && option.children === "PRINCIPAL") {
      setOcultarTipoServicio(true);
      setOcultarAdminObra(true);
    } else {
      setOcultarTipoServicio(false);
      setOcultarAdminObra(false);
    }
  }

  async function handleOnChangeCargo(value, option) {
    // if(isExplomin && (opcionesEmpresaSeleccionada && !opcionesEmpresaSeleccionada.includes(OPCIONES.PAGINA_MANTENIMIENTO_COLABORADOR.TXT_OMITIR_RANGO_SUELDO))){
    console.log("cargo-value:::", value);
    console.log("cargo-option:::", option);
    //const cargo = cargos.find(objeto => objeto.id === value);
    if (isExplomin) {
      const cargo = await CargoService.buscarPorId(value);
      console.log("cargooooooo:::", cargo);
      setOmitirValidarRango(cargo.omitirRangoSalarial);
      console.log("rangoSueldo-Min:::", cargo.sueldoMinimo);
      console.log("rangoSueldo-Max:::", cargo.sueldoMaximo);
      setRangoMinimo(cargo.sueldoMinimo != 0 ? cargo.sueldoMinimo : 0);
      setRangoMaximo(cargo.sueldoMaximo != 0 ? cargo.sueldoMaximo : 999999999);
    }
    // }

    formularioCrear.setFieldsValue({ cargo: { nombre: option.children } });
    const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
      getUsuarioAutenticado().empresaSeleccionada.id,
      value,
      CampoColaborador.CARGO
    );
    procesarCambios(campoEnviado);
  }

  function handleOnClickCancelar() {
    history.push("/personal/trabajador");
  }

  function onChangeSeccion(e) {
    setSeccionNueva(e.target.value);
  }
  async function agregarSeccion() {
    //console.log("Valor de seccion:",seccionNueva);
    if (seccionNueva !== undefined) {
      const seccion = {
        nombre: seccionNueva,
        empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
      };
      const seccionInsertada = await SeccionService.insertar(seccion);
      //console.log("seccion insertada:",seccionInsertada);
      const seccion_ = [...secciones, seccionInsertada];
      setSecciones(seccion_);
      setSeccionNueva();
      formularioCrear.setFieldsValue({ valorSeccion: "" });
    }
  }
  async function onSelectSeccion(value, option) {
    const seccion = {
      id: option.value,
      nombre: option.children,
    };
    setSeccionSeleccionada(seccion);
    const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
      getUsuarioAutenticado().empresaSeleccionada.id,
      value,
      CampoColaborador.SECCION
    );
    procesarCambios(campoEnviado);
  }

  function onChangeSubsedeNueva(e) {
    setSubsedeNueva(e.target.value);
  }
  async function agregarSubsede() {
    if (subsedeNueva !== undefined) {
      const subsede = {
        nombre: subsedeNueva,
        empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
      };
      const subsedeInsertada = await SubsedeService.insertar(subsede);
      const subsedes_ = [...subsedes, subsedeInsertada];
      setSubsedes(subsedes_);
      setSubsedeNueva();
    }
  }
  async function onSelectSubsede(value, option) {
    const subsede = {
      id: option.value,
      nombre: option.children,
    };
    setSubsedeSeleccionada(subsede);

    const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
      getUsuarioAutenticado().empresaSeleccionada.id,
      value,
      CampoColaborador.SUBSEDE
    );
    // console.log(campoEnviado)
    procesarCambios(campoEnviado);
  }

  function onChangePerfilNuevo(e) {
    setPerfilNuevo(e.target.value);
  }
  async function agregarPerfil() {
    if (perfilNuevo !== undefined) {
      const perfil = {
        nombre: perfilNuevo,
        empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
      };
      const perfilInsertado = await PerfilService.insertar(perfil);
      const perfiles_ = [...perfiles, perfilInsertado];
      setPerfiles(perfiles_);
      setPerfilNuevo();
    }
  }
  async function onSelectPerfil(value, option) {
    const perfil = {
      id: option.value,
      nombre: option.children,
    };
    setPerfilSeleccionado(perfil);

    const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
      getUsuarioAutenticado().empresaSeleccionada.id,
      value,
      CampoColaborador.PERFIL
    );
    // console.log(campoEnviado)
    procesarCambios(campoEnviado);
  }

  function onChangeTipoPlanillaNuevo(e) {
    setTipoPlanillaNueva(e.target.value);
  }

  async function agregarTipoPlanilla() {
    if (tipoPlanillaNueva !== undefined) {
      const tipoPlanilla = {
        nombre: tipoPlanillaNueva,
        empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
      };
      const tipoPlanillaInsertado = await TipoPlanillaService.insertar(
        tipoPlanilla
      );
      const tipoPlanillas_ = [...tipoPlanillas, tipoPlanillaInsertado];
      setTipoPlanillas(tipoPlanillas_);
      setTipoPlanillaNueva();
    }
  }
  async function onSelectTipoPlanilla(value, option) {
    const tipoPlanilla = {
      id: option.value,
      nombre: option.children,
    };
    setTipoPlanillaSeleccionado(tipoPlanilla);

    const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
      getUsuarioAutenticado().empresaSeleccionada.id,
      value,
      CampoColaborador.PLANILLA
    );
    // console.log(campoEnviado)
    procesarCambios(campoEnviado);
    setOcultarTipoPractica(true);
    if (isExplomin && option.children === "PRACTICANTE") {
      setOcultarTipoPractica(false);
    }
  }
  const onChangeNumeroDocumentoIdentidad = (e) => {
    const dni = formularioCrear
      .getFieldValue("numeroDocumentoIdentidad")
      .replace(/\s+/g, "");
    formularioCrear.setFieldsValue({
      numeroDocumentoIdentidad: dni,
    });
  };
  const onChangeCodigoColaborador = (e) => {
    const codigo = formularioCrear
      .getFieldValue("codigoTrabajador")
      .replace(/\s+/g, "");
    formularioCrear.setFieldsValue({
      codigoTrabajador: codigo,
    });
  };

  const onSelectTipoDocumento = (string, option) => {
    setTipoDocumentoSeleccionado(option.value);
  };

  const onSearchColaboradores = async (value) => {
    const _respuesta =
      await ComunicadoService.listarColaboradoresPorEmpresaConFiltro({
        empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
        filtro: value.trim(),
        login: getUsuarioAutenticado().login,
      });
    setColaboradoresBuscados(_respuesta);
    if (value === "") {
      formularioCrear.setFieldsValue({
        buscarJefeInmediato: "",
      });
      setJefeSeleccionado([]);
    }
  };

  const onChangeColaboradoresSeleccionados = (value) => {
    setJefeSeleccionado(value);
  };

  const onSeleccionarResponsableRenovacionContrato = (value) => {
    setresponsableRenovarContratoSeleccionado(value);
  };

  const onSearchColaboradorResponsableContrato = async (value) => {
    const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
      filtro: value.trim(),
      login: getUsuarioAutenticado().login,
    });

    if (value === "") {
      formularioCrear.setFieldsValue({
        responsableRenovarContratoId: "",
      });
      setresponsableRenovarContratoSeleccionado(undefined);
      setresponsablesRenovarContrato([]);
    } else {
      setresponsablesRenovarContrato(respuesta.colaboradores);
    }
  };

  const onSeleccionarAdministradorObra = (value) => {
    setAdministradorObraSeleccionado(value);
  };

  const onSearchAdministradorObra = async (value) => {
    const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
      filtro: value.trim(),
      login: getUsuarioAutenticado().login,
    });

    if (value === "") {
      formularioCrear.setFieldsValue({
        responsableRenovarContratoId: "",
      });
      setAdministradorObraSeleccionado(undefined);
      setAdministradoresObra([]);
    } else {
      setAdministradoresObra(respuesta.colaboradores);
    }
  };

  const handleChangeOtrosAprobadores = (value) => {
    setAprobadoresSeleccionados(value);
  };

  const propsFirma = {
    onRemove: (file) => {
      setFirmaHolografa([]);

      return {
        firmaHolografa: [],
      };
    },
    beforeUpload: (file) => {
      if (file.type !== "image/png" && file.type !== "image/jpeg") {
        message.error(`${file.name} no es un archivo png ó jpeg`);
      } else {
        setFirmaHolografa(file);
        return false;
      }
    },
    firmaHolografa,
  };

  const propsHuella = {
    onRemove: (file) => {
      setHuellaDactilar([]);

      return {
        huellaDactilar: [],
      };
    },
    beforeUpload: (file) => {
      if (file.type !== "image/png" && file.type !== "image/jpeg") {
        message.error(`${file.name} no es un archivo png ó jpeg`);
      } else {
        setHuellaDactilar(file);
        return false;
      }
    },
    huellaDactilar,
  };

  const onChangeDepartamento = async (value) => {
    const formData = new FormData();
    formData.append("departamento", value);
    const provincias = await UbigeoService.listarProvincias(formData);
    setProvincias(provincias);

    formularioCrear.setFieldsValue({
      domicilioActual: {
        provincia: provincias[0].provincia,
      },
    });

    const formDataDistrito = new FormData();
    formDataDistrito.append("provincia", provincias[0].provincia);
    const distritos = await UbigeoService.listarDistritos(formDataDistrito);
    setDistritos(distritos);

    formularioCrear.setFieldsValue({
      domicilioActual: {
        distrito: distritos[0].distrito,
      },
    });
  };

  const onChangeProvincia = async (value) => {
    const formData = new FormData();
    formData.append("provincia", value);
    const distritos = await UbigeoService.listarDistritos(formData);
    setDistritos(distritos);

    formularioCrear.setFieldsValue({
      domicilioActual: {
        distrito: distritos[0].distrito,
      },
    });
  };
  const onChangeApellidoPaterno = (e) => {
    const apellidoP = formularioCrear
      .getFieldValue("apellidoPaterno")
      .toUpperCase();
    formularioCrear.setFieldsValue({
      apellidoPaterno: apellidoP,
    });
  };

  const onChangeApellidoMaterno = (e) => {
    const apellidoM = formularioCrear
      .getFieldValue("apellidoMaterno")
      .toUpperCase();
    formularioCrear.setFieldsValue({
      apellidoMaterno: apellidoM,
    });
  };

  const onChangeNombres = () => {
    const nombre = formularioCrear.getFieldValue("nombres").toUpperCase();
    formularioCrear.setFieldsValue({
      nombres: nombre,
    });
  };
  const onChangeDireccion = () => {
    const direccion = formularioCrear
      .getFieldValue(["domicilioActual", "direccion"])
      .toUpperCase();
    formularioCrear.setFieldsValue({
      domicilioActual: {
        direccion: direccion,
      },
    });
  };

  const fetchOtrosAprobadores = async (value) => {
    const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
      filtro: value.trim(),
      login: getUsuarioAutenticado().login,
    });

    return respuesta.colaboradores.map((colaborador) => ({
      label: colaborador.nombreCompleto,
      value: colaborador.id,
    }));

  };

  return (
    <ContainerCrear titulo="Crear Colaborador" colSpan={12} divider={false}>
      <Form
        form={formularioCrear}
        layout="vertical"
        size="middle"
        onFinish={handleOnFinish}
        autoComplete="off"
      >
        <Form.Item name="eliminado" noStyle>
          <Input type="hidden" value={false} />
        </Form.Item>

        <Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
          <Space>
            <EditOutlined /> Información personal
          </Space>
        </Divider>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Tipo Documento Identidad"
              name="tipoDocumentoIdentidad"
              rules={[
                {
                  required: true,
                  message: "Selecciona tu tipo de documento de identidad",
                },
              ]}
            >
              <Select allowClear onSelect={onSelectTipoDocumento}>
                <Option value={tipoDocumentoIdentidad.DNI.valor}>
                  {tipoDocumentoIdentidad.DNI.nombre}
                </Option>
                <Option value={tipoDocumentoIdentidad.CARNET_EXTRANJERIA.valor}>
                  {tipoDocumentoIdentidad.CARNET_EXTRANJERIA.descripcion}
                </Option>
                <Option value={tipoDocumentoIdentidad.PASAPORTE.valor}>
                  {tipoDocumentoIdentidad.PASAPORTE.nombre}
                </Option>
                <Option value={tipoDocumentoIdentidad.OTRO_DOCUMENTO.valor}>
                  {tipoDocumentoIdentidad.OTRO_DOCUMENTO.descripcion}
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Número de Documento Identidad"
              name="numeroDocumentoIdentidad"
              rules={[
                {
                  required: true,
                  message: "Escribe el número de tu documento de identidad",
                  whitespace: true,
                },
              ]}
            >
              <Input
                autoComplete="off"
                maxLength={
                  tipoDocumentoSeleccionado == tipoDocumentoIdentidad.DNI.valor
                    ? 8
                    : 15
                }
                onChange={onChangeNumeroDocumentoIdentidad}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Apellido Paterno"
              name="apellidoPaterno"
              rules={[
                {
                  required: true,
                  message: "Escribe tu apellido paterno",
                  whitespace: true,
                },
              ]}
            >
              <Input autoComplete="off" onChange={onChangeApellidoPaterno} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Apellido Materno"
              name="apellidoMaterno"
              rules={[
                {
                  required: true,
                  message: "Escribe tu apellido materno",
                  whitespace: true,
                },
              ]}
            >
              <Input autoComplete="off" onChange={onChangeApellidoMaterno} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Nombres"
              name="nombres"
              rules={[
                {
                  required: true,
                  message: "Escribe los nombres completos del colaborador",
                  whitespace: true,
                },
              ]}
            >
              <Input autoComplete="off" onChange={onChangeNombres} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Género"
              name="sexoColaborador"
              rules={[{ required: true }]}
            >
              <Select>
                <Option value={SexoColaborador.FEMENINO}>
                  {SexoColaborador.FEMENINO}{" "}
                </Option>
                <Option value={SexoColaborador.MASCULINO}>
                  {SexoColaborador.MASCULINO}
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Fecha Nacimiento"
              name="fechaNacimiento"
              rules={[
                {
                  required: true,
                  message: "Selecciona tu fecha nacimiento",
                },
              ]}
            >
              <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item label="Tiene Hijos" name="tieneHijos">
              <Select>
                <Option value="SI">Si</Option>
                <Option value="NO">No</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
          <Space>
            <MailOutlined /> Contacto
          </Space>
        </Divider>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Correo Electrónico"
              name="correoElectronico"
              rules={[
                {
                  required: true,
                  message: "Escribe tu correo electrónico",
                },
                {
                  type: "email",
                  message: "Correo electrónico ingresado no es válido",
                },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Número de Celular" name="celular">
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Correo Electrónico Alternativo"
              name="correoElectronicoAlternativo"
              rules={[
                {
                  type: "email",
                  message: "Correo electrónico ingresado no es válido",
                },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>
          </Col>
        </Row>

        <Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
          <Space>
            <BankOutlined /> Empresa
          </Space>
        </Divider>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Código Colaborador"
              name="codigoTrabajador"
              rules={
                (codigoColaboradorActivo === "SI" || isLaredo)
                  ? [
                    {
                      required: true,
                      message: "Escribe el código del colaborador",
                      whitespace: true,
                    },
                  ]
                  : []
              }
            >
              <Input autoComplete="off" onChange={onChangeCodigoColaborador} />
            </Form.Item>
          </Col>
          <Col span={12} hidden={isExplomin}>
            <Form.Item
              name="fechaIngreso"
              label="Fecha Ingreso"
              rules={
                isLaredo
                  ? [{ required: true, message: "Ingrese la Fecha de Ingreso" }]
                  : []
              }
            >
              <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          {camposColaboradores.length > 0 &&
            camposColaboradores.map((campoColaborador) => {
              if (
                campoColaborador.visible === true &&
                campoColaborador.campo === CampoColaborador.PERFIL
              ) {
                return (
                  <Col key={campoColaborador.nombre} span={12}>
                    <Form.Item
                      label={campoColaborador.etiqueta}
                      name="perfil"
                      rules={
                        campoColaborador.campoObligatorio === true
                          ? [
                            {
                              required: true,
                              message: "Seleccione Perfil",
                            },
                          ]
                          : []
                      }
                    >
                      <Select
                        onSelect={onSelectPerfil}
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {perfiles.length > 0 &&
                          perfiles.map((perfil) => {
                            return (
                              <Option key={perfil.id} value={perfil.id}>
                                {perfil.nombre}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                );
              } else if (
                campoColaborador.visible === true &&
                campoColaborador.campo === CampoColaborador.SEDE
              ) {
                return (
                  <Col key={campoColaborador.nombre} span={12}>
                    <Form.Item
                      name={["sede", "id"]}
                      label={campoColaborador.etiqueta}
                      rules={
                        campoColaborador.campoObligatorio === true
                          ? [
                            {
                              required: true,
                              message: `Seleccione ${campoColaborador.etiqueta}`,
                            },
                          ]
                          : []
                      }
                    >
                      <Select onChange={handleOnChangeSede}>
                        {sedes.length > 0 &&
                          sedes.map((sede) => {
                            return (
                              <Option key={sede.id} value={sede.id}>
                                {sede.nombre}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item name={["sede", "nombre"]} noStyle>
                      <Input type="hidden" />
                    </Form.Item>
                  </Col>
                );
              } else if (
                campoColaborador.visible === true &&
                campoColaborador.campo === CampoColaborador.SUBSEDE
              ) {
                return (
                  <Col key={campoColaborador.nombre} span={12}>
                    <Form.Item
                      label={campoColaborador.etiqueta}
                      name="subsede"
                      rules={
                        campoColaborador.campoObligatorio === true
                          ? [
                            {
                              required: true,
                              message: "Seleccione Subcentro de costo",
                            },
                          ]
                          : []
                      }
                    >
                      <Select
                        onSelect={onSelectSubsede}
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {subsedes.length > 0 &&
                          subsedes.map((subsede) => {
                            return (
                              <Option key={subsede.id} value={subsede.id}>
                                {subsede.nombre}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                );
              } else if (
                campoColaborador.visible === true &&
                campoColaborador.campo === CampoColaborador.SECCION
              ) {
                return (
                  <Col key={campoColaborador.nombre} span={12}>
                    <Form.Item
                      label={campoColaborador.etiqueta}
                      name="seccion"
                      rules={
                        campoColaborador.campoObligatorio === true
                          ? [
                            {
                              required: true,
                              message: "Seleccione Sección",
                            },
                          ]
                          : []
                      }
                    >
                      <Select
                        onSelect={onSelectSeccion}
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {secciones.length > 0 &&
                          secciones.map((seccion) => {
                            return (
                              <Option key={seccion.id} value={seccion.id}>
                                {seccion.nombre}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                );
              } else if (
                campoColaborador.visible === true &&
                campoColaborador.campo === CampoColaborador.PLANILLA
              ) {
                return (
                  <Col key={campoColaborador.nombre} span={12}>
                    <Form.Item
                      label={campoColaborador.etiqueta}
                      name="tipoPlanilla"
                      rules={
                        campoColaborador.campoObligatorio === true
                          ? [
                            {
                              required: true,
                              message: "Seleccione Tipo Planilla",
                            },
                          ]
                          : []
                      }
                    >
                      <Select
                        onSelect={onSelectTipoPlanilla}
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {tipoPlanillas.length > 0 &&
                          tipoPlanillas.map((tipoPlanilla) => {
                            return (
                              <Option
                                key={tipoPlanilla.id}
                                value={tipoPlanilla.id}
                              >
                                {tipoPlanilla.nombre}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                );
              } else if (
                campoColaborador.visible === true &&
                campoColaborador.campo === CampoColaborador.CARGO
              ) {
                return (
                  <Col key={campoColaborador.nombre} span={12}>
                    <Form.Item
                      name={["cargo", "id"]}
                      label={campoColaborador.etiqueta}
                      rules={
                        campoColaborador.campoObligatorio === true
                          ? [
                            {
                              required: true,
                              message: "Seleccione Cargo",
                            },
                          ]
                          : []
                      }
                    >
                      <Select
                        onChange={handleOnChangeCargo}
                        showSearch
                        optionFilterProp="children"
                      >
                        {cargos.length > 0 &&
                          cargos.map((cargo) => {
                            return (
                              <Option key={cargo.id} value={cargo.id}>
                                {cargo.nombre}
                              </Option>
                            );
                          })}
                      </Select>
                    </Form.Item>
                    <Form.Item name={["cargo", "nombre"]} noStyle>
                      <Input type="hidden" />
                    </Form.Item>
                  </Col>
                );
              }
            })}

          {isExplomin && (
            <Col span={12}>
              <Form.Item
                name={["datosPlanillaPracticas", "tipoPracticas"]}
                label="Tipo de Prácticas"
                hidden={ocultarTipoPractica}
                rules={
                  [
                    {
                      required: !ocultarTipoPractica,
                      message: "Seleccione el tipo de práctica",
                    },
                  ]

                }
              >
                <Select>
                  {/* <Select.Option value="PREPROFESIONALES">
                        PREPROFESIONALES
                      </Select.Option> */}
                  <Select.Option value="PROFESIONALES">
                    PROFESIONALES
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>
        {isExplomin && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={["datosPlanillaPracticas", "nacionalidad"]}
                label="Nacionalidad"
                hidden={ocultarTipoPractica}
                rules={
                  [
                    {
                      required: !ocultarTipoPractica,
                      message: "Seleccione la nacionalidad",
                    },
                  ]

                }
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={["datosPlanillaPracticas", "profesion"]}
                label="Profesión Universitaria o Profesión Técnica"
                hidden={ocultarTipoPractica}
                rules={
                  [
                    {
                      required: !ocultarTipoPractica,
                      message: "Seleccione la profesión",
                    },
                  ]

                }
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        )}

        {isExplomin && (
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name={["datosPlanillaPracticas", "universidad"]}
                label="Universidad"
                hidden={ocultarTipoPractica}
                rules={
                  [
                    {
                      required: !ocultarTipoPractica,
                      message: "Ingrese la universidad",
                    },
                  ]

                }
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        )}
        {!isExplomin && (
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="buscarJefeInmediato" label="Jefe Inmediato">
                <Select
                  placeholder="Ingrese nombre de colaborador"
                  showSearch
                  onSearch={onSearchColaboradores}
                  onChange={onChangeColaboradoresSeleccionados}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {colaboradoresBuscados !== undefined &&
                    colaboradoresBuscados.map((colaborador) => {
                      return (
                        <Select.Option
                          key={colaborador.id}
                          value={colaborador.id}
                        >
                          {colaborador.nombreCompleto}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        )}

        {getUsuarioAutenticado().empresaSeleccionada
          ?.moduloContratoHabilitado &&
          isExplomin && (
            <React.Fragment>
              <Divider
                plain
                style={{ color: "#457b9d", borderColor: "#219ebc" }}
              >
                <Space>
                  <FolderAddOutlined /> Datos Contrato
                </Space>
              </Divider>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="estadoCivil"
                    label="Estado Civil"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      style={{
                        width: "100%",
                      }}
                      options={[
                        {
                          value: "SOLTERO",
                          label: "SOLTERO",
                        },
                        {
                          value: "CONVIVIENTE",
                          label: "CONVIVIENTE",
                        },
                        {
                          value: "CASADO",
                          label: "CASADO",
                        },
                        {
                          value: "DIVORCIADO",
                          label: "DIVORCIADO",
                        },
                        {
                          value: "VIUDO",
                          label: "VIUDO",
                        },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  {/* {opcionesEmpresaSeleccionada && !opcionesEmpresaSeleccionada.includes(OPCIONES.PAGINA_MANTENIMIENTO_COLABORADOR.TXT_OMITIR_RANGO_SUELDO) &&( */}
                  {!omitirValidarRango && (
                    <Form.Item
                      name={["contrato", "sueldo"]}
                      label="Sueldo"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          validator: (_, value) =>
                            value && (value < rangoMinimo || value > rangoMaximo)
                              ? Promise.reject(new Error('El sueldo debe estar entre ' + rangoMinimo + ' y ' + rangoMaximo + '.'))
                              : Promise.resolve(),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                  {/* {opcionesEmpresaSeleccionada && opcionesEmpresaSeleccionada.includes(OPCIONES.PAGINA_MANTENIMIENTO_COLABORADOR.TXT_OMITIR_RANGO_SUELDO)&&( */}
                  {omitirValidarRango && (
                    <Form.Item
                      name={["contrato", "sueldo"]}
                      label="Sueldo"
                      rules={[
                        {
                          required: true,
                        }
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  )}
                </Col>
                <Col span={8}>
                  <Form.Item
                    name={["contrato", "bono"]}
                    label="Bono"
                  //  rules={[
                  //      {
                  //          required: true,
                  //      },
                  //  ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name={["domicilioActual", "departamento"]}
                    label="Departamento"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={onChangeDepartamento}
                    >
                      {departamentos !== undefined &&
                        departamentos?.map((departamento) => (
                          <Select.Option
                            key={departamento.nombre}
                            value={departamento.nombre}
                          >
                            {departamento.nombre}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Provincia"
                    name={["domicilioActual", "provincia"]}
                    rules={[{ required: true }]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={onChangeProvincia}
                    >
                      {provincias !== undefined &&
                        provincias?.map((info) => (
                          <Select.Option
                            key={info.provincia}
                            value={info.provincia}
                          >
                            {info.provincia}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    label="Distrito"
                    name={["domicilioActual", "distrito"]}
                    rules={[{ required: true }]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {distritos !== undefined &&
                        distritos?.map((objeto) => (
                          <Select.Option
                            key={objeto.distrito}
                            value={objeto.distrito}
                          >
                            {objeto.distrito}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name={["domicilioActual", "direccion"]}
                    label="Direccion"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input onChange={onChangeDireccion} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name={["contrato", "fechaInicioContrato"]}
                    label="Fecha Inicio Contrato"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format={"DD/MM/YYYY"}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name={["contrato", "fechaFinContrato"]}
                    label="Fecha Fin Contrato"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      format={"DD/MM/YYYY"}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {!ocultarTipoServicio && (
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name={["contrato", "tipoServicio"]}
                      label="Tipo de Servicio"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Select
                        style={{
                          width: "100%",
                        }}
                        options={[
                          {
                            value: "SERVICIO TECNICO DE MUESTRERIA",
                            label: "SERVICIO TECNICO DE MUESTRERIA",
                          },
                          {
                            value: "PERFORACIÓN DE DIAMANTINA",
                            label: "PERFORACIÓN DE DIAMANTINA",
                          },
                          {
                            value: "PERFORACIÓN DE AIRE REVERSO",
                            label: "PERFORACIÓN DE AIRE REVERSO",
                          },
                          {
                            value: "PERFORACIÓN POZOS DE AGUA",
                            label: "PERFORACIÓN POZOS DE AGUA",
                          },
                          {
                            value: "PERFORACIÓN GEOTECNIA",
                            label: "PERFORACIÓN GEOTECNIA",
                          },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="responsableRenovarContratoId"
                    label="Responsable de Renovación de Contrato"
                  >
                    <Select
                      showSearch
                      allowClear
                      onSearch={onSearchColaboradorResponsableContrato}
                      onChange={onSeleccionarResponsableRenovacionContrato}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {responsablesRenovarContrato !== undefined &&
                        responsablesRenovarContrato.map((colaborador) => {
                          return (
                            <Select.Option
                              key={colaborador.id}
                              value={colaborador.id}
                            >
                              {colaborador.nombreCompleto}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    name="administradorObraId"
                    label="Administrador de Obra"
                    hidden={ocultarAdminObra}
                  >
                    <Select
                      showSearch
                      allowClear
                      onSearch={onSearchAdministradorObra}
                      onChange={onSeleccionarAdministradorObra}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {administradoresObra !== undefined &&
                        administradoresObra.map((colaborador) => {
                          return (
                            <Select.Option
                              key={colaborador.id}
                              value={colaborador.id}
                            >
                              {colaborador.nombreCompleto}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </React.Fragment>
          )}
        {isExplomin && (
          <>
            <Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
              <Space>
                <WalletOutlined /> Cuenta Haberes
              </Space>
            </Divider>
            <Form.Item
              name={["cuentaHaberes", "exist"]}
              rules={[
                { required: true, message: "Por favor seleccione una opcion" },
              ]}
            >
              <Radio.Group>
                <Radio value={true}>Tengo Cuenta</Radio>
                <Radio value={false}>Crear Cuenta</Radio>
              </Radio.Group>
            </Form.Item>
            <Row gutter={16}>
              <Col span={12}>
                {/* <Form.Item label="Banco" name={["cuentaHaberes", "banco"]} rules={[{ required: existeCuenta }]}> */}
                <Form.Item
                  label="Banco"
                  name={["cuentaHaberes", "banco"]}
                  rules={[{ required: true }]}
                >
                  {/* <Input disabled={!existeCuenta} /> */}
                  <Select>
                    <Select.Option value="BANCO CONTINENTAL">
                      BANCO CONTINENTAL
                    </Select.Option>
                    <Select.Option value="BANCO DE CREDITO">
                      BANCO DE CREDITO
                    </Select.Option>
                    <Select.Option value="BANCO INTERBANK">
                      BANCO INTERBANK
                    </Select.Option>
                    <Select.Option value="BANCO SCOTIABANK">
                      BANCO SCOTIABANK
                    </Select.Option>
                    <Select.Option value="BANCO CITIBANK">
                      BANCO CITIBANK
                    </Select.Option>
                    <Select.Option value="BANCO HSBC">BANCO HSBC</Select.Option>
                    <Select.Option value="BANCO DE LA NACION">
                      BANCO DE LA NACION
                    </Select.Option>
                    <Select.Option value="BANCO DE COMERCIO">
                      BANCO DE COMERCIO
                    </Select.Option>
                    <Select.Option value="BANCO FINANCIERO">
                      BANCO FINANCIERO
                    </Select.Option>
                    <Select.Option value="BANBIF">BANBIF</Select.Option>
                    <Select.Option value="BANCO FALABELLA">
                      BANCO FALABELLA
                    </Select.Option>
                    <Select.Option value="BANCO RIPLEY">
                      BANCO RIPLEY
                    </Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Moneda"
                  name={["cuentaHaberes", "tipoMoneda"]}
                  // rules={[{ required: existeCuenta }]}
                  rules={[{ required: true }]}
                >
                  {/* <Input disabled={!existeCuenta} /> */}
                  <Select>
                    <Select.Option value="SOLES">SOLES</Select.Option>
                    <Select.Option value="DOLARES">DOLARES</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Cuenta"
                  name={["cuentaHaberes", "nroCuenta"]}
                  rules={[{ required: existeCuenta }]}
                >
                  <Input disabled={!existeCuenta} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Cuenta CCI"
                  name={["cuentaHaberes", "nroCuentaCCI"]}
                  rules={[{ required: existeCuenta }]}
                >
                  <Input disabled={!existeCuenta} />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        {!isCclp && !isLaredo && (
          <>
            <Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
              <Space>
                <CarOutlined /> Vacaciones
              </Space>
            </Divider>
            <Row>
              <Col span={24}>
                <Form.Item
                  label="Otros aprobadores inmediatos"
                  name="otrosAprobadoresInmediatos"
                >
                  {/* <Select
                    mode="multiple"
                    allowClear
                    options={colaboradoresOtrosAprobadores}
                    onChange={handleChangeOtrosAprobadores}
                  /> */}

                  <DebounceSelect
                    mode="multiple"
                    value={colaboradoresOtrosAprobadores}
                    placeholder="Selecciona los jefes inmediatos"
                    fetchOptions={fetchOtrosAprobadores}
                    onChange={(newValue) => {
                      setColaboradoresOtrosAprobadores(newValue);
                    }}
                    style={{
                      width: '100%',
                    }}
                  />

                </Form.Item>
              </Col>
            </Row>
          </>
        )}

        {getUsuarioAutenticado().empresaSeleccionada
          ?.moduloOnboardingHabilitado && (
            <React.Fragment>
              <Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
                <Space>
                  <FolderAddOutlined /> Onboarding
                </Space>
              </Divider>

              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    label="Plantilla Onboarding"
                    name="plantillaOnboardingId"
                  >
                    <Select allowClear>
                      {plantillasOnboarding.length > 0 &&
                        plantillasOnboarding.map((plantillaOnboarding) => {
                          return (
                            <Select.Option
                              key={plantillaOnboarding.id}
                              value={plantillaOnboarding.id}
                            >
                              {plantillaOnboarding.nombre}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </React.Fragment>
          )}

        {/* <Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
          <Space>
            <PlusOutlined /> Información adicional
          </Space>
        </Divider>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="firmaHolografa" label="Firma Hológrafa">
              <Upload {...propsFirma} maxCount={1} accept={FormatoCarga.IMAGEN.extension}>
                <Button>Cargar Firma Hológrafa</Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="huellaDactilar" label="Huella Dactilar">
              <Upload {...propsHuella} maxCount={1} accept={FormatoCarga.IMAGEN.extension}>
                <Button>Cargar Huella Dactilar</Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row> */}

        <Row justify="center">
          <Space>
            <Col>
              <Form.Item style={{ textAlign: "center" }}>
                <Button type="default" onClick={handleOnClickCancelar}>
                  Cancelar
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  htmlType="submit"
                  loading={loading}
                >
                  Crear Colaborador
                </Button>
              </Form.Item>
            </Col>
          </Space>
        </Row>
      </Form>
    </ContainerCrear>
  );
}

const DebounceSelect = ({ fetchOptions, debounceTimeout = 800, ...props }) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      options={options}
      {...props}
    />
  );
}