import { SolicitudAusenciaService } from "services";
import {  
    filtroColaboradorAtom,
    filtroFechaInicioAtom,
    filtroFechaFinAtom,filtroEstadoAtom,filtroEstadoJefeInmediatoAtom,filtroTipoAusenciaAtom,
    filtroEstadoOtrosCargosAtom,
    filtroEstadoAprobacionAtom,
    loadingExportarAtom   
} from "../consultaSolicitudesStore";
import { useAtom } from "jotai";
import { saveAs } from "file-saver";
import { notification } from "antd";
import { useEffect } from "react";

const useGenerarReporteExcelSolicitudesAusencia = ()=>{
  const [filtroColaborador,setfiltroColaborador] = useAtom(filtroColaboradorAtom);
  const [filtroFechaInicio,setFiltroFechaInicio] = useAtom(filtroFechaInicioAtom);
  const [filtroFechaFin,setFiltroFechaFin] = useAtom(filtroFechaFinAtom);
  const [filtroEstado,setFiltroEstado]= useAtom(filtroEstadoAtom);
  const [filtroEstadoJefeInmediato,setFiltroEstadoJefeInmediato]= useAtom(filtroEstadoJefeInmediatoAtom);
  const [filtroTipoAusencia,setFiltroTipoAusencia] = useAtom(filtroTipoAusenciaAtom);
  const [filtroEstadoOtrosCargos,setFiltroEstadoOtrosCargos] = useAtom(filtroEstadoOtrosCargosAtom); 
  const [filtroEstadoAprobacion, setFiltroEstadoAprobacion] = useAtom(filtroEstadoAprobacionAtom);
  const [loadingExportar, setLoadingExportar] = useAtom(loadingExportarAtom);

  useEffect(()=>{ 
           
  },[filtroColaborador,filtroFechaInicio,filtroFechaFin,filtroEstado,filtroEstadoJefeInmediato,filtroTipoAusencia,filtroEstadoOtrosCargos, filtroEstadoAprobacion])

    const generarReporteSolicitudes= async()=>{         
        try {
          setLoadingExportar(true);
          const respuesta= await SolicitudAusenciaService.descargarReporteExcelSolicitudes({          
            colaborador:filtroColaborador,
            mesInicio:filtroFechaInicio,
            mesFin:filtroFechaFin,       
            // estado:filtroEstado,
            // estadoJefeInmediato:filtroEstadoJefeInmediato,
            // estadoOtrosCargos:filtroEstadoOtrosCargos,
            estadoAprobacion: filtroEstadoAprobacion,
            tipoAusencia:filtroTipoAusencia,    
           });
           setLoadingExportar(false);
         const filename = respuesta.headers.filename;
         saveAs(respuesta.data,filename);
        } catch (error) {
          setLoadingExportar(false);
         notification.error({
           message:"No hay vacaciones para exportar",        
           description: error.response.data.mensaje,
          }
         )
        }                
       }
       return{
         generarReporteSolicitudes,
       }

}
export default useGenerarReporteExcelSolicitudesAusencia;