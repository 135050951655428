import { Button } from "antd";
import React ,{useContext} from "react";
import { useAtom } from "jotai";
import {visibleModalFirmaHolografa} from "../../ListarFirmaHolografaStore";
import { SecurityContext } from "context/SecurityContextProvider";
import { FirmaHolografaService } from "services/FirmaHolografaService";
import {urlFirmaHolografaAtom} from "../../ListarFirmaHolografaStore";


const VerFirmaHolografa=({firma})=>{

   const[visible,setVisible]=useAtom(visibleModalFirmaHolografa);
   const { getUsuarioAutenticado } = useContext(SecurityContext);
   const[,setUrl]=useAtom(urlFirmaHolografaAtom);   

   const onClickVerFirma= async()=>{
        setVisible(true);
       //const respuesta=await FirmaHolografaService.getUrlDocumento(firma.id,getUsuarioAutenticado().empresaSeleccionada.id);
       const respuesta=await FirmaHolografaService.getUrlDocumento(firma.id,firma.empresa.id);
      // console.log("respuesta:",respuesta.urlFirmaHolografa);
       setUrl(respuesta.urlFirmaHolografa);
    
   }

           return(
            <Button type={"link"} onClick={onClickVerFirma}>
                Ver
            </Button>
        )

}
export default VerFirmaHolografa;