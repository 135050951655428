import React, { useContext } from "react";
import { Button } from "antd";
import { EditOutlined, FilePdfOutlined } from "@ant-design/icons";

import "./index.css"
import { EstadoDocumentoGenerico } from "enums/EstadoDocumentoGenerico";
import { FirmaDocumentoGenericoMisDocumentosContext } from "context/firma-documento-generico/mis-documentos";
import { VisorPdfContext } from "context/visor-pdf";
import { useMisDocumentosStore, useModalFirmarStore } from "hooks/firma-documento-generico";

const MenuAcciones = ({ documentoGenerico }) => {

    const BtnFirmarDocumento = ({ documentoGenerico }) => {

        const mostrarModalVisorFirma = useModalFirmarStore(state => state.mostrarModalVisorFirma);

        return (
            <Button type="link" onClick={() => mostrarModalVisorFirma(documentoGenerico)}>
                <EditOutlined /> Firmar Documento
            </Button >
        );
    }

    const BtnVerDocumento = ({ documentoGenerico }) => {

        const getUrlPdf = useMisDocumentosStore(state => state.getUrlPdf);
        const { mostrarVisorPdf } = useContext(VisorPdfContext);

        const onClickBtnVerDocumento = async () => {
            const urlPdf = await getUrlPdf(documentoGenerico.id);
            const informacion = {
                descripcion: documentoGenerico.nombreDocumento
            }
            mostrarVisorPdf(urlPdf, informacion);
        }

        return (
            <Button type="link" onClick={onClickBtnVerDocumento}>
                <FilePdfOutlined /> Ver Documento
            </Button>
        );
    }

    switch (documentoGenerico.estadoDocumento) {
        case EstadoDocumentoGenerico.FIRMADO:
        case EstadoDocumentoGenerico.RECHAZADO:
            return <BtnVerDocumento documentoGenerico={documentoGenerico} />

        case EstadoDocumentoGenerico.PENDIENTE_FIRMA:
            return <BtnFirmarDocumento documentoGenerico={documentoGenerico} />

        default:
            return "";
    }
}
export default MenuAcciones;