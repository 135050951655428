import React from "react";
import { Table,Button,Popconfirm } from "antd";
import { convocatoriaSeleccionadaIdAtom, loadingAtom} from "../AgregarPostulanteStore";
import { useAtom } from "jotai";
import { useCargarPostulantesConvocatoria } from "../hooks";
import { ConvocatoriaService } from "services/reclutamiento-y-seleccion/ConvocatoriaService";
import {DeleteOutlined} from "@ant-design/icons";
const TablaPostulantes=()=>{   

    const {postulantesConvocatoria,setPostulantesConvocatoria} = useCargarPostulantesConvocatoria();
    const [convocatoriaSeleccionadaId,setConvocatoriaSeleccionadaId]=useAtom(convocatoriaSeleccionadaIdAtom);
    const [,setLoading ]=useAtom(loadingAtom);

    const onClickQuitar = async(postulanteId) =>{   
        //console.log("convocatoriaID:",convocatoriaSeleccionadaId);      
        setLoading(true)
       await ConvocatoriaService.eliminarPostulante(convocatoriaSeleccionadaId,postulanteId);
       setPostulantesConvocatoria(postulantesConvocatoria.filter(postulante=>postulante.id!==postulanteId));
       setLoading(false)
    }
   const columnas=[
    {
        title:"Nombre",
        dataIndex:"nombreCompleto",
        align:"center",
    },
    {
        title:"Acciones",       
        align:"center",
        key:"acciones",
        render:(fila)=>(            
                 <Button type="link" danger icon={<DeleteOutlined />}  onClick={()=>onClickQuitar(fila.id)} > 
                   Quitar
                </Button>                       
            )       
           
    },
    
   ];
   return(
       <Table       
         rowKey={(postulante)=>postulante.id}
         dataSource={postulantesConvocatoria}
         columns={columnas}
         pagination={false}
       />
   )
};
export default TablaPostulantes;