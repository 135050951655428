import React from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

const BotonesExtra = () => {
  let history = useHistory();

  const onClickCrearPerfil = () => {
    history.push("/reclutamiento-y-seleccion/solicitud-personal/perfiles/crear");
  };
  return (
    <Button type="primary" onClick={onClickCrearPerfil}>
      Crear Perfil
    </Button>
  );
};
export default BotonesExtra;
