import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Form, Modal, Select, Upload, message } from 'antd';
import { useAtom } from 'jotai';
import { carpetaSeleccionadaAtom, colaboradorAtom, openModalSubirDocumentosAtom } from 'pages/legajo/legajoV3/LegajoColaboradorStore';
import TipoDocumentoService from 'services/TipoDocumentoService';

import {
    UploadOutlined
} from "@ant-design/icons"
import { LegajoColaboradorService } from 'services';
import { useListarDocumentos } from '../hooks';

const ModalSubirDocumentos = () => {

    const [form] = Form.useForm();
    const [open, setOpen] = useAtom(openModalSubirDocumentosAtom);
    const [colaborador] = useAtom(colaboradorAtom);
    const [carpetaSeleccionada] = useAtom(carpetaSeleccionadaAtom);
    const [tiposDocumento, setTiposDocumento] = useState([])
    const [archivos, setArchivos] = useState([]);
    const [loading, setLoading] = useState(false);
    const { fetchDocumentos } = useListarDocumentos();

    useEffect(() => {
        const fetchData = async () => {
            if (open) {
                const tiposDocumento = await TipoDocumentoService.listarPorCarpetaId(carpetaSeleccionada);

                setTiposDocumento(
                    tiposDocumento.map((tipoDocumento) => ({
                        value: tipoDocumento.id,
                        label: tipoDocumento.nombre
                    }))
                );
            }
        }
        fetchData();
    }, [open])

    const propsUpload = {
        onRemove: (file) => {
            setArchivos([]);
            return {
                archivos: [],
            };
        },
        beforeUpload: (file) => {
            if (file.type !== "application/pdf") {
                message.error(`${file.name} no es un archivo permitido (.docx o .pdf)`);
            } else {
                setArchivos(file);
                return false;
            }
        },
        archivos,
    };

    const onClickBtnSubirDocumento = () => {

        form.validateFields()
            .then(async (datosFormulario) => {
                try {
                    setLoading(true);
                    const formData = new FormData();
                    formData.append("numeroDocumentoIdentidad", colaborador.numeroDocumentoIdentidad);
                    if (datosFormulario.tipoDocumentoId) {
                        formData.append("tipoDocumentoId", datosFormulario.tipoDocumentoId);
                    }
                    formData.append("carpetaId", carpetaSeleccionada);
                    formData.append("periodo", datosFormulario.periodo.format("MM/YYYY"));
                    formData.append("documento", datosFormulario.documento?.file);

                    await LegajoColaboradorService.subirDocumento(formData);

                    message.success("Se subió correctamente el documento");

                    fetchDocumentos(carpetaSeleccionada);
                    setLoading(false);
                    setOpen(false);

                } catch (error) {
                    console.log(error);
                }
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    }

    return (
        <Modal
            title="Subir Documento"
            open={open}
            onOk={onClickBtnSubirDocumento}
            okText="Subir Documento"
            confirmLoading={loading}
            onCancel={() => setOpen(false)}
            cancelButtonProps={{ disabled: loading }}
            destroyOnClose={true}
            afterClose={() => form.resetFields()}
        >
            <Form
                form={form}
                layout="vertical"
            >

                <Form.Item
                    label="Seleccione un periodo"
                    name={"periodo"}
                    rules={[
                        {
                            required: true,
                            message: "Debe ingresar el periodo. ",
                        },
                    ]}
                >
                    <DatePicker allowClear={false} format={"MM/YYYY"} picker="month" style={{ width: "100%" }} />
                </Form.Item>

                <Form.Item
                    name="tipoDocumentoId"
                    label="Tipo Documento"
                    rules={[
                        {
                            required: true,
                            message: "Debe seleccionar el tipo de documento. ",
                        },
                    ]}
                >
                    <Select
                        showSearch
                        allowClear
                        options={tiposDocumento}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </Form.Item>

                <Form.Item
                    name="documento"
                    label="Documento"
                    valuePropName="documento"
                    rules={[{
                        required: true,
                        message: "Debe seleccionar un archivo.",
                    }]}>
                    <Upload
                        {...propsUpload}
                        maxCount={1}
                        accept={["application/pdf"]}>
                        <Button icon={<UploadOutlined />}>Subir Documento</Button>
                    </Upload>
                </Form.Item>
            </Form>
        </Modal >
    );
};

export default ModalSubirDocumentos;