import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const Botones=()=>{

    let history = useHistory();

    const BotonCrearSeccion = () =>{

       const onClickBtnCrearSeccion = () =>{
            history.push("/seccion/crear");
        }
        return(
            <Button
            type="primary"
            icon={<PlusOutlined/>}
            onClick={onClickBtnCrearSeccion}            
            >
                Crear Sección
            </Button>
        )

    }
    return(
        <React.Fragment>
            <BotonCrearSeccion/>
        </React.Fragment>
    )

}
export default Botones;