import React from "react";
import { Titulo } from "./Titulo";
import { Botones } from "./Botones";
import { useModalVisorPdf } from "store/global";
import { Alert } from "antd";

const Header = ({ botonesExtra }) => {
  const motivoRechazo = useModalVisorPdf((state) => state.motivoRechazo);

  return (
    <div>
      <div className="milegajo-visor-pdf-header">
        <Titulo />
        <Botones botonesExtra={botonesExtra} />
      </div>

      {motivoRechazo && (
        <Alert message="Documento Rechazado" description={"Motivo : " + motivoRechazo} type="warning" showIcon />
      )}
    </div>
  );
};

export default Header;
