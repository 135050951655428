import React from "react";
import { Container } from "components/Container";
import { useHistory } from "react-router";
import { Form, Input, Row, Col, Button, Space, notification, Select } from "antd";
import { FormCrearHorario } from "./componentes/form-crear-horario";

const CrearHorarioPage=()=>{
  let history = useHistory();

  const onClickRetonarListarTipoDocumentos=()=>{
    history.push("/asistencia/horarios");
  }

  return(
    <Container titulo="Crear Horario" onClickRetornar={onClickRetonarListarTipoDocumentos}>
       <FormCrearHorario/>
    </Container>
  )

}
export default CrearHorarioPage;