import React, { useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { TipoControl } from "enums";
import { useAtom } from "jotai";
import { Page } from "react-pdf";
import { Menu, Dropdown } from "antd";
import {
    controlPdfDefaultAtom,
    componentesAtom,
    visibleDrawerPropiedadesAtom,
    controlSeleccionadoAtom,
    zoomAtom,
} from "../../DisenarFormularioPdfStore";

import { Rnd } from "react-rnd";
import produce from "immer";

const useLocalDrop = (numeroPagina) => {
    const ref = useRef();
    const [componentes, setComponentes] = useAtom(componentesAtom);
    const [controlPdfDefault] = useAtom(controlPdfDefaultAtom);

    const [, dropTarget] = useDrop({
        accept: TipoControl.EFORM_PDF,
        drop(item, monitor) {
            const offset = monitor.getSourceClientOffset();
            if (offset && ref.current) {
                const dropTargetXy = ref.current.getBoundingClientRect();
                setComponentes(
                    produce((draft) => {
                        draft.push({
                            key: getKey(item.tipo),
                            label: getKey(item.tipo),
                            type: item.tipo,
                            required: true,
                            overlay: {
                                page: numeroPagina,
                                left: offset.x - dropTargetXy.left,
                                top: offset.y - dropTargetXy.top,
                                width: controlPdfDefault.ancho,
                                height: controlPdfDefault.alto,
                            },
                            condicional: {
                                accion: undefined,
                                control: undefined,
                                valor: undefined,
                            },
                            signatureType: item.tipo === TipoControl.SIGNATURE ? "IMAGEN_Y_DESCRIPCION" : "",
                            requiereFirmaElectronica: false,
                        });
                    })
                );
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    });

    const getKey = (tipoComponente) => {
        const componentesPorTipo = componentes.filter((c) => c.type === tipoComponente);
        return tipoComponente + (componentesPorTipo.length + 1);
    };

    return (elem) => {
        ref.current = elem;
        dropTarget(ref);
    };
};

const PaginaPdf = ({ numeroPagina }) => {
    const [componentes] = useAtom(componentesAtom);
    const refPaginaPdf = useLocalDrop(numeroPagina);
    const [zoom] = useAtom(zoomAtom);

    return (
        <React.Fragment>
            <div
                ref={refPaginaPdf}
                style={{
                    position: "relative",
                }}
            >
                <div id="componentes" style={{ zoom: zoom }}>
                    <Page pageNumber={numeroPagina} loading={null} />
                    {componentes?.length > 0 &&
                        componentes
                            .filter((componente) => componente.overlay.page === numeroPagina)
                            .map((componente, index) => {
                                return <ControlPdf key={componente.key} componente={componente} />;
                            })}
                </div>
            </div>
            <SeparadorPaginas />
        </React.Fragment>
    );
};

const ControlPdf = ({ componente }) => {
    const [, setComponentes] = useAtom(componentesAtom);
    const [, setVisibleDrawerPropiedades] = useAtom(visibleDrawerPropiedadesAtom);
    const [, setControlSeleccionado] = useAtom(controlSeleccionadoAtom);
    const [refRnd, setRefRnd] = useState(undefined);

    const onClickMenuEmergente = (e) => {
        if (e.key === "propiedades") {
            setControlSeleccionado({ ...componente, ref: refRnd });
            setVisibleDrawerPropiedades(true);
        } else if (e.key === "quitar") {
            setComponentes(
                produce((draft) => {
                    const index = draft.findIndex((c) => c.key === componente.key);
                    if (index !== -1) draft.splice(index, 1);
                })
            );
        }
    };

    useEffect(() => {
        if (refRnd) {
            setControlSeleccionado({ ...componente, ref: refRnd });
        }
    }, [refRnd]);

    const menu = (
        <Menu onClick={onClickMenuEmergente}>
            <Menu.Item key="propiedades">Propiedades</Menu.Item>
            <Menu.Item key="quitar">Quitar</Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={["contextMenu"]}>
            <Rnd
                ref={(ref) => setRefRnd(ref)}
                tabIndex="-1"
                className="eform-element"
                // key={componente.key}
                bounds="parent"
                onDragStop={(event, data) => {
                    // console.log("event", event);
                    setComponentes(
                        produce((draft) => {
                            const componenteEncontrado = draft.find((c) => c.key === componente.key);
                            if (componenteEncontrado) {
                                componenteEncontrado.overlay.left = data.x;
                                componenteEncontrado.overlay.top = data.y;
                            }
                        })
                    );
                }}
                onResizeStop={(e, direction, ref, delta, position) => {
                    setComponentes(
                        produce((draft) => {
                            const componenteEncontrado = draft.find((c) => c.key === componente.key);
                            if (componenteEncontrado) {
                                componenteEncontrado.overlay.width = parseInt(ref.style.width);
                                componenteEncontrado.overlay.height = parseInt(ref.style.height);
                            }
                        })
                    );
                }}
                style={{
                    position: "absolute",
                    fontSize: "12px",
                    background: "#ffe4b5",
                }}
                default={{
                    x: componente.overlay.left,
                    y: componente.overlay.top,
                    width: componente.overlay.width,
                    height: componente.overlay.height,
                }}
                enableResizing={{
                    bottomRight: true, // Activa solo la esquina inferior derecha para redimensionar
                }}
            ></Rnd>
        </Dropdown>
    );
};

const SeparadorPaginas = () => {
    return (
        <div style={{ width: "100%", position: "relative" }}>
            <div style={{ height: "2em" }}></div>
        </div>
    );
};

export default PaginaPdf;
