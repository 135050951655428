import { BaseService } from "./BaseService";
const FormularioSeguimientoService = {
  async listar(empresaId) {
    const respuesta = await BaseService.get("/formulario/envio/listar", {
      params: { empresaId: empresaId },
    });
    return respuesta.data;
  },

  async exportarDatosExcel(formData) {
    const respuesta = await BaseService.post(
      "/formulario/envio/exportar-datos-excel",
      formData,
      {
        responseType: "blob",
      }
    );
    return respuesta;
  },

  // async verDocumentoPdf(formData) {
  //   const respuesta = await BaseService.post(
  //     "/formulario/detalle-envio/ver-documento-pdf",
  //     formData,
  //     {
  //       responseType: "blob",
  //     }
  //   );
  //   return respuesta;
  // },

  async getUrlDocumentoPdf(detalleEnvioId) {
    const respuesta = await BaseService.get(`/formulario/seguimiento/detalle-envio/${detalleEnvioId}/url-pdf`);
    return respuesta.data;
  },

  async getUrlDocumento(empresaId, detalleEnvioId) {
    const respuesta = await BaseService.get(`/formulario/seguimiento/detalle-envio/${detalleEnvioId}/url-pdf`);
    return respuesta.data;
  },

  async getUrlPlantillaDocumento(tipoDocumentoId) {
    const respuesta = await BaseService.get(`/formulario/detalleOnboardingIngreso/${tipoDocumentoId}/url-pdf`);
    return respuesta.data;
  },

  async getDescargarDocumento(detalleEnvioId) {
    const respuesta = await BaseService.get(`/formulario/descargar/detalle-envio/${detalleEnvioId}`, {
      responseType: "blob",
    });
    return respuesta;
  },
};
export default FormularioSeguimientoService;