import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { Container } from "components/Container";
import { TablaPostulantes } from "./componentes";
import { BotonesExtra } from "./componentes/BotonesExtra";
import {
  UnorderedListOutlined,
} from "@ant-design/icons";

const PostulantesListarPage = () => {
  return (
    <JotaiProvider>
      <Container icono={<UnorderedListOutlined />} titulo="Postulantes" botonExtra={<BotonesExtra />}>
        <TablaPostulantes />
      </Container>
    </JotaiProvider>
  );
};

export default PostulantesListarPage;
