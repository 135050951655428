import React from "react";
import { Input, Form, Select, Row, Col, Button, Modal } from "antd";
import { useCargarPlantillas, useCargarPostulantes, useCargarConvocatorias } from "../../hooks";
import TablaDocumentos from "./TablaDocumentos";
import useSeleccionarPlantilla from "../../hooks/useSeleccionarPlantilla";
import useSeleccionarEtapas from "../../hooks/useSeleccionarEtapas";
import useSeleccionarPostulante from "../../hooks/useSeleccionarPostulante";
import useCrearProceso from "../../hooks/useCrearProceso";
import { convocatoriaSeleccionadaIdAtom } from "../../ProcesosCrearStore";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { useAtom } from "jotai";
import { useEffect } from "react";

const Formulario = () => {
  const { crearProceso } = useCrearProceso();
  const [formProceso] = Form.useForm();
  const { postulantes } = useCargarPostulantes();

  useEffect(() => {
    formProceso.setFieldsValue({
      postulanteId: [],
    });
  }, [postulantes]);

  const confirmarNotificacion = (proceso) => {
    Modal.confirm({
      title: "Notificar",
      content: "¿ Está seguro de notificar el proceso ?",
      icon: <ExclamationCircleOutlined />,
      okText: "Si",
      cancelText: "No",
      onOk() {
        crearProceso(proceso);
      },
    });
  };

  return (
    <Form layout="vertical" style={{ padding: "0px 2%" }} onFinish={confirmarNotificacion} form={formProceso}>
      <SelectConvocatorias />
      <SelectPostulante />
      <Row gutter={24}>
        <Col span={12}>
          <SelectPlantillaDocumentos />
        </Col>
        <Col span={12}>
          <SelectEtapa />
        </Col>
      </Row>
      <TablaDocumentos />
      <br />
      <BotonNotificar />
    </Form>
  );
};

const SelectConvocatorias = () => {
  const { convocatorias } = useCargarConvocatorias();
  const [, setConvocatoriaSeleccionadaId] = useAtom(convocatoriaSeleccionadaIdAtom);

  const onChangeConvocatoria = (value) => {
    setConvocatoriaSeleccionadaId(value);
  };

  return (
    <Form.Item
      label="Convocatoria"
      name="convocatoria"
      rules={[{ required: true, message: "Seleccione la convocatoria" }]}
    >
      <Select placeholder="Seleccione la convocatoria" onChange={onChangeConvocatoria}>
        {convocatorias !== undefined &&
          convocatorias.map((convocatoria) => {
            return (
              <Select.Option key={convocatoria.id} value={convocatoria.id}>
                {convocatoria.cargo}{" "}
              </Select.Option>
            );
          })}
      </Select>
    </Form.Item>
  );
};

const SelectPostulante = () => {
  const { postulantes } = useCargarPostulantes();
  const { seleccionarPostulante } = useSeleccionarPostulante();
  return (
    <Form.Item
      label="Postulante"
      name={"postulanteId"}
      rules={[{ required: true, message: "Seleccione el postulante" }]}
    >
      <Select
        style={{ width: "100%" }}
        showSearch
        optionFilterProp="children"
        allowClear
        onChange={seleccionarPostulante}
        mode="multiple"
      >
        {postulantes !== undefined &&
          postulantes.map((postulante) => {
            return (
              <Select.Option key={postulante.id} value={postulante.id}>
                {postulante.nombreCompleto}
              </Select.Option>
            );
          })}
      </Select>
    </Form.Item>
  );
};

const SelectPlantillaDocumentos = () => {
  const { plantillas } = useCargarPlantillas();
  const { seleccionarPlantilla } = useSeleccionarPlantilla();

  return (
    <Form.Item
      label="Plantilla de documentos"
      name={"plantillaId"}
      rules={[{ required: true, message: "Seleccione la plantilla de documentos" }]}
    >
      <Select style={{ width: "100%" }} onChange={seleccionarPlantilla}>
        {plantillas !== undefined &&
          plantillas.map((plantilla) => {
            return (
              <Select.Option key={plantilla.id} value={plantilla.id}>
                {plantilla.nombre}
              </Select.Option>
            );
          })}
      </Select>
    </Form.Item>
  );
};

const SelectEtapa = () => {
  const { seleccionarEtapas } = useSeleccionarEtapas();
  return (
    <Form.Item
      label="Filtrar por Etapas"
      name={"etapasId"}
      rules={[{ required: true, message: "Seleccione las etapas" }]}
    >
      <Select style={{ width: "100%" }} mode="multiple" onChange={seleccionarEtapas}>
        <Select.Option value="POSTULANTE">POSTULANTE</Select.Option>
        <Select.Option value="SELECCIONADO">SELECCIONADO</Select.Option>
        <Select.Option value="CONTRATADO">CONTRATADO</Select.Option>
      </Select>
    </Form.Item>
  );
};
const BotonNotificar = () => {
  return (
    <Form.Item name="BotonNotificar" style={{ textAlign: "center" }}>
      <Button type="primary" htmlType="submit">
        Notificar
      </Button>
    </Form.Item>
  );
};

export default Formulario;
