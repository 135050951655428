import React, { useEffect, useState, useContext } from 'react';
import { Form, Modal, Space,Row, notification,
    Col } from "antd";
import {
  FileOutlined,
  HighlightOutlined,
  NumberOutlined,
  SolutionOutlined,
  } from "@ant-design/icons";
import { useAtom } from "jotai";
import {visibleModalConfirmacionAtom} from "../../NotificarComunicadoStore"
import ComunicadoService from 'services/ComunicadoService';
import { useHistory } from 'react-router';



const ModalConfirmacion = ({tipoEnvio, responsabelEnvio,tipoDocumento, descripcion, comunicado}) => {
  const [visibleModalConfirmacion, setVisibleModalConfirmacion] = useAtom(visibleModalConfirmacionAtom);
  let history=useHistory();
  const[loading,setLoading]=useState(false);

  const onClickBtnCerrar= async() =>{
    setVisibleModalConfirmacion(false);
  }
  const onClickBtnConfirmar= async() =>{
    try{
      setLoading(true);
      const respuesta = await ComunicadoService.insertar(comunicado);     
      notification.success({
        message: "Operación exitosa",
        description: "Se registró el comunicado exitosamente",
      });
       setLoading(false);
       //antiguo redireccionamiento
      //history.push("/notificacion/seguimiento/");
      //nuevo redireccionamiento
      history.push(`/notificacion/seguimiento/detalle/Comunicados/${respuesta.id}`);
    } catch (error) {
       setLoading(false);
      console.error(error);
      notification.error({
        message: "Alerta!",
        description: error.response.data.mensaje,
      });
    }
    
    setVisibleModalConfirmacion(false);
  }
 
  
    return (
        <Modal
            title="Detalle de Notificación"
            visible={visibleModalConfirmacion}
           onOk={onClickBtnConfirmar}
           onCancel={onClickBtnCerrar}
            cancelButtonProps={{ style: { display: 'none' } }}
            okText="Aceptar"
            okButtonProps={{loading:loading}}
            //confirmLoading={loading}
            forceRender
            width={500}
        >

          <Row align="center">
          <Col span={27} className="space-right">
            
            <Row justify="start">
            <Space size={46}>
              <span style={{ fontWeight: "bold" }}>
                <FileOutlined /> Documento Laboral
              </span>
              <span >
                {tipoDocumento}
              </span>
              </Space>
            </Row>

            <Row justify="start">
            <Space size={100}>
              <span style={{ fontWeight: "bold" }}>
                <HighlightOutlined /> Descripción
              </span>
              <span >
                {descripcion}
              </span>
              </Space>
            </Row>

            <Row justify="start">
            <Space size={88}>
              <span style={{ fontWeight: "bold" }}>
                <NumberOutlined /> Tipo de Envío
              </span>
              <span >
                {tipoEnvio.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        ).replace(/_/g, ' ')}
              </span>
              </Space>
            </Row>
            <Row justify="start">
              <Space size={35}>
              <span style={{ fontWeight: "bold" }}>
                <SolutionOutlined /> Responsable de envío
              </span>
              <span >
                {responsabelEnvio}
              </span>
              </Space>
              
            </Row>
          </Col>
          
        </Row>
        
         
        
          
      
        </Modal>
    );
};

export default ModalConfirmacion;