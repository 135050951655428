import { atom } from "jotai";

 export const documentosProcesoAtom = atom([]);
 export const procesoIdSeleccionadoAtom =atom(undefined);
 export const visibleModalRechazarAtom = atom(false);
 export const botonesExtrasActivosAtom = atom(false);
 export const documentoIdSeleccionadoAtom = atom (undefined);
 export const documentoSeleccionadoAtom = atom (undefined);
 export const visibleModalEditarAtom = atom (false);
 export const detalleIdAtom = atom(undefined);
 export const dataColaboradorAtom = atom(undefined);

 export const filtrosAtom = atom({})
 export const modalCargaAtom = atom(false)
 export const resumenAtom = atom(undefined);
 export const resetAtom = atom(false);
 export const loadingAtom = atom(false);

 