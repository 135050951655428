import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, Checkbox, Input, Button, Space, Col, Row, notification } from "antd";
import { visibleModalRestablecerClaveAtom } from "../ColaboradorListarStore";
import { useAtom } from "jotai";
import { SecurityContext } from "../../../../context/SecurityContextProvider";
import GestionarClaveService from "../../../../services/GestionarClaveService";
import { usePermisos } from "hooks";

const ModalRestablecerClave = ({ colaborador }) => {

    const [visibleModalRestablecerClave, setVisibleModalRestablecerClave] = useAtom(visibleModalRestablecerClaveAtom);
    const [formRestablecerClave] = Form.useForm();
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [generaClave, setGeneraClave] = useState(false);
    const { fetchOpciones, existeOpcion } = usePermisos();

    useEffect(() => {
        if (visibleModalRestablecerClave) {
            fetchOpciones();
        }
    }, [visibleModalRestablecerClave]);

    const cerrarModal = () => {
        formRestablecerClave.resetFields();
        formRestablecerClave.setFieldsValue({
            checkedClave: false
        })
        setGeneraClave(false);
        setVisibleModalRestablecerClave(false);
    }

    const onFinishRestablecerClave = async (formulario) => {
        //  console.log("subdominio:",getUsuarioAutenticado().subdominio);
        //  console.log("numero documento",colaborador);
        try {
            if (generaClave) {
                await GestionarClaveService.generarClaveColaborador(
                    getUsuarioAutenticado().subdominio, getUsuarioAutenticado().empresaSeleccionada.id, colaborador);
                notification.success({
                    message: "Clave Generada, será enviada al correo del colaborador.",
                });
                formRestablecerClave.resetFields();
                setGeneraClave(false);
                setVisibleModalRestablecerClave(false);

            }
            else {
                await GestionarClaveService.cambiarClaveColaboradorPorAdmin(
                    getUsuarioAutenticado().subdominio, colaborador, formulario.clave);
                notification.success({
                    description: "Se actualizó la clave del colaborador.",
                });
                formRestablecerClave.resetFields();
                setGeneraClave(false);
                setVisibleModalRestablecerClave(false);

            }


        } catch (error) {
            notification.error({
                description: "No se puede guardar la clave",
                //description: "Error-> " + error.response.data.mensaje,
            });
        }

    }

    const onChangeGeneraClave = (e) => {
        setGeneraClave(e.target.checked);
        formRestablecerClave.setFieldsValue({
            clave: "",
            claveRepetida: "",
        })
    }

    return (

        <Modal
            title="Restablecer Clave"
            open={visibleModalRestablecerClave}
            onOk={onFinishRestablecerClave}
            onCancel={cerrarModal}
            footer={null}
        >
            <Form
                form={formRestablecerClave}
                layout="vertical"
                onFinish={onFinishRestablecerClave}
            >
                {existeOpcion("mantenimiento.colaborador.restablecer-clave.automatica")
                    && (
                        < Form.Item
                            valuePropName="checked"
                            name="checkedClave"
                        >
                            <Checkbox onChange={onChangeGeneraClave}>
                                Generar una clave automáticamente
                            </Checkbox>
                        </Form.Item>
                    )
                }

                {existeOpcion("mantenimiento.colaborador.restablecer-clave.manual")
                    && (
                        <>
                            <Form.Item
                                label="Nueva Clave"
                                name="clave"
                                rules={!generaClave ? [
                                    {
                                        required: true,
                                        message: "Ingrese su clave",
                                    },
                                ] : [{
                                    required: false,
                                    message: ""
                                },]}
                            >
                                <Input.Password disabled={generaClave ? true : false} />
                            </Form.Item>
                            <Form.Item
                                label="Reingrese la clave"
                                name="claveRepetida"
                                dependencies={["clave"]}
                                hasFeedback
                                rules={!generaClave ? [
                                    {
                                        required: true,
                                        message: "Ingrese nuevamente su clave",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (!value || getFieldValue("clave") === value) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject("Las claves deben ser iguales!");
                                        },
                                    }),
                                ] : [{ required: false, message: "" }]}
                            >
                                <Input.Password disabled={generaClave ? true : false} />
                            </Form.Item>
                        </>
                    )
                }

                <Row justify="end">
                    <Space>
                        <Col span={12}>
                            <Button type="default" onClick={cerrarModal}>Cancelar</Button>
                        </Col>
                        {(existeOpcion("mantenimiento.colaborador.restablecer-clave.manual")
                            || existeOpcion("mantenimiento.colaborador.restablecer-clave.automatica"))
                            && (
                                <Col span={12}>
                                    <Button type="primary" htmlType="submit">Enviar</Button>
                                </Col>
                            )
                        }
                    </Space>
                </Row>
                {/* </Form.Item> */}
            </Form>
        </Modal >
    )

}
export default ModalRestablecerClave;
