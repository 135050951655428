import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Col, Input, Checkbox, Select, Button, Table, Space, message, notification } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import TipoAbsentismoService from "services/TipoAbsentismoService";
import { SecurityContext } from "context/SecurityContextProvider";
import { useAtom } from "jotai";
import * as atoms from "../EditarTipoAbsentismoStore";
import { useHistory, useParams } from "react-router-dom";

const TipoabsentismoEditar = () => {
  const [formEditarTipoAbsentismo] = Form.useForm();
  const { Option } = Select;
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [aprobadores, setAprobadores] = useAtom(atoms.aprobadoresAtom);
  const [aprobadoresOtrosCargos, setAprobadoresOtrosCargos] = useAtom(atoms.aprobadoresOtrosCargosAtom);
  const [aprobadorSeleccionado, setAprobadorSeleccionado] = useAtom(atoms.aprobadorSeleccionadoAtom);
  const [aprobadorSeleccionadoOtrosCargos, setAprobadorSeleccionadoOtrosCargos] = useAtom(atoms.aprobadorSeleccionadoOtrosCargosAtom);
  const [aprobacionGestionHumana, setAprobacionGestionHumana] = useState();
  const [aprobacionOtrosCargos, setAprobacionOtrosCargos] = useState();
  const [aprobadoresAgregados, setAprobadoresAgregados] = useState([]);
  const [aprobadoresAgregadosOtrosCargos, setAprobadoresAgregadosOtrosCargos] = useState([]);
  const [agregarCamposPersonalizados, setAgregarCamposPersonalizados] = useState();
  const [loading, setLoading] = useState(false);

  const [, setVisibleDrawerCampoPersonalizado] = useAtom(atoms.visibleDrawerCamposPersonalizadosAtom);
  const [campos, setCampos] = useAtom(atoms.camposPersonalizadosAtom);
  const [, setEditarCampo] = useAtom(atoms.editarCampoAtom);
  const [, setCampoSeleccionado] = useAtom(atoms.campoSeleccionadoAtom);

  const [, setVisibleDrawerArchivo] = useAtom(atoms.visibleDrawerArchivosAtom);
  const [archivosAdjuntos, setArchivosAdjuntos] = useAtom(atoms.archivosAdjuntosAtom);
  const [, setEditarArchivo] = useAtom(atoms.editarArchivoAtom);
  const [, setArchivoSeleccionado] = useAtom(atoms.archivoSeleccionadoAtom);
  const [solicitarArchivoAdjunto, setSolicitarArchivoAdjunto] = useState();

  let { idTipoAbsentismo } = useParams();
  let history = useHistory();

  useEffect(() => {
    const cargarDatos = async () => {
      const tipoAbsentismoBuscado = await TipoAbsentismoService.buscarPorId(idTipoAbsentismo);
      formEditarTipoAbsentismo.setFieldsValue({
        id: tipoAbsentismoBuscado.id,
        descripcion: tipoAbsentismoBuscado.descripcion,
        nombre: tipoAbsentismoBuscado.nombre,
        requiereAprobacionJefeInmediato: tipoAbsentismoBuscado.requiereAprobacionJefeInmediato,
        contabilizarDiasVacaciones: tipoAbsentismoBuscado.contabilizarDiasVacaciones,
        requiereAprobacionGestionHumana: tipoAbsentismoBuscado.requiereAprobacionGestionHumana,
        agregarCamposPersonalizados: tipoAbsentismoBuscado.agregarCamposPersonalizados,
        solicitarArchivosAdjuntos: tipoAbsentismoBuscado.solicitarArchivosAdjuntos,
        generarFormatoAusencia: tipoAbsentismoBuscado.generarFormatoAusencia,
        permitirSolicitudFechasAnteriores: tipoAbsentismoBuscado.permitirSolicitudFechasAnteriores,
        contabilizarHoras: tipoAbsentismoBuscado.contabilizarHoras,
        requiereAprobacionOtrosCargos: tipoAbsentismoBuscado.requiereAprobacionOtrosCargos,
        ocultarDiasTruncos: tipoAbsentismoBuscado.ocultarDiasTruncos,
        requiereNotificarDocumentosAntesAprobacionGestionHumana: tipoAbsentismoBuscado.requiereNotificarDocumentosAntesAprobacionGestionHumana,
        permitirSolicitarAbsentismoConDocumentosPendientes: tipoAbsentismoBuscado.permitirSolicitarAbsentismoConDocumentosPendientes,
        permitirSolicitarAbsentismoConAbsentismoPendientes: tipoAbsentismoBuscado.permitirSolicitarAbsentismoConAbsentismoPendientes,
      });
      setAprobacionGestionHumana(tipoAbsentismoBuscado.requiereAprobacionGestionHumana);
      setAprobadoresAgregados(tipoAbsentismoBuscado.aprobadoresGestionHumana);
      setAgregarCamposPersonalizados(tipoAbsentismoBuscado.agregarCamposPersonalizados);
      setCampos(tipoAbsentismoBuscado.controles !== null ? tipoAbsentismoBuscado.controles : []);
      setArchivosAdjuntos(tipoAbsentismoBuscado.archivos != null ? tipoAbsentismoBuscado.archivos : []);
      setSolicitarArchivoAdjunto(tipoAbsentismoBuscado.solicitarArchivosAdjuntos);
      setAprobacionOtrosCargos(tipoAbsentismoBuscado.requiereAprobacionOtrosCargos);
      setAprobadoresAgregadosOtrosCargos(tipoAbsentismoBuscado.aprobadoresOtrosCargos);

    };
    cargarDatos();
  }, []);
  const onSearchAprobadores = async (value) => {
    const _respuesta = await TipoAbsentismoService.listarColaboradoresPorEmpresaConFiltro({
      empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
      filtro: value.trim(),
      login: getUsuarioAutenticado().login,
    });
    setAprobadores(_respuesta);
    if (value === "") {
      formEditarTipoAbsentismo.setFieldsValue({
        aprobador: "",
      });
      setAprobadorSeleccionado([]);
    }
  };
  const onChangeAprobadorSeleccionado = (value) => {
    setAprobadorSeleccionado(value);
  };
  const onChangeAprobacionGestionHumana = (e) => {
    setAprobacionGestionHumana(e.target.checked);
    setAprobadoresAgregados([]);
  };
  const onClickAgregar = () => {
    if (aprobadorSeleccionado.length > 0) {
      const aprobadorBuscado = aprobadoresAgregados.find((item) => item.id === aprobadorSeleccionado);
      if (aprobadorBuscado === undefined) {
        const _aprobador = aprobadores.find((aprobador) => aprobador.id === aprobadorSeleccionado);
        const _aprobadorAgregado = {
          id: _aprobador.id,
          numeroDocumentoIdentidad: _aprobador.numeroDocumentoIdentidad,
          nombreCompleto: _aprobador.nombreCompleto,
          correoElectronico: _aprobador.correoElectronico,
        };

        setAprobadorSeleccionado([]);
        setAprobadoresAgregados([...aprobadoresAgregados, _aprobadorAgregado]);
        formEditarTipoAbsentismo.setFieldsValue({
          aprobador: [],
        });
        setAprobadores([]);
      } else {
        message.error("El aprobador ya ha sido agregado");
      }
    }
  };
  const onClickQuitar = (aprobador) => {
    setAprobadoresAgregados(aprobadoresAgregados.filter((item) => item.id !== aprobador.id));
  };
  const columnasAprobadores = [
    {
      title: "Documento",
      dataIndex: "numeroDocumentoIdentidad",
      align: "center",
    },
    {
      title: "Apellidos y nombres",
      dataIndex: "nombreCompleto",
      align: "center",
    },
    {
      title: "Correo",
      dataIndex: "correoElectronico",
      align: "center",
    },
    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      render: (fila) => (
        <Button onClick={() => onClickQuitar(fila)} type="link">
          Quitar
        </Button>
      ),
    },
  ];
  const onFinishEditarTipoAbsentismo = async (formulario) => {
    try {
      // if (aprobacionGestionHumana && aprobadoresAgregados.length === 0) {
      //   message.error("Debe seleccionar al menos un Aprobador");
      // }else 
      if (aprobacionOtrosCargos && aprobadoresAgregadosOtrosCargos.length === 0) {
        message.error("Debe seleccionar al menos un Aprobador de otro cargo");
      } else if (agregarCamposPersonalizados && campos.length === 0) {
        message.error("Debe agregar al menos un campo");
      } else if (solicitarArchivoAdjunto && archivosAdjuntos.length === 0) {
        message.error("Debe agregar al menos un archivo");
      } else {
        setLoading(true);
        formulario.aprobadoresGestionHumana = aprobadoresAgregados;
        formulario.aprobadoresOtrosCargos = aprobadoresAgregadosOtrosCargos;
        formulario.empresaId = getUsuarioAutenticado().empresaSeleccionada.id;
        formulario.controles = campos;
        formulario.archivos = archivosAdjuntos;
        await TipoAbsentismoService.actualizar(formulario);
        notification.success({
          message: "Operación exitosa",
          description: "Se actualizó el tipo absentismo correctamente",
        });
        history.push("/tipoAbsentismo");
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      notification.error({
        message: "No se puede actualizar el Tipo absentismo",
        description: error.response.data.mensaje,
      });
    } finally {
      setLoading(false);
    }
  };
  const onclickCancelar = () => {
    history.push("/tipoAbsentismo");
  };
  const onChangeAgregarCamposPersonalizados = (e) => {
    setAgregarCamposPersonalizados(e.target.checked);
    setCampos([]);
  };
  const onClickAgregarCampoPersonalizado = () => {
    setVisibleDrawerCampoPersonalizado(true);
  };
  const columnasCamposPersonalizados = [
    {
      title: "Campo",
      dataIndex: "key",
      align: "center",
    },
    {
      title: "Etiqueta",
      dataIndex: "label",
      align: "center",
    },
    {
      title: "Tipo",
      dataIndex: "type",
      align: "center",
    },
    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      render: (fila) => (
        <React.Fragment>
          <Button onClick={() => onClickEditarCampo(fila)} type="link">
            Editar
          </Button>
          <Button onClick={() => onClickQuitarCampo(fila)} type="link">
            Quitar
          </Button>
        </React.Fragment>
      ),
    },
  ];
  const onClickQuitarCampo = (campo) => {
    setCampos(campos.filter((item) => item.key !== campo.key));
  };
  const onClickEditarCampo = (campo) => {
    setVisibleDrawerCampoPersonalizado(true);
    setEditarCampo(true);
    setCampoSeleccionado(campo);
  };
  const onChangeSolicitarArchivos = (e) => {
    setSolicitarArchivoAdjunto(e.target.checked);
    setArchivosAdjuntos([]);
  };

  const columnasArchivos = [
    {
      title: "Campo",
      dataIndex: "key",
      align: "center",
    },
    {
      title: "Etiqueta",
      dataIndex: "label",
      align: "center",
    },
    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      render: (fila) => (
        <React.Fragment>
          <Button onClick={() => onClickEditarArchivo(fila)} type="link">
            Editar
          </Button>
          <Button onClick={() => onClickQuitarArchivo(fila)} type="link">
            Quitar
          </Button>
        </React.Fragment>
      ),
    },
  ];

  const onClickEditarArchivo = (archivo) => {
    setVisibleDrawerArchivo(true);
    setEditarArchivo(true);
    setArchivoSeleccionado(archivo);
  };
  const onClickQuitarArchivo = (archivo) => {
    setArchivosAdjuntos(archivosAdjuntos.filter((item) => item.key !== archivo.key));
  };

  const onClickAgregarArchivo = () => {
    setVisibleDrawerArchivo(true);
  };
  const onChangeAprobacionOtrosCargos = (e) => {
    setAprobacionOtrosCargos(e.target.checked);
    setAprobadoresAgregadosOtrosCargos([]);
  }
  const onSearchAprobadoresOtrosCargos = async (value) => {
    const _respuesta = await TipoAbsentismoService.listarColaboradoresPorEmpresaConFiltro({
      empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
      filtro: value.trim(),
      login: getUsuarioAutenticado().login,
    });
    setAprobadoresOtrosCargos(_respuesta);
    if (value === "") {
      formEditarTipoAbsentismo.setFieldsValue({
        aprobadorOtroCargo: "",
      });
      setAprobadorSeleccionadoOtrosCargos([]);
    }
  };
  const onChangeAprobadorSeleccionadoOtrosCargos = (value) => {
    setAprobadorSeleccionadoOtrosCargos(value);
  };
  const onClickAgregarAprobadorOtroCargo = () => {
    if (aprobadorSeleccionadoOtrosCargos.length > 0) {
      const aprobadorBuscado = aprobadoresAgregadosOtrosCargos.find((item) => item.id === aprobadorSeleccionadoOtrosCargos);
      if (aprobadorBuscado === undefined) {
        const _aprobador = aprobadoresOtrosCargos.find((aprobador) => aprobador.id === aprobadorSeleccionadoOtrosCargos);
        const _aprobadorAgregado = {
          id: _aprobador.id,
          numeroDocumentoIdentidad: _aprobador.numeroDocumentoIdentidad,
          nombreCompleto: _aprobador.nombreCompleto,
          correoElectronico: _aprobador.correoElectronico,
        };

        setAprobadorSeleccionadoOtrosCargos([]);
        setAprobadoresAgregadosOtrosCargos([...aprobadoresAgregadosOtrosCargos, _aprobadorAgregado]);
        formEditarTipoAbsentismo.setFieldsValue({
          aprobadorOtroCargo: [],
        });
        setAprobadoresOtrosCargos([]);
      } else {
        message.error("El aprobador ya ha sido agregado");
      }
    }
  };
  const onClickQuitarAprobadorOtroCargo = (aprobador) => {
    setAprobadoresAgregadosOtrosCargos(aprobadoresAgregadosOtrosCargos.filter((item) => item.id !== aprobador.id));
  };

  const columnasAprobadoresOtrosCargos = [
    {
      title: "Documento",
      dataIndex: "numeroDocumentoIdentidad",
      align: "center",
    },
    {
      title: "Apellidos y nombres",
      dataIndex: "nombreCompleto",
      align: "center",
    },
    {
      title: "Correo",
      dataIndex: "correoElectronico",
      align: "center",
    },
    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      render: (fila) => (
        <Button onClick={() => onClickQuitarAprobadorOtroCargo(fila)} type="link">
          Quitar
        </Button>
      ),
    },
  ];
  return (
    <Form form={formEditarTipoAbsentismo} layout="vertical" onFinish={onFinishEditarTipoAbsentismo}>
      <Form.Item label="id" name="id" noStyle>
        <Input type="hidden" />
      </Form.Item>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Tipo Absentismo"
            name="descripcion"
            rules={[
              {
                required: true,
                message: "Ingrese el tipo de absentismo.",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item
            label="Nombre Documento"
            name="nombre"
            rules={[
              {
                required: true,
                message: "Ingresa el nombre de documento.",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="generarFormatoAusencia" valuePropName="checked">
            <Checkbox>¿Generar formato de ausencia?</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="contabilizarDiasVacaciones" valuePropName="checked">
            <Checkbox>¿Contabilizar días de vacaciones?</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="ocultarDiasTruncos" valuePropName="checked">
            <Checkbox>¿Ocultar días truncos?</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="contabilizarHoras" valuePropName="checked">
            <Checkbox>¿Contabilizar horas en la solicitud?</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="requiereAprobacionJefeInmediato" valuePropName="checked">
            <Checkbox>¿Requiere aprobación de jefe inmediato?</Checkbox>
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Form.Item name="contabilizarDiasVacaciones" valuePropName="checked">
            <Checkbox>¿Contabilizar días de vacaciones?</Checkbox>
          </Form.Item>
        </Col> */}
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="requiereAprobacionGestionHumana" valuePropName="checked">
            <Checkbox onChange={onChangeAprobacionGestionHumana}>¿Requiere aprobación de gestión humana?</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {aprobacionGestionHumana && (
        <React.Fragment>
          <Row gutter={6}>
            <Col span={16}>
              <Form.Item label="Aprobador Gestión Humana" name="aprobador">
                <Select
                  placeholder="Ingrese el nombre del aprobador"
                  showSearch
                  onSearch={onSearchAprobadores}
                  onChange={onChangeAprobadorSeleccionado}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {aprobadores !== undefined &&
                    aprobadores.map((aprobador) => {
                      return (
                        <Select.Option key={aprobador.id} value={aprobador.id}>
                          {aprobador.nombreCompleto}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label=" " name="botonAgregar">
                <Button onClick={onClickAgregar}>Agregar</Button>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                rowKey={(aprobador) => aprobador.id}
                dataSource={aprobadoresAgregados}
                columns={columnasAprobadores}
                pagination={false}
              />
            </Col>
          </Row>
          <br />
        </React.Fragment>
      )}
      <Row>
        <Col span={24}>
          <Form.Item name="requiereAprobacionOtrosCargos" valuePropName="checked">
            <Checkbox onChange={onChangeAprobacionOtrosCargos} >¿Requiere aprobación de otros cargos?</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {aprobacionOtrosCargos && (
        <React.Fragment>
          <Row gutter={6}>
            <Col span={16}>
              <Form.Item label="Aprobador otros cargos" name="aprobadorOtroCargo">
                <Select
                  placeholder="Ingrese el nombre del aprobador"
                  showSearch
                  onSearch={onSearchAprobadoresOtrosCargos}
                  onChange={onChangeAprobadorSeleccionadoOtrosCargos}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                >
                  {aprobadoresOtrosCargos !== undefined &&
                    aprobadoresOtrosCargos.map((aprobador) => {
                      return (
                        <Select.Option key={aprobador.id} value={aprobador.id}>
                          {aprobador.nombreCompleto}
                        </Select.Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label=" " name="botonAgregarAprobadorOtroCargo">
                <Button onClick={onClickAgregarAprobadorOtroCargo}>Agregar</Button>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                rowKey={(aprobador) => aprobador.id}
                dataSource={aprobadoresAgregadosOtrosCargos}
                columns={columnasAprobadoresOtrosCargos}
                pagination={false}
              />
            </Col>
          </Row>
          <br />
        </React.Fragment>
      )}
      <Row>
        <Col span={24}>
          <Form.Item name="agregarCamposPersonalizados" valuePropName="checked">
            <Checkbox onChange={onChangeAgregarCamposPersonalizados}>Agregar campos personalizados</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {agregarCamposPersonalizados && (
        <React.Fragment>
          <Row>
            <Col span={24}>
              <Button onClick={onClickAgregarCampoPersonalizado}>Agregar</Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                rowKey={(campo) => campo.key}
                columns={columnasCamposPersonalizados}
                dataSource={campos}
                pagination={false}
              />
            </Col>
          </Row>
          <br />
        </React.Fragment>
      )}

      <Row>
        <Col span={24}>
          <Form.Item name="solicitarArchivosAdjuntos" valuePropName="checked">
            <Checkbox onChange={onChangeSolicitarArchivos}>Solicitar archivos adjuntos</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      {solicitarArchivoAdjunto && (
        <React.Fragment>
          <Row>
            <Col span={24}>
              <Button onClick={onClickAgregarArchivo}>Agregar</Button>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                rowKey={(archivo) => archivo.key}
                columns={columnasArchivos}
                dataSource={archivosAdjuntos}
                pagination={false}
              />
            </Col>
          </Row>
          <br />
        </React.Fragment>
      )}
      <Row>
        <Col span={24}>
          <Form.Item name="permitirSolicitudFechasAnteriores" valuePropName="checked">
            <Checkbox>¿Permitir realizar solicitud en fechas anteriores?</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="requiereNotificarDocumentosAntesAprobacionGestionHumana" valuePropName="checked">
            <Checkbox>¿Requiere notificar documentos antes de aprobación de gestión humana?</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="permitirSolicitarAbsentismoConDocumentosPendientes" valuePropName="checked">
            <Checkbox>¿Permitir realizar solicitud con otros documentos pendientes?</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="permitirSolicitarAbsentismoConAbsentismoPendientes" valuePropName="checked">
            <Checkbox>¿Permitir realizar solicitud con absentismos pendientes?</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center">
        <Space>
          <Col>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="default" onClick={onclickCancelar}>
                Cancelar
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" icon={<CheckOutlined />} htmlType="submit" loading={loading}>
                Actualizar
              </Button>
            </Form.Item>
          </Col>
        </Space>
      </Row>
    </Form>
  );
};
export default TipoabsentismoEditar;
