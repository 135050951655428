
import React from "react";
import { PanelCantidad } from "./panelCantidad";
import { Resumen } from "./resumen";

const Dashboard = ({loading, resumenDocumento, colaborador, children}) => {

  return (
      <div style={{ display: 'flex', maxHeight: '120px', alignContent:'space-between', margin: 'auto', maxWidth:'100%'}}>

          
          <Resumen loading={loading} /> 

          {/* {children!=null && children} */}

              <PanelCantidad

                  total={resumenDocumento?.total | 0}

                  descripcion="Total Documentos"
                  color="rgb(24, 144, 255)"
                  backgroundColor="rgb(24, 144, 255, 0.08)" />

              <PanelCantidad
                  total={resumenDocumento?.totalAprobados | 0}
                  descripcion={"Aprobados"}
                  color="rgb(82, 196, 26)"
                  backgroundColor="rgb(82, 196, 26, 0.08)" />

              <PanelCantidad
                  total={resumenDocumento?.totalPendientesAprobar | 0}
                  descripcion="Pendientes de Aprobar"
                  color="rgb(255, 77, 79)"
                  backgroundColor="rgb(255, 77, 79, 0.08)" />

              <PanelCantidad
                  total={resumenDocumento?.totalSubidos | 0}
                  descripcion={"Subidos"}
                  color="rgb(82, 196, 26)"
                  backgroundColor="rgb(82, 196, 26, 0.08)" />

              <PanelCantidad
                  total={resumenDocumento?.totalPendientesSubir | 0}
                  descripcion="Pendientes de Subir"
                  color="rgb(255, 77, 79)"
                  backgroundColor="rgb(255, 77, 79, 0.08)" />
          

      </div>
  )
  }
export default Dashboard;
