import React, { useEffect, useState, useContext } from "react";
import { Button, Dropdown, Table, Modal, Form, Input, Select, DatePicker, message } from "antd";
import { UnorderedListOutlined, CaretDownOutlined, FilePdfOutlined } from "@ant-design/icons";
import { useListarContratos } from "../hooks";
import {
    documentoSeleccionadoAtom,
    urlPdfAtom,
    visibleModalVisorPdfAtom,
    visibleModalTransferirProyectoAtom,
} from "pages/legajo/legajoV3/LegajoColaboradorStore";
import { ContratosColaboradorService, TransferirContratoService } from "services/contratos";
import ColaboradorService from "services/ColaboradorService";
import { useAtom, useSetAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import { CampoColaborador } from "enums/CampoColaborador";
import { log } from "@craco/craco/lib/logger";
const ContratosTab = () => {
    return (
        <React.Fragment>
            <ListaContratos />
            <ModalTransferirProyecto />
        </React.Fragment>
    );
};

const ListaContratos = () => {
    const { contratos } = useListarContratos();

    const columnas = [
        {
            title: "Nombre",
            dataIndex: "nombreDocumento",
            width: "20%",
        },
        {
            title: "Cargo",
            dataIndex: "cargo",
        },
        {
            title: "Fecha Inicio",
            dataIndex: "fechaInicio",
            align: "center",
        },
        {
            title: "Fecha Fin",
            dataIndex: "fechaFin",
            align: "center",
        },
        {
            title: "Firmado",
            dataIndex: "estadoFirma",
            align: "center",
        },
        // {
        //     title: "Estado",
        //     dataIndex: "estado",
        //     align: "center",
        // },
        {
            title: "Acciones",
            align: "center",
            width: "10%",
            render: (fila) => <MenuAcciones contrato={fila} />,
        },
    ];

    return <Table rowKey={(item) => item.id} columns={columnas} dataSource={contratos} pagination={false} />;
};

const MenuAcciones = ({ contrato }) => {
    // const { getUsuarioAutenticado } = useContext(SecurityContext);
    const setVisibleModalVisorPdf = useSetAtom(visibleModalVisorPdfAtom);
    const setUrlPdf = useSetAtom(urlPdfAtom);
    const setDocumentoSeleccionado = useSetAtom(documentoSeleccionadoAtom);
    const [, setVisibleModal] = useAtom(visibleModalTransferirProyectoAtom);
    // const [, setOnboardings] = useAtom(onboardingsIngresoAtom);

    // const mostrarModal = useModalConfirmar(state => state.mostrarModal);

    const onClick = ({ key }) => {
        switch (key) {
            case "ver-documento":
                onClickVerDocumento();
                break;
            case "transferir":
                onClickTransferirPRoyecto();
                break;
            default:
                break;
        }
    };

    const items = [
        {
            label: "Ver Documento",
            key: "ver-documento",
            icon: <FilePdfOutlined style={{ fontSize: "16px" }} />,
        },
        // {
        //     label: "Transferir a otro proyecto",
        //     key: "transferir",
        //     icon: <FilePdfOutlined style={{ fontSize: "16px" }} />,
        // },
    ];

    const onClickVerDocumento = async () => {
        const respuesta = await ContratosColaboradorService.getUrlDocumento(contrato.detalleEnvioId);
        setDocumentoSeleccionado(contrato);
        setUrlPdf(respuesta.url);
        setVisibleModalVisorPdf(true);
    };
    const onClickTransferirPRoyecto = () => {
        setVisibleModal(true);
        setDocumentoSeleccionado(contrato);
    };

    return (
        <Dropdown menu={{ items, onClick }} placement="bottomRight" trigger="click" arrow>
            <Button type="link" className="ant-dropdown-link">
                <UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
                <CaretDownOutlined
                    style={{
                        marginLeft: "2px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#1678c1",
                    }}
                />
            </Button>
        </Dropdown>
    );
};

const ModalTransferirProyecto = () => {
    const [visible, setVisible] = useAtom(visibleModalTransferirProyectoAtom);
    const [sedes, setSedes] = useState([]);
    const [subsedes, setSubsedes] = useState([]);
    const [secciones, setSecciones] = useState([]);
    const [cargos, setCargos] = useState([]);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [formModalTransferir] = Form.useForm();
    const [sedeSeleccionada, setSedeSeleccionada] = useState(undefined);
    const [subsedeSeleccionada, setSubsedeSeleccionada] = useState(undefined);
    const [seccionSeleccionada, setSeccionSeleccionada] = useState(undefined);
    const [cargoSeleccionado, setCargoSeleccionado] = useState(undefined);
    const [documentoSeleccionado, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);

    useEffect(() => {
        const cargarDatos = async () => {
            const campoEnviado = await ColaboradorService.camposColaboradorListarNuevo(
                getUsuarioAutenticado().empresaSeleccionada.id
            );
            console.log("MMM:::", campoEnviado);
            setSedes(campoEnviado.SEDE);
            setSubsedes(campoEnviado.SUBSEDE);
            setSecciones(campoEnviado.SECCION);
            setCargos(campoEnviado.CARGO);
        };
        cargarDatos();
    }, []);

    const handleOnCancel = () => {
        setVisible(false);
        formModalTransferir.resetFields();
    };

    const procesarCambios = (campoEnviado) => {
        if (campoEnviado.CARGO != null) {
            setCargos(campoEnviado.CARGO);
            formModalTransferir.setFieldsValue({ cargo: { id: "" } });
        }
        if (campoEnviado.SECCION != null) {
            setSecciones(campoEnviado.SECCION);
            formModalTransferir.setFieldsValue({ seccion: "" });
        }
        if (campoEnviado.SEDE != null) {
            setSedes(campoEnviado.SEDE);
            formModalTransferir.setFieldsValue({ sede: { id: "" } });
        }
        if (campoEnviado.SUBSEDE != null) {
            setSubsedes(campoEnviado.SUBSEDE);
            formModalTransferir.setFieldsValue({ area: "" });
        }
    };

    const onSelectSede = async (value, option) => {
        const sede = {
            id: option.value,
            nombre: option.children,
        };
        setSedeSeleccionada(sede);
        const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
            getUsuarioAutenticado().empresaSeleccionada.id,
            value,
            CampoColaborador.SEDE
        );
        procesarCambios(campoEnviado);
    };

    const onSelectSubsede = async (value, option) => {
        const subsede = {
            id: option.value,
            nombre: option.children,
        };
        setSubsedeSeleccionada(subsede);
        const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
            getUsuarioAutenticado().empresaSeleccionada.id,
            value,
            CampoColaborador.SUBSEDE
        );
        procesarCambios(campoEnviado);
    };

    const onSelectSeccion = async (value, option) => {
        const seccion = {
            id: option.value,
            nombre: option.children,
        };
        setSeccionSeleccionada(seccion);
        const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
            getUsuarioAutenticado().empresaSeleccionada.id,
            value,
            CampoColaborador.SECCION
        );
        procesarCambios(campoEnviado);
    };

    const onSelectCargo = async (value, option) => {
        const cargo = {
            id: option.value,
            nombre: option.children,
        };
        setCargoSeleccionado(cargo);
        const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
            getUsuarioAutenticado().empresaSeleccionada.id,
            value,
            CampoColaborador.CARGO
        );
        procesarCambios(campoEnviado);
    };

    const onClickBtnTransferir = () => {
        formModalTransferir
            .validateFields()
            .then(async (formulario) => {
                try {
                    await TransferirContratoService.transferir({
                        seguimientoContratoId: documentoSeleccionado.id,
                        sede: sedeSeleccionada.id,
                        subsede: subsedeSeleccionada.id,
                        seccion: seccionSeleccionada.id,
                        cargo: cargoSeleccionado.id,
                        sueldo: formulario.sueldo,
                        //fechaInicioContrato: formulario.fechaInicioContrato.format("DD/MM/YYYY"),
                        //fechaFinContrato: formulario.fechaFinContrato.format("DD/MM/YYYY"),
                        fechaInicioContrato: formulario.fechaInicioContrato,
                        fechaFinContrato: formulario.fechaFinContrato,
                        tipoServicio: formulario.tipoServicio,
                    });
                    setVisible(false);
                    message.success("Se ha generado un nuevo contrato de transferencia");
                } catch (error) {
                    console.error(error);
                }
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    return (
        <Modal
            title="Transferir Proyecto"
            open={visible}
            onCancel={handleOnCancel}
            onOk={onClickBtnTransferir}
            // onClick={() => formModalTransferir.submit()}
        >
            <Form layout="vertical" form={formModalTransferir}>
                <Form.Item
                    label="Sede"
                    name="sede"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onSelect={onSelectSede}>
                        {sedes.length > 0 &&
                            sedes.map((sede) => {
                                return (
                                    <Select.Option key={sede.id} value={sede.id}>
                                        {sede.nombre}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Proyecto"
                    name="proyecto"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onSelect={onSelectSeccion}>
                        {secciones?.length > 0 &&
                            secciones.map((seccion) => {
                                return (
                                    <Select.Option key={seccion.id} value={seccion.id}>
                                        {seccion.nombre}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Area"
                    name="area"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onSelect={onSelectSubsede}>
                        {subsedes?.length > 0 &&
                            subsedes.map((subsede) => {
                                return (
                                    <Select.Option key={subsede.id} value={subsede.id}>
                                        {subsede.nombre}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Cargo"
                    name="cargo"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onSelect={onSelectCargo}>
                        {cargos.length > 0 &&
                            cargos.map((cargo) => {
                                return (
                                    <Select.Option key={cargo.id} value={cargo.id}>
                                        {cargo.nombre}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item>

                {/* <Form.Item
                    label="Proyecto"
                    name="proyecto"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onSelect={onSelectSede}>
                        {sedes.length > 0 &&
                            sedes.map((sede) => {
                                return (
                                    <Select.Option key={sede.id} value={sede.id}>
                                        {sede.nombre}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item> */}
                {/* <Form.Item
                    label="Area"
                    name="area"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onSelect={onSelectSubsede}>
                        {subsedes.length > 0 &&
                            subsedes.map((subsede) => {
                                return (
                                    <Select.Option key={subsede.id} value={subsede.id}>
                                        {subsede.nombre}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Cargo"
                    name="cargo"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select onSelect={onSelectCargo}>
                        {cargos.length > 0 &&
                            cargos.map((cargo) => {
                                return (
                                    <Select.Option key={cargo.id} value={cargo.id}>
                                        {cargo.nombre}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                </Form.Item> */}

                <Form.Item
                    label="Sueldo"
                    name="sueldo"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Tipo Servicio"
                    name="tipoServicio"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select
                        options={[
                            {
                                value: "SERVICIO TECNICO DE MUESTRERIA",
                                label: "SERVICIO TECNICO DE MUESTRERIA",
                            },
                            {
                                value: "SERVICIO DE PERFORACIÓN DIAMANTINA",
                                label: "SERVICIO DE PERFORACIÓN DIAMANTINA",
                            },
                            {
                                value: "PERFORACIÓN GEOTECNIA",
                                label: "PERFORACIÓN GEOTECNIA",
                              },
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    label="Fecha Inicio Contrato"
                    name="fechaInicioContrato"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
                </Form.Item>
                <Form.Item
                    label="Fecha Fin Contrato"
                    name="fechaFinContrato"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ContratosTab;
