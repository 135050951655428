import React, { useState, useEffect, useRef } from "react";

import { Container } from "../../../components/Container";

import {
  UnorderedListOutlined,
  QuestionCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import { Table, Space, Button, Popconfirm, notification, Input } from "antd";
import CargoService from "../../../services/CargoService";
import { useHistory } from "react-router-dom";

import Highlighter from "react-highlight-words";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import ModalCargaCargos from "../carga-cargos";
import { saveAs } from "file-saver";

export function CargoListarPage() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [cargos, setCargos] = useState([]);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [visibleModal, setVisibleModal] = useState(false);
  const isExplomin =
  getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837"
    ? true
    : false;
  let history = useHistory();

  useEffect(() => {
    async function cargarDatos() {
      const cargos = await CargoService.listar(
        getUsuarioAutenticado().empresaSeleccionada.id
      );
      setCargos(cargos);
    }

    cargarDatos();
  }, []);

  var adicionarColumnasFiltro = function getColumns(
    dataIndex,
    placeholder,
    propiedad
  ) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchInput}
              placeholder={
                placeholder !== undefined
                  ? placeholder
                  : `Buscar por ${dataIndex}`
              }
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Buscar
            </Button>
              <Button
                onClick={() => handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Limpiar
            </Button>
            </Space>
          </div>
        ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        propiedad === undefined
          ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
          : record[dataIndex][propiedad]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={
              propiedad === undefined
                ? text?.toString()
                : text[propiedad]?.toString()
            }
          />
        ) : propiedad === undefined ? (
          text
        ) : (
              text[propiedad]
            ),
    };
  };

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText("");
  }

  let columnas=[];
  
  if(isExplomin){
    columnas = [
      {
        title: "Nombre",
        dataIndex: "nombre",
        align: "center",
        ...adicionarColumnasFiltro("nombre"),
      },
      {
        title: "Sueldo Mínimo",
        dataIndex: "sueldoMinimo",
        align: "center"
      },
      {
        title: "Sueldo Máximo",
        dataIndex: "sueldoMaximo",
        align: "center"
      },
      {
        title: "Acciones",
        align: "center",
        key: "acciones",
        align: "center",
        render: (fila) =>
          cargos.length >= 1 ? (
            <Space>
              <a
                onClick={() => handleOnClickEditarCargo(fila.id)}
                className="custom-color-element"
              >
                <EditOutlined /> Editar
              </a>
              <Popconfirm
                title="¿Está seguro de eliminar el registro seleccionado?"
                okText="Si"
                cancelText="No"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => handleOnClickEliminarCargo(fila.id)}
              >
                <a style={{ color: "#CE3E3E" }}>
                  {" "}
                  <DeleteOutlined /> Eliminar
                </a>
              </Popconfirm>
            </Space>
          ) : null,
      },
    ];
  }else{
    columnas = [
      /* {
        title: "id",
        dataIndex: "id",
        align: "center",
        width: "10%",
      },*/
      {
        title: "Nombre",
        dataIndex: "nombre",
        align: "center",
        ...adicionarColumnasFiltro("nombre"),
      },
      {
        title: "Acciones",
        align: "center",
        key: "acciones",
        align: "center",
        render: (fila) =>
          cargos.length >= 1 ? (
            <Space>
              <a
                onClick={() => handleOnClickEditarCargo(fila.id)}
                className="custom-color-element"
              >
                <EditOutlined /> Editar
              </a>
              <Popconfirm
                title="¿Está seguro de eliminar el registro seleccionado?"
                okText="Si"
                cancelText="No"
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}
                onConfirm={() => handleOnClickEliminarCargo(fila.id)}
              >
                <a style={{ color: "#CE3E3E" }}>
                  {" "}
                  <DeleteOutlined /> Eliminar
                </a>
              </Popconfirm>
            </Space>
          ) : null,
      },
    ];
  }

  function handleOnClickEditarCargo(idCargo) {
    history.push(`/cargo/editar/${idCargo}`);
  }

  async function handleOnClickEliminarCargo(idCargo) {
    await CargoService.eliminar(idCargo);
    setCargos(cargos.filter((cargo) => cargo.id !== idCargo));
    notification.success({
      message: "Operación exitosa",
      description: "Se eliminó el registro correctamente.",
    });
  }

  function handleOnClickCrearCargo() {
    history.push("/cargo/crear");
  }
  async function cerrarModal(){
    setVisibleModal(false);    
    const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id );
    setCargos(cargos);
  }
  function handleOnclickImportarCargos(){
     setVisibleModal(true);
   }
  async function handleOnclickExportarCargos(){
    try {
      
      const formData=new FormData();
      formData.append("empresaId",getUsuarioAutenticado().empresaSeleccionada.id);
      let respuesta= await CargoService.descargarCargosExcel(formData);
      const filename = respuesta.headers.filename;
      saveAs(respuesta.data, filename);      

     } catch (error) {
      notification.error({
        message:"No se puede exportar los Cargos",
      })
     }
   }
  function botones(){
    return(
      <React.Fragment>
        <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleOnClickCrearCargo}
            >
              Crear Cargo
            </Button>  

            <Button 
              type="primary"
              icon={<UploadOutlined />}
              onClick={handleOnclickImportarCargos}
            >
              Importar Cargos
            </Button> 
                   
            <Button 
              type="primary" 
              icon={<DownloadOutlined />}
              onClick={handleOnclickExportarCargos}
              >
              Exportar Cargos
            </Button>
            
            
        </Space>
        
      </React.Fragment>
    );
  }

  return (
    <Container icono={<UnorderedListOutlined />} titulo="Cargo" botonExtra={botones()}>
      {/* <div style={{ marginBottom: 16 }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleOnClickCrearCargo}
        >
          Crear Cargo
        </Button>
      </div> */}
      <Table
        rowKey={(cargo) => cargo.id}
        columns={columnas}
        dataSource={cargos}
        pagination={{
          pageSize: [10],
        }}
      />
      <ModalCargaCargos
        visibleModal={visibleModal}
        onCerrarModal={cerrarModal}
      />
    </Container>
  );
}
