import React, { useContext, useEffect } from "react";
import { Button, Modal, notification, Space } from "antd";
import { PlusOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useAtom } from "jotai";
import { colaboradorPendienteAtom, verificacionDatosPersonales } from "../ListarVerificarDatosColaboradorStore";
import VerificarDatosColaboradorObservadosService from "services/VerificarDatosColaboradorObservadosService";
import { SecurityContext } from "context/SecurityContextProvider";

const Botones=({tabNumber, setLoading})=>{
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    let history = useHistory();
    const [colaboradorPendiente,]=useAtom(colaboradorPendienteAtom);
    const [, setVerificacionDatosPersonales] = useAtom(verificacionDatosPersonales);
    
    const BotonSolicitarVerificacionDatos = () =>{

       const onClickBtnNotificarVerificacionDatos = () =>{
            history.push("/configuracion/verificar-datos-colaborador/notificar");
        }
        const onClickBtnAprobarTodos =  () =>{
            const registroVerificarId=colaboradorPendiente.map(c=>c.id)
            return (
                Modal.confirm({
                    title: 'Confirmación',
                    icon: <ExclamationCircleOutlined />,
                    content: '¿Está seguro de aprobar todos los registros?',
                    onOk: async ()  => 
                   {try{
                    setLoading(true);
                    const respuesta=await VerificarDatosColaboradorObservadosService.aprobarVerificacionDatosColaboradorMasivo(registroVerificarId);
                    //console.log(respuesta)
                    notification['success']({
                        message: 'Se ha aprobado correctamente',
                        description:
                          'Todas las solicitudes pendientes han sido aprobadas',
                      })

                   }catch{
                    notification['error']({
                        message: 'Error de aprobación',
                        description:
                          'Hubo un error al aprobar las solicitudes pendientes.',
                      })
                   }finally{
                    const respuesta=await VerificarDatosColaboradorObservadosService.listarRegistros(getUsuarioAutenticado().empresaSeleccionada.id);
                    setVerificacionDatosPersonales(respuesta);
                    setLoading(false);
                   }
                       
                    },
                  })
            )
        }

        return(
            <React.Fragment>
                <Space>
                    <Button
                    hidden={tabNumber!=='2'}
                    type="primary"
                    onClick={onClickBtnAprobarTodos}      
                    disabled={colaboradorPendiente.length===0}      
                    >
                        Aprobar Todos
                    </Button>

                    <Button
                    type="primary"
                    icon={<PlusOutlined/>}
                    onClick={onClickBtnNotificarVerificacionDatos}            
                    >
                        Solicitar Verificación  Datos
                    </Button>
                </Space>
            </React.Fragment>
        )

    }

    return(
        <React.Fragment>
            <BotonSolicitarVerificacionDatos/>
        </React.Fragment>
    )

};
export default Botones;
