import React from "react";
import { Container } from "components/Container";
import { useHistory } from "react-router";

import { FirmaHolografaRegistro} from "./Firma-Holografa-Registro";
const CrearFirmaHolografaPage=()=>{
    let history=useHistory();

    const onClickListarFirmaHolografa=()=>{

            history.push("/configuracion/firmas-holografas/listar");
    }

    return(
        <Container titulo="Crear Firma Hológrafa" onClickRetornar={onClickListarFirmaHolografa}>
            <FirmaHolografaRegistro/>
        </Container>
    )

 }
 export default CrearFirmaHolografaPage;