import React from "react";
import { estadoColaborador } from "enums/EstadoColaborador";
import { Tag } from "antd";

const EstadoColaboradorTag = ({ estado }) => {
  if (estado === estadoColaborador.ACTIVO || estado === null) {
    return <Tag color="green">{estado}</Tag>;
  } else if (estado === estadoColaborador.ONBOARDING) {
    return <Tag color="#108ee9">{estado}</Tag>;
  } else if (estado === estadoColaborador.CESADO) {
    return <Tag color="red">{estado}</Tag>;
  }
  return <Tag>{estado}</Tag>;
};

export default EstadoColaboradorTag;
