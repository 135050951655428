import React, { useEffect, useState, useContext } from "react";
import { Modal, Form, Select, Space, Row, Input, Button, Typography, Spin, message } from "antd";
import { FolderFilled, FolderTwoTone, LoadingOutlined } from "@ant-design/icons";
import { OnboardingColaboradorService } from "services/OnboardingColaboradorService";




  const ModalAsignarCarpeta = ({documento, carpeta, carpetaFiltro, modalVisible, setModalVisible, isMasivo, setFilaDocumento}) => {
  const { Option } = Select;
  const [frmCarpeta] = Form.useForm();
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    const cargarDatos = async () => {
        if(!isMasivo){
          const tempcap = carpeta.find(item=> item.id==documento?.[0]?.carpeta?.id)
          frmCarpeta.setFieldsValue({
            carpetaId: tempcap?.id
          });
        }
    };
  
    cargarDatos();

  }, [documento]);



  const onFinishCarpeta = async (documentoCarpeta) => {
    setLoading(true)
    let respuesta = null;
    if(isMasivo)
      respuesta = await OnboardingColaboradorService.asignarCarpeta(documentoCarpeta.carpetaId, documentoCarpeta.carpetaDescripcion, documento);  
    else
      respuesta = await OnboardingColaboradorService.asignarCarpeta(documentoCarpeta.carpetaId, documentoCarpeta.carpetaDescripcion, documento?.[0]?.id);  
    if(respuesta)
      message.success("Se han realizado los cambios")
    else
      message.error("Ocurrió un error al actualizar.")

    setLoading(false)  
    setModalVisible(false);
    setFilaDocumento(undefined)
  };
  const onChangeSelectCarpeta = (value, option) => {
    frmCarpeta.setFieldsValue({ carpetaDescripcion: option.descripcion });
  };
  const onLimpiarDatos = () => {
    frmCarpeta.setFieldsValue({
      carpetaId: "",
      carpetaDescripcion: "",
    });

  };

  return (
    <Modal
      title={isMasivo?"Asignar Carpeta Masivamente:":"Asignar Carpeta:"}
      visible={modalVisible}
      onCancel={()=>{if(!loading){setModalVisible(false); setFilaDocumento(undefined)}}}
      footer={null}
      forceRender 
      afterClose={onLimpiarDatos}
    >
      <Spin indicator={<LoadingOutlined style={{ fontSize: 28 }}/>} spinning={loading} tip="Cargando...">
        {isMasivo && <Typography>
          <pre>Documentos Seleccionados: {documento?.length}</pre>
        </Typography>}
        <Form
          onFinish={onFinishCarpeta}
          form={frmCarpeta}
        >
          <Form.Item
            label="Carpeta"
            name="carpetaId"
            rules={[
              {
                required: true,
                message: "Seleccione la Carpeta.",
              },
            ]}
          >
            <Select
              onChange={onChangeSelectCarpeta}
              optionLabelProp="label"
              placeholder="Seleccione la carpeta"
            >
              {carpeta.length > 0 &&
                carpeta.map((carpeta) => {
                  return (
                    <Option
                      key={carpeta.id}
                      disabled={carpetaFiltro.filter((item) => item.id == carpeta.id).length == 0}
                      value={carpeta.id}
                      label={
                        <div>
                          <FolderTwoTone style={{ fontSize: "20px", color: "#F7D358" }} twoToneColor="#F7D358" />{" "}
                          {carpeta.ruta}
                        </div>
                      }
                      descripcion={carpeta.descripcion}
                    >
                      <Space size={carpeta.nivel * 10}>
                        <span></span>
                        <span>
                          {carpeta.nivel === 0 ? (
                            <FolderFilled style={{ fontSize: "20px", color: "#F7D358" }} />
                          ) : (
                            <FolderTwoTone style={{ fontSize: "18px", color: "#F7D358" }} twoToneColor="#F7D358" />
                          )}{" "}
                          {carpeta.descripcion}
                        </span>
                      </Space>
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item name="carpetaDescripcion" noStyle>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item>
            <Row justify="end">
              <Button type="primary" htmlType="submit">
                Guardar
              </Button>
            </Row>
          </Form.Item>
        </Form>
        </Spin>
    </Modal>
  );
};
export default ModalAsignarCarpeta;
