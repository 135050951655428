import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { Container } from "components/Container";
import { TablaPlantillas } from "./componentes";
import { BotonesExtra } from "./componentes";

const PlantillaListarPage=()=>{
    return (
        <JotaiProvider>
          <Container titulo="Plantillas" botonExtra={<BotonesExtra/>}>
           <TablaPlantillas/>
          </Container>
        </JotaiProvider>
      );

}
export default PlantillaListarPage;