import React, { useState, useEffect } from "react";
import { Button, Space, Result, Upload, Table, notification, Tabs, Tag, Descriptions, Popover } from "antd";
import { InboxOutlined, FileExcelOutlined, CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import { useAtom } from "jotai";
import {
  pasoActualAtom,
  resumenAtom,
  loadingAtom,
  datosColaboradorStepAtom,
  listaTipoPlantillaEnvioAtom,
  colaboradoresConCargasPendientesAtom,
  rutaStorageZipAtom,
} from "../../OnboardingIndividualStore";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { FormatoCarga } from "enums/FormatoCarga";
import { LoginService } from "services/LoginService";
import { Headers } from "enums";

const VerificarDocumentoStep = () => {
  const [, setPasoActual] = useAtom(pasoActualAtom);
  const [, setResumen] = useAtom(resumenAtom);
  const [, setLoaging] = useAtom(loadingAtom);
  const [listaTipoPlantillaEnvio] = useAtom(listaTipoPlantillaEnvioAtom);
  //const [existeContrato, setExisteContrato] = useState(false);
  const [existenDocumentosPorSubir, setExistenDocumentosPorSubir] = useState(false);

  const [datosColaboradorStep] = useAtom(datosColaboradorStepAtom);
  const [colaboradoresConCargasPendientes, setColaboradoresConCargasPendientes] = useAtom(
    colaboradoresConCargasPendientesAtom
  );

  useEffect(() => {
    const cargarDatos = async () => {
      //console.log("datosColaboradorStep", datosColaboradorStep);
      const colaboradores = [];
      if (datosColaboradorStep.documentos.length > 0) {
        datosColaboradorStep.documentos.forEach((colaborador) => {
          const plantillaEncontrada = listaTipoPlantillaEnvio.find((c) => c.id === colaborador.tipoPlantilla.id);
          //console.log("plantillaEncontrada", plantillaEncontrada);
          const existenDocumentosParaSubir = plantillaEncontrada.documentos.find(
            (c) => c.categoriaDocumento.nombre === "CONTRATOS" || c.categoriaDocumento.nombre === "DOCUMENTO LABORAL"
          );
          if (existenDocumentosParaSubir != null) {
            setExistenDocumentosPorSubir(true);
            colaboradores.push(colaborador.colaborador);
          }
        });
        //console.log("colaboradores", colaboradores);
        setColaboradoresConCargasPendientes(colaboradores);
      }
    };
    cargarDatos();
  }, []);

  return (
    <React.Fragment>
      {!existenDocumentosPorSubir && <NoExisteDocumentosPorSubir />}
      {existenDocumentosPorSubir && <ListaColaboradores />}
    </React.Fragment>
  );
};

const ListaColaboradores = () => {
  const [colaboradoresConCargasPendientes, setColaboradoresConCargasPendientes] = useAtom(
    colaboradoresConCargasPendientesAtom
  );
  const [, setRutaStorageZip] = useAtom(rutaStorageZipAtom);
  const [datosColaboradorStep] = useAtom(datosColaboradorStepAtom);
  const [, setPasoActual] = useAtom(pasoActualAtom);
  const [, setResumen] = useAtom(resumenAtom);
  const [cargaDocumentos, setCargaDocumentos] = useState([]);
  const { Dragger } = Upload;
  const { TabPane } = Tabs;

  ////
  const [analizado, setAnalizado] = useState(false);
  const [visible, setVisible] = useState(false);
  const [listaColaborador, setListaColaborador] = useState([]);
  const [listaColaboradorConError, setListaColaboradorConError] = useState([]);

  // const columnas = [
  //   {
  //     title: "Colaborador",
  //     dataIndex: "nombreCompleto",
  //     key: "nombreCompleto",
  //   },
  //   {
  //     title: "Cargar documento",
  //   },
  // ];

  const onClickSiguiente = () => {
    setResumen(datosColaboradorStep);
    setPasoActual(3);
  };

  const onClickAnterior = () => {
    setResumen(datosColaboradorStep);
    setPasoActual(1);
  };

  const onClickLimpiar = () => {
    setAnalizado(false);
    setListaColaborador([]);
    setListaColaboradorConError([]);
    setCargaDocumentos([]);
  };

  const propiedadesUpload = {
    name: "file",
    multiple: true,
    //lisType: "text",
    action: process.env.REACT_APP_ENDPOINT + "onboarding/cargarTemporal",
    headers: {
      Authorization: "Bearer " + LoginService.getJwtToken(),
      [Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
      [Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
    },
    data: {
      trama: new Blob(
        [
          JSON.stringify({
            colaboradores: datosColaboradorStep.documentos.map((s) => s.colaborador.codigoTrabajador),
            documentos: datosColaboradorStep.documentos.map((s) => s.tipoPlantilla.id),
          }),
        ],
        { type: "application/json" }
      ),
    },
    onChange(info) {
      const { status } = info.file;
      // Garantiza solo la carga de un archivo
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);

      if (status !== "uploading") {
      }
      if (status === "done") {
        if (
          (info.file.response.listaColaboradores != null && info.file.response.listaColaboradores.length >= 0) ||
          (info.file.response.listaColaboradoresObservados != null &&
            info.file.response.listaColaboradoresObservados.length >= 0)
        ) {
          setAnalizado(true);
          console.log("response", info.file.response);
          setRutaStorageZip(info.file.response.rutaStorageZip);
          setListaColaborador(info.file.response.listaColaboradores);
          setListaColaboradorConError(info.file.response.listaColaboradoresObservados);
        }

        // if (info.file.response.listaColaboradores.length === 0) {
        //   notification.warning({
        //     message: "Advertencia",
        //     description:
        //       "No se encontraron Colaboradores por favor revisar el archivo de carga - validar posición del número de documento.",
        //   });
        // } else {
        //   notification.success({
        //     message: "Operación exitosa",
        //     description: `Se cargo correctamente el archivo: ${info.file.name}`,
        //   });
        // }
      } else if (status === "error") {
        notification.error({
          message: "Error al cargar el archivo",
          description: "codigo: " + info.file.response,
        });
      }
      setCargaDocumentos(fileList);
    },
  };

  const colaboradores = [
    {
      title: "Colaborador",
      dataIndex: "colaborador",
      key: "colaborador",
    },
    {
      title: "Codigo Trabajador",
      dataIndex: "documentoIdentidad",
      key: "documentoIdentidad",
    },
    {
      title: "Estado",
      dataIndex: "mensaje",
      key: "mensaje",
      render: (_, fila) => (
        <Popover
          content={
            <Descriptions title="Estado" bordered size="small" column={1}>
              {fila.documentos.map((s) => (
                <Descriptions.Item key={s} label={s}>
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                </Descriptions.Item>
              ))}
              {fila.documentosFaltantes.map((s) => (
                <Descriptions.Item key={s} label={s}>
                  <CloseCircleTwoTone twoToneColor="#eb2f96" />
                </Descriptions.Item>
              ))}
            </Descriptions>
          }
          trigger="click"
          visible={visible}
          onVisibleChange={(visible) => setVisible(visible)}
        >
          <Tag color={fila.mensaje === "Error" ? "red" : "green"}>{fila.mensaje}</Tag>
        </Popover>
      ),
    },
  ];

  const colaboradoresObservacion = [
    {
      title: "Codigo Trabajador",
      dataIndex: "documentoIdentidad",
      key: "documentoIdentidad",
    },
    {
      title: "Estado",
      dataIndex: "mensaje",
      key: "mensaje",
    },
  ];

  return (
    <React.Fragment>
      {!analizado && (
        <Dragger {...propiedadesUpload} fileList={cargaDocumentos} accept="application/x-zip-compressed">
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">Por favor sube tus documentos.</p>
        </Dragger>
      )}
      {analizado && (
        <Tabs
          type="card"
          tabBarExtraContent={
            <Button onClick={onClickLimpiar} type="primary">
              Limpiar
            </Button>
          }
        >
          <TabPane tab="Colaboradores encontrados" key="1">
            <Table rowKey={(tabla) => tabla.documentoIdentidad} columns={colaboradores} dataSource={listaColaborador} />
          </TabPane>
          <TabPane tab="Colaboradores con observación" key="2">
            <Table
              rowKey={(tabla) => tabla.documentoIdentidad}
              columns={colaboradoresObservacion}
              dataSource={listaColaboradorConError}
            />
          </TabPane>
        </Tabs>
      )}
      <br />
      <div>
        <Space>
          <Button onClick={onClickAnterior} size="large">
            <LeftOutlined />
            Atras
          </Button>

          <Button onClick={onClickSiguiente} type="primary" size="large">
            Siguiente <RightOutlined />
          </Button>
        </Space>
      </div>

      {/* <Table rowKey={(tabla) => tabla.id} dataSource={colaboradoresConCargasPendientes} columns={columnas} /> */}
    </React.Fragment>
  );
};

const NoExisteDocumentosPorSubir = () => {
  const [datosColaboradorStep] = useAtom(datosColaboradorStepAtom);
  const [, setPasoActual] = useAtom(pasoActualAtom);
  const [, setResumen] = useAtom(resumenAtom);
  const [, setLoaging] = useAtom(loadingAtom);

  const onClickSiguiente = () => {
    setResumen(datosColaboradorStep);
    setPasoActual(3);
  };

  const onClickAnterior = () => {
    setResumen(datosColaboradorStep);
    setPasoActual(1);
  };

  return (
    <Result
      style={{ padding: "20px 40px" }}
      icon={<FileExcelOutlined style={{ color: "#607d8b" }} />}
      title={"No existe contratos configurados."}
      subTitle={<Subtitulo />}
      extra={
        <Space>
          <Button onClick={onClickAnterior} size="large">
            <LeftOutlined />
            Atras
          </Button>

          <Button onClick={onClickSiguiente} type="primary" size="large">
            Siguiente <RightOutlined />
          </Button>
        </Space>
      }
    />
  );
};

const Subtitulo = () => {
  return (
    <React.Fragment>
      <span style={{ display: "block", padding: "2px 0px" }}>
        No existe configurado ningún contrato en alguna plantilla onboarding seleccionada.
      </span>
      <span style={{ display: "block", padding: "2px 0px" }}>Para continuar, hacer clic en el botón Siguiente.</span>
    </React.Fragment>
  );
};

export default VerificarDocumentoStep;
