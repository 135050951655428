import { BaseService } from "./BaseService";
import qs from "qs";

export const DocumentoLaboralService = {

  async listar(empresaId, envioId, estado, colaborador, documentoIdentidad,codigoColaborador, login,resumen) {

    const respuesta = await BaseService.get("/documento-laboral", {
      params: {
        empresaId: empresaId,
        envioId: envioId,
        estado: estado,
        colaborador: colaborador,
        documentoIdentidad: documentoIdentidad,
        codigoColaborador:codigoColaborador,
        login:login,
        resumen:resumen
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    return respuesta.data;
  },

};
