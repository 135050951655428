import React from "react";
import { Dropdown, Menu, notification, message } from "antd";
import { CheckOutlined, CloseCircleOutlined, UnorderedListOutlined, CaretDownOutlined } from "@ant-design/icons";
import { VacacionesServiceAnterior } from "services/VacacionesService.bk";
import * as atoms from "../../ConsultaVacacionesStore";
import { useAtom } from "jotai";

export const MenuAcciones = ({ solicitud, solicitudVacaciones, setSolicitudVacaciones }) => {
  //const [solicitudesVacaciones, setSolicitudesVacaciones] = useAtom(atoms.solicitudesVacacionesAtom);
  const [visibleModalRechazo, setVisibleModalRechazo] = useAtom(atoms.visibleModalRechazoAtom);
  const [solicitudVacacionesSeleccionada, setSolicitudVacacionesSeleccionada] = useAtom(
    atoms.solicitudVacacionesSeleccionadaAtom
  );
  const menu = () => {
    return (
      <Menu onClick={onClickMenu}>
        <Menu.Item key="aprobar">
          <CheckOutlined /> Aprobar
        </Menu.Item>

        <Menu.Item key="rechazar">
          <CloseCircleOutlined /> Rechazar
        </Menu.Item>
      </Menu>
    );
  };
  const onClickMenu = (opcion) => {
    switch (opcion.key) {
      case "ver-solicitud":
        onClickMenuVerSolcitud();
        break;

      case "aprobar":
        onClickMenuAprobar();

        break;

      case "rechazar":
        onClickMenuRechazar();
        break;

      default:
        console.error("No se encuentra esa opción");
    }
  };
  const onClickMenuVerSolcitud = () => {};

  const onClickMenuAprobar = async () => {
    try {
      await VacacionesServiceAnterior.aprobar(solicitud.id);
      message.success("La solicitud ha sido aprobada.");
      setSolicitudVacaciones(solicitudVacaciones.filter((solicitudV) => solicitudV.id !== solicitud.id));
    } catch (error) {
      notification.error({
        message: "No se puede aprobar la solicitud",
        description: error.response.data.mensaje,
      });
    }
  };

  const onClickMenuRechazar = () => {
    setVisibleModalRechazo(true);
    setSolicitudVacacionesSeleccionada(solicitud);
  };

  return (
    <React.Fragment>
      <Dropdown overlay={menu} trigger={["click"]} placement="bottomCenter">
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
          <CaretDownOutlined style={{ paddingLeft: "2px", fontSize: "12px", fontWeight: "bold", color: "#1678c1" }} />
        </a>
      </Dropdown>
    </React.Fragment>
  );
};
