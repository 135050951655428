import React, { useContext, useState,useEffect } from "react";
import {  Form, Input, Button,Row,Col,Select } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useModalConfirmar } from "store/global"
import { SecurityContext } from "context/SecurityContextProvider";
import * as atoms from "../CrearTipoPlanillaStore";
import { useAtom } from "jotai";
import {CampoColaborador} from "enums/CampoColaborador";
import SubsedeService from "services/SubsedeService";
import PerfilService from "services/PerfilService";
import SedeService from "services/SedeService";
import SeccionService from "services/SeccionService";
import CargoService from "services/CargoService";


const TipoPlanillaRegistro=()=>{

    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const[,setTipoPlanillaAtom]=useAtom(atoms.tipoPlanilla);   
    const mostrarModalConfirmacion = useModalConfirmar(state => state.mostrarModal);

    const [dependenciaTipoPlanillaAtom,setDependenciaTipoPlanillaAtom]=useAtom(atoms.dependenciaTipoPlanilla);
    const [listaCamposAtom,setListaCamposAtom]=useAtom(atoms.listaCampos);
    const [perfilesAtom,setPerfilesAtom]=useAtom(atoms.perfiles);
    const [sedesAtom,setSedesAtom]=useAtom(atoms.sedes);
    const [subsedesAtom,setSubsedesAtom]=useAtom(atoms.subsedes);
    const [seccionesAtom,setSeccionesAtom]=useAtom(atoms.secciones);
    const [cargosAtom,setCargosAtom]=useAtom(atoms.cargos);
    const [camposColaboradorAtom,setCamposColaboradorAtom]=useAtom(atoms.camposColaborador);
      
    const { Option } = Select;
    const [formTipoPlanilla] = Form.useForm();

    useEffect(()=>{

      const cargarDatosIniciales = async ()=>{
        var listaCampos=[],dependencia; 
        const empresa = getUsuarioAutenticado().empresaSeleccionada.id;
        const campos_colaborador=getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
        setCamposColaboradorAtom(campos_colaborador);

        const campo_tipoPlanilla= campos_colaborador.find(campoColaborador=>campoColaborador.campo===CampoColaborador.PLANILLA);

        const dependenciaTipoPlanilla=campo_tipoPlanilla.campoDependencia;            
      

        if(dependenciaTipoPlanilla!==null){

            setDependenciaTipoPlanillaAtom(dependenciaTipoPlanilla);
            dependencia=dependenciaTipoPlanilla;
            listaCampos.push(dependencia);

            while(dependencia!==null){
              var campo= campos_colaborador.find(campoColaborador=>campoColaborador.campo===dependencia);
              dependencia=campo.campoDependencia;
               if(dependencia!==null){
                listaCampos.push(dependencia);
               }
            
            }
            setListaCamposAtom(listaCampos.reverse());
          }   
          else{
            setListaCamposAtom(listaCampos);
            setDependenciaTipoPlanillaAtom(CampoColaborador.SINCAMPO);
          }
           if(listaCampos.length>0 ){
              const primerCampo=listaCampos[0];
              if(primerCampo===CampoColaborador.PERFIL){
                const perfiles= await PerfilService.listar(empresa);
                setPerfilesAtom(perfiles);
              }
              else if(primerCampo===CampoColaborador.SEDE){
                const sedes= await SedeService.listar(empresa);
                setSedesAtom(sedes);
        
              }
              else if(primerCampo===CampoColaborador.SUBSEDE){           
                const subsedes= await SubsedeService.listar(empresa);
                setSubsedesAtom(subsedes);
        
              }
              else if(primerCampo===CampoColaborador.SECCION){
                const secciones= await SeccionService.listar(empresa);
                setSeccionesAtom(secciones);
                
              }
              else if(primerCampo===CampoColaborador.CARGO){
                const cargos = await CargoService.listar(empresa);
                setCargosAtom(cargos);
              }       
           }

      }

      cargarDatosIniciales();


    },[]);
    
    const onClickBtnCrearTipoPlanilla=(tipoPlanilla)=>{
      tipoPlanilla.empresaId=getUsuarioAutenticado().empresaSeleccionada.id;
      tipoPlanilla.dependencia=dependenciaTipoPlanillaAtom;   
      setTipoPlanillaAtom(tipoPlanilla);
      mostrarModalConfirmacion();
    }
    const handleOnchangePerfil = async(value,option)=>{

      let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.PERFIL);
  
      for(let i=0 ; i < listaCampos.length ; i++){
         if (listaCampos[i].campo===CampoColaborador.SEDE){         
           const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _sedes = sedes.filter(sede=>sede.perfilId===value)
           setSedesAtom(_sedes);  
           formTipoPlanilla.setFieldsValue({ sedeId:""})
               
           
         }         
         else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
           const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _subsedes = subsedes.filter(subsede=>subsede.perfilId===value);
           setSubsedesAtom(_subsedes);
           formTipoPlanilla.setFieldsValue({ subsedeId:""})
          
         }
         else if(listaCampos[i].campo===CampoColaborador.CARGO){
           const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _cargos = cargos.filter(cargo=>cargo.perfilId===value);
           setCargosAtom(_cargos);
           formTipoPlanilla.setFieldsValue({ cargoId:""})
           
         }
         else if(listaCampos[i].campo===CampoColaborador.SECCION){
           const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _secciones = secciones.filter(seccion=>seccion.perfilId===value);
           setSeccionesAtom(_secciones);
           formTipoPlanilla.setFieldsValue({ seccionId:""})
           
         }
        }   
    }
    const handleOnchangeSede = async(value,option)=>{
      let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.SEDE);
  
      for(let i=0 ; i < listaCampos.length ; i++){
         if (listaCampos[i].campo===CampoColaborador.PERFIL){         
           const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _perfiles = perfiles.filter(perfil=>perfil.sedeId===value)
           setPerfilesAtom(_perfiles);  
           formTipoPlanilla.setFieldsValue({ perfilId:""})
               
           
         }         
         else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
           const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _subsedes = subsedes.filter(subsede=>subsede.sedeId===value);
           setSubsedesAtom(_subsedes);
           formTipoPlanilla.setFieldsValue({ subsedeId:""})
          
         }
         else if(listaCampos[i].campo===CampoColaborador.CARGO){
           const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _cargos = cargos.filter(cargo=>cargo.sedeId===value);
           setCargosAtom(_cargos);
           formTipoPlanilla.setFieldsValue({ cargoId:""})
           
         }
         else if(listaCampos[i].campo===CampoColaborador.SECCION){
           const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _secciones = secciones.filter(seccion=>seccion.sedeId===value);
           setSeccionesAtom(_secciones);
           formTipoPlanilla.setFieldsValue({ seccionId:""})
           
         }
        }   
    }
    const handleOnchangeSubsede = async(value,option)=>{
      let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.SUBSEDE);
  
      for(let i=0 ; i < listaCampos.length ; i++){
         if (listaCampos[i].campo===CampoColaborador.PERFIL){         
           const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _perfiles = perfiles.filter(perfil=>perfil.subsedeId===value)
           setPerfilesAtom(_perfiles);  
           formTipoPlanilla.setFieldsValue({ perfilId:""})
               
           
         }         
         else if(listaCampos[i].campo===CampoColaborador.SEDE){
           const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _sedes = sedes.filter(sede=>sede.subsedeId===value);
           setSedesAtom(_sedes);
           formTipoPlanilla.setFieldsValue({ sedeId:""})
          
         }
         else if(listaCampos[i].campo===CampoColaborador.CARGO){
           const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _cargos = cargos.filter(cargo=>cargo.subsedeId===value);
           setCargosAtom(_cargos);
           formTipoPlanilla.setFieldsValue({ cargoId:""})
           
         }
         else if(listaCampos[i].campo===CampoColaborador.SECCION){
           const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _secciones = secciones.filter(seccion=>seccion.subsedeId===value);
           setSeccionesAtom(_secciones);
           formTipoPlanilla.setFieldsValue({ seccionId:""})
           
         }
        }   
      
    }
    const handleOnchangeSeccion = async(value,option)=>{
      let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.SECCION);
  
      for(let i=0 ; i < listaCampos.length ; i++){
         if (listaCampos[i].campo===CampoColaborador.PERFIL){         
           const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _perfiles = perfiles.filter(perfil=>perfil.seccionId===value)
           setPerfilesAtom(_perfiles);  
           formTipoPlanilla.setFieldsValue({ perfilId:""})
               
           
         }         
         else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
           const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _subsedes = subsedes.filter(subsede=>subsede.seccionId===value);
           setSubsedesAtom(_subsedes);
           formTipoPlanilla.setFieldsValue({ subsedeId:""})
          
         }
         else if(listaCampos[i].campo===CampoColaborador.CARGO){
           const cargos=await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _cargos = cargos.filter(cargo=>cargo.seccionId===value);
           setCargosAtom(_cargos);
           formTipoPlanilla.setFieldsValue({ cargoId:""})
           
         }
         else if(listaCampos[i].campo===CampoColaborador.SEDE){
           const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _sedes =  sedes.filter(sede=>sede.seccionId===value);
           setSedesAtom(_sedes);
           formTipoPlanilla.setFieldsValue({ sedeId:""})
           
         }
        }   

    }
    const handleOnchangeCargo = async(value,option)=>{
      let listaCampos= camposColaboradorAtom.filter(campoColaborador=>campoColaborador.campoDependencia===CampoColaborador.CARGO);
  
      for(let i=0 ; i < listaCampos.length ; i++){
         if (listaCampos[i].campo===CampoColaborador.PERFIL){         
           const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _perfiles = perfiles.filter(perfil=>perfil.cargoId===value)
           setPerfilesAtom(_perfiles);  
           formTipoPlanilla.setFieldsValue({ perfilId:""})
               
           
         }         
         else if(listaCampos[i].campo===CampoColaborador.SUBSEDE){
           const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _subsedes = subsedes.filter(subsede=>subsede.cargoId===value);
           setSubsedesAtom(_subsedes);
           formTipoPlanilla.setFieldsValue({ subsedeId:""})
          
         }
         else if(listaCampos[i].campo===CampoColaborador.SECCION){
           const secciones=await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _secciones = secciones.filter(seccion=>seccion.cargoId===value);
           setSeccionesAtom(_secciones);
           formTipoPlanilla.setFieldsValue({ seccionId:""})
           
         }
         else if(listaCampos[i].campo===CampoColaborador.SEDE){
           const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
           const _sedes =  sedes.filter(sede=>sede.cargoId===value);
           setSedesAtom(_sedes);
           formTipoPlanilla.setFieldsValue({ sedeId:""})
           
         }
        }   
    }

    return(
        <Form
           layout="vertical"                      
           onFinish={onClickBtnCrearTipoPlanilla}
           form={formTipoPlanilla}
        > 
             {
              listaCamposAtom.length>0 &&(
                listaCamposAtom.map((listaCampo)=>{
                    if (listaCampo===CampoColaborador.PERFIL){   
                      return(
                            <Row>
                                    <Col span={12} offset={6}>
                                    <Form.Item
                                        label="Perfil"
                                        name="perfilId"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Seleccione Perfil"
                                          },
                                        ]}
                                    >
                                      <Select
                                      placeholder="Seleccione Perfil"
                                      onChange={handleOnchangePerfil}
                                      >
                                      {perfilesAtom.length > 0 &&
                                        perfilesAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                      </Select>

                                    </Form.Item>
                                    </Col>
                          </Row>
                      )       
                      }                                             
                      
                      else if (listaCampo===CampoColaborador.SEDE){
                        return(
                          <Row>
                                    <Col span={12} offset={6}>
                                    <Form.Item
                                        label="Sede"
                                        name="sedeId"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Seleccione Sede"
                                          },
                                        ]}
                                    >
                                      <Select
                                      placeholder="Seleccione Sede"
                                      onChange={handleOnchangeSede}
                                      >
                                      {sedesAtom.length > 0 &&
                                        sedesAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                        
                                      </Select>

                                    </Form.Item>
                                    </Col>
                          </Row>
                        )
                      }
                      else if (listaCampo===CampoColaborador.SUBSEDE){
                        return(
                          <Row>
                                <Col span={12} offset={6}>
                                <Form.Item
                                    label="Subsede"
                                    name="subsedeId"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Seleccione Subsede"
                                      },
                                    ]}
                                >
                                  <Select
                                  placeholder="Seleccione Subsede"
                                  onChange={handleOnchangeSubsede}
                                  >
                                  {subsedesAtom.length > 0 &&
                                        subsedesAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                  </Select>

                                </Form.Item>
                                </Col>
                        </Row>
                        )
                      }
                      else if (listaCampo===CampoColaborador.SECCION){
                        return(
                          <Row>
                                <Col span={12} offset={6}>
                                <Form.Item
                                    label="Sección"
                                    name="seccionId"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Seleccione sección"
                                      },
                                    ]}
                                >
                                  <Select
                                  placeholder="Seleccione sección"
                                  onChange={handleOnchangeSeccion}
                                  >
                                  {seccionesAtom.length > 0 &&
                                        seccionesAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                  </Select>

                                </Form.Item>
                                </Col>
                        </Row>
                        )
                
                      }
                      else if (listaCampo===CampoColaborador.CARGO){
                        return(
                          <Row>
                                <Col span={12} offset={6}>
                                <Form.Item
                                    label="Cargo"
                                    name="cargoId"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Seleccione Cargo"
                                      },
                                    ]}
                                >
                                  <Select
                                    placeholder="Seleccione Cargo"
                                    onChange={handleOnchangeCargo}
                                  >
                                  {cargosAtom.length > 0 &&
                                        cargosAtom.map((lista) => {
                                              return (
                                                <Option key={lista.id} value={lista.id}>
                                                  {lista.nombre}
                                                </Option>
                                              );
                                            })}  
                                  </Select>

                                </Form.Item>
                                </Col>
                        </Row>
                        )             
                      }                      
                                        
                })
                
              )           
            
          }     
        
         <Row> 
              <Col span={12} offset={6}>   
                <Form.Item                  
                  label="Nombre"
                  name="nombre"
                  rules={[
                    {
                      required: true,
                      message: "Escribe el nombre del tipo planilla",
                    },
                  ]}
                >
                  <Input
                    placeholder="Nombre del tipo planilla"
                    maxLength="50"
                    autoComplete="off"
                  />
                </Form.Item>
            </Col>
         </Row> 
          
        <Row justify="center">
          
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}                  
                  htmlType="submit"
                >
                  Crear Tipo Planilla
                </Button>
              </Form.Item>           
         
        </Row>
        </Form>
    )
}
export default TipoPlanillaRegistro;