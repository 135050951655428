import React, { useContext, useEffect, useState } from "react";
import { Input, Form, Button, Select, DatePicker, Space, Col, Row, Typography, message,Upload } from "antd";
import { UploadOutlined, FolderTwoTone, FolderFilled } from "@ant-design/icons";
import { SecurityContext } from "context/SecurityContextProvider";
import { FormularioNotificarService } from "services/FormularioNotificarService";
import CarpetaService from "../../../../../services/CarpetaService";
import { TablaColaboradoresSeleccionados } from "./tabla-colaboradores-seleccionados";
import { TablaSedesSeleccionadas } from "./tabla-sedes-seleccionadas";
import { ModalConfirmar } from "components/Modal/ModalConfirmar";
import { useModalConfirmar } from "store/global";

import { useAtom } from "jotai";
import * as atoms from "../store/NotificarFormularioStore";
import { useHistory } from "react-router";
import { destinatarioFormulario } from "enums/DestinatarioFormulario";
import { visibleModalConfirmacionAtom } from "../store/NotificarFormularioStore";
import { ModalConfirmacion } from "./ModalConfirmacion";
import {
  colaboradoresAtom,
  visibleTablaColaboradoresAtom,
  visibleTablaSedesAtom,
  sedesAtom,
} from "../store/NotificarFormularioStore";
import { CategoriaDocumento } from "enums/CategoriaDocumento";
import TipoDocumentoService from "services/TipoDocumentoService";
import SedeService from "services/SedeService";
import { estadoColaborador } from "enums/EstadoColaborador";
import UsuarioService from "services/UsuarioService";
import { CampoColaborador } from "enums/CampoColaborador";
import SubsedeService from "services/SubsedeService";
import SeccionService from "services/SeccionService";
import TipoPlanillaService from "services/TipoPlanillaService";
import PerfilService from "services/PerfilService";
import produce from "immer";
import CargoService from "services/CargoService";
import { FormularioService } from "services/FormularioService";
import CertificadoService from "services/CertificadoService";
import { EstadoCertificadoDigital } from "enums/EstadoCertificadoDigital";
import AlertCertificadoDigital from "../alert-certificado-digital";
import { FormatoCarga } from "enums/FormatoCarga";

const Formulario = () => {
  const { Option } = Select;
  const { Text } = Typography;
  let history = useHistory();
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [loading, setLoading] = useState(false);
  const mostrarModalConfirmacion = useModalConfirmar((state) => state.mostrarModal);
  const cerrarModalConfirmacion = useModalConfirmar((state) => state.cerrarModal);
  const [notificacion, setNotificacion] = useState([]);
  const [formularios, setFormularios] = useState([]);
  const [, setVisibleModalColaboradores] = useAtom(atoms.visibleModalSeleccionarColaborador);
  const [colaboradoresSeleccionadosId, setColaboradoresSeleccionadosId] = useAtom(atoms.colaboradoresSeleccionadosId);
  const [carpetas, setCarpetas] = useState([]);
  const [frmCrearFormulario] = Form.useForm();
  const [visibleModalConfirmacion, setVisibleModalConfirmacion] = useAtom(visibleModalConfirmacionAtom);
  const [colaboradores, setColaboradores] = useAtom(colaboradoresAtom);
  const [sedes, setSedes] = useAtom(sedesAtom);
  const [visibleTablaColaboradores, setVisibleTablaColaboradores] = useAtom(visibleTablaColaboradoresAtom);
  const [visibleTablaSedes, setVisibleTablaSedes] = useAtom(visibleTablaSedesAtom);
  const [valorDestinatarioFormulario, setValorDestinatarioFormulario] = useState("");
  const [estadosColaboradores, setEstadosColaboradores] = useState(false);
  const [ingresaColaborador, setIngresaColaborador] = useState(false);
  const [ingresaSede, setIngresaSede] = useState(false);
  const [valorEstadoColaborador, setValorEstadoColaborador] = useState(estadoColaborador.SINESTADO);
  const [todosColaboradores, setTodosColaboradores] = useState(false);
  const [colaboradorSeleccionado, setColaboradorSeleccionado] = useAtom(atoms.colaboradorSeleccionadoAtom);
  const [colaboradoresAgregados, setColaboradoresAgregados] = useAtom(atoms.colaboradoresAgregadosAtom);
  const [colaboradoresAgregadosIds, setColaboradoresAgregadosIds] = useAtom(atoms.colaboradoresAgregadosIdsAtom);
  const [sedeSeleccionada, setSedeSeleccionada] = useAtom(atoms.sedeSelecionadaAtom);
  const [sedesAgregadas, setSedesAgregadas] = useAtom(atoms.sedesAgregadasAtom);
  const [sedesAgregadasIds, setSedesAgregadasIds] = useAtom(atoms.sedesAgregadasIdsAtom);
  const [formularioNombre, setFormularioNombre] = useState("");
  const [colaboradoresXSedes, setColaboradoresXSedes] = useState([]);
  const [listaDependencias, setListaDependencias] = useState([]);
  const [gutterForm, setgutterForm] = useState(0);
  const [categorias, setCategorias] = useState([]);
  const [valoresSeleccionados, setValoresSeleccionados] = useState(null);
  const camposColaboradores = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
  const [respuestaCertificadoExpirado, setRespuestaCertificadoExpirado] = useAtom(
    atoms.respuestaCertificadoExpiradoAtom
  );
  const [mostrarAlerta, setMostrarAlerta] = useAtom(atoms.mostrarAlertaAtom);
  const [responsableFirmaDocumentos, setResponsableFirmaDocumentos] = useState("");
  const [cargaArchivoExcel, setCargaArchivoExcel] = useState(false);
  const [archivoExcel, setArchivoExcel] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const _formularios = await FormularioService.listarPorUsuario(getUsuarioAutenticado().login);
        console.log("_formularios", _formularios);
        setFormularios(_formularios);
        // if (_tipoDocumento !== null) {
        //   console.log("tipos documentos", _tipoDocumento);
        //   const _tipoDocumentoFormulario = _tipoDocumento.filter((f) => f.categoriaDocumento.nombre === "FORMULARIOS");
        //   setFormularios(_tipoDocumentoFormulario);
        // } else {
        //   const tipoDocumentos = await TipoDocumentoService.listar(
        //     getUsuarioAutenticado().empresaSeleccionada.id,
        //     CategoriaDocumento.FORMULARIOS.id
        //   );
        //   setFormularios(tipoDocumentos);
        // }

        const _carpetas = await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);

        setCarpetas(_carpetas);

        var _categorias = [];
        var perfiles = (await PerfilService.listarPorUsuario(getUsuarioAutenticado().login)).map((perfil) =>
          getEntidad(perfil, CampoColaborador.PERFIL)
        );
        _categorias.push(...perfiles);

        var sedes = (await SedeService.listarPorUsuario(getUsuarioAutenticado().login)).map((sede) =>
          getEntidad(sede, CampoColaborador.SEDE)
        );
        _categorias.push(...sedes);

        var subsedes = (await SubsedeService.listarPorUsuario(getUsuarioAutenticado().login)).map((subsede) =>
          getEntidad(subsede, CampoColaborador.SUBSEDE)
        );
        _categorias.push(...subsedes);

        var secciones = (await SeccionService.listarPorUsuario(getUsuarioAutenticado().login)).map((seccion) =>
          getEntidad(seccion, CampoColaborador.SECCION)
        );
        _categorias.push(...secciones);

        var cargos = (await CargoService.listarPorUsuario(getUsuarioAutenticado().login)).map((cargo) =>
          getEntidad(cargo, CampoColaborador.CARGO)
        );
        _categorias.push(...cargos);

        var planillas = (await TipoPlanillaService.listarPorUsuario(getUsuarioAutenticado().login)).map((planilla) =>
          getEntidad(planilla, CampoColaborador.PLANILLA)
        );
        _categorias.push(...planillas);

        setCategorias(_categorias);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (ingresaSede) {
      const camposColaborador = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
      let campoSeleccionadoForm = ingresaSede ? CampoColaborador.SEDE : CampoColaborador.SINCAMPO;
      const campoCargo = camposColaborador.find((c) => c.campo === campoSeleccionadoForm);
      let dependencia = campoCargo.campoDependencia;
      let arrayDependencia = [];
      let busqueda = true;

      if (dependencia !== null) {
        arrayDependencia.push({ campo: campoCargo.campo, etiqueta: campoCargo.etiqueta, datos: [] });
        while (busqueda) {
          let campoActual = camposColaborador.find((c) => c.campo === dependencia);
          if (campoActual.campoDependencia === null) {
            arrayDependencia.push({
              campo: campoActual.campo,
              etiqueta: campoActual.etiqueta,
              datos: categorias
                .filter((categoria) => categoria.campo === campoActual.campo)
                .map((categoria) => {
                  var opcion = {
                    //key: categoria.id,
                    id: categoria.id,
                    label: categoria.nombre,
                    value: categoria.id,
                    campo: campoActual.campo,
                    campodependencia: null,
                    iddependencia: null,
                  };
                  return opcion;
                }),
            });
            busqueda = false;
          } else {
            arrayDependencia.push({ campo: campoActual.campo, etiqueta: campoActual.etiqueta, datos: [] });
            dependencia = campoActual.campoDependencia;
          }
        }
        arrayDependencia.reverse();
      } else {
        arrayDependencia.push({
          campo: campoCargo.campo,
          etiqueta: campoCargo.etiqueta,
          datos: categorias
            .filter((categoria) => categoria.campo === campoCargo.campo)
            .map((categoria) => {
              var opcion = {
                // key: categoria.id,
                id: categoria.id,
                label: categoria.nombre,
                value: categoria.id,
                campo: campoCargo.campo,
                campodependencia: null,
                iddependencia: null,
              };
              return opcion;
            }),
        });
      }
      setListaDependencias(arrayDependencia);
      for (let i = 0; i < arrayDependencia.length; i++) {
        frmCrearFormulario.setFieldsValue({ [arrayDependencia[i].campo]: null });
      }
      const size = Math.ceil((arrayDependencia.length + 1) / 2 - 1) * 50;
      setgutterForm(size);
    }
  }, [ingresaSede]);

  const propsCargaExcel = {
    onRemove: (file) => {
      setArchivoExcel([]);

      return {
        setArchivoExcel: [],
      };
    },
    beforeUpload: (file) => {
      if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        message.error(`${file.name} no es un archivo Excel`);
      } else {
        setArchivoExcel(file);
        return false;
      }
    },
    archivoExcel,
  };

  const onChangeFiltros = (valor, item) => {
    if (valor != null) {
      if (item.campo == CampoColaborador.SEDE && ingresaSede) {
        setSedeSeleccionada(valor);
      }
      const index = listaDependencias.findIndex((l) => l.campo == item.campo);
      if (index + 1 < listaDependencias.length) {
        setListaDependencias(
          produce((draft) => {
            draft[index + 1].datos = categorias
              .filter(
                (categoria) =>
                  categoria.campo === listaDependencias[index + 1].campo && categoria.idDependencia === valor
              )
              .map((categoria) => {
                var opcion = {
                  // key: categoria.id,
                  id: categoria.id,
                  label: categoria.nombre,
                  value: categoria.id,
                  campo: listaDependencias[index + 1].campo,
                  campodependencia: categoria.campoDependencia,
                  iddependencia: valor,
                };
                return opcion;
              });
          })
        );
      }

      if (index < listaDependencias.length) {
        for (let i = index + 1; i < listaDependencias.length; i++) {
          if (i > index + 1) {
            setListaDependencias(
              produce((draft) => {
                draft[i].datos = [];
              })
            );
          }
          frmCrearFormulario.setFieldsValue({ [listaDependencias[i].campo]: null });
        }
      }
    } else {
      const index = listaDependencias.findIndex((l) => l.campo == item);
      if (item == CampoColaborador.SEDE && ingresaSede) {
        setSedeSeleccionada([]);
      }
      if (index < listaDependencias.length) {
        for (let i = index; i < listaDependencias.length; i++) {
          if (i > index) {
            setListaDependencias(
              produce((draft) => {
                draft[i].datos = [];
              })
            );
          }
          frmCrearFormulario.setFieldsValue({ [listaDependencias[i].campo]: null });
        }
      }
    }
  };

  // const onClickAgregarColaboradores = () => {
  //   setVisibleModalColaboradores(true);
  // };
  const onClickAgregarColaboradores = () => {
    if (colaboradorSeleccionado.length !== 0) {
      const colaboradorBuscado = colaboradoresAgregados.find((item) => item.id === colaboradorSeleccionado);
      if (colaboradorBuscado === undefined) {
        setColaboradoresAgregadosIds([...colaboradoresAgregadosIds, colaboradorSeleccionado]);
        const _colaboradorAgregado = colaboradores.find((colaborador) => colaborador.id === colaboradorSeleccionado);
        setColaboradorSeleccionado([]);
        setColaboradoresAgregados([...colaboradoresAgregados, _colaboradorAgregado]);
        setVisibleTablaColaboradores(true);
        frmCrearFormulario.setFieldsValue({
          buscarColaborador: [],
        });

        setColaboradores([]);
      } else {
        message.error("El colaborador ya ha sido agregado");
      }
    }
  };

  const getEntidad = (entidad, campo) => {
    const campoDependencia = camposColaboradores.find((c) => c.campo === campo).campoDependencia;
    var campo = {
      id: entidad.id,
      nombre: entidad.nombre,
      campo: campo,
      campoDependencia: undefined,
      idDependencia: undefined,
    };
    if (campoDependencia === CampoColaborador.PERFIL) {
      return { ...campo, campoDependencia: CampoColaborador.PERFIL, idDependencia: entidad.perfilId };
    } else if (campoDependencia === CampoColaborador.SEDE) {
      return { ...campo, campoDependencia: CampoColaborador.SEDE, idDependencia: entidad.sedeId };
    } else if (campoDependencia === CampoColaborador.SUBSEDE) {
      return { ...campo, campoDependencia: CampoColaborador.SUBSEDE, idDependencia: entidad.subsedeId };
    } else if (campoDependencia === CampoColaborador.SECCION) {
      return { ...campo, campoDependencia: CampoColaborador.SECCION, idDependencia: entidad.seccionId };
    } else if (campoDependencia === CampoColaborador.CARGO) {
      return { ...campo, campoDependencia: CampoColaborador.CARGO, idDependencia: entidad.cargoId };
    } else if (campoDependencia === CampoColaborador.PLANILLA) {
      return { ...campo, campoDependencia: CampoColaborador.PLANILLA, idDependencia: entidad.tipoPlanillaId };
    }
    return campo;
  };

  const onClickBtnNotificar = async (form) => {
    if (valorDestinatarioFormulario === "") {
      message.error("Seleccione destinatario");
    } else if (
      valorDestinatarioFormulario === destinatarioFormulario.COLABORADOR &&
      colaboradoresAgregados.length === 0
    ) {
      message.error("No se encontraron colaboradores");
    } else if (
      valorDestinatarioFormulario === destinatarioFormulario.TODOS_LOS_COLABORADORES &&
      valorEstadoColaborador === estadoColaborador.SINESTADO
    ) {
      message.error("No ha seleccionado el estado de los colaboradores");
    } else if (valorDestinatarioFormulario === destinatarioFormulario.SEDE && sedesAgregadas.length === 0) {
      message.error("No se encontraron centros de costo");
    } else if(valorDestinatarioFormulario === destinatarioFormulario.CARGA_EXCEL_DNIS && archivoExcel.length===0){
      message.error("No ha cargado un archivo excel");
    } else {
      // if(valorDestinatarioFormulario===destinatarioFormulario.SEDE){
      //   const _colaboradoresXSedes= await FormularioNotificarService.listarColaboradoresPorEmpresaIdYSedes({
      //     empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
      //     sedesId: sedesAgregadasIds,
      //   });
      //   setColaboradoresXSedes(_colaboradoresXSedes);
      //   console.log("por sedes:",_colaboradoresXSedes);

      // }
      const _notificacion = {
        periodo: form.periodo.format("MM/YYYY"),
        descripcion: form.descripcion,
        formularioId: form.formulario,
        formularioNombre: formularios.find((f) => f.id === form.formulario).nombre,
        categoriaDocumentoId: formularios.find((f) => f.id === form.formulario).categoriaDocumento.id,
        categoriaDocumentoNombre: formularios.find((f) => f.id === form.formulario).categoriaDocumento.nombre,
        usuarioEnvio: {
          login: getUsuarioAutenticado().login,
          nombreCompleto: getUsuarioAutenticado().nombreCompleto,
        },
        organizacionId: getUsuarioAutenticado().organizacionId,
        empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
        //colaboradoresId: colaboradoresSeleccionadosId,
        colaboradoresId: colaboradoresAgregadosIds,
        carpeta: {
          id: form.carpeta.id,
          descripcion: form.carpeta.descripcion,
        },
        destinatarioFormulario:
          valorDestinatarioFormulario === destinatarioFormulario.TODOS_LOS_COLABORADORES
            ? "TODOS"
            : valorDestinatarioFormulario,
        estadoColaborador: valorEstadoColaborador,
        todosColaboradores: todosColaboradores,
        sedesId: sedesAgregadasIds,
      };
      //console.log(_notificacion)

      const formData = new FormData();
      if (valorDestinatarioFormulario === destinatarioFormulario.CARGA_EXCEL_DNIS) {
        formData.append("fileExcel", form.cargarArchivoExcel.file);
      }
      console.log("_notificacion::", _notificacion);
      formData.append(
        "formulario",
        new Blob([JSON.stringify(_notificacion)], {
          type: "application/json",
        })
      );
      setNotificacion(formData);
      //setNotificacion(_notificacion);
      //mostrarModalConfirmacion();
      setVisibleModalConfirmacion(true);
    }
  };

  const onClickBtnConfirmarModal = async () => {
    try {
      setLoading(true);
      const respuesta = await FormularioNotificarService.notificar(notificacion);
      cerrarModalConfirmacion();
      history.push(`/notificacion/formulario/seguimiento/${respuesta.idEnvio}/detalle`);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onClickBtnCancelarModal = () => {
    cerrarModalConfirmacion();
  };
  const onChangeSelectCarpeta = (value, option) => {
    frmCrearFormulario.setFieldsValue({
      carpeta: { descripcion: option.descripcion },
    });
  };
  const onChangeColaboradoresSeleccionados = (value) => {
    setColaboradorSeleccionado(value);
  };
  const onSearchColaboradores = async (value) => {
    const _respuesta = await FormularioNotificarService.listarColaboradoresPorEmpresaIdConFiltro({
      empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
      filtro: value.trim(),
      login:getUsuarioAutenticado().login,
    });
    setColaboradores(_respuesta);
    if (value === "") {
      frmCrearFormulario.setFieldsValue({
        buscarColaborador: "",
      });
      setColaboradorSeleccionado([]);
    }
  };
  const onChangeDestinatario = (value) => {
    setgutterForm(0);
    if (value === destinatarioFormulario.TODOS_LOS_COLABORADORES) {
      setIngresaColaborador(false);
      setIngresaSede(false);
      setEstadosColaboradores(true);
      setValorDestinatarioFormulario(destinatarioFormulario.TODOS_LOS_COLABORADORES);
      setTodosColaboradores(true);
      setVisibleTablaColaboradores(false);
      setVisibleTablaSedes(false);
      setColaboradorSeleccionado([]);
      setColaboradoresAgregados([]);
      setColaboradoresAgregadosIds([]);
      setValorEstadoColaborador(estadoColaborador.SINESTADO);
      setSedes([]);
      setSedeSeleccionada([]);
      setSedesAgregadas([]);
      setSedesAgregadasIds([]);
      setCargaArchivoExcel(false);
      frmCrearFormulario.setFieldsValue({
        buscarColaborador: [],
      });
      frmCrearFormulario.setFieldsValue({
        buscarSede: [],
      });
    } else if (value === destinatarioFormulario.COLABORADOR) {
      setgutterForm(50);
      setIngresaColaborador(true);
      setEstadosColaboradores(false);
      setIngresaSede(false);
      setTodosColaboradores(false);
      setValorDestinatarioFormulario(destinatarioFormulario.COLABORADOR);
      setValorEstadoColaborador(estadoColaborador.SINESTADO);
      setSedes([]);
      setSedeSeleccionada([]);
      setSedesAgregadas([]);
      setSedesAgregadasIds([]);
      setVisibleTablaSedes(false);
      setCargaArchivoExcel(false);
      frmCrearFormulario.setFieldsValue({
        buscarSede: [],
      });
    } else if (value === destinatarioFormulario.SEDE) {
      setIngresaSede(true);
      setEstadosColaboradores(false);
      setIngresaColaborador(false);
      setTodosColaboradores(false);
      setValorDestinatarioFormulario(destinatarioFormulario.SEDE);
      setValorEstadoColaborador(estadoColaborador.SINESTADO);
      setVisibleTablaColaboradores(false);
      setColaboradorSeleccionado([]);
      setColaboradoresAgregados([]);
      setColaboradoresAgregadosIds([]);
      setCargaArchivoExcel(false);
      frmCrearFormulario.setFieldsValue({
        buscarColaborador: [],
      });
      handleOnClickSede();
    }else if(value === destinatarioFormulario.CARGA_EXCEL_DNIS){
      setValorDestinatarioFormulario(destinatarioFormulario.CARGA_EXCEL_DNIS);
      setCargaArchivoExcel(true);
      setEstadosColaboradores(false);
      setIngresaColaborador(false);
      setIngresaSede(false);
      setTodosColaboradores(false);
      setValorEstadoColaborador(estadoColaborador.SINESTADO);
      setSedes([]);
      setSedeSeleccionada([]);
      setSedesAgregadas([]);
      setSedesAgregadasIds([]);
      setVisibleTablaColaboradores(false);
      setVisibleTablaSedes(false);
      setColaboradorSeleccionado([]);
      setColaboradoresAgregados([]);
      setColaboradoresAgregadosIds([]);
      frmCrearFormulario.setFieldsValue({
        buscarColaborador: [],
      });
      frmCrearFormulario.setFieldsValue({
        buscarSede: [],
      });

    }
    async function handleOnClickSede() {
      const _respuesta = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);

      setSedes(_respuesta);
    }
  };
  const onChangeEstado = (value) => {
    if (value === estadoColaborador.ACTIVO) {
      setValorEstadoColaborador(estadoColaborador.ACTIVO);
    } else if (value === estadoColaborador.CESADO) {
      setValorEstadoColaborador(estadoColaborador.CESADO);
    }
  };
  const onClikAgregarSedes = () => {
    if (sedeSeleccionada.length !== 0) {
      const sedeBuscada = sedesAgregadas.find((item) => item.id === sedeSeleccionada);
      if (sedeBuscada === undefined) {
        setSedesAgregadasIds([...sedesAgregadasIds, sedeSeleccionada]);
        const _sedeAgregada = sedes.find((sede) => sede.id === sedeSeleccionada);
        setSedesAgregadas([...sedesAgregadas, _sedeAgregada]);
        setSedeSeleccionada([]);
        setVisibleTablaSedes(true);
        frmCrearFormulario.setFieldsValue({
          SEDE: [],
        });
      } else {
        message.error("La sede ya ha sido agregada");
      }
    }
  };
  const onChangeSede = (value) => {
    setSedeSeleccionada(value);
  };
  const onSearchSedes = (value) => {
    if (value === "") {
      frmCrearFormulario.setFieldsValue({
        buscarSede: "",
      });
      setSedeSeleccionada([]);
    }
  };
  const onChangeFormulario = (value, option) => {
    const formulario = formularios.find((f) => f.id === value);
    setResponsableFirmaDocumentos(formulario.nombreRepresentanteLegal);
    setFormularioNombre(option.children);
  };

  return (
    <React.Fragment>
      <AlertCertificadoDigital />
      <Form
        name="frmNotificarFormulario"
        layout="vertical"
        labelCol={{ span: 10, offset: 8 }}
        wrapperCol={{ span: 10, offset: 8 }}
        onFinish={onClickBtnNotificar}
        form={frmCrearFormulario}
      >
        <Form.Item label="Periodo" name="periodo" rules={[{ required: true, message: "Seleccione el periodo" }]}>
          <DatePicker picker="month" format="MM/YYYY" style={{ width: "50%" }} />
        </Form.Item>

        <Form.Item
          label="Formulario"
          name="formulario"
          rules={[{ required: true, message: "Seleccione el formulario" }]}
        >
          <Select
            showSearch
            placeholder="Selecciona el formulario"
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            onChange={onChangeFormulario}
          >
            {formularios.length > 0 &&
              formularios.map((formulario) => {
                return (
                  <Select.Option key={formulario.id} value={formulario.id}>
                    {formulario.nombre + " ("+formulario.categoriaDocumento.nombre+")"}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item label="Descripción" name="descripcion">
          <Input />
        </Form.Item>

        <Form.Item label="Notificar a" name="notificarA">
          <Row
            gutter={2}
            style={{
              height:
                frmCrearFormulario.getFieldValue("destinatarioFormulario") == destinatarioFormulario.SEDE ||
                frmCrearFormulario.getFieldValue("destinatarioFormulario") == destinatarioFormulario.COLABORADOR
                  ? "8vh"
                  : "4vh",
            }}
          >
            <Col
              span={
                frmCrearFormulario.getFieldValue("destinatarioFormulario") == destinatarioFormulario.COLABORADOR ||
                frmCrearFormulario.getFieldValue("destinatarioFormulario") == destinatarioFormulario.SEDE
                  ? 11
                  : 15
              }
            >
              <Form.Item name="destinatarioFormulario">
                <Select placeholder="Seleccionar destinatario" onChange={onChangeDestinatario} style={{ width: "98%" }}>
                  <Option value={destinatarioFormulario.TODOS_LOS_COLABORADORES} key="1">
                    TODOS LOS COLABORADORES
                  </Option>
                  <Option value={destinatarioFormulario.COLABORADOR} key="2">
                    {destinatarioFormulario.COLABORADOR}
                  </Option>
                  <Option value={destinatarioFormulario.SEDE} key="3">
                    CENTRO DE COSTO
                  </Option>
                  <Option value={destinatarioFormulario.CARGA_EXCEL_DNIS} key="4">
                    COLABORADORES ARCHIVO EXCEL
                  </Option>
                </Select>
              </Form.Item>
            </Col>
            {estadosColaboradores && (
              <Col span={9}>
                <Form.Item>
                  <Select placeholder="Seleccionar estado" onChange={onChangeEstado}>
                    <Option value={estadoColaborador.ACTIVO}>{estadoColaborador.ACTIVO}</Option>
                    <Option value={estadoColaborador.CESADO}>{estadoColaborador.CESADO}</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
            {ingresaColaborador && (
              <Col span={13}>
                <Form.Item name="buscarColaborador">
                  <Select
                    placeholder="Ingrese los colaboradores"
                    showSearch
                    onSearch={onSearchColaboradores}
                    //style={{width:"100%"}}
                    onChange={onChangeColaboradoresSeleccionados}
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {colaboradores !== undefined &&
                      colaboradores.map((colaborador) => {
                        return (
                          <Select.Option key={colaborador.id} value={colaborador.id}>
                            {colaborador.nombreCompleto}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </Col>
            )}
            {
              ingresaSede &&
                listaDependencias.length > 0 &&
                listaDependencias.map((campo) => {
                  return (
                    <Col span={11}>
                      <Form.Item key={campo.campo + 1} name={campo.campo}>
                        <Select
                          placeholder={"Seleccione " + campo.etiqueta}
                          onSelect={onChangeFiltros}
                          allowClear
                          onClear={() => onChangeFiltros(null, campo.campo)}
                          options={campo.datos}
                          value={valoresSeleccionados} /*onSelect={onSelect} options={options} onDeselect={onDeselect}*/
                        />
                      </Form.Item>
                    </Col>
                  );
                })

              /*&&( 
                        <Col span={15}>                
                        <Form.Item name="buscarSede">
                          <Select placeholder="Ingrese Centro de costo"
                           showSearch
                           onChange={onChangeSede}
                           onSearch={onSearchSedes}
                           filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                           >
                          {sedes
                              .map((sede) => (
                                <Option key={sede.id} value={sede.id}>
                                  {sede.nombre}
                                </Option>
                              ))}
                          
                        </Select>                
                        </Form.Item>  
                        </Col> 
                                    
                      )*/
            }

            {ingresaSede && (
              <Col span={10}>
                <Form.Item>
                  <Select placeholder="Seleccionar estado" onChange={onChangeEstado}>
                    <Option value={estadoColaborador.ACTIVO}>{estadoColaborador.ACTIVO}</Option>
                    <Option value={estadoColaborador.CESADO}>{estadoColaborador.CESADO}</Option>
                  </Select>
                </Form.Item>
              </Col>
            )}

            {ingresaColaborador && (
              <Col span={3}>
                <Form.Item>
                  <Button onClick={onClickAgregarColaboradores}>Agregar</Button>
                </Form.Item>
              </Col>
            )}

            {ingresaSede && (
              <Col span={3}>
                <Form.Item>
                  <Button onClick={onClikAgregarSedes}>Agregar</Button>
                </Form.Item>
              </Col>
            )}
            {cargaArchivoExcel && (
              <Col span={9}>
                <Form.Item
                  name="cargarArchivoExcel"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) =>
                        archivoExcel.length !== 0
                          ? Promise.resolve()
                          : Promise.reject(new Error("Seleccione el Archivo Excel")),
                    },
                  ]}
                >
                  <Upload {...propsCargaExcel} maxCount={1} accept={FormatoCarga.EXCEL.extension}>
                    <Button icon={<UploadOutlined />} block style={{ whiteSpace: "normal", width: "100%" }}>
                      Cargar Archivo Excel&nbsp;
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            )}
          </Row>
        </Form.Item>
        {(valorEstadoColaborador === estadoColaborador.CESADO ||
          valorEstadoColaborador === estadoColaborador.ACTIVO) && (
          <React.Fragment>
            <div>
              {/* <Col span={7} offset={9}>   */}
              <Col span={10} offset={8}>
                <Text>
                  {valorEstadoColaborador === estadoColaborador.ACTIVO && (
                    <span
                      style={{
                        border: "1px solid #91d5ff",
                        backgroundColor: "#e6f7ff",
                        padding: "5px 10px 5px 10px",
                        borderRadius: "8px",
                      }}
                    >
                      Se notificará a todos los colaboradores<b> ACTIVOS</b> de la empresa
                    </span>
                  )}
                  {valorEstadoColaborador === estadoColaborador.CESADO && (
                    <span
                      style={{
                        border: "1px solid #91d5ff",
                        backgroundColor: "#e6f7ff",
                        padding: "5px 10px 5px 10px",
                        borderRadius: "8px",
                      }}
                    >
                      Se notificará a todos los colaboradores<b> CESADOS</b> de la empresa
                    </span>
                  )}
                </Text>
              </Col>
            </div>
            <br />
          </React.Fragment>
        )}

        {visibleTablaColaboradores && (
          <Form.Item>
            <TablaColaboradoresSeleccionados />
          </Form.Item>
        )}
        {visibleTablaSedes && (
          <Form.Item>
            <TablaSedesSeleccionadas />
          </Form.Item>
        )}

        {/* <Form.Item
          label="Colaboradores"
          name="colaboradores"
          extra={<TablaColaboradoresSeleccionados />}
          rules={[
            {
              validator: async (_, _colaboradores) => {
                if (
                  !colaboradoresSeleccionadosId ||
                  colaboradoresSeleccionadosId?.length <= 0
                ) {
                  return Promise.reject(
                    new Error("Seleccione al menos 1 colaborador")
                  );
                } else {
                  return Promise.resolve();
                }
              },
            },
          ]}
        >
          <Button onClick={onClickAgregarColaboradores}>
            <UploadOutlined /> Agregar/Quitar Colaboradores
          </Button>
        </Form.Item> */}

        <Form.Item label="Carpeta" name={["carpeta", "id"]}>
          <Select onChange={onChangeSelectCarpeta} optionLabelProp="label" placeholder="Seleccione la carpeta">
            {carpetas.length > 0 &&
              carpetas.map((carpeta) => {
                return (
                  <Option
                    key={carpeta.id}
                    value={carpeta.id}
                    label={
                      <div>
                        <FolderTwoTone style={{ fontSize: "20px", color: "#F7D358" }} twoToneColor="#F7D358" />{" "}
                        {carpeta.ruta}
                      </div>
                    }
                    descripcion={carpeta.descripcion}
                  >
                    <Space size={carpeta.nivel * 10}>
                      <span></span>
                      <span>
                        {carpeta.nivel === 0 ? (
                          <FolderFilled style={{ fontSize: "20px", color: "#F7D358" }} />
                        ) : (
                          <FolderTwoTone style={{ fontSize: "18px", color: "#F7D358" }} twoToneColor="#F7D358" />
                        )}{" "}
                        {carpeta.descripcion}
                      </span>
                    </Space>
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item name={["carpeta", "descripcion"]} noStyle>
          <Input type="hidden" />
        </Form.Item>

        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            block
            style={{ paddingLeft: "30px", paddingRight: "30px" }}
          >
            Notificar
          </Button>
        </Form.Item>
      </Form>

      <ModalConfirmar
        loading={loading}
        mensaje="¿Está seguro de notificar el formulario?"
        onClickBtnCancelar={onClickBtnCancelarModal}
        onClickBtnConfirmar={onClickBtnConfirmarModal}
      />
      <ModalConfirmacion
        tipoDocumento={formularioNombre}
        descripcion={frmCrearFormulario.getFieldValue("descripcion")}
        periodo={notificacion.periodo}
        tipoEnvio={valorDestinatarioFormulario}
        responsableFirmaDocumentos={responsableFirmaDocumentos}
        formulario={notificacion}
      />
    </React.Fragment>
  );
};

export default Formulario;
