import React,{useState} from "react";
import {Form,Select,Row,Col,DatePicker, Button} from "antd";
import ColaboradorService from "services/ColaboradorService";
import{filtroColaboradorAtom,filtroFechaFinAtom} from "../../SeguimientoContratosStore";
import { useAtom } from "jotai";

const FiltroSeguimientoContrato=()=>{

    const [colaboradores,setColaboradores]=useState(undefined);
    const [filtroColaborador,setfiltroColaborador] = useAtom(filtroColaboradorAtom);
    const [filtroFechaFin,setFiltroFechaFin] = useAtom(filtroFechaFinAtom);

    const[formFiltro] = Form.useForm();

    const onSearchColaboradores = async (value)=>{
        
        if (value === "") {
            setColaboradores([]);
            return;
          }
          const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
            filtro: value.trim(),
          });
          setColaboradores(respuesta.colaboradores);
        };
     const onChangeColaborador=(value)=>{         
                    
            setfiltroColaborador(value);
          }  
     const onChangeMeses=(date,dateString)=>{
                                                     
          setFiltroFechaFin(dateString);
     
         }         
     const  onClickFiltrar=()=>{        
       setfiltroColaborador(formFiltro.getFieldValue("colaborador"));       
       setFiltroFechaFin((formFiltro.getFieldValue("meses")!==undefined && formFiltro.getFieldValue("meses")!==null) ? formFiltro.getFieldValue("meses").format("MM/YYYY"):"");
      
     }
    return(
        <Form
           layout="inline" 
           form={formFiltro}
        >           
                <Form.Item label="Colaborador" name="colaborador" >
                    <Select
                        style={{ width: "350px" }}
                        allowClear
                        showSearch
                        placeholder="Ingrese el nombre del colaborador"
                        onSearch={onSearchColaboradores}                                              
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}                        
                       // onChange={onChangeColaborador}
                    >
                         {colaboradores!==undefined && colaboradores?.map((colaborador)=>(                       
                            <Select.Option key={colaborador.numeroDocumentoIdentidad} value={colaborador.numeroDocumentoIdentidad}>
                                {colaborador.nombreCompleto}
                            </Select.Option>                      
                        
                     ))}
                    </Select>

                </Form.Item>
               
                    <Form.Item label="Fecha fin de contrato" name="meses">
                    <DatePicker 
                        picker="month" 
                        format={"MM/YYYY"} 
                       // onChange={onChangeMeses}
                       />
                    </Form.Item>
                    <Button onClick={onClickFiltrar}>Filtrar</Button>
               
        </Form>
    )

}
export default FiltroSeguimientoContrato;