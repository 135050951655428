import { BaseService } from "./BaseService";

const ContratoEnvioService = {

  async verPDF(fd) {
    const respuesta = await BaseService.post("/contrato/envio/verDocumento", fd, {
      responseType: "blob",
    });
    const data = respuesta.data;
    return data;
  },
  async crear(contrato) {
    const respuesta = await BaseService.post("/contrato/envio/crearEnvio", contrato);

    return respuesta.data;
  },
  async notificar(envio) {
    const respuesta = await BaseService.post("/contrato/envio/procesarEnvio", envio);
    return respuesta.data;
  },
  async listarEnvio(empresaId) {
    const respuesta = await BaseService.get("/contrato/envio/listar", {
      params: { empresaId: empresaId },
    });
    return respuesta.data;
  },
};
export default ContratoEnvioService;
