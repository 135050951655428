import React, {useEffect,useContext} from "react";
import {Table} from "antd";
import { FirmaHolografaService } from "services/FirmaHolografaService";
import { firmasHolografasAtom } from "../ListarFirmaHolografaStore";
import { useAtom } from "jotai";
import {VerFirmaHolografa} from "./VerFirmaHolografa";
import { MenuAcciones } from "./MenuAcciones";
import { SecurityContext } from "context/SecurityContextProvider";


const TablaFirmaHolografa =()=>{
    const [firmasHolografas,setFirmasHolografas]=useAtom(firmasHolografasAtom);
    const {getUsuarioAutenticado}=useContext(SecurityContext);
    

  useEffect(()=>{
       const cargarDatos= async()=>{
            //const _firmasHolografas= await FirmaHolografaService.listar(firmasHolografasAtom);
            const _firmasHolografas= await FirmaHolografaService.listar(getUsuarioAutenticado().id,getUsuarioAutenticado().empresaSeleccionada.id);
            console.log("firmaHolografaListada:",_firmasHolografas);           
             setFirmasHolografas(_firmasHolografas);
            
       }

   cargarDatos();

  },[]);

    const columnasFirmaHolografa=[
        {
         title:"Empresa",
         dataIndex:["empresa","razonSocial"],
        // key:"empresaId"   
        },
        {
            title:"Firma Hológrafa",
           // dataIndex:"firmaHolografa",
            key:"firmaHolografa",       
            render:(fila)=>(<VerFirmaHolografa firma={fila}/>)
        },
        {
            title:"Acciones",
            key:"acciones",
            render:(fila)=>(<MenuAcciones firma={fila}/>)
        }
    ]

    return(
        <Table
            rowKey={firma=>firma.id}
            columns={columnasFirmaHolografa}            
            dataSource={firmasHolografas}
        />
    )
}
export default TablaFirmaHolografa;