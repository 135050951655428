import React, { useContext, useState,useEffect } from "react";
import { Form, Input, Button, Checkbox, Select,Row,Col,Collapse } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useModalConfirmar } from "store/global"
import { SecurityContext } from "context/SecurityContextProvider";
import * as atoms from "../CrearTipoDocumentosStore";
import { useAtom } from "jotai";
import { useHistory } from "react-router";
import { findRenderedDOMComponentWithClass } from "react-dom/test-utils";
import { CategoriaDocumento } from "../../../../../enums/CategoriaDocumento";
import {Flujo} from "../../../../../enums/Flujo";
import {FormatoCarga} from "../../../../../enums/FormatoCarga";
import {IdentificadorJson} from "../../../../../enum/IdentificadorJson";
import CertificadoService from "../../../../../services/CertificadoService";
import TipoDocumentoService from "../../../../../services/TipoDocumentoService";
import UsuarioService from "../../../../../services/UsuarioService";

const TipoDocumentoRegistro = () =>{
    let history = useHistory();
    const { Option } = Select;
    const { Panel } = Collapse;

    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [, setTipoDocumento] = useAtom(atoms.tipoDocumento);
    const mostrarModalConfirmacion = useModalConfirmar(state => state.mostrarModal);
    const [certificados,setCertificados]=useState([]);
    const [categoriaIdSeleccionada,setCategoriaIdSeleccionada]=useState([]);
    const[categoriaNombreSeleccionada,setCategoriaNombreSeleccionada]=useState([]);
    const [formatoIdSeleccionado,setFormatoIdSeleccionado]=useState([]);
    const [formatoNombreSeleccionado,setFormatoNombreSeleccionado]=useState([]);
    const [formatoDesSeleccionado,setFormatoDesSeleccionado]=useState([]);
    const[flujo,setFlujo]=useState("");
    const [usuariosAprobadores,setUsuariosAprobadores]=useState([]);
    useEffect(() => {
        const fetch = async () => {
            try {
                 const certificados = await CertificadoService.listar(getUsuarioAutenticado().empresaSeleccionada.id,"",-1);
                 setCertificados(certificados);
                 const _usuarios=await UsuarioService.listarUsuariosAProbadores(getUsuarioAutenticado().empresaSeleccionada.id);
                 setUsuariosAprobadores(_usuarios);
                                 
            } catch (error) {
                console.error(error);
            }
           
        }     
            
        fetch();
    }, []);
    const onClickBtnCrearTipoDocumento=(tipoDocumento)=>{
            setTipoDocumento({
                ...tipoDocumento,
                empresaId:getUsuarioAutenticado().empresaSeleccionada.id,
                categoriaDocumento:
                    {
                        id:categoriaIdSeleccionada,
                        nombre:categoriaNombreSeleccionada,
                    },
                formato:
                {
                    id:formatoIdSeleccionado,
                    nombre:formatoNombreSeleccionado,
                    descripcion:formatoDesSeleccionado,
                },                       

            });
            console.log("TD",tipoDocumento);
           mostrarModalConfirmacion();
    }
    const onSelectCategoria=(value,option)=>{
        setCategoriaIdSeleccionada(value);
        setCategoriaNombreSeleccionada(option.children);
       // console.log("Ca",categoriaNombreSeleccionada);
        
    }
    const onSelectFormato=(value,option)=>{
        setFormatoIdSeleccionado(value);
        setFormatoNombreSeleccionado(option.children);
        setFormatoDesSeleccionado(option.des);
        //console.log("For",formatoDesSeleccionado);
    }
    const onSelectFlujo=(value)=>{
        setFlujo(value);
    }
    return(
        <Form
        layout="vertical"
        // labelCol={{ span: 10 }}
        // wrapperCol={{ span: 6 }}
        size="middle"
        onFinish={onClickBtnCrearTipoDocumento}
        >
            <Form.Item
            label="Categoría"
            name="categoriaDocumento"
            rules={[
                {
                    required: true,
                    message: "El campo Categoría es obligatorio.",
                },
            ]}           
            >
                <Select onSelect={onSelectCategoria}>
                    <Option key={CategoriaDocumento.DOCUMENTO_LABORAL.id} value={CategoriaDocumento.DOCUMENTO_LABORAL.id} >
                          {CategoriaDocumento.DOCUMENTO_LABORAL.nombre}
                    </Option>
                    <Option key={CategoriaDocumento.COMUNICADOS.id} value={CategoriaDocumento.COMUNICADOS.id} >
                          {CategoriaDocumento.COMUNICADOS.nombre}
                    </Option>
                    <Option key={CategoriaDocumento.CONTRATOS.id} value={CategoriaDocumento.CONTRATOS.id} >
                          {CategoriaDocumento.CONTRATOS.nombre}
                    </Option>

                </Select>            

            </Form.Item>
            <Form.Item
                label="Nombre Documento"
                name="nombre"
                rules={[
                    {
                        required: true,
                        message: "El campo nombre es obligatorio.",
                    },
                ]}
                
            >
                <Input/>

            </Form.Item>
            <Form.Item
               label="Flujo"
               name="flujo"
                rules={[
                    {
                        required: true,
                        message: "El campo Flujo es obligatorio.",
                    },
                ]}
            >
                <Select onSelect={onSelectFlujo}>
                    <Option key={Flujo.SIN_APROBACION.valor}value={Flujo.SIN_APROBACION.valor}>
                        {Flujo.SIN_APROBACION.nombre}
                    </Option>
                    <Option key={Flujo.CON_APROBACION.valor} value={Flujo.CON_APROBACION.valor}>
                       {Flujo.CON_APROBACION.nombre}
                    </Option>
                </Select>

            </Form.Item>
            {/* {
                flujo===Flujo.CON_APROBACION.valor &&(
                    <React.Fragment>
                       <Form.Item
                           label="Usuario"
                           name="usuario"
                           rules={[
                                    {
                                        required: true,
                                        message: "El campo Usuario es obligatorio.",
                                    },
                                ]}            
                            >
                                <Select>
                                { usuariosAprobadores.length>0 && usuariosAprobadores.map((usuarioAprobador)=>{

                                    return(
                                            <Select.Option
                                            key={usuarioAprobador.id}
                                            value={usuarioAprobador.id}
                                            >
                                                {usuarioAprobador.nombreCompleto}        
                                            </Select.Option> 

                                       );
                                 })
                                }

                                </Select>
                                
                        </Form.Item>
                    </React.Fragment>
                )

            }
             */}
            <Form.Item
                    label="Certificado Digital"
                    name="certificadoDigitalId"
                     rules={[
                         {
                             required: true,
                             message: "El campo Certificado Digital es obligatorio.",
                         },
                     ]}
            >
                <Select>
                    { certificados.length>0 &&
                      certificados.map((certificado)=>{

                        return(
                            <Select.Option key={certificado.id}
                               value={certificado.id}
                            >
                                {certificado.representanteLegalNombre}        
                           </Select.Option> 

                        );
                      })
                    }
                       
                </Select>
            </Form.Item>  
            <Form.Item
              label="Solicitar Firma del Colaborador"
              name="firmaColaboradorRequerido"
               rules={[
                   {
                       required: true,
                       message: "El campo Firma del colaborador es obligatorio.",
                   },
               ]}
            >
                <Select>
                    <Option key="true" value="true">
                        Si
                    </Option>
                    <Option key="false" value="false">
                        No
                    </Option>
                </Select>

            </Form.Item>  
            <Form.Item
                 label="Formato"
                 name="formato"
                  rules={[
                      {
                          required: true,
                          message: "El campo Formato es obligatorio.",
                      },
                  ]}
            >
                <Select onSelect={onSelectFormato}>
                    <Option key={FormatoCarga.PDF_MULTIPAGINA.id} value={FormatoCarga.PDF_MULTIPAGINA.id} des={FormatoCarga.PDF_MULTIPAGINA.descripcion}>
                            {FormatoCarga.PDF_MULTIPAGINA.nombre}
                    </Option>
                    <Option key={FormatoCarga.EXCEL.id} value={FormatoCarga.EXCEL.id} des={FormatoCarga.EXCEL.descripcion}>
                        {FormatoCarga.EXCEL.nombre}
                    </Option> 
                </Select>              

            </Form.Item>   
            {
                    formatoIdSeleccionado===FormatoCarga.PDF_MULTIPAGINA.id &&(
                    <React.Fragment>
                    <Collapse defaultActiveKey={['1']}>
                        <Panel header="Ubicación de Colaborador:" key="1">
                            <Form.Item
                                label="Ubicar Por:"
                                name="identificadorJson"
                                rules={[
                                    {
                                        required: true,
                                        message: "El campo ubicación es obligatorio.",
                                    },
                                ]}
                                
                            >
                                <Select>
                                    <Option key={IdentificadorJson.JSON_CAMPO_CODIGO_COLABORADOR} value={IdentificadorJson.JSON_CAMPO_CODIGO_COLABORADOR}>
                                      {IdentificadorJson.JSON_CAMPO_CODIGO_COLABORADOR}
                                    </Option>
                                    <Option key={IdentificadorJson.JSON_CAMPO_NRO_DOCUMENTO} value={IdentificadorJson.JSON_CAMPO_NRO_DOCUMENTO}>
                                       {IdentificadorJson.JSON_CAMPO_NRO_DOCUMENTO}
                                    </Option>                                    
                                </Select>

                            </Form.Item>
                            <Row>
                            <Input.Group size="small">   
                         
                                        <Row gutter={5}>                    
                                            <Col span={2}>
                                                <Form.Item 
                                                    name="busquedaRangoLlX"
                                                    label="X"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "El campo X es obligatorio.",
                                                        },
                                                    ]}
                                                    >
                                                <Input/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <Form.Item 
                                                name="busquedaRangoLlY"
                                                label="Y"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "El campo Y es obligatorio.",
                                                    },
                                                ]}
                                                >
                                                <Input/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <Form.Item 
                                                name="busquedaRangoUrX"
                                                label="Ancho"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "El campo Ancho es obligatorio.",
                                                    },
                                                ]}
                                                >
                                                <Input/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <Form.Item 
                                                name="busquedaRangoUrY" 
                                                label="Alto"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "El campo Alto es obligatorio.",
                                                    },
                                                ]}
                                                >
                                                <Input/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                </Input.Group>
                            </Row>
                        </Panel>
                        <Panel header="Firma Hológrafa" key="2">
                        <Input.Group size="small">                               
                            <Row gutter={5}>
                                <Col span={2}><Form.Item name={["firmaHolografa","posicionX"]} label="X"><Input/></Form.Item></Col>
                                <Col span={2}><Form.Item name={["firmaHolografa","posicionY"]}label="Y"><Input/></Form.Item></Col>
                                <Col span={2}><Form.Item name={["firmaHolografa","ancho"]} label="Ancho"><Input/></Form.Item></Col>
                                <Col span={2}><Form.Item name={["firmaHolografa","alto"]}label="Alto"><Input/></Form.Item></Col>
                            </Row>
                        </Input.Group>                           

                        </Panel>
                        <Panel header="Ubicación QR" key="3">
                        <Input.Group size="small">                              
                            <Row gutter={5}>
                                <Col span={2}><Form.Item name={["qr","posicionX"]} label="X"><Input/></Form.Item></Col>
                                <Col span={2}><Form.Item name={["qr","posicionY"]}label="Y"><Input/></Form.Item></Col>
                                <Col span={2}><Form.Item name={["qr","ancho"]} label="Ancho"><Input/></Form.Item></Col>
                                <Col span={2}><Form.Item name={["qr","alto"]}label="Alto"><Input/></Form.Item></Col>
                            </Row>
                        </Input.Group>
                        </Panel>
                        <Panel header="Logotipo" key="4">
                        <Input.Group size="small">                                
                            <Row gutter={5}>
                                <Col span={2}><Form.Item name={["logotipo","posicionX"]} label="X"><Input/></Form.Item></Col>
                                <Col span={2}><Form.Item name={["logotipo","posicionY"]}label="Y"><Input/></Form.Item></Col>
                                <Col span={2}><Form.Item name={["logotipo","ancho"]} label="Ancho"><Input/></Form.Item></Col>
                                <Col span={2}><Form.Item name={["logotipo","alto"]} label="Alto"><Input/></Form.Item></Col>
                            </Row>
                        </Input.Group>                        
                        </Panel>
                    </Collapse >
                             
                    </React.Fragment>
                    )
                }                 
          
            <br></br>    
            <Form.Item /*style={{ textAlign: "center" }}*/ wrapperCol={{ span: 24, offset: 10 }}>
                <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    htmlType="submit"
                >
                    Crear Tipo Documento
                </Button>
            </Form.Item>


        </Form>
    )

}
export default TipoDocumentoRegistro;