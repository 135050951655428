import { Checkbox, Col, DatePicker, Form, Input, Modal, Row, Select,Divider,Typography } from "antd";
import { SecurityContext } from "context/SecurityContextProvider";
import React, { useEffect, useState, useContext } from "react";
import { FormularioNotificarService } from "services/FormularioNotificarService";
import moment from "moment/moment";
import { EntrevistaService } from "services/reclutamiento-y-seleccion/EntrevistaService";
import { useAtom } from "jotai";
import { entrevistaAtom } from "./ConvocatoriasStore";

const ProgramarEntrevista = ({ modalProgramar, setModalProgramar, postulante, convocatoria }) => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [entrevistas, setEntrevistas] = useAtom(entrevistaAtom);
  const [formEntrevista] = Form.useForm();
  const [colaboradores, setColaboradores] = useState(undefined);

  useEffect(() => {
    if (postulante) {
      formEntrevista.setFieldsValue({
        postulante: postulante.nombreCompleto,
        postulanteId: postulante.postulanteId,
      });
    }
  }, [postulante]);

  const onSearchColaboradores = async (value) => {
    const _respuesta = await FormularioNotificarService.listarColaboradoresPorEmpresaIdConFiltro({
      empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
      filtro: value.trim(),
      login: getUsuarioAutenticado().login,
    });
    setColaboradores(_respuesta);
    if (value === "") {
      formEntrevista.setFieldsValue({
        entrevistador: "",
      });
    }
  };

  const onSelectEntrevistador = (value) => {
    const selectedObject = colaboradores.find((opt) => opt.id === value);
    if (selectedObject) {
      formEntrevista.setFieldsValue({
        entrevistador: {
          correoElectronico: selectedObject.correoElectronico,
          numeroDocumentoIdentidad: selectedObject.numeroDocumentoIdentidad,
          nombreCompleto: selectedObject.nombreCompleto,
        },
      });
    }
  };

  const deshabilitadorDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const onFinishProgramar = (formulario) => {
    const fechaProgramada = formulario.fechaHoraProgramada;
    formulario.fechaHoraInicio = fechaProgramada.format("DD/MM/YYYY HH:mm");
    formulario.fechaHoraFin = fechaProgramada.add(formulario.duracion, "minutes").format("DD/MM/YYYY HH:mm");
    formulario.postulante = {
      id: postulante.postulanteId,
      nombreCompleto: postulante.nombreCompleto,
      numeroDocumentoIdentidad: postulante.numeroDocumentoIdentidad,
    };

    EntrevistaService.insertar(formulario);
    if (entrevistas) {
      const _entrevista = [...entrevistas];
      _entrevista.unshift({
        id: moment(),
        entrevistador: {
          nombreCompleto: formulario.entrevistador.nombreCompleto,
        },
        fechaHoraProgramada: fechaProgramada.format("DD/MM/YYYY HH:mm a"),
        duracion: formulario.duracion,
      });
      setEntrevistas(_entrevista);
    }
    setModalProgramar(false);
  };

  return (
    <Modal
      title="Programar Entrevista"
      open={modalProgramar}
      onOk={() => formEntrevista.submit()}
      onCancel={() => {
        setModalProgramar(false);
      }}
      destroyOnClose
    >
      <Form
        form={formEntrevista}
        layout="vertical"
        size="middle"
        style={{ padding: "0 15px" }}
        onFinish={onFinishProgramar}
        initialValues={{
          ...convocatoria,
          organizacionId: getUsuarioAutenticado().empresaSeleccionada.id,
          empresaId: getUsuarioAutenticado().organizacionId,
          indicadorRegistrarCompetencia: true,
          indicadorAdjuntarArchivos: true,
          indicadorExperienciaLaboral: true,
          indicadorMostrarPreguntas: true,
          indicadorCargaHojaVida:true,
          indicadorCargaPsicotest:true,
          indicadorCargaEvaluacionConocimiento:true,
        }}
      >
        <Form.Item name="solicitudPersonalId" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="convocatoriaId" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="organizacionId" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="empresaId" noStyle>
          <Input type="hidden" />
        </Form.Item>
        {/* <Form.Item name="tipoEntrevistador" noStyle>
              <Input type="hidden" />
            </Form.Item> */}
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={"Puesto"}
              name={["puesto", "nombre"]}
              rules={[{ required: true, message: "Ingrese el puesto." }]}
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item name={["puesto", "id"]} noStyle>
              <Input type="hidden" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={"Postulante"}
              name={"postulante"}
              rules={[{ required: true, message: "Ingrese el postulante." }]}
            >
              <Input readOnly />
            </Form.Item>
            <Form.Item name="postulanteId" noStyle>
              <Input type="hidden" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={"Entrevistador"}
          name={["entrevistador", "id"]}
          rules={[{ required: true, message: "Ingrese el entrevistador" }]}
        >
          <Select
            placeholder="Ingrese el entrevistador"
            showSearch
            onSelect={onSelectEntrevistador}
            onSearch={onSearchColaboradores}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {colaboradores !== undefined &&
              colaboradores.map((colaborador) => {
                return (
                  <Select.Option key={colaborador.id} value={colaborador.id}>
                    {colaborador.nombreCompleto}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item name={["entrevistador", "nombreCompleto"]} noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name={["entrevistador", "correoElectronico"]} noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name={["entrevistador", "numeroDocumentoIdentidad"]} noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label={"Duracion Entrevista"}
              name={"duracion"}
              rules={[
                {
                  required: true,
                  message: "Seleccione la duración de la entrevista.",
                },
              ]}
            >
              <Select>
                <Select.Option value={15}>15 Minutos</Select.Option>
                <Select.Option value={30}>30 Minutos</Select.Option>
                <Select.Option value={45}>45 Minutos</Select.Option>
                <Select.Option value={60}>60 Minutos</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={"Fecha y Hora"}
              name={"fechaHoraProgramada"}
              rules={[{ required: true, message: "Ingrese la Fecha." }]}
            >
              <DatePicker
                format="DD/MM/YYYY h:mm a"
                disabledDate={deshabilitadorDate}
                //disabledTime={disabledDateTime}
                showTime={{
                  defaultValue: moment("00:00", "h:mm A"),
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name="indicadorRegistrarCompetencia" valuePropName="checked">
              <Checkbox>¿Evaluar competencia?</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="indicadorExperienciaLaboral" valuePropName="checked">
              <Checkbox>¿Permitir registrar experiencia laboral?</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          {/* <Col span={12}>
            <Form.Item name="indicadorAdjuntarArchivos" valuePropName="checked">
              <Checkbox>¿Permitir subir archivos adjuntos?</Checkbox>
            </Form.Item>
          </Col> */}
          <Col span={12}>
            <Form.Item name="indicadorMostrarPreguntas" valuePropName="checked">
              <Checkbox>¿Mostrar preguntas de evaluación?</Checkbox>
            </Form.Item>
          </Col>
        </Row>       
        <Divider/>        
        <Row><Typography.Text style={{fontWeight:"bold"}}>Documentos Adjuntos</Typography.Text></Row>
        <br/>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name="indicadorCargaHojaVida" valuePropName="checked">
              <Checkbox>¿Permitir cargar hoja de vida?</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="indicadorCargaPsicotest" valuePropName="checked">
              <Checkbox>¿Permitir cargar psicotest?</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item name="indicadorCargaEvaluacionConocimiento" valuePropName="checked">
               <Checkbox>¿Permitir cargar evaluación de conocimiento?</Checkbox>
            </Form.Item >
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ProgramarEntrevista;
