import React, { useEffect, useState, useContext } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  Upload,
  Space,
  Divider,
  Row,
  Col,
} from "antd";
import {
  CheckOutlined,
  UploadOutlined,
  FolderTwoTone,
  FolderFilled,
} from "@ant-design/icons";
import { useModalConfirmar } from "store/global";
import { SecurityContext } from "context/SecurityContextProvider";
import * as atoms from "../CrearFormulariosStore";
import { useAtom } from "jotai";
import { CategoriaDocumento } from "enums/CategoriaDocumento";
import CertificadoService from "services/CertificadoService";
import CarpetaService from "services/CarpetaService";
import ColaboradorService from "services/ColaboradorService";
import UsuarioService from "services/UsuarioService";

const FormularioRegistro = () => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [formTipoDocumento] = Form.useForm();

  const [, setFormulario] = useAtom(atoms.formulario);
  const mostrarModalConfirmacion = useModalConfirmar((state) => state.mostrarModal);

  const [certificados, setCertificados] = useState([]);
  const [carpetas, setCarpetas] = useState([]);
  const [requiereFirmaRepresentanteLegal, setRequiereFirmaRepresentanteLegal] = useState(false);
  const [nombreRepresentanteLegal, setNombreRepresentanteLegal] = useState(undefined);
  const [tipoDocumentoSeleccionado, setTipoDocumentoSeleccionado] = useState(undefined);
  const [uploadFormat, setUploadFormat] = useState(false);
  const [aprobadores, setAprobadores] = useState([]);
  const [responsables, setResponsables] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const requiereAprobacion = Form.useWatch('requiereAprobacion', formTipoDocumento);
  const documentoInterno = Form.useWatch('interno', formTipoDocumento);

  const { Option } = Select;

  useEffect(() => {
    const fetch = async () => {
      try {
        const certificados = await CertificadoService.listar("", "", -1);
        setCertificados(certificados);

        const _carpetas = await CarpetaService.listar(
          getUsuarioAutenticado().empresaSeleccionada.id
        );
        setCarpetas(_carpetas);

        const aprobadores = await UsuarioService.listar();
        setAprobadores(aprobadores.map((aprobador) => {
          return {
            value: aprobador.id,
            label: aprobador.nombreCompleto
          }
        }));

        const responsables = await UsuarioService.listar();
        setResponsables(responsables.map((responsable) => {
          return {
            value: responsable.id,
            label: responsable.nombreCompleto
          }
        }));

      } catch (error) {
        console.error(error);
      }
    };
    fetch();
  }, []);

  const onClickBtnCrearFormulario = (formulario) => {
    if (requiereFirmaRepresentanteLegal) {
      formulario.nombreRepresentanteLegal = nombreRepresentanteLegal;
    }
    formulario.organizacionId = getUsuarioAutenticado().organizacionId;
    formulario.empresaId = getUsuarioAutenticado().empresaSeleccionada.id;
    formulario.usuarioCreacionId = getUsuarioAutenticado().id;
    formulario.usuarioCreacionNombre = getUsuarioAutenticado().nombreCompleto;
    formulario.categoriaDocumento = tipoDocumentoSeleccionado;
    formulario.carpeta = {
      id: formulario.carpeta.id,
      descripcion: formulario.carpeta.descripcion,
    };
    const formData = new FormData();
    if (formulario.fileTemplate != null && formulario.fileTemplate.length === 1)
      formData.append(
        "plantillaCarga",
        formulario.fileTemplate[0].originFileObj
      );
    formData.append(
      "tipoDocumentoRequest",
      new Blob([JSON.stringify({ ...formulario, fileTemplate: undefined })], {
        type: "application/json",
      })
    );
    setFormulario(formData);
    mostrarModalConfirmacion();
  };

  const onSeleccionarCertificado = (value, option) => {
    setNombreRepresentanteLegal(option.children);
  };

  const onChangeTipoDocumento = (value, option) => {
    const categoria = {
      id: option.value,
      nombre: option.children,
    };
    setTipoDocumentoSeleccionado(categoria);

    if (categoria?.id === CategoriaDocumento.CARGA_DOCUMENTO.id) {
      formTipoDocumento.setFieldsValue({
        requiereFirmaColaborador: false,
        requiereFirmaRepresentanteLegal: false,
      });
      setRequiereFirmaRepresentanteLegal(false);
    }

    if (categoria?.id !== CategoriaDocumento.CARGA_DOCUMENTO.id) {
      formTipoDocumento.setFieldsValue({
        requierePlantilla: false,
      });
      setUploadFormat(false);
      formTipoDocumento.setFieldValue("fileTemplate", undefined);
    }

    if (categoria?.id == CategoriaDocumento.COMUNICADOS_FORMULARIOS.id) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  };

  useEffect(() => {
    if (!requiereFirmaRepresentanteLegal) {
      setNombreRepresentanteLegal(null);
    }
  }, [requiereFirmaRepresentanteLegal]);

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onChangeCargaFormato = (e) => {
    if (!e.target.checked)
      formTipoDocumento.setFieldValue("fileTemplate", undefined);
    setUploadFormat(e.target.checked);
  };
  const onChangeSelectCarpeta = (value, option) => {
    formTipoDocumento.setFieldsValue({
      carpeta: { descripcion: option.descripcion },
    });
  };

  return (
    <Form
      layout="vertical"
      size="large"
      form={formTipoDocumento}
      initialValues={{
        requiereAprobacion: false,
        mostrarEnPortalColaborador: false,
        requiereFirmaColaborador: false,
      }}
      onFinish={onClickBtnCrearFormulario}
    >
      <Form.Item
        label="Tipo Documento"
        name="tipoDocumento"
        rules={[
          {
            required: true,
            message: "El campo Tipo documento es obligatorio.",
          },
        ]}
      >
        <Select
          placeholder="Seleccione tipo documento"
          onChange={onChangeTipoDocumento}
        >
          <Select.Option value={CategoriaDocumento.FORMULARIOS.id}>
            {CategoriaDocumento.FORMULARIOS.nombre}
          </Select.Option>
          <Select.Option value={CategoriaDocumento.CARGA_DOCUMENTO.id}>
            {CategoriaDocumento.CARGA_DOCUMENTO.nombre}
          </Select.Option>
          <Select.Option value={CategoriaDocumento.FORMATO_AUSENCIA.id}>
            {CategoriaDocumento.FORMATO_AUSENCIA.nombre}
          </Select.Option>
          <Select.Option value={CategoriaDocumento.COMUNICADOS_FORMULARIOS.id}>
            {CategoriaDocumento.COMUNICADOS_FORMULARIOS.nombre}
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Nombre"
        name="nombre"
        rules={[
          {
            required: true,
            message: "El campo nombre es obligatorio.",
          },
        ]}
      >
        <Input autoComplete="off" />
      </Form.Item>
      <Form.Item label="Carpeta" name={["carpeta", "id"]}>
        <Select
          onChange={onChangeSelectCarpeta}
          optionLabelProp="label"
          placeholder="Seleccione la carpeta"
        >
          {carpetas.length > 0 &&
            carpetas.map((carpeta) => {
              return (
                <Option
                  key={carpeta.id}
                  value={carpeta.id}
                  label={
                    <div>
                      <FolderTwoTone
                        style={{ fontSize: "20px", color: "#F7D358" }}
                        twoToneColor="#F7D358"
                      />{" "}
                      {carpeta.ruta}
                    </div>
                  }
                  descripcion={carpeta.descripcion}
                >
                  <Space size={carpeta.nivel * 10}>
                    <span></span>
                    <span>
                      {carpeta.nivel === 0 ? (
                        <FolderFilled
                          style={{ fontSize: "20px", color: "#F7D358" }}
                        />
                      ) : (
                        <FolderTwoTone
                          style={{ fontSize: "18px", color: "#F7D358" }}
                          twoToneColor="#F7D358"
                        />
                      )}{" "}
                      {carpeta.descripcion}
                    </span>
                  </Space>
                </Option>
              );
            })}
        </Select>
      </Form.Item>
      <Form.Item name={["carpeta", "descripcion"]} noStyle>
        <Input type="hidden" />
      </Form.Item>

      <div style={{ fontWeight: "600", marginBottom: "10px" }}>
        Configuración
      </div>
      <Divider />
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item>
            <Form.Item
              name="mostrarEnPortalColaborador"
              valuePropName="checked"
            >
              <Checkbox disabled={isDisabled}>
                <span style={{ display: "block" }}>
                  Mostrar en portal del colaborador
                </span>
                <span style={{ color: "gray" }}>
                  Formulario estará siempre disponible para el colaborador.
                </span>
              </Checkbox>
            </Form.Item>

            <Form.Item name="requiereFirmaColaborador" valuePropName="checked">
              <Checkbox
                disabled={
                  tipoDocumentoSeleccionado?.id ===
                    CategoriaDocumento.CARGA_DOCUMENTO.id
                    ? true
                    : isDisabled
                }
              >
                <span style={{ display: "block" }}>
                  Requiere Firma Colaborador
                </span>
                <span style={{ color: "gray" }}>
                  Formulario debe tener la firma del colaborador.
                </span>
              </Checkbox>
            </Form.Item>

            <Form.Item name="requiereAprobacion" valuePropName="checked">
              <Checkbox disabled={isDisabled}>
                <span style={{ display: "block" }}>Requiere Aprobación</span>
                <span style={{ color: "gray" }}>
                  Formulario requiere aprobación.
                </span>
              </Checkbox>
            </Form.Item>

            {requiereAprobacion
              &&
              <Form.Item
                name="aprobadores"
                label="Seleccione los aprobadores"
                style={{ paddingLeft: "25px" }}
              >
                <Select
                  mode="multiple"
                  allowClear
                  options={aprobadores}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            }

            <Form.Item
              name="requiereFirmaRepresentanteLegal"
              valuePropName="checked"
            >
              <Checkbox
                onChange={(e) =>
                  setRequiereFirmaRepresentanteLegal(e.target.checked)
                }
                disabled={
                  tipoDocumentoSeleccionado?.id ===
                    CategoriaDocumento.CARGA_DOCUMENTO.id
                    ? true
                    : false
                }
              >
                <span style={{ display: "block" }}>
                  Requiere Firma Represente Legal
                </span>
                <span style={{ color: "gray" }}>
                  Formulario debe ser firmado por el representante legal.
                </span>
              </Checkbox>
            </Form.Item>
            {requiereFirmaRepresentanteLegal && (
              <div style={{ paddingLeft: "25px" }}>
                <Form.Item
                  name="certificadoDigitalIdRepresentanteLegal"
                  label="Seleccione el certificado digital del representante legal"
                  rules={[
                    {
                      required: true,
                      message: "El campo certificado digital es obligatorio.",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    style={{ width: "90%" }}
                    onChange={onSeleccionarCertificado}
                  >
                    {certificados.length > 0 &&
                      certificados.map((certificado) => {
                        return (
                          <Select.Option
                            key={certificado.id}
                            value={certificado.id}
                          >
                            {certificado.representanteLegalNombre}
                          </Select.Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </div>
            )}

            <Form.Item name="interno" valuePropName="checked">
              <Checkbox disabled={isDisabled}>
                <span style={{ display: "block" }}>¿Documento interno?</span>
                <span style={{ color: "gray" }}>
                  Este documento solo estará disponible para usuarios
                  administradores.
                </span>
              </Checkbox>
            </Form.Item>

            {documentoInterno
              &&
              <Form.Item
                name="responsables"
                label="Seleccione los responsables"
                style={{ paddingLeft: "25px" }}
              >
                <Select
                  mode="multiple"
                  allowClear
                  options={responsables}
                  filterOption={(input, option) =>
                    (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Form.Item>
            }

            <Form.Item name="requierePlantilla" valuePropName="checked">
              <Checkbox
                onChange={onChangeCargaFormato}
                disabled={
                  tipoDocumentoSeleccionado?.id ===
                    CategoriaDocumento.CARGA_DOCUMENTO.id
                    ? false
                    : isDisabled
                }
              >
                <span style={{ display: "block" }}>Subir Formato</span>
                <span style={{ color: "gray" }}>
                  Agregar el formato PDF que se para los archivos de carga.
                </span>
              </Checkbox>
            </Form.Item>

            {uploadFormat && (
              <Form.Item
                name="fileTemplate"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                noStyle
                rules={[{ required: true, message: "Cargue un archivo." }]}
              >
                <Upload
                  beforeUpload={() => {
                    return false;
                  }}
                  accept="application/pdf"
                  maxCount={1}
                >
                  <Button>
                    <UploadOutlined /> Cargar Formato ( max. 1 )
                  </Button>
                </Upload>
              </Form.Item>
            )}
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item name="opcional" valuePropName="checked">
            <Checkbox disabled={isDisabled}>
              <span style={{ display: "block" }}>Opcional</span>
              <span style={{ color: "gray" }}>
                El formulario o carga de documento será opcional.
              </span>
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "center" }}>
        <Button type="primary" icon={<CheckOutlined />} htmlType="submit">
          Crear Tipo Documento
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormularioRegistro;
