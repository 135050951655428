import React, { useEffect } from "react";
import { Tag, Popover, Table, Button, Progress } from "antd";
import {
  CheckCircleOutlined,
  LoginOutlined,
  InfoCircleOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";

export const MenuAvance = ({ documento }) => {
    const columnas = [
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        align: "center",
      },
      {
        title: "Aprobados",
        dataIndex: "aprobados",
        key: "aprobados",
        align: "center",
      },
      {
        title: "Pendientes",
        dataIndex: "pendientes",
        key: "pendientes",
        align: "center",
      },
    ];
    const notificacion = [
      {
        key: "2",
        total: (
          <React.Fragment>
            <LoginOutlined style={{ color: "green" }} />
            &nbsp;{documento.total}
          </React.Fragment>
        ),
        aprobados: (
          <React.Fragment>
            <CheckCircleOutlined style={{ color: "green" }} />
            &nbsp;{documento.aprobados}
          </React.Fragment>
        ),
        pendientes: (
          <React.Fragment>
            <InfoCircleOutlined style={{ color: "red" }} />
            &nbsp;{documento.pendientes}
          </React.Fragment>
        ),
      },
    ];

    const datosVisualizacion = (
      <div>
        <Table dataSource={notificacion} columns={columnas} pagination={false} />
      </div>
    );
        return (
          <div style={{ width: "100%"}}>
            <Popover content={datosVisualizacion} placement="bottom" trigger="click">
              <Button block type="link">
                <Progress
                  percent={Math.round((documento.aprobados * 100) / documento.total)}
                  size="default"
                />
              </Button>
            </Popover>
          </div>
        );
};
