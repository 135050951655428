import { atom } from "jotai";

export const postulantesAtom = atom([]);
export const postulanteSeleccionadoAtom = atom(undefined);
export const postulantesAgregadosAtom =atom ([]);
export const convocatoriaSeleccionadaIdAtom =atom(undefined);
export const postulantesConvocatoriaAtom= atom([]);
export const filtroPostulanteAtom = atom (undefined);
export const estadoMensajeErrorAtom = atom(false);

export const loadingAtom = atom(false);