import React from "react";
import { Button } from "antd";

import { useHistory } from "react-router";



const MenuAcciones = ({ envio }) => {
  let history = useHistory();

  const onClickMenuVerDetalle = () => {
    return (
        history.push(`/notificacion-por-completar/${envio.id}`)
    );
}

  return (
    <React.Fragment>
      <Button type="link" onClick={onClickMenuVerDetalle} >Ver detalle</Button>
    </React.Fragment>
  );
};

export default MenuAcciones;
