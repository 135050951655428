
import React from "react";
import { Button,Popconfirm } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FirmaHolografaService } from "services/FirmaHolografaService";
import { useHistory } from "react-router-dom";
import {firmasHolografasAtom} from "../../ListarFirmaHolografaStore";
import { useAtom } from "jotai";

const MenuAcciones=({firma})=>{
    let history=useHistory();
    const[firmasHolografas,setFirmasHolografas]=useAtom(firmasHolografasAtom);
    
    const onClickEliminarFirma= async ()=>{       
        await FirmaHolografaService.eliminarPorId(firma.id);
        setFirmasHolografas(firmasHolografas.filter(firmaHolografa=>firmaHolografa.id!=firma.id));
    }
    return(
        <Popconfirm 
            title="Está seguro de eliminar la firma?"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={onClickEliminarFirma}
            okText="Si"
            cancelText="No"
        >
          <Button type={"link"} >
            Quitar
        </Button>
       </Popconfirm>
        
    )

}
export default MenuAcciones;