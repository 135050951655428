import React, { useContext, useEffect, useState } from "react";
import { Table,Space } from "antd";
import * as atoms from "../ListarSubsedeStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import  SubsedeService from "../../../../services/SubsedeService";
import { MenuAcciones } from "./MenuAcciones";

const TablaSubsede=()=>{
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [loading, setLoading] = useState(false);
    const [subsedes, setSubsedes] = useAtom(atoms.subsedesAtom);

    useEffect(()=>{
        const fetch = async ()=>{
            try {
             setLoading(true);                    
             const _subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             setSubsedes(_subsedes);                        
             
            } catch (error) {
                console.error(error);
                
            } finally{
                setLoading(false);
            }

        }
        fetch(); 
    },[]);  
    const columnasSubsedes=[
        {
            title:"Nombre",
            dataIndex:"nombre",   
            align:"center"             
        },
        
        {
            title:"Acciones",
            key:"acciones",
            align:"center",
            render:(fila)=>
                <MenuAcciones
                  subsede={fila}
                  subsedes={subsedes}
                  setSubsedes={setSubsedes}
                />            
        },
        
    ];
    return(
        <Table
            loading={loading}
            rowKey={(subsede)=>subsede.id}
            columns={columnasSubsedes}
            dataSource={subsedes}
            pagination={false}
        
        
        />
    )

}
export default TablaSubsede;