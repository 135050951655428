import React, { useContext, useState, useEffect } from "react";
import { ContainerCrear } from "../../../components/ContainerCrear";
import { Form, Upload, message, Descriptions } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { LoginService } from "services/LoginService";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { Headers } from "enums";

export function ColaboradorCargaMasivaPage() {
  const { Dragger } = Upload;
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  console.log("usuario autenticado:", getUsuarioAutenticado());
  const [totalRegistrosFallidos, setTotalRegistrosFallidos] = useState([]);
  const [totalColaboradoresRegistrados, setTotalColaboradoresRegistrados] =
    useState([]);
  const [totalColaboradoresActualizados, setTotalColaboradoresActualizados] =
    useState([]);

  useEffect(() => {
    setTotalRegistrosFallidos(0);
    setTotalColaboradoresRegistrados(0);
    setTotalColaboradoresActualizados(0);
  }, []);

  const datos = {
    plataformaId: getUsuarioAutenticado().plataforma.id,
    plataformaSubdominio: getUsuarioAutenticado().plataforma.subdominio,
    empresaId: getUsuarioAutenticado().empresas[0].id,
    empresaRuc: getUsuarioAutenticado().empresas[0].ruc,
    empresaRazonSocial: getUsuarioAutenticado().empresas[0].razonSocial,
  };

  const props = {
    name: "file",
    multiple: true,
    action: process.env.REACT_APP_ENDPOINT + "colaborador/cargarMasivamente",
    headers: {
      Authorization: "Bearer " + LoginService.getJwtToken(),
      [Headers.ORGANIZACION_ID]:
        LoginService.getUsuarioAutenticado().organizacionId,
      [Headers.EMPRESA_ID]:
        LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
    },
    data: datos,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        //console.log(info.file, info.fileList);
        //console.log("entre1:");
      }
      if (status === "done") {
        //console.log("entre2:", info.file.response);
        message.success(`${info.file.name} file uploaded successfully.`);

        setTotalRegistrosFallidos(info.file.response.totalRegistrosFallidos);
        setTotalColaboradoresRegistrados(
          info.file.response.totalColaboradoresRegistrados
        );
        setTotalColaboradoresActualizados(
          info.file.response.totalColaboradoresActualizados
        );
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <ContainerCrear
      titulo="Carga Masiva"
      descripcion="Crear y/o actualizar colaboradores por medio de un archivo excel."
      colSpan={12}
    >
      <Form>
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Haga click ó arrastre su archivo hacia aquí para iniciar el proceso.
          </p>
          <p className="ant-upload-hint">
            Solo se aceptan archivos excel con extension xls ó xlsx.
          </p>
        </Dragger>
        <br />
        <Descriptions title="Resultados de la carga" bordered>
          <Descriptions.Item label="Registros Fallidos">
            {totalRegistrosFallidos}
          </Descriptions.Item>
          <Descriptions.Item label="Colaboradores Registrados">
            {totalColaboradoresRegistrados}
          </Descriptions.Item>
          <Descriptions.Item label="Colaboradores Actualizados">
            {totalColaboradoresActualizados}
          </Descriptions.Item>
        </Descriptions>
      </Form>
    </ContainerCrear>
  );
}
