import React from "react";
import { EstadoDocumentoGenerico } from "enums/EstadoDocumentoGenerico"
import { Tag } from "antd";

const EtiquetaEstadoDocumentoGenerico = ({ estado }) => {
    switch (estado) {
        case EstadoDocumentoGenerico.PENDIENTE_FIRMA:
            return <Tag color="gold">PENDIENTE</Tag>

        case EstadoDocumentoGenerico.FIRMADO:
            return <Tag color="green">FIRMADO</Tag>

        case EstadoDocumentoGenerico.RECHAZADO:
            return <Tag color="volcano">RECHAZADO</Tag>

        default:
            return <Tag>-</Tag>;
    }
}

export default EtiquetaEstadoDocumentoGenerico;