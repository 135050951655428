import React, { useContext, useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useModalConfirmar } from "store/global"
import { SecurityContext } from "context/SecurityContextProvider";
import * as atoms from "../CrearSeccionStore";
import { useAtom } from "jotai";
import { useHistory } from "react-router";
import { CampoColaborador } from "enums/CampoColaborador";
import SubsedeService from "services/SubsedeService";
import PerfilService from "services/PerfilService";
import SedeService from "services/SedeService";
import TipoPlanillaService from "services/TipoPlanillaService";
import CargoService from "services/CargoService";

const SeccionRegistro = () => {
  //let history = useHistory();
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [, setSeccionAtom] = useAtom(atoms.seccion);
  const mostrarModalConfirmacion = useModalConfirmar(state => state.mostrarModal);

  const [dependenciaSeccionAtom, setDependenciaSeccionAtom] = useAtom(atoms.dependenciaSeccion);
  const [listaCamposAtom, setListaCamposAtom] = useAtom(atoms.listaCampos);
  const [perfilesAtom, setPerfilesAtom] = useAtom(atoms.perfiles);
  const [sedesAtom, setSedesAtom] = useAtom(atoms.sedes);
  const [subsedesAtom, setSubsedesAtom] = useAtom(atoms.subsedes);
  const [tipoPlanillasAtom, setTipoPlanillasAtom] = useAtom(atoms.tipoPlanillas);
  const [cargosAtom, setCargosAtom] = useAtom(atoms.cargos);
  const [camposColaboradorAtom, setCamposColaboradorAtom] = useAtom(atoms.camposColaborador)

  const { Option } = Select;
  const [formSeccion] = Form.useForm();

  useEffect(() => {

    const cargarDatosIniciales = async () => {
      var listaCampos = [], dependencia;
      const empresa = getUsuarioAutenticado().empresaSeleccionada.id;
      const campos_colaborador = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
      setCamposColaboradorAtom(campos_colaborador);

      const campo_seccion = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.SECCION);

      const seccionDependencia = campo_seccion.campoDependencia;


      if (seccionDependencia !== null) {

        setDependenciaSeccionAtom(seccionDependencia);
        dependencia = seccionDependencia;
        listaCampos.push(dependencia);

        while (dependencia !== null) {
          var campo = campos_colaborador.find(campoColaborador => campoColaborador.campo === dependencia);
          dependencia = campo.campoDependencia;
          if (dependencia !== null) {
            listaCampos.push(dependencia);
          }

        }
        setListaCamposAtom(listaCampos.reverse());
      }
      else {
        setListaCamposAtom(listaCampos);
        setDependenciaSeccionAtom(CampoColaborador.SINCAMPO);
      }
      if (listaCampos.length > 0) {
        const primerCampo = listaCampos[0];
        if (primerCampo === CampoColaborador.PERFIL) {
          const perfiles = await PerfilService.listar(empresa);
          setPerfilesAtom(perfiles);
        }
        else if (primerCampo === CampoColaborador.SEDE) {
          const sedes = await SedeService.listar(empresa);
          setSedesAtom(sedes);

        }
        else if (primerCampo === CampoColaborador.SUBSEDE) {
          const subsedes = await SubsedeService.listar(empresa);
          setSubsedesAtom(subsedes);

        }
        else if (primerCampo === CampoColaborador.PLANILLA) {
          const planillas = await TipoPlanillaService.listar(empresa);
          setTipoPlanillasAtom(planillas);

        }
        else if (primerCampo === CampoColaborador.CARGO) {
          const cargos = await CargoService.listar(empresa);
          setCargosAtom(cargos);
        }
      }

    }

    cargarDatosIniciales();


  }, []);

  const onClickBtnCrearSeccion = (seccion) => {
    seccion.empresaId = getUsuarioAutenticado().empresaSeleccionada.id;
    seccion.dependencia = dependenciaSeccionAtom;
    setSeccionAtom(seccion);
    mostrarModalConfirmacion();
  }
  const handleOnchangePerfil = async (value, option) => {

    let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.PERFIL);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter(sede => sede.perfilId === value)
        setSedesAtom(_sedes);
        formSeccion.setFieldsValue({ sedeId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter(subsede => subsede.perfilId === value);
        setSubsedesAtom(_subsedes);
        formSeccion.setFieldsValue({ subsedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.CARGO) {
        const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _cargos = cargos.filter(cargo => cargo.perfilId === value);
        setCargosAtom(_cargos);
        formSeccion.setFieldsValue({ cargoId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanilla = tipoPlanilla.filter(planilla => planilla.perfilId === value);
        setTipoPlanillasAtom(_tipoPlanilla);
        formSeccion.setFieldsValue({ tipoPlanillaId: "" })

      }
    }
  }
  const handleOnchangeSede = async (value, option) => {
    let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.SEDE);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.sedeId === value)
        setPerfilesAtom(_perfiles);
        formSeccion.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter(subsede => subsede.sedeId === value);
        setSubsedesAtom(_subsedes);
        formSeccion.setFieldsValue({ subsedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.CARGO) {
        const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _cargos = cargos.filter(cargo => cargo.sedeId === value);
        setCargosAtom(_cargos);
        formSeccion.setFieldsValue({ cargoId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanilla = tipoPlanilla.filter(planilla => planilla.sedeId === value);
        setTipoPlanillasAtom(_tipoPlanilla);
        formSeccion.setFieldsValue({ tipoPlanillaId: "" })

      }
    }
  }
  const handleOnchangeSubsede = async (value, option) => {
    let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.SUBSEDE);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.subsedeId === value)
        setPerfilesAtom(_perfiles);
        formSeccion.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter(sede => sede.subsedeId === value);
        setSedesAtom(_sedes);
        formSeccion.setFieldsValue({ sedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.CARGO) {
        const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _cargos = cargos.filter(cargo => cargo.subsedeId === value);
        setCargosAtom(_cargos);
        formSeccion.setFieldsValue({ cargoId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanilla = tipoPlanilla.filter(planilla => planilla.subsedeId === value);
        setTipoPlanillasAtom(_tipoPlanilla);
        formSeccion.setFieldsValue({ tipoPlanillaId: "" })

      }
    }

  }
  const handleOnchangeTipoPlanilla = async (value, option) => {
    let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.PLANILLA);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.tipoPlanillaId === value)
        setPerfilesAtom(_perfiles);
        formSeccion.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter(subsede => subsede.tipoPlanillaId === value);
        setSubsedesAtom(_subsedes);
        formSeccion.setFieldsValue({ subsedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.CARGO) {
        const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _cargos = cargos.filter(cargo => cargo.tipoPlanillaId === value);
        setCargosAtom(_cargos);
        formSeccion.setFieldsValue({ cargoId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter(sede => sede.tipoPlanillaId === value);
        setSedesAtom(_sedes);
        formSeccion.setFieldsValue({ sedeId: "" })

      }
    }

  }
  const handleOnchangeCargo = async (value, option) => {
    let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.CARGO);

    for (let i = 0; i < listaCampos.length; i++) {
      if (listaCampos[i].campo === CampoColaborador.PERFIL) {
        const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _perfiles = perfiles.filter(perfil => perfil.cargoId === value)
        setPerfilesAtom(_perfiles);
        formSeccion.setFieldsValue({ perfilId: "" })


      }
      else if (listaCampos[i].campo === CampoColaborador.SUBSEDE) {
        const subsedes = await SubsedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _subsedes = subsedes.filter(subsede => subsede.cargoId === value);
        setSubsedesAtom(_subsedes);
        formSeccion.setFieldsValue({ subsedeId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
        const tipoPlanillas = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _tipoPlanillas = tipoPlanillas.filter(planilla => planilla.cargoId === value);
        setTipoPlanillasAtom(_tipoPlanillas);
        formSeccion.setFieldsValue({ tipoPlanillaId: "" })

      }
      else if (listaCampos[i].campo === CampoColaborador.SEDE) {
        const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
        const _sedes = sedes.filter(sede => sede.cargoId === value);
        setSedesAtom(_sedes);
        formSeccion.setFieldsValue({ sedeId: "" })

      }
    }
  }
  return (
    <Form
      layout="vertical"
      onFinish={onClickBtnCrearSeccion}
      form={formSeccion}
    >
      {
        listaCamposAtom.length > 0 && (
          listaCamposAtom.map((listaCampo) => {
            if (listaCampo === CampoColaborador.PERFIL) {
              return (
                <Row>
                  <Col span={12} offset={6}>
                    <Form.Item
                      label="Perfil"
                      name="perfilId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Perfil"
                        },
                      ]}
                    >
                      <Select
                        placeholder="Seleccione Perfil"
                        onChange={handleOnchangePerfil}
                      >
                        {perfilesAtom.length > 0 &&
                          perfilesAtom.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}
                      </Select>

                    </Form.Item>
                  </Col>
                </Row>
              )
            }

            else if (listaCampo === CampoColaborador.SEDE) {
              return (
                <Row>
                  <Col span={12} offset={6}>
                    <Form.Item
                      label="Centro de Costo"
                      name="sedeId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Centro de Costo"
                        },
                      ]}
                    >
                      <Select
                        placeholder="Seleccione Centro de Costo"
                        onChange={handleOnchangeSede}
                      >
                        {sedesAtom.length > 0 &&
                          sedesAtom.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}

                      </Select>

                    </Form.Item>
                  </Col>
                </Row>
              )
            }
            else if (listaCampo === CampoColaborador.SUBSEDE) {
              return (
                <Row>
                  <Col span={12} offset={6}>
                    <Form.Item
                      label="Subcentro de Costo"
                      name="subsedeId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Subcentro de Costo"
                        },
                      ]}
                    >
                      <Select
                        placeholder="Seleccione Subcentro de Costo"
                        onChange={handleOnchangeSubsede}
                      >
                        {subsedesAtom.length > 0 &&
                          subsedesAtom.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}
                      </Select>

                    </Form.Item>
                  </Col>
                </Row>
              )
            }
            else if (listaCampo === CampoColaborador.PLANILLA) {
              return (
                <Row>
                  <Col span={12} offset={6}>
                    <Form.Item
                      label="Tipo planilla"
                      name="tipoPlanillaId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Tipo planilla"
                        },
                      ]}
                    >
                      <Select
                        placeholder="Seleccione Tipo planilla"
                        onChange={handleOnchangeTipoPlanilla}
                      >
                        {tipoPlanillasAtom.length > 0 &&
                          tipoPlanillasAtom.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}
                      </Select>

                    </Form.Item>
                  </Col>
                </Row>
              )

            }
            else if (listaCampo === CampoColaborador.CARGO) {
              return (
                <Row>
                  <Col span={12} offset={6}>
                    <Form.Item
                      label="Cargo"
                      name="cargoId"
                      rules={[
                        {
                          required: true,
                          message: "Seleccione Cargo"
                        },
                      ]}
                    >
                      <Select
                        placeholder="Seleccione Cargo"
                        onChange={handleOnchangeCargo}
                      >
                        {cargosAtom.length > 0 &&
                          cargosAtom.map((lista) => {
                            return (
                              <Option key={lista.id} value={lista.id}>
                                {lista.nombre}
                              </Option>
                            );
                          })}
                      </Select>

                    </Form.Item>
                  </Col>
                </Row>
              )
            }

          })

        )

      }

      <Row >
        <Col span={12} offset={6}>
          <Form.Item
            label="Nombre"
            name="nombre"
            rules={[
              {
                required: true,
                message: "Escribe el nombre de la sección",
              },
            ]}
          >
            <Input
              placeholder="Nombre de la sección"
              maxLength="50"
              autoComplete="off"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="center">

        <Form.Item style={{ textAlign: "center" }}>
          <Button
            type="primary"
            icon={<CheckOutlined />}
            htmlType="submit"
          >
            Crear Sección
          </Button>
        </Form.Item>


      </Row>
    </Form>

  )

}
export default SeccionRegistro;