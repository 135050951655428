import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, Input, Row, Col, Button, Space, notification } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import DetalleEnvioService from "../../../../services/DetalleEnvioService";
import { ContainerCrear } from "../../../../components/ContainerCrear";
export function ListarDetalleEditarPage() {
  const [frmEditar] = Form.useForm();
  const [loading, setLoading] = useState(false);

  let history = useHistory();
  let { detalleEnvioId } = useParams();
  useEffect(() => {
    async function cargarDatos() {
      const detalleEnvio = await DetalleEnvioService.buscarPorId(
        detalleEnvioId
      );
      frmEditar.setFieldsValue({
        id: detalleEnvio.id,
        correoElectronico: detalleEnvio.colaborador.correoElectronico,
      });
    }
    cargarDatos();
  }, []);
  async function handleOnFinish(formulario) {
    try {
      setLoading(true);
      await DetalleEnvioService.actualizarCorreoE(formulario);
      notification.success({
        message: "Operación exitosa",
        description: "Se actualizó el correo electrónico correctamente.",
      });
      setLoading(false);
      history.push("/documento-laboral/listar-detalle");
    } catch (error) {
      setLoading(false);
    }
  }
  function handleOnClickCancelar() {
    history.push("/documento-laboral/listar-detalle");
  }
  return (
    <ContainerCrear
      titulo="Editar Correo Electrónico"
      descripcion="Formulario para editar el correo electrónico del colaborador de tu organización."
      colSpan={12}
    >
      <Form
        layout="vertical"
        size="large"
        onFinish={handleOnFinish}
        form={frmEditar}
      >
        <Form.Item name="id" label="id" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Row>
          <Col span={24}>
            <Form.Item
              label="Correo Electrónico"
              // name={["colaborador", "correoElectronico"]}
              name="correoElectronico"
              rules={[
                {
                  required: true,
                  message: "Escribe el correo electrónico del colaborador",
                },
              ]}
            >
              <Input
                placeholder="correo Electrónico"
                maxLength="50"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Space>
            <Col>
              <Form.Item style={{ textAlign: "center" }}>
                <Button type="default" onClick={handleOnClickCancelar}>
                  Cancelar
                </Button>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item style={{ textAlign: "center" }}>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  loading={loading}
                  htmlType="submit"
                >
                  Actualizar Correo Electrónico
                </Button>
              </Form.Item>
            </Col>
          </Space>
        </Row>
      </Form>
    </ContainerCrear>
  );
}
