import { useEffect } from "react";
import { useAtom } from "jotai";
import ControlVacacionalService from "services/ControlVacacionalService";
import { vacacionesAtom } from "../ControlVacacionalStorage";
import { HttpResponseStatus } from "enums";

const useCargarVacaciones = () => {
  const [vacaciones, setVacaciones] = useAtom(vacacionesAtom);

  // useEffect(() => {
  //   const cargarDatos = async () => {
  //     console.log("cargando vacaciones....");
  //     const vacaciones = await ControlVacacionalService.listar();
  //     if (vacaciones.estado === HttpResponseStatus.OK) {
  //       setVacaciones(vacaciones.data);
  //     }
  //   };
  //   cargarDatos();
  // }, []);

  return { vacaciones, setVacaciones };
};

export default useCargarVacaciones;
