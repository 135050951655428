import React, { useEffect } from "react";
import { Tag, Popover, Table } from "antd";
import { EstadoCorreoElectronico } from "enums/EstadoCorreoElectronico";
import { EstadoSms } from "enums/EstadoSms";
import { CheckCircleOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";

export const TagNotificado = ({ documento, color, className, children }) => {

    const columnas = [
        {
            title: 'Fecha Notificación',
            dataIndex: 'fechaNotificacion',
            key: 'fechaNotificacion',
            align: 'center'
        },
        {
            title: 'Bandeja',
            dataIndex: 'bandeja',
            key: 'bandeja',
        },
    ];

    const historial = [];
    if (documento.estadoCorreoEnviado === EstadoCorreoElectronico.ACEPTADO ||
        documento.estadoCorreoEnviado === EstadoCorreoElectronico.ENVIADO) {
        historial.push(
            {
                key: historial.length + 1,
                fechaNotificacion:
                    <React.Fragment>
                        <CheckCircleOutlined style={{ color: 'green' }} />
                        &nbsp;
                        {documento.fechaCorreoEnviado}
                    </React.Fragment>,
                bandeja:
                    <React.Fragment>
                        <MailOutlined />
                        &nbsp;
                        {documento.colaboradorCorreoElectronico}
                    </React.Fragment>
            }
        )
    }

    if (documento.estadoSmsEnviado === EstadoSms.ENVIADO) {
        historial.push(
            {
                key: historial.length + 1,
                fechaNotificacion:
                    <React.Fragment>
                        <CheckCircleOutlined style={{ color: 'green' }} />
                        &nbsp;
                        {documento.fechaSmsEnviado}
                    </React.Fragment>,
                bandeja:
                    <React.Fragment>
                        <PhoneOutlined />
                        &nbsp;
                        {documento.colaboradorNumeroCelular}
                    </React.Fragment>
            }
        )
    }

    const datosVisualizacion = (
        <div>
            <Table dataSource={historial} columns={columnas} pagination={false} />
        </div>
    );

    return (
        <Popover content={datosVisualizacion} placement="bottom" trigger="click">
            <Tag color={color} className={className}>{children}</Tag>
        </Popover>
    )
}
