import create from 'zustand'

const uesModalExitoStore = create((set, get) => ({
    visibleModalExito: false,
    mostrarModalExito: () => {
        set({ visibleModalExito: true })
    },
    cerrarModalExito: () => {
        set({ visibleModalExito: false })
    }

}));

export default uesModalExitoStore;