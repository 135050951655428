import React, { useState } from "react";
import { Row, Col, Card, Typography, Space, Dropdown, Menu } from "antd";
import { DownOutlined, SettingOutlined } from "@ant-design/icons";

const { Text } = Typography;

export function ContainerListarDetalle({
  icono,
  titulo,
  descripcion,
  sede,
  menu,
  menuMasOpciones,
  children,
}) {
  return (
    <Row>
      <Col span={24}>
        <Card
          title={
            <Text>
              <Space>
                {icono}
                <span className="mock-block">
                  <Text strong>{titulo}</Text>
                  <div>{descripcion}</div>
                  <div style={{ color: "#1286C1" }}>{sede}</div>
                  {/* <p>{descripcion}</p>
                  <p>{sede}</p>                   */}

                </span>
              </Space>
            </Text>
          }
          extra={
            <Space size={20}>
              {/* <Dropdown overlayStyle={{ textAlign: "center" }} overlay={menu}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  Ver <DownOutlined />
                </a>
              </Dropdown> */}
              <Dropdown
                overlayStyle={{ textAlign: "center" }}
                overlay={menuMasOpciones}
              >
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <SettingOutlined /> Más opciones
                </a>
              </Dropdown>
            </Space>
          }
          /* extra={
            <Dropdown overlay={menu}>
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <SettingOutlined />
                Más opciones
              </a>
            </Dropdown>
          }*/
          headStyle={{ fontWeight: "400" }}
        >
          {children}
        </Card>
      </Col>
    </Row>
  );
}
