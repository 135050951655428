import { atom } from 'jotai';
import moment from "moment";
export const categoriaSeguimientoAtom = atom();
export const tipoDocumentoSeguimientoAtom = atom();
export const periodoAtom = atom([]);
export const paginaSeguimientoAtom =atom();
export const paginActualAtom =atom();
export const totalPaginacionEnviosAtom = atom();
export const totalPaginacionDetalleEnviosAtom = atom();
export const carpetaAtom =atom();
//
export const filtroTipoDocumentoAtom = atom("");
export const filtroPeriodoAtom = atom([moment().startOf("year"),moment().endOf("year")]);
export const botonAtrasDetalleSeguimientoAtom = atom(false);
export const filtroEstadoAtom = atom("TODOS");