import React, { useState } from "react";
import { Button, Form, Typography, notification, Upload, message, Row, Col, Alert } from "antd";
import { CheckOutlined, FileExcelOutlined } from "@ant-design/icons";
import ColaboradorService from "services/ColaboradorService";
import { saveAs } from "file-saver";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import { useHistory } from "react-router";

const VerificarDatosColaboradorNotificar = () => {
  const { Text } = Typography;
  const [documentoExcel, setDocumentoExcel] = useState([]);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [mensajeError, setMensajeError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const onClickDescargarPlantilla = async () => {
    try {
      let respuesta = await ColaboradorService.descargarPlantillaVerificacionDatos();
      const filename = respuesta.headers.filename;
      saveAs(respuesta.data, filename);
    } catch (error) {
      notification.error({
        message: "No se puede descargar el modelo de verificación de datos colaborador",
      });
    }
  };

  // const datos = {
  //   organizacionId: getUsuarioAutenticado().organizacionId,
  //   empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
  // };

  const props = {
    onRemove: (file) => {
      setDocumentoExcel([]);
      return {
        documentoExcel: [],
      };
    },
    beforeUpload: (file) => {
      if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        message.error(`${file.name} no es un archivo Excel`);
      } else {
        setDocumentoExcel(file);
        return false;
      }
    },
    documentoExcel,
  };
  const onClickNotificar = async (formulario) => {
    setLoading(true);

    const datosDocumento = {
      organizacionId: getUsuarioAutenticado().organizacionId,
      empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
      usuarioEnvioId: getUsuarioAutenticado().id,
    };

    const formData = new FormData();
    formData.append("file", formulario.documento.file);
    formData.append(
      "datosDocumento",
      new Blob([JSON.stringify(datosDocumento)], {
        type: "application/json",
      })
    );
    const respuesta = await ColaboradorService.notificarVerificacionDatosColaborador(formData);
    if (respuesta.exito == false) {
      setMensajeError(
        <React.Fragment>
          <span>
            No se notificaron a los siguientes colaboradores, descargar la información{" "}
            <b>
              <a style={{ color: "#000" }} href={respuesta.urlExcelError}>
                aquí.
              </a>
            </b>
          </span>
        </React.Fragment>
      );
    } else {
      notification.success({
        message: "Operación exitosa",
        description: "Se ha notificado a los colaboradores",
      });
      history.push("/configuracion/verificar-datos-colaborador")
    }
    setLoading(false);
  };

  return (
    <Form layout="vertical" onFinish={onClickNotificar}>
      {mensajeError && (
        <Alert
          message={mensajeError}
          type="warning"
          showIcon
          closable
          style={{ marginBottom: "6px" }}
          onClose={() => setMensajeError(undefined)}
        />
      )}
      <Row>
        <Col span={10} offset={8}>
          <Form.Item
            label="Colaboradores"
            name="documento"
            rules={[
              {
                required: true,
                validator: (_, value) =>
                  documentoExcel.length !== 0 ? Promise.resolve() : Promise.reject(new Error("Seleccione el Archivo")),
              },
            ]}
          >
            <Upload {...props} maxCount={1}>
              <Button style={{ borderRadius: 4, borderColor: "green" }}>
                <FileExcelOutlined style={{ fontSize: 20, color: "green" }} />{" "}
                <Text style={{ color: "green" }}>Cargar Archivo Excel</Text>
              </Button>
            </Upload>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={10} offset={8}>
          <Form.Item>
            Descargar el modelo para la verificación de datos personales {""}
            <a style={{ color: "#0F7FF0", fontStyle: "italic" }} onClick={onClickDescargarPlantilla}>
              aquí
            </a>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={10} offset={7}>
          <Form.Item>
            <Button type="primary" icon={<CheckOutlined />} htmlType="submit" loading={loading}>
              Notificar
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};
export default VerificarDatosColaboradorNotificar;
