import { ActualizarClavePage } from "../pages/cuenta/actualizar-clave";
import { BaseService } from "./BaseService";

const RolService = {
    async listar(empresaId,estado) {
        const respuesta = await BaseService.get("/rol",{params:{empresaId:empresaId,estado:estado}});
        return respuesta.data;
      },

     async listarOpciones(){
      const respuesta = await BaseService.get("/rol/opciones");
      return respuesta.data;
     },
     async insertar(rol) {
      const respuesta = await BaseService.post("/rol", rol);
      return respuesta.data;
    }, 
    async actualizar(rol){
      const respuesta = await BaseService.put("/rol", rol);
      return respuesta.data;
    },
    async buscarPorId(id){
      const respuesta = await BaseService.get(`/rol/${id}`);
      return respuesta.data;
    },
    async eliminar(id){
      const respuesta=await BaseService.delete(`/rol/${id}`);
      return respuesta.data;
      
    }

}
export default RolService;