import create from 'zustand';
import { DocumentoGenericoService } from "services/DocumentoGenericoService"
import CertificadoService from 'services/CertificadoService';

const useModalFirmarStore = create((set, get) => ({
    loading: false,
    stepSeleccionado: 0,
    visibleModalFirmar: false,
    numeroPagina: 1,
    pagina: { alto: 0, ancho: 0 },
    posicionFirma: { left: 0, top: 0 },
    certificados: [],
    certificadoSeleccionadoId: undefined,
    documento: undefined,
    urlPdf: undefined,
    irAlPaso: (numeroStep) => {
        set({ stepSeleccionado: numeroStep });
    },
    setCertificadoSeleccionadoId: (certificadoId) => {
        set({ certificadoSeleccionadoId: certificadoId });
    },
    setNumeroPagina: (numeroPagina) => {
        set({ numeroPagina: numeroPagina });
    },
    setPagina: (alto, ancho) => {
        set(state => ({ pagina: { ...state.pagina, alto: alto, ancho: ancho } }))
    },
    setPosicionFirma: (left, top) => {
        set(state => ({ posicionFirma: { ...state.posicionFirma, left: left, top: top } }))
    },
    getCertificadosFirmante: async () => {
        try {
            set({ loading: true });
            const certificados = await CertificadoService.listar(get().documento.empresaId, get().documento.usuarioFirmanteId, '1');
            set({ certificados: certificados });
        } catch (error) {
            console.error(error);
        } finally {
            set({ loading: false });
        }
    },
    mostrarModalVisorFirma: async (documento) => {
        const respuesta = await DocumentoGenericoService.obtenerUrlPdf(documento.id);
        set({
            loading: true,
            documento: documento,
            visibleModalFirmar: true,
            urlPdf: respuesta.urlDocumento
        });
    },
    cerrarModalVisorFirma: () => {
        set({
            visibleModalFirmar: false,
            documento: undefined,
            urlPdf: undefined,
            stepSeleccionado: 0,
            numeroPagina: 1,
            certificadoSeleccionadoId: undefined,
            pagina: { alto: 0, ancho: 0 },
            posicionFirma: { left: 0, top: 0 }
        });
    },
    finalizarLoading: () => {
        set({ loading: false });
    }

}));

export default useModalFirmarStore;