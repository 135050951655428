import React, { useState } from "react";
import { Button, Popconfirm, Space, Table, notification } from "antd";
import { plantillasEnvioDocumentosAtom } from "../ListarPlantillaEnvioDocumentosStore";
import { useAtom } from "jotai";
import { useEffect, useContext } from "react";
import { PlantillaEnvioDocumentoService } from "../../../../../services/PlantillaEnvioDocumentoService";
import { SecurityContext } from "context/SecurityContextProvider";
import { verDocumentoAtom } from "../ListarPlantillaEnvioDocumentosStore";
import { plantillaSeleccionadaAtom } from "../ListarPlantillaEnvioDocumentosStore";
import { useHistory } from "react-router";
import { EditOutlined, QuestionCircleOutlined, DeleteOutlined, LoadingOutlined } from "@ant-design/icons";

const TablaPlantillaEnvioDocumentos = () => {
  const [plantillasEnvioDocumentos, setPlantillasEnvioDocumentos] = useAtom(plantillasEnvioDocumentosAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [verDocumento, setVerDocumento] = useAtom(verDocumentoAtom);
  const [, setPlantillaSeleccionada] = useAtom(plantillaSeleccionadaAtom);
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  useEffect(() => {
    const cargarDatos = async () => {
      setLoading(true);
      const plantillas = await PlantillaEnvioDocumentoService.listar(getUsuarioAutenticado().login);
      // const plantillas = await PlantillaEnvioDocumentoService.listar();
      setPlantillasEnvioDocumentos(plantillas);
      setLoading(false);
    };
    cargarDatos();
  }, []);

  const onclickVerDocumento = async (fila) => {
    setVerDocumento(true);
    const _plantillaSeleccionada = await PlantillaEnvioDocumentoService.buscarPorId(fila.id);
    setPlantillaSeleccionada(_plantillaSeleccionada.documentos);
  };

  const onClickEditar = (plantilla) => {
    const rutaEditarPlantillaPage = `/configuracion/plantilla-envio-documentos/${plantilla.id}/editar`;
    history.push(rutaEditarPlantillaPage);
  };

  async function handleOnClickEliminar(idPlantilla) {
    await PlantillaEnvioDocumentoService.eliminar(idPlantilla);
    setPlantillasEnvioDocumentos(plantillasEnvioDocumentos.filter((plantilla) => plantilla.id !== idPlantilla));
    notification.success({
      message: "Operación exitosa",
      description: "Se eliminó el registro correctamente.",
    });
  }

  const columnas = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },
    {
      title: "Documento",
      key: "documento",
      render: (text, fila) => (
        <Button type="link" onClick={() => onclickVerDocumento(fila)}>
          Ver
        </Button>
      ),
    },
    {
      title: "Acciones",
      key: "acciones",
      render: (text, fila) => (
        <Space size={8}>
          <a onClick={() => onClickEditar(fila)} className="custom-color-element">
            <EditOutlined /> Editar
          </a>
          <Popconfirm
            title="¿Está seguro de eliminar el registro seleccionado?"
            okText="Si"
            cancelText="No"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => handleOnClickEliminar(fila.id)}
          >
            <a style={{ color: "#CE3E3E" }}>
              {" "}
              <DeleteOutlined /> Eliminar
            </a>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Table
      rowKey={(plantilla) => plantilla.id}
      loading={{
        spinning: loading,
        indicator: <LoadingOutlined style={{ fontSize: 28 }} />,
        tip: "Cargando...",
      }}
      columns={columnas}
      dataSource={plantillasEnvioDocumentos}
    />
  );
};
export default TablaPlantillaEnvioDocumentos;
