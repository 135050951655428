import { atom } from "jotai";

export const listaCargasAtom = atom([]);
export const visibleModalCrearCargaAtom = atom(false);
export const listaArchivosAtom = atom([]);
export const visibleModalTiposDocumentoAtom = atom(false);
export const tipoDocumentosAtom = atom([]);
export const tipoDocumentoSeleccionadoAtom = atom([]);
export const tipoDocumentoIdAtom = atom([]);


