import React, { useState } from 'react';
import { message, Modal, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import {InboxOutlined} from "@ant-design/icons";
import { useAtom } from 'jotai';
import { documentoSeleccionadoAtom, documentosProcesoAtom, modalCargaAtom } from '../../DocumentosProcesoListarStore';
import { LoginService } from "services/LoginService";
import { Headers } from "enums";
import { FormatoCarga } from 'enums/FormatoCarga';

const ModalCargarDocumento = () => {
  const [visible, setVisible] = useAtom(modalCargaAtom)
  const [documentosListado, ] = useAtom(documentosProcesoAtom);
  const [filaSeleccionada, ] = useAtom(documentoSeleccionadoAtom)
  const [cargaArchivo, setCargaArchivo] = useState(undefined);
  const [ loading, setLoading] = useState(false);

  const { Dragger } = Upload;
  const datos = {
    detalleId:filaSeleccionada?.id,
  };

  const props = {
    name: "file",
        action: process.env.REACT_APP_ENDPOINT_RECLUTAMIENTO_Y_SELECCION + "/procesos/documentos/subir-archivo",
        showUploadList: "false",
        headers: {
          authorization: "Bearer " + LoginService.getJwtToken(),
          [Headers.ORGANIZACION_ID]:
            LoginService.getUsuarioAutenticado().organizacionId,
          [Headers.EMPRESA_ID]:
            LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
        },
        data: datos,
    onChange(info) {
        setLoading(true)
        const { status } = info.file;
        if (status !== "uploading") {
        }
        if (status === "done") {
            documentosListado.map(p=> {
                if(p.id === filaSeleccionada.id){
                    p.estadoVistaColaborador = "FIRMADO";
                }
            })
            setCargaArchivo()
            setLoading(false)
            setVisible(false)
            message.success(`${info.file.name} se ha cargado correctamente.`);
        } else if (info.file.status === "error") {
            setCargaArchivo()
            setLoading(false)
            setVisible(false)
          message.error(`${info.file.name} Carga del archivo ha fallado.`);
        }
    }
  };

  return (
    <Modal title={"Cargar Documento"} visible={visible} onCancel={()=>setVisible(false)} destroyOnClose>
        {/* <ImgCrop rotate minZoom={0.5} modalTitle={"Editar Imagen"}> */}
            <Dragger {...props} fileList={cargaArchivo} disabled={loading} accept={[FormatoCarga.PDF_MULTIPAGINA.extension,FormatoCarga.IMAGEN.extension]}>
            <p className="ant-upload-drag-icon">
            <InboxOutlined />
            </p>
            <p className="ant-upload-text">Click o arrastrar el archivo al area para comenzar la carga</p>
            {/* <p className="ant-upload-hint">
            Support for a single or bulk upload. Strictly prohibit from uploading company data or other
            band files
            </p> */}
            </Dragger>
        {/* </ImgCrop> */}
    </Modal>
  );
};
export default ModalCargarDocumento;