import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";
import qs from "qs";

const URL_API = ApiUrl.ASISTENCIA;

const TurnoService = {
  async insertar(turno) {
    const respuesta = await ApiService(URL_API).post("/turnos", turno);
    return respuesta.data;
  },
  async listar() {
    const respuesta = await ApiService(URL_API).get("/turnos");
    return respuesta.data;
  }
};

export { TurnoService };
