import React, { useCallback, useEffect } from "react";
import { useDrop } from "react-dnd";
import { Page } from "react-pdf";
import { useModalFirmarStore } from "hooks/firma-documento-generico";
import { FirmaHolografa } from "./FirmaHolografa";

export const PaginaPdf = ({ numeroPagina, index, name }) => {
  const posicionFirma = useModalFirmarStore((state) => state.posicionFirma);
  const setPosicionFirma = useModalFirmarStore((state) => state.setPosicionFirma);
  const pagina = useModalFirmarStore((state) => state.pagina);

  useEffect(() => {
    const left = pagina.ancho - 232 - 30;
    const top = pagina.alto - 50 - 30;
    setPosicionFirma(left, top);
  }, [pagina]);

  const moverFirma = useCallback(
    (left, top) => {
      // setPosicionFirma({ ...posicionFirma, left, top });
      setPosicionFirma(left, top);
    },
    [posicionFirma, setPosicionFirma]
  );

  const [, drop] = useDrop(
    () => ({
      accept: "FirmaHolografa", //solo acepta elementos del tipo FirmaHolografa
      hover: (item, monitor) => {
        const delta = monitor.getDifferenceFromInitialOffset();
      },
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        console.log("left: ", left);
        console.log("top: ", top);
        moverFirma(left, top);
        return { name: "PaginaPdf" };
      },
    }),
    [moverFirma]
  );

  // const { left, top } = posicionFirma;

  return (
    <div ref={drop} style={{ position: "relative" }}>
      <Page key={`page_${numeroPagina}`} pageNumber={numeroPagina} loading={""} />
      {/* <FirmaHolografa setIsPaginaPdf={setIsPaginaPdf} left={posicionFirma.left} top={posicionFirma.top} /> */}
      <FirmaHolografa left={posicionFirma.left} top={posicionFirma.top} />
    </div>
  );
};
