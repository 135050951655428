import React from "react";
import moment from 'moment';
import {
    LoginOutlined, 
  } from '@ant-design/icons';



export const columnaEnvio = [

    {
        title:"Documento de Identidad",
        dataIndex:"numeroDocumentoIdentidad",
        align:"center",            

    },
    {
    title:"Nombres completos",
    dataIndex:"nombresCompletos",
    align:"center",            

    },
    {
        title:"Correo Notificado",
        dataIndex:"correoElectronico",
        align:"center",  
        render: ((correoElectronico)=>correoElectronico!=null?<div><LoginOutlined style={{ fontSize: '20px', color: 'goldenrod' }} /> {correoElectronico}</div>:"")
    },
    {
        title:"Celular Notificado",
        dataIndex:"celular",
        align:"center",  
    },
   {
        title:"Fecha de Envío",
        dataIndex:"fechaEnvio",
        align:"center",  
        render: ((fechaEnvio) => moment(new Date(fechaEnvio)).format("DD/MM/YYYY HH:mm"))
    } ,
];
