import React, { useContext, useState, useEffect } from "react";
import { Form, Row, Col, Select, notification, message, Input, Table, Button, Switch, Space, Typography, Tag } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import {
  empresasAgregadasAtom,
  empresaSeleccionadaAtom,
  empresasEnviadasAtom,
  visibleModalFiltrarAtom,
  visibleModalRolAtom,
} from "../../UsuarioCrearStore";
import { SecurityContext } from "context/SecurityContextProvider";
import UsuarioService from "services/UsuarioService";
import { useHistory } from "react-router";
import { tipoDocumentoIdentidad } from "enums/TipoDocumentoIdentidad";
import TipoDocumentoService from "services/TipoDocumentoService";
import { EmpresaService } from "services/EmpresaService";
import CarpetaService from "services/CarpetaService";

const { Option } = Select;
const { Text } = Typography;

const FormularioCrear = () => {
  const history = useHistory();
  const [frmCrearUsuario] = Form.useForm();
  const { getUsuarioAutenticado } = useContext(SecurityContext);

  const [empresas, setEmpresas] = useState([]);
  const [, setCarpetas] = useState([]);
  const [, setTipoDocumento] = useState([]);
  const [loading, setLoading] = useState(false);
  const [empresasEnviadas, setEmpresasEnviadas] = useAtom(empresasEnviadasAtom);
  const [empresasAgregadas, setEmpresasAgregadas] = useAtom(empresasAgregadasAtom);
  const [empresaSeleccionada, setEmpresaSeleccionada] = useAtom(empresaSeleccionadaAtom);
  const [, setVisibleModalFiltrar] = useAtom(visibleModalFiltrarAtom);
  const [, setVisibleModalRol] = useAtom(visibleModalRolAtom);

  useEffect(() => {
    async function cargarDatos() {
      const _tipoDocumento = await TipoDocumentoService.listarPorEmpresaYCategoria(getUsuarioAutenticado().empresaSeleccionada.id, "");
      setTipoDocumento(_tipoDocumento);
      const empresas = await EmpresaService.listarPorOrganizacionId(getUsuarioAutenticado().organizacionId);
      setEmpresas(empresas);
      const _carpetas = await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
      setCarpetas(_carpetas);
    }
    cargarDatos();
  }, []);

  const handleOnFinish = async (usuario) => {
    try {
      if (empresasAgregadas.length !== 0) {
        setLoading(true);
        usuario.empresas = empresasEnviadas;
        usuario.organizacion = {
          id: getUsuarioAutenticado().organizacionId,
          subdominio: getUsuarioAutenticado().subdominio,
        };

        //usuario.estadoClaveGenerada = estadoclaveGenerada;

        const indexEmpresas = empresasEnviadas.find((item) => item.rolesId == undefined);
        if (indexEmpresas == undefined) {
          const respuesta = await UsuarioService.insertar(usuario);
          //if (estadoclaveGenerada && respuesta.primeraClave === true) {
          if (usuario.estadoClaveGenerada && respuesta.primeraClave === true) {
            notification.success({
              message: "Operación exitosa",
              description: "Se registró al usuario correctamente, la clave de inicio de sesión será enviada al correo que usted ingresó.",
            });
          } else {
            notification.success({
              message: "Operación exitosa",
              description: "Se registró al usuario correctamente",
            });
          }
          setLoading(false);
          history.push("/usuario");
        } else {
          console.log(indexEmpresas.length);
          setLoading(false);
          message.error("La(s) empresa(s) seleccionada(s) debe tener al menos 1 rol asignado");
        }
      } else {
        message.error("No hay empresas agregadas para este usuario.");
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: "No se puede registrar el usuario",
        //description: "Error " + error.response.data.mensaje,
        description: error.response.data.mensaje,
      });
      setLoading(false);
    }
  };

  const handleOnSelectEmpresa = (valores, option) => {
    setEmpresaSeleccionada(option.value);
  };

  const handleOnClickAgregar = () => {
    if (empresaSeleccionada !== undefined) {
      const empresa = empresasAgregadas.find((empresa) => empresa.id === empresaSeleccionada);
      if (empresa === undefined) {
        const empresaBuscada = empresas.find((empresa) => empresa.id === empresaSeleccionada);
        const empresaAgregada = {
          id: empresaBuscada.id,
          razonSocial: empresaBuscada.razonSocial,
          filtroUsuarioNotificador: "todos",
          filtroSede: undefined,
          filtroCargo: undefined,
          filtroTipoDocumento: undefined,
          filtroCarpeta: undefined,
          filtroPlantillaOnboarding : undefined,
          filtroPerfil: undefined,
          filtroSubSede: undefined,
          filtroSeccion: undefined,
          filtroTipoPlanilla: undefined,
        };
        const empresaEnviada = {
          id: empresaBuscada.id,
          razonSocial: empresaBuscada.razonSocial,
          urlImagenLogotipo: empresaBuscada.urlImagenLogotipo,
          rolesId: undefined,
          filtroUsuarioNotificador: "todos",
          filtroSede: undefined,
          filtroCargo: undefined,
          filtroTipoDocumento: undefined,
          filtroCarpeta: undefined,
          filtroPlantillaOnboarding : undefined,
          filtroPerfil: undefined,
          filtroSubSede: undefined,
          filtroSeccion: undefined,
          filtroTipoPlanilla: undefined,
        };
        setEmpresasAgregadas([...empresasAgregadas, empresaAgregada]);
        setEmpresasEnviadas([...empresasEnviadas, empresaEnviada]);
        //setEmpresaSeleccionada();
      } else {
        message.error("La empresa ya ha sido agregada");
      }
    } else {
      message.error("No ha seleccionado la empresa");
    }
  };

  const onClickConfigurarFiltros = (empresa) => {
    setEmpresaSeleccionada(empresa);
    setVisibleModalFiltrar(true);
  };

  const onClickConfigurarRoles = (empresa) => {
    setEmpresaSeleccionada(empresa);
    setVisibleModalRol(true);
  };

  const handleOnClickQuitar = (empresa) => {
    setEmpresasAgregadas(empresasAgregadas.filter((item) => item.id !== empresa.id));
    setEmpresasEnviadas(empresasEnviadas.filter((item) => item.id !== empresa.id));
  };

  const columnasTabla = [
    {
      title: "Empresa",
      dataIndex: "razonSocial",
      key: "razonSocial",
      align: "left",
    },

    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      render: (text, fila) => (
        <React.Fragment>
          <Button type="link" size="middle" onClick={() => onClickConfigurarFiltros(fila)}>
            Configurar Filtros
          </Button>
          <Button type="link" size="middle" onClick={() => onClickConfigurarRoles(fila)}>
            Configurar Roles
          </Button>
          <Button type="link" size="middle" onClick={() => handleOnClickQuitar(fila)}>
            Quitar
          </Button>
        </React.Fragment>
      ),
    },
  ];

  const expanded = {
    expandedRowRender: (record, i) => {
      return (
        <React.Fragment>
          {record.roles ? (
            <React.Fragment>
              <Text>{"Roles: "}</Text>
              {record.roles.map((tag) => (
                <Tag color="blue" key={tag}>
                  {tag}
                </Tag>
              ))}
            </React.Fragment>
          ) : (
            "No se han asignado Roles"
          )}
        </React.Fragment>
      );
    },
  };

  const handleOnClickCancelar = () => {
    history.push("/usuario");
  };

  return (
    <Form form={frmCrearUsuario} layout="vertical" size="large" onFinish={handleOnFinish}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Tipo Documento Identidad"
            name="tipoDocumentoIdentidad"
            rules={[
              {
                required: true,
                message: "Selecciona tu tipo de documento de identidad",
              },
            ]}
          >
            <Select allowClear>
              <Option value={tipoDocumentoIdentidad.DNI.valor}>{tipoDocumentoIdentidad.DNI.nombre}</Option>
              <Option value={tipoDocumentoIdentidad.CARNET_EXTRANJERIA.valor}>
                {tipoDocumentoIdentidad.CARNET_EXTRANJERIA.descripcion}
              </Option>
              <Option value={tipoDocumentoIdentidad.PASAPORTE.valor}>{tipoDocumentoIdentidad.PASAPORTE.nombre}</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Número de Documento Identidad"
            name="numeroDocumentoIdentidad"
            rules={[
              {
                required: true,
                message: "Escribe el número de tu documento de identidad",
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label="Apellidos"
            name="apellidos"
            rules={[
              {
                required: true,
                message: "Escribe tus apellidos",
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Nombres"
            name="nombres"
            rules={[
              {
                required: true,
                message: "Escribe los nombres completos del usuario",
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Login"
            name="login"
            rules={[
              {
                required: true,
                message: "Escribe el login para acceder a tu cuenta",
              },
              {
                type: "email",
                message: "Correo electrónico ingresado no es válido",
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Empresas" name="empresas">
            <Select placeholder="Seleccionar la empresa" onSelect={handleOnSelectEmpresa}>
              {empresas.length > 0 &&
                empresas.map((empresa) => {
                  return (
                    <Option key={empresa.id} value={empresa.id}>
                      {empresa.razonSocial}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="">
            <Button size="middle" onClick={handleOnClickAgregar} shape="round">
              Agregar Empresa
            </Button>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="">
            <Table
              rowKey={(empresa) => empresa.id}
              columns={columnasTabla}
              dataSource={empresasAgregadas}
              expandable={{
                ...expanded,
              }}
              pagination={false}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item label="Generar Clave" name="estadoClaveGenerada" valuePropName="checked" initialValue={false}>
            {/* <Switch checkedChildren="Si" unCheckedChildren="No" onChange={handleOnChangeGenerarClave} /> */}
            <Switch checkedChildren="Si" unCheckedChildren="No" />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="center">
        <Space>
          <Col>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="default" onClick={handleOnClickCancelar}>
                Cancelar
              </Button>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item style={{ textAlign: "center" }}>
              <Button type="primary" icon={<CheckOutlined />} htmlType="submit" loading={loading}>
                Crear Usuario
              </Button>
            </Form.Item>
          </Col>
        </Space>
      </Row>
    </Form>
  );
};

export default FormularioCrear;
