import { BaseService } from "./BaseService";
import { HttpService } from "./HttpService";

const TipoPlanillaService = {
  async listar(empresaId) {
    const respuesta = await HttpService(empresaId).get("/tipoPlanilla");
    return respuesta.data;
  },
  async listarPromise(empresaId) {
    return HttpService(empresaId).get("/tipoPlanilla");
  },
  async insertar(tipoPlanilla) {
    const respuesta = await BaseService.post("/tipoPlanilla", tipoPlanilla);
    return respuesta.data;
  },

  async actualizar(tipoPlanilla) {
    const respuesta = await BaseService.put("/tipoPlanilla", tipoPlanilla);
    return respuesta.data;
  },
  async buscarPorId(id) {
    const respuesta = await BaseService.get(`/tipoPlanilla/${id}`);
    return respuesta.data;
  },
  async eliminar(id) {
    const respuesta = await BaseService.delete(`/tipoPlanilla/${id}`);
    return respuesta.data;
  },
  async listarPorUsuario(login) {
    const respuesta = await BaseService.get("/tipoPlanilla/usuario", {
      params: {
        login: login,
      },
    });
    return respuesta.data;
  },
};
export default TipoPlanillaService;
