import React, { useContext } from "react";
import { Modal } from "antd";
import { cssBodyVisorPdf, cssVisorPdf } from "./VisorPdfStyles"
import { Header, Body } from "./componentes"
import { VisorPdfContext } from "context/visor-pdf"

const VisorPdf = () => {

    const { visible, cerrarVisorPdf } = useContext(VisorPdfContext);

    return (
        <React.Fragment>
            <Modal
                title={null}
                visible={visible}
                onCancel={cerrarVisorPdf}
                bodyStyle={cssBodyVisorPdf}
                style={cssVisorPdf}
                closable={false}
                footer={null}
                className="milegajo-ant-modal"
                width="calc(100vw - 60px)"
                height="calc(100vh - 60px)"
            >

                <Header />

                <Body />

            </Modal>

        </React.Fragment >
    );
}

export default VisorPdf;