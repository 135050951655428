import { BaseService } from "./BaseService";
import qs from "qs";
const VerificarDatosColaboradorObservadosService = {
    async listar(empresaId) {
    const respuesta = await BaseService.get(
      "/verificarDatosColaboradorObservados/listar",
      {
        params: {
          empresaId: empresaId,
        },
      }
    );
    return respuesta.data;
  },
  async aprobarVerificacionDatosColaborador(colaboradorVerificado){
    const respuesta = await BaseService.post("/verificarDatosColaboradorObservados/aprobarVerificacion", colaboradorVerificado );
    return respuesta.data;
  },
  async listarRegistros(empresaId) {
    const respuesta = await BaseService.get("/verificarDatosColaboradorObservados/listarRegistros", {
        params: {   empresaId: empresaId,   }, 
      });
    return respuesta.data;
  },
  async aprobarVerificacionDatosColaboradorMasivo(registroVerificarId){
    const respuesta = await BaseService.post("/verificarDatosColaboradorObservados/aprobarVerificacionMasivo",
    {
      registroVerificarId:registroVerificarId
    }
  );
    return respuesta.data;
  },
    
}
export default VerificarDatosColaboradorObservadosService;