import React, { useEffect } from "react";
import { Tag, Popover, Table, Button, Progress } from "antd";
import { CheckCircleOutlined, LoginOutlined, InfoCircleOutlined,  EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

export const TablaAvanceAprobados = ({ documento}) => {

        const columnas = [
        
            {
                title: "Enviados",
                dataIndex: "enviados",
                key: "enviados",
                align: 'center'
            },
            {
                title: "Aprobados",
                dataIndex: "totalAprobados",
                key: "totalFirmados",
                align: 'center'
            },
            {
                title: "Rechazados",
                dataIndex: "totalRechazados",
                key: "pendientes",
                align: 'center'
            },
        ];
        const notificacion = [
            {
                key: '1',
                enviados: <React.Fragment><LoginOutlined style={{ color: 'green' }} />&nbsp;{documento?.resumenOnboarding.totalEnvio}</React.Fragment>,
                totalAprobados: <React.Fragment><CheckCircleOutlined style={{ color: 'green' }} />&nbsp;{documento?.resumenOnboarding.totalAprobados}</React.Fragment>,
                totalRechazados: <React.Fragment><InfoCircleOutlined style={{ color: 'orange' }} />&nbsp;{documento?.resumenOnboarding.totalRechazados}</React.Fragment>
            }
        ];



        const datosVisualizacion = (
            <div>
                <Table dataSource={notificacion} columns={columnas} pagination={false} />
            </div>
        );

            return(
                <div style={{ width: 170 }}>
                <Popover content={datosVisualizacion} placement="bottom" trigger="click">
                <Button block type="link" ><Progress percent={Math.round((documento?.resumenOnboarding.totalAprobados) * 100 / (documento?.resumenOnboarding?.totalEnvio))} size="small" /></Button>
                </Popover>
                </div>);
               
}
