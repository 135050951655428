import React, { useEffect, useContext, useState } from "react";
import { Container } from "../../components/Container";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { Table, Breadcrumb, notification, Modal, Button, Descriptions, Space, Alert, Divider, Row } from "antd";
import { columnas } from "./componentes/Columnas";
import {
  codigosOpcionesAtom,
  datosPaginacionAtom,
  detalleEnvioSeguimientoAtom,
  documentoEnvioAtom,
  documentosConErrorAtom,
  documentosSeleccionadoAtom,
  resumenAtom,
  urlAtom,
  visibleAtom,
} from "./ListarNotificacionCompletarDetalleStore";
import { SecurityContext } from "../../context/SecurityContextProvider";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import DetalleEnvioService from "services/DetalleEnvioService";
import { categoriaSeguimientoAtom, totalPaginacionDetalleEnviosAtom } from "store/local/seguimiento/SeguimientoStore";
import { BotonesGlobales } from "pages/notificacion-por-completar-detalle/componentes/BotonesGlobales";
import { OpcionService } from "services";
import produce from "immer";
import { ModalPDF } from "components/PDFViewer/visorCrearNotificacion";
import Bowser from "bowser";
import Axios from "axios";
import { EnvioService } from "services/EnvioService";
import UsuarioService from "services/UsuarioService";

function ListarNotificacionCompletarDetalle() {
  const { envioId } = useParams();

  const [, setCodigosOpciones] = useAtom(codigosOpcionesAtom);
  const [detalleEnvio, setDetalleEnvio] = useAtom(detalleEnvioSeguimientoAtom);
  const [documentosSeleccionados, setDocumentosSeleccionados] = useAtom(documentosSeleccionadoAtom);
  const [paginacionDetalleEnvios, setPaginacionDetalleEnvios] = useAtom(totalPaginacionDetalleEnviosAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [datosPaginacion, setDatosPaginacion] = useAtom(datosPaginacionAtom);
  const [loading, setLoading] = useState(false);
  const [url] = useAtom(urlAtom);
  const [visible, setVisible] = useAtom(visibleAtom);
  const [titulo, setTitulo] = useState();
  const [documentoEnvio, setDocumentoEnvio] = useAtom(documentoEnvioAtom);
  const [visibleModalResumen, setVisibleModalResumen] = useState(false);
  const [usuarioAprobador, setUsuarioAprobador] = useState(null);
  let history = useHistory();

  async function procesarNotificacion() {
    const browser = Bowser.parse(window.navigator.userAgent);
    //const res = await Axios.get("https://geolocation-db.com/json/");

    try {
      setLoading(true);
      const datos = {
        navegador: browser.browser.name,
        navegadorVersion: browser.browser.version,
        os: browser.os.name,
        osVersion: browser.os.versionName,
        //ipEnvio: res.data.IPv4,
        id: envioId,
        organizacionId: getUsuarioAutenticado().organizacionId,
        empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
        envioId: envioId,
        tipoDocumentoId: documentoEnvio.tipoDocumentoId,
        tipoDocumentoNombre: documentoEnvio.documentoNombre,
        descripcion: documentoEnvio.descripcion,
        usuarioLogin: getUsuarioAutenticado().login,
        usuarioCorreoElectronico: getUsuarioAutenticado().correoElectronico,
        usuarioNombreCompleto: getUsuarioAutenticado().nombreCompleto,
        estadoEnvio: documentoEnvio.flujo != "SIMPLE_SIN_APROBADOR" ? 1 : 0,
        usuariosAprobadores: documentoEnvio.flujo != "SIMPLE_SIN_APROBADOR" ? documentoEnvio.usuariosAprobadores : null,
        documentosSeleccionados: documentosSeleccionados,
        totalDetallesSeleccionados: documentosSeleccionados.length,
        totalDetallesPendientes: detalleEnvio.length - documentosSeleccionados.length,
        botonNotificacionPorCompletar: true,
      };
      console.log(datos);

      const data = await EnvioService.notificarNuevo(datos);
      notification.success({
        message: "Operación exitosa",
        description: "Se realizo el proceso correctamente.",
      });
      setLoading(false);
      cerrarModalResumen();

      history.push(`/notificacion/seguimiento/detalle/Documento_Laboral/${data.id}`);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  const onClickBtnRetornar = () => {
    history.push("/notificacion-por-completar");
  };

  useEffect(() => {
    const cargarDatos = async () => {
      setLoading(true);

      const detalleEnvios = await DetalleEnvioService.listarDetalleNotificacionPorCompletar(
        getUsuarioAutenticado().empresaSeleccionada.id,
        envioId
      );
      const documentoEnvio_ = await EnvioService.buscarPorId(envioId);
      setDocumentoEnvio(documentoEnvio_);
      try {
        if (documentoEnvio_.usuariosAprobadores.length > 0) {
          setUsuarioAprobador(documentoEnvio_.usuariosAprobadores[0].nombreCompleto);
        }
      } catch {
        setUsuarioAprobador(null);
      }
      setDetalleEnvio(detalleEnvios.detalles);
      setPaginacionDetalleEnvios(detalleEnvios.totalColaboradores);
      setTitulo(
        detalleEnvios.detalles[0] === undefined
          ? ""
          : detalleEnvios.detalles[0]?.envio?.descripcion !== null
          ? detalleEnvios.detalles[0].envio.descripcion
          : detalleEnvios.detalles[0].envio.tipoDocumentoNombre + " " + detalleEnvios.detalles[0].periodo
      );
      const codigosOpciones = await OpcionService.listar(getUsuarioAutenticado().id);
      setCodigosOpciones(codigosOpciones);
      setLoading(false);
      setDocumentosSeleccionados(detalleEnvios.detalles.map((d) => d.id));
    };
    cargarDatos();
  }, []);

  async function handleOnClickNotificar() {
    setVisibleModalResumen(true);
  }

  function cerrarModalResumen() {
    setVisibleModalResumen(false);
  }

  useEffect(() => {
    setDatosPaginacion({ ...datosPaginacion, paginaActual: 1 });
    setPaginacionDetalleEnvios(detalleEnvio.length);
  }, [detalleEnvio]);

  const rowSelection = {
    selectedRowKeys: documentosSeleccionados,

    onChange: (selectedRowKeys, selectedRows) => {
      setDocumentosSeleccionados(selectedRowKeys);
    },
    getCheckboxProps: (documento) => ({
      disabled: documento.eliminado === true,
    }),
  };

  function handleOnOkModal() {
    setVisible(false);
  }

  const onPageChange = async (paginaActual, paginaSize) => {
    setDatosPaginacion({ paginaActual: paginaActual, registrosPorPagina: paginaSize });
  };
  return (
    <Container
      icono={
        <Breadcrumb>
          <Breadcrumb.Item href="/dashboard">
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/notificacion-por-completar">Notificación por completar</Breadcrumb.Item>
          <Breadcrumb.Item href={null}>{titulo}</Breadcrumb.Item>
        </Breadcrumb>
      }
      onClickRetornar={onClickBtnRetornar}
      botonExtra={
        <BotonesGlobales
          botonName={usuarioAprobador != null ? "Solicitar Aprobación" : "Notificar"}
          onClickEnviar={handleOnClickNotificar}
          usuariosSeleccionados={documentosSeleccionados}
        />
      }
    >
      {usuarioAprobador != null && (
        <React.Fragment>
          <Row>
            <UserOutlined /> &nbsp; Usuario Aprobador : {usuarioAprobador}
          </Row>
          <Divider />
        </React.Fragment>
      )}
      <ModalPDF ruta={url} visible={visible} handleOnOkModal={handleOnOkModal} />
      {/* <FiltroBusqueda /> */}
      <Table
        loading={loading}
        rowKey={(detalleEnvio) => detalleEnvio.id}
        dataSource={detalleEnvio}
        columns={columnas}
        rowSelection={rowSelection}
        pagination={{
          size: "small",
          total: paginacionDetalleEnvios,
          showTotal: (total) => `Total ${total} documentos`,
          current: datosPaginacion.paginaActual,
          pageSize: datosPaginacion.registrosPorPagina,
          onChange: onPageChange,
        }}
      />
      <Modal
        title="Resumen"
        width={600}
        visible={visibleModalResumen}
        onOk={procesarNotificacion}
        onCancel={cerrarModalResumen}
        width={650}
        footer={[
          <Button key="back" onClick={cerrarModalResumen}>
            Cancelar
          </Button>,
          <Button key="submit" type="primary" loading={loading} onClick={procesarNotificacion}>
            {usuarioAprobador != null ? "Solicitar Aprobación" : "Enviar"}
          </Button>,
        ]}
      >
        <Space direction="vertical" size={10}>
          <Descriptions bordered size="small" column={1}>
            <Descriptions.Item label="Documento">{documentoEnvio?.tipoDocumentoNombre}</Descriptions.Item>
            <Descriptions.Item label="Descripción">{documentoEnvio?.descripcion}</Descriptions.Item>
            <Descriptions.Item label="Colaboradores a notificar">{documentosSeleccionados?.length}</Descriptions.Item>
            <Descriptions.Item label="Colaboradores pendientes de Notificar *">
              {detalleEnvio.length - documentosSeleccionados?.length}
            </Descriptions.Item>
            <Descriptions.Item label="Responsable de Envio">{getUsuarioAutenticado().nombreCompleto}</Descriptions.Item>
          </Descriptions>
          <Alert
            message="(*) Colaboradores pendientes de notificar: Estos colaboradores no serán notificados en este envío"
            type="warning"
          />
        </Space>
      </Modal>
    </Container>
  );
}

export default ListarNotificacionCompletarDetalle;
