import { useEffect } from "react";
import { useAtom } from "jotai";
import { contratosAtom } from "../BandejaAprobacionAdministracionPersonalStore";
import { BandejaAprobacionAdministracionPersonalService } from "services/contratos/BandejaAprobacionAdministracionPersonalService";

const useListarContratosPendientesAprobar = () => {
    const [contratos, setContratos] = useAtom(contratosAtom);

    useEffect(() => {
        const cargarDatos = async () => {
            const contratos = await BandejaAprobacionAdministracionPersonalService.listarContratosPendientesAprobar();
            setContratos(contratos);
        };
        cargarDatos();
    }, []);

    return { contratos, setContratos};
};

export default useListarContratosPendientesAprobar;
