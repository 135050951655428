import { BaseService } from "./BaseService";

export const EnvioService = {
  async buscarPorId(envioId) {
    const respuesta = await BaseService.get(`/envio/${envioId}`);
    return respuesta.data;
  },

  async listarSeguimiento(
    empresaId,
    nombreCategoriaDocumento,
    nombreTipoDocumento,
    periodoInicio,
    periodoFin,
    pagina,
    cantidad,
    login,
    estado
  ) {
    const respuesta = await BaseService.get("/envio/seguimiento", {
      params: {
        empresaId: empresaId,
        nombreCategoriaDocumento: nombreCategoriaDocumento,
        nombreTipoDocumento: nombreTipoDocumento,
        periodoInicio: periodoInicio,
        periodoFin: periodoFin,
        pagina: pagina,
        cantidad: cantidad,
        login: login,
        estado: estado,
      },
    });
    return respuesta.data;
  },

  async listarNotificacionPorCompletar(empresaId, nombreTipoDocumento, periodoInicio, periodoFin, login) {
    const respuesta = await BaseService.get("/envio/notificacion-por-completar", {
      params: {
        empresaId: empresaId,
        nombreTipoDocumento: nombreTipoDocumento,
        periodoInicio: periodoInicio,
        periodoFin: periodoFin,
        login: login,
      },
    });
    return respuesta.data;
  },

  async listar(empresaId) {
    const respuesta = await BaseService.get("/envio", {
      params: { empresaId: empresaId },
    });
    return respuesta.data;
  },

  async verPDF(fd) {
    const respuesta = await BaseService.post("/envio/verPDF", fd, {
      responseType: "blob",
    });
    const data = respuesta.data;
    return data;
  },

  async vistaPrevia(data) {
    const respuesta = await BaseService.get(
      `/envio/empresa/${data.empresaId}/detalle-envio/${data.detalleEnvioId}/vista-previa`,
      {
        responseType: "blob",
      }
    );
    return respuesta.data;
  },

  async notificar(envio) {
    const respuesta = await BaseService.post("/envio/notificar", envio);
    return respuesta.data;
  },

  async notificarNuevo(envio) {
    const respuesta = await BaseService.post("/envio/notificarNuevo", envio);
    return respuesta.data;
  },

  async inhabilitar(id) {
    const respuesta = await BaseService.delete(`/envio/${id}`);
    return respuesta.data;
  },
  async habilitar(id) {
    const respuesta = await BaseService.post(`/envio/${id}`);
    return respuesta.data;
  },
  async listarPorAprobar(empresaId, login) {
    const respuesta = await BaseService.get("envio/listarPorAprobar", {
      params: {
        empresaId: empresaId,
        login: login,
      },
    });
    return respuesta.data;
  },
  async crear(envio) {
    const respuesta = await BaseService.post("/envio/crear", envio);
    return respuesta.data;
  },
  async aprobarEnvio(envio) {
    const respuesta = await BaseService.post("/envio/aprobarNotificacion", envio);
    return respuesta.data;
  },
  async verPDFExcelOrWs(fd) {
    const respuesta = await BaseService.post("/envio/verPDFExcelOrWs", fd, {
      responseType: "blob",
    });
    const data = respuesta.data;
    return data;
  },

  async rechazarEnvio(envio) {
    const respuesta = await BaseService.post("/envio/rechazarNotificacion", envio);
    return respuesta.data;
  },
  async eliminarTodos(empresaId) {
    const respuesta = await BaseService.get("/envio/eliminarTodos", {
      params: {
        empresaId: empresaId,
      },
    });
    return respuesta.data;
  },
  async asignarCarpeta(envio) {
    const respuesta = await BaseService.post("/envio/asignarCarpeta", envio);
    return respuesta.data;
  },
};
