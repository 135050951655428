import React, { useState, useEffect, useRef } from "react";
import { Container } from "../../../components/Container";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { Button, Space, Table, notification, Popconfirm } from "antd";
import {
    UnorderedListOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import CertificadoService from "../../../services/CertificadoService";
import ModalCrearCertificadoDigital from "../crear";
export function CertificadosDigitalesListarPage() {
    const [certificados, setCertificados] = useState([]);
    const [visibleModal, setVisibleModal] = useState(false);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [listaDocumentos, setListaDocumentos] = useState([]);
    const [loading, setLoading] = useState(false);
    
    useEffect(() => {
    async function cargarDatos() {
      const _certificados = await CertificadoService.listar(
          getUsuarioAutenticado().empresaSeleccionada.id,
          '',
          '-1'
      );
      setCertificados(_certificados);
    }

    cargarDatos();
    }, []);
    
    function handleOnClickCrearCertificado() {
        setVisibleModal(true);
    }

    async function handleOnClickQuitarCertificado(certificadoId) {
      try {
        const certificados = await CertificadoService.eliminar(
          getUsuarioAutenticado().empresaSeleccionada.id,
          certificadoId
        );
        setCertificados(certificados);
        notification.success({
          message: "Operación exitosa",
          description: "Se registró el certificado correctamente.",
        });
      } catch (error) {
        notification.error({
          message: "Alerta!",
          description: error.response.data.mensaje,
        });
      }
    }

    function cerrarModal() {
        setVisibleModal(false);
    }

    async function handleOnFinish(formulario) {
      const formData = new FormData();

      try {
        setLoading(true);
        //formData.append('file', listaDocumentos);
        formData.append("archivo", formulario.archivo.file);
        formData.append("clave", formulario.clave);
        formData.append(
          "empresaId",
          getUsuarioAutenticado().empresaSeleccionada.id
        );
        formData.append("usuarioId", formulario.usuarioId);
        formData.append("estado", "-1");

        const _certificados = await CertificadoService.insertar(formData);
        setCertificados(_certificados);
        notification.success({
          message: "Operación exitosa",
          description: "Se registró el certificado correctamente.",
        });
        setVisibleModal(false);
        setLoading(false);
        //history.push("/cargo");
      } catch (error) {
        notification.error({
          message: "Alerta!",
          description: error.response.data.mensaje,
        });
      setLoading(false);
      }
    }

    const columnas = [
    {
      title: "Serie",
      dataIndex: "serie",
      align: "center",
    },
    {
      title: "Representante Legal",
      dataIndex: "representanteLegalNombre",
      align: "center",
    },
    // {
    //   title: "Fecha Vigencia Inicio",
    //   dataIndex: "fechaVigenciaInicio",
    //   align: "center",
    // },
    // {
    //   title: "Fecha Vigencia Fin",
    //   dataIndex: "fechaVigenciaFin",
    //   align: "center",
    // },

    {
      title: "Acciones",
      align: "center",
      key: "acciones",
      align: "center",
      render: (text, fila) => (
        <Space size={8}>
          <Popconfirm
            title="¿Está seguro de eliminar el registro seleccionado?"
            okText="Si"
            cancelText="No"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            onConfirm={() => handleOnClickQuitarCertificado(fila.id)}
          >
            <a style={{ color: "#E01D4C" }}>
              {" "}
              <DeleteOutlined /> Quitar
            </a>
          </Popconfirm>
        </Space>
      ),
    },
    ];
    
    return (
        <Container
            icono={<UnorderedListOutlined />}
            titulo="Certificados digitales"
            botonExtra={
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={handleOnClickCrearCertificado}
                >
                    Subir Certificado Digital
        </Button>
            }
        >
            <Table
                rowKey={(fila) => fila.id}
                columns={columnas}
                dataSource={certificados}
                pagination={{
                    pageSize: [10],
                }}
            />

            <ModalCrearCertificadoDigital
                loading={loading}
                visibleModal={visibleModal}
                onCerrarModal={cerrarModal}
                listaDocumentos={listaDocumentos}
                setListaDocumentos={setListaDocumentos}
                handleOnFinish={handleOnFinish}
            />
        </Container>
    );
}