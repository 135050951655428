import { Button } from "antd";
import React from "react";
import { useModalDetalleVacaciones } from "../../hooks";

const MenuAcciones = ({ colaborador }) => {
  const { mostrarModal: mostrarModalDetalleVacaciones } = useModalDetalleVacaciones();

  return (
    <div>
      <Button type="link" onClick={() => mostrarModalDetalleVacaciones(colaborador)}>
        Detalle
      </Button>
    </div>
  );
};

export default MenuAcciones;
