import React from "react";
import { Container } from "components/Container";
import { Provider as JotaiProvider } from "jotai";
import { useHistory } from "react-router-dom";
import { FormularioAgregarPostulante } from "./componentes";

const AgregarPostulantePage=()=>{
    let history=useHistory();

    const onClickRetornar=()=>{
        history.push("/reclutamiento-y-seleccion/convocatorias");
     }

    return(
        <JotaiProvider>
            <Container titulo="Agregar Postulante" onClickRetornar={onClickRetornar}>
              <FormularioAgregarPostulante/>
            </Container>
        </JotaiProvider>
    )

}
export default AgregarPostulantePage;