import React from "react";
import { PanelBotones } from "./PanelBotones"
import { PanelInformacion } from "./PanelInformacion"

const Header = () => {
    return (
        <div className="milegajo-visor-pdf-header">
            <PanelInformacion />
            <PanelBotones />
        </div>
    )
}

export default Header;