import React, { useEffect, useState } from 'react';
import { Form, Modal, Input, notification } from "antd";
import DetalleEnvioService from "services/DetalleEnvioService";

const ModalActualizarCorreo = ({ documento, visible, setVisible, actualizarInformacionColaborador }) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const onClickBtnActualizar = () => {
        form
            .validateFields()
            .then(async (values) => {
                //form.resetFields();
                try {
                    setLoading(true);
                    const data = {
                        id: documento.id,
                        correoElectronico: values.correoElectronico,
                        numeroCelular: values.numeroCelular
                    }

                    const respuesta = await DetalleEnvioService.actualizarCorreoE(data);

                    actualizarInformacionColaborador(documento.id, values.correoElectronico, values.numeroCelular);
                    notification.success({
                        message: "Operación exitosa",
                        description: "Se actualizó el correo electrónico correctamente.",
                    });

                } catch (error) {
                    console.error(error);
                } finally {
                    setVisible(false);
                    setLoading(false);
                }
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    const onClickBtnCerrar = () => {
        setVisible(false);
    }

    useEffect(() => {
        form.resetFields();
    }, [documento]);

    return (
        <Modal
            title="Actualizar Correo"
            visible={visible}
            onOk={onClickBtnActualizar}
            onCancel={onClickBtnCerrar}
            okText="Actualizar"
            cancelText="Cancelar"
            confirmLoading={loading}
            forceRender
        >

            <Form
                form={form}
                layout="vertical"
                name="frmActualizarCorreo"
                initialValues={{
                    correoElectronico: documento?.colaborador?.correoElectronico,
                    numeroCelular: documento?.colaborador?.numeroCelular
                }}
            >
                <Form.Item
                    name="correoElectronico"
                    label="Correo Electronico"
                    rules={[
                        {
                            required: true,
                            message: 'Campo correo electrónico es obligatorio.',
                        },
                        {
                            type: 'email',
                            message: "Correo electrónico ingresado no es válido.",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item name="numeroCelular" label="Celular">
                    <Input />
                </Form.Item>

            </Form>


        </Modal>
    );
};

export default ModalActualizarCorreo;