import React from "react";
import { FilterServer } from "components/MultiFiltro";
import { usePaginacion } from "../../hooks";

const FiltroTablaControlVacaciones = () => {
  const { onFiltrar } = usePaginacion();

  const filtrosBusqueda = {
    titulo: "Filtrar por: ",
    data: [
      { id: "colaborador", titulo: "Apellidos y Nombres", tipo: "texto" },
      { id: "documentoIdentidad", titulo: "Documento Identidad", tipo: "texto" },
      { id: "codigoColaborador", titulo: "Código de Colaborador", tipo: "texto" },
    ],
  };

  return (
    <FilterServer
      placeholder="Haz clic aquí para filtrar los colaboradores"
      filtros={filtrosBusqueda}
      onFiltrarDatos={onFiltrar}
      //reset={reset}
      filtroSeleccionadoInicial={{ id: "apellidosNombres", titulo: "Apellidos y Nombres", tipo: "texto" }}
    />
  );
};

export default FiltroTablaControlVacaciones;
