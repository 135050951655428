import React, { useEffect ,useContext} from "react";
import {Modal,Row,Col,message,Divider} from "antd"
import { useAtom } from "jotai";
import { Form,Input,Button,Upload,Table } from "antd";
import{UploadOutlined,SearchOutlined} from "@ant-design/icons";
import {solicitudAusenciaSeleccionadaAtom,visibleModalAdjuntarAltaMedicaAtom} from "../../consultaSolicitudesStore";
import { useState } from "react";
import  {SolicitudAusenciaService} from "services";
import { ApiResponse } from "enums";
import { useModalVisorPdf } from "store/global";
import { SecurityContext } from "context/SecurityContextProvider";
import {FormatoCarga} from "enums/FormatoCarga"



const ModalAdjuntarAltaMedica=()=>{
    const [visibleModalAdjuntarAltaMedica,setVisibleModalAdjuntarAltaMedica] = useAtom(visibleModalAdjuntarAltaMedicaAtom);
    const [solicitudSeleccionada,setSolicitudSeleccionada] = useAtom(solicitudAusenciaSeleccionadaAtom);
    const [archivos, setArchivos] = useState(undefined);
    const [formAdjuntoAltaMedica]=Form.useForm();
    const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal);
    const [loading, setLoading]=useState(false);
    useEffect(()=>{

    },[solicitudSeleccionada])

    const onClickBtnCancelar=()=>{
        setVisibleModalAdjuntarAltaMedica(false);
        formAdjuntoAltaMedica.resetFields();
    }
    const props = {
        onRemove: (file) => {
            setArchivos([]);
           
            return {
                archivos: [],           
  
            };
     
        },
        beforeUpload: (file) => {
          if (file.type !== 'application/pdf') {
            message.error(`${file.name} no es un archivo PDF`);
          }
          else{
            setArchivos(file);          
            return false;       
          }
                
       
        },
          archivos,
       
    }; 
    const onClikEnviar = async(formulario) =>{
      setLoading(true);
      const formData=new FormData();
        formData.append("file",formulario.adjunto.file);
        formData.append("solicitudId",solicitudSeleccionada.id);
       const respuesta= await SolicitudAusenciaService.agregarAltaMedica(formData);
       if(respuesta.estado===ApiResponse.EXITO){
          setArchivos([]);
          setLoading(false);
          formAdjuntoAltaMedica.resetFields();
       }
       else{
        message.error(respuesta.mensaje);
        setLoading(false);
       }
    }
    const onclickVerDocumento = async()=>{
        
            const respuesta = await SolicitudAusenciaService.getUrlAltaMedica(solicitudSeleccionada.id,"WEB");
           
             if(respuesta.estado===ApiResponse.EXITO){
              onClickBtnCancelar();
              mostrarModalVisorPdf(respuesta.data.urlSolicitud,"Alta Médica ", true, true);
             }
             else{
              message.error(respuesta.mensaje);              
             }          
             

    }
    return(
        <Modal
          title={"Adjuntar alta médica"}     
          visible={visibleModalAdjuntarAltaMedica}     
          onCancel={onClickBtnCancelar}
          footer={null}
          width={600}
        >
          <Form
            form={formAdjuntoAltaMedica}            
            layout="vertical"
            onFinish={onClikEnviar}
          >
            <Row>
               <div
                 style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
               >
                <Form.Item 
                     label="Adjuntar alta médica" 
                     name="adjunto"                    
                     rules={[
                      {
                        required: true,
                        message: "Seleccione el archivo de alta médica",
                      },
                      {
                        validator: (_, value) =>
                          archivos?.length!==0 ? Promise.resolve() : Promise.reject(new Error('No ha Seleccionado el documento')),
                      },
                    ]}   
                                  
                     >
                    <Upload
                       {...props}
                       maxCount={1} 
                       accept={FormatoCarga.PDF_MULTIPAGINA.extension}                      
                    >
                    <Button icon={<UploadOutlined/> } >Cargar Documento</Button>
                    </Upload>
                    
                  </Form.Item>
               </div>
            </Row>
            <Row>
              <Col span={24}>
                  <Form.Item >
                    <Button  htmlType="submit" type="primary" loading={loading} block> Enviar </Button>        
                    
                  </Form.Item>
              </Col>
            </Row>            
          </Form>
          <Divider/>  
          <Row>
            <div
               style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Button type="link" icon={<SearchOutlined/>} onClick={onclickVerDocumento}>Ver Adjunto</Button>    
            </div>
              
          </Row> 
        </Modal>
    )

}
export default ModalAdjuntarAltaMedica;