import React, { useEffect, useContext } from "react";
import { Table } from "antd";
// import { MenuAcciones } from "./MenuAcciones";
import { useAtom } from "jotai";
import { TurnoService } from "services/asistencias/TurnoService";
// import { SecurityContext } from "context/SecurityContextProvider";
// import { VacacionesServiceAnterior } from "services/VacacionesService.bk";
import { turnosAtom } from "../../ListarTurnosStore";

const TablaTurnos = () => {
  const [turnos, setTurnos] = useAtom(turnosAtom);
  const diasSemana = {
    "1": "Lunes",
    "2": "Martes",
    "3": "Miércoles",
    "4": "Jueves",
    "5": "Viernes",
    "6": "Sábado",
    "7": "Domingo"
  };
  // const [solicitudesVacacionesIds, setSolicitudesVacacionesIds] = useAtom(atoms.solicitudesVacacionesIdsAtom);
  // const { getUsuarioAutenticado } = useContext(SecurityContext);

  useEffect(() => {
    const CargarDatos = async () => {
      const turnos = await TurnoService.listar();
      console.log("turnos", turnos);
      setTurnos(turnos);
    };
    CargarDatos();
  }, []);

  const columnas = [
    {
      title: "Nombre",
      dataIndex: "nombre",
      align: "center"
    },
    {
      title: "Tipo",
      dataIndex: "tipo",
      align: "center"
    },
    {
      title: "Días",
      align: "center",
      render: (text, fila) => {
        // Convierte los valores numéricos en los nombres de los días
        const dias = fila?.dias?.map(dia => diasSemana[dia]).join(', ');
        return dias;
      },
    },
    {
      title: "Hora Inicio",
      align: "center",
      dataIndex: "horaInicio",
    },
    {
      title: "Hora Fin",
      align: "center",
      dataIndex: "horaFin",
    },
  ];

  return (
    <Table
      rowKey={(turnos) => turnos.id}
      dataSource={turnos}
      columns={columnas}
    />
  );
};
export default TablaTurnos;
