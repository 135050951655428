import React, {  useEffect } from "react";
import { Modal, Form, Input } from "antd";
import { useModalRechazarOnboarding, } from "store/global";


const ModalRechazarOnboarding = () => {

    const visible = useModalRechazarOnboarding(state => state.visible);
    const cerrarModal = useModalRechazarOnboarding(state => state.cerrarModal);
    const detalleId = useModalRechazarOnboarding(state => state.detalleId);
    const terminarProcesoOnboarding = useModalRechazarOnboarding(state => state.terminarProceso);
    //const finalizarModal = useModalVisorPdf(state=>state.rechazarOnboarding)
    const [formRechazo] = Form.useForm();
    const { TextArea } = Input;

    useEffect(() => {
        formRechazo.setFieldsValue({detalleId:detalleId});
        return () => {
            cerrarModal();
        }
    }, []);    

    const onFinishRechazo = (formulario) => {
            const data = {...formulario, detalleId:detalleId}   
        terminarProcesoOnboarding(data);
    }


    return (
        <Modal
            title="Motivo de Rechazo"
            visible={visible}
            onCancel={()=>{cerrarModal();}}
            onOk={()=>formRechazo.submit()}
        >
            <Form
            layout="vertical"
            form={formRechazo}
            onFinish={onFinishRechazo}>
                <Form.Item 
                label="Indica el motivo del rechazo"
                name={"motivoRechazo"}>
                <TextArea showCount maxLength={160} style={{ height: 120 }} autoSize={false} />
                </Form.Item>
                <Form.Item 
                name={"detalleId"}
                hidden>
                <Input />
                </Form.Item>

            </Form>
        </Modal >
    );
}

export default ModalRechazarOnboarding;