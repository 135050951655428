import React from "react";
import { ListaDocumentosGenericos, FiltroDocumentos } from "./componentes"
import { Container } from "components/Container"
import { VisorPdfContextProvider } from "context/visor-pdf"
import { VisorPdf } from "components/VisorPdf";
import { Button } from "antd"
import { useHistory } from "react-router-dom";
import { CloudUploadOutlined } from "@ant-design/icons"

const FirmaDocumentoGenericoListarPage = () => {

    let history = useHistory();

    const onClickBtnCargarDocumento = () => {
        history.push("/notificacion/firma-documento-generico/crear");
    }

    return (
        <VisorPdfContextProvider>

            <Container titulo="Lista de documentos" botonExtra={<Button type="primary" onClick={onClickBtnCargarDocumento}><CloudUploadOutlined /> Cargar Documento</Button>}>

                <FiltroDocumentos />

                <ListaDocumentosGenericos />

                <VisorPdf />

            </Container>

        </VisorPdfContextProvider>
    )
}

export default FirmaDocumentoGenericoListarPage;