import React, { useState, useEffect, useContext } from "react";
import {
  Modal,  Upload,  message,  Button,  Space,  
  Typography,  Row,  notification,  Col,
} from "antd";
import { FileExcelOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { SecurityContext } from "context/SecurityContextProvider";
import { LoginService } from "services/LoginService";
import { Headers } from "enums";
import { FormatoCarga } from "enums/FormatoCarga";
import ColaboradorService from "services/ColaboradorService";
import { saveAs } from "file-saver";

export default function ModalCargarExcel({visibleModal, setVisibleModal, envioId,setSeleccion, setExcelDisabled}) {
    const [listaDocumentoIdentidad, setListaDocumentoIdentidad] = useState([]);
    const [cargaExcel, setCargaExcel] = useState([]);
    const [dataCarga, setDataCarga] = useState();
    const [cargaInfo, setCargaInfo] = useState(false);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [colorBase, setColorBase]= useState("white");
    const [colorBaseBorder, setColorBaseBorder]= useState("green");
    const datos = {
        empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
        envioId:envioId,
      };
    const { Text } = Typography;

    useEffect(() => {
        setListaDocumentoIdentidad([]);
      }, []);
    

    const props = {
        name: "file",
        action: process.env.REACT_APP_ENDPOINT + "colaborador/cargarExcelDocumentoIdentidad",
        showUploadList: "false",
        headers: {
          authorization: "Bearer " + LoginService.getJwtToken(),
          [Headers.ORGANIZACION_ID]:
            LoginService.getUsuarioAutenticado().organizacionId,
          [Headers.EMPRESA_ID]:
            LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
        },
        data: datos,
        onChange(info) {
          const { status } = info.file;
          let fileList = [...info.fileList];
          fileList = fileList.slice(-1);
          if (status !== "uploading") {
          }
          if (status === "done") {
            setDataCarga(info.file.response);
            setCargaInfo(true);
            if(info.file.response.listaColaboradores.length==0){
                notification.warning({
                    message: "Advertencia",
                    description:
                      "No se encontraron Colaboradores por favor revisar el archivo de carga"});
            }else{
                message.success(`${info.file.name} Archivo subido correctamente`);
                setListaDocumentoIdentidad(info.file.response.listaColaboradores);
            } 
          } else if (info.file.status === "error") {
            //console.log("entre3:");
            message.error(`${info.file.name} Carga del archivo ha fallado.`);
          }

          setCargaExcel(fileList)
        },
        progress: {
          strokeColor: {
            "0%": "#108ee9",
            "100%": "#87d068",
          },
          strokeWidth: 3,
          format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
        },
      };
    
      function borrarDatos() {
        setListaDocumentoIdentidad([]);
        setVisibleModal(false);
        setCargaExcel([]);
        setCargaInfo(false);
        setDataCarga();
      }
      
      function handleOnOkButton() {   
        setExcelDisabled(true);
        setSeleccion(listaDocumentoIdentidad);
        setVisibleModal(false);
        setCargaExcel([]);
        setCargaInfo(false);
        setDataCarga();
        setListaDocumentoIdentidad([]);
      } 

      const onClickDescargarPlantilla = async () => {
        try {
          let respuesta = await ColaboradorService.descargarPlantillaSeleccion();
          const filename = respuesta.headers.filename;
          saveAs(respuesta.data, filename);
        } catch (error) {
          notification.error({
            message: "No se puede descargar plantilla de Selección",
          });
        }
      };

    return(<Modal
        title="Importar Colaboradores a Seleccionar"
        visible={visibleModal}
        width={600}
        onCancel={borrarDatos}
        onOk={handleOnOkButton}
        okButtonProps={{ disabled:  listaDocumentoIdentidad.length==0  }}
    >
        <Row
      type="flex"
      style={{ alignItems: 'center' }}
      justify="center"
      gutter={10}
    >
      <Col span={12}>
          <Space direction="vertical"  style={{width: '100%', justifyContent: 'center'}} >
            <Upload {...props} fileList={cargaExcel} accept={FormatoCarga.EXCEL.extension}>
              <Button style={{ borderRadius: 4, borderColor: "green", backgroundColor: colorBase }} 
              onMouseEnter={()=>{
                  setColorBaseBorder("white")
                  setColorBase("green")}} 
               onMouseLeave={()=>{
                  setColorBaseBorder("green")
                  setColorBase("white")}}>
                <FileExcelOutlined style={{ fontSize: 20, color: colorBaseBorder }} />{" "}
                <Text style={{ color: colorBaseBorder }}>Cargar Excel de Colaboradores</Text>
              </Button>
            </Upload>
            </Space>            
            </Col>
        </Row>  
        <br/>
        <Row>
          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Descargar el modelo para la selección de Colaboradores{" "}
            <a
              style={{ color: "#0F7FF0", fontStyle: "italic" }}
              onClick={onClickDescargarPlantilla}
            >
              aquí
            </a>
          </div>
        </Row>
        
        {cargaInfo && (
        <div>
            <br/>
          <Row justify="center">
            <Space size={60}>
              {/*<Tag color="success">*/}

              <Text style={{ fontSize: 12 }}>
                <CheckCircleOutlined style={{ color: "#329BE9 " }} />{" "}
                Procesados: {dataCarga.colaboradorProcesados}
              </Text>

              {/* </Tag> */}
              {/* <Tag color="processing"> */}

              <Text style={{ fontSize: 12 }}>
                <CheckCircleOutlined style={{ color: "#D89817" }} />{" "}
                Seleccionados: {dataCarga.colaboradoresSeleccionados}
              </Text>
              {/* </Tag> */}
              {/* <Tag color="error"> */}

              <Text style={{ fontSize: 12 }}>
                <CheckCircleOutlined style={{ color: "#DD0C3F" }} />{" "}
                No encontrados: {dataCarga.colaboradoresNoEncontrados}
              </Text>

              {/* </Tag> */}
            </Space>
          </Row>
        </div>
      )}
    </Modal>)

}