export const ContratoCrearReducer = (state, action) => {
    switch (action.type) {
        case 'INICIAR':
            return {
                ...state,
                loading: true
            }

        case 'FINALIZAR':
            return {
                ...state,
                loading: false
            }

        case 'SET_CONTRATO':
            return {
                ...state,
                contrato: action.payload
            }

        case 'SET_PLANTILLAS_CONTRATOS':
            return {
                ...state,
                plantillasContratos: action.payload
            }

        case 'MOSTRAR_MODAL_CONFIRMACION':
            return {
                ...state,
                visibleModalConfirmacion: true
            }

        case 'CERRAR_MODAL_CONFIRMACION':
            return {
                ...state,
                visibleModalConfirmacion: false
            }

        default:
            return state;
    }
}