import React from "react";
import { MenuAcciones } from "../MenuAcciones";
import { BotonDocumento, BotonDocumentoNombre } from "../Botones";



export const columnas = [

  {
    title: "#",
    dataIndex: "indice",
    render: (text, fila, index) => (<BotonDocumento documento={fila} index={index} />)
  },
  {
    title: "Periodo",
    dataIndex: "periodo"
  },
  {
    title: "Documento",
    dataIndex: "documento",
    align: "center",
    render: (text, fila) => (<BotonDocumentoNombre documento={fila}/>
    )

  },

  {
    title: "Fecha Envío",
    dataIndex: "fechaEnvio",

  },
  {
    title: "Responsable de Envío",
    dataIndex: ["usuarioEnvio", "nombreCompleto"],
  },
  
  {
    title: "Acciones",
    align: "center",
    //width: "25%",
    render: (text, fila) => <MenuAcciones envio={fila} />,
  },
];
