import { useAtom } from "jotai";
import { useEffect } from "react";
import { colaboradorSeleccionadoAtom, visibleModalDetalleVacacionesAtom } from "../ControlVacacionalStorage";

const useModalDetalleVacaciones = () => {
  const [visible, setVisible] = useAtom(visibleModalDetalleVacacionesAtom);
  const [colaboradorSeleccionado, setColaboradorSeleccionado] = useAtom(colaboradorSeleccionadoAtom);

  const mostrarModal = (colaborador) => {
    setColaboradorSeleccionado(colaborador);
    setVisible(true);
  };

  const ocultarModal = () => {
    setVisible(false);
  };

  return {
    visible,
    colaboradorSeleccionado,
    mostrarModal,
    ocultarModal,
  };
};

export default useModalDetalleVacaciones;
