import { Button, Drawer, message, Modal, Typography,Form, Select, DatePicker, InputNumber, Input } from "antd";
import React, { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CloseCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ConvocatoriaService } from "services/reclutamiento-y-seleccion/ConvocatoriaService";
import { useAtom } from "jotai";
import { isConvocatoriaAtom, solicitudAtom} from "../../DetalleSeguimientoSolicitudPersonalStore";
import moment from "moment";
import ModalAgregarPostulante from "./ModalAgregarPostulante";
import ModalPostulante from "./ModalPostulante";
import { useRef } from "react";
import { AgregarPostulantePage } from "./AgregarPostulantePage";
import { useHistory } from "react-router-dom";
import ModalCerrarProceso from "./ModalCerrarProceso";
const grid = 8;

//contenedor de items
const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: "8px",
  border: "1px",
  background: isDragging ? "#B9B9B9" : "white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  ...draggableStyle,
});

//items
const getListStyle = (isDraggingOver) => ({
  marginInline: "15px",
  borderRadius: "0 0 8px 8px",
  background: isDraggingOver ? "#B0D3DF" : "#E8EBEC",
  padding: grid,
  //minWidth: 230,
  //width: "18vw",
  width: "230px",
  minHeight: "calc( 100% - 3rem )",
  // maxHeight: 550,
  // overflowY: 'auto',
  // overflowX: 'hidden',
});

//contenedor de contenedores
const getListPadreStyle = (isDraggingOver) => ({
  //marginRight: "20px",
  //background:"yellow",
  //minHeight: "calc( 100% - 150px )",
  //minWidth: "100%",
  display: "inline-flex",
  // overflowX: "hidden",
  // overflowY: "auto",
  background: isDraggingOver ? "#F3F9F7" : "white",
  padding: grid,
});

const ConvocatoriasListar = ({ modalAgregarPostulante, setModalAgregarPostulante, restablecer, setRestablecer,modalCerrarProceso,setModalCerrarProceso, cantidad }) => {
  const [solicitud] = useAtom(solicitudAtom);
  const [convocatoriaEtapas, setConvocatoriaEtapas] = useState(undefined);
  const [renderItem, setRenderItem] = useState(false);
  const [modalPostulante, setModalPostulante] = useState(false);
  const [postulanteSeleccionado, setPostulanteSeleccionado] = useState(undefined);
  const [convocatoriaActual, setConvocatoriaActual] = useState(undefined);
  const [drawCrearPostulante, setDrawCrearPostulante] = useState(false);
  const [postulantes, setPostulantes] = useState(undefined);  
  const [isClosable, setIsClosable] = useState(false)
  const [formEntrevista] = Form.useForm();

  const [isFormValid, setIsFormValid] = useState(false);

  const { confirm } = Modal;

  let { solicitudId } = useParams();
  const droppableRef = useRef(null);
  const [, setIsConvocatoria] = useAtom(isConvocatoriaAtom);
  const [formCierreProceso] = Form.useForm();
  let history = useHistory();

  useEffect(() => {
    const cargarDatos = async () => {
      setIsConvocatoria(true);
      const _convocatoria = await ConvocatoriaService.buscarPorSolicitudPersonal(solicitudId);
      const data = _convocatoria.etapas;
      const _postulantes = _convocatoria.postulantes;
      setPostulantes(_postulantes);
      setConvocatoriaActual({
        puesto: solicitud.puesto,
        convocatoriaId: _convocatoria.id,
        solicitudPersonalId: _convocatoria.solicitudPersonalId,
        tipoEntrevistador: _convocatoria.tipo,
        etapas: _convocatoria.etapas,
        postulantes: _convocatoria.postulantes,
      });
      if (data) {
        const newData = asignarItem(data, _postulantes);
        setConvocatoriaEtapas(newData);
        setRenderItem(true);
      }
    };
    cargarDatos();
  }, []);

  useEffect(() => {
    if (restablecer) showRestablecer();
    
  }, [restablecer]);

  const showRestablecer = () => {
    Modal.confirm({
      title: "Restablecer etapas",
      icon: <ExclamationCircleOutlined />,
      content: "¿Esta seguro de restablecer las etapas de la convocatoria?",
      onOk() {
        const restablecer = async () => {
          const etapasRestablecidas = await ConvocatoriaService.restablecerEtapasConvocatoria(
            convocatoriaActual.convocatoriaId
          );
          const newData = asignarItem(etapasRestablecidas, postulantes);
          setConvocatoriaEtapas(newData);
        };
        restablecer();
        message.success("Se ha restablecido las etapas");
        setRestablecer(false);
      },
      onCancel() {
        setRestablecer(false);
      },
    });
  };

  const asignarItem = (data, postulantesData) => {
    //console.log(data)
    data.forEach(function (value) {
      value.items = [];
      
      if (postulantesData.length > 0) {
        const _postulanteConvoatoria = postulantesData.filter((postulante) => postulante.etapaId === value.id)
        if (_postulanteConvoatoria.length > 0) value.items = _postulanteConvoatoria;
        if(value.descripcion === "Contratado") setIsClosable(value.items.length >=1 )
      }
    });
    return data;
  };

  const cambiarEtapaConvocatoria = async (etapaOrigenId, etapaDestinoId) => {
    try {
      const data = {
        convocatoriaId: convocatoriaActual.convocatoriaId,
        etapaIdOrigen: etapaOrigenId,
        etapaIdDestino: etapaDestinoId,
      };
      await ConvocatoriaService.cambiarEtapaConvocatoria(data);
      message.success("Se ha cambiado el orden");
    } catch (error) {
      console.error(error);
    }
  };

  const cambiarEtapa = async (postulanteId, etapaId,isContratado, isRemoved, formContratado ) => {
    try {
      await ConvocatoriaService.cambiarEtapaPostulante({
        convocatoriaId: convocatoriaActual.convocatoriaId,
        postulanteId: postulanteId,
        etapaId: etapaId,
        isContratado: isContratado,
        isRemoved:isRemoved,
        formContratado: formContratado
      });
      message.success("Se ha actualizado la etapa del postulante");
    } catch (error) {
      console.error(error);
    }
  };

  const onDragEnd = async(result) => {
    //console.log(result)
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId) {
      if (source.droppableId == "droppables") {
        onDragEndDroppable(destination, source);
        return;
      }
      const newDroppable = convocatoriaEtapas.find((d) => d.id === source.droppableId) || {};
      const newItems = Array.from(newDroppable.items || []);
      const [removed] = newItems.splice(source.index, 1);
      newItems.splice(destination.index, 0, removed);

      const newDroppables = convocatoriaEtapas.map((d) => {
        if (d.id === source.droppableId) {
          return { ...d, items: newItems };
        }
        return d;
      });
      setConvocatoriaEtapas(newDroppables);
    } else {
      const sourceDroppable = convocatoriaEtapas.find((d) => d.id === source.droppableId);
      const destinationDroppable = convocatoriaEtapas.find((d) => d.id === destination.droppableId);
      let isRemoved = false

      if (!sourceDroppable || !destinationDroppable) {
        return;
      }
      if(destinationDroppable.descripcion.toUpperCase() === "CONTRATADO")
        if(destinationDroppable.items.length - cantidad === 0){
          message.warning("Ya se ha completado la cantidad de personal solicitado.")
          return;
        }else{
          formEntrevista.resetFields();
          const _result = await new Promise((resolve, reject) => {
            const modal =confirm({
              title: "Contratar Colaborador",
              icon: null,
              content: <Form form={formEntrevista} layout="vertical" onFieldsChange={(changedFields, allFields)=>{if(allFields.every(field => field.touched === true) && allFields.every(field => field.errors.length === 0))modal.update({okButtonProps:{disabled:false}}); else modal.update({okButtonProps:{disabled:true}})}} >
                <Form.Item
                    label={"Movilidad"}
                    name={"movilidad"}
                    rules={[{ required: true, message: "Ingrese el movilidad." }]}
                  >
                    <InputNumber style={{width:"100%"}} min={0} placeholder="Ingrese un monto"/>
                  </Form.Item>
                  <Form.Item
                    label={"Planilla"}
                    name={"planilla"}
                    rules={[{ required: true, message: "Seleccione una opción." }]}
                  >
                    <Select placeholder={"Seleccione una opción"}>
                    <Select.Option value={true}>Si</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={"Remuneración Mensual"}
                    name={"remuneracionMensual"}
                    rules={[{ required: true, message: "Ingrese la remuneración." }]}
                  >
                    <InputNumber style={{width:"100%"}} min={1} placeholder="Ingrese un monto" />
                  </Form.Item>
                  <Form.Item
                    label={"Asignación Familiar"}
                    name={"asignacionFamiliar"}
                    rules={[{ required: true, message: "Seleccione una opción." }]}
                  >
                    <Select placeholder={"Seleccione una opción"}>
                    <Select.Option value={true}>Si</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={"Fecha Ingreso"}
                    name={"fechaIngreso"}
                    rules={[{ required: true, message: "Seleccione la fecha de ingreso." }]}
                  >
                    <DatePicker format={date => date.utc().format("DD/MM/YYYY")} style={{width:"100%"}} placeholder="Seleccione una fecha" />
                  </Form.Item>
                  <Form.Item
                    label={"Fecha Cierre"}
                    name={"fechaCierre"}
                    rules={[{ required: true, message: "Seleccione la fecha de cierre." }]}
                  >
                    <DatePicker format={date => date.utc().format("DD/MM/YYYY")} style={{width:"100%"}} placeholder="Seleccione una fecha" />
                  </Form.Item>
                  {/* <Form.Item
                    label={"Fecha Cierre"}
                    name={"fechaCierre"}
                    rules={[{ required: true}]}
                  >
                    <DatePicker defaultValue={moment()} format={"DD/MM/YYYY"} disabled style={{width:"100%"}} />
                  </Form.Item> */}
                
                 </Form>,
              okText: 'Aceptar',
              okType: 'primary',
              okButtonProps:{disabled:true},
              cancelText: 'Cancelar',
              onOk() {
                resolve(true)
              },
              onCancel() {
                resolve(false)
              },
            });
          });
          if(_result)
            setIsClosable(true)
          else
            return;
          
        }
      else if(sourceDroppable.descripcion.toUpperCase() === "CONTRATADO"){

        const result = await new Promise((resolve, reject) => {
          confirm({
            title: 'Confirmar Accion',
            icon: <ExclamationCircleOutlined />,
            content: '¿Estas seguro de quitar al colaborador contratado?',
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
              resolve(true)
            },
            onCancel() {
              resolve(false)
            },
          });
        });
        if(result){
          setIsClosable(destinationDroppable.items.length - 1 >= 1)
          isRemoved = true;}
        else
         return;
      }

      const sourceItems = Array.from(sourceDroppable.items);
      const destinationItems = Array.from(destinationDroppable.items);
      //   if (source.index >= sourceItems.length || destination.index >= destinationItems.length) {
      //     return;
      //   }
      const [removed] = sourceItems.splice(source.index, 1);
      destinationItems.splice(destination.index, 0, { ...removed, etapaId: destination.droppableId });

      const newDroppables = convocatoriaEtapas.map((d) => {
        if (d.id === source.droppableId) {
          return { ...d, items: sourceItems };
        }
        if (d.id === destination.droppableId) {
          return { ...d, items: destinationItems };
        }
        return d;
      });
      setConvocatoriaEtapas(newDroppables);
      cambiarEtapa(
        draggableId,
        destination.droppableId,
        destinationDroppable.descripcion.toUpperCase() === "Contratado".toUpperCase(),
        isRemoved,
        formEntrevista.getFieldsValue()
      );
    }
  };

  const onDragEndDroppable = (destination, source) => {
    //const { destination, source } = result;

    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    if (destination.index === 0 || destination.index >= convocatoriaEtapas.length - 2) {
      console.log("salida");
      message.warning("No se pueden cambiar el orden de las etapas fijas");
      return;
    }

    const idOrigen = convocatoriaEtapas[source.index].id;
    const idDestino = convocatoriaEtapas[destination.index].id;

    const newDroppables = Array.from(convocatoriaEtapas);
    const [removed] = newDroppables.splice(source.index, 1);

    if (destination.index === 0) {
      newDroppables.unshift(removed);
    } else {
      newDroppables.splice(destination.index, 0, removed);
    }
    setConvocatoriaEtapas(newDroppables);
    cambiarEtapaConvocatoria(idOrigen, idDestino);
    //
  };

  const cambiarEstados = (data) => {
    const sourceDroppable = convocatoriaEtapas.find((d) => d.id === data.original);
    const destinationDroppable = convocatoriaEtapas.find((d) => d.id === data.destino);
    if (!sourceDroppable || !destinationDroppable) {
      return;
    }
    const sourceItems = Array.from(sourceDroppable.items);
    const destinationItems = Array.from(destinationDroppable.items);

    const sourceItem = sourceItems.findIndex((d) => d.id === data.postulante);

    const [removed] = sourceItems.splice(sourceItem, 1);
    destinationItems.splice(0, 0, { ...removed, etapaId: destinationDroppable.id });

    const newDroppables = convocatoriaEtapas.map((d) => {
      if (d.id === data.original) {
        return { ...d, items: sourceItems };
      }
      if (d.id === data.destino) {
        return { ...d, items: destinationItems };
      }
      return d;
    });
    setConvocatoriaEtapas(newDroppables);
    message.success("Se ha actualizado la etapa del postulante");
  };

  const getColorTitle = (isDragging, index) => {
    if (index === 0) return "#A1C5E3";
    if (index === convocatoriaEtapas.length - 2) return "#ADE3A1";
    if (index === convocatoriaEtapas.length - 1) return "#E3A1A1";

    return isDragging ? "#E7DEAC" : "#CBCBCB";
  };

  const removerEtapa = async (etapaId, index) => {
    const etapa = convocatoriaEtapas[index];
    if (etapa.items.length !== 0) {
      message.error("No se puede eliminar una etapa con postulantes");
      return;
    }
    await ConvocatoriaService.removerEtapa(convocatoriaActual.convocatoriaId, etapaId);
    const newDroppables = Array.from(convocatoriaEtapas);
    newDroppables.splice(index, 1);
    setConvocatoriaEtapas(newDroppables);
    message.success(`La etapa ${etapa.descripcion} ha sido removida`);
    //restablecerEtapasConvocatoria
  };

  const agregarPostulante = (postulante) => {
    const source = convocatoriaEtapas.find((d) => d.descripcion.toUpperCase() === "Postulante".toUpperCase());
    const _items = Array.from(source.items);
    _items.splice(0, 0, { ...postulante, etapaId: source.id });
    const newDroppables = convocatoriaEtapas.map((d) => {
      if (d.id === source.id) {
        return { ...d, items: _items };
      }
      return d;
    });
    setConvocatoriaEtapas(newDroppables);
    message.success("Se agrego el colaboradro exitosamente");
  };


  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        {renderItem && (
          <div style={{ display: "flex", overflow: "auto", height: "calc(75vh - 4rem)" }}>
            <Droppable
              droppableId="droppables"
              direction="horizontal"
              type="droppable"
              onDragEnd={onDragEndDroppable}
              getDroppableRef={droppableRef}             
            >
              {(provided, snapshot1) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={getListPadreStyle(snapshot1.isDraggingOver)}
                >
                  {convocatoriaEtapas.map((droppable, index) => (
                    <Draggable
                      key={droppable.id}
                      draggableId={droppable.id}
                      index={index}
                      isDragDisabled={
                        index === 0 ||
                        index === convocatoriaEtapas.length - 2 ||
                        index === convocatoriaEtapas.length - 1
                      }
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          //className="droppable"
                        >
                          <div
                            style={{
                              // display: 'flex',
                              // alignItems: 'center',
                              // justifyContent: 'center',
                              textAlign: "center",
                              position: "relative",
                              borderRadius: "8px 8px 0px 0px",
                              height: "40px",
                              backgroundColor: getColorTitle(snapshot.isDragging, index),
                              marginInline: "15px",
                              //marginBlock: "5px",
                              fontSize: "20px",
                            }}
                          >
                            <Typography.Text strong> {droppable.descripcion}</Typography.Text>
                            {![0, convocatoriaEtapas.length - 2, convocatoriaEtapas.length - 1].includes(index) && (
                              <CloseCircleOutlined
                                onClick={() => removerEtapa(droppable.id, index)}
                                style={{ position: "absolute", right: 5, top: 8, cursor: "pointer" }}
                              />
                            )}
                          </div>
                          <Droppable droppableId={droppable.id} type={"QUOTE"} getDroppableRef={droppableRef}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                style={getListStyle(snapshot.isDraggingOver)}
                              >
                                {droppable.items.map((item, index) => (
                                  <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        onClick={() => {
                                          setPostulanteSeleccionado(item.id);
                                          const _convocatoria = convocatoriaEtapas?.find((c) => c.id === item.etapaId);
                                          setConvocatoriaActual({ ...convocatoriaActual, etapa: _convocatoria });
                                          setModalPostulante(true);
                                        }}
                                      >
                                        {item.nombreCompleto}
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        )}
        <ModalPostulante
          postulanteSeleccionado={postulanteSeleccionado}
          modalPostulante={modalPostulante}
          setModalPostulante={setModalPostulante}
          convocatoria={convocatoriaActual}
          cambiarEstados={onDragEnd}
        />
        <ModalAgregarPostulante
          modalAgregarPostulante={modalAgregarPostulante}
          setModalAgregarPostulante={setModalAgregarPostulante}
          setDrawCrearPostulante={setDrawCrearPostulante}
          convocatoria={convocatoriaActual}
          agregarPostulante={agregarPostulante}
        />
        <Drawer
          title="Crear Postulante"
          width={"45vw"}
          placement="right"
          destroyOnClose
          onClose={() => setDrawCrearPostulante(false)}
          open={drawCrearPostulante}
        >
          <AgregarPostulantePage
            setDrawerVisible={setDrawCrearPostulante}
            setModalAgregarPostulante={setModalAgregarPostulante}
            agregarPostulante={agregarPostulante}
            convocatoriaId={convocatoriaActual?.convocatoriaId}
          />
        </Drawer>
        <ModalCerrarProceso
           modalCerrarProceso={modalCerrarProceso}
           setModalCerrarProceso={setModalCerrarProceso}
           convocatoriaId={convocatoriaActual?.convocatoriaId}
           setIsConvocatoria={setIsConvocatoria}
           closable={isClosable}
         />
      </DragDropContext>
    </div>
  );
};

export default ConvocatoriasListar;
