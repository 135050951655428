import React, { useState,useEffect } from "react";
import { Modal, Input, Form, message } from "antd";
import { useAtom } from "jotai";
import {solicitudAusenciaSeleccionadaAtom, visibleModalEliminarSolicitudAtom} from "../../consultaSolicitudesStore";
import SolicitudPersonalService from "services/reclutamiento-y-seleccion/SolicitudPersonalService";
const ModalCancelarSolicitud = () => {
  const [visibleModalEliminarSolicitud, setVisibleModalEliminarSolicitud] = useAtom(visibleModalEliminarSolicitudAtom);
  const [solicitudSeleccionada,setSolicitudSeleccionada] = useAtom(solicitudAusenciaSeleccionadaAtom);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

   useEffect(() => {
        const cargarDatos = () => {
            if (visibleModalEliminarSolicitud) {
                form.resetFields();	
                
			}
        }
        cargarDatos();
    
   }, [visibleModalEliminarSolicitud]);
    
    const onCancel = () => {
      setVisibleModalEliminarSolicitud(false);
     };

  const onClickBtnCancelarSolicitud = () => {
    form
      .validateFields()
      .then(async (formulario) => {
        setLoading(true);
        // await SolicitudPersonalService.cancelar({
        //   solicitudPersonalId: solicitudPersonalId,
        //   motivo: formulario.motivo,
        // });
        setLoading(false);
        setVisibleModalEliminarSolicitud(false);
        message.success("Se canceló la solicitud seleccionada!");
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };
    return (
    <Modal
      title="Cancelar Solicitud"
      open={visibleModalEliminarSolicitud}
      okText="Cancelar Solicitud"
      onOk={onClickBtnCancelarSolicitud}
      onCancel={onCancel}
      confirmLoading={loading}
      centered
    >
      <Form form={form} layout="vertical" size="middle">
        <Form.Item
          label="Motivo"
          name="motivo"
          rules={[
            {
              required: true,
              message: "Ingrese el motivo",
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );

    
}
export default ModalCancelarSolicitud;