import React from "react";
import { Tabs } from "antd";

const TabDocumentos = ({ children }) => {
  const { TabPane } = Tabs;
  return (
    <div style={{ margin: "0em 10px", height: "100%" }}>
      <Tabs style={{ height: "100%" }}>
        <TabPane tab="Documentos" key="documentos" style={{ height: "100%" }}>
          {children}
        </TabPane>
      </Tabs>
    </div>
  );
};
export default TabDocumentos;
