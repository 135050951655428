import React, { useContext, useEffect, useState } from "react";
import { Container } from "components/Container";
import { ModalConfirmar } from "components/Modal/ModalConfirmar";
import { TipoDocumentoRegistro } from "./tipo-documentos-registro";
import { useHistory } from "react-router";
import * as atoms from "./CrearTipoDocumentosStore";
import { useAtom } from "jotai";
import { useModalConfirmar } from "store/global";
import TipoDocumentoService from "services/TipoDocumentoService";

const CrearTipoDocumentosPage = () => {
    let history = useHistory();
    const cerrarModalConfirmacion = useModalConfirmar(state => state.cerrarModal);
    const [tipoDocumento] = useAtom(atoms.tipoDocumento);
    const [loading, setLoading] = useState(false);
    const onClickBtnConfirmarModal = async () => {
        try {
            setLoading(true);
            const _tipoDocumento = await TipoDocumentoService.insertarTipoDocumento(
                tipoDocumento
            );
            cerrarModalConfirmacion();
            history.push("/configuracion/tipo-documentos/listar");
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    const onClickBtnCancelarModal = () => {
        cerrarModalConfirmacion();
    }
    const onClickRetonarListarTipoDocumentos=()=>{
        history.push("/configuracion/tipo-documentos/listar");
    }
    return(
        <Container titulo=" Crear tipo Documento" onClickRetornar={onClickRetonarListarTipoDocumentos}>
            <TipoDocumentoRegistro/>
            <ModalConfirmar
                loading={loading}
                mensaje="¿Está seguro de guardar el Tipo Documento? "
                onClickBtnCancelar={onClickBtnCancelarModal}
                onClickBtnConfirmar={onClickBtnConfirmarModal} />

        </Container>
    )

}
export default CrearTipoDocumentosPage;