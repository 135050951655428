import React, { useState } from "react";
import { Row, Col, Card, Typography, Space, Dropdown, Menu } from "antd";
import { DownOutlined, SettingOutlined } from "@ant-design/icons";

const { Text } = Typography;

export function ContainerListarDetalleAprobador({
  icono,
  titulo,
  descripcion,
  children,
}) {
  return (
    <Row>
      <Col span={24}>
        <Card
          title={
            <Text>
              <Space>
                {icono}
                <span className="mock-block">
                  <Text strong>{titulo}</Text>
                  <p>{descripcion}</p>
                </span>
              </Space>
            </Text>
          }
          // extra={
          //   <Space size={20}>
          //     <Dropdown overlayStyle={{ textAlign: "center" }} overlay={menu}>
          //       <a
          //         className="ant-dropdown-link"
          //         onClick={(e) => e.preventDefault()}
          //       >
          //         Ver <DownOutlined />
          //       </a>
          //     </Dropdown>
          //     <Dropdown
          //       overlayStyle={{ textAlign: "center" }}
          //       overlay={menuMasOpciones}
          //     >
          //       <a
          //         className="ant-dropdown-link"
          //         onClick={(e) => e.preventDefault()}
          //       >
          //         <SettingOutlined /> Más opciones
          //       </a>
          //     </Dropdown>
          //   </Space>
          // }
          headStyle={{ fontWeight: "400" }}
        >
          {children}
        </Card>
      </Col>
    </Row>
  );
}
