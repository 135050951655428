import React, { useContext, useEffect, useState } from "react";
import { Resumen } from "./resumen";
import { PanelCantidad, TotalDocumentos } from "./panelCantidad";
import { useAtom } from "jotai";

import { resumenDocumentoAtom } from "../../DetalleSeguimientoOnboardingStorage";

//import {  documentosConErrorAtom, resumenAtom } from "../../ListarDetalleSeguimientoStore";


const InformacionDocumento = () => {

    const [loading, setLoading] = useState(false);
    const [resumenDocumento] = useAtom(resumenDocumentoAtom);


    return (
        <div style={{ display: 'flex', maxHeight: '94px' }}>

            <Resumen loading={loading} /> 

            <div style={{ display: 'flex', width: '70%' }}>

                <PanelCantidad

                    total={resumenDocumento?.totalDocumento}

                    descripcion="Documentos"
                    color="rgb(24, 144, 255)"
                    backgroundColor="rgb(24, 144, 255, 0.08)" />

                <PanelCantidad
                    total={resumenDocumento?.totalCompletado}
                    descripcion={"Completados"}
                    color="rgb(82, 196, 26)"
                    backgroundColor="rgb(82, 196, 26, 0.08)" />

                <PanelCantidad
                    total={resumenDocumento?.totalPendiente}
                    descripcion={"Pendientes"}
                    color="rgb(212, 136, 6)"
                    backgroundColor="rgb(212, 136, 6, 0.08)" />

                <PanelCantidad
                    total={resumenDocumento?.totalRechazado}
                    descripcion="Rechazados"
                    color="rgb(255, 77, 79)"
                    backgroundColor="rgb(255, 77, 79, 0.08)" />

            </div>

        </div>
    )
}

export default InformacionDocumento;