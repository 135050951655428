import ColaboradorService from "services/ColaboradorService";
import { useState } from "react";

const useListarResponsables = () => {
  const [filtroResponsable, setFiltroResponsable] = useState(undefined);

  const cargarResponsables = async (valor) => {
    const respuesta = await ColaboradorService.listarPorNombreCompletoODni({ filtro: valor.trim() });
    const responsables = respuesta.colaboradores.map((colaborador) => ({
      label: colaborador.nombreCompleto,
      value: colaborador.numeroDocumentoIdentidad,
    }));
    responsables.unshift({ label: "TRABAJADOR", value: "TRABAJADOR" });
    return responsables;
  };

  return { filtroResponsable, setFiltroResponsable, cargarResponsables };
};
export default useListarResponsables;
