import React, { useState } from "react";
import {
  Row,
  Col,
  Card,
  Typography,
  Space,
  Layout,
  Divider,
  List,
  Checkbox,
  Button,
  Menu,
  Dropdown,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import MenuItem from "antd/lib/menu/MenuItem";

const { Text } = Typography;
const { Header, Content, Sider } = Layout;
/*const [checkedList, setCheckedList] = useState();
const [indeterminate, setIndeterminate] = useState(true);
const [checkAll, setCheckAll] = useState(false);*/

export function ContainerListarPage({
  icono,
  titulo,
  children,
  tituloList,
  dataList,
  iconoList,
  iconoSeleccionado,
  colaboradorSeleccionado,
  handleOnClickColaborador,
}) {
  const menu = (
    <Menu>
      <MenuItem key="docmasivos">Descarga Masiva Documentos</MenuItem>
    </Menu>
  );

  return (
    <Row>
      <Col span={24}>
        <Card
          title={
            <Text>
              <Space>
                {icono}
                <span className="mock-block">{titulo}</span>
              </Space>
            </Text>
          }
          headStyle={{ fontWeight: "400" }}
        >
          <Row>
            <Col span={5}>
              <List
                header={
                  <Row gutter={100}>
                    <Col>
                      <Checkbox>
                        <Text strong>{tituloList}</Text>
                      </Checkbox>
                    </Col>
                    <Col>
                      <Dropdown overlay={menu}>
                        <a onClick={(e) => e.preventDefault()}>
                          Reportes
                          <DownOutlined />
                        </a>
                      </Dropdown>
                    </Col>
                  </Row>
                }
                itemLayout="horizontal"
                bordered
                dataSource={dataList}
                renderItem={(item) => (
                  <List.Item>
                    {colaboradorSeleccionado.numeroDocumentoIdentidad ===
                    item.numeroDocumentoIdentidad ? (
                      <Checkbox>
                        <Space>
                          <Text className="listar-legajo-colaborador-seleccionado">
                            {iconoSeleccionado}
                          </Text>
                          <Text className="listar-legajo-colaborador-seleccionado">
                            {item.nombreCompleto}
                          </Text>
                        </Space>
                      </Checkbox>
                    ) : (
                      // <a
                      //   className="listar-legajo-colaborador-noseleccionado"
                      //   onClick={() => handleOnClickColaborador(item)}
                      // >
                      //   <Space>
                      //     {iconoList}
                      //     {item.nombreCompleto}
                      //   </Space>
                      // </a>
                      <Checkbox>
                        <Button
                          type="text"
                          className="listar-legajo-colaborador-noseleccionado"
                          onClick={() => handleOnClickColaborador(item)}
                        >
                          {iconoList}
                          {item.nombreCompleto}
                        </Button>
                      </Checkbox>
                    )}
                  </List.Item>
                )}
              />
            </Col>
            <Divider type="vertical" />
            <Col span={16}>{children}</Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
}
