import React from "react";

export function FilterServerInputTxtBusqueda({
  placeholder,
  valorTxtBusqueda,
  onChange,
  onKeyDown,
  onClick,
  refTxtBusqueda,
}) {
  return (
    <div className="filter-input-search-control-container">
      <input
        type="text"
        className="filter-input-search-control"
        placeholder={placeholder}
        value={valorTxtBusqueda}
        onChange={(e) => onChange(e.target.value)}
        onClick={(e) => onClick(e.target.value)}
        onKeyDown={(e) =>
          onKeyDown(
            e,
            e.target.value.substring(e.target.value.indexOf(":") + 1).trim()
          )
        }
        ref={refTxtBusqueda}
      />
    </div>
  );
}
