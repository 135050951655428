import React, { useEffect, useState } from "react";
import { DatePicker,Typography,Space } from "antd";
import { Line } from "@ant-design/plots";
import { ReporteSolicitudPersonalService } from "services/reclutamiento-y-seleccion";
import moment from "moment";
const ReporteContratacionPersonal=()=>{
    const [data, setData] = useState([]);
    const [anio,setAnio] = useState(moment().year())
    useEffect(()=>{
        //const anio = 2023;  
        ReporteSolicitudPersonalService.getTasaContratacionPersonal({ anio: anio })
         .then((response) => {
           setData(response.data);           
         })
         .catch((error) => console.log("error", error));
        //console.log("información...");   
       

    },[anio])
    const onChange=(date, dateString)=>{   
  
        setAnio(parseInt(dateString,10));
      }
    const meta = 80;
    
    const config = {
      data,
      padding: "auto",
      xField: "mes",
      yField: "porcentajeContratacion",
      yAxis: {
        tickInterval: 10,       
      },
      lineStyle: {
        stroke: "#F4664A",
      },
      annotations: [
        {
          type: "regionFilter",
          start: ["min", meta],
          end: ["max", 100],
          color: "#1890ff",
        },
        {
          type: "text",
          position: ["min", meta],
          content: "Meta: " + meta + "%",
          offsetY: -4,
          style: {
            textBaseline: "bottom",
          },
        },
        
        {
          type: "line",
          start: ["min", meta],
          end: ["max", meta],
          style: {
            stroke: "#1890ff",
            lineDash: [2, 2],
          },
        },
        
      ],
    };
    return(
       <React.Fragment>
            <Space>
            <Typography.Text>Año:</Typography.Text>
            <DatePicker defaultValue={moment()} onChange={onChange} picker="year" allowClear={false}/>
            </Space> 
            <br/>
            <br/>
            <Line {...config} />
       </React.Fragment>
       )

}
export default ReporteContratacionPersonal;