import React from "react";
import { Button } from "antd";
import { FormOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";

const BotonesGlobales = () => {

    const history = useHistory();

    const onClickBtnNotificarFormulario = () => {
        history.push('/notificacion/formulario/notificar');
    }

    return (
        <React.Fragment>
            <Button type="primary" onClick={onClickBtnNotificarFormulario}>
                <FormOutlined />Notificar Formulario
            </Button>
        </React.Fragment>
    );
}

export default BotonesGlobales;