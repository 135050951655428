import React, { useContext, useState, useEffect } from "react";
import { Form, Input, Button, Row, Col, Select } from "antd";
import { useModalConfirmar } from "store/global"
import { SecurityContext } from "context/SecurityContextProvider";
import * as atoms from "../EditarSubsedeStore";
import { useAtom } from "jotai";
import { useParams } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import SubsedeService from "../../../../services/SubsedeService";
import { CampoColaborador } from "enums/CampoColaborador";
import SeccionService from "services/SeccionService";
import PerfilService from "services/PerfilService";
import SedeService from "services/SedeService";
import TipoPlanillaService from "services/TipoPlanillaService";
import CargoService from "services/CargoService";

const SubsedeEditar = () => {

    let { idSubsede } = useParams();
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [, setSubsede] = useAtom(atoms.subsedeAtom);
    const mostrarModalConfirmacion = useModalConfirmar(state => state.mostrarModal);
    const [formularioSubsede] = Form.useForm();

    const [dependenciaSubsedeAtom, setDependenciaSubsedeAtom] = useAtom(atoms.dependenciaSubsede);
    //const [listaCampoDependenciaAtom,setListaCampoDependenciaAtom]=useAtom(atoms.listacampoDependencia);
    //const [nameCampoAtom,setNameCampoAtom]=useAtom(atoms.nameCampo);
    const [listaCamposAtom, setListaCamposAtom] = useAtom(atoms.listaCampos);
    const [perfilesAtom, setPerfilesAtom] = useAtom(atoms.perfiles);
    const [sedesAtom, setSedesAtom] = useAtom(atoms.sedes);
    const [seccionesAtom, setSeccionesAtom] = useAtom(atoms.secciones);
    const [tipoPlanillasAtom, setTipoPlanillasAtom] = useAtom(atoms.tipoPlanillas);
    const [cargosAtom, setCargosAtom] = useAtom(atoms.cargos);
    const [camposColaboradorAtom, setCamposColaboradorAtom] = useAtom(atoms.camposColaborador)

    const { Option } = Select;

    useEffect(() => {
        const cargarDatos = async () => {
            try {
                // const empresa = getUsuarioAutenticado().empresaSeleccionada.id;

                // const campo_subsede= (getUsuarioAutenticado().empresaSeleccionada.camposColaborador).find(campoColaborador=>campoColaborador.campo===CampoColaborador.SUBSEDE);

                // const subsedeDependencia=campo_subsede.campoDependencia;   

                const SubsedeObtenida = await SubsedeService.buscarPorId(idSubsede);
                formularioSubsede.setFieldsValue(
                    {
                        id: SubsedeObtenida.id,
                        nombre: SubsedeObtenida.nombre,
                    }
                )
                let listaCampos = [], dependencia;
                var perfilId, sedeId, seccionId, tipoPlanillaId, cargoId;
                const empresa = getUsuarioAutenticado().empresaSeleccionada.id;
                const campos_colaborador = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
                setCamposColaboradorAtom(campos_colaborador);
                const campo_subsede = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.SUBSEDE);
                const subsedeDependencia = campo_subsede.campoDependencia;

                if (subsedeDependencia !== null) {
                    //buscamos IDs
                    if (subsedeDependencia === CampoColaborador.PERFIL) {
                        perfilId = SubsedeObtenida.perfilId;
                    }
                    else if (subsedeDependencia === CampoColaborador.SEDE) {
                        sedeId = SubsedeObtenida.sedeId;
                    }
                    else if (subsedeDependencia === CampoColaborador.SECCION) {
                        seccionId = SubsedeObtenida.seccionId;
                    }
                    else if (subsedeDependencia === CampoColaborador.PLANILLA) {
                        tipoPlanillaId = SubsedeObtenida.tipoPlanillaId;
                    }
                    else if (subsedeDependencia === CampoColaborador.CARGO) {
                        cargoId = SubsedeObtenida.cargoId;
                    }
                    //                         
                    setDependenciaSubsedeAtom(subsedeDependencia);
                    dependencia = subsedeDependencia;
                    listaCampos.push(dependencia);

                    while (dependencia !== null) {
                        var campo = campos_colaborador.find(campoColaborador => campoColaborador.campo === dependencia);
                        dependencia = campo.campoDependencia;
                        if (dependencia !== null) {
                            listaCampos.push(dependencia);
                            //buscamos IDs
                            if (dependencia === CampoColaborador.PERFIL) {
                                perfilId = SubsedeObtenida.perfilId;
                                console.log("el prefil:", SubsedeObtenida.perfilId);
                            }
                            else if (dependencia === CampoColaborador.SEDE) {
                                sedeId = SubsedeObtenida.sedeId;
                            }
                            else if (dependencia === CampoColaborador.SECCION) {
                                seccionId = SubsedeObtenida.seccionId;
                            }
                            else if (dependencia === CampoColaborador.PLANILLA) {
                                tipoPlanillaId = SubsedeObtenida.tipoPlanillaId;
                            }
                            else if (dependencia === CampoColaborador.CARGO) {
                                cargoId = SubsedeObtenida.cargoId;
                            }
                            // 
                        }

                    }
                    setListaCamposAtom(listaCampos.reverse());

                }
                else {
                    setListaCamposAtom(listaCampos);
                    setDependenciaSubsedeAtom(CampoColaborador.SINCAMPO);
                }
                if (listaCampos.length > 0) {

                    for (let i = 0; i < listaCampos.length; i++) {

                        if (listaCampos[i] === CampoColaborador.SEDE) {
                            const campo_sede = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.SEDE);
                            const sedeDependencia = campo_sede.campoDependencia;
                            const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                            var _sedes;
                            if (sedeDependencia === CampoColaborador.PERFIL) {
                                _sedes = sedes.filter(sede => sede.perfilId === perfilId)
                            }
                            else if (sedeDependencia === CampoColaborador.SECCION) {
                                _sedes = sedes.filter(sede => sede.seccionlId === seccionId)

                            }
                            else if (sedeDependencia === CampoColaborador.PLANILLA) {
                                _sedes = sedes.filter(sede => sede.tipoPlanillaId === tipoPlanillaId)

                            }
                            else if (sedeDependencia === CampoColaborador.CARGO) {

                                _sedes = sedes.filter(sede => sede.cargoId === cargoId)

                            }
                            else {
                                _sedes = sedes;
                            }
                            setSedesAtom(_sedes);
                            formularioSubsede.setFieldsValue({ sedeId: sedeId })


                        }
                        else if (listaCampos[i] === CampoColaborador.PERFIL) {

                            const campo_perfil = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.PERFIL);
                            const perfilDependencia = campo_perfil.campoDependencia;
                            const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                            var _perfiles;
                            if (perfilDependencia === CampoColaborador.SEDE) {
                                _perfiles = perfiles.filter(perfil => perfil.sedeId === sedeId)
                            }
                            else if (perfilDependencia === CampoColaborador.SECCION) {
                                _perfiles = perfiles.filter(perfil => perfil.seccionlId === seccionId)

                            }
                            else if (perfilDependencia === CampoColaborador.PLANILLA) {
                                _perfiles = perfiles.filter(perfil => perfil.tipoPlanillaId === tipoPlanillaId)

                            }
                            else if (perfilDependencia === CampoColaborador.CARGO) {

                                _perfiles = perfiles.filter(perfil => perfil.cargoId === cargoId)

                            }
                            else {
                                _perfiles = perfiles;
                            }
                            setPerfilesAtom(_perfiles);

                            formularioSubsede.setFieldsValue({ perfilId: perfilId })
                        }
                        else if (listaCampos[i] === CampoColaborador.SECCION) {

                            const campo_seccion = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.SECCION);
                            const seccionDependencia = campo_seccion.campoDependencia;
                            const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                            var _secciones;
                            if (seccionDependencia === CampoColaborador.SEDE) {
                                _secciones = secciones.filter(seccion => seccion.sedeId === sedeId)
                            }
                            else if (seccionDependencia === CampoColaborador.PERFIL) {
                                _secciones = secciones.filter(seccion => seccion.perfilId === perfilId)

                            }
                            else if (seccionDependencia === CampoColaborador.PLANILLA) {
                                _secciones = secciones.filter(seccion => seccion.tipoPlanillaId === tipoPlanillaId)

                            }
                            else if (seccionDependencia === CampoColaborador.CARGO) {

                                _secciones = secciones.filter(seccion => seccion.cargoId === cargoId)

                            }
                            else {
                                _secciones = secciones;
                            }
                            setSeccionesAtom(_secciones);

                            formularioSubsede.setFieldsValue({ seccionId: seccionId })
                        }
                        else if (listaCampos[i] === CampoColaborador.PLANILLA) {

                            const campo_planilla = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.PLANILLA);
                            const planillaDependencia = campo_planilla.campoDependencia;
                            const planillas = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                            var _planillas;
                            if (planillaDependencia === CampoColaborador.SEDE) {
                                _planillas = planillas.filter(planilla => planilla.sedeId === sedeId)
                            }
                            else if (planillaDependencia === CampoColaborador.PERFIL) {
                                _planillas = planillas.filter(planilla => planilla.perfilId === perfilId)

                            }
                            else if (planillaDependencia === CampoColaborador.SECCION) {
                                _planillas = planillas.filter(planilla => planilla.seccionaId === seccionId)

                            }
                            else if (planillaDependencia === CampoColaborador.CARGO) {

                                _planillas = planillas.filter(planilla => planilla.cargoId === cargoId)

                            }
                            else {
                                _planillas = planillas;
                            }
                            setTipoPlanillasAtom(_planillas);

                            formularioSubsede.setFieldsValue({ tipoPlanillaId: tipoPlanillaId })
                        }
                        else if (listaCampos[i] === CampoColaborador.CARGO) {

                            const campo_cargo = campos_colaborador.find(campoColaborador => campoColaborador.campo === CampoColaborador.CARGO);
                            const cargoDependencia = campo_cargo.campoDependencia;
                            const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                            var _cargos;
                            if (cargoDependencia === CampoColaborador.SEDE) {
                                _cargos = cargos.filter(cargo => cargo.sedeId === sedeId)
                            }
                            else if (cargoDependencia === CampoColaborador.PERFIL) {
                                _cargos = cargos.filter(cargo => cargo.perfilId === perfilId)

                            }
                            else if (cargoDependencia === CampoColaborador.SECCION) {
                                _cargos = cargos.filter(cargo => cargo.seccionaId === seccionId)

                            }
                            else if (cargoDependencia === CampoColaborador.PLANILLA) {
                                _cargos = cargos.filter(cargo => cargo.tipoPlanillaId === tipoPlanillaId)

                            }
                            else {
                                _cargos = cargos;
                            }
                            setCargosAtom(_cargos);

                            formularioSubsede.setFieldsValue({ cargoId: cargoId })
                        }
                    }
                }
                // const primerCampo=listaCampos[0];
                // if(primerCampo===CampoColaborador.PERFIL){
                //     const perfiles= await PerfilService.listar(empresa);
                //     setPerfilesAtom(perfiles);
                // }
                // else if(primerCampo===CampoColaborador.SEDE){
                //     const sedes= await SedeService.listar(empresa);
                //     setSedesAtom(sedes);

                // }
                // else if(primerCampo===CampoColaborador.SECCION){
                //     const secciones= await SeccionService.listar(empresa);
                //     setSeccionesAtom(secciones);

                // }
                // else if(primerCampo===CampoColaborador.PLANILLA){
                //     const planillas= await TipoPlanillaService.listar(empresa);
                //     setTipoPlanillasAtom(planillas);

                // }
                // else if(primerCampo===CampoColaborador.CARGO){
                //     const cargos = await CargoService.listar(empresa);
                //     setCargosAtom(cargos);
                // }
                // if(subsedeDependencia!==null){

                //     setDependenciaSubsedeAtom(subsedeDependencia);
                //     setNameCampoAtom(subsedeDependencia.toLowerCase()+"Id");

                //     if (subsedeDependencia===CampoColaborador.SEDE){
                //       const sedes= await SedeService.listar(empresa);
                //       setListaCampoDependenciaAtom(sedes); 
                //       formularioSubsede.setFieldsValue({ sedeId:SubsedeObtenida.sedeId });                   

                //     }
                //     else if(subsedeDependencia===CampoColaborador.PERFIL){
                //       const perfiles= await PerfilService.listar(empresa);
                //       setListaCampoDependenciaAtom(perfiles);
                //       formularioSubsede.setFieldsValue({ perfilId:SubsedeObtenida.perfilId });

                //     }
                //     else if(subsedeDependencia===CampoColaborador.SECCION){
                //       const secciones= await SeccionService.listar(empresa);
                //       setListaCampoDependenciaAtom(secciones);
                //       formularioSubsede.setFieldsValue({ seccionId:SubsedeObtenida.seccionId });

                //     }
                //     else if(subsedeDependencia===CampoColaborador.PLANILLA){
                //       const planillas= await TipoPlanillaService.listar(empresa);
                //       setListaCampoDependenciaAtom(planillas);
                //       setNameCampoAtom("tipoPlanillaId");
                //       formularioSubsede.setFieldsValue({ tipoPlanillaId:SubsedeObtenida.tipoPlanillaId });
                //     }
                //     else if(subsedeDependencia===CampoColaborador.CARGO){
                //       const cargos = await CargoService.listar(empresa);
                //       setListaCampoDependenciaAtom(cargos);
                //       formularioSubsede.setFieldsValue({ cargoId:SubsedeObtenida.cargoId });
                //     }


                // }
                // else{          
                //   setDependenciaSubsedeAtom(CampoColaborador.SINCAMPO);         
                // }

            } catch (error) {
                console.error(error);
            }

        }

        cargarDatos();

    }, []);
    const onClickBtnActualizarSubsede = async (subsede) => {

        subsede.empresaId = getUsuarioAutenticado().empresaSeleccionada.id;
        subsede.dependencia = dependenciaSubsedeAtom;
        setSubsede(subsede);
        mostrarModalConfirmacion();

    }
    const handleOnchangePerfil = async (value, option) => {

        let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.PERFIL);

        for (let i = 0; i < listaCampos.length; i++) {
            if (listaCampos[i].campo === CampoColaborador.SEDE) {
                const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _sedes = sedes.filter(sede => sede.perfilId === value)
                setSedesAtom(_sedes);
                formularioSubsede.setFieldsValue({ sedeId: "" })


            }
            else if (listaCampos[i].campo === CampoColaborador.SECCION) {
                const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _secciones = secciones.filter(seccion => seccion.perfilId === value);
                setSeccionesAtom(_secciones);
                formularioSubsede.setFieldsValue({ seccionId: "" })

            }
            else if (listaCampos[i].campo === CampoColaborador.CARGO) {
                const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _cargos = cargos.filter(cargo => cargo.perfilId === value);
                setCargosAtom(_cargos);
                formularioSubsede.setFieldsValue({ cargoId: "" })

            }
            else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
                const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _tipoPlanilla = tipoPlanilla.filter(planilla => planilla.perfilId === value);
                setTipoPlanillasAtom(_tipoPlanilla);
                formularioSubsede.setFieldsValue({ tipoPlanillaId: "" })

            }
        }
    }
    const handleOnchangeSede = async (value, option) => {
        let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.SEDE);

        for (let i = 0; i < listaCampos.length; i++) {
            if (listaCampos[i].campo === CampoColaborador.PERFIL) {
                const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _perfiles = perfiles.filter(perfil => perfil.sedeId === value)
                setPerfilesAtom(_perfiles);
                formularioSubsede.setFieldsValue({ perfilId: "" })


            }
            else if (listaCampos[i].campo === CampoColaborador.SECCION) {
                const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _secciones = secciones.filter(seccion => seccion.sedeId === value);
                setSeccionesAtom(_secciones);
                formularioSubsede.setFieldsValue({ seccionId: "" })

            }
            else if (listaCampos[i].campo === CampoColaborador.CARGO) {
                const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _cargos = cargos.filter(cargo => cargo.sedeId === value);
                setCargosAtom(_cargos);
                formularioSubsede.setFieldsValue({ cargoId: "" })

            }
            else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
                const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _tipoPlanilla = tipoPlanilla.filter(planilla => planilla.sedeId === value);
                setTipoPlanillasAtom(_tipoPlanilla);
                formularioSubsede.setFieldsValue({ tipoPlanillaId: "" })

            }
        }
    }
    const handleOnchangeSeccion = async (value, option) => {
        let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.SECCION);

        for (let i = 0; i < listaCampos.length; i++) {
            if (listaCampos[i].campo === CampoColaborador.PERFIL) {
                const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _perfiles = perfiles.filter(perfil => perfil.seccionId === value)
                setPerfilesAtom(_perfiles);
                formularioSubsede.setFieldsValue({ perfilId: "" })


            }
            else if (listaCampos[i].campo === CampoColaborador.SEDE) {
                const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _sedes = sedes.filter(sede => sede.seccionId === value);
                setSedesAtom(_sedes);
                formularioSubsede.setFieldsValue({ sedeId: "" })

            }
            else if (listaCampos[i].campo === CampoColaborador.CARGO) {
                const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _cargos = cargos.filter(cargo => cargo.seccionId === value);
                setCargosAtom(_cargos);
                formularioSubsede.setFieldsValue({ cargoId: "" })

            }
            else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
                const tipoPlanilla = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _tipoPlanilla = tipoPlanilla.filter(planilla => planilla.seccionId === value);
                setTipoPlanillasAtom(_tipoPlanilla);
                formularioSubsede.setFieldsValue({ tipoPlanillaId: "" })

            }
        }

    }
    const handleOnchangeTipoPlanilla = async (value, option) => {
        let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.PLANILLA);

        for (let i = 0; i < listaCampos.length; i++) {
            if (listaCampos[i].campo === CampoColaborador.PERFIL) {
                const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _perfiles = perfiles.filter(perfil => perfil.tipoPlanillaId === value)
                setPerfilesAtom(_perfiles);
                formularioSubsede.setFieldsValue({ perfilId: "" })


            }
            else if (listaCampos[i].campo === CampoColaborador.SECCION) {
                const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _secciones = secciones.filter(seccion => seccion.tipoPlanillaId === value);
                setSeccionesAtom(_secciones);
                formularioSubsede.setFieldsValue({ seccionId: "" })

            }
            else if (listaCampos[i].campo === CampoColaborador.CARGO) {
                const cargos = await CargoService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _cargos = cargos.filter(cargo => cargo.tipoPlanillaId === value);
                setCargosAtom(_cargos);
                formularioSubsede.setFieldsValue({ cargoId: "" })

            }
            else if (listaCampos[i].campo === CampoColaborador.SEDE) {
                const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _sedes = sedes.filter(sede => sede.tipoPlanillaId === value);
                setSedesAtom(_sedes);
                formularioSubsede.setFieldsValue({ tipoPlanillaId: "" })

            }
        }

    }
    const handleOnchangeCargo = async (value, option) => {
        let listaCampos = camposColaboradorAtom.filter(campoColaborador => campoColaborador.campoDependencia === CampoColaborador.CARGO);

        for (let i = 0; i < listaCampos.length; i++) {
            if (listaCampos[i].campo === CampoColaborador.PERFIL) {
                const perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _perfiles = perfiles.filter(perfil => perfil.cargoId === value)
                setPerfilesAtom(_perfiles);
                formularioSubsede.setFieldsValue({ perfilId: "" })


            }
            else if (listaCampos[i].campo === CampoColaborador.SECCION) {
                const secciones = await SeccionService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _secciones = secciones.filter(seccion => seccion.cargoId === value);
                setSeccionesAtom(_secciones);
                formularioSubsede.setFieldsValue({ seccionId: "" })

            }
            else if (listaCampos[i].campo === CampoColaborador.PLANILLA) {
                const tipoPlanillas = await TipoPlanillaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _tipoPlanillas = tipoPlanillas.filter(planilla => planilla.cargoId === value);
                setTipoPlanillasAtom(_tipoPlanillas);
                formularioSubsede.setFieldsValue({ tipoPlanillaId: "" })

            }
            else if (listaCampos[i].campo === CampoColaborador.SEDE) {
                const sedes = await SedeService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
                const _sedes = sedes.filter(sede => sede.cargoId === value);
                setSedesAtom(_sedes);
                formularioSubsede.setFieldsValue({ sedeId: "" })

            }
        }
    }
    return (
        <Form
            layout="vertical"
            form={formularioSubsede}
            onFinish={onClickBtnActualizarSubsede}
        >
            <Form.Item
                label="id"
                name="id"
                noStyle
            >
                <Input type="hidden" />
            </Form.Item>
            {/* { dependenciaSubsedeAtom!==CampoColaborador.SINCAMPO &&
                <Row>
                <Col span={12} offset={6}>
                    <Form.Item
                        label={dependenciaSubsedeAtom===CampoColaborador.SEDE ?"Centro de costo":                            
                                dependenciaSubsedeAtom===CampoColaborador.SECCION ?"Sección" :
                                dependenciaSubsedeAtom===CampoColaborador.PLANILLA ?"Tipo planilla":
                                dependenciaSubsedeAtom}
                        name={nameCampoAtom}
                        rules={[
                        {
                            required: true,
                            message: "Seleccione "+(dependenciaSubsedeAtom===CampoColaborador.SEDE ?"Centro de costo":                            
                                                        dependenciaSubsedeAtom===CampoColaborador.SECCION ?"Sección" :
                                                        dependenciaSubsedeAtom===CampoColaborador.PLANILLA ?"Tipo planilla":
                                                        dependenciaSubsedeAtom),
                        },
                        ]}
                    >
                            <Select
                                placeholder={"Seleccione "+(dependenciaSubsedeAtom===CampoColaborador.SEDE ?"Centro de costo":                            
                                dependenciaSubsedeAtom===CampoColaborador.SECCION ?"Sección" :
                                dependenciaSubsedeAtom===CampoColaborador.PLANILLA ?"Tipo planilla":
                                dependenciaSubsedeAtom)}
                            >
                            {listaCampoDependenciaAtom.length > 0 &&
                                listaCampoDependenciaAtom.map((lista) => {
                                            return (
                                                <Option key={lista.id} value={lista.id}>
                                                {lista.nombre}
                                                </Option>
                                            );
                                            })}  
                            </Select>
                    </Form.Item>
                    </Col>
                </Row>
              } */}
            {
                listaCamposAtom.length > 0 && (
                    listaCamposAtom.map((listaCampo) => {
                        if (listaCampo === CampoColaborador.PERFIL) {
                            return (
                                <Row>
                                    <Col span={12} offset={6}>
                                        <Form.Item
                                            label="Perfil"
                                            name="perfilId"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Seleccione Perfil"
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Seleccione Perfil"
                                                onChange={handleOnchangePerfil}
                                            >
                                                {perfilesAtom.length > 0 &&
                                                    perfilesAtom.map((lista) => {
                                                        return (
                                                            <Option key={lista.id} value={lista.id}>
                                                                {lista.nombre}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>

                                        </Form.Item>
                                    </Col>
                                </Row>
                            )
                        }

                        else if (listaCampo === CampoColaborador.SEDE) {
                            return (
                                <Row>
                                    <Col span={12} offset={6}>
                                        <Form.Item
                                            label="Centro de Costo"
                                            name="sedeId"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Seleccione Centro de Costo"
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Seleccione Centro de Costo"
                                                onChange={handleOnchangeSede}
                                            >
                                                {sedesAtom.length > 0 &&
                                                    sedesAtom.map((lista) => {
                                                        return (
                                                            <Option key={lista.id} value={lista.id}>
                                                                {lista.nombre}
                                                            </Option>
                                                        );
                                                    })}

                                            </Select>

                                        </Form.Item>
                                    </Col>
                                </Row>
                            )
                        }
                        else if (listaCampo === CampoColaborador.SECCION) {
                            return (
                                <Row>
                                    <Col span={12} offset={6}>
                                        <Form.Item
                                            label="Sección"
                                            name="seccionId"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Seleccione Sección"
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Seleccione Sección"
                                                onChange={handleOnchangeSeccion}
                                            >
                                                {seccionesAtom.length > 0 &&
                                                    seccionesAtom.map((lista) => {
                                                        return (
                                                            <Option key={lista.id} value={lista.id}>
                                                                {lista.nombre}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>

                                        </Form.Item>
                                    </Col>
                                </Row>
                            )
                        }
                        else if (listaCampo === CampoColaborador.PLANILLA) {
                            return (
                                <Row>
                                    <Col span={12} offset={6}>
                                        <Form.Item
                                            label="Tipo planilla"
                                            name="tipoPlanillaId"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Seleccione Tipo planilla"
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Seleccione Tipo planilla"
                                                onChange={handleOnchangeTipoPlanilla}
                                            >
                                                {tipoPlanillasAtom.length > 0 &&
                                                    tipoPlanillasAtom.map((lista) => {
                                                        return (
                                                            <Option key={lista.id} value={lista.id}>
                                                                {lista.nombre}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>

                                        </Form.Item>
                                    </Col>
                                </Row>
                            )

                        }
                        else if (listaCampo === CampoColaborador.CARGO) {
                            return (
                                <Row>
                                    <Col span={12} offset={6}>
                                        <Form.Item
                                            label="Cargo"
                                            name="cargoId"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Seleccione Cargo"
                                                },
                                            ]}
                                        >
                                            <Select
                                                placeholder="Seleccione Cargo"
                                                onChange={handleOnchangeCargo}
                                            >
                                                {cargosAtom.length > 0 &&
                                                    cargosAtom.map((lista) => {
                                                        return (
                                                            <Option key={lista.id} value={lista.id}>
                                                                {lista.nombre}
                                                            </Option>
                                                        );
                                                    })}
                                            </Select>

                                        </Form.Item>
                                    </Col>
                                </Row>
                            )
                        }

                    })

                )

            }
            <Row>
                <Col span={12} offset={6}>
                    <Form.Item
                        label="Nombre"
                        name="nombre"
                        rules={[
                            {
                                required: true,
                                message: "Escribe el nombre del subcentro de costo.",
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item style={{ textAlign: "center" }} >
                <Button
                    type="primary"
                    icon={<CheckOutlined />}
                    htmlType="submit"
                >
                    Actualizar Subcentro de costo
                </Button>
            </Form.Item>
        </Form>
    )

}
export default SubsedeEditar;