import React, { useState } from 'react';
import { Alert, Breadcrumb, Button, Card, Modal, Row, Space, Table, Upload, message, notification } from 'antd';
import {
    HomeOutlined,
    UploadOutlined,
    CheckCircleTwoTone,
    SendOutlined,
    CloseCircleTwoTone,
    WarningTwoTone,
    ExclamationCircleOutlined
} from "@ant-design/icons"
import { Provider as JotaiProvider, useAtom } from "jotai";
import { Headers, TipoUsuario } from 'enums';
import { LoginService } from 'services/LoginService';
import { cargaExitosaAtom, colaboradoresAtom, colaboradoresObservadosAtom } from './store/Store';
import { OnboardingMasivoService } from 'services';

const NotificarOnboardingMasivoPage = () => {
    return (
        <JotaiProvider>
            <Navegacion />
            <Card bordered={false}>
                <Botones />
                <ListaColaboradores />
                <ListaColaboradoresObservados />
            </Card>
        </JotaiProvider>
    );
};

const ListaColaboradores = () => {

    const [cargaExitosa] = useAtom(cargaExitosaAtom);
    const [colaboradores] = useAtom(colaboradoresAtom);

    const columnas = [
        {
            title: 'Doc. Identidad',
            width: '10%',
            render: (colaborador) =>
                <span>
                    {colaborador.tipoDocumentoIdentidad} - {colaborador.numeroDocumentoIdentidad}
                </span>
        },
        {
            title: 'Colaborador',
            width: '20%',
            render: (colaborador) =>
                <span>
                    {colaborador.nombres} {colaborador.apellidoPaterno} {colaborador.apellidoMaterno}
                </span>
        },
        {
            title: 'Plantillas a Notificar',
            width: '35%',
            render: (colaborador) =>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {colaborador.plantillas && colaborador.plantillas.map(plantilla => {
                        return (
                            <span key={plantilla}>
                                <CheckCircleTwoTone twoToneColor="#52c41a" /> {plantilla}
                            </span>
                        )
                    })}
                </div>
        },
        {
            title: 'Observaciones',
            // width: '35%',
            render: (colaborador) =>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {colaborador.observaciones && colaborador.observaciones.map(observacion => {
                        return (
                            <span key={observacion}>
                                <WarningTwoTone twoToneColor="#d48806" /> {observacion}
                            </span>
                        )
                    })}
                </div>
        },
    ];

    if (cargaExitosa === false) {
        return "";
    }

    return (
        <Table
            rowKey="numeroDocumentoIdentidad"
            dataSource={colaboradores}
            columns={columnas}
            pagination={false}
        />
    )
}

const ListaColaboradoresObservados = () => {

    const [cargaExitosa] = useAtom(cargaExitosaAtom);
    const [colaboradoresObservados] = useAtom(colaboradoresObservadosAtom);

    const columnas = [
        {
            title: 'Documento Identidad',
            render: (colaborador) =>
                <span>
                    {colaborador.tipoDocumentoIdentidad} - {colaborador.numeroDocumentoIdentidad}
                </span>
        },
        {
            title: 'Apellido Paterno',
            dataIndex: "apellidoPaterno"
        },
        {
            title: 'Apellido Materno',
            dataIndex: "apellidoMaterno"
        },
        {
            title: 'Nombres',
            dataIndex: "nombres"
        },
        {
            title: 'Errores',
            width: '30%',
            render: (colaborador) =>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    {colaborador.errores && colaborador.errores.map(error => {
                        return (
                            <span key={error}>
                                <CloseCircleTwoTone twoToneColor="#eb2f96" /> {error}
                            </span>
                        )
                    })}
                </div>
        },
    ];

    if (cargaExitosa === undefined || cargaExitosa === true) {
        return "";
    }

    return (
        <>
            <Alert message="Se encontraron las siguientes observaciones en el excel de carga:" type="error" showIcon />

            <Table
                rowKey="numeroDocumentoIdentidad"
                dataSource={colaboradoresObservados}
                columns={columnas}
                pagination={false}
                style={{ marginTop: "10px" }}
            />
        </>
    )
}

const Botones = () => {

    const [loading, setLoading] = useState(false);
    const [cargaExitosa, setCargaExitosa] = useAtom(cargaExitosaAtom);
    const [colaboradores, setColaboradores] = useAtom(colaboradoresAtom);
    const [colaboradoresObservados, setColaboradoresObservados] = useAtom(colaboradoresObservadosAtom);

    const props = {
        name: "excel",
        action: process.env.REACT_APP_ENDPOINT + "notificacion/onboarding-masivo/importar",
        showUploadList: "false",
        headers: {
            ["authorization"]: "Bearer " + LoginService.getJwtToken(),
            [Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
            [Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
            [Headers.TIPO_USUARIO]: TipoUsuario.ADMIN
        },
        onChange(info) {
            if (info.file.status === 'uploading') {
                setLoading(true);
            }
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                setCargaExitosa(info.file.response.success);
                setColaboradores(info.file.response.colaboradores);
                setColaboradoresObservados(info.file.response.colaboradoresObservados);
                message.success(`${info.file.name} file uploaded successfully`);
                setLoading(false);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
                setLoading(false);
            }
        },
    };

    const onClickBtnNotificar = async () => {

        Modal.confirm({
            title: '¿Está seguro de realizar la notificación?',
            icon: <ExclamationCircleOutlined />,
            okText: "Si, Notificar!",
            cancelButtonProps: { disabled: loading },
            onOk: async () => {

                setLoading(true);

                await OnboardingMasivoService.notificar({
                    colaboradores: colaboradores
                })

                setCargaExitosa(undefined);
                setColaboradores([]);
                setColaboradoresObservados([]);

                notification.success({
                    message: "Notificación Exitosa",
                    description: "Se realizó la acción correctamente.",
                });

                setLoading(false);
            },
        });

    }

    return (
        <Row justify="end" style={{ marginBottom: "15px" }}>
            <Space>
                <Upload {...props} maxCount={1} itemRender={() => ("")}>
                    <Button icon={<UploadOutlined />}>
                        Importar
                    </Button>
                </Upload>
                <Button type='primary' icon={<SendOutlined />} onClick={onClickBtnNotificar} disabled={cargaExitosa !== true}>
                    Notificar
                </Button>
            </Space>
        </Row>
    )
}

const Navegacion = () => {
    return (
        <Breadcrumb style={{ marginBottom: "10px" }}>
            <Breadcrumb.Item>
                <HomeOutlined />
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                Notificación
            </Breadcrumb.Item>
            <Breadcrumb.Item>
                Onboarding Masivo
            </Breadcrumb.Item>
        </Breadcrumb>
    )
}

export default NotificarOnboardingMasivoPage;