import { useEffect } from "react";
import { useAtom } from "jotai";
import { PlantillaService } from "services/reclutamiento-y-seleccion";
import { documentosAtom, etapasSeleccionadasAtom, plantillaAtom, documentoProcesoAtom } from "../AgregarDocumentoStore";

const useCargarDocumentos = () => {
  const [documentos, setDocumentos] = useAtom(documentosAtom);
  const[documentoProceso,setDocumentoProceso]=useAtom(documentoProcesoAtom);
  const [plantillas,setPlantillas] = useAtom(plantillaAtom);
  const [etapas] = useAtom(etapasSeleccionadasAtom);

  useEffect(() => {
    const cargarDocumentos = async () => {
      if (etapas.length > 0) {
        let _plantilla = plantillas;
        //console.log(etapas)
        if(!plantillas){
          const plantilla = await PlantillaService.buscarPorId(documentoProceso.plantillaId);
          setPlantillas(plantilla);
          //console.log(plantilla)
          _plantilla = plantilla
        }
        const documentosListados = _plantilla.documentos;
        //console.log(etapas)
        if (etapas.length === 1) {
          const documentosFiltrados = documentosListados.filter((documento) => documento.etapa.nombre === etapas[0]);
          setDocumentos(documentosFiltrados);
        } else 
        if (etapas.length === 2) {
          const documentosFiltrados = documentosListados.filter(
            (documento) => documento.etapa.nombre === etapas[0] || documento.etapa.nombre === etapas[1]
          );
          setDocumentos(documentosFiltrados);
        } else if (etapas.length === 3) {
          const documentosFiltrados = documentosListados.filter(
            (documento) =>
              documento.etapa.nombre === etapas[0] ||
              documento.etapa.nombre === etapas[1] ||
              documento.etapa.nombre === etapas[2]
          );
          setDocumentos(documentosFiltrados);
        }
      } else {
        setDocumentos([]);
      }
    };

    cargarDocumentos();
  }, [etapas]);

  return { documentos, setDocumentos };
};

export default useCargarDocumentos;
