import {useEffect} from "react";
import { procesosAtom} from "../ProcesosListarStore";
import { useAtom } from "jotai";
import { ProcesoService } from "services/reclutamiento-y-seleccion/ProcesoService";
const useCargarProcesos=()=>{
    const [procesos,setProcesos]=useAtom(procesosAtom);

  useEffect(()=>{
     const cargarDatos = async()=>{
          const respuesta= await ProcesoService.listar();
          setProcesos(respuesta);
     }
     cargarDatos();
  },[])
  return {procesos}
}
export default useCargarProcesos;