import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { colaboradorAtom } from "../LegajoColaboradorStore";
import  ColaboradorService  from "services/ColaboradorService";
import { useParams } from "react-router-dom";

const useCargarColaborador = () => {
    let { colaboradorId } = useParams();
	const [colaborador, setColaborador] = useAtom(colaboradorAtom);

	const cargarColaborador = async () => {        		
        const respuesta = await ColaboradorService.buscarPorId(colaboradorId);
		setColaborador(respuesta);		
	};

	return { cargarColaborador };
};
export default useCargarColaborador;