import React from "react";
import { Button, Space} from "antd";
import { PlusOutlined} from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const Botones = () => {
  let history = useHistory();

  const BotonCrear = () => {
    const onClickBtnCrear = () => {
      history.push("/asistencia/turnos/crear");
    };

    return (
      <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={onClickBtnCrear}
            >
              Crear Turno
       </Button>
    );
  };
  
  return (
    <React.Fragment>
      <Space>
        <BotonCrear />
      </Space>
    </React.Fragment>
  );
};
export default Botones;
