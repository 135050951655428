import React, { useEffect, useContext } from "react";
import { Container } from "../../../components/Container";
import { HomeOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { Table, Space, Breadcrumb, Select, Form, DatePicker } from "antd";
import { columnas } from "./componentes/Columnas";
import TipoDocumentoService from "../../../services/TipoDocumentoService";
import { tipoDocumentosAtom, carpetasAtom, enviosAtom, codigosOpcionesAtom } from "./NotificacionPorCompletarStore";
import {
  tipoDocumentoSeguimientoAtom,
  periodoAtom,
  paginaSeguimientoAtom,
  totalPaginacionEnviosAtom,
  paginActualAtom,
} from "store/local/seguimiento/SeguimientoStore";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { EnvioService } from "services/EnvioService";
import CarpetaService from "services/CarpetaService";
import moment from "moment";
import { OpcionService } from "services";
import { CategoriaDocumento } from "enums/CategoriaDocumento";

function NotificacionPorCompletar() {
  const [paginaSeguimiento, setPaginaSeguimiento] = useAtom(paginaSeguimientoAtom);
  const [totalPaginacion, setTotalPaginacion] = useAtom(totalPaginacionEnviosAtom);
  const [paginActual, setPaginActual] = useAtom(paginActualAtom);
  const { RangePicker } = DatePicker;
  const { Option } = Select;

  const [, setCodigosOpciones] = useAtom(codigosOpcionesAtom);
  const [envios, setEnvios] = useAtom(enviosAtom);
  const [, setCarpetas] = useAtom(carpetasAtom);
  const [tipoDocumentos, setTipoDocumentos] = useAtom(tipoDocumentosAtom);
  const [tipoDocumentoListar, setTipoDocumentoListar] = useAtom(tipoDocumentoSeguimientoAtom);
  const [periodoListar] = useAtom(periodoAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  let paginacion = 30;
  const [form] = Form.useForm();

  useEffect(() => {
    const cargarDatos = async () => {
      const envios_ = await EnvioService.listarNotificacionPorCompletar(
        getUsuarioAutenticado().empresaSeleccionada.id,
        tipoDocumentoListar ? tipoDocumentoListar : "",
        "",
        "",
        getUsuarioAutenticado().login
      );

      setEnvios(envios_.envios);
      setTotalPaginacion(envios_.totalEnvios);
      const _carpetas = await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
      setCarpetas(_carpetas);

      const tipoDocumento = await TipoDocumentoService.listarPorUsuario(getUsuarioAutenticado().login);
      setTipoDocumentos(tipoDocumento.filter(d=>d.categoriaDocumento.nombre===CategoriaDocumento.DOCUMENTO_LABORAL.nombre));

      // const codigosOpciones = await OpcionService.listar(getUsuarioAutenticado().id);
      // setCodigosOpciones(codigosOpciones);
    };
    cargarDatos();

  }, []);

 

  const onClickDate = async () => {
     setPaginActual(1);
     setPaginaSeguimiento(0);

    if (form.getFieldValue("periodo")) {
       const envios_ = await EnvioService.listarNotificacionPorCompletar(
        getUsuarioAutenticado().empresaSeleccionada.id,
        form.getFieldValue("tipoDocumento"),
        form.getFieldValue("periodo")[0].format("MM/YYYY"),
         form.getFieldValue("periodo")[1].format("MM/YYYY"),
        getUsuarioAutenticado().login
      );
       setEnvios(envios_.envios);
       setTotalPaginacion(envios_.totalEnvios);
     } else {
       const envios_ = await EnvioService.listarNotificacionPorCompletar(
        getUsuarioAutenticado().empresaSeleccionada.id,
        form.getFieldValue("tipoDocumento"),
        "",
         "",
        getUsuarioAutenticado().login
      );
       setEnvios(envios_.envios);
       setTotalPaginacion(envios_.totalEnvios);
     }
  };

  const onSelectTipoDocumento = async () => {
    setPaginActual(1);
    setPaginaSeguimiento(0);
    if (form.getFieldValue("periodo")) {
      const envios_ = await EnvioService.listarNotificacionPorCompletar(
        getUsuarioAutenticado().empresaSeleccionada.id,
        form.getFieldValue("tipoDocumento"),
        form.getFieldValue("periodo")[0].format("MM/YYYY"),
         form.getFieldValue("periodo")[1].format("MM/YYYY"),
        getUsuarioAutenticado().login
      );
      setEnvios(envios_.envios);
      setTotalPaginacion(envios_.totalEnvios);
    } else {
      const envios_ = await EnvioService.listarNotificacionPorCompletar(
        getUsuarioAutenticado().empresaSeleccionada.id,
        form.getFieldValue("tipoDocumento"),
        "",
         "",
        getUsuarioAutenticado().login
      );
      setEnvios(envios_.envios);
      setTotalPaginacion(envios_.totalEnvios);
    }

    setTipoDocumentoListar(form.getFieldValue("tipoDocumento"));
  };
  const onChangePagination = async (page) => {
     setPaginaSeguimiento(page - 1);
     setPaginActual(page);
  };

  return (
    <Container
      icono={
        <Breadcrumb itemRender>
          <Breadcrumb.Item href="/dashboard">
            <HomeOutlined />
          </Breadcrumb.Item>
          <Breadcrumb.Item href="/notificacion-por-completar">Notificación por completar</Breadcrumb.Item>
        </Breadcrumb>
      }
      // botonExtra={<BotonCrearNotificacion />}
    >
      <Space size={80}>
        <Form
          layout="inline"
          form={form}
          initialValues={{
            tipoDocumento: tipoDocumentoListar ? tipoDocumentoListar : "",
            periodo:
              periodoListar == null
                ? [moment().startOf("year"), moment().endOf("year")]
                : periodoListar.length != 0
                ? periodoListar
                : [moment().startOf("year"), moment().endOf("year")],
          }}
        >
          <Space size={30}>
            <Form.Item label="Tipo de Documento" name="tipoDocumento">
              <Select style={{ width: 210 }} onSelect={() => onSelectTipoDocumento()} showSearch>
                <Option value="">Todos</Option>
                {tipoDocumentos.map((tipoDocumento) => (
                  <Option key={tipoDocumento.id} value={tipoDocumento.id}>
                    {tipoDocumento.nombre}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item label="Periodo" name="periodo">
              <RangePicker
                allowClear={false}
                onChange={onClickDate}
                format={"MM/YYYY"}
                picker="month"
                style={{ width: 200 }}
              />
            </Form.Item>
          </Space>
        </Form>
      </Space>

      <br />
      <Table
        id="table"
        rowKey={(envio) => envio.id}
        dataSource={envios}
        columns={columnas}
        pagination={{
          size: "small",
          defaultCurrent: paginActual ? paginActual : 1,
          current: paginActual,
          total: totalPaginacion,
          showTotal: (total) => `Total ${total} documentos`,
          pageSize: paginacion,
          onChange: onChangePagination,
        }}
      />
    </Container>
  );
}

export default NotificacionPorCompletar;
