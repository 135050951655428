import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { Container } from "components/Container";
import { BotonesExtra } from "./componentes";
import {TablaProcesos} from "./componentes";
import { Breadcrumb } from "antd";
import { HomeOutlined } from "@ant-design/icons";
import { ModalObtenerLink } from "./componentes";
const ProcesosListarPage = () => {
  return (
    <JotaiProvider>
      <Container icono={<IconoRuta />} botonExtra={<BotonesExtra />}>
        <TablaProcesos />
        <ModalObtenerLink/>
      </Container>
    </JotaiProvider>
  );
};

export default ProcesosListarPage;

const IconoRuta = () =>{
  return(
      <Breadcrumb itemRender>
        <Breadcrumb.Item href="/dashboard">
          <HomeOutlined />
        </Breadcrumb.Item>
        <Breadcrumb.Item >
          Procesos de Onboarding
        </Breadcrumb.Item>
      </Breadcrumb>
    
  )
}