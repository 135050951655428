import { BaseService } from "./BaseService";

const BandejaTareasService = {

  async listarTareas() {
    const respuesta = await BaseService.get(`/bandeja-tareas`);
    return respuesta.data;
  }

};

export default BandejaTareasService;
