import { BaseService } from "./BaseService";

const HistorialNotificacionService = {
  async buscarPorDetalleEnvioId(detalleEnvioId , empresaId) {
    const respuesta = await BaseService.get("/historialNotificacion", {
      params: { detalleEnvioId: detalleEnvioId,
                empresaId: empresaId },
    });
    return respuesta.data;
  },
};
export default HistorialNotificacionService;
