import React from "react";
import { Container } from "components/Container";
import { TablaPlantillaEnvioDocumentos } from "./Tabla-Plantilla-Envio-Documentos";
import { Botones } from "./Botones";
import { ModalVerDocumento } from "./ModalVerDocumento";

const ListarPlantillaEnvioDocumentosPage = () => {
  return (
    <Container titulo="Plantillas Onboarding" botonExtra={<Botones />}>
      <TablaPlantillaEnvioDocumentos />
      <ModalVerDocumento />
    </Container>
  );
};
export default ListarPlantillaEnvioDocumentosPage;
