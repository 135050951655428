import React, { useContext, useEffect, useState } from "react";
import { FilterServer } from "components/MultiFiltro";
import { useAtom } from "jotai";
import { detalleEnvioSeguimientoAtom } from "../../ListarDetalleSeguimientoStore";

const FiltroBusqueda = ({setLoading, detalleEnvio, reset = false}) => {
  const [, setDetalleEnvio] = useAtom(detalleEnvioSeguimientoAtom);
  const filtrosBusqueda = {
    titulo: "Filtrar por: ",
    data: [
      { id: "estado", titulo: "Estado", tipo: "lista" },
      { id: "colaborador", titulo: "Apellidos y Nombres", tipo: "texto" },
      { id: "documentoIdentidad", titulo: "Documento Identidad", tipo: "texto" },
      { id: "codigoColaborador", titulo: "Código de Colaborador", tipo: "texto" }
    ],
  };

  const listaFiltros = [{ estado: "PENDIENTE" }, { estado: "VISUALIZADO" }, { estado: "SIN VISUALIZAR" }, { estado: "FIRMADO" }, { estado: "ERROR" }];
  

  const onFiltrar = async (filtrosSeleccionados) => {

    try {
      setLoading(true)
      if (Object.entries(filtrosSeleccionados).length>0){
        let detalleBase=detalleEnvio;
        for(let [key, value] of Object.entries(filtrosSeleccionados)){
          switch (key){
            case 'estado':
              if(value.length===1 && value.includes("SIN VISUALIZAR"))
              detalleBase = detalleBase.filter(e=>["PENDIENTE","No"].includes(e.estadoVisualizacion)||
                                                  ["PENDIENTE","No"].includes(e.visualizado))   

              else if (value.length>1 && !value.includes("PENDIENTE") && !value.includes("FIRMADO") && !value.includes("VISUALIZADO")){
                value.push("PENDIENTE")
                value.push("No")
                detalleBase = detalleBase.filter(e=> value.includes(e.estadoVisualizacion)||
                                                    value.includes(e.estadoDocumento)||
                                                    value.includes(e.estadoCorreoEnviado)||
                                                    value.includes(e.visualizado))
              }
              else 
              detalleBase = detalleBase.filter(e=> value.includes(e.estadoFirmaColaborador)||
                                                  value.includes(e.estadoVisualizacion)||
                                                  value.includes(e.estadoDocumento)||
                                                  value.includes(e.estadoNotificacion)||
                                                  value.includes(e.estadoCorreoEnviado)) 
   
              break;
          case 'colaborador':
            detalleBase = detalleBase.filter(e=>  value.some(v=>e.colaborador.nombreCompleto.toLowerCase().includes(v.toLowerCase())))
            break;
          case 'documentoIdentidad':
            detalleBase = detalleBase.filter(e=>  value.some(v=>e.colaborador.numeroDocumentoIdentidad.includes(v)))
            break;
          case 'codigoColaborador':
            detalleBase = detalleBase.filter(e=>  value.every(v=>e.colaborador.codigo === v))
            break;
          default :
          console.log("NADA")
          break;
          }
      }
      setDetalleEnvio(detalleBase)

      }else{
        setDetalleEnvio(detalleEnvio)
      }

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false)
    }
  };

  return (
    <FilterServer
      placeholder="Haz clic aquí para filtrar tus contratos"
      filtros={filtrosBusqueda}
      onFiltrarDatos={onFiltrar}
      data={listaFiltros}
      reset={reset}
     filtroSeleccionadoInicial={{ id: "colaborador", titulo: "Apellidos y Nombres", tipo: "texto" }}
    />
  );
};

export default FiltroBusqueda;
