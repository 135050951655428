import React, { useState} from "react";
import { Modal, message,Upload, Button, Typography,Row, notification } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import { BandejaAprobacionAdministracionPersonalService } from "services/contratos/BandejaAprobacionAdministracionPersonalService";
import { useAtom } from "jotai";
import { FormatoCarga } from "enums/FormatoCarga";

const ModalActualizarResponsables = ({visible, setVisible}) => {
    const { Text, Paragraph } = Typography;
    const [listaDocumentos, setListaDocumentos] = useState([]);
    const [loading, setLoading] = useState(false);

    const onClickBtnActualizarResponsables = async () => {
        if (listaDocumentos.name) {
          try {
            setLoading(true);
            const formData = new FormData();
            formData.append("file", listaDocumentos);
            await BandejaAprobacionAdministracionPersonalService.actualizarResponsables(formData);
            message.success("La actualización se realizó correctamente");
          } catch (error) {
            setLoading(false);
            notification.error({
              message: "Alerta!",
              description: error.response.data.mensaje,
            });
          } finally {
            setLoading(false);
            setVisible(false);
          }
        } else {
          message.error("No ha seleccionado el archivo excel");
        }
    };

    const onClickBtnCancelar = () => {
        setVisible(false);
    };

    const props = {
        onRemove: (file) => {
          setListaDocumentos([]);
          borrarDatos();
          return {
            listaDocumentos: [],
          };
        },
        beforeUpload: (file) => {
          if (!FormatoCarga.EXCEL.extension.includes(file.type)) {
            message.error(`${file.name} no es un archivo Excel`);
          } else {
            setListaDocumentos(file);
            return false;
          }
        },
        listaDocumentos,
      };

    function borrarDatos() {
        setListaDocumentos([]);
    }

    return (
        <Modal
            title="Actualizar responsable renovación y/o Administrador"
            open={visible}
            onOk={onClickBtnActualizarResponsables}
            onCancel={onClickBtnCancelar}
            okText="Si, Actualizar!"
            afterClose={borrarDatos}
            destroyOnClose={true}
        >
            <div>
                <Row>
                    <div
                        style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        }}
                    >
                        <Upload {...props} maxCount={1} accept={FormatoCarga.EXCEL.extension}>
                            <Button style={{ borderRadius: 4, borderColor: "green" }} loading={loading}>
                                <FileExcelOutlined style={{ fontSize: 20, color: "green" }} />{" "}
                                <Text style={{ color: "green" }}>Cargar archivo Excel</Text>
                            </Button>
                        </Upload>
                    </div>
                </Row>
            </div>
        </Modal>
    );
};

export default ModalActualizarResponsables;
