import { useEffect } from "react";
import { useAtom } from "jotai";
import { plantillasAtom } from "../SeguimientoRenovacionContratoStore";
import { PlantillaContratoService } from "services/PlantillaContratoService";

const useListarPlantillasContratos = () => {
    const [plantillas, setPlantillas] = useAtom(plantillasAtom);

    useEffect(() => {
        const cargarDatos = async () => {
            const plantillas = await PlantillaContratoService.listarNuevo(undefined);
            console.log("plantillas", plantillas);
            setPlantillas(plantillas);
        };
        cargarDatos();
    }, []);

    return { plantillas };
};

export default useListarPlantillasContratos;
