import React, { useContext } from "react";
import {Button } from "antd";
import { FormatoCarga } from "enums/FormatoCarga";
import { EnvioService } from "services/EnvioService";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import {
  loadingAtom,
  visibleAtom,
  urlAtom,detalleEnvioSeleccionadoAtom,
  documentoEnvioAtom
} from "../../ListarNotificacionCompletarDetalleStore";


const MenuAcciones = ({ envio }) => {


  const [loading, setLoading] = useAtom(loadingAtom);
  const [, setUrl] = useAtom(urlAtom);
  const [, setVisible] = useAtom(visibleAtom);
  const [detalleSeleccionado, setDetalleSeleccionado] = useAtom(detalleEnvioSeleccionadoAtom)
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [documentoEnvio,]=useAtom(documentoEnvioAtom)



  const onClickBtnVerDocumento = async () =>  {

    try {
      
      setLoading(true);
      setDetalleSeleccionado(envio.colaborador.numeroDocumentoIdentidad);

      let data;
      const formData = new FormData();

      if (
        envio.envio.formatoCargaId ===
        "fa8c5f15-dffb-4c64-8e52-e65de5faea75"
      ) {
        formData.append("envioId", documentoEnvio.id);
        formData.append(
          "numeroDocumentoColaborador",
          envio.colaborador.numeroDocumentoIdentidad
        );

        data = await EnvioService.verPDFExcelOrWs(formData);
      } else if (
        envio.envio.formatoCargaId ===
          "e136ea17-8bea-4b1c-b719-dd876597a303" ||
          envio.envio.formatoCargaId === FormatoCarga.TXT_MULTIPAGINA.id ||
          envio.envio.formatoCargaId ===
          FormatoCarga.TXT_MULTIPAGINA_PARAMETROS.id
      ) {
        data = await EnvioService.vistaPrevia({
          empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
          detalleEnvioId: envio.id,
        });
      } else {
        formData.append("ruta", envio?.urlDocumentoGenerado);
        formData.append("tipoDocumentoId", documentoEnvio?.tipoDocumentoId);
        formData.append(
          "empresaId",
          getUsuarioAutenticado().empresaSeleccionada.id
        );

        formData.append(
          "organizacionId",
          getUsuarioAutenticado().organizacionId
        );
        formData.append("detalleEnvioId", envio.id);
        formData.append(
          "numeroDocumentoIdentidad",
          envio.colaborador.numeroDocumentoIdentidad
        );
        data = await EnvioService.verPDF(formData);
      }
      const url = window.URL.createObjectURL(
        new Blob([data], { type: "application/pdf" })
      );

      setUrl(url);
      setVisible(true);

      setLoading(false);
      
    } catch (error) {
      setLoading(false);
    }
  }
  
  

  return     <Button type="link" onClick={onClickBtnVerDocumento} loading={
    detalleSeleccionado === envio.colaborador.numeroDocumentoIdentidad &&
    loading
  }>ver</Button>;
};

export default MenuAcciones;
