import React from "react";
import { Table } from "antd";
import { useListarCarga } from "../../hooks";
const ListaCargoLegajo = () => {
  const {cargas} = useListarCarga();

  const columnas=[
    {
      title: "Responsable Carga",
      dataIndex: ["responsableCarga","nombreCompleto"],
      key: "responsableCarga",
      align: "center",
    },
    {
      title: "Fecha y Hora",
      dataIndex: "fechaHoraCarga",
      key: "fechaHoraCarga",
      align: "center",
      
    },
    {
      title: "Documento",
      dataIndex: "nombreDocumentoCargado",
      key: "nombreDocumentoCargado",
      align: "center",
      
    },   
    {
      title: "Estado",
      dataIndex: "estado",
      key: "estado",
      align: "center",
     
    },
   
  ]
  return(<Table rowkey={(carga)=>carga.id} columns={columnas} dataSource={cargas}/>)
};
export default ListaCargoLegajo;
