import { atom } from "jotai";

export const pasoActualAtom = atom(1);
export const loadingAtom = atom(false);
export const modalVisibleAtom = atom(false);
export const resumenAtom = atom();
export const listaTipoPlantillaEnvioAtom = atom([]);
export const datosColaboradorStepAtom = atom();
export const colaboradoresAgregadosAtom = atom([]);
export const colaboradoresConCargasPendientesAtom = atom([]);
export const rutaStorageZipAtom = atom(undefined);
