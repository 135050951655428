import React, { useContext, useEffect, useState } from "react";
import { List, Spin } from "antd";
import * as atoms from "../ConsultarLegajoColaboradorStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import LegajoService from "../../../../services/LegajoService";
import { InputBusquedaColaborador } from "./input-busqueda-colaborador";
import { ItemColaborador } from "./ItemColaborador";
import { LoadingColaboradores } from "components/LoadingColaboradores";
import { LegajoColaboradorService } from "services";
import InfiniteScroll from "react-infinite-scroller";
import "./ListaColaboradores.less";

const ListaColaboradores = () => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  // const [loading, setLoading] = useState(false);

  const [totalColaboradores, setTotalColaboradores] = useState(0);
  const [totalColaboradoresCargados, setTotalColaboradoresCargados] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useAtom(atoms.loadingColaboradores);
  const [filtro, setFiltro] = useAtom(atoms.filtro);
  const [colaboradores, setColaboradores] = useAtom(atoms.colaboradores);
  const [, setColaboradorSeleccionado] = useAtom(atoms.colaboradorSeleccionado);
  const [, setPrimercolaborador] = useAtom(atoms.primerColaborador);
  const [page, setPage] = useState(0);

  useEffect(() => {
    cargarColaboradores();
  }, []);

  useEffect(() => {
    cargarColaboradores();
  }, [filtro]);


  const cargarColaboradores = async () => {
    var respuesta;
    try {
      setLoading(true);

      if (
        getUsuarioAutenticado().subdominio.includes("cofacorh") ||
        getUsuarioAutenticado().subdominio.includes("cititexrh")
      ) {
        respuesta = await LegajoColaboradorService.listarColaboradoresPorfiltroUsuarioYColaborador({
          empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
          usuarioId: getUsuarioAutenticado().id,
          filtro: filtro,
        });
      } else {

        respuesta = await LegajoColaboradorService.listarColaboradores({
          empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
          filtro: filtro,
        });

      }

      setColaboradores(respuesta.colaboradores);
      setPrimercolaborador(respuesta.colaboradores[0]);
      setColaboradorSeleccionado(respuesta.colaboradores[0]);
      setTotalColaboradores(respuesta.totalColaboradores);
      setTotalColaboradoresCargados(respuesta.colaboradores.length);
      setPage(0);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (totalColaboradores > 0 && totalColaboradores > 0 && totalColaboradoresCargados < totalColaboradores) {
      setHasMore(true);
    }
  }, [totalColaboradoresCargados, totalColaboradores]);

  const handleLoadMoreColaboradores = async (pagina) => {
    try {
      setLoading(true);
      if (totalColaboradoresCargados >= totalColaboradores) {
        setHasMore(false);
        setLoading(false);
        return;
      }
      const respuesta = await LegajoColaboradorService.listarColaboradores({
        empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
        filtro: filtro,
        pagina: page + 1,
      });
      let colaboradoresCopiados = colaboradores.slice();
      colaboradoresCopiados = colaboradoresCopiados.concat(respuesta.colaboradores);
      setColaboradores(colaboradoresCopiados);
      setTotalColaboradores(respuesta.totalColaboradores);
      setTotalColaboradoresCargados(totalColaboradoresCargados + respuesta.colaboradores.length);
      setPage(page + 1);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container-colaboradores">
      <InputBusquedaColaborador />
      <div
        style={{
          marginTop: "10px",
          textAlign: "right",
        }}
      >
        Total colaboradores: <b>{totalColaboradores}</b>
      </div>
      <div className="lista-colaboradores">
        {/* {loading && <LoadingColaboradores items={5} />} */}
        {/* {!loading && ( */}
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          loadMore={handleLoadMoreColaboradores}
          hasMore={!loading && hasMore}
          // hasMore={!this.state.loading && this.state.hasMore}
          useWindow={false}
        >
          <List
            itemLayout="horizontal"
            dataSource={colaboradores}
            rowKey={(colaborador) => colaborador.id}
            renderItem={(item) => <ItemColaborador colaborador={item} loading={loading} />}
          >
            {loading && hasMore && (
              <div className="demo-loading-container">
                <Spin />
              </div>
            )}
          </List>
        </InfiniteScroll>
        {/* )} */}
      </div>
    </div>
  );
};
export default ListaColaboradores;
