import React, { useContext } from "react";
import { Menu, Dropdown, Button } from "antd";
import { UnorderedListOutlined, SearchOutlined } from "@ant-design/icons";
import { useListaDocumentosStore } from "hooks/firma-documento-generico";
import { VisorPdfContext } from "context/visor-pdf"
import "./index.css"

const MenuAcciones = ({ documentoGenerico }) => {

    const getUrlPdf = useListaDocumentosStore(state => state.getUrlPdf);
    const { mostrarVisorPdf } = useContext(VisorPdfContext);

    const onClickBtnVerDocumento = async () => {
        const urlPdf = await getUrlPdf(documentoGenerico.id);
        const informacion = {
            descripcion: documentoGenerico.nombreDocumento,
            nombreArchivo: documentoGenerico.nombreDocumento
        }
        mostrarVisorPdf(urlPdf, informacion);
    }

    const menu = (
        <Menu>
            <Menu.Item key="ver-documento" icon={<SearchOutlined />}>
                <Button type="text" onClick={onClickBtnVerDocumento}>
                    Ver Documento
                </Button>
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                {/* <UnorderedListOutlined /> */}
                <UnorderedListOutlined style={{ fontSize: '16px', fontWeight: 'bold', color: "#1678c1" }} />
            </a>
        </Dropdown>
    );
}
export default MenuAcciones;