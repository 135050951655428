import React from "react";
import { Button, Table } from "antd";
import { useListarContratosAprobadosParaRenovar } from "../hooks";
import { LikeOutlined, DislikeOutlined } from "@ant-design/icons";
import { contratoSeleccionadoAtom, visibleModalAprobarContratoAtom } from "../SeguimientoRenovacionContratoStore";
import { useAtom } from "jotai";

const ListaContratos = () => {
    const { contratos } = useListarContratosAprobadosParaRenovar();
    //const contratos = [];

    const columnas = [
        {
            title: "Colaborador",
            dataIndex: ["colaborador", "nombreCompleto"],
        },
        {
            title: "Contrato",
            dataIndex: "nombreContrato",
        },
        // {
        //     title: "Cargo",
        //     dataIndex: ["colaborador", "cargo"],
        // },
        {
            title: "Fecha Inicio",
            dataIndex: "fechaInicio",
            align: "center",
        },
        {
            title: "Fecha Fin",
            dataIndex: "fechaFin",
            align: "center",
        },
        {
            title: "Meses Renovación",
            dataIndex: "totalMesesRenovacion",
            align: "center",
        },
        {
            title: "Aprobador",
            dataIndex: ["responsableJefatura", "nombreCompleto"],
        },
        // {
        //     title: "Estado",
        //     dataIndex: "estado",
        //     align: "center",
        // },
        {
            title: "Acciones",
            align: "center",
            width: "10%",
            render: (fila) => <MenuAcciones contrato={fila} />,
        },
    ];

    return <Table rowKey={(item) => item.id} columns={columnas} dataSource={contratos} pagination={false} />;
};

const MenuAcciones = ({ contrato }) => {
    const [, setVisibleModalAprobarContrato] = useAtom(visibleModalAprobarContratoAtom);
    const [, setContratoSeleccionado] = useAtom(contratoSeleccionadoAtom);

    const onClickBtnAprobar = () => {
        setContratoSeleccionado(contrato);
        setVisibleModalAprobarContrato(true);
    };

    return (
        <React.Fragment>
            <Button type="link" onClick={onClickBtnAprobar}>
                <LikeOutlined /> Aprobar
            </Button>
            <Button type="link" danger>
                <DislikeOutlined /> Rechazar
            </Button>
        </React.Fragment>
    );
};

export default ListaContratos;
