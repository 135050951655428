import React from "react";
import { Table } from "antd";
import { useListarDescargasMasivas } from "../../hooks";
import MenuAcciones from "./MenuAcciones";
import EstadoDescargaMasiva from "./EstadoDescargaMasiva";

const TablaDescargaMasiva = () => {
  const { descargas } = useListarDescargasMasivas();

  const columnas = [
    {
      title: "Colaborador",
      dataIndex: "nombreCompletoColaborador",
      align: "center",
    },
    {
      title: "Tipo Documento",
      dataIndex: "tipoDocumentoNombre",
      align: "center",
    },
    {
      title: "Periodo Inicio",
      dataIndex: "periodoInicio",
      align: "center",
    },
    {
      title: "Periodo Fin",
      dataIndex: "periodoFin",
      align: "center",
    },
    {
      title: "Fecha Registro",
      dataIndex: "fechaRegistro",
      align: "center",
    },
    {
      title: "Estado",
      align: "center",
      render: (fila) => <EstadoDescargaMasiva descargaMasiva={fila} />,
    },
    {
      title: "Descargar",
      align: "center",
      render: (fila) => <MenuAcciones descargaMasiva={fila} />,
    },
  ];
  return <Table rowKey={(descarga) => descarga.id} dataSource={descargas} columns={columnas} />;
};
export default TablaDescargaMasiva;
