import { atom } from "jotai";

export const tipoPlanilla = atom([]);
export const dependenciaTipoPlanilla= atom();

export const listaCampos=atom([]);
export const perfiles=atom([]);
export const sedes=atom([]);
export const subsedes=atom([]);
export const secciones=atom([]);
export const cargos=atom([]);
export const camposColaborador=atom([]);
