import React, { useEffect, useState, useContext } from 'react';
import { Form, Modal, Space,Row,
    Col, Table } from "antd";
import {
    UserOutlined,
    PhoneOutlined,
    MailOutlined,
    CalendarOutlined,
  } from "@ant-design/icons";
import { useAtom } from "jotai";
import HistorialNotificacionService from "../../../../services/HistorialNotificacionService";
import moment from "moment"
import { consultaDocumentoSeleccionadoAtom,visibleModalHistorialNotifiacaionAtom} from "../../ConsultaDocumentoStore"
import { SecurityContext } from '../../../../context/SecurityContextProvider';


const ModalHistorialNotificacion = () => {
  const {getUsuarioAutenticado } =useContext(SecurityContext);
  const [consultaDocumentoSeleccionado, ] = useAtom(consultaDocumentoSeleccionadoAtom);
  const [visibleModalHistorialNotifiacaion, setVisibleModalHistorialNotifiacaion] = useAtom(visibleModalHistorialNotifiacaionAtom);
  const [historialNotificacion, setHistorialNotificacion] = useState(undefined);
  useEffect(() => {
    const cargarDatos= async()=>{
        try {
            //console.log("detalle Seleccionado Buscado::", detalleEnvioSeleccionado);
            const historialBuscado= await HistorialNotificacionService.buscarPorDetalleEnvioId(consultaDocumentoSeleccionado?.id , getUsuarioAutenticado().empresaSeleccionada.id);
            for (let i = 0; i < historialBuscado.length; i++) {
              historialBuscado[i].fechaHoraNotificacion=moment(historialBuscado[i].fechaHoraNotificacion).format('DD/MM/YYYY hh:mm A');
            }
            setHistorialNotificacion(historialBuscado); 
            //console.log("Historial Buscado::", historialBuscado);
        } catch (error) {
            console.error(error);
        }

    }
    cargarDatos();
  }, [visibleModalHistorialNotifiacaion]);

  
    

const columnas = [
    {
      title: "Correo Electrónico",
      dataIndex: "correoElectronico",
    },
    {
      title: "Número de celular",
      dataIndex: "celular",
      render: (text, fila) => {
        if (
          fila.celular === null 
        ) {
          return fila.celular="No Registrado";
        }
        return fila.celular;
      }
    },
    {
      title: "Hora de envío",
      dataIndex: "fechaHoraNotificacion",
    }
  ];

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    
    const onClickBtnCerrar = () => {
        setVisibleModalHistorialNotifiacaion(false);
    }
  
    return (
        <Modal
            title="Detalle de Notificación"
            visible={visibleModalHistorialNotifiacaion}
           onOk={onClickBtnCerrar}
           onCancel={onClickBtnCerrar}
            cancelButtonProps={{ style: { display: 'none' } }}
            okText="Aceptar"
            confirmLoading={loading}
            forceRender
            width={1000}
        >
         
        <div style={{ textAlign: "left" }}>
        {<React.Fragment>
          <Space>
            <Row justify="start"><span style={{ fontWeight: "bold" }}>
                <UserOutlined /> Colaborador:
                
                </span></Row>
                <Row justify="start">
                <span>
                  {consultaDocumentoSeleccionado?.colaborador?.nombreCompleto}
                </span>
              </Row>
                </Space> 
          


        </React.Fragment>}
      </div>  
      <br/>   
      <div style={{ color: "#000000" }}><Table 
      bordered
      rowKey={(historialNotificacion) => historialNotificacion.id}
      dataSource={historialNotificacion}
      columns={columnas}
      visible={false}
    /></div>
        </Modal>
    );
};

export default ModalHistorialNotificacion;