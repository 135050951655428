import { Alert, Badge, Button, Col, Drawer, Form, Row, Select, Upload, message } from 'antd';
import { useAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { openDrawerImportarAusenciasMasivasAtom } from '../../consultaSolicitudesStore';
import { UploadOutlined } from "@ant-design/icons"
import { LoginService } from 'services/LoginService';
import { ApiResponse, Headers, TipoUsuario } from 'enums';
import { AusenciaService, TiposAusenciaService } from 'services/VacacionesService';

const DrawerImportarAusenciasMasiva = () => {

    const STATUS = Object.freeze({
        LOADING: 'loading',
        SUCCESS: 'success',
        ERROR: 'error',
        // Puedes agregar más estados según tus necesidades
    });

    const [form] = Form.useForm();
    const [open, setOpen] = useAtom(openDrawerImportarAusenciasMasivasAtom);
    const [tiposAusencia, setTiposAusencia] = useState([]);
    // const [loading, setLoading] = useState(false);
    const [errores, setErrores] = useState([]);
    const [estado, setEstado] = useState(undefined);

    useEffect(() => {
        const fetchData = async () => {
            if (open) {
                const response = await TiposAusenciaService.listar();
                if (response.estado === ApiResponse.EXITO) {
                    setTiposAusencia(response.data.map(ausencia => ({
                        value: ausencia.id,
                        label: ausencia.descripcion
                    })));
                }
            }
        }
        fetchData();
    }, [open])

    const onClickBtnProcesar = () => {
        form
            .validateFields()
            .then(async (formulario) => {
                setEstado(STATUS.LOADING);
                const formData = new FormData();
                formData.append("excel", formulario.excel.file);
                formData.append("tipoAusenciaId", formulario.tipoAusenciaId);
                const response = await AusenciaService.importar(formData);
                if (response.estado === "EXITO") {
                    setEstado(STATUS.SUCCESS);
                    form.resetFields();
                } else {
                    setEstado(STATUS.ERROR);
                    setErrores(response.data);
                }

            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }

    return (
        <Drawer title="Importar Ausencia Masiva" placement="right" onClose={() => setOpen(false)} open={open}>

            <Form layout='vertical' form={form}>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Tipo Ausencia"
                            name="tipoAusenciaId"
                            rules={[
                                {
                                    required: true,
                                    message: "Seleccione el tipo de ausencia.",
                                },
                            ]}
                        >
                            <Select options={tiposAusencia} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            label="Plantilla Excel"
                            name="excel"
                            valuePropName="excel"
                            rules={[
                                {
                                    required: true,
                                    message: "Por favor, cargue su archivo.",
                                },
                            ]}
                        >
                            <Upload
                                beforeUpload={() => false}
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                maxCount={1}>
                                <Button icon={<UploadOutlined />}>Seleccionar Archivo</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Form.Item>
                            <Button type="primary" loading={estado === STATUS.LOADING} onClick={onClickBtnProcesar}>
                                Procesar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            {estado === STATUS.SUCCESS
                && <Alert
                    message="Proceso Exitoso"
                    description="Espere mientras se termina de procesar la carga. Puede cerrar la ventana y actualizar en unos minutos."
                    type="success"
                    showIcon
                />
            }

            {estado === STATUS.ERROR &&
                <Alert
                    style={{ marginTop: "10px" }}
                    message="No se pudo procesar la importación. Detalle:"
                    description={
                        <>
                            {errores && errores.map((error, index) => (
                                <div key={index}>
                                    <Badge status="error" text={error} />
                                </div>
                            ))}
                        </>
                    }
                    type="error"
                    showIcon />
            }
        </Drawer >
    );
};

export default DrawerImportarAusenciasMasiva;