import React from "react";
import { EstadoNotificacion } from "enums/EstadoNotificacion";
import { Tag } from "antd";
import { TagNotificado } from "./TagNotificado";

const EtiquetaEstadoNotificacion = ({ documento }) => {
    switch (documento.estadoNotificacion) {
        case EstadoNotificacion.NO_APLICA:
            return <Tag color="default">No Aplica</Tag>;

        case EstadoNotificacion.PENDIENTE:
            return <Tag color="gold" className="milegajo-ant-tag">PENDIENTE</Tag>;

        case EstadoNotificacion.ENVIADO:
            return (
                <TagNotificado documento={documento} color="green" className="milegajo-ant-tag pointer">
                    ENVIADO
                </TagNotificado>
            );

        case EstadoNotificacion.ERROR:
            return <Tag color="red" className="milegajo-ant-tag">ERROR</Tag>;

        default:
            return <Tag color="gold" className="milegajo-ant-tag">PENDIENTE</Tag>;
    }
}

export default EtiquetaEstadoNotificacion;