import { Button,Dropdown,Menu, Modal, message } from "antd";
import React,{ useContext} from "react";
import { UnorderedListOutlined, CaretDownOutlined, SelectOutlined, PaperClipOutlined, CloseOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import { useAtom } from "jotai";
//import { useModalDetalleVacaciones } from "../../hooks";
import {solicitudAusenciaSeleccionadaAtom,visibleModalSeguimientoAtom,visibleModalAdjuntarAltaMedicaAtom,
  visibleModalEliminarSolicitudAtom, solicitudesAusenciaAtom} from "../../consultaSolicitudesStore";
import  {SolicitudAusenciaService} from "services";
import { SecurityContext } from "../../../../../../context/SecurityContextProvider";

const MenuAcciones = ({ solicitud }) => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  //const { mostrarModal: mostrarModalDetalleVacaciones } = useModalDetalleVacaciones();
   const [solicitudSeleccionada,setSolicitudSeleccionada] = useAtom(solicitudAusenciaSeleccionadaAtom);
   const [visibleModalSeguimiento,setVisibleModalSeguimiento] = useAtom(visibleModalSeguimientoAtom);
   const [visibleModalAltaMedica,setVisibleModalAltaMedica]=useAtom(visibleModalAdjuntarAltaMedicaAtom);
   const [visibleModalEliminarSolictud,setVisibleModalEliminarSolictud]=useAtom(visibleModalEliminarSolicitudAtom);
   const [solicitudesAusencia, setSolicitudesAusencia] = useAtom(solicitudesAusenciaAtom);

  const onClickMenu = (opcion) => {
    switch (opcion.key) {
        case 'seguimientos':
            onClickMenuSeguimientos();
            break; 
         case 'adjuntarAltaMedica':
            onClickAdjuntarAltaMedica(); 
            break;   
        case 'eliminarSolicitud':
            onClickEliminarSolicitud(); 
            break;  
        default:
            console.error("No se encuentra esa opción");
    }
  }
  
  const onClickEliminarSolicitud=()=>{
    //En caso quieran agregar un motivo usar el modal
    // setSolicitudSeleccionada(solicitud);
    // setVisibleModalEliminarSolictud(true);

    Modal.confirm({
			icon: <ExclamationCircleOutlined />,
			content: "¿Está seguro de cancelar la solicitud ?",
			onOk() {
				cancelarSolicitud();
			},
			onCancel() {},
			okText: "Si",
			cancelText: "No",
		});

    const cancelarSolicitud = async () => {
      try{
        const response = await SolicitudAusenciaService.cancelarSolicitudFromAdmin({ 
          solicitudAusenciaId: solicitud.id,
          id:getUsuarioAutenticado().id,
          numeroDocumentoIdentidad:getUsuarioAutenticado().numeroDocumentoIdentidad,
          nombreCompleto:getUsuarioAutenticado().nombreCompleto
        });
        const _documentos = [...solicitudesAusencia]; //siempre se debe generar un nuevo array
        const indice = solicitudesAusencia.findIndex((solicitud) => solicitud.id === solicitud.id);
        const detalleModificar = solicitudesAusencia[indice];
      
        const detalleModificado = {
          ...detalleModificar,
          estado:"CANCELADO"
        }
        _documentos.splice(indice, 1, detalleModificado);
        setSolicitudesAusencia(_documentos);
        
        
        message.success("Se ha cancelado la solicitud correctamente.");
      } catch (error) {
       
        message.error("Ocurrio un error al realizar la solicitud");
     }
    
  
       // if (response.estado === ApiResponse.EXITO) {
      //   message.success("Se ha cancelado la solicitud correctamente.");
  
      //   cargarDatos();
      // } else {
      //   message.error(response.mensaje);
      // }
    };

  }
  const onClickMenuSeguimientos=()=>{
      setSolicitudSeleccionada(solicitud);
      setVisibleModalSeguimiento(true);
  }

  const onClickAdjuntarAltaMedica=()=>{
    setSolicitudSeleccionada(solicitud);
    setVisibleModalAltaMedica(true);
  }
  const menu = () => {
	  const isAceros = getUsuarioAutenticado().empresaSeleccionada.razonSocial.toUpperCase().includes("ACEROS CHILCA SAC")
		? true
		: false;

    return (
        <Menu onClick={onClickMenu}>
            <Menu.Item key="seguimientos">
                <SelectOutlined /> Seguimientos
            </Menu.Item>
            <Menu.Item key="adjuntarAltaMedica">
              <PaperClipOutlined /> Adjuntar alta médica
            </Menu.Item>
            { solicitud?.admin && isAceros && solicitud?.estado =="APROBADO" &&(
              <Menu.Item key="eliminarSolicitud">
                <CloseOutlined /> Eliminar
              </Menu.Item>
            )}
        </Menu>
    )
}
  
  return (   
    <React.Fragment>
        <Dropdown
                overlay={menu}
                trigger={['click']}
                placement="bottomCenter">

                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <UnorderedListOutlined style={{ fontSize: '16px', fontWeight: 'bold', color: "#1678c1" }} />
                    <CaretDownOutlined style={{ paddingLeft: '2px', fontSize: '12px', fontWeight: 'bold', color: "#1678c1" }} />
                </a>

          </Dropdown>
         
    </React.Fragment> 
         
  );
}

export default MenuAcciones;
