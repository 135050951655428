//import { BaseService } from "./BaseService";
import { HttpService } from "./HttpService";
import { Headers } from "enums";

export const UsuarioFirmanteService = {
  async listarUsuariosFirmantes(empresaId) {
    const respuesta = await HttpService(empresaId).get(
      "/usuario/listarUsuariosFirmantes",
      {
        params: { empresaId: empresaId },
      }
    );
    return respuesta.data;
  },
};
