import React from "react";
import { Table, Tag, Button, Spin } from "antd";
import { useCargarProcesos } from "../../hooks";
import { MenuAcciones } from "../MenuAcciones";
import { useHistory } from "react-router-dom";

import { FiltroBusqueda } from "../FiltroBusqueda";
import { useState } from "react";
import { useEffect } from "react";
import { ProcesoService } from "services/reclutamiento-y-seleccion/ProcesoService";
import { MenuAvance } from "../MenuAvance/MenuAvance";
import { LoadingOutlined } from "@ant-design/icons";

const TablaProcesos = () => {
  const { procesos } = useCargarProcesos();
  let history = useHistory();
  const [filtros, setFiltros] = useState(undefined);
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(false);
  const [documentosListado, setDocumentosListados] = useState([]);

  useEffect(() => {
    if (filtros !== undefined) {
      actualizarDatos();
    }
  }, [filtros]);

  async function actualizarDatos(pagina, cantidad) {
    setLoading(true);
    const respuesta = await ProcesoService.listarProcesoPorFiltro(
      filtros["convocatoria"],
      filtros["postulante"],
      filtros["estado"],
      0,
      10
    );
    //console.log(respuesta)
    setDocumentosListados(respuesta.procesos);
    setReset(false);
    setLoading(false);
  }

  const columnas = [
    {
      title: "Convocatoria",
      dataIndex: ["convocatoria", "cargo"],
      align: "center",
    },
    {
      title: "Postulante",
      //dataIndex: ["postulante","nombreCompleto"],
      align: "center",
      render: (text, fila) => (
        <Button
          type="link"
          onClick={() => {
            return history.push(`/reclutamiento-y-seleccion/procesos/${fila.id}/documentos`);
          }}
        >
          {fila.postulante?.nombreCompleto}
        </Button>
      ),
    },
    {
      title: "Correo electrónico",
      dataIndex: ["postulante", "correoElectronico"],
      align: "center",
    },
    {
      title: "Porcentaje de avance",
      dataIndex: "porcentajeAvance",
      align: "center",
      render: (text, fila) => <MenuAvance documento={fila} />,
    },
    {
      title: "Fecha Inicio",
      dataIndex: "fechaInicio",
      align: "center",
    },
    {
      title: "Fecha Fin",
      dataIndex: "fechaFin",
      align: "center",
    },
    {
      title: "Estado",
      //dataIndex: "estado",
      align: "center",
      render: (fila) => <Tag color="processing">{fila.estado}</Tag>,
    },
    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      render: (fila) => <MenuAcciones proceso={fila} />,
    },
  ];

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 28 }} />} spinning={loading} size="large" tip="Cargando...">
      <FiltroBusqueda setFiltros={setFiltros} filtro={filtros} reset={reset} filtroActual={filtros} />
      <Table rowKey={(procesos) => procesos.id} dataSource={documentosListado} columns={columnas} loading={loading} />
    </Spin>
  );
};
export default TablaProcesos;
