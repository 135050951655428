import { BaseService } from "./BaseService";
import qs from "qs";

export const FormularioService = {
	async listarPorEmpresaId(empresaId) {
		const respuesta = await BaseService.get("/formulario", {
			params: {
				empresaId: empresaId,
			},
		});
		return respuesta.data;
	},

	async listarPorUsuario(usuarioLogin) {
		const respuesta = await BaseService.get("/formulario/listar-por-usuario", {
			params: {
				usuarioLogin: usuarioLogin,
			},
		});
		return respuesta.data;
	},

	async buscarPorFormularioId(formularioId) {
		const respuesta = await BaseService.get(`/formulario/${formularioId}`);
		return respuesta.data;
	},

	async insertar(formulario) {
		const respuesta = await BaseService.post("/formulario", formulario);
		return respuesta.data;
	},

	async guardarEsquema(formulario) {
		const respuesta = await BaseService.post("/formulario/esquema", formulario);
		return respuesta.data;
	},

	async listarPorEmpresaYEnvio(empresaId, envioId, estado, colaborador, documentoIdentidad) {
		const respuesta = await BaseService.get("/formulario/listar-por-empresa-y-envio", {
			params: {
				empresaId: empresaId,
				envioId: envioId,
				estado: estado,
				colaborador: colaborador,
				documentoIdentidad: documentoIdentidad,
			},
			paramsSerializer: (params) => {
				return qs.stringify(params, { arrayFormat: "repeat" });
			},
		});
		return respuesta.data;
	},
	async buscarEnvioPorId(envioId) {
		const respuesta = await BaseService.get(`/formulario/envio/${envioId}`);
		return respuesta.data;
	},
	async actualizar(formulario, formularioId) {
		const respuesta = await BaseService.put(`/formulario/${formularioId}`, formulario);
		return respuesta.data;
	},
	async limpiarPlantillaPdf(formulario) {
		const respuesta = await BaseService.put("/formulario/limpiar-plantilla-pdf", formulario);
		return respuesta.data;
	},
	guardarDiseno(formulario) {
		return BaseService.put("/formulario/diseno", formulario);
		//return respuesta.data;
	},
	async eliminar(formularioId) {
		const respuesta = await BaseService.delete(`formulario/${formularioId}`);
		return respuesta.data;
	},

	async exportarDatos(params) {
		const respuesta = await BaseService.get(
			"/formulario/exportar-datos",

			{
				responseType: "blob",
				params: {
					...params,
				},
				paramsSerializer: (params) => {
					return qs.stringify(params, { arrayFormat: "repeat" });
				},
			}
		);
		return respuesta;
	},
};
