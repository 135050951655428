import React, { useContext, useState } from "react";
import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Document, Page } from "react-pdf";
import { VisorPdfContext } from "context/visor-pdf";

const Body = () => {
  const { urlPdf, loading, setBlobPdf, finalizarCargaPdf } = useContext(VisorPdfContext);
  const [totalPaginas, setTotalPaginas] = useState(undefined);

  async function onLoadSuccess(successEvent) {
    const data = await successEvent.getData();
    const blob = new Blob([data], { type: "application/pdf" });
    setBlobPdf(blob);
    setTotalPaginas(successEvent.numPages);
    finalizarCargaPdf();
  }

  return (
    <div className="milegajo-visor-pdf-body">
      {loading && (
        <div className="loading">
          <Loading3QuartersOutlined spin className="spin" />
        </div>
      )}

      <Document file={urlPdf} onLoadSuccess={onLoadSuccess} loading={null}>
        {Array.from(new Array(totalPaginas), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={1.5} loading={""} />
        ))}
      </Document>
    </div>
  );
};

export default Body;
