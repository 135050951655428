import React, { useState } from "react";
import { Dropdown, Menu, Button } from "antd";
import { UnorderedListOutlined, EditOutlined, CaretDownOutlined, CheckCircleOutlined, SearchOutlined, StopOutlined, MailOutlined, AuditOutlined } from "@ant-design/icons";
import { EstadoRegistro } from "enums/EstadoRegistro";

const MenuAcciones = ({ documento, onClickVerDocumento, onClickReenviarNotificacion, onClickInhabilitarDocumento, onClickHabilitarDocumento, onClickActualizarCorreo, onClickHitorialNotificacion }) => {

    // const [visibleMenu, setVisibleMenu] = useState(false);

    const onClickMenu = async ({ key }) => {
        if (key === "ver-documento") {
            await onClickVerDocumento(documento);
        } else if (key === 'reenviar-notificacion') {
            onClickReenviarNotificacion(documento.id);
        } else if (key === 'inhabilitar-documento') {
            onClickInhabilitarDocumento(documento.id);
        } else if (key === 'habilitar-documento') {
            onClickHabilitarDocumento(documento.id);
        } else if (key === 'actualizar-correo') {
            onClickActualizarCorreo(documento);
        } else if (key === 'historial-notificacion'){
            onClickHitorialNotificacion(documento);
        }
    }

    const menu = () => {

        if (documento.estadoRegistro === EstadoRegistro.INACTIVO) {
            return (
                <Menu onClick={onClickMenu}>
                    <Menu.Item key="ver-documento">
                        <SearchOutlined /> Ver Documento
                    </Menu.Item>
                    <Menu.Item key="habilitar-documento">
                        <CheckCircleOutlined /> Habilitar Documento
                    </Menu.Item >
                </Menu >
            )
        }

        return (
            <Menu onClick={onClickMenu}>
                <Menu.Item key="ver-documento">
                    <SearchOutlined /> Ver Documento
                </Menu.Item>
                <Menu.Item key="reenviar-notificacion">
                    <MailOutlined /> Reenviar Notificación
                </Menu.Item>
                <Menu.Item key="inhabilitar-documento">
                    <StopOutlined /> Inhabilitar Documento
                </Menu.Item >
                <Menu.Item key="actualizar-correo">
                    <EditOutlined /> Actualizar correo
                </Menu.Item >
                <Menu.Item key="historial-notificacion">
                    <AuditOutlined /> Historial de Notificacion
                </Menu.Item >
            </Menu >
        )
    }

    return (
        <Dropdown
            overlay={menu}
            trigger={['click']}
            placement="bottomRight">
            {/* onVisibleChange={(flag) => setVisibleMenu(flag)}
            visible={visibleMenu}> */}

            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                <UnorderedListOutlined style={{ fontSize: '16px', fontWeight: 'bold', color: "#1678c1" }} />
                <CaretDownOutlined style={{ paddingLeft: '2px', fontSize: '12px', fontWeight: 'bold', color: "#1678c1" }} />
            </a>

        </Dropdown>
    );
}

export default MenuAcciones;