import React, {useState} from "react";
import { Form, Input, Row, Col, Button, TimePicker, Select, Tabs, notification } from "antd";
import { PlusOutlined} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";
import { TipoTurno } from "enums/asistencia/TipoTurno";
import { Provider as JotaiProvider, useAtom } from "jotai";
import { TurnoService } from "services/asistencias/TurnoService";
import moment from "moment";

const FormCrearTurno = () => {
  const format = 'HH:mm';
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const options = [
    { key: 1, value: '1', label: 'Lunes' },
    { key: 2, value: '2', label: 'Martes' },
    { key: 3, value: '3', label: 'Miercoles' },
    { key: 4, value: '4', label: 'Jueves' },
    { key: 5, value: '5', label: 'Viernes' },
    { key: 6, value: '6', label: 'Sabado' },
    { key: 7, value: '7', label: 'Domingo' },
  ];

  const onClickRetonarTurnos=()=>{
    history.push("/asistencia/turnos");
  }

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const onClickBtnCrearTurno= async(form) =>{
    try{
      setLoading(true);
      

      form.horaInicio = moment(form.horaInicioAuxiliar).format('HH:mm');
      form.horaFin = moment(form.horaFinAuxiliar).format('HH:mm');

      console.log("turnos", form);
      const respuesta = await TurnoService.insertar(form);     
      notification.success({
        message: "Operación exitosa",
        description: "Se registró el turno exitosamente",
      });
       setLoading(false);
      history.push(`/asistencia/turnos`);
    } catch (error) {
       setLoading(false);
      console.error(error);
      notification.error({
        message: "Alerta! Ocurrio un error al registrar el turno",
      });
    }
  }

  return (
   <div>
      <Form
             layout="vertical"           
             //size="middle"
             onFinish={onClickBtnCrearTurno}
        >
         <Row gutter={16}>
           <Col span={12}>
             <Form.Item
                   label="Nombre"
                   name="nombre"
                   rules={[
                       {
                           required: true,
                           message: "El campo nombre es obligatorio.",
                       },
                   ]}   
               >
                   <Input/>
               </Form.Item>
           </Col>
           <Col span={12}>
             <Form.Item
                   label="Descripción"
                   name="descripcion"
                   rules={[
                       {
                           required: true,
                           message: "El campo descripción es obligatorio.",
                       },
                   ]}   
               >
                   <Input/>
               </Form.Item>
           </Col>
          </Row> 
        <Row gutter={16}>
		 					<Col span={12}>
		 						<Form.Item label="Tipo" name="tipoAuxiliar">
		 							<Select placeholder="Seleccione el tipo" allowClear>
		 								<Select.Option value={TipoTurno.laborable}>
		 									{TipoTurno.laborable}
										</Select.Option>
		 								<Select.Option value={TipoTurno.dia_descanso}>
		 									{TipoTurno.dia_descanso}
		 								</Select.Option>
		 								<Select.Option value={TipoTurno.horas_extras}>
											{TipoTurno.horas_extras}
		 								</Select.Option>
		 							</Select>
		 						</Form.Item>
		 					</Col>
		 					<Col span={12}>
                 <Form.Item
                       label="Color"
                       name="color"
                       rules={[
                          {
                               required: true,
                               message: "El campo color es obligatorio.",
                           },
                      ]}   
                   >
                       <Input/>
                  </Form.Item>
              </Col>
		 				</Row>
             <Row gutter={16}>
		 					<Col span={6}>
                 <Form.Item
                       label="Hora Inicio"
                       name="horaInicioAuxiliar"
                       rules={[
                           {
                               required: true,
                               message: "El campo hora inicio es obligatorio.",
                           },
                      ]}   
                   >
                       <TimePicker style={{ width: '170px' }} format={format} />
                 </Form.Item>
		 					</Col>
               <Col span={6}>
                 <Form.Item
                       label="Hora Fin"
                       name="horaFinAuxiliar"
                       rules={[
                           {
                               required: true,
                             message: "El campo hora fin es obligatorio.",
                           },
                       ]}   
                   >
                       <TimePicker style={{ width: '170px' }} format={format} />
                   </Form.Item>
		 					</Col>
		 					<Col span={12}>
                 <Form.Item
                       label="Días"
                       name="dias"
                       rules={[
                         {
                               required: true,
                               message: "El campo días es obligatorio.",
                           },
                       ]}   
                   >
                      <Select
                         placeholder="Seleccione el día"
                         mode="tags"
                         style={{
                           width: '100%',
                         }}
                         onChange={handleChange}
                         tokenSeparators={[',']}
                         options={options}
                       />
                   </Form.Item>
               </Col>
		 				</Row>
             <Form.Item style={{ textAlign: "center" }} >
                 <Button
                     type="primary"
                     icon={<CheckOutlined />}
                     htmlType="submit"
                     loading={loading}
                 >
                    Crear Turno
                 </Button>
             </Form.Item>
       </Form>
   </div> 
  );
};
export default FormCrearTurno;
