import React, { useState, useEffect } from "react";
import {
  Modal,
  Upload,
  message,
  Button,
  Space,
  Typography,
  Row,
  notification,
} from "antd";
import { FileExcelOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { LoginService } from "services/LoginService";
import { useHistory } from "react-router-dom";
import { saveAs } from "file-saver";
import SedeService from "services/SedeService";
import { Headers } from "enums";

export default function ModalCargaSedes({ visibleModal, onCerrarModal }) {
  const { Text } = Typography;
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [sedesNuevas, setSedesNuevas] = useState([]);
  const [sedesActualizadas, setSedesActualizadas] = useState([]);
  const [listaDocumentos, setListaDocumentos] = useState([]);
  const [sedesNoProcesadas, setSedesNoProcesadas] = useState([]);
  const [etiqueta, setEtiqueta] = useState(false);
  let history = useHistory();
  useEffect(() => {
    setSedesNuevas(0);
    setSedesActualizadas(0);
    setSedesNoProcesadas(0);
  }, []);
  const datos = {
    empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
  };
  const props = {
    name: "file",
    action: process.env.REACT_APP_ENDPOINT + "sede/cargarSedes",
    showUploadList: "false",
    headers: {
      authorization: "Bearer " + LoginService.getJwtToken(),
      [Headers.ORGANIZACION_ID]:
        LoginService.getUsuarioAutenticado().organizacionId,
      [Headers.EMPRESA_ID]:
        LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
    },
    data: datos,
    onChange(info) {
      const { status } = info.file;
      // Garantiza solo la carga de un archivo
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
        // console.log("entre1:");
      }
      if (status === "done") {
        // console.log("entre2:", info.file.response.colaboradoresNuevos);
        // console.log("entre21:", info.file.response.colaboradoresActualizados);
        message.success(`${info.file.name} Archivo subido correctamente`);
        setSedesNuevas(info.file.response.sedeNuevas);
        setSedesActualizadas(info.file.response.sedesActualizadas);
        setSedesNoProcesadas(info.file.response.sedesNoProcesadas);
        setEtiqueta(true);
      } else if (info.file.status === "error") {
        //console.log("entre3:");
        message.error(`${info.file.name} Carga del archivo ha fallado.`);
      }
      setListaDocumentos(fileList);
    },
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
  };
  function borrarDatos() {
    setSedesNuevas(0);
    setSedesActualizadas(0);
    setSedesNoProcesadas(0);
    setListaDocumentos([]);
    setEtiqueta(false);
  }
  async function onClickDescargarPlantilla() {
    try {
      let respuesta = await SedeService.descargarPlantillaExcel(getUsuarioAutenticado().empresaSeleccionada.id);
      const filename = respuesta.headers.filename;
      saveAs(respuesta.data, filename);
    } catch (error) {
      notification.error({
        message: "No se puede descargar plantilla de Sedes",
      });
    }
  }
  return (
    <Modal
      title="Importar Centros de costo desde excel"
      visible={visibleModal}
      width={600}
      onCancel={onCerrarModal}
      afterClose={borrarDatos}
      footer={null}
    >
      <div>
        <Row>
          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Upload {...props} fileList={listaDocumentos}>
              <Button style={{ borderRadius: 4, borderColor: "green" }}>
                <FileExcelOutlined style={{ fontSize: 20, color: "green" }} />{" "}
                <Text style={{ color: "green" }}>Cargar Centros de costo Excel</Text>
              </Button>
            </Upload>
          </div>
        </Row>
        <br />
        <Row>
          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Descargar el modelo para la importación de Centros de costo{" "}
            <a
              style={{ color: "#0F7FF0", fontStyle: "italic" }}
              onClick={onClickDescargarPlantilla}
            >
              aquí
            </a>
          </div>
        </Row>
      </div>
      <br />
      {etiqueta ? (
        <div>
          <Row justify="center">
            <Space size={60}>
              <Text style={{ fontSize: 12 }}>
                <CheckCircleOutlined style={{ color: "#329BE9 " }} /> Centros de costo
                nuevos: {sedesNuevas}
              </Text>

              <Text style={{ fontSize: 12 }}>
                <CheckCircleOutlined style={{ color: "#D89817" }} /> Centros de costo
                actualizados: {sedesActualizadas}
              </Text>

              <Text style={{ fontSize: 12 }}>
                <CheckCircleOutlined style={{ color: "#DD0C3F" }} /> Centros de costo no
                procesados: {sedesNoProcesadas}
              </Text>
            </Space>
          </Row>
        </div>
      ) : (
        <div></div>
      )}
    </Modal>
  );
}
