import { BaseService } from "./BaseService";
import qs from "qs";
export const OnboardingColaboradorService = {
  async listar() {
    const respuesta = await BaseService.get("/onboardingColaborador");
    return respuesta.data;
  },
  async notificar(onboarding) {
    const respuesta = await BaseService.post("/onboarding/notificar", onboarding);
    return respuesta.data;
  },
  async aprobar(detalleId) {
    const respuesta = await BaseService.get("/onboardingColaborador/aprobarDetalle", {
      params: {
        detalleId: detalleId,
      },
    });
    return respuesta.data;
  },
  async rechazar(formRechazo) {
    const respuesta = await BaseService.post("/onboardingColaborador/rechazarDetalle", formRechazo);
    return respuesta.data;
  },
  async listarEnvioOnboarding(colaboradorDni, fechaInicio, fechaFin, proyecto, reclutador, proceso, estadoColaborador, login, pagina) {
    const respuesta = await BaseService.get("/onboardingColaborador/ListarEnvio", {
      params: {
        colaboradorDni: colaboradorDni,
        fechaInicio: fechaInicio,
        fechaFin: fechaFin,
        proyecto: proyecto,
        reclutador: reclutador,
        proceso:proceso,
        estadoColaborador:estadoColaborador,
        login: login,
        pagina: pagina,
      },
    });
    return respuesta.data;
  },
  async listarDetalleOnboarding(envioId, tipoDocumentoId, estado, login) {
    const respuesta = await BaseService.get("/onboardingColaborador/ListarDetalleEnvio", {
      params: {
        envioId: envioId,
        tipoDocumentoId: tipoDocumentoId,
        estado: estado,
        login: login,
      },
    });
    return respuesta.data;
  },
  async cargarColaboradorExcel(formData) {
    const respuesta = await BaseService.post("/onboardingColaborador/cargarColaboradorExcel", formData);
    return respuesta.data;
  },
  async asignarCarpeta(carpetaId, documentoCarpeta, documentos) {
    const respuesta = await BaseService.get("/onboardingColaborador/asignarCarpeta", {
      params: {
        carpetaId: carpetaId,
        documentoCarpeta:documentoCarpeta,
        documentos: documentos,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    return respuesta.data;
  },
  async eliminar(envioId){
    const respuesta= await BaseService.delete(`/onboardingColaborador/${envioId}`);
    return respuesta.data;
  },
  async descargarExcel(
		colaboradorDni, fechaInicio, fechaFin, proyecto, reclutador, proceso, login
	) {
		const respuesta = await BaseService.get(
			"/onboardingColaborador/descargarExcel",
			{
				responseType: "blob",
				params: {
          colaboradorDni: colaboradorDni,
          fechaInicio: fechaInicio,
          fechaFin: fechaFin,
          proyecto: proyecto,
          reclutador: reclutador,
          proceso: proceso,
          login: login
        },
				paramsSerializer: (params) => {
					return qs.stringify(params, { arrayFormat: "repeat" });
				},
			}
		);
		return respuesta;
	}
};
