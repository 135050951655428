import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";
import qs from "qs";

const URL_API = ApiUrl.RECLUTAMIENTO_Y_SELECCION;

const PostulantesService = {
  async insertar(postulante) {
    const respuesta = await ApiService(URL_API).post("/v1/postulantes", postulante);
    return respuesta.data;
  },

  async filtrarPorNombreOrDni(filtro) {
    const respuesta = await ApiService(URL_API).get("/v1/postulantes/filtrar-por-nombre-o-dni", {
      params: {
        ...filtro,
      },
    });
    return respuesta.data;
  },

  async listar(empresaId, accesoPlataforma, estado, tipoDocumento, colaborador, documentoIdentidad, login, pagina) {
    const respuesta = await ApiService(URL_API).get("/postulantes", {
      params: {
        empresaId: empresaId,
        accesoPlataforma: accesoPlataforma,
        estado: estado,
        tipoDocumento: tipoDocumento,
        colaborador: colaborador,
        documentoIdentidad: documentoIdentidad,
        login: login,
        pagina: pagina,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    return respuesta.data;
  },

  async listarFiltrado(filtro) {
    const respuesta = await ApiService(URL_API).get("/postulantes/filtrar-por-nombre-o-dni", {
      params: {
        ...filtro,
      },
    });
    return respuesta.data;
  },

  async obtenerInformacionDashboard(procesoId) {
    const respuesta = await ApiService(URL_API).get(`/postulantes/dashboard/${procesoId}`);
    return respuesta.data;
  },
  async buscarPorId(postulanteId) {
    const respuesta = await ApiService(URL_API).get(`/postulantes/${postulanteId}`);
    return respuesta.data;
  },
  async buscarPorDocumentoIdentidad(postulanteId) {
    const respuesta = await ApiService(URL_API).get(`/postulantes/informacion/${postulanteId}`);
    return respuesta.data;
  },

};

export { PostulantesService };
