import React, { useContext } from "react";
import { FilterServer } from "components/MultiFiltro";
import { SecurityContext } from "context/SecurityContextProvider";
import { DocumentoLaboralService } from "services/DocumentoLaboralService";

const FiltroTabla = ({ envioId, setDetalleEnvio }) => {

    const { getUsuarioAutenticado } = useContext(SecurityContext);
    // const [envio] = useAtom(atoms.envio);
    // const [, setContratos] = useAtom(atoms.contratos);

    const filtrosBusqueda = {
        titulo: "Filtrar por: ",
        data: [
            { id: "estado", titulo: "Estado", tipo: "lista" },
            { id: "colaborador", titulo: "Apellidos y Nombres", tipo: "texto" },
            { id: "documentoIdentidad", titulo: "Documento Identidad", tipo: "texto" }
        ],
    };

    const listaFiltros = [
        { "estado": "PENDIENTE" },
        { "estado": "VISUALIZADO" },
        { "estado": "FIRMADO" },
        { "estado": "ERROR" }
    ];

    const onFiltrar = async (filtrosSeleccionados) => {
        try {
            const _documentos = await DocumentoLaboralService.listar(
                getUsuarioAutenticado().empresaSeleccionada.id,
                envioId,
                filtrosSeleccionados["estado"],
                filtrosSeleccionados["colaborador"],
                filtrosSeleccionados["documentoIdentidad"]
            )
            setDetalleEnvio(_documentos);
        } catch (error) {
            console.error(error);
        } finally {

        }
    }

    return (
        <FilterServer
            placeholder="Haz clic aquí para filtrar tus documentos"
            filtros={filtrosBusqueda}
            onFiltrarDatos={onFiltrar}
            data={listaFiltros}
        />
    );

}

export default FiltroTabla;