import React from "react";
import { Button,Popconfirm } from "antd";
import { EditOutlined, LayoutOutlined,DeleteOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import PerfilService from "../../../../services/PerfilService";

export const MenuAcciones=({perfil,perfiles,setPerfiles})=>{
    let history = useHistory();
    const BotonEditar = () => {
        const onClickBtnEditar=()=>{
                                   
            const rutaEditarPerfilPage=`/perfil/editar/${perfil.id}`;                                              
            history.push(rutaEditarPerfilPage);
        
        }

        return (
            <Button type="link" icon={<EditOutlined />} onClick={onClickBtnEditar}>
                Editar
            </Button >
        )
    }
    const BotonEliminar = ()=>{
        const onclickBtnEliminar = async() =>{               
              await PerfilService.eliminar(perfil.id);
              setPerfiles(perfiles.filter((_perfil)=>_perfil.id!==perfil.id));              
            
        }

        return(            
            <Popconfirm
            title="Estás seguro de eliminar el perfil"
            onConfirm={onclickBtnEliminar}           
            okText="Si"
            cancelText="No"
           >
            <Button type="link" icon={<DeleteOutlined />} >
                Eliminar
            </Button>
          </Popconfirm>

        )
    }
    return (
        <React.Fragment>
            <BotonEditar />
            <BotonEliminar />
        </React.Fragment>
    );

}
