import React from "react";
import { Button, Modal, Table } from "antd";
import { useModalDetalleVacaciones } from "../../hooks";
import { CloseCircleTwoTone } from "@ant-design/icons";
import moment from "moment";

const ModalDetalleVacaciones = () => {
  const { colaboradorSeleccionado, visible, ocultarModal } = useModalDetalleVacaciones();

  return (
    <Modal
      title="Detalle Vacaciones"
      width="60%"
      visible={visible}
      onOk={ocultarModal}
      onCancel={ocultarModal}
      cancelButtonProps={{ style: { display: "none" } }}
      destroyOnClose={true}
    >
      <div style={{ fontSize: "20px", fontWeight: "bold", color: "rgb(16, 142, 233)", textAlign: "center" }}>
        {colaboradorSeleccionado?.vacacionesDisponibles} DÍAS
      </div>
      <div style={{ fontSize: "13px", textAlign: "center" }}>diponibles</div>
      <ListaPeriodosVacaciones />
    </Modal>
  );
};

const ListaPeriodosVacaciones = () => {
  const { colaboradorSeleccionado } = useModalDetalleVacaciones();

  const expanded = {
    expandedRowRender: (fila) => {
      if (fila.solicitudesAusencia !== undefined && fila.solicitudesAusencia.length > 0) {
        const columns = [
          {
            title: "Tipo Ausencia",
            dataIndex: ["tipo", "descripcion"],
            align: "center",
          },
          {
            title: "Jefe Inmediato",
            dataIndex: ["jefeInmediato", "nombreCompleto"],
            align: "center",
          },
          {
            title: "Fecha Inicio",
            //width: "10%",
            align: "center",
            render: (text, fila) => moment(fila.fechaInicioAusencia).format("DD/MM/YYYY"),
          },
          {
            title: "Fecha Fin",
            //width: "10%",
            align: "center",
            render: (text, fila) => moment(fila.fechaFinAusencia).format("DD/MM/YYYY"),
          },
          {
            title: "Dias",
            dataIndex: "diasSolicitados",
            //width: "5%",
            align: "center",
          },
          {
            title: "Fin de Semana (Días)",
            dataIndex: "diasSolicitadosFinDeSemana",
            //width: "5%",
            align: "center",
          },
          {
            title: "Fecha de Solicitud",
            //width: "15%",
            align: "center",
            render: (text, fila) => moment(fila.fechaHoraSolicitud).format("DD/MM/YYYY hh:mm a"),
          },
        ];
        return (
          <Table
            rowKey={(solicitud) => solicitud.id}
            columns={columns}
            dataSource={fila.solicitudesAusencia}
            pagination={false}
          />
        );
      } else {
        return <p style={{ margin: 0 }}>No hay solicitudes</p>;
      }
    },
    expandRowByClick: true,
    expandIcon: ({ expanded, onExpand, record }) =>
      expanded ? (
        // <Button type="link" onClick={(e) => onExpand(record, e)} >Cerrar</Button>
        <CloseCircleTwoTone onClick={(e) => onExpand(record, e)} />
      ) : (
        <Button type="link" onClick={(e) => onExpand(record, e)}>
          Ver Solicitudes
        </Button>
      ),
  };

  const columns = [
    {
      title: "Periodo Vacacional",
      key: "periodoVacacional",
      align: "center",
      render: (_, fila) => (
        <React.Fragment>
          {fila.periodoInicio} - {fila.periodoFin}
        </React.Fragment>
      ),
      width: "35%",
    },
    {
      title: "Días Ganadas",
      dataIndex: "vacacionesGanadas",
      key: "vacacionesGanadas",
      align: "center",
      width: "15%",
    },
    {
      title: "Días Gozadas",
      dataIndex: "vacacionesGozadas",
      key: "vacacionesGozadas",
      align: "center",
      width: "15%",
    },
    {
      title: "Días Pendientes",
      dataIndex: "vacacionesPendientes",
      key: "vacacionesPendientes",
      align: "center",
      width: "15%",
    },
    // Table.EXPAND_COLUMN,
  ];

  return (
    <Table
      dataSource={colaboradorSeleccionado.periodosVacacionales}
      columns={columns}
      size="small"
      expandable={{
        ...expanded,
      }}
      pagination={false}
      scroll={{
        y: 450,
      }}
    />
  );
};

export default ModalDetalleVacaciones;
