import React, { useState, useContext } from "react";
import { Form, Button, Select, DatePicker, Row, Col, Space, Input, notification } from "antd";
import { FiltroDescargaMasiva } from "enums/FiltroDescargaMasiva";
import { OrganizarCarpeta } from "enums/OrganizarCarpeta";
import { FormaDescarga } from "enums/FormaDescarga";
import { useListarTipoDocumento } from "../../hooks";
import { useEffect } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import DescargaMasivaService from "services/DescargaMasivaService";
import { useHistory } from "react-router-dom";
import ColaboradorService from "services/ColaboradorService";

const RegistroDescargaMasiva = () => {
  const { tipoDocumentos } = useListarTipoDocumento();
  const [tipoDocumentoNombre, setTipoDocumentoNombre] = useState(undefined);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [formDescarga] = Form.useForm();
  const [loading, setLoading] = useState(false);

  let history = useHistory();
  useEffect(() => { });

  const onFinishProgramarDescarga = async (formulario) => {
    try {
      setLoading(true);
      if (formulario.filtro === FiltroDescargaMasiva.INDIVIDUAL) {
        const colaboradorBuscado = await ColaboradorService.buscarPorNumeroDocumentoIdentidad(
          "",
          formulario.numeroDocumentoIdentidadColaborador.trim()
        );
        const nombreCompleto =
          colaboradorBuscado.apellidoPaterno +
          " " +
          colaboradorBuscado.apellidoMaterno +
          " " +
          colaboradorBuscado.nombres;
        formulario.nombreCompletoColaborador = nombreCompleto;
        // formulario.numeroDocumentoIdentidadColaborador = formulario.numeroDocumentoIdentidadColaborador.trim();
      } else {
        formulario.nombreCompletoColaborador = FiltroDescargaMasiva.TODOS;
        formulario.numeroDocumentoIdentidadColaborador = "TODOS";
      }
      formulario.periodoInicio = formulario.periodoInicio.format("MM/YYYY");
      formulario.periodoFin = formulario.periodoFin.format("MM/YYYY");
      formulario.tipoDocumentoNombre = tipoDocumentoNombre;
      formulario.usuarioRegistro = getUsuarioAutenticado().id;

      await DescargaMasivaService.insertar(formulario);
      history.push("/consultar/descarga-masiva");
      notification.success({
        message: "Exito",
        description: "Se ha programado la descarga exitosamente.",
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: "No se puede programar la descarga",
        description: error.response.data.mensaje,
      });
      setLoading(false);
    }
  };
  const onChangeTipoDocumento = (value, Option) => {
    setTipoDocumentoNombre(Option.children);
  };

  const onChangeFiltro = (value) => {
    if (value === FiltroDescargaMasiva.TODOS) {
      formDescarga.setFieldsValue({
        numeroDocumentoIdentidadColaborador: "TODOS",
      });
    } else {
      formDescarga.setFieldsValue({
        numeroDocumentoIdentidadColaborador: "",
      });
    }
  };
  return (
    <Form
      layout="vertical"
      labelCol={{ span: 10, offset: 8 }}
      wrapperCol={{ span: 10, offset: 8 }}
      onFinish={onFinishProgramarDescarga}
      form={formDescarga}
    >
      <Form.Item
        name="tipoDocumentoId"
        label="Tipo Documento"
        rules={[
          {
            required: true,
            message: "Seleccione el tipo de documento.",
          },
        ]}
      >
        <Select
          placeholder="Buscar el Tipo Documento"
          showSearch
          onChange={onChangeTipoDocumento}
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {tipoDocumentos.length > 0 &&
            tipoDocumentos.map((tipoDocumento) => {
              return (
                <Select.Option value={tipoDocumento.id} key={tipoDocumento.id}>
                  {tipoDocumento.nombre}
                </Select.Option>
              );
            })}
        </Select>
      </Form.Item>

      <Form.Item
        name="periodoInicio"
        label="Periodo Inicio"
        rules={[
          {
            required: true,
            message: "Seleccione el Periodo inicio.",
          },
        ]}
      >
        <DatePicker format="MM/YYYY" picker="month" style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        name="periodoFin"
        label="Periodo Fin"
        rules={[
          {
            required: true,
            message: "Seleccione el Periodo Fin.",
          },
        ]}
      >
        <DatePicker format="MM/YYYY" picker="month" style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        name="filtro"
        label="Filtro de Colaboradores"
        rules={[
          {
            required: true,
            message: "Seleccione el Filtro de colaboradores.",
          },
        ]}
      >
        <Select placeholder="Seleccionar Filtro de colaboradores" onChange={onChangeFiltro}>
          <Select.Option value={FiltroDescargaMasiva.TODOS} key={FiltroDescargaMasiva.TODOS}>
            {FiltroDescargaMasiva.TODOS}
          </Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="organizarCarpeta"
        label="Organizar Carpetas"
        rules={[
          {
            required: true,
            message: "Seleccione el organizar carpetas.",
          },
        ]}
      >
        <Select placeholder="Seleccionar Organizar Carpetas">
          <Select.Option value={OrganizarCarpeta.PERIODO}>{OrganizarCarpeta.PERIODO}</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="formaDescarga"
        label="Forma Descarga"
        rules={[
          {
            required: true,
            message: "Seleccione la forma descarga.",
          },
        ]}
      >
        <Select placeholder="Seleccionar la Forma Descarga">
          <Select.Option value={FormaDescarga.INDIVIDUAL}>{FormaDescarga.INDIVIDUAL}</Select.Option>
          {/* <Select.Option value={FormaDescarga.MULTIPAGINA}>{FormaDescarga.MULTIPAGINA}</Select.Option> */}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" loading={loading} block htmlType="submit">
          Programar Descarga
        </Button>
      </Form.Item>
    </Form>
  );
};
export default RegistroDescargaMasiva;
