import React, { useEffect, useContext } from "react";
import { Table } from "antd";
import { MenuAcciones } from "./MenuAcciones";
import { useAtom } from "jotai";
import * as atoms from "../../ConsultaVacacionesStore";
import { SecurityContext } from "context/SecurityContextProvider";
import { VacacionesServiceAnterior } from "services/VacacionesService.bk";

const TablaVacaciones = () => {
  const [solicitudesVacaciones, setSolicitudesVacaciones] = useAtom(atoms.solicitudesVacacionesAtom);
  const [solicitudesVacacionesIds, setSolicitudesVacacionesIds] = useAtom(atoms.solicitudesVacacionesIdsAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);

  useEffect(() => {
    const CargarDatos = async () => {
      const vacaciones = await VacacionesServiceAnterior.listar(getUsuarioAutenticado().numeroDocumentoIdentidad);
      setSolicitudesVacaciones(vacaciones);
    };
    CargarDatos();
  }, []);

  const columnas = [
    {
      title: "Código de Trabajador",
      align: "center",
      render: (text, fila) => (fila.codigoColaborador !== null ? fila.codigoColaborador : "-"),
    },
    {
      title: "Documento de Identidad",
      dataIndex: "numeroDocumentoIdentidad",
      align: "center",
    },
    {
      title: "Colaborador",
      dataIndex: "nombreCompletoColaborador",
      align: "center",
    },
    {
      title: "Tipo",
      dataIndex: ["tipoAbsentismo", "descripcion"],
      align: "center",
    },
    {
      title: "FechaInicio",
      dataIndex: "fechaInicio",
      align: "center",
    },
    {
      title: "FechaFin",
      dataIndex: "fechaFin",
      align: "center",
    },
    {
      title: "Estado",
      dataIndex: "estado",
      align: "center",
    },
    {
      title: "Acciones",
      align: "center",
      render: (text, fila) => (
        <MenuAcciones
          solicitud={fila}
          solicitudVacaciones={solicitudesVacaciones}
          setSolicitudVacaciones={setSolicitudesVacaciones}
        />
      ),
    },
  ];
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSolicitudesVacacionesIds(selectedRowKeys);
    },
  };

  return (
    <Table
      rowKey={(vacaciones) => vacaciones.id}
      dataSource={solicitudesVacaciones}
      columns={columnas}
      rowSelection={{
        type: "checkbox",
        ...rowSelection,
      }}
    />
  );
};
export default TablaVacaciones;
