import { Button, Col, DatePicker, Divider, Dropdown, Form, Input, InputNumber, Menu, Modal, Popconfirm, Row, Select, Tabs, Typography, message } from "antd";
import React, { useEffect, useState } from "react";
import { PostulantesService } from "services/reclutamiento-y-seleccion";
import { UserOutlined, MailOutlined, PhoneOutlined, DownOutlined } from "@ant-design/icons";
import ProgramarEntrevista from "./ProgramarEntrevista";
import ListarEntrevista from "./ListarEntrevista";
import { PDFObject } from "react-pdfobject";
import { ConvocatoriaService } from "services/reclutamiento-y-seleccion/ConvocatoriaService";
import ModalCambiarconvocatoria from "./ModalCambiarConvocatoria";
import { SpinLoading } from "components/SpinLoading";
import SolicitudPersonalService from "services/reclutamiento-y-seleccion/SolicitudPersonalService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
const ModalPostulante = ({
  postulanteSeleccionado,
  modalPostulante,
  setModalPostulante,
  convocatoria,
  cambiarEstados,
}) => {
  const [postulanteInfo, setPostulanteInfo] = useState(undefined);
  const [modalProgramar, setModalProgramar] = useState(false);
  const [modalCambiarconvocatoria, setModalCambiarConvocatoria] = useState(false);
  const [itemsMenu, setItemsMenu] = useState([]);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const cargarDatos = async () => {
      setLoading(true)
      const _postulante = await PostulantesService.buscarPorDocumentoIdentidad(postulanteSeleccionado);
      setPostulanteInfo(_postulante);
      setLoading(false)
    };
    if (postulanteSeleccionado) cargarDatos();
  }, [postulanteSeleccionado]);

  useEffect(() => {
    if (convocatoria?.etapa !== undefined) {
      const _items = [];
      convocatoria.etapas.forEach((element) => {
        if (element.id !== convocatoria.etapa.id)
          _items.push({
            key: element.id,
            label: element.descripcion,
          });
      });
      setItemsMenu(_items);
    }
  }, [convocatoria]);

  const getItems = () => {
    return (
      <Menu>
        {itemsMenu &&
          itemsMenu.map((item) => {
            return (
              <Menu.Item
                onClick={(item) => {
                  cambiarEtapa(item.key);
                }}
                key={item.key}
              >
                {item.label}
              </Menu.Item>
            );
          })}
      </Menu>
    );
  };

  const cambiarEtapa = async (etapaId) => {
    try {
      // const etapa = convocatoria?.etapas.find((p) => p.id === etapaId);
      // const data = {
      //   convocatoriaId: convocatoria.convocatoriaId,
      //   postulanteId: postulanteSeleccionado,
      //   etapaId: etapaId,
      //   contratado: etapa.descripcion.toUpperCase() === "Contratado".toUpperCase(),
      // };
      //console.log(data)
      //cambiarEstados({ postulante: postulanteSeleccionado, original: convocatoria.etapa.id, destino: etapaId });
      cambiarEstados({
        destination: {
          droppableId: etapaId,
          index: 0
        },
        draggableId: postulanteSeleccionado,
        source: {
          droppableId: convocatoria.etapa.id,
          index: 0
        }
      })
      //await ConvocatoriaService.cambiarEtapaPostulante(data);
    } catch (error) {
      console.error(error);
    }
    setModalPostulante(false);
  };

  const menuPostulante = [
    {
      label: "Información",
      key: "info",
      children: <InformacionPostulante postulanteInfo={postulanteInfo} />,
    },
    {
      label: "Entrevistas",
      key: "entrevistas",
      children: <ListarEntrevista postulanteId={postulanteInfo?.postulanteId} convocatoriaId={convocatoria?.convocatoriaId} />,
    },
    convocatoria?.etapa?.descripcion.toUpperCase() === "CONTRATADO" && {
      label: "Información Contratación",
      key: "infoContratacion",
      children: <InformacionContratacion contratacionInfo={postulanteInfo} convocatoriaId={convocatoria?.convocatoriaId}
      setModalPostulante={setModalPostulante} />,
    }
  ];

  return (
    <Modal
      title="Información"
      open={modalPostulante}
      width={"70%"}
      footer={
        <Button type="primary" onClick={() => setModalPostulante()}>
          Cerrar
        </Button>
      }
      onCancel={() => {
        setModalPostulante(false);
      }}
      centered
      destroyOnClose
    >
      <SpinLoading loadingPage={loading}>
        <Row gutter={18}>
          <Col
            span={3}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <UserOutlined style={{ fontSize: "50px", color: "#08c" }} />
          </Col>
          <Col span={16}>
            <Row>
              <Typography.Text strong style={{ fontSize: "20px" }}>
                {postulanteInfo?.nombreCompleto}
              </Typography.Text>
            </Row>
            <Row
              gutter={16}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Col>DNI: {postulanteInfo?.numeroDocumentoIdentidad}</Col>
              <Col>
                <MailOutlined /> {postulanteInfo?.correoElectronico}
              </Col>
              <Col>
                <PhoneOutlined /> {postulanteInfo?.celular || "-"}
              </Col>
              {/* <Col span={6}> _</Col> */}
            </Row>
          </Col>
          <Col span={5}>
            <div style={{ textAlign: "end" }}>Etapa Actual</div>
            <div style={{ textAlign: "end" }}>{convocatoria?.etapa?.descripcion}</div>
          </Col>
        </Row>
        <div style={{ padding: "15px 0 0 0" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{ margin: "5px 10px" }}
              onClick={() => {
                setModalProgramar(true);
              }}
            >
              Programar Entrevista
            </Button>
            <Button
              style={{ margin: "5px 10px" }}
              onClick={() => {
                cambiarEtapa(convocatoria?.etapas[convocatoria?.etapas?.length - 2].id);
              }}
            >
              Contratar
            </Button>
            <Button
              danger
              style={{ margin: "5px 10px" }}
              onClick={() => {
                cambiarEtapa(convocatoria?.etapas[convocatoria?.etapas?.length - 1].id);
              }}
            >
              Rechazar
            </Button>
            <Dropdown overlay={getItems} trigger={["click"]}>
              <Button style={{ margin: "5px 10px" }}>
                Cambiar de Etapa <DownOutlined />
              </Button>
            </Dropdown>
            <Button
              style={{ margin: "5px 10px" }}
              onClick={() => { setModalCambiarConvocatoria(true) }}>
              Cambiar de Convocatoria
            </Button>
          </div>
        </div>

        <Divider />
        <Tabs defaultActiveKey="1" type="card" items={menuPostulante} />
        <ProgramarEntrevista
          modalProgramar={modalProgramar}
          setModalProgramar={setModalProgramar}
          postulante={postulanteInfo}
          convocatoria={convocatoria}
        />
        <ModalCambiarconvocatoria
          modalCambiarconvocatoria={modalCambiarconvocatoria}
          setModalCambiarConvocatoria={setModalCambiarConvocatoria}
          postulante={postulanteInfo}
          convocatoria={convocatoria}
        />
      </SpinLoading>
    </Modal>
  );
};

export default ModalPostulante;

const InformacionPostulante = ({ postulanteInfo }) => {
  const [formPostulante] = Form.useForm();
  useEffect(() => {
    if (postulanteInfo) {
      formPostulante.resetFields();
      formPostulante.setFieldsValue(postulanteInfo);
    }
  }, [postulanteInfo]);
  return (
    <Form form={formPostulante} layout="vertical" size="large" style={{ padding: "0 30px" }}>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={"Tipo Documento Identidad"} name={"tipoDocumentoIdentidad"}>
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={"Numero Documento Identidad"} name={"numeroDocumentoIdentidad"}>
            <Input readOnly />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={"Apellido Paterno"} name={"apellidoPaterno"}>
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={"Apellido Materno"} name={"apellidoMaterno"}>
            <Input readOnly />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={"Nombres"} name={"nombres"}>
            <Input readOnly />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={"Sexo"} name={"genero"}>
            <Input readOnly />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const InformacionContratacion = ({ contratacionInfo, convocatoriaId, setModalPostulante }) => {
  const [formInfoContratacion] = Form.useForm();
  const [loading, setLoading] = useState(false)
  let { solicitudId } = useParams();

  useEffect(() => {
    const cargarFecha = async()=>{
      setLoading(true)
      formInfoContratacion.resetFields();
      const response = await SolicitudPersonalService.obtenerFechaCierreColaborador(solicitudId, contratacionInfo.postulanteId)
      // formInfoContratacion.setFieldsValue({...contratacionInfo, fechaCierre:getFecha(response)
      //   , fechaIngreso:getFecha(contratacionInfo.fechaIngreso) });
      const _postulante = await PostulantesService.buscarPorDocumentoIdentidad(contratacionInfo.postulanteId);
    
      formInfoContratacion.setFieldsValue({..._postulante, fechaCierre:getFecha(response)
        , fechaIngreso:getFecha(_postulante.fechaIngreso) });

      console.log("contratacionInfo:::::",contratacionInfo);
      console.log("_postulante:::::",_postulante);

      setLoading(false)
    }
    if (contratacionInfo) {
      cargarFecha()
    }
  }, [contratacionInfo]);


  function getFecha(fecha) {
    if(fecha!=null && fecha!=="")
      return(moment(fecha));
    
    return undefined;
  }

  const ActualizarConvocatoria = async (form) =>{

      try {
        await ConvocatoriaService.ActualizarInformacionPostulanteContratado({
          convocatoriaId: convocatoriaId,
        solicitudPersonalId: solicitudId,
        postulanteId: contratacionInfo.postulanteId,
        fechaCierre: form.fechaCierre,
        colaboradorInfo: {...contratacionInfo,...form}
        })
        message.success("Se actualizo correctamente.")
        setModalPostulante(false)
        formInfoContratacion.resetFields()
      } catch (error) {
        message.error("Ocurrio un error al intentar actualizar la informacion.")
      }
      
  }

  const ValidateForm = async () => {
    try {
      const response = await formInfoContratacion.validateFields();
      ActualizarConvocatoria(response)
    } catch (error) {
      message.error("Todos los valores son requeridos.");
    }
  };

  return (
    <SpinLoading loadingPage={loading}>
    <Form form={formInfoContratacion} layout="vertical" size="large" style={{ padding: "0 30px" }} onFinish={ActualizarConvocatoria}>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={"Movilidad"} name={"movilidad"} rules={[
          {
            required: true,
            message: 'Por favor ingrese un valor.',
          },
        ]}>
            <InputNumber min={0} style={{width:"100%"}} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={"Planilla"} name={"planilla"} rules={[
          {
            required: true,
            message: 'Por favor seleccione una opción.',
          },
        ]}>
          <Select placeholder="Seleccione un opcion">
              <Select.Option key={true} value={true}>Si</Select.Option>
              <Select.Option key={false} value={false}>No</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={"Remuneración Mensual"} name={"remuneracionMensual"} rules={[
          {
            required: true,
            message: 'Por favor seleccione un valor',
          },
        ]}>
            <InputNumber min={1} style={{width:"100%"}} />  
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={"Asigación Familiar"} name={"asignacionFamiliar"} rules={[
          {
            required: true,
            message: 'Por favor seleccione una opción.',
          },
        ]}>
            <Select  placeholder="Seleccione un opcion">
            <Select.Option key={true} value={true}>Si</Select.Option>
              <Select.Option key={false} value={false}>No</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={"Fecha Ingreso"} name={"fechaIngreso"} rules={[
          {
            required: true,
            message: 'Por favor seleccione una fecha.',
          },
        ]}>
            <DatePicker format={"DD/MM/YYYY"} style={{width:"100%"}} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={"Fecha Cierre"} name={"fechaCierre"} rules={[
          {
            required: true,
            message: 'Por favor seleccione una fecha.',
          },
        ]}>
            <DatePicker format={"DD/MM/YYYY"} style={{width:"100%"}} />
          </Form.Item>
        </Col>
      </Row>
      <Popconfirm
        title="¿Esta seguro de querer actualizar la informacion de contratación?"
        onConfirm={()=>ValidateForm()}
        okText="Si"
        cancelText="No"
      >
        <Button block type="primary">Actualizar</Button>
      </Popconfirm>
    </Form>
    </SpinLoading>
  );
};
