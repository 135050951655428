import { useAtom } from "jotai";
import { carpetaSeleccionadaAtom, colaboradorAtom, documentosAtom } from "../../../LegajoColaboradorStore";
import { useContext, useEffect } from "react";
import LegajoService from "services/LegajoService";
import { SecurityContext } from "context/SecurityContextProvider";

const useListarDocumentos = (cargaInicial = false) => {

    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [colaborador] = useAtom(colaboradorAtom);
    const [documentos, setDocumentos] = useAtom(documentosAtom);

    useEffect(() => {
        const fetchData = async () => {
            if (cargaInicial) {
                fetchDocumentos();
            }
        };
        fetchData();
    }, []);

    const fetchDocumentos = async (carpetaId) => {
        if (carpetaId) {
            const documentos = await LegajoService.listarDocumentosCarpeta(
                colaborador.numeroDocumentoIdentidad,
                getUsuarioAutenticado().empresaSeleccionada.id,
                carpetaId,
                getUsuarioAutenticado().login
            );
            setDocumentos(documentos);
        } else {
            const documentos = await LegajoService.listarDocumentosColaborador(
                colaborador?.numeroDocumentoIdentidad,
                getUsuarioAutenticado().empresaSeleccionada.id,
                getUsuarioAutenticado().login
            );
            setDocumentos(documentos);

        }
    }

    return { documentos, fetchDocumentos };
};
export default useListarDocumentos;
