import React from "react";
import { Button, Modal } from "antd"
import { CheckCircleOutlined } from "@ant-design/icons"
import { styleIcono, styleTitulo, styleMensaje, styleBoton, bodyStyle } from "./estilos"
import { useContext } from "react";
import { FirmaDocumentoGenericoMisDocumentosContext } from "context/firma-documento-generico/mis-documentos"
import { useModalExitoStore } from "hooks/firma-documento-generico";

const ModalExito = () => {

    // const { visibleModalExito, cerrarModalExito } = useContext(FirmaDocumentoGenericoMisDocumentosContext)

    const visibleModalExito = useModalExitoStore(state => state.visibleModalExito);
    const cerrarModalExito = useModalExitoStore(state => state.cerrarModalExito);

    return (
        <Modal
            title="Mi Legajo Digital"
            visible={visibleModalExito}
            width={400}
            footer={null}
            bodyStyle={bodyStyle}
            onCancel={cerrarModalExito}
            centered
        >
            <CheckCircleOutlined style={styleIcono} />
            <span style={styleTitulo}>
                Firma Exitosa
            </span>
            <span style={styleMensaje}>
                Documento firmado exitosamente
            </span>
            <Button style={styleBoton} onClick={cerrarModalExito}>
                Aceptar
            </Button>

        </Modal >
    );
}

export default ModalExito;