import { atom } from "jotai";

export const perfilAtom = atom([]);
export const dependenciaPerfil= atom();

export const listaCampos=atom([]);
export const secciones=atom([]);
export const sedes=atom([]);
export const subsedes=atom([]);
export const tipoPlanillas=atom([]);
export const cargos=atom([]);
export const camposColaborador=atom([]);