import React, { useEffect, useState, useContext, useRef, useMemo } from "react";
import { ContainerCrear } from "../../../components/ContainerCrear";
import { SecurityContext } from "../../../context/SecurityContextProvider";

import {
	Select,
	Form,
	Input,
	Row,
	Col,
	Button,
	notification,
	Space,
	Checkbox,
	DatePicker,
	Spin,
	Upload,
	message,
	Divider,
	Radio,
	Alert
} from "antd";

import {
	CheckOutlined,
	CheckCircleFilled,
	LoadingOutlined,
	EditOutlined,
	MailOutlined,
	BankOutlined,
	CarOutlined,
	WalletOutlined,
	FolderAddOutlined,
} from "@ant-design/icons";
import ColaboradorService from "../../../services/ColaboradorService";
import CargoService from "../../../services/CargoService";
import { useHistory, useParams } from "react-router-dom";
import { tipoDocumentoIdentidad } from "../../../enums/TipoDocumentoIdentidad";
import moment from "moment";
import { SexoColaborador } from "enums/SexoColaborador";
import ParametrosService from "../../../services/ParametrosService";
import { CampoColaborador } from "enums/CampoColaborador";
import ComunicadoService from "services/ComunicadoService";
import { MotivoCese } from "enums/MotivoCese";
import { FormatoCarga } from "enums/FormatoCarga";
import { UbigeoService } from "services";
import { OPCIONES } from "enums";
import { debounce } from "lodash";
const { Option } = Select;

export function ColaboradorEditarPage() {
	const [formularioEditar] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [cargos, setCargos] = useState([]);
	const [sedes, setSedes] = useState([]);
	const { getUsuarioAutenticado } = useContext(SecurityContext);
	const [actualizarCorreo, setActualizarCorreo] = useState(false);
	const [ingresoFechaCese, setIngresoFechaCese] = useState(false);
	const [secciones, setSecciones] = useState([]);
	const [subsedes, setSubsedes] = useState([]);
	const [perfiles, setPerfiles] = useState([]);
	const [tipoPlanillas, setTipoPlanillas] = useState([]);
	const [seccionSeleccionada, setSeccionSeleccionada] = useState(null);
	const [subsedeSeleccionada, setSubsedeSeleccionada] = useState(null);
	const [perfilSeleccionado, setPerfilSeleccionado] = useState(null);
	const [tipoPlanillaSeleccionado, setTipoPlanillaSeleccionado] = useState(null);
	const [codigoColaboradorActivo, setCodigoColaboradorActivo] = useState(undefined);
	const [camposColaboradores, setCamposColaboradores] = useState([]);
	const [correoVerificado, setCorreoVerificado] = useState(false);
	const [celularVerificado, setCelularVerificado] = useState(false);
	const [estadoColaborador, setEstadoColaborador] = useState(undefined);
	const [colaboradoresBuscados, setColaboradoresBuscados] = useState([]);
	const [jefeSeleccionado, setJefeSeleccionado] = useState([]);
	const [firmaHolografa, setFirmaHolografa] = useState([]);
	const [huellaDactilar, setHuellaDactilar] = useState([]);
	const [colaboradoresOtrosAprobadores, setColaboradoresOtrosAprobadores] = useState([]);
	const [aprobadoresSeleccionados, setAprobadoresSeleccionados] = useState([]);
	var opcionesOtrosAprobadores = [];
	let history = useHistory();
	let { idColaborador } = useParams();
	const { TextArea } = Input;
	const [departamentos, setDepartamentos] = useState([]);
	const [provincias, setProvincias] = useState([]);
	const [distritos, setDistritos] = useState([]);

	const isLaredo = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20132377783" ? true : false;
	const isExplomin = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20501523837" ? true : false;
	const isCime = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20117322751" ? true : false;
	const isCclp = getUsuarioAutenticado().empresaSeleccionada?.ruc === "20156181596" ? true : false;
	const existeCuenta = Form.useWatch(["cuentaHaberes", "exist"], formularioEditar);

	const [responsablesRenovarContrato, setresponsablesRenovarContrato] = useState([]);
	const [responsableRenovarContratoSeleccionado, setresponsableRenovarContratoSeleccionado] = useState(undefined);

	const [administradoresObra, setAdministradoresObra] = useState([]);
	const [administradorObraSeleccionado, setAdministradorObraSeleccionado] = useState(undefined);
	const [ocultarAdminObra, setOcultarAdminObra] = useState(false);
	const [sueldoContrato, setSueldoContrato] = useState(null);
	const opcionesEmpresaSeleccionada = getUsuarioAutenticado().empresaSeleccionada.opciones;
	const [rangoMinimo, setRangoMinimo] = useState();
	const [rangoMaximo, setRangoMaximo] = useState();
	const [omitirValidarRango, setOmitirValidarRango] = useState(false);
	const [ocultarTipoServicio, setOcultarTipoServicio] = useState(false);
	const [ocultarTipoPractica, setOcultarTipoPractica] = useState(true);
	const [otrosAprobadoresInmediatosListaInicial, setOtrosAprobadoresInmediatosListaInicial] = useState([]);


	useEffect(() => {
		async function cargarDatos() {
			setLoading(true);
			const colaborador = await ColaboradorService.buscarPorId(idColaborador);

			const campos_colaborador = getUsuarioAutenticado().empresaSeleccionada.camposColaborador;
			setCamposColaboradores(campos_colaborador);

			const campos = await ColaboradorService.camposColaborador(
				getUsuarioAutenticado().empresaSeleccionada.id,
				idColaborador
			);
			//Object.values(campos).forEach(d=>console.log(d))
			//console.log(campos)
			setPerfiles(campos.PERFIL);
			setSedes(campos.SEDE);
			setSubsedes(campos.SUBSEDE);
			setSecciones(campos.SECCION);
			setCargos(campos.CARGO);
			setTipoPlanillas(campos.PLANILLA);
			setJefeSeleccionado(colaborador.jefeInmediato?.id);
			setAprobadoresSeleccionados(colaborador.otrosJefesInmediatosIds);

			// const colaboradores = await ColaboradorService.listarTodos(getUsuarioAutenticado().login);
			// colaboradores.forEach((colaborador) => {
			// 	opcionesOtrosAprobadores.push({
			// 		label: colaborador.nombreCompleto,
			// 		value: colaborador.id,
			// 	});
			// });
			// setColaboradoresOtrosAprobadores(opcionesOtrosAprobadores);

			if (colaborador.otrosJefesInmediatosIds?.length > 0) {
				for (const jefeInmediatoId of colaborador.otrosJefesInmediatosIds) {
					const jefeInmediato = await ColaboradorService.buscarPorId(jefeInmediatoId);
					opcionesOtrosAprobadores.push({
						label: jefeInmediato.nombreCompleto,
						value: jefeInmediato.id,
					});
				}
				setOtrosAprobadoresInmediatosListaInicial(opcionesOtrosAprobadores);
			}

			const departamentos = await UbigeoService.listarDepartamentos();
			setDepartamentos(departamentos);
			const formData = new FormData();
			formData.append("departamento", colaborador.domicilioActual?.departamento);
			const provincias = await UbigeoService.listarProvincias(formData);
			setProvincias(provincias);
			const formDataDistrito = new FormData();
			formDataDistrito.append("provincia", colaborador.domicilioActual?.provincia);
			const distritos = await UbigeoService.listarDistritos(formDataDistrito);
			setDistritos(distritos);
			//responsables de contrato y administrador de obra
			if (colaborador.responsableRenovarContratoId != null) {
				const responsableContrato = await ColaboradorService.buscarPorId(
					colaborador.responsableRenovarContratoId
				);
				var responsablesContratos = [];
				responsablesContratos.push(
					responsableContrato !== null
						? {
							id: responsableContrato.id,
							numeroDocumentoIdentidad: responsableContrato.numeroDocumentoIdentidad,
							nombreCompleto:
								responsableContrato.apellidoPaterno +
								" " +
								responsableContrato.apellidoMaterno +
								" " +
								responsableContrato.nombres,
							codigoTrabajador: responsableContrato.codigoTrabajador,
						}
						: null
				);
				setresponsablesRenovarContrato(responsablesContratos);
			}

			if (colaborador.administradorObraId != null) {
				const administradorObra = await ColaboradorService.buscarPorId(colaborador.administradorObraId);
				var administradoresObra = [];
				administradoresObra.push(
					administradorObra !== null
						? {
							id: administradorObra.id,
							numeroDocumentoIdentidad: administradorObra.numeroDocumentoIdentidad,
							nombreCompleto:
								administradorObra.apellidoPaterno +
								" " +
								administradorObra.apellidoMaterno +
								" " +
								administradorObra.nombres,
							codigoTrabajador: administradorObra.codigoTrabajador,
						}
						: null
				);
				setAdministradoresObra(administradoresObra);
			}

			//explomin
			console.log("sueldo", colaborador.contrato?.sueldo);
			//
			formularioEditar.setFieldsValue({
				id: colaborador.id,
				codigoTrabajador: colaborador.codigoTrabajador,
				personaId: colaborador.personaId,
				// tipoDocumentoIdentidad: colaborador.tipoDocumentoIdentidad,
				tipoDocumentoIdentidad:
					colaborador.tipoDocumentoIdentidad === tipoDocumentoIdentidad.CARNET_EXTRANJERIA.valor ||
						colaborador.tipoDocumentoIdentidad === tipoDocumentoIdentidad.CARNET__EXTRANJERIA.valor
						? tipoDocumentoIdentidad.CARNET_EXTRANJERIA.valor
						: colaborador.tipoDocumentoIdentidad,
				numeroDocumentoIdentidad: colaborador.numeroDocumentoIdentidad,
				apellidoPaterno: colaborador.apellidoPaterno,
				apellidoMaterno: colaborador.apellidoMaterno,
				nombres: colaborador.nombres,
				empresa: {
					id: colaborador.empresa.id,
					razonsocial: colaborador.empresa.razonsocial,
				},
				correoElectronico: colaborador.correoElectronico,
				correoElectronicoAlternativo: colaborador.correoElectronicoAlternativo,
				celular: colaborador.celular,
				cargo: {
					id: colaborador.cargo?.id,
					nombre: colaborador.cargo?.nombre,
				},
				sede: {
					id: colaborador.sede?.id,
					nombre: colaborador.sede?.nombre,
				},
				eliminado: colaborador.eliminado,
				fechaIngreso:
					//colaborador.fechaIngreso && moment(moment(colaborador.fechaIngreso).format("DD/MM/YYYY"), "DD/MM/YYYY"),
					colaborador.fechaIngreso && moment(colaborador.fechaIngreso, "DD/MM/YYYY"),
				fechaCese:
					//colaborador.fechaCese && moment(moment(colaborador.fechaCese).format("DD/MM/YYYY"), "DD/MM/YYYY"),
					colaborador.fechaCese && moment(colaborador.fechaCese, "DD/MM/YYYY"),
				motivoCese: colaborador.motivoCese,
				sexoColaborador: colaborador.sexoColaborador,
				tieneHijos: colaborador.tieneHijos,
				subsede: colaborador.subsede?.id,
				seccion: colaborador.seccion?.id,
				perfil: colaborador.perfil?.id,
				tipoPlanilla: colaborador.tipoPlanilla?.id,
				tipoOperador: colaborador.tipoOperador,
				buscarJefeInmediato: colaborador.jefeInmediato
					? colaborador.jefeInmediato?.apellidoPaterno +
					" " +
					colaborador.jefeInmediato?.apellidoMaterno +
					" " +
					colaborador.jefeInmediato?.nombres
					: "",
				fechaNacimiento:
					//colaborador.fechaNacimiento && moment(moment(colaborador.fechaNacimiento).format("DD/MM/YYYY"), "DD/MM/YYYY"),
					colaborador.fechaNacimiento && moment(colaborador.fechaNacimiento, "DD/MM/YYYY"),
				observacionCese: colaborador.observacionCese,
				otrosAprobadoresInmediatos: colaborador.otrosJefesInmediatosIds
					? colaborador.otrosJefesInmediatosIds
					: [],
				domicilioActual: {
					departamento: colaborador.domicilioActual?.departamento,
					provincia: colaborador.domicilioActual?.provincia,
					distrito: colaborador.domicilioActual?.distrito,
					direccion: colaborador.domicilioActual?.direccion,
				},
				cuentaHaberes: {
					exist:
						colaborador.cuentaHaberes != null
							? colaborador.cuentaHaberes.nroCuenta !== null
								? true
								: false
							: null,
					banco: colaborador.cuentaHaberes?.banco,
					tipoMoneda: colaborador.cuentaHaberes?.tipoMoneda,
					nroCuenta: colaborador.cuentaHaberes?.nroCuenta,
					nroCuentaCCI: colaborador.cuentaHaberes?.nroCuentaCCI,
				},
				contrato: {
					tipoServicio: colaborador.contrato?.tipoServicio,
					sueldo: colaborador.contrato?.sueldo,
					fechaInicioContrato:
						colaborador.contrato?.fechaInicioContrato &&
						moment(colaborador.contrato?.fechaInicioContrato, "DD/MM/YYYY"),
					fechaFinContrato:
						colaborador.contrato?.fechaFinContrato &&
						moment(colaborador.contrato?.fechaFinContrato, "DD/MM/YYYY"),
				},
				responsableRenovarContratoId: colaborador.responsableRenovarContratoId,
				administradorObraId: colaborador.administradorObraId,
				estadoCivil: colaborador?.estadoCivil,
				datosPlanillaPracticas: {
					tipoPracticas: colaborador.datosPlanillaPracticas?.tipoPracticas,
					nacionalidad: colaborador.datosPlanillaPracticas?.nacionalidad,
					profesion: colaborador.datosPlanillaPracticas?.profesion,
					universidad: colaborador.datosPlanillaPracticas?.universidad,
				},
			});

			if (formularioEditar.getFieldValue("fechaCese") !== null) {
				setIngresoFechaCese(true);
			}

			if (colaborador.subsede !== null) {
				setSubsedeSeleccionada(colaborador.subsede);
			}
			if (colaborador.seccion !== null) {
				setSeccionSeleccionada(colaborador.seccion);
			}
			if (colaborador.perfil != null) {
				setPerfilSeleccionado(colaborador.perfil);
			}
			if (colaborador.tipoPlanilla !== null) {
				setTipoPlanillaSeleccionado(colaborador.tipoPlanilla);
			}

			const parametros = await ParametrosService.buscarPorEmpresaConCodigoColaboradorActivo(
				getUsuarioAutenticado().empresaSeleccionada.id
			);
			setCodigoColaboradorActivo(parametros.respuesta);

			const _correoVerificado = await ColaboradorService.verificarCorreoElectronico(
				getUsuarioAutenticado().empresaSeleccionada.id,
				colaborador.numeroDocumentoIdentidad
			);
			const _celularVerificado = await ColaboradorService.verificarCelular(
				getUsuarioAutenticado().empresaSeleccionada.id,
				colaborador.numeroDocumentoIdentidad
			);
			setCorreoVerificado(_correoVerificado);
			setCelularVerificado(_celularVerificado);
			setEstadoColaborador(colaborador.estadoRegistro);
			setLoading(false);
			setresponsableRenovarContratoSeleccionado(colaborador.responsableRenovarContratoId);
			setAdministradorObraSeleccionado(colaborador.administradorObraId);
			setSueldoContrato(colaborador.contrato?.sueldo);
			console.log("sueldo", sueldoContrato);


			if (isExplomin) {
				if (colaborador.sede?.nombre === "PRINCIPAL") {
					setOcultarTipoServicio(true);
					//setOcultarAdminObra(true);
				} else {
					setOcultarTipoServicio(false);
					//setOcultarAdminObra(false);
				}

				setOcultarTipoPractica(true);
				if (colaborador.tipoPlanilla?.nombre === "PRACTICANTE") {
					setOcultarTipoPractica(false);
				}

				const cargo = await CargoService.buscarPorId(colaborador.cargo?.id);
				console.log("cargo inicial", cargo);
				setOmitirValidarRango(cargo.omitirRangoSalarial);
				console.log("rangoSueldo-Min:::", cargo.sueldoMinimo);
				console.log("rangoSueldo-Max:::", cargo.sueldoMaximo);
				setRangoMinimo(cargo.sueldoMinimo != 0 ? cargo.sueldoMinimo : 0);
				setRangoMaximo(cargo.sueldoMaximo != 0 ? cargo.sueldoMaximo : 999999999);
			}
		}
		cargarDatos();
	}, []);

	async function handleOnChangeSede(value, option) {
		formularioEditar.setFieldsValue({ sede: { nombre: option.children } });
		const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
			getUsuarioAutenticado().empresaSeleccionada.id,
			value,
			CampoColaborador.SEDE
		);
		procesarCambios(campoEnviado);
		if (isExplomin && option.children === "PRINCIPAL") {
			setOcultarTipoServicio(true);
			setOcultarAdminObra(true);
		} else {
			setOcultarTipoServicio(false);
			setOcultarAdminObra(false);
		}
	}

	async function handleOnChangeCargo(value, option) {
		// if(isExplomin && (opcionesEmpresaSeleccionada && !opcionesEmpresaSeleccionada.includes(OPCIONES.PAGINA_MANTENIMIENTO_COLABORADOR.TXT_OMITIR_RANGO_SUELDO))){
		// 	const rangoSueldo = cargos.find(objeto => objeto.id === value);
		// 	console.log("rangoSueldo-Min:::", rangoSueldo.sueldoMinimo);
		// 	console.log("rangoSueldo-Max:::", rangoSueldo.sueldoMaximo);
		// 	setRangoMinimo(rangoSueldo.sueldoMinimo!=0?rangoSueldo.sueldoMinimo:0);
		// 	setRangoMaximo(rangoSueldo.sueldoMaximo!=0?rangoSueldo.sueldoMaximo:999999999);
		// }

		console.log("cargo-value:::", value);
		console.log("cargo-option:::", option);
		//const cargo = cargos.find(objeto => objeto.id === value);


		if (isExplomin) {
			const cargo = await CargoService.buscarPorId(value);
			console.log("cargooooooo:::", cargo);
			setOmitirValidarRango(cargo.omitirRangoSalarial);
			console.log("rangoSueldo-Min:::", cargo.sueldoMinimo);
			console.log("rangoSueldo-Max:::", cargo.sueldoMaximo);
			setRangoMinimo(cargo.sueldoMinimo != 0 ? cargo.sueldoMinimo : 0);
			setRangoMaximo(cargo.sueldoMaximo != 0 ? cargo.sueldoMaximo : 999999999);
		}

		formularioEditar.setFieldsValue({ cargo: { nombre: option.children } });
		const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
			getUsuarioAutenticado().empresaSeleccionada.id,
			value,
			CampoColaborador.CARGO
		);
		procesarCambios(campoEnviado);
	}

	async function handleOnFinish(colaborador) {
		try {
			setLoading(true);
			colaborador.empresa = {
				id: getUsuarioAutenticado().empresaSeleccionada.id,
				razonSocial: getUsuarioAutenticado().empresaSeleccionada.razonSocial,
				urlImagenLogotipo: getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo,
			};
			colaborador.organizacionId = getUsuarioAutenticado().organizacionId;
			colaborador.subdominio = getUsuarioAutenticado().subdominio;
			//colaborador.login = colaborador.numeroDocumentoIdentidad;
			//colaborador.clave = "";
			colaborador.estadoRegistro = estadoColaborador;
			colaborador.actualizarCorreoNotificaciones = actualizarCorreo;
			if (colaborador.fechaCese === null) {
				colaborador.motivoCese = null;
			}
			colaborador.seccion = seccionSeleccionada;
			colaborador.subsede = subsedeSeleccionada;
			colaborador.perfil = perfilSeleccionado;
			colaborador.tipoPlanilla = tipoPlanillaSeleccionado;

			colaborador.apellidoPaterno = colaborador.apellidoPaterno.trim().toUpperCase();
			colaborador.apellidoMaterno = colaborador.apellidoMaterno.trim().toUpperCase();
			colaborador.nombres = colaborador.nombres.trim().toUpperCase();
			colaborador.codigoTrabajador =
				!colaborador.codigoTrabajador || colaborador.codigoTrabajador.trim() === ""
					? null
					: colaborador.codigoTrabajador.trim();
			colaborador.celular =
				!colaborador.celular || colaborador.celular.trim() === "" ? null : colaborador.celular.trim();
			colaborador.motivoCese =
				!colaborador.motivoCese || colaborador.motivoCese.trim() === "" ? null : colaborador.motivoCese.trim();
			colaborador.jefeInmediatoId =
				jefeSeleccionado != null && jefeSeleccionado.length > 0 ? jefeSeleccionado : null;
			colaborador.observacionCese =
				!colaborador.observacionCese || colaborador.observacionCese.trim() === ""
					? null
					: colaborador.observacionCese.trim();
			colaborador.vinculoLaboral = "TRABAJADOR";
			// colaborador.otrosJefesInmediatosIds =
			// 	aprobadoresSeleccionados?.length > 0 ? aprobadoresSeleccionados : null;

			colaborador.otrosJefesInmediatosIds = colaboradoresOtrosAprobadores.map(item => item.value);

			if (colaborador.domicilioActual) {
				colaborador.domicilioActual.direccion = colaborador.domicilioActual !== undefined ? (colaborador.domicilioActual.direccion !== undefined ? (colaborador.domicilioActual.direccion.trim() !== "" ? colaborador.domicilioActual.direccion.toUpperCase() : null) : null) : null;
			}

			if (isExplomin) {
				colaborador.contrato.fechaInicioContrato =
					colaborador.contrato.fechaInicioContrato.format("DD/MM/YYYY");
				colaborador.contrato.fechaFinContrato = colaborador.contrato.fechaFinContrato.format("DD/MM/YYYY");

				if (opcionesEmpresaSeleccionada && !opcionesEmpresaSeleccionada.includes(OPCIONES.PAGINA_MANTENIMIENTO_COLABORADOR.TXT_EDITAR_VER_SUELDO)) {
					colaborador.contrato.sueldo = sueldoContrato;
				}
			}
			colaborador.usuarioModificacion = {
				nombreCompleto: getUsuarioAutenticado().nombreCompleto,
				numeroDocumentoIdentidad: getUsuarioAutenticado().numeroDocumentoIdentidad
			};

			console.log("Informacion Cola:::", colaborador);
			const formData = new FormData();
			formData.append("firmaHolografa", firmaHolografa);
			formData.append("huellaDactilar", huellaDactilar);
			formData.append(
				"colaborador",
				new Blob([JSON.stringify(colaborador)], {
					type: "application/json",
				})
			);

			await ColaboradorService.actualizar(formData);

			notification.success({
				message: "Operación exitosa",
				description: "Se actualizó el colaborador correctamente.",
			});
			//console.log(colaborador)
			setLoading(false);
			history.push("/personal/trabajador");
		} catch (error) {
			console.log(error);
			notification.error({
				message: "No se puede actualizar el Colaborador",
				description: error.response.data.mensaje,
			});
			setLoading(false);
		}
	}
	function handleOnClickCancelar() {
		history.push("/personal/trabajador");
	}
	function onchangeActualizarCorreo(e) {
		setActualizarCorreo(e.target.checked);
	}
	function onChangeFechaCese(value) {
		if (value !== null) {
			setIngresoFechaCese(true);
		} else {
			setIngresoFechaCese(false);
			formularioEditar.setFieldsValue({
				motivoCese: "",
				observacionCese: "",
			});
		}
	}

	function procesarCambios(campoEnviado) {
		if (campoEnviado.PLANILLA != null) {
			setTipoPlanillas(campoEnviado.PLANILLA);
			formularioEditar.setFieldsValue({ tipoPlanilla: "" });
		}
		if (campoEnviado.CARGO != null) {
			setCargos(campoEnviado.CARGO);
			formularioEditar.setFieldsValue({ cargo: { id: "" } });
		}
		if (campoEnviado.PERFIL != null) {
			setPerfiles(campoEnviado.PERFIL);
			formularioEditar.setFieldsValue({ perfil: "" });
		}
		if (campoEnviado.SECCION != null) {
			setSecciones(campoEnviado.SECCION);
			formularioEditar.setFieldsValue({ seccion: "" });
		}
		if (campoEnviado.SEDE != null) {
			setSedes(campoEnviado.SEDE);
			formularioEditar.setFieldsValue({ sede: { id: "" } });
		}
		if (campoEnviado.SUBSEDE != null) {
			setSubsedes(campoEnviado.SUBSEDE);
			formularioEditar.setFieldsValue({ subsede: "" });
		}
	}
	async function onSelectSeccion(value, option) {
		const seccion = {
			id: option.value,
			nombre: option.children,
		};
		setSeccionSeleccionada(seccion);
		const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
			getUsuarioAutenticado().empresaSeleccionada.id,
			value,
			CampoColaborador.SECCION
		);
		// console.log(campoEnviado)
		procesarCambios(campoEnviado);
	}
	async function onSelectSubsede(value, option) {
		const subsede = {
			id: option.value,
			nombre: option.children,
		};
		setSubsedeSeleccionada(subsede);
		const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
			getUsuarioAutenticado().empresaSeleccionada.id,
			value,
			CampoColaborador.SUBSEDE
		);
		// console.log(campoEnviado)
		procesarCambios(campoEnviado);
	}
	async function onSelectPerfil(value, option) {
		const perfil = {
			id: option.value,
			nombre: option.children,
		};
		setPerfilSeleccionado(perfil);

		const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
			getUsuarioAutenticado().empresaSeleccionada.id,
			value,
			CampoColaborador.PERFIL
		);
		// console.log(campoEnviado)
		procesarCambios(campoEnviado);
	}
	async function onSelectTipoPlanilla(value, option) {
		const tipoPlanilla = {
			id: option.value,
			nombre: option.children,
		};
		setTipoPlanillaSeleccionado(tipoPlanilla);
		const campoEnviado = await ColaboradorService.camposColaboradorSeleccionado(
			getUsuarioAutenticado().empresaSeleccionada.id,
			value,
			CampoColaborador.PLANILLA
		);
		//  console.log(campoEnviado)
		procesarCambios(campoEnviado);
		setOcultarTipoPractica(true);
		if (isExplomin && option.children === "PRACTICANTE") {
			setOcultarTipoPractica(false);
		}
	}

	const onChangeCodigoColaborador = (e) => {
		const codigo = formularioEditar.getFieldValue("codigoTrabajador").replace(/\s+/g, "");
		formularioEditar.setFieldsValue({
			codigoTrabajador: codigo,
		});
	};

	const onSearchColaboradores = async (value) => {
		const _respuesta = await ComunicadoService.listarColaboradoresPorEmpresaConFiltro({
			empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
			filtro: value.trim(),
			login: getUsuarioAutenticado().login,
		});
		setColaboradoresBuscados(_respuesta);
		if (value === "") {
			formularioEditar.setFieldsValue({
				buscarJefeInmediato: "",
			});
			setJefeSeleccionado([]);
		}
	};

	const onChangeColaboradoresSeleccionados = (value) => {
		setJefeSeleccionado(value);
	};

	const propsFirma = {
		onRemove: (file) => {
			setFirmaHolografa([]);

			return {
				firmaHolografa: [],
			};
		},
		beforeUpload: (file) => {
			if (file.type !== "image/png" && file.type !== "image/jpeg") {
				message.error(`${file.name} no es un archivo png ó jpeg`);
			} else {
				setFirmaHolografa(file);
				return false;
			}
		},
		firmaHolografa,
	};

	const propsHuella = {
		onRemove: (file) => {
			setHuellaDactilar([]);

			return {
				huellaDactilar: [],
			};
		},
		beforeUpload: (file) => {
			if (file.type !== "image/png" && file.type !== "image/jpeg") {
				message.error(`${file.name} no es un archivo png ó jpeg`);
			} else {
				setHuellaDactilar(file);
				return false;
			}
		},
		huellaDactilar,
	};
	const handleChangeOtrosAprobadores = (value) => {
		setAprobadoresSeleccionados(value);
	};
	const onChangeDepartamento = async (value) => {
		const formData = new FormData();
		formData.append("departamento", value);
		const provincias = await UbigeoService.listarProvincias(formData);
		//setProvincias(provincias);
		// formularioEditar.setFieldsValue({
		// 	domicilioActual: {
		// 		provincia: provincias[0].provincia,
		// 	},
		// });
		if (provincias.length > 0) {
			setProvincias(provincias);
			formularioEditar.setFieldsValue({
				domicilioActual: {
					provincia: provincias[0].provincia,
				},
			});
			const formDataDistrito = new FormData();
			formDataDistrito.append("provincia", provincias[0].provincia);
			const distritos = await UbigeoService.listarDistritos(formDataDistrito);
			setDistritos(distritos);

			formularioEditar.setFieldsValue({
				domicilioActual: {
					distrito: distritos[0].distrito,
				},
			});
		}
		else {
			setProvincias(undefined);
			formularioEditar.setFieldsValue({
				domicilioActual: {
					provincia: undefined,
				},
			});
			setDistritos(undefined);
			formularioEditar.setFieldsValue({
				domicilioActual: {
					distrito: undefined,
				},
			});
		}


		// const formDataDistrito = new FormData();
		// formDataDistrito.append("provincia", provincias[0].provincia);
		// const distritos = await UbigeoService.listarDistritos(formDataDistrito);
		// setDistritos(distritos);

		// formularioEditar.setFieldsValue({
		// 	domicilioActual: {
		// 		distrito: distritos[0].distrito,
		// 	},
		// });
	};

	const onChangeProvincia = async (value) => {
		const formData = new FormData();
		formData.append("provincia", value);
		const distritos = await UbigeoService.listarDistritos(formData);
		//setDistritos(distritos);

		// formularioEditar.setFieldsValue({
		// 	domicilioActual: {
		// 		distrito: distritos[0].distrito,
		// 	},
		// });
		if (distritos.length > 0) {
			setDistritos(distritos);
			formularioEditar.setFieldsValue({
				domicilioActual: {
					distrito: distritos[0].distrito,
				},
			});
		}
		else {
			setDistritos(undefined);
			formularioEditar.setFieldsValue({
				domicilioActual: {
					distrito: undefined,
				},
			});
		}
	};
	const onChangeApellidoPaterno = (e) => {
		const apellidoP = formularioEditar.getFieldValue("apellidoPaterno").toUpperCase();
		formularioEditar.setFieldsValue({
			apellidoPaterno: apellidoP,
		});
	};

	const onChangeApellidoMaterno = (e) => {
		const apellidoM = formularioEditar.getFieldValue("apellidoMaterno").toUpperCase();
		formularioEditar.setFieldsValue({
			apellidoMaterno: apellidoM,
		});
	};

	const onChangeNombres = () => {
		const nombre = formularioEditar.getFieldValue("nombres").toUpperCase();
		formularioEditar.setFieldsValue({
			nombres: nombre,
		});
	};
	const onChangeDireccion = () => {
		const direccion = formularioEditar.getFieldValue(["domicilioActual", "direccion"]).toUpperCase();
		formularioEditar.setFieldsValue({
			domicilioActual: {
				direccion: direccion,
			},
		});
	};
	const onSeleccionarResponsableRenovacionContrato = (value) => {
		setresponsableRenovarContratoSeleccionado(value);
	};

	const onSearchColaboradorResponsableContrato = async (value) => {
		const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
			filtro: value.trim(),
			login: getUsuarioAutenticado().login,
		});

		if (value === "") {
			formularioEditar.setFieldsValue({
				responsableRenovarContratoId: "",
			});
			setresponsableRenovarContratoSeleccionado(undefined);
			setresponsablesRenovarContrato([]);
		} else {
			setresponsablesRenovarContrato(respuesta.colaboradores);
		}
	};
	const onSeleccionarAdministradorObra = (value) => {
		setAdministradorObraSeleccionado(value);
	};

	const onSearchAdministradorObra = async (value) => {
		const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
			filtro: value.trim(),
			login: getUsuarioAutenticado().login,
		});

		if (value === "") {
			formularioEditar.setFieldsValue({
				responsableRenovarContratoId: "",
			});
			setAdministradorObraSeleccionado(undefined);
			setAdministradoresObra([]);
		} else {
			setAdministradoresObra(respuesta.colaboradores);
		}
	};

	const fetchOtrosAprobadores = async (value) => {
		// console.log("fetchOtrosAprobadores===>", value)
		const respuesta = await ColaboradorService.listarPorNombreCompletoODni({
			filtro: value.trim(),
			login: getUsuarioAutenticado().login,
		});

		return respuesta.colaboradores.map((colaborador) => ({
			label: colaborador.nombreCompleto,
			value: colaborador.id,
		}));

	};

	return (
		<Spin
			indicator={<LoadingOutlined style={{ fontSize: 28 }} />}
			spinning={loading}
			size="large"
			tip="Cargando..."
		>
			<ContainerCrear
				titulo="Editar Colaborador"
				descripcion="En este formulario podrás editar la información personal de tu colaborador."
				colSpan={12}
			>
				<Form form={formularioEditar} layout="vertical" size="large" onFinish={handleOnFinish}>
					<Form.Item name="id" label="id" noStyle>
						<Input type="hidden" />
					</Form.Item>
					<Form.Item name="eliminado" noStyle>
						<Input type="hidden" />
					</Form.Item>
					<Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
						<Space>
							<EditOutlined /> Información personal
						</Space>
					</Divider>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								label="Tipo Documento Identidad"
								//name={["tipoDocumentoIdentidad", "nombre"]}
								name="tipoDocumentoIdentidad"
								rules={[
									{
										required: true,
										message: "Selecciona tu tipo de documento de identidad",
									},
								]}
							>
								<Select placeholder="Documento de identidad" allowClear>
									<Option value={tipoDocumentoIdentidad.DNI.valor}>
										{tipoDocumentoIdentidad.DNI.nombre}
									</Option>
									<Option value={tipoDocumentoIdentidad.CARNET_EXTRANJERIA.valor}>
										{tipoDocumentoIdentidad.CARNET_EXTRANJERIA.descripcion}
									</Option>
									<Option value={tipoDocumentoIdentidad.PASAPORTE.valor}>
										{tipoDocumentoIdentidad.PASAPORTE.nombre}
									</Option>
									<Option value={tipoDocumentoIdentidad.OTRO_DOCUMENTO.valor}>
										{tipoDocumentoIdentidad.OTRO_DOCUMENTO.descripcion}
									</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="Número de Documento Identidad"
								name="numeroDocumentoIdentidad"
								rules={[
									{
										required: true,
										message: "Escribe el número de tu documento de identidad",
									},
								]}
							>
								<Input
									placeholder="Escriba el número de documento de indentidad"
									autoComplete="off"
									disabled={true}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								label="Apellido Paterno"
								name="apellidoPaterno"
								rules={[
									{
										required: true,
										message: "Escriba el apellido paterno",
										whitespace: true,
									},
								]}
							>
								<Input
									placeholder="Escriba el apellido paterno"
									autoComplete="off"
									onChange={onChangeApellidoPaterno}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="Apellido Materno"
								name="apellidoMaterno"
								rules={[
									{
										required: true,
										message: "Escriba el apellido materno",
										whitespace: true,
									},
								]}
							>
								<Input
									placeholder="Escriba el apellido materno"
									autoComplete="off"
									onChange={onChangeApellidoMaterno}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								label="Nombres"
								name="nombres"
								rules={[
									{
										required: true,
										message: "Escriba los nombres completos del colaborador",
										whitespace: true,
									},
								]}
							>
								<Input
									placeholder="Escriba aquí sus nombres completos"
									autoComplete="off"
									onChange={onChangeNombres}
								/>
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								label="Género"
								name="sexoColaborador"
								rules={[
									{
										required: true,
										message: "Selecciona tu género",
									},
								]}
							>
								<Select>
									<Option value={SexoColaborador.FEMENINO}>{SexoColaborador.FEMENINO} </Option>
									<Option value={SexoColaborador.MASCULINO}>{SexoColaborador.MASCULINO}</Option>
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								label="Fecha Nacimiento"
								name="fechaNacimiento"
								rules={[
									{
										required: isCime ? false : true,
										message: "Selecciona tu fecha nacimiento",
									},
								]}
							>
								<DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								label="Tiene Hijos"
								name="tieneHijos"
								rules={[
									{
										required: isCime || isLaredo ? false : true,
										message: "Selecciona si tienes hijos",
									},
								]}
							>
								<Select allowClear>
									<Option value="SI">Si</Option>
									<Option value="NO">No</Option>
								</Select>
							</Form.Item>
						</Col>
					</Row>
					<Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
						<Space>
							<MailOutlined /> Contacto
						</Space>
					</Divider>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								//label="Correo Electrónico"
								label={
									<React.Fragment>
										<span>
											Correo Electrónico {"  "}
											{correoVerificado && (
												<CheckCircleFilled style={{ fontSize: "20px", color: "#68BA39" }} />
											)}
										</span>{" "}
									</React.Fragment>
								}
								name="correoElectronico"
								rules={[
									{
										required: true,
										message: "Escriba el correo electrónico",
									},
									{
										type: "email",
										message: "Correo electrónico ingresado no es válido",
									},
								]}
							>
								<Input placeholder="Escriba el correo electrónico" autoComplete="off" />
							</Form.Item>
							<Form.Item label="" name="actualizarCorreoNotificaciones">
								<Checkbox onChange={onchangeActualizarCorreo} defaultChecked={false}>
									Actualizar Correo en Notificaciones{" "}
								</Checkbox>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								//label="Número de Celular"
								label={
									<React.Fragment>
										<span>
											Número de Celular{"  "}{" "}
											{celularVerificado && (
												<CheckCircleFilled style={{ fontSize: "20px", color: "#68BA39" }} />
											)}
										</span>{" "}
									</React.Fragment>
								}
								name="celular"
							>
								<Input placeholder="Escriba el número de celular" autoComplete="off" />
							</Form.Item>
						</Col>
					</Row>
					<Row gutter={16}>
						<Col span={24}>
							<Form.Item
								label="Correo Electrónico Alternativo"
								name="correoElectronicoAlternativo"
								rules={[
									{
										type: "email",
										message: "Correo electrónico ingresado no es válido",
									},
								]}
							>
								<Input autoComplete="off" />
							</Form.Item>
						</Col>
					</Row>
					{!isLaredo &&
						<>
							<Row gutter={16}>
								<Col span={8}>
									<Form.Item
										name={["domicilioActual", "departamento"]}
										label="Departamento"
										rules={[
											{
												required: isCime ? false : true,
											},
										]}
									>
										<Select
											style={{ width: "100%" }}
											allowClear
											showSearch
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											onChange={onChangeDepartamento}
										>
											{departamentos !== undefined &&
												departamentos?.map((departamento) => (
													<Select.Option key={departamento.nombre} value={departamento.nombre}>
														{departamento.nombre}
													</Select.Option>
												))}
										</Select>
									</Form.Item>
								</Col>
								<Col span={8}>
									<Form.Item
										label="Provincia"
										name={["domicilioActual", "provincia"]}
										rules={[{ required: isCime ? false : true, }]}
									>
										<Select
											style={{ width: "100%" }}
											allowClear
											showSearch
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											onChange={onChangeProvincia}
										>
											{provincias !== undefined &&
												provincias?.map((info) => (
													<Select.Option key={info.provincia} value={info.provincia}>
														{info.provincia}
													</Select.Option>
												))}
										</Select>
									</Form.Item>
								</Col>

								<Col span={8}>
									<Form.Item
										label="Distrito"
										name={["domicilioActual", "distrito"]}
										rules={[{ required: isCime ? false : true, }]}
									>
										<Select
											style={{ width: "100%" }}
											allowClear
											showSearch
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
										>
											{distritos !== undefined &&
												distritos?.map((objeto) => (
													<Select.Option key={objeto.distrito} value={objeto.distrito}>
														{objeto.distrito}
													</Select.Option>
												))}
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={16}>
								<Col span={24}>
									<Form.Item
										name={["domicilioActual", "direccion"]}
										label="Dirección"
										rules={[
											{
												required: isCime ? false : true,
											},
										]}
									>
										<Input onChange={onChangeDireccion} />
									</Form.Item>
								</Col>
							</Row>
						</>
					}
					<Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
						<Space>
							<BankOutlined /> Empresa
						</Space>
					</Divider>
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item
								label="Código Colaborador"
								name="codigoTrabajador"
								rules={
									codigoColaboradorActivo === "SI" || isLaredo
										? [
											{
												required: true,
												message: "Escribe el código del colaborador",
												whitespace: true,
											},
										]
										: []
								}
							>
								<Input
									placeholder="Escriba el código del colaborador"
									autoComplete="off"
									onChange={onChangeCodigoColaborador}
								/>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item
								name="fechaIngreso"
								label="Fecha Ingreso"
								rules={
									isLaredo
										? [{ required: true, message: "Ingrese la Fecha de Ingreso" }]
										: []
								}
							>
								<DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
							</Form.Item>
						</Col>
					</Row>

					<Row gutter={16}>
						{camposColaboradores.length > 0 &&
							camposColaboradores.map((campoColaborador) => {
								if (
									campoColaborador.visible === true &&
									campoColaborador.campo === CampoColaborador.PERFIL
								) {
									return (
										<Col span={12}>
											<Form.Item
												label={campoColaborador.etiqueta}
												name="perfil"
												rules={
													campoColaborador.campoObligatorio === true
														? [
															{
																required: true,
																message: "Seleccione Perfil",
															},
														]
														: []
												}
											>
												<Select
													onSelect={onSelectPerfil}
													showSearch
													filterOption={(input, option) =>
														option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
													}
												>
													{perfiles.length > 0 &&
														perfiles.map((perfil) => {
															return (
																<Option key={perfil.id} value={perfil.id}>
																	{perfil.nombre}
																</Option>
															);
														})}
												</Select>
											</Form.Item>
										</Col>
									);
								} else if (
									campoColaborador.visible === true &&
									campoColaborador.campo === CampoColaborador.SEDE
								) {
									return (
										<Col span={12}>
											<Form.Item
												name={["sede", "id"]}
												label={campoColaborador.etiqueta}
												rules={
													campoColaborador.campoObligatorio === true
														? [
															{
																required: true,
																message: `Seleccione " + ${campoColaborador.etiqueta}`,
															},
														]
														: []
												}
											>
												<Select onChange={handleOnChangeSede}>
													{sedes.length > 0 &&
														sedes.map((sede) => {
															return (
																<Option key={sede.id} value={sede.id}>
																	{sede.nombre}
																</Option>
															);
														})}
												</Select>
											</Form.Item>
											<Form.Item name={["sede", "nombre"]} noStyle>
												<Input type="hidden" />
											</Form.Item>
										</Col>
									);
								} else if (
									campoColaborador.visible === true &&
									campoColaborador.campo === CampoColaborador.SUBSEDE
								) {
									return (
										<Col span={12}>
											<Form.Item
												label={campoColaborador.etiqueta}
												name="subsede"
												rules={
													campoColaborador.campoObligatorio === true
														? [
															{
																required: true,
																message: "Seleccione Subcentro de costo",
															},
														]
														: []
												}
											>
												<Select
													onSelect={onSelectSubsede}
													showSearch
													filterOption={(input, option) =>
														option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
													}
												>
													{subsedes.length > 0 &&
														subsedes.map((subsede) => {
															return (
																<Option key={subsede.id} value={subsede.id}>
																	{subsede.nombre}
																</Option>
															);
														})}
												</Select>
											</Form.Item>
										</Col>
									);
								} else if (
									campoColaborador.visible === true &&
									campoColaborador.campo === CampoColaborador.SECCION
								) {
									return (
										<Col span={12}>
											<Form.Item
												label={campoColaborador.etiqueta}
												name="seccion"
												rules={
													campoColaborador.campoObligatorio === true
														? [
															{
																required: true,
																message: "Seleccione Sección",
															},
														]
														: []
												}
											>
												<Select
													onSelect={onSelectSeccion}
													showSearch
													filterOption={(input, option) =>
														option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
													}
												>
													{secciones.length > 0 &&
														secciones.map((seccion) => {
															return (
																<Option key={seccion.id} value={seccion.id}>
																	{seccion.nombre}
																</Option>
															);
														})}
												</Select>
											</Form.Item>
										</Col>
									);
								} else if (
									campoColaborador.visible === true &&
									campoColaborador.campo === CampoColaborador.PLANILLA
								) {
									return (
										<Col span={12}>
											<Form.Item
												label={campoColaborador.etiqueta}
												name="tipoPlanilla"
												rules={
													campoColaborador.campoObligatorio === true
														? [
															{
																required: true,
																message: "Seleccione Tipo Planilla",
															},
														]
														: []
												}
											>
												<Select
													onSelect={onSelectTipoPlanilla}
													showSearch
													filterOption={(input, option) =>
														option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
													}
												>
													{tipoPlanillas.length > 0 &&
														tipoPlanillas.map((tipoPlanilla) => {
															return (
																<Option key={tipoPlanilla.id} value={tipoPlanilla.id}>
																	{tipoPlanilla.nombre}
																</Option>
															);
														})}
												</Select>
											</Form.Item>
										</Col>
									);
								} else if (
									campoColaborador.visible === true &&
									campoColaborador.campo === CampoColaborador.CARGO
								) {
									return (
										<Col span={12}>
											<Form.Item
												name={["cargo", "id"]}
												label={campoColaborador.etiqueta}
												rules={
													campoColaborador.campoObligatorio === true
														? [
															{
																required: true,
																message: "Seleccione Cargo",
															},
														]
														: []
												}
											>
												<Select onChange={handleOnChangeCargo}>
													{cargos.length > 0 &&
														cargos.map((cargo) => {
															return (
																<Option key={cargo.id} value={cargo.id}>
																	{cargo.nombre}
																</Option>
															);
														})}
												</Select>
											</Form.Item>
											<Form.Item name={["cargo", "nombre"]} noStyle>
												<Input type="hidden" />
											</Form.Item>
										</Col>
									);
								}
							})}

						{isExplomin && (
							<Col span={12}>
								<Form.Item
									name={["datosPlanillaPracticas", "tipoPracticas"]}
									label="Tipo de Prácticas"
									hidden={ocultarTipoPractica}
									rules={
										[
											{
												required: !ocultarTipoPractica,
												message: "Seleccione el tipo de práctica",
											},
										]

									}
								>
									<Select>
										{/* <Select.Option value="PREPROFESIONALES">
									PREPROFESIONALES
								</Select.Option> */}
										<Select.Option value="PROFESIONALES">
											PROFESIONALES
										</Select.Option>
									</Select>
								</Form.Item>
							</Col>
						)}
					</Row>

					{isExplomin && (
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item
									name={["datosPlanillaPracticas", "nacionalidad"]}
									label="Nacionalidad"
									hidden={ocultarTipoPractica}
									rules={
										[
											{
												required: !ocultarTipoPractica,
												message: "Seleccione la nacionalidad",
											},
										]

									}
								>
									<Input />
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item
									name={["datosPlanillaPracticas", "profesion"]}
									label="Profesión Universitaria o Profesión Técnica"
									hidden={ocultarTipoPractica}
									rules={
										[
											{
												required: !ocultarTipoPractica,
												message: "Seleccione la profesión",
											},
										]

									}
								>
									<Input />
								</Form.Item>
							</Col>
						</Row>
					)}

					{isExplomin && (
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item
									name={["datosPlanillaPracticas", "universidad"]}
									label="Universidad"
									hidden={ocultarTipoPractica}
									rules={
										[
											{
												required: !ocultarTipoPractica,
												message: "Ingrese la universidad",
											},
										]

									}
								>
									<Input />
								</Form.Item>
							</Col>
						</Row>
					)}
					<Row gutter={16}>
						<Col span={12}>
							<Form.Item name="buscarJefeInmediato" label="Jefe Inmediato">
								<Select
									placeholder="Ingrese nombre de colaborador"
									allowClear
									showSearch
									onSearch={onSearchColaboradores}
									onChange={onChangeColaboradoresSeleccionados}
									filterOption={(input, option) =>
										option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									}
								>
									{colaboradoresBuscados !== undefined &&
										colaboradoresBuscados.map((colaborador) => {
											return (
												<Select.Option key={colaborador.id} value={colaborador.id}>
													{colaborador.nombreCompleto}
												</Select.Option>
											);
										})}
								</Select>
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item name="fechaCese" label="Fecha Cese">
								<DatePicker
									style={{ width: "100%" }}
									format={"DD/MM/YYYY"}
									onChange={onChangeFechaCese}
								/>
							</Form.Item>
						</Col>
					</Row>

					{/* <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="firmaHolografa" label="Firma Hológrafa">
                <Upload {...propsFirma} maxCount={1} accept={FormatoCarga.IMAGEN.extension}>
                  <Button>Cargar Firma Hológrafa</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="huellaDactilar" label="Huella Dactilar">
                <Upload {...propsHuella} maxCount={1} accept={FormatoCarga.IMAGEN.extension}>
                  <Button>Cargar Huella Dactilar</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row> */}

					{ingresoFechaCese && (
						<Row gutter={16}>
							<Col span={12}>
								<Form.Item label="Motivo Cese" name="motivoCese">
									<Select placeholder="Seleccione el motivo cese" allowClear>
										<Select.Option value={MotivoCese.renuncia}>
											{MotivoCese.renuncia}{" "}
										</Select.Option>
										<Select.Option value={MotivoCese.termino_contrato}>
											{MotivoCese.termino_contrato}
										</Select.Option>
										<Select.Option value={MotivoCese.despido_abandono_trabajo}>
											{MotivoCese.despido_abandono_trabajo}{" "}
										</Select.Option>
										<Select.Option value={MotivoCese.despido_falta_grave}>
											{MotivoCese.despido_falta_grave}{" "}
										</Select.Option>
										<Select.Option value={MotivoCese.no_inicio_relacion_laboral}>
											{MotivoCese.no_inicio_relacion_laboral}
										</Select.Option>
										<Select.Option value={MotivoCese.periodo_prueba}>
											{MotivoCese.periodo_prueba}
										</Select.Option>
										<Select.Option value={MotivoCese.despido_o_destitucion}>
											{MotivoCese.despido_o_destitucion}
										</Select.Option>
										<Select.Option value={MotivoCese.mutuo_disenso}>
											{MotivoCese.mutuo_disenso}
										</Select.Option>
									</Select>
								</Form.Item>
							</Col>
							<Col span={12}>
								<Form.Item label="Observación Cese" name="observacionCese">
									<TextArea rows={4} />
								</Form.Item>
							</Col>
						</Row>
					)}
					{getUsuarioAutenticado().empresaSeleccionada?.moduloContratoHabilitado && isExplomin && (
						<React.Fragment>
							<Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
								<Space>
									<FolderAddOutlined /> Datos Contrato
								</Space>
							</Divider>
							<Alert message="Esta información se actualizará en el ultimo contrato vigente" type="warning" showIcon />

							{opcionesEmpresaSeleccionada && opcionesEmpresaSeleccionada.includes(OPCIONES.PAGINA_MANTENIMIENTO_COLABORADOR.TXT_EDITAR_VER_SUELDO) &&
								!omitirValidarRango && (
									<Row gutter={16}>
										<Col span={12}>
											<Form.Item
												name="estadoCivil"
												label="Estado Civil"
												rules={[
													{
														required: true,
													},
												]}
											>
												<Select
													style={{
														width: "100%",
													}}
													options={[
														{
															value: "SOLTERO",
															label: "SOLTERO",
														},
														{
															value: "CONVIVIENTE",
															label: "CONVIVIENTE",
														},
														{
															value: "CASADO",
															label: "CASADO",
														},
														{
															value: "DIVORCIADO",
															label: "DIVORCIADO",
														},
														{
															value: "VIUDO",
															label: "VIUDO",
														},
													]}
												/>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												name={["contrato", "sueldo"]}
												label="Sueldo"
												rules={[
													{
														required: true,
													},
													{
														validator: (_, value) =>
															value && (value < rangoMinimo || value > rangoMaximo)
																? Promise.reject(new Error('El sueldo debe estar entre ' + rangoMinimo + ' y ' + rangoMaximo + '.'))
																: Promise.resolve(),
													},
												]}
											>
												<Input />
											</Form.Item>
										</Col>
									</Row>

								)}
							{opcionesEmpresaSeleccionada && opcionesEmpresaSeleccionada.includes(OPCIONES.PAGINA_MANTENIMIENTO_COLABORADOR.TXT_EDITAR_VER_SUELDO) &&
								omitirValidarRango && (
									<Row gutter={16}>
										<Col span={12}>
											<Form.Item
												name="estadoCivil"
												label="Estado Civil"
												rules={[
													{
														required: true,
													},
												]}
											>
												<Select
													style={{
														width: "100%",
													}}
													options={[
														{
															value: "SOLTERO",
															label: "SOLTERO",
														},
														{
															value: "CONVIVIENTE",
															label: "CONVIVIENTE",
														},
														{
															value: "CASADO",
															label: "CASADO",
														},
														{
															value: "DIVORCIADO",
															label: "DIVORCIADO",
														},
														{
															value: "VIUDO",
															label: "VIUDO",
														},
													]}
												/>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												name={["contrato", "sueldo"]}
												label="Sueldo"
												rules={[
													{
														required: true,
													},
												]}
											>
												<Input />
											</Form.Item>
										</Col>
									</Row>

								)}
							<Row gutter={16}>
								<Col span={12}>
									<Form.Item
										name={["contrato", "fechaInicioContrato"]}
										label="Fecha Inicio Contrato"
										rules={[
											{
												required: true,
											},
										]}
									>
										<DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										name={["contrato", "fechaFinContrato"]}
										label="Fecha Fin Contrato"
										rules={[
											{
												required: true,
											},
										]}
									>
										<DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
									</Form.Item>
								</Col>
							</Row>
							{!ocultarTipoServicio && (
								<Row gutter={16}>
									<Col span={12}>
										<Form.Item
											name={["contrato", "tipoServicio"]}
											label="Tipo de Servicio"
											rules={[
												{
													required: true,
												},
											]}
										>
											<Select
												style={{
													width: "100%",
												}}
												options={[
													{
														value: "SERVICIO TECNICO DE MUESTRERIA",
														label: "SERVICIO TECNICO DE MUESTRERIA",
													},
													{
														value: "PERFORACIÓN DE DIAMANTINA",
														label: "PERFORACIÓN DE DIAMANTINA",
													},
													{
														value: "PERFORACIÓN DE AIRE REVERSO",
														label: "PERFORACIÓN DE AIRE REVERSO",
													},
													{
														value: "PERFORACIÓN POZOS DE AGUA",
														label: "PERFORACIÓN POZOS DE AGUA",
													},
													{
														value: "PERFORACIÓN GEOTECNIA",
														label: "PERFORACIÓN GEOTECNIA",
													},
												]}
											/>
										</Form.Item>
									</Col>
								</Row>
							)}
							<Row gutter={16}>
								<Col span={24}>
									<Form.Item
										name="responsableRenovarContratoId"
										label="Responsable de Renovación de Contrato"
									>
										<Select
											showSearch
											allowClear
											onSearch={onSearchColaboradorResponsableContrato}
											onChange={onSeleccionarResponsableRenovacionContrato}
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
										>
											{responsablesRenovarContrato !== undefined &&
												responsablesRenovarContrato.map((colaborador) => {
													return (
														<Select.Option key={colaborador.id} value={colaborador.id}>
															{colaborador.nombreCompleto}
														</Select.Option>
													);
												})}
										</Select>
									</Form.Item>
								</Col>
							</Row>

							<Row gutter={16}>
								<Col span={24}>
									<Form.Item
										name="administradorObraId"
										label="Administrador de Obra"
										hidden={ocultarAdminObra}
									>
										<Select
											showSearch
											allowClear
											onSearch={onSearchAdministradorObra}
											onChange={onSeleccionarAdministradorObra}
											filterOption={(input, option) =>
												option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
										>
											{administradoresObra !== undefined &&
												administradoresObra.map((colaborador) => {
													return (
														<Select.Option key={colaborador.id} value={colaborador.id}>
															{colaborador.nombreCompleto}
														</Select.Option>
													);
												})}
										</Select>
									</Form.Item>
								</Col>
							</Row>
						</React.Fragment>
					)}
					{isExplomin && (
						<>
							<Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
								<Space>
									<WalletOutlined /> Cuenta Haberes
								</Space>
							</Divider>
							<Form.Item
								name={["cuentaHaberes", "exist"]}
								rules={[{ required: true, message: "Por favor seleccione una opcion" }]}
							>
								<Radio.Group>
									<Radio value={true}>Tengo Cuenta</Radio>
									<Radio value={false}>Crear Cuenta</Radio>
								</Radio.Group>
							</Form.Item>
							<Row gutter={16}>
								<Col span={12}>
									{/* <Form.Item label="Banco" name={["cuentaHaberes", "banco"]} rules={[{ required: existeCuenta }]}> */}
									<Form.Item
										label="Banco"
										name={["cuentaHaberes", "banco"]}
										rules={[{ required: true }]}
									>
										{/* <Input disabled={!existeCuenta} /> */}
										<Select>
											<Select.Option value="BANCO CONTINENTAL">BANCO CONTINENTAL</Select.Option>
											<Select.Option value="BANCO DE CREDITO">BANCO DE CREDITO</Select.Option>
											<Select.Option value="BANCO INTERBANK">BANCO INTERBANK</Select.Option>
											<Select.Option value="BANCO SCOTIABANK">BANCO SCOTIABANK</Select.Option>
											<Select.Option value="BANCO CITIBANK">BANCO CITIBANK</Select.Option>
											<Select.Option value="BANCO HSBC">BANCO HSBC</Select.Option>
											<Select.Option value="BANCO DE LA NACION">BANCO DE LA NACION</Select.Option>
											<Select.Option value="BANCO DE COMERCIO">BANCO DE COMERCIO</Select.Option>
											<Select.Option value="BANCO FINANCIERO">BANCO FINANCIERO</Select.Option>
											<Select.Option value="BANBIF">BANBIF</Select.Option>
											<Select.Option value="BANCO FALABELLA">BANCO FALABELLA</Select.Option>
											<Select.Option value="BANCO RIPLEY">BANCO RIPLEY</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label="Moneda"
										name={["cuentaHaberes", "tipoMoneda"]}
										// rules={[{ required: existeCuenta }]}
										rules={[{ required: true }]}
									>
										{/* <Input disabled={!existeCuenta} /> */}
										<Select>
											<Select.Option value="SOLES">SOLES</Select.Option>
											<Select.Option value="DOLARES">DOLARES</Select.Option>
										</Select>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label="Cuenta"
										name={["cuentaHaberes", "nroCuenta"]}
										rules={[{ required: existeCuenta }]}
									>
										<Input disabled={!existeCuenta} />
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label="Cuenta CCI"
										name={["cuentaHaberes", "nroCuentaCCI"]}
										rules={[{ required: existeCuenta }]}
									>
										<Input disabled={!existeCuenta} />
									</Form.Item>
								</Col>
							</Row>
						</>
					)}
					{!isCclp && !isLaredo && (
						<>
							<Divider plain style={{ color: "#457b9d", borderColor: "#219ebc" }}>
								<Space>
									<CarOutlined /> Vacaciones
								</Space>
							</Divider>
							<Row>
								<Col span={24}>
									<Form.Item label="Otros aprobadores inmediatos" name="otrosAprobadoresInmediatos">
										{/* <Select
											mode="multiple"
											allowClear
											filterOption={(input, option) =>
												(option?.label ?? "").toLowerCase().includes(input.toLowerCase())
											}
											options={colaboradoresOtrosAprobadores}
											onChange={handleChangeOtrosAprobadores}
										/> */}

										<DebounceSelect
											mode="multiple"
											value={colaboradoresOtrosAprobadores}
											initialValues={otrosAprobadoresInmediatosListaInicial}
											placeholder="Selecciona los jefes inmediatos"
											fetchOptions={fetchOtrosAprobadores}
											onChange={(newValue) => {
												setColaboradoresOtrosAprobadores(newValue);
											}}
											style={{
												width: '100%',
											}}
										/>

									</Form.Item>
								</Col>
							</Row>
						</>
					)}

					<Form.Item name="personaId" noStyle>
						<Input type="hidden" />
					</Form.Item>
					<Form.Item name="tipoOperador" noStyle>
						<Input type="hidden" />
					</Form.Item>

					<Row justify="center">
						<Space>
							<Col>
								<Form.Item style={{ textAlign: "center" }}>
									<Button type="default" onClick={handleOnClickCancelar}>
										Cancelar
									</Button>
								</Form.Item>
							</Col>
							<Col>
								<Form.Item style={{ textAlign: "center" }}>
									<Button type="primary" icon={<CheckOutlined />} htmlType="submit" loading={loading}>
										Actualizar Colaborador
									</Button>
								</Form.Item>
							</Col>
						</Space>
					</Row>
				</Form>
			</ContainerCrear>
		</Spin>
	);
}

const DebounceSelect = ({ fetchOptions, initialValues = [], debounceTimeout = 800, ...props }) => {
	const [fetching, setFetching] = useState(false);
	const [options, setOptions] = useState([]);
	const fetchRef = useRef(0);

	useEffect(() => {
		setOptions(initialValues);
	}, [initialValues]);

	const debounceFetcher = useMemo(() => {
		const loadOptions = (value) => {
			fetchRef.current += 1;
			const fetchId = fetchRef.current;
			setOptions([]);
			setFetching(true);
			fetchOptions(value).then((newOptions) => {
				if (fetchId !== fetchRef.current) {
					// for fetch callback order
					return;
				}
				setOptions(newOptions);
				setFetching(false);
			});
		};
		return debounce(loadOptions, debounceTimeout);
	}, [fetchOptions, debounceTimeout]);

	return (
		<Select
			labelInValue
			filterOption={false}
			onSearch={debounceFetcher}
			notFoundContent={fetching ? <Spin size="small" /> : null}
			options={options}
			{...props}
		/>
	);
}
