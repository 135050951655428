import React,{useEffect,useContext, useState} from "react";
import {Form,Select,Upload,Button,notification,message} from "antd";
import { UploadOutlined,CheckOutlined } from '@ant-design/icons';
import { SecurityContext } from "context/SecurityContextProvider";
//import { EmpresaService } from "services/EmpresaService";
import {FirmaHolografaService} from "services/FirmaHolografaService";
// import {empresasAtom} from "../CrearFirmaHolografaStore";
// import {empresaAtom} from "../CrearFirmaHolografaStore";
//import { useAtom } from "jotai";
import { useHistory } from "react-router-dom";
//import  UsuarioService  from "../../../../../services/UsuarioService";

const FirmaHolografaRegistro = ()=>{    
    // const {Option}=Select;
    const {getUsuarioAutenticado}=useContext(SecurityContext);
    // const [empresas,setEmpresas]=useAtom(empresasAtom);
    // const[empresa,setEmpresa]=useAtom(empresaAtom);
    const[archivos,setArchivos]=useState([]);
    const[loading,setLoading]=useState(false);    
    let history=useHistory();    

    useEffect(()=>{
    //     const cargarDatos =  async() =>{           
    //         const _empresas= await UsuarioService.buscarPorId(getUsuarioAutenticado().id) ;
    //         setEmpresas(_empresas.empresas);
    //     }

    //    cargarDatos();
    },[])

    const props = {
        onRemove: (file) => {
          setArchivos([]);
          return {
            archivos: [],
          };
        },
        beforeUpload: (file) => {
            setArchivos(file);
            return false;
        },
        archivos,       
      };
    // const onSelectEmpresa=(string,option)=>{
    //     setEmpresa(empresas.filter(empresa=>empresa.id===option.value));
       
    // }  

    const handleOnClickSubirFirmaHolografa =async(firma) =>{
       
        try {
            setLoading(true);
            const firmaHolografaEnviada={                
                empresa:{
                    id:getUsuarioAutenticado().empresaSeleccionada.id,
                    razonSocial:getUsuarioAutenticado().empresaSeleccionada.razonSocial,
                },
                usuarioId:getUsuarioAutenticado().id,
               
            }
            
            const formData = new FormData();
            formData.append("archivo", firma.archivo.file);
            formData.append("firmaHolografaEnviada",new Blob([JSON.stringify(firmaHolografaEnviada)], {
                type: "application/json",  }));
            await FirmaHolografaService.insertar(formData) 
            notification.success({
                message: "Operación exitosa",
                description: "Se registró la firma hológrafa correctamente.",
            });    
            setLoading(false);
            history.push("/configuracion/firmas-holografas/listar");
        } catch (error) {
            setLoading(false);
            notification.error({
                message: "No se puede registrar la firma hológrafa",
                description: error.response.data.mensaje,
              });
            
        }

    }

 return(
    <Form
    labelCol={{span:4,offset:10}}
    wrapperCol={{span:4,offset:10}}
     layout="vertical"
     onFinish={handleOnClickSubirFirmaHolografa}
        
    >
        {/* <Form.Item
           label="Empresa"
           name={["empresa","id"]}
           rules={[
                {
                    required: true,
                    message: "Debe seleccionar la empresa.",
                },
            ]}
        >
            <Select onSelect={onSelectEmpresa}>
                {empresas.length>0 &&(
                    empresas.map((empresa)=>
                     <Option value={empresa.id} key={empresa.id}>
                         {empresa.razonSocial}
                     </Option>
                    )                   

                )}                

            </Select>
        </Form.Item> */}
        <Form.Item           
           label="Firma Hológrafa"
           name="archivo"
           valuePropName="archivo"           
           rules={[
            {
                 required: true,                
                validator: (_, value) =>
                   archivos.length!==0 ? Promise.resolve() : Promise.reject(new Error("No ha seleccionado la Firma.")),
            },
        ]}
        >
            <Upload {...props}  maxCount={1}>
                <Button icon={<UploadOutlined />}>
                    Seleccionar Firma Hológrafa
                </Button>
            </Upload>

        </Form.Item>
        <Form.Item style={{ textAlign: "center" }}>
            <Button
               type="primary"
               icon={<CheckOutlined />}
               block
               htmlType="submit"
               loading={loading}
            > 
                Guardar Firma Hológrafa
            </Button>
        </Form.Item>

    </Form>
 );
}
export default FirmaHolografaRegistro;