import { BaseService } from "./BaseService";

const HistorialColaboradorService={
    async listarHistorialCodigos(empresaId,colaboradorId) {
        const respuesta = await BaseService.get("/historialColaborador", {
          params: {
            empresaId: empresaId,
            colaboradorId:colaboradorId,
          },
        });
        return respuesta.data;
      },

}
export default HistorialColaboradorService;