import React, { useContext, useEffect, useState } from "react";
import { FileTextOutlined, SoundOutlined, FileDoneOutlined, CaretDownOutlined, PlusOutlined } from "@ant-design/icons";
import { Menu, Button, Dropdown, Space, Form, DatePicker, Select, Input } from "antd";
import { useParams } from "react-router";
import { OnboardingColaboradorService } from "services/OnboardingColaboradorService";
import { SecurityContext } from "context/SecurityContextProvider";
import { detallesOnboardingAtom, } from "../DetalleSeguimientoOnboardingStorage";
import { useAtom } from "jotai";
import TipoDocumentoService from "services/TipoDocumentoService";
import { EstadoVistaColaborador } from "enums/EstadoVistaColaborador";

const DetalleFiltro = () => {
  const { envioId } = useParams();
  const [formFiltro] = Form.useForm();
  const [tiposDocumentos, setTiposDocumentos] = useState([]);
  const [, setDetallesOnboarding] = useAtom(detallesOnboardingAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);

  useEffect(() => {
    const CargarDocumentos = async () => {
      const _tipoDocumentos = await TipoDocumentoService.listarPorEmpresaYCategoria(
        getUsuarioAutenticado().empresaSeleccionada.id,
        "FORMULARIOS"
      );
      setTiposDocumentos(_tipoDocumentos);
    };
    CargarDocumentos();
  }, []);

  const onClickFiltrar = async () => {
    const _documentos = await OnboardingColaboradorService.listarDetalleOnboarding(
      envioId,
      formFiltro.getFieldValue("tipoDocumentoNombre"),
      formFiltro.getFieldValue("estado"),
      getUsuarioAutenticado().login
    );
    setDetallesOnboarding(_documentos.detalles);
  };

  return (
    <Form layout="inline" form={formFiltro}>
      <Form.Item label="Tipo Documento" name={"tipoDocumentoNombre"}>
        <Select style={{ width: "250px" }} allowClear showSearch placeholder="Ingrese el nombre del colaborador">
          {tiposDocumentos.length > 0 &&
            tiposDocumentos?.map((documentos) => (
              <Select.Option key={documentos.id} value={documentos.nombre}>
                {documentos.nombre}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="Estado" name="estado">
        <Select style={{ width: "250px" }} allowClear showSearch placeholder="Seleccione el estado del documento">
          <Select.Option key={EstadoVistaColaborador.FIRMADO} value={EstadoVistaColaborador.FIRMADO}>
            {EstadoVistaColaborador.FIRMADO}
          </Select.Option>
          <Select.Option key={EstadoVistaColaborador.PENDIENTE} value={EstadoVistaColaborador.PENDIENTE}>
            {EstadoVistaColaborador.PENDIENTE}
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button onClick={onClickFiltrar}>Filtrar</Button>
      </Form.Item>
    </Form>
  );
};

export default DetalleFiltro;
