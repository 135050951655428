import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const Botones =()=>{
    let history=useHistory();

    const onClickCrearFirmaHolografa=()=>{
        history.push("/configuracion/firmas-holografas/crear");
    }
    return(
        <Button 
            type="primary"
            icon={<PlusOutlined/>}
            onClick={onClickCrearFirmaHolografa}
        >
            Crear Firma Hológrafa
        </Button>
    )

}
export default Botones;