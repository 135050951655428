import React from "react";
import { Container } from "components/Container";
import { TablaFormularios } from "./tabla-formularios";
import { Botones } from "./botones";

const ListarFormulariosPage = () => {
  return (
    <Container titulo="Tipos Documento" botonExtra={<Botones />}>
      <TablaFormularios />
    </Container>
  );
};

export default ListarFormulariosPage;
