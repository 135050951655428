import React, { useContext, useEffect, useState } from "react";
import { Container } from "components/Container";
import { Alertas } from "./alertas";
import { TablaContratosCargados } from "./tabla-contratos-cargados";
import { Botones } from "./botones";
import { LoadingOutlined } from "@ant-design/icons";
import { ModalConfirmar } from "components/Modal/ModalConfirmar";
import { useAtom } from "jotai";
import * as atoms from "store/local/contratos/DetalleSeguimientoContratoStore";
import { SecurityContext } from "context/SecurityContextProvider";
import { useModalConfirmar } from "store/global";
import { ContratoService } from "services/ContratoService";
import { ModalVisorPdf } from "components/Modal/ModalVisorPdf";
import Bowser from "bowser";
import Axios from "axios";
import { Alert, Spin } from "antd";
import { EstadoCertificadoDigital } from "enums/EstadoCertificadoDigital";
import Text from "antd/lib/typography/Text";
import CertificadoService from "services/CertificadoService";

const ListaContratosSinNotificar = ({ envio, setEnvio }) => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const cerrarModal = useModalConfirmar((state) => state.cerrarModal);
  const [loading, setLoading] = useState(false);
  const [parametrosEnvio, setParametros] = useState();
  const [mostrarAlerta, setMostrarAlerta] = useState(false);

  const [estado, setEstado] = useState("VIGENTE");

  useEffect(() => {
    const cargarDatos = async () => {
      setLoading(true);
      const browser = Bowser.parse(window.navigator.userAgent);
      //const res = await Axios.get("https://geolocation-db.com/json/");
      const parametros = {
        navegador: browser.browser.name,
        navegadorVersion: browser.browser.version,
        os: browser.os.name,
        osVersion: browser.os.versionName,
        //ipEnvio: res.data.IPv4,
      };
      setParametros(parametros);

      const estado = await CertificadoService.validarCertificadoEstado(
        getUsuarioAutenticado().empresaSeleccionada.id,
        null,
        envio.id
      );
      setMostrarAlerta(estado === "EXPIRADO" || estado === "SIN_CERTIFICADO");
      setEstado(estado);
      setLoading(false);
    };
    cargarDatos();
  }, []);

  const onClickBtnConfirmarModal = async () => {
    try {
      setLoading(true);

      const _envio = await ContratoService.notificar(
        getUsuarioAutenticado().empresaSeleccionada.id,
        envio.id,
        getUsuarioAutenticado().id,
        parametrosEnvio
      );
      //1. Cuando se actualiza el estado "envio", se refrescará el componente para mostrar la lista de contratos cargados
      //2. Al recargarse el componente, el componente actual se desmontará
      //3. Al desmontarse ya no es necesario agregar: setLoading(false) y cerrarModal();
      setEnvio(_envio.data);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const onClickBtnCancelarModal = () => {
    cerrarModal();
  };

  return (
    <Spin spinning={loading} indicator={<LoadingOutlined style={{ fontSize: 28 }} />} tip="Cargando...">
      <Container titulo="Detalle Contrato Sin Notificar">
        {mostrarAlerta && (
          <div style={{ marginBottom: "10px" }}>
            <Alert
              message={
                estado === EstadoCertificadoDigital.EXPIRADO ? (
                  <Text>No puede cargar este documento porque su certificado digital se encuentra expirado. </Text>
                ) : (
                  <Text>No hay certificado registrado para está empresa</Text>
                )
              }
              type="warning"
              showIcon
            />
          </div>
        )}

        <Botones disabled={mostrarAlerta} envio={envio} setEnvio={setEnvio} />

        <Alertas envio={envio} />

        <TablaContratosCargados envio={envio} />

        <ModalConfirmar
          loading={loading}
          onClickBtnCancelar={onClickBtnCancelarModal}
          onClickBtnConfirmar={onClickBtnConfirmarModal}
        />

        <ModalVisorPdf />
      </Container>
    </Spin>
  );
};

export default ListaContratosSinNotificar;
