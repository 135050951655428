import { atom } from "jotai";

export const visibleModalFiltrarAtom = atom(false);
export const visibleModalRolAtom = atom(false);
export const empresasAgregadasAtom = atom([]);
export const empresasEnviadasAtom = atom([]);
export const empresaSeleccionadaAtom = atom(undefined);

export const opcionesAtom = atom([]);
export const categoriasAtom = atom([]);
export const opcionesSeleccionadasAtom = atom([]);
