import { BaseService } from "./BaseService";

const ReporteService = {
  async listar(empresaId) {
    const respuesta = await BaseService.get("/reporte");
    return respuesta.data;
  },
  async insertar(reporte) {
    const respuesta = await BaseService.post("/reporte", reporte);
    return respuesta.data;
  },
  async descargarExcel(parametros) {
    const respuesta = await BaseService.post("/reporte/descargarExcel", parametros, {
      responseType: "blob",
    });
    return respuesta;
  },
  async obtenerUrl(reporteId) {
    const respuesta = await BaseService.get(`/reporte/obtener-url/${reporteId}`);
    return respuesta.data;
  },
};
export default ReporteService;
