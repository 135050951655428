import { useAtom } from "jotai";
import {dataColaboradorAtom, procesoIdSeleccionadoAtom} from "../DocumentosProcesoListarStore";
import { PostulantesService } from "services/reclutamiento-y-seleccion";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";

const useCargarDashboardData=()=>{
    const [dashboard, setDashboard] = useAtom(dataColaboradorAtom);
  const { procesoId } = useParams();
  useEffect(() => {
    const cargarDatos = async () => {
        const respuesta = await PostulantesService.obtenerInformacionDashboard(procesoId);
        //console.log(respuesta)
        setDashboard(respuesta);
    };

    if(procesoId)
        cargarDatos();
  }, []);

  return { dashboard };
};
export default useCargarDashboardData;