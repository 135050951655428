import React from "react";
import { Route } from "react-router-dom";
import { PostulantesListarPage } from "pages/seleccion";
import { PlantillaCrearPage, PlantillaListarPage, PlantillaEditarPage } from "pages/seleccion";
import { ProcesosCrearPage, ProcesosListarPage, DocumentosProcesoListarPage } from "pages/seleccion/procesos";
import {
  ConvocatoriaListarPage,
  ConvocatoriaCrearPage,
  ConvocatoriaEditarPage,
  AgregarPostulantePage,
} from "pages/seleccion/convocatorias";
import { ColaboradorListarPage } from "pages/colaborador/listar";
import { AgregarDocumentoPage } from "pages/seleccion/procesos/agregarDocumento";
import { SeguimientoSolicitudesPersonalPage } from "pages/seleccion";
import { DetalleSolicitudPersonalPage } from "pages/seleccion";
import {
  CrearPerfilSolicitudPersonalPage,
  EditarPerfilSolicitudPersonalPage,
  ListarPerfilSolicitudPersonalPage,
} from "pages/solicitud-personal";
import { SeguimientoEntrevistasPage } from "pages/seleccion/entrevistas";
import { ReporteSolicitudPersonalPage } from "pages/seleccion/reportes";
export function RutasReclutamientoSeleccion() {
  return (
    <React.Fragment>
      <Route exact path="/reclutamiento-y-seleccion/plantillas/crear" component={PlantillaCrearPage} />
      <Route exact path="/reclutamiento-y-seleccion/plantillas" component={PlantillaListarPage} />
      <Route exact path="/reclutamiento-y-seleccion/plantillas/:plantillaId/editar" component={PlantillaEditarPage} />
      <RutasSolicitudPersonal />
      <RutasProcesos />
      <RutasConvocatorias />
      <RutasSeguimientoSolicitudPersonal />
      <RutasSeguimientoEntrevistas/>
      <RutasReportes/>
    </React.Fragment>
  );
}

const RutasSolicitudPersonal = () => {
  return (
    <React.Fragment>
      <Route
        exact
        path="/reclutamiento-y-seleccion/solicitud-personal/perfiles"
        component={ListarPerfilSolicitudPersonalPage}
      />
      <Route
        exact
        path="/reclutamiento-y-seleccion/solicitud-personal/perfiles/crear"
        component={CrearPerfilSolicitudPersonalPage}
      />
      <Route
        exact
        path="/reclutamiento-y-seleccion/solicitud-personal/perfiles/editar/:perfilId"
        component={EditarPerfilSolicitudPersonalPage}
      />
    </React.Fragment>
  );
};

const RutasProcesos = () => {
  return (
    <React.Fragment>
      <Route exact path="/reclutamiento-y-seleccion/procesos" component={ProcesosListarPage} />
      <Route exact path="/reclutamiento-y-seleccion/procesos/crear" component={ProcesosCrearPage} />
      <Route exact path="/reclutamiento-y-seleccion/procesos/agregar/:procesoId" component={AgregarDocumentoPage} />
      <Route
        exact
        path="/reclutamiento-y-seleccion/procesos/:procesoId/documentos"
        component={DocumentosProcesoListarPage}
      />
    </React.Fragment>
  );
};

const RutasConvocatorias = () => {
  return (
    <React.Fragment>
      <Route exact path="/reclutamiento-y-seleccion/convocatorias" component={ConvocatoriaListarPage} />
      <Route
        exact
        path="/reclutamiento-y-seleccion/convocatorias/crear/:solicitudPersonalId/:desde"
        component={ConvocatoriaCrearPage}
      />
      <Route
        exact
        path="/reclutamiento-y-seleccion/convocatorias/:convocatoriaId/editar"
        component={ConvocatoriaEditarPage}
      />
      <Route
        exact
        path="/reclutamiento-y-seleccion/convocatorias/:convocatoriaId/agregar-postulante"
        component={AgregarPostulantePage}
      />
    </React.Fragment>
  );
};
const RutasSeguimientoSolicitudPersonal = () => {
  return (
    <React.Fragment>
      <Route
        exact
        path="/reclutamiento-y-seleccion/solicitud-personal/solicitudes"
        component={SeguimientoSolicitudesPersonalPage}
      />
      <Route
        exact
        path="/reclutamiento-y-seleccion/solicitud-personal/detalleSolicitud/:solicitudId"
        component={DetalleSolicitudPersonalPage}
      />
    </React.Fragment>
  );
};

const RutasSeguimientoEntrevistas = () =>{
  return(
    <React.Fragment>
       <Route
        exact
        path="/reclutamiento-y-seleccion/solicitud-personal/entrevistas"
        component={SeguimientoEntrevistasPage}
      />
    </React.Fragment>
  )
}

const RutasReportes=()=>{
  return(
    <React.Fragment>
      <Route
       exact
       path="/reclutamiento-y-seleccion/solicitud-personal/reportes"
       component={ReporteSolicitudPersonalPage}
      />
    </React.Fragment>
  )
}
