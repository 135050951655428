import React, { useContext, useEffect, useState } from "react";
import { Table } from "antd";
import * as atoms from "../ListarTipoDocumentosStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import  TipoDocumentoService from "services/TipoDocumentoService";
import CertificadoService from "services/CertificadoService";
import { MenuAcciones } from "./MenuAcciones";

const TablaTipoDocumentos = () =>{
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [loading, setLoading] = useState(false);
    const [tipoDocumentos, setTipoDocumentos] = useAtom(atoms.tipoDocumentos);
    
    useEffect(()=>{
        const fetch = async ()=>{
            try {
             setLoading(true);                    
             const _tipoDocumentos = await TipoDocumentoService.listarPorEmpresa(getUsuarioAutenticado().empresaSeleccionada.id);
             setTipoDocumentos(_tipoDocumentos);             
             
            } catch (error) {
                console.error(error);
                
            } finally{
                setLoading(false);
            }


        }
        fetch(); 
    },[]);   

    const columnas=[
        {
            title:"Categoría",
            dataIndex:"categoriaDocumento",
            align:"center",
            //width:'70%'

        },
        {
            title:"Nombre",
            dataIndex:"nombre",
            align:"center",
           // width:'70%'

        },
        {
            title:"Formato",
            dataIndex:"formato",
            align:"center",
            

        },
        {
            title:"Flujo",
            dataIndex:"flujo",
            align:"center",
            
        },
        {
            title:"Certificado",
            dataIndex:"certificadoDigitalRepresentante",
            align:"center",
                  
        },
        {
            title:"Requiere Firma colaborador",
            dataIndex:"firmaColaboradorRequerido",
            align:"center",  
            render:(text,fila)=>{              
                    if (fila.firmaColaboradorRequerido)
                    return("Si")
                    else
                    return("No")               

            }          
        },
        {
            title:"Acciones",
            key:"acciones",
            align:"center",
            render:(fila)=>
              <MenuAcciones 
              tipoDocumento={fila}
            //   tipoDocumentos={tipoDocumentos}
            //   setTipoDocumentos={setTipoDocumentos}
               />
            
        }

    ];
    return(
        <Table
            loading={loading}
            rowKey={(tipoDocumento)=>tipoDocumento.id}
            columns={columnas}
            dataSource={tipoDocumentos}
        />
    )
}
export default TablaTipoDocumentos;
