import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";
import qs from "qs";

const URL_API = ApiUrl.ASISTENCIA;

const HorarioService = {
  async insertar(horario) {
    const respuesta = await ApiService(URL_API).post("/horarios", horario);
    return respuesta.data;
  },
  async listar() {
    const respuesta = await ApiService(URL_API).get("/horarios");
    return respuesta.data;
  }
};

export { HorarioService };
