import React from "react";
import { Container } from "components/Container";
import { ModalCancelarSolicitud, TablaSeguimientoSolicitudPersonal } from "./componentes";
import { UnorderedListOutlined } from "@ant-design/icons";
import { BotonExportar } from "./componentes/BotonExportar";
import { SpinLoading } from "components/SpinLoading";
import { useAtom } from "jotai";
import { loadingSolicitudAtom } from "./SeguimientoSolicitudesPersonalStore";

const SeguimientoSolicitudesPersonalPage = () => {
  const [loading,] = useAtom(loadingSolicitudAtom);
  return (
    <SpinLoading loadingPage={loading}>
      <Container titulo="Seguimiento solicitudes" icono={<UnorderedListOutlined />} botonExtra={<BotonExportar />}>
          <TablaSeguimientoSolicitudPersonal />
          <ModalCancelarSolicitud />
      </Container>
    </SpinLoading>
  );
};
export default SeguimientoSolicitudesPersonalPage;
