import React, { useEffect, useState } from "react";
import { DatePicker,Typography,Space,Radio,Form } from "antd";
import { Column } from "@ant-design/plots";
import {ReporteColaboradoresCesadosService} from "services/reclutamiento-y-seleccion";
import moment from "moment";
import { useForm } from "antd/lib/form/Form";

const ReporteCesadosPorMotivoCese = () =>{

    const [data, setData] = useState([]);
    const [anio,setAnio] = useState(moment().year());
    const [mes,setMes] =useState(null);
    const [valorFiltro,setValorFiltro] = useState("anual");
    const [formAnio] =Form.useForm();
    const [formMes] =Form.useForm();

    useEffect(()=>{     
        ReporteColaboradoresCesadosService.getTasaCesadosPorMotivoCese({ anio: anio ,mes:mes})
        .then((response) => {
          setData(response.data);
         
        })
        .catch((error) => console.log("error", error));
       //console.log("información...");   

    },[anio,mes]);

    const onChangeAnio=(date, dateString)=>{           
        setAnio(parseInt(dateString,10));
      }

    const onChangeMensual = (date,dateString) =>{
        setMes(dateString);
    }
    const onchangeRadioGroup = (e) =>{
     
      setValorFiltro(e.target.value);

       if(e.target.value==="anual"){
          formMes.setFieldsValue({
            mes:moment()
          })         
          setAnio(moment().year());
          setMes(null);
       }else{
          formAnio.setFieldsValue({
            anio:moment()
          });
         
          setMes(moment().format("YYYY-MM"));
          setAnio(0) ;
        }      

    }
    const config = {
        data,        
        xField: "motivoCese",
        yField: "porcentajeCesados",   
        columnWidthRatio: 0.2,        
        label: {           
            position: 'middle',           
            style: {
              fill: '#FFFFFF',
              opacity: 0.6,
            },
          },
        yAxis: {
           tickInterval: 10,
           
         },
        
      
      };     

    return(
        <React.Fragment>
            <Space direction="vertical">
              <Radio.Group onChange={onchangeRadioGroup} value={valorFiltro}>
                <Space >
                <Radio value={"anual"}>Anual</Radio>
                <Radio value={"mensual"}>Mensual</Radio>
                </Space>
                
              </Radio.Group>
              <Space>
                <Form form={formAnio}>
                  <Form.Item label="Año" name="anio">
                    <DatePicker
                        defaultValue={moment()} 
                        onChange={onChangeAnio} 
                        picker="year" 
                        allowClear={false}
                        disabled={valorFiltro==="anual"?false:true}
                        />
                  </Form.Item>
                </Form>                 
               
                 <Form form={formMes}>
                  <Form.Item label="Mes" name="mes">
                    <DatePicker 
                        defaultValue={moment()} 
                        onChange={onChangeMensual} 
                        picker="month" 
                        allowClear={false}
                        disabled={valorFiltro==="mensual" ?false:true}
                        />
                  </Form.Item>
                 </Form>                 
                
              </Space>
            </Space> 
            <br/>
            <br/>
            <Column {...config} />
        </React.Fragment>
    )

}
export default ReporteCesadosPorMotivoCese;