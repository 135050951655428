import React from "react";
import { Container } from "components/Container"
import {TablaSeccion} from "./tabla-seccion";
import {Botones} from "./botones"
import {UnorderedListOutlined} from "@ant-design/icons";
const ListarSeccionPage=()=>{
    return(

        <Container icono={<UnorderedListOutlined />} titulo="Sección" botonExtra={<Botones/>}  >
             <TablaSeccion/>             
        </Container>
    )

}
export default ListarSeccionPage;