import React from "react";
import { Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { LoginService } from "services/LoginService";
import { useParams } from "react-router-dom";
import { Headers } from "enums";
import { useAtom } from "jotai";
import { formularioAtom } from "../../DisenarFormularioPdfStore";

const SubirPdf = () => {
  const { formularioId } = useParams();
  const [, setFormulario] = useAtom(formularioAtom);
  const { Dragger } = Upload;

  const props = {
    name: "file",
    multiple: false,
    action: process.env.REACT_APP_ENDPOINT + "formulario/subir-plantilla-pdf",
    headers: {
      Authorization: "Bearer " + LoginService.getJwtToken(),
      [Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
      [Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
    },
    data: {
      formulario: new Blob(
        [
          JSON.stringify({
            formularioId: formularioId,
          }),
        ],
        { type: "application/json" }
      ),
    },
    onChange(info) {
      const { status } = info.file;
      if (status === "done") {
        setFormulario(info.file.response);
        message.success(`${info.file.name} se subió exitosamente.`);
      } else if (status === "error") {
        message.error(`Ocurrió un error al subir el documento ${info.file.name}.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <div
      style={{
        width: "85%",
        maxWidth: "85%",
        height: "calc(100% - 96px)",
        padding: "10px 10px",
      }}
    >
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text">Clic aquí o arrastra un archivo aquí</p>
        <p className="ant-upload-hint">Selecciona el archivo PDF para construir tu formulario PDF</p>
      </Dragger>
    </div>
  );
};

export default SubirPdf;
