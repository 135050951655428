import React, { useContext, useState } from "react";
import { Container } from "components/Container";
import { useHistory } from "react-router";
import * as atoms from "./EditarCarpetaLegajoStore";
import { useAtom } from "jotai";
import { useModalConfirmar } from "store/global";
import { ModalConfirmar } from "components/Modal/ModalConfirmar";
import {CarpetaLegajoEditar} from "./carpeta-legajo-editar";
import CarpetaService from "../../../../services/CarpetaService";
import{notification} from "antd";
import { SecurityContext } from "context/SecurityContextProvider";

const EditarCarpetaLegajoPage=()=>{
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const cerrarModalConfirmacion = useModalConfirmar(state => state.cerrarModal);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [carpetasLegajos,] = useAtom(atoms.carpetasLegajos);

    const onClickRetonarListarTipoDocumentos=()=>{
        history.push("/configuracion/carpetas-legajos/listar");
    }
    const onClickBtnCancelarModal=()=>{

        cerrarModalConfirmacion();
    }
    const onClickBtnConfirmarModal = async()=>{
        try {
            setLoading(true);
            console.log(carpetasLegajos)
            const _carpeta= await CarpetaService.actualizar(carpetasLegajos);            
            cerrarModalConfirmacion();
            history.push("/configuracion/carpetas-legajos/listar");
            
        } catch (error) {
            console.error(error);
            notification.error({
                message: "No se puede actualizar la carpeta",
                description: error.response.data.mensaje,
        
              });
              cerrarModalConfirmacion();
        }finally{
            setLoading(false);
        }
    }
    return(
        <Container titulo="Actualizar Carpeta" onClickRetornar={onClickRetonarListarTipoDocumentos}>
            <CarpetaLegajoEditar/>
            <ModalConfirmar
                loading={loading}
                mensaje="¿Está seguro de actualizar la Carpeta? "
                onClickBtnCancelar={onClickBtnCancelarModal}
                onClickBtnConfirmar={onClickBtnConfirmarModal} />

        </Container>
    )

}
export default EditarCarpetaLegajoPage;