import React, { useState, useEffect } from "react";
import {
  Modal,
  Upload,
  message,
  Button,
  notification,
  Space,
  Typography,
  Tag,
  Divider,
  Row,
  Checkbox,
  Col,
  Badge,
} from "antd";
import { FileExcelOutlined, CheckCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { LoginService } from "services/LoginService";
import { useHistory, useParams } from "react-router-dom";
import ColaboradorService from "../../../services/ColaboradorService";
import PlantillaArchivoService from "../../../services/PlantillaArchivoService";
import { saveAs } from "file-saver";
import { FormatoCarga } from "enums/FormatoCarga";

export default function ModalCargaMasiva({ visibleModal, onCerrarModal, etiquetaColaboradores }) {
  const { Text, Paragraph } = Typography;
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [colaboradoresNuevos, setColaboradoresNuevos] = useState([]);
  const [colaboradoresActualizados, setColaboradoresActualizados] = useState([]);
  const [listaDocumentos, setListaDocumentos] = useState([]);
  const [colaboradorNoProcesados, setColaboradorNoProcesados] = useState([]);
  const [rol, setRol] = useState("");
  const [etiqueta, setEtiqueta] = useState(false);
  const [reemplazarCorreos, setReemplazarCorreos] = useState(false);
  const [urlExcelObservaciones, setUrlExcelObservaciones] = useState();
  const [colaboradoresObservados, setColaboradoresObservados] = useState([]);
  const [totalColaboradores, setTotalColaboradores] = useState(undefined);
  const [visibleDescargaModelo, setVisibleDescargaModelo] = useState(true);
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  useEffect(() => {
    setColaboradoresNuevos(0);
    setColaboradoresActualizados(0);
    setColaboradorNoProcesados(0);
    setColaboradoresObservados(0);
  }, []);

  const datos = {
    organizacionId: getUsuarioAutenticado().organizacionId,
    subdominio: getUsuarioAutenticado().subdominio,
    empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
    empresaRazonSocial: getUsuarioAutenticado().empresaSeleccionada.razonSocial,
    empresaUrlImagenLogotipo: getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo,
    reemplazarCorreos: reemplazarCorreos,
  };
  const props = {
    onRemove: (file) => {
      setListaDocumentos([]);
      borrarDatos();
      return {
        listaDocumentos: [],
      };
    },
    beforeUpload: (file) => {
      if (!FormatoCarga.EXCEL.extension.includes(file.type)) {
        message.error(`${file.name} no es un archivo Excel`);
      } else {
        setListaDocumentos(file);
        setVisibleDescargaModelo(false);
        return false;
      }
    },
    listaDocumentos,
  };
  async function handleOnClickDescargarPlantilla() {
    //let respuesta = await ColaboradorService.descargarExcel();
    const formData = new FormData();
    formData.append("empresaId", getUsuarioAutenticado().empresaSeleccionada.id);
    formData.append("modeloArchivoId", "26d9c487-db52-47ab-becd-9692f82f4c09");

    let respuesta = await PlantillaArchivoService.descargarExcel(formData);
    const filename = respuesta.headers.filename;

    saveAs(respuesta.data, filename);
  }
  function borrarDatos() {
    setColaboradoresNuevos(0);
    setColaboradoresActualizados(0);
    setColaboradorNoProcesados(0);
    setEtiqueta(false);
    setListaDocumentos([]);
    setReemplazarCorreos(false);
    setVisibleDescargaModelo(true);
    setColaboradoresObservados(0);
    setTotalColaboradores(undefined);
  }

  const onCheckReemplazarEnvios = (e) => {
    setReemplazarCorreos(e.target.checked);
  };
  const onClickDescargarPlantilla = async () => {
    try {
      let respuesta = await ColaboradorService.descargarPlantillaExcel(getUsuarioAutenticado().empresaSeleccionada.id);
      const filename = respuesta.headers.filename;
      saveAs(respuesta.data, filename);
    } catch (error) {
      notification.error({
        message: "No se puede descargar plantilla de Sedes",
      });
    }
  };
  const cargarColaboradores = async () => {
    
    if (listaDocumentos.name) {
      try {
        setLoading(true);
        const datosColaborador = {
          organizacionId: getUsuarioAutenticado().organizacionId,
          subdominio: getUsuarioAutenticado().subdominio,
          empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
          empresaRazonSocial: getUsuarioAutenticado().empresaSeleccionada.razonSocial,
          empresaUrlImagenLogotipo: getUsuarioAutenticado().empresaSeleccionada.urlImagenLogotipo,
          reemplazarCorreos: reemplazarCorreos,
          vinculoLaboral: "TRABAJADOR",
          usuarioRegistro: getUsuarioAutenticado().nombreCompleto,
          usuarioRegistroLogin:getUsuarioAutenticado().login
        };
        const formData = new FormData();
        //console.log("archivo",listaDocumentos);
        formData.append("file", listaDocumentos);
        formData.append(
          "datosColaborador",
          new Blob([JSON.stringify(datosColaborador)], {
            type: "application/json",
          })
        );
        const respuesta = await ColaboradorService.cargarMasivamente(formData);
        if (respuesta.colaboradoresObservados === 0) {
          message.success(`${listaDocumentos.name} Archivo subido correctamente`);
        }
        setColaboradoresNuevos(respuesta.colaboradoresNuevos);
        setColaboradoresActualizados(respuesta.colaboradoresActualizados);
        setColaboradorNoProcesados(respuesta.colaboradorNoProcesados);
        setUrlExcelObservaciones(respuesta.urlExcelObservaciones);
        setColaboradoresObservados(respuesta.colaboradoresObservados);
        setTotalColaboradores(respuesta.totalColaboradores);
        setEtiqueta(true);
      } catch (error) {
        setLoading(false);
        notification.error({
          message: "Alerta!",
          description: error.response.data.mensaje,
        });
      } finally {
        setLoading(false);
      }
    } else {
      message.error("No ha seleccionado el archivo excel");
    }
  };

  return (
    <Modal
      title="Importar colaboradores desde excel"
      visible={visibleModal}
      width={750}
      //centered
      onCancel={onCerrarModal}
      afterClose={borrarDatos}
      destroyOnClose={true}
      /*okButtonProps={{
        style: { display: "none" },
      }}*/
      footer={null}
    >
      <div>
        <Row>
          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {/* <Upload {...props} fileList={listaDocumentos}> */}
            <Upload {...props} maxCount={1} accept={FormatoCarga.EXCEL.extension}>
              <Button style={{ borderRadius: 4, borderColor: "green" }}>
                <FileExcelOutlined style={{ fontSize: 20, color: "green" }} />{" "}
                <Text style={{ color: "green" }}>Cargar archivo Excel</Text>
              </Button>
            </Upload>
          </div>
        </Row>
        <Row style={{ justifyContent: "center", margin: "12px 0px" }}>
          <Checkbox onChange={onCheckReemplazarEnvios} checked={reemplazarCorreos}>
            ¿Reemplazar correos en los envíos realizados?{" "}
          </Checkbox>
        </Row>
        {/* {
          visibleDescargaModelo &&(
            <Row>
          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            Descargar el modelo para la importación de Colaboradores{" "}
            <a
              style={{ color: "#0F7FF0", fontStyle: "italic" }}
              onClick={onClickDescargarPlantilla}
            >
              aquí
            </a>
          </div>
        </Row>
          )
        } */}

        <Row style={{ justifyContent: "center", margin: "12px 0px" }}>
          <Button type="primary" onClick={cargarColaboradores} loading={loading}>
            <UploadOutlined />
            Iniciar Carga
          </Button>
        </Row>
      </div>
      {/* <br /> */}
      {colaboradoresObservados > 0 && (
        <Row justify="center">
          <Text strong type="danger">
            Se encontraron observaciones en el archivo cargado,ver{" "}
            <a style={{ color: "#0F7FF0", fontStyle: "italic" }} href={urlExcelObservaciones}>
              aquí
            </a>
          </Text>
        </Row>
      )}
      <br />
      {etiqueta && totalColaboradores > 0 ? (
        <div>
          <Row justify="center">
            <Space size={60}>
              {/*<Tag color="success">*/}

              <Text style={{ fontSize: 12 }}>
                <CheckCircleOutlined style={{ color: "#329BE9 " }} /> Colaboradores Nuevos: {colaboradoresNuevos}
              </Text>

              {/* </Tag> */}
              {/* <Tag color="processing"> */}

              <Text style={{ fontSize: 12 }}>
                <CheckCircleOutlined style={{ color: "#D89817" }} /> Colaboradores actualizados:{" "}
                {colaboradoresActualizados}
              </Text>
              {/* </Tag> */}
              {/* <Tag color="error"> */}

              <Text style={{ fontSize: 12 }}>
                <CheckCircleOutlined style={{ color: "#DD0C3F" }} /> Colaboradores no procesados:{" "}
                {colaboradorNoProcesados}
              </Text>

              {/* </Tag> */}
            </Space>
          </Row>
        </div>
      ) : (
        <div></div>
      )}

      {totalColaboradores === 0 && (
        <Row justify="center">
          <Text type="danger">El archivo no tiene colaboradores</Text>
        </Row>
      )}

      <Divider />
      <div style={{ textAlign: "center" }}>
        {/* <Button
          icon={<DownloadOutlined />}
          onClick={handleOnClickDescargarPlantilla}
          style={{ backgroundColor: "#06A428", color: "white" }}
        >
          Descargar Plantilla Colaborador
        </Button> */}
      </div>
    </Modal>
  );
}
