import React, { useContext, useEffect, useState } from "react";
import { Input } from "antd";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import * as atoms from "../../ConsultarLegajoColaboradorStore";
import LegajoService from "../../../../../services/LegajoService";

const InputBusquedaColaborador = () => {
  const { Search } = Input;
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [filtro, setFiltro] = useAtom(atoms.filtro);
  const [loading, setLoading] = useAtom(atoms.loadingColaboradores);
  const [listaColaboradores, setListaColaboradores] = useState([]);

  const onSearch = async (valor) => {
    setFiltro(valor.trim());
  };

  return (
    <Search
      placeholder="Buscar por Nombre ó DNI ó Código"
      allowClear
      loading={loading}
      onSearch={onSearch}
      enterButton
    />
  );
};
export default InputBusquedaColaborador;
