import React, { useEffect, useContext, useState } from "react";
import { EstadoDocumento } from "enums/EstadoDocumento";
import { EstadoRegistro } from "enums/EstadoRegistro";
import { Table, Tag } from "antd";
import { EtiquetaEstadoNotificacion } from "./etiqueta-estado-notificacion";
import { EtiquetaVisualizacion } from "./etiqueta-visualizacion";
import { EtiquetaFirmaColaborador } from "./etiqueta-firma-colaborador";
import { MenuAcciones } from "./menu-acciones"
import { SecurityContext } from "context/SecurityContextProvider";
import { useAtom } from "jotai";
import { FormularioService } from "../../../../../../services/FormularioService";
import * as atoms from "store/local/formularios/DetalleSeguimientoFormularioStore";
//import * as atoms from "../../DetalleSeguimientoFormularioStore";
import { CheckCircleOutlined, SyncOutlined, MinusCircleOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
//import ModalActualizarCorreo from "./modal-actualizar-correo/ModalActualizarCorreo";

const TablaFormularios = () => {

    const { getOrganizacion, getUsuarioAutenticado } = useContext(SecurityContext);

    const [loading, setLoading] = useState(false);

    const [envio] = useAtom(atoms.envio);

    const [formularios, setFormularios] = useAtom(atoms.formularios);

    const [formulariosSeleccionados, setFormulariosSeleccionados] = useAtom(atoms.formulariosSeleccionados);

    useEffect(() => {
        const cargarFormularios = async () => {
            try {
                setLoading(true);
                const _formularios =
                    await FormularioService
                        .listarPorEmpresaYEnvio(
                            getUsuarioAutenticado().empresaSeleccionada.id,
                            envio.id
                        );

                setFormularios(_formularios);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }
        cargarFormularios();
    }, []);

    const columnas = [
        {
            title: 'Documento',
            key: 'colaboradorNumeroDocumentoIdentidad',
            dataIndex: "colaboradorNumeroDocumentoIdentidad",
            align: 'center',
            width: '10%',
        },
        {
            title: 'Colaborador',
            key: 'colaboradorNombreCompleto',
            dataIndex: "colaboradorNombreCompleto",
            width: '20%',
        },
        {
            title: 'Bandeja',
            key: 'colaboradorCorreoElectronico',
            dataIndex: "colaboradorCorreoElectronico",
            render: (text, fila) => {
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {
                            fila.colaboradorCorreoElectronico &&
                            <span>
                                <MailOutlined /> {fila.colaboradorCorreoElectronico}
                            </span>
                        }

                        {
                            getOrganizacion().notificarPorSms &&
                            fila.colaboradorNumeroCelular &&
                            <span>
                                <PhoneOutlined /> {fila.colaboradorNumeroCelular}
                            </span>
                        }
                    </div>
                )
            }

        },
        {
            title: 'Notificación',
            key: 'estadoCorreoEnviado',
            dataIndex: "estadoCorreoEnviado",
            align: 'center',
            // width: '20%',
            render: (text, fila) => {

                if (fila.estadoRegistro === EstadoRegistro.ACTIVO && fila.estadoDocumento === EstadoDocumento.GENERADO) {
                    return <EtiquetaEstadoNotificacion formulario={fila} />
                }

                if (fila.estadoRegistro === EstadoRegistro.INACTIVO) {
                    return {
                        children:
                            <span>
                                <Tag color="default" icon={<MinusCircleOutlined />}>
                                    INHABILITADO
                                </Tag>
                            </span>,
                        props: {
                            colSpan: 3
                        }
                    }
                }
                

                return {
                    children:
                        <span style={{ color: '#7d7939' }}>
                            <SyncOutlined spin style={{ color: '#7d7939' }} /> Procesando...
                        </span>,
                    props: {
                        colSpan: 3
                    }
                }

            }
        },
        {
            title: 'Visualizado',
            key: 'estadoVisualizacion',
            dataIndex: "estadoVisualizacion",
            align: 'center',
            // width: '20%',
            render: (text, fila) => {

                if (fila.estadoRegistro === EstadoRegistro.ACTIVO && fila.estadoDocumento === EstadoDocumento.GENERADO) {
                    return <EtiquetaVisualizacion formulario={fila} />
                }

                return {
                    props: {
                        colSpan: 0
                    }
                }
            }
        },
        {
            title: 'Firmado',
            key: 'estadoFirmaColaborador',
            dataIndex: "estadoFirmaColaborador",
            align: 'center',
            // width: '20%',
            render: (text, fila) => {

                if (fila.estadoRegistro === EstadoRegistro.ACTIVO && fila.estadoDocumento === EstadoDocumento.GENERADO) {
                    return <EtiquetaFirmaColaborador formulario={fila} />
                }

                return {
                    props: {
                        colSpan: 0
                    }
                }
            }
        },
        {
            title: 'Acciones',
            key: 'acciones',
            align: 'center',
            width: '15%',
            render: (text, fila) => (
                <MenuAcciones formulario={fila} />
            )
        }
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setFormulariosSeleccionados(selectedRowKeys);
        },
        getCheckboxProps: (formulario) => ({
            disabled: formulario.estadoRegistro === EstadoRegistro.INACTIVO
        }),
    }

    return (
        <React.Fragment>

            <Table
                rowKey={(formulario) => formulario.id}
                dataSource={formularios}
                columns={columnas}
                rowSelection={{ ...rowSelection }}
                rowClassName={
                    (formulario, indice) => formulario.estadoRegistro === EstadoRegistro.INACTIVO ? "milegajo-fila-tabla-inactiva" : ""
                }
                loading={loading} />

            {/* <ModalActualizarCorreo /> */}

        </React.Fragment>
    )
}

export default TablaFormularios;