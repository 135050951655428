export const FormatoCarga = Object.freeze({
  // PDF_MULTIPAGINA("33528d45-e75b-4437-bb82-5bcd2c43292e", "PDF", "PDF MULTIPAGINA"),
  // EXCEL("fa8c5f15-dffb-4c64-8e52-e65de5faea75", "EXCEL", "XSL-XLSX"),
  TXT_MULTIPAGINA_PARAMETROS: {
    id: "c7488172-343c-4922-99a9-292bebff52b9",
    nombre: "TXT_PARAMETROS",
    descripcion: "SE OBTENDRÁ EL DOCUMENTO A PARTIR DE UN TXT",
    extension: ["application/x-prn", "text/plain"],
  },
  TXT_MULTIPAGINA: {
    id: "c7488172-343c-4922-99a9-282bebff52b8",
    nombre: "TXT",
    descripcion: "SE OBTENDRÁ EL DOCUMENTO A PARTIR DE UN TXT",
    extension: ["application/x-prn", "text/plain"],
  },
  PDF_MULTIPAGINA_OCR: {
    id: "e136ea17-8bea-4b1c-b719-dd876597a303",
    nombre: "PDF_OCR",
    descripcion: "SE OBTENDRÁ INFORMACIÓN DE CADA ELEMENTO",
    extension: ["application/pdf"],
  },
  PDF_MULTIPAGINA: {
    id: "33528d45-e75b-4437-bb82-5bcd2c43292e",
    nombre: "PDF",
    descripcion: "PDF MULTIPAGINA",
    extension: ["application/pdf"],
  },
  EXCEL: {
    id: "fa8c5f15-dffb-4c64-8e52-e65de5faea75",
    nombre: "EXCEL",
    descripcion: "XSL-XLSX",
    extension: ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
  },
  ZIP: {
    id: "f37a76b9-c66b-4982-8f5f-9d74a7841e2e",
    nombre: "ZIP",
    descripcion: "ZIP",
    extension: ["application/zip"],
  },
  ZIP_OCR: {
    id: "f37a76b9-c66b-4982-8f5f-9d74a7841e2f",
    nombre: "ZIP_OCR",
    descripcion: "ZIP_OCR",
    extension: ["application/zip"],
  },
  IMAGEN: {
    //id: "f37a76b9-c66b-4982-8f5f-9d74a7841e2e",
    nombre: "IMG",
    descripcion: "IMG",
    extension: ["image/*"],
  },
});
