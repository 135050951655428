import React from "react";
import { Container } from "components/Container"
import {TablaConsultasColaborador} from "./tabla-consultas-colaborador";
import {ModalVerMensajeConsulta} from "./ModalVerMensajeConsulta";
import { ModalVisorPdf } from "components/Modal/ModalVisorPdf";
import { Button,notification } from "antd";
import {filtrosSeleccionadosAtom,periodoInicioAtom,periodoFinAtom} from "./ListarConsultasColaboradorStore";
import { useAtom } from "jotai";
import ConsultarMensajeColaboradorService from "services/ConsultarMensajeColaboradorService";
import { saveAs } from "file-saver";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import {DownloadOutlined} from "@ant-design/icons";
const ListarConsultaColaboradorPage = () =>{

  const [filtros, setFiltros] = useAtom(filtrosSeleccionadosAtom);
  const [filtroPeriodoInicio,setFiltroPeriodoInicio]= useAtom(periodoInicioAtom);
  const [filtroPeriodoFin,setFiltroPeriodoFin]= useAtom(periodoFinAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);

  const onClickExportarDatos = async()=>{    
    try {
      let respuesta= await ConsultarMensajeColaboradorService.descargarExcel(
         getUsuarioAutenticado().empresaSeleccionada.id,
         getUsuarioAutenticado().id,
        filtros["tipoDocumento"],       
        filtros["colaborador"],
        filtros["documentoIdentidad"],
        filtros["codigoColaborador"], 
        filtros["tipoConsulta"],
        filtros["estadoConsulta"],
        filtroPeriodoInicio,
        filtroPeriodoFin,       
      )
      const filename = respuesta.headers.filename;
      
      saveAs(respuesta.data, filename);
      
    } catch (error) {
      console.log(error);
      notification.error({
        message:"No hay consulta de colaboradores para exportar",        
        description: error.response.data.mensaje,
       }
      )
    }  

  }

  return(
      <Container titulo="Observaciones Colaboradores" botonExtra={<Button type="primary" icon={<DownloadOutlined/>} onClick={onClickExportarDatos}>Exportar</Button>}>
          <TablaConsultasColaborador/>
          <ModalVerMensajeConsulta/>
          <ModalVisorPdf/>
      </Container>
  )
}
export default ListarConsultaColaboradorPage;