import React from "react";
import { Input, Form, Select, Row, Col, Button, Modal, Spin } from "antd";
import TablaDocumentos from "./TablaDocumentos";
import useSeleccionarEtapas from "../../hooks/useSeleccionarEtapas";
import useCrearProceso from "../../hooks/useCrearProceso";
import {  documentosAtom, loadingAtom } from "../../AgregarDocumentoStore";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";

import { useAtom } from "jotai";
import { useEffect } from "react";
import useCargarDocumentoProceso from "../../hooks/useCargarDocumentoProceso";
import { useParams } from "react-router-dom";


const Formulario = () => {
  const { crearProceso } = useCrearProceso();
  const [formProceso] = Form.useForm();
  const { documentoProceso } = useCargarDocumentoProceso();
  const [, setDocumentos] = useAtom(documentosAtom);
  const [loading, setLoading] = useAtom(loadingAtom);
  const { procesoId } = useParams();

  useEffect(()=>{
    const cargarData = () => {
      formProceso.setFieldsValue({
        postulanteId: documentoProceso.postulantesId,
        plantillaId: documentoProceso.plantillaNombre,
        convocatoria: documentoProceso.convocatoriaId,
        etapasId:documentoProceso.etapas
      });
      setLoading(false)
      setDocumentos(documentoProceso.documentos)
    }
    if(documentoProceso!==undefined)
      cargarData()
  },[documentoProceso])

  const confirmarNotificacion = (proceso) => {
    crearProceso(procesoId,proceso)
    // Modal.confirm({
    //   title: "Notificar",
    //   content: "¿ Está seguro de notificar el proceso ?",
    //   icon: <ExclamationCircleOutlined />,
    //   okText: "Si",
    //   cancelText: "No",
    //   onOk() {
    //     //crearProceso(proceso);
    //   },
    // });
  };

  return (
    <Spin indicator={<LoadingOutlined style={{ fontSize: 28 }} />} spinning={loading} size="large" tip="Cargando...">
    <Form layout="vertical" style={{ padding: "0px 2%" }} onFinish={confirmarNotificacion} form={formProceso}>
      <SelectConvocatorias />
      <SelectPostulante />
      <Row gutter={24}>
        <Col span={12}>
          <SelectPlantillaDocumentos />
        </Col>
        <Col span={12}>
          <SelectEtapa documentoProceso={documentoProceso} />
        </Col>
      </Row>
      <TablaDocumentos />
      <br />
      <BotonNotificar etapasSelected={documentoProceso?.etapas?.length} etapasNew={formProceso.getFieldValue('etapasId')?.length} />
    </Form>
    </Spin>
  );
};

const SelectConvocatorias = () => {
  return (
    <Form.Item
      label="Convocatoria"
      name="convocatoria">
      <Input disabled />
    </Form.Item>
  );
};

const SelectPostulante = () => {
  return (
    <Form.Item
      label="Postulante"
      name="postulanteId">
      <Input disabled />
    </Form.Item>
  );
};

const SelectPlantillaDocumentos = () => {
  return (
    <Form.Item
      label="Plantilla de documentos"
      name="plantillaId">
      <Input disabled />
    </Form.Item>
  );
};

const SelectEtapa = ({documentoProceso}) => {
  const { seleccionarEtapas } = useSeleccionarEtapas();
  return (
    <Form.Item
      label="Filtrar por Etapas"
      name={"etapasId"}
      rules={[{ required: true, message: "Seleccione las etapas" }]}
    >
      <Select style={{ width: "100%" }} mode="multiple" onChange={seleccionarEtapas}>
        <Select.Option value="POSTULANTE" disabled={documentoProceso?.etapas?.includes("POSTULANTE")} >POSTULANTE</Select.Option>
        <Select.Option value="SELECCIONADO" disabled={documentoProceso?.etapas?.includes("SELECCIONADO")}>SELECCIONADO</Select.Option>
        <Select.Option value="CONTRATADO" disabled={documentoProceso?.etapas?.includes("CONTRATADO")} >CONTRATADO</Select.Option>
      </Select>
    </Form.Item>
  );
};
const BotonNotificar = ({etapasSelected, etapasNew}) => {
  return (
    <Form.Item name="BotonNotificar" style={{ textAlign: "center" }}>
      <Button type="primary" htmlType="submit" disabled={etapasSelected===etapasNew}>
        Notificar
      </Button>
    </Form.Item>
  );
};

export default Formulario;
