import React from "react";
import { useDrag } from "react-dnd";
import { TipoControl } from "enums";

export const ControlPdf = ({ icono, tipo, nombre, posicion }) => {
  const [{ opacity }, drag] = useDrag(
    () => ({
      type: TipoControl.EFORM_PDF,
      item: { name: nombre, tipo: tipo, left: posicion.left, top: posicion.top },
      end: (item, monitor) => {
        //console.log("item: ", item);
        // const dropResult = monitor.getDropResult();
        // if (item && dropResult) {
        //   alert(`You dropped ${item.nombre} into ${dropResult.name}!`);
        // }
      },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.4 : 1,
      }),
    }),
    [nombre]
  );
  return (
    <div
      ref={drag}
      style={{
        cursor: "move",
        background: "#2780e3",
        color: "#fff",
        borderRadius: "0.3em",
        fontSize: ".8em",
        padding: "5px 5px 5px 8px",
        marginTop: "0.2rem",
        opacity: opacity,
      }}
    >
      {icono} {nombre}
    </div>
  );
};
