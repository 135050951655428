import React, { useState, useEffect } from "react";
import { ContainerCrear } from "../../../../components/ContainerCrear";
import {
    Select,
    Form,
    Input,
    Row,
    Col,
    Button,
    notification,
    Space,
    Upload,
    message,
    Table,
    Modal,
    Spin,
    Descriptions,
    Switch,
} from "antd";
import {
    UploadOutlined,
    CheckOutlined,
    LoadingOutlined,
    CloseCircleOutlined,
    CheckCircleOutlined,
    CloseOutlined,
} from "@ant-design/icons";
import { useContext } from "react";
import { SecurityContext } from "../../../../context/SecurityContextProvider";
import { useHistory, useParams } from "react-router-dom";
import { PlantillaContratoService } from "services/PlantillaContratoService";
import { TipoFirmaElectronica } from "../../../../enum/TipoFirmaElectronica";

export function PlantillaContratoEditarPage() {
    let usuariosFirmantesObtenidos = [];
    let contadorObtenido;
    const [formularioEditar] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleInfoModal, setVisibleInfoModal] = useState(false);
    const [archivos, setArchivos] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [usuariosFirmantes, setUsuariosFirmantes] = useState([]);
    const [usuarioSeleccionado, setUsuarioSeleccionado] = useState([]);
    const [usuarioIdSeleccionado, setUsuarioIdSeleccionado] = useState([]);
    const [tipoFirmaDescripcion, setTipofirmaDescripcion] = useState("");
    const [tipoFirmaValue, setTipofirmaValue] = useState("");
    const [cont, setCont] = useState(0);
    const [archivoCambiado, setArchivoCambiado] = useState(false);
    const [plantillaActual, setPlantillaActual] = useState([]);
    const [camposValidos, setCamposValidos] = useState(undefined);
    const [verificarCampos, setVerificarCampos] = useState(true);
    let history = useHistory();
    let { idPlantillaContrato } = useParams();
    const { Option } = Select;
    useEffect(() => {
        setLoading(true);
        async function cargarDatos() {
            const plantilla = await PlantillaContratoService.buscarPorIdNuevo(idPlantillaContrato);
            const archivoActual = [{ uid: "1", name: plantilla.urlPlantillaWord, status: "done" }];

            formularioEditar.setFieldsValue({
                id: plantilla.id,
                nombre: plantilla.nombre,
                archivo: archivoActual,
                requiereFirmaHolografaColaborador: plantilla.requiereFirmaHolografaColaborador,
            });

            usuariosFirmantesObtenidos = plantilla.usuariosFirmantes;
            setUsuariosFirmantes(usuariosFirmantesObtenidos);
            const usuariosModal = await PlantillaContratoService.listarUsuariosFirmantes(
                getUsuarioAutenticado().empresaSeleccionada.id
            );
            setUsuarios(usuariosModal);
            let indiceUsuarioObtenido = plantilla.usuariosFirmantes.length - 1;
            contadorObtenido = plantilla.usuariosFirmantes[indiceUsuarioObtenido].orden;
            setPlantillaActual(archivoActual);
            setCont(contadorObtenido + 1);
            setLoading(false);
        }
        cargarDatos();
    }, []);

    const props = {
        onRemove: (file) => {
            setArchivos([]);
            return {
                archivos: [],
            };
        },

        beforeUpload: (file) => {
            setArchivos(file);
            return false;
        },
        onChange: ({ file, fileList }) => {
            if (file.status !== "uploading") {
                setArchivoCambiado(true);
                setPlantillaActual(fileList);
                setArchivos(file);
                if (fileList.length == 0) {
                    formularioEditar.setFieldsValue({ archivo: undefined });
                    setCamposValidos(undefined);
                } else {
                    validar(file);
                    formularioEditar.setFieldsValue({ archivo: file });
                }
            }
        },
    };

    const validar = async (file) => {
        if (verificarCampos) {
            setVisibleInfoModal(true);
            setCamposValidos([]);
            const formData = new FormData();
            formData.append("archivo", file);
            const data = await PlantillaContratoService.validarNuevo(formData);
            if (data.length > 0) {
                setTimeout(function () {
                    setCamposValidos(data);
                }, 300);
            }
        } else setCamposValidos([true]);
    };

    const columnas = [
        {
            title: "N°",
            dataIndex: "orden",
            key: "orden",
        },

        {
            title: "Usuario",
            dataIndex: "nombreCompleto",
            key: "nombreCompleto",
        },
        {
            title: "Tipo Firma",
            dataIndex: "tipoFirma",
            key: "tipoFirma",
        },
        {
            title: "Acciones",
            render: (fila) => (
                <React.Fragment>
                    {usuariosFirmantes.length >= 1 ? (
                        <a onClick={() => handleOnClickQuitar(fila.orden)}>Quitar</a>
                    ) : null}
                </React.Fragment>
            ),
        },
    ];
    async function handleOnFinishActualizarPlantilla(plantilla) {
        if (usuariosFirmantes.length > 0) {
            try {
                if (camposValidos != undefined && camposValidos.length > 0 && !camposValidos.includes(false)) {
                    setLoading(true);
                    const plantillaContratoEnviada = {
                        id: plantilla.id,
                        nombre: plantilla.nombre,
                        empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
                        requiereFirmaHolografaColaborador: plantilla.requiereFirmaHolografaColaborador,
                        usuariosFirmantes: usuariosFirmantes,
                    };

                    const formData = new FormData();

                    if (archivoCambiado) formData.append("archivo", archivos);

                    formData.append(
                        "plantillaContratoEnviada",
                        new Blob([JSON.stringify(plantillaContratoEnviada)], {
                            type: "application/json",
                        })
                    );

                    await PlantillaContratoService.actualizarNuevo(formData);
                    notification.success({
                        message: "Operación exitosa",
                        description: "Se actualizó la plantila contrato correctamente.",
                    });
                    setLoading(false);
                    history.push("/contrato/plantilla-contrato/listar");
                } else {
                    message.error("La plantilla no cuenta con los campos predefinidos.");
                }
            } catch (error) {
                console.error(error);
                notification.error({
                    message: "No se puede actualizar la plantilla contrato",
                    description: error.response.data.mensaje,
                });
                setLoading(false);
            }
        } else {
            message.error("Debe Agregar un usuario");
        }
    }
    function handleOnClickCancelar() {
        history.push("/contrato/plantilla-contrato/listar");
    }
    function handleOnclickBtnAgregarFirmante() {
        setVisibleModal(true);
    }
    function cerrarModal() {
        setVisibleModal(false);
    }
    async function handleOnFinishAgregarFirmante(formulario) {
        if (usuariosFirmantes.find((u) => u.id === usuarioIdSeleccionado) === undefined) {
            const nuevoUsuarioFirmante = {
                orden: cont,
                nombreCompleto: usuarioSeleccionado,
                //tipoFirma: tipoFirmaDescripcion,
                tipoFirma: tipoFirmaValue,
                id: usuarioIdSeleccionado,
                //tipoFirmaValor:tipoFirmaValue,
            };
            setCont(cont + 1);
            setUsuariosFirmantes([...usuariosFirmantes, nuevoUsuarioFirmante]);

            setVisibleModal(false);
            setUsuarioSeleccionado([]);
            setUsuarioIdSeleccionado([]);
            setTipofirmaDescripcion("");
            setTipofirmaValue("");
        } else {
            message.error("El usuario firmante ya ha sido agregado.");
        }
    }
    function handleOnClickQuitar(orden) {
        let usuarioNuevo = usuariosFirmantes.filter((usuario) => usuario.orden !== orden);
        let indiceNuevo = 0;
        usuarioNuevo.forEach((u) => {
            indiceNuevo += 1;
            u.orden = indiceNuevo;
        });
        setUsuariosFirmantes(usuarioNuevo);
        setCont(usuariosFirmantes.length);
    }
    function handleOnChangeUsuarioModal(value) {
        let usuarioNombrecompleto = usuarios.find((obj) => obj.id === value).nombreCompleto;
        let usuarioId = usuarios.find((obj) => obj.id == value).id;
        setUsuarioSeleccionado(usuarioNombrecompleto);
        setUsuarioIdSeleccionado(usuarioId);
    }
    function handleOnChangeTipoFirma(value) {
        if (value === TipoFirmaElectronica.CON_APROBACION) {
            setTipofirmaDescripcion("firma con aprobación");
            setTipofirmaValue(TipoFirmaElectronica.CON_APROBACION);
        } else if (value === TipoFirmaElectronica.AUTOMATIZADA) {
            setTipofirmaDescripcion("firma automatizada");
            setTipofirmaValue(TipoFirmaElectronica.AUTOMATIZADA);
        }
    }

    const onClickBoton = () => {
        setVisibleInfoModal(true);
    };

    const onClickVerificar = (value) => {
        formularioEditar.setFieldsValue({ archivo: undefined });
        setArchivos([]);
        setPlantillaActual([]);
        setVerificarCampos(value);
    };

    return (
        <Spin spinning={loading}>
            <ContainerCrear
                titulo="Editar Plantilla Contrato"
                descripcion="En este formulario podrás editar la información de la plantilla contrato."
                colSpan={12}
            >
                <Form
                    layout="vertical"
                    size="large"
                    onFinish={handleOnFinishActualizarPlantilla}
                    form={formularioEditar}
                >
                    <Form.Item name="id" label="id" noStyle>
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item
                        label="Descripción"
                        name="nombre"
                        rules={[
                            {
                                required: true,
                                message: "Ingrese la descripción de la plantilla de Contrato",
                            },
                        ]}
                    >
                        <Input autoComplete="off" />
                    </Form.Item>
                    <Form.Item
                        label="Subir Archivo"
                        name="archivo"
                        valuePropName="archivo"
                        rules={[
                            {
                                required: true,
                                message: "subir el archivo de la plantilla de Contrato",
                            },
                        ]}
                    >
                        <Space direction="vertical" size={20}>
                            <Row>
                                <Switch
                                    onChange={onClickVerificar}
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultChecked={verificarCampos}
                                />
                                <div style={{ paddingLeft: "15px" }}> Verificar campos obligatorios </div>
                            </Row>
                            <Row>
                                <Upload
                                    {...props}
                                    fileList={plantillaActual}
                                    maxCount={1}
                                    accept={
                                        ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                    }
                                >
                                    <Button icon={<UploadOutlined />}>Subir</Button>
                                </Upload>
                            </Row>

                            {verificarCampos && (
                                <Row>
                                    <a
                                        style={{ textDecoration: "underline" }}
                                        onClick={onClickBoton}
                                        className="custom-color-element"
                                    >
                                        Ver campos predefinidos para plantilla de contrato
                                    </a>
                                </Row>
                            )}
                        </Space>
                    </Form.Item>

                    <Form.Item
                        label="¿Requiere Firma Holografa Colaborador?"
                        name="requiereFirmaHolografaColaborador"
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>

                    <Form.Item label="Firmante" name="firmante">
                        <Button onClick={handleOnclickBtnAgregarFirmante}>Agregar Firmante</Button>
                    </Form.Item>
                    <Form.Item>
                        {usuariosFirmantes.length > 0 && (
                            <Table
                                rowKey={(usuario) => usuario.orden}
                                columns={columnas}
                                dataSource={usuariosFirmantes}
                            />
                        )}
                    </Form.Item>

                    <Row justify="center">
                        <Space>
                            <Col>
                                <Form.Item style={{ textAlign: "center" }}>
                                    <Button type="default" onClick={handleOnClickCancelar}>
                                        Cancelar
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item style={{ textAlign: "center" }}>
                                    <Button type="primary" icon={<CheckOutlined />} htmlType="submit" loading={loading}>
                                        Actualizar plantilla contrato
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Space>
                    </Row>
                </Form>
                <Modal
                    visible={visibleModal}
                    title="Agregar Usuario Firmante"
                    //onOk={this.handleOk}
                    onCancel={cerrarModal}
                    footer={null}
                    destroyOnClose={true}
                >
                    <Form layout="vertical" onFinish={handleOnFinishAgregarFirmante}>
                        <Form.Item
                            label="Seleccionar Firmante"
                            name="firmanteSeleccionado"
                            rules={[
                                {
                                    required: true,
                                    message: "Seleccione firmante",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                style={{ width: 300 }}
                                placeholder="Seleccione Firmante"
                                optionFilterProp="children"
                                onChange={handleOnChangeUsuarioModal}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {usuarios.length > 0 &&
                                    usuarios.map((usuario) => {
                                        return (
                                            <Option key={usuario.id} value={usuario.id}>
                                                {usuario.nombreCompleto}
                                            </Option>
                                        );
                                    })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label="Tipo Firma"
                            name="tipoFirma"
                            rules={[
                                {
                                    required: true,
                                    message: "Debe seleccionar Tipo Firma",
                                },
                            ]}
                        >
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder="Seleccione Tipo Firma"
                                optionFilterProp="children"
                                onChange={handleOnChangeTipoFirma}
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                <Option value={TipoFirmaElectronica.CON_APROBACION}>Firma con Aprobación</Option>
                                <Option value={TipoFirmaElectronica.AUTOMATIZADA}>Firma Automatizada</Option>
                            </Select>
                        </Form.Item>

                        <Row align="center">
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Seleccionar
                                </Button>
                            </Form.Item>
                        </Row>
                    </Form>
                </Modal>
                <InfoModal visible={visibleInfoModal} setVisible={setVisibleInfoModal} camposValidos={camposValidos} />
            </ContainerCrear>
        </Spin>
    );
}

const InfoModal = ({ visible, setVisible, camposValidos }) => {
    const styleRojo = {
        fontSize: "20px",
        color: "red",
    };
    const styleVerde = {
        fontSize: "20px",
        color: "green",
    };

    return (
        <Modal
            width={700}
            getContainer={false}
            visible={visible}
            closable={false}
            footer={null}
            title={"Campos Predefinidos"}
        >
            {/* <div style={{border: "1px solid gray", padding: "30px"}}> */}
            <div style={{ paddingLeft: "50px" }}>
                <Descriptions
                    title={"Lista de parámetros obligatorios en la plantilla de contrato"}
                    size="small"
                    column={1}
                    bordered={camposValidos !== undefined}
                    colon={false}
                >
                    <Descriptions.Item
                        label={
                            <div>
                                <strong>{"<COLABORADOR_NOMBRE_COMPLETO> "}</strong>: Nombre completo del colaborador.
                            </div>
                        }
                    >
                        {" "}
                        {camposValidos === undefined ? (
                            ""
                        ) : camposValidos.length == 0 ? (
                            <LoadingOutlined />
                        ) : camposValidos[0] ? (
                            <CheckCircleOutlined style={styleVerde} />
                        ) : (
                            <CloseCircleOutlined style={styleRojo} />
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={
                            <div>
                                <strong>{"<COLABORADOR_NUMERO_DOCUMENTO_IDENTIDAD> "}</strong>: Documento de identidad
                                del colaborador.
                            </div>
                        }
                    >
                        {camposValidos === undefined ? (
                            ""
                        ) : camposValidos.length == 0 ? (
                            <LoadingOutlined />
                        ) : camposValidos[1] ? (
                            <CheckCircleOutlined style={styleVerde} />
                        ) : (
                            <CloseCircleOutlined style={styleRojo} />
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={
                            <div>
                                <strong>{"<COLABORADOR_CARGO> "}</strong>: Nombre del cargo.
                            </div>
                        }
                    >
                        {camposValidos === undefined ? (
                            ""
                        ) : camposValidos.length == 0 ? (
                            <LoadingOutlined />
                        ) : camposValidos[2] ? (
                            <CheckCircleOutlined style={styleVerde} />
                        ) : (
                            <CloseCircleOutlined style={styleRojo} />
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={
                            <div>
                                <strong>{"<REMUNERACION> "}</strong>: Remuneración del colaborador.
                            </div>
                        }
                    >
                        {camposValidos === undefined ? (
                            ""
                        ) : camposValidos.length == 0 ? (
                            <LoadingOutlined />
                        ) : camposValidos[3] ? (
                            <CheckCircleOutlined style={styleVerde} />
                        ) : (
                            <CloseCircleOutlined style={styleRojo} />
                        )}
                    </Descriptions.Item>
                    {/* <Descriptions.Item
            label={
              <div>
                <strong>{"<REMUNERACION_LETRAS> "}</strong>: Remuneración del colaborador en letras.
              </div>
            }
          >
            {camposValidos === undefined ? (
              ""
            ) : camposValidos.length == 0 ? (
              <LoadingOutlined />
            ) : camposValidos[4] ? (
              <CheckCircleOutlined style={styleVerde} />
            ) : (
              <CloseCircleOutlined style={styleRojo} />
            )}
          </Descriptions.Item> */}
                    <Descriptions.Item
                        label={
                            <div>
                                <strong>{"<FECHA_CONTRATO_INICIO> "}</strong>: Fecha inicio de contrato.
                            </div>
                        }
                    >
                        {camposValidos === undefined ? (
                            ""
                        ) : camposValidos.length == 0 ? (
                            <LoadingOutlined />
                        ) : camposValidos[4] ? (
                            <CheckCircleOutlined style={styleVerde} />
                        ) : (
                            <CloseCircleOutlined style={styleRojo} />
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item
                        label={
                            <div>
                                <strong>{"<FECHA_CONTRATO_FIN> "}</strong>: Fecha fin de contrato.
                            </div>
                        }
                    >
                        {camposValidos === undefined ? (
                            ""
                        ) : camposValidos.length == 0 ? (
                            <LoadingOutlined />
                        ) : camposValidos[5] ? (
                            <CheckCircleOutlined style={styleVerde} />
                        ) : (
                            <CloseCircleOutlined style={styleRojo} />
                        )}
                    </Descriptions.Item>
                </Descriptions>
                <br />
            </div>
            <div style={{ textAlign: "center" }}>
                <Button type="primary" onClick={() => setVisible(false)} disabled={camposValidos?.length == 0}>
                    {camposValidos === undefined ? "Cerrar" : "Aceptar"}
                </Button>
            </div>
            {/* </div> */}
        </Modal>
    );
};
