import React, { useState, useEffect, useRef } from "react";
import { ContainerListarPage } from "../../../components/ContainerListarLegajos";
import { Container } from "../../../components/Container";
import {
  Space,
  List,
  Table,
  Typography,
  Tag,
  Row,
  Col,
  Divider,
  Button,
  Dropdown,
  Menu,
  Modal,
  Checkbox,
  Result,
  Input,
  message,
  Badge,
} from "antd";
import {
  GroupOutlined,
  FolderOutlined,
  FolderOpenOutlined,
  DownOutlined,
  UserOutlined,
  SmileOutlined,
  CaretLeftOutlined,
  SearchOutlined,
  EllipsisOutlined,
  SettingOutlined,
  CloudDownloadOutlined,
  FileOutlined,
  PlusCircleTwoTone,
  MinusCircleTwoTone,
} from "@ant-design/icons";
import MenuItem from "antd/lib/menu/MenuItem";
import ColaboradorService from "../../../services/ColaboradorService";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import LegajoService from "../../../services/LegajoService";
import { ModalPDF } from "../../../components/PDFViewer";
import { saveAs } from "file-saver";
import { render } from "@testing-library/react";
import Highlighter from "react-highlight-words";
import { EstadoVistaColaborador } from "enums/EstadoVistaColaborador";

const { Text } = Typography;
const dataTable = [];
let dataList = [];
const tituloModal = "Descarga Masiva de Documentos";

export function LegajoListarPage() {
  const [colaboradores, setColaboradores] = useState([]);
  const [colaboradorSeleccionado, setColaboradorSeleccionado] = useState([]);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [legajo, setLegajo] = useState([]);
  const [url, setUrl] = useState("");
  const [visible, setVisible] = useState("");
  const [visibleModalDescarga, setVisibleModalDescarga] = useState(false);
  const [visibleModalDocumentosDescarga, setVisibleModalDocumentosDescarga] =
    useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeysDocuments, setSelectedRowKeysDocuments] = useState([]);
  const [selectedRowKeysOptions, setSelectedRowKeysOptions] = useState([]);
  const [selectedRowKeysOptionsDocuments, setSelectedRowKeysOptionsDocuments] =
    useState([]);
  const CheckboxGroup = Checkbox.Group;
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filaSeleccionada, setFilaSeleccionada] = useState("");
  const [documentoSeleccionado, setDocumentoSeleccionado] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [documentoNombre, setDocumentoNombre] = useState("");
  const [fechaFirma, setFechaFirma] = useState("");
  const [representanteLegal, setRepresentanteLegal] = useState("");
  const [nombreColaborador, setNombreColaborador] = useState("");
  const [fechaFirmaColaborador, setFechaFirmaColaborador] = useState("");
  const [estadoDetalleEnvio, setEstadoDetalleEnvio] = useState();

  var opcionesSeleccionadas = [];

  function getColumns(dataIndex) {
    return {
      // boton: () => <Button>{dataIndex}</Button>,
      render: (dataIndex) => <Button>{dataIndex}</Button>,
    };
  }
  const menu = (
    // <Menu onClick={confirmOpcionesModalColaboradores}>
    <Menu onClick={handleOnClickMenu}>
      <MenuItem
        key="descargadocumentos"
        //disabled={selectedRowKeys.length === 0}
      >
        Descarga Masiva Documentos
      </MenuItem>
    </Menu>
  );
  const menuDocumentos = (
    // <Menu onClick={confirmOpcionesModalLegajos}>
    <Menu onClick={handleOnClickMenuDocumentos}>
      <MenuItem key="descarga">Descarga Documentos</MenuItem>
    </Menu>
  );
  var adicionarColumnasFiltro = function getColumns(
    dataIndex,
    placeholder,
    propiedad
  ) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={
              placeholder !== undefined
                ? placeholder
                : `Buscar por ${dataIndex}`
            }
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        propiedad === undefined
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : record[dataIndex][propiedad]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={
              propiedad === undefined
                ? text.toString()
                : text[propiedad].toString()
            }
          />
        ) : propiedad === undefined ? (
          text
        ) : (
          text[propiedad]
        ),
    };
  };
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText("");
  }

  const columns = [
    {
      title: "Documento",
      dataIndex: "envio",
      key: "tipoDocumentoNombre",
      align: "center",
      ...adicionarColumnasFiltro(
        "envio",
        "Buscar por Tipo Documento",
        "tipoDocumentoNombre"
      ),
    },
    {
      title: "Descripción",
      dataIndex: "envio",
      key: "desripcion",
      align: "center",
      ...adicionarColumnasFiltro(
        "envio",
        "Buscar por Descripción Documento",
        "descripcion"
      ),
    },
    {
      title: "Estado",
      dataIndex: "estadoVistaColaborador",
      key: "estadoVistaColaborador",
      align: "center",
      render: (texto, fila) => {
        if (fila.estadoProcesoId && fila.estadoProcesoId === 8) {
          return (
            <span>
              <Badge status="success" /> Firmado
            </span>
          );
        } else if (
          fila.estadoVistaColaborador === EstadoVistaColaborador.VISUALIZADO
        ) {
          return (
            <span>
              <Badge status="success" /> Visualizado
            </span>
          );
        } else if (
          fila.estadoVistaColaborador === EstadoVistaColaborador.FIRMADO
        ) {
          return (
            <span>
              <Badge status="success" /> Firmado
            </span>
          );
        }
        return (
          <span>
            <Badge status="warning" /> Pendiente
          </span>
        );
      },
    },
    {
      title: "Fecha Notificación",
      dataIndex: "fechaEnvio",
      key: "fechaEnvio",
      align: "center",
      ...adicionarColumnasFiltro("fechaEnvio", "Buscar por Fecha Notificación"),
    },
    {
      title: "Acciones",
      dataIndex: "acciones",
      key: "acciones",
      align: "center",
      render: (text, fila) => (
        <Space size={8}>
          <Button
            type="link"
            onClick={() => handleOnClickVerDocumento(fila)}
            loading={filaSeleccionada === fila.id ? loading : false}
          >
            Ver
          </Button>
        </Space>
      ),
    },
  ];
  const columnatablalateral = [
    {
      title: "COLABORADORES",
      dataIndex: "nombreCompleto",
      //align: "left",
      width: "400px",
      ...adicionarColumnasFiltro("nombreCompleto", "Buscar por Nombre"),
      render: (text, fila) => (
        <React.Fragment>
          {colaboradorSeleccionado.numeroDocumentoIdentidad ===
          fila.numeroDocumentoIdentidad ? (
            /* <Text className="listar-legajo-colaborador-seleccionado">
                <FolderOpenOutlined style={{ fontSize: "20px" }} />
              </Text>
              <Text className="listar-legajo-colaborador-seleccionado">
                {fila.nombreCompleto}
              </Text> */

            <Button
              type="link"
              className="listar-legajo-colaborador-seleccionado"
              onClick={() => handleOnClickColaborador(fila)}
            >
              {<FolderOpenOutlined style={{ fontSize: "20px" }} />}
              {fila.nombreCompleto}
            </Button>
          ) : (
            <Button
              type="link"
              className="listar-legajo-colaborador-noseleccionado"
              onClick={() => handleOnClickColaborador(fila)}
            >
              {<FolderOutlined style={{ fontSize: "20px" }} />}
              {fila.nombreCompleto}
            </Button>
          )}
        </React.Fragment>
      ),
    },
    {
      title: (
        // <Dropdown overlay={menu} disabled={selectedRowKeys.length === 0}>
        <Dropdown overlay={menu} disabled={selectedRowKeys.length <= 1}>
          <a onClick={(e) => e.preventDefault()}>
            <EllipsisOutlined />
          </a>
        </Dropdown>
      ),

      dataIndex: "reportes",
      key: "reportes",
      align: "right",
      width: "42px",
      render: (text, fila) => (
        // <Space size={8}>
        <Dropdown
          overlay={menu}
          // disabled={colaboradorSeleccionado.id === fila.id ? false : true}
          disabled={
            selectedRowKeys[0] === fila.numeroDocumentoIdentidad &&
            selectedRowKeys.length === 1
              ? false
              : true
          }
        >
          <a onClick={(e) => e.preventDefault()}>
            <EllipsisOutlined />
          </a>
        </Dropdown>
        // </Space>
      ),
    },
  ];

  useEffect(() => {
    async function cargarDatos() {
      /*const colaboradores = await ColaboradorService.listar(
        getUsuarioAutenticado().empresaSeleccionada.id
      );*/
      const colaboradores = await LegajoService.listarColaboradores(
        getUsuarioAutenticado().empresaSeleccionada.id
      );
      setColaboradores(colaboradores);
      //console.log("colaboradores ", colaboradores);
    }

    cargarDatos();
  }, []);

  async function handleOnClickColaborador(colaborador) {
    setColaboradorSeleccionado(colaborador);
    const legajos = await LegajoService.listar(
      colaborador.numeroDocumentoIdentidad,
      getUsuarioAutenticado().empresaSeleccionada.id
    );
    setLegajo(legajos);
    setDocumentoSeleccionado([]);
  }
  async function handleOnClickDocumento(colaborador, documento) {
    const legajos =
      await LegajoService.listarDocumentosPorColaboradoryTipoDocumento(
        colaborador.numeroDocumentoIdentidad,
        documento.id,
        getUsuarioAutenticado().empresaSeleccionada.id
      );
    setLegajo(legajos);
    setColaboradorSeleccionado(colaborador);
    setDocumentoSeleccionado(documento);
  }

  async function handleOnClickVerDocumento(
    detalleEnvio,
    numeroDocumentoIdentidad
  ) {
    try {
      setLoading(true);
      setFilaSeleccionada(detalleEnvio.id);
      setEstadoDetalleEnvio(detalleEnvio.estadoProcesoId);
      const formData = new FormData();
      formData.append("detalleEnvioId", detalleEnvio.id);
      formData.append("estadoProcesoId", detalleEnvio.estadoProcesoId);
      //0 es vista desde web administrador se manda este valor para que mo pase por validaciones
      //del colaborador que se dera cuando  el valor sea mayor a cero
      formData.append("codigoPlataforma", 0);
      //formData.append("numeroDocumentoIdentidad", numeroDocumentoIdentidad);

      let data = await LegajoService.verPDF(formData);

      //TODO validar esta funcion que crea url
      // const url = window.URL.createObjectURL(
      //   new Blob([data], { type: "application/pdf" })
      // );
      //setUrl(url);
      setUrl(data.urlEncriptada);
      setVisible(true);
      setDocumentoNombre(detalleEnvio.envio.tipoDocumentoNombre);
      setRepresentanteLegal(detalleEnvio.representanteLegalNombre);
      setFechaFirma(detalleEnvio.fechaEnvio);
      setNombreColaborador(detalleEnvio.colaborador.nombreCompleto);
      setFechaFirmaColaborador(detalleEnvio.colaborador.fechaFirma);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  function handleOnOkModal() {
    setVisible(false);
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      //setSelectedRowKeys(selectedRows.numeroDocumentoIdentidad);
    },
  };
  function handleOnClickMenu(event) {
    const { key } = event;
    if (key === "descargadocumentos") {
      setVisibleModalDescarga(true);
    }
  }
  function handleOnClickMenuDocumentos(event) {
    const { key } = event;
    if (key === "descarga") {
      setVisibleModalDocumentosDescarga(true);
    }
  }

  async function handleOnOkModalDescarga() {
    const formData = new FormData();

    if (selectedRowKeysOptions.length !== 0) {
      let respuesta = await LegajoService.descargarDocumentos(
        getUsuarioAutenticado().empresaSeleccionada.id,
        selectedRowKeys,
        selectedRowKeysOptions
      );
      const filename = respuesta.headers.filename;

      saveAs(respuesta.data, filename);

      handleOnCancelModalDescarga();
    } else {
      message.error("Debe seleccionar alguna opción");
    }
  }

  async function handleOnOkModalDescargaPorDetalleEnvio() {
    /*formData.append(
      "empresaId",
      getUsuarioAutenticado().empresaSeleccionada.id
    );
    formData.append("listaNumeroDocumentoIdentidad", selectedRowKeys);
    formData.append("listaOpcionReporteIds", checkedList);*/
    if (selectedRowKeysOptionsDocuments.length !== 0) {
      let respuesta = await LegajoService.descargarDocumentosPorDetalleEnvio(
        getUsuarioAutenticado().empresaSeleccionada.id,
        selectedRowKeysDocuments,
        selectedRowKeysOptionsDocuments
      );
      const filename = respuesta.headers.filename;

      saveAs(respuesta.data, filename);

      handleOnCancelDocumentosModalDescarga();
    } else {
      message.error("Debe seleccionar alguna opción");
    }
  }

  function handleOnCancelModalDescarga() {
    setVisibleModalDescarga(false);
    //setIndeterminate(false);
    //setCheckedList([]);
    //setCheckAll(false);
    setSelectedRowKeysOptions([]);
  }
  function handleOnCancelDocumentosModalDescarga() {
    setVisibleModalDocumentosDescarga(false);
    setSelectedRowKeysOptionsDocuments([]);
  }

  // const opciones = [
  //   {
  //     label: "Documentos Laborales",
  //     value: 1,
  //   },
  //   {
  //     label: "Acuse de Envio",
  //     value: 2,
  //   },
  //   {
  //     label: "Acuse de Lectura",
  //     value: 3,
  //   },
  // ];
  const colOpcionesDescarga = [
    {
      title: "Todos",
      dataIndex: "opc",
      key: "opc",
    },
  ];
  const opcionesDescarga = [
    {
      id: "1",
      opc: "Documentos Laborales",
    },
    {
      id: "2",
      opc: "Acuse de Envio",
    },
    {
      id: "3",
      opc: "Acuse de Lectura",
    },
  ];
  function confirmOpcionesModalColaboradores(event) {
    const { key } = event;
    if (key === "descargadocumentos") {
      Modal.confirm({
        title: "Descarga Masiva de Documentos",
        icon: <CloudDownloadOutlined />,

        content: (
          <Table
            rowKey={(opcionesDescarga) => opcionesDescarga.id}
            columns={colOpcionesDescarga}
            dataSource={opcionesDescarga}
            pagination={false}
            rowSelection={{
              type: "checkbox",
              ...rowSelectionOpciones,
              columnWidth: 2,
            }}
            size="small"
          />
        ),
        okText: "OK",
        cancelText: "Cancel",
        onOk() {
          handleOnOkModalDescarga();
        },
        // onCancel: { handleOnCancelModalDescarga },
      });
    }
  }
  function confirmOpcionesModalLegajos(event) {
    const { key } = event;
    if (key === "descarga") {
      Modal.confirm({
        title: "Descarga Masiva de Documentos",
        icon: <CloudDownloadOutlined />,

        content: (
          <Table
            rowKey={(opcionesDescarga) => opcionesDescarga.id}
            columns={colOpcionesDescarga}
            dataSource={opcionesDescarga}
            pagination={false}
            rowSelection={{
              type: "checkbox",
              ...rowSelectionOpcionesDocumentos,
              columnWidth: 2,
            }}
            size="small"
          />
        ),
        okText: "OK",
        cancelText: "Cancel",
        onOk() {
          handleOnOkModalDescargaPorDetalleEnvio();
        },
      });
    }
  }

  const rowSelectionOpciones = {
    onChange: (selectedRowKeysOptions, selectedRowsOptions) => {
      setSelectedRowKeysOptions(selectedRowKeysOptions);
    },
  };
  const rowSelectionOpcionesDocumentos = {
    onChange: (
      selectedRowKeysOptionsDocuments,
      selectedRowsOptionsDocuments
    ) => {
      setSelectedRowKeysOptionsDocuments(selectedRowKeysOptionsDocuments);
    },
  };

  const rowSelectionDocument = {
    onChange: (selectedRowKeysDocuments, selectedRowsDocuments) => {
      setSelectedRowKeysDocuments(selectedRowKeysDocuments);
    },
  };
  const expanded = {
    expandedRowRender: (record) => {
      const colummnadocumento = [
        { width: "20%" },
        {
          //title: "D",
          width: "80%",
          dataIndex: "nombre",
          key: "nombre",
          align: "left",
          //className: "listar-legajo-colaborador-documento",
          render: (texto, fila) => (
            <React.Fragment>
              {documentoSeleccionado.id === fila.id &&
              colaboradorSeleccionado.numeroDocumentoIdentidad ===
                record.numeroDocumentoIdentidad ? (
                <Button
                  type="link"
                  onClick={() => handleOnClickDocumento(record, fila)}
                  className="listar-legajo-colaborador-documento-seleccionado"
                >
                  <FileOutlined />
                  {fila.nombre}
                </Button>
              ) : (
                <Button
                  type="link"
                  onClick={() => handleOnClickDocumento(record, fila)}
                  className="listar-legajo-colaborador-documento-noseleccionado"
                >
                  <FileOutlined />
                  {fila.nombre}
                </Button>
              )}
            </React.Fragment>
          ),
        },
      ];

      var documentos = [];
      documentos = record.documentos;

      return (
        <Table
          rowKey={(documentos) => documentos.id}
          columns={colummnadocumento}
          dataSource={documentos}
          pagination={false}
          showHeader={false}
        />
      );
    },
    expandIcon: ({ expanded, onExpand, record }) =>
      record.documentos !== null &&
      (expanded ? (
        <MinusCircleTwoTone onClick={(e) => onExpand(record, e)} />
      ) : (
        <PlusCircleTwoTone onClick={(e) => onExpand(record, e)} />
      )),
  };

  return (
    <Container
      icono={<GroupOutlined style={{ fontSize: "40px" }} />}
      titulo="Legajos"
    >
      {/* <div style={{ marginBottom: 16 }}></div> */}
      {/* <div style={{ marginLeft: 370 }}>
        <Dropdown overlay={menu}>
          <a onClick={(e) => e.preventDefault()}>
            Reportes
            <DownOutlined />
          </a>
        </Dropdown>
      </div> */}
      <Row>
        <Col lg={8}>
          <Table
            rowKey={(colaboradores) => colaboradores.numeroDocumentoIdentidad}
            //rowKey={(colaboradores) => colaboradores.id}
            dataSource={colaboradores}
            columns={columnatablalateral}
            rowSelection={{
              type: "checkbox",
              columnWidth: 27,
              ...rowSelection,
            }}
            pagination={false}
            scroll={{ y: 500 }}
            expandable={{
              ...expanded,
              rowExpandable: (record) =>
                record.documentos !== null ? true : false,
            }}
          />
        </Col>
        <Divider type="vertical" />
        {colaboradorSeleccionado.length !== 0 ? (
          <Col xs={10} lg={15}>
            <div className="div-colaborador-legajo">
              <Row style={{ marginTop: 6 }}>
                <Col span={2}>
                  <UserOutlined style={{ fontSize: "40px" }} />
                </Col>
                <Col span={14}>
                  <span>
                    <Text strong>{colaboradorSeleccionado.nombreCompleto}</Text>
                    <p>{colaboradorSeleccionado.numeroDocumentoIdentidad}</p>
                  </span>
                </Col>
                <Col align="right" span={8}>
                  <Dropdown
                    overlay={menuDocumentos}
                    disabled={selectedRowKeysDocuments.length === 0}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <SettingOutlined /> Reportes
                    </a>
                  </Dropdown>
                </Col>
              </Row>
            </div>
            {/* <br /> */}
            <Table
              rowKey={(legajo) => legajo.id}
              dataSource={legajo}
              columns={columns}
              pagination={{
                pageSize: [10],
              }}
              rowSelection={{
                type: "checkbox",
                ...rowSelectionDocument,
              }}
            />
          </Col>
        ) : (
          <Col xs={10} lg={15}>
            <Result
              icon={<SmileOutlined />}
              title="Por favor, Seleccione al menos un colaborador para poder ver sus documentos!"
              extra={
                <CaretLeftOutlined
                  style={{ fontSize: "50px", color: "#4D6880" }}
                />
              }
            />
          </Col>
        )}
      </Row>
      <ModalPDF
        ruta={url}
        visible={visible}
        handleOnOkModal={handleOnOkModal}
        documentoNombre={documentoNombre}
        fechaFirma={fechaFirma}
        representanteLegal={representanteLegal}
        nombreColaborador={nombreColaborador}
        fechaFirmaColaborador={fechaFirmaColaborador}
        estadoDetalleEnvio={estadoDetalleEnvio}
      />
      <Modal
        title={
          <span>
            <CloudDownloadOutlined
              style={{ color: "#F5B71E", fontSize: "25px" }}
            />
            {"  "}
            Descarga Masiva de Documentos
          </span>
        }
        visible={visibleModalDescarga}
        onOk={handleOnOkModalDescarga}
        onCancel={handleOnCancelModalDescarga}
        destroyOnClose={true}
      >
        {/* <div>
          <Checkbox
            indeterminate={indeterminate}
            onChange={onCheckAllChange}
            checked={checkAll}
          >
            Todos
          </Checkbox>
        </div>
        <br />
        <CheckboxGroup
          options={opciones}
          value={checkedList}
          onChange={onCheckChange}
        /> */}
        <Table
          rowKey={(opcionesDescarga) => opcionesDescarga.id}
          columns={colOpcionesDescarga}
          dataSource={opcionesDescarga}
          bordered={false}
          pagination={false}
          rowSelection={{
            type: "checkbox",
            ...rowSelectionOpciones,
            columnWidth: 2,
          }}
          size="small"
        />
      </Modal>
      <Modal
        title={
          <span>
            <CloudDownloadOutlined
              style={{ color: "#F5B71E", fontSize: "25px" }}
            />{" "}
            Descarga Masiva de Documentos
          </span>
        }
        visible={visibleModalDocumentosDescarga}
        onOk={handleOnOkModalDescargaPorDetalleEnvio}
        onCancel={handleOnCancelDocumentosModalDescarga}
        destroyOnClose={true}
      >
        <Table
          rowKey={(opcionesDescarga) => opcionesDescarga.id}
          columns={colOpcionesDescarga}
          dataSource={opcionesDescarga}
          bordered={false}
          pagination={false}
          rowSelection={{
            type: "checkbox",
            ...rowSelectionOpcionesDocumentos,
            columnWidth: 2,
            hideSelectAll: false,
          }}
          size="small"
        />
      </Modal>
    </Container>
    /* <ContainerListarPage
      icono={<GroupOutlined style={{ fontSize: "40px" }} />}
      titulo="Legajos"
      tituloList="COLABORADORES"
      dataList={colaboradores}
      iconoList={<FolderOutlined />}
      iconoSeleccionado={<FolderOpenOutlined />}
      colaboradorSeleccionado={colaboradorSeleccionado}
      handleOnClickColaborador={handleOnClickColaborador}
    >
      <ModalPDF
        ruta={url}
        visible={visible}
        handleOnOkModal={handleOnOkModal}
      />

      <Table
        dataSource={colaboradores}
        columns={columnatablalateral}
        //bordered={true}
        pagination={false}
      />

      <Space direction="vertical">
        <Space>
          <Tag color="blue">DOCUMENTOS</Tag>
          <Text strong>{colaboradorSeleccionado.nombreCompleto}</Text>
        </Space>
        <Space>
          <Text strong>{colaboradorSeleccionado.tipoDocumentoIdentidad}</Text>

          <Text>{colaboradorSeleccionado.numeroDocumentoIdentidad}</Text>
        </Space>
      </Space>
      <Table
        rowKey={(legajo) => legajo.id}
        dataSource={legajo}
        columns={columns}
        bordered={true}
        pagination={{
          pageSize: [10],
        }}
      />
    </ContainerListarPage>*/
  );
}
