import React from "react";
import { Container } from "components/Container"
import {TablaPerfil} from "./tabla-perfil";
import {Botones} from "./botones";
import {UnorderedListOutlined} from "@ant-design/icons";

const ListarPerfilPage = () =>{
    return(

        <Container icono={<UnorderedListOutlined />} titulo="Perfil" botonExtra={<Botones/>}  >
             <TablaPerfil/>             
        </Container>
    )

}
export default ListarPerfilPage;