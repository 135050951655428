import React from "react";
import { PanelCrear } from "components/Paneles/PanelCrear";
import { FormularioCrearContrato } from "./componentes/formulario-crear-contrato";
import { ModalConfirmacion } from "./componentes/modal-confirmacion";
import { ContratoCrearContextProvider } from "context/contrato/crear/ContratoCrearContextProvider";

export function ContratoCrearPage() {
  return (
    <ContratoCrearContextProvider>
      <PanelCrear titulo="Crear Contrato">
        <FormularioCrearContrato />
        <ModalConfirmacion />
      </PanelCrear>
    </ContratoCrearContextProvider>
  );
}
