import React, { useContext } from "react";
// import { Tag } from "antd"
import { FilePdfOutlined, CheckCircleOutlined } from "@ant-design/icons"
import { VisorPdfContext } from "context/visor-pdf";
// import { EstadoVistaColaborador } from "../../../../../../enum/EstadoVistaColaborador";
// import { useContext } from "react";
// import { MisDocumentosContext } from "../../../../../../context/MisDocumentos/MisDocumentosContext";

export const PanelInformacion = () => {

    const { informacion } = useContext(VisorPdfContext);

    // const { documentoSeleccionado } = useContext(MisDocumentosContext)

    // const EstadoDocumento = ({ estado }) => {
    //     if (estado === EstadoVistaColaborador.PENDIENTE) {
    //         return <Tag color="volcano">{estado}</Tag>
    //     }
    //     return <Tag color="green" icon={<CheckCircleOutlined />}>{estado}</Tag>
    // }

    return (
        <div className="informacion">
            <FilePdfOutlined className="icono" />
            <div className="texto">
                <span>
                    <span className="periodo">{informacion?.periodo}</span>
                    {" - "}
                    <span className="documento">{informacion?.descripcion}</span>
                </span>
                {/* <span className="estado">
                    <EstadoDocumento estado={documentoSeleccionado?.estadoVistaColaborador} />
                </span> */}
            </div>
        </div>
    );
}