import { formulario } from "pages/configuracion/formularios/crear/CrearFormulariosStore";
import { BaseService } from "./BaseService";
import { LoginService } from "./LoginService";

export const FormularioNotificarService = {

  async listarPorEmpresaId(empresaId) {
    const respuesta = await BaseService.get(`/notificar/formulario/empresa/${empresaId}/listar`);
    return respuesta.data;
  },

  async listarColaboradoresPorEmpresaId(empresaId) {
    const respuesta = await BaseService.get(`/notificar/formulario/empresa/${empresaId}/colaborador/listar`);
    return respuesta.data;
  },

  async notificar(formulario) {
    const respuesta = await BaseService.post("/notificar/formulario", formulario);
    return respuesta.data;
  },

  async listarColaboradoresPorEmpresaIdConFiltro(data){
    const respuesta = await BaseService.get("/notificar/formulario/empresa/colaborador/listarConFiltro",{
      params: {
        ...data,
      },
    });
    return respuesta.data;
  },
  async listarColaboradoresPorEmpresaIdYSedes(data){
    const respuesta = await BaseService.get("/notificar/formulario/empresa/colaborador/listarPorSedes",{
      params: {
        ...data,
      },
    });
    return respuesta.data;
  }

};