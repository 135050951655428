import React from "react";
import { EstadoFirmaColaborador } from "enums/EstadoFirmaColaborador";
import { Tag } from "antd";
import { TagFirmado } from "./TagFirmado";
import { EditOutlined } from "@ant-design/icons";

const EtiquetaFirmaColaborador = ({ formulario }) => {
    switch (formulario.estadoFirmaColaborador) {
        case EstadoFirmaColaborador.PENDIENTE:
            return <Tag color="gold" className="milegajo-ant-tag">PENDIENTE</Tag>;

        case EstadoFirmaColaborador.FIRMADO:
            return (
                <TagFirmado color="green" className="milegajo-ant-tag pointer" formulario={formulario}>
                    <EditOutlined /> FIRMADO
                </TagFirmado>
            );

        default:
            return <Tag color="gold" className="milegajo-ant-tag">PENDIENTE</Tag>;
    }
}

export default EtiquetaFirmaColaborador;