import React, { useContext, useEffect, useState } from "react";
import { SearchOutlined, EditOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { Table, Modal, Tag } from "antd";
import { useParams } from "react-router";
import {
  detallesOnboardingAtom,
  resumenColaboradorAtom,
  resumenDocumentoAtom,
  loadingAtom,
} from "../DetalleSeguimientoOnboardingStorage";
import { useAtom } from "jotai";
import { OnboardingColaboradorService } from "services/OnboardingColaboradorService";
import { SecurityContext } from "context/SecurityContextProvider";
import FormularioSeguimientoService from "services/FormularioSeguimientoService";
import { useModalVisorPdf } from "store/global";

const DetalleTablaListar = () => {
  const { envioId } = useParams();
  const [detallesOnboarding, setDetallesOnboarding] = useAtom(detallesOnboardingAtom);
  const [, setResumenColaborador] = useAtom(resumenColaboradorAtom);
  const [, setResumenDocumento] = useAtom(resumenDocumentoAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal);
  const [, setLoading] = useAtom(loadingAtom);

  useEffect(() => {
    const cargarOnboardings = async () => {
      setLoading(true);
      const _documentos = await OnboardingColaboradorService.listarDetalleOnboarding(
        envioId,
        null,
        null,
        getUsuarioAutenticado().login
      );
      setDetallesOnboarding(_documentos.detalles);
      setResumenColaborador(_documentos.colaborador);
      setResumenDocumento(_documentos.resumen);
      //console.log(_documentos)
      setLoading(false);
    };
    cargarOnboardings();
  }, []);

  const onClickVerDetalle = async ({ fila }) => {
    if (fila?.estadoVistaColaborador !== "FIRMADO")
      Modal.error({
        title: "Falta completar",
        content: "El documento no ha sido completado.",
      });
    else
      try {
        //console.log(fila)
        const respuesta = await FormularioSeguimientoService.getUrlDocumento(
          getUsuarioAutenticado().empresaSeleccionada.id,
          fila.id
        );
        mostrarModalVisorPdf(
          respuesta.urlDocumento,
          fila.tipoDocumentoNombre,
          true,
          true,
          undefined,
          undefined,
          fila?.motivoRechazo
        );
      } catch (error) {
        console.error(error);
      }
  };

  const columnas = [
    {
      title: "#",
      dataIndex: "indice",
      render: (text, fila, index) => (
        <a onClick={() => onClickVerDetalle((fila = { fila }))} className="custom-color-element">
          {index + 1}
        </a>
      ),
    },
    // {
    //   title: "Código",
    //   dataIndex: ["colaborador", "codigo"],
    // },
    // {
    //   title: "Documento de Identidad",
    //   dataIndex: ["colaborador", "numeroDocumentoIdentidad"],
    //   align: "center",
    // },
    // {
    //   title: "Colaborador",
    //   dataIndex: ["colaborador", "nombreCompleto"],
    //   align: "center",
    // },
    {
      title: "Documento",
      dataIndex: "tipoDocumentoNombre",
      align: "center",
    },
    {
      title: "Fecha de Envío",
      dataIndex: "fechaEnvio",
    },
    {
      title: "Estado Documento",
      dataIndex: "estadoVistaColaborador",
      align: "center",
      render: (text, fila) => {
        return fila?.estadoVistaColaborador === "FIRMADO" ? (
          <Tag color="green" className="milegajo-ant-tag">
            {" "}
            <EditOutlined /> FIRMADO{" "}
          </Tag>
        ) : (
          /*:fila?.Visualizado==="Si"?"VISUALIZADO"*/ <Tag color="gold" className="milegajo-ant-tag">
            PENDIENTE
          </Tag>
        );
      },
    },
    {
      title: "Estado Aprobación",
      dataIndex: "estado Aprobación",
      align: "center",
      render: (text, fila) => {
        return <EstadoAprobacion estado={fila?.estadoAprobacion} />;
      },
    },
    {
      title: "Acciones",
      align: "center",
      render: (text, fila) => (
        <a onClick={() => onClickVerDetalle((fila = { fila }))} className="custom-color-element">
          <SearchOutlined /> Ver Detalle
        </a>
      ),
    },
  ];

  return (
    <Table rowKey={(documento) => documento.id} dataSource={detallesOnboarding} columns={columnas} pagination={false} />
  );
};

const EstadoAprobacion = ({ estado }) => {
  if (estado === null || estado === "SIN_APROBACION") {
    return <Tag className="milegajo-ant-tag">NO APLICA</Tag>;
  } else if (estado === "APROBADO") {
    return (
      <Tag color="green" className="milegajo-ant-tag">
        {" "}
        <CheckOutlined /> APROBADO
      </Tag>
    );
  } else if (estado === "RECHAZADO") {
    return (
      <Tag color="red" className="milegajo-ant-tag">
        {" "}
        <CloseOutlined /> RECHAZADO{" "}
      </Tag>
    );
  }
  return (
    <Tag color="gold" className="milegajo-ant-tag">
      {" "}
      PENDIENTE
    </Tag>
  );
};

export default DetalleTablaListar;
