import React, { useState, useEffect, useContext } from "react";

import { Button, Table } from "antd";
import { Container } from "../../../components/Container";
import ContratoEnvioService from "../../../services/ContratoEnvioService";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { EstadoContrato } from "./componentes/EstadoContrato";
import { useHistory } from "react-router-dom";
import iconoFolder from "../../../assets/img/folder.svg";
import { VisorPdf } from "../../../components/VisorPdf";
import { MenuAcciones } from "./componentes";
import { useModalAsignarCarpeta } from "store/global";
import { ModalAsignarCarpeta } from "components/Modal/ModalAsignarCarpeta";
import * as atoms from "store/local/contratos/DetalleContratoStore";
import { useAtom } from "jotai";

export function SeguimientoContratoPage() {
  let history = useHistory();
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [loading, setLoading] = useState(false);
  const [envios, setEnvios] = useState([]);
  const cerrarModalAsignarCarpeta = useModalAsignarCarpeta((state) => state.cerrarModal);
  const [contratoSeleccionado, setContratoSeleccionado] = useAtom(atoms.contratoSeleccionado);

  useEffect(() => {
    async function cargarDatos() {
      try {
        setLoading(true);
        const envios = await ContratoEnvioService.listarEnvio(getUsuarioAutenticado().empresaSeleccionada.id);
        setEnvios(envios);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    cargarDatos();
  }, []);

  function onClickSeleccionarDetalle(envioId) {
    //history.push(`/contrato/seguimiento/${envioId}/detalle`);
    history.push(`/notificacion/seguimiento/detalle/Contrato/${envioId}`);
  }
  function onClickBtnCancelarModal() {
    cerrarModalAsignarCarpeta();
  }

  const columnas = [
    {
      title: "Documento",
      key: "descripcion",
      render: (text, fila) => (
        <Button type="link" className="milegajo-link-table" onClick={() => onClickSeleccionarDetalle(fila.id)}>
          <img src={iconoFolder} alt="iconoFolder" width="32px" style={{ marginRight: "4px" }} />
          {fila.descripcion}
        </Button>
      ),
    },
    {
      title: "Estado",
      align: "center",
      dataIndex: "estado",
      key: "estado",
      render: (text, fila) => <EstadoContrato estado={fila.estado} />,
    },
    {
      title: "Fecha de envío",
      align: "center",
      dataIndex: "fechaEnvio",
      key: "fechaEnvio",
    },
    {
      title: "% Avance",
      align: "center",
      key: "avance",
      render: (text, fila) =>
        // (fila.totalFirmados + fila.totalRechazados) * 100 / fila.totalEnviados + " %"
        Math.round(((fila.totalFirmados + fila.totalRechazados) * 100) / fila.totalCargados) + " %",
    },
    {
      title: "Firmados",
      align: "center",
      dataIndex: "totalFirmados",
      key: "totalFirmados",
    },
    {
      title: "Rechazados",
      align: "center",
      dataIndex: "totalRechazados",
      key: "totalRechazados",
    },
    {
      title: "Pendientes",
      align: "center",
      key: "totalPendientes",
      render: (text, fila) => fila.totalCargados - (fila.totalFirmados + fila.totalRechazados),
    },
    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      // width: '15%',
      render: (text, fila) => <MenuAcciones envio={fila} />,
    },
  ];

  return (
    <Container titulo="Seguimiento Contratos">
      <Table rowKey={(envios) => envios.id} dataSource={envios} columns={columnas} loading={loading} />
    </Container>
  );
}
