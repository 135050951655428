import React from "react"
import { EstadoProcesoEnvio } from "../../../../../enums/EstadoProcesoEnvio"

import { Tag } from "antd"

function EstadoRegistrado() {
    return <Tag color="blue">REGISTRADO</Tag>
}

function EstadoEnCurso() {
    return <Tag color="gold">EN CURSO</Tag>
}

function EstadoCompletado() {
    return <Tag color="green">COMPLETADO</Tag>
}

function EstadoPendienteNotificar() {
    return <Tag color="volcano">FALTA NOTIFICAR</Tag>
}

export function EstadoContrato({ estado }) {

    switch (estado) {
        case EstadoProcesoEnvio.REGISTRADO:
            return <EstadoRegistrado />

        case EstadoProcesoEnvio.PENDIENTE_NOTIFICAR:
            return <EstadoPendienteNotificar />

        case EstadoProcesoEnvio.EN_CURSO:
            return <EstadoEnCurso />

        case EstadoProcesoEnvio.COMPLETADO:
            return <EstadoCompletado />

        default:
            return <EstadoRegistrado />;
    }

}