import React, { useEffect, useState, useContext } from "react";
import { Modal, Form, Select, Space, Row, Input, Button } from "antd";
import { FolderFilled, FolderTwoTone } from "@ant-design/icons";
import { useHistory } from "react-router";
import CarpetaService from "services/CarpetaService";
import { useModalAsignarCarpeta } from "store/global";
import { useAtom } from "jotai";
import  DetalleEnvioService  from "services/DetalleEnvioService";
import {enviosAtom} from "pages/seguimiento/listar/ListarSeguimientoStore"
import { visibleModalAsignarCarpetaAtom, consultaDocumentoSeleccionadoAtom, documentosAtom, carpetaAtom } from "pages/consulta-documento/ConsultaDocumentoStore";
import { SecurityContext } from "context/SecurityContextProvider";
import { RutasApp } from "router/RutasApp";


  const ModalAsignarCarpeta = () => {
  const [visibleModalAsignarCarpeta, setVisibleModalAsignarCarpeta] = useAtom(visibleModalAsignarCarpetaAtom);
  const { Option } = Select;
  const [carpetas, setCarpetas] = useAtom(carpetaAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [consultaDocumento, setConsultaDocumento] = useAtom(documentosAtom);
  const [documentoSeleccionado,setDocumentoSeleccionado ] = useAtom(consultaDocumentoSeleccionadoAtom );
  const [frmCarpeta] = Form.useForm();


  useEffect(() => {
    const cargarDatos = async () => {
      /*const _carpetas = await CarpetaService.listar(getUsuarioAutenticado().empresaSeleccionada.id);

      setCarpetas(_carpetas);*/
      
      const tempcap = carpetas.find(item=> item.id==documentoSeleccionado?.carpeta?.id)

      frmCarpeta.setFieldsValue({
        carpetaId: <React.Fragment>      
            <FolderTwoTone
        style={{ fontSize: "18px", color: "#F7D358" }}
        twoToneColor="#F7D358"
      />
    {" "}{tempcap?.ruta}  
    </React.Fragment>
      });

    };
  
    cargarDatos();

  }, [documentoSeleccionado]);



  const onFinishCarpeta = async (documentoCarpeta) => {
    //console.log("contratoS:", carpetaDescripcion.carpeta.descripcion);
    documentoCarpeta.id = documentoSeleccionado.id;
    await DetalleEnvioService.asignarCarpeta(documentoCarpeta);
    if(consultaDocumento.data.length>0){
      console.log("se esta modificando");
      const _documentos = [...consultaDocumento.data]; //siempre se debe generar un nuevo array
      const indice = consultaDocumento.data.findIndex((documento) => documento.id === documentoSeleccionado.id);
      const detalleModificar = consultaDocumento.data[indice];
      const detalleModificado = {
          ...detalleModificar,
          carpeta: {
              id:documentoCarpeta.carpetaId,
              descripcion:documentoCarpeta.carpetaDescripcion,
              ruta: carpetas.find(item=> item.id==documentoCarpeta.carpetaId)?.ruta
          }
      }
      _documentos.splice(indice, 1, detalleModificado);
      setConsultaDocumento({...consultaDocumento,data:_documentos});
    }
    
    setVisibleModalAsignarCarpeta(false);
  };
  const onChangeSelectCarpeta = (value, option) => {
    frmCarpeta.setFieldsValue({ carpetaDescripcion: option.descripcion });
  };
  const onLimpiarDatos = () => {
    frmCarpeta.setFieldsValue({
      carpetaId: "",
      carpetaDescripcion: "",
    });
    setDocumentoSeleccionado(null);

  };

  const onClickBtnCancelar = () => {
    setVisibleModalAsignarCarpeta(false);

  };

  return (
    <Modal
      title={<div>Asignar Carpeta a:</div>}
      getContainer={false}
      //title={documento}
      visible={visibleModalAsignarCarpeta}
      onCancel={onClickBtnCancelar}
      footer={null}
      afterClose={onLimpiarDatos}
    >
      <Form
        onFinish={onFinishCarpeta}
        form={frmCarpeta}
        //initialValues={{ carpetaId: documento.carpeta.id }}
      >
        <Form.Item
          label="Carpeta"
          name="carpetaId"
          rules={[
            {
              required: true,
              message: "Seleccione la Carpeta.",
            },
          ]}
        >
          <Select
            onChange={onChangeSelectCarpeta}
            optionLabelProp="label"
            placeholder="Seleccione la carpeta"
          >
            {carpetas.length > 0 &&
              carpetas.map((carpeta) => {
                return (
                  <Option
                    key={carpeta.id}
                    value={carpeta.id}
                    label={
                      <div>
                        <FolderTwoTone
                          style={{ fontSize: "20px", color: "#F7D358" }}
                          twoToneColor="#F7D358"
                        />{" "}
                        {carpeta.ruta}
                      </div>
                    }
                    descripcion={carpeta.descripcion}
                  >
                    <Space size={carpeta.nivel * 10}>
                      <span></span>
                      <span>
                        {carpeta.nivel === 0 ? (
                          <FolderFilled
                            style={{ fontSize: "20px", color: "#F7D358" }}
                          />
                        ) : (
                          <FolderTwoTone
                            style={{ fontSize: "18px", color: "#F7D358" }}
                            twoToneColor="#F7D358"
                          />
                        )}{" "}
                        {carpeta.descripcion}
                      </span>
                    </Space>
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item name="carpetaDescripcion" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item>
          <Row justify="end">
            <Button type="primary" htmlType="submit">
              Guardar
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default ModalAsignarCarpeta;
