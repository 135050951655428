import React ,{useEffect,useState}from "react";
import { DatePicker,Typography,Space } from "antd";
import { Bar } from "@ant-design/plots";
import { ReporteSolicitudPersonalService } from "services/reclutamiento-y-seleccion";
import moment from "moment";

const ReporteRotacionPesonalPorSede = () =>{
    const [data, setData] = useState([]);
    const [anio,setAnio] = useState(moment().year())

    useEffect(()=>{
        ReporteSolicitudPersonalService.getTasaRotacionPersonalPorSede({ anio: anio })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => console.log("error", error));
    //console.log("información...");   
        
    },[anio]);
    const onChange=(date, dateString)=>{           
         setAnio(parseInt(dateString,10));
       }
   
    const config = {
         data,        
         xField: "porcentajeRotacion",
         yField: "sede",
         seriesField: 'sede', 
         label: {           
          position: 'middle',           
          style: {
            fill: '#000000',            
          },
        },      
        legend: {
            position: 'top-left',
          }, 
        xAxis: {
            tickInterval: 10,
            
          },
          minBarWidth: 20,
          maxBarWidth: 20,
       
       };   
     
    return(
        <React.Fragment>
            <Space>
            <Typography.Text>Año:</Typography.Text>
                <DatePicker defaultValue={moment()} onChange={onChange} picker="year" allowClear={false}/>
            </Space> 
            <br/>
            <br/>
            <Bar {...config} />
        </React.Fragment>
    )

}
export default ReporteRotacionPesonalPorSede;