import React, { useContext, useEffect, useState } from "react";
import { Table, Tag } from "antd";
import * as atoms from "../ListarFormulariosStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import { FormularioService } from "services/FormularioService";
import { MenuAcciones } from "./MenuAcciones";
import { CategoriaDocumento } from "enums/CategoriaDocumento";

const TablaFormularios = () => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [loading, setLoading] = useState(false);
  const [formularios, setFormularios] = useAtom(atoms.formularios);

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const formularios = await FormularioService.listarPorEmpresaId(getUsuarioAutenticado().empresaSeleccionada.id);
        setFormularios(formularios);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, []);

  const columnas = [    
    {
      title: "Nombre",
      //dataIndex: "nombre",
      width: "30%",
      render:(text,formulario)=>(<React.Fragment>{formulario.nombre}
                                 {formulario.categoriaDocumento.id===CategoriaDocumento.FORMULARIOS.id ?
                                 <p><Tag color="blue" style={{borderRadius: "15px"}}>FORMULARIOS</Tag></p>:
                                 formulario.categoriaDocumento.id===CategoriaDocumento.CARGA_DOCUMENTO.id?
                                 <p><Tag color="purple"style={{borderRadius: "15px"}}>CARGA DOCUMENTO</Tag></p>:

                                 formulario.categoriaDocumento.id===CategoriaDocumento.COMUNICADOS_FORMULARIOS.id?
                                 <p><Tag color="orange"style={{borderRadius: "15px"}}>COMUNICADOS</Tag></p>:

                                 <p><Tag color="cyan" style={{borderRadius: "15px"}}>FORMATO AUSENCIA</Tag></p>
                                 }                        
                        </React.Fragment>)
    },
    {
      title: "Mostrar en Portal del Colaborador",
      dataIndex: "mostrarEnPortalColaborador",
      align: "center",
      width: "10%",
      render: (text, formulario) =>
        formulario.mostrarEnPortalColaborador ? <Tag color="success">Si</Tag> : <Tag color="error">No</Tag>,
    },
    {
      title: "Requiere Firma del Colaborador",
      dataIndex: "requiereFirmaColaborador",
      align: "center",
      width: "10%",
      render: (text, formulario) =>
        formulario.requiereFirmaColaborador ? <Tag color="success">Si</Tag> : <Tag color="error">No</Tag>,
    },
    {
      title: "Requiere Aprobación",
      dataIndex: "requiereAprobacion",
      align: "center",
      width: "10%",
      render: (text, formulario) =>
        formulario.requiereAprobacion ? <Tag color="success">Si</Tag> : <Tag color="error">No</Tag>,
    },
    {
      title: "Requiere Firma Representante Legal",
      dataIndex: "requiereFirmaRepresentanteLegal",
      align: "center",
      width: "10%",
      render: (text, formulario) =>
        formulario.requiereFirmaRepresentanteLegal ? <Tag color="success">Si</Tag> : <Tag color="error">No</Tag>,
    },
    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      width: "30%",
      render: (formulario) => <MenuAcciones formulario={formulario} />,
    },
  ];

  return (
    <Table
      loading={loading}
      rowKey={(formulario) => formulario.id}
      columns={columnas}
      dataSource={formularios}
      pagination={false}
    />
  );
};

export default TablaFormularios;
