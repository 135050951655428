import { Tag } from "antd";
import React from "react";

const StyleTag = {
  fontSize: "9px",
  borderRadius: "12px",
};

const TagEtapa = ({ etapa }) => {
  if (etapa === "POSTULANTE") {
    return (
      <Tag style={StyleTag} color="cyan">
        {etapa}
      </Tag>
    );
  } else if (etapa === "SELECCIONADO") {
    return (
      <Tag style={StyleTag} color="blue">
        {etapa}
      </Tag>
    );
  } else if (etapa === "CONTRATADO") {
    return (
      <Tag style={StyleTag} color="lime">
        {etapa}
      </Tag>
    );
  }
};

export default TagEtapa;
