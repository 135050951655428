import React, { useState, useEffect } from "react";
import { FormularioCargarTramaExcel } from "./formulario-cargar-trama-excel";
import { ListaContratosSinNotificar } from "./lista-contratos-sin-notificar";
import { ListaContratosNotificados } from "./lista-contratos-notificados";
import { useParams } from "react-router-dom";
import { EstadoProcesoEnvio } from "enums/EstadoProcesoEnvio";
import { ContratoService } from "services/ContratoService";
import { LoadingContainer } from "components/Loading";

const DetalleSeguimientoContratoPage = () => {
  const { envioId } = useParams();
  const [loading, setLoading] = useState(false);
  const [envio, setEnvio] = useState(undefined);

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const _envio = await ContratoService.buscarEnvioPorId(envioId);
        setEnvio(_envio);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, []);

  const DetalleContrato = () => {
    if (envio?.estado === EstadoProcesoEnvio.REGISTRADO) {
      return <FormularioCargarTramaExcel envio={envio} setEnvio={setEnvio} />;
    } else if (envio?.estado === EstadoProcesoEnvio.PENDIENTE_NOTIFICAR) {
      return <ListaContratosSinNotificar envio={envio} setEnvio={setEnvio} />;
    } else if (envio?.estado === EstadoProcesoEnvio.EN_CURSO || envio?.estado === EstadoProcesoEnvio.COMPLETADO) {
      return <ListaContratosNotificados envio={envio} />;
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      {loading && <LoadingContainer />}
      {!loading && <DetalleContrato />}
    </React.Fragment>
  );
};

export default DetalleSeguimientoContratoPage;
