import React from "react";
import { Steps, Spin } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { pasoActualAtom, loadingAtom } from "./OnboardingIndividualStore";
import { useAtom } from "jotai";
import { ColaboradorStep, VerificarDocumentoStep, ResumenStep } from "./componentes";
import { Container } from "components/Container";

const OnboardingIndividualPage = () => {
  const [pasoActual] = useAtom(pasoActualAtom);

  return (
    <Container titulo={"Notificar Procesos Documentales"}>
      <div style={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
        <div style={{ padding: "2% 10%" }}>
          <Steps current={pasoActual - 1}>
            <Steps.Step key="onboarding" title="Registrar Onboarding" />
            <Steps.Step key="contratos" title="Registrar Contratos" />
            <Steps.Step key="resumen" title="Notificar Onboarding" />
          </Steps>
        </div>
        <div style={{ padding: "2% 10%", display: "flex", flexDirection: "column" }}>
          {pasoActual === 1 && <ColaboradorStep />}
          {pasoActual === 2 && <VerificarDocumentoStep />}
          {pasoActual === 3 && <ResumenStep />}
        </div>
      </div>
    </Container>
  );
};

const Informacion = ({ children }) => {
  return <div>{children}</div>;
};

const Pasos = ({ children }) => {
  return (
    <div
      id="pasos"
      style={{
        height: "120px",
        //background: "#f5f5f5",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ width: "60%", textAlign: "center" }}>{children}</div>
    </div>
  );
};

const ContainerOnboarding = ({ children }) => {
  const [pasoActual] = useAtom(pasoActualAtom);
  const [loading] = useAtom(loadingAtom);
  return (
    <div
      id="container"
      style={{
        background: "#fff",
        display: "flex",
        flexDirection: "column",
        minHeight: pasoActual !== 0 ? "80vh" : null,
      }}
    >
      <Spin spinning={loading}>{children}</Spin>
    </div>
  );
};

export default OnboardingIndividualPage;
