import React from "react";
import {  Button } from "antd";
import { useHistory } from "react-router";
import Text from "antd/lib/typography/Text";

const BotonDocumentoNombre = ({ documento }) => {

    let history = useHistory();
    //setEnvio(documento);
    
    const onClickMenuVerDetalle = () => {
                return (
                    history.push(`/notificacion-por-completar/${documento.id}`)
                );
        
    }

        return(

            documento.tipoDocumentoNombre?(<React.Fragment><Button block type="text" onClick={onClickMenuVerDetalle} style={{textAlign:"left" } }><Text strong>{documento.tipoDocumentoNombre}</Text>
            <br />{documento.descripcion}  </Button></React.Fragment>):<React.Fragment><Button block type="text" onClick={onClickMenuVerDetalle} style={{textAlign:"left" } }><Text strong>{documento.categoriaDocumento.nombre.replace(
                /\w\S*/g,
                function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()})}</Text><br />{documento.descripcion}</Button></React.Fragment>
)

}

export default BotonDocumentoNombre;