import React, { useContext, useEffect, useState } from 'react';
import { Form, Modal, Input, notification, Typography, Upload, Alert } from "antd";
import * as atoms from "store/local/contratos/DetalleSeguimientoContratoStore";
import { useAtom } from "jotai";
import DetalleEnvioService from "services/DetalleEnvioService";
import { SeguimientoService } from 'services/SeguimientoService';
import ParametrosService from "services/ParametrosService";
import CertificadoService from "services/CertificadoService";
import { LoginService } from "services/LoginService";
import { SecurityContext } from 'context/SecurityContextProvider';
import { EstadoCertificadoDigital } from "enums/EstadoCertificadoDigital";
import {detalleEnvioSeguimientoAtom, detalleEnvioSeleccionadoAtom,visibleModalActualizarDocumentoAtom} from "../../ListarDetalleSeguimientoStore"
import { SearchOutlined, FolderOpenOutlined, UploadOutlined } from "@ant-design/icons";

const { Text } = Typography;
const ModalActualizarDocumento = () => {
    const { Dragger } = Upload;
    const [form] = Form.useForm();

    const { getUsuarioAutenticado } = useContext(SecurityContext);

    const [loading, setLoading] = useState(false);
    //const [detalleEnvio, setDetalleEnvio] = useAtom(detalleEnvioSeguimientoAtom);
    const [detalleEnvioSeleccionado] = useAtom(detalleEnvioSeleccionadoAtom);
    const [mostrarAlerta, setMostrarAlerta] = useState(false);
    const [estado, setEstado] = useState("VIGENTE");
    //const [visibleModalActualizarCorreo, setVisibleModalActualizarCorreo] = useAtom(visibleModalActualizarCorreoAtom);
    const [visibleModalActualizarDocumento, setVisibleModalActualizarDocumento] = useAtom(visibleModalActualizarDocumentoAtom);
    const [listaDocumentos, setListaDocumentos] = useState([]);

    const datos = {
        // organizacionId: getUsuarioAutenticado().organizacionId,
        // envioId: getDatosEnvio().id,
        // formatoCargaId: getDatosEnvio().formatoCargaId,
      };
      //console.log("detalleEnvioSeleccionado11", detalleEnvioSeleccionado);
    useEffect(() => {
        const cargarCertificadoEstado = async () => {
            console.log("detalleEnvioSeleccionado", detalleEnvioSeleccionado);
          const seleccionMultiple = await ParametrosService.isSeleccionMultiple();
          if (!seleccionMultiple && visibleModalActualizarDocumento) {
            const estado = await CertificadoService.validarCertificadoEstado(
              getUsuarioAutenticado().empresaSeleccionada.id,
              detalleEnvioSeleccionado?.envio?.tipoDocumentoId,
              null
            );
            setMostrarAlerta(estado === "EXPIRADO" || estado === "SIN_CERTIFICADO");
            setEstado(estado);
          }
        };
        cargarCertificadoEstado();
      }, [detalleEnvioSeleccionado]);
    
    
    const onClickBtnActualizar = () => {
        // form
        //     .validateFields()
        //     .then(async (values) => {
        //         //form.resetFields();
        //         try {
        //             setLoading(true);
        //             console.log(detalleEnvioSeleccionado)
        //             const data = {
        //                 empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
        //                 detalleEnvioId: detalleEnvioSeleccionado.id,
        //                 correoElectronico: values.correoElectronico,
        //                 numeroCelular: values.numeroCelular,
        //             }

        //            // console.log("data", data);

        //             const respuesta = await SeguimientoService.actualizarCorreoYCelular(data);

        //             actualizarInformacionEnTablaDetalle(data.detalleEnvioId, data.correoElectronico, data.numeroCelular);

        //             // actualizarInformacionColaborador(documento.id, values.correoElectronico, values.numeroCelular);
        //             notification.success({
        //                 message: "Operación exitosa",
        //                 description: "Se actualizó el correo electrónico correctamente.",
        //             });

        //         } catch (error) {
        //             console.error(error);
        //         } finally {
        //             setVisibleModalActualizarCorreo(false);
        //             setLoading(false);
        //         }
        //     })
        //     .catch((info) => {
        //         console.log('Validate Failed:', info);
        //     });
    }

    const actualizarInformacionEnTablaDetalle = (detalleId, correoElectronico, numeroCelular) => {
        // const _documentos = [...detalleEnvio]; //siempre se debe generar un nuevo array
        // const indice = detalleEnvio.findIndex((detalle) => detalle.id === detalleId);
        // const detalleModificar = detalleEnvio[indice];
        
        // const detalleModificado = {
        //     ...detalleModificar,
        //     colaborador: {
        //         nombreCompleto:detalleModificar.colaborador.nombreCompleto,
        //         numeroDocumentoIdentidad:detalleModificar.colaborador.numeroDocumentoIdentidad,
        //         correoElectronico:correoElectronico,
        //         numeroCelular: numeroCelular,
        //         codigo:detalleModificar.colaborador.codigo
        //     }
        // }
        // _documentos.splice(indice, 1, detalleModificado);
        // setDetalleEnvio(_documentos);
    }

    const onClickBtnCerrar = () => {
        setVisibleModalActualizarDocumento(false);
    }

    // useEffect(() => {
    //     form.resetFields();
    // }, [detalleEnvioSeleccionado]);

    const props = {
        name: "file",
        multiple: true,
        action: process.env.REACT_APP_ENDPOINT + "envio/cargarDocumentoMasivo",
        headers: {
          Authorization: "Bearer " + LoginService.getJwtToken(),
          [Headers.ORGANIZACION_ID]: LoginService.getUsuarioAutenticado().organizacionId,
          [Headers.EMPRESA_ID]: LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
        },
        data: datos,
        onChange(info) {
          const { status } = info.file;
          // Garantiza solo la carga de un archivo
          let fileList = [...info.fileList];
          fileList = fileList.slice(-1);
    
          if (status !== "uploading") {
          }
          if (status === "done") {
    
            // setColaboradores(info.file.response.listaColaboradores);
            
            // const colaboradoresId = info.file.response.listaColaboradores?.filter((c) => c.estadoDocumentoEnvio == null)
            //   .map((c) => c.detalleEnvioId);
           
            // setDocumentosSeleccionados(colaboradoresId);
    
            // setRepresentanteLegal(info.file.response.representanteLegal);
            // setColaboradoresObservados(info.file.response.listaColaboradoresObservados);
    
            if (
              info.file.response.listaColaboradores?.length === 0 &&
              info.file.response.listaColaboradoresObservados === 0
            ) {
              notification.warning({
                message: "Advertencia",
                description:
                  "No se encontraron Colaboradores por favor revisar el archivo de carga - validar posición del número de documento.",
              });
            } else {
              notification.success({
                message: "Operación exitosa",
                description: `Se cargo correctamente el archivo: ${info.file.name}`,
              });
    
            //   if (info.file.response.listaColaboradores[0]?.tipoDocumentoNombre == null) {
            //     setHiddenNombre(true)
            //     setCantidadColaboradores(info.file.response.listaColaboradores?.length)
            //   } else
            //     setCantidadColaboradores(info.file.response.numeroColaboradores);
    
            //   setDeshabilitarBtnNotificar(false);
            //   setColaboradoresVisibles(true);
            }
          } else if (status === "error") {
            notification.error({
              message: "Error al cargar el archivo",
              description: "codigo: " + info.file.response.error + " - " + info.file.response.mensaje,
            });
          }
          setListaDocumentos(fileList);
        },
      };
    return (
        <Modal
            title="Actualizar Correo"
            open={visibleModalActualizarDocumento}
            onOk={onClickBtnActualizar}
            onCancel={onClickBtnCerrar}
            okText="Actualizar"
            cancelText="Cancelar"
            confirmLoading={loading}
            forceRender
            centered
        >

            <div
              style={{
                marginBottom: "30px",
                textAlign: "left",
              }}
            >
              <div>
                <Text strong style={{ fontSize: "15px" }}>
                  Cargar Documento Laboral
                </Text>
              </div>
              <br />
              {mostrarAlerta && (
                <div style={{ marginBottom: "10px" }}>
                  <Alert
                    message={
                      estado === EstadoCertificadoDigital.EXPIRADO ? (
                        <Text>
                          No puede cargar este documento porque su certificado digital se encuentra expirado.{" "}
                        </Text>
                      ) : (
                        <Text>No hay certificado registrado para está empresa</Text>
                      )
                    }
                    type="warning"
                    showIcon
                  />
                </div>
              )}
              <Dragger
                {...props}
                fileList={listaDocumentos}
                //accept={acceptedFiles}
                disabled={mostrarAlerta}
              >
                <p className="ant-upload-drag-icon">
                  <UploadOutlined />
                </p>
                <p className="ant-upload-text">Haga click ó arrastre su archivo hacia aquí para iniciar el proceso.</p>
              </Dragger>
            </div>

        </Modal>
    );
};

export default ModalActualizarDocumento;