import React, { useContext, useEffect, useState } from "react";
import { Form, Modal, Input, notification, DatePicker } from "antd";
import { useAtom } from "jotai";
//import { SeguimientoService } from 'services/SeguimientoService';
import { SecurityContext } from "context/SecurityContextProvider";
import {
  documentosProcesoAtom,
  documentoSeleccionadoAtom,
  visibleModalEditarAtom,
} from "../../DocumentosProcesoListarStore";
// import { moment } from 'formiojs/utils/utils';
import moment from "moment";
import useListarResponsables from "../../hooks/useListarResponsables";
import { DebounceSelect } from "components/DebounceSelect";
import { ProcesoService } from "services/reclutamiento-y-seleccion/ProcesoService";

const ModalActualizarDocumento = () => {
  const [form] = Form.useForm();

  const { getUsuarioAutenticado } = useContext(SecurityContext);

  const [loading, setLoading] = useState(false);
  const [detalleEnvio, setDetalleEnvio] = useAtom(documentosProcesoAtom);
  const [detalleEnvioSeleccionado, setDetalleSeleccionado] = useAtom(documentoSeleccionadoAtom);
  const [visibleModalActualizarDocumento, setVisibleModalActualizarDocumento] = useAtom(visibleModalEditarAtom);
  const { filtroResponsable, setFiltroResponsable, cargarResponsables } = useListarResponsables();

  const onClickBtnActualizar = () => {
    form
      .validateFields()
      .then(async (values) => {
        //form.resetFields();
        try {
          setLoading(true);
          const data = {
            documentoId: detalleEnvioSeleccionado.id,
            fechaLimite: moment(values.fechaLimite).format("DD/MM/YYYY"),
            responsable: {
              trabajador: values.responsable?.value === "TRABAJADOR",
              numeroDocumentoIdentidad: values.responsable?.value,
              nombreCompleto: values.responsable?.label,
            },
          };

          const respuesta = await ProcesoService.actualizarParametros(data);
          console.log(respuesta);
          actualizarInformacionEnTablaDetalle(data.documentoId, data.fechaLimite, data.responsable);

          // // actualizarInformacionColaborador(documento.id, values.correoElectronico, values.numeroCelular);
          notification.success({
            message: "Operación exitosa",
            description: "Se actualizó el correo electrónico correctamente.",
          });
        } catch (error) {
          console.error(error);
        } finally {
          form.resetFields();
          setVisibleModalActualizarDocumento(false);
          setLoading(false);
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const actualizarInformacionEnTablaDetalle = (detalleId, fechaLimite, responsable) => {
    const _documentos = [...detalleEnvio]; //siempre se debe generar un nuevo array
    const indice = detalleEnvio.findIndex((detalle) => detalle.id === detalleId);
    const detalleModificar = detalleEnvio[indice];

    const detalleModificado = {
      ...detalleModificar,
      fechaLimite: fechaLimite,
      responsable: responsable,
    };
    _documentos.splice(indice, 1, detalleModificado);
    setDetalleEnvio(_documentos);
  };

  const onClickBtnCerrar = () => {
    setDetalleSeleccionado();
    form.resetFields();
    setVisibleModalActualizarDocumento(false);
  };

  useEffect(() => {
    form.resetFields();
  }, [detalleEnvioSeleccionado]);

  return (
    <Modal
      title="Actualizar Datos Documento"
      visible={visibleModalActualizarDocumento}
      onOk={onClickBtnActualizar}
      onCancel={onClickBtnCerrar}
      okText="Actualizar"
      cancelText="Cancelar"
      confirmLoading={loading}
      forceRender
      destroyOnClose={true}
    >
      <Form
        form={form}
        layout="vertical"
        name="frmActualizarDocumento"
        initialValues={{
          fechaLimite: moment(detalleEnvioSeleccionado?.fechaLimite, "DD/MM/YYYY"),
          responsable: {
            key: detalleEnvioSeleccionado?.responsable?.numeroDocumentoIdentidad,
            label: detalleEnvioSeleccionado?.responsable?.nombreCompleto,
            value: detalleEnvioSeleccionado?.responsable?.numeroDocumentoIdentidad,
          },
        }}
      >
        <Form.Item
          name="fechaLimite"
          label="Fecha Limite"
          rules={[{ required: true, message: "Campo Fecha Limite es obligatorio." }]}
        >
          <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
        </Form.Item>

        <Form.Item
          name="responsable"
          label="Responsable"
          rules={[{ required: true, message: "Campo Responsable es obligatorio." }]}
        >
          <DebounceSelect
            allowClear
            showSearch
            placeholder="Escribe para buscar un responsable"
            value={filtroResponsable}
            fetchOptions={cargarResponsables}
            onChange={(newValue) => {
              setFiltroResponsable(newValue);
            }}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalActualizarDocumento;
