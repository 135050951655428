import { BaseService } from "./BaseService";
export const PlantillaEnvioDocumentoService = {
  async listar(login) {
    const respuesta = await BaseService.get("/plantillaEnvioDocumento", {
      params: {
        login: login,
      },
    });
    return respuesta.data;
  },
  async insertar(plantilla) {
    const respuesta = await BaseService.post("/plantillaEnvioDocumento", plantilla);
    return respuesta.data;
  },
  async obtenercodigo(empresaId) {
    const respuesta = await BaseService.get("/plantillaEnvioDocumento/obtenerCodigo", {
      params: {
        empresaId: empresaId,
      },
    });
    return respuesta;
  },
  async buscarPorId(id) {
    const respuesta = await BaseService.get(`/plantillaEnvioDocumento/${id}`);
    return respuesta.data;
  },
  async actualizar(plantilla) {
    const respuesta = await BaseService.put("/plantillaEnvioDocumento", plantilla);
    return respuesta.data;
  },
  async buscarPorNombre(empresaId, nombre) {
    const respuesta = await BaseService.get("/plantillaEnvioDocumento/existeNombre", {
      params: { empresaId: empresaId, nombre: nombre },
    });
    return respuesta.data;
  },
  async eliminar(id) {
    const respuesta = await BaseService.delete(`/plantillaEnvioDocumento/${id}`);
    return respuesta.data;
  },
  async listarTodosPorEmpresa(empresaId) {
    const respuesta = await BaseService.get("/plantillaEnvioDocumento/listar-por-empresa",{
      params: {
        empresaId: empresaId,
      },
    });
    return respuesta.data;
  },
};
