import React, { useEffect, useState } from "react";
import { Space, Select, Button, InputNumber } from "antd";
import { PaginaPdf } from "./PaginaPdf"
import { DndProvider } from "react-dnd"
import { Loading } from "../loading/Loading";
import { useModalFirmarStore } from "hooks/firma-documento-generico";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Document } from "react-pdf"
import { StepsFirmaDocumento } from "enums/StepsFirmaDocumento"

const StepPosicionFirma = () => {

    const urlPdf = useModalFirmarStore(state => state.urlPdf);
    const loading = useModalFirmarStore(state => state.loading);
    const numeroPagina = useModalFirmarStore(state => state.numeroPagina);
    const setPagina = useModalFirmarStore(state => state.setPagina);
    const setNumeroPagina = useModalFirmarStore(state => state.setNumeroPagina);
    const irAlPaso = useModalFirmarStore(state => state.irAlPaso);
    const finalizarLoading = useModalFirmarStore(state => state.finalizarLoading);

    const [totalPaginas, setTotalPaginas] = useState(undefined);
    const [visibleSeleccionarPaginas, setVisibleSeleccionarPaginas] = useState(false);

    async function onLoadSuccess(successEvent) {
        const page = await successEvent.getPage(numeroPagina);
        setNumeroPagina(numeroPagina);
        setPagina(page.view[3], page.view[2]);
        setTotalPaginas(successEvent.numPages);
        finalizarLoading();
    }

    const onClickSeleccionarPagina = (valor) => {

        switch (valor) {
            case 'PRIMERA-PAGINA':
                setNumeroPagina(1);
                setVisibleSeleccionarPaginas(false);
                break;

            case 'ULTIMA-PAGINA':
                setNumeroPagina(totalPaginas);
                setVisibleSeleccionarPaginas(false);
                break;

            case 'SELECCIONAR-PAGINA':
                setVisibleSeleccionarPaginas(true);
                break;

            default:
                setNumeroPagina(1);
        }
    }

    const onSeleccionarPagina = (pagina) => {
        setNumeroPagina(pagina);
    }

    return (
        <DndProvider backend={HTML5Backend}>

            { loading && <Loading />}


            <div style={{ height: '120px' }}>
                <div style={{ marginTop: '10px', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>
                    Ubicar Posición Firma
                </div>

                <div>
                    <Space>
                        <Select defaultValue="primera-pagina" style={{ width: 200 }} onChange={onClickSeleccionarPagina}>
                            <Select.Option value='PRIMERA-PAGINA'>Primera Página</Select.Option>
                            <Select.Option value='SELECCIONAR-PAGINA'>Seleccionar Página</Select.Option>
                            <Select.Option value='ULTIMA-PAGINA'>Última Página</Select.Option>
                        </Select>
                        {visibleSeleccionarPaginas && <InputNumber min={1} max={totalPaginas} defaultValue={numeroPagina} onChange={onSeleccionarPagina} />}
                    </Space>
                </div>

                <div style={{ marginTop: '10px', marginBottom: '10px', textAlign: 'center' }}>
                    <Button type="primary" onClick={() => irAlPaso(StepsFirmaDocumento.CERTIFICADO_DIGITAL)}>
                        {`Siguiente >`}
                    </Button>
                </div>

            </div>

            <Document file={urlPdf} onLoadSuccess={onLoadSuccess} loading={null}>
                <PaginaPdf numeroPagina={numeroPagina} />
            </Document>

        </DndProvider>
    )
}

export default StepPosicionFirma;