import React, { useEffect, useState } from "react";
import { Form, Input, Modal } from "antd";
import { useContext } from "react";
import { SecurityContext } from "context/SecurityContextProvider";
import SolicitudPersonalService from "services/reclutamiento-y-seleccion/SolicitudPersonalService";
import { solicitudAtom } from "../DetalleSeguimientoSolicitudPersonalStore";
import { useAtom } from "jotai";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ModalRechazarConvocatoria = ({ modalRechazar, setModalRechazar }) => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [solicitud, setSolicitud] = useAtom(solicitudAtom);
  const [formRechazar] = Form.useForm();
  let history = useHistory();

  useEffect(() => {
    if (modalRechazar) {
      formRechazar.setFieldsValue({
        id: solicitud.id,
        empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
        organizacionId: getUsuarioAutenticado().organizacionId,
      });
    }
  }, [modalRechazar]);

  const onClickRechazar = async (formulario) => {
    try {
      const _resp = await SolicitudPersonalService.rechazar(formulario);
      setSolicitud({ ...solicitud, estado: "RECHAZADO" });
      setModalRechazar(false);
      history.push("/reclutamiento-y-seleccion/solicitud-personal/solicitudes");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      title="Rechazar Solicitud"
      open={modalRechazar}
      onOk={() => formRechazar.submit()}
      onCancel={() => {
        setModalRechazar(false);
        formRechazar.resetFields();
      }}
      destroyOnClose
    >
      <Form layout="vertical" form={formRechazar} onFinish={onClickRechazar}>
        <Form.Item name="id" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="empresaId" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item name="organizacionId" noStyle>
          <Input type="hidden" />
        </Form.Item>
        <Form.Item
          name={"motivoRechazo"}
          label={"Motivo de Rechazo"}
          rules={[{ required: true, message: "Ingrese el motivo del rechazo." }]}
        >
          <Input.TextArea autoSize={{ minRows: 4, maxRows: 7 }} style={{ width: "100%" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalRechazarConvocatoria;
