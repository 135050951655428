import React, { useContext, useState } from "react";
import { Menu, Dropdown, Button, notification, Modal } from "antd";
import {
  EditOutlined,
  CaretDownOutlined,
  UnorderedListOutlined,
  SearchOutlined,
  StopOutlined,
  QuestionCircleOutlined,
  CheckCircleOutlined,
  MailOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { useModalVisorPdf } from "store/global";
import { FormularioService } from "services/FormularioService";
import { SecurityContext } from "context/SecurityContextProvider";
import { useAtom } from "jotai";
import * as atoms from "store/local/formularios/DetalleSeguimientoFormularioStore";
import { EstadoRegistro } from "enums/EstadoRegistro";
import { saveAs } from "file-saver";
import FormularioSeguimientoService from "services/FormularioSeguimientoService";
//import { ModalActualizarCorreo } from "../modal-actualizar-correo/ModalActualizarCorreo";

const MenuAcciones = ({ formulario }) => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal);
  const [loading, setLoading] = useState(false);
  const [envio] = useAtom(atoms.envio);
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [contratos, setContratos] = useAtom(atoms.formularios);
  const [, setVisibleModalActualizarCorreo] = useAtom(
    atoms.visibleModalActualizarCorreo
  );
  const [, setFormularioSeleccionado] = useAtom(atoms.formularioSeleccionado);

  const onClickMenu = async ({ key }) => {
    if (key === "ver-documento") {
      await onClickBtnVerDocumento();
    } else if (key === "reenviar-notificacion") {
      //await onClickBtnReenviarNotificacion();
    } else if (key === "inhabilitar-contrato") {
      // onClickBtnInhabilitarContrato();
    } else if (key === "habilitar-contrato") {
      //onClickBtnHabilitarContrato();
    } else if (key === "actualizar-correo") {
      setFormularioSeleccionado(formulario);
      //setVisibleMenu(false);
      //setVisibleModalActualizarCorreo(true);
    }
  };

  const onClickBtnVerDocumento = async () => {
    try {
      setLoading(true);
      setVisibleMenu(false);
      const respuesta = await FormularioSeguimientoService.getUrlDocumento(
        getUsuarioAutenticado().empresaSeleccionada.id,
        formulario.id
      );
      mostrarModalVisorPdf(
        respuesta.urlDocumento,
        envio.descripcion,
        true,
        true
      );
    } catch (error) {
      setVisibleMenu(false);
      console.error(error);
    } finally {
      setLoading(false);
    }

    // try {
    //     setLoading(true);
    //     setVisibleMenu(false);

    //     const formData = new FormData();
    //     formData.append("detalleEnvioId", formulario.id);
    //     formData.append("empresaId", getUsuarioAutenticado().empresaSeleccionada.id);

    //     let respuesta = await FormularioSeguimientoService.verDocumentoPdf(formData);
    //     const filename = respuesta.headers.filename;

    //     saveAs(respuesta.data, filename);
    // } catch (error) {
    //     setVisibleMenu(false);
    //     console.error(error);
    // } finally {
    //     setLoading(false);
    // }
  };

  const onClickBtnReenviarNotificacion = async () => {
    Modal.confirm({
      bodyStyle: { display: "flex", flexDirection: "column" },
      icon: <QuestionCircleOutlined style={{ fontSize: "32px" }} />,
      content: (
        <span
          style={{ textAlign: "center", display: "block", fontSize: "16px" }}
        >
          ¿Está seguro de <b>reenvíar la notificación</b> del contrato
          seleccionado?
        </span>
      ),
      autoFocusButton: "cancel",
      centered: true,
      maskClosable: true,
      cancelText: "No, cerrar ventana",
      okText: "Si, envíar notificación",
      onOk: async () => {
        try {
          setLoading(true);
          // await ContratoService.reenviarNotificacionMasivamente(
          //     getUsuarioAutenticado().organizacionId,
          //     getUsuarioAutenticado().empresaSeleccionada.id,
          //     envio.id,
          //     [contrato.id]
          // );
          setVisibleMenu(false);
          notification.success({
            message: `Notificación`,
            description: "Se reenvió la notificación exitosamente.",
            placement: "topRight",
          });
        } catch (error) {
          console.error(error);
        } finally {
          setLoading(false);
        }
      },
    });
    setVisibleMenu(false);
  };

  const onClickBtnInhabilitarContrato = () => {
    Modal.confirm({
      bodyStyle: { display: "flex", flexDirection: "column" },
      icon: <QuestionCircleOutlined style={{ fontSize: "32px" }} />,
      content: (
        <span
          style={{ textAlign: "center", display: "block", fontSize: "16px" }}
        >
          ¿Está seguro de <b>inhabilitar</b> el contrato seleccionado?
        </span>
      ),
      autoFocusButton: "cancel",
      centered: true,
      maskClosable: true,
      cancelText: "No, cerrar ventana",
      okText: "Si, inhabilitar contrato",
      onOk: async () => {
        try {
          // const contratoActualizado = await ContratoService.inhabilitar(
          //     getUsuarioAutenticado().empresaSeleccionada.id,
          //     contrato.id
          // );
          // setContratos(contratos.map((contrato) => {
          //     if (contrato.id === contratoActualizado.id) {
          //         return contratoActualizado;
          //     }
          //     return contrato;
          // }))
          notification.success({
            message: `Notificación`,
            description: "Se inhabilitó el contrato exitosamente.",
            placement: "topRight",
          });
          //console.log("contratoActualizado", contratoActualizado);
        } catch (error) {
          console.error(error);
        }
      },
    });
    setVisibleMenu(false);
  };

  const onClickBtnHabilitarContrato = () => {
    Modal.confirm({
      bodyStyle: { display: "flex", flexDirection: "column" },
      icon: <QuestionCircleOutlined style={{ fontSize: "32px" }} />,
      content: (
        <span
          style={{ textAlign: "center", display: "block", fontSize: "16px" }}
        >
          ¿Está seguro de <b>habilitar</b> el contrato seleccionado?
        </span>
      ),
      autoFocusButton: "cancel",
      centered: true,
      maskClosable: true,
      cancelText: "No, cerrar ventana",
      okText: "Si, habilitar contrato",
      onOk: async () => {
        try {
          // const contratoActualizado = await ContratoService.habilitar(
          //     getUsuarioAutenticado().empresaSeleccionada.id,
          //     contrato.id
          // );
          // setContratos(contratos.map((contrato) => {
          //     if (contrato.id === contratoActualizado.id) {
          //         return contratoActualizado;
          //     }
          //     return contrato;
          // }))

          notification.success({
            message: `Notificación`,
            description: "Se habilitó el contrato exitosamente.",
            placement: "topRight",
          });
          // console.log("contratoActualizado", contratoActualizado);
        } catch (error) {
          console.error(error);
        }
      },
    });
    setVisibleMenu(false);
  };

  const menu = () => {
    if (formulario.estadoRegistro === EstadoRegistro.INACTIVO) {
      return (
        <Menu onClick={onClickMenu}>
          <Menu.Item key="ver-documento">
            {/* <Button type="link" onClick={onClickBtnVerDocumento}> */}
            <SearchOutlined /> Ver Documento
            {/* </Button> */}
          </Menu.Item>
          <Menu.Item key="habilitar-contrato">
            {/* <Button type="link" onClick={onClickBtnHabilitarContrato}> */}
            <CheckCircleOutlined /> Habilitar Contrato
            {/* </Button> */}
          </Menu.Item>
        </Menu>
      );
    }

    return (
      <Menu onClick={onClickMenu}>
        <Menu.Item key="ver-documento">
          {/* <Button type="link" onClick={onClickBtnVerDocumento}> */}
          <SearchOutlined /> Ver Documento
          {/* </Button> */}
        </Menu.Item>
        <Menu.Item key="reenviar-notificacion">
          {/* <Button type="link" onClick={onClickBtnReenviarNotificacion}> */}
          <MailOutlined /> Reenviar Notificación
          {/* </Button> */}
        </Menu.Item>
        <Menu.Item key="inhabilitar-contrato">
          {/* <Button type="link" onClick={onClickBtnInhabilitarContrato}> */}
          <StopOutlined /> Inhabilitar Contrato
          {/* </Button> */}
        </Menu.Item>
        <Menu.Item key="actualizar-correo">
          {/* <Button type="link" onClick={onClickBtnInhabilitarContrato}> */}
          <EditOutlined /> Actualizar correo
          {/* </Button> */}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <React.Fragment>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        placement="bottomCenter"
        onVisibleChange={(flag) => setVisibleMenu(flag)}
        visible={visibleMenu}
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <UnorderedListOutlined
            style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }}
          />
          <CaretDownOutlined
            style={{
              paddingLeft: "2px",
              fontSize: "12px",
              fontWeight: "bold",
              color: "#1678c1",
            }}
          />
        </a>
      </Dropdown>
    </React.Fragment>
  );
};

export default MenuAcciones;
