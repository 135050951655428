import React, { useEffect } from "react";
import { Modal, Form, Select, Input, Checkbox, Button, message } from "antd";
import { useAtom } from "jotai";
import {
  visibleModalDocumentosAtom,
  documentosAtom,
  documentoEditadoAtom,
  documentoSeleccionadoAtom,
  filtroDocumentoAtom,
} from "../../PlantillaEditarStore";
import { useListarTipoDocumento, useListarUsuarios } from "../../hooks";
import { useState } from "react";
import { EtapaDocumento } from "enums/EtapaDocumento";
import { DebounceSelect } from "components/DebounceSelect";
import { useListarResponsables } from "pages/seleccion/plantillas/commons";

const ModalDocumentos = () => {

  const [visible, setVisible] = useAtom(visibleModalDocumentosAtom);
  const [formModalDocumentos] = Form.useForm();
  const { tiposDocumento } = useListarTipoDocumento();  
  const { filtroResponsable, setFiltroResponsable, cargarResponsables } = useListarResponsables();
  const [documentos, setDocumentos] = useAtom(documentosAtom);
  const [etapaSeleccionada, setEtapaSeleccionada] = useState([]);
  const [documentoEditado, setDocumentoEditado] = useAtom(documentoEditadoAtom);
  const [documentoSeleccionado, setDocumentoSeleccionado] = useAtom(documentoSeleccionadoAtom);  
  const [tipoDocumentoNombre, setTipoDocumentoNombre] = useState(undefined);

  useEffect(() => {
    const cargarDatos = () => {
      if (documentoEditado) {
        formModalDocumentos.setFieldsValue({
          keyPrev: documentoSeleccionado.id,
          documento: documentoSeleccionado.id,
          plazo: documentoSeleccionado.plazoEnDias,
          caducidad: documentoSeleccionado.caducidad,
          obligatorio: documentoSeleccionado.obligatorio,
          etapa: documentoSeleccionado.etapa?.id,
          responsable: {
            trabajador: documentoSeleccionado.responsable.trabajador,
            label: documentoSeleccionado.responsable.nombreCompleto,
            value: documentoSeleccionado.responsable.numeroDocumentoIdentidad,
          },
          index:documentoSeleccionado.index,
        });
        setEtapaSeleccionada(documentoSeleccionado.etapa);        
        setTipoDocumentoNombre(documentoSeleccionado.nombre);
      }
    };
    cargarDatos();
  }, [documentoEditado, documentoSeleccionado]);

  const onCancelModal = () => {
    setDocumentoEditado(false);
    setDocumentoSeleccionado();
    setVisible(false);    
    setTipoDocumentoNombre();
    formModalDocumentos.resetFields();
  };
  const onChangeEtapa = (value, Option) => {
    const etapa = {
      id: Option.value,
      nombre: Option.children,
    };
    setEtapaSeleccionada(etapa);
  };

  //   const onSearchDocumentos=(value)=>{
  //     setFiltroDocumento(value);
  //  }

  const onChangeTipoDocumentoSeleccionado = (value, option) => {
    setTipoDocumentoNombre(option.children);
  };

  const onClickCrear = () => {
    let indiceMayor=-1;
    formModalDocumentos
      .validateFields()
      .then((form) => {
          documentos.map((documento)=>{
            documento.index>indiceMayor && (indiceMayor=documento.index)
        })
        const documentoAgregado = {
          keyPrev: form.keyPrev,
          id: form.documento,
          nombre: tipoDocumentoNombre,
          plazoEnDias: form.plazo,
          caducidad: form.caducidad,
          obligatorio: form.obligatorio,
          etapa: etapaSeleccionada,
          responsable: {
            trabajador: form.responsable.value === "TRABAJADOR" ? true : false,
            numeroDocumentoIdentidad: form.responsable.value === "TRABAJADOR" ? "TRABAJADOR" : form.responsable.value,
            nombreCompleto: form.responsable.value === "TRABAJADOR" ? "TRABAJADOR" : form.responsable.label,
          },
          index:documentoEditado ?(form.index):(documentos.length>0 ? (indiceMayor+1):0),
        };

        const existeControl = documentos.find((d) => d.id === documentoAgregado.id);
        if (existeControl && existeControl.id !== form.keyPrev) {
          message.error("Ya existe un documento con ese nombre");
        } else {
          if (documentoEditado) {
            setDocumentos(
              documentos.map((documento) => {
                if (documento.id === documentoAgregado.keyPrev) {
                  return { ...documento, ...documentoAgregado };
                }
                return documento;
              })
            );
          } else {
            setDocumentos([...documentos, documentoAgregado]);
          }

          onCancelModal();
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  return (
    <Modal title="Agregar Documento" visible={visible} footer={null} onCancel={onCancelModal}>
      <Form layout="vertical" form={formModalDocumentos}>
        <Form.Item name="keyPrev" hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item name="index" hidden={true}>
           <Input />
        </Form.Item>
        <Form.Item
          name="documento"
          label="Documentos"
          rules={[{ required: true, message: "El campo documento es requerido" }]}
        >
          <Select
            placeholder="Seleccione el documento"
            showSearch
            //onSearch={onSearchDocumentos}
            onChange={onChangeTipoDocumentoSeleccionado}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {tiposDocumento.length > 0 &&
              tiposDocumento.map((documento) => {
                return (
                  <Select.Option value={documento.id} key={documento.id}>
                    {documento.nombre}
                  </Select.Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          name="plazo"
          label="Plazo (días hábiles)"
          rules={[{ required: true, message: "El campo plazo es requerido",whitespace:true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="responsable"
          label="Responsable"
          rules={[{ required: true, message: "El campo responsable es requerido" }]}
        >
          <DebounceSelect
            allowClear
            showSearch
            placeholder="Escribe para buscar un responsable"
            value={filtroResponsable}
            fetchOptions={cargarResponsables}
            onChange={(newValue) => {
              setFiltroResponsable(newValue);
            }}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item
          name="etapa"
          label="¿En que etapa debe estar disponible este documento?"
          rules={[{ required: true, message: "El campo etapa es requerido" }]}
        >
          <Select placeholder="Seleccione" onChange={onChangeEtapa}>
            <Select.Option value={EtapaDocumento.POSTULANTE.id}>{EtapaDocumento.POSTULANTE.nombre}</Select.Option>
            <Select.Option value={EtapaDocumento.SELECCIONADO.id}>{EtapaDocumento.SELECCIONADO.nombre}</Select.Option>
            <Select.Option value={EtapaDocumento.CONTRATADO.id}>{EtapaDocumento.CONTRATADO.nombre}</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="caducidad" valuePropName="checked">
          <Checkbox>¿Documento tiene caducidad?</Checkbox>
        </Form.Item>
        <Form.Item name="obligatorio" valuePropName="checked">
          <Checkbox>¿Documento es obligatorio?</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={onClickCrear}>
            Agregar Documento
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default ModalDocumentos;
