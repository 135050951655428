import React, { useState } from "react";
import { Button, Col, Form, Input, message, Modal, Row, Table } from "antd";
import { PostulantesService } from "services/reclutamiento-y-seleccion";
import { ConvocatoriaService } from "services/reclutamiento-y-seleccion/ConvocatoriaService";

const ModalAgregarPostulante = ({
  modalAgregarPostulante,
  setModalAgregarPostulante,
  setDrawCrearPostulante,
  convocatoria,
  agregarPostulante,
}) => {
  const [formCrearPostulante] = Form.useForm();
  const [postulantes, setPostulantes] = useState([]);

  const columns = [
    {
      title: "Doc. Identidad",
      dataIndex: "numeroDocumentoIdentidad",
      key: "numeroDocumentoIdentidad",
      width: "35%",
    },
    {
      title: "Postulante",
      dataIndex: "nombreCompleto",
      key: "nombreCompleto",
      width: "45%",
    },
    {
      title: "Acción",
      dataIndex: "accion",
      key: "accion",
      width: "20%",
      align: "center",
      render: (_, item) => (
        <Button onClick={() => agregarColaborador(item)} type="link">
          Seleccionar
        </Button>
      ),
    },
  ];

  const agregarColaborador = async (item) => {
    try {
      if (convocatoria.postulantes !== undefined) {
        const postulanteExistente = convocatoria.postulantes.filter((p) => p.id === item.id);
        if (postulanteExistente.length > 0) {
          message.error("El postulante ya se encuentra registrado en la convocatoria.");
          return;
        }
      }

      const data = {
        convocatoriaId: convocatoria?.convocatoriaId,
        postulanteId: item.id,
      };

      await ConvocatoriaService.agregarPostulante(data);

      const dataPostulante = {
        id: item.id,
        nombreCompleto: item.nombreCompleto,
      };

      agregarPostulante(dataPostulante);

      setDrawCrearPostulante(false);
      setModalAgregarPostulante(false);
      limpiarPanel();
    } catch (error) {
      console.log(error);
    }
  };

  const onClickBtnBuscar = () => {
    formCrearPostulante.validateFields().then(async (formulario) => {
      const respuesta = await PostulantesService.filtrarPorNombreOrDni({
        filtro: formulario.filtro.trim(),
      });
      setPostulantes(respuesta.postulantes);
    });
  };

  const limpiarPanel = () => {
    formCrearPostulante.resetFields();
    setPostulantes([]);
    setModalAgregarPostulante(false);
  };

  return (
    <Modal
      title="Agregar Postulante"
      width={"60%"}
      open={modalAgregarPostulante}
      footer={
        <Button type="primary" onClick={() => limpiarPanel()}>
          Cerrar
        </Button>
      }
      onCancel={() => limpiarPanel()}
      destroyOnClose
    >
      <Form form={formCrearPostulante} layout="vertical" size="middle" style={{ padding: "0 15px" }}>
        <Row
          gutter={16}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Col span={12}>
            <Form.Item
              label="Filtrar por nombre y/o apellidos:"
              name="filtro"
              rules={[
                {
                  required: true,
                  message: "Ingrese al menos un nombre y/o apellido",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Button block onClick={() => onClickBtnBuscar()}>
              Buscar
            </Button>
          </Col>
          <Col span={6}>
            <Button block onClick={() => setDrawCrearPostulante(true)}>
              Crear
            </Button>
          </Col>
        </Row>
      </Form>
      <Table columns={columns} rowKey={(data) => data.id} dataSource={postulantes} />
    </Modal>
  );
};

export default ModalAgregarPostulante;
