import React from "react";
import { Container } from "components/Container";
import {UnorderedListOutlined} from "@ant-design/icons";
import {Botones} from "./componentes";
import {TablaDescargaMasiva} from "./componentes";

const DescargaMasivaListarPage = () => {
 
  return(
    <Container icono={<UnorderedListOutlined/>} titulo="Descarga Masiva"  botonExtra={<Botones/>}>
        <TablaDescargaMasiva/>
    </Container>
)
};

export default DescargaMasivaListarPage;
