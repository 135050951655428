import React from "react";
import { Document } from "react-pdf";
import { errorMostradoAtom, loadingAtom, totalPaginasAtom, urlPdfAtom } from "../../VisorFormularioStorage";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { PaginaPdf } from "./componentes";

const Visor = () => {
  const [totalPaginas, setTotalPaginas] = useAtom(totalPaginasAtom);
  const errorMostrado = useAtomValue(errorMostradoAtom);
  const urlPdf = useAtomValue(urlPdfAtom);
  const [, setLoading] = useAtom(loadingAtom);

  const onLoadSuccess = async (successEvent) => {
    setTotalPaginas(successEvent.numPages);
    setLoading(false);
  };

  return (
    <div
      className="visor-formulario-generico-body"
      style={{ height: errorMostrado ? "calc(100% - 72px)" : "calc(100% - 32px)" }}
    >
      <Document file={urlPdf} onLoadSuccess={onLoadSuccess} loading={null}>
        {Array.from(new Array(totalPaginas), (_, index) => (
          <PaginaPdf key={`page_${index + 1}`} numeroPagina={index + 1} />
        ))}
      </Document>
    </div>
  );
};

export default Visor;
