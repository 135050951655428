import React,{useEffect,useContext} from "react";
import TipoDocumentoService from "services/TipoDocumentoService";
import {tipoDocumentosAtom,filtroDocumentoAtom} from "../PlantillaCrearStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import { CategoriaDocumento } from "enums/CategoriaDocumento";

const useListarTipoDocumento=()=>{

    const[tiposDocumento,setTiposDocumento]=useAtom(tipoDocumentosAtom);
    const {getUsuarioAutenticado}=useContext(SecurityContext);
   // const[filtroDocumento,setFiltroDocumento] = useAtom(filtroDocumentoAtom);

  useEffect(()=>{
       const cargarDatos = async()=>{
           //const documentos= await TipoDocumentoService.listar(getUsuarioAutenticado().empresaSeleccionada.id,CategoriaDocumento.FORMULARIOS.id); 
         //  if(filtroDocumento!==undefined){
            const documentos= await TipoDocumentoService.listarFormularioYCargaDocumento();      
            setTiposDocumento(documentos);
          // }
          
       }
       cargarDatos();
  },[]);
  return{tiposDocumento}
}
export default useListarTipoDocumento;