import { useContext, useEffect } from "react";
import { SolicitudAusenciaService } from "services";
import { ApiResponse } from "enums";
import { useAtom } from "jotai";
import { solicitudesAusenciaPendientesAtom } from "../SolicitudPendienteAprobacionStore";
import { SecurityContext } from "context/SecurityContextProvider";

const useListarSolicitudesAusenciaPorGestionHumana = (estado) => {
  const [solicitudesAusencia, setSolicitudesAusencia] = useAtom(solicitudesAusenciaPendientesAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);

  useEffect(() => {
    const cargarDatos = async () => {
      const solicitudesAusencia = await SolicitudAusenciaService.listarPorGestionHumana({
        numeroDocumentoIdentidad: getUsuarioAutenticado().numeroDocumentoIdentidad,
        estado: estado,
      });
      if (solicitudesAusencia.estado === ApiResponse.EXITO) {
        setSolicitudesAusencia(solicitudesAusencia.data);
        //console.log("solicitudes pendientes:",solicitudesAusencia.data);
      }
    };

    cargarDatos();
  }, []);

  const cargarSolicitudesAusenciaPendientes = async()=>{
    const solicitudesAusencia = await SolicitudAusenciaService.listarPorGestionHumana({
      numeroDocumentoIdentidad: getUsuarioAutenticado().numeroDocumentoIdentidad,
      estado: estado,
    });
    if (solicitudesAusencia.estado === ApiResponse.EXITO) {
      setSolicitudesAusencia(solicitudesAusencia.data);
     
    }
  }

  return { solicitudesAusencia,cargarSolicitudesAusenciaPendientes };
};

export default useListarSolicitudesAusenciaPorGestionHumana;
