import React, { useContext, useEffect, useState } from "react";
import { Table} from "antd";
import * as atoms from "../ListarPerfilStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import  PerfilService from "../../../../services/PerfilService";
import { MenuAcciones } from "./MenuAcciones";

const TablaPerfil=()=>{

    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [loading, setLoading] = useState(false);
    const [perfiles, setPerfiles] = useAtom(atoms.perfilesAtom);

    useEffect(()=>{
        const fetch = async ()=>{
            try {
             setLoading(true);                    
             const _perfiles = await PerfilService.listar(getUsuarioAutenticado().empresaSeleccionada.id);
             setPerfiles(_perfiles);                        
             
            } catch (error) {
                console.error(error);
                
            } finally{
                setLoading(false);
            }

        }
        fetch(); 
    },[]);  
    const columnasPerfiles=[
        {
            title:"Nombre",
            dataIndex:"nombre",   
            align:"center"             
        },
        
        {
            title:"Acciones",
            key:"acciones",
            align:"center",
            render:(fila)=>
                <MenuAcciones
                  perfil={fila}
                  perfiles={perfiles}
                  setPerfiles={setPerfiles}
                />            
        },
        
    ];
    return(
        <Table
            loading={loading}
            rowKey={(perfil)=>perfil.id}
            columns={columnasPerfiles}
            dataSource={perfiles}
            pagination={false}
        
        
        />
    )

}
export default TablaPerfil;