import React from "react";
import {Modal,Button,Typography,Row} from "antd";
import { useAtom } from "jotai";
import {visibleModalLinkAtom,enlacePostulanteAtom} from "../../ProcesosListarStore";
import {CopyOutlined} from "@ant-design/icons";
const ModalObtenerLink=()=>{
    const[visible,setVisible]=useAtom(visibleModalLinkAtom);
    const[enlacePostulante,setEnlacePostulante]=useAtom(enlacePostulanteAtom);
    const onCancel=()=>{
        setVisible(false);
    }
    const onClickCopiar=async()=>{        
          await navigator.clipboard.writeText(enlacePostulante);        
       
    }
    return(
        <Modal
          title="Link Convocatoria"
          visible={visible}
          onCancel={onCancel}
          footer={null}
        >
            <Row>Enlace:</Row>
            <span><Typography.Text code>{enlacePostulante && enlacePostulante}</Typography.Text></span>
            <br/>
            <Row><Button icon={<CopyOutlined />} onClick={onClickCopiar}>Copiar</Button></Row> 
        </Modal>
    )

}
export default ModalObtenerLink;