import React, { useState, useContext } from "react";
import { Modal, Button, Input, Form, Row } from "antd";
import TipoDocumentoService from "services/TipoDocumentoService";
import { SecurityContext } from "context/SecurityContextProvider";
import { CategoriaDocumento } from "enums/CategoriaDocumento";
import {tipoDocumentoSeleccionadoAtom} from  "../../NotificarComunicadoStore";
import { useAtom } from "jotai";

export function ModalAgregarTipoDocumento({ visible,
    setVisible, setTipoDocumentos }) {
    const [frmCrearTipoDocumento] = Form.useForm();
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const[tipoDocumentoSeleccionado,setTipoDocumentoSeleccionado]=useAtom(tipoDocumentoSeleccionadoAtom);

    function onClickCerrarModal() {
        setVisible(false);
    }
    async function onClickAgregarTipoDocumento(formulario) {
        try {
            formulario.empresaId = getUsuarioAutenticado().empresaSeleccionada.id;
            formulario.categoriaDocumento = {
                id: CategoriaDocumento.COMUNICADOS.id,
                nombre: CategoriaDocumento.COMUNICADOS.nombre,
            };
            const tipoDocumentoInsertado=await TipoDocumentoService.insertarNuevo(formulario);
            
            onClickCerrarModal();
            // const _tiposDocumento = await TipoDocumentoService.listar(
            //     getUsuarioAutenticado().empresaSeleccionada.id,
            //     CategoriaDocumento.COMUNICADOS.id
            // );
            // setTipoDocumentos(_tiposDocumento);
            const _tipoDocumento = await TipoDocumentoService.listarPorUsuario(getUsuarioAutenticado().login);
            let documentoListado=null;
         if (_tipoDocumento!==null){
            documentoListado = _tipoDocumento.filter(f=> f.categoriaDocumento.nombre==="COMUNICADOS");
            setTipoDocumentos(documentoListado);
            
        }else{
            documentoListado = await TipoDocumentoService.listar(
              getUsuarioAutenticado().empresaSeleccionada.id,CategoriaDocumento.COMUNICADOS.id);
              setTipoDocumentos(documentoListado);
        }

        if(documentoListado!=null){
            if(documentoListado.filter(c=> c.id===tipoDocumentoInsertado.id).length>0){
                setTipoDocumentoSeleccionado(tipoDocumentoInsertado.id);
            }
        
        }
            
            

        } catch (error) {
            console.error(error);
        }


    }
    function onLimpiarDatos() {
        frmCrearTipoDocumento.setFieldsValue({
            nombre: "",
        })

    }

    return (
        <Modal
            title="Tipo Documento"
            visible={visible}
            onCancel={onClickCerrarModal}
            //destroyOnClose={false}
            afterClose={onLimpiarDatos}
            // maskClosable={false}
            footer={null}
            //forceRender

        >

            <Form
                onFinish={onClickAgregarTipoDocumento}
                form={frmCrearTipoDocumento}
            >
                <Form.Item
                    label="Tipo Documento"
                    name="nombre"
                    //valuePropName="tipoDocumento"
                    rules={[{ required: true, message: "Ingrese el tipo documento" }]}
                >
                    <Input autoComplete="off" />


                </Form.Item>
                <Form.Item>
                    <Row justify="center">
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            Agregar
                        </Button>
                    </Row>
                </Form.Item>

            </Form>


        </Modal>
    );


}