import { BaseService } from "./BaseService";
import { LoginService } from "./LoginService";
import qs from "qs";
import { Headers } from "enums";

export const ContratoService = {
  async insertar(contrato) {
    const respuesta = await BaseService.post("/contrato", contrato);
    return respuesta.data;
  },

  async listar(empresaId, envioId, estado, colaborador, documentoIdentidad) {
    const respuesta = await BaseService.get("/contrato", {
      params: {
        empresaId: empresaId,
        envioId: envioId,
        estado: estado,
        colaborador: colaborador,
        documentoIdentidad: documentoIdentidad,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    });
    return respuesta.data;
  },

  async buscarEnvioPorId(envioId) {
    const respuesta = await BaseService.get(`/contrato/envio/${envioId}`);
    return respuesta.data;
  },

  async obtenerUrl(empresaId, detalleEnvioId) {
    const respuesta = await BaseService.get(
      `/contrato/empresa/${empresaId}/detalle-envio/${detalleEnvioId}/url`
    );
    return respuesta.data;
  },

  async limpiarDatosTramaCargada(empresaId, envioId) {
    const respuesta = await BaseService.post(
      `/contrato/empresa/${empresaId}/envio/${envioId}/limpiar-datos-trama-cargada`
    );
    return respuesta.data;
  },

  async notificar(empresaId, envioId, usuarioId, parametros) {
    const respuesta = await BaseService.post(
      `/contrato/empresa/${empresaId}/envio/${envioId}/usuario/${usuarioId}/notificar`,
      {
        navegador: parametros.navegador,
        navegadorVersion: parametros.navegadorVersion,
        os: parametros.os,
        osVersion: parametros.osVersion,
        ipEnvio: parametros.ipEnvio,
      }
    );
    return respuesta;
  },

  getUrlVistaPrevia(empresaId, detalleEnvioId) {
    const urlPdf =
      process.env.REACT_APP_ENDPOINT +
      `contrato/empresa/${empresaId}/detalle-envio/${detalleEnvioId}/vistaPrevia`;
    const httpHeaders = {
      Authorization: "Bearer " + LoginService.getJwtToken(),
      [Headers.ORGANIZACION_ID]:
        LoginService.getUsuarioAutenticado().organizacionId,
      [Headers.EMPRESA_ID]:
        LoginService.getUsuarioAutenticado().empresaSeleccionada.id,
    };

    return {
      url: urlPdf,
      httpHeaders: httpHeaders,
    };
  },

  async getUrlDocumento(empresaId, detalleEnvioId) {
    const respuesta = await BaseService.get(
      `/contrato/empresa/${empresaId}/detalle-envio/${detalleEnvioId}/url-pdf`
    );
    return respuesta.data;
  },

  async obtenerResumenEnvio(empresaId, envioId) {
    const respuesta = await BaseService.get(
      `/contrato/empresa/${empresaId}/envio/${envioId}/resumen`
    );
    return respuesta.data;
  },

  async reenviarNotificacion(empresaId, detalleEnvioId) {
    const respuesta = await BaseService.post(
      `/contrato/empresa/${empresaId}/detalleEnvio/${detalleEnvioId}/reenviar-notificacion`
    );
    return respuesta.data;
  },

  async inhabilitar(empresaId, detalleEnvioId) {
    const respuesta = await BaseService.post(
      `/contrato/empresa/${empresaId}/detalleEnvio/${detalleEnvioId}/inhabilitar`
    );
    return respuesta.data;
  },

  async habilitar(empresaId, detalleEnvioId) {
    const respuesta = await BaseService.post(
      `/contrato/empresa/${empresaId}/detalleEnvio/${detalleEnvioId}/habilitar`
    );
    return respuesta.data;
  },

  async reenviarNotificacionMasivamente(
    organizacionId,
    empresaId,
    envioId,
    listaContratosId
  ) {
    const respuesta = await BaseService.post(
      "/contrato/reenviar-notificacion-masiva",
      {
        organizacionId: organizacionId,
        empresaId: empresaId,
        envioId: envioId,
        listaContratosId: listaContratosId,
      }
    );
    return respuesta.data;
  },

  async reprocesar(organizacionId, empresaId, envioId, listaContratosId) {
    const respuesta = await BaseService.post("/contrato/reprocesar", {
      organizacionId: organizacionId,
      empresaId: empresaId,
      envioId: envioId,
      listaContratosId: listaContratosId,
    });
    return respuesta.data;
  },

  async actualizarCorreoYCelular(data) {
    const respuesta = await BaseService.post(
      "/contrato/actualizar-correo-y-celular",
      {
        empresaId: data.empresaId,
        detalleEnvioId: data.detalleEnvioId,
        correoElectronico: data.correoElectronico,
        numeroCelular: data.numeroCelular,
      }
    );
    return respuesta.data;
  },
};
