import React,{useContext} from "react";
import { Dropdown, Menu, Button, message } from "antd";
import { UnorderedListOutlined, CaretDownOutlined, SelectOutlined, FileExcelOutlined, FilePdfOutlined, CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { EnvioService } from "services/EnvioService";
import { useModalAsignarCarpeta } from "../../../../../store/global";
import * as atoms from "../../../../../store/local/contratos/DetalleContratoStore";
import { useAtom } from "jotai";
import { ModalAsignarCarpeta } from "components/Modal/ModalAsignarCarpeta";
import { SecurityContext } from "context/SecurityContextProvider";
import DetalleEnvioService from "services/DetalleEnvioService";
import { saveAs } from "file-saver";

const MenuAcciones = ({ envio }) => {

    let history = useHistory();
    const [contratoSeleccionado, setContratoSeleccionado] = useAtom(atoms.contratoSeleccionado);
    const mostrarModalAsignarCarpeta = useModalAsignarCarpeta(state => state.mostrarModal);
    const cerrarModalAsignarCarpeta = useModalAsignarCarpeta(state => state.cerrarModal);
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    const onClickMenu = (opcion) => {
        switch (opcion.key) {
            case 'ver-contratos':
                onClickMenuVerContratos();
                break;

            case 'generar-reporte-excel':
                onClickMenuGenerarReporteExcel();
                break;

            case 'generar-reporte-pdf':
                onClickMenuGenerarReportePdf();
                break;

            case 'eliminar':
                onClickMenuEliminar();
                break;
            case 'asignar-carpeta':
                onClickMenuAsignarCarpeta();
                break;
            default:
                console.error("No se encuentra esa opción");;
        }
    }

    const onClickMenuVerContratos = () => {
        history.push(`/contrato/seguimiento/${envio.id}/detalle`);
    }

    const onClickMenuGenerarReporteExcel = async () => {
        //console.log("generar-reporte-excel");
        try{
            const formData = new FormData();
            formData.append("envioId", envio.id);
            formData.append("empresaId", getUsuarioAutenticado().empresaSeleccionada.id);

            let respuesta = await DetalleEnvioService.descargarExcel(formData);
            const filename = respuesta.headers.filename;

            saveAs(respuesta.data, filename);
            
        } catch (error) {
            
            console.error(error);
        }
    }

    const onClickMenuGenerarReportePdf = async() => {
        //console.log("generar-reporte-pdf");
        try {    
            const formData = new FormData();        
            formData.append("envioId", envio.id);
            formData.append("empresaId", getUsuarioAutenticado().empresaSeleccionada.id);
      
            let respuesta = await DetalleEnvioService.descargarPDF(formData);
            const filename = respuesta.headers.filename;
      
            saveAs(respuesta.data, filename);
            
          } catch (error) {
            
            console.error(error);
          }
    }

    const onClickMenuEliminar = () => {
        console.log("eliminar");
    }
    const onClickMenuAsignarCarpeta = () => {
        //const envioObtenido = await EnvioService.buscarPorId(envio.id);
        //console.log("contrato", envio.carpeta.id);
        setContratoSeleccionado(envio);
        mostrarModalAsignarCarpeta();

    }
    function onClickBtnCancelarModal() {
        cerrarModalAsignarCarpeta();

    }

    const menu = () => {

        return (
            <Menu onClick={onClickMenu}>
                <Menu.Item key="ver-contratos">
                    <SelectOutlined /> Ver Contratos
                </Menu.Item>
                <Menu.Item key="generar-reporte-excel">
                    <FileExcelOutlined /> Generar Reporte Excel
                </Menu.Item>
                <Menu.Item key="generar-reporte-pdf">
                    <FilePdfOutlined /> Generar Reporte PDF
                </Menu.Item>
                <Menu.Item key="eliminar">
                    <CloseOutlined /> Eliminar
                </Menu.Item>
                <Menu.Item key="asignar-carpeta">
                    <SelectOutlined />Asignar Carpeta
                </Menu.Item>
            </Menu>
        )
    }

    return (
        <React.Fragment>
            <Dropdown
                overlay={menu}
                trigger={['click']}
                placement="bottomCenter">

                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <UnorderedListOutlined style={{ fontSize: '16px', fontWeight: 'bold', color: "#1678c1" }} />
                    <CaretDownOutlined style={{ paddingLeft: '2px', fontSize: '12px', fontWeight: 'bold', color: "#1678c1" }} />
                </a>

            </Dropdown>
            <ModalAsignarCarpeta
                onClickBtnCancelar={onClickBtnCancelarModal}
                documento={contratoSeleccionado}
            // carpetaId={contratoSeleccionado.carpeta.id}
            // carpetaDescripcion={contratoSeleccionado.carpeta.descripcion}
            />

        </React.Fragment>
    );
}

export default MenuAcciones;