import React, { useState, useEffect, useRef, Fragment } from "react";

import { Container } from "../../../components/Container";
import {
  UnorderedListOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import { Table, Space, Button, Popconfirm, notification, Input } from "antd";
import SedeService from "../../../services/SedeService";
import { useHistory } from "react-router-dom";
import { useContext } from "react";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import Highlighter from "react-highlight-words";
import ModalCargaSedes from "../carga-sedes";
import { saveAs } from "file-saver";

export function SedeListarPage() {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const [sedes, setSedes] = useState([]);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [visibleModal, setVisibleModal] = useState(false);
  let history = useHistory();
  
  useEffect(() => {
    async function cargarDatos() {
      const sedes = await SedeService.listar(
        getUsuarioAutenticado().empresaSeleccionada.id
      );
      setSedes(sedes);
    }
    cargarDatos();
  }, []);
  var adicionarColumnasFiltro = function getColumns(
    dataIndex,
    placeholder,
    propiedad
  ) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={searchInput}
              placeholder={
                placeholder !== undefined
                  ? placeholder
                  : `Buscar por ${dataIndex}`
              }
              value={selectedKeys[0]}
              onChange={(e) =>
                setSelectedKeys(e.target.value ? [e.target.value] : [])
              }
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Buscar
            </Button>
              <Button
                onClick={() => handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
              >
                Limpiar
            </Button>
            </Space>
          </div>
        ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        propiedad === undefined
          ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
          : record[dataIndex][propiedad]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={
              propiedad === undefined
                ? text?.toString()
                : text[propiedad]?.toString()
            }
          />
        ) : propiedad === undefined ? (
          text
        ) : (
              text[propiedad]
            ),
    };
  };
  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText("");
  }
  const columnas = [
    
    // {
    //   title: "Código",
    //   dataIndex: "codigo",
    //   align: "center",
    //   ...adicionarColumnasFiltro("codigo"),
    // },
    {
      title: "Nombre",
      dataIndex: "nombre",
      align: "center",
      ...adicionarColumnasFiltro("nombre"),
    },
    {
      title: "Acciones",
      align: "center",
      key: "acciones",
      align: "center",
      render: (fila) =>
        sedes.length >= 1 ? (
          <Space>
            <a
              onClick={() => handleOnClickEditarSede(fila.id)}
              className="custom-color-element"
            >
              <EditOutlined /> Editar
            </a>
            <Popconfirm
              title="¿Está seguro de eliminar el registro seleccionado?"
              okText="Si"
              cancelText="No"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => handleOnClickEliminarSede(fila.id)}
            >
              <a style={{ color: "#CE3E3E" }}>
                <DeleteOutlined /> Eliminar
              </a>
            </Popconfirm>
          </Space>
        ) : null,
    },
  ];
  function handleOnClickEditarSede(idSede) {
    history.push(`/sede/editar/${idSede}`);
  }

  async function handleOnClickEliminarSede(idSede) {
    await SedeService.eliminar(idSede);
    setSedes(sedes.filter((sede) => sede.id !== idSede));
    notification.success({
      message: "Operación exitosa",
      description: "Se eliminó el registro correctamente.",
    });
  }

  function handleOnClickCrearSede() {
    history.push("/sede/crear");
  }
  async function handleOnClickExportarSedes(){
    try {
      
      const formData=new FormData();
      formData.append("empresaId",getUsuarioAutenticado().empresaSeleccionada.id);
      let respuesta= await SedeService.descargarSedesExcel(formData);
      console.log("arhcivo",respuesta);
      const filename = respuesta.headers.filename;
      saveAs(respuesta.data, filename);      

     } catch (error) {
       notification.error({
         message:"No se puede exportar las Sedes",
       })
     }


  }
  function handleOnclickImportarSedes(){
    setVisibleModal(true);
  }
  async function cerrarModal(){
     setVisibleModal(false);
     const sedes = await SedeService.listar(
      getUsuarioAutenticado().empresaSeleccionada.id
    );
    setSedes(sedes);
  }
  function botones(){
    return(
      <React.Fragment>
        <Space>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleOnClickCrearSede}
            >
              Crear Centro de costo
            </Button>

            <Button 
              type="primary"
              icon={<UploadOutlined />}
              onClick={handleOnclickImportarSedes}
             >
              Importar Centros de costo
            </Button>    
                    
            <Button 
              type="primary"
              icon={<DownloadOutlined />}
              onClick={handleOnClickExportarSedes}
            >
              Exportar Centros de costo
            </Button>
            
            
        </Space>
        
      </React.Fragment>
    );
  }
  return (
    <Container icono={<UnorderedListOutlined />} titulo="Centro de costo" botonExtra={botones()}>
      {/* <div style={{ marginBottom: 16 }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={handleOnClickCrearSede}
        >
          Crear Sede
        </Button>
      </div> */}
      <Table
        rowKey={(sede) => sede.id}
        columns={columnas}
        dataSource={sedes}
        pagination={{
          pageSize: [10],
        }}
      />
      <ModalCargaSedes
        visibleModal={visibleModal}
        onCerrarModal={cerrarModal}
      />
    </Container>
  );
}
