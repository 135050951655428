import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { ListaSolicitudesAusenciaPendientes, ModalAprobarSolicitudAusencia,ModalRechazarSolicitudAusencia,ModalAdjuntos } from "./componentes";
import { ModalVisorPdf } from "components/Modal/ModalVisorPdf";
const SolicitudesPendientesAprobacionPage = () => {
  return (
    <JotaiProvider>
      <ListaSolicitudesAusenciaPendientes />
      <ModalVisorPdf />
      <ModalAprobarSolicitudAusencia />
      <ModalRechazarSolicitudAusencia/>
      <ModalAdjuntos/>
    </JotaiProvider>
  );
};

export default SolicitudesPendientesAprobacionPage;
