import React, { useContext, useEffect, useState } from "react";
import { SolicitudAusenciaService } from "services";
import {
  solicitudAusenciaSeleccionadaAtom,
  solicitudesAusenciaPendientesAtom,
  visibleModalAprobarSolicitudAtom,
} from "../../SolicitudPendienteAprobacionStore";
import { Button, Modal, Alert, Input, message,Divider,Row,Select,Checkbox,Form,Typography } from "antd";
import { useAtom } from "jotai";
import produce from "immer";
import { SecurityContext } from "context/SecurityContextProvider";
import { ApiResponse } from "enums";
import { archivosAdjuntosAtom } from "pages/tipoAbsentismo/crear/CrearTipoAbsentismoStore";
import { useListarFormularios } from "../../hooks";
import DetallEnvioService  from "../../../../../../services/DetalleEnvioService";
import {useListarSolicitudesAusenciaPorGestionHumana} from "../../hooks";

const ModalAprobarSolicitudAusencia = () => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const [, setSolicitudesPendientesAprobar] = useAtom(solicitudesAusenciaPendientesAtom);
  const [solicitudAusenciaSeleccionada] = useAtom(solicitudAusenciaSeleccionadaAtom);
  const [visibleModalAprobar, setVisibleModalAprobar] = useAtom(visibleModalAprobarSolicitudAtom);
  const [loading, setLoading] = useState(false);
  const [claveIngresada, setClaveIngresada] = useState("");
  const [mensajeError, setMensajeError] = useState(""); 

  const [formNotificarDocumento] =Form.useForm();   
  const [estado,setEstado] = useState(undefined);
  const {formularios} = useListarFormularios();
  const [nombreDocumento,setNombreDocumento]=useState("");
  const [estadoDocumento,setEstadoDocumento] = useState(undefined);  
  const { cargarSolicitudesAusenciaPendientes } = useListarSolicitudesAusenciaPorGestionHumana("PENDIENTE");

  useEffect(async() => {
    if (visibleModalAprobar) {
      setClaveIngresada("");
      setMensajeError("");   
      if(solicitudAusenciaSeleccionada?.requiereNotificarDocumentosAntesAprobacionGestionHumana &&         
           solicitudAusenciaSeleccionada?.detalleEnvioId===null){
             setEstado("notificar");
       }
      else{
          setEstado("aprobar");
      }
            
     if(solicitudAusenciaSeleccionada.detalleEnvioId!==null) {
     
        const detalleEnvio = await DetallEnvioService.buscarPorId(solicitudAusenciaSeleccionada.detalleEnvioId);

        if(detalleEnvio.estadoVistaColaborador!=="FIRMADO"){
          setEstadoDocumento("NoFirmado");
          setNombreDocumento(detalleEnvio.nombreDocumento);
        }
      }
    }
  }, [visibleModalAprobar]);

  const onClickBtnAprobar = async () => {

    if(estado==="aprobar"){      
      setLoading(true);
      const response = await SolicitudAusenciaService.aprobarPorGestionHumana({
      solicitudAusenciaId: solicitudAusenciaSeleccionada.id,
      numeroDocumentoIdentidad: getUsuarioAutenticado().numeroDocumentoIdentidad,
      clave: claveIngresada,
     });
    if (response.estado === ApiResponse.EXITO) {
        message.success("Se ha aprobado la solicitud correctamente.");
        //setVisibleModalAprobar(false);
        onClickBtnCancelar();
        setSolicitudesPendientesAprobar(
        produce((draft) => {
          const index = draft.findIndex((solicitud) => solicitud.id === solicitudAusenciaSeleccionada.id);
          draft.splice(index, 1);
        })
      );
    } else {
      setMensajeError(response.mensaje);
    }
    setLoading(false);
    }

    else if(estado==="notificar"){
      
      if(formNotificarDocumento.getFieldValue("formularioConvenio")!==undefined){        
        setLoading(true);
        const response= await SolicitudAusenciaService.notificarDocumento({
          solicitudAusenciaId: solicitudAusenciaSeleccionada.id,
          colaboradorNumeroDocumentoIdentidad:solicitudAusenciaSeleccionada.colaborador.numeroDocumentoIdentidad,
          formularioId:formNotificarDocumento.getFieldValue("formularioConvenio"),
          usuarioLogin:getUsuarioAutenticado().login,
          usuarioNombreCompleto:getUsuarioAutenticado().nombreCompleto
         });
         if (response.estado === ApiResponse.EXITO) {
          message.success("Se ha notificado el documento.");
          //setVisibleModalAprobar(false);
          onClickBtnCancelar();         
          cargarSolicitudesAusenciaPendientes();
         } else {
          setMensajeError(response.mensaje);
         }
         setLoading(false);
      }      
      
     }
    // setLoading(true);
    // const response = await SolicitudAusenciaService.aprobarPorGestionHumana({
    //   solicitudAusenciaId: solicitudAusenciaSeleccionada.id,
    //   numeroDocumentoIdentidad: getUsuarioAutenticado().numeroDocumentoIdentidad,
    //   clave: claveIngresada,
    // });
    // if (response.estado === ApiResponse.EXITO) {
    //   message.success("Se ha aprobado la solicitud correctamente.");
    //   setVisibleModalAprobar(false);
    //   setSolicitudesPendientesAprobar(
    //     produce((draft) => {
    //       const index = draft.findIndex((solicitud) => solicitud.id === solicitudAusenciaSeleccionada.id);
    //       draft.splice(index, 1);
    //     })
    //   );
    // } else {
    //   setMensajeError(response.mensaje);
    // }
    // setLoading(false);
  };
  const onClickBtnCancelar = () => {
    setVisibleModalAprobar(false);    
    setEstado(undefined);
    formNotificarDocumento.resetFields();
    setNombreDocumento("");
    setEstadoDocumento(undefined);
  };
  const onChangeNoAplica=(e)=>{    
     if(e.target.checked===true)
       setEstado("aprobar");
     else
       setEstado("notificar");     
  }
  
  return (
    <Modal
      title={
        <div>
          Aprobar solicitud de: <b>{solicitudAusenciaSeleccionada?.colaborador.nombreCompleto}</b>
        </div>
      }
      visible={visibleModalAprobar}
      closable={false}
      width={400}
      destroyOnClose={true}
      footer={[
        <Button key="cancelar" disabled={loading} onClick={onClickBtnCancelar} style={{ padding: "0px 30px" }}>
          Cancelar
        </Button>,
        // <Button
        //   key="aprobar"
        //   type="primary"
        //   loading={loading}
        //   onClick={onClickBtnAprobar}
        //   style={{ padding: "0px 30px" }}
        // >
        //   Aprobar
        // </Button>,
        <Button
          key="aprobar"
          type="primary"
          loading={loading}
          onClick={onClickBtnAprobar}
          style={{ padding: "0px 30px" }}
          disabled={estadoDocumento==="NoFirmado" ? true :false}
        >
          {estado==="aprobar"? "Aprobar":"Enviar"}
        </Button>,
      ]}
      centered
    >
           
      {mensajeError && (
        <Alert
          message={mensajeError}
          onClose={() => setMensajeError(undefined)}
          style={{ marginBottom: "4px" }}
          type="error"
          showIcon
          closable
        />
      )}
      {
        estadoDocumento==="NoFirmado" &&(
          <Alert
             message={<Typography.Text>El documento "{nombreDocumento}" aún se encuentra pendiente de firmar.</Typography.Text>}
             style={{ marginBottom: "4px" }}
             type="warning"
             showIcon
             closable
          />
        )
      }
       {/* se modifica */}      
       {estado==="notificar" &&(  
         <React.Fragment>
          <Form form={formNotificarDocumento} layout="vertical" disabled={estado==="aprobar" ? true:false}>             
              <Form.Item 
                     name="formularioConvenio" 
                     label="Seleccionar convenio Vacacional"                     
                     >
                <Select allowClear showSearch optionFilterProp="children">
                  {formularios.length>0 && formularios.map((formulario)=>{
                    return(
                      <Select.Option value={formulario.id}>{formulario.nombre}</Select.Option>
                    )
                      
                  })}
                  
                </Select>
              </Form.Item >
              <Form.Item name="NoAplica" label="">
                 <Checkbox onChange={onChangeNoAplica}>No aplica</Checkbox>
              </Form.Item>
              
          </Form>
           
           </React.Fragment>
          
        )}        
        {estado==="aprobar" &&(         
          <React.Fragment>
            <span style={{ display: "block", marginBottom: "4px" }}>Ingresa tu clave</span>
            <Input.Password
              value={claveIngresada}
              onChange={(e) => {
                setClaveIngresada(e.target.value);
                setMensajeError(undefined);
              }}
              placeholder={"Clave"}
              disabled={estadoDocumento==="NoFirmado"? true: false}
            />
           
          </React.Fragment>
          
         )
         }

       {/* termina modificación */}
              
      {/* <span style={{ display: "block", marginBottom: "4px" }}>Ingresa tu clave</span>
      <Input.Password
        value={claveIngresada}
        onChange={(e) => {
          setClaveIngresada(e.target.value);
          setMensajeError(undefined);
        }}
        placeholder={"Clave"}
      /> */}
    </Modal>
  );
};

export default ModalAprobarSolicitudAusencia;
