import React, { useContext, useEffect, useState } from "react";
import { EnvioService } from "services/EnvioService";
import { Resumen } from "./resumen";
import { PanelCantidad, TotalDocumentos } from "./panelCantidad";
import { useAtom } from "jotai";
import { useParams } from "react-router-dom";

import {  documentosConErrorAtom, resumenAtom } from "../../ListarDetalleSeguimientoStore";


const InformacionDocumento = () => {
    const { envioId } = useParams();
    const [loading, setLoading] = useState(false);
    const [resumen,setResumen ] = useAtom(resumenAtom);
    const [documentosConError, ] =useAtom(documentosConErrorAtom)


    return (
        <div style={{ display: 'flex', maxHeight: '94px' }}>

            <Resumen loading={loading} /> 

            <div style={{ display: 'flex', width: '70%' }}>

                <PanelCantidad

                    total={resumen?.totalDocumentos}

                    descripcion="Documentos"
                    color="rgb(24, 144, 255)"
                    backgroundColor="rgb(24, 144, 255, 0.08)" />

                <PanelCantidad
                    total={resumen?.documentosFirmados!=null?resumen?.documentosFirmados:resumen?.documentosVisualizados}
                    descripcion={resumen?.documentosFirmados!=null?"Firmados":"Visualizados"}
                    color="rgb(82, 196, 26)"
                    backgroundColor="rgb(82, 196, 26, 0.08)" />

                <PanelCantidad
                    total={resumen?.documentosFirmados!=null?resumen?.documentosPendientes:resumen?.documentosNoVisualizados}
                    descripcion={resumen?.documentosFirmados!=null?"Pendientes":"No Visualizados"}
                    color="rgb(212, 136, 6)"
                    backgroundColor="rgb(212, 136, 6, 0.08)" />

                <PanelCantidad
                    total={resumen!=null?documentosConError?.length:""}
                    descripcion="Erróneos"
                    color="rgb(255, 77, 79)"
                    backgroundColor="rgb(255, 77, 79, 0.08)" />

            </div>

        </div>
    )
}

export default InformacionDocumento;