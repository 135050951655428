import React, { useState, useEffect } from "react";
import {
  Modal,
  Upload,
  message,
  Button,
  notification,
  Space,
  Typography,
  Tag,
  Divider,
  Row,
  Checkbox,
  Col,
  Badge,
} from "antd";
import { FileExcelOutlined, CheckCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { useContext } from "react";
import { LoginService } from "services/LoginService";
import { useHistory } from "react-router-dom";
import { OnboardingColaboradorService } from "services/OnboardingColaboradorService";
import { colaboradoresAgregadosAtom } from "../../OnboardingIndividualStore";
import { useAtom } from "jotai";

export default function ModalCargaExcel({ visibleModal, onCerrarModal }) {
  const { Text, Paragraph } = Typography;
  const [listaDocumentos, setListaDocumentos] = useState([]);
  const [respuestaConError, setRespuestaConError] = useState(false);
  const [listaRespuesta, setListaRespuesta] = useAtom(colaboradoresAgregadosAtom);
  const [colaboradoresError, setColaboradoresError] = useState(0);
  const [plantillasError, setPlantillasError] = useState(0);

  const [loading, setLoading] = useState(false);

  let history = useHistory();

  const props = {
    onRemove: (file) => {
      setListaDocumentos([]);
      return {
        listaDocumentos: [],
      };
    },
    beforeUpload: (file) => {
      if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        message.error(`${file.name} no es un archivo Excel`);
      } else {
        setListaDocumentos(file);
        return false;
      }
    },
    listaDocumentos,
  };

  const cargarColaboradores = async () => {
    
    if (listaDocumentos.name) {
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", listaDocumentos);
        const respuesta = await OnboardingColaboradorService.cargarColaboradorExcel(formData);
        setListaRespuesta(respuesta.datos);
        setColaboradoresError(respuesta?.colaboradorNoEncontrado);
        setPlantillasError(respuesta?.plantillaNoEncontrado);
        if (respuesta?.colaboradorNoEncontrado > 0 || respuesta?.plantillaNoEncontrado > 0) {
          setRespuestaConError(true);
        } else {
          onCerrarModal();
        }
      } catch (error) {
        notification.error({
          message: "Alerta!",
          description: error.response.data.mensaje,
        });
      } finally {
        setLoading(false);
      }
    } else {
      message.error("No ha seleccionado el archivo excel");
    }
  };

  return (
    <Modal
      title="Importar colaboradores desde excel"
      visible={visibleModal}
      width={500}
      //centered
      onCancel={onCerrarModal}
      destroyOnClose={true}
      footer={null}
    >
      <div>
        <Row>
          <div
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {/* <Upload {...props} fileList={listaDocumentos}> */}
            <Upload {...props} maxCount={1}>
              <Button style={{ borderRadius: 4, borderColor: "green" }}>
                <FileExcelOutlined style={{ fontSize: 20, color: "green" }} />{" "}
                <Text style={{ color: "green" }}>Cargar archivo Excel</Text>
              </Button>
            </Upload>
          </div>
        </Row>
        <br />
        <Row style={{ justifyContent: "center", margin: "12px 0px" }}>
          <Button type="primary" onClick={cargarColaboradores} loading={loading}>
            <UploadOutlined />
            Iniciar Carga
          </Button>
        </Row>
        <br />
        {respuestaConError && listaRespuesta.length > 0 ? (
          <div>
            <Row justify="center">
              <Space size={60}>
                <Text style={{ fontSize: 12 }}>
                  <CheckCircleOutlined style={{ color: "#329BE9 " }} /> Colaboradores Procesados:{" "}
                  {listaRespuesta.length}
                </Text>
                <Text style={{ fontSize: 12 }}>
                  <CheckCircleOutlined style={{ color: "#D89817" }} /> Colaboradores no Encontrados:{" "}
                  {colaboradoresError}
                </Text>
                <Text style={{ fontSize: 12 }}>
                  <CheckCircleOutlined style={{ color: "#DD0C3F" }} /> Plantillas no Encontrados: {plantillasError}
                </Text>

                {/* </Tag> */}
              </Space>
            </Row>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </Modal>
  );
}
