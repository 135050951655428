import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Form,
  Input,
  Typography,
  Row,
  Col,
  Button,
  Space,
  Modal,
  DatePicker,
  notification,
  message,
  Tag
} from "antd";
import { ContainerCrear } from "../../../components/ContainerCrear";
import { ContainerRecuperarClave } from "../../../components/ContainerRecuperarClave"
import FormItem from "antd/lib/form/FormItem";
import { CheckOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { Option } from "antd/lib/mentions";
import GestionarClaveService from "../../../services/GestionarClaveService";
//import ModalGenerarCodigo from "./modalGenerarCodigo";
const host = window.location.host;
//const subdominio = host.split(".")[0];
//const subdominio = "dev";
const { Text } = Typography;
export function ActualizarClavePage() {
  const [formularioRegistrarClave] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [envio, setEnvio] = useState(false);
  const [visibleAlertError, setVisibleAlertError] = useState(false);
  const [mensajeError, setMensajeError] = useState("");
  const [correoElectronico, setCorreoElectronico] = useState("");
  let history = useHistory();

  async function handleOnFinish(formulario) {
    try {
      setLoading(true);
      const rpta =
        await GestionarClaveService.actualizarClave(
          // subdominio,
          //formulario.numeroDocumentoIdentidad,
          formulario.correoElectronico,
        );
      if (rpta == 'OK') {
        setEnvio(true);
        setVisibleAlertError(false);
        setMensajeError("");
        setCorreoElectronico(formulario.correoElectronico);
      } else {
        setEnvio(false);
        setVisibleAlertError(true);
        setMensajeError(rpta);
        setCorreoElectronico("")
      }
      setLoading(false);
    } catch (error) {
      setEnvio(false);
      setLoading(false);
      console.error(error.response);
      console.error(error.response.data.mensaje);
      notification.error({
        message: "Error",
        description: "Error-> " + error.response.data.mensaje,
      });
    }
  }
  function handleOnClickCancelar() {
    history.push("/login");
  }
  function OnModal(correo) {
    Modal.info({
      title: "Generar código de verificación Clave",
      content: (
        <div>
          <p>
            Se le enviará el código de seguridad al correo:{" "}
            <span>{correo}</span>
          </p>
        </div>
      ),
      onOk() {
        // handleOnOKGenerarCodigo(correo);
      },
    });
  }
  // async function handleOnClickGenerarCodigo() {
  //   try {
  //     if (
  //       formularioRegistrarClave.getFieldValue().numeroDocumentoIdentidad ===
  //         undefined ||
  //       formularioRegistrarClave.getFieldValue().numeroDocumentoIdentidad === ""
  //     ) {
  //       message.error("Debe ingresar Número Documento Identidad");
  //     } else {
  //       const numeroDocumentoIdentidad = formularioRegistrarClave.getFieldsValue()
  //         .numeroDocumentoIdentidad;
  //       const correo = await GestionarClaveService.buscarCorreo(
  //         subdominio,
  //         numeroDocumentoIdentidad
  //       );
  //       OnModal(correo);
  //     }
  //   } catch (error) {
  //     notification.error({
  //       message: "No se puede obtener el código",
  //       description: "Error: " + error.response.data.mensaje,
  //     });
  //   }
  // }
  return (
    <div>
      {!envio ? (<ContainerRecuperarClave titulo="Recupera tu clave" descripcion="Se enviará un correo electrónico para el cambio de clave" colSpan={8} visibleAlertError={visibleAlertError} mensajeError={mensajeError}>
        <Form
          form={formularioRegistrarClave}
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 12,
          }}
          layout="vertical"
          size="default"
          onFinish={handleOnFinish}
        >
          <Row align="center">
            <Col span={20}>
              <FormItem>
                {/* <Text strong>Ingresa tú número de documento de identidad y una dirección de correo electrónico a la cual te enviaremos un mensaje. */}
                <Text strong>Ingresa una dirección de correo electrónico a la cual te enviaremos un mensaje.
                </Text>
              </FormItem>
            </Col>
          </Row>
          {/* <Row align="center">            
              <Col span={20}>
                <FormItem
                  label="Número documento identidad"
                  name="numeroDocumentoIdentidad"
                  rules={[
                    {
                      required: true,
                      message: "Ingrese número documento identidad",
                    },
                  ]}
                >
                  <Input
                    placeholder="Ingrese número documento identidad"
                    autoComplete="off"
                  />
                </FormItem>
              </Col>
            </Row> */}

          <Row align="center">
            <Col span={20}>
              <FormItem
                label="Login (correo electrónico)"
                name="correoElectronico"
                rules={[
                  {
                    type: 'email',
                    message: "Correo electrónico ingresado no es válido",
                  },
                  {
                    required: true,
                    message: "Ingrese correo electrónico",
                  },
                ]}
              >
                <Input
                  placeholder="Ingrese correo electrónico"
                  autoComplete="off"
                />
              </FormItem>
            </Col>
          </Row>

          <Row justify="center">
            <Space>
              <Col span={20}>
                <Button
                  type="primary"
                  icon={<CheckOutlined />}
                  htmlType="submit"
                  loading={loading}
                >
                  Enviar
                  </Button>
              </Col>
              <Col>
                <Button type="default" onClick={handleOnClickCancelar}>
                  Cancelar
                  </Button>
              </Col>
            </Space>
          </Row>
        </Form>
      </ContainerRecuperarClave>) :
        (
          <ContainerRecuperarClave titulo="Recupera tu clave" descripcion="Se enviará un correo electrónico para el cambio de clave" colSpan={8} visibleAlertError={visibleAlertError} mensajeError={mensajeError}>
            <p align="center"><span>Te hemos enviado un correo electrónico a <Tag color="blue">{correoElectronico}</Tag></span></p>
            <p align="center">Has clic en el enlace del correo para cambiar tu clave.</p>

            <p align="center"><span>Has click <a onClick={handleOnClickCancelar}> aquí</a> para volver a la página de iniciar sesión.</span></p>
          </ContainerRecuperarClave>
        )}
    </div>
  );
}

/*<ContainerCrear titulo="Registra / Actualiza tu Clave" colSpan={12}>
   <Form
     form={formularioRegistrarClave}
     labelCol={{
       span: 12,
     }}
     wrapperCol={{
       span: 12,
     }}
     layout="vertical"
     size="default"
     onFinish={handleOnFinish}
   >
     <Row align="center">
       <Col span={20}>
         <FormItem
           label="Tipo Documento"
           name="tipoDocumentoIdentidad"
           rules={[
             {
               required: true,
               message: "Seleccione el Tipo de Documento",
             },
           ]}
         >
           <Select placeholder="Seleccione Tipo Documento" allowClear>
             <Select.Option value="DNI">D.N.I</Select.Option>
 //             {/*<Option value="DNI">D.N.I</Option>*/
    //           </Select>
    //         </FormItem>
    //       </Col>
    //     </Row>
    //     <Row align="center">
    //       <Col span={20}>
    //         <FormItem
    //           label="Número Documento"
    //           name="numeroDocumentoIdentidad"
    //           rules={[
    //             {
    //               required: true,
    //               message: "Ingrese número Documento Identidad",
    //             },
    //           ]}
    //         >
    //           <Input
    //             placeholder="Ingrese número Documento"
    //             autoComplete="off"
    //           />
    //         </FormItem>
    //       </Col>
    //     </Row>

    //     <Row align="center">
    //       <Col span={20}>
    //         <FormItem
    //           label="Clave"
    //           name="clave"
    //           rules={[
    //             {
    //               required: true,
    //               message: "Ingrese su clave",
    //             },
    //           ]}
    //         >
    //           <Input.Password />
    //         </FormItem>
    //       </Col>
    //     </Row>
    //     <Row align="center">
    //       <Col span={20}>
    //         <FormItem
    //           label="Repetir Clave"
    //           name="claveR"
    //           dependencies={["clave"]}
    //           hasFeedback
    //           rules={[
    //             {
    //               required: true,
    //               message: "Ingrese nuevamente su clave",
    //             },
    //             ({ getFieldValue }) => ({
    //               validator(rule, value) {
    //                 if (!value || getFieldValue("clave") === value) {
    //                   return Promise.resolve();
    //                 }

    //                 return Promise.reject("Las claves deben ser iguales!");
    //               },
    //             }),
    //           ]}
    //         >
    //           <Input.Password />
    //         </FormItem>
    //       </Col>
    //     </Row>
    //     <Row align="center">
    //       <Col span={20}>
    //         <FormItem
    //           label="Fecha Nacimiento"
    //           name="fechaNacimiento"
    //           rules={[
    //             {
    //               required: true,
    //               message: "Seleccione su fecha nacimiento",
    //             },
    //           ]}
    //         >
    //           <DatePicker />
    //         </FormItem>
    //       </Col>
    //     </Row>

    //     <Row style={{ marginLeft: 50 }}>
    //       <FormItem label="Código">
    //         <Row gutter={8}>
    //           <Col span={12}>
    //             <FormItem
    //               name="codigo"
    //               rules={[
    //                 {
    //                   required: true,
    //                   message: "Ingrese código",
    //                 },
    //               ]}
    //             >
    //               <Input
    //                 placeholder="Ingrese código"
    //                 autoComplete="off"
    //               ></Input>
    //             </FormItem>
    //           </Col>
    //           <Col span={8}>
    //             <Button onClick={handleOnClickGenerarCodigo}>
    //               Solicitar código
    //             </Button>
    //           </Col>
    //         </Row>
    //       </FormItem>
    //     </Row>

    //     <Row justify="center">
    //       <Space>
    //         <Col span={20}>
    //           <Button
    //             type="primary"
    //             icon={<CheckOutlined />}
    //             htmlType="submit"
    //             loading={loading}
    //           >
    //             Guardar
    //           </Button>
    //         </Col>
    //         <Col>
    //           <Button type="default" onClick={handleOnClickCancelar}>
    //             Cancelar
    //           </Button>
    //         </Col>
    //       </Space>
    //     </Row>
    //   </Form>
    //   {/* <ModalGenerarCodigo
    //     visibleModal={visibleModal}
    //     oncerrarModal={onCerrarModal}
    //     handleOk={handleOk}
    //     defaultValorCorreo={defaultValorCorreo}
    //     handleOnChangeCorreo={handleOnChangeCorreo}
    //     listaCorreos={listaCorreos}
    //   /> */}
    // </ContainerCrear>

