import React,{useState} from "react";
import { Drawer, Button, Space, Form, Row, Col, Input, Divider, Checkbox, message, Select } from "antd";
import { useAtom } from "jotai";
import { PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {visibleDrawerCampoAtom,camposAtom} from "../../CrearPerfilSolicitudPersonalStore";
import { TipoControl } from "enums";
import { useEffect } from "react";

const DrawerCampos=()=>{
    const [visible,setVisible]=useAtom(visibleDrawerCampoAtom);
    const [frmCampos] = Form.useForm();
    const [controlSeleccionado, setControlSeleccionado] = useState(undefined);
    const [campos, setCampos] = useAtom(camposAtom);
    //const [editarCampo, setEditarCampo] = useAtom(editarCampoAtom);
    //const [campoSeleccionado, setCampoSeleccionado] = useAtom(campoSeleccionadoAtom);
    useEffect(()=>{

        const cargarDatos=()=>{
            frmCampos.setFieldsValue({
                required: true,
              });
              console.log("cargado");
        }
        cargarDatos();
    },[])
    const onClose = () => {
        //setEditarCampo(false);
        //setCampoSeleccionado();
        setControlSeleccionado();
        frmCampos.resetFields();
        setVisible(false);
      };

    const onSelectTipoControl = (value) => {
        setControlSeleccionado(value);
      };
      const onClickBtnGuardar = () => {
        frmCampos
          .validateFields()
          .then((form) => {
            const campoAgregado = {
              key: form.key,
              keyPrev: form.keyPrev,
              label: form.label,
              type: controlSeleccionado,
              required: form.required,
              items: controlSeleccionado === TipoControl.LISTA ? form.items : null,
            };
    
            const existeControl = campos.find((c) => c.key === campoAgregado.key);
            if (existeControl && existeControl.key !== form.keyPrev) {
              message.error("Ya existe un control con ese nombre");
            } else {
            //   if (editarCampo) {
            //     setCampos(
            //       campos.map((campo) => {
            //         if (campo.key === campoAgregado.keyPrev) {
            //           return { ...campo, ...campoAgregado };
            //         }
            //         return campo;
            //       })
            //     );
            //   } else {
                setCampos([...campos, campoAgregado]);
             // }
    
              onClose();
            }
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      };
    return(
        <Drawer
            title="Propiedades Control"
            placement="right"
            onClose={onClose}
            visible={visible}
            width="420px"
            extra={
                <Space>
                <Button onClick={onClose}>Cerrar</Button>
                <Button onClick={onClickBtnGuardar} type="primary">
                    Aplicar
                </Button>
                </Space>
            }
        >
            {/* <Form layout="vertical" form={frmCampos} initialValues={{ required: !editarCampo && true }}> */}
            <Form layout="vertical" form={frmCampos} >
                <Form.Item name="keyPrev" hidden={true}>
                <Input />
                </Form.Item>
                <Row gutter={16}>
                <Col span={24}>
                    <Form.Item name="type" label="Tipo" rules={[{ required: true, message: "El campo tipo es requerido" }]}>
                    <Select style={{ width: "100%" }} onSelect={onSelectTipoControl}>
                        <Select.Option value={TipoControl.CAJA_TEXTO}>Caja de Texto</Select.Option>
                        <Select.Option value={TipoControl.LISTA}>Lista</Select.Option>
                        <Select.Option value={TipoControl.FECHA}>Fecha</Select.Option>
                    </Select>
                    </Form.Item>
                </Col>
                </Row>
                <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                    name="key"
                    label="Identificador"
                    rules={[{ required: true, message: "El campo identificador es requerido" }]}
                    >
                    <Input />
                    </Form.Item>
                </Col>
                </Row>
                <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                    name="label"
                    label="Etiqueta"
                    rules={[{ required: true, message: "El campo etiqueta es requerido" }]}
                    >
                    <Input />
                    </Form.Item>
                </Col>
                </Row>
                <Row gutter={16}>
                <Col span={24}>
                    <Form.Item name="required" valuePropName="checked">
                    <Checkbox>Requerido</Checkbox>
                    </Form.Item>
                </Col>
                </Row>
                {controlSeleccionado && controlSeleccionado === TipoControl.LISTA && (
                <React.Fragment>
                    <Divider orientation="left" plain>
                    Valores Lista
                    </Divider>
                    <Row gutter={16}>
                    <Col span={24}>
                        <Form.List name="items">
                        {(fields, { add, remove }) => (
                            <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space key={key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                                <Form.Item
                                    {...restField}
                                    name={[name, "key"]}
                                    rules={[{ required: true, message: "Código es obligatorio" }]}
                                >
                                    <Input placeholder="Código" />
                                </Form.Item>
                                <Form.Item
                                    {...restField}
                                    name={[name, "value"]}
                                    rules={[{ required: true, message: "Valor es obligatorio" }]}
                                >
                                    <Input placeholder="Valor" />
                                </Form.Item>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                Adicionar Valores
                                </Button>
                            </Form.Item>
                            </>
                        )}
                        </Form.List>
                    </Col>
                    </Row>
                </React.Fragment>
                )}
            </Form>
       </Drawer>
    )
}
export default DrawerCampos;