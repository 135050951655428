import { ApiService } from "services/ApiService";
import { ApiUrl } from "services/ApiUrl";

const URL_API = ApiUrl.RECLUTAMIENTO_Y_SELECCION;

const ReporteColaboradoresCesadosService = {

    async getTasaCesadosPorMotivoCese(params) {
        return ApiService(URL_API).get("/reportes/dashboard/tasa-cesados-por-motivo-cese", {
          params: { ...params },
        });
      },

}
export {ReporteColaboradoresCesadosService};