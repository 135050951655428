import React from "react";
import { Modal, message } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import {
    contratoSeleccionadoAtom,
    contratosAtom,
    visibleModalAprobarContratoAtom,
} from "../../BandejaAprobacionAdministracionPersonalStore";
import { BandejaAprobacionAdministracionPersonalService } from "services/contratos/BandejaAprobacionAdministracionPersonalService";
import { LoginService } from "services/LoginService";
import { useAtom } from "jotai";
import produce from "immer";

const ModalAprobar = () => {
    const [visible, setVisible] = useAtom(visibleModalAprobarContratoAtom);
    const [contratoSeleccionado, setContratoSeleccionado] = useAtom(contratoSeleccionadoAtom);
    const [, setContratos] = useAtom(contratosAtom);

    const onClickBtnAprobar = async () => {
        try {
            await BandejaAprobacionAdministracionPersonalService.aprobarContrato({
                seguimientoContratoId: contratoSeleccionado.id,
                usuarioId: LoginService.getUsuarioAutenticado().id,
            });
            setContratos(
                produce((draft) => {
                    const index = draft.findIndex((contrato) => contrato.id === contratoSeleccionado.id);
                    draft.splice(index, 1);
                })
            );
            message.success("Se aprobó el contrato seleccionado!.");
            setVisible(false);
        } catch (error) {
            message.error("No se pudo aprobar el contrato. Intente nuevamente!");
        }
    };

    const onClickBtnCancelar = () => {
        setVisible(false);
    };

    return (
        <Modal
            title="Aprobar Contrato"
            open={visible}
            onOk={onClickBtnAprobar}
            onCancel={onClickBtnCancelar}
            okText="Si, Aprobar Contrato!"
            destroyOnClose={true}
        >
            <div style={{ textAlign: "center", paddingBottom: "15px" }}>
                <QuestionCircleOutlined style={{ fontSize: "40px", color: "#ebc334" }} />
            </div>
            <div style={{ textAlign: "center", fontSize: "18px" }}>
                ¿Está seguro de aprobar el contrato seleccionado?
            </div>
        </Modal>
    );
};

export default ModalAprobar;
