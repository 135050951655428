import { Form, Input, Modal, Select } from 'antd';
import React, { useEffect } from 'react';
import { useModalMoverDocumento } from '../hooks';

const ModalMoverDocumento = () => {

    const [form] = Form.useForm();

    const {
        open,
        carpetas,
        loading,
        cerrarModal,
        setFormInstance,
        fetchCarpetas,
        guardar
    } = useModalMoverDocumento();

    useEffect(() => {
        if (open) {
            fetchCarpetas();
            setFormInstance(form);
        }
    }, [open]);

    return (
        <Modal
            title="Mover documento a otra carpeta"
            open={open}
            onOk={guardar}
            okText="Guardar"
            confirmLoading={loading}
            onCancel={cerrarModal}
            cancelButtonProps={{ disabled: loading }}
            destroyOnClose={true}
        >
            <Form
                form={form}
                layout="vertical"
            >
                <Form.Item
                    name="carpetaId"
                    label="Selecciona la nueva carpeta destino"
                    rules={[
                        {
                            required: true,
                            message: "Debe seleccionar la carpeta destino. ",
                        },
                    ]}
                >
                    <Select
                        showSearch
                        allowClear
                        options={carpetas}
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        onChange={
                            (carpetaId, option) => form.setFieldValue("carpetaDescripcion", option.carpetaDescripcion)
                        }
                    />
                </Form.Item>

                <Form.Item name="carpetaDescripcion" noStyle>
                    <Input type="hidden" />
                </Form.Item>

            </Form>
        </Modal >
    );
};

export default ModalMoverDocumento;