import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

const Botones=()=>{

    let history = useHistory();

    const BotonCrearCarpetaLegajo = () =>{

       const onClickBtnCrearCarpetaLegajo = () =>{
            history.push("/configuracion/carpetas-legajos/crear");
        }
        return(
            <Button
            type="primary"
            icon={<PlusOutlined/>}
            onClick={onClickBtnCrearCarpetaLegajo}            
            >
                Crear Carpeta
            </Button>
        )

    }
    return(
        <React.Fragment>
            <BotonCrearCarpetaLegajo/>
        </React.Fragment>
    )


};
export default Botones;