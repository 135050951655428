import { VacacionesService } from "services/VacacionesService";
import { filtrosSeleccionadosAtom, paginaActualAtom,
  tamanioPaginadoAtom,} from "../ControlVacacionalStorage";
import { useAtom } from "jotai";
import { saveAs } from "file-saver";
import { notification } from "antd";

const useGenerarReporteExcel=()=>{
    
    const [filtrosSeleccionados, setFiltrosSeleccionados] = useAtom(filtrosSeleccionadosAtom);
    const [paginaActual, setPaginaActual] = useAtom(paginaActualAtom);
    const [tamanioPaginado] = useAtom(tamanioPaginadoAtom);
   
    const generarReporteExcel= async()=>{     
     try {
        const respuesta= await VacacionesService.descargarReporteExcel({          
        colaborador: filtrosSeleccionados["colaborador"],
        documentoIdentidad: filtrosSeleccionados["documentoIdentidad"],
        codigoColaborador: filtrosSeleccionados["codigo"],       
        });
      const filename = respuesta.headers.filename;
      saveAs(respuesta.data,filename);
     } catch (error) {
      notification.error({
        message:"No hay vacaciones para exportar",        
        description: error.response.data.mensaje,
       }
      )
     }
        
       
    }
    return{
      generarReporteExcel,
    }

}
export default useGenerarReporteExcel;