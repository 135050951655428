import React, { useState, useEffect, useContext, useRef } from "react";
import { Container } from "../../../components/Container";
import {
  UnorderedListOutlined,
  SearchOutlined,
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
  FolderFilled,
  FolderTwoTone,
} from "@ant-design/icons";
import {
  Table,
  Space,
  Typography,
  Select,
  Button,
  Input,
  Popconfirm,
  notification,
  Modal,
  Tag,
  Row,
  Col,
  Form,
} from "antd";
import { SecurityContext } from "../../../context/SecurityContextProvider";
import { EnvioService } from "services/EnvioService";
import DetalleEnvioService from "../../../services/DetalleEnvioService";
import { useHistory } from "react-router-dom";
import { SnippetsOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { FileSaver } from "file-saver";
import { saveAs } from "file-saver";
import CarpetaService from "services/CarpetaService";

const { Option } = Select;
const { confirm } = Modal;

export function DocumentoLaboralListarPage() {
  let history = useHistory();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const { setDatosEnvio } = useContext(SecurityContext);
  const { setDatosEnvioCargarDocumento } = useContext(SecurityContext);
  const [envios, setEnvios] = useState([]);
  const [loading, setLoading] = useState(false);
  const { Text } = Typography;
  const [defaultValue, setDefaultValue] = useState("default");
  const [filaSeleccionada, setFilaSeleccionada] = useState("");
  const [visibleModalCarpeta, setVisibleModalCarpeta] = useState(false);
  const [carpetas, setCarpetas] = useState([]);
  const [frmCarpeta] = Form.useForm();

  useEffect(() => {
    async function cargarDatos() {
      const envios = await EnvioService.listar(
        getUsuarioAutenticado().empresaSeleccionada.id
      );
      setEnvios(envios);
      const _carpetas = await CarpetaService.listar(
        getUsuarioAutenticado().empresaSeleccionada.id
      );
      setCarpetas(_carpetas);
    }

    cargarDatos();
  }, []);

  var adicionarColumnasFiltro = function getColumns(
    dataIndex,
    placeholder,
    propiedad
  ) {
    return {
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={
              placeholder !== undefined
                ? placeholder
                : `Buscar por ${dataIndex}`
            }
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{ width: 188, marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => handleReset(clearFilters)}
              size="small"
              style={{ width: 90 }}
            >
              Limpiar
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        propiedad === undefined
          ? record[dataIndex]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase())
          : record[dataIndex][propiedad]
              .toString()
              .toLowerCase()
              .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: (text) =>
        searchedColumn === dataIndex ? (
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={
              propiedad === undefined
                ? text.toString()
                : text[propiedad].toString()
            }
          />
        ) : propiedad === undefined ? (
          text
        ) : (
          text[propiedad]
        ),
    };
  };

  function handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  function handleReset(clearFilters) {
    clearFilters();
    setSearchText("");
  }
  const columnasConCampoAdicional = [
    {
      title: "Documento",
      key: "documento",
      align: "left",
      width: 300,
      ...adicionarColumnasFiltro(
        "tipoDocumentoNombre",
        "Buscar por Tipo Documento"
      ),
      render: (text, fila) => (
        <React.Fragment>
          <Space align="center">
            {!fila.eliminado ? (
              <a
                onClick={() =>
                  fila.estadoProcesoId === 3
                    ? handleOnClickVerDetalle(
                        fila.id,
                        fila.tipoDocumentoNombre,
                        fila.descripcion,
                        fila.responsableCargaDocumento,
                        fila.sedeNombre
                      )
                    : fila.estadoProcesoId === 0
                    ? cargarDocumento(
                        fila.id,
                        fila.tipoDocumentoId,
                        fila.tipoDocumentoNombre,
                        //fila.aprobacion,
                        fila.flujo,
                        fila.descripcion,
                        fila.formatoCargaId
                      )
                    : handleOnClickVerDetallePorAprobar(
                        fila.id,
                        fila.tipoDocumentoNombre,
                        fila.descripcion
                      )
                }
              >
                {fila.estadoProcesoId === 0 ? (
                  <img
                    src={require("../../../assets/img/upload-document.png")}
                  />
                ) : fila.estadoProcesoId === 3 ? (
                  /*<FolderOutlined
                  style={{ fontSize: "50px", color: "#1678c1" }}
                />*/ <img src={require("../../../assets/img/send-file.png")} />
                ) : (
                  /*<FolderOutlined
                  style={{ fontSize: "50px", color: "#5677DF " }}
                />*/ <img
                    src={require("../../../assets/img/filePorAprobar.png")}
                  />
                )}
              </a>
            ) : fila.estadoProcesoId === 0 ? (
              <img src={require("../../../assets/img/upload-document.png")} />
            ) : fila.estadoProcesoId === 3 ? (
              <img src={require("../../../assets/img/send-file.png")} />
            ) : (
              <img src={require("../../../assets/img/filePorAprobar.png")} />
            )

            //<FolderOutlined style={{ fontSize: "50px", color: "#1678c1" }} />
            }

            <span className="mock-block">
              <Text strong>{fila.tipoDocumentoNombre}</Text>
              <p>{fila.descripcion}</p>
            </span>
          </Space>
        </React.Fragment>
      ),
    },
    {
      title: "Unidad Trabajo",
      dataIndex: "sedeNombre",
      align: "center",
    },
    {
      title: "Responsable envío",
      // dataIndex: "usuarioEnvio",
      dataIndex: ["usuarioEnvio", "nombreCompleto"],
      align: "center",
      /* ...adicionarColumnasFiltro(
         "usuarioEnvio",
         "Buscar por responsable",
         "nombreCompleto"
       ),*/
    },
    {
      title: "Fecha envío",
      dataIndex: "fechaEnvio",
      align: "center",
      //...adicionarColumnasFiltro("fechaEnvio", "Buscar por fecha de envío"),
    },
    {
      title: "Resumen",
      key: "totales",
      align: "center",
      render: (text, fila) => (
        <React.Fragment>
          <Space align="center">
            {fila.responsableCargaDocumento === "COLABORADOR" ? (
              <span className="mock-block">
                <Row>
                  <Col>
                    <p>
                      <Tag color="processing">
                        Enviados: {fila.totalEnviados}
                      </Tag>
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p>
                      <Tag color="success">
                        {/* <Text strong>Visualizados: </Text> {fila.totalVisualizados} */}
                        Cargados: {fila.totalDocumentosCargados}
                      </Tag>
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p>
                      <Tag color="volcano">
                        {/* <Text strong>Sin visualizar: </Text>
                {fila.totalEnviados - fila.totalVisualizados} */}
                        No Cargados: {fila.totalDocumentosNoCargados}
                      </Tag>
                    </p>
                  </Col>
                </Row>
              </span>
            ) : fila.estadoProcesoDescripcion === "NOTIFICADO" ? (
              <span className="mock-block">
                <Row>
                  <Col>
                    <p>
                      <Tag color="processing">
                        Enviados: {fila.totalEnviados}
                      </Tag>
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p>
                      <Tag color="success">
                        {/* <Text strong>Visualizados: </Text> {fila.totalVisualizados} */}
                        Visualizados: {fila.totalVisualizados}
                      </Tag>
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p>
                      <Tag color="volcano">
                        {/* <Text strong>Sin visualizar: </Text>
                                      {fila.totalEnviados - fila.totalVisualizados} */}
                        Sin visualizar:{" "}
                        {fila.totalEnviados - fila.totalVisualizados}
                      </Tag>
                    </p>
                  </Col>
                </Row>
              </span>
            ) : fila.estadoProcesoDescripcion === "REGISTRADO" ? (
              <Tag color="orange">Pendiente de Cargar</Tag>
            ) : fila.estadoProcesoDescripcion === "RECHAZADO" ? (
              <Tag color="volcano">Rechazado</Tag>
            ) : (
              <Tag color="geekblue">Pendiente de Aprobar</Tag>
            )}
          </Space>
        </React.Fragment>
      ),
    },
    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      render: (text, fila) => (
        <React.Fragment>
          {fila.eliminado ? (
            <a onClick={() => handleOnClickHabilitarEnvio(fila.id)}>
              Habilitar
            </a>
          ) : fila.estadoProcesoDescripcion === "NOTIFICADO" ? (
            <Space size={8}>
              <Select
                placeholder="Seleccione la Acción"
                value={defaultValue}
                style={{ width: 200 }}
                onSelect={(value) =>
                  handleAccionesOnClick(
                    value,
                    fila.id,
                    fila.tipoDocumentoNombre,
                    fila.descripcion,
                    fila.responsableCargaDocumento,
                    fila.sedeNombre
                  )
                }
                //loading={loading}
                loading={filaSeleccionada === fila.id ? loading : false}
                allowClear
              >
                <Option value="default">Seleccione la Acción </Option>
                <Option value="verDetalle">Ver detalle </Option>
                <Option value="reporteExcel">Reporte Excel </Option>
                <Option value="reportePDF">Reporte PDF </Option>
                <Option value="inhabilitar">Inhabilitar</Option>
              </Select>
            </Space>
          ) : fila.estadoProcesoDescripcion === "REGISTRADO" ? (
            <Space size={8}>
              <Select
                placeholder="Seleccione la Acción"
                value={defaultValue}
                style={{ width: 200 }}
                onSelect={(value) =>
                  handleAccionesEnvioRegistradoOnClick(
                    value,
                    fila.id,
                    fila.tipoDocumentoId,
                    fila.tipoDocumentoNombre,
                    fila.aprobacion,
                    fila.descripcion
                  )
                }
                //loading={loading}
                loading={filaSeleccionada === fila.id ? loading : false}
                allowClear
              >
                <Option value="default">Seleccione la Acción </Option>
                <Option value="cargarDocumento">Cargar Documento </Option>
                <Option value="inhabilitar">Inhabilitar</Option>
              </Select>
            </Space>
          ) : (
            //aqui seria cuando el estado es pendienter de aprobar--> ver que opciones van
            <Space size={8}>
              <Select
                placeholder="Seleccione la Acción"
                value={defaultValue}
                style={{ width: 200 }}
                onSelect={(value) =>
                  handleAccionesEnvioPorAprobarOnClick(
                    value,
                    fila.id,
                    fila.tipoDocumentoNombre,
                    fila.descripcion
                  )
                }
                //loading={loading}
                loading={filaSeleccionada === fila.id ? loading : false}
                allowClear
              >
                <Option value="default">Seleccione la Acción </Option>
                {/* <Option value="aprobar">Aprobar</Option>*/}
                <Option value="verDetalle">Ver detalle</Option>
                <Option value="inhabilitar">Inhabilitar</Option>
              </Select>
            </Space>
          )}
        </React.Fragment>
      ),
    },
  ];

  const columnas = [
    {
      title: "Documento",
      key: "documento",
      align: "left",
      width: 300,
      ...adicionarColumnasFiltro(
        "tipoDocumentoNombre",
        "Buscar por Tipo Documento"
      ),
      render: (text, fila) => (
        <React.Fragment>
          <Space align="center">
            {!fila.eliminado ? (
              <a
                onClick={() =>
                  fila.estadoProcesoId === 3
                    ? handleOnClickVerDetalle(
                        fila.id,
                        fila.tipoDocumentoNombre,
                        fila.descripcion,
                        fila.responsableCargaDocumento,
                        fila.sedeNombre
                      )
                    : fila.estadoProcesoId === 0
                    ? cargarDocumento(
                        fila.id,
                        fila.tipoDocumentoId,
                        fila.tipoDocumentoNombre,
                        //fila.aprobacion,
                        fila.flujo,
                        fila.descripcion,
                        fila.formatoCargaId
                      )
                    : handleOnClickVerDetallePorAprobar(
                        fila.id,
                        fila.tipoDocumentoNombre,
                        fila.descripcion
                      )
                }
              >
                {fila.estadoProcesoId === 0 ? (
                  <img
                    src={require("../../../assets/img/upload-document.png")}
                  />
                ) : fila.estadoProcesoId === 3 ? (
                  /*<FolderOutlined
                  style={{ fontSize: "50px", color: "#1678c1" }}
                />*/ <img src={require("../../../assets/img/send-file.png")} />
                ) : (
                  /*<FolderOutlined
                  style={{ fontSize: "50px", color: "#5677DF " }}
                />*/ <img
                    src={require("../../../assets/img/filePorAprobar.png")}
                  />
                )}
              </a>
            ) : fila.estadoProcesoId === 0 ? (
              <img src={require("../../../assets/img/upload-document.png")} />
            ) : fila.estadoProcesoId === 3 ? (
              <img src={require("../../../assets/img/send-file.png")} />
            ) : (
              <img src={require("../../../assets/img/filePorAprobar.png")} />
            )

            //<FolderOutlined style={{ fontSize: "50px", color: "#1678c1" }} />
            }

            <span className="mock-block">
              <Text strong>{fila.tipoDocumentoNombre}</Text>
              <p>{fila.descripcion}</p>
            </span>
          </Space>
        </React.Fragment>
      ),
    },
    {
      title: "Responsable envío",
      // dataIndex: "usuarioEnvio",
      dataIndex: ["usuarioEnvio", "nombreCompleto"],
      align: "center",
      /* ...adicionarColumnasFiltro(
         "usuarioEnvio",
         "Buscar por responsable",
         "nombreCompleto"
       ),*/
    },
    {
      title: "Fecha envío",
      dataIndex: "fechaEnvio",
      align: "center",
      //...adicionarColumnasFiltro("fechaEnvio", "Buscar por fecha de envío"),
    },
    {
      title: "Resumen",
      key: "totales",
      align: "center",
      render: (text, fila) => (
        <React.Fragment>
          <Space align="center">
            {fila.responsableCargaDocumento === "COLABORADOR" ? (
              <span className="mock-block">
                <Row>
                  <Col>
                    <p>
                      <Tag color="processing">
                        Enviados: {fila.totalEnviados}
                      </Tag>
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p>
                      <Tag color="success">
                        {/* <Text strong>Visualizados: </Text> {fila.totalVisualizados} */}
                        Cargados: {fila.totalDocumentosCargados}
                      </Tag>
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p>
                      <Tag color="volcano">
                        {/* <Text strong>Sin visualizar: </Text>
                {fila.totalEnviados - fila.totalVisualizados} */}
                        No Cargados: {fila.totalDocumentosNoCargados}
                      </Tag>
                    </p>
                  </Col>
                </Row>
              </span>
            ) : fila.estadoProcesoDescripcion === "NOTIFICADO" ? (
              <span className="mock-block">
                <Row>
                  <Col>
                    <p>
                      <Tag color="processing">
                        Enviados: {fila.totalEnviados}
                      </Tag>
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p>
                      <Tag color="success">
                        {/* <Text strong>Visualizados: </Text> {fila.totalVisualizados} */}
                        Visualizados: {fila.totalVisualizados}
                      </Tag>
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <p>
                      <Tag color="volcano">
                        {/* <Text strong>Sin visualizar: </Text>
                                      {fila.totalEnviados - fila.totalVisualizados} */}
                        Sin visualizar:{" "}
                        {fila.totalEnviados - fila.totalVisualizados}
                      </Tag>
                    </p>
                  </Col>
                </Row>
              </span>
            ) : fila.estadoProcesoDescripcion === "REGISTRADO" ? (
              <Tag color="orange">Pendiente de Cargar</Tag>
            ) : fila.estadoProcesoDescripcion === "RECHAZADO" ? (
              <Tag color="volcano">Rechazado</Tag>
            ) : (
              <Tag color="geekblue">Pendiente de Aprobar</Tag>
            )}
          </Space>
        </React.Fragment>
      ),
    },
    {
      title: "Acciones",
      key: "acciones",
      align: "center",
      render: (text, fila) => (
        <React.Fragment>
          {fila.eliminado ? (
            <a onClick={() => handleOnClickHabilitarEnvio(fila.id)}>
              Habilitar
            </a>
          ) : fila.estadoProcesoDescripcion === "NOTIFICADO" ? (
            <Space size={8}>
              <Select
                placeholder="Seleccione la Acción"
                value={defaultValue}
                style={{ width: 200 }}
                onSelect={(value) =>
                  handleAccionesOnClick(
                    value,
                    fila.id,
                    fila.tipoDocumentoNombre,
                    fila.descripcion,
                    fila.responsableCargaDocumento,
                    fila.sedeNombre
                  )
                }
                //loading={loading}
                loading={filaSeleccionada === fila.id ? loading : false}
                allowClear
              >
                <Option value="default">Seleccione la Acción </Option>
                <Option value="verDetalle">Ver detalle </Option>
                <Option value="reporteExcel">Reporte Excel </Option>
                <Option value="reportePDF">Reporte PDF </Option>
                <Option value="inhabilitar">Inhabilitar</Option>
                {/* Se comenta esta opción porque no está funcionando - inicio */}
                <Option value="asignarCarpeta">Asignar Carpeta</Option>
                {/* Se comenta esta opción porque no está funcionando - fin */}
              </Select>
            </Space>
          ) : fila.estadoProcesoDescripcion === "REGISTRADO" ? (
            <Space size={8}>
              <Select
                placeholder="Seleccione la Acción"
                value={defaultValue}
                style={{ width: 200 }}
                onSelect={(value) =>
                  handleAccionesEnvioRegistradoOnClick(
                    value,
                    fila.id,
                    fila.tipoDocumentoId,
                    fila.tipoDocumentoNombre,
                    fila.aprobacion,
                    fila.descripcion
                  )
                }
                //loading={loading}
                loading={filaSeleccionada === fila.id ? loading : false}
                allowClear
              >
                <Option value="default">Seleccione la Acción </Option>
                <Option value="cargarDocumento">Cargar Documento </Option>
                <Option value="inhabilitar">Inhabilitar</Option>
              </Select>
            </Space>
          ) : (
            //aqui seria cuando el estado es pendienter de aprobar--> ver que opciones van
            <Space size={8}>
              <Select
                placeholder="Seleccione la Acción"
                value={defaultValue}
                style={{ width: 200 }}
                onSelect={(value) =>
                  handleAccionesEnvioPorAprobarOnClick(
                    value,
                    fila.id,
                    fila.tipoDocumentoNombre,
                    fila.descripcion
                  )
                }
                //loading={loading}
                loading={filaSeleccionada === fila.id ? loading : false}
                allowClear
              >
                <Option value="default">Seleccione la Acción </Option>
                {/* <Option value="aprobar">Aprobar</Option>                         */}
                <Option value="verDetalle">Ver detalle</Option>
                <Option value="inhabilitar">Inhabilitar</Option>
                {/* Se comenta esta opción porque no está funcionando - inicio */}
                {/* <Option value="asignarCarpeta">Asignar Carpeta</Option> */}
                {/* Se comenta esta opción porque no está funcionando - fin */}
              </Select>
            </Space>
          )}
        </React.Fragment>
      ),
    },
  ];

  function handleAccionesOnClick(
    value,
    envioId,
    envioDocumentoNombre,
    envioDocumentoDescripcion,
    envioResponsableCargaDocumento,
    envioSedeNombre
  ) {
    if (value === "verDetalle") {
      handleOnClickVerDetalle(
        envioId,
        envioDocumentoNombre,
        envioDocumentoDescripcion,
        envioResponsableCargaDocumento,
        envioSedeNombre
      );
    } else if (value === "reporteExcel") {
      handleOnClickReporteExcel(envioId);
    } else if (value === "reportePDF") {
      handleOnClickReportePDF(envioId);
    } else if (value === "inhabilitar") {
      showDeleteConfirm(envioId);
    } else if (value === "asignarCarpeta") {
      handleOnclickAsignarCarpeta(envioId);
    }
  }
  function handleAccionesEnvioRegistradoOnClick(
    value,
    envioId,
    envioDocumentoId,
    envioDocumentoNombre,
    //envioAprobacion,
    envioFlujo,
    envioDocumentoDescripcion
  ) {
    if (value === "inhabilitar") {
      showDeleteConfirm(envioId);
    } else if (value === "cargarDocumento") {
      /* setDatosEnvio(envioId,envioDocumentoId,envioDocumentoNombre,envioAprobacion,envioDocumentoDescripcion);
       history.push("/documento-laboral/listarEnvioNotificacion");*/
      cargarDocumento(
        envioId,
        envioDocumentoId,
        envioDocumentoNombre,
        //envioAprobacion,
        envioFlujo,
        envioDocumentoDescripcion
      );
    } else if (value === "asignarCarpeta") {
      handleOnclickAsignarCarpeta(envioId);
    }
  }
  function handleAccionesEnvioPorAprobarOnClick(
    value,
    envioId,
    envioDocumentoNombre,
    envioDocumentoDescripcion
  ) {
    if (value === "inhabilitar") {
      showDeleteConfirm(envioId);
    } else if (value === "verDetalle") {
      setDatosEnvio(envioId, envioDocumentoNombre, envioDocumentoDescripcion);
      //history.push("/documento-laboral-porAprobar/listar-detalle");
      history.push("/documento-laboral/listar-detalle");
    } else if (value === "asignarCarpeta") {
      handleOnclickAsignarCarpeta(envioId);
    }
  }
  function cargarDocumento(
    envioId,
    envioDocumentoId,
    envioDocumentoNombre,
    //envioAprobacion,
    envioFlujo,
    envioDocumentoDescripcion,
    formatoCargaId
  ) {
    //setDatosEnvio(envioId,envioDocumentoNombre,envioDocumentoDescripcion,envioDocumentoId,envioAprobacion, formatoCargaId);
    setDatosEnvio(
      envioId,
      envioDocumentoNombre,
      envioDocumentoDescripcion,
      envioDocumentoId,
      envioFlujo,
      formatoCargaId
    );
    history.push("/documento-laboral/listarEnvioNotificacion");
  }

  function showDeleteConfirm(envioId) {
    confirm({
      title: "Estas seguro de inhabilitar el registro seleccionado?",
      icon: <ExclamationCircleOutlined />,
      okText: "si",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleOnClickInhabilitarEnvio(envioId);
      },
    });
  }

  async function handleOnClickInhabilitarEnvio(envioId) {
    await EnvioService.inhabilitar(envioId);
    // setEnvios(
    //   envios.map((envio) => {
    //     if (envio.id === envioId) {
    //       return { ...envio, eliminado: true };
    //     }
    //     return envio;
    //   })
    // );
    setEnvios(envios.filter((envio) => envio.id !== envioId));
    notification.success({
      message: "Operación exitosa",
      description: "Se inhabilito el registro correctamente.",
    });
  }
  async function handleOnClickHabilitarEnvio(envioId) {
    await EnvioService.habilitar(envioId);
    setEnvios(
      envios.map((envio) => {
        if (envio.id === envioId) {
          return { ...envio, eliminado: false };
        }
        return envio;
      })
    );
    notification.success({
      message: "Operación exitosa",
      description: "Se habilito el registro.",
    });
  }

  async function handleOnClickReporteExcel(envioId) {
    try {
      setLoading(true);
      setFilaSeleccionada(envioId);
      const formData = new FormData();
      formData.append("envioId", envioId);
      formData.append(
        "empresaId",
        getUsuarioAutenticado().empresaSeleccionada.id
      );

      let respuesta = await DetalleEnvioService.descargarExcel(formData);
      const filename = respuesta.headers.filename;

      saveAs(respuesta.data, filename);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function handleOnClickReportePDF(envioId) {
    try {
      setLoading(true);
      setFilaSeleccionada(envioId);
      const formData = new FormData();
      formData.append("envioId", envioId);
      formData.append(
        "empresaId",
        getUsuarioAutenticado().empresaSeleccionada.id
      );

      let respuesta = await DetalleEnvioService.descargarPDF(formData);
      const filename = respuesta.headers.filename;

      saveAs(respuesta.data, filename);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  async function handleOnClickVerDetalle(
    envioId,
    envioDocumentoNombre,
    envioDocumentoDescripcion,
    envioResposanbleCargaDocumento,
    envioSedeNombre
  ) {
    setDatosEnvio(
      envioId,
      envioDocumentoNombre,
      envioDocumentoDescripcion,
      "",
      "",
      "",
      envioSedeNombre
    );

    //function setDatosEnvio(id, nombre, descripcion,tipoDocumentoId,aprobacion,formatoCargaId,sedeNombre)

    if (envioResposanbleCargaDocumento !== "COLABORADOR") {
      history.push("/documento-laboral/listar-detalle");
    } else {
      history.push("/documento-personal/listar-detalle");
    }
  }
  async function handleOnClickVerDetallePorAprobar(
    envioId,
    envioDocumentoNombre,
    envioDocumentoDescripcion
  ) {
    setDatosEnvio(envioId, envioDocumentoNombre, envioDocumentoDescripcion);
    //history.push("/documento-laboral-porAprobar/listar-detalle");
    history.push("/documento-laboral/listar-detalle");
  }

  // async function handleOnClickReportes(envioId) {
  //   console.log("handleOnClickReportes:", envioId);
  // }

  function handleOnclickCrearNotificacion() {
    history.push("/documento-laboral/envio");
  }
  async function handleOnclickAsignarCarpeta(envioId) {
    setFilaSeleccionada(envioId);
    const envioObtenido = await EnvioService.buscarPorId(envioId);
    frmCarpeta.setFieldsValue({
      carpetaId: envioObtenido.carpeta.id,
      carpetaDescripcion: envioObtenido.carpeta.descripcion,
    });
    setVisibleModalCarpeta(true);
  }
  function onClickCerrarModal() {
    setVisibleModalCarpeta(false);
  }
  function onLimpiarDatos() {
    frmCarpeta.setFieldsValue({
      carpetaId: "",
      carpetaDescripcion: "",
    });
  }
  function onChangeSelectCarpeta(value, option) {
    frmCarpeta.setFieldsValue({ carpetaDescripcion: option.descripcion });
  }
  async function onFinishCarpeta(carpeta) {
    try {
      carpeta.id = filaSeleccionada;
      await EnvioService.asignarCarpeta(carpeta);
      onClickCerrarModal();
    } catch (error) {
      //console.error(error);
      notification.error({
        message: "Error al asignar la Carpeta",
        descripcion: error.response.data.mensaje,
      });
    }
  }

  return (
    <Container
      icono={<UnorderedListOutlined />}
      titulo="Seguimiento Documentos Laborales"
      botonExtra={
        <Button type="primary" onClick={handleOnclickCrearNotificacion}>
          Crear Notificación
        </Button>
      }
    >
      <div style={{ marginBottom: 16 }}></div>
      {/* <div style={{textAlign:"right"}}><Button type="primary" onClick={handleOnclickCrearNotificacion}>Crear Notificación</Button></div> */}
      {getUsuarioAutenticado().empresaSeleccionada.nombreCampoSede !== null &&
      getUsuarioAutenticado().empresaSeleccionada.nombreCampoSede !== "" ? (
        <Table
          rowKey={(envios) => envios.id}
          columns={columnasConCampoAdicional}
          dataSource={envios}
          pagination={{
            pageSize: [10],
          }}
          rowClassName={(envios) =>
            envios.eliminado ? "detalle-envio_registro-eliminado" : ""
          }
        />
      ) : (
        <Table
          rowKey={(envios) => envios.id}
          columns={columnas}
          dataSource={envios}
          pagination={{
            pageSize: [10],
          }}
          rowClassName={(envios) =>
            envios.eliminado ? "detalle-envio_registro-eliminado" : ""
          }
        />
      )}
      <Modal
        title="Asignar Carpeta"
        visible={visibleModalCarpeta}
        onCancel={onClickCerrarModal}
        afterClose={onLimpiarDatos}
        footer={null}
      >
        <Form form={frmCarpeta} onFinish={onFinishCarpeta}>
          <Form.Item
            label="Carpeta"
            name="carpetaId"
            rules={[
              {
                required: true,
                message: "Seleccione la Carpeta.",
              },
            ]}
          >
            <Select
              onChange={onChangeSelectCarpeta}
              optionLabelProp="label"
              placeholder="Seleccione la carpeta"
            >
              {carpetas.length > 0 &&
                carpetas.map((carpeta) => {
                  return (
                    <Option
                      key={carpeta.id}
                      value={carpeta.id}
                      label={
                        <div>
                          <FolderTwoTone
                            style={{ fontSize: "20px", color: "#F7D358" }}
                            twoToneColor="#F7D358"
                          />{" "}
                          {carpeta.descripcion}
                        </div>
                      }
                      descripcion={carpeta.descripcion}
                    >
                      <Space size={carpeta.nivel * 10}>
                        <span></span>
                        <span>
                          {carpeta.nivel === 0 ? (
                            <FolderFilled
                              style={{ fontSize: "20px", color: "#F7D358" }}
                            />
                          ) : (
                            <FolderTwoTone
                              style={{ fontSize: "18px", color: "#F7D358" }}
                              twoToneColor="#F7D358"
                            />
                          )}{" "}
                          {carpeta.descripcion}
                        </span>
                      </Space>
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item name="carpetaDescripcion" noStyle>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item>
            <Row justify="end">
              <Button type="primary" htmlType="submit">
                Guardar
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </Container>
  );
}
