import React, { useState, useContext } from "react";
import { Menu, Dropdown,message } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  UnorderedListOutlined,
  CaretDownOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useAtom } from "jotai";
import {
  solicitudAusenciaSeleccionadaAtom,
  visibleModalAprobarSolicitudAtom,
  visibleModalRechazarSolicitudAtom,
  visibleModalAdjuntosAtom,
} from "../../SolicitudPendienteAprobacionStore";
import { SolicitudAusenciaService } from "services";
import { SecurityContext } from "context/SecurityContextProvider";
import { useModalVisorPdf } from "store/global";

const MenuAcciones = ({ solicitud }) => {
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [, setSolicituduAusenciaSeleccionada] = useAtom(solicitudAusenciaSeleccionadaAtom);
  const [, setVisibleModalAprobarSolicitud] = useAtom(visibleModalAprobarSolicitudAtom);
  const [, setVisibleModalRechazarSolicitud] = useAtom(visibleModalRechazarSolicitudAtom);
  const { getUsuarioAutenticado } = useContext(SecurityContext);
  const mostrarModalVisorPdf = useModalVisorPdf((state) => state.mostrarModal);
  const [, setVisibleModalAdjuntos] = useAtom(visibleModalAdjuntosAtom);

  const onClickMenu = async ({ key }) => {
    if (key === "aprobar") {
      onClickOpcionAprobar();
    } else if (key === "rechazar") {
      onClickOpcionRechazar();
    } else if (key === "verSolicitud") {
      onClickVerSolicitud();
    } else if (key === "verAdjuntos") {
      onclickVerAdjuntos();
    }
    setVisibleMenu(false);
  };

  const onClickOpcionAprobar = () => {
    setVisibleModalAprobarSolicitud(true);
    setSolicituduAusenciaSeleccionada(solicitud);
  };

  const onClickOpcionRechazar = () => {
    console.log("rechazar...");
    setVisibleModalRechazarSolicitud(true);
    setSolicituduAusenciaSeleccionada(solicitud);
  };
  const onClickVerSolicitud = async () => {
    const respuesta = await SolicitudAusenciaService.getUrlDocumento(
      getUsuarioAutenticado().empresaSeleccionada.id,
      solicitud.id,
      "WEB"
    );       
    mostrarModalVisorPdf(respuesta.data.urlSolicitud, "Solicitud " + solicitud.tipo.descripcion, true, true);   
  };
  const onclickVerAdjuntos = () => {
    setVisibleModalAdjuntos(true);
    setSolicituduAusenciaSeleccionada(solicitud);
  };

  const menu = () => {
    return (
      <Menu onClick={onClickMenu}>        
        <Menu.Item key="aprobar">
          <CheckCircleOutlined /> Aprobar Solicitud
        </Menu.Item>
        {/* <Menu.Item key="notificarConvenio">
          <CheckCircleOutlined /> Notificar convenio
        </Menu.Item> */}
        <Menu.Item key="rechazar">
          <CloseCircleOutlined /> Rechazar Solicitud
        </Menu.Item>
        <Menu.Item key="verSolicitud">
          <SearchOutlined /> Ver Solicitud
        </Menu.Item>
        <Menu.Item key="verAdjuntos">
          <SearchOutlined /> Ver Adjuntos
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <React.Fragment>
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        placement="bottomCenter"
        onVisibleChange={(visible) => setVisibleMenu(visible)}
        visible={visibleMenu}
      >
        <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          <UnorderedListOutlined style={{ fontSize: "16px", fontWeight: "bold", color: "#1678c1" }} />
          <CaretDownOutlined
            style={{
              paddingLeft: "2px",
              fontSize: "12px",
              fontWeight: "bold",
              color: "#1678c1",
            }}
          />
        </a>
      </Dropdown>
    </React.Fragment>
  );
};

export { MenuAcciones };
