import { BaseService } from "./BaseService";

const ComunicadoService = {
    async insertar(comunicado) {
        const respuesta = await BaseService.post("/notificar/comunicado", comunicado);
        return respuesta.data;
    },

    async listarColaboradoresPorEmpresaConFiltro(data) {
        const respuesta = await BaseService.get("/comunicado/colaborador/listar", {
            params: {
                ...data,
            },
        });
        return respuesta.data;
    },
};
export default ComunicadoService;
