import React from "react";

import { FilterServerInputFiltrosSeleccionados } from "./FilterServerInputFiltrosSeleccionados";
import { FilterServerInputTxtBusqueda } from "./FilterServerInputTxtBusqueda";
import { FilterOutlined } from "@ant-design/icons"

export function FilterServerInput({
  placeholder,
  filtrosSeleccionados,
  valorTxtBusqueda,
  onChangeTxtBusqueda,
  onKeyDownTxtBusqueda,
  onClickTxtBusqueda,
  refTxtBusqueda,
  refDivFiltro,
  onQuitarFiltro,
  onClickFiltro,
  menu,
}) {
  return (
    <div className="filter-input-container">
      <div className="filter-input-search-icon">
        <FilterOutlined />
      </div>
      <FilterServerInputFiltrosSeleccionados
        refDivFiltro={refDivFiltro}
        filtrosSeleccionados={filtrosSeleccionados}
        onQuitarFiltro={onQuitarFiltro}
        menu={menu}
        onClickFiltro={onClickFiltro}
      />

      <FilterServerInputTxtBusqueda
        placeholder={placeholder}
        valorTxtBusqueda={valorTxtBusqueda}
        onChange={onChangeTxtBusqueda}
        onKeyDown={onKeyDownTxtBusqueda}
        onClick={onClickTxtBusqueda}
        refTxtBusqueda={refTxtBusqueda}
      />
    </div>
  );
}
