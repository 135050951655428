import { atom } from "jotai";

export const documentosOnboardingAtom = atom([]);
export const detallesOnboardingAtom = atom([]);
export const isDetalleAtom = atom(false);
export const envioIdAtom = atom();
export const descripcionEnvioAtom = atom();
export const filtroColaboradorAtom = atom();
export const filtroFechaInicioAtom = atom();
export const filtroFechaFinAtom = atom();
export const buttonMasivoAtom = atom(false);
export const loadingAtom = atom(false);
export const totalEnviosAtom= atom(null);
export const paginaActualAtom = atom(1);
export const filtroProyectoAtom = atom(null);
export const filtroReclutadorAtom = atom(null);
export const filtroProcesoAtom = atom(null);
export const filtroEstadoColaboradorAtom = atom(null);