import React from "react";

import FilterConstantes from "./FilterServerConstantes";

// import DatePicker from "react-datepicker";

// import format from "date-fns/format";

export function FilterServerInputMenu({
  menu = "",
  filtros,
  onSeleccionarFiltro,
  onCrearFiltro,
  filtroSeleccionado,
  data,
  refTxtBusqueda,
}) {
  let style = { left: 0 };

  if (refTxtBusqueda.current !== null) {
    style.left = refTxtBusqueda.current.offsetLeft;
  }

  if (menu === FilterConstantes.MENU.filtros) {
    return (
      <FilterInputMenuFiltros
        filtros={filtros}
        onSeleccionarFiltro={onSeleccionarFiltro}
        style={style}
      />
    );
  }

  if (menu === FilterConstantes.MENU.opciones) {
    switch (filtroSeleccionado.tipo) {
      case "lista":
        const opcionesPorColumna = data.map((fila) => {
          return fila[filtroSeleccionado.id];
        });

        let opcionesPorColumnaUnicas = [...new Set(opcionesPorColumna.filter(e => e))];

        return (
          <FilterInputMenuLista
            opciones={opcionesPorColumnaUnicas}
            onCrearFiltro={onCrearFiltro}
            filtroSeleccionado={filtroSeleccionado}
            style={style}
          />
        );
      case "fecha":
        return (
          <FilterInputMenuFecha
            onCrearFiltro={onCrearFiltro}
            filtroSeleccionado={filtroSeleccionado}
            style={style}
          />
        );
      default:
        return "";
    }
  }

  return "";
}

export function FilterInputMenuFiltros({
  filtros,
  onSeleccionarFiltro,
  style,
}) {
  return (
    <div className="filter-menu-container" style={style}>
      <div className="filter-menu-titulo">{filtros.titulo}</div>
      <div className="filter-menu-divider" />
      {filtros.data.map((filtro) => {
        return (
          <div
            key={filtro.id}
            className="filter-menu-item"
            onClick={() => onSeleccionarFiltro(filtro)}
          >
            {filtro.titulo}
          </div>
        );
      })}
    </div>
  );
}

export function FilterInputMenuFecha({
  filtroSeleccionado,
  onCrearFiltro,
  style,
}) {
  const fecha = new Date();

  return (
    <div className="filter-menu-container" style={style}>
      Aqui va el datapicker
      {/* <DatePicker
        className="form-control"
        selected={fecha}
        // onChange={fecha => onCrearFiltro(format(fecha, "dd/MM/yyyy"))}
        onChange={(fecha) =>
          onCrearFiltro(
            format(
              fecha,
              filtroSeleccionado.formato
                ? filtroSeleccionado.formato
                : "dd/MM/yyyy"
            )
          )
        }
        inline
      /> */}
    </div>
  );
}

export function FilterInputMenuLista({
  opciones,
  filtroSeleccionado,
  onCrearFiltro,
  style,
}) {
  if (!opciones || opciones.length === 0) {
    return "";
  }

  return (
    <div className="filter-menu-container" style={style}>
      {opciones.map((opcion, indice) => {
        return (
          <div
            key={indice}
            className="filter-menu-item"
            onClick={() => {
              onCrearFiltro(opcion);
            }}
          >
            {opcion}
          </div>
        );
      })}
    </div>
  );
}
