import React, { useEffect, useState } from "react";
import { useModalFirmarStore } from "hooks/firma-documento-generico"
import { Table, Space, Button } from "antd";
import { Loading } from "../loading/Loading";
import useModal from "antd/lib/modal/useModal";
import { StepsFirmaDocumento } from "enums/StepsFirmaDocumento";

const StepCertificadoDigital = () => {

    const loading = useModalFirmarStore(state => state.loading);
    const certificados = useModalFirmarStore(state => state.certificados);
    const certificadoSeleccionadoId = useModalFirmarStore(state => state.certificadoSeleccionadoId);
    const getCertificadosFirmante = useModalFirmarStore(state => state.getCertificadosFirmante);
    const setCertificadoSeleccionadoId = useModalFirmarStore(state => state.setCertificadoSeleccionadoId);
    const irAlPaso = useModalFirmarStore(state => state.irAlPaso);

    useEffect(() => {
        getCertificadosFirmante();
    }, []);

    const columnas = [
        {
            title: "Serie",
            dataIndex: "serie",
            width: '20%'
        },
        {
            title: "Representante Legal",
            dataIndex: "representanteLegalNombre",
            align: "center",
        },
        {
            title: "Fecha Vigencia Inicio",
            dataIndex: "fechaVigenciaInicio",
            align: "center",
        },
        {
            title: "Fecha Vigencia Fin",
            dataIndex: "fechaVigenciaFin",
            align: "center",
        },

    ];

    return (
        <React.Fragment>

            <div style={{ height: '120px' }}>
                <div style={{ marginTop: '10px', fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>
                    Seleccione su certificado
                </div>

                <div style={{ marginTop: '10px', marginBottom: '10px', textAlign: 'center' }}>
                    <Space>
                        <Button onClick={() => irAlPaso(StepsFirmaDocumento.POSICION_FIRMA)}>
                            {`< Atras`}
                        </Button>

                        <Button type="primary" disabled={certificadoSeleccionadoId === undefined} onClick={() => irAlPaso(StepsFirmaDocumento.FIRMAR)}>
                            {`Siguiente >`}
                        </Button>

                    </Space>
                </div>


            </div>

            <div style={{ height: '100%', width: '90%' }}>
                <Table
                    rowKey={(certificado) => certificado.id}
                    rowSelection={{
                        type: 'radio',
                        onChange: (selectedRowKeys, selectedRows) => {
                            setCertificadoSeleccionadoId(selectedRowKeys[0]);
                        },
                    }}
                    loading={loading}
                    columns={columnas}
                    dataSource={certificados}
                />
            </div>
        </React.Fragment>
    );
}

export default StepCertificadoDigital;