import React, { useState, useEffect } from "react";
import { Select } from "antd";
import produce from "immer";

const CampoSelect = ({ options, valoresIniciales, onSeleccionar, onQuitar }) => {
  const [valoresSeleccionados, setValoresSeleccionados] = useState([]);
  const [itemsSeleccionados, setItemsSeleccionados] = useState([]);

  const onSelect = (valor, item) => {
    // console.log("onselect - itemseleccionado : ", itemsSeleccionados)
    // console.log("onselect - item: ", item )
    setValoresSeleccionados(
      produce((draft) => {
        draft.push(valor);
      })
    );
    setItemsSeleccionados(
      produce((draft) => {
        draft.push(item);
      })
    );
    onSeleccionar([...itemsSeleccionados, item]);
  };

  useEffect(() => {

    const _valoresIniciales = valoresIniciales?.map((v) => {
      return v.id;
    });
    setValoresSeleccionados(_valoresIniciales);
    setItemsSeleccionados(valoresIniciales);
    onSeleccionar(valoresIniciales);


  }, [valoresIniciales]);

  const onDeselect = (valor, item) => {
    const quitarCampo = onQuitar(valor, item);
    if (!quitarCampo) {
      //se mantiene los valores seleccionados
      setValoresSeleccionados([...valoresSeleccionados]);
    } else {
      //se quita el valor del estado valoresSeleccionados
      setValoresSeleccionados(
        produce((draft) => {
          const indice = draft.findIndex((v) => v === valor);
          draft.splice(indice, 1);
        })
      );
      //se quita el item del estado itemsSeleccionados
      setItemsSeleccionados(
        produce((draft) => {
          const indice = draft.findIndex((i) => i.id === item.id);
          draft.splice(indice, 1);
        })
      );
      //se invoca al metodo onSeleccionar
      const items = itemsSeleccionados.slice().filter((i) => i.id !== item.id);
      onSeleccionar([...items]);
    }
  };

   return <Select mode="multiple" placeholder={"Seleccione una opción "} value={valoresSeleccionados} onSelect={onSelect} options={options} onDeselect={onDeselect} />;

  // return (
  //   <Select
  //     mode="multiple"
  //     value={valoresSeleccionados}
  //     onSelect={onSelect}
  //     options={options}
  //     onDeselect={onDeselect}
  //   />
  // );

};

export default CampoSelect;
