import React, { useContext, useEffect, useState } from "react";
import { SolicitudAusenciaService } from "services";
import { Button, Modal, Alert, Input, message,Form } from "antd";
import { useAtom } from "jotai";
import produce from "immer";
import { SecurityContext } from "context/SecurityContextProvider";
import { ApiResponse } from "enums";
import { solicitudAusenciaSeleccionadaAtom,solicitudesAusenciaPendientesAtom,
    visibleModalRechazarSolicitudAtom, } from "../../SolicitudPendienteAprobacionStore";


const ModalRechazarSolicitudAusencia=()=>{
  const { getUsuarioAutenticado } = useContext(SecurityContext);
    const [solicitudAusenciaSeleccionada] = useAtom(solicitudAusenciaSeleccionadaAtom);
    const [, setSolicitudesPendientesAprobar] = useAtom(solicitudesAusenciaPendientesAtom);
    const [visibleModalRechazar, setVisibleModalRechazar] = useAtom(visibleModalRechazarSolicitudAtom);
    const [loading, setLoading] = useState(false);
    const [claveIngresada, setClaveIngresada] = useState("");
    const [mensajeError, setMensajeError] = useState("");
    const [formRechazo] = Form.useForm();

    const onClickBtnRechazar = async() =>{
      setLoading(true);
      const response = await SolicitudAusenciaService.rechazarPorGestionHumana({
        solicitudAusenciaId: solicitudAusenciaSeleccionada.id,
        numeroDocumentoIdentidad: getUsuarioAutenticado().numeroDocumentoIdentidad,
        //clave: claveIngresada,
        motivo:formRechazo.getFieldValue("motivoRechazo").trim(),
      });
      if (response.estado === ApiResponse.EXITO) {
        message.success("Se ha rechazado la solicitud correctamente.");
        setVisibleModalRechazar(false);
        setSolicitudesPendientesAprobar(
          produce((draft) => {
            const index = draft.findIndex((solicitud) => solicitud.id === solicitudAusenciaSeleccionada.id);
            draft.splice(index, 1);
          })
        );
      } else {
        setMensajeError(response.mensaje);
      }
      setLoading(false);
     }
    const onClickBtnCancelar=()=>{
     setVisibleModalRechazar(false);
     formRechazo.resetFields();
    }
    return(
       <Modal
       title={
        <div>
          Rechazar solicitud de: <b>{solicitudAusenciaSeleccionada?.colaborador.nombreCompleto}</b>
        </div>
      }
      visible={visibleModalRechazar}
      closable={false}
      width={400}
      destroyOnClose={true}
      footer={[
        <Button key="cancelar" disabled={loading} onClick={onClickBtnCancelar} style={{ padding: "0px 30px" }}>
          Cancelar
        </Button>,
        <Button
          key="rechazar"
          type="primary"
          loading={loading}
         // onClick={onClickBtnRechazar}
          onClick={formRechazo.submit}
          style={{ padding: "0px 30px" }}
        >
          Rechazar
        </Button>,
      ]}
      centered
       >
        {mensajeError && (
        <Alert
          message={mensajeError}
          onClose={() => setMensajeError(undefined)}
          style={{ marginBottom: "4px" }}
          type="error"
          showIcon
          closable
        />
      )}
        <Form layout="vertical" form={formRechazo} onFinish={onClickBtnRechazar}>
            {/* <Form.Item label="Ingresa tu clave" name="clave">
            <Input.Password
             value={claveIngresada}
             onChange={(e) => {
             setClaveIngresada(e.target.value);
             setMensajeError(undefined);
             }}
             placeholder={"Clave"}
            />
            </Form.Item> */}
            <Form.Item 
               label="Ingrese motivo de rechazo" 
               name="motivoRechazo"
               rules={[{
                required:true,
                message:"Por favor ingrese el motivo"
               }]}
               >
                <Input.TextArea rows={4}/>
            </Form.Item>
        </Form>
       </Modal>
    );

}
export default ModalRechazarSolicitudAusencia;