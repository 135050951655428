import React, { useEffect, useState } from 'react'
import { Descriptions, Form, Typography, Input, Row, Col } from 'antd'
import moment from 'moment'

const DetalleSolicitudPersonal = ({ solicitud }) => {
  const [form] = Form.useForm()
  const [camposAdicionales, setCamposAdicionales] = useState(undefined)
  useEffect(() => {
    form.setFieldsValue(solicitud)
    const perfilAdicionales =
      solicitud?.perfilPuesto?.perfilInformacionAdicional
    if (perfilAdicionales != null)
      setCamposAdicionales(Object.keys(perfilAdicionales))
  }, [solicitud])

  return (
    <div style={{ padding:"0 20vw", height:"calc(75vh - 4rem)", overflow:"hidden scroll"}}>
      <Form form={form} layout="vertical" >
        <Row gutter={36}>
          <Col span={8}>
            <Form.Item name={'tipoConvocatoria'} label={'Tipo Convocatoria'}>
              <Input readOnly width={'100%'} />
            </Form.Item>{' '}
          </Col>
          <Col span={8}>
            <Form.Item name={['perfilPuesto', 'descripcion']} label={'Perfil'}>
              <Input readOnly width={'100%'} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'fechaInicioLabores'} label={'Fecha Inicio Laborales'} >
              <Input readOnly width={'100%'} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={36}>
          <Col span={16}>
            <Form.Item name={['puesto', 'nombre']} label={'Puesto'}>
              <Input readOnly width={'100%'} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'cantidad'} label={'Cantidad'}>
              <Input readOnly width={'100%'} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name={'objetivo'} label={'Objetivos'}>
          <Input.TextArea readOnly
             autoSize={{ minRows: 3, maxRows: 4 }} style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item name={'requisitos'} label={'Requisitos'}>
          <Input.TextArea readOnly
             autoSize={{ minRows: 3, maxRows: 6 }} style={{ width: '100%' }} />
        </Form.Item>
      </Form>
      {camposAdicionales && (
        <Descriptions title="Información Adicionales" bordered>
          {camposAdicionales.map((key) => {
            const data =
              solicitud?.perfilPuesto?.perfilInformacionAdicional[key]
            const isDate = () => {
              const date = Date.parse(data)
              return !isNaN(date)
            }
            const textConverted = () => {
              const date = moment(data)
              return date.format('DD/MM/YYYY')
            }
            return (
              <Descriptions.Item key={key} label={key} span={3}>
                {' '}
                {isDate() ? textConverted() : data}{' '}
              </Descriptions.Item>
            )
          })}
        </Descriptions>
      )}
    </div>
  )
}

export default DetalleSolicitudPersonal
