import React from "react";
import { Form,DatePicker,Select,Row,Col } from "antd";
import  {EstadoEntrevista}  from "enums/reclutamiento-y-seleccion/EstadoEntrevista";
import { EntrevistaService } from "services/reclutamiento-y-seleccion/EntrevistaService";
import { entrevistasAtom } from "../../SeguimientoEntrevistasStore";
import { useAtom } from "jotai";
import moment from "moment";
const FiltroEntrevistas=()=>{

    const [formFiltro]=Form.useForm();
    const [entrevistas,setEntrevistas]=useAtom(entrevistasAtom);

    const actualizaTabla = async(formulario)=>{
     
      const respuesta = await EntrevistaService.listarConFiltros(
                            formulario.filtroFechaEntrevista ? formulario.filtroFechaEntrevista.format("DD/MM/YYYY 00:00"):undefined,
                            formulario.filtroEstado);
    
      setEntrevistas(respuesta);
    }

    return(
        <Form 
           layout="horizontal"
           form={formFiltro}
           onFinish={actualizaTabla}
          
        >
            <Row gutter={16}>
                <Col>
                    <Form.Item label="Fecha Entrevista" name="filtroFechaEntrevista">
                    <DatePicker format={"DD/MM/YYYY"} onChange={()=>formFiltro.submit()} />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item label="Estado" name="filtroEstado">
                        <Select style={{ width: "250px" }} placeholder="Seleccione el estado" allowClear={true}  onChange={()=>formFiltro.submit()}>
                            <Select.Option value={EstadoEntrevista.PENDIENTE}>{EstadoEntrevista.PENDIENTE}</Select.Option> 
                            <Select.Option value={EstadoEntrevista.REALIZADO}>{EstadoEntrevista.REALIZADO}</Select.Option>
                            <Select.Option value={EstadoEntrevista.NO_ASISTIO}>NO ASISTIO</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            
            
        </Form>
    )
}
export default FiltroEntrevistas;