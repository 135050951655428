import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { Container } from "components/Container";
import { RegistroConvocatoria } from "./componentes";
import { useHistory } from "react-router-dom";

const ConvocatoriaCrearPage=()=>{

  let history=useHistory();

  const onClickRetornar=()=>{
    history.push("/reclutamiento-y-seleccion/convocatorias");
 }
    return(
        <JotaiProvider>
          <Container titulo="Crear Convocatoria" onClickRetornar={onClickRetornar}>
            <RegistroConvocatoria/>
          </Container>
        </JotaiProvider>
        
    )

}
export default ConvocatoriaCrearPage;