import React from "react";
import { Provider as JotaiProvider } from "jotai";
import { Container } from "components/Container";
import { PerfilRegistro } from "./componentes";
import { DrawerCampos } from "./componentes";
import { useHistory } from "react-router-dom";

const CrearPerfilSolicitudPersonalPage = () => {
  let history = useHistory();
  const onClickRetornar = () => {
    history.push("/reclutamiento-y-seleccion/solicitud-personal/perfiles");
  };
  return (
    <JotaiProvider>
      <Container titulo="Crear Perfil" onClickRetornar={onClickRetornar}>
        <PerfilRegistro />
        <DrawerCampos />
      </Container>
    </JotaiProvider>
  );
};
export default CrearPerfilSolicitudPersonalPage;
