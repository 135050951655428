import React, { useContext, useState } from "react";
import { Button, Popconfirm ,notification} from "antd";
import { SendOutlined, ClearOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { useModalConfirmar } from "store/global/";
import { ContratoService } from "services/ContratoService";
import { useAtom } from "jotai";
import * as atoms from "store/local/contratos/DetalleSeguimientoContratoStore";

import "./Botones.css";
import { SecurityContext } from "context/SecurityContextProvider";

const Botones = ({ disabled = false, envio, setEnvio }) => {
  const { getUsuarioAutenticado } = useContext(SecurityContext);

  const mostrarModal = useModalConfirmar((state) => state.mostrarModal);

  const [loading, setLoading] = useState(false);
  const [contratos, setContratos] = useAtom(atoms.contratos);
  // const [loading, setLoading] = useAtom(atoms.loading);
  //const [envio, setEnvio] = useAtom(atoms.envio);

  const onEliminarContratosCargados = async () => {
    try {
      setLoading(true);
      const envioId = envio.id;
      const empresaId = getUsuarioAutenticado().empresaSeleccionada.id;
      const _envio = await ContratoService.limpiarDatosTramaCargada(empresaId, envioId);
      setEnvio(_envio);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onMostrarModalConfirmar = () => {
    if(contratos.length>0){
      mostrarModal();
    }
    else{
      notification.error({
        message:"No se puede notificar el contrato",
        description:"No hay colaboradores cargados."
      })
    }
  };

  return (
    <div style={{ marginBottom: "10px" }}>
      <Button className="boton-notificar" loading={loading} onClick={onMostrarModalConfirmar} disabled={disabled}>
        <SendOutlined /> Notificar Contratos
      </Button>

      <Popconfirm
        placement="bottom"
        icon={<QuestionCircleOutlined style={{ color: "orange" }} />}
        title="¿Está seguro de eliminar los contratos cargados？"
        okText="Si"
        okButtonProps={{ loading: loading }}
        cancelText="No"
        onConfirm={onEliminarContratosCargados}
        disabled={disabled}
      >
        <Button loading={loading} disabled={disabled}>
          <ClearOutlined /> Limpiar datos cargados
        </Button>
      </Popconfirm>
    </div>
  );
};

export default Botones;
