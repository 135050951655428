import React, { useContext, useEffect } from "react";
import { Select, DatePicker, Input, Button } from "antd"
import { EstadoDocumentoGenerico } from "enums/EstadoDocumentoGenerico";
import locale from 'antd/es/date-picker/locale/es_ES';
import { FilterOutlined } from "@ant-design/icons";
import { useListaDocumentosStore } from "hooks/firma-documento-generico";
import { SecurityContext } from "context/SecurityContextProvider";
import moment from 'moment';
import "./FiltroDocumento.css"

const { RangePicker } = DatePicker;
const { Option } = Select;

const FiltroDocumentos = () => {

    const setDocumentos = useListaDocumentosStore(state => state.setDocumentos);
    const buscarDocumentos = useListaDocumentosStore(state => state.buscarDocumentos);
    const { getUsuarioAutenticado } = useContext(SecurityContext);

    let filtros = {
        organizacionId: getUsuarioAutenticado().organizacionId,
        fechaEnvioInicio: moment().startOf('month'),
        fechaEnvioFin: moment().endOf('month'),
        estado: null,
        solicitadoPor: undefined
    };


    const onChangeEstadoDocumento = (estadoDocumento) => {
        filtros = { ...filtros, estado: estadoDocumento };
    }

    const onChangeFechaEnvio = (fechas) => {
        filtros = { ...filtros, fechaEnvioInicio: fechas[0], fechaEnvioFin: fechas[1] }
    }

    const onChangeSolicitadoPor = (e) => {
        filtros = { ...filtros, solicitadoPor: e.target.value };
    }

    const onClickBtnFiltrar = async () => {
        const documentos = await buscarDocumentos(filtros);
        setDocumentos(documentos);
    }

    return (
        <div className='filtro-contenedor'>
            <div className="filtro-item">
                <span className="etiqueta">
                    <span className="requerido">*</span>
                    Fecha envío:
                </span>
                <RangePicker
                    className="control"
                    locale={locale}
                    defaultValue={[filtros?.fechaEnvioInicio, filtros?.fechaEnvioFin]}
                    format="DD/MM/YYYY"
                    onChange={onChangeFechaEnvio} />
            </div>

            <div className="filtro-item">
                <span className="etiqueta">Estado:</span>
                <Select defaultValue={null} style={{ width: '100%' }} onChange={onChangeEstadoDocumento} >
                    <Option value={null}>
                        TODOS
                    </Option>
                    <Option value={EstadoDocumentoGenerico.PENDIENTE_FIRMA}>
                        PENDIENTE
                    </Option>
                    <Option value={EstadoDocumentoGenerico.FIRMADO}>
                        FIRMADO
                    </Option>
                </Select>
            </div>

            <div className="filtro-item">
                <span className="etiqueta">Solicitado por:</span>
                <Input className="control" onChange={onChangeSolicitadoPor} />
            </div>

            <div className="filtro-item boton">
                <Button style={{ width: '100%', borderColor: '#1678c1', color: '#1678c1' }} onClick={onClickBtnFiltrar}>
                    <FilterOutlined />Filtrar
                </Button>
            </div>

            {/* <div className="filtro-item boton">
                <Button style={{ width: '100%' }}>
                    <ClearOutlined />Limpiar
                </Button>
            </div> */}

        </div>

    )
};

export default FiltroDocumentos;