import React,{useEffect,useContext} from "react";
import TipoDocumentoService from "services/TipoDocumentoService";
import {tipoDocumentosAtom,filtroDocumentoAtom} from "../PlantillaEditarStore";
import { useAtom } from "jotai";
import { SecurityContext } from "context/SecurityContextProvider";
import { CategoriaDocumento } from "enums/CategoriaDocumento";

const useListarTipoDocumento=()=>{

    const[tiposDocumento,setTiposDocumento]=useAtom(tipoDocumentosAtom);          

  useEffect(()=>{
       const cargarDatos = async()=>{                                  
           const documentos= await TipoDocumentoService.listarFormularioYCargaDocumento();              
           setTiposDocumento(documentos);
      
           
       }
       cargarDatos();
  },[])
  return{tiposDocumento}
}
export default useListarTipoDocumento;