import React,{useEffect,useState} from "react";
import { Button, Modal, Alert, Input, message,Divider,Row ,Table,Typography} from "antd";
import { useAtom } from "jotai";
import {visibleModalAdjuntosAtom,solicitudAusenciaSeleccionadaAtom} from "../../SolicitudPendienteAprobacionStore";
import { SolicitudAusenciaService } from "services";
import { SearchOutlined } from '@ant-design/icons';
import { useModalVisorPdf } from "store/global";

const ModalAdjuntos=()=>{
    const[visibleModalAdjuntos,setVisibleModalAdjuntos]=useAtom(visibleModalAdjuntosAtom);
    const[solicitudSeleccionada,setSolicitudSeleccionada]=useAtom(solicitudAusenciaSeleccionadaAtom);
    const [adjuntos,setAdjuntos] =useState([]);
    const mostrarModalVisorPdf = useModalVisorPdf((state)=>state.mostrarModal);
     useEffect(async()=>{
        
       if(visibleModalAdjuntos && solicitudSeleccionada){
           // console.log("solicitud seleccionada: ", solicitudSeleccionada);
          const respuesta= await SolicitudAusenciaService.getUrlsAdjuntos(solicitudSeleccionada.id);
          const _adjuntos= Object.values(respuesta.data);
          setAdjuntos(_adjuntos);  
          //console.log("adjuntos",Object.values(respuesta.data));
       }

     },[visibleModalAdjuntos]);

    const onClickCerrar=()=>{
      setVisibleModalAdjuntos(false);
    }

    const onClickVerAdjunto=(url)=>{
      mostrarModalVisorPdf(url,"Adjuntos",true,true);
      //console.log("VerUrl:",url);
     
    }
          
   return(
    <Modal
       title="Documentos Adjuntos"
       visible={visibleModalAdjuntos}
       width={400}
       onCancel={onClickCerrar}
       destroyOnClose={true}       
       centered
       footer={null}
    >
           
        {adjuntos && adjuntos.length>0 ?                           
            (  adjuntos.map((url)=>{

              return ( 
                <React.Fragment>                 
                    <Button key={url} type="link" icon={< SearchOutlined/>} onClick={()=>onClickVerAdjunto(url)}>
                      ver Adjunto {adjuntos.indexOf(url)+1}
                    </Button>                 
                    <Divider />
                </React.Fragment>
                );
            }) )
            :(
              <Typography.Text type="secondary">No hay documentos adjuntos</Typography.Text>
            )
         }        
      
      
    </Modal>
   )
}
export default ModalAdjuntos;