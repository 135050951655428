import React,{useContext,useEffect,useState} from "react";
import { Dropdown, Menu, Button, message,notification } from "antd";
import { UnorderedListOutlined, CaretDownOutlined, FormOutlined } from "@ant-design/icons";
import { SecurityContext } from "context/SecurityContextProvider";
import { ContratoVencidoService } from "services/ContratoVencidoService";
import EvaluacionService from "services/EvaluacionService";
import TipoDocumentoService from"services/TipoDocumentoService";
import { CategoriaDocumento } from "enums/CategoriaDocumento";
import { EstadoAprobacionEvaluacion } from "enums/EstadoAprobacionEvaluacion";
import { moment } from "moment";

const MenuAcciones = ({contrato}) =>{
    const { getUsuarioAutenticado } = useContext(SecurityContext);
    //const [evaluacionesPendientes,setEvaluacionesPendientes] = useState([]);

//   useEffect(()=>{
//      const datosIniciales = async()=>{
//        const evaluaciones= await EvaluacionService.listarPorcolaborador(contrato.numeroDocumentoIdentidadColaborador,EstadoAprobacionEvaluacion.PENDIENTE);
//        setEvaluacionesPendientes(evaluaciones);       
//      }
//       datosIniciales();
//   },[evaluacionesPendientes])

   const onClickMenu = (opcion) => {
        switch (opcion.key) {
            case 'notificar-evaluacion-desempenio':
                onClickNotificarEvaluacionDesempenio();
                break;           
            default:
                console.error("No se encuentra esa opción");
        }
    }
    const onClickNotificarEvaluacionDesempenio = async()=>{
        
        try {

            const evaluacionesPendientes= await EvaluacionService.listarPorcolaborador(contrato.numeroDocumentoIdentidadColaborador,EstadoAprobacionEvaluacion.PENDIENTE);
            if(evaluacionesPendientes.length>0){
                notification.error({
                    message: "Error al notificar la evaluación",
                    description: "Tiene una evaluación pendiente",
                  });
            }
            else{

                var fechaActual=(new Date().getMonth()+ 1).toString().length===1 ? "0"+(new Date().getMonth()+1):new Date().getMonth()+1;
                const tipoDocumentos = await TipoDocumentoService.listar(
                    getUsuarioAutenticado().empresaSeleccionada.id,
                    CategoriaDocumento.EVALUACION.id
                );

                const evaluacion={
                periodo: fechaActual+"/"+new Date().getFullYear(),
                empresaId: getUsuarioAutenticado().empresaSeleccionada.id,
                organizacionId: getUsuarioAutenticado().organizacionId,   
                tipoDocumentoId:tipoDocumentos[0].id ,            
                tipoDocumentoNombre: tipoDocumentos[0].nombre,
                numeroDocumentoIdentidadColaborador:contrato.numeroDocumentoIdentidadColaborador,
                }             
                await ContratoVencidoService.insertarEvaluacion(evaluacion);
                notification["success"]({
                    message: "Notificación enviada",
                    description: "Se ha enviado la evaluación exitosamente.",
                });

                }

            
             
        } catch (error) {
            console.error(error);
            notification.error({
                message: "Error al notificar la evaluación",
                description: error.response.data.mensaje,
              });
        }     
     
    }    

    const menu =() => {        
        
        return (                  
            <Menu onClick={onClickMenu}>              
                {/* {evaluacionesPendientes.length===0 && */}
                    <Menu.Item key="notificar-evaluacion-desempenio">
                    <FormOutlined /> Notificar Evaluación desempeño
                    </Menu.Item>               
               
                {/* } */}
            </Menu>
            
            
        )
    }
    return(              
        <Dropdown
                overlay={menu}                
                trigger={['click']}
                placement="bottomCenter">

                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    <UnorderedListOutlined style={{ fontSize: '16px', fontWeight: 'bold', color: "#1678c1" }} />
                    <CaretDownOutlined style={{ paddingLeft: '2px', fontSize: '12px', fontWeight: 'bold', color: "#1678c1" }} />
                </a>

            </Dropdown>
        
    )

}
export default MenuAcciones;